import AppRoutes from "./routes/AppRoutes";
import store from "./store";
import DateFnsUtils from "@date-io/date-fns";
import ReactGA from "react-ga4";
import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { theme } from "./styles/theme";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientConfig, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TrackerStateProvider } from "./context/TrackerContext";
import { CELL_SIZE_MIN } from "./modules/tracker/services/headerGenerator";
import { GA4_ID, INTERCOM_API_BASE, INTERCOM_APP_ID } from "./config/globals";
import { useMount } from "./hooks/useMount";
import { IntercomProvider } from "react-use-intercom";

import "src/styles/ag-theme-pupil-analisys.scss";
import "src/styles/ag-theme-pupil.scss";
import { makeStyles } from "@mui/styles";
import { AuthStateProvider } from "./context/AuthContext";

const useStyles = makeStyles({
    containerRoot: {
        bottom: "100px !important",
    },
});

const App = () => {
    const classes = useStyles();

    const config: QueryClientConfig = {
        defaultOptions: {
            queries: {
                refetchOnMount: true, // TODO to change on refetch on mount
                refetchOnWindowFocus: false,
                retry: false,
                staleTime: 10 * (60 * 1000), // 10 min
            },
        },
    };
    const queryClient = new QueryClient(config);

    useMount(() => {
        if (GA4_ID) {
            ReactGA.initialize(GA4_ID);
        }
    });

    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <IntercomProvider
                    appId={INTERCOM_APP_ID}
                    apiBase={INTERCOM_API_BASE}
                    autoBoot={false}
                >
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <GlobalStyles
                                styles={{
                                    ":root": {
                                        "--cell-size-min": `${CELL_SIZE_MIN}px`,
                                    },
                                }}
                            />
                            <Provider store={store}>
                                <SnackbarProvider
                                    maxSnack={3}
                                    classes={{ containerRoot: classes.containerRoot }}
                                >
                                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                                        <TrackerStateProvider>
                                            <AuthStateProvider>
                                                <AppRoutes />
                                            </AuthStateProvider>
                                        </TrackerStateProvider>
                                    </LocalizationProvider>
                                </SnackbarProvider>
                                <ReactQueryDevtools initialIsOpen={false} />
                            </Provider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </IntercomProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
};

export default App;
