import PaperInner from "src/components/PaperInner";
import COLORS from "src/styles/colors";
import { FC, useEffect, useRef, useState } from "react";
import { AssessmentCreateTypes } from "./AssessmentCreateModal";
import { Box, Button, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HeadingCounter from "src/components/HeadingCounter";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { mdiDelete, mdiFlipToFront } from "@mdi/js";
import Icon from "src/components/Icon";
import AssessmentLabel from "./AssessmentLabel";
import { CustomisedAssessmentFormShape } from "./forms/AddCustomisedAssessmentForm";
import { PublishedAssessmentFormShape } from "./forms/AddPublishedAssessmentForm";
import {
    CustomisedAssessmentUnitModel,
    PublishedAssessmentUnitModel,
} from "../models/AssessmentUnitModel";
import ActionsTableButton from "src/components/ActionTableButton";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";

const useStyles = makeStyles((theme: Theme) => ({
    classMeta: {},
    classMetaItem: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        "& + &": {
            marginTop: theme.spacing(1.5),
        },
    },
    classMetaLabel: {
        color: COLORS.GREY_3,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: 400,
    },
    classMetaText: {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        margin: theme.spacing(0.25, 0, 0),
    },
    classMetaTextBig: {
        fontSize: theme.typography.pxToRem(16),
    },
    classMetaTextNormal: {
        fontWeight: 400,
    },
    classMetaType: {
        display: "flex",
        alignItems: "center",
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        marginTop: theme.spacing(0.25),
    },
    classMetaTypeBig: {
        fontSize: theme.typography.pxToRem(16),
        marginTop: 0,
    },
}));

interface OwnProps {
    type: AssessmentCreateTypes;
    assessment: CustomisedAssessmentFormShape | PublishedAssessmentFormShape;
    collapsed: boolean;
    index: number | null;
    handleRemove: (type: AssessmentCreateTypes, index: number) => void;
    handleDuplicate?: (type: AssessmentCreateTypes, index: number) => void;
    handleEdit?: (type: AssessmentCreateTypes, index: number) => void;
    units?: (CustomisedAssessmentUnitModel | PublishedAssessmentUnitModel)[];
}

const AssessmentAccordeonHeader: FC<OwnProps> = ({
    type,
    assessment,
    collapsed,
    units,
    index,
    handleEdit,
    handleDuplicate,
    handleRemove,
}) => {
    const ref = useRef() as any;
    const { t } = useTranslation();
    const classes = useStyles();
    const [ppAssessment, setPPAssessment] = useState<PublishedAssessmentFormShape | null>(
        type === "pp" ? (assessment as PublishedAssessmentFormShape) : null,
    );
    const [eottAssessment, setEottAssessment] = useState<CustomisedAssessmentFormShape | null>(
        type === "eott" ? (assessment as CustomisedAssessmentFormShape) : null,
    );

    useEffect(() => {
        if (type === "pp" && assessment)
            setPPAssessment(assessment as PublishedAssessmentFormShape);
        if (type === "eott" && assessment)
            setEottAssessment(assessment as CustomisedAssessmentFormShape);
    }, [assessment, type]);

    useEffect(() => {
        if (!collapsed) {
            ref.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [collapsed]);

    return (
        <PaperInner border="bottom" color="lightGrey" variant="course">
            <Box
                ref={ref}
                className={classes.classMeta + " assessmentAccordeonHeader"}
                onClick={() => {
                    // handleToggle(type, index);
                }}
            >
                <Grid container spacing={4}>
                    <Grid item sm={collapsed ? 4 : 8}>
                        <Box sx={{ display: "flex" }}>
                            {index !== null ? (
                                <>
                                    {collapsed && (
                                        <HeadingCounter
                                            number={index + 1}
                                            variant="contained"
                                            size="big"
                                        />
                                    )}
                                    <Box>
                                        {collapsed ? (
                                            <Box className={classes.classMetaItem}>
                                                <Box className={classes.classMetaLabel}>
                                                    {t("class.assessment.add.assessment")}
                                                    <Box className={classes.classMetaType}>
                                                        {type === "pp" || type === "eott" ? (
                                                            <>
                                                                <AssessmentLabel
                                                                    variant={type}
                                                                    size={"small"}
                                                                    sx={{
                                                                        mr: 0.75,
                                                                    }}
                                                                />
                                                                {assessment.name}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                className={clsx(
                                                    classes.classMetaType,
                                                    classes.classMetaTypeBig,
                                                )}
                                            >
                                                {type === "pp" || type === "eott" ? (
                                                    <>
                                                        <AssessmentLabel
                                                            variant={type}
                                                            size="medium"
                                                            sx={{
                                                                mr: 1.25,
                                                            }}
                                                        />
                                                        {t(
                                                            "class.assessment.add.assessment" +
                                                                type,
                                                        )}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    className={clsx(
                                        classes.classMetaType,
                                        classes.classMetaTypeBig,
                                    )}
                                >
                                    <Box sx={{ mr: 1.25, mt: 0.5, width: 24, height: 24 }}>
                                        <Icon path={mdiFlipToFront} size={1} />
                                    </Box>
                                    {t("class.assessment.add.copyAssessment")}
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    {collapsed && (
                        <Grid item sm={4}>
                            {ppAssessment && ppAssessment.tier && (
                                <Box className={classes.classMetaItem}>
                                    {ppAssessment.tier !== "-" && (
                                        <>
                                            <Box className={classes.classMetaLabel}>
                                                {t("common.tierName" + ppAssessment.tier)}
                                            </Box>
                                        </>
                                    )}
                                    <Box
                                        className={clsx(
                                            classes.classMetaText,
                                            classes.classMetaTextNormal,
                                        )}
                                    >
                                        {ppAssessment.unitName ||
                                            units?.find(
                                                u =>
                                                    parseInt(`${u.id}`) ===
                                                    parseInt(`${ppAssessment.unit}`),
                                            )?.name}
                                    </Box>
                                </Box>
                            )}
                            {eottAssessment &&
                                eottAssessment.tierUnits &&
                                eottAssessment.tierUnits.map(tierUnit => {
                                    return (
                                        <Box className={classes.classMetaItem} key={tierUnit.tier}>
                                            {tierUnit.tier !== "-" && (
                                                <Box className={classes.classMetaLabel}>
                                                    {t("common.tierName" + tierUnit.tier)}
                                                </Box>
                                            )}
                                            <Box
                                                className={clsx(
                                                    classes.classMetaText,
                                                    classes.classMetaTextNormal,
                                                )}
                                            >
                                                {tierUnit.unitName ||
                                                    units?.find(
                                                        u =>
                                                            parseInt(`${u.id}`) ===
                                                            parseInt(`${tierUnit.unit}`),
                                                    )?.name}
                                            </Box>
                                        </Box>
                                    );
                                })}
                        </Grid>
                    )}
                    <Grid item sm={4}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            {collapsed &&
                                assessment &&
                                assessment.visibleClassTrackers.length > 0 && (
                                    <Box className={classes.classMetaItem}>
                                        <Box className={classes.classMetaLabel}>
                                            {t("class.assessment.add.numberOfClasses")}
                                        </Box>
                                        <Box
                                            className={clsx(
                                                classes.classMetaText,
                                                classes.classMetaTextNormal,
                                            )}
                                        >
                                            {assessment.hideForNow
                                                ? assessment.visibleClassTrackers.length
                                                : assessment.visibleClassTrackers.filter(
                                                      vct => vct.visible,
                                                  ).length}
                                        </Box>
                                    </Box>
                                )}
                            {!collapsed && (
                                <Button
                                    onClick={() => {
                                        handleRemove(type, index);
                                    }}
                                    variant="text"
                                    startIcon={<Icon path={mdiDelete} />}
                                    disableRipple
                                >
                                    {t("class.assessment.add.delete")}
                                </Button>
                            )}
                            {collapsed && handleEdit && (
                                <>
                                    <ActionsTableButton
                                        actions={[
                                            getMenuActionProps("edit", {
                                                onAction: (rowData: {
                                                    index: number;
                                                    type: AssessmentCreateTypes;
                                                }) => {
                                                    if (handleEdit) {
                                                        handleEdit(rowData.type, rowData.index);
                                                    }
                                                },
                                            }),
                                            getMenuActionProps("duplicate", {
                                                text: t("class.assessment.add.duplicate"),
                                                onAction: (rowData: {
                                                    index: number;
                                                    type: AssessmentCreateTypes;
                                                }) => {
                                                    handleDuplicate(rowData.type, rowData.index);
                                                },
                                            }),
                                            getMenuActionProps("delete", {
                                                onAction: (rowData: {
                                                    index: number;
                                                    type: AssessmentCreateTypes;
                                                }) => {
                                                    handleRemove(rowData.type, rowData.index);
                                                },
                                            }),
                                        ]}
                                        rowData={{ index, type }}
                                    />
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </PaperInner>
    );
};

export default AssessmentAccordeonHeader;
