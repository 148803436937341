import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSubscriptionHistory } from "src/orm/models/SubscriptionHistory";
import { getSchoolAccountId } from "src/services/url";

export const getRequestedListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscriptions/requested/`,
    method: ApiRequests.GET,
};

export type SubscriptionHistoryResponse = OrmSubscriptionHistory[];
