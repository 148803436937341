import Navbar from "src/modules/common/components/Navbar";
import Footer from "src/modules/common/components/Footer";
import MobileLock from "src/components/MobileLock";
import { Outlet } from "react-router-dom";

export default function CommonLayout() {
    return (
        <>
            <Navbar />
            <Outlet />
            <Footer />
            <MobileLock />
        </>
    );
}
