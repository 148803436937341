import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { TrackerContext } from "../TrackerGrid";
import { absenceValuesArray } from "src/modules/tracker/services/valueHandler";

interface OwnProps {
    value: { value: any; displayValue: any } | null;
    context?: TrackerContext;
    field?: string;
    customField?: any;
    isDummy?: boolean;
}

const PinnedTopRenderer: FC<OwnProps> = ({ value, context, field, customField, isDummy }) => {
    const hasCompareValues = !!(value && context?.compareValues);
    const [compareTo, setCompareTo] = useState<number | null>(null);
    const [shouldHideCompare, setShouldHideCompare] = useState<boolean>(false);

    const isFloat =
        !value && !value?.value
            ? false
            : Number(value?.value) === value?.value && value?.value % 1 !== 0;

    useEffect(() => {
        if (hasCompareValues && !compareTo) {
            let tmpValue: number = 0;
            let counter = 0;

            Object.keys(context.compareValues).forEach(row => {
                const val =
                    context.compareValues?.[row]?.[field] !== null &&
                    context.compareValues?.[row]?.[field] !== undefined &&
                    !absenceValuesArray.includes(`${context.compareValues?.[row]?.[field]}`)
                        ? parseFloat(context.compareValues?.[row]?.[field])
                        : null;

                if (val !== null && val !== undefined) {
                    tmpValue = tmpValue + val;
                    counter = counter + 1;
                }
            });
            const compareToValue =
                counter && tmpValue !== null && tmpValue !== undefined && !isFloat
                    ? Math.round(tmpValue / counter)
                    : counter && tmpValue !== null && tmpValue !== undefined && isFloat
                      ? tmpValue / counter
                      : 0;

            if (compareToValue !== null && value?.value !== null) {
                setCompareTo(
                    isFloat
                        ? parseFloat(`${value.value - compareToValue}`)
                        : parseInt(`${value.value - compareToValue}`),
                );
            }
            if ((value?.value === null || value?.value === undefined) && compareToValue === 0) {
                setShouldHideCompare(true);
            }
        }
        if (hasCompareValues && !compareTo && customField && field?.includes("-display")) {
            setShouldHideCompare(true);
        }
    }, [hasCompareValues]);

    return (
        <div
            style={{ height: hasCompareValues || isDummy ? 56 : 38 }}
            className={clsx(
                `ag-value-cell-label ag-value-cell-label--pinned${hasCompareValues ? " ag-value-cell-label--pinned-compare" : ""} `,
            )}
        >
            {!shouldHideCompare && compareTo !== null && compareTo !== undefined && (
                <div
                    style={{ height: "24px", flexShrink: 0 }}
                    className={clsx(
                        "ag-value-cell-compare",
                        `ag-value-cell-compare--${
                            compareTo < 0 ? "red" : compareTo > 0 ? "blue" : "green"
                        }`,
                    )}
                >
                    {!isNaN(compareTo) && !isFloat ? Math.ceil(compareTo) : ""}
                    {!isNaN(compareTo) && isFloat ? compareTo.toFixed(2) : ""}
                </div>
            )}

            <div
                className={`ag-value-cell-label-truncate`}
                style={{
                    pointerEvents: "none",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value?.displayValue}
            </div>
        </div>
    );
};

export default PinnedTopRenderer;
