import { darken } from "@mui/material";
import clsx from "clsx";
import COLORS from "src/styles/colors";

const PinnedTopRenderer = ({
    value,
    columnColour,
}: {
    value: string | null;
    gridApi?: any;
    columnColour?: string;
}) => {
    const backgroundColor = darken(columnColour || COLORS.VERY_LIGHT_GREY_8, 0.04);
    return (
        <div
            className={clsx("ag-value-cell-label ag-value-cell-label--pinned")}
            style={{
                backgroundColor: backgroundColor,
                height: "100%",
            }}
        >
            <div
                className="ag-value-cell-label-truncate"
                style={{
                    pointerEvents: "none",
                }}
            >
                {value}
            </div>
        </div>
    );
};

export default PinnedTopRenderer;
