import { ApiRequests, ApiEndpoint } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const getClassTrackerGroupSnapshotListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker-group/{classTrackerGroup}/snapshot/`,
    method: ApiRequests.GET,
};

export interface SnapshotList {
    id: number;
    gradeBoundaryFactor: number;
    gradeBoundarySeries: ListObject<number>;
    created: number;
    canRemove?: boolean;
    name: string;
    hasReports: boolean;
}
