import Subscription, { OrmSubscription } from "src/orm/models/Subscription";
import SubscriptionHistory, { OrmSubscriptionHistory } from "src/orm/models/SubscriptionHistory";
import {
    selectAll,
    selectAllWithFilter,
    selectByGivenId,
    selectOneWithFilter,
} from "src/orm/selectors";

export const subscriptionsSelector = selectAll<OrmSubscription>(Subscription);

export const subscriptionWithFilterSelector = selectAllWithFilter<OrmSubscription>(Subscription);

export const subscriptionDetailsSelector = selectByGivenId<OrmSubscription>(Subscription);

export const subscriptionHistorySelector = selectAll<OrmSubscriptionHistory>(SubscriptionHistory);

export const subscriptionHistoryWithFilterSelector =
    selectAllWithFilter<OrmSubscriptionHistory>(SubscriptionHistory);

export const subscriptionHistorySingleWithFilterSelector =
    selectOneWithFilter<OrmSubscriptionHistory>(SubscriptionHistory);

export const subscriptionHistortDetailsSelector =
    selectByGivenId<OrmSubscriptionHistory>(SubscriptionHistory);
