import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ClassTrackerRelatedObject } from "src/modules/tracker/dto/TrackerRelated";
import { getSchoolAccountId } from "src/services/url";

export const ARCHIVED_TRACKER_RELATED_DATA_QUERY = "archivedTrackerRelatedDataQuery";

export const useArchivedTrackerRelatedData = (
    id: number,
    dataSource1?: "live-tracking" | "forecast" | string,
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [ARCHIVED_TRACKER_RELATED_DATA_QUERY, schoolAccountId, id, dataSource1],
        queryFn: async (): Promise<ClassTrackerRelatedObject | null> => {
            if (id) {
                let url = `school/${getSchoolAccountId()}/archive/class-tracker/${id}/related-data/`;

                if (dataSource1 && dataSource1 !== "live-tracking" && dataSource1 !== "forecast") {
                    url = `school/${getSchoolAccountId()}/class-tracker/${id}/snapshot/${dataSource1}/related-data/`;
                }
                const { data } = await axiosInstance.get(url);

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
