import COLORS from "src/styles/colors";
import { Box, Grid, styled } from "@mui/material";

export const StyledConvertsLabel = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    color: "#C2C4C8",
    "& > p": {
        marginRight: theme.spacing(1.5),
        color: "#C2C4C8",
        fontStyle: "italic",
    },
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
    "& > .highlight": {
        position: "relative",
    },
    "& > .highlight::before": {
        content: '""',
        backgroundColor: COLORS.VERY_LIGHT_GREY_4,
        position: "absolute",
        top: theme.spacing(-3),
        right: theme.spacing(-3),
        bottom: theme.spacing(-3),
        left: theme.spacing(-3),
        zIndex: 1,
        pointersEvents: "none",
    },
    "& > .highlight > *": {
        position: "relative",
        zIndex: 2,
    },
}));

export const StyledTotalMaxMarks = styled(Box)(({ theme }) => ({
    backgroundColor: COLORS.VERY_LIGHT_GREY_4,
    padding: theme.spacing(1.5, 1),
    marginRight: 62,
    width: "calc(33% - 78px)",
    display: "flex",
    flexDirection: "column",
    "& span": {
        fontSize: theme.typography.pxToRem(10),
        color: theme.palette.text.secondary,
    },
    "& div": {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightMedium,
    },
}));
