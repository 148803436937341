import { useState } from "react";
import { Box, Button, Switch, Theme } from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import Icon from "src/components/Icon";
import { mdiCog, mdiViewCarousel } from "@mdi/js";
import { useTranslation } from "react-i18next";
import ConfigureColumnsModal from "../../Common/ConfigureStudentColumnsModal";
import COLORS from "src/styles/colors";
import { KS4_GRADE_VIEW_TYPE } from "../KS4GradeAnalysisGrid";
import { CustomFormControlLabel } from "src/modules/common/components/Grid/GridToolsToggleButton";
import AnalysisTagsModal from "../../Common/AnalysisTagsModal";
import { ColumnApi, GridApi } from "ag-grid-community";
import AnalysisTagsUpsertModal from "../../Common/AnalysisTagsUpsertModal";
import { useAnalysisTagList } from "src/modules/tagging/hooks/Analysis/useAnalysisTagList";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useAnalysisUserSettingsStore } from "src/modules/analysis/hooks/useAnalysisUserSettingsStore";

interface OwnProps {
    handleOnClick: () => {};
    context: any;
    api: GridApi;
    columnApi: ColumnApi;
}

const useStyles = makeStyles((theme: Theme) => ({
    configButton: {
        position: "absolute",
        top: 28,
        left: 22,
        zIndex: 200,
    },
    configureContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(1.5),
        borderTop: `1px solid ${theme.palette.divider}`,
        width: "calc(100% - 44px)",
        position: "absolute",
        top: 68,
        left: 22,
        zIndex: 200,
    },
    configureItem: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    summary: {
        backgroundColor: COLORS.VERY_LIGHT_GREY_4,
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2, 3),
        width: "100%",
        position: "absolute",
        top: 134,
    },
    legend: {
        backgroundColor: COLORS.VERY_LIGHT_GREY_4,
        display: "flex",
        flexDirection: "column",
        gap: 10,
    },
    legendItem: {
        display: "inline-flex",
        alignItems: "center",
        color: COLORS.GREY_3,
        fontFamily: theme.typography.fontFamilyPrimary,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: 400,
        lineHeight: 1,
        "& > span": {
            color: COLORS.WHITE,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(10),
            fontWeight: 600,
            lineHeight: 1.1,
            backgroundColor: COLORS.LIGHT_GREY_4,
            width: 23,
            height: 15,
            borderRadius: 2,
            margin: theme.spacing(0, 1, 0, 0),
        },
    },
    avarageValuesContainer: {
        display: "flex",
        gap: 24,
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(0, 0, 2),
        margin: theme.spacing(0, 0, 2),
    },
    avarageValuesItem: {
        "& > span": {
            color: COLORS.GREY_3,
            fontSize: theme.typography.pxToRem(10),
            fontWeight: theme.typography.fontWeightRegular,
        },
        "& > strong": {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: theme.typography.fontWeightMedium,
            marginLeft: theme.spacing(1),
        },
    },
}));

const CustomSwitch = withStyles(() =>
    createStyles({
        switchBase: {
            "&$checked": {
                color: COLORS.GREEN_1,
            },
            "&$checked + $track": {
                backgroundColor: COLORS.GREEN_1,
            },
        },
        checked: {},
        track: {},
    }),
)(Switch);

const ColumnConfigurationHeader = ({ context, columnApi }: OwnProps) => {
    const { cohort, yearGroup, viewType, studentColumns } = context;

    const classes = useStyles();
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();

    const [tagModalOpened, setTagModalOpened] = useState<boolean>(false);
    const [tagUpsertModalId, setTagUpsertModalId] = useState<number | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const { refetch: refetchTagList } = useAnalysisTagList(cohort, yearGroup);

    const { setAnalysisUserSettings: setAnalysisUserSettingsStore, ...analysisUserSettings } =
        useAnalysisUserSettingsStore();
    const setAnalysisUserSettings = values => {
        setAnalysisUserSettingsStore(values);
        refetchTagList();
    };

    const handleTagModalClose = (confirmed: boolean, selectedTags?: number[]) => {
        if (confirmed && selectedTags) {
            const visibleTags = context?.tags
                ?.filter(tag => !!selectedTags.includes(tag.id))
                .map(tag => `tag-${tag.id}`);
            columnApi?.setColumnsVisible(visibleTags, true);

            const hiddenTags = context?.tags
                ?.filter(tag => !selectedTags.includes(tag.id))
                .map(tag => `tag-${tag.id}`);
            columnApi?.setColumnsVisible(hiddenTags, false);

            const newUserSettings: any = { ...analysisUserSettings, selectedTags };

            if (JSON.stringify(newUserSettings) !== JSON.stringify(analysisUserSettings)) {
                setAnalysisUserSettings(newUserSettings);
            }
        }

        setTagModalOpened(false);
    };

    const handleCreateTag = (newTagId: number) => {
        const newUserSettings: any = {
            ...analysisUserSettings,
            selectedTags: analysisUserSettings?.selectedTags
                ? [...analysisUserSettings.selectedTags, newTagId]
                : [newTagId],
        };
        if (JSON.stringify(newUserSettings) !== JSON.stringify(analysisUserSettings)) {
            setAnalysisUserSettings(newUserSettings);
        }
    };

    return cohort && yearGroup > -1 && viewType ? (
        <>
            <ConfigureColumnsModal
                availableStudentColumns={studentColumns}
                cohort={cohort}
                yearGroup={yearGroup}
                viewType={viewType}
                afterModalCloseActions={saved => {
                    if (saved) {
                        navigate(0);
                    } else {
                        setModalOpen(false);
                    }
                }}
                open={modalOpen}
            />
            <Button
                id="column-config-btn"
                onClick={() => setModalOpen(true)}
                variant="text"
                startIcon={<Icon path={mdiViewCarousel} />}
                disableRipple
                className={classes.configButton}
            >
                {t("analysis.ks4Grade.configureColumnsBtn")}
            </Button>
            <div className={classes.configureContainer}>
                <div className={classes.configureItem}>
                    <CustomFormControlLabel
                        control={
                            <CustomSwitch
                                name="tagsVisible"
                                checked={analysisUserSettings?.tagsVisible}
                                onChange={() =>
                                    setAnalysisUserSettings({
                                        tagsVisible: !analysisUserSettings?.tagsVisible,
                                    })
                                }
                            />
                        }
                        label={t("tracker.grid.tagsToggle")}
                        id="tagsToggle"
                    />
                    {analysisUserSettings?.tagsVisible && (
                        <Button
                            onClick={() => {
                                setTagModalOpened(true);
                            }}
                            variant="text"
                            startIcon={<Icon path={mdiCog} />}
                            disableRipple
                            id="tagsConfigureButton"
                        >
                            {t("common.configure")}
                        </Button>
                    )}
                </div>
            </div>

            {analysisUserSettings?.tagsVisible && (
                <>
                    <AnalysisTagsModal
                        cohort={cohort}
                        yearGroup={yearGroup}
                        opened={tagModalOpened}
                        handleTagModalClose={handleTagModalClose}
                        selectedTags={analysisUserSettings?.selectedTags || []}
                        handleUpsertTag={tagId => {
                            setTagUpsertModalId(tagId);
                        }}
                    />
                    <AnalysisTagsUpsertModal
                        cohort={cohort}
                        yearGroup={yearGroup}
                        opened={tagUpsertModalId !== null}
                        tagId={tagUpsertModalId}
                        handleClose={reload => {
                            if (reload) navigate(0);
                            setTagUpsertModalId(null);
                        }}
                        handleCreateTag={handleCreateTag}
                    />
                </>
            )}
            {viewType === KS4_GRADE_VIEW_TYPE && (
                <Box className={classes.summary}>
                    <Box className={classes.avarageValuesContainer}>
                        <div className={classes.avarageValuesItem}>
                            <span>{t("analysis.ks4Grade.p8Score")}:</span>
                            <strong>{context?.averageRow?.computed_4 || 0}</strong>
                        </div>
                        <div className={classes.avarageValuesItem}>
                            <span>{t("analysis.ks4Grade.a8Score")}:</span>
                            <strong>{context?.averageRow?.computed_1 || 0}</strong>
                        </div>
                        <div className={classes.avarageValuesItem}>
                            <span>{t("analysis.ks4Grade.ebaccAPS")}:</span>
                            <strong>{context?.averageRow?.computed_5 || 0}</strong>
                        </div>
                    </Box>
                    <Box className={classes.legend}>
                        <Box className={classes.legendItem}>
                            <Box component="span">8+E</Box> {t("analysis.ks4Grade.includeda8e")}
                        </Box>
                        <Box className={classes.legendItem}>
                            <Box component="span">8</Box> {t("analysis.ks4Grade.includeda8")}
                        </Box>
                        <Box className={classes.legendItem}>
                            <Box component="span">E</Box> {t("analysis.ks4Grade.includede")}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    ) : (
        <></>
    );
};

export default ColumnConfigurationHeader;
