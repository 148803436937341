import MisSyncUnsyncForm from "../../components/MIS/forms/MisSyncUnsyncForm";
import FormikRef from "src/forms/FormikRef";
import PaperInner from "src/components/PaperInner";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import AppContainer from "src/components/AppContainer";
import { Button, Typography, Box, Paper, CircularProgress } from "@mui/material";
import { FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { createRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GroupCallClassFormFields } from "../../components/ClassTracking/forms/ConvertGroupCallClassForm";
import { addDataSyncClassSchema } from "../../components/ClassTracking/forms/addClassSchema";
import {
    CreateGroupCallClassRequest,
    useGroupCallClassesCreateMutation,
} from "../../hooks/MIS/useGroupCallClassesCreateMutation";
import { FormError, getErrorMessage } from "src/services/error";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

import { ROUTE_CLASS_CLASS_LIST } from "src/routes";

const convertFormDataToRequest = (
    values: GroupCallClassFormFields,
): CreateGroupCallClassRequest => {
    return {
        yearGroups: values.yearGroups
            .filter(
                yg =>
                    yg.classes &&
                    yg.classes.length > 0 &&
                    yg.classes.some(c => c.selected && !c.synchronized),
            )
            .map(yg => ({
                yearGroup: yg.yearGroup,
                classes: yg.classes
                    .filter(c => c.selected && !c.synchronized)
                    .map(c => ({ id: c.id })),
            })),
        attributes: values.attributes
            .filter(attr => {
                return (
                    (attr.isSelected === true || attr.isMandatory === true) &&
                    attr.attributeItemId &&
                    !values.disabledItems?.includes(attr.attributeItemId)
                );
            })
            .map(attr => ({
                id: attr.classTrackerAttributeValueId || undefined,
                customName: attr.customName || null,
                attributeValue: { id: attr.id },
            })),
        syncWithMis: true,
    };
};

const AddClassMisSynchroniseClassContainer = () => {
    const { data: accountInfo } = useAccountInfo();
    const form = createRef() as any;
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const {
        mutate: createClasses,
        isSuccess,
        isPending,
    } = useGroupCallClassesCreateMutation(err => {
        const currentForm = form.current as any;
        currentForm.setSubmitting(false);
        const error: FormError = getErrorMessage(err);
        if (error.message)
            enqueueSnackbar(error.message, {
                ...SnackbarErrorOptions,
            });
        if (error.formError) currentForm.setErrors(error.formError);
    });

    const initialValues: GroupCallClassFormFields = {
        attributes: [],
        items: [],
        groups: [],
        disabledItems: [],
        initialEditAttributes: [],
        yearGroups: [],
    };

    const handleSubmit = (values: GroupCallClassFormFields) => {
        const convertedValues = convertFormDataToRequest(values);

        addDataSyncClassSchema(t)
            .isValid(convertedValues)
            .then(isValid => {
                if (isValid) {
                    if (
                        convertedValues.yearGroups &&
                        convertedValues.yearGroups.length > 0 &&
                        convertedValues.yearGroups.some(yg => yg.classes.length > 0) &&
                        values.specification
                    ) {
                        createClasses({
                            ...convertedValues,
                            specificationId: values.specification,
                        });
                    } else if (
                        (convertedValues.yearGroups && convertedValues.yearGroups.length === 0) ||
                        (convertedValues.yearGroups &&
                            convertedValues.yearGroups.length > 0 &&
                            !convertedValues.yearGroups.some(yg => yg.classes.length > 0))
                    ) {
                        enqueueSnackbar(t("class.addClass.noClasses"), {
                            ...SnackbarErrorOptions,
                        });

                        form.current.setSubmitting(false);
                    }
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });

                    form.current.setSubmitting(false);
                }
            });
    };

    useEffect(() => {
        if (isSuccess) {
            navigate(ROUTE_CLASS_CLASS_LIST);
            enqueueSnackbar(t("class.addClass.classCreatedSuccess"), { variant: "success" });
        }
    }, [isSuccess]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.addClass.headerMisSynchroniseClass").replaceAll(
                    "MIS_Name",
                    accountInfo?.misName || "",
                )}
            </Typography>
            <Paper>
                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => addDataSyncClassSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<GroupCallClassFormFields>) => (
                        <>
                            <MisSyncUnsyncForm shouldSynchronise={false} />
                            <PaperInner border="top">
                                <Box mt={4}>
                                    <Button
                                        disabled={formProps.isSubmitting}
                                        onClick={formProps.submitForm}
                                        color="primary"
                                    >
                                        {t("class.addClass.addClassButton")}
                                        {isPending && (
                                            <Box ml={2}>
                                                <CircularProgress size={18} />
                                            </Box>
                                        )}
                                    </Button>
                                    <GenericErrorMessage
                                        errors={formProps.errors}
                                        submitCount={formProps.submitCount}
                                    />
                                </Box>
                            </PaperInner>
                        </>
                    )}
                </FormikRef>
            </Paper>
        </AppContainer>
    );
};

export default AddClassMisSynchroniseClassContainer;
