import { FC, useEffect } from "react";
import { FormControlLabel, Grid, MenuItem, Radio, Typography } from "@mui/material";
import { Field, Form } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { usePrevious } from "src/hooks/usePrevious";
import { ReportTemplateList } from "../../../api/ReportTemplate/getList";
import { SearchSnapshotFiltersResponse } from "../../../api/SnapshotReport/searchSnapshotFilters";
import { CreateSubjectSnapshotReportRequest } from "src/modules/report/api/SnapshotReport/createSubject";
import * as yup from "yup";
import DateTimePicker, { getConvertedDateTime } from "src/components/DateTimePicker";
import { SearchSnapshotListResponse } from "src/modules/report/hooks/useSearchSnapshotList";

export interface SubjectReportFormShape {
    tmpSubject: number;
    tmpQualification: number;
    tmpSpecification: number;
    tmpYearGroup: number;
    tmpPublishType: string;
    snapshot: number;
    publishedAt: string | null;
    reportTemplate: number;
}

export const snapshotReportFormInitialValues = {
    tmpSubject: 0,
    tmpQualification: 0,
    tmpSpecification: 0,
    tmpYearGroup: 0,
    tmpPublishType: "now",
    snapshot: 0,
    publishedAt: null,
    reportTemplate: 0,
};

export const subjectReportSchema = (t: TFunction) =>
    yup.object().shape({
        snapshot: yup
            .number()
            .min(1, t("report.snapshot.create.snapshotRequired"))
            .required(t("report.snapshot.create.snapshotRequired")),
        reportTemplate: yup
            .number()
            .min(1, t("report.snapshot.create.reportTemplateRequired"))
            .required(t("report.snapshot.create.reportTemplateRequired")),
    });

interface OwnProps {
    values: SubjectReportFormShape;
    setValues: (values) => void;
    setFieldValue: (name: string, value: any) => void;
    isSubmitting: boolean;
    courseFilters: SearchSnapshotFiltersResponse | null;
    handleSpecificationChange: (values: SubjectReportFormShape) => void;
    snapshots: SearchSnapshotListResponse | null;
    cohort: number | null;
    reportTemplates: ReportTemplateList | null;
}

export const convertFormValuesToRequest = (
    values: SubjectReportFormShape,
): CreateSubjectSnapshotReportRequest => {
    return {
        snapshot: { id: values.snapshot },
        publishedAt:
            values.tmpPublishType === "now"
                ? new Date().toISOString()
                : getConvertedDateTime(values.publishedAt),
        reportTemplate: { id: values.reportTemplate },
    };
};

const SubjectReportForm: FC<OwnProps> = ({
    courseFilters,
    values,
    isSubmitting,
    setValues,
    setFieldValue,
    handleSpecificationChange,
    snapshots,
    cohort,
    reportTemplates,
}) => {
    const { t } = useTranslation();

    const qualificationsList =
        courseFilters?.subjectAreas.find(sa => sa.id === values.tmpSubject)?.qualifications || [];

    const specificationsList =
        qualificationsList?.find(sa => sa.id === values.tmpQualification)?.specifications || [];

    const yearGroupList =
        specificationsList?.find(s => s.id === values.tmpSpecification)?.yearGroups || [];

    const prevCohort = usePrevious(cohort);

    useEffect(() => {
        if (cohort && prevCohort && prevCohort !== cohort) {
            setValues(snapshotReportFormInitialValues);
        }
    }, [cohort]);

    useEffect(() => {
        if (values.tmpSpecification && values.tmpYearGroup) {
            handleSpecificationChange(values);
        }
    }, [values.tmpSpecification, values.tmpYearGroup]);

    useEffect(() => {
        if (reportTemplates && reportTemplates.length > 0 && values.reportTemplate === 0) {
            setFieldValue("reportTemplate", reportTemplates[0].id);
        }
    }, [reportTemplates]);

    return (
        <Form>
            {courseFilters && courseFilters.subjectAreas.length > 0 ? (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography>{t("report.snapshot.create.hint1")}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name={"tmpSubject"}
                            label={t("report.snapshot.create.subjectArea")}
                            select
                            component={TextField}
                            fullWidth
                            displayEmpty
                            placeholder={t("report.snapshot.create.subjectArea")}
                            disabled={isSubmitting}
                        >
                            <MenuItem value={0}>{t("common.none")}</MenuItem>
                            {courseFilters.subjectAreas
                                .sort((a, b) => (b.name < a.name ? 1 : -1))
                                .map(sa => {
                                    return (
                                        <MenuItem key={sa.id} value={sa.id}>
                                            {sa.name}
                                        </MenuItem>
                                    );
                                })}
                        </Field>
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name={"tmpQualification"}
                            label={t("report.snapshot.create.qualification")}
                            select
                            component={TextField}
                            fullWidth
                            disabled={isSubmitting || !values.tmpSubject}
                            displayEmpty
                            placeholder={t("report.snapshot.create.qualification")}
                        >
                            <MenuItem value={0}>{t("common.none")}</MenuItem>
                            {qualificationsList &&
                                qualificationsList.length > 0 &&
                                qualificationsList
                                    .sort((a, b) => (b.name < a.name ? 1 : -1))
                                    .map(q => {
                                        return (
                                            <MenuItem key={q.id} value={q.id}>
                                                {q.name}
                                            </MenuItem>
                                        );
                                    })}
                        </Field>
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name={"tmpSpecification"}
                            label={t("report.snapshot.create.specification")}
                            select
                            component={TextField}
                            fullWidth
                            disabled={isSubmitting || !values.tmpQualification}
                            displayEmpty
                            placeholder={t("report.snapshot.create.specification")}
                        >
                            <MenuItem value={0}>{t("common.none")}</MenuItem>
                            {specificationsList &&
                                specificationsList.length > 0 &&
                                specificationsList
                                    .sort((a, b) => (b.name < a.name ? 1 : -1))
                                    .map(s => {
                                        return (
                                            <MenuItem key={s.id} value={s.id}>
                                                {s.name}
                                            </MenuItem>
                                        );
                                    })}
                        </Field>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{t("report.snapshot.create.hint2")}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name={"tmpYearGroup"}
                            label={t("report.snapshot.create.yearGroup")}
                            select
                            component={TextField}
                            fullWidth
                            disabled={isSubmitting || !values.tmpSpecification}
                            displayEmpty
                            placeholder={t("report.snapshot.create.yearGroup")}
                        >
                            <MenuItem value={""}>{t("common.none")}</MenuItem>
                            {yearGroupList &&
                                yearGroupList.length > 0 &&
                                yearGroupList.map(yg => {
                                    return (
                                        <MenuItem key={yg} value={yg}>
                                            {yg === 0 ? t("common.yg0") : yg}
                                        </MenuItem>
                                    );
                                })}
                        </Field>
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name={"snapshot"}
                            label={t("report.snapshot.create.snapshot")}
                            select
                            component={TextField}
                            fullWidth
                            disabled={isSubmitting || !values.tmpYearGroup}
                            displayEmpty
                            placeholder={t("report.snapshot.create.snapshot")}
                        >
                            <MenuItem value={0}>{t("common.none")}</MenuItem>
                            {snapshots &&
                                snapshots.length > 0 &&
                                snapshots.map(s => {
                                    return (
                                        <MenuItem key={s.id} value={s.id}>
                                            {s.name}
                                        </MenuItem>
                                    );
                                })}
                        </Field>
                    </Grid>
                    {values.snapshot ? (
                        <>
                            <Grid item xs={12}>
                                <Field
                                    name={"tmpPublishType"}
                                    label={""}
                                    component={RadioGroup}
                                    row
                                >
                                    <FormControlLabel
                                        value={`now`}
                                        control={<Radio />}
                                        label={<>{t("report.snapshot.create.publishTypeNow")}</>}
                                    />
                                    <FormControlLabel
                                        value={`future`}
                                        control={<Radio />}
                                        label={<>{t("report.snapshot.create.publishTypeFuture")}</>}
                                    />
                                </Field>
                            </Grid>
                            {values.tmpPublishType === "future" ? (
                                <Grid item xs={4}>
                                    <DateTimePicker
                                        name="publishedAt"
                                        label={t("report.snapshot.create.publishFutureDate")}
                                        disabled={
                                            isSubmitting || !!(values.tmpPublishType !== "future")
                                        }
                                    />
                                </Grid>
                            ) : (
                                <></>
                            )}
                            <Grid item xs={4}>
                                <Field
                                    name={"reportTemplate"}
                                    label={t("report.snapshot.create.reportTemplate")}
                                    select
                                    component={TextField}
                                    fullWidth
                                    displayEmpty
                                    placeholder={t("report.snapshot.create.reportTemplate")}
                                >
                                    {reportTemplates &&
                                        reportTemplates.length > 0 &&
                                        reportTemplates.map(s => {
                                            return (
                                                <MenuItem key={s.id} value={s.id || 0}>
                                                    {s.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Field>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            ) : (
                <Typography variant="h5">{t("report.snapshot.create.noSubjects")}</Typography>
            )}
        </Form>
    );
};

export default SubjectReportForm;
