import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ListObject, FileType } from "src/forms/types";
import { AttributesRequest } from "./uploadAndSend";
import { getSchoolAccountId } from "src/services/url";

export const multipleClassesEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker/multiple-classes/`,
    method: ApiRequests.POST,
};

export interface MultipleClassesRequest {
    specification: ListObject<number>;
    cohort: ListObject<number>;
    attrubutes: AttributesRequest[];
    file: FileType;
}
