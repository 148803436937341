import { useEffect, useCallback, createRef } from "react";
import { Typography, Paper, CircularProgress, Box } from "@mui/material";
import FormikRef from "src/forms/FormikRef";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppState } from "src/store/reducers";
import { ApiData, ApiStatus } from "src/api/constants";
import { Dispatch } from "redux";
import { FormikProps } from "formik";
import { FormError, getErrorMessage } from "src/services/error";
import { useSnackbar } from "notistack";
import { usePrevious } from "src/hooks/usePrevious";
import AppContainer from "src/components/AppContainer";
import { emptyReportTemplate, OrmReportTemplate } from "src/orm/models/ReportTemplate";
import { reportTemplateDetailsSelector } from "src/modules/report/selectors/ReportTempatesSelectors";
import { useMount } from "src/hooks/useMount";
import ReportTemplateForm, {
    ReportTemplateFormShape,
    reportTemplateSchema,
} from "../components/ReportTemplateForm";
import { ReportActions } from "../store/actions";
import { ROUTE_REPORT_TEMPLATE_LIST } from "src/routes";
import { useParams } from "react-router";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const dispatchActions = (dispatch: Dispatch) => ({
    getReportTemplateDetails: (id: number) => {
        dispatch(ReportActions.getReportTemplateDetails(id));
    },
    createReportTemplate: (values: OrmReportTemplate) => {
        dispatch(ReportActions.createReportTemplate(values));
    },
    updateReportTemplate: (id: number, values: OrmReportTemplate) => {
        dispatch(ReportActions.updateReportTemplate(id, values));
    },
});

const ReportTemplateAddEditContainer = () => {
    const { id } = useParams();
    const templateId = id ? parseInt(id) : 0;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const form = createRef();

    const { getReportTemplateDetails, createReportTemplate, updateReportTemplate } =
        dispatchActions(dispatch);

    const {
        apiGetDetails,
        apiCreate,
        apiUpdate,
        reportTemplate,
    }: {
        apiGetDetails: ApiData;
        apiCreate: ApiData;
        apiUpdate: ApiData;
        reportTemplate: OrmReportTemplate;
    } = useSelector(
        (state: AppState) => ({
            apiGetDetails: state.api.report.getReportTemplateDetails,
            apiCreate: state.api.report.createReportTemplate,
            apiUpdate: state.api.report.updateReportTemplate,
            reportTemplate: reportTemplateDetailsSelector(state, parseInt(`${id}`)),
        }),
        shallowEqual,
    );

    const handleErrorResponse = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    if (error.formError) currentForm.setErrors(error.formError);
                    break;
                }
            }
        },
        [enqueueSnackbar, form],
    );

    const initialValues: ReportTemplateFormShape = reportTemplate
        ? {
              ...reportTemplate,
              id: reportTemplate?.id ? reportTemplate.id : undefined,
              fileType: reportTemplate?.fileNamePattern ? "individual" : "merge",
              fileNamePattern: reportTemplate?.fileNamePattern || "",
              hideDistanceFromMtgColours: !reportTemplate.showDistanceFromMtgColours,
          }
        : {
              ...emptyReportTemplate,
              id: undefined,
              fileType: emptyReportTemplate?.fileNamePattern ? "individual" : "merge",
              fileNamePattern: emptyReportTemplate?.fileNamePattern || "",
              hideDistanceFromMtgColours: !reportTemplate.showDistanceFromMtgColours,
          };

    const handleSubmit = (values: ReportTemplateFormShape) => {
        const converted = {
            name: values.name,
            fileNamePattern:
                values.fileType === "individual" ? values.fileNamePattern || null : null,
            mergeIntoOneFile: values.fileType === "merge",
            hideMtg: values.hideMtg,
            showDistanceFromMtgColours: !values.hideDistanceFromMtgColours,
            hideCurrentGrade: values.hideCurrentGrade,
            hideAverageUnitGrade: values.hideAverageUnitGrade,
            hideTpg: values.hideTpg,
            reportTemplateCustomFields: values.reportTemplateCustomFields,
        };
        if (templateId) {
            updateReportTemplate(templateId, {
                id: templateId,
                ...converted,
            });
        } else {
            createReportTemplate({
                id: null,
                ...converted,
            });
        }
    };

    useMount(() => {
        if (templateId) {
            getReportTemplateDetails(templateId);
        }
    });

    const prevApiCreateStatus = usePrevious(apiCreate.status);
    const prevApiUpdateStatus = usePrevious(apiUpdate.status);

    useEffect(() => {
        if (prevApiCreateStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.SUCCESS) {
            navigate(ROUTE_REPORT_TEMPLATE_LIST);
            enqueueSnackbar(t("report.reportTemplate.createdMessage"), { variant: "success" });
        } else if (
            prevApiCreateStatus === ApiStatus.LOADING &&
            apiCreate.status === ApiStatus.ERROR
        ) {
            handleErrorResponse(apiCreate);
        }
    }, [apiCreate, prevApiCreateStatus]);

    useEffect(() => {
        if (prevApiUpdateStatus === ApiStatus.LOADING && apiUpdate.status === ApiStatus.SUCCESS) {
            navigate(ROUTE_REPORT_TEMPLATE_LIST);
            enqueueSnackbar(t("report.reportTemplate.updatedMessage"), { variant: "success" });
        } else if (
            prevApiUpdateStatus === ApiStatus.LOADING &&
            apiUpdate.status === ApiStatus.ERROR
        ) {
            handleErrorResponse(apiUpdate);
        }
    }, [apiUpdate, prevApiUpdateStatus]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {templateId
                    ? t("report.reportTemplate.addEdit.headerEdit")
                    : t("report.reportTemplate.addEdit.headerAdd")}
            </Typography>
            <Paper>
                {templateId && apiGetDetails.status === ApiStatus.LOADING ? (
                    <Box mt={4} mb={4} textAlign="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <></>
                )}
                {!templateId || (templateId && reportTemplate?.id) ? (
                    <FormikRef
                        ref={form}
                        initialValues={initialValues}
                        validationSchema={() => reportTemplateSchema(t)}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {(formProps: FormikProps<ReportTemplateFormShape>) => (
                            <ReportTemplateForm {...formProps} />
                        )}
                    </FormikRef>
                ) : (
                    <></>
                )}
            </Paper>
        </AppContainer>
    );
};

export default ReportTemplateAddEditContainer;
