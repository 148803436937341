import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { AssessmentListModel } from "../../models/AssessmentListModel";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

const apiRemoveCustomisedAssessment = async (
    id: number,
): Promise<AxiosResponse<AssessmentListModel>> => {
    return await axiosInstance.delete(
        `school/${getSchoolAccountId()}/assessments/customised/${id}/`,
    );
};

export const useCustomisedAssessmentRemoveMutation = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: { id: number }) => apiRemoveCustomisedAssessment(values.id),
        onSuccess: () => {
            enqueueSnackbar(t("common.removed"), { variant: "success" });
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
