import clsx from "clsx";
import {
    ReportDownloadState,
    useReportDownloader,
} from "src/modules/tracker/hooks/useReportDownloader";
import PinnedTopRenderer from "./PinnedTopRenderer";
import { FC } from "react";
import { CELL_SIZE_MIN } from "src/modules/tracker/services/headerGenerator";

interface OwnProps {
    context: any;
    data: any;
    node: any;
}

export const ReportDownloadRenderer: FC<OwnProps> = ({ context, data, node }) => {
    if (node.rowPinned) {
        return <PinnedTopRenderer value={null} isDummy={true} />;
    }

    const studentId = data.id || data.student_id;

    const classTrackerId =
        context.classTrackerId ||
        data.classTracker?.id ||
        data.student_classTracker_id ||
        data.student_classTrackerId;

    const { handleReportDownloadClick, reportDownloadState } = useReportDownloader();

    const isComparing = context.snapshotData && context.snapshotData.compare !== null;

    const isDownloading = reportDownloadState
        ? !!reportDownloadState.find(
              (rds: ReportDownloadState) =>
                  rds.classTracker === classTrackerId &&
                  rds.id === studentId &&
                  rds.isDownloading === true,
          )
        : false;

    return (
        <div
            style={{ height: isComparing ? 56 : CELL_SIZE_MIN }}
            className={clsx(
                "ag-report-cell",
                context.snapshotData &&
                    context.snapshotData.compare !== null &&
                    "ag-report-cell--compare",
            )}
        >
            <button
                className="ag-report-cell-btn"
                type="button"
                onClick={() => handleReportDownloadClick(context, data, classTrackerId, studentId)}
                disabled={isDownloading}
            >
                <svg viewBox="0 0 24 24" role="presentation" style={{ width: 18, height: 18 }}>
                    <path
                        d="M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M16.5,16.25C16.5,14.75 13.5,14 12,14C10.5,14 7.5,14.75 7.5,16.25V17H16.5M12,12.25A2.25,2.25 0 0,0 14.25,10A2.25,2.25 0 0,0 12,7.75A2.25,2.25 0 0,0 9.75,10A2.25,2.25 0 0,0 12,12.25Z"
                        style={{ fill: "currentcolor" }}
                    ></path>
                </svg>
                <span
                    className={clsx(
                        "ag-report-cell-status",
                        isDownloading && " ag-report-cell-status--update",
                    )}
                />
            </button>
        </div>
    );
};
