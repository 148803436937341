import { Box, Button, Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiltersShape } from "src/modules/common/components/Grid/GridStudentFilter";

interface OwnProps {
    handleFiltersChange: (filter: FiltersShape) => void;
    specifications;
    relatedData;
    columns;
}

const AnalysisGradeFilter: FC<OwnProps> = ({
    handleFiltersChange,
    specifications,
    relatedData,
    columns,
}) => {
    const { t } = useTranslation();
    const [specColumn, setSpecColumn] = useState<string>("");
    const [minimumValue, setMinimumValue] = useState<string | number>("");
    const [maximumValue, setMaximumValue] = useState<string | number>("");
    const [showWithNoData, setShowWithNoData] = useState<boolean>(false);

    const getGrades = (): any => {
        if (specColumn && specColumn !== "ALL_GRADES") {
            const gradeTypeId = columns[specColumn].gradeTypeId || null;

            return (
                (gradeTypeId &&
                    relatedData &&
                    relatedData.gradeTypes &&
                    relatedData.gradeTypes.find(gradeType => gradeType.id === gradeTypeId)
                        ?.courseGrades) ||
                []
            );
        }
        return [];
    };
    const disableValueChooser = showWithNoData || !specColumn || specColumn === "ALL_GRADES";
    return (
        <>
            <TextField
                value={specColumn}
                select
                SelectProps={{
                    displayEmpty: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                margin="normal"
                label={t("common.filterByGradeColumn")}
                onChange={e => setSpecColumn(e.target.value as string)}
            >
                <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                <MenuItem value={"ALL_GRADES"}>{t("common.filterAllSpecifications")}</MenuItem>
                {specifications &&
                    specifications.map(s => {
                        return (
                            <MenuItem key={s.field} value={s.field}>
                                {s.parentNodeName ? s.parentNodeName + " | " : ""}
                                {s.subject ? s.subject + " | " : ""}
                                {s.name}
                            </MenuItem>
                        );
                    })}
            </TextField>
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="32px">
                <TextField
                    value={minimumValue}
                    select
                    disabled={disableValueChooser}
                    SelectProps={{
                        displayEmpty: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    label={t("common.filterByGradeMin")}
                    onChange={e => setMinimumValue(e.target.value as string)}
                >
                    <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                    <MenuItem value={-1}>U</MenuItem>
                    {getGrades().map(grade => (
                        <MenuItem key={grade.id} value={grade.id}>
                            {grade.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    value={maximumValue}
                    select
                    disabled={disableValueChooser}
                    SelectProps={{
                        displayEmpty: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    label={t("common.filterByGradeMax")}
                    onChange={e => setMaximumValue(e.target.value as string)}
                >
                    <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                    <MenuItem value={-1}>U</MenuItem>
                    {getGrades().map(grade => (
                        <MenuItem key={grade.id} value={grade.id}>
                            {grade.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box>
                {specColumn && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showWithNoData}
                                onChange={() => {
                                    setShowWithNoData(!showWithNoData);
                                }}
                                name=""
                            />
                        }
                        label={t("common.showOnlyWithNoData")}
                    />
                )}
            </Box>
            <Box pt={2}>
                <Button
                    color="primary"
                    disabled={
                        specColumn === "" ||
                        (specColumn &&
                            minimumValue === "" &&
                            maximumValue === "" &&
                            !showWithNoData)
                    }
                    onClick={() => {
                        let finalMinValue = showWithNoData
                            ? "no-data"
                            : parseInt(minimumValue as string);
                        let finalMaxValue = showWithNoData
                            ? "no-data"
                            : parseInt(maximumValue as string);
                        if (finalMinValue !== "no-data" && finalMinValue > finalMaxValue)
                            [finalMinValue, finalMaxValue] = [finalMaxValue, finalMinValue];

                        handleFiltersChange({
                            [specColumn]: {
                                type: "grade",
                                attribute: specColumn,
                                attributeDisplay: `${
                                    specifications.find(o => o && o.field === specColumn)?.name
                                }`,
                                values: [
                                    {
                                        value: finalMinValue,
                                        valueTo: finalMaxValue,
                                        valueDisplay: "",
                                    },
                                ],
                            },
                        });
                        setSpecColumn("");
                        setMinimumValue("");
                        setMaximumValue("");
                    }}
                >
                    {t("common.filterAdd")}
                </Button>
            </Box>
        </>
    );
};

export default AnalysisGradeFilter;
