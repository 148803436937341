import { FormControlLabel, Radio, Switch, Typography, Box } from "@mui/material";
import { addMonths, addYears, format } from "date-fns";
import { Field } from "formik";
import { RadioGroup } from "formik-mui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "src/styles/theme";
import COLORS from "src/styles/colors";

const SubscriptionTypeForm = ({ showType = false, setFieldValue, values, fieldName }) => {
    const { t } = useTranslation();
    const availableMonthLengths = [3, 4, 5, 6, 7, 8, 9, 10, 11];

    const handleTypeChange = isAnnualy => {
        setFieldValue("isAnnualy", isAnnualy);
        setFieldValue(fieldName, isAnnualy ? "1" : `${availableMonthLengths[0]}`);
    };

    return (
        <Fragment>
            {showType && (
                <Fragment>
                    <Box display="flex" alignItems="flex-start" component="label" mb={2.5}>
                        <Box display="flex" flexDirection="column" alignItems="flex-end" mt={1}>
                            <Box
                                lineHeight={1}
                                fontWeight={theme.typography.fontWeightBold}
                                color={!values.isAnnualy ? COLORS.LIGHT_GREY_1 : COLORS.BLUE_3}
                            >
                                {t("subscription.upgrade.annually")}
                            </Box>
                            <Box color={COLORS.GREY_3} fontSize={11} fontStyle="italic" mt={0.375}>
                                {t("subscription.upgrade.subscriptionPeriodDiscount")}
                            </Box>
                        </Box>
                        <Box mx={4}>
                            <Field
                                label=""
                                checked={!values.isAnnualy}
                                onChange={() => handleTypeChange(!values.isAnnualy)}
                                name="isAnnualy"
                                component={Switch}
                            />
                        </Box>
                        <Box
                            mt={1}
                            lineHeight={1}
                            fontWeight={theme.typography.fontWeightBold}
                            color={values.isAnnualy ? COLORS.LIGHT_GREY_1 : COLORS.BLUE_3}
                        >
                            {t("subscription.upgrade.monthly")}
                        </Box>
                    </Box>
                    <Typography paragraph>
                        {t("subscription.upgrade.subscriptionPeriodHint")}
                    </Typography>
                </Fragment>
            )}
            {!showType || (showType && values.isAnnualy === true) ? (
                <Field name={fieldName} label="Radio Group" component={RadioGroup}>
                    <FormControlLabel
                        value={`1`}
                        control={<Radio />}
                        label={
                            <>
                                {t("subscription.upgrade.P1YSubscription")}{" "}
                                <Typography variant="overline" component="span">
                                    {t("subscription.summary.validUntil")}{" "}
                                    {format(addYears(new Date(), 1), "d/MM/Y")}
                                </Typography>
                            </>
                        }
                    />
                    <FormControlLabel
                        value={`2`}
                        control={<Radio />}
                        label={
                            <>
                                {t("subscription.upgrade.P2YSubscription")}{" "}
                                <Typography variant="overline" component="span">
                                    {t("subscription.summary.validUntil")}{" "}
                                    {format(addYears(new Date(), 2), "d/MM/Y")}
                                </Typography>
                            </>
                        }
                    />
                    <FormControlLabel
                        value={`3`}
                        control={<Radio />}
                        label={
                            <>
                                {t("subscription.upgrade.P3YSubscription")}{" "}
                                <Typography variant="overline" component="span">
                                    {t("subscription.summary.validUntil")}{" "}
                                    {format(addYears(new Date(), 3), "d/MM/Y")}
                                </Typography>
                            </>
                        }
                    />
                </Field>
            ) : (
                <Field name={fieldName} label="Radio Group" component={RadioGroup}>
                    {availableMonthLengths.map(len => (
                        <FormControlLabel
                            key={len}
                            value={`${len}`}
                            control={<Radio />}
                            label={
                                <>
                                    {len} {t("common.months")}{" "}
                                    <Typography variant="overline" component="span">
                                        {t("subscription.summary.validUntil")}{" "}
                                        {format(addMonths(new Date(), len), "d/MM/Y")}
                                    </Typography>
                                </>
                            }
                        />
                    ))}
                </Field>
            )}
        </Fragment>
    );
};

export default SubscriptionTypeForm;
