import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    styled,
} from "@mui/material";
import { FC, ReactElement, ReactNode } from "react";
import Icon from "src/components/Icon";
import { mdiWindowClose } from "@mdi/js";
import COLORS from "src/styles/colors";
import { LoadingButton } from "@mui/lab";

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
}));

interface OwnProps {
    open: boolean;
    title?: string;
    onClose: (confirmed: boolean) => void;
    children: ReactNode;
    yesLabel?: string;
    noLabel?: string;
    yesDisabled?: boolean;
    noDisabled?: boolean;
    loading?: boolean;
}

const PromptDialog: FC<OwnProps> = ({
    open,
    title,
    onClose,
    children,
    yesLabel = "Yes",
    noLabel = "No",
    yesDisabled,
    loading,
    noDisabled,
}): ReactElement => {
    const actions = [
        yesLabel && (
            <LoadingButton
                id="confirmButton"
                key="yes"
                disabled={yesDisabled}
                loading={loading}
                variant="contained"
                onClick={() => onClose(true)}
                color="primary"
            >
                {yesLabel}
            </LoadingButton>
        ),
        noLabel && (
            <Button
                id="cancelButton"
                variant="text"
                key="no"
                disabled={noDisabled}
                onClick={() => onClose(false)}
            >
                {noLabel}
            </Button>
        ),
    ];

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CloseButton
                id="closeButton"
                aria-label="close"
                onClick={() => onClose(false)}
                size="small"
            >
                <Icon size="32px" color={COLORS.LIGHT_GREY_1} path={mdiWindowClose} />
            </CloseButton>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
};

export default PromptDialog;
