import { FC } from "react";
import { ColDef, ColumnApi, GridApi } from "ag-grid-community";
import {
    getCellColour,
    ColourTypes,
    getCurrentGradeColour,
    getContrastText,
    DEFAULT_DISPLAY_FIELD_OPACITY,
    hexToRGB,
} from "src/modules/tracker/services/colorHandler";
import { ColumnConfigTypes } from "src/modules/tracker/services/headerGenerator";
import clsx from "clsx";
import {
    absenceValuesArray,
    absenceValuesLabels,
    getFieldValue,
} from "src/modules/tracker/services/valueHandler";
import { useHighlightGradeValues } from "src/modules/tracker/hooks/useHighlightGradeValues";
import PinnedTopRenderer from "./PinnedTopRenderer";
import { ColumnNode } from "src/modules/tracker/dto/TrackerHeader";

interface OwnProps {
    value: boolean | string | { value: boolean | string; whileUpdate: boolean };
    cellConfig: ColumnNode | null;
    colDef: ColDef;
    data: any;
    context: any;
    parentUnitColour: string | undefined;
    isDisplayField?: boolean;
    rowIndex: number;
    unitType?: string | null;
    row?: string;
    node?: any;
    api?: GridApi;
    columnApi?: ColumnApi;
    customField?: any;
}

export const DefaultValueRenderer: FC<OwnProps> = ({
    value: firstValue,
    cellConfig,
    colDef,
    data,
    context,
    parentUnitColour,
    isDisplayField,
    rowIndex,
    unitType,
    node,
    columnApi,
    customField,
}) => {
    const { field } = colDef;
    const classTrackerId = data?.["student_classTrackerId"];
    const splited = field?.split("$");
    const ppId = splited?.[1] || undefined;

    if (node.rowPinned) {
        return <PinnedTopRenderer value={field && data ? data[field] : ""} />;
    }

    const { handleHighlightGradeClick, handleRemoveHighlightGradeClick, highlihtValuesState } =
        useHighlightGradeValues();

    let value: any = "";
    let whileUpdate = false;

    if (typeof firstValue === "object" && firstValue !== null && firstValue?.value !== undefined) {
        value = firstValue.value;
        whileUpdate = true;
    } else {
        value = firstValue;
        whileUpdate = false;
    }

    const { relatedData, compareValues } = context;

    if (customField && field?.includes("-display")) {
        const tmpValue = data[field.replace("-display", "")];

        if (tmpValue && tmpValue["value"]) {
            value = customField.values[customField.values.length - tmpValue["value"]];
        }
        if (tmpValue && !tmpValue["value"]) {
            value = customField.values[customField.values.length - tmpValue];
        }
    }
    if (node.rowPinned) {
        return (
            <PinnedTopRenderer
                field={field}
                value={field && data ? data[field] : ""}
                context={context}
                customField={customField}
            />
        );
    }
    const resolveCellColour = () => {
        if (field && data?.metaColumnsConfig && data.metaColumnsConfig[field]) {
            const metaConfig = data.metaColumnsConfig[field];
            if (
                metaConfig &&
                metaConfig["mtgDistance"] !== undefined &&
                cellConfig?.colourConfig?.type === ColourTypes.MTG
            ) {
                return getCurrentGradeColour(metaConfig, cellConfig, relatedData, parentUnitColour);
            }
        }

        if (
            cellConfig?.columnConfig &&
            [
                ColumnConfigTypes.PROJECTED_GRADE,
                ColumnConfigTypes.CURRENT_GRADE,
                ColumnConfigTypes.OTHER_GRADE,
                ColumnConfigTypes.BTEC_PROJECTED_GRADE,
            ].includes(cellConfig.columnConfig.type as ColumnConfigTypes) &&
            cellConfig?.colourConfig?.type === ColourTypes.MTG
        ) {
            return {
                colour: parentUnitColour,
                opacity: DEFAULT_DISPLAY_FIELD_OPACITY,
            };
        }

        return getCellColour(value, cellConfig, parentUnitColour, relatedData, isDisplayField);
    };

    const colour = resolveCellColour();

    const rowWhileUpdate = whileUpdate;

    const fieldError = false;

    const fieldValue: any = getFieldValue(
        value?.value || value,
        relatedData,
        unitType,
        cellConfig?.columnConfig?.type,
        cellConfig?.columnType,
        cellConfig?.columnConfig?.typeConfig?.gradeType,
    );

    let ppAssessmentEnabled = true;

    if (
        relatedData?.enabledAssessments &&
        relatedData?.enabledAssessments?.[classTrackerId] &&
        ppId
    ) {
        ppAssessmentEnabled = !!relatedData.enabledAssessments[classTrackerId].includes(ppId);
    }

    if (compareValues) {
        const values = compareValues;
        const compareRow = values[data.row];
        let compareValue: number | null = null;

        if (field && field.indexOf("column") === 0) {
            if (value !== null || (value !== undefined && !isNaN(parseInt(`${value}`)))) {
                let compareFrom = value as any;

                const compareTo = (
                    !compareRow || !compareRow[field] || compareRow[field] < 0
                        ? 0
                        : compareRow[field]
                ) as any;

                if (
                    cellConfig?.columnConfig &&
                    [
                        ColumnConfigTypes.PROJECTED_GRADE,
                        ColumnConfigTypes.CURRENT_GRADE,
                        ColumnConfigTypes.OTHER_GRADE,
                        ColumnConfigTypes.BTEC_PROJECTED_GRADE,
                    ].includes(cellConfig?.columnConfig?.type as ColumnConfigTypes) &&
                    (value === `-1` || value < 0)
                ) {
                    compareFrom = 0;
                }

                compareValue = (compareFrom || 0) - (compareTo || 0);
            }

            if (!value && compareRow && compareRow[field] && !isNaN(parseInt(compareRow[field]))) {
                const compareTo = parseInt(compareRow[field]);
                if (compareTo > 0) {
                    compareValue = 0 - (compareTo || 0);
                }
            }
        }

        const isFloat = !compareValue
            ? false
            : Number(compareValue) === compareValue && compareValue % 1 !== 0;

        return (
            <div className="ag-value-cell-compare-row">
                {compareValue !== null && compareValue !== undefined && (
                    <div
                        className={clsx(
                            "ag-value-cell-compare",
                            `ag-value-cell-compare--${
                                compareValue < 0 ? "red" : compareValue > 0 ? "blue" : "green"
                            }`,
                        )}
                    >
                        {!isNaN(compareValue) && !isFloat
                            ? compareValue
                            : !isNaN(compareValue) && isFloat
                              ? compareValue.toFixed(2)
                              : ""}
                    </div>
                )}

                <div
                    className={clsx(
                        "ag-value-cell-compared-row",
                        "ag-value-cell-label",
                        `ag-value-cell-label--${
                            compareValue !== null && compareValue !== undefined && compareValue < 0
                                ? "red"
                                : compareValue !== null &&
                                    compareValue !== undefined &&
                                    compareValue > 0
                                  ? "blue"
                                  : "green"
                        }`,
                    )}
                >
                    {" "}
                    {typeof fieldValue === "object" && fieldValue !== null
                        ? fieldValue?.value
                        : fieldValue}{" "}
                </div>
            </div>
        );
    }

    let backgroundColor =
        value === null || value === ""
            ? "transparent"
            : colour?.colour && colour?.opacity !== null && colour?.opacity < 100
              ? hexToRGB(parentUnitColour, colour.opacity)
              : colour.colour;

    let canHover = false;
    let relatedHoverFields = [];
    let isHoverable = false;
    const columnsToHover: string[] = [];

    if (relatedData?.currentGradesHashes) {
        canHover = !!Object.keys(relatedData?.currentGradesHashes).find(hash => hash === field);
        isHoverable = !!(field && relatedData?.currentGradesHashes[field] !== undefined);

        if (canHover && field) {
            relatedHoverFields = relatedData?.currentGradesHashes[field];
        }

        if (relatedHoverFields && relatedHoverFields.length > 0) {
            relatedHoverFields.forEach(mod => {
                const group = columnApi?.getColumnGroup("g-" + mod);
                if (group) {
                    const children = group.getChildren();

                    if (children) {
                        children.forEach((child: any) => {
                            if (child.children && child.children.length > 0) {
                                child.children.forEach(cc => {
                                    if (cc.children && cc.children.length > 0) {
                                        cc.children.forEach(c => {
                                            columnsToHover.push(c.colId);
                                        });
                                    }
                                    columnsToHover.push(cc.colId);
                                });
                            }
                            columnsToHover.push(child.colId);
                        });
                    }
                }
            });
        }
    }
    let isClicked = false;
    let isClickedToggle = false;
    let isToggleHovered = false;
    let clickedRow: any = null;

    if (highlihtValuesState) {
        clickedRow = highlihtValuesState.find(
            thc => thc.clickedHash === field && thc.rowIndex === rowIndex,
        );
        isClicked = !!clickedRow;
        isClickedToggle = !!clickedRow?.columns.find(c => c === field);
        isToggleHovered = !!highlihtValuesState.find(thc => {
            if (thc.columns && thc.columns.length > 0) {
                return thc.columns.includes(field || "") && thc.rowIndex === rowIndex;
            }
            return false;
        });
    }

    if (cellConfig?.columnConfig?.type === "topic-field") {
        let opacity = 0;
        const topicValue = parseInt(`${value}`);
        if (!isNaN(topicValue)) {
            if (topicValue < 20) opacity = 0.2;
            if (topicValue >= 20 && topicValue < 40) opacity = 0.4;
            if (topicValue >= 40 && topicValue < 60) opacity = 0.6;
            if (topicValue >= 60 && topicValue < 80) opacity = 0.8;
            if (topicValue >= 80) opacity = 1;

            backgroundColor = hexToRGB(parentUnitColour, opacity);
        }
    }

    let displayValue =
        typeof fieldValue === "object" && fieldValue !== null ? fieldValue?.value : fieldValue;

    if (
        cellConfig?.columnConfig?.type !== ColumnConfigTypes.DISTANCE &&
        cellConfig?.columnConfig?.type !== ColumnConfigTypes.PMV &&
        absenceValuesArray.includes(`${displayValue}`)
    ) {
        displayValue = absenceValuesLabels[`${displayValue}`];
    }

    return (
        <div
            title={displayValue}
            className={`ag-value-cell-label ${
                isHoverable ? " hover-current-grade-clickable" : ""
            } ${
                isClicked || isClickedToggle || !!isToggleHovered
                    ? `hover-current-grade${
                          clickedRow?.clickedHash === field ? " hover-current-grade-hash" : ""
                      }`
                    : undefined
            }`}
            onClick={() => {
                if (!isClicked && isHoverable && handleHighlightGradeClick) {
                    handleHighlightGradeClick(columnsToHover, rowIndex, field);
                } else {
                    if (isHoverable && handleRemoveHighlightGradeClick) {
                        handleRemoveHighlightGradeClick(clickedRow.rowIndex);
                    }
                }
            }}
            style={{
                cursor: !ppAssessmentEnabled ? "not-allowed" : undefined,
                backgroundColor,
                color:
                    colour?.colour && (colour?.opacity === 100 || colour?.opacity === null)
                        ? getContrastText(colour?.colour)
                        : undefined,
            }}
        >
            <div className="ag-value-cell-label-truncate">{displayValue}</div>
            {(rowWhileUpdate || fieldError) && (
                <span
                    className={`ag-value-cell-status${
                        rowWhileUpdate
                            ? " ag-value-cell-status--update"
                            : fieldError
                              ? " ag-value-cell-status--error"
                              : ""
                    }`}
                />
            )}
        </div>
    );
};
