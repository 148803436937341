import Dialog from "src/components/Dialog";
import COLORS from "src/styles/colors";
import clsx from "clsx";
import Icon from "src/components/Icon";
import { Box, Button, Typography, Tabs, Tab, Chip } from "@mui/material";
import {
    mdiGrid,
    mdiSchool,
    mdiSeat,
    mdiCloudSyncOutline,
    mdiCloudOffOutline,
    mdiFormatRotate90,
} from "@mdi/js";
import { FC, Fragment, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { ChooseButton } from "src/modules/common/components/ChooseButton";
import { ROUTE_CLASS_CLASS_LIST } from "src/routes";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

// xls = multiple, single = existing_students
export enum AddClassManualActions {
    XLS = "manual-xls",
    EXISTING_STUDENTS = "manual-existing-students",
    UPLOAD = "manual-upload",
}

// mis
export enum AddClassMisActions {
    SYNCHRONIZE = "synchronise",
    AUTOSUGGEST = "autosuggest",
    UNSYNCHRONISED = "unsynchronised",
}

// upload = upload (others), demo = demo (others),
export enum AddClassOtherActions {
    DEMO = "other-demo",
}

export type AddClassTabs = "manual" | "mis" | "other";

export const AddClassTypesIcons = {
    [AddClassManualActions.XLS]: mdiGrid,
    [AddClassManualActions.EXISTING_STUDENTS]: mdiSchool,
    [AddClassManualActions.UPLOAD]: mdiSeat,
    [AddClassMisActions.SYNCHRONIZE]: mdiCloudSyncOutline,
    [AddClassMisActions.AUTOSUGGEST]: mdiFormatRotate90,
    [AddClassMisActions.UNSYNCHRONISED]: mdiCloudOffOutline,
};

interface AddClassActionTileProps {
    action: AddClassManualActions | AddClassMisActions | AddClassOtherActions;
    isActive: boolean;
    handleClick: (key: AddClassManualActions | AddClassMisActions | AddClassOtherActions) => void;
    misName?: string;
    children?: ReactNode;
}

const AddClassActionTile: FC<AddClassActionTileProps> = ({
    action,
    isActive,
    handleClick,
    children,
    misName = "",
}) => {
    const { t } = useTranslation();

    return (
        <Box key={action} bgcolor={COLORS.VERY_LIGHT_GREY_1}>
            <ChooseButton
                onClick={() => {
                    handleClick(action);
                }}
                className={isActive ? "active" : undefined}
            >
                <div className="row">
                    {action === AddClassOtherActions.DEMO ? (
                        <Box mr={2}>
                            <Chip size="small" label={"DEMO"} />
                        </Box>
                    ) : (
                        <div className={clsx("icon", isActive && "active")}>
                            <Icon path={AddClassTypesIcons[action]} size="inherit" />
                        </div>
                    )}
                    <div className="title">{t(`class.addModal.${action}Title`)}</div>
                </div>
                <div className={clsx("subtitle", isActive && "active")}>
                    {t(`class.addModal.${action}Hint`).replaceAll("MIS_Name", misName)}
                </div>
                {children}
            </ChooseButton>
        </Box>
    );
};

const AddClassModal = ({
    open,
    handleClose,
}: {
    open: boolean;
    handleClose: (confirm: boolean) => void;
}) => {
    const { data: accountInfo } = useAccountInfo();
    const params = useParams();
    const [selectedAddClassTab, setSelectedAddClassTab] = useState<AddClassTabs>(
        accountInfo?.hasGroupCallSynchronization ? "mis" : "manual",
    );
    const [selectedAddClassAction, setSelectedAddClassAction] = useState<
        AddClassManualActions | AddClassMisActions | AddClassOtherActions
    >(AddClassManualActions.XLS);
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();

    return (
        <Dialog
            className="addClassModal"
            open={open}
            onClose={() => handleClose(false)}
            title={t("class.addModal.header")}
            actions={
                selectedAddClassAction ? (
                    <Button
                        onClick={() => {
                            navigate(
                                (ROUTE_CLASS_CLASS_LIST + "/" + selectedAddClassAction).replace(
                                    ":cohort",
                                    params.cohort,
                                ),
                            );
                            handleClose(true);
                        }}
                        color="primary"
                    >
                        {t("common.continue")}
                    </Button>
                ) : null
            }
            contentOverflow="visible"
        >
            <Fragment>
                <Typography gutterBottom>{t("class.addModal.chooseHow")}</Typography>
                {accountInfo && (
                    <Tabs
                        value={selectedAddClassTab}
                        onChange={(event: React.ChangeEvent<{}>, value: AddClassTabs): void => {
                            setSelectedAddClassTab(value);
                        }}
                    >
                        <Tab label={t("class.addModal.manualTab")} value={"manual"} />
                        {accountInfo.hasGroupCallSynchronization && (
                            <Tab
                                label={(accountInfo?.misName || "") + t("class.addModal.misTab")}
                                value={"mis"}
                            />
                        )}
                        <Tab label={t("class.addModal.otherTab")} value={"other"} />
                    </Tabs>
                )}

                {accountInfo && (
                    <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr 1fr"
                        gap="1px 1px"
                        marginX={-6}
                        marginTop={5}
                        bgcolor={COLORS.VERY_LIGHT_GREY_7}
                    >
                        {selectedAddClassTab === "manual" ? (
                            <>
                                <AddClassActionTile
                                    action={AddClassManualActions.XLS}
                                    isActive={selectedAddClassAction === AddClassManualActions.XLS}
                                    handleClick={key => setSelectedAddClassAction(key)}
                                />
                                {accountInfo?.hasStudents && (
                                    <AddClassActionTile
                                        action={AddClassManualActions.EXISTING_STUDENTS}
                                        isActive={
                                            selectedAddClassAction ===
                                            AddClassManualActions.EXISTING_STUDENTS
                                        }
                                        handleClick={key => setSelectedAddClassAction(key)}
                                    />
                                )}
                                <AddClassActionTile
                                    action={AddClassManualActions.UPLOAD}
                                    isActive={
                                        selectedAddClassAction === AddClassManualActions.UPLOAD
                                    }
                                    handleClick={key => setSelectedAddClassAction(key)}
                                />
                                {!accountInfo?.hasStudents && (
                                    <Box bgcolor={COLORS.VERY_LIGHT_GREY_1} />
                                )}
                            </>
                        ) : selectedAddClassTab === "mis" ? (
                            <>
                                <AddClassActionTile
                                    action={AddClassMisActions.SYNCHRONIZE}
                                    isActive={
                                        selectedAddClassAction === AddClassMisActions.SYNCHRONIZE
                                    }
                                    handleClick={key => setSelectedAddClassAction(key)}
                                    misName={accountInfo?.misName || ""}
                                >
                                    <div
                                        className={clsx(
                                            "subtitle",
                                            selectedAddClassAction ===
                                                AddClassMisActions.SYNCHRONIZE && "active",
                                        )}
                                        style={{ fontWeight: "bold" }}
                                    >
                                        {t(`class.addModal.synchroniseRecommended`)}
                                    </div>
                                </AddClassActionTile>
                                <AddClassActionTile
                                    action={AddClassMisActions.UNSYNCHRONISED}
                                    isActive={
                                        selectedAddClassAction === AddClassMisActions.UNSYNCHRONISED
                                    }
                                    handleClick={key => setSelectedAddClassAction(key)}
                                    misName={accountInfo?.misName || ""}
                                />
                                <AddClassActionTile
                                    action={AddClassMisActions.AUTOSUGGEST}
                                    isActive={
                                        selectedAddClassAction === AddClassMisActions.AUTOSUGGEST
                                    }
                                    handleClick={key => setSelectedAddClassAction(key)}
                                />
                            </>
                        ) : (
                            <>
                                <AddClassActionTile
                                    action={AddClassOtherActions.DEMO}
                                    isActive={selectedAddClassAction === AddClassOtherActions.DEMO}
                                    handleClick={key => setSelectedAddClassAction(key)}
                                />
                                <Box bgcolor={COLORS.VERY_LIGHT_GREY_1} />
                                <Box bgcolor={COLORS.VERY_LIGHT_GREY_1} />
                            </>
                        )}
                    </Box>
                )}
            </Fragment>
        </Dialog>
    );
};

export default AddClassModal;
