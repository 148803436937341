import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import {
    AnalysisGradeSummaryValuesObject,
    StudentFilterRequest,
    ViewGrades,
} from "./useAnalysisGradeSummaryValues";
import { getSchoolAccountId } from "src/services/url";

export const ANALYSIS_GRADE_SUMMARY_SNAPSHOT_COMPARE_QUERY =
    "analysisGradeSummarySnapshotCompareQuery";

export interface GradeSummarySnapshotCompareResponse {
    values1: AnalysisGradeSummaryValuesObject;
    values2: AnalysisGradeSummaryValuesObject;
}

export const useAnalysisGradeSummarySnapshotCompare = (
    cohort: number,
    yearGroup: number,
    viewGrade: ViewGrades,
    dataSource1: string,
    dataSource2: string,
    studentFilters: StudentFilterRequest,
) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [
            ANALYSIS_GRADE_SUMMARY_SNAPSHOT_COMPARE_QUERY,
            schoolAccountId,
            cohort,
            yearGroup,
            viewGrade,
            dataSource1,
            dataSource2,
            studentFilters,
        ],
        queryFn: async (): Promise<GradeSummarySnapshotCompareResponse | null> => {
            if (cohort && yearGroup && viewGrade && dataSource1 && dataSource2) {
                const { data } = await axiosInstance.get(
                    `school/${schoolAccountId}/analysis/grade-summary/${cohort}/${yearGroup}/snapshot/compare/${viewGrade}/${dataSource1}/${dataSource2}`,
                    { params: studentFilters },
                );
                return data || null;
            }
            return null;
        },
    });
};
