import {
    ROUTE_MY_SCHOOL_STUDENTS,
    ROUTE_MY_SCHOOL_STUDENT_ADD,
    ROUTE_MY_SCHOOL_STUDENT_EDIT,
} from "src/routes";
import StudentUpsertContainer from "../containers/Upsert";
import StudentListContainer from "../containers/List";

export const StudentRoutes = [
    {
        path: ROUTE_MY_SCHOOL_STUDENT_EDIT,
        element: <StudentUpsertContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_STUDENT_ADD,
        element: <StudentUpsertContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_STUDENTS,
        element: <StudentListContainer />,
    },
];
