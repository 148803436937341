import HeadingCounter from "src/components/HeadingCounter";
import ErrorMessage from "src/forms/ErrorMessage";
import CheckboxField from "src/forms/CheckboxField";
import FieldChangeHandler from "src/forms/FieldChangeHandler";
import PaperInner from "src/components/PaperInner";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import StaticSpecificationSelector from "../../../class/components/Common/StaticSpecificationSelector";
import PromptDialog from "src/forms/PromptDialog";
import Icon from "src/components/Icon";
import Datepicker, { getDateFormated } from "src/components/Datepicker";
import { useEffect, useState, Fragment, useCallback, FC } from "react";
import { useTranslation } from "react-i18next";
import { Field, FieldArray, Form, useFormikContext } from "formik";
import { TextField, RadioGroup } from "formik-mui";
import { useSnackbar } from "notistack";
import {
    MenuItem,
    Grid,
    Typography,
    Box,
    FormControlLabel,
    Radio,
    Button,
    TextField as MuiTextField,
} from "@mui/material";
import { format } from "date-fns";
import { OrmTopic } from "src/orm/models/Topic";
import { OrmGrade } from "src/orm/models/Grade";
import { makeStyles } from "@mui/styles";
import { ListObject } from "src/forms/types";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { CohortModel } from "src/modules/common/models/CohortModel";
import { TFunction } from "i18next";
import { CustomisedAssessmentRequest } from "../../hooks/Customised/useEditMutation";
import { useRoles } from "src/modules/common/hooks/useRoles";
import { useCustomisedAssessmentGradeBoundaries } from "../../hooks/Customised/useGradeBoundary";
import { useClassListByCohort } from "src/modules/class/hooks/useClassList";
import {
    mdiArrowRight,
    mdiCheckboxMultipleBlankOutline,
    mdiChevronDown,
    mdiChevronUp,
    mdiDelete,
    mdiPlus,
} from "@mdi/js";
import { StyledConvertsLabel, StyledGrid, StyledTotalMaxMarks } from "../ConvertsLabel";
import { AssessmentTopicSelector } from "../AssessmentTopicSelector";
import * as yup from "yup";
import ActionsTableButton from "src/components/ActionTableButton";

const useStyles = makeStyles(() => ({
    topicMultiselect: {
        width: "250px",
        paddingTop: "16px",
        lineHeight: "15px",
    },
}));

export const questionActions = (arrayHelper, i, t) => {
    return [
        {
            text: t("class.assessment.add.removeQLA"),
            icon: <Icon path={mdiDelete} />,
            onAction: () => arrayHelper.remove(i),
        },
        {
            text: t("class.assessment.add.up"),
            icon: <Icon path={mdiChevronUp} />,
            onAction: () => arrayHelper.move(i, i - 1),
        },
        {
            text: t("class.assessment.add.down"),
            icon: <Icon path={mdiChevronDown} />,
            onAction: () => arrayHelper.move(i, i + 1),
        },
        {
            text: t("class.assessment.add.duplicate"),
            icon: <Icon path={mdiCheckboxMultipleBlankOutline} />,
            onAction: rowData =>
                arrayHelper.push({
                    ...rowData,
                }),
        },
    ];
};
export interface EditEndOfTopicTestFormShape {
    id: number | null;
    name: string;
    countsTowardGrade: boolean;
    hiddenColumns: boolean;
    gradeBoundaryFactor: string;
    examDate: string | null;
    yearGroup: number;
    cohort: number | null;
    subjectArea: ListObject<number | null> | null;
    qualification: ListObject<number | null> | null;
    specification: ListObject<number | null> | null;
    dataEntryType: "single" | "qla";
    classTrackerUnitConfigs: {
        classTracker: ListObject<number> & { yearGroup?: number };
        visible: boolean;
        index?: number;
    }[];
    tierUnits: {
        tier: TiersTypes;
        unit: ListObject<number | null> | null;
    }[];
    personalisedAssesmentQuestions: {
        id?: number;
        question: string;
        topics?: number[] | null;
        maxValue: string;
    }[];
    yearGroupWithoutVisibility: number[];
    gradeBoundaryValues?: { id: number; name: string; requireMark: number | null }[];
    gradeBoundaryType?: "tracker" | "factor" | "custom";
    canAddCustomGradeValues?: boolean;
    showQlaInReports: boolean;
}

export const editEndOfTopicTestSchema = (t: TFunction) => {
    return yup.object().shape({
        name: yup.string().required(t("common.form.validation.name.required")),
        examDate: yup
            .mixed()
            .typeError(t("common.form.validation.examDate.type"))
            .notRequired()
            .nullable(),
        countsTowardGrade: yup.boolean().notRequired(),
        yearGroup: yup.number().required(t("common.form.validation.yearGroup.required")).min(0),
        cohort: yup.number().required(t("common.form.validation.cohort.required")).min(1),
        classTrackerUnitConfigs: yup.array().min(1),
        tierUnits: yup.array().min(1),
        gradeBoundaryValues: yup.mixed().when("gradeBoundaryType", ([gradeBoundaryType]) => {
            return gradeBoundaryType === "custom"
                ? yup.array(yup.object({ requireMark: yup.mixed().required(t("common.required")) }))
                : yup.mixed().notRequired().nullable();
        }),
        personalisedAssesmentQuestions: yup
            .array()
            .of(
                yup.object().shape({
                    question: yup
                        .string()
                        .required(
                            t("common.form.validation.personalisedAssesmentQuestions.required"),
                        ),
                    maxValue: yup
                        .number()
                        .min(1)
                        .max(9999)
                        .required(t("common.form.validation.maxValue.required"))
                        .typeError(t("common.form.validation.maxValue.type"))
                        .label(t("common.form.label.maxValue")),
                }),
            )
            .min(1),
        gradeBoundaryFactor: yup.string().when("gradeBoundaryType", ([gradeBoundaryType]) => {
            return gradeBoundaryType === "factor"
                ? yup.string().required()
                : yup.string().nullable().notRequired();
        }),
        specification: yup
            .object()
            .shape({
                id: yup.number().required(t("common.form.validation.specification.required")),
            })
            .nullable(),
    });
};

export const convertFormDataToApiRequest = (
    values: EditEndOfTopicTestFormShape,
): CustomisedAssessmentRequest => {
    return {
        ...values,
        examDate: values.examDate ? format(getDateFormated(values.examDate), "Y-MM-dd") : "",
        classTrackerUnitConfigs: values.classTrackerUnitConfigs.map(ctuc => ({
            classTracker: { id: ctuc.classTracker.id },
            visible: ctuc.visible,
        })),
        hiddenColumns: !values.countsTowardGrade ? values.hiddenColumns : false,
        personalisedAssesmentQuestions: values.personalisedAssesmentQuestions.map(paq => ({
            id: paq.id || undefined,
            question: paq.question || values.name,
            topics: paq.topics && paq.topics.length > 0 ? paq.topics.map(t => ({ id: t })) : [],
            maxValue: paq.maxValue ? parseInt(paq.maxValue) : 0,
        })),
        gradeBoundaryFactor:
            values.gradeBoundaryType && values.gradeBoundaryType === "custom"
                ? 0
                : parseInt(values.gradeBoundaryFactor),
        gradeBoundaryValues:
            values.gradeBoundaryType === "custom" && values.gradeBoundaryValues
                ? values.gradeBoundaryValues.map(gb => ({
                      ...gb,
                      requireMark: parseInt(`${gb.requireMark}`) || 0,
                  }))
                : [],
    };
};

interface OwnProps {
    cohort?: CohortModel;
    isFormLocked?: boolean;
    grades: OrmGrade[];
    topics: OrmTopic[];
}

const EditEndOfTopicTestForm: FC<OwnProps> = ({ cohort, grades, topics, isFormLocked }) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { values, errors, submitCount, isSubmitting, submitForm, setFieldValue, setSubmitting } =
        useFormikContext<EditEndOfTopicTestFormShape>();
    const { canManageAssessments } = useRoles();
    const [noClassDialogOpened, setNoClassDialogOpened] = useState<boolean>(false);
    const [customGradeBoundary] = useState<boolean>(false);
    const [classTrackerGroupId, setClassTrackerGroupId] = useState<number | null>(null);

    const { data: classList, refetch: getClasses } = useClassListByCohort(values.cohort, {
        qualificationId: values.qualification.id,
        yearGroup: values.yearGroup,
        page: 1,
        perPage: 100000,
    });

    const { data: gradeBoundaries, refetch: refetchGradeBoundaries } =
        useCustomisedAssessmentGradeBoundaries({
            classTrackerGroupId,
            tierUnits: values.tierUnits.filter(tu => tu.unit).map(tu => ({ ...tu })),
        });

    const handleNoClassDialogClose = (confirmed: boolean): void => {
        if (confirmed === true) {
            submitForm();
        }
        setNoClassDialogOpened(false);
    };

    const handleDataEntryTypeChange = useCallback(() => {
        const currentQuestion = values.personalisedAssesmentQuestions[0];
        setFieldValue("personalisedAssesmentQuestions", [
            {
                id: currentQuestion?.id || undefined,
                question: values.name || "",
                topics: currentQuestion?.topics || [],
                maxValue: currentQuestion?.maxValue || null,
            },
        ]);
    }, [setFieldValue, values.name]);

    const handleNameQuestionbChange = useCallback(
        newValue => {
            if (values.dataEntryType === "single") {
                setFieldValue("personalisedAssesmentQuestions", [
                    { ...values.personalisedAssesmentQuestions[0], question: newValue },
                ]);
            }
        },
        [setFieldValue, values.dataEntryType, values.personalisedAssesmentQuestions],
    );

    useEffect(() => {
        if (!classList) {
            getClasses();
        }
    }, [values.qualification.id, values.yearGroup]);

    useEffect(() => {
        const classTrackerGroup = classList?.data?.find(cl => cl.yearGroup === values.yearGroup);
        if (classTrackerGroup && classTrackerGroupId !== classTrackerGroup.id) {
            setClassTrackerGroupId(classTrackerGroup.id || null);
        }
    }, [classList]);

    useEffect(() => {
        if (
            grades &&
            grades.length > 0 &&
            (customGradeBoundary || values.canAddCustomGradeValues) &&
            (!values.gradeBoundaryValues || values.gradeBoundaryValues.length !== grades.length)
        ) {
            setFieldValue(
                "gradeBoundaryValues",
                grades.map(g => ({ id: g.id, name: g.name, requireMark: null })),
            );
        }
    }, [grades, customGradeBoundary, values.gradeBoundaryType]);

    useEffect(() => {
        if (classTrackerGroupId) refetchGradeBoundaries();
    }, [classTrackerGroupId]);

    return (
        <Form>
            <PaperInner>
                <HeadingCounter number="1">
                    {t("class.endOfTopicTest.add.targetUnit")}
                </HeadingCounter>
                <StaticSpecificationSelector
                    subjectArea={values.subjectArea as ListObject}
                    qualification={values.qualification as ListObject}
                    specification={values.specification as ListObject}
                />
                {values.tierUnits && values.tierUnits.length > 0 && (
                    <Grid container spacing={4}>
                        {values.tierUnits.map((tu, i) => (
                            <Grid key={tu.tier} item sm={4}>
                                <Box display="flex" alignItems="center">
                                    <Field
                                        sx={{ mt: 2 }}
                                        name={`tierUnits.${i}.unit.id`}
                                        select
                                        disabled={true}
                                        label={
                                            tu.tier !== "-"
                                                ? `${t(`common.tierName${tu.tier}`)} ${t(
                                                      "class.endOfTopicTest.add.unit",
                                                  )}`
                                                : t("class.endOfTopicTest.add.selectUnit")
                                        }
                                        component={TextField}
                                        fullWidth
                                    >
                                        <MenuItem value={tu.unit.id}>{tu.unit.name}</MenuItem>
                                    </Field>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </PaperInner>
            <Fragment>
                <PaperInner border="top">
                    <HeadingCounter number="2">
                        {t("class.endOfTopicTest.add.configureEndOfTopicTest")}
                    </HeadingCounter>
                    <Grid container spacing={4}>
                        <Grid item sm={4}>
                            <Field
                                name="name"
                                label={t("common.form.label.name")}
                                component={TextField}
                                disabled={isFormLocked}
                                margin="normal"
                            />
                            <Field
                                name={`countsTowardGrade`}
                                disabled={isFormLocked}
                                component={CheckboxField}
                                label={t("class.pastPaper.add.countsTowardGrade")}
                            />
                            {!values.countsTowardGrade && (
                                <Field
                                    name={`hiddenColumns`}
                                    component={CheckboxField}
                                    label={t("class.assessment.add.hiddenColumns")}
                                    disabled={isFormLocked}
                                />
                            )}
                            <Field
                                name={`showQlaInReports`}
                                component={CheckboxField}
                                label={t("class.assessment.add.showQlaInReports")}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <Datepicker
                                name="examDate"
                                disabled={isFormLocked}
                                label={t("common.form.label.examDate")}
                            />
                            {errors?.examDate && <ErrorMessage name="examDate" />}
                        </Grid>
                    </Grid>
                    <Box mt={3.5}>
                        <Typography component="h3" variant="h5" gutterBottom>
                            {t("class.endOfTopicTest.add.dataEntry")}
                        </Typography>
                        <Field name="dataEntryType" label="" component={RadioGroup} row>
                            <FormControlLabel
                                value={"single"}
                                control={<Radio />}
                                label={t("class.endOfTopicTest.add.singleMark")}
                            />
                            <FormControlLabel
                                value={"qla"}
                                control={<Radio />}
                                label={t("class.endOfTopicTest.add.qla")}
                            />
                        </Field>
                        <FieldChangeHandler
                            onChange={handleDataEntryTypeChange}
                            value={values.dataEntryType}
                        />
                        <FieldChangeHandler
                            onChange={handleNameQuestionbChange}
                            value={values.name}
                        />
                        <Box mt={2}>
                            {values.dataEntryType === "single" && (
                                <Grid container spacing={4}>
                                    <Grid item sm={4}>
                                        <Field
                                            name="personalisedAssesmentQuestions[0].maxValue"
                                            label={t("class.endOfTopicTest.add.maxValue")}
                                            component={TextField}
                                            disabled={isFormLocked}
                                        />
                                        <ErrorMessage name="maxValue" />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <AssessmentTopicSelector
                                            topics={topics}
                                            i={0}
                                            setFieldValue={setFieldValue}
                                            personalisedAssesmentQuestions={
                                                values?.personalisedAssesmentQuestions
                                            }
                                            disabled={isFormLocked}
                                            className={styles.topicMultiselect}
                                            xs={3}
                                            style={{
                                                width: 250,
                                                paddingTop: 15,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {values.dataEntryType === "qla" && (
                                <FieldArray
                                    name="personalisedAssesmentQuestions"
                                    render={arrayHelpers => (
                                        <Fragment>
                                            {values.personalisedAssesmentQuestions.map((paq, i) => (
                                                <Grid key={i} container spacing={4} sx={{ mb: 3 }}>
                                                    <Grid item xs={4}>
                                                        <Field
                                                            name={`personalisedAssesmentQuestions[${i}].question`}
                                                            label={t(
                                                                "class.endOfTopicTest.add.question",
                                                            )}
                                                            component={TextField}
                                                            disabled={isFormLocked}
                                                        />
                                                    </Grid>
                                                    <AssessmentTopicSelector
                                                        topics={topics}
                                                        i={i}
                                                        setFieldValue={setFieldValue}
                                                        personalisedAssesmentQuestions={
                                                            values?.personalisedAssesmentQuestions
                                                        }
                                                        disabled={isFormLocked}
                                                        // className={styles.topicMultiselect}
                                                    />
                                                    <Grid item xs={4}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                gap: 3,
                                                            }}
                                                        >
                                                            <Field
                                                                name={`personalisedAssesmentQuestions[${i}].maxValue`}
                                                                type="number"
                                                                label={t(
                                                                    "class.endOfTopicTest.add.maxValue",
                                                                )}
                                                                disabled={isFormLocked}
                                                                component={TextField}
                                                                fullWidth
                                                            />
                                                            <ActionsTableButton
                                                                rowData={paq}
                                                                actions={questionActions(
                                                                    arrayHelpers,
                                                                    i,
                                                                    t,
                                                                )}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    mt: 4,
                                                }}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        arrayHelpers.push({
                                                            question: "",
                                                            maxValue: 1,
                                                            topics: [],
                                                        })
                                                    }
                                                    variant="text"
                                                    startIcon={<Icon path={mdiPlus} />}
                                                    disableRipple
                                                >
                                                    {t("class.endOfTopicTest.add.questionAdd")}
                                                </Button>
                                                <StyledTotalMaxMarks>
                                                    <span>
                                                        {t("class.assessment.add.totalMaxMarks")}
                                                    </span>
                                                    <div>
                                                        {values.personalisedAssesmentQuestions.reduce(
                                                            (curr, prev) => {
                                                                const maxValue = isNaN(
                                                                    parseInt(`${prev.maxValue}`),
                                                                )
                                                                    ? 0
                                                                    : parseInt(`${prev.maxValue}`);

                                                                return curr + maxValue;
                                                            },
                                                            0,
                                                        )}
                                                    </div>
                                                </StyledTotalMaxMarks>
                                            </Box>
                                        </Fragment>
                                    )}
                                />
                            )}
                        </Box>
                    </Box>
                </PaperInner>
                <PaperInner border="top">
                    <HeadingCounter number="3">
                        {t("class.endOfTopicTest.add.gradeBoundaries")}
                    </HeadingCounter>
                    <Box mt={2}>
                        <Grid container spacing={4}>
                            <Grid item sm={4}>
                                <Field
                                    name="gradeBoundaryType"
                                    label={""}
                                    component={TextField}
                                    select
                                    disabled={isFormLocked}
                                >
                                    <MenuItem value={"tracker"}>
                                        {t("class.endOfTopicTest.add.tracker")}
                                    </MenuItem>
                                    <MenuItem value={"factor"}>
                                        {t("class.endOfTopicTest.add.gradeShift")}
                                    </MenuItem>
                                    {values.canAddCustomGradeValues && (
                                        <MenuItem value={"custom"}>
                                            {t("class.endOfTopicTest.add.customGrade")}
                                        </MenuItem>
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                        {values.gradeBoundaryType === "factor" && (
                            <Box mt={2}>
                                {!(customGradeBoundary || values.canAddCustomGradeValues) && (
                                    <Typography component="h3" variant="h5" gutterBottom>
                                        {t("class.endOfTopicTest.add.gradeShift")}
                                    </Typography>
                                )}
                                <Typography gutterBottom>
                                    {t("class.endOfTopicTest.add.gradeShiftHint")}
                                </Typography>
                                <Field
                                    name="gradeBoundaryFactor"
                                    label=""
                                    component={RadioGroup}
                                    row
                                    disabled={isFormLocked}
                                >
                                    <Grid container spacing={4}>
                                        <Grid item sm={4}>
                                            <Box>
                                                <FormControlLabel
                                                    value={`-5`}
                                                    control={<Radio />}
                                                    label={t(
                                                        "class.endOfTopicTest.table.gradeShiftMin5",
                                                    )}
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    value={`-10`}
                                                    control={<Radio />}
                                                    label={t(
                                                        "class.endOfTopicTest.table.gradeShiftMin10",
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box>
                                                <FormControlLabel
                                                    value={`0`}
                                                    control={<Radio />}
                                                    label={t(
                                                        "class.endOfTopicTest.table.gradeShift0",
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box>
                                                <FormControlLabel
                                                    value={`5`}
                                                    control={<Radio />}
                                                    label={t(
                                                        "class.endOfTopicTest.table.gradeShift5",
                                                    )}
                                                />
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    value={`10`}
                                                    control={<Radio />}
                                                    label={t(
                                                        "class.endOfTopicTest.table.gradeShift10",
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Field>
                            </Box>
                        )}
                        {values.gradeBoundaryType === "custom" && (
                            <Box mt={2}>
                                <Typography gutterBottom>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t("class.endOfTopicTest.add.customHint1"),
                                        }}
                                    ></div>
                                </Typography>
                                <Typography gutterBottom>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t("class.endOfTopicTest.add.customHint2"),
                                        }}
                                    ></div>
                                </Typography>
                                <Typography gutterBottom>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t("class.endOfTopicTest.add.customHint4"),
                                        }}
                                    ></div>
                                </Typography>
                                <Typography gutterBottom>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t("class.endOfTopicTest.add.customHint3"),
                                        }}
                                    ></div>
                                </Typography>
                                {values.gradeBoundaryValues?.map((gB, i) => (
                                    <Grid container key={gB.id} spacing={4}>
                                        <Grid item sm={2}>
                                            <Field
                                                name={`gradeBoundaryValues[${i}].name`}
                                                label={t("class.endOfTopicTest.add.name")}
                                                disabled={true}
                                                component={TextField}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item sm={2}>
                                            <Field
                                                name={`gradeBoundaryValues[${i}].requireMark`}
                                                label={t("class.endOfTopicTest.add.mark")}
                                                type="number"
                                                disabled={isFormLocked}
                                                component={TextField}
                                                margin="normal"
                                            />
                                        </Grid>
                                        {classTrackerGroupId &&
                                        !!values.tierUnits.find(tu => tu.unit !== null) ? (
                                            <>
                                                <Grid item sm={2}>
                                                    <StyledConvertsLabel>
                                                        <Typography whiteSpace="nowrap">
                                                            {t("class.assessment.add.convertsTo")}
                                                        </Typography>
                                                        <Icon path={mdiArrowRight} />
                                                    </StyledConvertsLabel>
                                                </Grid>
                                                {gradeBoundaries?.map(gb => {
                                                    return (
                                                        <>
                                                            <StyledGrid key={gb.id} item sm={2}>
                                                                <Box className="highlight">
                                                                    <MuiTextField
                                                                        value={gb.values[i]}
                                                                        disabled={true}
                                                                        label={
                                                                            gb.tier !== "-"
                                                                                ? t(
                                                                                      "common.tierName" +
                                                                                          gb.tier,
                                                                                  )
                                                                                : ""
                                                                        }
                                                                    />
                                                                </Box>
                                                            </StyledGrid>
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                ))}
                            </Box>
                        )}
                    </Box>
                </PaperInner>
                <PaperInner border="top">
                    <HeadingCounter number="4">
                        {t("class.endOfTopicTest.add.targetClasses")}
                    </HeadingCounter>
                    <Grid container spacing={4}>
                        {!!values.id && (
                            <Grid item sm={4}>
                                <Field
                                    name="yearGroup"
                                    label={t("class.endOfTopicTest.add.yearGroup")}
                                    component={TextField}
                                    disabled={true}
                                    select
                                >
                                    <MenuItem value={values.yearGroup}>
                                        {values.yearGroup === 0
                                            ? t("common.yg0")
                                            : values.yearGroup}
                                    </MenuItem>
                                </Field>
                            </Grid>
                        )}
                        <Grid item sm={4}>
                            <Field
                                name="cohort"
                                label={t("class.endOfTopicTest.add.cohort")}
                                component={TextField}
                                select
                                disabled={true}
                            >
                                <MenuItem value={cohort?.id}>{cohort?.name}</MenuItem>
                            </Field>
                        </Grid>
                    </Grid>
                    {values.yearGroup > 0 && values.classTrackerUnitConfigs.length > 0 && (
                        <Box mt={3.5}>
                            <Typography component="p" gutterBottom>
                                {t("class.endOfTopicTest.add.classChooseHint")}
                            </Typography>
                            {values.classTrackerUnitConfigs.map((c, i) => (
                                <Box key={c.classTracker.id}>
                                    <Field
                                        name={`classTrackerUnitConfigs[${i}].visible`}
                                        component={CheckboxField}
                                        disabled={isFormLocked}
                                        label={values.classTrackerUnitConfigs[i].classTracker.name}
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                    <Box mt={3.5}>
                        <Typography component="p">
                            <Box component="b">
                                {t("class.endOfTopicTest.add.classChooseHint2")}
                            </Box>{" "}
                            {t("class.endOfTopicTest.add.classChooseHint3")}
                        </Typography>
                    </Box>
                </PaperInner>
            </Fragment>
            <PaperInner variant="paddingTopNone">
                {canManageAssessments && !isFormLocked && (
                    <Button
                        disabled={isSubmitting}
                        onClick={() => {
                            if (
                                !values.id &&
                                values.yearGroupWithoutVisibility.length === 0 &&
                                values.classTrackerUnitConfigs.filter(ctuc => ctuc.visible)
                                    .length === 0
                            ) {
                                setSubmitting(false);
                                enqueueSnackbar(t("class.endOfTopicTest.add.pleaseSelectClasses"), {
                                    variant: "info",
                                });
                            } else {
                                const shouldSubmit = values.classTrackerUnitConfigs.find(
                                    ctuc => ctuc.visible,
                                );
                                if (!shouldSubmit) {
                                    setNoClassDialogOpened(true);
                                } else {
                                    submitForm();
                                }
                            }
                        }}
                        color="primary"
                    >
                        {t("class.endOfTopicTest.add.addButton")}
                    </Button>
                )}

                <GenericErrorMessage errors={errors} submitCount={submitCount} />
                <PromptDialog open={noClassDialogOpened} onClose={handleNoClassDialogClose}>
                    {t("class.endOfTopicTest.add.noClassesPromptWarning")}
                </PromptDialog>
            </PaperInner>
        </Form>
    );
};

export default EditEndOfTopicTestForm;
