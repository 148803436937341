import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";
export const SUBJECT_ANALYSIS_GRADE_BREAKDOWN_DATA_QUERY = "subjectAnalysisGradeBreakdownDataQuery";

export interface SubjectAnalysisGradeBreakdownDataResponse {
    grades: {
        position: number;
        name: string;
        numberCurrentWorkingThisGrade: number;
        numberCurrentWorkingThisGradeOrAbove: number;
        numberProjectedWorkingThisGrade: number;
        numberProjectedWorkingThisGradeOrAbove: number;
        numberTeacherWorkingThisGrade: number;
        numberTeacherWorkingThisGradeOrAbove: number;
        numberTargetWorkingThisGradeOrAbove: number;
    }[];
    numberOfStudents: number;
}

export const useSubjectAnalysisGradeBreakdownData = (
    cohort: number,
    yearGroup: number,
    specification: number,
    classTrackers?: number[],
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            SUBJECT_ANALYSIS_GRADE_BREAKDOWN_DATA_QUERY,
            schoolAccountId,
            cohort,
            yearGroup,
            specification,
        ],
        queryFn: async (): Promise<SubjectAnalysisGradeBreakdownDataResponse> => {
            const filteredClassTrackers =
                classTrackers && classTrackers.length > 0 && classTrackers.filter(ct => ct !== 0);
            const classTrackerIds =
                filteredClassTrackers && filteredClassTrackers.length > 0
                    ? filteredClassTrackers.join(",")
                    : undefined;
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/analysis/subject-analysis/${cohort}/${yearGroup}/specifications/${specification}/grade-breakdown/values/`,
                { params: { classTrackerIds } },
            );
            return data || null;
        },
    });
};
