import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getStudentNumberForYearGroupEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/student-report/{cohort}/{yearGroup}/by-year-group/`,
    method: ApiRequests.GET,
};

export interface StudentNumberResponse {
    numberOfStudents: number;
}
