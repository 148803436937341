import { Menu, MenuItem, MenuProps, Box, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Icon from "../Icon";
import { mdiPlay } from "@mdi/js";

interface StyledMenuProps extends MenuProps {
    width?: number;
}

export const StyledMenu = withStyles(() =>
    createStyles({
        paper: {
            border: "0 none",
            boxShadow: "0 1px 2px 0 rgba(0,0,0,0.20), 0 1px 3px 0 rgba(0,0,0,0.10)",
            borderRadius: "0 0 4px 4px",
        },
    }),
)((props: StyledMenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        MenuListProps={{
            disablePadding: true,
        }}
        PaperProps={{
            style: { width: props.width || 380 },
        }}
        {...props}
    />
));

export const StyledMenuItem = withStyles(() => ({
    root: {
        backgroundColor: "transparent",
        margin: 0,
        padding: 0,
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}))(MenuItem);

interface StyledMenuVideoProps {
    id?: number;
    title?: string;
    image?: string;
    onClick?: () => void;
    classes?: any;
}

export const StyledMenuVideoItem = withStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "stretch",
        whiteSpace: "normal",
        flex: 1,
        padding: theme.spacing(2, 2.5),
        width: "100%",
        "& span": {
            width: 64,
            height: 48,
            marginRight: theme.spacing(2.5),
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
        },
        "& svg": {
            opacity: 0,
            filter: "drop-shadow(0px 0px 8px rgba(0, 0, 0, 1))",
        },
        "&:hover svg": {
            opacity: 1,
        },
        "& h3": {
            lineHeight: 1.5,
        },
    },
}))((props: StyledMenuVideoProps) => (
    <MenuItem
        // component="button"
        disableRipple
        disableTouchRipple
        onClick={props.onClick}
        // alignItems="flex-start"
        className={props.classes.root}
    >
        <Box component="span" style={{ backgroundImage: `url(${props.image})` }}>
            <Icon path={mdiPlay} size="20px" color="#fff" />
        </Box>
        <Box display="flex" alignItems="center">
            <Typography component="h3">{props.title}</Typography>
        </Box>
    </MenuItem>
));
