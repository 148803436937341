import { ActionsUnion, Keys } from "@martin_hotell/rex-tils";
import { processEndpoints } from "src/api/helpers";
import { Modules } from "src/types";
import {
    ReportTemplateActionsTypes,
    ReportTemplateApiEndpoints,
    ReportTemplateRawActions,
} from "./ReportTemplate/actions";
import {
    SnapshotReportActionsTypes,
    SnapshotReportApiEndpoints,
    SnapshotReportRawActions,
} from "./SnapshotReport/actions";
import {
    StudentReportActionsTypes,
    StudentReportApiEndpoints,
    StudentReportRawActions,
} from "./StudentReport/actions";

export const ReportActionTypes = {
    ...ReportTemplateActionsTypes,
    ...StudentReportActionsTypes,
    ...SnapshotReportActionsTypes,
};

const _endpoints = {
    ...ReportTemplateApiEndpoints,
    ...StudentReportApiEndpoints,
    ...SnapshotReportApiEndpoints,
};

export type ReportEndpointKeys = Keys<typeof _endpoints>;

export const ReportEndpoints = processEndpoints<ReportEndpointKeys>(Modules.REPORT, _endpoints);

export const ReportActions = {
    ...ReportTemplateRawActions(ReportEndpoints),
    ...StudentReportRawActions(ReportEndpoints),
    ...SnapshotReportRawActions(ReportEndpoints),
};

export type ReportActions = ActionsUnion<typeof ReportActions>;
