import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ClassTrackerStudentTagModel } from "../../model/TagModel";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_STUDENT_TAG_LIST_QUERY = "classTrackerGroupStudentTagListQuery";

export const useClassTrackerGroupStudentTags = (classTrackerGroupId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            CLASS_TRACKER_GROUP_STUDENT_TAG_LIST_QUERY,
            schoolAccountId,
            classTrackerGroupId,
        ],
        queryFn: async (): Promise<ClassTrackerStudentTagModel[] | null> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/student-tags/`,
            );

            return data || null;
        },
    });
};
