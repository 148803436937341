export enum Modules {
    MAINTENANCE = "maintenance",
    AUTH = "auth",
    COMMON = "common",
    CLASS = "class",
    USER = "user",
    SUBSCRIPTION = "subscription",
    SCHOOL = "school",
    TRACKER = "tracker",
    ANALYSIS = "analysis",
    REPORT = "report",
}

export const DATEPICKER_FORMAT = "d MMMM yyyy";
export const DATETIMEPICKER_FORMAT = "d MMMM yyyy H:mm:ss";

export interface ApiListResponse<T> {
    data: T[];
    count: number;
    nextPage: number;
}

export interface BreadcrumbItem {
    id: number;
    name: string;
    type: "SubjectArea" | "Qualification" | "Specification";
}

export type WithRequiredKeys<T, K extends keyof T> = { [P in Exclude<keyof T, K>]?: T[P] } & {
    [P in K]: T[P];
};

export type CustomMap<D, T> = { [P in keyof D]?: T };

export type NotNullableInterface<T> = { [P in keyof T]: NonNullable<T[P]> };

export type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export type PagedList<T> = { data: T[]; nextPage: boolean | number; count?: number };

export type Pagination = { page: number; perPage: number };

export const defaultPagination: Pagination = { page: 1, perPage: 50 };
