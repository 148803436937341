import { Box, Typography } from "@mui/material";
import COLORS from "src/styles/colors";

const BuildingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 29" width={32} height={29}>
            <path
                d="M24.4 13.5h7c.4 0 .6.2.6.5v14.4c0 .4-.2.6-.6.6H.6c-.4 0-.6-.2-.6-.6V14c0-.3.2-.5.6-.5h7v-1.4c0-.2.1-.4.3-.5l7.5-4.2V.6c0-.4.3-.6.6-.6s.6.2.6.6v.6h3.9c.3 0 .6.3.6.6v.8h2.2c.3.1.4.4.3.7l-.4 1.3.4 1.3.1.1c0 .3-.3.6-.6.6h-4.5c-.3 0-.6-.3-.6-.6v-.8h-1.4v2.2l7.5 4.2c.2.1.3.3.3.5zM20 2.3h-3.4v1.8H20zm1 2.3l.1.1c0 .3-.3.5-.6.5h-1.3v.3h3.1l-.2-.7c-.1-.1-.1-.3 0-.4l.2-.7H21zm-.4 23.3h2.7V12.4L16 8.3l-7.3 4.2v15.4h2.7V21c0-.3.3-.5.6-.5h8c.3 0 .6.2.6.5zm-6.9-15.6c0-1.2 1-2.3 2.3-2.3 1.3 0 2.3 1.1 2.3 2.3 0 1.3-1 2.3-2.3 2.3-1.3.1-2.3-1-2.3-2.3zm1.1 0c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2c0-.6-.5-1.2-1.2-1.2s-1.2.6-1.2 1.2zM1.1 27.9h6.5V14.6H1.1zm23.3 0h6.5V14.6h-6.5zm-22-12.1h1.1V18H2.4zm3.9 0H5.2V18h1.1zm4.1 0h2.2v1.1h-2.2zm6.7 1.1v-1.1h-2.2v1.1zm2.3-1.1h2.2v1.1h-2.2zm6.3 0h1.1V18h-1.1zm3.9 0h-1.1V18h1.1zm-17 2.6h-2.2v1.1h2.2zm4.5 0v1.1h-2.2v-1.1zm4.5 0h-2.2v1.1h2.2zM2.4 20.1h1.1v2.2H2.4zm3.9 0H5.2v2.2h1.1zm19.4 0h1.1v2.2h-1.1zm3.9 0h-1.1v2.2h1.1zm-17 7.8h2.8v-6.3h-2.8zm4 0h2.8v-6.3h-2.8zM2.4 24.5h1.1v2.2H2.4zm3.9 0H5.2v2.2h1.1zm19.4 0h1.1v2.2h-1.1zm3.9 0h-1.1v2.2h1.1z"
                fillRule="evenodd"
                fill="#c2c4c8"
            />
        </svg>
    );
};

const AccountButton = ({
    title,
    logo,
    onClick,
}: {
    title: string;
    logo?: string;
    onClick?: () => void;
}) => {
    return (
        <Box
            component="button"
            onClick={onClick}
            sx={{
                backgroundColor: "#f9f9f9",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "divider",
                borderRadius: 4,
                padding: 0,
                margin: 0,
                overflow: "hidden",
                display: "flex",
                alignItems: "stretch",
                width: "100%",
                cursor: "pointer",
                "&:hover": {
                    borderColor: "grey.400",
                },
            }}
        >
            <Box
                sx={{
                    backgroundColor: "background.paper",
                    width: 80,
                    height: 80,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRightWidth: 1,
                    borderRightStyle: "solid",
                    borderRightColor: "inherit",
                    "& > img": {
                        maxWidth: 56,
                        maxHeight: 56,
                    },
                }}
            >
                {logo ? <img src={logo} /> : <BuildingIcon />}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
                <Typography sx={{ fontWeight: "fontWeightMedium", color: COLORS.GREY_4 }}>
                    {title}
                </Typography>
            </Box>
        </Box>
    );
};

export default AccountButton;
