import { Box, Button, Grid, MenuItem, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import COLORS from "src/styles/colors";
import {
    SubjectAnalysisQualification,
    SubjectAnalysisSubjectArea,
    SubjectAnalysisSubjectAreaListResponse,
} from "../../hooks/SubjectAnalysis/useSubjectAnalysisSubjectAreasWithSpecifications";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(0, 0, 3),
    },
    container: {
        display: "flex",
        flex: 1,
        width: "100%",
        minWidth: 1200,
        borderTop: `1px solid ${COLORS.LIGHT_GREY_2}`,
        overflow: "hidden",
        backgroundColor: COLORS.WHITE,
    },
    content: {
        flex: 1,
        overflowY: "auto",
        position: "relative",
    },
    inner: {
        padding: theme.spacing(6, 4),
        [theme.breakpoints.up(1400)]: {
            padding: theme.spacing(8, 12.5),
        },
    },
}));

interface OwnProps {
    subjectAnalysisSubjects: SubjectAnalysisSubjectAreaListResponse | null;
    selectedSubject: number | null;
    selectedQualification: number | null;
    selectedSpecification: number | null;
    handleChangeSelection: (
        type: "subject" | "qualification" | "specification",
        value: number,
    ) => void;
    handleShowReport: () => void;
}

const SubjectAnalysisCourseSelectorBox: FC<OwnProps> = ({
    subjectAnalysisSubjects,
    selectedSubject,
    selectedQualification,
    selectedSpecification,
    handleChangeSelection,
    handleShowReport,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    let subjectAreaObj: SubjectAnalysisSubjectArea | null = null;
    let qualificationObj: SubjectAnalysisQualification | null = null;

    if (subjectAnalysisSubjects) {
        subjectAreaObj = subjectAnalysisSubjects?.find(sa => sa.id === selectedSubject) || null;
    }

    if (subjectAreaObj) {
        qualificationObj =
            subjectAreaObj?.qualifications.find(q => q.id === selectedQualification) || null;
    }

    return (
        <Box className={classes.content}>
            <Box className={classes.inner}>
                <Typography gutterBottom>{t("analysis.subjectAnalysis.hint")}</Typography>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <TextField
                            label={t("analysis.subjectAnalysis.subjectArea")}
                            onChange={e =>
                                handleChangeSelection("subject", parseInt(e.target.value))
                            }
                            select
                            value={selectedSubject || null}
                            margin="normal"
                        >
                            {subjectAnalysisSubjects &&
                                subjectAnalysisSubjects.map(sas => {
                                    return (
                                        <MenuItem value={sas.id} key={sas.id}>
                                            {sas.name}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                        {selectedSubject && subjectAreaObj && (
                            <TextField
                                label={t("analysis.subjectAnalysis.qualification")}
                                onChange={e =>
                                    handleChangeSelection("qualification", parseInt(e.target.value))
                                }
                                select
                                value={selectedQualification || null}
                                margin="normal"
                            >
                                {subjectAreaObj &&
                                    subjectAreaObj.qualifications.map(q => {
                                        return (
                                            <MenuItem value={q.id} key={q.id}>
                                                {q.name}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        )}
                        {selectedQualification && qualificationObj && (
                            <TextField
                                label={t("analysis.subjectAnalysis.specification")}
                                onChange={e =>
                                    handleChangeSelection("specification", parseInt(e.target.value))
                                }
                                select
                                value={selectedSpecification || null}
                                margin="normal"
                            >
                                {qualificationObj &&
                                    qualificationObj.specifications.map(s => {
                                        return (
                                            <MenuItem value={s.id} key={s.id}>
                                                {s.name}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        )}
                    </Grid>
                </Grid>
                {selectedSpecification && (
                    <Box mt={3}>
                        <Button color="primary" onClick={() => handleShowReport()}>
                            {t("analysis.subjectAnalysis.showAnalysisBtn")}
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
export default SubjectAnalysisCourseSelectorBox;
