import { attr, Model } from "redux-orm";
import { OrmSchoolCustomFieldShort, SchoolCustomFieldTypes } from "../SchoolCustomField";

const fields = {
    id: attr(),
    name: attr(),
    isDefault: attr(),
    fileNamePattern: attr(),
    mergeIntoOneFile: attr(),
    hideMtg: attr(),
    hideCurrentGrade: attr(),
    hideAverageUnitGrade: attr(),
    hideTpg: attr(),
    reportTemplateCustomFields: attr(),
};

export interface OrmReportTemplate {
    id: number | null;
    name: string;
    isDefault?: boolean;
    fileNamePattern: string | null;
    mergeIntoOneFile: boolean;
    hideMtg: boolean;
    hideCurrentGrade: boolean;
    hideAverageUnitGrade: boolean;
    hideTpg: boolean;
    reportTemplateCustomFields: OrmSchoolCustomFieldShort[];
    showDistanceFromMtgColours: boolean;
}

export const emptyReportTemplate = {
    id: null,
    name: "",
    isDefault: false,
    fileNamePattern: null,
    mergeIntoOneFile: true,
    hideMtg: false,
    hideCurrentGrade: false,
    hideAverageUnitGrade: false,
    hideTpg: false,
    showDistanceFromMtgColours: true,
    reportTemplateCustomFields: [
        { fieldType: SchoolCustomFieldTypes.ATTITUDE, hidden: false, fromLabel: true },
        { fieldType: SchoolCustomFieldTypes.EFFORT, hidden: false, fromLabel: true },
        { fieldType: SchoolCustomFieldTypes.RESILIENCE, hidden: false, fromLabel: true },
        { fieldType: SchoolCustomFieldTypes.HOME_LEARNING, hidden: false, fromLabel: true },
        { fieldType: SchoolCustomFieldTypes.INDEPENDENT_LEARNING, hidden: false, fromLabel: true },
        { fieldType: SchoolCustomFieldTypes.ORGANISATION, hidden: false, fromLabel: true },
    ],
};

class ReportTemplate extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "ReportTemplate";
    static fields = fields;
    static emptyRef: OrmReportTemplate = emptyReportTemplate;

    get ormRef(): ReportTemplate {
        return this.ref as any;
    }

    get ormRefDetails(): OrmReportTemplate {
        return this.ormRef as any;
    }
}

export default ReportTemplate;
