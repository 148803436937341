import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmUserActions, OrmUserActionTypes } from "./actions";

export const userReducer: ModelReducer = {
    [OrmUserActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmUserActions.bulkImport>,
        session,
    ) => {
        const { User } = session;

        User.all().delete();
        action.payload.forEach(c => User.create(ormToRef(c, {})));
    },
    [OrmUserActionTypes.UPSERT]: (action: ReturnType<typeof OrmUserActions.upsert>, session) => {
        const { User } = session;
        User.upsert(
            ormToRef(action.payload, {
                defaults: {},
            }),
        );
    },
    [OrmUserActionTypes.MASS_MERGE]: (
        action: ReturnType<typeof OrmUserActions.massMerge>,
        session,
    ) => {
        const { User } = session;

        action.payload.forEach(c => User.upsert(ormToRef(c, { defaults: {} })));
    },
};
