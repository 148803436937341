import HeadingCounter from "src/components/HeadingCounter";
import ErrorMessage from "src/forms/ErrorMessage";
import CheckboxField from "src/forms/CheckboxField";
import PaperInner from "src/components/PaperInner";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import StaticSpecificationSelector from "../../../class/components/Common/StaticSpecificationSelector";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { MenuItem, Grid, Typography, Box, Button } from "@mui/material";
import { ListObject } from "src/forms/types";
import { PublishedAssessmentRequest } from "../../hooks/Published/useEditMutation";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { CohortModel } from "src/modules/common/models/CohortModel";
import { TFunction } from "i18next";
import { useRoles } from "src/modules/common/hooks/useRoles";
import * as yup from "yup";

export interface EditPastPaperFormShape {
    id: number | null;
    name: string;
    countsTowardGrade: boolean;
    hiddenColumns: boolean;
    unit: { id: number; name: string; isRepeated: boolean };
    module: ListObject<number>;
    yearGroup: number;
    showQlaInReports: boolean;
    cohort: number | null;
    classTrackerUnitConfigs: {
        classTracker: ListObject<number> & { yearGroup: number };
        visible: boolean;
        index?: number;
    }[];
    specification: ListObject<number | null> | number | null;
    subjectArea: ListObject<number | null> | number | null;
    qualification: ListObject<number | null> | number | null;
    tier?: TiersTypes | null;
}

export const editPastPaperSchema = (t: TFunction) => {
    return yup.object().shape({
        name: yup.string().required(t("common.form.validation.name.required")),
        countsTowardGrade: yup.boolean().notRequired(),
        classTrackerUnitConfigs: yup
            .array()
            .min(1, t("common.form.validation.classTracker.required")),
        specification: yup
            .object()
            .shape({
                id: yup.number().required(t("common.form.validation.specification.required")),
            })
            .nullable(),
    });
};

export const convertFormDataToApiRequest = (
    values: EditPastPaperFormShape,
): PublishedAssessmentRequest => {
    return {
        name: values.name,
        countsTowardGrade: values.countsTowardGrade,
        hiddenColumns: values.hiddenColumns || false,
        showQlaInReports: values.showQlaInReports || false,
        classTrackerUnitConfigs: values.classTrackerUnitConfigs.map(ctuc => ({
            classTracker: { id: ctuc.classTracker.id },
            visible: ctuc.visible,
        })),
    };
};

interface OwnProps {
    cohort?: CohortModel;
    isFormLocked?: boolean;
}

const EditPastPaperForm: FC<OwnProps> = ({ cohort, isFormLocked }) => {
    const { t } = useTranslation();
    const { values, errors, submitCount, isSubmitting, submitForm } =
        useFormikContext<EditPastPaperFormShape>();
    const { canManageAssessments } = useRoles();
    const isRepeated = values.unit.isRepeated;

    return (
        <Form>
            <PaperInner>
                <HeadingCounter number="1">{t("class.pastPaper.add.targetUnit")}</HeadingCounter>
                <StaticSpecificationSelector
                    subjectArea={values.subjectArea as ListObject}
                    qualification={values.qualification as ListObject}
                    specification={values.specification as ListObject}
                />
                <Grid container spacing={4}>
                    {values.tier !== "-" && (
                        <Grid item sm={4}>
                            <Field
                                sx={{ mt: 2 }}
                                name="tier"
                                select
                                label={t("class.pastPaper.add.tier")}
                                disabled={true}
                                component={TextField}
                            >
                                <MenuItem value={values.tier}>
                                    {t("common.tierName" + values.tier)}
                                </MenuItem>
                            </Field>
                        </Grid>
                    )}
                    {values.unit && (
                        <Grid item sm={4}>
                            <Field
                                sx={{ mt: 2 }}
                                name="unit.id"
                                select
                                label={t("class.pastPaper.add.unit")}
                                disabled={true}
                                component={TextField}
                            >
                                <MenuItem value={values.unit.id}>{values.unit.name}</MenuItem>
                            </Field>
                        </Grid>
                    )}
                </Grid>
            </PaperInner>
            <PaperInner border="top">
                <HeadingCounter number="2">
                    {t("class.pastPaper.add.configurePastPaper")}
                </HeadingCounter>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <Field
                            name="name"
                            label={t("common.form.label.name")}
                            component={TextField}
                            disabled={isFormLocked}
                            margin="normal"
                        />
                        <Field
                            name={`countsTowardGrade`}
                            component={CheckboxField}
                            label={t("class.pastPaper.add.countsTowardGrade")}
                            disabled={isFormLocked}
                        />
                        {!isRepeated && (
                            <Field
                                name={`showQlaInReports`}
                                component={CheckboxField}
                                label={t("class.pastPaper.add.showQlaInReports")}
                                disabled={isFormLocked}
                            />
                        )}
                    </Grid>
                    <Grid item sm={4}>
                        <Field
                            name="module.id"
                            select
                            disabled={true}
                            label={t("class.pastPaper.add.module")}
                            component={TextField}
                        >
                            <MenuItem value={values.module.id}>{values.module.name}</MenuItem>
                        </Field>
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner border="top">
                <HeadingCounter number="3">{t("class.pastPaper.add.targetClasses")}</HeadingCounter>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <Field
                            name="yearGroup"
                            label={t("class.pastPaper.add.yearGroup")}
                            component={TextField}
                            disabled={true}
                            select
                        >
                            <MenuItem value={values.yearGroup}>
                                {values.yearGroup === 0 ? t("common.yg0") : values.yearGroup}
                            </MenuItem>
                        </Field>
                    </Grid>
                    <Grid item sm={4}>
                        <Field
                            name="cohort"
                            label={t("class.pastPaper.add.cohort")}
                            component={TextField}
                            select
                            disabled={true}
                        >
                            <MenuItem value={cohort?.id}>{cohort?.name}</MenuItem>
                        </Field>
                    </Grid>
                </Grid>
                {values.classTrackerUnitConfigs?.length > 0 && (
                    <Box mt={3.5}>
                        <Typography component="p" gutterBottom>
                            {t("class.pastPaper.add.classChooseHint")}
                        </Typography>
                        {!!values.id &&
                            values.classTrackerUnitConfigs.map((c, i) => (
                                <Box key={c.classTracker.id}>
                                    <Field
                                        name={`classTrackerUnitConfigs[${i}].visible`}
                                        disabled={isFormLocked}
                                        component={CheckboxField}
                                        label={values.classTrackerUnitConfigs[i].classTracker.name}
                                    />
                                </Box>
                            ))}
                    </Box>
                )}
                {errors &&
                    errors.classTrackerUnitConfigs &&
                    typeof errors.classTrackerUnitConfigs == "string" && (
                        <ErrorMessage name="classTrackerUnitConfigs" />
                    )}
                <Box mt={3.5}>
                    <Typography component="p">
                        <Box component="b">{t("class.pastPaper.add.classChooseHint2")}</Box>{" "}
                        {t("class.pastPaper.add.classChooseHint3")}
                    </Typography>
                </Box>
            </PaperInner>
            <PaperInner variant="paddingTopNone">
                {canManageAssessments && !isFormLocked && (
                    <Button disabled={isSubmitting} onClick={submitForm} color="primary">
                        {t("class.pastPaper.add.addButton")}
                    </Button>
                )}
                <GenericErrorMessage errors={errors} submitCount={submitCount} />
            </PaperInner>
        </Form>
    );
};

export default EditPastPaperForm;
