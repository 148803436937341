import { TFunction } from "i18next";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import AverageHeaderRenderer from "../components/Grid/HeaderRenderer/AverageHeaderRenderer";
import ColumnConfigurationHeader from "../components/Grid/HeaderRenderer/ColumnConfigurationHeader";
import DefaultHeaderRenderer from "../components/Grid/HeaderRenderer/DefaultHeaderRenderer";
import SpecHeaderRenderer from "../components/Grid/HeaderRenderer/SpecHeaderRenderer";
import { AnalysisGridFrameworkComponents } from "./analysisGridConfig";
import { TagModel } from "src/modules/tagging/model/TagModel";
import { TagHeader } from "../components/Grid/HeaderRenderer/TagHeader";
import { SchoolUserRole } from "src/orm/models/User";
import { mdiSortAscending, mdiSortDescending } from "@mdi/js";
import { isValueDefined } from "src/services/utilsGPT";
import { AnalysisGradeSummaryHeaderObject } from "../hooks/GradeSumary/useAnalysisGradeSummaryHeader";

export const ANALYSIS_INITIAL_STUDENT_PARAMS = ["student_firstName", "student_lastName"];
export const STUDENTS_HEADER_CLASS = "ag-students-header";
export const STUDENTS_CELL_CLASS = "ag-students-cell";

export enum AnalysisHeaderNodes {
    STUDENT = "student",
    SUMMARY = "summary", // 1
    BUCKET = "bucket", // 2
    DUMMY = "dummy",
    SPEC = "spec", // 2.1
    QUAL = "qual", //
    ATL = "atl",
    AGGREGATE = "aggregated", // average / 2.2
    COMPUTED = "computed", // summary / child / 1.1
}

export enum GroupColumShow {
    OPENED = "open",
    CLOSED = "closed",
}

export enum PinnedTypes {
    LEFT = "left",
    TOP = "top",
}

export const calculateAnalysisHeaderHeight = (
    analysisHeader: AnalysisGradeSummaryHeaderObject | null,
) => {
    return 248 + ((analysisHeader?.maxLevel || 0) - 1) * 78;
};

export const calculateKS4AnalysisHeaderHeight = (
    analysisHeader: AnalysisGradeSummaryHeaderObject | null,
) => {
    return 248 + 78 + Math.max(0, (analysisHeader?.maxLevel || 0) - 2) * 78;
};

const sharedNode = {
    cellClass: "ag-value-cell",
    width: 132,
    headerClass: "",
    pinned: "",
    sortable: false,
    suppressNavigable: true,
    suppressMovable: true,
    resizable: false,
    suppressMenu: true,
    // headerGroupComponentFramework: DefaultHeaderRenderer,
    headerComponentFramework: DefaultHeaderRenderer,
    headerComponentParams: {},
    headerGroupComponentParams: {},
    // pinnedRowCellRenderer: AnalysisGridFrameworkComponents.PINNED_TOP_RENDERER,
    cellRendererSelector: () => ({
        component: AnalysisGridFrameworkComponents.ANALYSIS_DUMMY_RENDERER,
        params: {},
    }),
    children: undefined,
};

const resolveStudentColumnWidth = cc => {
    if (["student_firstName", "student_lastName", "student_uniquePupilNumber"].includes(cc)) {
        return 160;
    }
    if (["student_ethnicity_name"].includes(cc)) {
        return 250;
    }

    return 110;
};

export const generateTagsAvgHeader = () => {
    return [
        {
            headerName: "",
            field: `tagAvg`,
            singleClickEdit: false,
            pinned: PinnedTypes.LEFT,
            width: 42,
            editable: false,
            sortable: false,
            suppressNavigable: true,
            suppressMovable: true,
            resizable: false,
            autoHeight: false,
            suppressMenu: true,
            cellRendererSelector: () => ({
                component: AnalysisGridFrameworkComponents.TAG_AVG_RENDERER,
            }),
        },
    ];
};

const generateExcelStudentHeader = (t: TFunction, analysisUserSettings) => {
    const visibleStudentColumns = [...ANALYSIS_INITIAL_STUDENT_PARAMS];

    if (analysisUserSettings?.columnConfig) {
        Object.keys(analysisUserSettings?.columnConfig)
            .filter(
                key =>
                    analysisUserSettings?.columnConfig[key] &&
                    !ANALYSIS_INITIAL_STUDENT_PARAMS.includes(key),
            )
            .forEach(key => {
                visibleStudentColumns.push(key);
            });
    }

    return [
        {
            headerName: "",
            field: "dummy",
            children: visibleStudentColumns.map(vsc => ({
                headerName: t(`tracker.grid.configureColumns.${vsc}`),
                field: vsc,
            })),
        },
    ];
};

const generateStudentHeader = (t: TFunction, analysisUserSettings) => {
    const visibleStudentColumns = [...ANALYSIS_INITIAL_STUDENT_PARAMS];

    const tagsAvg: any = generateTagsAvgHeader();

    if (analysisUserSettings?.columnConfig) {
        Object.keys(analysisUserSettings?.columnConfig)
            .filter(
                key =>
                    analysisUserSettings?.columnConfig[key] &&
                    !ANALYSIS_INITIAL_STUDENT_PARAMS.includes(key),
            )
            .forEach(key => {
                visibleStudentColumns.push(key);
            });
    }

    return [
        {
            ...sharedNode,
            headerName: "",
            field: "dummy",
            headerGroupComponent: ColumnConfigurationHeader,
            suppressMovable: true,
            children: tagsAvg.concat(
                visibleStudentColumns.map(vsc => ({
                    headerName: t(`tracker.grid.configureColumns.${vsc.replace("student_", "")}`),
                    field: vsc,
                    width: resolveStudentColumnWidth(vsc),
                    headerClass: STUDENTS_HEADER_CLASS,
                    cellClass: () => STUDENTS_CELL_CLASS,
                    pinned: PinnedTypes.LEFT as string,
                    sortable: true,
                    icons: {
                        sortAscending: `<svg viewBox="0 0 24 24" role="presentation" style="width: 18px; height: 18px; margin: 0 0 -4px 0"><path d=${mdiSortAscending.replaceAll(
                            " ",
                            ",",
                        )} style="fill: currentcolor;"></path></svg>`,
                        sortDescending: `<svg viewBox="0 0 24 24" role="presentation" style="width: 18px; height: 18px; margin: 0 0 -4px 0"><path d=${mdiSortDescending} style="fill: currentcolor;"></path></svg>`,
                    },
                    suppressNavigable: true,
                    suppressMovable: true,
                    resizable: false,
                    suppressMenu: true,
                    autoHeight: true,
                    // pinnedRowCellRenderer:
                    //     AnalysisGridFrameworkComponents.ANALYSIS_DEFAULT_GRADE_RENDERER,
                    cellRendererSelector: () => {
                        return {
                            component: AnalysisGridFrameworkComponents.STUDENT_DATA_RENDERER,
                        };
                    },
                })),
            ),
        },
    ];
};

const getCellRenderer = type => {
    switch (type) {
        case AnalysisHeaderNodes.SPEC:
            return {
                component: AnalysisGridFrameworkComponents.ANALYSIS_DEFAULT_GRADE_RENDERER,
            };
        default:
            return {
                component: AnalysisGridFrameworkComponents.ANALYSIS_DUMMY_RENDERER,
            };
    }
};

const getHeaderDataNode = (
    headerNode,
    flatHeader,
    colour,
    dataType: DataTypeTypes,
    readOnly?: boolean,
    level?: number,
    parentNodeType?: string,
    parentNodeName?: string,
    parentNodeUnit?: string,
) => {
    const columnColour = colour || headerNode.headerConfig?.colour;
    const currentLevel = level || 1;
    const parentNode = headerNode.nodeType || null;
    const parentName = headerNode.name || undefined;
    const parentUnit = headerNode.nodeType === "atl" ? headerNode.hash : parentNodeUnit || "";

    const getChildren = () => {
        const children = headerNode.children
            .filter(child => child.nodeType !== AnalysisHeaderNodes.STUDENT)
            .map(child => {
                return getHeaderDataNode(
                    child,
                    flatHeader,
                    columnColour,
                    dataType,
                    readOnly,
                    currentLevel + 1,
                    parentNode,
                    parentName,
                    parentUnit,
                );
            });

        if (headerNode.nodeType === "atl") {
            const averageCol = {
                headerName: headerNode.name,
                cellClass: "ag-value-cell ag-value-cell-analysis",
                field: headerNode.hash + "-atl-average",
                width: 54,
                headerClass: "",
                columnGroupShow: GroupColumShow.OPENED,
                pinned: "",
                sortable: false,
                suppressNavigable: true,
                suppressMovable: true,
                resizable: false,
                singleClickEdit: false,
                editable: false,
                autoHeight: false,
                suppressMenu: true,
                headerComponent: AverageHeaderRenderer,
                headerComponentParams: {
                    headerConfig: headerNode.headerConfig,
                    columnColour,
                    nodeType: headerNode.nodeType,
                    parentNodeType: parentNodeType,
                    level: currentLevel,
                },
                cellRendererSelector: () => ({
                    component: AnalysisGridFrameworkComponents.ANALYSIS_AVERAGE_RENDERER,
                    params: { columnColour, headerConfig: headerNode.headerConfig },
                }),
                children: undefined,
            };
            children.push(averageCol);
        }

        return children;
    };
    const node = {
        headerName: headerNode.name,
        cellClass: "ag-value-cell ag-value-cell-analysis",
        field: headerNode.hash,
        width: headerNode.nodeType === AnalysisHeaderNodes.SPEC ? 100 : 54,
        headerClass: "",
        pinned: "",
        sortable: true,
        suppressNavigable: true,
        suppressMovable: true,
        resizable: false,
        singleClickEdit: true,
        autoHeight: false,
        columnGroupShow: headerNode?.headerConfig?.isNeverCollapsed
            ? null
            : headerNode.nodeType === AnalysisHeaderNodes.AGGREGATE ||
                headerNode.nodeType === AnalysisHeaderNodes.ATL
              ? GroupColumShow.OPENED
              : GroupColumShow.CLOSED,
        editable: data => {
            if (readOnly) return false;
            if (data.node.rowPinned === "top") {
                return false;
            }
            return !!(
                dataType === DataTypeTypes.FORECAST &&
                headerNode.nodeType === AnalysisHeaderNodes.SPEC
            );
        },
        suppressMenu: true,
        headerGroupComponent: DefaultHeaderRenderer,
        headerComponent: [
            AnalysisHeaderNodes.SPEC,
            AnalysisHeaderNodes.AGGREGATE,
            AnalysisHeaderNodes.DUMMY,
            AnalysisHeaderNodes.COMPUTED,
        ].includes(headerNode.nodeType)
            ? SpecHeaderRenderer
            : DefaultHeaderRenderer,
        headerComponentParams: {
            headerConfig: headerNode.headerConfig,
            columnColour,
            nodeType: headerNode.nodeType,
            parentNodeType: parentNodeType,
            level: currentLevel,
        },
        headerGroupComponentParams: {
            headerConfig: headerNode.headerConfig,
            columnColour,
            nodeType: headerNode.nodeType,
            parentNodeType: parentNodeType,
            level: currentLevel,
        },
        cellRendererSelector: () => ({
            ...getCellRenderer(headerNode.nodeType),
            params: { columnColour, headerConfig: headerNode.headerConfig, readOnly },
        }),
        cellEditorSelector: () => ({
            component: AnalysisGridFrameworkComponents.DEFAULT_VALUE_EDITOR,
            params: { columnColour, headerConfig: headerNode.headerConfig },
        }),
        children: headerNode.children ? getChildren() : undefined,
        comparator: (num1: string | null, num2: string | null, _nodeA, _nodeB, isInverted) => {
            if (!isValueDefined(num1) && !isValueDefined(num2)) {
                if (num1 === null) return isInverted ? 1 : -1;
                return isInverted ? -1 : 1;
            }
            if (!isValueDefined(num1)) return isInverted ? -1 : 1;
            if (!isValueDefined(num2)) return isInverted ? 1 : -1;
            return Number(num1) - Number(num2);
        },
    };

    if (node.field.indexOf("spec") > -1) {
        flatHeader.push({
            field: node.field,
            name: node.headerName,
            parentNodeName,
            subject: headerNode.headerConfig?.subjectArea,
            parentUnit,
        });
    }

    return node;
};

const getExcelHeaderDataNode = (headerNode, dataType: DataTypeTypes, prefix?: string) => {
    const processedChildren = [] as any;
    if (headerNode.children) {
        headerNode.children
            .filter(child => child.nodeType !== AnalysisHeaderNodes.STUDENT)
            .forEach(child => {
                processedChildren.push(getExcelHeaderDataNode(child, dataType));
                if (child.nodeType === AnalysisHeaderNodes.SPEC) {
                    processedChildren.push(getExcelHeaderDataNode(child, dataType, "A8__"));
                }
            });
    }

    return {
        headerName: (prefix || "") + headerNode.name,
        field: (prefix || "") + headerNode.hash,
        children: processedChildren.length > 0 ? processedChildren : undefined,
    };
};

const generateDataHeader = (
    analysisGradeSummaryHeader: AnalysisGradeSummaryHeaderObject,
    flatHeader,
    dataType: DataTypeTypes,
    readOnly?: boolean,
) => {
    if (analysisGradeSummaryHeader) {
        return analysisGradeSummaryHeader.header
            .filter(headerNode => headerNode.nodeType !== AnalysisHeaderNodes.DUMMY)
            .map(headerNode => getHeaderDataNode(headerNode, flatHeader, null, dataType, readOnly));
    }
    return [];
};

const generateExcelDataHeader = (
    analysisGradeSummaryHeader: AnalysisGradeSummaryHeaderObject,
    dataType: DataTypeTypes,
) => {
    if (analysisGradeSummaryHeader) {
        return analysisGradeSummaryHeader.header
            .filter(headerNode => headerNode.nodeType !== AnalysisHeaderNodes.DUMMY)
            .map(headerNode => getExcelHeaderDataNode(headerNode, dataType));
    }
    return [];
};

// Generate header MAIN entry
export const generateHeader = (
    analysisGradeSummaryHeader: AnalysisGradeSummaryHeaderObject,
    t: TFunction,
    flatHeader,
    dataType: DataTypeTypes,
    analysisUserSettings?: any,
    tags?,
    readOnly?: boolean,
) => {
    const studentHeader = generateStudentHeader(t, analysisUserSettings);
    const tagsHeader = generateTagsHeader(tags, t);

    const dataHeader = generateDataHeader(
        analysisGradeSummaryHeader,
        flatHeader,
        dataType,
        readOnly,
    );

    return studentHeader.concat(tagsHeader).concat(dataHeader as any);
};

export const generateTagsHeader = (tags, t) => {
    return (tags || [])
        .map((t: TagModel) => {
            const checkIsEditable = ({ dataType, userProfile }) => {
                const isEditable = dataType === DataTypeTypes.LIVE && t.status === "active";
                if (
                    userProfile.userRole === SchoolUserRole.SCHOOL_ADMIN ||
                    userProfile.userRole === SchoolUserRole.OWNER ||
                    t.accessLevel === "all"
                )
                    return isEditable;
                if (t.accessLevel === "author" && userProfile) {
                    return isEditable && userProfile.id === t.authorId;
                }
                if (t.accessLevel === "leader" && userProfile) {
                    return (
                        isEditable &&
                        (userProfile.isLeaderOfAll || userProfile.leaderOfSubjectAreas.length > 0)
                    );
                }
                return isEditable;
            };
            return {
                headerName: t.name,
                field: `tag-${t.id}`,
                singleClickEdit: true,
                pinned: PinnedTypes.LEFT,
                hide: true,
                suppressMovable: true, //991 enable move tags
                cellDataType: "object",
                width: 42,
                editable: ({ context }) => checkIsEditable(context),
                headerComponent: TagHeader,
                headerComponentParams: { locked: !(t.status === "active") },
                cellEditorSelector: () => ({
                    component: AnalysisGridFrameworkComponents.TAG_DATA_EDITOR,
                }),
                cellRendererSelector: () => ({
                    component: AnalysisGridFrameworkComponents.TAG_DATA_RENDERER,
                    params: { checkIsEditable },
                }),
            };
        })
        .concat([
            {
                headerName: t("common.allSubjects"),
                field: `tagAllSubjects`,
                singleClickEdit: false,
                pinned: PinnedTypes.LEFT,
                width: 42,
                editable: false,
                sortable: false,
                suppressNavigable: true,
                suppressMovable: true,
                resizable: false,
                headerComponentParams: {
                    isAllSubjects: true,
                },
                autoHeight: false,
                suppressMenu: true,
                headerComponent: TagHeader,
                hide: false,
                cellRendererSelector: () => ({
                    component: AnalysisGridFrameworkComponents.TAG_ALLSUBJECTS_RENDERER,
                }),
            },
        ]);
};
// Generate  ATL header MAIN entry
export const generateAtlHeader = (
    analysisGradeSummaryHeader: AnalysisGradeSummaryHeaderObject,
    t: TFunction,
    flatHeader,
    dataType: DataTypeTypes,
    analysisUserSettings?: any,
    tags?,
) => {
    const studentHeader = generateStudentHeader(t, analysisUserSettings);
    const tagsHeader = generateTagsHeader(tags, t);

    const dataHeader = generateDataHeader(analysisGradeSummaryHeader, flatHeader, dataType);

    return studentHeader.concat(tagsHeader).concat(dataHeader as any);
};

// Generate excel header MAIN entry
export const generateExcelHeader = (
    analysisGradeSummaryHeader: AnalysisGradeSummaryHeaderObject,
    t: TFunction,
    dataType: DataTypeTypes,
    analysisUserSettings,
) => {
    const studentHeader = generateExcelStudentHeader(t, analysisUserSettings);
    const dataHeader = generateExcelDataHeader(analysisGradeSummaryHeader, dataType);

    return studentHeader.concat(dataHeader as any);
};
