import StudentFilter, {
    FiltersShape,
    studentValueMinMaxFilters,
} from "../../../../common/components/Grid/GridStudentFilter";
import useDebounce from "src/hooks/useDebounce";
import GridFilterSummary from "src/modules/common/components/Grid/GridFilterSummary";
import KS4Filter from "./FilterPanel/KS4Filter";
import AnalysisGradeFilter from "./FilterPanel/GradeFilter";
import TagFilter from "src/modules/tracker/components/Grid/ToolsPanel/FilterPanel/TagFilter";
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAvailableStudentParamsBuColumns } from "src/modules/tracker/services/valueHandler";
import { useLocation } from "react-router";
import { baseHeaders, get } from "src/services/ajax";
import { apiUrl } from "src/config/globals";
import { excludedStudentParams } from "src/modules/common/services/gridFilter";
import { TagModel } from "src/modules/tagging/model/TagModel";
import { mergeFilters } from "src/services/utils";
import MTGFilter, {
    getInitialMtgOptions,
} from "src/modules/tracker/components/Grid/ToolsPanel/FilterPanel/MTGFilter";
import SummaryFilter from "./FilterPanel/SummaryFilter";
import { rangeValueDisplay } from "./FilterPanel/RangeFilterComponent";
import { isValueDefined } from "src/services/utilsGPT";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getSchoolAccountId } from "src/services/url";
import { useAnalysisUserSettingsStore } from "src/modules/analysis/hooks/useAnalysisUserSettingsStore";

export enum GridFilterTypes {
    STUDENT = "student",
    GRADE = "grade",
    TAG = "tag",
    MTG = "mtg",
    SUMMARY = "summary",
}

interface OwnProps {
    context;
    api: GridApi;
}

const AnalysisFilterPanel: FC<OwnProps> = ({ context }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { navigate } = useSchoolNavigate();
    const {
        cohort,
        yearGroup,
        handleFiltersChange,
        analysisType,
        flatHeader,
        relatedData,
        columns,
        summaryColumns,
    } = context;

    const [studentForms, setStudentForms] = useState([]);
    const [studentAcademicHouses, setStudentAcademicHouses] = useState([]);

    const { setAnalysisUserSettings, ...analysisUserSettings } = useAnalysisUserSettingsStore();

    const [filterTypeRadio, setFilterTypeRadio] = useState<GridFilterTypes>(
        GridFilterTypes.STUDENT,
    );
    const [filters, setFilters] = useState<FiltersShape>({});
    const debouncedFilters = useDebounce(filters, 400);
    const [studentParams, setStudentParams] = useState<string[]>([]);

    const updateSearchParams = params => {
        [...excludedStudentParams].forEach(excludedParam => {
            delete params[excludedParam];
        });

        const filterParams = { ...params };

        Object.keys(filterParams).forEach(key => {
            if (key === "dataType" || key === "viewGrade" || key === "viewType") {
                delete filterParams[key];
            }
        });

        const newSearchParams = new URLSearchParams(params).toString();
        const newFilterParams = new URLSearchParams(filterParams).toString();

        if (cohort && yearGroup && analysisType) {
            setAnalysisUserSettings({ ...analysisUserSettings, filters: newFilterParams });
        }
        navigate({
            pathname: location.pathname,
            search: "?" + newSearchParams,
        });
    };

    const handleFilters = (filter: FiltersShape) => {
        const newFilter = mergeFilters(filters, filter);

        const currentParams = Object.fromEntries(new URLSearchParams(location.search));

        Object.keys(newFilter).forEach(key => {
            const currentParamValue = [];
            newFilter[key].values.forEach(({ value, valueTo }) => {
                if (value === "no-data") {
                    currentParamValue.push("no-data");
                } else if (
                    newFilter[key].type === "grade" ||
                    newFilter[key].type === "KS4" ||
                    newFilter[key].type === "summary" ||
                    (newFilter[key].type === "student" && isValueDefined(valueTo))
                ) {
                    currentParamValue.push(`${value}|${valueTo}`);
                } else {
                    currentParamValue.push(`${value}`);
                }
            });
            currentParams[key] = JSON.stringify(currentParamValue);
        });

        updateSearchParams(currentParams);
    };

    const handleTagFilters = (tags: TagModel[]) => {
        const currentParams = Object.fromEntries(new URLSearchParams(location.search));
        currentParams["tags"] = tags.length > 0 ? tags.map(t => t.id).join(",") : "";

        updateSearchParams(currentParams);
    };

    const handleFiltersRemove = (filterKey: string, valueHash: string) => {
        const newFilters = { ...filters };
        if (newFilters[filterKey]) {
            const currentFilterValues = newFilters[filterKey].values.filter(
                ({ value, valueTo }) => valueHash !== value + "x" + valueTo,
            );
            const currentParams = Object.fromEntries(new URLSearchParams(location.search));
            if (currentFilterValues.length === 0) {
                delete newFilters[filterKey];
                delete currentParams[filterKey];
            } else {
                newFilters[filterKey].values = currentFilterValues;
                currentParams[filterKey] = JSON.stringify(
                    currentFilterValues.map(({ value }) => value),
                );
            }
            setFilters(newFilters);
            updateSearchParams(currentParams);
        }
    };

    const handleMtgFilters = (filter: FiltersShape) => {
        const newFilter = mergeFilters(filters, filter);

        const currentParams = Object.fromEntries(new URLSearchParams(location.search));
        Object.keys(newFilter).forEach(key => {
            const currentParamValue = [];
            newFilter[key].values.forEach(({ value }) => {
                currentParamValue.push(value ? `${value}` : "N");
            });
            currentParams[key] = JSON.stringify(currentParamValue);
        });

        updateSearchParams(currentParams);
    };

    // get student forms
    useEffect(() => {
        if (yearGroup && cohort) {
            get(
                apiUrl(
                    `school/${getSchoolAccountId()}/analysis/student-report/${cohort}/${yearGroup}/student-forms/`,
                ),
                undefined,
                baseHeaders(),
            ).subscribe(res => res.response && setStudentForms(res.response));
            get(
                apiUrl(
                    `school/${getSchoolAccountId()}/analysis/student-report/${cohort}/${yearGroup}/student-academic-houses/`,
                ),
                undefined,
                baseHeaders(),
            ).subscribe(res => res.response && setStudentAcademicHouses(res.response));
        }
    }, [yearGroup, cohort]);

    const mtgColumns = flatHeader
        ? flatHeader.map(fh => ({
              id: fh.field,
              name: fh.name || "",
          }))
        : [];

    const getMtgDisplayValue = (_option, values) =>
        (
            <>
                {values.map((v, i) => {
                    const mtgOption = getInitialMtgOptions(t).find(option =>
                        `${v}` === "N" ? option.value === null : `${option.value}` === `${v}`,
                    );

                    if (mtgOption) {
                        return (
                            <span key={v} style={{ color: mtgOption.colour }}>
                                {mtgOption.label}
                                {i < values.length - 1 ? ", " : ""}
                            </span>
                        );
                    } else {
                        return "";
                    }
                })}
            </>
        ) as ReactElement;

    // handle initial filters from search params
    useEffect(() => {
        const currentParams = Object.fromEntries(new URLSearchParams(location.search));

        const getParamData = (paramKey: string): any[] => {
            let paramData = [];
            try {
                paramData = JSON.parse(currentParams[paramKey]);
                if (!Array.isArray(paramData)) {
                    throw new Error("Parameter is not an array!");
                }
            } catch {
                //needed for backward compatibility
                paramData = [currentParams[paramKey]];
            }
            return paramData || [];
        };

        const newFilters = { ...filters };
        if (studentParams && studentParams.length > 0) {
            Object.keys(currentParams).forEach(paramKey => {
                if (studentValueMinMaxFilters.includes(paramKey)) {
                    newFilters[paramKey] = {
                        type: "student",
                        attribute: paramKey,
                        attributeDisplay: t(`tracker.grid.configureColumns.` + paramKey),
                        values: getParamData(paramKey).map(v => {
                            const minValue = v.split("|")[0] || "";
                            const maxValue = v.split("|")[1] || "";
                            return {
                                value: minValue,
                                valueTo: maxValue,
                                valueDisplay: rangeValueDisplay(t, minValue, maxValue),
                            };
                        }),
                    };
                } else if (studentParams.includes(paramKey)) {
                    newFilters[paramKey] = {
                        type: "student",
                        attribute: paramKey,
                        attributeDisplay: t(`tracker.grid.configureColumns.` + paramKey),
                        values: getParamData(paramKey).map(v => ({
                            value: v,
                            valueDisplay: v === "no-data" ? t("common.noData") : v,
                        })),
                    };
                }
            });
        }

        if (analysisType === "ks4") {
            const excludedParams = [
                "dataType",
                "viewGrade",
                "viewType",
                "dataSource1",
                "dataSource2",
                "snapshotDataSource",
                "tags",
            ];
            Object.keys(currentParams).forEach(paramKey => {
                if (
                    !excludedParams.includes(paramKey) &&
                    studentParams &&
                    !studentParams.includes(paramKey)
                ) {
                    if (paramKey.includes("computed")) {
                        newFilters[paramKey] = {
                            type: "summary",
                            attribute: paramKey,
                            attributeDisplay: `${
                                summaryColumns.find(o => o && o.hash === paramKey).name
                            }`,
                            values: getParamData(paramKey).map(v => {
                                const minValue = v.split("|")[0] || "";
                                const maxValue = v.split("|")[1] || "";

                                if (minValue === "no-data") {
                                    return {
                                        value: minValue,
                                        valueTo: undefined,
                                        valueDisplay: t("common.noData"),
                                    };
                                }
                                return {
                                    value: minValue,
                                    valueTo: maxValue,
                                    valueDisplay:
                                        (minValue !== ""
                                            ? `<span>${t("common.filterMin")}:</span> ` + minValue
                                            : "") +
                                        (minValue !== "" && maxValue !== "" ? ", " : "") +
                                        (maxValue !== ""
                                            ? `<span>${t("common.filterMax")}:</span> ` + maxValue
                                            : ""),
                                };
                            }),
                        };
                    } else {
                        newFilters[paramKey] = {
                            type: "KS4",
                            attribute: paramKey,
                            attributeDisplay:
                                flatHeader.find(fh => fh.field === paramKey)?.name || "",
                            values: getParamData(paramKey).map(v => {
                                const minValue = v.split("|")[0];
                                const maxValue = v.split("|")[1] || undefined;

                                if (minValue === "no-data") {
                                    return {
                                        value: minValue,
                                        valueTo: undefined,
                                        valueDisplay: t("common.noData"),
                                    };
                                }

                                return {
                                    value: minValue,
                                    valueTo: maxValue,
                                    valueDisplay:
                                        (minValue !== ""
                                            ? "<span>" +
                                              t("common.filterMin") +
                                              "</span>: " +
                                              minValue
                                            : "") +
                                        (maxValue !== ""
                                            ? `${maxValue ? "," : ""} <span>${t(
                                                  "common.filterMax",
                                              )}:</span> ` + maxValue
                                            : ""),
                                };
                            }),
                        };
                    }
                }
            });
        }

        if (analysisType === "grades") {
            Object.keys(currentParams).forEach(paramKey => {
                let columnName = flatHeader.find(fh => fh.field === paramKey)?.name || "";
                if (paramKey === "ALL_GRADES") {
                    columnName = t("common.filterAllSpecifications");
                }
                if (columnName) {
                    const gradeTypeId = columns[paramKey] ? columns[paramKey].gradeTypeId : null;
                    const grades =
                        (gradeTypeId &&
                            relatedData &&
                            relatedData.gradeTypes &&
                            relatedData.gradeTypes.find(gradeType => gradeType.id === gradeTypeId)
                                ?.courseGrades) ||
                        [];

                    newFilters[paramKey] = {
                        type: "grade",
                        attribute: paramKey,
                        attributeDisplay: columnName,
                        values: getParamData(paramKey).map(v => {
                            const minValue = v.split("|")[0];
                            const maxValue = v.split("|")[1] || undefined;

                            if (minValue === "no-data") {
                                return {
                                    value: minValue,
                                    valueTo: undefined,
                                    valueDisplay: t("common.noData"),
                                };
                            }

                            const gradesMin =
                                minValue === "-1"
                                    ? "U"
                                    : grades?.find(g => g.id === parseInt(minValue))?.name || "";
                            const gradesMax =
                                maxValue === "-1"
                                    ? "U"
                                    : maxValue
                                      ? grades?.find(g => g.id === parseInt(maxValue))?.name
                                      : "";
                            return {
                                value: minValue,
                                valueTo: maxValue,
                                valueDisplay:
                                    (gradesMin !== ""
                                        ? "<span>" + t("common.filterMin") + "</span>: " + gradesMin
                                        : "") +
                                    (gradesMax !== ""
                                        ? `${gradesMin ? "," : ""} <span>${t(
                                              "common.filterMax",
                                          )}:</span> ` + gradesMax
                                        : ""),
                            };
                        }),
                    };
                }
            });
        }

        if (context?.tags?.length > 0) {
            Object.keys(currentParams).forEach(paramKey => {
                if (paramKey === "tags") {
                    const selectedTags = context?.tags?.filter(t =>
                        currentParams["tags"].includes(`${t.id}`),
                    );
                    newFilters[paramKey] = {
                        type: "tags",
                        attribute: "tags",
                        attributeDisplay: t("common.selectedTags"),
                        values: [
                            {
                                value: selectedTags?.map(t => t.id).join(","),
                                valueDisplay: selectedTags?.map(t => t.name).join(", "),
                            },
                        ],
                    };
                }
            });
        }

        if (mtgColumns && mtgColumns.length > 0) {
            Object.keys(currentParams).forEach(paramKey => {
                if (paramKey.indexOf("mtg-") === 0) {
                    const option = mtgColumns.find(o => o && o.id === paramKey.replace("mtg-", ""));
                    if (option) {
                        newFilters[paramKey] = {
                            type: "mtg",
                            attribute: paramKey,
                            attributeDisplay: option.name,
                            values: getParamData(paramKey).map(v => ({
                                value: v,
                                valueDisplay: getMtgDisplayValue(option, v.split(",")),
                            })),
                        };
                    }
                }
            });
        }
        if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
            setFilters(newFilters);
        }
    }, [studentParams, filters, location, t]);

    useEffect(() => {
        if (context.studentColumns) {
            setStudentParams(getAvailableStudentParamsBuColumns(context.studentColumns));
        }
    }, [context, studentParams.length]);

    useEffect(() => {
        handleFiltersChange(debouncedFilters);
    }, [debouncedFilters, handleFiltersChange]);

    return (
        <>
            <Box px={5} pt={5} pb={1}>
                <Box mb={3}>
                    <RadioGroup
                        onChange={(_, value) => setFilterTypeRadio(value as GridFilterTypes)}
                        value={filterTypeRadio}
                        name="filter-types"
                        row
                    >
                        <FormControlLabel
                            value={GridFilterTypes.STUDENT}
                            control={<Radio />}
                            label={t("common.filterByStudent")}
                        />
                        {analysisType === "grades" || analysisType === "ks4" ? (
                            <FormControlLabel
                                value={GridFilterTypes.GRADE}
                                control={<Radio />}
                                label={
                                    analysisType === "ks4"
                                        ? t("common.filterBySpecification")
                                        : t("common.filterByGrade")
                                }
                            />
                        ) : (
                            <></>
                        )}
                        {(analysisType === "grades" || analysisType === "ks4") && (
                            <FormControlLabel
                                value={GridFilterTypes.MTG}
                                control={<Radio />}
                                label={t("common.filterByMTG")}
                            />
                        )}
                        <FormControlLabel
                            value={GridFilterTypes.TAG}
                            control={<Radio />}
                            label={t("common.filterByTag")}
                        />
                        {analysisType === "ks4" ? (
                            <FormControlLabel
                                value={GridFilterTypes.SUMMARY}
                                control={<Radio />}
                                label={t("common.filterBySummary")}
                            />
                        ) : (
                            <></>
                        )}
                    </RadioGroup>
                </Box>

                {filterTypeRadio === GridFilterTypes.STUDENT && (
                    <StudentFilter
                        studentParams={studentParams}
                        studentForms={studentForms}
                        studentAcademicHouses={studentAcademicHouses}
                        excludedParams={excludedStudentParams}
                        handleFiltersChange={handleFilters}
                    />
                )}
                {filterTypeRadio === GridFilterTypes.GRADE && analysisType === "ks4" && (
                    <KS4Filter handleFiltersChange={handleFilters} specifications={flatHeader} />
                )}
                {filterTypeRadio === GridFilterTypes.SUMMARY && (
                    <SummaryFilter handleFiltersChange={handleFilters} columns={summaryColumns} />
                )}
                {filterTypeRadio === GridFilterTypes.GRADE && analysisType === "grades" && (
                    <AnalysisGradeFilter
                        handleFiltersChange={handleFilters}
                        specifications={flatHeader}
                        relatedData={relatedData}
                        columns={columns}
                    />
                )}
                {filterTypeRadio === GridFilterTypes.MTG && mtgColumns && (
                    <MTGFilter handleFiltersChange={handleMtgFilters} columns={mtgColumns} />
                )}
                {filterTypeRadio === GridFilterTypes.TAG && (
                    <TagFilter handleTagFiltersChange={handleTagFilters} tags={context.tags} />
                )}
            </Box>
            {filters && Object.keys(filters).length > 0 && (
                <>
                    <Divider />
                    <Box px={5} pb={5}>
                        <Typography variant="h6" gutterBottom>
                            {t("common.filterExistingFilters")}
                        </Typography>
                        <GridFilterSummary
                            filters={filters}
                            handleFiltersRemove={handleFiltersRemove}
                        />
                    </Box>
                </>
            )}
        </>
    );
};

export default AnalysisFilterPanel;
