import React, { createContext, Dispatch, useContext } from "react";
import { Storage } from "src/services/storage";

const AuthStateContext = createContext({});
const AuthDispatchContext = createContext({});

interface AuthState {
    token: string | null;
    loggedInAs: boolean;
}

const initialState: AuthState = {
    token: null,
    loggedInAs: false,
};

export const AuthActionTypes = {
    SET_TOKEN: "setToken",
    SET_LOGGED_IN_AS: "setLoggedInAs",
    CLEAR_AUTH_CONTEXT: "clearAuthContext",
};

const authReducer = (state, action) => {
    switch (action.type) {
        case AuthActionTypes.SET_LOGGED_IN_AS: {
            return { ...state, loggedInAs: action.payload };
        }
        case AuthActionTypes.SET_TOKEN: {
            return { ...state, token: action.payload };
        }
        case AuthActionTypes.CLEAR_AUTH_CONTEXT: {
            return {
                ...state,
                token: null,
                schoolAccountId: null,
                loggedInAs: false,
                trustId: null,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const AuthStateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(authReducer, initialState);

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};

const useAuthState = () => {
    const authState = React.useContext(AuthStateContext);

    if (authState === undefined) {
        throw new Error("useAuthState must be used within a AuthProvider");
    }

    return authState;
};

const useAuthStateDispatch = () => {
    const dispatch = useContext(AuthDispatchContext);

    if (dispatch === undefined) {
        throw new Error("useAuthStateDispatch must be used within a AuthStateProvider");
    }

    return dispatch;
};

const useAuthContext = () => {
    const dispatch = useAuthStateDispatch() as Dispatch<any>;
    const authState = useContext(AuthStateContext);

    const setLoggedInAs = (loggedInAs: boolean) => {
        dispatch({
            type: AuthActionTypes.SET_LOGGED_IN_AS,
            payload: loggedInAs,
        });
    };

    const setToken = (token: string) => {
        Storage.setItem("token", token);
        dispatch({
            type: AuthActionTypes.SET_TOKEN,
            payload: token,
        });
    };

    const clearAuthContext = () => {
        Storage.removeItem("token");
        Storage.removeItem("schoolAccountId");
        Storage.removeItem("trustId");
        dispatch({
            type: AuthActionTypes.CLEAR_AUTH_CONTEXT,
        });
    };

    return {
        setToken,
        clearAuthContext,
        setLoggedInAs,
        authState,
    } as {
        setToken: (token: string) => void;
        clearAuthContext: () => void;
        authState: AuthState;
        setLoggedInAs: (loggedInAs: boolean) => void;
    };
};

export { AuthStateProvider, useAuthState, useAuthStateDispatch, useAuthContext };
