import { apiUrl } from "src/config/globals";
import { usePrevious } from "src/hooks/usePrevious";
import { isArraysEqual } from "src/services/array";
import { useState } from "react";
import { useObservable } from "rxjs-hooks";
import { withLatestFrom, tap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { baseHeaders } from "src/services/ajax";
import { getSchoolAccountId } from "src/services/url";

export interface SubscriptionValidatorRequest {
    period?: number;
    financeManager?: {
        firstName: string;
        lastName: string;
        email: string;
        title: string;
    };
    couponName?: string;
    subjectArea?: { id: number };
    qualifications?: { id: number }[];
    length?: number;
    type?: string;
    isAnnualy?: boolean;
}

export interface SubscriptionValidatorResult {
    price: string;
    subscriptionsRebate?: string;
    couponRebate: string | null;
    isFinanceManager: boolean;
    vatValue?: string | null;
}

export interface SubscriptionValidatorResponse {
    result: SubscriptionValidatorResult | null;
    error: string | null;
}

export enum SubscriptionValidTypes {
    UPRGADE_TO_WHOLESCHOOL = "upgrade-to-whole-school",
    UPGRADE = "upgrade",
    EXTEND = "extend",
    BUY_AFTER_TRIAL = "buy-after-trial",
    CREATE = "create",
}

export const useSubscriptionValid = (
    type: SubscriptionValidTypes,
    formValues: SubscriptionValidatorRequest,
    subscriptionId?: number,
    required?: string[],
    loadOnInit?: boolean,
): SubscriptionValidatorResponse => {
    const [result, setResult] = useState<SubscriptionValidatorResult | null>(null);
    const [error, setError] = useState<string | null>(null);
    const {
        period,
        financeManager,
        couponName,
        qualifications,
        length,
        isAnnualy,
        type: formType,
        subjectArea,
    } = formValues;

    const prevError = usePrevious(error);
    let shouldLoad = true;

    if (required && required.length > 0) {
        required.forEach(el => {
            if (
                formValues[el] === undefined ||
                formValues[el] === null ||
                !formValues[el] ||
                (Array.isArray(formValues[el]) && formValues[el].length === 0)
            ) {
                shouldLoad = false;
            }
        });
    }

    const prevValues = usePrevious([
        period,
        financeManager,
        couponName,
        qualifications,
        length,
        subjectArea,
        isAnnualy,
        formType,
    ]);

    if (
        isArraysEqual(prevValues, [
            period,
            financeManager,
            couponName,
            qualifications,
            length,
            subjectArea,
            isAnnualy,
            formType,
        ])
    ) {
        shouldLoad = false;
    }

    useObservable(
        inputs$ => {
            const getValidationResult = value => {
                const [
                    type,
                    period,
                    couponName,
                    qualifications,
                    subjectArea,
                    length,
                    isAnnualy,
                    financeManager,
                    formType,
                    shouldLoad,
                ] = value;

                if (shouldLoad) {
                    ajax.post(
                        subscriptionId
                            ? apiUrl(
                                  `school/${getSchoolAccountId()}/subscriptions/${subscriptionId}/${type}/valid/`,
                              )
                            : apiUrl(`school/${getSchoolAccountId()}/subscriptions/${type}/valid/`),
                        {
                            period,
                            couponName: couponName ? couponName : null,
                            qualifications,
                            length,
                            isAnnualy,
                            type: formType || undefined,
                            subjectArea:
                                subjectArea && subjectArea > 0 ? { id: subjectArea } : undefined,
                            financeManager,
                        },
                        baseHeaders(),
                    )
                        .toPromise()
                        .then(res => setResult(res.response))
                        .catch(err => {
                            if (err.response.error && prevError !== err.response.error) {
                                setError(err.response.error);
                            }
                        });
                }
            };

            return inputs$.pipe(
                tap(() => setError(null)),
                withLatestFrom(value => {
                    getValidationResult(value);
                }),
            ) as any;
        },
        null,
        [
            type,
            period,
            couponName,
            qualifications,
            subjectArea,
            length,
            isAnnualy,
            financeManager,
            formType,
            loadOnInit || shouldLoad,
        ],
    );

    return { result: result, error: error };
};
