/* eslint-disable @typescript-eslint/no-explicit-any */

import { Formik, FormikConfig, FormikProps } from "formik";
import { forwardRef, useImperativeHandle } from "react";

function FormikRef(props: FormikConfig<any>, ref) {
    let _formikProps: FormikProps<any>;

    useImperativeHandle(ref, () => _formikProps);

    return (
        <Formik {...props}>
            {formikProps => {
                _formikProps = formikProps;
                if (typeof props.children === "function") {
                    return props.children(formikProps);
                }
                return props.children;
            }}
        </Formik>
    );
}

export default forwardRef(FormikRef);
