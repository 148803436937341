import { ApiData } from "src/api/constants";
import { shouldLoadData } from "src/api/helpers";
import { subjectAreaSelector } from "src/modules/common/selectors/SubjectAreaSelectors";
import { CommonActions } from "src/modules/common/store/actions";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { AppState } from "src/store/reducers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import HeadingCounter from "src/components/HeadingCounter";
import RHFMultipleSelect from "src/components/hookFormComponents/RHFMultipleSelect";
import { useFormContext } from "react-hook-form";

const dispatchActions = (dispatch: Dispatch) => ({
    getSubjectAreaList: () => {
        dispatch(CommonActions.getSubjectAreaList());
    },
});

type MemberOrNaht = "naht" | "member";

const SubjectAreaStep = () => {
    const { getValues, setValue } = useFormContext();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const hasBothPromo = getValues("school")?.enableNaht && getValues("school")?.isMember;
    const [selectedMemberOrNaht, setSelectedMemberOrNaht] = useState<MemberOrNaht | null>(
        hasBothPromo ? "member" : null,
    );

    useEffect(() => {
        setValue("promoType", selectedMemberOrNaht);
        setValue("nahtSupported", []);
        setValue("pixlSupported", []);
    }, [selectedMemberOrNaht]);

    const {
        apiList,
        subjectAreas,
    }: {
        apiList: ApiData;
        subjectAreas: OrmSubjectArea[];
    } = useSelector(
        (state: AppState) => ({
            apiList: state.api.common.getSubjectAreaList,
            subjectAreas: subjectAreaSelector(state),
        }),
        shallowEqual,
    );
    const { getSubjectAreaList } = dispatchActions(dispatch);

    useEffect(() => {
        if (shouldLoadData(apiList)) getSubjectAreaList();
    }, [apiList, getSubjectAreaList]);

    useEffect(() => {
        if (subjectAreas.length > 0 && getValues("school")?.isMember) {
            const math = subjectAreas.find(sa => sa.code === "Ma");
            const eng = subjectAreas.find(sa => sa.code === "En");
            setValue("pixlSupported", [{ ...math }, { ...eng }]);
        }
    }, [subjectAreas]);

    const trialAvailableOptions = [...subjectAreas].filter(
        sa =>
            !getValues("pixlSupported")
                .map(gs => gs.id)
                .includes(sa.id) &&
            !getValues("nahtSupported")
                .map(gs => gs.id)
                .includes(sa.id),
    );

    return (
        <>
            <Box mb={2.5}>
                {/* <Typography>{t("auth.register.subjectAreaStep.fieldLabel")}</Typography> */}
                {getValues("subjectAreas") && getValues("subjectAreas").length === 1 && (
                    <Typography>{t("auth.register.subjectAreaStep.fieldLabelHint")}</Typography>
                )}
            </Box>
            {hasBothPromo ? (
                <>
                    <HeadingCounter number="1">
                        {t("auth.register.subjectAreaStep.promoSubscriptionsHeading")}
                    </HeadingCounter>
                    <RadioGroup
                        defaultValue="member"
                        name="radio-buttons-group"
                        onChange={(e, value) => setSelectedMemberOrNaht(value as MemberOrNaht)}
                    >
                        <FormControlLabel
                            value="member"
                            control={<Radio />}
                            label={t("auth.register.subjectAreaStep.pixlHeading")}
                        />
                        <FormControlLabel
                            value="naht"
                            control={<Radio />}
                            label={t("auth.register.subjectAreaStep.nahtHeading")}
                        />
                    </RadioGroup>
                </>
            ) : (
                <></>
            )}
            {getValues("school")?.isMember &&
            ((!hasBothPromo && selectedMemberOrNaht === null) ||
                (hasBothPromo && selectedMemberOrNaht === "member")) ? (
                <>
                    {!hasBothPromo && (
                        <HeadingCounter number="1">
                            {t("auth.register.subjectAreaStep.pixlHeading")}
                        </HeadingCounter>
                    )}
                    <br />
                    <Box>
                        <strong>{t("auth.register.subjectAreaStep.pixlHint1")}</strong>
                    </Box>
                    <br />
                    <Box
                        sx={{ lineHeight: 1.6 }}
                        dangerouslySetInnerHTML={{
                            __html: t("auth.register.subjectAreaStep.pixlHint2"),
                        }}
                    ></Box>
                    <Box sx={{ lineHeight: 1.6, mb: 3 }}>
                        <i>{t("auth.register.subjectAreaStep.pixlValid")}</i>
                    </Box>
                    <RHFMultipleSelect
                        name="pixlSupported"
                        placeholder={t("auth.register.subjectAreaStep.fieldPlaceholder")}
                        label=" "
                        disabled={getValues("pixlSupported").length >= 2}
                        hideSelect={true}
                        disableDelete={true}
                        options={subjectAreas}
                        variant={"outlined"}
                    />
                    <Box mx={-10}>
                        <Divider />
                    </Box>
                </>
            ) : getValues("school")?.enableNaht &&
              ((!hasBothPromo && selectedMemberOrNaht === null) ||
                  (hasBothPromo && selectedMemberOrNaht === "naht")) ? (
                <>
                    {!hasBothPromo && (
                        <HeadingCounter number="1">
                            {t("auth.register.subjectAreaStep.nahtHeading")}
                        </HeadingCounter>
                    )}
                    <br />
                    <Box
                        sx={{ lineHeight: 1.6, mb: 3 }}
                        dangerouslySetInnerHTML={{
                            __html: t("auth.register.subjectAreaStep.nahtHint"),
                        }}
                    ></Box>
                    <RHFMultipleSelect
                        name="nahtSupported"
                        placeholder={t("auth.register.subjectAreaStep.fieldPlaceholder")}
                        label=" "
                        disabled={getValues("nahtSupported").length >= 2}
                        options={subjectAreas}
                        variant={"outlined"}
                    />
                    <Box mx={-10}>
                        <Divider />
                    </Box>
                </>
            ) : (
                <></>
            )}
            <HeadingCounter number={getValues("school")?.isMember ? "2" : "1"}>
                {t("auth.register.subjectAreaStep.trialHeading")}
            </HeadingCounter>
            <Box
                sx={{ lineHeight: 1.6, mb: 3 }}
                dangerouslySetInnerHTML={{ __html: t("auth.register.subjectAreaStep.trialHint") }}
            ></Box>
            <RHFMultipleSelect
                name="subjectAreas"
                placeholder={t("auth.register.subjectAreaStep.fieldPlaceholder")}
                label=""
                options={trialAvailableOptions}
                variant="standard"
            />
        </>
    );
};

export default SubjectAreaStep;
