import { mdiCheck, mdiClose, mdiLinkOff } from "@mdi/js";
import {
    Box,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Typography,
    Chip,
    Divider,
} from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";

export interface ClassTileHeaderItem {
    subjectArea: {
        parentName: string;
        colour: string;
        id: number;
        name: string;
    };
    qualification: {
        id: number;
        name: string;
    };
    specification: {
        parentName: string;
        id: number;
        name: string;
    };
}

export interface ClassTileItem extends ClassTileHeaderItem {
    existingClasses: { id: number; name: string; groupCallStatus: string }[];
    classes: { id: number; name: string }[];
    discontinued?: boolean;
    disabled?: boolean;
}

interface OwnProps {
    fieldName: string;
    item: ClassTileItem;
    handleClassChange: (specificationId: number, classId: number, selected: boolean) => void;
    handleClassChangeAll: (specificationId: number) => void;
}

export const ClassListTileHeader: FC<{ item: ClassTileHeaderItem }> = ({ item }) => {
    return (
        <Box p={3} bgcolor="#f4f5f6" borderBottom={"1px solid #d7dbe3"}>
            <Box mb={2}>
                <Chip
                    style={{ backgroundColor: item.subjectArea.colour }}
                    label={item.subjectArea.name}
                />
            </Box>
            <Typography>
                <strong>{item.qualification.name}</strong> {"> "}
                {item.specification.parentName ? item.specification.parentName + " " : ""}
                {item.specification.name}
            </Typography>
        </Box>
    );
};

const ClassListFormTile: FC<OwnProps> = ({
    item,
    fieldName,
    handleClassChange,
    handleClassChangeAll,
}) => {
    const { t } = useTranslation();
    const { values } = useFormikContext();

    const isAllFromSpecificationSelected = specification => {
        let isSelected = false;

        const classes = values?.[fieldName]?.find(
            g => g.specification.id === specification.id,
        )?.classes;
        if (classes && classes.length > 0) {
            const selectedLen = classes.filter(c => c.selected).length;

            if (selectedLen === classes.length) {
                isSelected = true;
            }
        }

        return isSelected;
    };

    const isChecked = (specificationId, classId) => {
        if (values[fieldName]) {
            const group = values[fieldName].find(g => g.specification.id === specificationId);
            if (group?.classes) {
                return group?.classes?.find(gc => gc.id === classId)?.selected;
            }
        }

        return false;
    };

    return item.subjectArea && item.qualification && item.specification ? (
        <FormGroup>
            <Box height="100%" bgcolor="#f9f9f9">
                <ClassListTileHeader item={item} />
                <Box p={3}>
                    {item.existingClasses && item.existingClasses.length > 0 ? (
                        <Box>
                            {t("common.trackerExists")}
                            <p>{t("common.existingClassesHint")}</p>
                            {item.existingClasses.map(ec => {
                                return (
                                    <FormControlLabel
                                        sx={{ height: 40, width: "100%", marginLeft: 0.5 }}
                                        key={ec.id}
                                        control={<Icon path={mdiCheck} color={COLORS.BLUE_2} />}
                                        label={
                                            <Typography
                                                sx={{ marginLeft: 1, marginRight: 4 }}
                                                variant={"body1"}
                                            >
                                                {ec.name}
                                                <Box component="span" sx={{ margin: 3 }}>
                                                    {ec.groupCallStatus === "manual" ? (
                                                        <Icon path={mdiLinkOff} />
                                                    ) : ec.groupCallStatus === "deleted" ? (
                                                        <Icon path={mdiClose} />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Box>
                                            </Typography>
                                        }
                                    />
                                );
                            })}
                            <Divider />
                        </Box>
                    ) : (
                        <></>
                    )}

                    {item.classes && item.classes.length > 0 ? (
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="selectAll"
                                        value={true}
                                        checked={isAllFromSpecificationSelected(item.specification)}
                                        onChange={() => {
                                            handleClassChangeAll(item.specification.id);
                                        }}
                                    />
                                }
                                label={t("common.selectAll")}
                            />
                        </Box>
                    ) : (
                        <>{t("common.noClassesAvailable")}</>
                    )}

                    {item?.classes?.map(c => (
                        <Box key={c.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={`${fieldName}.${c.id}.selected`}
                                        value={true}
                                        checked={isChecked(item.specification.id, c.id)}
                                        onChange={() => {
                                            handleClassChange(
                                                item.specification.id,
                                                c.id,
                                                !isChecked(item.specification.id, c.id),
                                            );
                                        }}
                                    />
                                }
                                label={c.name}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </FormGroup>
    ) : (
        <></>
    );
};
export default ClassListFormTile;
