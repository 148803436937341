import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { CommonTopicResponse } from "src/modules/common/api/getTopics";

export enum OrmTopicActionTypes {
    BULK_IMPORT = "[orm] Topic BULK IMPORT",
}

export const OrmTopicActions = {
    bulkImport: (payload: { data: CommonTopicResponse; specificationId: number }) =>
        createAction(OrmTopicActionTypes.BULK_IMPORT, payload),
};

export type OrmTopicActions = ActionsUnion<typeof OrmTopicActions>;
