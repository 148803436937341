import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

const apiNewPassword = async (hash: string, password: string): Promise<AxiosResponse> => {
    return await axiosInstance.post(`api/auth/new-password/${hash}/`, { password });
};

export const useNewPassword = (onSuccess?: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    return useMutation({
        mutationFn: ({ hash, password }: { hash: string; password: string }) =>
            apiNewPassword(hash, password),

        onSuccess: () => {
            enqueueSnackbar(t("auth.resetPassword.newPasswordSuccess"), {
                variant: "success",
            });
            if (onSuccess) onSuccess();
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("common.unknownError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
