import { FieldProps } from "formik";

const nullableField = WrappedField => {
    const Nullable = ({ field, form, ...props }: FieldProps) => {
        if (field.value === null) {
            field.value = "";
        }

        field.onChange = e => {
            form.setFieldValue(e.target.name, e.target.value === "" ? null : e.target.value);
        };

        return <WrappedField {...props} field={field} form={form} />;
    };

    Nullable.displayName = `Nullable(${
        WrappedField.displayName || WrappedField.name || "Component"
    })`;

    return Nullable;
};

export default nullableField;
