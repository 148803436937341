import { Reducer } from "redux";
import { NotificationActions, NotificationActionTypes, SnackBarData } from "./actions";

export type SnackbarState = SnackBarData[];

const initialState: SnackbarState = [];

export const reducer: Reducer<SnackbarState, NotificationActions> = (
    state: SnackbarState = initialState,
    action: NotificationActions,
): SnackbarState => {
    switch (action.type) {
        case NotificationActionTypes.ENQUEUE: {
            const snackbar: SnackBarData = {
                ...action.payload,
                key: action.payload.key || new Date().getTime() + Math.random(),
            };
            return [...state, snackbar];
        }
        case NotificationActionTypes.DISMISS:
            return state.map(notification =>
                notification.key === action.payload
                    ? { ...notification, dismissed: true }
                    : { ...notification },
            );
        case NotificationActionTypes.DISMISS_ALL:
            return state.map(notification => ({ ...notification, dismissed: true }));
        case NotificationActionTypes.REMOVE:
            return state.filter(notification => notification.key !== action.payload);
        default:
            return state;
    }
};
