import SpecificationSelectField from "src/forms/SpecificationSelectField";
import SelectedSubjectRow from "../subjectRows/SelectedSubjectRow";
import PaperInner from "src/components/PaperInner";
import {
    Typography,
    Button,
    Grid,
    Radio,
    FormControlLabel,
    Box,
    Checkbox,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Field, useFormikContext } from "formik";
import { TextField, RadioGroup } from "formik-mui";
import { FC } from "react";
import { TFunction } from "i18next";
import { TagAccessLevelEnum, TagStatusEnum } from "../../model/TagModel";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import * as yup from "yup";
import ErrorMessage from "src/forms/ErrorMessage";

export interface TagAddEditFormShape {
    name: string;
    accessLevel: TagAccessLevelEnum;
    status: TagStatusEnum;
    yearGroups: number[];
    allYearGroups: "all" | "selected";
    specifications: {
        subjectAreaId: number;
        qualificationId: number;
        specificationId: number;
    }[];
    currentSpecifications: {
        subjectAreaId: number;
        qualificationId: number;
        specificationId: number;
    } | null;
    allSubjects: "all" | "selected";
}

export const emptyTagForm: TagAddEditFormShape = {
    name: "",
    accessLevel: TagAccessLevelEnum.ALL,
    status: TagStatusEnum.ACTIVE,
    yearGroups: [],
    allYearGroups: "all",
    specifications: [],
    allSubjects: "all",
    currentSpecifications: null,
};

export const addEditTagSchema = (t: TFunction) => {
    return yup.object().shape({
        name: yup.string().required(t("tag.addEdit.nameReq")),
        allYearGroups: yup.string(),
        yearGroups: yup.array().when(
            "allYearGroups",
            ([allYearGroups]) => {
                //TODO this is the way what we have to do it
                return allYearGroups === "selected"
                    ? yup
                          .array()
                          .required(t("tag.addEdit.yearGroupsReq"))
                          .min(1, t("tag.addEdit.yearGroupsReq"))
                    : yup.array().notRequired().nullable();
            },
            /*{
            is: v => {
                
                //    "selected"
                return true;
            },
            then: yup
                .array()
                .required(t("tag.addEdit.yearGroupsReq"))
                .min(1, t("tag.addEdit.yearGroupsReq")),
            otherwise: yup.array().notRequired().nullable(),
        }*/
        ),
        allSubjects: yup.string(),
        specifications: yup.array().when("allSubjects", ([allSubjects]) => {
            return allSubjects === "selected"
                ? yup
                      .array()
                      .required(t("tag.addEdit.specificationsReq"))
                      .min(1, t("tag.addEdit.specificationsReq"))
                : yup.array().notRequired().nullable();
        }),
    });
};

interface OwnProps {
    displayInDialog?: boolean;
}

const TagAddEditForm: FC<OwnProps> = ({ displayInDialog }) => {
    const { t } = useTranslation();
    const { data } = useCoreValues();
    const yearGroups = data?.yearGroups;
    const { values, setFieldValue } = useFormikContext<TagAddEditFormShape>();

    return (
        <Form>
            <PaperInner border="bottom" variant={displayInDialog ? "dialog" : "default"}>
                <Grid container spacing={4}>
                    <Grid item sm={6}>
                        <Field
                            name="name"
                            label={t("tag.addEdit.name")}
                            component={TextField}
                            id="nameField"
                        />
                    </Grid>
                    <Grid item sm={6} />
                    <Grid item sm={6}>
                        <Typography variant="h2" fontSize={14} gutterBottom>
                            {t("tag.addEdit.status")}
                        </Typography>
                        <Field
                            name="status"
                            label="Radio Group"
                            component={RadioGroup}
                            row
                            id="statusField"
                        >
                            {Object.values(TagStatusEnum).map(key => (
                                <FormControlLabel
                                    id={`status-${key}-radio`}
                                    key={key}
                                    value={key}
                                    control={<Radio />}
                                    label={
                                        <>
                                            {t("tag.status." + key)}{" "}
                                            <Typography variant="overline" sx={{ ml: 1 }}>
                                                ({t("tag.status." + key + "Hint")})
                                            </Typography>
                                        </>
                                    }
                                />
                            ))}
                        </Field>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="h2" fontSize={14} gutterBottom>
                            {t("tag.addEdit.accessLevel.header")}
                        </Typography>
                        <Field
                            name="accessLevel"
                            label="Radio Group"
                            component={RadioGroup}
                            row
                            id="accessLevelField"
                        >
                            {Object.values(TagAccessLevelEnum).map(key => (
                                <FormControlLabel
                                    id={`accessLevel-${key}-radio`}
                                    key={key}
                                    value={key}
                                    control={<Radio />}
                                    label={t("tag.addEdit.accessLevel." + key)}
                                />
                            ))}
                        </Field>
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner border="bottom" variant={displayInDialog ? "dialog" : "default"}>
                <Typography variant="h2" fontSize={14} gutterBottom>
                    {t("tag.addEdit.yearGroups")}
                </Typography>
                <Field
                    name="allYearGroups"
                    label="Radio Group"
                    component={RadioGroup}
                    row
                    id="yearGroupsField"
                >
                    <FormControlLabel
                        value={"all"}
                        control={<Radio />}
                        label={t("tag.addEdit.all")}
                        id={`yearGroups-all-radio`}
                    />
                    <FormControlLabel
                        value={"selected"}
                        control={<Radio />}
                        label={t("tag.addEdit.selected")}
                        id={`yearGroups-selected-radio`}
                    />
                </Field>
                {values.allYearGroups !== "all" && (
                    <>
                        <ErrorMessage name="yearGroups" />
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "repeat(4, 1fr)",
                            }}
                        >
                            {yearGroups.map(({ id, name }) => (
                                <FormControlLabel
                                    id={`yearGroups-${id}-checkbox`}
                                    key={id}
                                    value={id}
                                    control={<Checkbox />}
                                    label={name}
                                    checked={values.yearGroups.includes(id)}
                                    onChange={() => {
                                        if (values.yearGroups.includes(id)) {
                                            setFieldValue(
                                                "yearGroups",
                                                values.yearGroups.filter(v => v != id),
                                            );
                                        } else {
                                            setFieldValue("yearGroups", [...values.yearGroups, id]);
                                        }
                                    }}
                                />
                            ))}
                        </Box>
                    </>
                )}
            </PaperInner>
            <PaperInner variant={displayInDialog ? "dialog" : "default"}>
                <Typography variant="h2" fontSize={14} gutterBottom>
                    {t("tag.addEdit.subjects")}
                </Typography>
                <Field
                    name="allSubjects"
                    label="Radio Group"
                    component={RadioGroup}
                    row
                    id="subjects"
                >
                    <FormControlLabel
                        value={"all"}
                        control={<Radio />}
                        label={
                            <>
                                {t("tag.addEdit.all")}
                                <Typography variant="overline" sx={{ ml: 1 }}>
                                    ({t("tag.addEdit.allHint")})
                                </Typography>
                            </>
                        }
                        id={`subjects-all-radio`}
                    />
                    <FormControlLabel
                        value={"selected"}
                        control={<Radio />}
                        label={
                            <>
                                {t("tag.addEdit.selected")}
                                <Typography variant="overline" sx={{ ml: 1 }}>
                                    ({t("tag.addEdit.selectedHint")})
                                </Typography>
                            </>
                        }
                        id={`subjects-selected-radio`}
                    />
                </Field>
                {values.allSubjects !== "all" && (
                    <>
                        <Box display="flex" alignItems="flex-end" mb={3}>
                            <Box flex={1} pr={3}>
                                <Field
                                    id="currentSpecifications"
                                    name={`currentSpecifications`}
                                    key={values.specifications.length}
                                    liveOnly={true}
                                    component={SpecificationSelectField}
                                    showParentSpecification={true}
                                    allowedOnly={true}
                                    handleSpecificationChange={specification => {
                                        setFieldValue("currentSpecifications", {
                                            subjectAreaId: specification.subjectArea,
                                            qualificationId: specification.qualification,
                                            specificationId: specification.id,
                                        });
                                    }}
                                />
                            </Box>
                            <Button
                                onClick={() => {
                                    setFieldValue("currentSpecifications", null);
                                    setFieldValue("specifications", [
                                        ...values.specifications,
                                        values.currentSpecifications,
                                    ]);
                                }}
                                color="primary"
                                disabled={!values.currentSpecifications}
                                id="addSpecificationButton"
                            >
                                {t("tag.addEdit.addSpecification")}
                            </Button>
                        </Box>
                        <ErrorMessage name="specifications" />
                        {values?.specifications?.length > 0 && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("class.addClass.subjectArea")}</TableCell>
                                        <TableCell>{t("class.addClass.qualification")}</TableCell>
                                        <TableCell>{t("class.addClass.specification")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {values.specifications.map(specyfication => (
                                        <SelectedSubjectRow
                                            key={specyfication.specificationId}
                                            {...specyfication}
                                            onDelete={() => {
                                                setFieldValue(
                                                    "specifications",
                                                    values.specifications.filter(
                                                        spec =>
                                                            spec.specificationId !=
                                                            specyfication.specificationId,
                                                    ),
                                                );
                                            }}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </>
                )}
            </PaperInner>
        </Form>
    );
};

export default TagAddEditForm;
