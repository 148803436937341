import { TextField } from "@mui/material";
import { InputHTMLAttributes, useEffect, useState } from "react";

// A debounced input react component
export function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    label,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
    label?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <TextField
            fullWidth
            label={label}
            InputLabelProps={{
                shrink: true,
            }}
            {...(props as any)}
            value={value}
            onChange={e => setValue(e.target.value)}
        />
    );
}
