import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmSubscriptionActions, OrmSubscriptionActionTypes } from "./actions";

export const subscriptionReducer: ModelReducer = {
    [OrmSubscriptionActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmSubscriptionActions.bulkImport>,
        session,
    ) => {
        const { Subscription } = session;
        Subscription.all().delete();
        action.payload.forEach(s => Subscription.create(ormToRef(s, {})));
    },
    [OrmSubscriptionActionTypes.UPSERT]: (
        action: ReturnType<typeof OrmSubscriptionActions.upsert>,
        session,
    ) => {
        const { Subscription } = session;
        Subscription.upsert(
            ormToRef(action.payload, {
                defaults: {},
            }),
        );
    },
};
