import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const validateStudentReportEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/student-report/validate/{hash}/`,
    method: ApiRequests.GET,
};

export interface ValidateStudentReportResponse {
    url: string;
}
