import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { commonSubjectAreasEndpoint, CommonSubjectAreasResponse } from "../../api/subjectAreas";
import {
    allowedSubjectAreasEndpoint,
    AllowedSubjectAreasResponse,
} from "../../api/allowedSubjectAreas";

export enum CommonSubjectAreaActionsTypes {
    GET_SUBJECT_AREA_LIST = "[common] GET SUBJECT AREA LIST",
    GET_SUBJECT_AREA_LIST_SUCCESS = "[common] GET SUBJECT AREA LIST SUCCESS",
    GET_ALLOWED_SUBJECT_AREA_LIST = "[common] GET ALLOWED SUBJECT AREA LIST",
    GET_ALLOWED_SUBJECT_AREA_LIST_SUCCESS = "[common] GET ALLOWED SUBJECT AREA LIST SUCCESS",
    SET_ALLOWED_SUBJECT_AREAS = "[common] SET ALLOWED SUBJECT AREAS",
}

export const CommonSubjectAreaApiEndpoints = {
    getSubjectAreaList: createActionObject(
        commonSubjectAreasEndpoint,
        CommonSubjectAreaActionsTypes.GET_SUBJECT_AREA_LIST_SUCCESS,
    ),
    getAllowedSubjectAreaList: createActionObject(
        allowedSubjectAreasEndpoint,
        CommonSubjectAreaActionsTypes.GET_ALLOWED_SUBJECT_AREA_LIST_SUCCESS,
    ),
};

export const CommonSubjectAreaRawActions = (
    CommonEndpoints: Record<Keys<typeof CommonSubjectAreaApiEndpoints>, ApiActionMeta>,
) => ({
    getSubjectAreaList: () =>
        createApiAction(
            CommonSubjectAreaActionsTypes.GET_SUBJECT_AREA_LIST,
            undefined,
            CommonEndpoints.getSubjectAreaList,
        ),
    getSubjectAreaListSuccess: (payload: ApiCallbackPayload<CommonSubjectAreasResponse>) =>
        createAction(CommonSubjectAreaActionsTypes.GET_SUBJECT_AREA_LIST_SUCCESS, payload),
    getAllowedSubjectAreaList: (hasClasses?: boolean) =>
        createApiAction(
            CommonSubjectAreaActionsTypes.GET_ALLOWED_SUBJECT_AREA_LIST,
            { values: { hasClasses }, params: {} },
            CommonEndpoints.getAllowedSubjectAreaList,
        ),
    getAllowedSubjectAreaListSuccess: (payload: ApiCallbackPayload<AllowedSubjectAreasResponse>) =>
        createAction(CommonSubjectAreaActionsTypes.GET_ALLOWED_SUBJECT_AREA_LIST_SUCCESS, payload),
    setAllowedSubjectAreas: (payload: AllowedSubjectAreasResponse) =>
        createAction(CommonSubjectAreaActionsTypes.SET_ALLOWED_SUBJECT_AREAS, payload),
});
