import { createTheme } from "@mui/material/styles";
import COLORS from "./colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        fontFamilyPrimary: string;
    }

    interface TypographyVariantsOptions {
        fontFamilyPrimary?: string;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        fontFamilyPrimary: true;
    }
}

export const DEFAULT_FONT_FAMILY = '"Open Sans", "Helvetica", "Arial", sans-serif';
export const PRIMARY_FONT_FAMILY = '"Nunito", "Helvetica", "Arial", sans-serif';

export const theme = createTheme({
    typography: {
        fontFamily: DEFAULT_FONT_FAMILY,
        fontFamilyPrimary: PRIMARY_FONT_FAMILY,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        button: {
            fontFamily: DEFAULT_FONT_FAMILY,
            fontSize: "0.875rem",
            fontWeight: 600,
            lineHeight: 1,
        },
        h1: {
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: "1.5rem",
            fontWeight: 700,
        },
        h2: {
            color: COLORS.BLUE_3,
            fontFamily: DEFAULT_FONT_FAMILY,
            fontSize: "1.125rem",
            fontWeight: 600,
            lineHeight: 1.34,
        },
        h3: {
            fontFamily: DEFAULT_FONT_FAMILY,
            fontSize: "1.125rem",
            fontWeight: 600,
            lineHeight: 1.71,
        },
        h4: {
            fontFamily: DEFAULT_FONT_FAMILY,
            fontSize: "0.875rem",
            fontWeight: 700,
            lineHeight: 1.71,
        },
        h5: {
            fontFamily: DEFAULT_FONT_FAMILY,
            fontSize: "0.875rem",
            fontWeight: 600,
            lineHeight: 1.71,
        },
        h6: {
            color: COLORS.BLUE_2,
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: "1.125rem",
            fontWeight: 600,
            lineHeight: 1.34,
        },
        body1: {
            color: COLORS.GREY_1,
            fontSize: "0.875rem",
            lineHeight: 1.71,
        },
        overline: {
            color: COLORS.GREY_3,
            fontSize: "0.688rem",
            fontStyle: "italic",
            lineHeight: 1.63,
            letterSpacing: "normal",
            textTransform: "none",
        },
        subtitle1: {
            color: COLORS.GREY_2,
            fontSize: "0.75rem",
            fontFamily: PRIMARY_FONT_FAMILY,
            fontStyle: "italic",
            fontWeight: 400,
            lineHeight: 1.5,
        },
    },
    palette: {
        contrastThreshold: 3,
        primary: {
            main: COLORS.BLUE_1,
            contrastText: "#fff",
        },
        secondary: {
            main: COLORS.BLUE_2,
        },
        text: {
            primary: COLORS.GREY_1,
            secondary: COLORS.BLUE_3,
            disabled: "rgba(75, 78, 80, 0.54)",
            // hint: "rgba(75, 78, 80, 0.38)",
        },
        divider: "#e8e9eb",
        background: {
            paper: "#fff",
            default: "#f1f3f4",
        },
        action: {
            // active: "rgba(75, 78, 80, 0.54)",
            hover: COLORS.VERY_LIGHT_GREY_1,
            // hoverOpacity: 0.08,
            selected: COLORS.VERY_LIGHT_GREY_2,
            // disabled: "rgba(75, 78, 80, 0.26)",
            // disabledBackground: "rgba(75, 78, 80, 0.12)",
        },
    },
    shape: {
        borderRadius: 2,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1536,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@global": {
                    body: {
                        backgroundColor: COLORS.VERY_LIGHT_GREY_1,
                        fontFamily: DEFAULT_FONT_FAMILY,
                        counterReset: "boxCounter",
                    },
                    a: {
                        color: COLORS.BLUE_3,
                        textDecoration: "none",
                        display: "inline-flex",
                        alignItems: "center",

                        "& > svg": {
                            marginLeft: 8,
                        },
                    },
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: "none",
                    width: 1280,
                },
            },
        },
        MuiAppBar: {
            defaultProps: {
                color: "default",
                elevation: 0,
            },
            styleOverrides: {
                colorDefault: {
                    backgroundColor: COLORS.WHITE,
                    borderBottom: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
                    minWidth: 1280,
                    left: 0,
                    right: "auto",
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 1,
            },
            styleOverrides: {
                root: {
                    "& + &": {
                        borderRadius: "0 0 2px 2px",
                        borderTop: 0,
                    },
                },
                elevation1: {
                    border: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
                    boxShadow: "none",
                },
                elevation8: {
                    boxShadow: "0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)",
                },
                elevation23: {
                    border: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
                    backgroundColor: COLORS.VERY_LIGHT_GREY_4,
                    boxShadow: "none",
                    padding: "34px 48px 48px",
                    borderRadius: "2px 2px 0 0",
                },
                elevation24: {
                    border: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
                    boxShadow: "none",
                    padding: "34px 48px 48px",
                },
            },
        },
        MuiButton: {
            defaultProps: {
                color: "primary",
                variant: "contained",
            },
            styleOverrides: {
                root: {
                    padding: "11px 16px",
                    "& + &": {
                        marginLeft: 8,
                        "@media (max-width: 959px)": {
                            marginLeft: 0,
                            marginTop: 16,
                        },
                    },
                    "& + .MuiButton-text": {
                        marginLeft: 32,
                        "@media (max-width: 959px)": {
                            marginLeft: 0,
                            marginTop: 16,
                        },
                    },
                    ".column-reverse &": {
                        "@media (max-width: 959px)": {
                            marginTop: 16,
                        },
                    },
                    ".column-reverse & + &": {
                        marginLeft: 8,
                        "@media (max-width: 959px)": {
                            marginLeft: 0,
                            marginTop: 0,
                        },
                    },
                    ".column-reverse & + .MuiButton-text": {
                        marginLeft: 32,
                        "@media (max-width: 959px)": {
                            marginLeft: 0,
                            marginTop: 0,
                        },
                    },
                },
                contained: {
                    // backgroundColor: COLORS.LIGHT_GREY_1,
                    // color: COLORS.WHITE,
                    boxShadow: "none",
                    "&:hover": {
                        boxShadow: "none",
                    },
                },
                containedPrimary: {
                    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.20), 0 1px 3px 0 rgba(0,0,0,0.10)",
                },
                containedSecondary: {
                    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.20), 0 1px 3px 0 rgba(0,0,0,0.10)",
                },
                text: {
                    color: COLORS.GREY_2,
                    fontSize: "0.813rem",
                    padding: 0,
                    transition: "all 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "&:hover": {
                        backgroundColor: "transparent",
                        color: COLORS.BLUE_1,
                    },
                },
                textSecondary: {
                    color: COLORS.GREY_2,
                    padding: "7px 13px",
                    "&:hover": {
                        backgroundColor: COLORS.GREEN_1,
                        color: COLORS.WHITE,
                        boxShadow: "0 1px 2px 0 rgba(0,0,0,0.20), 0 1px 3px 0 rgba(0,0,0,0.10)",
                    },
                },
                startIcon: {
                    marginLeft: 0,
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                grouped: {
                    backgroundColor: COLORS.WHITE,
                    borderColor: COLORS.VERY_LIGHT_GREY_3,
                    fontFamily: PRIMARY_FONT_FAMILY,
                    textTransform: "capitalize",
                    padding: "6px 14px",
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: "none",
            },
            styleOverrides: {
                root: {
                    display: "flex",
                },
                marginNormal: {
                    marginTop: 0,
                    marginBottom: 16,
                },
                marginDense: {
                    marginTop: 0,
                    marginBottom: 24,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginRight: 36,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: COLORS.LIGHT_GREY_1,
                    fontSize: "0.875rem",
                    "&.Mui-focused, &.Mui-filled, &.Mui-focused.Mui-error": {
                        color: COLORS.GREY_2,
                    },
                    "&.Mui-error": {
                        color: COLORS.LIGHT_GREY_1,
                    },
                    "&.Mui-disabled, &.Mui-filled.Mui-disabled": {
                        color: COLORS.GREY_3,
                    },
                },
                filled: {
                    color: COLORS.GREY_2,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: -3,
                },
                shrink: {
                    top: 0,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: COLORS.LIGHT_GREY_1,
                    fontFamily: PRIMARY_FONT_FAMILY,
                    fontSize: "0.75rem",
                    fontStyle: "italic",
                    marginTop: 8,
                    lineHeight: 1.33,
                    "&$error": {
                        fontSize: "0.75rem",
                        fontStyle: "normal",
                        marginTop: 8,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: COLORS.GREY_1,
                    fontSize: "0.875rem",
                    "&$disabled": {
                        color: COLORS.GREY_3,
                    },
                    "@media screen and (-webkit-min-device-pixel-ratio:0)": {
                        fontSize: 16,
                    },
                },
                input: {
                    height: "auto",
                    minHeight: "16px",
                    padding: "1px 0 4px",
                    "&:-webkit-autofill": {
                        transitionDelay: "9999s",
                        transitionProperty: "background-color, color",
                        backgroundColor: "transparent !important",
                        fontSize: "0.875rem",
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    "&:before": {
                        borderBottom: `1px solid ${COLORS.LIGHT_GREY_2}`,
                    },
                    "&:hover:not(.Mui-disabled):before": {
                        borderBottom: `1px solid ${COLORS.LIGHT_GREY_1}`,
                    },
                    "&:after": {
                        borderBottom: `1px solid ${COLORS.LIGHT_GREY_1}`,
                    },
                    "&$disabled:before": {
                        borderBottomStyle: "solid",
                        borderBottomColor: COLORS.VERY_LIGHT_GREY_3,
                    },
                    "&$disabled .MuiSelect-icon": {
                        color: COLORS.VERY_LIGHT_GREY_3,
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard",
            },
            styleOverrides: {
                root: {},
            },
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: KeyboardArrowDownIcon,
                variant: "standard",
            },
            styleOverrides: {
                select: {
                    // paddingBottom: 5,
                    "&:focus": {
                        backgroundColor: "transparent",
                    },
                },
                icon: {
                    color: COLORS.LIGHT_GREY_1,
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: "default",
            },
            styleOverrides: {
                root: {
                    color: COLORS.LIGHT_GREY_2,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    "&.Mui-checked": {
                        color: COLORS.BLUE_3,
                    },
                    "&.Mui-checked + .MuiFormControlLabel-label": {
                        fontWeight: 600,
                    },
                    "& .MuiSvgIcon-root": {
                        fontSize: "1.667rem",
                    },
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                color: "default",
            },
            styleOverrides: {
                root: {
                    color: COLORS.LIGHT_GREY_2,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    "&.Mui-checked": {
                        color: COLORS.BLUE_3,
                    },
                    "&.Mui-checked + .MuiFormControlLabel-label": {
                        fontWeight: 600,
                    },
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: "default",
            },
            styleOverrides: {
                thumb: {
                    boxShadow: "0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)",
                },
                track: {
                    opacity: 0.3,
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                horizontal: {
                    border: 0,
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-active": {
                        display: "block",
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: 24,
                    fontSize: "0.75rem",
                    fontWeight: 600,
                    borderRadius: 2,
                    color: COLORS.WHITE,
                    backgroundColor: COLORS.BLUE_3,
                    lineHeight: 1.2,
                },
                colorPrimary: {
                    color: COLORS.WHITE,
                    backgroundColor: COLORS.BLUE_1,
                },
                sizeSmall: {
                    height: 20,
                    fontSize: "0.65rem",
                },
                deleteIcon: {
                    width: 24,
                    height: 24,
                    margin: 0,
                    color: "inherit",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                        color: "inherit",
                    },
                    "& > svg": {
                        width: 16,
                        height: 16,
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    minWidth: 17,
                    height: 17,
                    fontFamily: PRIMARY_FONT_FAMILY,
                    fontWeight: 800,
                    lineHeight: 0.9,
                    padding: "0 4px",
                    top: "50%",
                    transform: "scale(1) translate(calc(100% + 3px), -50%)",
                },
                colorPrimary: {
                    backgroundColor: COLORS.RED_1,
                },
                anchorOriginTopRight: {},
            },
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: "1em",
                },
                h2: {
                    "& + .MuiTypography-subtitle1": {
                        marginTop: 7,
                    },
                },
                h5: {
                    "& + .MuiTypography-subtitle1": {
                        marginTop: 10,
                    },
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                textColor: "inherit",
            },
            styleOverrides: {
                root: {
                    minHeight: 34,
                    borderBottom: `1px solid ${COLORS.LIGHT_GREY_2}`,
                    marginBottom: 32,
                },
                indicator: {
                    backgroundColor: COLORS.GREY_4,
                    height: 4,
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableFocusRipple: true,
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: "4px 16px",
                    justifyContent: "flex-start",
                    maxWidth: "none",
                    minWidth: 0,
                    minHeight: 34,
                    fontFamily: PRIMARY_FONT_FAMILY,
                    textTransform: "none",
                    overflow: "visible",
                    "@media (min-width: 600px)": {
                        fontSize: "0.875rem",
                        minWidth: 0,
                    },
                },
                textColorInherit: {
                    color: COLORS.GREY_3,
                    opacity: 1,
                    "&.Mui-selected": {
                        color: COLORS.GREY_4,
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    "&.classTracker td": {
                        verticalAlign: "middle",
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&$hover:hover": {
                        backgroundColor: COLORS.VERY_LIGHT_GREY_1,
                    },
                },
                hover: {
                    transition: "background-color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${COLORS.LIGHT_GREY_2}`,
                    verticalAlign: "top",
                },
                head: {
                    fontSize: "0.688rem",
                    fontWeight: 600,
                },
                sizeSmall: {
                    padding: "11px 16px 10px",
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    margin: "32px 0",
                    backgroundColor: COLORS.LIGHT_GREY_2,
                },
            },
        },
        // MuiGrid: {
        //     container: {
        //         "&$spacing-xs-4": {
        //             "& $item": {
        //                 paddingTop: 8,
        //                 paddingBottom: 8,
        //             },
        //         },
        //     },
        // },
        // MuiExpansionPanelDetails: {
        //     root: {
        //         flexDirection: "column",
        //     },
        // },
        // MuiExpansionPanelActions: {
        //     root: {
        //         justifyContent: "flex-start",
        //         padding: 24,
        //         borderTop: "1px solid #e8e9eb",
        //     },
        // },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
                PaperProps: {
                    elevation: 0,
                },
            },
            styleOverrides: {
                paper: {
                    // width: "100%",
                    padding: "42px 48px",
                },
            },
        },
        MuiDialogTitle: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    color: COLORS.BLUE_2,
                    fontFamily: PRIMARY_FONT_FAMILY,
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: 1.33,
                    padding: 0,
                    marginBottom: 26,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    overflowY: "visible",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    display: "flex",
                    padding: 0,
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: 32,
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    color: COLORS.GREY_2,
                    fontSize: "0.813rem",
                    lineHeight: 2.7,
                    textTransform: "uppercase",
                    pointerEvents: "none",
                    cursor: "default",
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    outline: "0 none",
                },
            },
        },
        MuiAccordion: {
            defaultProps: {
                square: true,
            },
            styleOverrides: {
                root: {
                    border: 0,
                    borderRadius: 0,
                    margin: 0,
                    "&$expanded": {
                        margin: 0,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    flexDirection: "column",
                    padding: 0,
                },
            },
        },
    },
});
