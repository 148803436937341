import {
    Box,
    Chip,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Menu,
    ListItem,
    ListItemText,
    Theme,
} from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";
import { mdiClose, mdiAccount } from "@mdi/js";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApiStatus } from "src/api/constants";
import Icon from "src/components/Icon";
import { apiUrl, HTTP_NO_CONTENT } from "src/config/globals";
import useDebounce from "src/hooks/useDebounce";
import { useMount } from "src/hooks/useMount";
import { useResponse } from "src/hooks/useResponse";
import { StudentModel } from "src/orm/models/Student";
import { baseHeaders, get } from "src/services/ajax";
import { AppState } from "src/store/reducers";
import { useUserReportValidation } from "../../hooks/useUserReportValidation";
import ReportActionsContainer from "./ReportActionsContainer";
import COLORS from "src/styles/colors";
import { ReportActions } from "../../store/actions";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useCohortSnapshotList } from "src/modules/school/hooks/query/CohortSnapshot/useCohortSnapshotList";
import { getSchoolAccountId } from "src/services/url";

const StyledChip = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: COLORS.GREY_5,
            borderRadius: 14,
            height: theme.spacing(3.5),
            margin: theme.spacing(2, 2, 0, 0),
            padding: theme.spacing(0.375),
            "&:focus": {
                backgroundColor: COLORS.GREY_5,
            },
        },
        icon: {
            backgroundColor: COLORS.VERY_LIGHT_GREY_3,
            color: COLORS.GREY_5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 22,
            height: 22,
            borderRadius: "50%",
            margin: 0,
        },
        label: {
            color: COLORS.WHITE,
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 600,
            padding: theme.spacing(0, 2, 0, 1.5),
        },
    }),
)(Chip);

const dispatchActions = (dispatch: Dispatch) => ({
    getStudentReportByStudent: (
        cohort,
        yearGroup,
        students,
        onlySummary,
        reportTemplate,
        schoolSnapshot: number | null,
    ) => {
        dispatch(
            ReportActions.getStudentReportByStudent(
                cohort,
                yearGroup,
                students,
                onlySummary,
                reportTemplate,
                schoolSnapshot ? { id: schoolSnapshot } : null,
            ),
        );
    },
});

const StudentReportByStudentTab = ({ cohort, yearGroup, reportTemplates }) => {
    const { t } = useTranslation();
    const autocompleteRef = useRef(null) as any;
    const dispatch = useDispatch();
    const [selectedReportTemplate, setSelectedReportTemplate] = useState<number>(0);
    const { enqueueSnackbar } = useSnackbar();
    const { getStudentReportByStudent } = dispatchActions(dispatch);
    const [locked, setLocked] = useState(false);
    const [students, setStudents] = useState([]);
    const [search, setSearch] = useState<string>("");
    const debouncedSearch = useDebounce(search, 500);
    const [selectedStudents, setSelectedStudents] = useState<StudentModel[]>([]);
    const [selectedSnapshot, setSelectedSnapshot] = useState<number | null>(0);
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
    const { apiValidation, checkValidation } = useUserReportValidation();
    const { apiReport } = useSelector(
        (state: AppState) => ({
            apiReport: state.api.report.getStudentReportByStudent,
        }),
        shallowEqual,
    );

    const { data: snapshots, refetch: refetchSnapshots } = useCohortSnapshotList(cohort, {
        onlyCreated: true,
    });

    useEffect(() => {
        if (reportTemplates && reportTemplates.length > 0 && selectedReportTemplate === 0) {
            const defaultTemplate = reportTemplates.find(rt => rt.isDefault);
            setSelectedReportTemplate(defaultTemplate ? defaultTemplate.id : reportTemplates[0].id);
        }
    }, [reportTemplates]);

    useEffect(() => {
        if (debouncedSearch && cohort && yearGroup > -1) {
            get(
                apiUrl(
                    `school/${getSchoolAccountId()}/analysis/common/${cohort}/${yearGroup}/search-students/`,
                ),
                { filter: debouncedSearch },
                baseHeaders(),
            ).subscribe(
                res => {
                    if (res.response) {
                        setIsMenuVisible(true);
                        setStudents(res.response);
                    }
                },
                err => {
                    if (err.message)
                        enqueueSnackbar(err.message, {
                            ...SnackbarErrorOptions,
                        });
                },
            );
        }

        checkValidation();
    }, [cohort, debouncedSearch, yearGroup]);

    useEffect(() => {
        setSelectedStudents([]);
        refetchSnapshots();
    }, [cohort, yearGroup]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (autocompleteRef?.current && !autocompleteRef?.current?.contains(event.target)) {
                setIsMenuVisible(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [autocompleteRef]);

    useMount(() => checkValidation());

    const handleAutocompleteChange = obj => {
        setSelectedStudents(prevState => {
            const newState = [...prevState].filter(s => s.id !== obj.id);
            newState.push(obj);

            return newState;
        });
        setSearch("");
    };

    const handleInputChange = e => {
        setSearch(e.target.value);
    };

    const requestReport = (onlySummary: boolean) => {
        if (selectedStudents && cohort && yearGroup > -1) {
            getStudentReportByStudent(
                cohort,
                yearGroup,
                selectedStudents.map(s => ({ id: s.id })),
                onlySummary,
                selectedReportTemplate || undefined,
                selectedSnapshot || null,
            );
        }
    };

    useEffect(() => {
        if (apiValidation.status === ApiStatus.ERROR && apiReport.status === ApiStatus.ERROR) {
            setLocked(true);
        }
        if (
            apiValidation.status === ApiStatus.SUCCESS &&
            apiValidation.responseStatus === HTTP_NO_CONTENT &&
            apiReport.status === ApiStatus.ERROR
        ) {
            setLocked(false);
        }
    }, [apiReport, apiValidation]);

    useResponse(() => {
        if (
            apiReport.status === ApiStatus.SUCCESS &&
            apiReport.responseStatus === HTTP_NO_CONTENT
        ) {
            setLocked(false);
            enqueueSnackbar(t("report.studentReport.byStudent.requestSuccess"), {
                variant: "success",
            });
        }
        if (apiReport.status === ApiStatus.ERROR) {
            checkValidation();
        }
    }, apiReport);
    const filteredSnapshots = snapshots?.filter(({ isCreated }) => isCreated);
    return (
        <Box pr="9.5%">
            <Typography variant="h6" gutterBottom>
                {t("report.studentReport.byStudent.header")}
            </Typography>
            <Box sx={{ width: "50%" }}>
                <Grid container spacing={4}>
                    <Grid item sm={12}>
                        <Typography>{t("report.studentReport.byStudent.hint")}</Typography>
                    </Grid>
                    {filteredSnapshots?.length > 0 && (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.byStudent.source")}
                                onChange={event =>
                                    setSelectedSnapshot(parseInt(`${event.target.value}`))
                                }
                                fullWidth
                                value={selectedSnapshot}
                                select
                            >
                                <MenuItem key={0} value={0}>
                                    {t("report.studentReport.byStudent.liveTracking")}
                                </MenuItem>
                                {filteredSnapshots.map(s => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}

                    <Grid item sm={8}>
                        <div ref={autocompleteRef}>
                            <TextField
                                label={t("report.studentReport.byStudent.autocompletePlaceholder")}
                                SelectProps={{ displayEmpty: true }}
                                margin="none"
                                name="search"
                                value={search}
                                onChange={handleInputChange}
                            />
                            <Menu
                                anchorEl={autocompleteRef.current}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={isMenuVisible}
                            >
                                {students && students.length > 0 ? (
                                    students.map((student: StudentModel) => (
                                        <MenuItem
                                            key={student.id}
                                            value={student.id}
                                            onClick={() => handleAutocompleteChange(student)}
                                        >
                                            {`${student.firstName} ${student.lastName} (${student.uniquePupilNumber})`}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <ListItem>
                                        <ListItemText primary="No results." />
                                    </ListItem>
                                )}
                            </Menu>
                        </div>
                        {selectedStudents && (
                            <>
                                {selectedStudents.map(student => {
                                    return (
                                        <StyledChip
                                            icon={
                                                <Box component="span" className="MuiChip-icon">
                                                    <Icon path={mdiAccount} />
                                                </Box>
                                            }
                                            style={{ marginRight: 10 }}
                                            key={`selectedStudent-${student.id}`}
                                            label={`${student.firstName} ${student.lastName} (${student.uniquePupilNumber})`}
                                            onDelete={() => {
                                                setSelectedStudents(prevState => {
                                                    return [...prevState].filter(
                                                        s => s.id !== student.id,
                                                    );
                                                });
                                            }}
                                            deleteIcon={
                                                <Box
                                                    component="span"
                                                    className="MuiChip-deleteIcon"
                                                >
                                                    <Icon
                                                        color={COLORS.LIGHT_GREY_3}
                                                        path={mdiClose}
                                                    />
                                                </Box>
                                            }
                                        />
                                    );
                                })}
                            </>
                        )}
                    </Grid>
                    {reportTemplates && reportTemplates.length > 0 ? (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.tabs.reportTemplate")}
                                value={selectedReportTemplate}
                                onChange={e => {
                                    setSelectedReportTemplate(parseInt(e.target.value));
                                }}
                                select
                            >
                                {reportTemplates.map(s => {
                                    return (
                                        <MenuItem value={s.id} key={s.id}>
                                            {s.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid item sm={12}>
                        <ReportActionsContainer
                            studentsCount={selectedStudents.length}
                            refreshReportHandler={() => checkValidation()}
                            requestReportHandler={(onlySummary: boolean) =>
                                requestReport(onlySummary)
                            }
                            showOnlySummaryCheckbox={true}
                            locked={locked}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default StudentReportByStudentTab;
