import { Box, Button, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface OwnProps {
    handleCreateSnapshot: (name: string) => void;
}

const CreateSnapshot: FC<OwnProps> = ({ handleCreateSnapshot }) => {
    const { t } = useTranslation();
    const [tmpName, setTmpName] = useState<string>("");

    return (
        <Box>
            <Grid container>
                <Grid sm={8}>
                    <TextField
                        value={tmpName}
                        onChange={e => setTmpName(e.target.value)}
                        fullWidth
                        label={t("common.snapshotModal.createSnapshotHint")}
                    />
                </Grid>
                <Grid sm={4}>
                    <Box ml={2} mt={0.75}>
                        <Button onClick={() => handleCreateSnapshot(tmpName)} color="primary">
                            {t("common.continue")}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CreateSnapshot;
