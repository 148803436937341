import { Dispatch } from "redux";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { AppState } from "src/store/reducers";
import { ApiData } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";

const dispatchActions = (dispatch: Dispatch) => ({
    moveStudentsToClass: (classTrackerGroupId: number, values) => {
        dispatch(
            TrackerActions.moveStudentsToClass(classTrackerGroupId, {
                studentClassTracker: values,
            }),
        );
    },
});

export interface MoveStudentsToClassRequest {
    student: ListObject<number>;
    classTracker?: ListObject<number>;
    tier?: TiersTypes;
}

export const useStudentMove = (): {
    apiMoveStudents: ApiData;
    moveStudentsToClass: (
        classTrackerGroupId: number,
        values: MoveStudentsToClassRequest[],
    ) => void;
} => {
    const dispatch = useDispatch();

    const { moveStudentsToClass } = dispatchActions(dispatch);

    const { apiMoveStudents }: { apiMoveStudents: ApiData } = useSelector(
        (state: AppState) => ({
            apiMoveStudents: state.api.tracker.moveStudentsToClass,
        }),
        shallowEqual,
    );

    return {
        apiMoveStudents,
        moveStudentsToClass,
    };
};
