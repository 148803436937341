import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

// custom settings - will grow
export interface ClassTrackerUserSettingsObject {
    columnConfig?: {
        [key: string]: boolean;
    };
    expandedColumns?: {
        [key: string]: boolean;
    };
    expandedColumnsH?: {
        [key: string]: boolean;
    };
    expandedColumnsI?: {
        [key: string]: boolean;
    };
    expandedColumnsF?: {
        [key: string]: boolean;
    };
    filters?: string;
    selectedTags?: number[];
    selectedLinks?: number[];
    tagsVisible?: boolean;
    linksVisible?: boolean;
    selectedSeriesId?: number | null;
    tagsOrder?: string[];
}

export const TRACKER_USER_SETTINGS_QUERY = "trackerUserSettingsQuery";

export const useTrackerUserSettings = (classTrackerId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [TRACKER_USER_SETTINGS_QUERY, schoolAccountId, classTrackerId],
        queryFn: async (): Promise<ClassTrackerUserSettingsObject | null> => {
            if (classTrackerId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/user-settings/`,
                );

                return data || null;
            }
            return null;
        },
    });
};
