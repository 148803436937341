import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const CUSTOMISED_ASSESSMENT_GRADE_BOUNDARIES_QUERY =
    "customisedAssessmentGradeBoundariesQuery";

export interface CustomisedAssessmentsGradeBoundary {
    id: number;
    name: string;
    tier: string;
    maxValue: number;
    values: number[];
}

export const useCustomisedAssessmentGradeBoundaries = ({ classTrackerGroupId, tierUnits }) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CUSTOMISED_ASSESSMENT_GRADE_BOUNDARIES_QUERY, schoolAccountId],
        queryFn: async (): Promise<CustomisedAssessmentsGradeBoundary[] | null> => {
            if (classTrackerGroupId && tierUnits.length > 0) {
                const { data } = await axiosInstance.post(
                    `school/${getSchoolAccountId()}/assessments/customised/class-tracker-group/${classTrackerGroupId}/grade-boundary-series/`,
                    { tierUnits },
                );

                return data;
            }
            return null;
        },
    });
};
