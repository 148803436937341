import { useState } from "react";
import Dialog from "src/components/Dialog";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, Button, Grid, Typography, InputLabel, FormControl } from "@mui/material";
import { ForecastUnitList } from "../../api/Forecast/ClassTrackerGroup/getUnitList";

const ClearForecastModal = ({
    open,
    handleClose,
    units,
}: {
    open: boolean;
    handleClose: (confirm: boolean, dataSource?: string) => void;
    units: ForecastUnitList[] | null;
}) => {
    const [selectedUnit, setSelectedUnit] = useState<string>("all");
    const { t }: { t: TFunction } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            title={t("tracker.forecast.clearForecast")}
            actions={
                <>
                    <Button color="primary" onClick={() => handleClose(true, selectedUnit)}>
                        {t("common.continue")}
                    </Button>
                    <Button onClick={() => handleClose(false)}>{t("common.cancel")}</Button>
                </>
            }
        >
            <Typography gutterBottom>{t("tracker.forecast.chooseClearForecast")}</Typography>
            <Grid container spacing={4}>
                <Grid item sm={5}>
                    <FormControl fullWidth>
                        <InputLabel id="dataSource">{t("tracker.forecast.dataSource")}</InputLabel>
                        <Select
                            labelId="dataSource"
                            fullWidth
                            value={selectedUnit}
                            onChange={e => setSelectedUnit(e.target.value as string)}
                        >
                            <MenuItem value="all">
                                <em>{t("tracker.forecast.wholeTreackerOption")}</em>
                            </MenuItem>
                            {units &&
                                units.map((unit, i) => (
                                    <MenuItem key={i} value={unit.unitId}>
                                        {unit.name || unit.unitId}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ClearForecastModal;
