import { OrmTopic } from "src/orm/models/Topic";
import { Input, MenuItem, Select, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CSSProperties, FC, useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    headerTopic: {
        opacity: theme.palette.action.disabledOpacity,
    },
}));

interface OwnProps {
    topics: OrmTopic[];
    i: number;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    personalisedAssesmentQuestions: {
        question: string;
        topics?: number[];
    }[];
    fieldValuePrefix?: string;
    disabled?: boolean;
    className?: string;
    xs?: number;
    style?: CSSProperties;
}
type HeaderTopics = { [id: number]: number[] };

export const AssessmentTopicSelector: FC<OwnProps> = ({
    topics,
    i,
    setFieldValue,
    personalisedAssesmentQuestions,
    fieldValuePrefix = "",
    disabled,
    className,
    xs = 4,
    style,
}) => {
    const classes = useStyles();
    const [headerTopics, setHeaderTopics] = useState<HeaderTopics>({});
    const [selectedHeaderTopics, setSelectedHeaderTopics] = useState<number[]>([]);

    useEffect(() => {
        const topicList = {};
        const hederTopics = topics?.filter(
            ({ lft, lvl, rgt }) => lvl === 0 && rgt && rgt - lft > 1,
        );
        topics
            ?.filter(({ lft, lvl, rgt }) => !(lvl === 0 && rgt && rgt - lft > 1))
            .forEach(subTopic => {
                const hederTopicId = hederTopics?.find(
                    ({ lft, rgt }) => subTopic.lft > lft && subTopic.rgt < rgt,
                )?.id;
                if (hederTopicId) {
                    topicList[hederTopicId] = topicList[hederTopicId]
                        ? [...topicList[hederTopicId], subTopic.id]
                        : [subTopic.id];
                }
            });
        setHeaderTopics(topicList);
    }, [topics]);

    const handleValueChange = (value: number[]) => {
        let finalTopicsList = [...value];
        value
            .filter(topic => headerTopics[topic])
            .forEach(headerTopic => {
                //header topic it is already selected
                if (selectedHeaderTopics.find(v => v === headerTopic)) {
                    setSelectedHeaderTopics(selectedHeaderTopics.filter(v => v !== headerTopic));
                    finalTopicsList = finalTopicsList.filter(
                        v => v !== headerTopic && !headerTopics[headerTopic].includes(v),
                    );
                } else {
                    setSelectedHeaderTopics([...selectedHeaderTopics, headerTopic]);
                    finalTopicsList = [
                        ...finalTopicsList.filter(v => v !== headerTopic),
                        ...headerTopics[headerTopic],
                    ];
                }
            });
        finalTopicsList = [...new Set(finalTopicsList)];
        setFieldValue(
            fieldValuePrefix + `personalisedAssesmentQuestions[${i}.topics]`,
            finalTopicsList,
        );
    };

    if (!(topics && topics.length > 0)) return <></>;

    return (
        <Grid item xs={xs}>
            <Select
                fullWidth
                className={className}
                disabled={disabled}
                multiple
                displayEmpty
                value={personalisedAssesmentQuestions[i]?.topics || []}
                style={{
                    paddingTop: 15,
                    ...style,
                }}
                renderValue={selected => {
                    if (!selected || selected.length === 0) {
                        return "[None]";
                    }
                    return (selected as any)
                        .map(sId => {
                            if (topics) {
                                return topics.find(t => t.id === sId)?.name;
                            }
                            return sId;
                        })
                        .join(", ");
                }}
                onChange={e => {
                    handleValueChange(e.target.value as number[]);
                }}
                input={<Input />}
            >
                {topics.map(topic => {
                    return (
                        <MenuItem
                            className={
                                topic.lvl === 0 && topic.rgt && topic.rgt - topic.lft > 1
                                    ? classes.headerTopic
                                    : null
                            }
                            key={topic.id}
                            value={topic.id}
                        >
                            {topic.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </Grid>
    );
};
