/* eslint-disable react/display-name */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { GridApi } from "ag-grid-community";
import { Checkbox } from "@mui/material";
import { TrackerContext } from "../TrackerGrid";
import { DataTypeTypes } from "../../Common/TierHeaderSelect";

interface OwnProps {
    value: string;
    api: GridApi;
    context: TrackerContext;
    colDef;
    node;
}

const TagDataEditor = forwardRef(
    ({ value: propsValue, api: gridApi, context, colDef, node }: OwnProps, ref) => {
        const isAllSelected = gridApi?.getRenderedNodes()?.reduce((prev, curr) => {
            if (curr.data?.[colDef.field] !== 1 && prev) {
                return false;
            }

            return prev;
        }, true);

        const isWhileUpdate = gridApi?.getRenderedNodes()?.reduce((prev, curr) => {
            if (curr.data?.[colDef.field]?.["whileUpdate"]) {
                return true;
            }

            return prev;
        }, false);

        const [value] = useState(
            node.rowPinned && isWhileUpdate
                ? isAllSelected
                : context.dataType === DataTypeTypes.LIVE
                  ? !propsValue
                  : !!propsValue,
        );

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    if (
                        node.rowPinned &&
                        context?.handleTagsLinksAllColumnSelect &&
                        !isWhileUpdate
                    ) {
                        context.handleTagsLinksAllColumnSelect(
                            colDef.field,
                            isAllSelected ? 0 : 1,
                            "tag",
                        );
                    }
                    return node.rowPinned
                        ? value === true
                            ? 1
                            : 0
                        : {
                              value: value === true ? 1 : 0,
                              whileUpdate: true,
                          };
                },
            };
        });

        useEffect(() => {
            gridApi.stopEditing();
        }, []);

        return (
            <div className="ag-editor-cell">
                <Checkbox
                    disabled={context.dataType !== DataTypeTypes.LIVE}
                    checked={value}
                ></Checkbox>
            </div>
        );
    },
);

export default TagDataEditor;
