import { CellKeyDownEvent, ColumnApi, GridApi } from "ag-grid-community";

export const KEY_LEFT = "ArrowLeft";
export const KEY_UP = "ArrowUp";
export const KEY_RIGHT = "ArrowRight";
export const KEY_DOWN = "ArrowDown";

export const NAV_KEYS = [KEY_LEFT, KEY_DOWN, KEY_RIGHT, KEY_UP];

// custom navigation to next cell
export const navigateToNextCell = (
    event: CellKeyDownEvent,
    gridApi: GridApi,
    columnApi: ColumnApi,
) => {
    const e = event.event as KeyboardEvent;

    if (e && NAV_KEYS.includes(e.code)) {
        const rowIndex = event.rowIndex;
        const colDefField = event.colDef.field;

        const columns = columnApi?.getAllDisplayedColumns().filter(c => {
            const singleColumn = c.getColDef();
            if (singleColumn) {
                return singleColumn.editable;
            }
            return false;
        });
        const currentColIndex = columns.findIndex(c => c.getColDef().field === colDefField);
        const currentRowIndex = rowIndex;

        const isRowEditable = (rowIndex, colIndex) => {
            const row = gridApi.getDisplayedRowAtIndex(rowIndex);
            const isEditable = Object.keys(gridApi.getRowNode(row.id).data).includes(
                columns[colIndex].getColId(),
            );
            return isEditable;
        };
        const getNextColIndex = (
            currRow: number,
            currCol: number,
            rowModificator: number = 0,
            colModificator: number = 0,
        ): { nextRowIndex: number; nextColIndex: number } => {
            //if we try go out of scope
            if (
                currCol >= columns.length ||
                currCol < 0 ||
                currentRowIndex === null ||
                currentRowIndex < 0 ||
                currentRowIndex >= gridApi.getDisplayedRowCount()
            )
                return { nextRowIndex: currentRowIndex || 0, nextColIndex: currentColIndex };
            const nextRowIndex = currRow + rowModificator;
            const nextColIndex = currCol + colModificator;
            if (isRowEditable(nextRowIndex, nextColIndex)) return { nextRowIndex, nextColIndex };
            return getNextColIndex(nextRowIndex, nextColIndex, rowModificator, colModificator);
        };

        const rowModificator = e.code === KEY_UP ? -1 : e.code === KEY_DOWN ? 1 : 0;
        const colModificator = e.code === KEY_LEFT ? -1 : e.code === KEY_RIGHT ? 1 : 0;
        const { nextRowIndex, nextColIndex } = getNextColIndex(
            currentRowIndex,
            currentColIndex,
            rowModificator,
            colModificator,
        );
        gridApi.setFocusedCell(nextRowIndex, columns[nextColIndex].getColId());
        gridApi.startEditingCell({
            rowIndex: nextRowIndex,
            colKey: columns[nextColIndex],
        });
        gridApi.ensureColumnVisible(columns[nextColIndex]);
        gridApi.ensureIndexVisible(nextRowIndex);
    }
};
