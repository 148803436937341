import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { LoginResponse } from "./useLogin";
import { useAuthContext } from "src/context/AuthContext";
import { ROUTE_AUTH_POST_LOGIN } from "src/routes";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const apiLoginAs = async (token: string): Promise<AxiosResponse<LoginResponse>> => {
    return await axiosInstance.post(`api/auth/login-as-user-account/${token}/`);
};

export const useLoginAs = (onError: (errorMessage: string) => void) => {
    const { setToken, setLoggedInAs } = useAuthContext();
    const { navigate } = useSchoolNavigate();

    return useMutation({
        mutationFn: (token: string) => apiLoginAs(token),
        onSuccess: (res: AxiosResponse<LoginResponse>) => {
            setToken(res.data.token);
            setLoggedInAs(true);
            navigate(ROUTE_AUTH_POST_LOGIN);
            navigate(0);
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (onError) onError(err.response.data?.error);
        },
    });
};
