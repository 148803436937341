import FooterTracker from "src/modules/common/components/FooterTracker";
import MobileLock from "src/components/MobileLock";
import { Outlet } from "react-router-dom";

export default function TrackerLayout() {
    return (
        <>
            <Outlet />
            <FooterTracker />
            <MobileLock />
        </>
    );
}
