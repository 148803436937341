import AnalysisHeader from "../components/Header/AnalysisHeader";
import AtlAnalysisGrid, { ATL_VIEW_TYPE } from "../components/Grid/AtlAnalysisGrid";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { usePrevious } from "src/hooks/usePrevious";
import { AnalysisTypes } from "../components/Common/AnalysisTypeModal";
import { useAnalysisSnapshots } from "../hooks/useAnalysisSnapshots";
import { useLocation, useParams } from "react-router";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { SnapshotData } from "src/context/TrackerContext";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useAnalysisUserSettingsApiConnector } from "../hooks/useAnalysisUserSettingsStore";

const AtlAnalysisContainer = () => {
    const location = useLocation();
    const { navigate } = useSchoolNavigate();
    const query = new URLSearchParams(location.search);
    const { cohort: cohortParam, yearGroup: yearGroupParam } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
    const [toolsVisible, setToolsVisible] = useState<boolean>(false);
    const [dataType, setDataType] = useState<DataTypeTypes>(
        (query && (query.get("dataType") as DataTypeTypes)) || DataTypeTypes.LIVE,
    );

    const [snapshotParams, setSnapshotParams] = useState<SnapshotData>(() => {
        const dataSource1 = (query && query.get("dataSource1")) || undefined;
        const dataSource2 = (query && query.get("dataSource2")) || undefined;
        return {
            snapshot: (query && query.get("snapshotDataSource")) || null,
            compare: dataSource1 && dataSource2 ? { dataSource1, dataSource2 } : null,
        };
    });

    const { refetchSnapshots, setSnapshotData } = useAnalysisSnapshots(parseInt(cohortParam || ""));

    const prevCohort = usePrevious(cohortParam);

    const { analysisUserSettings } = useAnalysisUserSettingsApiConnector(
        parseInt(cohortParam || ""),
        parseInt(yearGroupParam || ""),
        ATL_VIEW_TYPE,
    );

    useEffect(() => {
        if (prevCohort) {
            refetchSnapshots();
        }
    }, [cohortParam]);

    useEffect(() => {
        setSnapshotData(snapshotParams);
    }, [snapshotParams]);

    useEffect(() => {
        navigate(
            `${location.pathname}?dataType=${dataType}${
                snapshotParams?.compare?.dataSource1 && snapshotParams?.compare?.dataSource2
                    ? "&dataSource1=" +
                      snapshotParams?.compare?.dataSource1 +
                      "&dataSource2=" +
                      snapshotParams?.compare?.dataSource2
                    : ""
            }${snapshotParams?.snapshot ? "&snapshotDataSource=" + snapshotParams.snapshot : ""}${
                analysisUserSettings?.filters ? "&" + analysisUserSettings?.filters : ""
            }`,
        );
    }, [dataType, snapshotParams]);

    return (
        <Box component="main" height="100vh" pt={0} pb={3} display="flex" flexDirection="column">
            <AnalysisHeader
                cohort={parseInt(`${cohortParam}`)}
                yearGroup={parseInt(`${yearGroupParam}`)}
                analysisType={AnalysisTypes.ATL_ANALYSIS}
                dataType={dataType}
                handleDataTypeChange={dataType => {
                    setDataType(dataType);
                    setFiltersVisible(false);
                    setToolsVisible(false);
                    setSnapshotParams({ snapshot: null, compare: null });
                }}
                handleFiltersVisible={isChecked => {
                    setFiltersVisible(isChecked);
                    if (toolsVisible && isChecked) {
                        setToolsVisible(!isChecked);
                    }
                }}
                handleToolsVisible={isChecked => {
                    setToolsVisible(isChecked);
                    if (filtersVisible && isChecked) {
                        setFiltersVisible(!isChecked);
                    }
                }}
                filtersVisible={filtersVisible}
                toolsVisible={toolsVisible}
                isLoadingGrid={isLoading}
                handleSnapshotParamsChange={setSnapshotParams}
            />
            <AtlAnalysisGrid
                cohort={parseInt(cohortParam || "")}
                yearGroup={parseInt(yearGroupParam || "")}
                filtersVisible={filtersVisible}
                toolsVisible={toolsVisible}
                dataType={dataType}
                handleIsLoading={isLoading => setIsLoading(isLoading)}
            />
        </Box>
    );
};

export default AtlAnalysisContainer;
