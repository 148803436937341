import AuthContainer from "src/components/AuthContainer";
import { Typography, Theme, Box, Button, Step, Stepper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { SchoolUserRole } from "src/orm/models/User";
import { ROUTE_CLASS_CLASS_LIST, ROUTE_SUBSCRIPTION_LIST } from "src/routes";
import { steps } from "../components/Common/steps";
import { CustomConnector, CustomStepLabel, CustomStepIcon } from "../components/Common/Stepper";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getSchoolAccountId } from "src/services/url";

const useStyles = makeStyles((theme: Theme) => ({
    bold: {
        fontWeight: 700,
    },
    stepper: {
        padding: 0,
        margin: theme.spacing(4.5, -2.5, 8.5),
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(4.5, -2, 8.5),
        },
    },
}));

const InvitedContainer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { data: invitedUser } = useProfile();
    const { navigate } = useSchoolNavigate();

    return (
        <AuthContainer>
            <Stepper
                className={classes.stepper}
                activeStep={3}
                alternativeLabel
                connector={<CustomConnector />}
            >
                {steps(t).map(label => (
                    <Step key={label}>
                        <CustomStepLabel StepIconComponent={CustomStepIcon}>
                            {label}
                        </CustomStepLabel>
                    </Step>
                ))}
            </Stepper>
            {invitedUser && invitedUser.userRole === SchoolUserRole.TEACHER && (
                <Fragment>
                    <Typography gutterBottom className={classes.bold}>
                        {t("auth.invite.finishedStep.header")}
                    </Typography>
                    <Typography gutterBottom>
                        {t("auth.invite.finishedStep.letsGetStartedMsg")}
                    </Typography>
                    <Box mb={4.5}>
                        <Button
                            onClick={() =>
                                navigate(
                                    ROUTE_CLASS_CLASS_LIST.replace(
                                        "schoolAccountId",
                                        getSchoolAccountId(),
                                    ),
                                )
                            }
                            color="primary"
                        >
                            {t("auth.invite.finishedStep.letsGetStartedBtn")}
                        </Button>
                    </Box>
                </Fragment>
            )}
            {invitedUser && invitedUser.userRole === SchoolUserRole.FINANCE && (
                <Fragment>
                    <Typography gutterBottom className={classes.bold}>
                        {t("auth.invite.finishedStep.header")}
                    </Typography>
                    <Typography gutterBottom>
                        {t("auth.invite.finishedStep.financeMessage")}
                    </Typography>
                    <Box mb={4.5}>
                        <Button
                            // component={Link}
                            onClick={() =>
                                navigate(
                                    ROUTE_SUBSCRIPTION_LIST.replace(
                                        ":schoolAccountId",
                                        `${invitedUser.school.id}`,
                                    ),
                                )
                            }
                            color="primary"
                        >
                            {t("auth.invite.finishedStep.goToSubscriptionsButton")}
                        </Button>
                    </Box>
                </Fragment>
            )}
        </AuthContainer>
    );
};

export default InvitedContainer;
