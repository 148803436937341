import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SchoolActions, SchoolActionTypes } from "../actions";

const uploadSchoolLogoSuccessEpic = (action$: ActionsObservable<SchoolActions>) =>
    action$.pipe(
        ofType(SchoolActionTypes.UPLOAD_SCHOOL_LOGO_SUCCESS),
        map((action: ReturnType<typeof SchoolActions.uploadSchoolLogoSuccess>) => action.payload),
        mergeMap(payload => of(SchoolActions.setSchoolDetails(payload.response))),
    );

const deleteSchoolLogoSuccessEpic = (action$: ActionsObservable<SchoolActions>) =>
    action$.pipe(
        ofType(SchoolActionTypes.DELETE_SCHOOL_LOGO_SUCCESS),
        map((action: ReturnType<typeof SchoolActions.deleteSchoolLogoSuccess>) => action.payload),
        mergeMap(payload => of(SchoolActions.setSchoolDetails(payload.response))),
    );

export const schoolDetailsEpics = combineEpics(
    uploadSchoolLogoSuccessEpic,
    deleteSchoolLogoSuccessEpic,
);
