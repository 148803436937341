import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface AutoSuggestCreateRequest {
    specificationAndClasses: {
        specification: { id: number };
        classes: { id: number }[];
    }[];
}

const apiAutoSuggestCreate = async (
    sourceYearGroup: number | "",
    targetAcademicYear: number | "",
    values: AutoSuggestCreateRequest,
) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/auto-suggest-trackers/${sourceYearGroup}/${targetAcademicYear}/`,
        values,
    );
};

export const useAutoSuggestCreateMutation = (
    sourceYearGroup: number | "",
    targetAcademicYear: number | "",
    onSuccess,
    onError,
) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: AutoSuggestCreateRequest) =>
            apiAutoSuggestCreate(sourceYearGroup, targetAcademicYear, values),

        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError) {
                onError();
            }
        },
    });
};
