import FormikRef from "src/forms/FormikRef";
import { Form, Field } from "formik";
import { AutocompleteField } from "src/forms/Autocomplete";
import { Button, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SchoolActions } from "../store/actions";
import { Dispatch } from "redux";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { OrmUser } from "src/orm/models/User";
import { usersWithFilterSelector } from "src/modules/user/selectors/UsersSelectors";
import Icon from "src/components/Icon";
import { mdiDelete } from "@mdi/js";
import { createRef } from "react";
import { getSchoolAccountId } from "src/services/url";

const dispatchActions = (dispatch: Dispatch) => ({
    setLeader: (subjectAreaId: number, leaderId: number) => {
        dispatch(SchoolActions.setLeader(subjectAreaId, leaderId));
    },
    setTeacher: (subjectAreaId: number, leaderId: number) => {
        dispatch(SchoolActions.setTeacher(subjectAreaId, leaderId));
    },
    removeLeader: (subjectAreaId: number, leaderId: number) => {
        dispatch(SchoolActions.removeLeader(subjectAreaId, leaderId));
    },
    removeTeacher: (subjectAreaId: number, leaderId: number) => {
        dispatch(SchoolActions.removeTeacher(subjectAreaId, leaderId));
    },
});

const SubjectAreaTeachersList = ({ subjectAreaId, name, adminList, canModify }) => {
    const form = createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { setLeader, setTeacher, removeLeader, removeTeacher } = dispatchActions(dispatch);

    let userIds: number[] = [];
    const {
        subjectAreaUserList,
    }: {
        subjectAreaUserList: { [key: string]: { leaders: number[]; teachers: number[] } };
    } = useSelector(
        (state: AppState) => ({
            subjectAreaUserList: state.school.subjectAreaUserList,
        }),
        shallowEqual,
    );

    const currentUserList = subjectAreaUserList && subjectAreaUserList[`sa-${subjectAreaId}`];
    userIds = (currentUserList && currentUserList[name + "s"]) || [];

    const {
        users,
    }: {
        users: OrmUser[];
    } = useSelector(
        (state: AppState) => ({
            users: usersWithFilterSelector(
                state,
                u => u.id !== undefined && userIds.includes(u.id),
            ),
        }),
        shallowEqual,
    );

    const handleSubmit = (values, { setFieldValue }) => {
        const data = values[`tmpUserAutocomplete-${subjectAreaId}-${name}`];
        if (data && name === "leader") {
            setLeader(subjectAreaId, data.id);
        }
        if (data && name === "teacher") {
            setTeacher(subjectAreaId, data.id);
        }
        setFieldValue(`tmpUserAutocomplete-${subjectAreaId}-${name}`, null);
    };

    const handleRemoveUser = (userId: number) => {
        if (name === "leader") {
            removeLeader(subjectAreaId, userId);
        }
        if (name === "teacher") {
            removeTeacher(subjectAreaId, userId);
        }
    };

    return (
        <FormikRef
            ref={form}
            initialValues={{
                [`tmpUserAutocomplete-${subjectAreaId}-${name}`]: "",
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {formProps => (
                <>
                    {canModify && (
                        <Form>
                            <Box display="flex" alignItems="flex-end" mb={3}>
                                <Box flex={1} pr={3}>
                                    <Field
                                        apiPathName={`school/${getSchoolAccountId()}/subject-area/${subjectAreaId}/teachers-and-leaders/`} // todo
                                        name={`tmpUserAutocomplete-${subjectAreaId}-${name}`}
                                        label={t("school.subjectAreas.search")}
                                        component={AutocompleteField}
                                        multiple={false}
                                        resultCallback={results => {
                                            return results.map(val => ({
                                                ...val,
                                                name: `${val.firstName} ${val.lastName}`,
                                                displayName: `${val.firstName} ${val.lastName}`,
                                            }));
                                        }}
                                        margin="none"
                                    />
                                </Box>
                                <Button
                                    onClick={formProps.submitForm}
                                    color={
                                        formProps.values[
                                            `tmpUserAutocomplete-${subjectAreaId}-${name}`
                                        ]
                                            ? "primary"
                                            : undefined
                                    }
                                >
                                    {t(`school.subjectAreas.add${name}`)}
                                </Button>
                            </Box>
                        </Form>
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("common.userName")}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {adminList &&
                                adminList.map(a => (
                                    <TableRow key={a.id}>
                                        <TableCell scope="row">
                                            {`${a.firstName} ${a.lastName} (${t(
                                                "school.subjectAreas.admin",
                                            )})`}
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                            {users.map(u => (
                                <TableRow key={u.id} hover>
                                    <TableCell scope="row">
                                        {`${u.firstName} ${u.lastName}`}
                                    </TableCell>
                                    <TableCell align="right">
                                        {canModify && (
                                            <Button
                                                variant="text"
                                                startIcon={<Icon path={mdiDelete} />}
                                                disableRipple
                                                onClick={() => {
                                                    if (u.id) handleRemoveUser(u.id);
                                                }}
                                            >
                                                {t("common.delete")}
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </FormikRef>
    );
};

export default SubjectAreaTeachersList;
