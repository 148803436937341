import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getAnalysisAtlValuesEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/atl/{cohort}/{yearGroup}/values/`,
    method: ApiRequests.GET,
};

export interface AnalysisAtlValuesFlatObject {
    [key: string]: any;
}

export interface AnalysisAtlValuesObject {
    [key: string]: AnalysisAtlValuesFlatObject;
}
