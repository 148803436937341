import { FC, useEffect, useRef, useState } from "react";
import FormikRef from "src/forms/FormikRef";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApiData, ApiStatus } from "src/api/constants";
import { useMount } from "src/hooks/useMount";
import { useResponse } from "src/hooks/useResponse";
import { ROUTE_REPORT_SNAPSHOT_LIST } from "src/routes";
import { AppState } from "src/store/reducers";
import { ReportTemplateList } from "../../api/ReportTemplate/getList";
import { CreateSubjectSnapshotReportRequest } from "../../api/SnapshotReport/createSubject";
import { SearchSnapshotFiltersResponse } from "../../api/SnapshotReport/searchSnapshotFilters";
import { reportTemplateListSelector } from "../../selectors/ReportTempatesSelectors";
import { ReportActions } from "../../store/actions";
import SubjectReportForm, {
    convertFormValuesToRequest,
    snapshotReportFormInitialValues,
    SubjectReportFormShape,
    subjectReportSchema,
} from "./forms/SubjectReportForm";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import {
    SearchSnapshotListFilters,
    useSearchSnapshotList,
} from "../../hooks/useSearchSnapshotList";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

interface OwnProps {
    cohort: number | null;
}

const dispatchActions = (dispatch: Dispatch) => ({
    getSearchSnapshotFilters: (cohort: number) => {
        dispatch(ReportActions.getSearchSnapshotFilters(cohort));
    },
    getReportTemplateList: () => {
        dispatch(ReportActions.getReportTemplateList());
    },
    createSubjectReport: (values: CreateSubjectSnapshotReportRequest) => {
        dispatch(ReportActions.createSingleSubjectSnapshotReport(values));
    },
});

const SubjectReportTab: FC<OwnProps> = ({ cohort }) => {
    const dispatch = useDispatch();
    const form = useRef() as any;
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { getSearchSnapshotFilters, getReportTemplateList, createSubjectReport } =
        dispatchActions(dispatch);
    const {
        apiCreate,
        apiFilter,
        courseFilters,
        reportTemplates,
    }: {
        apiCreate: ApiData;
        apiFilter: ApiData;
        courseFilters: SearchSnapshotFiltersResponse | null;
        reportTemplates: ReportTemplateList | null;
    } = useSelector(
        (state: AppState) => ({
            apiCreate: state.api.report.createSubjectSnapshotReport,
            apiFilter: state.api.report.getSearchSnapshotFilters,
            courseFilters: state.report.searchSnapshotFilters,
            reportTemplates: reportTemplateListSelector(state),
        }),
        shallowEqual,
    );
    const [searchSnapshotListFilters, setSearchSnapshotListFilters] =
        useState<SearchSnapshotListFilters | null>(null);
    const { data: snapshots /*, refetch: getSnapshotList*/ } = useSearchSnapshotList(
        cohort,
        searchSnapshotListFilters,
    );

    const handleSubmit = values => {
        const converted = convertFormValuesToRequest(values);
        if (converted) {
            createSubjectReport(converted);
        }
    };

    useMount(() => {
        getReportTemplateList();
    });

    useEffect(() => {
        if (cohort) {
            getSearchSnapshotFilters(cohort);
        }
        //eslint-disable-next-line
    }, [cohort]);

    useResponse(() => {
        const currentForm = form?.current as any;
        if (apiCreate.status === ApiStatus.SUCCESS) {
            enqueueSnackbar(t("report.snapshot.create.subjectReportSuccess"), {
                variant: "success",
            });
            navigate(ROUTE_REPORT_SNAPSHOT_LIST);
        }
        if (apiCreate.status === ApiStatus.ERROR) {
            if (apiCreate.error?.response.error) {
                enqueueSnackbar(apiCreate.error?.response.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("report.snapshot.create.subjectReportError"), {
                    ...SnackbarErrorOptions,
                });
            }
        }
        currentForm.setSubmitting(false);
    }, apiCreate);

    return (
        <Box>
            <Typography variant="body1" gutterBottom>
                {t("report.snapshot.create.subjectReportHint")}
            </Typography>
            {apiFilter.status === ApiStatus.SUCCESS ? (
                <FormikRef
                    ref={form}
                    initialValues={snapshotReportFormInitialValues}
                    validationSchema={subjectReportSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<SubjectReportFormShape>) => (
                        <>
                            <SubjectReportForm
                                {...formProps}
                                cohort={cohort}
                                snapshots={snapshots}
                                courseFilters={courseFilters}
                                reportTemplates={reportTemplates}
                                handleSpecificationChange={values => {
                                    if (cohort) {
                                        setSearchSnapshotListFilters({
                                            subjectAreaId: values.tmpSubject,
                                            qualificationId: values.tmpQualification,
                                            specificationId: values.tmpSpecification,
                                            yearGroup: values.tmpYearGroup,
                                            onlyCreated: true,
                                        });
                                    }
                                }}
                            />
                            <Box
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                justifyContent="space-between"
                                width={1}
                                mt={5}
                            >
                                <Button
                                    color="primary"
                                    disabled={
                                        formProps.isSubmitting ||
                                        !snapshots ||
                                        snapshots.length === 0
                                    }
                                    onClick={formProps.submitForm}
                                >
                                    {t("report.snapshot.create.publishBtn")}
                                </Button>
                            </Box>
                        </>
                    )}
                </FormikRef>
            ) : (
                <CircularProgress />
            )}
        </Box>
    );
};
export default SubjectReportTab;
