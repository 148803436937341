import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_ACADEMIC_HOUSES_QUERY = "classTrackerGroupAcademicHousesHeader";

export const useGroupAcademicHouses = (groupId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CLASS_TRACKER_GROUP_ACADEMIC_HOUSES_QUERY, schoolAccountId, groupId],
        queryFn: async (): Promise<string[] | null> => {
            if (groupId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker-group/${groupId}/student-academic-houses/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
