import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    TextFieldProps,
} from "@mui/material";
import { format } from "date-fns";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "src/hooks/usePrevious";
import { filterNotNullable } from "src/services/object";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DATEPICKER_FORMAT } from "src/types";
import { useCoreValues } from "../../hooks/useCore";
import RangeFilterComponent from "src/modules/analysis/components/Grid/ToolsPanel/FilterPanel/RangeFilterComponent";
import { useAccountInfo } from "../../hooks/useAccountInfo";

export type FilterTypes =
    | "student"
    | "grade"
    | "KS4"
    | "distance"
    | "mtg"
    | "atl"
    | "tags"
    | "summary";
export interface AttributePair {
    type: FilterTypes;
    attribute: string;
    attributeDisplay: string;
    values: {
        value: string | number;
        valueTo?: string | number;
        valueDisplay: string | ReactElement;
    }[];
}

export interface FiltersShape {
    [key: string]: AttributePair;
}

interface OwnProps {
    handleFiltersChange: (filter: FiltersShape) => void;
    studentParams?: string[];
    excludedParams?: string[];
    studentForms?: string[];
    classTrackers?: string[];
    studentAcademicHouses?: string[];
}

export const multipleStudentFilterAttributes = [
    "ethnicity_name",
    "senNeed",
    "senStatus",
    "form",
    "gender",
    "priorAbility",
    "classTrackerName",
    "academicHouse",
];

export const studentYesNoFilters = ["pupilPremium", "fsm", "lookedAfter", "eal", "nativeSpeaker"];

export const studentDateFilters = ["fromDateJoined", "toDateJoined"];

export const studentValueMinMaxFilters = ["ks2FineLevelScaled"];

export const studentWithNoDataOptionFilters = [
    "ks2FineLevelScaled",
    "form",
    "senStatus",
    "priorAbility",
    "senNeed",
    "catQuantitative",
    "academicHouse",
    "nativeSpeaker",
    "dateOfBirth",
    "email",
    "yearGroup",
];

const StudentFilter: FC<OwnProps> = ({
    handleFiltersChange,
    studentParams,
    excludedParams,
    studentForms: forms,
    studentAcademicHouses,
    classTrackers,
}) => {
    const { t } = useTranslation();
    const [selectedParam, setSelectedParam] = useState<string>("");
    const [attributeValue, setAttributeValue] = useState<string>("");
    const [localExcludedParams, setLocalExcludedParams] = useState(excludedParams);
    const [showWithNoData, setShowWithNoData] = useState<boolean>(false);
    const { data: accountInfo } = useAccountInfo();
    const yesNoOptions = [
        { id: "true", name: t("common.yes") },
        { id: "false", name: t("common.no") },
    ];

    const { data } = useCoreValues();

    const ethnicityList = data?.studentProperties?.ethnicities;
    const senNeeds = data?.studentProperties?.senNeed;
    const genderList = data?.studentProperties?.genders;
    const senStatusList = data?.studentProperties?.senStatus;
    const priorAbilityList = data?.studentProperties?.priorAbility;

    const handleChangeParam = e => {
        setSelectedParam(e.target.value);
    };

    const resolveValues = param => {
        switch (param) {
            case "form":
                return forms || [];
            case "ethnicity_name":
                return ethnicityList?.map(e => ({ id: e.name, name: e.name })) || [];
            case "gender":
                return genderList;
            case "senNeed":
                return senNeeds || [];
            case "senStatus":
                return senStatusList || [];
            case "academicHouse":
                return studentAcademicHouses || [];
            case "priorAbility":
                return priorAbilityList;
            case "classTrackerName":
                return classTrackers || [];
            default:
                return [];
        }
    };

    const resolveValueDisplay = (selectedParam: string, attributeValue: string) => {
        if (showWithNoData) return t("common.noData");
        if (studentYesNoFilters.includes(selectedParam)) {
            return !!attributeValue === true ? t("common.yes") : t("common.no");
        }
        if (studentDateFilters.includes(selectedParam)) {
            return format(new Date(attributeValue), "dd MMMM yyyy hh:mm:ss");
        }
        if (selectedParam === "ethnicity_name") {
            return ethnicityList?.find(e => `${e.id}` === attributeValue)?.name || attributeValue;
        }
        if (selectedParam === "senNeeds") {
            return senNeeds?.find(e => `${e.id}` === attributeValue)?.id || attributeValue;
        }

        return attributeValue;
    };

    const renderValues = (field: string, items: any[]) => {
        return (
            <FormControl fullWidth margin="normal">
                <InputLabel id={field} shrink>
                    {t("common.studentAttributesFilter.value")}
                </InputLabel>
                <Select
                    disabled={!!showWithNoData}
                    name={field}
                    labelId={field}
                    displayEmpty
                    fullWidth
                    onChange={e => setAttributeValue(e.target.value as string)}
                    value={attributeValue}
                >
                    <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                    {filterNotNullable(items).map(i => {
                        if (i.id) {
                            return (
                                <MenuItem key={i.id} value={i.id}>
                                    {i.name || i.id}
                                </MenuItem>
                            );
                        }

                        return <MenuItem key={`${i}`} value={`${i}`}>{`${i}`}</MenuItem>;
                    })}
                </Select>
            </FormControl>
        );
    };

    const renderPicker = () => {
        return (
            <FormControl fullWidth>
                <DesktopDatePicker
                    disabled={!!showWithNoData}
                    inputFormat={DATEPICKER_FORMAT}
                    value={
                        typeof attributeValue === "string" && attributeValue
                            ? new Date(attributeValue)
                            : new Date()
                    }
                    showToolbar={false}
                    label={t("common.form.label.examDate")}
                    onChange={(date: any) => {
                        if (date) setAttributeValue(date?.toISOString() || "");
                    }}
                    renderInput={(props: TextFieldProps): any => (
                        <TextField
                            {...props}
                            margin="none"
                            inputRef={props.inputRef}
                            name={props.name}
                            id={props.id}
                            defaultValue={props.defaultValue}
                            label={t("common.form.label.examDate")}
                            fullWidth
                            type={props.type}
                            onBlur={props.onBlur}
                        />
                    )}
                />
            </FormControl>
        );
    };

    const prevSelectedParam = usePrevious(selectedParam);

    useEffect(() => {
        if (prevSelectedParam && prevSelectedParam !== selectedParam) {
            setAttributeValue("");
        }
    }, [selectedParam, prevSelectedParam]);

    useEffect(() => {
        if (!classTrackers) {
            setLocalExcludedParams(excludedParams?.concat("classTrackerName"));
        }
    }, [classTrackers, excludedParams]);

    useEffect(() => {
        if (!accountInfo?.hasStudentPortalEnabled) {
            setLocalExcludedParams(excludedParams?.concat("email"));
        }
    }, [accountInfo, excludedParams]);
    return (
        <>
            {studentParams && studentParams.length > 0 ? (
                <Box>
                    <TextField
                        value={selectedParam}
                        select
                        SelectProps={{
                            displayEmpty: true,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label={t("common.filterByStudent")}
                        onChange={handleChangeParam}
                        margin="normal"
                    >
                        <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                        {studentParams
                            .filter(
                                sp => !(localExcludedParams && localExcludedParams.includes(sp)),
                            )
                            .concat(studentDateFilters)
                            .map(asp => (
                                <MenuItem key={asp} value={asp}>
                                    {t(`tracker.grid.configureColumns.${asp}`)}
                                </MenuItem>
                            ))}
                    </TextField>
                    {studentValueMinMaxFilters.includes(selectedParam) ? (
                        <RangeFilterComponent
                            handleFiltersChange={handleFiltersChange}
                            columnName={selectedParam}
                            columnDisplayName={t(`tracker.grid.configureColumns.` + selectedParam)}
                            filterType="student"
                            noDataEnabled={true}
                            onSubmit={() => setSelectedParam("")}
                        />
                    ) : (
                        <>
                            {studentDateFilters.includes(selectedParam) ? (
                                <>{renderPicker()}</>
                            ) : studentYesNoFilters.includes(selectedParam) ? (
                                renderValues(selectedParam, yesNoOptions)
                            ) : multipleStudentFilterAttributes.includes(selectedParam) ? (
                                renderValues(selectedParam, resolveValues(selectedParam))
                            ) : (
                                <TextField
                                    disabled={!!showWithNoData}
                                    label={t("common.filterStudentAttribute")}
                                    value={attributeValue || ""}
                                    onChange={e => setAttributeValue(e.target.value)}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                            {studentWithNoDataOptionFilters.includes(selectedParam) && (
                                <Box>
                                    <Box>
                                        {selectedParam && (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={showWithNoData}
                                                        onChange={() => {
                                                            setShowWithNoData(!showWithNoData);
                                                        }}
                                                        name=""
                                                    />
                                                }
                                                label={t("common.showOnlyWithNoData")}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            )}
                            <Box pt={2}>
                                <Button
                                    color="primary"
                                    disabled={
                                        !selectedParam || (!attributeValue && !showWithNoData)
                                    }
                                    onClick={() => {
                                        if (selectedParam && (attributeValue || showWithNoData)) {
                                            handleFiltersChange({
                                                [selectedParam]: {
                                                    type: "student",
                                                    attribute: selectedParam,
                                                    attributeDisplay: t(
                                                        `tracker.grid.configureColumns.` +
                                                            selectedParam,
                                                    ),
                                                    values: [
                                                        {
                                                            value: showWithNoData
                                                                ? "no-data"
                                                                : attributeValue,
                                                            valueDisplay: resolveValueDisplay(
                                                                selectedParam,
                                                                attributeValue,
                                                            ),
                                                        },
                                                    ],
                                                },
                                            });
                                            setSelectedParam("");
                                            setAttributeValue("");
                                            setShowWithNoData(false);
                                        }
                                    }}
                                >
                                    {t("common.filterAdd")}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            ) : (
                <></>
            )}
        </>
    );
};

export default StudentFilter;
