import COLORS from "src/styles/colors";
import { getContrastRatio } from "@mui/material";
import { NodeTypes } from "./headerGenerator";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { ClassTrackerHeaderObject, ColourConfigDetailsObject } from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";

export const DEFAULT_CELL_COLOUR = "#ffffff";

export const DEFAULT_FIELD_OPACITY = 25;

export const DEFAULT_DISPLAY_FIELD_OPACITY = 35;

export interface UnitColour {
    hash: string;
    colour: string;
}
export enum ColourConfigTypes {
    DISABLED = "disabled",
    DEFAULT = "default",
    CUSTOM = "custom",
}

export enum ColourTypes {
    MARKS = "marks",
    MARKS_SHADING = "marks-shading",
    GRADE = "grade",
    MTG = "mtg",
}

const resolveDefaultTypeColour = (
    value,
    maxValue?,
    colourType?: ColourTypes,
    relatedData?: ClassTrackerRelatedObject,
    initialColour?,
) => {
    if (relatedData?.colourConfig) {
        const colourConfig = relatedData?.colourConfig.find(cc => cc.type === colourType);
        const colourDetails = colourConfig?.details;

        // default marks
        if (
            (colourType === ColourTypes.MARKS || colourType === ColourTypes.MARKS_SHADING) &&
            value !== null &&
            colourDetails &&
            maxValue
        ) {
            const colourDetailsValues = colourDetails
                .map((cd: { threshold: number }) => cd.threshold)
                .sort((a, b) => b - a);
            const colourDataIndex = colourDetailsValues.findIndex(
                (cd: number) => (value / maxValue) * 100 >= cd,
            );

            if (colourDataIndex < 0) {
                return colourDetails[colourDetailsValues.length - 1];
            }

            return colourDetails[colourDataIndex];
        }

        // default grades
        if (colourType === ColourTypes.GRADE && value !== null) {
            const colourGrade = colourConfig?.details.find(c => c.gradeId === value);

            if (!colourGrade && value === -1) {
                return initialColour;
                // colourGrade = cellConfig?.details.find(c => c.gradeId === 0);
            }

            return colourGrade || null;
        }

        // default mtg
        if (colourType === ColourTypes.MTG && value !== null) {
            if (value === -1) {
                return colourConfig?.details.find(c => c.mtgDistance === "-1") || null;
            } else if (value > 0) {
                return colourConfig?.details.find(c => c.mtgDistance === ">") || null;
            } else if (value < 0) {
                return colourConfig?.details.find(c => c.mtgDistance === "<") || null;
            }

            return colourConfig?.details.find(c => c.mtgDistance === "==") || null;
        }
    }

    return null;
};

const resolveCustomTypeColour = (value, maxValue?, cellConfig?, initialColour?) => {
    // custom marks
    if (
        (cellConfig?.colourConfig.type === ColourTypes.MARKS ||
            cellConfig?.colourConfig.type === ColourTypes.MARKS_SHADING) &&
        value !== null &&
        cellConfig?.colourConfig.details &&
        maxValue
    ) {
        const colourDetailsValues = cellConfig?.colourConfig.details
            .map((cd: { threshold: number }) => cd.threshold)
            .sort((a, b) => b - a);
        const colourDataIndex = colourDetailsValues.findIndex(
            (cd: number) => (value / maxValue) * 100 >= cd,
        );
        const details = cellConfig?.colourConfig.details.sort((a, b) => b.threshold - a.threshold);

        if (colourDataIndex < 0) {
            return details[colourDetailsValues.length - 1];
        }

        return details[colourDataIndex];
    }

    // custom grades
    if (cellConfig?.colourConfig.type === ColourTypes.GRADE && value !== null) {
        const colourGrade = cellConfig?.colourConfig?.details.find(c => c.gradeId === value);

        if (!colourGrade && value === -1) {
            return initialColour;
            // colourGrade = cellConfig?.colourConfig?.details.find(c => c.gradeId === 0);
        }

        return colourGrade || null;
    }

    // custom mtg
    if (cellConfig?.colourConfig.type === ColourTypes.MTG && value !== null) {
        if (value === -1) {
            return cellConfig?.colourConfig?.details.find(c => c.mtgDistance === "-1") || null;
        } else if (value > 0) {
            return cellConfig?.colourConfig?.details.find(c => c.mtgDistance === ">") || null;
        } else if (value < 0) {
            return cellConfig?.colourConfig?.details.find(c => c.mtgDistance === "<") || null;
        }

        return cellConfig?.colourConfig?.details.find(c => c.mtgDistance === "==") || null;
    }

    return null;
};

// get btec cell main function
export const getBtecCellColour = (value: string, parentColour?: string, cellConfig?) => {
    const initialColour = {
        colour: parentColour,
        opacity: DEFAULT_FIELD_OPACITY,
    };

    const options = cellConfig.colourConfig?.options;
    if (options && value) {
        return { colour: options[value.toUpperCase()], opacity: DEFAULT_FIELD_OPACITY };
    }

    return initialColour;
};

// get cell color main function
export const getCellColour = (
    value,
    cellConfig?,
    parentColour?: string,
    relatedData?: ClassTrackerRelatedObject,
    isDisplayField?: boolean,
) => {
    const maxValue = cellConfig?.maxValue;
    const colourConfigType: ColourConfigTypes | undefined =
        cellConfig?.columnConfig?.colourConfigType;
    const colourType: ColourTypes | undefined = cellConfig?.colourConfig?.type;
    const initialColour = {
        colour: parentColour,
        opacity: isDisplayField ? DEFAULT_DISPLAY_FIELD_OPACITY : DEFAULT_FIELD_OPACITY,
    };

    let colour: ColourConfigDetailsObject | null = null;
    switch (colourConfigType) {
        case ColourConfigTypes.DEFAULT:
            colour = resolveDefaultTypeColour(
                value,
                maxValue,
                colourType,
                relatedData,
                initialColour,
            );
            break;
        case ColourConfigTypes.CUSTOM:
            colour = resolveCustomTypeColour(value, maxValue, cellConfig, initialColour);
            break;
    }

    return colour || initialColour;
};

// get current grade colour
export const getCurrentGradeColour = (
    metaConfig: { mtgDistande: number },
    cellConfig,
    relatedData: ClassTrackerRelatedObject,
    parentColour?: string,
) => {
    const initialColour = {
        colour: parentColour,
        opacity: DEFAULT_DISPLAY_FIELD_OPACITY,
    };

    if (metaConfig) {
        const mtgDistance = metaConfig["mtgDistance"];
        const colourType = cellConfig?.columnConfig?.colourConfigType;
        let colourDetails: ColourConfigDetailsObject[] = [];

        if (colourType === "default") {
            const tmpColourConfig = relatedData?.colourConfig.find(cc => cc.type === "mtg");
            if (tmpColourConfig) colourDetails = tmpColourConfig.details;
        }
        if (colourType === "custom") {
            colourDetails = cellConfig?.colourConfig?.details;
        }

        let colour: { colour: string | undefined; opacity: number } | null = null;
        if (colourDetails) {
            let tmpColour: ColourConfigDetailsObject | null = null;
            if (mtgDistance === -1) {
                tmpColour = colourDetails?.find(c => c.mtgDistance === "-1") || null;
            } else if (mtgDistance > 0) {
                tmpColour = colourDetails?.find(c => c.mtgDistance === ">") || null;
            } else if (mtgDistance < 0) {
                tmpColour = colourDetails?.find(c => c.mtgDistance === "<") || null;
            } else {
                tmpColour = colourDetails?.find(c => c.mtgDistance === "==") || null;
            }
            if (tmpColour) {
                colour = {
                    colour: tmpColour.colour,
                    opacity: tmpColour.opacity === null ? 100 : tmpColour.opacity,
                };
            }
        }
        return colour || initialColour;
    }
    return initialColour;
};

// get unit colout by element hash
export const getUnitColourByHash = (
    hash: string,
    classTrackerHeader: ClassTrackerHeaderObject | null,
): UnitColour => {
    const unitColours: UnitColour[] = [];

    if (classTrackerHeader) {
        classTrackerHeader.header[0].children.forEach(headerElement => {
            if (headerElement.nodeType === NodeTypes.UNIT && headerElement.headerConfig.colour) {
                unitColours.push({
                    hash: headerElement.hash,
                    colour: headerElement.headerConfig.colour,
                });
            }
        });
    }

    return unitColours.find(uc => uc.hash === hash) || { hash, colour: DEFAULT_CELL_COLOUR };
};

// hex to rgb helper
export const hexToRGB = (hex, alpha) => {
    if (alpha > 1) {
        alpha = alpha / 100;
    }

    if (hex) {
        hex = hex.replace("#", "");
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        if (alpha) {
            return `rgba(${r},${g},${b},${alpha})`;
        }
        return `rgba(${r},${g},${b})`;
    }
    return "transparent";
};

const removeHash = hex => (hex.charAt(0) === "#" ? hex.slice(1) : hex);

const parseHex = nakedHex => {
    const isShort = nakedHex.length === 3 || nakedHex.length === 4;

    const twoDigitHexR = isShort
        ? `${nakedHex.slice(0, 1)}${nakedHex.slice(0, 1)}`
        : nakedHex.slice(0, 2);
    const twoDigitHexG = isShort
        ? `${nakedHex.slice(1, 2)}${nakedHex.slice(1, 2)}`
        : nakedHex.slice(2, 4);
    const twoDigitHexB = isShort
        ? `${nakedHex.slice(2, 3)}${nakedHex.slice(2, 3)}`
        : nakedHex.slice(4, 6);
    const twoDigitHexA =
        (isShort ? `${nakedHex.slice(3, 4)}${nakedHex.slice(3, 4)}` : nakedHex.slice(6, 8)) || "ff";

    return {
        r: twoDigitHexR,
        g: twoDigitHexG,
        b: twoDigitHexB,
        a: twoDigitHexA,
    };
};

const hexToDecimal = hex => parseInt(hex, 16);

const hexesToDecimals = ({ r, g, b, a }) => ({
    r: hexToDecimal(r),
    g: hexToDecimal(g),
    b: hexToDecimal(b),
    a: +(hexToDecimal(a) / 255).toFixed(2),
});

const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n); // eslint-disable-line no-restricted-globals, max-len

const formatRgb = (decimalObject, parameterA) => {
    const { r, g, b, a: parsedA } = decimalObject;
    const a = isNumeric(parameterA) ? parameterA : parsedA;

    return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const hexToRgba = (hex, a) => {
    const hashlessHex = removeHash(hex);
    const hexObject = parseHex(hashlessHex);
    const decimalObject = hexesToDecimals(hexObject);

    return formatRgb(decimalObject, a);
};

// get contrast text (decide to use black/white font color depending on background)
export const getContrastText = (backgroundColor, opacity?) => {
    const defaultTextColor = COLORS.GREY_2;
    const altTextColor = COLORS.WHITE;
    const contrastThreshold = 1.7;

    if (opacity && opacity < 1) {
        opacity = opacity * 100;
    }

    if (opacity < 50) {
        return defaultTextColor;
    }

    if (!backgroundColor) {
        return defaultTextColor;
    }

    const contrastText =
        getContrastRatio(backgroundColor, defaultTextColor) >= contrastThreshold
            ? defaultTextColor
            : altTextColor;

    return contrastText;
};

export const getTrackerBorderColor = (dataType: DataTypeTypes) => {
    switch (dataType) {
        case DataTypeTypes.FORECAST:
            return COLORS.CYAN_1;
        case DataTypeTypes.SNAPSHOT:
            return COLORS.YELLOW_2;
        default:
            return COLORS.GREEN_1;
    }
};
