import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface ParentExportRequest {
    ids?: number[] | null;
}

const apiExportMutation = async (values: ParentExportRequest): Promise<AxiosResponse> => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/parents/export/`, values);
};

export const useParentExportMutation = (onSuccess?: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: ParentExportRequest) => apiExportMutation(values),
        onSuccess: () => {
            enqueueSnackbar(t("parent.requestModal.exportSuccess"), { variant: "success" });
            if (onSuccess) onSuccess();
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
