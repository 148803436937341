import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { PARENT_DETAILS_QUERY } from "./useParentDetails";
import { getSchoolAccountId } from "src/services/url";

export interface EditParentStudentsRequest {
    parentId: number;
    students: ListObject<number>[];
}

const apiEditParentStudents = async (values: EditParentStudentsRequest): Promise<AxiosResponse> => {
    return await axiosInstance.put(
        `school/${getSchoolAccountId()}/parents/${values.parentId}/students/`,
        values,
    );
};

export const useEditParentsStudentsMutation = (onSuccess: () => void, onError: (err) => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (values: EditParentStudentsRequest) => apiEditParentStudents(values),
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [PARENT_DETAILS_QUERY] });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(t("parent.addEdit.studentsUpdated"), { ...SnackbarErrorOptions });
            }
            if (err?.response?.data?.errors && onError) {
                onError(err.response.data.errors);
            }
        },
    });
};
