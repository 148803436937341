import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SubscriptionsActions } from "../actions";
import { PaymentActionsTypes } from "./actions";
import { OrmSubscriptionHistoryActions } from "src/orm/models/SubscriptionHistory/actions";

const createPaymentIntentSuccess = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(PaymentActionsTypes.CREATE_PAYMENT_INTENT_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.createPaymentIntentSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(SubscriptionsActions.setPaymentIntent(payload.response))),
    );

const confirmPaymentIntentSuccess = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(PaymentActionsTypes.CONFIRM_PAYMENT_INTENT_SUCCESS),
        mergeMap(() => of(SubscriptionsActions.clearPaymentIntent())),
    );

const changeCouponCodeSuccess = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(PaymentActionsTypes.CHANGE_COUPON_CODE_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.changeCouponCodeSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(OrmSubscriptionHistoryActions.upsert(payload.response));
        }),
    );

// const createInvoiceSuccess = (action$: ActionsObservable<SubscriptionsActions>) =>
//     action$.pipe(
//         ofType(PaymentActionsTypes.CREATE_INVOICE_SUCCESS),
//         map(
//             (action: ReturnType<typeof SubscriptionsActions.createInvoiceSuccess>) => action.payload
//         ),
//         mergeMap(payload => of(SubscriptionsActions.setInvoice(payload.response)))
//     );

export const paymentEpics = combineEpics(
    createPaymentIntentSuccess,
    confirmPaymentIntentSuccess,
    changeCouponCodeSuccess,
    // createInvoiceSuccess
);
