export const isArraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
        if (!Array.isArray(a[i]) && !Array.isArray(b[i]) && a[i] !== b[i]) {
            return false;
        }
    }
    for (let j = 0; j < a.length; ++j) {
        if (Array.isArray(a[j]) && Array.isArray(b[j]) && a[j].length !== b[j].length) {
            return false;
        }
    }

    return true;
};

export const groupElementsToArray = (data, elCount) => {
    const group = [] as any;
    for (let i = 0, j = 0; i < data.length; i++) {
        if (i >= elCount && i % elCount === 0) j++;
        group[j] = group[j] || [];
        group[j].push(data[i]);
    }
    return group;
};

export const sortArrayBy = (array, sortByParam) => {
    array.sort((a, b) =>
        `${a[sortByParam]}`.toLowerCase() > `${b[sortByParam]}`.toLowerCase()
            ? 1
            : `${b[sortByParam]}`.toLowerCase() > `${a[sortByParam]}`.toLowerCase()
              ? -1
              : 0,
    );
};

export const sortArrayByFirstLastName = (array, firstParam, secondParam) => {
    if (array && array.length > 0) {
        return array.sort((a, b) => {
            const result = a[secondParam].localeCompare(b[secondParam]);
            return result !== 0 ? result : a[firstParam].localeCompare(b[firstParam]);
        });
    }
    return [];
};

export function sortByTwoParams(arr, firstParam, secondParam) {
    const newArray = [...arr];
    newArray.sort(function (a, b) {
        const lastNameComparison = a[firstParam].localeCompare(b[firstParam]);
        if (lastNameComparison !== 0) {
            return lastNameComparison;
        } else {
            return a[secondParam].localeCompare(b[secondParam]);
        }
    });
    return newArray;
}

export function sortTiers(tiers: string[]) {
    const sortedTiers = [];
    if (tiers.includes("H")) {
        sortedTiers.push("H");
    }
    if (tiers.includes("Higher")) {
        sortedTiers.push("Higher");
    }
    if (tiers.includes("I")) {
        sortedTiers.push("I");
    }
    if (tiers.includes("Intermediate")) {
        sortedTiers.push("Intermediate");
    }
    if (tiers.includes("F")) {
        sortedTiers.push("F");
    }
    if (tiers.includes("Foundation")) {
        sortedTiers.push("Foundation");
    }
    return sortedTiers;
}
