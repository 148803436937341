import { FC, ReactElement, ReactNode } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import COLORS from "src/styles/colors";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100%",
    },
    variantDefault: {
        padding: theme.spacing(6),
    },
    variantFilter: {
        padding: theme.spacing(3, 6),
    },
    variantSubjectArea: {
        padding: theme.spacing(1.75, 3),
    },
    variantSubscription: {
        padding: theme.spacing(4, 6),
    },
    variantPaddingTopNone: {
        padding: theme.spacing(0, 6, 6),
    },
    variantPaddingBottomNone: {
        padding: theme.spacing(6, 6, 0),
    },
    variantCourse: {
        padding: theme.spacing(4, 6),
    },
    variantSmallAction: {
        padding: theme.spacing(3, 6),
        "& .MuiButton-root": {
            marginTop: 0,
        },
    },
    variantDialog: {
        padding: theme.spacing(3, 6),
    },
    colorDefault: {
        backgroundColor: COLORS.WHITE,
    },
    colorLightGrey: {
        backgroundColor: COLORS.VERY_LIGHT_GREY_4,
    },
    borderTop: {
        borderTop: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
    },
    borderRight: {
        borderRight: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
    },
    borderBottom: {
        borderBottom: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
    },
    borderLeft: {
        borderLeft: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
    },
}));

interface PaperInnerProps {
    children: ReactNode;
    variant?:
        | "default"
        | "filter"
        | "subjectArea"
        | "subscription"
        | "paddingTopNone"
        | "paddingBottomNone"
        | "course"
        | "smallAction"
        | "dialog";
    color?: "default" | "lightGrey" | string;
    border?: "top" | "right" | "bottom" | "left";
}

const PaperInner: FC<PaperInnerProps> = ({
    children,
    variant = "default",
    color = "default",
    border,
}): ReactElement => {
    const classes = useStyles();

    return (
        <Box
            className={clsx(
                classes.root,
                variant === "default" && classes.variantDefault,
                variant === "filter" && classes.variantFilter,
                variant === "subjectArea" && classes.variantSubjectArea,
                variant === "subscription" && classes.variantSubscription,
                variant === "paddingTopNone" && classes.variantPaddingTopNone,
                variant === "paddingBottomNone" && classes.variantPaddingBottomNone,
                variant === "course" && classes.variantCourse,
                variant === "smallAction" && classes.variantSmallAction,
                variant === "dialog" && classes.variantDialog,
                color === "default" && classes.colorDefault,
                color === "lightGrey" && classes.colorLightGrey,
                border === "top" && classes.borderTop,
                border === "right" && classes.borderRight,
                border === "bottom" && classes.borderBottom,
                border === "left" && classes.borderLeft,
            )}
        >
            {children}
        </Box>
    );
};

export default PaperInner;
