import { FC } from "react";
import CustomTable from "src/components/CustomTable";
import { useTranslation } from "react-i18next";
import { CellDataGetterParams } from "src/components/CustomTable/types";
import { StudentModel } from "src/orm/models/Student";
import { Box } from "@mui/material";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { ActionMenuItemProps } from "src/components/ActionTableButton/types";

interface OwnProps {
    students: StudentModel[];
    isSyncedClass?: boolean;
    handleDelete?: (itemId: number) => void;
}

const StudentsTable: FC<OwnProps> = ({ students, isSyncedClass, handleDelete }) => {
    const { t } = useTranslation();
    const actions = [] as ActionMenuItemProps<{}>[];

    if (handleDelete) {
        actions.push(
            getMenuActionProps("delete", {
                onAction: (su: StudentModel & { index: number }) => {
                    handleDelete(su.id as number);
                },
                text: t("common.remove"),
                showAction: () => !isSyncedClass,
            }),
        );
    }

    return (
        <>
            {students.length > 0 && (
                <CustomTable
                    margin="none"
                    data={students}
                    columns={[
                        {
                            key: "studentsName",
                            label: t("class.addClass.studentsName"),
                            cellDataGetter: ({ rowData }: CellDataGetterParams<StudentModel>) => {
                                if (rowData.firstName && rowData.lastName) {
                                    return (
                                        <Box fontWeight="fontWeightMedium">{`${rowData.firstName} ${rowData.lastName}`}</Box>
                                    );
                                }
                                return "";
                            },
                        },
                        {
                            key: "uniquePupilNumber",
                            label: t("class.addClass.studentsUniquePupilNumber"),
                        },
                    ]}
                    actions={actions}
                />
            )}
        </>
    );
};

export default StudentsTable;
