import { GridApi } from "ag-grid-community";
import {
    ColumnConfigTypes,
    MOVE_STUDENTS_CURRENT_CLASS_FIELD,
    MOVE_STUDENTS_MOVE_TO_FIELD,
} from "./headerGenerator";
import { getFieldValue } from "./valueHandler";
import { isValueSet } from "src/services/utilsGPT";

export const EXCEL_DEFAULT_PARAMS = { allColumns: true, columnGroups: true };

export const exportTrackerToExcel = (gridApi: GridApi) => {
    gridApi.exportDataAsExcel({
        ...EXCEL_DEFAULT_PARAMS,
        processHeaderCallback: param => {
            const colDef = param.column.getColDef();
            if (colDef.headerComponentParams) {
                return `${
                    colDef.headerComponentParams?.label ? colDef.headerComponentParams?.label : ""
                } ${
                    colDef.headerComponentParams?.maxValue
                        ? "/" + colDef.headerComponentParams?.maxValue
                        : ""
                }`;
            }
            return colDef.headerName ? colDef.headerName : "";
        },
        processCellCallback: param => {
            const colId = param.column.getColId();
            const value = param.value?.displayValue ?? param.value?.value ?? param.value;
            let fineGrade = "";
            if (
                param.column.getUserProvidedColDef()?.headerComponentParams?.columnConfigType ===
                    ColumnConfigTypes.TEACHER_JUDGEMENT &&
                param.node?.data?.metaColumnsConfig?.[colId]?.fineGrade
            ) {
                fineGrade = param.node?.data?.metaColumnsConfig?.[colId]?.fineGrade;
            }

            if (param.column.getColId() === MOVE_STUDENTS_CURRENT_CLASS_FIELD) {
                return param.node?.data["student_classTrackerName"] || "";
            }

            if (
                param.column.getColId() === MOVE_STUDENTS_MOVE_TO_FIELD &&
                param.context.allClassTrackers &&
                param.context.allClassTrackers.length > 0
            ) {
                const filteredClass =
                    param.context.allClassTrackers &&
                    param.context.allClassTrackers.find(c => c.id === value);
                if (filteredClass) {
                    return filteredClass.name;
                }
                return "";
            }

            if (
                param.column.getColId() === MOVE_STUDENTS_MOVE_TO_FIELD &&
                param.context.relatedData.classTrackers &&
                param.context.relatedData.classTrackers.length > 0
            ) {
                const filteredClass =
                    param.context.relatedData.classTrackers &&
                    param.context.relatedData.classTrackers.find(c => c.id === value);
                if (filteredClass) {
                    return filteredClass.name;
                }
                return "";
            }

            if (value?.name) {
                return value.name;
            }
            const isPinned = param.node?.rowPinned;
            if (isPinned) {
                return value;
            }

            const relatedData = param.context.relatedData;

            const field = param.column.getColId();
            const flatHeaderObject = param.context.flatHeader.find(fh => {
                return fh.field === field;
            });

            const headerComponentParams = param.column.getColDef().headerComponentParams;
            if (flatHeaderObject && headerComponentParams) {
                const unitType = flatHeaderObject.parentUnitType;

                const displayValue = getFieldValue(
                    value,
                    relatedData,
                    unitType,
                    headerComponentParams.columnConfigType,
                    headerComponentParams.columnType,
                    headerComponentParams.gradeType,
                );
                return isValueSet(displayValue) ? displayValue + fineGrade : null;
            }

            if (field?.includes("-display")) {
                const tmpValue = param.node?.data[field.replace("-display", "")];
                const altConfigsList = param?.context?.relatedData?.atlConfigs;
                const columnName = param.column?.getColDef()?.headerName;
                const customField =
                    altConfigsList?.length > 0
                        ? altConfigsList.find(atl => atl.name === columnName)
                        : null;
                if (tmpValue && tmpValue["value"]) {
                    return customField.values[customField.values.length - tmpValue["value"]];
                }
                if (tmpValue && !tmpValue["value"]) {
                    return customField.values[customField.values.length - tmpValue];
                }
            }
            return value;
        },
    });
};
