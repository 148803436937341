import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { OrmSnapshotReportActions } from "src/orm/models/SnapshotReport/actions";
import { ReportActions, ReportActionTypes } from "../actions";

const getSnapshotReportsSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.GET_SNAPSHOT_REPORT_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.getSnapshotReportListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(
                OrmSnapshotReportActions.bulkImport(payload.response),
                ReportActions.setSnapshotReportfilter({
                    ...payload.response,
                    requestParams: null,
                }),
            );
        }),
    );

const getSnapshotReportFiltersSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.GET_SNAPSHOT_REPORT_LIST_FILTERS_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.getSnapshotReportListFiltersSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(ReportActions.setSnapshotReportListFilter(payload.response));
        }),
    );

const createSummarySnapshotReportSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.CREATE_SUMMARY_SNAPSHOT_REPORT_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.createSummarySnapshotReportSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(OrmSnapshotReportActions.upsert(payload.response))),
    );

const createAllSubjectsSnapshotReportSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.CREATE_ALL_SUBJECTS_SNAPSHOT_REPORT_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.createAllSubjectsSnapshotReportSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(OrmSnapshotReportActions.upsert(payload.response))),
    );

const createSingleSubjectSnapshotReportSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.CREATE_SINGLE_SUBJECT_SNAPSHOT_REPORT_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.createSingleSubjectSnapshotReportSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(OrmSnapshotReportActions.upsert(payload.response))),
    );

export const snapshotReportEpics = combineEpics(
    getSnapshotReportsSuccessEpic,
    createSummarySnapshotReportSuccessEpic,
    createAllSubjectsSnapshotReportSuccessEpic,
    createSingleSubjectSnapshotReportSuccessEpic,
    getSnapshotReportFiltersSuccessEpic,
);
