import PromptDialog from "src/forms/PromptDialog";
import {
    Box,
    CircularProgress,
    Divider,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StudentModel } from "../model/StudentModel";
import { useExportStudentsMutation } from "../hooks/useExportStudentsMutation";
import { useStudentInviteMutation } from "../hooks/useInviteMutation";

export interface StudentMassActions {
    requestType: "invite" | "download";
    criteria: "all" | "selected";
}

interface OwnProps {
    students: StudentModel[];
    rowSelection;
    opened: boolean;
    count: number;
    handleClose: () => void;
}

const StudentMassActionsModal: FC<OwnProps> = ({
    rowSelection,
    opened,
    count,
    students,
    handleClose,
}) => {
    const ids = Object.keys(rowSelection).map(rs => parseInt(`${rs}`)) || null;

    const { t } = useTranslation();

    // student mass actions
    const { mutate: exportStudents, isPending: isLoadingStudents } = useExportStudentsMutation(
        () => {
            handleClose();
        },
    );
    const { mutate: inviteStudents, isPending: isLoadingInvite } = useStudentInviteMutation(() => {
        handleClose();
    });

    const [requestData, setRequestData] = useState<StudentMassActions>({
        requestType: "invite",
        criteria: ids === null || ids.length === 0 ? "all" : "selected",
    });

    useEffect(() => {
        if (opened) {
            setRequestData({
                ...requestData,
                criteria: ids === null || ids.length === 0 ? "all" : "selected",
            });
        }
    }, [opened]);

    const [sendInvitationsDialogOpened, setSendInvitationsDialogOpened] = useState<boolean>(false);
    const selectedIds = [];
    Object.keys(rowSelection).forEach(index => {
        selectedIds.push(students?.[parseInt(index)].id);
    });

    return (
        <PromptDialog
            yesLabel={isLoadingInvite || isLoadingStudents ? "" : t("common.request")}
            noLabel={isLoadingInvite || isLoadingStudents ? "" : t("common.cancel")}
            open={opened}
            onClose={confirmed => {
                if (confirmed && requestData) {
                    if (requestData.requestType === "download") {
                        exportStudents(requestData.criteria === "all" ? {} : { ids: selectedIds });
                    } else {
                        setSendInvitationsDialogOpened(true);
                    }
                }
                if (!confirmed) {
                    handleClose();
                }
            }}
        >
            <Box>
                <Typography variant="h4">{t("students.requestModal.criteria")}</Typography>
                <RadioGroup
                    name="criteria"
                    value={requestData.criteria}
                    onChange={e => {
                        setRequestData({ ...requestData, criteria: e.target.value as any });
                    }}
                >
                    <FormControlLabel
                        value={"all"}
                        control={<Radio />}
                        label={
                            <Box>{`${t("students.requestModal.allStudents")} (${t(
                                "students.requestModal.allStudentsCount",
                            ).replace(":count", `${count}`)})`}</Box>
                        }
                        id="allStudentsRadio"
                    />
                    <FormControlLabel
                        value={"selected"}
                        control={<Radio disabled={ids === null || ids.length === 0} />}
                        label={
                            <Box>{`${t("students.requestModal.selectedStudents")} (${t(
                                "students.requestModal.selectedStudentsCount",
                            ).replace(":count", `${ids.length || 0}`)})`}</Box>
                        }
                        id="selectedStudentsRadio"
                    />
                </RadioGroup>
                <Divider />
                <Typography variant="h4">{t("students.requestModal.action")}</Typography>
                <RadioGroup
                    name="requestType"
                    value={requestData.requestType}
                    onChange={e => {
                        setRequestData({ ...requestData, requestType: e.target.value as any });
                    }}
                >
                    <FormControlLabel
                        value={"invite"}
                        control={<Radio />}
                        label={<Box>{t("students.requestModal.actionInvite")}</Box>}
                        id="inviteRequestRadio"
                    />
                    <FormControlLabel
                        value={"download"}
                        control={<Radio />}
                        label={<Box>{t("students.requestModal.actionDownloadParentReport")}</Box>}
                        id="downloadRequestRadio"
                    />
                </RadioGroup>
                {(isLoadingInvite || isLoadingStudents) && (
                    <Box mt={2} mb={0}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
            <PromptDialog
                open={sendInvitationsDialogOpened}
                onClose={confirmed => {
                    if (confirmed && requestData) {
                        inviteStudents(requestData.criteria === "all" ? {} : { ids: selectedIds });
                        setSendInvitationsDialogOpened(false);
                    }
                    if (!confirmed) {
                        setSendInvitationsDialogOpened(false);
                    }
                }}
                yesLabel={t("students.requestModal.actionInviteDialog.sendInvites")}
                noLabel={t("students.requestModal.actionInviteDialog.cancel")}
            >
                <Typography component="h3">
                    {t("students.requestModal.actionInviteDialog.header").replace(
                        ":count",
                        `${requestData.criteria === "all" ? count : ids.length || 0}`,
                    )}
                </Typography>
                <Typography component="h2">
                    {t("students.requestModal.actionInviteDialog.pleaseConfirm")}
                </Typography>
            </PromptDialog>
        </PromptDialog>
    );
};

export default StudentMassActionsModal;
