import { FC } from "react";
import { TrackerContext } from "../TrackerGrid";
import Icon from "src/components/Icon";
import { mdiLabelOutline, mdiLink, mdiLock } from "@mdi/js";
import { GridApi } from "ag-grid-community";
import Tag from "src/components/Tag";

interface OwnProps {
    context: TrackerContext;
    displayName: string;
    isTag?: boolean;
    locked: boolean;
    api: GridApi;
    column;
}

export const TagLinkHeader: FC<OwnProps> = ({
    displayName,
    isTag,
    locked,
    api: gridApi,
    column,
}) => {
    let counter: number = 0;

    gridApi.forEachNode(node => {
        if (node?.data[column.colId] === 1) counter++;
    });

    return (
        <div className="ag-column-header-row ag-column-header-row--bottom-icon">
            <div className="ag-column-header-icon">
                <Icon path={isTag ? mdiLabelOutline : mdiLink} />
            </div>
            <span className="ag-column-header-label ag-column-header-label--tags">
                <span>{displayName}</span>
                <b className="ag-column-header-label__tags">
                    {isTag && counter > 0 && (
                        <span className="ag-column-header-label__tag">
                            <Tag arrow="bottom">{counter}</Tag>
                        </span>
                    )}
                    {locked && (
                        <span className="ag-column-header-label__tag-lock-icon">
                            <Icon path={mdiLock} />
                        </span>
                    )}
                </b>
            </span>
        </div>
    );
};
