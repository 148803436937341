import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { ClassTrackerListResponse } from "src/modules/class/api/Classes/getClassTrackers";
import { LastUpdatedClassTrackerStatuses } from "src/modules/class/api/Classes/getLastUpdated";
import { OrmClassTrackerGroup } from ".";

export enum OrmClassTrackerGroupActionTypes {
    BULK_IMPORT = "[orm] CLASS TRACKER GROUP BULK IMPORT",
    UPSERT = "[orm] CLASS TRACKER GROUP UPSERT",
    CLEAR = "[orm] CLASS TRACKER GROUP LIST CLEAR",
    UPDATE_STATUS = "[orm] CLASS TRACKER UPDATE STATUSES",
}

export const OrmClassTrackerGroupActions = {
    bulkImport: (payload: ClassTrackerListResponse, cohort?: number) => {
        return createAction(OrmClassTrackerGroupActionTypes.BULK_IMPORT, { data: payload, cohort });
    },
    upsert: (payload: OrmClassTrackerGroup) =>
        createAction(OrmClassTrackerGroupActionTypes.UPSERT, payload),
    clear: () => createAction(OrmClassTrackerGroupActionTypes.CLEAR),
    updateStatus: (payload: LastUpdatedClassTrackerStatuses) =>
        createAction(OrmClassTrackerGroupActionTypes.UPDATE_STATUS, payload),
};

export type OrmClassTrackerGroupActions = ActionsUnion<typeof OrmClassTrackerGroupActions>;
