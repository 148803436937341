import clsx from "clsx";
import PinnedTopRenderer from "./PinnedTopRenderer";
import { ColDef } from "ag-grid-community";
import { FC } from "react";

interface OwnProps {
    data: any;
    context: any;
    colDef: ColDef;
    node: any;
}

export const CurrentClassRenderer: FC<OwnProps> = ({ context, data, colDef, node }) => {
    const { field } = colDef;

    if (node.rowPinned) {
        return (
            <PinnedTopRenderer
                field={field}
                value={field && data ? data[field] : ""}
                context={context}
            />
        );
    }

    const getClassTrackerName = () => {
        const classTrackerId = data["student_classTrackerId"];
        const classTracker = context.relatedData.classTrackers.find(c => c.id === classTrackerId);

        return classTracker && classTracker.id ? classTracker.name : data.classTracker?.name || "";
    };

    const isComparing = context.snapshotData && context.snapshotData.compare !== null;

    const displayName = context.yearGroupOverview
        ? getClassTrackerName()
        : context.classDetails.name;

    return (
        <div
            title={displayName}
            className={clsx(
                "ag-student-data-cell ag-student-data-cell--border-left",
                isComparing && "ag-student-data-cell--compare",
            )}
        >
            {displayName}
        </div>
    );
};
