import { useState } from "react";
import AppContainer from "src/components/AppContainer";
import { Typography, Paper, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import MyProfilesTab from "../components/MyProfilesTab";
import MyAccountTab from "../components/MyAccountTab";

export enum AccountTabs {
    ACCOUNT_TAB = "my-account",
    PROFILES_TAB = "my-profiles",
}

const AccountContainer = () => {
    const { t } = useTranslation();

    const [currentTab, setCurrentTab] = useState<string>(AccountTabs.ACCOUNT_TAB);
    const tabs = [
        {
            key: AccountTabs.ACCOUNT_TAB,
            label: t("account.myAccountTab.header"),
        },
        {
            key: AccountTabs.PROFILES_TAB,
            label: t("account.myProfilesTab.header"),
        },
    ];

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("account.header")}
            </Typography>
            <Tabs value={currentTab} onChange={(_e, v) => setCurrentTab(v)}>
                {tabs.map(tab => (
                    <Tab label={tab.label} key={tab.key} value={tab.key} />
                ))}
            </Tabs>
            <Paper>
                {currentTab === AccountTabs.ACCOUNT_TAB && <MyAccountTab />}
                {currentTab === AccountTabs.PROFILES_TAB && <MyProfilesTab />}
            </Paper>
        </AppContainer>
    );
};

export default AccountContainer;
