import { GridApi } from "ag-grid-community";
import { getAnalysisGradeValue } from "./analysisValueHandler";
import i18next from "i18next";

export const EXCEL_DEFAULT_PARAMS = { allColumns: true, columnGroups: true };
export const percentAnalysisClassColumns = [
    "numberCurrentAboveTarget",
    "numberCurrentOnTarget",
    "numberCurrenOneGradeAway",
    "numberCurrentOffTarget",
    "numberTargetNotSet",
    "numberProjectedAboveTarget",
    "numberProjectedOnTarget",
    "numberProjectedOneGradeAway",
    "numberProjectedOffTarget",
    "numberTargetNotSet",
];

export const exportAnalysisToExcel = (gridApi: GridApi, columnConfig, relatedData, viewType) => {
    gridApi.exportDataAsExcel({
        ...EXCEL_DEFAULT_PARAMS,
        processCellCallback: param => {
            const colId = param.column.getColId();
            let value = param.value;

            const column: string | undefined = param.column?.getColDef()?.field;
            let gradeTypeId: number | null = null;
            if (columnConfig && column) {
                gradeTypeId = columnConfig[column]?.gradeTypeId;
            }

            if (gradeTypeId) {
                value = getAnalysisGradeValue(value, relatedData, gradeTypeId, viewType);
            }

            if (value && param.node?.data?.metaColumnsConfig?.[colId]?.fineGrade) {
                const fineGrade = param.node?.data?.metaColumnsConfig?.[colId]?.fineGrade || "";
                value = value + fineGrade;
            }

            if (
                value &&
                column &&
                param.node?.data?.metaColumnsConfig &&
                param.node.data.metaColumnsConfig[column] &&
                param.node.data.metaColumnsConfig[column].tier
            ) {
                value = value + ` (${param.node.data.metaColumnsConfig[column].tier})`;
            }
            return value;
        },
    });
};

export const exportAnalysisKS4ToExcel = (gridApi: GridApi) => {
    gridApi.exportDataAsExcel({
        ...EXCEL_DEFAULT_PARAMS,
        processHeaderCallback: param => {
            const columnDef = param.column.getColDef();

            if (columnDef.headerName?.indexOf("A8__") === 0) {
                return "A8/P8 & Ebacc";
            }

            if (columnDef.headerName?.indexOf("tracker.") === 0) {
                return i18next.t(columnDef.headerName);
            }

            return columnDef.headerName || "";
        },
        processCellCallback: param => {
            const value = param.value;
            const colId = param.column.getColId();

            if (colId.indexOf("A8__") === 0) {
                const data = param.node?.data;

                if (!data) return value;

                let a8 = undefined;
                let ebacc = undefined;

                const currentRelatedField = colId.replace("A8__", "");
                if (currentRelatedField && data.metaColumnsConfig) {
                    a8 = data.metaColumnsConfig[currentRelatedField]?.a8;
                    ebacc = data.metaColumnsConfig[currentRelatedField]?.ebacc;
                }

                return `${a8 ? "8" : ""}${a8 && ebacc ? "+" : ""}${ebacc ? "E" : ""}`;
            }

            return value;
        },
    });
};

export const exportAnalysisAtlToExcel = (gridApi: GridApi) => {
    gridApi.exportDataAsExcel({
        ...EXCEL_DEFAULT_PARAMS,
        processHeaderCallback: param => {
            const columnDef = param.column.getColDef();

            if (columnDef.headerName?.indexOf("tracker.") === 0) {
                return i18next.t(columnDef.headerName);
            }

            return columnDef.headerName || "";
        },
        processCellCallback: param => {
            const value = param.value;
            if (value < 0) {
                if (value == -1) return "U";
                if (value == -2) return "X";
                if (value == -3) return "A";
            }
            return value;
        },
    });
};

export const exportSubjectAnalysisClassToExcel = (gridApi: GridApi) => {
    gridApi.exportDataAsExcel({
        ...EXCEL_DEFAULT_PARAMS,
        processHeaderCallback: param => {
            const columnDef = param.column.getColDef();
            return columnDef.headerName?.replace("<1/>", "") || "";
        },
        processCellCallback: param => {
            const value = param.value;
            const colId = param.column.getColId();
            if (colId === "teachers") {
                const teacherList: string[] = [];
                param.value.forEach(t => {
                    teacherList.push(`${t.title} ${t.firstName} ${t.lastName}`);
                });
                return teacherList.join(",");
            }
            if (percentAnalysisClassColumns.includes(colId)) {
                return Math.round((value / param.node?.data.numberOfStudents) * 100) + "%";
            }

            return value;
        },
    });
};

export const exportSubjectAnalysisSubgroupToExcel = (gridApi: GridApi) => {
    gridApi.exportDataAsExcel({
        ...EXCEL_DEFAULT_PARAMS,
        processHeaderCallback: param => {
            const columnDef = param.column.getColDef();
            return columnDef.headerName?.replace("<1/>", "") || "";
        },
        processCellCallback: param => {
            const value = param.value;
            const colId = param.column.getColId();

            if (percentAnalysisClassColumns.includes(colId)) {
                return (Math.round((value / param.node?.data.numberOfStudents) * 100) || 0) + "%";
            }

            return value;
        },
    });
};

export const exportSubjectAnalysisGradeBreakdownToExcel = (gridApi: GridApi, numberOfStudents) => {
    gridApi.exportDataAsExcel({
        ...EXCEL_DEFAULT_PARAMS,
        processHeaderCallback: param => {
            const columnDef = param.column.getColDef();
            return columnDef.headerName?.replace("<1/>", "") || "";
        },
        processCellCallback: param => {
            const value = param.value;
            const colId = param.column.getColId();

            if (["numberTargetWorkingThisGradeOrAbove"].includes(colId)) {
                return (Math.round((value / numberOfStudents) * 100) || 0) + "%";
            }

            if (["numberCurrentWorkingThisGradeOrAboveP"].includes(colId)) {
                return (
                    (Math.round(
                        (param.node?.data.numberCurrentWorkingThisGradeOrAbove / numberOfStudents) *
                            100,
                    ) || 0) + "%"
                );
            }

            if (["numberProjectedWorkingThisGradeOrAboveP"].includes(colId)) {
                return (
                    (Math.round(
                        (param.node?.data.numberProjectedWorkingThisGradeOrAbove /
                            numberOfStudents) *
                            100,
                    ) || 0) + "%"
                );
            }

            if (["numberTeacherWorkingThisGradeOrAboveP"].includes(colId)) {
                return (
                    (Math.round(
                        (param.node?.data.numberTeacherWorkingThisGradeOrAbove / numberOfStudents) *
                            100,
                    ) || 0) + "%"
                );
            }

            return value;
        },
    });
};
