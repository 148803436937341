import HeadingCounter from "src/components/HeadingCounter";
import CheckboxField from "src/forms/CheckboxField";
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrmClassTrackerGroup, TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { PublishedAssessmentUnitModel } from "../../models/AssessmentUnitModel";
import { ClassTrackerModel } from "src/modules/tracker/models/ClassTrackerModel";
import { AssessmentCreateFormShape } from "./AddAssessmentForm";
import { TFunction } from "i18next";
import * as yup from "yup";

export interface PublishedAssessmentFormShape {
    tier: TiersTypes;
    unit: number;
    unitName?: string;
    module: string;
    moduleType: string;
    hasModules: boolean;
    name: string;
    showQlaInReports?: boolean;
    countsTowardGrade: boolean;
    hiddenColumns: boolean;
    hideForNow: boolean;
    visibleClassTrackers: (ClassTrackerModel & { visible: boolean })[];
    savedAndCollapsed?: boolean;
}

export const publishedAssessmentsFromSchema = (t: TFunction) =>
    yup.array(
        yup.object({
            name: yup.string().required(t("common.form.validation.name.required")),
            countsTowardGrade: yup.boolean().notRequired(),
            unit: yup
                .number()
                .min(1, t("common.form.validation.unit.required"))
                .required(t("common.form.validation.unit.required")),
            module: yup.mixed().when("hasModules", ([hasModules]) => {
                return hasModules
                    ? yup.number().required(t("common.form.validation.module.required"))
                    : yup.mixed().notRequired().nullable();
            }),
        }),
    );

interface OwnProps {
    tiers: TiersTypes[] | null;
    index: number;
    classList?: OrmClassTrackerGroup[];
    units: PublishedAssessmentUnitModel[];
    handleTierChange: (tier: TiersTypes) => void;
}

const AddPublishedAssessmentForm: FC<OwnProps> = ({
    handleTierChange,
    classList,
    units,
    tiers,
    index,
}) => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<AssessmentCreateFormShape>();
    const [moduleTypes, setModuleTypes] = useState<string[]>([t("common.all")]);
    const [modules, setModules] = useState([]);

    useEffect(() => {
        if (tiers !== null && tiers.length > 0 && values.publishedAssessments[index].tier === "-") {
            setFieldValue(`publishedAssessments.${index}.tier`, tiers[0]);
        }
    }, [tiers]);

    useEffect(() => {
        handleTierChange(values.publishedAssessments[index].tier);
    }, [values.publishedAssessments[index].tier]);

    useEffect(() => {
        if (values.publishedAssessments[index].hideForNow && classList) {
            setFieldValue(
                `publishedAssessments.${index}.visibleClassTrackers`,
                values.publishedAssessments[index].visibleClassTrackers.map(c => ({
                    ...c,
                    visible: c.visible || false,
                })),
            );
        }
    }, [values.publishedAssessments[index].hideForNow]);

    useEffect(() => {
        if (values.publishedAssessments[index].visibleClassTrackers.length > 0) {
            setFieldValue(
                `publishedAssessments.${index}.hideForNow`,
                !values.publishedAssessments[index].visibleClassTrackers.find(
                    c => c.visible === true,
                ),
            );
        }
    }, [values.publishedAssessments[index].visibleClassTrackers]);

    useEffect(() => {
        if (values.publishedAssessments[index].unit) {
            const unitModel = units?.find(u => u.id === values.publishedAssessments[index].unit);
            if (unitModel?.modules) {
                setModules(unitModel.modules);
                if (unitModel?.modules.length === 1) {
                    setFieldValue(`publishedAssessments.${index}.module`, unitModel?.modules[0].id);
                } else {
                    setFieldValue(`publishedAssessments.${index}.module`, "");
                }
            }
        }
    }, [values.publishedAssessments[index].unit]);

    useEffect(() => {
        if (modules?.length > 0) {
            const moduleTypes = modules.reduce(
                (moduleTypesList, module) => {
                    if (module?.customType && !moduleTypesList.find(m => m === module.customType)) {
                        return moduleTypesList.concat(module?.customType);
                    }
                    return moduleTypesList;
                },
                [t("common.all")],
            );

            setModuleTypes(moduleTypes);
            setFieldValue(`publishedAssessments.${index}.hasModules`, true);
        }
    }, [modules]);

    useEffect(() => {
        const classTrackers = [];

        classList
            ?.filter(
                ctg =>
                    ctg.yearGroup === values.yearGroup &&
                    ctg.specification.id === values.specification,
            )
            .forEach(ctg => {
                ctg.classTrackers.forEach((classTracker, cti) =>
                    classTrackers.push({
                        ...classTracker,
                        visible: values.publishedAssessments[index].savedAndCollapsed
                            ? values.publishedAssessments[index]?.visibleClassTrackers[cti]?.visible
                            : true,
                    }),
                );
            });

        setFieldValue(`publishedAssessments.${index}.visibleClassTrackers`, classTrackers);
    }, [classList, values.yearGroup, values.specification]);

    const isMultiCheckboxChecked = !values.publishedAssessments[index]?.visibleClassTrackers.find(
        vct => vct.visible === false,
    );

    const currentUnitIsRepeated = units?.find(
        u => u.id === values.publishedAssessments[index].unit,
    )?.isRepeated;

    return (
        <>
            <Box>
                <HeadingCounter number={2}>{t("class.assessment.add.configure")}</HeadingCounter>

                {!values.specification && t("class.assessment.add.pleaseSelectSpecification")}

                {values.specification && (
                    <>
                        <Grid container spacing={4} sx={{ marginBottom: 4 }}>
                            {values.publishedAssessments?.[index].tier !== "-" ? (
                                <Grid item sm={4}>
                                    <Field
                                        name={`publishedAssessments.${index}.tier`}
                                        label={t("class.assessment.add.tier")}
                                        component={TextField}
                                        select
                                    >
                                        {tiers?.map(tier => (
                                            <MenuItem key={tier} value={tier}>
                                                {t("common.tierName" + tier)}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid container spacing={4} sx={{ marginBottom: 4 }}>
                            {units && units.length > 0 ? (
                                <Grid item sm={4}>
                                    <Field
                                        name={`publishedAssessments.${index}.unit`}
                                        label={t("class.assessment.add.unit")}
                                        component={TextField}
                                        select
                                        displayEmpty
                                    >
                                        <MenuItem value={0}>
                                            {t("class.assessment.add.selectUnit")}
                                        </MenuItem>
                                        {units?.map(unit => (
                                            <MenuItem key={unit.id} value={unit.id}>
                                                {unit.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                            ) : (
                                <Grid item sm={4}>
                                    <Typography>{t("class.assessment.add.noUnits")}</Typography>
                                </Grid>
                            )}
                            {units?.length > 0 && modules?.length > 0 ? (
                                <>
                                    {moduleTypes?.length > 1 ? (
                                        <Grid item sm={4}>
                                            <Field
                                                name={`publishedAssessments.${index}.moduleType`}
                                                label={t("class.assessment.add.moduleType")}
                                                component={TextField}
                                                select
                                            >
                                                {moduleTypes?.map(moduleType => (
                                                    <MenuItem key={moduleType} value={moduleType}>
                                                        {moduleType}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                    {modules?.length > 1 ? (
                                        <Grid item sm={4}>
                                            <Field
                                                name={`publishedAssessments.${index}.module`}
                                                label={t("class.assessment.add.module")}
                                                component={TextField}
                                                displayEmpty
                                                select
                                            >
                                                {modules
                                                    ?.filter(m => {
                                                        if (
                                                            values.publishedAssessments[index]
                                                                .moduleType !== t("common.all")
                                                        ) {
                                                            return (
                                                                m.customType ===
                                                                values.publishedAssessments[index]
                                                                    .moduleType
                                                            );
                                                        }
                                                        return true;
                                                    })
                                                    .map(module => (
                                                        <MenuItem key={module.id} value={module.id}>
                                                            {module.name +
                                                                (module.paperCode
                                                                    ? ` | ` + module.paperCode
                                                                    : "")}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </Grid>
                                    ) : (
                                        <Grid item sm={4}>
                                            {currentUnitIsRepeated &&
                                                t("class.assessment.add.repeatableStructure")}
                                        </Grid>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </Grid>

                        {units?.length > 0 ? (
                            <>
                                <Grid container spacing={4} sx={{ marginBottom: 3 }}>
                                    <Grid item sm={4}>
                                        <Field
                                            name={`publishedAssessments[${index}].name`}
                                            label={t("class.assessment.add.name")}
                                            component={TextField}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ mb: 4 }}>
                                    <Field
                                        name={`publishedAssessments.${index}.countsTowardGrade`}
                                        component={CheckboxField}
                                        label={t("class.assessment.add.countsTowardGrade")}
                                    />
                                    {!currentUnitIsRepeated && (
                                        <Field
                                            name={`publishedAssessments.${index}.showQlaInReports`}
                                            component={CheckboxField}
                                            label={t("class.assessment.add.showQlaInReports")}
                                        />
                                    )}
                                </Box>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </Box>
            {units && units.length > 0 ? (
                <Box>
                    <HeadingCounter number={3}>
                        {t("class.assessment.add.targetClasses")}
                    </HeadingCounter>
                    {!!(!values.specification || values.yearGroup === null) &&
                        t("class.assessment.add.pleaseSelectYg")}
                    {values.specification &&
                    values.yearGroup !== null &&
                    values.publishedAssessments[index]?.visibleClassTrackers.length > 0 ? (
                        <>
                            <Typography gutterBottom>
                                {t("class.assessment.add.chooseClassHint")}
                            </Typography>
                            <Box height="100%" bgcolor="#f9f9f9" width={500} p={4}>
                                <Box mb={2}>
                                    <strong>
                                        {t("common.yearGroup") +
                                            " " +
                                            (values.yearGroup === 0
                                                ? t("common.yg0")
                                                : values.yearGroup)}
                                    </strong>
                                </Box>
                                <Field
                                    name={`publishedAssessments.${index}.hideForNow`}
                                    component={CheckboxField}
                                    label={t("class.assessment.add.hideForNow")}
                                    disabled={values.publishedAssessments[
                                        index
                                    ].visibleClassTrackers.find(vct => vct.visible)}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isMultiCheckboxChecked}
                                            name=""
                                            onChange={() => {
                                                setFieldValue(
                                                    `publishedAssessments.${index}.visibleClassTrackers`,
                                                    values.publishedAssessments[
                                                        index
                                                    ].visibleClassTrackers.map(vct => ({
                                                        ...vct,
                                                        visible: !isMultiCheckboxChecked,
                                                    })),
                                                );
                                            }}
                                        />
                                    }
                                    label={t("class.assessment.add.selectDeselectAll")}
                                />
                                {values.publishedAssessments[index]?.visibleClassTrackers.map(
                                    (visibleClassTracker, vctI) => (
                                        <Box key={visibleClassTracker.id}>
                                            <Field
                                                name={`publishedAssessments.${index}.visibleClassTrackers.${vctI}.visible`}
                                                component={CheckboxField}
                                                label={visibleClassTracker.name}
                                            />
                                        </Box>
                                    ),
                                )}
                            </Box>
                        </>
                    ) : (
                        t("class.assessment.add.noClasses")
                    )}
                </Box>
            ) : (
                <></>
            )}
        </>
    );
};

export default AddPublishedAssessmentForm;
