import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSpecificationAttribute } from "src/orm/models/SpecificationAttribute";
import { getSchoolAccountId } from "src/services/url";

export const commonSpecificationAttributesEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/common/specification/{specification}/attributes/`,
    method: ApiRequests.GET,
};

export type CommonSpecificationAttributesResponse = OrmSpecificationAttribute;
