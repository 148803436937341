import { Component } from "react";
import clsx from "clsx";
import { Translation } from "react-i18next";

interface OwnProps {
    value: string;
    context: any;
}

export default class StudentDataRenderer extends Component<OwnProps> {
    normalizeValue = value => {
        if (value === true) {
            return <Translation>{t => <p>{t("common.yes")}</p>}</Translation>;
        }

        if (value === false) {
            return <Translation>{t => <p>{t("common.no")}</p>}</Translation>;
        }

        if (value && value.name) return value.name;

        if (value && value.id) return value.id;

        return value;
    };

    render() {
        return (
            <div
                className={clsx(
                    "ag-student-data-cell",
                    this.props.context.compareValues &&
                        this.props.context.compareValues !== null &&
                        "ag-student-data-cell--compare",
                )}
            >
                {this.normalizeValue(this.props.value)}
            </div>
        );
    }
}
