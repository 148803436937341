import { Box, useTheme, useMediaQuery, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import logotype from "src/assets/logotype.svg";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import COLORS from "src/styles/colors";
import { mdiWindowClose, mdiCellphoneOff } from "@mdi/js";
import { ROUTE_AUTH_BASE } from "src/routes";
import { useLocation } from "react-router";

const useStyles = makeStyles(() => ({
    logo: {
        "& > img": {
            display: "block",
            width: "100%",
            height: "auto",
        },
    },
    btn: {
        position: "absolute",
        top: 16,
        right: 16,
    },
}));

const MobileLock = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [isVisible, setIsVisible] = useState(isMobile);
    const location = useLocation();
    const isAuthPath = !!(location.pathname.indexOf(ROUTE_AUTH_BASE) === 0);

    useEffect(() => {
        if (isMobile && !isVisible && !isAuthPath) {
            setIsVisible(true);
        }
    }, [isMobile]);

    return (
        <Box
            bgcolor="#fff"
            position="fixed"
            top={0}
            right={0}
            bottom={0}
            left={0}
            zIndex={99999}
            display={isVisible ? "flex" : "none"}
            alignItems="center"
            justifyContent="center"
        >
            <IconButton
                aria-label="close"
                className={classes.btn}
                onClick={() => setIsVisible(false)}
                size="small"
            >
                <Icon size="32px" color={COLORS.LIGHT_GREY_1} path={mdiWindowClose} />
            </IconButton>
            <Box maxWidth={240} textAlign="center">
                <Box
                    component="figure"
                    width={160}
                    mt={0}
                    mr="auto"
                    mb={4.5}
                    ml="auto"
                    className={classes.logo}
                >
                    <img src={logotype} alt={t("common.logoAlt")} />
                </Box>
                <Typography variant="body1" gutterBottom>
                    {t("mobileLock.text1")}
                </Typography>
                <Typography variant="body1">{t("mobileLock.text2")}</Typography>
                <Box mt={4.5}>
                    <Icon size="88px" color={COLORS.LIGHT_GREY_1} path={mdiCellphoneOff} />
                </Box>
            </Box>
        </Box>
    );
};

export default MobileLock;
