import Dialog from "src/components/Dialog";
import FormikRef from "src/forms/FormikRef";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useRef } from "react";
import { useClassTrackerLinksList } from "src/modules/links/hooks/ClassTracker/useClassTrackerLinks";
import {
    LinkCreateRequest,
    useCreateLinkMutation,
} from "src/modules/links/hooks/useCreateMutation";
import { useEditLinkMutation } from "src/modules/links/hooks/useEditMutation";
import { useLinkDetails } from "src/modules/links/hooks/useLinkDetails";
import LinkAddEditForm, {
    LinkAddEditFormShape,
    addEditLinkSchema,
} from "src/modules/links/components/forms/LinkAddEditForm";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { LinkDetailedModel } from "src/modules/links/model/LinkModel";

interface OwnProps {
    opened: boolean;
    linkId: number;
    classTrackerId: number;
    specificationId?: number;
    yearGroup?: number;
    handleClose: (reload?: boolean) => void;
}

const LinksUpsertModal: FC<OwnProps> = ({
    opened,
    classTrackerId,
    linkId,
    specificationId,
    yearGroup,
    handleClose,
}) => {
    const { t } = useTranslation();
    const form = useRef();
    const { refetch } = useClassTrackerLinksList(classTrackerId);
    const { data: accountInfo } = useAccountInfo();

    const handleErrorResponse = useCallback(
        errors => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            if (errors) currentForm.setErrors(errors);
        },
        [form],
    );

    const { mutate: addLink } = useCreateLinkMutation(
        () => {
            refetch();
            handleClose(true);
        },
        ({ errors }) => handleErrorResponse(errors),
    );
    const { mutate: editLink } = useEditLinkMutation(
        () => {
            refetch();
            handleClose();
        },
        ({ errors }) => handleErrorResponse(errors),
    );

    const { data: linkDetails } = useLinkDetails(linkId);

    const initialValues = {
        specification: linkDetails?.specification?.id || specificationId,
        academicYear: linkDetails?.academicYear?.id || accountInfo?.activeCohortId,
        status: linkDetails?.active ? "active" : "inactive",
        yearGroup: linkDetails?.yearGroup || yearGroup,
        url: linkDetails?.url || "",
        title: linkDetails?.title || "",
        description: linkDetails?.description || "",
    };

    const handleSubmit = ({
        specification,
        academicYear,
        status,
        ...restValues
    }: LinkAddEditFormShape) => {
        const data: LinkCreateRequest = {
            ...restValues,
            specification: { id: specification },
            academicYear: { id: academicYear },
            active: status === "active",
        };
        if (linkId) {
            editLink({ id: linkId, ...data });
        } else {
            addLink(data);
        }
    };

    return opened ? (
        <FormikRef
            ref={form}
            initialValues={initialValues}
            validationSchema={() => addEditLinkSchema(t)}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ submitForm, errors, submitCount, isSubmitting }) => (
                <Dialog
                    open={opened}
                    onClose={() => handleClose()}
                    title={t("tracker.grid.links.headerAlt")}
                    actions={
                        <>
                            <Button onClick={submitForm} color="primary" disabled={isSubmitting}>
                                {t("tracker.grid.links.saveBtn")}
                            </Button>
                            <Button onClick={() => handleClose()} color="inherit">
                                {t("common.cancel")}
                            </Button>
                        </>
                    }
                    contentOverflow="visible"
                >
                    <Box sx={{ mx: -6, mt: -3 }}>
                        <LinkAddEditForm
                            linkDetails={
                                linkDetails ||
                                ({
                                    specification: { id: initialValues.specification },
                                    yearGroup: initialValues.yearGroup,
                                } as LinkDetailedModel)
                            }
                        />
                    </Box>
                    <GenericErrorMessage errors={errors} submitCount={submitCount} />
                </Dialog>
            )}
        </FormikRef>
    ) : (
        <></>
    );
};

export default LinksUpsertModal;
