import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction, paramRequest } from "src/api/helpers";
import { StudentFilterRequest } from "../../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { AnalysisAtlHeaderObject, getAnalysisAtlHeaderEndpoint } from "../../api/Atl/getHeader";
import {
    getAnalysisAtlSnapshotCompareEndpoint,
    AtlSnapshotCompareResponse,
} from "../../api/Atl/getSnapshotCompare";
import { AnalysisAtlValuesObject, getAnalysisAtlValuesEndpoint } from "../../api/Atl/getValues";
import { getAnalysisAtlSnapshotValuesEndpoint } from "../../api/Atl/getSnapshotValues";

export enum AnalysisAtlActionsTypes {
    GET_ANALYSIS_ATL_HEADER = "[analysis] GET ANALYSIS ATL HEADER",
    GET_ANALYSIS_ATL_HEADER_SUCCESS = "[analysis] GET ANALYSIS ATL HEADER SUCCESS",
    SET_ANALYSIS_ATL_HEADER = "[analysis] SET ANALYSIS ATL HEADER",
    GET_ANALYSIS_ATL_VALUES = "[analysis] GET ANALYSIS ATL VALUES",
    GET_ANALYSIS_ATL_VALUES_SUCCESS = "[analysis] GET ANALYSIS ATL VALUES SUCCESS",
    SET_ANALYSIS_ATL_VALUES = "[analysis] SET ANALYSIS ATL VALUES",
    GET_ANALYSIS_ATL_SNAPSHOT = "[analysis] GET ANALYSIS ATL SNAPSHOT ",
    GET_ANALYSIS_ATL_SNAPSHOT_SUCCESS = "[analysis] GET ANALYSIS ATL SNAPSHOT SUCCESS",
    GET_ANALYSIS_ATL_SNAPSHOT_COMPARE = "[analysis] GET ANALYSIS ATL SNAPSHOT COMPARE",
    GET_ANALYSIS_ATL_SNAPSHOT_COMPARE_SUCCESS = "[analysis] GET ANALYSIS ATL SNAPSHOT COMPARE SUCCESS",
    SET_ANALYSIS_ATL_COMPARE = "[analysis] SET ANALYSIS ATL COMPARE",
}

export const AnalysisAtlApiEndpoints = {
    getAnalysisAtlHeader: createActionObject(
        getAnalysisAtlHeaderEndpoint,
        AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_HEADER_SUCCESS,
    ),
    getAnalysisAtlValues: createActionObject(
        getAnalysisAtlValuesEndpoint,
        AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_VALUES_SUCCESS,
    ),
    getAnalysisAtlSnapshot: createActionObject(
        getAnalysisAtlSnapshotValuesEndpoint,
        AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_SNAPSHOT_SUCCESS,
    ),
    getAnalysisAtlSnapshotCompare: createActionObject(
        getAnalysisAtlSnapshotCompareEndpoint,
        AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_SNAPSHOT_COMPARE_SUCCESS,
    ),
};

export const AnalysisAtlRawActions = (
    AnalysisAtlEndpoints: Record<Keys<typeof AnalysisAtlApiEndpoints>, ApiActionMeta>,
) => ({
    getAnalysisAtlHeader: (cohort: number, yearGroup: number) =>
        createApiAction(
            AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_HEADER,
            paramRequest({ cohort, yearGroup }),
            AnalysisAtlEndpoints.getAnalysisAtlHeader,
        ),
    getAnalysisAtlHeaderSuccess: (payload: ApiCallbackPayload<AnalysisAtlHeaderObject>) =>
        createAction(AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_HEADER_SUCCESS, payload),
    setAnalysisAtlHeader: (payload: AnalysisAtlHeaderObject) =>
        createAction(AnalysisAtlActionsTypes.SET_ANALYSIS_ATL_HEADER, payload),
    getAnalysisAtlValues: (
        cohort: number,
        yearGroup: number,
        studentFilters: StudentFilterRequest,
    ) =>
        createApiAction(
            AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_VALUES,
            { values: studentFilters, params: { cohort, yearGroup } },
            AnalysisAtlEndpoints.getAnalysisAtlValues,
        ),
    getAnalysisAtlValuesSuccess: (payload: ApiCallbackPayload<AnalysisAtlValuesObject>) =>
        createAction(AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_VALUES_SUCCESS, payload),
    setAnalysisAtlValues: (payload: AnalysisAtlValuesObject) =>
        createAction(AnalysisAtlActionsTypes.SET_ANALYSIS_ATL_VALUES, payload),
    getAnalysisAtlSnapshot: (
        cohort: number,
        yearGroup: number,
        snapshot: number,
        studentFilters: StudentFilterRequest,
    ) =>
        createApiAction(
            AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_SNAPSHOT,
            {
                values: studentFilters,
                params: { cohort, yearGroup, snapshot },
            },
            AnalysisAtlEndpoints.getAnalysisAtlSnapshot,
        ),
    getAnalysisAtlSnapshotSuccess: (payload: ApiCallbackPayload<AnalysisAtlValuesObject>) =>
        createAction(AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_SNAPSHOT_SUCCESS, payload),
    getAnalysisAtlSnapshotCompare: (
        cohort: number,
        yearGroup: number,
        dataSource1: string,
        dataSource2: string,
        studentFilters: StudentFilterRequest,
    ) =>
        createApiAction(
            AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_SNAPSHOT_COMPARE,
            {
                values: studentFilters,
                params: { cohort, yearGroup, dataSource1, dataSource2 },
            },
            AnalysisAtlEndpoints.getAnalysisAtlSnapshotCompare,
        ),
    getAnalysisAtlSnapshotCompareSuccess: (
        payload: ApiCallbackPayload<AtlSnapshotCompareResponse>,
    ) => createAction(AnalysisAtlActionsTypes.GET_ANALYSIS_ATL_SNAPSHOT_COMPARE_SUCCESS, payload),
    setAnalysisAtlCompare: (payload: AtlSnapshotCompareResponse) =>
        createAction(AnalysisAtlActionsTypes.SET_ANALYSIS_ATL_COMPARE, payload),
});
