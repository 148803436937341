import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { AssessmentListModel } from "../../models/AssessmentListModel";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface CustomisedAssessmentRequest {
    name: string;
    countsTowardGrade: boolean;
    hiddenColumns: boolean;
    gradeBoundaryFactor: number;
    examDate: string;
    showQlaInReports: boolean;
    classTrackerUnitConfigs: {
        classTracker: {
            id: number;
        };
        visible: boolean;
    }[];
    personalisedAssesmentQuestions: {
        id: number;
        question: string;
        maxValue: number;
        topics: {
            id: number;
        }[];
    }[];
    gradeBoundaryValues: {
        id: number;
        requireMark: number;
    }[];
}

const apiUpdateCustomisedAssessment = async (
    id: number,
    values: CustomisedAssessmentRequest,
): Promise<AxiosResponse<AssessmentListModel>> => {
    return await axiosInstance.put(
        `school/${getSchoolAccountId()}/assessments/customised/${id}/`,
        values,
    );
};

export const useCustomisedAssessmentEditMutation = (id: number, onSuccess, onError) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: CustomisedAssessmentRequest) =>
            apiUpdateCustomisedAssessment(id, values),

        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError) {
                onError();
            }
        },
    });
};
