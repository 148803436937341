import { Box, styled, Theme } from "@mui/material";
import { withTheme } from "@mui/styles";
import COLORS from "src/styles/colors";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";

export const MenuChoosePrimaryButton = styled("button")(() => ({
    cursor: "pointer",
    color: COLORS.GREY_1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "0 none",
    outline: "0 none",
    padding: 0,
    margin: 0,
    backgroundColor: "transparent",
    "& .MuiTypography-root": {
        paddingRight: 50,
    },
}));

export const MenuChooseSecondaryLabel = styled(withTheme(Box))(() => ({
    fontSize: 14,
    lineHeight: 1.36,
    color: COLORS.LIGHT_GREY_1,
    position: "absolute",
    top: 0,
    left: 0,
    transform: "scale(0.78)",
    transformOrigin: "top left",
}));

export const MenuChooseSecondaryButton = styled(withTheme(Box))((theme: Theme) => ({
    cursor: "pointer",
    color: COLORS.GREY_3,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "0 none",
    outline: "0 none",
    padding: 0,
    margin: `${theme.spacing(2)}px 0 0`,
    backgroundColor: "transparent",
    "& .MuiTypography-root": {
        color: COLORS.GREY_3,
        fontFamily: PRIMARY_FONT_FAMILY,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        paddingRight: theme.spacing(2),
    },
    "& svg": {
        color: COLORS.LIGHT_GREY_1,
    },
}));
