import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface MigrateClassesRequest {
    pendingClasses: { id: number }[];
}

const apiMigrateClasses = async (values: MigrateClassesRequest) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/migrate-classes/migrate/`,
        values,
    );
};

export const useMigrateClassesMutation = onSuccess => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: MigrateClassesRequest) => apiMigrateClasses(values),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
