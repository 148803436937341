import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import { FC, useState } from "react";
import { mdiMinus, mdiPlus } from "@mdi/js";
import { ColumnGroup } from "ag-grid-community";
import { DEFAULT_FIELD_OPACITY, hexToRGB } from "src/modules/tracker/services/colorHandler";
import { UnitTypes } from "src/orm/models/ClassTracker";
import { useMount } from "src/hooks/useMount";
import { CELL_SIZE_MIN } from "src/modules/tracker/services/headerGenerator";

interface OwnProps {
    displayName: string;
    level: number;
    context: { expanded: {}; initialExpandSettings: boolean };
    columnGroup: ColumnGroup;
    customColor: string;
    showTitle?: boolean;
    unitType?: string | null;
    setExpanded: (v: boolean) => void;
}

export const UnitGroupHeader: FC<OwnProps> = ({
    columnGroup,
    context,
    setExpanded,
    unitType,
    level,
    customColor,
    displayName,
    showTitle,
}) => {
    const getExpandedState = () => {
        return columnGroup?.getProvidedColumnGroup()?.isExpanded() ? "expanded" : "collapsed";
    };

    const [expandState, setExpandState] = useState(getExpandedState());

    const expandOrCollapse = () => {
        const currentState = columnGroup?.getProvidedColumnGroup()?.isExpanded();
        setExpanded(!currentState);
    };

    const syncExpandButtons = () => setExpandState(getExpandedState());

    useMount(() => {
        columnGroup
            ?.getProvidedColumnGroup()
            ?.addEventListener("expandedChanged", syncExpandButtons);

        syncExpandButtons();

        const providedColumnGroup: any = columnGroup?.getProvidedColumnGroup();
        const expandedGroups = context?.expanded;

        if (context?.initialExpandSettings) {
            setExpanded(!!(unitType && unitType === UnitTypes.SUMMARY));
        } else {
            if (expandedGroups) {
                const isInitiallyExpanded = expandedGroups[`cg-${columnGroup?.getGroupId()}`];

                if (isInitiallyExpanded === undefined) {
                    expandOrCollapse();
                } else if (!providedColumnGroup?.isExpanded() && !isInitiallyExpanded) {
                    setExpanded(true);
                }
            }
        }
    });

    if (!columnGroup) {
        return <></>;
    }

    return (
        <div
            style={{
                top: `${(level - 1) * CELL_SIZE_MIN}px`,
                backgroundColor: hexToRGB(customColor || "#fefefe", DEFAULT_FIELD_OPACITY),
            }}
            className="ag-unit-header__row"
        >
            {columnGroup?.isExpandable() && (
                <div onClick={expandOrCollapse} className="ag-expand-icon">
                    <Icon
                        size="14px"
                        path={expandState === "collapsed" ? mdiMinus : mdiPlus}
                        color={COLORS.WHITE}
                    />
                </div>
            )}
            <div className="ag-unit-header__label">{showTitle === false ? "" : displayName}</div>
        </div>
    );
};
