import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import { getSchoolAccountId } from "src/services/url";

export const CLASSES_FOR_SPECYFICATION_QUERY = "classesForSpecificationQuery";
export type ClassesForSpecificationResponse = OrmClassTracker[];

export const useClassesForSpecification = (
    cohort: number,
    yearGroup: number,
    specification: number,
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            CLASSES_FOR_SPECYFICATION_QUERY,
            schoolAccountId,
            cohort,
            yearGroup,
            specification,
        ],
        queryFn: async (): Promise<ClassesForSpecificationResponse> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/analysis/subject-analysis/${cohort}/${yearGroup}/specifications/${specification}/classes/`,
            );
            return data || [];
        },
    });
};
