import MultipleSelectField from "src/forms/MultipleSelect";
import { MenuItem, Grid, Box } from "@mui/material";
import { usePrevious } from "src/hooks/usePrevious";
import { usersSelector } from "src/modules/user/selectors/UsersSelectors";
import { UserActions } from "src/modules/user/store/actions";
import { OrmUser } from "src/orm/models/User";
import { AppState } from "src/store/reducers";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { SingleClassForm } from "../../../containers/AddClass/ManualExistingStudentsContainer";
import { EditClassFormFields } from "../../ClassTracking/forms/EditClassForm";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useCoreValues } from "src/modules/common/hooks/useCore";

const dispatchActions = (dispatch: Dispatch) => ({
    getTeachers: () => {
        dispatch(UserActions.getUserTeachersList());
    },
});

type OwnProps = {
    lockYearGroup?: boolean;
    isSyncedClass?: boolean;
};

const ClassDetailsForm: FC<OwnProps> = ({ lockYearGroup, isSyncedClass }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data: accountInfo } = useAccountInfo();
    const { getTeachers } = dispatchActions(dispatch);
    const { values: formValues, setFieldValue } = useFormikContext<
        SingleClassForm | EditClassFormFields
    >();
    const activeCohortId = accountInfo?.activeCohortId || null;
    const specificationId = formValues.specification;
    const [deactivatedTeachers, setDeactivatedTeachers] = useState<any[]>([]);

    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const yearGroups = data?.yearGroups;

    const { teachers }: { teachers: OrmUser[] } = useSelector(
        (state: AppState) => ({
            teachers: usersSelector(state),
        }),
        shallowEqual,
    );

    useEffect(() => {
        if (accountInfo.activeCohortId && accountInfo.activeCohortId !== formValues.cohort) {
            setFieldValue("cohort", accountInfo.activeCohortId);
        }
    }, [accountInfo.activeCohortId]);

    const prevSpecificationId = usePrevious(specificationId);

    useEffect(() => {
        if (prevSpecificationId !== specificationId) {
            getTeachers();
        }
    }, [getTeachers, prevSpecificationId, specificationId]);

    const convertedTechers = teachers.map(teacher => ({
        ...teacher,
        name: (teacher.title || "") + ` ${teacher.firstName} ${teacher.lastName}`,
    }));

    const prevTeachers = usePrevious(formValues.teachers);

    useEffect(() => {
        if (!prevTeachers && formValues.teachers) {
            setDeactivatedTeachers(
                formValues?.teachers && formValues?.teachers.length > 0
                    ? (formValues.teachers as any).filter(t => t.status === "deactivated")
                    : [],
            );
        }
    }, [formValues.teachers, prevTeachers]);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item sm={4}>
                    <Field
                        name="name"
                        label={t("class.addClass.className")}
                        disabled={isSyncedClass}
                        component={TextField}
                    />
                </Grid>
                <Grid item sm={4}>
                    <Field
                        name="yearGroup"
                        label={t("class.addClass.yearGroup")}
                        component={TextField}
                        select
                        disabled={isSyncedClass || lockYearGroup || false}
                        margin="normal"
                    >
                        <MenuItem key={-1} value={-1}>
                            {t("common.noneSelect")}
                        </MenuItem>
                        {yearGroups?.map(year => (
                            <MenuItem key={year.id} value={year.id}>
                                {year.name}
                            </MenuItem>
                        ))}
                    </Field>
                    {cohorts?.length > 0 && (
                        <Field
                            name="cohort"
                            label={t("class.addClass.cohort")}
                            component={TextField}
                            disabled={isSyncedClass}
                            select
                        >
                            {cohorts.map(cohort => (
                                <MenuItem
                                    key={cohort.id}
                                    disabled={
                                        cohort.id !== activeCohortId
                                        // cohort.id !== migrationCohort
                                    }
                                    value={cohort.id}
                                >
                                    {cohort.name}
                                </MenuItem>
                            ))}
                        </Field>
                    )}
                </Grid>
                <Grid item sm={4}>
                    <Field
                        name="teachers"
                        placeholder={t("class.addClass.teachers")}
                        helperText={t("class.addClass.noTeachers")}
                        label=" "
                        options={convertedTechers}
                        component={MultipleSelectField}
                        disabled={isSyncedClass}
                        margin="none"
                    />
                    {deactivatedTeachers && deactivatedTeachers.length > 0 && (
                        <Box mt={2}>
                            Deactivated:{" "}
                            {deactivatedTeachers.map(t => t.firstName + " " + t.lastName).join(",")}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ClassDetailsForm;
