import AppContainer from "src/components/AppContainer";
import FormikRef from "src/forms/FormikRef";
import EditPastPaperForm, {
    convertFormDataToApiRequest,
    editPastPaperSchema,
} from "../components/forms/EditPastPaperForm";
import { useRef } from "react";
import { Typography, Paper, CircularProgress, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ROUTE_CLASS_ASSESSMENT_LIST } from "src/routes";
import { useLocation, useParams } from "react-router";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { usePublishedAssessmentDetails } from "../hooks/Published/useDetails";
import { useSnackbar } from "notistack";
import { usePublishedAssessmentEditMutation } from "../hooks/Published/useEditMutation";
import { useMount } from "src/hooks/useMount";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const EditPastPaperContainer = () => {
    const form = useRef() as any;
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();
    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const { enqueueSnackbar } = useSnackbar();
    const { pastPaper: pastPaperId, cohort } = useParams();
    const { data: pastPaper, refetch } = usePublishedAssessmentDetails(parseInt(pastPaperId));
    const location = useLocation();
    const { qualification, subjectArea, yearGroup } = Object.fromEntries(
        new URLSearchParams(location.search),
    );
    const { mutate: editPastPaper } = usePublishedAssessmentEditMutation(
        parseInt(pastPaperId),
        () => {
            enqueueSnackbar(t("class.pastPaper.add.success"), {
                variant: "success",
            });
            form.current.setSubmitting(false);
            navigate(
                ROUTE_CLASS_ASSESSMENT_LIST.replace(":cohort", cohort) +
                    `?subjectArea=${subjectArea || ""}&qualification=${
                        qualification || ""
                    }&yearGroup=${yearGroup || ""}`,
            );
        },
        () => {
            form.current.setSubmitting(false);
        },
    );

    const handleSubmit = values => {
        if (values.id) {
            const processedValues = convertFormDataToApiRequest(values);
            editPastPaper(processedValues);
        }
    };

    useMount(() => {
        refetch();
    });

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {pastPaperId ? t("class.pastPaper.edit.header") : t("class.pastPaper.add.header")}
            </Typography>
            <Paper>
                {pastPaper ? (
                    <FormikRef
                        ref={form}
                        initialValues={{
                            ...pastPaper,
                            cohort: cohort,
                            hiddenColumns: pastPaper.hiddenColumns || false,
                        }}
                        validationSchema={() => editPastPaperSchema(t)}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {() => (
                            <EditPastPaperForm
                                cohort={cohorts.find(c => c.id === parseInt(cohort || ""))}
                                isFormLocked={!pastPaper.canEdit}
                            />
                        )}
                    </FormikRef>
                ) : (
                    <Box p={4} sx={{ textAlign: "center" }}>
                        <CircularProgress />
                    </Box>
                )}
            </Paper>
        </AppContainer>
    );
};

export default EditPastPaperContainer;
