import HeadingCounter from "src/components/HeadingCounter";
import PaperInner from "src/components/PaperInner";
import ErrorMessage from "src/forms/ErrorMessage";
import SpecificationSelectField from "src/forms/SpecificationSelectField";
import AttributesConfigList from "../Common/AttributesConfigList";
import useDebounce from "src/hooks/useDebounce";
import Icon from "src/components/Icon";
import { Box, Button, Typography } from "@mui/material";
import { Field } from "formik";
import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DragDropFileUpload } from "src/forms/DragDropFileUpload";
import { UploadClassFormCourse } from "../../containers/AddClass/OtherUploadClassContainer";
import { usePrevious } from "src/hooks/usePrevious";
import { getRandomId } from "src/services/object";
import { baseHeaders, post, remove } from "src/services/ajax";
import { apiUrl, HTTP_NO_CONTENT } from "src/config/globals";
import { useSnackbar } from "notistack";
import { mdiDelete } from "@mdi/js";
import { useTrackerPath } from "../../hooks/useTrackerPath";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

const UploadClassCourseData = ({
    classToUploadData,
    index,
    errors,
    setFieldValue,
    setFieldTouched,
}: {
    classToUploadData: UploadClassFormCourse;
    errors;
    index: number;
    setFieldValue: (field: string, value: any) => void;
    setFieldTouched: (field: string, isTouched?: boolean) => void;
}) => {
    const { t }: { t: TFunction } = useTranslation();
    const prevSpecificationId = usePrevious(classToUploadData.specification);
    const { enqueueSnackbar } = useSnackbar();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const { data: trackerPath, refetch } = useTrackerPath(classToUploadData.specification);

    const handleFileDelete = (fileName: string) => {
        remove(
            apiUrl(
                `school/${getSchoolAccountId()}/class-tracker/upload-and-send/file/${fileName}/`,
            ),
            undefined,
            baseHeaders(),
        ).subscribe(
            res => {
                if (res.status === HTTP_NO_CONTENT) {
                    const prevQueue = classToUploadData.fileQueue;
                    setFieldValue(
                        `classToUploadData[${index}].fileQueue`,
                        prevQueue.filter(pq => pq.tmpFileName !== fileName),
                    );
                    const realFileNames = classToUploadData.realFileNames;
                    delete realFileNames[fileName];
                    setFieldValue(`classToUploadData[${index}].realFileNames`, realFileNames);
                    setFieldValue(
                        `classToUploadData[${index}].fileNames`,
                        classToUploadData.fileNames.filter(fN => fN !== fileName),
                    );
                }
            },
            err => {
                enqueueSnackbar(err.message, {
                    ...SnackbarErrorOptions,
                });
            },
        );
    };

    useEffect(() => {
        if (
            classToUploadData.specification &&
            prevSpecificationId !== classToUploadData.specification &&
            !trackerPath
        ) {
            refetch();
        }
    }, [prevSpecificationId, classToUploadData.specification]);

    const debouncedFileLen = useDebounce(classToUploadData.files.length, 1000);

    useEffect(() => {
        if (classToUploadData.files && classToUploadData.files.length > 0) {
            const prevList = classToUploadData.fileQueue;
            const newList = classToUploadData.files.map(f => {
                const hasSameName = prevList.find(pL => pL.name === f.name);
                return {
                    ...f,
                    name: !hasSameName ? f.name : getRandomId(4).toLowerCase() + "-" + f.name,
                    tmpFileName: "",
                };
            });
            setFieldValue(`classToUploadData[${index}].fileQueue`, [...prevList].concat(newList));
            setFieldValue(`classToUploadData[${index}].files`, []);
        }
    }, [debouncedFileLen]);

    const prevFileListLen = usePrevious(classToUploadData.fileQueue.length);

    useEffect(() => {
        if (prevFileListLen !== classToUploadData.fileQueue.length) {
            classToUploadData.fileQueue
                .filter(fL => fL.tmpFileName === "")
                .forEach(fL => {
                    post(
                        apiUrl(
                            `school/${getSchoolAccountId()}/class-tracker/upload-and-send/file/`,
                        ),
                        { file: { ...fL, tmpFileName: undefined } },
                        baseHeaders(),
                    ).subscribe(
                        res => {
                            if (res.response && res.response.tmpFileName) {
                                const newFileList = [...classToUploadData.fileQueue];
                                const fileIndex = newFileList.findIndex(
                                    nfl => nfl.name === fL.name,
                                );
                                newFileList[fileIndex]["tmpFileName"] = res.response.tmpFileName;
                                const fileNames = classToUploadData.fileNames;
                                const realFileNames = classToUploadData.realFileNames;
                                fileNames.push(res.response.tmpFileName);
                                realFileNames[res.response.tmpFileName] = fL.name;
                                setFieldValue(`classToUploadData[${index}].fileQueue`, newFileList);
                                setFieldValue(`classToUploadData[${index}].fileNames`, fileNames);
                                setFieldValue(
                                    `classToUploadData[${index}].realFileNames`,
                                    realFileNames,
                                );
                            }
                        },
                        err => {
                            if (err.message)
                                enqueueSnackbar(err.message, {
                                    ...SnackbarErrorOptions,
                                });
                        },
                    );
                });
        }
        setIsUploading(false);
    }, [classToUploadData.fileQueue]);

    return (
        <>
            <PaperInner border="bottom">
                <HeadingCounter number="1">
                    {t("class.addClass.chooseTrackerHeader")}
                </HeadingCounter>
                <Typography component="p" gutterBottom>
                    {t("class.addClass.uploadClassTrackerMessage")}
                </Typography>
                <Typography component="p" gutterBottom>
                    {t("class.addClass.uploadClassRequired")}
                </Typography>
                <Box component="ul" mb={3}>
                    <Box component="li">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t("class.addClass.uploadClassReqUpn"),
                            }}
                        ></div>
                    </Box>
                    <Box component="li">{t("class.addClass.uploadClassReqFirstLastName")}</Box>
                    <Box component="li">{t("class.addClass.uploadClassReqClassName")}</Box>
                </Box>
                <Typography component="p" gutterBottom>
                    {t("class.addClass.uploadClassOptional")}
                </Typography>
                <Box component="ul" mb={3}>
                    <Box component="li">{t("class.addClass.uploadClassOptionalCN")}</Box>
                    <Box component="li">{t("class.addClass.uploadClassOptionalKS2")}</Box>
                    <Box component="li">{t("class.addClass.uploadClassOptionalSEN")}</Box>
                </Box>
                <Field
                    name={`classToUploadData[${index}].specification`}
                    liveOnly={true}
                    component={SpecificationSelectField}
                    showParentSpecification={true}
                    allowedOnly={true}
                    trackerPath={trackerPath}
                />
                <ErrorMessage name={`classToUploadData[${index}].specification`} />
                {classToUploadData.specification && classToUploadData.attributes && (
                    <AttributesConfigList
                        namePrefix={`classToUploadData.${index}`}
                        attributes={classToUploadData.attributes}
                        specificationId={classToUploadData.specification}
                        items={classToUploadData.items}
                        groups={classToUploadData.groups}
                        disabledItems={classToUploadData.disabledItems}
                        errors={errors?.classToUploadData?.[index] || errors}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialAttributes={[]}
                    />
                )}
                {errors && errors.classToUploadData?.[index]?.attributes && (
                    <ErrorMessage name={`classToUploadData[${index}].attributes`} />
                )}
                {classToUploadData.specification && (
                    <Box mt={4}>
                        <HeadingCounter number="2">{t("class.addClass.uploadFile")}</HeadingCounter>
                        <Box mt={1.75}>
                            <Field
                                name={`classToUploadData[${index}].files`}
                                component={DragDropFileUpload}
                                label={t("class.addClass.uploadClassList")}
                                showList={false}
                                accept={`.xlsx,.pdf`}
                                disabled={isUploading}
                                multiple={true}
                            />
                            {classToUploadData.fileQueue &&
                                classToUploadData.fileQueue.map(f => (
                                    <Box key={f.name} pt={3}>
                                        {f.name}{" "}
                                        {!f.tmpFileName ? (
                                            " - " + t("common.uploading")
                                        ) : (
                                            <Button
                                                style={{ marginLeft: 5 }}
                                                onClick={() => handleFileDelete(f.tmpFileName)}
                                                variant="text"
                                                startIcon={<Icon path={mdiDelete} />}
                                                disableRipple
                                            >
                                                {t("common.delete")}
                                            </Button>
                                        )}
                                    </Box>
                                ))}
                            {errors && errors.classToUploadData?.[index]?.fileNames && (
                                <ErrorMessage name={`classToUploadData[${index}].fileNames`} />
                            )}
                        </Box>
                    </Box>
                )}
            </PaperInner>
        </>
    );
};

export default UploadClassCourseData;
