import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider } from "src/components/hookFormComponents";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import NewPasswordForm from "src/modules/auth/components/NewPassword/NewPasswordForm";
import { newPasswordSchema } from "src/modules/auth/components/NewPassword/newPasswordSchema";
import { useResetPassword } from "../hooks/useResetPassword";
import COLORS from "src/styles/colors";

const ChangePasswordForm = () => {
    const { t } = useTranslation();
    const { mutate: newPassword, status } = useResetPassword();

    const handleSubmit = values => {
        newPassword(values.password);
    };
    const methods = useForm({
        resolver: yupResolver(newPasswordSchema(t)),
        mode: "onSubmit",
        defaultValues: {
            password: "",
            repeatPassword: "",
        },
    });
    const { reset } = methods;

    useEffect(() => {
        reset();
    }, [status]);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit}>
            <NewPasswordForm />
            <Typography sx={{ color: COLORS.BLUE_3, mt: 1.5, fontSize: 12, fontStyle: "italic" }}>
                Please don&apos;t change any of the above two fields if you don&apos;t want to
                change your password
            </Typography>
            <Box mt={3}>
                <Button type="submit" color="inherit">
                    {t("account.myAccountTab.changePassword")}
                </Button>
            </Box>
        </FormProvider>
    );
};

export default ChangePasswordForm;
