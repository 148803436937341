import { Dispatch } from "redux";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { ApiData, ApiStatus } from "src/api/constants";
import { useResponse } from "src/hooks/useResponse";
import { HTTP_ERROR_CONFLICT } from "src/config/globals";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { ClassTrackerValuesObject } from "../dto/TrackerValues";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { ClassActions } from "src/modules/class/store/actions";
import { StudentModel } from "src/orm/models/Student";
import { studentsSelector } from "src/modules/user/selectors/StudentsSelectors";
import { useEffect, useState } from "react";
import { mergeStudentsToValues } from "../services/valueHandler";

const dispatchActions = (dispatch: Dispatch) => ({
    getClassTrackerValues: (classTrackerId: number, tier: TiersTypes) => {
        dispatch(TrackerActions.getClassTrackerValues(classTrackerId, tier));
    },
    getClassTrackerGroupValues: (classTrackerGroupId: number, tier: TiersTypes) => {
        dispatch(TrackerActions.getClassTrackerGroupValues(classTrackerGroupId, tier));
    },
    getStudentsForClassTracker: (classTrackerId: number, tier?: TiersTypes) => {
        dispatch(ClassActions.getStudentsByClass(classTrackerId, tier));
    },
    getStudentsForClassTrackerGroup: (classTrackerGroupId: number, tier?: TiersTypes) => {
        dispatch(ClassActions.getStudentsByGroup(classTrackerGroupId, tier));
    },
    clearStudents: () => {
        dispatch(ClassActions.clearStudents());
    },
});

export const useTrackerValues = (skipRedirect?: boolean) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();

    const [values, setValues] = useState(null);
    const [groupValues, setGroupValues] = useState(null);

    const {
        getClassTrackerValues,
        getClassTrackerGroupValues,
        getStudentsForClassTracker,
        getStudentsForClassTrackerGroup,
        clearStudents,
    } = dispatchActions(dispatch);

    const {
        apiClassTrackerValues,
        apiClassTrackerGroupValues,
        classTrackerValues,
        apiStudents,
        apiStudentsGroup,
        classTrackerStudents,
        classTrackerGroupStudents,
    }: {
        apiClassTrackerValues: ApiData;
        apiClassTrackerGroupValues: ApiData;
        apiStudents: ApiData;
        apiStudentsGroup: ApiData;
        classTrackerValues: ClassTrackerValuesObject | null;
        classTrackerStudents: StudentModel[] | null;
        classTrackerGroupStudents: StudentModel[] | null;
    } = useSelector(
        (state: AppState) => ({
            classTrackerValues: state.tracker.classTrackerValues,
            apiClassTrackerGroupValues: state.api.tracker.getClassTrackerGroupValues,
            apiClassTrackerValues: state.api.tracker.getClassTrackerValues,
            apiStudents: state.api.class.getStudentsByClass,
            apiStudentsGroup: state.api.class.getStudentsByGroup,
            classTrackerStudents: studentsSelector(state),
            classTrackerGroupStudents: studentsSelector(state),
        }),
        shallowEqual,
    );

    useResponse(() => {
        if (
            apiClassTrackerValues.status === ApiStatus.ERROR &&
            apiClassTrackerValues.error?.status === HTTP_ERROR_CONFLICT &&
            !skipRedirect
        ) {
            enqueueSnackbar(t("tracker.maintenance"), {
                ...SnackbarErrorOptions,
            });

            setTimeout(() => {
                navigate("/");
            }, 3000);
        }
    }, apiClassTrackerValues);

    useEffect(() => {
        if (classTrackerValues && classTrackerStudents?.length > 0) {
            const newValues = mergeStudentsToValues(classTrackerValues, classTrackerStudents);

            setValues(newValues);
        }
    }, [classTrackerValues, classTrackerStudents]);

    useEffect(() => {
        if (classTrackerValues && classTrackerGroupStudents?.length > 0) {
            const newValues = mergeStudentsToValues(classTrackerValues, classTrackerGroupStudents);

            setGroupValues(newValues);
        }
    }, [classTrackerValues, classTrackerGroupStudents]);

    return {
        getClassTrackerValues,
        getStudentsForClassTracker,
        getClassTrackerGroupValues,
        getStudentsForClassTrackerGroup,
        classTrackerValues: values,
        classTrackerGroupValues: groupValues,
        apiClassTrackerValues,
        apiClassTrackerGroupValues,
        apiStudents,
        apiStudentsGroup,
        clearStudents,
        classTrackerStudents,
    };
};
