import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { LinkDetailedModel } from "../model/LinkModel";
import { getSchoolAccountId } from "src/services/url";

export const LINK_DETAILS_QUERY = "linkDetailsQuery";

export const useLinkDetails = (id: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [LINK_DETAILS_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<LinkDetailedModel | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/report-links/${id}/`,
                );

                return data || null;
            }
            return null;
        },
    });
};
