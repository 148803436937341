import clsx from "clsx";
import COLORS from "src/styles/colors";
import { darken } from "@mui/material";

const AverageHeaderRenderer = props => {
    return (
        <div className={clsx("ag-spec-header", "ag-spec-header--bucket")}>
            <div
                style={{
                    backgroundColor: props.columnColour
                        ? darken(props.columnColour, 0.02)
                        : COLORS.VERY_LIGHT_GREY_8,
                }}
                className={clsx(
                    "ag-spec-header-row",
                    //  `ag-spec-header-row--level-${level}`
                )}
            >
                <div className="ag-spec-header-vertical">
                    <div className="ag-spec-header-label ag-spec-header-label--truncate">
                        {`${props.displayName} - Average`}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AverageHeaderRenderer;
