import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { CommonGradeResponse } from "src/modules/common/api/grades";

export enum OrmGradeActionTypes {
    BULK_IMPORT = "[orm] GRADE BULK IMPORT",
}

export const OrmGradeActions = {
    bulkImport: (payload: { data: CommonGradeResponse; specificationId: number }) => {
        return createAction(OrmGradeActionTypes.BULK_IMPORT, payload);
    },
};

export type OrmGradeActions = ActionsUnion<typeof OrmGradeActions>;
