import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { OrmReportTemplate } from ".";

export enum OrmReportTemplateActionTypes {
    BULK_IMPORT = "[orm] REPORT TEMPLATE BULK IMPORT",
    UPSERT = "[orm] REPORT TEMPLATE UPSERT",
    CLEAR = "[orm] REPORT TEMPLATE CLEAR",
}

export const OrmReportTemplateActions = {
    bulkImport: (payload: OrmReportTemplate[]) => {
        return createAction(OrmReportTemplateActionTypes.BULK_IMPORT, payload);
    },
    upsert: (payload: OrmReportTemplate) =>
        createAction(OrmReportTemplateActionTypes.UPSERT, payload),
    clear: () => createAction(OrmReportTemplateActionTypes.CLEAR),
};

export type OrmReportTemplateActions = ActionsUnion<typeof OrmReportTemplateActions>;
