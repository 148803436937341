import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getSnapshotReportsFiltersEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/snapshot/report/{cohort}/filters/`,
    method: ApiRequests.GET,
};

interface SnapshotReportFilter {
    qualifications: {
        yearGroups: number[];
        id: number;
        name: string;
    }[];
    id: number;
    name: string;
}

export type SnapshotReportFilterResponse = SnapshotReportFilter[];
