import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import {
    createPaymentIntentEndpoint,
    CreatePaymentIntentResponse,
} from "../../api/Payment/createPaymentIntent";
import { changeCouponCodeEndpoint } from "../../api/Payment/changeCouponCode";
import { PaymentDetailsResponse } from "../../api/Payment/paymentDetails";
import { confirmPaymentIntentEndpoint } from "../../api/Payment/confirmPaymentIntent";
// import { createInvoiceEndpoint, CreateInvoiceResponse } from "../../api/payment/createInvoice";

export enum PaymentActionsTypes {
    CREATE_PAYMENT_INTENT = "[subscription] CREATE PAYMENT INTENT",
    CREATE_PAYMENT_INTENT_SUCCESS = "[subscription] CREATE PAYMENT INTENT SUCCESS",
    CONFIRM_PAYMENT_INTENT = "[subscription] CONFIRM PAYMENT INTENT",
    CONFIRM_PAYMENT_INTENT_SUCCESS = "[subscription] CONFIRM PAYMENT INTENT SUCCESS",
    SET_PAYMENT_INTENT = "[subscription] SET PAYMENT INTENT",
    CLEAR_PAYMENT_INTENT = "[subscription] CLEAR PAYMENT INTENT",
    CHANGE_COUPON_CODE = "[subscription] CHANGE COUPON CODE",
    CHANGE_COUPON_CODE_SUCCESS = "[subscription] CHANGE COUPON CODE SUCCESS",
    // CREATE_INVOICE = "[subscription] CREATE INVOICE",
    // CREATE_INVOICE_SUCCESS = "[subscription] CREATE INVOICE SUCCESS",
    // SET_INVOICE = "[subscription] SET INVOICE",
}

export const PaymentApiEndpoints = {
    createPaymentIntent: createActionObject(
        createPaymentIntentEndpoint,
        PaymentActionsTypes.CREATE_PAYMENT_INTENT_SUCCESS,
    ),
    confirmPaymentIntent: createActionObject(
        confirmPaymentIntentEndpoint,
        PaymentActionsTypes.CONFIRM_PAYMENT_INTENT_SUCCESS,
    ),
    changeCouponCode: createActionObject(
        changeCouponCodeEndpoint,
        PaymentActionsTypes.CHANGE_COUPON_CODE_SUCCESS,
    ),
    // createInvoice: createActionObject(
    //     createInvoiceEndpoint,
    //     PaymentActionsTypes.CREATE_INVOICE_SUCCESS
    // ),
};

export const PaymentRawActions = (
    PaymentEndpoints: Record<Keys<typeof PaymentApiEndpoints>, ApiActionMeta>,
) => ({
    createPaymentIntent: (paymentId, amount, subscriptionId, orderDescription) =>
        createApiAction(
            PaymentActionsTypes.CREATE_PAYMENT_INTENT,
            {
                values: { paymentMethodId: paymentId, amount, orderDescription },
                params: { id: subscriptionId },
            },
            PaymentEndpoints.createPaymentIntent,
        ),
    createPaymentIntentSuccess: (payload: ApiCallbackPayload<CreatePaymentIntentResponse>) =>
        createAction(PaymentActionsTypes.CREATE_PAYMENT_INTENT_SUCCESS, payload),
    changeCouponCode: (subscription: number, couponName: string) =>
        createApiAction(
            PaymentActionsTypes.CHANGE_COUPON_CODE,
            {
                values: { couponName },
                params: { subscription },
            },
            PaymentEndpoints.changeCouponCode,
        ),
    changeCouponCodeSuccess: (payload: ApiCallbackPayload<PaymentDetailsResponse>) =>
        createAction(PaymentActionsTypes.CHANGE_COUPON_CODE_SUCCESS, payload),
    confirmPaymentIntent: (intentId, subscriptionId) =>
        createApiAction(
            PaymentActionsTypes.CONFIRM_PAYMENT_INTENT,
            {
                values: { paymentIntentId: intentId },
                params: { id: subscriptionId },
            },
            PaymentEndpoints.confirmPaymentIntent,
        ),
    confirmPaymentIntentSuccess: () =>
        createAction(PaymentActionsTypes.CONFIRM_PAYMENT_INTENT_SUCCESS),
    setPaymentIntent: (payload: CreatePaymentIntentResponse) =>
        createAction(PaymentActionsTypes.SET_PAYMENT_INTENT, payload),
    clearPaymentIntent: () => createAction(PaymentActionsTypes.CLEAR_PAYMENT_INTENT),
    // createInvoice: (subscriptionHistoryId: number, paymentIntentId: string, description: string) =>
    //     createApiAction(
    //         PaymentActionsTypes.CREATE_INVOICE,
    //         {
    //             values: { paymentIntentId, description },
    //             params: { id: subscriptionHistoryId },
    //         },
    //         PaymentEndpoints.createInvoice
    //     ),
    // createInvoiceSuccess: (payload: ApiCallbackPayload<CreateInvoiceResponse>) =>
    //     createAction(PaymentActionsTypes.CREATE_INVOICE_SUCCESS, payload),
    // setInvoice: (payload: CreateInvoiceResponse) =>
    //     createAction(PaymentActionsTypes.SET_INVOICE, payload),
});
