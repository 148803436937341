import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmUser } from "src/orm/models/User";
import { getSchoolAccountId } from "src/services/url";

export const getSubjectAreaUsersListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subject-area/{subjectArea}/teachers-and-leaders/`,
    method: ApiRequests.GET,
};

export interface SubjectAreaUsersListResponse {
    teachers: OrmUser[];
    leaders: OrmUser[];
}
