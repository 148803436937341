import { Dispatch } from "redux";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { KS4ViewGrades, KS4ViewTypes } from "../api/KS4Grade/getValues";
import { AnalysisActions } from "../store/actions";
import { prepareStudentFilters } from "../services/analysisValueHandler";
import { SnapshotData } from "src/context/TrackerContext";
import { useCohortSnapshotList } from "src/modules/school/hooks/query/CohortSnapshot/useCohortSnapshotList";
import { CohortSnapshotModel } from "src/modules/school/models/CohortSnapshotModel";
import {
    UpsertSnapshotRequest,
    useCreateSnapshotMutation,
} from "src/modules/school/hooks/query/CohortSnapshot/useCreateMutation";
import {
    RemoveSnapshotRequest,
    useRemoveSnapshotMutation,
} from "src/modules/school/hooks/query/CohortSnapshot/useRemoveMutation";

export interface StudentAttributePair {
    attribute: string;
    attributeDisplay: string;
    value: string;
    valueDisplay: string;
}

const dispatchActions = (dispatch: Dispatch) => ({
    getSnapshotValues: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        snapshot: number,
        studentFilters: StudentAttributePair[],
    ) => {
        dispatch(
            AnalysisActions.getAnalysisKS4GradeSnapshot(
                cohort,
                yearGroup,
                viewGrade,
                viewType,
                snapshot,
                prepareStudentFilters(studentFilters),
            ),
        );
    },
    getSnapshotCompareValues: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        dataSource1: string,
        dataSource2: string,
        studentFilters: StudentAttributePair[],
    ) => {
        dispatch(
            AnalysisActions.getAnalysisKS4GradeSnapshotCompare(
                cohort,
                yearGroup,
                viewGrade,
                viewType,
                dataSource1,
                dataSource2,
                prepareStudentFilters(studentFilters),
            ),
        );
    },
    setSnapshotData: (snapShotData: SnapshotData) => {
        dispatch(AnalysisActions.setSnapshotData(snapShotData));
    },
});

export const useAnalysisSnapshots = (
    cohortId: number,
): {
    snapshots: CohortSnapshotModel[];
    snapshotData: SnapshotData | null;
    setSnapshotData: (data: SnapshotData | null) => void;
    createCohortSnapshot: (value: UpsertSnapshotRequest) => void;
    removeCohortSnapshot: (value: RemoveSnapshotRequest) => void;
    getSnapshotValues: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        snapshot: number,
        studentFilters: StudentAttributePair[],
    ) => void;
    getSnapshotCompareValues: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        dataSource1: string,
        dataSource2: string,
        studentFilters: StudentAttributePair[],
    ) => void;
    refetchSnapshots: () => void;
} => {
    const dispatch = useDispatch();

    const { refetch: fetchCohortSnapshots, data: snapshots } = useCohortSnapshotList(cohortId);

    const { mutate: createCohortSnapshot } = useCreateSnapshotMutation(cohortId);
    const { mutate: removeCohortSnapshot } = useRemoveSnapshotMutation(cohortId);

    const { getSnapshotCompareValues, getSnapshotValues, setSnapshotData } =
        dispatchActions(dispatch);

    const {
        snapshotData,
    }: {
        snapshotData: SnapshotData | null;
    } = useSelector(
        (state: AppState) => ({
            snapshotData: state.analysis.snapshotData,
        }),
        shallowEqual,
    );

    const refetchSnapshots = () => {
        fetchCohortSnapshots();
    };

    return {
        snapshots,
        snapshotData,
        setSnapshotData,
        getSnapshotValues,
        getSnapshotCompareValues,
        refetchSnapshots,
        removeCohortSnapshot,
        createCohortSnapshot,
    };
};
