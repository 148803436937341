import AutoSuggestForm, { AutoSuggestFormShape } from "../../components/MIS/forms/AutoSuggestForm";
import FormikRef from "src/forms/FormikRef";
import PaperInner from "src/components/PaperInner";
import HeadingCounter from "src/components/HeadingCounter";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import AppContainer from "src/components/AppContainer";
import { useRef } from "react";
import { Box, Grid, Button, MenuItem, TextField, Typography, Paper, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { useEffect, useState } from "react";
import { useAutoSuggestClassList } from "src/modules/class/hooks/MIS/useAutoSuggestClassList";
import { ROUTE_CLASS_ADD_CLASS_SYNCHRONIZE, ROUTE_CLASS_CLASS_LIST } from "src/routes";
import { useAutoSuggestCreateMutation } from "../../hooks/MIS/useAutoSuggestCreateMutation";
import { useSnackbar } from "notistack";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const AddClassAutoSuggestTrackerContainer = () => {
    const form = useRef() as any;
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const yearGroups = data?.yearGroups;
    const { data: accountInfo } = useAccountInfo();

    const [sourceAcademicYear, setSourceAcademicYear] = useState<number | "">(
        accountInfo?.activeCohortId - 1 || "",
    );
    const [sourceYearGroup, setSourceYearGroup] = useState<number | "">("");
    const [targetAcademicYear, setTargetAcademicYear] = useState<number | "">(
        accountInfo?.activeCohortId || "",
    );

    const { mutate: create } = useAutoSuggestCreateMutation(
        sourceYearGroup,
        targetAcademicYear,
        () => {
            enqueueSnackbar(t("class.addClass.autoSuggest.success"), { variant: "success" });
            navigate(ROUTE_CLASS_CLASS_LIST);
        },
        () => {
            if (form.current) {
                form.current.setSubmitting(false);
            }
        },
    );

    const { data: classList, refetch: refetchClassList } = useAutoSuggestClassList(
        sourceAcademicYear,
        sourceYearGroup === "" ? null : sourceYearGroup,
        targetAcademicYear,
    );

    const handleSubmit = (values: AutoSuggestFormShape) => {
        const converted = values.specificationAndClasses.filter(sac => {
            const isSomeClassSelected = sac.classes.find(c => c.selected);
            if (isSomeClassSelected) {
                return true;
            }
            return false;
        });

        const filtered = converted.map(sc => ({
            specification: { id: sc.specification.id },
            classes: sc.classes.filter(c => c.selected).map(c => ({ id: c.id })),
        }));

        create({
            specificationAndClasses: filtered,
        });
    };

    const isSomeClassSelected = (values: AutoSuggestFormShape) => {
        return values.specificationAndClasses.reduce((isSelected, specClass) => {
            if (!isSelected && !!specClass.classes.find(c => c.selected)) {
                return true;
            }
            return isSelected;
        }, false);
    };

    useEffect(() => {
        if (sourceAcademicYear && sourceYearGroup !== "" && targetAcademicYear) {
            refetchClassList();
        }
    }, [sourceAcademicYear, sourceYearGroup, targetAcademicYear]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.addClass.headerAutosuggest")}
            </Typography>
            <Paper>
                <PaperInner>
                    <HeadingCounter number="1">
                        {t("class.addClass.chooseTrackerHeader")}
                    </HeadingCounter>
                    <Typography component="p" gutterBottom>
                        {t("class.addClass.autoSuggest.autoSuggestHint")}
                    </Typography>
                    <Box mb={4}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t("class.addClass.autoSuggest.autoSuggestHint2"),
                            }}
                        ></div>
                    </Box>

                    {sourceAcademicYear && (
                        <Box p={2} mb={4} width={780} sx={{ background: "#fafbfc" }}>
                            <Typography component="p" gutterBottom>
                                {t("class.addClass.autoSuggest.autoSuggestHint3").replaceAll(
                                    "MIS_Name",
                                    accountInfo?.misName || "",
                                )}
                            </Typography>
                            <Button
                                sx={{ color: "#ffffff", backgroundColor: "#c3c4c8" }}
                                onClick={() => {
                                    navigate(ROUTE_CLASS_ADD_CLASS_SYNCHRONIZE);
                                }}
                            >
                                {t("class.addClass.autoSuggest.autoSuggestHint3Btn").replaceAll(
                                    "MIS_Name",
                                    accountInfo?.misName || "",
                                )}
                            </Button>
                        </Box>
                    )}
                    <Grid container spacing={4}>
                        <Grid item sm={4}>
                            <TextField
                                select
                                value={sourceAcademicYear}
                                onChange={e => {
                                    setSourceAcademicYear(parseInt(`${e.target.value}`));
                                }}
                                label={t("class.addClass.autoSuggest.sourceAcedemicYear")}
                            >
                                {cohorts.map(c => {
                                    return (
                                        <MenuItem value={c.id} key={c.id}>
                                            {c.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                select
                                value={sourceYearGroup}
                                onChange={e => {
                                    setSourceYearGroup(parseInt(`${e.target.value}`));
                                }}
                                label={t("class.addClass.autoSuggest.sourceYearGroup")}
                            >
                                {yearGroups.map(yg => {
                                    return (
                                        <MenuItem value={yg.id} key={yg.id}>
                                            {yg.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                select
                                value={targetAcademicYear}
                                onChange={e => {
                                    setTargetAcademicYear(parseInt(`${e.target.value}`));
                                }}
                                label={t("class.addClass.autoSuggest.targetAcedemicYear")}
                            >
                                {cohorts.map(c => {
                                    return (
                                        <MenuItem value={c.id} key={c.id}>
                                            {c.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{ marginLeft: -6, marginTop: 6, marginRight: -6, marginBottom: 6 }}
                    />
                    {classList?.length > 0 && (
                        <FormikRef
                            ref={form}
                            initialValues={{
                                specificationAndClasses: classList.map(cl => {
                                    return {
                                        specification: { id: cl.specification.id },
                                        classes: cl.groupCallGroups.map(gcg => ({
                                            ...gcg,
                                            selected: false,
                                        })),
                                    };
                                }),
                            }}
                            onSubmit={handleSubmit}
                            enableReinitialize={false}
                        >
                            {formProps => (
                                <Box mt={4}>
                                    <HeadingCounter number="2">
                                        {t("class.addClass.autoSuggest.chooseNewClasses")}
                                    </HeadingCounter>
                                    <AutoSuggestForm classList={classList} />
                                    <Box mt={4}>
                                        <Button
                                            disabled={
                                                formProps.isSubmitting ||
                                                !isSomeClassSelected(formProps.values)
                                            }
                                            onClick={formProps.submitForm}
                                            color="primary"
                                        >
                                            {t("class.addClass.autoSuggest.addClassButton")}
                                        </Button>
                                        <GenericErrorMessage
                                            errors={formProps.errors}
                                            submitCount={formProps.submitCount}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </FormikRef>
                    )}
                </PaperInner>
            </Paper>
        </AppContainer>
    );
};

export default AddClassAutoSuggestTrackerContainer;
