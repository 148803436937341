import { createStyles, FormControlLabel, Theme, Switch } from "@mui/material";
import { withStyles } from "@mui/styles";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import COLORS from "src/styles/colors";
import { DataTypeTypes } from "./GridDataTypeButtons";

export const CustomFormControlLabel = withStyles(({ typography }: Theme) =>
    createStyles({
        label: {
            color: COLORS.GREY_2,
            fontSize: typography.pxToRem(13),
            fontWeight: 700,
            textTransform: "uppercase",
        },
    }),
)(FormControlLabel);

const LiveTrackingSwitch = withStyles(() =>
    createStyles({
        switchBase: {
            "&$checked": {
                color: COLORS.GREEN_1,
            },
            "&$checked + $track": {
                backgroundColor: COLORS.GREEN_1,
            },
        },
        checked: {},
        track: {},
    }),
)(Switch);

const ForecastSwitch = withStyles(() =>
    createStyles({
        switchBase: {
            "&$checked": {
                color: COLORS.CYAN_1,
            },
            "&$checked + $track": {
                backgroundColor: COLORS.CYAN_1,
            },
        },
        checked: {},
        track: {},
    }),
)(Switch);

const SnapshotsSwitch = withStyles(() =>
    createStyles({
        switchBase: {
            "&$checked": {
                color: COLORS.YELLOW_1,
            },
            "&$checked + $track": {
                backgroundColor: COLORS.YELLOW_1,
            },
        },
        checked: {},
        track: {},
    }),
)(Switch);

export const getSwitchComponent = (
    dataType: DataTypeTypes,
    isChecked: boolean,
    handleChange: () => void,
) => {
    switch (dataType) {
        case DataTypeTypes.FORECAST:
            return (
                <ForecastSwitch name="toolsVisible" checked={isChecked} onChange={handleChange} />
            );
        case DataTypeTypes.SNAPSHOT:
            return (
                <SnapshotsSwitch name="toolsVisible" checked={isChecked} onChange={handleChange} />
            );
        default:
            return (
                <LiveTrackingSwitch
                    name="toolsVisible"
                    checked={isChecked}
                    onChange={handleChange}
                />
            );
    }
};

interface OwnProps {
    dataType: DataTypeTypes;
    isChecked: boolean;
    disabled: boolean;
    handleChange: () => void;
}

const GridToolsToggleButton: FC<OwnProps> = ({ disabled, dataType, isChecked, handleChange }) => {
    const { t } = useTranslation();

    return (
        <CustomFormControlLabel
            disabled={disabled}
            control={getSwitchComponent(dataType, isChecked, handleChange)}
            label={t("common.showToolsSwitch")}
        />
    );
};

export default GridToolsToggleButton;
