import {
    ROUTE_BUY_AFTER_TRIAL_SUBSCRIPTION,
    ROUTE_BUY_SUBSCRIPTION,
    ROUTE_EXTEND_SUBSCRIPTION,
    ROUTE_REQUEST_NEW_TRIAL,
    ROUTE_SUBSCRIPTION_LIST_TAB,
    ROUTE_SUBSCRIPTION_LIST,
    ROUTE_UPGRADE_SUBSCRIPTION,
    ROUTE_UPGRADE_TO_WHOLE_SCHOOL,
    ROUTE_SUBSCRIPTION_LIST_INVOICE_FILTER,
    ROUTE_MAKE_PAYMENT,
} from "src/routes";
import BuyAfterTrialSubscription from "../containers/BuyAfterTrialSubscription";
import BuySubscription from "../containers/BuySubscription";
import ExtendSubscription from "../containers/ExtendSubscription";
import SubscriptionList from "../containers/SubscriptionList";
import TrialSubscription from "../containers/TrialSubscription";
import UpgradeSubscription from "../containers/UpgradeSubscription";
import WholeSchoolSubscription from "../containers/WholeSchoolSubscription";
import MakePayment from "../containers/MakePayment";

export const SubscriptionRoutes = [
    { path: ROUTE_SUBSCRIPTION_LIST, element: <SubscriptionList /> },
    {
        path: ROUTE_SUBSCRIPTION_LIST_INVOICE_FILTER,
        element: <SubscriptionList />,
    },
    { path: ROUTE_SUBSCRIPTION_LIST_TAB, element: <SubscriptionList /> },
    { path: ROUTE_REQUEST_NEW_TRIAL, element: <TrialSubscription /> },
    {
        path: ROUTE_UPGRADE_TO_WHOLE_SCHOOL,
        element: <WholeSchoolSubscription />,
    },
    { path: ROUTE_UPGRADE_SUBSCRIPTION, element: <UpgradeSubscription /> },
    { path: ROUTE_EXTEND_SUBSCRIPTION, element: <ExtendSubscription /> },
    {
        path: ROUTE_BUY_AFTER_TRIAL_SUBSCRIPTION,
        element: <BuyAfterTrialSubscription />,
    },
    { path: ROUTE_BUY_SUBSCRIPTION, element: <BuySubscription /> },
    { path: ROUTE_MAKE_PAYMENT, element: <MakePayment /> },
];
