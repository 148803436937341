import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import clsx from "clsx";
import { useState } from "react";
import { Box, Button, MenuItem, styled, TextField, Typography } from "@mui/material";
import { mdiChevronDown } from "@mdi/js";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ROUTE_ANALYSIS_TAB } from "src/routes";
import { filterNotNullable } from "src/services/object";
import { AppState } from "src/store/reducers";
import {
    AnalysisTypes,
    AnalysisTypesLabels,
    AnalysisTypesIcons,
    hiddenAnalysisTypes,
} from "./AnalysisTypeModal";
import { useLocation, useParams } from "react-router";
import { CommonCohortAnalysisYearGroupResponse } from "src/modules/common/api/analysisCohortYearGroups";
import { useMount } from "src/hooks/useMount";
import { AnalysisActions } from "../../store/actions";
import { Dispatch } from "redux";
import { MenuChoosePrimaryButton } from "src/components/MenuChooseButton";
import { StyledMenu, StyledMenuItem } from "src/components/StyledMenu";
import { SchoolUserRole } from "src/orm/models/User";
import { ViewGrades } from "../../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useAnalysisUserSettingsStore } from "../../hooks/useAnalysisUserSettingsStore";
import { KS4_GRADE_VIEW_TYPE } from "../Grid/KS4GradeAnalysisGrid";
import { useUserSettingsEditMutation } from "../../hooks/useUserSettingsEditMutation";
import { GRADE_SUMMARY_VIEW_TYPE } from "../Grid/StudentsGradeSummaryGrid";
import { ATL_VIEW_TYPE } from "../Grid/AtlAnalysisGrid";

const dispatchActions = (dispatch: Dispatch) => ({
    getAnalysisCohortYearGroups: () => {
        dispatch(AnalysisActions.getAnalysisCohortYearGroups());
    },
});

export const StyledMenuButton = styled("button")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    backgroundColor: COLORS.VERY_LIGHT_GREY_1,
    padding: theme.spacing(1.375, 3, 2.5),
    borderBottom: `1px solid ${COLORS.VERY_LIGHT_GREY_5}`,
    cursor: "pointer",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    textAlign: "left",
    border: "0 none",
    outline: "0 none",
    width: "100%",
    height: "100%",
    position: "relative",
    "&:hover": {
        backgroundColor: COLORS.VERY_LIGHT_GREY_5,
    },
    "&.active": {
        backgroundColor: COLORS.VERY_LIGHT_GREY_5,
        "&::before": {
            content: "''",
            backgroundColor: COLORS.BLUE_1,
            width: 3,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            pointerEvents: "none",
        },
    },
    "& .row": {
        display: "flex",
        alignItems: "center",
    },
    "& .icon": {
        color: COLORS.GREY_2,
        fontSize: 24,
        width: 24,
        height: 24,
        marginRight: theme.spacing(1.75),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(-0.25),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .icon.active": {
        color: COLORS.GREY_1,
    },
    "& .title": {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
    },
    "& .subtitle": {
        color: COLORS.GREY_2,
        fontSize: theme.typography.pxToRem(10),
        lineHeight: 1.4,
        marginTop: theme.spacing(0.375),
        whiteSpace: "normal",
    },
    "& .subtitle.active": {
        color: COLORS.GREY_1,
    },
}));

const AnalysisTypeDropdownMenu = ({
    cohort,
    yearGroup,
    analysisType,
}: {
    cohort: number;
    yearGroup: number;
    analysisType: string;
}) => {
    const { t }: { t: TFunction } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const { getAnalysisCohortYearGroups } = dispatchActions(dispatch);
    const { data: userProfile, refetch: getUserProfile } = useProfile();

    const {
        analysisCohortYearGroups,
    }: { analysisCohortYearGroups: CommonCohortAnalysisYearGroupResponse[] | null } = useSelector(
        (state: AppState) => ({
            analysisCohortYearGroups: state.analysis.analysisCohortYearGroups,
        }),
        shallowEqual,
    );

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const [selectedCohort, setSelectedCohort] = useState<number | null>(cohort);
    const [selectedYearGroup, setSelectedYearGroup] = useState<number>(yearGroup);
    const [selectedAnalysis, setSelectedAnalysis] = useState<AnalysisTypes | string | null>(
        analysisType,
    );
    const cohortName =
        analysisCohortYearGroups?.find(c => c.id === parseInt(`${cohort}`))?.name || "";

    const { cohort: cohortParam, yearGroup: yearGroupParam } = useParams();

    const analysisUserSettings = useAnalysisUserSettingsStore();

    const viewTypeMapper = () => {
        switch (analysisType) {
            case AnalysisTypes.KS4_GRADE_ANALYSIS:
                return KS4_GRADE_VIEW_TYPE;
            case AnalysisTypes.STUDENTS_GRADE_SUMMARY:
                return GRADE_SUMMARY_VIEW_TYPE;
            case AnalysisTypes.ATL_ANALYSIS:
                return ATL_VIEW_TYPE;
            default:
                return undefined;
        }
    };
    const { mutate: setAnalysisUserSettingsApi } = useUserSettingsEditMutation(
        parseInt(cohortParam || ""),
        parseInt(yearGroupParam || ""),
        viewTypeMapper(),
    );
    const saveAnalysisUserSettings = () => setAnalysisUserSettingsApi(analysisUserSettings);

    const handleAnalysisShow = () => {
        if (selectedAnalysis) {
            const url =
                ROUTE_ANALYSIS_TAB.replace(":cohort", `${selectedCohort}`)
                    .replace(":yearGroup", `${selectedYearGroup}`)
                    .replace(":tab", selectedAnalysis) +
                `?viewGrade=${query.get("viewGrade") || ViewGrades.PROJECTED}`;

            navigate(url);
        }
        handleClose();
    };

    let yearGroups: number[] = [];
    if (analysisCohortYearGroups && selectedCohort) {
        yearGroups =
            analysisCohortYearGroups.find(c => c.id === parseInt(`${selectedCohort}`))?.yearGroup ||
            [];
    }

    useMount(() => {
        if (!analysisCohortYearGroups) getAnalysisCohortYearGroups();
        if (!userProfile) getUserProfile();
    });

    return (
        analysisCohortYearGroups && (
            <Box display="flex" flexDirection="column" minHeight={42}>
                <Box fontSize={11} lineHeight={1.36} color={COLORS.LIGHT_GREY_1} mb={0.375}>
                    {cohortName + " > "}
                    {yearGroup === 0 ? t("common.yg0") : `${t("common.yearGroup")} ${yearGroup}`}
                </Box>
                <MenuChoosePrimaryButton onClick={handleClick}>
                    <>
                        <Typography component="h1" variant="h1">
                            {t(`analysis.types.${AnalysisTypesLabels[analysisType]}`)}
                        </Typography>
                        <Icon path={mdiChevronDown} size="30px" />
                    </>
                </MenuChoosePrimaryButton>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <StyledMenuItem disableRipple disableTouchRipple>
                        <Box display="flex" width="100%">
                            <Box paddingTop={1.375} paddingX={2.5} paddingBottom={2.125} flex={1}>
                                <TextField
                                    label={t("analysis.modal.cohort")}
                                    select
                                    SelectProps={{ displayEmpty: true }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="none"
                                    name="cohort"
                                    value={selectedCohort}
                                    onChange={e => {
                                        setSelectedCohort(parseInt(`${e.target.value}`));
                                        saveAnalysisUserSettings();
                                        setSelectedYearGroup(0);
                                    }}
                                >
                                    {filterNotNullable(analysisCohortYearGroups).map(obj => (
                                        <MenuItem key={obj.id} value={obj.id}>
                                            {obj.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                paddingTop={1.375}
                                paddingX={2.5}
                                paddingBottom={2.125}
                                borderLeft={`1px solid ${COLORS.VERY_LIGHT_GREY_5}`}
                                flex={1}
                            >
                                <TextField
                                    label={t("analysis.modal.yearGroup")}
                                    select
                                    SelectProps={{ displayEmpty: true }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="none"
                                    name="yearGroup"
                                    value={selectedYearGroup}
                                    onChange={e => {
                                        setSelectedYearGroup(parseInt(`${e.target.value}`));
                                        saveAnalysisUserSettings();
                                    }}
                                >
                                    <MenuItem value={-1}>
                                        <Box
                                            component="span"
                                            fontStyle="italic"
                                            color={COLORS.GREY_2}
                                        >
                                            {t("analysis.modal.yearGroupSelectNone")}
                                        </Box>
                                    </MenuItem>
                                    {yearGroups.map(obj => (
                                        <MenuItem key={obj} value={obj}>
                                            {obj === 0 ? t("common.yg0") : obj}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Box>
                    </StyledMenuItem>
                    {userProfile &&
                        userProfile.accessToAnalysisTypes
                            .filter(key => !hiddenAnalysisTypes.includes(key))
                            //TODO it is temporary solution, remove it after API changes
                            .filter(
                                key =>
                                    !(
                                        key == AnalysisTypes.ATL_ANALYSIS &&
                                        userProfile.userRole == SchoolUserRole.TEACHER &&
                                        !(
                                            userProfile.isLeaderOfAll ||
                                            userProfile.leaderOfSubjectAreas.length > 0
                                        )
                                    ),
                            )
                            .map(key => {
                                return (
                                    <StyledMenuItem key={key} disableRipple disableTouchRipple>
                                        <StyledMenuButton
                                            onClick={() => setSelectedAnalysis(key)}
                                            className={selectedAnalysis === key && "active"}
                                        >
                                            <div className="row">
                                                <div
                                                    className={clsx(
                                                        "icon",
                                                        selectedAnalysis === key && "active",
                                                    )}
                                                >
                                                    <Icon
                                                        path={AnalysisTypesIcons[key]}
                                                        size="inherit"
                                                    />
                                                </div>
                                                <div className="title">
                                                    {t(
                                                        `analysis.types.${AnalysisTypesLabels[key]}`,
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={clsx(
                                                    "subtitle",
                                                    selectedAnalysis === key && "active",
                                                )}
                                            >
                                                {t(
                                                    `analysis.types.${AnalysisTypesLabels[key]}Text`,
                                                )}
                                            </div>
                                        </StyledMenuButton>
                                    </StyledMenuItem>
                                );
                            })}
                    {selectedYearGroup > -1 && (
                        <StyledMenuItem disableRipple disableTouchRipple>
                            <Box paddingY={2.25} paddingX={2.5}>
                                <Button onClick={() => handleAnalysisShow()} color="primary">
                                    {t("common.continue")}
                                </Button>
                            </Box>
                        </StyledMenuItem>
                    )}
                </StyledMenu>
            </Box>
        )
    );
};

export default AnalysisTypeDropdownMenu;
