import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";

export interface TrustDashboardItem {
    id: number;
    name: string;
    numberOfStudents: number;
    currentA8: string;
    projectedA8: string;
    teacherA8: string;
    currentP8: string;
    projectedP8: string;
    teacherP8: string;
}

export const TRUST_DASHBOARD_DATA_QUERY = "trustDashboardDataQuery";

export const useTrustDashboardData = (trustId: number, cohortId: number, yearGroup: number) => {
    return useQuery({
        queryKey: [TRUST_DASHBOARD_DATA_QUERY, trustId, cohortId, yearGroup],
        queryFn: async (): Promise<TrustDashboardItem[] | null> => {
            if (trustId && cohortId && yearGroup) {
                const { data } = await axiosInstance.get(
                    `trust/${trustId}/common/dashboard/${cohortId}/${yearGroup}/`,
                );

                return data || null;
            }
            return null;
        },
    });
};
