import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { OrmSubscriptionHistory } from ".";

export enum OrmSubscriptionHistoryActionTypes {
    BULK_IMPORT = "[orm] SUBSCRIPTION HISTORY BULK IMPORT",
    UPSERT = "[orm] SUBSCRIPTION HISTORY UPSERT",
}

export const OrmSubscriptionHistoryActions = {
    bulkImport: (payload: OrmSubscriptionHistory[]) => {
        return createAction(OrmSubscriptionHistoryActionTypes.BULK_IMPORT, payload);
    },
    upsert: (payload: OrmSubscriptionHistory) => {
        return createAction(OrmSubscriptionHistoryActionTypes.UPSERT, payload);
    },
};

export type OrmSubscriptionHistoryActions = ActionsUnion<typeof OrmSubscriptionHistoryActions>;
