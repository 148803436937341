import clsx from "clsx";
import COLORS from "src/styles/colors";
import Icon from "src/components/Icon";
import {
    Box,
    Theme,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TableFooter,
    Button,
    Chip,
    darken,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { theme } from "src/styles/theme";
import { AgChartsReact } from "ag-charts-react";
import { ViewGrades } from "../../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { SubjectAnalysisCharTypes } from "../../services/analysisValueHandler";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import {
    CustomTableCell,
    VerticalCustomTableCell,
    CustomTableRow,
} from "../../containers/SubjectAnalysisContainer";
import { mdiDownload } from "@mdi/js";
import { getCharBarColor } from "../../services/analysisColourHandler";
import { exportSubjectAnalysisClassToExcel } from "../../services/analysisDataExport";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { useSubjectAnalysisClassData } from "../../hooks/SubjectAnalysis/useSubjectAnalysisClassData";
import { useSubjectAnalysisClassSnapshotData } from "../../hooks/SubjectAnalysis/useSubjectAnalysisClassSnapshotData";
import {
    SubjectAnalysisSubjectArea,
    SubjectAnalysisSubjectAreaListResponse,
} from "../../hooks/SubjectAnalysis/useSubjectAnalysisSubjectAreasWithSpecifications";

const useStyles = makeStyles((theme: Theme) => ({
    innerBigRight: {
        padding: theme.spacing(6, 10, 6, 4),
        [theme.breakpoints.up(1400)]: {
            padding: theme.spacing(8, 27.5, 8, 12.5),
        },
    },
    innerBorderBottom: {
        borderBottom: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
    },
    tableVerticalBottom: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1,
        verticalAlign: "bottom",
    },
    tableBorderLeft: {
        borderLeft: `1px solid ${COLORS.LIGHT_GREY_2}`,
    },
    tableBackground: {
        backgroundColor: "rgba(232, 233, 235, 0.2)",
    },
    tableColorOpacity: {
        color: "#a5acb1 !important",
    },
}));

interface OwnProps {
    cohort: number;
    yearGroup: number;
    specification: number | null;
    viewGrade: ViewGrades;
    dataType: DataTypeTypes;
    snapshot?: number;
    subjectAnalysisSubjects: SubjectAnalysisSubjectAreaListResponse | null;
    subjectArea: number | null;
    classTrackers?: number[];
}

const SubjectAnalysisClassSummary: FC<OwnProps> = ({
    cohort,
    yearGroup,
    specification,
    viewGrade,
    dataType,
    snapshot,
    subjectAnalysisSubjects,
    subjectArea,
    classTrackers,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { data: subjectAnalysisClassBasicData, refetch: getSubjectAnalysisClassData } =
        useSubjectAnalysisClassData(cohort, yearGroup, specification, classTrackers);
    const {
        data: subjectAnalysisClasssSnapshotData,
        refetch: getSubjectAnalysisClassSnapshotData,
    } = useSubjectAnalysisClassSnapshotData(
        cohort,
        yearGroup,
        specification,
        snapshot,
        classTrackers,
    );
    const subjectAnalysisClassData =
        dataType === DataTypeTypes.SNAPSHOT
            ? subjectAnalysisClasssSnapshotData
            : subjectAnalysisClassBasicData;

    const gridApi = useRef() as unknown as { current: GridApi };
    const [chartData, setChartData] = useState([]);
    const [showChart, setShowChart] = useState<boolean>(false);
    const [subjectAreaObject, setSubjectAreaObject] = useState<SubjectAnalysisSubjectArea | null>(
        null,
    );

    const onGridReady = params => {
        gridApi.current = params.api;
    };

    const hasA8Score = !!(
        subjectAnalysisClassData?.length > 0 &&
        subjectAnalysisClassData[0].avgCurrentA8 !== undefined &&
        subjectAnalysisClassData[0].avgCurrentA8 !== null
    );
    const hasP8Score = !!(
        subjectAnalysisClassData?.length > 0 &&
        subjectAnalysisClassData[0].avgCurrentP8 !== undefined &&
        subjectAnalysisClassData[0].avgCurrentP8 !== null
    );
    const hasGrade95 = !!(
        subjectAnalysisClassData?.length > 0 &&
        subjectAnalysisClassData[0].numberCurrentGrade95 !== undefined &&
        subjectAnalysisClassData[0].numberCurrentGrade95 !== null
    );
    const hasGrade94 = !!(
        subjectAnalysisClassData?.length > 0 &&
        subjectAnalysisClassData[0].numberCurrentGrade94 !== undefined &&
        subjectAnalysisClassData[0].numberCurrentGrade94 !== null
    );

    const columnDefs = [
        {
            field: "name",
            headerName: t("analysis.subjectAnalysis.class"),
        },
        {
            field: "teachers",
            headerName: t("analysis.subjectAnalysis.teacher"),
        },
        {
            field: "numberOfStudents",
            headerName: t("analysis.subjectAnalysis.numberOfStudents"),
        },
        {
            field: "a8Score",
            headerName: t("analysis.subjectAnalysis.a8Score"),
            children: [
                {
                    field:
                        viewGrade === ViewGrades.CURRENT
                            ? "avgCurrentA8"
                            : ViewGrades.PROJECTED
                              ? "avgProjectedA8"
                              : "avgTeacherA8",
                    headerName: t("analysis.subjectAnalysis.averageUnitGrade"),
                },
                {
                    field: "avgTargetA8",
                    headerName: t("analysis.subjectAnalysis.minimumTargetGrade"),
                },
            ],
        },
        {
            field: "p8Score",
            headerName: t("analysis.subjectAnalysis.p8Score"),
            children: [
                {
                    field:
                        viewGrade === ViewGrades.CURRENT
                            ? "avgCurrentP8"
                            : ViewGrades.PROJECTED
                              ? "avgProjectedP8"
                              : "avgTeacherP8",
                    headerName: t("analysis.subjectAnalysis.averageUnitGrade"),
                },
                {
                    field: "avgTargetP8",
                    headerName: t("analysis.subjectAnalysis.minimumTargetGrade"),
                },
            ],
        },
        {
            field: "grade95",
            headerName: t("analysis.subjectAnalysis.grade95"),
            children: [
                {
                    field:
                        viewGrade === ViewGrades.CURRENT
                            ? "numberCurrentGrade95"
                            : viewGrade === ViewGrades.PROJECTED
                              ? "numberProjectedGrade95"
                              : "numberTeacherGrade95",
                    headerName: t("analysis.subjectAnalysis.averageUnitGrade"),
                },
                {
                    field: "numberTargetGrade95",
                    headerName: t("analysis.subjectAnalysis.minimumTargetGrade"),
                },
            ],
        },
        {
            field: "grade94",
            headerName: t("analysis.subjectAnalysis.grade94"),
            children: [
                {
                    field:
                        viewGrade === ViewGrades.CURRENT
                            ? "numberCurrentGrade94"
                            : viewGrade === ViewGrades.PROJECTED
                              ? "numberProjectedGrade94"
                              : "numberTeacherGrade94",
                    headerName: t("analysis.subjectAnalysis.averageUnitGrade"),
                },
                {
                    field: "numberTargetGrade94",
                    headerName: t("analysis.subjectAnalysis.minimumTargetGrade"),
                },
            ],
        },
        {
            field:
                viewGrade === ViewGrades.CURRENT
                    ? "numberCurrentAboveTarget"
                    : viewGrade === ViewGrades.PROJECTED
                      ? "numberProjectedAboveTarget"
                      : "numberTeacherAboveTarget",
            headerName: t("analysis.subjectAnalysis.aboveTarget"),
        },
        {
            field:
                viewGrade === ViewGrades.CURRENT
                    ? "numberCurrentOnTarget"
                    : viewGrade === ViewGrades.PROJECTED
                      ? "numberProjectedOnTarget"
                      : "numberTeacherOnTarget",
            headerName: t("analysis.subjectAnalysis.onTarget"),
        },
        {
            field:
                viewGrade === ViewGrades.CURRENT
                    ? "numberCurrenOneGradeAway"
                    : viewGrade === ViewGrades.PROJECTED
                      ? "numberProjectedOneGradeAway"
                      : "numberTeacherOneGradeAway",
            headerName: t("analysis.subjectAnalysis.oneGradeAway"),
        },
        {
            field:
                viewGrade === ViewGrades.CURRENT
                    ? "numberCurrentOffTarget"
                    : viewGrade === ViewGrades.PROJECTED
                      ? "numberProjectedOffTarget"
                      : "numberTeacherOffTarget",
            headerName: t("analysis.subjectAnalysis.offTarget"),
        },
        {
            field: "numberTargetNotSet",
            headerName: t("analysis.subjectAnalysis.numberTargetNotSet"),
        },
    ];

    const getAboveTargetValue = dataRow => {
        return (
            ((viewGrade === ViewGrades.CURRENT
                ? dataRow.numberCurrentAboveTarget
                : viewGrade === ViewGrades.PROJECTED
                  ? dataRow.numberProjectedAboveTarget
                  : dataRow.numberTeacherAboveTarget) /
                dataRow.numberOfStudents) *
            100
        );
    };

    const getOnTargetValue = dataRow => {
        return (
            ((viewGrade === ViewGrades.CURRENT
                ? dataRow.numberCurrentOnTarget
                : viewGrade === ViewGrades.PROJECTED
                  ? dataRow.numberProjectedOnTarget
                  : dataRow.numberTeacherOnTarget) /
                dataRow.numberOfStudents) *
            100
        );
    };

    const getOneGradeAwayValue = dataRow => {
        return (
            ((viewGrade === ViewGrades.CURRENT
                ? dataRow.numberCurrenOneGradeAway
                : viewGrade === ViewGrades.PROJECTED
                  ? dataRow.numberProjectedOneGradeAway
                  : dataRow.numberTeacherOneGradeAway) /
                dataRow.numberOfStudents) *
            100
        );
    };

    const getOffTargetValue = dataRow => {
        return (
            ((viewGrade === ViewGrades.CURRENT
                ? dataRow.numberCurrentOffTarget
                : viewGrade === ViewGrades.PROJECTED
                  ? dataRow.numberProjectedOffTarget
                  : dataRow.numberTeacherOffTarget) /
                dataRow.numberOfStudents) *
            100
        );
    };

    useEffect(() => {
        let isChartDataEmpty = true;

        const chartDatatoProcess = [];

        if (subjectAnalysisClassData && subjectAnalysisClassData.length > 0) {
            subjectAnalysisClassData.forEach((sac, i) => {
                if (i > 0) {
                    chartDatatoProcess.push({
                        class: sac.name,
                        aboveTarget:
                            viewGrade === ViewGrades.CURRENT
                                ? sac.numberCurrentAboveTarget
                                : viewGrade === ViewGrades.PROJECTED
                                  ? sac.numberProjectedAboveTarget
                                  : sac.numberTeacherAboveTarget,
                        onTarget:
                            viewGrade === ViewGrades.CURRENT
                                ? sac.numberCurrentOnTarget
                                : viewGrade === ViewGrades.PROJECTED
                                  ? sac.numberProjectedOnTarget
                                  : sac.numberTeacherOnTarget,
                        oneGradeAway:
                            viewGrade === ViewGrades.CURRENT
                                ? sac.numberCurrenOneGradeAway
                                : viewGrade === ViewGrades.PROJECTED
                                  ? sac.numberProjectedOneGradeAway
                                  : sac.numberTeacherOneGradeAway,
                        offTarget:
                            viewGrade === ViewGrades.CURRENT
                                ? sac.numberCurrentOffTarget
                                : viewGrade === ViewGrades.PROJECTED
                                  ? sac.numberProjectedOffTarget
                                  : sac.numberTeacherOffTarget,
                        numberTargetNotSet: sac.numberTargetNotSet,
                    });
                }
            });
        }

        chartDatatoProcess.forEach(chartData => {
            Object.keys(chartData).forEach(key => {
                if (key !== "class" && chartData[key]) {
                    isChartDataEmpty = false;
                }
            });
        });
        setShowChart(!isChartDataEmpty);
        setChartData(chartDatatoProcess);
    }, [subjectAnalysisClassData, viewGrade]);

    useEffect(() => {
        setSubjectAreaObject(subjectAnalysisSubjects?.find(sa => sa.id === subjectArea) || null);
    }, [subjectAnalysisSubjects]);

    useEffect(() => {
        if (dataType === DataTypeTypes.LIVE && specification) {
            getSubjectAnalysisClassData();
        }
        if (dataType === DataTypeTypes.SNAPSHOT && snapshot && specification) {
            getSubjectAnalysisClassSnapshotData();
        }
    }, [cohort, yearGroup, specification, dataType, snapshot, classTrackers]);

    const getTableGradeLabel = () => {
        return viewGrade === ViewGrades.CURRENT ? (
            <Trans i18nKey="analysis.subjectAnalysis.currentUnitGrade">
                Current Unit <br />
                Grade
            </Trans>
        ) : viewGrade === ViewGrades.PROJECTED ? (
            <Trans i18nKey="analysis.subjectAnalysis.averageUnitGrade">
                Average Unit <br />
                Grade
            </Trans>
        ) : (
            <Trans i18nKey="analysis.subjectAnalysis.teacherUnitGrade">
                Teacher Predicted <br />
                Grade
            </Trans>
        );
    };

    return (
        <Box className={clsx(classes.innerBigRight, classes.innerBorderBottom)}>
            <Typography variant="h6">{t("analysis.subjectAnalysis.classSummary")}</Typography>
            <Box className="ag-theme-alpine">
                <AgGridReact
                    onGridReady={onGridReady}
                    columnDefs={columnDefs as any[]}
                    rowData={subjectAnalysisClassData || []}
                    gridOptions={{ domLayout: "print" }}
                    containerStyle={{ display: "none" }}
                />
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <CustomTableCell size="small" colSpan={3} />
                        {hasA8Score && (
                            <CustomTableCell size="small" colSpan={2}>
                                {t("analysis.subjectAnalysis.a8Score")}
                            </CustomTableCell>
                        )}
                        {hasP8Score && (
                            <CustomTableCell size="small" colSpan={2}>
                                {t("analysis.subjectAnalysis.p8Score")}
                            </CustomTableCell>
                        )}
                        {hasGrade95 && (
                            <CustomTableCell size="small" colSpan={2}>
                                {t("analysis.subjectAnalysis.grade95")}
                            </CustomTableCell>
                        )}
                        {hasGrade94 && (
                            <CustomTableCell size="small" colSpan={2}>
                                {t("analysis.subjectAnalysis.grade94")}
                            </CustomTableCell>
                        )}
                        <CustomTableCell size="small" />
                        <CustomTableCell size="small" />
                        <CustomTableCell size="small" />
                        <CustomTableCell size="small" />
                        <CustomTableCell size="small" />
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.tableVerticalBottom}>
                            {t("analysis.subjectAnalysis.class")}
                        </TableCell>
                        <TableCell className={classes.tableVerticalBottom}>
                            {t("analysis.subjectAnalysis.teacher")}
                        </TableCell>
                        <TableCell className={classes.tableVerticalBottom}>
                            {t("analysis.subjectAnalysis.numberOfStudents")}
                        </TableCell>
                        {hasA8Score && (
                            <Fragment>
                                <VerticalCustomTableCell
                                    className={clsx(
                                        classes.tableBackground,
                                        classes.tableBorderLeft,
                                    )}
                                >
                                    <span>{getTableGradeLabel()}</span>
                                </VerticalCustomTableCell>
                                <VerticalCustomTableCell className={classes.tableBackground}>
                                    <span>
                                        <Trans i18nKey="analysis.subjectAnalysis.minimumTargetGrade">
                                            Minimum Target <br />
                                            Grade
                                        </Trans>
                                    </span>
                                </VerticalCustomTableCell>
                            </Fragment>
                        )}
                        {hasP8Score && (
                            <Fragment>
                                <VerticalCustomTableCell className={classes.tableBorderLeft}>
                                    <span>{getTableGradeLabel()}</span>
                                </VerticalCustomTableCell>
                                <VerticalCustomTableCell>
                                    <span>
                                        <Trans i18nKey="analysis.subjectAnalysis.minimumTargetGrade">
                                            Minimum Target <br />
                                            Grade
                                        </Trans>
                                    </span>
                                </VerticalCustomTableCell>
                            </Fragment>
                        )}
                        {hasGrade95 && (
                            <Fragment>
                                <VerticalCustomTableCell
                                    className={clsx(
                                        classes.tableBackground,
                                        classes.tableBorderLeft,
                                    )}
                                >
                                    <span>{getTableGradeLabel()}</span>
                                </VerticalCustomTableCell>
                                <VerticalCustomTableCell className={classes.tableBackground}>
                                    <span>
                                        <Trans i18nKey="analysis.subjectAnalysis.minimumTargetGrade">
                                            Minimum Target <br />
                                            Grade
                                        </Trans>
                                    </span>
                                </VerticalCustomTableCell>
                            </Fragment>
                        )}
                        {hasGrade94 && (
                            <Fragment>
                                <VerticalCustomTableCell className={classes.tableBorderLeft}>
                                    <span>{getTableGradeLabel()}</span>
                                </VerticalCustomTableCell>
                                <VerticalCustomTableCell>
                                    <span>
                                        <Trans i18nKey="analysis.subjectAnalysis.minimumTargetGrade">
                                            Minimum Target <br />
                                            Grade
                                        </Trans>
                                    </span>
                                </VerticalCustomTableCell>
                            </Fragment>
                        )}
                        <VerticalCustomTableCell className={classes.tableBorderLeft}>
                            <Box component="span" color={COLORS.BLUE_4}>
                                % {t("analysis.subjectAnalysis.aboveTarget")}
                            </Box>
                        </VerticalCustomTableCell>
                        <VerticalCustomTableCell>
                            <Box component="span" color={COLORS.GREEN_2}>
                                % {t("analysis.subjectAnalysis.onTarget")}
                            </Box>
                        </VerticalCustomTableCell>
                        <VerticalCustomTableCell>
                            <Box component="span" color={COLORS.ORANGE_1}>
                                % {t("analysis.subjectAnalysis.oneGradeAway")}
                            </Box>
                        </VerticalCustomTableCell>
                        <VerticalCustomTableCell>
                            <Box component="span" color={COLORS.RED_2}>
                                % {t("analysis.subjectAnalysis.offTarget")}
                            </Box>
                        </VerticalCustomTableCell>
                        <VerticalCustomTableCell>
                            <Box component="span" color={COLORS.GREY_1}>
                                % {t("analysis.subjectAnalysis.numberTargetNotSet")}
                            </Box>
                        </VerticalCustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subjectAnalysisClassData &&
                        subjectAnalysisClassData.map((dataRow, index) => {
                            const row = (
                                <Fragment>
                                    <CustomTableCell
                                        variant={index === 0 ? "head" : "body"}
                                        colSpan={index === 0 ? 2 : 1}
                                    >
                                        {index > 0 ? (
                                            <Chip
                                                style={{
                                                    backgroundColor:
                                                        subjectAreaObject?.colour || COLORS.BLUE_1,
                                                }}
                                                label={dataRow.name}
                                            />
                                        ) : (
                                            dataRow.name
                                        )}
                                    </CustomTableCell>
                                    {index > 0 ? (
                                        <CustomTableCell variant={index === 0 ? "head" : "body"}>
                                            {dataRow?.teachers?.map(teacher => (
                                                <Box
                                                    key={teacher.id}
                                                >{`${teacher.firstName} ${teacher.lastName}`}</Box>
                                            ))}
                                        </CustomTableCell>
                                    ) : null}
                                    <CustomTableCell variant={index === 0 ? "head" : "body"}>
                                        {index > 0 ? (
                                            <Box fontWeight="fontWeightBold">
                                                {dataRow.numberOfStudents}
                                            </Box>
                                        ) : (
                                            dataRow.numberOfStudents
                                        )}
                                    </CustomTableCell>
                                    {hasA8Score && (
                                        <Fragment>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    classes.tableBackground,
                                                    classes.tableBorderLeft,
                                                )}
                                            >
                                                {viewGrade === ViewGrades.CURRENT
                                                    ? dataRow.avgCurrentA8
                                                    : viewGrade === ViewGrades.PROJECTED
                                                      ? dataRow.avgProjectedA8
                                                      : dataRow.avgTeacherA8}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={classes.tableBackground}
                                            >
                                                {dataRow.avgTargetA8}
                                            </CustomTableCell>
                                        </Fragment>
                                    )}
                                    {hasP8Score && (
                                        <Fragment>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={classes.tableBorderLeft}
                                            >
                                                {viewGrade === ViewGrades.CURRENT
                                                    ? dataRow.avgCurrentP8
                                                    : viewGrade === ViewGrades.PROJECTED
                                                      ? dataRow.avgProjectedP8
                                                      : dataRow.avgTeacherP8}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                            >
                                                {dataRow.avgTargetP8}
                                            </CustomTableCell>
                                        </Fragment>
                                    )}
                                    {hasGrade95 && (
                                        <Fragment>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    classes.tableBackground,
                                                    classes.tableBorderLeft,
                                                    viewGrade === ViewGrades.CURRENT
                                                        ? dataRow.numberCurrentGrade95
                                                        : viewGrade === ViewGrades.PROJECTED
                                                          ? dataRow.numberProjectedGrade95
                                                          : dataRow.numberTeacherGrade95 === 0 &&
                                                              index > 0
                                                            ? classes.tableColorOpacity
                                                            : undefined,
                                                )}
                                            >
                                                {viewGrade === ViewGrades.CURRENT
                                                    ? dataRow.numberCurrentGrade95
                                                    : viewGrade === ViewGrades.PROJECTED
                                                      ? dataRow.numberProjectedGrade95
                                                      : dataRow.numberTeacherGrade95}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    classes.tableBackground,
                                                    dataRow.numberTargetGrade95 === 0 && index > 0
                                                        ? classes.tableColorOpacity
                                                        : undefined,
                                                )}
                                            >
                                                {dataRow.numberTargetGrade95}
                                            </CustomTableCell>
                                        </Fragment>
                                    )}
                                    {hasGrade94 && (
                                        <Fragment>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    classes.tableBorderLeft,
                                                    viewGrade === ViewGrades.CURRENT
                                                        ? dataRow.numberCurrentGrade94
                                                        : viewGrade === ViewGrades.PROJECTED
                                                          ? dataRow.numberProjectedGrade94
                                                          : dataRow.numberTeacherGrade94 === 0 &&
                                                              index > 0
                                                            ? classes.tableColorOpacity
                                                            : undefined,
                                                )}
                                            >
                                                {viewGrade === ViewGrades.CURRENT
                                                    ? dataRow.numberCurrentGrade94
                                                    : viewGrade === ViewGrades.PROJECTED
                                                      ? dataRow.numberProjectedGrade94
                                                      : dataRow.numberTeacherGrade94}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    dataRow.numberTargetGrade94 === 0 && index > 0
                                                        ? classes.tableColorOpacity
                                                        : undefined,
                                                )}
                                            >
                                                {dataRow.numberTargetGrade94}
                                            </CustomTableCell>
                                        </Fragment>
                                    )}
                                    <CustomTableCell
                                        variant={index === 0 ? "head" : "body"}
                                        className={clsx(
                                            classes.tableBorderLeft,
                                            Math.round(getAboveTargetValue(dataRow)) === 0 &&
                                                index > 0
                                                ? classes.tableColorOpacity
                                                : undefined,
                                        )}
                                    >
                                        {!isNaN(getAboveTargetValue(dataRow))
                                            ? Math.round(getAboveTargetValue(dataRow)) + "%"
                                            : "-"}
                                    </CustomTableCell>
                                    <CustomTableCell
                                        variant={index === 0 ? "head" : "body"}
                                        className={clsx(
                                            Math.round(getOnTargetValue(dataRow)) === 0 && index > 0
                                                ? classes.tableColorOpacity
                                                : undefined,
                                        )}
                                    >
                                        {!isNaN(getOnTargetValue(dataRow))
                                            ? Math.round(getOnTargetValue(dataRow)) + "%"
                                            : "-"}
                                    </CustomTableCell>
                                    <CustomTableCell
                                        variant={index === 0 ? "head" : "body"}
                                        className={clsx(
                                            Math.round(getOneGradeAwayValue(dataRow)) === 0 &&
                                                index > 0
                                                ? classes.tableColorOpacity
                                                : undefined,
                                        )}
                                    >
                                        {!isNaN(getOneGradeAwayValue(dataRow))
                                            ? Math.round(getOneGradeAwayValue(dataRow)) + "%"
                                            : "-"}
                                    </CustomTableCell>
                                    <CustomTableCell
                                        variant={index === 0 ? "head" : "body"}
                                        className={clsx(
                                            Math.round(getOffTargetValue(dataRow)) === 0 &&
                                                index > 0
                                                ? classes.tableColorOpacity
                                                : undefined,
                                        )}
                                    >
                                        {!isNaN(getOffTargetValue(dataRow))
                                            ? Math.round(getOffTargetValue(dataRow)) + "%"
                                            : "-"}
                                    </CustomTableCell>
                                    <CustomTableCell
                                        variant={index === 0 ? "head" : "body"}
                                        className={clsx(
                                            Math.round(
                                                (dataRow.numberTargetNotSet /
                                                    dataRow.numberOfStudents) *
                                                    100,
                                            ) === 0 && index > 0
                                                ? classes.tableColorOpacity
                                                : undefined,
                                        )}
                                    >
                                        {!isNaN(
                                            (dataRow.numberTargetNotSet /
                                                dataRow.numberOfStudents) *
                                                100,
                                        )
                                            ? Math.round(
                                                  (dataRow.numberTargetNotSet /
                                                      dataRow.numberOfStudents) *
                                                      100,
                                              ) + "%"
                                            : "-"}
                                    </CustomTableCell>
                                </Fragment>
                            );
                            return index === 0 ? (
                                <CustomTableRow key={index}>{row}</CustomTableRow>
                            ) : (
                                <TableRow key={index}>{row}</TableRow>
                            );
                        })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <CustomTableCell colSpan={15}>
                            <Button
                                variant="text"
                                onClick={() => exportSubjectAnalysisClassToExcel(gridApi.current)}
                                startIcon={<Icon path={mdiDownload} />}
                                disableRipple
                            >
                                {t("analysis.subjectAnalysis.downloadExcelBtn")}
                            </Button>
                        </CustomTableCell>
                    </TableRow>
                </TableFooter>
            </Table>
            {showChart && chartData && chartData.length > 0 ? (
                <Box height={450} width={chartData.length > 6 ? "100%" : "75%"}>
                    <AgChartsReact
                        options={{
                            data: chartData,
                            autoSize: true,
                            series: [
                                {
                                    type: "column",
                                    xKey: "class",
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    //@ts-ignore
                                    yKeys: [
                                        SubjectAnalysisCharTypes.ABOVE_TARGET,
                                        SubjectAnalysisCharTypes.ON_TARGET,
                                        SubjectAnalysisCharTypes.ONE_GRADE_AWAY,
                                        SubjectAnalysisCharTypes.OFF_TARGET,
                                        SubjectAnalysisCharTypes.NOT_SET,
                                    ],
                                    yNames: [
                                        t("analysis.subjectAnalysis.aboveTarget"),
                                        t("analysis.subjectAnalysis.onTarget"),
                                        t("analysis.subjectAnalysis.oneGradeAway"),
                                        t("analysis.subjectAnalysis.offTarget"),
                                        t("analysis.subjectAnalysis.numberTargetNotSet"),
                                    ],
                                    grouped: true,
                                    fills: [
                                        COLORS.BLUE_4,
                                        COLORS.GREEN_2,
                                        COLORS.ORANGE_1,
                                        COLORS.RED_2,
                                        COLORS.GREY_1,
                                    ],
                                    strokes: [
                                        COLORS.BLUE_4,
                                        COLORS.GREEN_2,
                                        COLORS.ORANGE_1,
                                        COLORS.RED_2,
                                        COLORS.GREY_1,
                                    ],
                                    strokeOpacity: 0,
                                    formatter: params => ({
                                        fill: params.highlighted
                                            ? darken(
                                                  getCharBarColor(
                                                      params.yKey as SubjectAnalysisCharTypes,
                                                  ),
                                                  0.2,
                                              )
                                            : getCharBarColor(
                                                  params.yKey as SubjectAnalysisCharTypes,
                                              ),
                                    }),
                                },
                            ],
                            axes: [
                                {
                                    type: "category",
                                    position: "bottom",
                                    label: { rotation: 30 },
                                },
                                {
                                    type: "number",
                                    position: "left",
                                    label: {
                                        fontWeight: "400",
                                        fontSize: 12,
                                        fontFamily: theme.typography.fontFamily,
                                    },
                                    title: {
                                        text: t("analysis.subjectAnalysis.numberOfStudents"),
                                    },
                                },
                            ],
                            legend: {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                color: COLORS.GREY_1,
                                fontWeight: 400,
                                fontSize: 14,
                                fontFamily: theme.typography.fontFamily,
                                layoutVerticalSpacing: 16,
                                itemSpacing: 12,
                                markerSize: 16,
                                strokeWidth: 0,
                            },
                        }}
                    />
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default SubjectAnalysisClassSummary;
