/* eslint-disable no-prototype-builtins */
import { StringMap } from "@martin_hotell/rex-tils";
import { removeSpecialCharacters } from "./utilsGPT";
import { OrmSpecification } from "src/orm/models/Specification";
import { SpecificationModel } from "src/modules/tracker/models/SpecificationModel";
import { Storage } from "./storage";

export const processUrl = (url: string, params: StringMap<string | undefined>): string =>
    url.replace(/:(\w+)/g, (all, key) => params[key] || all);

export const convertParamsToNumber = <T>(params: T): { [TKey in keyof T]: number } => {
    const result: any = {};

    Object.keys(params as any).forEach(key => {
        result[key] = parseInt(params[key], 10);
    });

    return result;
};

export const serialize = obj => {
    const str = [] as any;
    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            const encoded = (encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])) as any;
            str.push(encoded);
        }
    return str.join("&");
};

export const generateTrackerUrl = (
    url: string,
    id: number,
    cohort: number,
    subject: string,
    qualification: string,
    specification: SpecificationModel | OrmSpecification | string | null,
    tier: string,
    yearGroup: number | string,
) => {
    const processed = url
        .replace(":classTrackerGroup", `${id}`)
        .replace(":classTracker", `${id}`)
        .replace(":cohort", `${cohort}`)
        .replace(":yearGroup", `${yearGroup}`)
        .replace(
            ":specification",
            typeof specification === "string"
                ? specification
                : removeSpecialCharacters(
                      `${
                          specification?.parentName
                              ? specification?.parentName + "-" + specification?.name || ""
                              : specification?.name || ""
                      }`,
                  ),
        )
        .replace(":subject", removeSpecialCharacters(`${subject}`))
        .replace(":qualification", removeSpecialCharacters(`${qualification}`))
        .replace(
            ":tier",
            tier === "-"
                ? "-"
                : tier === "Foundation"
                  ? "F"
                  : tier === "Intermediate"
                    ? "I"
                    : tier === "Higher"
                      ? "H"
                      : tier,
        );

    return processed;
};

export const getSchoolAccountId = () => {
    if (location) {
        const match = location.pathname.match(/\/school\/(\d+)\//);
        const schoolAccountIdFromUrl = match ? parseInt(match[1]) : null;

        return schoolAccountIdFromUrl || Storage.getItem("schoolAccountId") || null;
    }

    return null;
};

export const getTrustAccountId = () => {
    if (location) {
        const match = location.pathname.match(/\/mat\/(\d+)\//);
        const trustIdFromUrl = match ? parseInt(match[1]) : null;

        return trustIdFromUrl || Storage.getItem("trustId") || null;
    }

    return null;
};
