import { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import Icon from "src/components/Icon";
import {
    mdiDownload,
    mdiGrid,
    mdiGridOff,
    mdiRepeat,
    mdiRepeatOff,
    mdiSquareEditOutline,
} from "@mdi/js";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import {
    ROUTE_CLASS_EDIT_CLASS,
    ROUTE_TRACKER_CLASS_TRACKER,
    ROUTE_TRACKER_CLASS_TRACKER_BULK_EDIT,
    ROUTE_TRACKER_CLASS_TRACKER_MOVE_STUDENTS,
} from "src/routes";
import useDebounce from "src/hooks/useDebounce";
import PromptDialog from "src/forms/PromptDialog";
import COLORS from "src/styles/colors";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { useParams } from "react-router";
import { generateTrackerUrl } from "src/services/url";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const LiveTrackerActionButtons = ({
    isSyncedClass,
    exportAsExcel,
    saveBulkEdit,
    tier,
    archived,
}: {
    isDemoClass?: boolean;
    isSyncedClass?: boolean;
    exportAsExcel: () => void;
    saveBulkEdit?: () => void;
    tier?: TiersTypes;
    archived?: boolean;
}) => {
    const { t }: { t: TFunction } = useTranslation();
    const {
        bulkEdit,
        classTracker,
        cohort,
        moveStudents,
        specification,
        qualification,
        subject,
        yearGroup,
    } = useParams();

    const moveStudentsMode = useDebounce(!!moveStudents, 800);
    const { navigate } = useSchoolNavigate();
    const [syncedClassWarningOpen, setSyncedClassWarningOpen] = useState(false);

    const discardBulkEdit = () => {
        navigate(
            generateTrackerUrl(
                ROUTE_TRACKER_CLASS_TRACKER,
                parseInt(classTracker),
                parseInt(cohort),
                subject,
                qualification,
                specification,
                tier || "-",
                parseInt(`${yearGroup}`),
            ),
        );
        navigate(0);
    };

    const goToBulkEdit = () => {
        navigate(
            generateTrackerUrl(
                ROUTE_TRACKER_CLASS_TRACKER_BULK_EDIT,
                parseInt(classTracker),
                parseInt(cohort),
                subject,
                qualification,
                specification,
                tier || "-",
                parseInt(`${yearGroup}`),
            ).replace(":bulkEdit", "1"),
        );
        navigate(0);
    };

    const goToTrackerEdit = () => {
        navigate(
            ROUTE_CLASS_EDIT_CLASS.replace(":class", classTracker || "") +
                `?await=1&returnUrl=` +
                encodeURIComponent(location.pathname + location.search),
        );
        navigate(0);
    };

    const goToMoveStudents = () => {
        if (!isSyncedClass || (isSyncedClass && tier && tier !== "-")) {
            navigate(
                generateTrackerUrl(
                    ROUTE_TRACKER_CLASS_TRACKER_MOVE_STUDENTS,
                    parseInt(classTracker),
                    parseInt(cohort),
                    subject,
                    qualification,
                    specification,
                    tier || "-",
                    parseInt(`${yearGroup}`),
                ).replace(":moveStudents", "1"),
            );
            navigate(0);
        } else {
            setSyncedClassWarningOpen(true);
        }
    };

    const closeMoveStudents = () => {
        navigate(
            generateTrackerUrl(
                ROUTE_TRACKER_CLASS_TRACKER,
                parseInt(classTracker),
                parseInt(cohort),
                subject,
                qualification,
                specification,
                tier || "-",
                parseInt(`${yearGroup}`),
            ),
        );
        navigate(0);
    };

    return (
        <>
            <Box
                px={4}
                py={3}
                borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                display="flex"
                flexDirection="column"
            >
                <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27} mb={1}>
                    {t("tracker.grid.toolsTracker")}
                </Box>
                {!bulkEdit && (
                    <Button
                        variant="text"
                        onClick={exportAsExcel}
                        startIcon={<Icon path={mdiDownload} />}
                        disableRipple
                    >
                        {t("tracker.grid.downloadBtn")}
                    </Button>
                )}
                {!archived &&
                    (!bulkEdit ? (
                        <Button
                            onClick={() => goToBulkEdit()}
                            variant="text"
                            startIcon={<Icon path={mdiGrid} />}
                            disableRipple
                        >
                            {t("tracker.grid.bulkEditBtn")}
                        </Button>
                    ) : (
                        <>
                            <Button
                                onClick={saveBulkEdit}
                                variant="text"
                                color="primary"
                                startIcon={<Icon path={mdiGrid} />}
                                disableRipple
                            >
                                {t("tracker.grid.saveBulkEdit")}
                            </Button>
                            <Button
                                onClick={discardBulkEdit}
                                variant="text"
                                color="primary"
                                startIcon={<Icon path={mdiGridOff} />}
                                disableRipple
                            >
                                {t("tracker.grid.discardBulkEdit")}
                            </Button>
                        </>
                    ))}
                <>
                    {!bulkEdit &&
                        (moveStudentsMode ? (
                            <Button
                                variant="text"
                                color="primary"
                                onClick={closeMoveStudents}
                                startIcon={<Icon path={mdiRepeatOff} />}
                                disableRipple
                            >
                                {t("tracker.grid.closeMoveStudents")}
                            </Button>
                        ) : !isSyncedClass || (isSyncedClass && tier && tier !== "-") ? (
                            <Button
                                variant="text"
                                onClick={goToMoveStudents}
                                startIcon={<Icon path={mdiRepeat} />}
                                disableRipple
                            >
                                {isSyncedClass && tier !== "-"
                                    ? t("tracker.grid.moveStudentsTiersSynced")
                                    : !isSyncedClass && tier && tier !== "-"
                                      ? t("tracker.grid.moveStudentsTiers")
                                      : t("tracker.grid.moveStudents")}
                            </Button>
                        ) : (
                            <></>
                        ))}
                </>
                {!bulkEdit && !archived && (
                    <Button
                        variant="text"
                        onClick={goToTrackerEdit}
                        startIcon={<Icon path={mdiSquareEditOutline} />}
                        disableRipple
                    >
                        {t("tracker.grid.editTrackerOptionsBtn")}
                    </Button>
                )}
            </Box>
            <PromptDialog
                noLabel=""
                yesLabel="Ok"
                open={syncedClassWarningOpen}
                onClose={() => setSyncedClassWarningOpen(false)}
            >
                <Typography variant="h3">
                    These classes are managed by syncing with your MIS system
                </Typography>
                <Typography variant="body2">
                    If you want to make changes to these classlists, please contact your school’s
                    data manager to make the changes on your MIS e.g. SIMs, Bromcom, Arbor, iSAMS”
                </Typography>
            </PromptDialog>
        </>
    );
};

export default LiveTrackerActionButtons;
