import AppContainer from "src/components/AppContainer";
import ActionsTableButton from "src/components/ActionTableButton";
import PaperInner from "src/components/PaperInner";
import Icon from "src/components/Icon";
import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useTrustUserDetails } from "../hooks/useTrustUserDetails";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { TrustUserSchool } from "../models/TrustUserModel";
import { ROUTE_MY_SCHOOL_STAFF_EDIT } from "src/routes";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useStaffActivateMutation } from "src/modules/school/hooks/query/Staff/useStaffActivateMutation";
import { useStaffDeactivateMutation } from "src/modules/school/hooks/query/Staff/useStaffDeactivateMutation";
import { mdiAccountLockOpenOutline, mdiAccountLockOutline } from "@mdi/js";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useUserContext } from "src/modules/user/hooks/useUserContext";

const TrustUserDetailsContainer = () => {
    const params = useParams();
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { data: userContext } = useUserContext();
    const { mutate: activateStaff, isSuccess: isSuccessActivate } = useStaffActivateMutation(() => {
        enqueueSnackbar(t("common.saved"), { variant: "success" });
    });
    const { mutate: deactivateStaff, isSuccess: isSuccessDeactivate } = useStaffDeactivateMutation(
        () => {
            enqueueSnackbar(t("common.saved"), { variant: "success" });
        },
    );

    const {
        data: trustUser,
        isLoading,
        refetch,
    } = useTrustUserDetails(parseInt(params.trust), parseInt(params.id));

    const canManageThisSchoolAccount = (schoolAccountId: number) => {
        return !!userContext.schoolAccounts.find(sa => sa.id === schoolAccountId);
    };

    useEffect(() => {
        if (isSuccessActivate || isSuccessDeactivate) {
            refetch();
        }
    }, [isSuccessActivate, isSuccessDeactivate]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("trust.users.header")}
            </Typography>
            {isLoading && <CircularProgress />}
            {trustUser && (
                <Paper>
                    <PaperInner>
                        <Typography variant="h2" sx={{ color: "text.primary" }}>
                            {trustUser.title} {trustUser.firstName} {trustUser.lastName}
                        </Typography>
                        <Typography sx={{ color: "text.secondary", fontStyle: "italic" }}>
                            {trustUser.email}
                        </Typography>
                    </PaperInner>
                    <PaperInner border="top">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("trust.users.account")}</TableCell>
                                    <TableCell>{t("trust.users.status")}</TableCell>
                                    <TableCell>{t("trust.users.role")}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trustUser.schools.map(school => (
                                    <TableRow key={school.id}>
                                        <TableCell>{school.name}</TableCell>
                                        <TableCell>{school.status}</TableCell>
                                        <TableCell>
                                            {school.userRole ? (
                                                <Box
                                                    sx={{
                                                        color: "text.secondary",
                                                        fontStyle: "italic",
                                                    }}
                                                >
                                                    {t("common.roles." + school.userRole)}
                                                </Box>
                                            ) : (
                                                t("common.noAccess")
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Box textAlign={"right"}>
                                                <ActionsTableButton
                                                    actions={[
                                                        getMenuActionProps("active", {
                                                            text: t("trust.users.grantAccess"),
                                                            icon: (
                                                                <Icon
                                                                    path={mdiAccountLockOpenOutline}
                                                                />
                                                            ),
                                                            onAction: (
                                                                rowData: TrustUserSchool,
                                                            ) => {
                                                                activateStaff({
                                                                    schoolAccountId:
                                                                        rowData.schoolAccountId,
                                                                    id: rowData.schoolUserId,
                                                                });
                                                            },
                                                            showAction: (
                                                                rowData: TrustUserSchool,
                                                            ) => rowData.status === "deactivated",
                                                        }),
                                                        getMenuActionProps("deactive", {
                                                            text: t("trust.users.revokeAccess"),
                                                            icon: (
                                                                <Icon
                                                                    path={mdiAccountLockOutline}
                                                                />
                                                            ),
                                                            onAction: (
                                                                rowData: TrustUserSchool,
                                                            ) => {
                                                                deactivateStaff({
                                                                    schoolAccountId:
                                                                        rowData.schoolAccountId,
                                                                    id: rowData.schoolUserId,
                                                                });
                                                            },
                                                            showAction: (
                                                                rowData: TrustUserSchool,
                                                            ) => rowData.status === "active",
                                                        }),
                                                        getMenuActionProps("show", {
                                                            text: t("trust.users.editRole"),
                                                            onAction: (
                                                                rowData: TrustUserSchool,
                                                            ) => {
                                                                navigate(
                                                                    ROUTE_MY_SCHOOL_STAFF_EDIT.replace(
                                                                        ":schoolAccountId",
                                                                        `${rowData.schoolAccountId}`,
                                                                    ).replace(
                                                                        ":staff",
                                                                        `${rowData.schoolUserId}`,
                                                                    ),
                                                                );
                                                                navigate(0);
                                                            },
                                                            showAction: (
                                                                rowData: TrustUserSchool,
                                                            ) =>
                                                                !!rowData.schoolUserId &&
                                                                canManageThisSchoolAccount(
                                                                    rowData.schoolAccountId,
                                                                ),
                                                        }),
                                                    ]}
                                                    rowData={school}
                                                    forceDotMenu={true}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </PaperInner>
                </Paper>
            )}
        </AppContainer>
    );
};

export default TrustUserDetailsContainer;
