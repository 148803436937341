import { createRef, useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    MenuItem,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AppContainer from "src/components/AppContainer";
import { filterNotNullable } from "src/services/object";
import Tab from "src/components/Tabs/Tab";
import FormikRef from "src/forms/FormikRef";
import CustomFieldForm from "../components/CustomFieldForm";
import { SchoolCustomFieldTypes } from "src/orm/models/SchoolCustomField";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import PromptDialog from "src/forms/PromptDialog";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { useCustomFieldsEditMutation } from "../hooks/query/CustomFields/useCustomFieldsEditMutation";
import { useCustomFieldsDefaultConfig } from "../hooks/query/CustomFields/useCustomFieldsDefaultConfig";
import { useCustomFieldsList } from "../hooks/query/CustomFields/useCustomFieldsList";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const CustomFieldsConfigContainer = () => {
    const form = createRef() as any;
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const [tmpConfirm, setTmpConfirm] = useState<string>("");
    const [selectedCohort, setSelectedCohort] = useState<number | null>(null);
    const [currentTab, setCurrentTab] = useState<string | null>(null);
    const [tabs, setTabs] = useState<{ key: string; label: string }[]>([]);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

    const { data } = useCoreValues();
    const cohorts = data?.cohorts;

    const { data: accountInfo } = useAccountInfo();
    const activeCohort = accountInfo?.activeCohortId;
    const { data: customFieldsDefaultConfig } = useCustomFieldsDefaultConfig();
    const { data: schoolCustomFields, isLoading, isSuccess } = useCustomFieldsList(selectedCohort);
    const { mutate: editSchoolCustomField } = useCustomFieldsEditMutation(
        () => {
            navigate(0);
        },
        ({ error }) => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);

            if (error.formError) {
                currentForm.setErrors(error);
            }
        },
    );

    const handleTabChange = (event: React.ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    const handleSubmit = values => {
        const converted = values.fields.map(field => {
            return {
                ...field,
                configurations: field.configurations.map(config => {
                    return {
                        ...config,
                        displayedName: config.displayedName,
                        fieldValues: config.useDefaultValues
                            ? []
                            : config.fieldValues.map(fv => `${fv}`),
                    };
                }),
            };
        });

        editSchoolCustomField({ fields: converted });
    };

    useEffect(() => {
        if (activeCohort && selectedCohort !== activeCohort) {
            setSelectedCohort(activeCohort);
        } //eslint-disable-next-line
    }, [activeCohort]);

    const activeCohortId = accountInfo?.activeCohortId || null;

    useEffect(() => {
        if (cohorts?.length > 0 && !selectedCohort) {
            const defaultCohort = cohorts?.find(c => c.id === activeCohortId);
            let cohortId = cohorts[0].id;
            if (defaultCohort) {
                cohortId = defaultCohort.id;
            }
            setSelectedCohort(cohortId);
        }
        //eslint-disable-next-line
    }, [cohorts, selectedCohort]);

    useEffect(() => {
        if (customFieldsDefaultConfig && customFieldsDefaultConfig.length > 0) {
            setTabs(
                customFieldsDefaultConfig.map(cfdc => ({ key: cfdc.fieldType, label: cfdc.name })),
            );
            setCurrentTab(customFieldsDefaultConfig[0].fieldType);
        }
    }, [customFieldsDefaultConfig]);

    useEffect(() => {
        if (schoolCustomFields && schoolCustomFields.length > 0 && form.current) {
            const newConfigurations = form.current?.values.fields.map(field => {
                return {
                    ...field,
                    configurations: schoolCustomFields
                        .filter(scf => scf.fieldType === field.fieldType)
                        .map(scf => ({ ...scf, displayedName: scf.displayedName || "" })),
                };
            });

            form.current.setFieldValue("fields", newConfigurations);
        }

        //eslint-disable-next-line
    }, [schoolCustomFields, form.current]);

    const canSave = !!(activeCohort === selectedCohort && accountInfo?.customFieldsEditionEnabled);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("school.customFields.tab")}
            </Typography>
            <Box width={200} mb={4}>
                {cohorts && cohorts.length > 0 ? (
                    <TextField
                        label={t("school.snapshots.cohortFilterLabel")}
                        select
                        SelectProps={{ displayEmpty: true }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        margin="none"
                        name="cohort"
                        value={selectedCohort || 0}
                        onChange={e => setSelectedCohort(parseInt(`${e.target.value}`))}
                    >
                        {filterNotNullable(cohorts).map(obj => (
                            <MenuItem key={obj.id} value={obj.id}>
                                {obj.name}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <></>
                )}
            </Box>
            <Tabs value={currentTab} onChange={handleTabChange}>
                {tabs.map(tab => (
                    <Tab label={tab.label} key={tab.key} value={tab.key} />
                ))}
            </Tabs>
            {isLoading && (
                <Box style={{ textAlign: "center" }}>
                    <CircularProgress />
                </Box>
            )}
            {isSuccess && activeCohort && tabs.length > 0 && customFieldsDefaultConfig ? (
                <FormikRef
                    ref={form}
                    initialValues={{
                        fields: tabs.map(tab => ({
                            fieldType: tab.key,
                            configurations: [
                                {
                                    displayedName: tab.label,
                                    isEnabled: false,
                                    useDefaultValues: true,
                                    fieldValues:
                                        customFieldsDefaultConfig.findIndex(
                                            c => c.fieldType === tab.key,
                                        ) > -1
                                            ? customFieldsDefaultConfig.find(
                                                  c => c.fieldType === tab.key,
                                              )?.values
                                            : [],
                                    allYearGroups: true,
                                    yearGroups: [],
                                },
                            ],
                        })),
                    }}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {formProps => (
                        <Box>
                            {tabs.map(tab => (
                                <Box key={tab.key}>
                                    {tab.key === currentTab ? (
                                        <CustomFieldForm
                                            {...formProps}
                                            tab={tab}
                                            customFieldsDefaultConfig={customFieldsDefaultConfig}
                                            schoolCustomFields={
                                                activeCohort === selectedCohort
                                                    ? schoolCustomFields
                                                    : []
                                            }
                                            canSave={canSave}
                                            fieldType={tab.key as SchoolCustomFieldTypes}
                                            otherYearGroups={formProps.values.fields
                                                .find(f => f.fieldType === currentTab)
                                                ?.configurations.reduce((prev, next) => {
                                                    let newYearGroups = [...prev];
                                                    newYearGroups = newYearGroups.concat(
                                                        next.yearGroups,
                                                    );
                                                    const uniqueYearGroups = [
                                                        ...new Set(newYearGroups),
                                                    ];
                                                    return uniqueYearGroups;
                                                }, [])}
                                        />
                                    ) : null}
                                </Box>
                            ))}
                            {canSave ? (
                                <Button onClick={() => setEditModalOpen(true)} color="primary">
                                    {t("school.customFields.saveAllBtn")}
                                </Button>
                            ) : (
                                <></>
                            )}
                            <PromptDialog
                                open={editModalOpen}
                                yesLabel={
                                    tmpConfirm === "continue"
                                        ? t("school.customFields.saveYes")
                                        : ""
                                }
                                noLabel={t("common.no")}
                                onClose={confirm => {
                                    if (confirm) {
                                        formProps.submitForm();
                                    }
                                    setTmpConfirm("");
                                    setEditModalOpen(false);
                                }}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t("school.customFields.saveWarning"),
                                    }}
                                ></div>
                                <TextField
                                    value={tmpConfirm}
                                    onChange={e => {
                                        setTmpConfirm(e.target.value);
                                    }}
                                />
                            </PromptDialog>
                        </Box>
                    )}
                </FormikRef>
            ) : (
                <></>
            )}
        </AppContainer>
    );
};

export default CustomFieldsConfigContainer;
