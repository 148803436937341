import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { StudentModel } from "src/modules/student/model/StudentModel";
import { getSchoolAccountId } from "src/services/url";

export const STUDENT_DETAILS_QUERY = "studentDetailsQuery";

export const useStudentDetails = (id: number) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [STUDENT_DETAILS_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<StudentModel | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/student/${id}/`,
                    {},
                );

                return data || null;
            }
            return null;
        },
    });
};
