import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { ListObject } from "src/forms/types";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";

export interface RegisterRequest {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    subjectAreas: ListObject<number>[];
    school: ListObject<number>;
}

const apiRegister = async (values: RegisterRequest): Promise<AxiosResponse> => {
    return await axiosInstance.post(`api/auth/register/`, values);
};

export const useRegister = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: RegisterRequest) => apiRegister(values),
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            } else if (err.response.data?.errors) {
                Object.keys(err.response.data?.errors).forEach(key => {
                    enqueueSnackbar(err.response.data?.errors[key], {
                        ...SnackbarErrorOptions,
                    });
                });
            } else {
                enqueueSnackbar(t("common.unknownError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
