import { create } from "zustand";
import { ClassTrackerUserSettingsObject } from "./useTrackerUserSettings";

export type ClassTrackerUserSettingsStoreObject = ClassTrackerUserSettingsObject & {
    setClassTrackerUserSettings: (newSettings: ClassTrackerUserSettingsObject) => void;
};

export const useTrackerUserSettingsStore = create<ClassTrackerUserSettingsStoreObject>(set => ({
    setClassTrackerUserSettings: (newSettings: ClassTrackerUserSettingsObject) =>
        set(state => ({ ...state, ...newSettings })),
}));
