import Dialog from "src/components/Dialog";
import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useAnalysisTagList } from "src/modules/tagging/hooks/Analysis/useAnalysisTagList";
import { mdiPencil } from "@mdi/js";
import Icon from "src/components/Icon";
import { NavLink } from "react-router-dom";
import { ROUTE_MY_SCHOOL_TAGS } from "src/routes";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { SchoolUserRole } from "src/orm/models/User";
import { useProfile } from "src/modules/user/hooks/useProfile";

interface OwnProps {
    opened: boolean;
    selectedTags: number[];
    cohort?: number;
    yearGroup?: number;
    handleTagModalClose: (confirmed: boolean, selectedTags?: number[]) => void;
    handleUpsertTag: (tagId: number) => void;
}

const AnalysisTagsModal: FC<OwnProps> = ({
    opened,
    selectedTags,
    cohort,
    yearGroup,
    handleTagModalClose,
    handleUpsertTag,
}) => {
    const { attachSchoolId } = useSchoolNavigate();
    const { t } = useTranslation();
    const [checkedTags, setCheckedTags] = useState<number[]>(selectedTags || []);
    const [filter, setFilter] = useState<string>("");

    const { data: tags } = useAnalysisTagList(cohort, yearGroup);

    const { data: userProfile } = useProfile();
    const canEditTags = !!(
        userProfile?.userRole === SchoolUserRole.SCHOOL_ADMIN ||
        userProfile?.userRole === SchoolUserRole.OWNER ||
        userProfile?.isLeaderOfAll ||
        (userProfile?.leaderOfSubjectAreas && userProfile?.leaderOfSubjectAreas.length > 0)
    );

    return (
        <Dialog
            open={opened}
            onClose={() => handleTagModalClose(false)}
            title={t("tracker.grid.tags.header")}
            actions={
                <>
                    <Button onClick={() => handleTagModalClose(true, checkedTags)} color="primary">
                        {t("tracker.grid.tags.saveBtn")}
                    </Button>
                    {canEditTags && (
                        <Button onClick={() => handleUpsertTag(0)} color="inherit">
                            {t("tracker.grid.tags.addTag")}
                        </Button>
                    )}
                    <NavLink
                        to={
                            attachSchoolId(ROUTE_MY_SCHOOL_TAGS) +
                            `?returnUrl=` +
                            encodeURIComponent(location.pathname + location.search)
                        }
                        style={{ marginLeft: 8 }}
                    >
                        {t("tracker.grid.tags.goToTagsList")}
                    </NavLink>
                </>
            }
        >
            <Typography sx={{ mb: 3 }}>{t("tracker.grid.tags.hint")}</Typography>
            <Box sx={{ width: "65%", marginBottom: 3 }}>
                <TextField
                    label={t("tracker.grid.tags.filter")}
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("tracker.grid.tags.visible")}</TableCell>
                        <TableCell>{t("tracker.grid.tags.tagName")}</TableCell>
                        <TableCell>{t("tracker.grid.tags.students")}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tags
                        ?.filter(tag => {
                            if (filter === "") return true;

                            return tag.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
                        })
                        .map(tag => (
                            <TableRow
                                key={tag.id}
                                // sx={{ backgroundColor: "rgba(224,239,242,0.3)" }} // TODO: @michal
                            >
                                <TableCell
                                    sx={{ px: 1, py: 0.5, verticalAlign: "middle", width: 80 }}
                                >
                                    <Checkbox
                                        checked={!!checkedTags.find(st => st === tag.id)}
                                        onClick={() => {
                                            if (checkedTags.find(st => st === tag.id)) {
                                                setCheckedTags(
                                                    checkedTags.filter(st => st !== tag.id),
                                                );
                                            } else {
                                                setCheckedTags(checkedTags.concat(tag.id));
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell>{tag.name}</TableCell>
                                <TableCell>{tag.numberOfStudents || 0}</TableCell>
                                <TableCell sx={{ width: 120 }}>
                                    <Button
                                        onClick={() => handleUpsertTag(tag.id)}
                                        variant="text"
                                        startIcon={<Icon path={mdiPencil} />}
                                        disableRipple
                                    >
                                        {t("tracker.grid.tags.editTag")}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Dialog>
    );
};

export default AnalysisTagsModal;
