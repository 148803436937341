import PaperInner from "src/components/PaperInner";
import TrackingHeaderTrackerPath from "../../class/components/Common/TrackingHeaderTrackerPath";
import { Box, Button, CircularProgress } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Icon from "src/components/Icon";
import { mdiArrowLeft, mdiPencil } from "@mdi/js";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

interface OwnHeaderProps {
    subjectArea: any; // TODO: when there is no ORMModel
    qualification: any; // TODO: when there is no ORMModel
    specification: any; // TODO: when there is no ORMModel
    yearGroup: number | null;
    handleQuickEditClick: () => void;
    handleDiscardChangesClick: () => void;
    groupId: number | null;
    editModeGroupId: number | null;
    handleAllUnitsUncheck: () => void;
    returnUrlDisabled?: boolean;
}

const AssessmentSaHeader: FC<OwnHeaderProps> = ({
    groupId,
    subjectArea,
    specification,
    qualification,
    yearGroup,
    handleQuickEditClick,
    handleDiscardChangesClick,
    editModeGroupId,
    handleAllUnitsUncheck,
    returnUrlDisabled,
}) => {
    const { t } = useTranslation();
    const searchParams = new URLSearchParams(location.search);
    const returnUrl = decodeURIComponent(searchParams.get("returnUrl") || "");
    const { navigate } = useSchoolNavigate();

    return (
        <PaperInner variant="subjectArea" color="lightGrey" border="bottom">
            <Box display="flex" className="assessmentHeader">
                <Box display="flex" justifyContent="space-between" flex={1}>
                    <TrackingHeaderTrackerPath
                        subjectArea={subjectArea}
                        specification={specification}
                        qualification={qualification}
                        yearGroup={yearGroup}
                    />

                    <Box display="flex" alignItems="center" height={24}>
                        {returnUrl && !editModeGroupId ? (
                            <Button
                                onClick={() => {
                                    navigate(returnUrl);
                                }}
                                variant="text"
                                disableRipple
                                disabled={returnUrlDisabled}
                                startIcon={<Icon path={mdiArrowLeft} />}
                            >
                                {t("class.assessment.backToTracker")}
                                {returnUrlDisabled && (
                                    <Box ml={2}>
                                        <CircularProgress size={18} />
                                    </Box>
                                )}
                            </Button>
                        ) : (
                            <></>
                        )}
                        {editModeGroupId && groupId === editModeGroupId ? (
                            <Button
                                onClick={() => {
                                    handleAllUnitsUncheck();
                                }}
                                variant="text"
                                disableRipple
                            >
                                {t("class.assessment.uncheckAllAssessments")}
                            </Button>
                        ) : (
                            <></>
                        )}

                        {editModeGroupId && groupId === editModeGroupId ? (
                            <Button
                                onClick={() => {
                                    handleDiscardChangesClick();
                                }}
                                variant="text"
                                disableRipple
                            >
                                {t("class.assessment.discardChanges")}
                            </Button>
                        ) : (
                            <></>
                        )}

                        <Button
                            onClick={() => {
                                handleQuickEditClick();
                            }}
                            variant="text"
                            startIcon={!editModeGroupId ? <Icon path={mdiPencil} /> : undefined}
                            disableRipple
                        >
                            {!editModeGroupId
                                ? t("class.assessment.quickEdit")
                                : groupId === editModeGroupId
                                  ? t("class.assessment.saveChanges")
                                  : ""}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </PaperInner>
    );
};

export default AssessmentSaHeader;
