import PinnedTopRenderer from "./PinnedTopRenderer";
import { ColDef } from "ag-grid-community";
import {
    getCellColour,
    ColourTypes,
    getContrastText,
    getCurrentGradeColour,
    hexToRGB,
} from "src/modules/tracker/services/colorHandler";
import { ColumnNode, ColumnTypeGradeTypes } from "src/modules/tracker/dto/TrackerHeader";
import { FC, useEffect, useState } from "react";

interface OwnProps {
    value: boolean | string;
    cellConfig: ColumnNode | null;
    colDef: ColDef;
    data: any;
    context: any;
    parentUnitColour: string | undefined;
    isDisplayField?: boolean;
    isBtecCourseGrade?: boolean;
    node: any;
}

export const CurrentGradeRenderer: FC<OwnProps> = ({
    context,
    isBtecCourseGrade,
    parentUnitColour,
    cellConfig,
    value,
    isDisplayField,
    colDef,
    data,
    node,
}) => {
    const { field, headerComponentParams } = colDef;

    if (node.rowPinned) {
        return (
            <PinnedTopRenderer
                field={field}
                value={field && data ? data[field] : ""}
                context={context}
            />
        );
    }

    const { relatedData } = context;
    const { columnType, colourConfig, columnConfig } = cellConfig;
    const { whileUpdate, errors, metaColumnsConfig } = data;

    const fieldError = errors?.errors.find(e => e.row === data.row && e.column === field);
    const rowWhileUpdate = whileUpdate && data.id ? whileUpdate[data.id] : null;

    const [cellColour, setCellColour] = useState<{ colour: string; opacity: number } | null>(null);

    useEffect(() => {
        if (headerComponentParams) {
            if (headerComponentParams.isCurrentGrade && field && metaColumnsConfig) {
                const metaConfig = metaColumnsConfig[field] || undefined;

                if (colourConfig?.type === ColourTypes.MTG) {
                    const gradeColour = getCurrentGradeColour(
                        metaConfig,
                        cellConfig,
                        relatedData,
                        parentUnitColour,
                    );
                    setCellColour(gradeColour);
                } else {
                    setCellColour(
                        getCellColour(
                            value,
                            cellConfig,
                            parentUnitColour,
                            relatedData,
                            isDisplayField,
                        ),
                    );
                }
            }
        }
    }, [headerComponentParams, value, metaColumnsConfig]);

    // getting the grade value
    const getGradeValue = (value, gradeType) => {
        if (value < 0) return "U";
        let gradeValue = relatedData?.grades.find(g => g.id === value);

        if (
            gradeType &&
            gradeType === ColumnTypeGradeTypes.COURSE &&
            relatedData?.courseGrades &&
            relatedData?.courseGrades.length > 0
        ) {
            gradeValue = relatedData?.courseGrades.find(g => g.id === value);
        }

        if (isBtecCourseGrade && relatedData?.courseGrades) {
            gradeValue = relatedData?.courseGrades.find(g => g.id === value);
        }

        return gradeValue?.name || null;
    };

    // getting display value
    const getDisplayValue = () => {
        return value === false
            ? "A"
            : value === "-1"
              ? "U"
              : columnType === "grade"
                ? getGradeValue(value, columnConfig?.typeConfig?.gradeType)
                : value;
    };

    // field colors (background + font)
    const backgroundColor =
        value !== null &&
        value !== undefined &&
        value !== "" &&
        cellColour?.colour &&
        cellColour.colour !== parentUnitColour
            ? hexToRGB(cellColour?.colour, cellColour?.opacity)
            : colourConfig?.type === ColourTypes.GRADE
              ? hexToRGB(cellColour?.colour, cellColour?.opacity)
              : "transparent";

    const fontColor =
        cellColour && cellColour?.colour && cellColour?.opacity === 100
            ? getContrastText(cellColour?.colour)
            : undefined;

    const displayName = getDisplayValue();

    return (
        <div
            title={displayName}
            className={`ag-value-cell-label${fieldError ? " ag-value-cell-label--error" : ""}`}
            style={{ backgroundColor, color: fontColor }}
        >
            {displayName}
            <span
                className={`ag-value-cell-status${
                    rowWhileUpdate &&
                    rowWhileUpdate.find(rwu => `${rwu.column}` === `${colDef.field}`)
                        ? " ag-value-cell-status--update"
                        : fieldError
                          ? " ag-value-cell-status--error"
                          : ""
                }`}
            />
        </div>
    );
};
