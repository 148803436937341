import { combineEpics } from "redux-observable";
import { analysisAtlEpics } from "./Atl/epics";
import { analysisCommonEpics } from "./Common/epics";
import { analysisKS4GradeEpics } from "./KS4Grade/epics";

export const analysisEpics = combineEpics(
    analysisCommonEpics,
    analysisKS4GradeEpics,
    analysisAtlEpics,
);
