import axiosInstance from "src/config/axios";
import { useQueries } from "@tanstack/react-query";
import {
    PUBLISHED_ASSESSMENT_LIVE_TRACKING_DATA_QUERY,
    PublishedAssessmentLiveTrackingData,
} from "./Published/useLiveTrackingData";
import { CUSTOMISED_ASSESSMENT_DETAILS_QUERY } from "./Customised/useDetails";
import { CustomisedAssessmentModel } from "../models/CustomisedAssessmentModel";
import { getSchoolAccountId } from "src/services/url";

export const PUBLISHED_ASSESSMENT_DETAILS_QUERY = "publishedAssessmentDetailsQuery";

export const useMultipleAssessmentsData = (assessments: { id: number }[], type: "pp" | "eott") => {
    const schoolAccountId = getSchoolAccountId();

    return useQueries({
        queries: assessments.map(assessment => {
            return type === "pp"
                ? {
                      queryKey: [
                          PUBLISHED_ASSESSMENT_LIVE_TRACKING_DATA_QUERY,
                          schoolAccountId,
                          assessment.id,
                      ],
                      queryFn: async (): Promise<PublishedAssessmentLiveTrackingData[] | null> => {
                          if (assessment.id) {
                              const { data } = await axiosInstance.get(
                                  `school/${getSchoolAccountId()}/assessments/published/${assessment.id}/live-tracking-data/`,
                              );

                              return data;
                          }
                          return null;
                      },
                  }
                : {
                      queryKey: [
                          CUSTOMISED_ASSESSMENT_DETAILS_QUERY,
                          schoolAccountId,
                          assessment.id,
                      ],
                      queryFn: async (): Promise<CustomisedAssessmentModel | null> => {
                          if (assessment.id) {
                              const { data } = await axiosInstance.get(
                                  `school/${getSchoolAccountId()}/assessments/customised/${assessment.id}/`,
                              );

                              return data;
                          }
                          return null;
                      },
                  };
        }),
    });
};
