import { Box, Button, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import ErrorMessage from "src/forms/ErrorMessage";
import { FormikProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleClassForm } from "../../containers/AddClass/ManualExistingStudentsContainer";
import ChooseClassModal from "./ChooseClassModal";
import ChooseStudentsModal from "./ChooseStudentsModal";
// import ImportStudentsModal from "./ImportStudentsModal";
import { mdiFaceMan, mdiAccountBoxMultiple, mdiPencil, mdiDelete } from "@mdi/js";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";

const ImportBox = withStyles(({ shape, spacing, typography }) => ({
    root: {
        counterIncrement: "boxCounter",
        backgroundColor: COLORS.VERY_LIGHT_GREY_1,
        borderRadius: shape.borderRadius,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: 140,
        padding: `${spacing(2.5)}px ${spacing(3)}px ${spacing(3.5)}px ${spacing(3)}px`,
        position: "relative",
        overflow: "hidden",
        "& > button": {
            marginLeft: spacing(2),
        },
        "&:before": {
            content: "counter(boxCounter)",
            color: COLORS.LIGHT_GREY_1,
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: typography.pxToRem(160),
            // fontWeight: typography.fontWeightMedium,
            lineHeight: 0.68,
            opacity: 0.12,
            position: "absolute",
            right: spacing(4),
            bottom: 0,
        },
    },
}))(Box);

const ChooseStudents = (formProps: FormikProps<SingleClassForm>) => {
    const { t } = useTranslation();

    const [chooseClassOpen, setChooseClassOpen] = useState(false);
    const handleChooseClassOpen = () => setChooseClassOpen(true);
    const handleChooseClassClose = () => setChooseClassOpen(false);

    // const [importStudentsOpen, setImportStudentsOpen] = useState(false);
    // const handleImportStudentsOpen = () => setImportStudentsOpen(true);
    // const handleImportStudentsClose = () => setImportStudentsOpen(false);

    const [chooseStudentsOpen, setChooseStudentsOpen] = useState(false);
    const handleChooseStudentsOpen = () => setChooseStudentsOpen(true);
    const handleChooseStudentsClose = () => setChooseStudentsOpen(false);

    const handleResetStudentList = () => {
        formProps.setFieldValue("students", []);
        formProps.setFieldValue("chooseClass", 0);
    };

    return (
        <>
            {formProps.values.students.length === 0 ? (
                <>
                    <Typography component="p" gutterBottom>
                        {t("class.addClass.chooseStudentsMessage")}
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item sm={4}>
                            <ChooseClassModal
                                {...formProps}
                                open={chooseClassOpen}
                                handleClose={handleChooseClassClose}
                            />
                            <ImportBox>
                                <Typography component="h3" variant="h5">
                                    {t("class.addClass.importFromClass")}
                                </Typography>
                                <Button
                                    variant="text"
                                    startIcon={<Icon path={mdiFaceMan} />}
                                    disableRipple
                                    onClick={handleChooseClassOpen}
                                >
                                    {t("class.addClass.chooseClass")}
                                </Button>
                            </ImportBox>
                        </Grid>
                        <Grid item sm={4}>
                            <ChooseStudentsModal
                                {...formProps}
                                open={chooseStudentsOpen}
                                handleClose={handleChooseStudentsClose}
                            />
                            <ImportBox>
                                <Typography component="h3" variant="h5">
                                    {t("class.addClass.chooseStudentsRegister")}
                                </Typography>
                                <Button
                                    variant="text"
                                    startIcon={<Icon path={mdiAccountBoxMultiple} />}
                                    disableRipple
                                    onClick={handleChooseStudentsOpen}
                                >
                                    {t("class.addClass.chooseStudents")}
                                </Button>
                            </ImportBox>
                        </Grid>
                        {/* <Grid item sm={4}>
                            <ImportStudentsModal
                                {...formProps}
                                open={importStudentsOpen}
                                handleClose={handleImportStudentsClose}
                            />
                            <ImportBox>
                                <Typography component="h3" variant="h5">
                                    {t("class.addClass.uploadExcelStudents")}
                                </Typography>
                                <Button
                                    variant="text"
                                    startIcon={<Icon path={mdiPublish} />}
                                    disableRipple
                                    onClick={handleImportStudentsOpen}
                                >
                                    {t("class.addClass.chooseThisOption")}
                                </Button>
                            </ImportBox>
                        </Grid> */}
                    </Grid>
                </>
            ) : (
                <Box
                    display="inline-flex"
                    px={4}
                    py={3.5}
                    borderRadius={2}
                    bgcolor={COLORS.VERY_LIGHT_GREY_1}
                >
                    <ChooseStudentsModal
                        {...formProps}
                        open={chooseStudentsOpen}
                        handleClose={handleChooseStudentsClose}
                    />
                    <Box mr={4.5}>
                        <Typography variant="h4">{`${formProps.values.students.length} ${t(
                            "class.addClass.studentsSelected",
                        )}`}</Typography>
                    </Box>
                    <Button
                        variant="text"
                        startIcon={<Icon path={mdiPencil} />}
                        disableRipple
                        onClick={handleChooseStudentsOpen}
                    >
                        {t("class.addClass.changeSelection")}
                    </Button>
                    <Button
                        variant="text"
                        startIcon={<Icon path={mdiDelete} />}
                        disableRipple
                        onClick={handleResetStudentList}
                    >
                        {t("class.addClass.clearSelection")}
                    </Button>
                </Box>
            )}
            {formProps.errors.students ? <ErrorMessage name={`students`} /> : null}
        </>
    );
};

export default ChooseStudents;
