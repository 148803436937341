import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const GET_SEARCH_STUDENTS_QUERY = "getSearchStudentsQuery";

export interface SearchStudentsResponse {
    id: number;
    title: string;
    firstName: string;
    lastName: string;
    uniquePupilNumber: string;
}

export const useGetSearchStudents = (filter: string) => {
    return useQuery({
        queryKey: [GET_SEARCH_STUDENTS_QUERY],
        queryFn: async (): Promise<SearchStudentsResponse[] | null> => {
            if (filter) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/search/students/?filter=${filter}`,
                );
                return data || null;
            }
            return null;
        },
    });
};
