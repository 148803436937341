import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TagModel } from "../../model/TagModel";
import { getSchoolAccountId } from "src/services/url";

export const ANALYSIS_TAG_LIST_QUERY = "analysisTagListQuery";

export const useAnalysisTagList = (cohort: number, yearGroup: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [ANALYSIS_TAG_LIST_QUERY, schoolAccountId, cohort, yearGroup],
        queryFn: async (): Promise<TagModel[] | null> => {
            if (cohort) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/analysis/tags/${cohort}/${yearGroup}/`,
                );

                return data || null;
            }
            return null;
        },
    });
};
