import useDebounce from "src/hooks/useDebounce";
import { usePrevious } from "src/hooks/usePrevious";
import { memo, useCallback, useEffect } from "react";

const FormikObserver = ({
    onChange,
    values,
    debounce,
    formProps,
}: {
    onChange: (debounced: typeof values, formProps?) => void;
    values: any;
    debounce?: boolean;
    formProps?: any;
}) => {
    const debounced: typeof values = useDebounce(values, debounce ? 200 : 0);
    const previous: typeof debounced = usePrevious(debounced);

    const isChanged = useCallback(
        (): boolean =>
            Object.keys(debounced).reduce((prev, curr) => {
                if (
                    previous !== undefined &&
                    debounced[curr] !== previous[curr] &&
                    prev === false
                ) {
                    return true;
                }
                return prev;
            }, false),
        [debounced, previous],
    );

    useEffect(() => {
        if (isChanged()) onChange(debounced, formProps ? formProps : undefined);
    }, [debounced, formProps, isChanged, onChange, previous]);

    return null;
};

export default memo(FormikObserver);
