import AppContainer from "src/components/AppContainer";
import TablePage, { TablePageFilterType } from "src/components/TablePage";
import useDebounce from "src/hooks/useDebounce";
import ActionsTableButton from "src/components/ActionTableButton";
import InviteTrustAdminModal from "../components/InviteTrustAdminModal";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTrustUsers } from "../hooks/useTrustUsers";
import { useEffect, useMemo, useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { useTrustSchools } from "../hooks/useTrustSchools";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { TrustUserModel } from "../models/TrustUserModel";
import { ROUTE_TRUST_USER_DETAILS } from "src/routes";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getTrustAccountId } from "src/services/url";

const TrustUserListContainer = () => {
    const { t } = useTranslation();
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const { data: schoolsSearch } = useTrustSchools(parseInt(getTrustAccountId()));
    const { attachTrustId } = useSchoolNavigate();
    const [inviteModalOpened, setInviteModalOpened] = useState<boolean>(false);

    // filters and pagination
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 50,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    // columns definition
    const [columns, setColumns] = useState([]);

    // filter types definition
    const [filters, setFilters] = useState<TablePageFilterType[]>([]);
    const debouncedFilters = useDebounce(filters, 200);

    const {
        data: trustUsers,
        isLoading,
        refetch,
    } = useTrustUsers(
        parseInt(getTrustAccountId()),
        { page: pagination.pageIndex + 1, perPage: pagination.pageSize },
        debouncedFilters.reduce((filters, df) => {
            if (df.value) {
                filters[df.id] = searchParams[df.id] || df.value;
            }

            return filters;
        }, {}),
    );

    useEffect(() => {
        if (columns.length === 0 && trustUsers) {
            setColumns([
                {
                    id: "title",
                    header: t("trust.users.title"),
                    cell: ({ row }) => {
                        return <Box>{row.original.title}</Box>;
                    },
                },
                {
                    id: "firstName",
                    header: t("trust.users.firstName"),
                    accessorKey: "firstName",
                },
                {
                    id: "lastName",
                    header: t("trust.users.lastName"),
                    accessorKey: "lastName",
                },
                {
                    id: "email",
                    header: t("trust.users.primaryEmail"),
                    accessorKey: "email",
                },
                {
                    id: "schools",
                    header: t("trust.users.schools"),
                    cell: ({ row }) => {
                        return (
                            <Box>
                                {row.original.schools.map(school => {
                                    return (
                                        <Box minWidth={200} key={school.schoolAccountId}>
                                            {school.name}{" "}
                                            <Box component="em" sx={{ color: "text.secondary" }}>
                                                ({t(`common.roles.${school.userRole}`)})
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        );
                    },
                },
                {
                    // actions definition (always use id "actions")
                    id: "actions",
                    cell: ({ row }) => (
                        <Box textAlign={"right"}>
                            <ActionsTableButton
                                actions={[
                                    getMenuActionProps("show", {
                                        text: t("common.details"),
                                        linkCallback: (rowData: TrustUserModel) =>
                                            attachTrustId(
                                                ROUTE_TRUST_USER_DETAILS.replace(
                                                    ":id",
                                                    `${rowData.id}`,
                                                ),
                                            ),
                                    }),
                                ]}
                                rowData={row.original}
                                forceDotMenu={true}
                            />
                        </Box>
                    ),
                },
            ]);
        }
    }, [trustUsers]);

    useEffect(() => {
        setFilters([
            {
                id: "name",
                type: "text",
                label: t("trust.users.firstLastName"),
                value: searchParams["name"] || "",
            },
            {
                id: "schoolAccountId",
                type: "select",
                label: t("trust.users.school"),
                value: searchParams["schoolAccountId"] || "",
                options: schoolsSearch?.map(school => ({
                    label: school.name,
                    value: `${school.schoolAccountId}`,
                })),
            },
        ]);
    }, [schoolsSearch]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("trust.users.header")}
            </Typography>
            <TablePage
                headerNavButtons={
                    <>
                        <Button
                            variant="contained"
                            disableRipple
                            onClick={() => setInviteModalOpened(true)}
                            id="inviteTrustAdmin"
                        >
                            {t("trust.users.inviteAdminBtn")}
                        </Button>
                    </>
                }
                isLoading={isLoading}
                data={trustUsers?.data}
                count={trustUsers?.count}
                columns={columns}
                filters={filters}
                handleFiltersChange={setFilters}
                pagination={pagination}
                handlePaginationChange={setPagination}
            />
            <InviteTrustAdminModal
                opened={inviteModalOpened}
                handleClose={() => {
                    setInviteModalOpened(false);
                    refetch();
                }}
            />
        </AppContainer>
    );
};

export default TrustUserListContainer;
