import { Button, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NewPasswordForm from "../components/NewPassword/NewPasswordForm";
import { newPasswordSchema } from "../components/NewPassword/newPasswordSchema";
import AuthContainer from "src/components/AuthContainer";
import { useParams } from "react-router";
import { useNewPassword } from "../hooks/useNewPassword";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "src/components/hookFormComponents";
import { ROUTE_AUTH_LOGIN } from "src/routes";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export interface NewPasswordformValues {
    password: string;
    repeatPassword: string;
}

const NewPasswordContainer = () => {
    const { t } = useTranslation();
    const methods = useForm({
        resolver: yupResolver(newPasswordSchema(t)),
        mode: "all",
        defaultValues: {
            password: "",
            repeatPassword: "",
        },
    });
    const {
        formState: { isSubmitting },
    } = methods;

    const params = useParams();
    const { navigate } = useSchoolNavigate();
    const { mutate: newPassword } = useNewPassword(() => navigate(ROUTE_AUTH_LOGIN));

    const handleSubmit = (values: NewPasswordformValues) => {
        if (params.hash) {
            newPassword({ hash: params.hash, password: values.password });
        }
    };

    return (
        <AuthContainer>
            <Box mb={2.5}>
                <Typography>{t("auth.resetPassword.newPasswordHeader")}</Typography>
            </Box>
            <FormProvider methods={methods} onSubmit={handleSubmit}>
                <NewPasswordForm />
                <Box
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent="space-between"
                    width={1}
                    mt={5}
                >
                    <Button color="primary" type="submit" disabled={isSubmitting}>
                        {t("auth.resetPassword.button")}
                    </Button>
                </Box>
            </FormProvider>
        </AuthContainer>
    );
};

export default NewPasswordContainer;
