import { CellKeyDownEvent, ColumnApi, GridApi } from "ag-grid-community";

export const KEY_LEFT = "ArrowLeft";
export const KEY_UP = "ArrowUp";
export const KEY_RIGHT = "ArrowRight";
export const KEY_DOWN = "ArrowDown";

export const NAV_KEYS = [KEY_LEFT, KEY_DOWN, KEY_RIGHT, KEY_UP];

// custom navigation to next cell
export const navigateToNextCell = (
    event: CellKeyDownEvent,
    gridApi?: GridApi,
    columnApi?: ColumnApi,
    bulkEdit?: boolean,
) => {
    const e = event.event as KeyboardEvent;

    if (e && gridApi && columnApi && NAV_KEYS.includes(e.code)) {
        const rowIndex = event.rowIndex;
        const colDefField = event.colDef.field;

        const columns = columnApi?.getAllDisplayedColumns().filter(c => {
            const singleColumn = c.getColDef();
            if (singleColumn && singleColumn.headerComponentParams) {
                return !singleColumn.headerComponentParams?.isDisplayField && singleColumn.editable;
            }
            return false;
        });

        const currentColIndex = columns.findIndex(c => c.getColId() === colDefField);

        const currentRowIndex = rowIndex;
        let nextRowIndex = currentRowIndex;
        let nextColIndex = currentColIndex;

        switch (e.code) {
            case "ArrowUp":
                if (currentRowIndex !== null && currentRowIndex > 0)
                    nextRowIndex = currentRowIndex - 1;
                break;
            case "ArrowDown":
                if (
                    currentRowIndex !== null &&
                    currentRowIndex < gridApi.getDisplayedRowCount() - 1
                )
                    nextRowIndex = currentRowIndex + 1;
                break;
            case "ArrowLeft":
                if (currentColIndex >= 0) nextColIndex = currentColIndex - 1;
                break;
            case "ArrowRight":
                if (currentColIndex < columns.length) nextColIndex = currentColIndex + 1;
                break;
        }

        if (!bulkEdit && columns[nextColIndex]) {
            // return gridApi.setFocusedCell(nextRowIndex || 0, columns[nextColIndex].getColId());
            return gridApi.startEditingCell({
                rowIndex: nextRowIndex as number,
                colKey: columns[nextColIndex].getColId(),
            });
        } else {
            gridApi.clearRangeSelection();
            if (!columns[nextColIndex] && !columns[currentColIndex]) {
                return gridApi.clearFocusedCell();
            }

            if (!columns[nextColIndex]) {
                return gridApi.setFocusedCell(
                    nextRowIndex || 0,
                    columns[currentColIndex].getColId(),
                );
            }

            return gridApi.setFocusedCell(nextRowIndex || 0, columns[nextColIndex].getColId());
        }
    }

    return null;
};
