/* eslint-disable prefer-const */
import { NotNullableInterface } from "src/types";

export const fromEntries = <T>(entries: [string, T][]): { [key: string]: T } => {
    return entries.reduce((obj, { 0: key, 1: val }) => Object.assign(obj, { [key]: val }), {});
};

export const pick = <T extends object, U extends keyof T>(subject: T, props: U[]): Pick<T, U> => {
    return props.reduce<T>((obj, key) => Object.assign(obj, { [key]: subject[key] }), {} as any);
};

export const filterNotNullable = <T extends { id: any }>(list: T[]): NotNullableInterface<T>[] => {
    return list && list.length > 0 ? (list.filter(obj => obj.id !== null) as any) : [];
};

export const sortByKey =
    (key: string) =>
    (a: object, b: object): number =>
        a[key] - b[key];

export const trimObjectStringFields = (body: Record<string, any>): Record<string, any> => {
    Object.keys(body).forEach(key => {
        if (body[key] !== null && typeof body[key] == "object") {
            trimObjectStringFields(body[key]);
        } else if (body[key] !== null && typeof body[key] == "string") {
            body[key] = `${body[key]}`.trim();
        }
    });

    return body;
};

export const omit = (obj: any, names: string[]): any => {
    const result = {};
    const index = {};
    let idx = 0;

    while (idx < names.length) {
        index[names[idx]] = 1;
        idx += 1;
    }

    for (const prop in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (!index.hasOwnProperty(prop)) result[prop] = obj[prop];
    }

    return result;
};

export const filterData = (
    search: string,
    list: any[],
    fields: string[],
    withParent = false,
    accurateComapre = false,
): any[] => {
    if (!search) {
        return list;
    }
    const results = [] as any;
    const tmpResult = list.filter(obj =>
        fields.reduce(
            (prev, current) =>
                prev ||
                (obj[current] &&
                    (obj[current].toString() === search ||
                        (!accurateComapre &&
                            (obj[current].toString().indexOf(search) !== -1 ||
                                obj[current].toString().toLowerCase().indexOf(search) !== -1)))),

            false,
        ),
    );
    tmpResult.forEach(e => {
        if (e.lvl > 0 && withParent) {
            const parent = list.filter(sa => sa.lft < e.lft && sa.rgt > e.rgt && sa.lvl < e.lvl);
            parent.forEach(p => {
                const parentPresent = results.some((r: { id: number }) => r.id === p.id);
                if (!parentPresent) results.push(p);
            });
        }
        results.push(e);
    });

    return results;
};

export const getRandomId = length => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const findValueInObjectByPath = (obj, path, def?) => {
    const stringToPath = function (path) {
        if (typeof path !== "string") return path;
        const output = [] as any;

        path.split(".").forEach(function (item) {
            item.split(/\[([^}]+)\]/g).forEach(function (key) {
                if (key.length > 0) {
                    output.push(key);
                }
            });
        });

        return output;
    };

    path = stringToPath(path);
    let current = obj;

    for (let i = 0; i < path.length; i++) {
        if (!current[path[i]]) return def;

        current = current[path[i]];
    }

    return current;
};

export function objShallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}

export function removeMetaIfAllUndefined(obj) {
    for (let key in obj) {
        let entry = obj[key];
        if (entry && entry.meta) {
            let meta = entry.meta;
            let columns = Object.keys(meta);
            let allUndefined = columns.every(column => meta[column] === undefined);
            if (allUndefined) {
                delete entry.meta;
            }
        }
    }
    return obj;
}

export const isNumeric = (key: string) => /^[0-9]*\.?[0-9]*$/.test(key);
