import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSnapshotReport } from "src/orm/models/SnapshotReport";
import { getSchoolAccountId } from "src/services/url";

export const getSnapshotReportListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/snapshot/report/{cohort}/`,
    method: ApiRequests.GET,
};

export interface GetSnapshotReportListFilter {
    snapshotName: string;
    state: string;
    reportType: string;
    subjectAreaId: number;
    qualificationId: number;
    yearGroup: number;
    page: number;
    perPage: number;
}

export interface SnapshotReportListResponse {
    data: OrmSnapshotReport[];
    nextPage: number | null;
    count: number;
}
