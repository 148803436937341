import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TrustSchoolModel } from "../models/TrustSchoolModel";

export const TRUST_SCHOOLS_QUERY = "trustSchoolsQuery";

export const useTrustSchools = (trustId: number) => {
    return useQuery({
        queryKey: [TRUST_SCHOOLS_QUERY, trustId],
        queryFn: async (): Promise<TrustSchoolModel[] | null> => {
            if (trustId) {
                const { data } = await axiosInstance.get(`trust/${trustId}/school/`);

                return data || null;
            }
            return null;
        },
    });
};
