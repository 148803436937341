import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TRACKER_GROUP_USER_SETTINGS_QUERY } from "./useTrackerGroupUserSettings";
import { ClassTrackerUserSettingsObject } from "../ClassTracker/useTrackerUserSettings";
import { getSchoolAccountId } from "src/services/url";

const apiEditTrackerGroupUserSettings = async (
    classTrackerGroupId: number,
    values: ClassTrackerUserSettingsObject,
) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/user-settings/`,
        values,
    );
};
export const useEditTrackerGroupUserSettings = (
    classTrackerGroupId: number,
    onSuccess?: () => void,
    onError?: (err: any) => void,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: ClassTrackerUserSettingsObject) =>
            apiEditTrackerGroupUserSettings(classTrackerGroupId, values),

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [
                    TRACKER_GROUP_USER_SETTINGS_QUERY,
                    getSchoolAccountId(),
                    classTrackerGroupId,
                ],
            });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
