import AppContainer from "src/components/AppContainer";
import FormikRef from "src/forms/FormikRef";
import FieldChangeHandler from "src/forms/FieldChangeHandler";
import SpecificationSelectField from "src/forms/SpecificationSelectField";
import ErrorMessage from "src/forms/ErrorMessage";
import AttributesConfigList from "../../components/Common/AttributesConfigList";
import PaperInner from "src/components/PaperInner";
import HeadingCounter from "src/components/HeadingCounter";
import Dialog from "src/components/Dialog";
import COLORS from "src/styles/colors";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import Icon from "src/components/Icon";
import PromptDialog from "src/forms/PromptDialog";
import { useState, useCallback, useEffect, createRef } from "react";
import {
    AttributeValueExtended,
    AttributeItem,
    AttributeGroup,
} from "src/orm/models/SpecificationAttribute";
import { FileType } from "src/forms/types";
import { ClassActions } from "../../store/actions";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { FormError, getErrorMessage } from "src/services/error";
import { ApiStatus, ApiData, AjaxErrorCustom } from "src/api/constants";
import { Form, Field, FormikProps } from "formik";
import { TFunction } from "i18next";
import { createMultipleClassSchema } from "../../components/ClassTracking/forms/addClassSchema";
import { usePrevious } from "src/hooks/usePrevious";
import { AppState } from "src/store/reducers";
import { HTTP_NO_CONTENT, HTTP_SUCCESS_ACCEPTED } from "src/config/globals";
import { Typography, Button, MenuItem, Box, Grid, CircularProgress, Paper } from "@mui/material";
import { DragDropFileUpload } from "src/forms/DragDropFileUpload";
import { TextField } from "formik-mui";
import { mdiAutorenew } from "@mdi/js";
import { MultipleClassesRequest } from "../../api/Classes/multipleClasses";
import { ROUTE_CLASS_CLASS_LIST, ROUTE_CLASS_CLASS_LIST_COHORT } from "src/routes";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { ImportStudentsDatabaseError } from "src/modules/student/hooks/useImportMutation";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export interface MultipleClassesForm {
    specification: number | null;
    attributes: AttributeValueExtended[];
    items: AttributeItem[];
    groups: AttributeGroup[];
    disabledItems: number[];
    cohort: number | null;
    file: FileType[];
}

const dispatchActions = (dispatch: Dispatch) => ({
    getClassTrackerGroupList: (cohort: number) => {
        dispatch(ClassActions.getClassTrackerGroupList(cohort));
    },
    createMultipleClasses: (values: MultipleClassesRequest, overwrite?: boolean | null) => {
        dispatch(ClassActions.createMultipleClasses(values, overwrite));
    },
    getMultipleClassesTemplate: (specification: number) => {
        dispatch(ClassActions.getImportMultipleClassesTemplate(specification));
    },
    // clearImportDatabaseError: () => {
    //     dispatch(SchoolActions.clearImportDatabaseError());
    // },
});

export const convertFormDataToRequest = (values: MultipleClassesForm) => {
    return {
        specification: { id: values.specification },
        file: values.file[0],
        cohort: {
            id: values.cohort === 0 ? null : values.cohort,
        },
        attributes: values.attributes
            .filter(
                attr =>
                    (attr.isSelected === true || attr.isMandatory === true) &&
                    attr.attributeItemId &&
                    !values.disabledItems?.includes(attr.attributeItemId),
            )
            .map(attr => ({
                customName: attr.customName || null,
                attributeValue: { id: attr.id },
            })),
    };
};

const AddClassManualXlsUploadContainer = () => {
    const dispatch = useDispatch();
    const form = createRef() as any;
    const { navigate } = useSchoolNavigate();
    const { t }: { t: TFunction } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        createMultipleClasses,
        getMultipleClassesTemplate,
        getClassTrackerGroupList,
        // clearImportDatabaseError,
    } = dispatchActions(dispatch);
    const [importError, setImportError] = useState<AjaxErrorCustom | null>(null);
    const [importDatabaseModalOpen, setImportDatabaseModalOpen] = useState<boolean>(false);
    const [differentNamesOpen, setDifferentNamesOpen] = useState<boolean>(false);
    const [proceedIsLocked, setProceedIsLocked] = useState<boolean>(true);
    const { data: accountInfo } = useAccountInfo();
    const activeCohortId = accountInfo?.activeCohortId || null;

    const handleDifferentNamesClose = () => {
        setDifferentNamesOpen(false);
        if (form?.current) {
            form?.current?.setSubmitting(false);
        }
    };

    const [redirectCohort, setRedirectCohort] = useState<string>("");

    const {
        apiCreated,
        importDatabaseError,
        importDatabaseErrorFile,
    }: {
        apiCreated: ApiData;
        importDatabaseError: ImportStudentsDatabaseError[] | null;
        importDatabaseErrorFile: MultipleClassesRequest | null;
    } = useSelector((state: AppState) => ({
        apiCreated: state.api.class.createMultipleClasses,
        importDatabaseError: state.class.studentImportDatabaseError,
        importDatabaseErrorFile: state.class.studentImportDatabaseErrorFile,
    }));

    const { data } = useCoreValues();
    const cohorts = data?.cohorts;

    useEffect(() => {
        if (importDatabaseError && importDatabaseError.length <= 4) {
            setProceedIsLocked(false);
        }
    }, [importDatabaseError]);

    const handleFormError = (error: FormError, currentForm) => {
        if (error.formError && "items" in error.formError) {
            const { items } = error.formError;
            if (items) {
                Object.keys(items).forEach(id => {
                    const index = currentForm.values.items.findIndex(
                        (i: AttributeItem) => i.id === parseInt(id),
                    );
                    const tmpItems: string[] = [];
                    tmpItems[index] = items[`${id}`];
                    error.formError = { items: tmpItems };
                });
            }
        }
        if (error.formError && "groups" in error.formError) {
            const { groups } = error.formError;
            if (groups) {
                Object.keys(groups).forEach(id => {
                    const index = currentForm.values.groups.findIndex(
                        (g: AttributeGroup) => g.id === parseInt(id),
                    );
                    const tmpGroups: string[] = [];
                    tmpGroups[index] = groups[`${id}`];
                    error.formError = { groups: tmpGroups };
                });
            }
        }
        currentForm.setErrors(error.formError);
    };

    const handleErrorResponse = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.formError) handleFormError(error, currentForm);
                    if (error.formError?.students && Array.isArray(error.formError?.students)) {
                        error.formError?.students.forEach(studentError => {
                            enqueueSnackbar(studentError, {
                                ...SnackbarErrorOptions,
                            });
                        });
                    } else {
                        setImportError(data.error);
                    }
                    break;
                }
            }
        },
        [enqueueSnackbar, form],
    );

    const getDefaultCohort = () => {
        if (cohorts && cohorts.length > 0) {
            return cohorts.find(c => c.id === activeCohortId)?.id || cohorts[0].id;
        }
        return null;
    };

    const initialValues: MultipleClassesForm = {
        specification: null,
        attributes: [],
        items: [],
        groups: [],
        disabledItems: [],
        file: [],
        cohort: getDefaultCohort(),
    };

    const handleSubmit = (values: MultipleClassesForm) => {
        createMultipleClassSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid) {
                    const convertedValues = convertFormDataToRequest(values);
                    setRedirectCohort(`${values.cohort}`);
                    createMultipleClasses(convertedValues as any);
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    const handleSpecificationChange = (
        newValue: number,
        oldValue: number,
        args: { setValues: (values: any) => void },
    ) => {
        if (newValue !== oldValue) {
            const { setValues } = args;
            setValues({ ...initialValues, specification: newValue });
            setProceedIsLocked(true);
        }
    };

    const prevApiStatus = usePrevious(apiCreated.status);

    useEffect(() => {
        if (prevApiStatus === ApiStatus.LOADING && apiCreated.responseStatus === HTTP_NO_CONTENT) {
            if (redirectCohort) {
                navigate(ROUTE_CLASS_CLASS_LIST_COHORT.replace(":cohort", redirectCohort));
            } else {
                navigate(ROUTE_CLASS_CLASS_LIST);
            }
            enqueueSnackbar(t("class.addClass.classAdded"), { variant: "success" });
        } else if (prevApiStatus === ApiStatus.LOADING && apiCreated.status === ApiStatus.ERROR) {
            handleErrorResponse(apiCreated);
        } else if (
            prevApiStatus === ApiStatus.LOADING &&
            apiCreated.responseStatus === HTTP_SUCCESS_ACCEPTED
        ) {
            setImportDatabaseModalOpen(true);
        }
    }, [
        apiCreated,
        enqueueSnackbar,
        getClassTrackerGroupList,
        handleErrorResponse,
        prevApiStatus,
        redirectCohort,
        t,
    ]);

    const handleClose = () => {
        setImportError(null);
    };

    const handleImportCancel = () => {
        setImportDatabaseModalOpen(false);
        // clearImportDatabaseError();
        setDifferentNamesOpen(true);
        setProceedIsLocked(true);
    };

    const handleScrollToBottom = e => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;

        if (bottom) {
            setProceedIsLocked(false);
        }
    };

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.addClass.headerManualXls")}
            </Typography>
            <Paper>
                <Dialog
                    open={importError !== null}
                    onClose={handleClose}
                    title={t("class.addClass.uploadMultipleClasses")}
                    actions={
                        <Button onClick={() => setImportError(null)}>{t("common.tryAgain")}</Button>
                    }
                >
                    <Typography>
                        <Box component="strong">{t("class.addClass.importFailed")}</Box>
                    </Typography>
                    <Typography>{t("class.addClass.importFailedProblem")}</Typography>
                    <Box p={4} mt={4} bgcolor={COLORS.VERY_LIGHT_GREY_4}>
                        <Typography color="error">
                            <Box component="strong">
                                {importError && importError.response && importError.response.error}
                            </Box>
                        </Typography>
                        {(importError?.response?.fileLine ||
                            importError?.response?.invalidValue) && (
                            <Box
                                display="flex"
                                borderTop={`1px solid ${COLORS.VERY_LIGHT_GREY_3}`}
                                mt={2.5}
                                pt={2.5}
                            >
                                {importError.response.fileLine && (
                                    <Box width={100} mr={3}>
                                        <Box color={COLORS.GREY_4} fontSize={11}>
                                            {t("common.row")}:
                                        </Box>
                                        <Box fontWeight="fontWeightMedium" mt={0.25}>
                                            {importError.response.fileLine}
                                        </Box>
                                    </Box>
                                )}
                                {importError.response.invalidValue && (
                                    <Box width={100} mr={3}>
                                        <Box color={COLORS.GREY_4} fontSize={11}>
                                            {t("common.value")}:
                                        </Box>
                                        <Box fontWeight="fontWeightMedium" mt={0.25}>
                                            {importError.response.invalidValue}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                </Dialog>

                {importDatabaseModalOpen && importDatabaseError && importDatabaseErrorFile && (
                    <Dialog
                        open={importDatabaseModalOpen}
                        onClose={handleImportCancel}
                        title={t("class.addClass.uploadMultipleClasses")}
                        contentOverflow="visible"
                    >
                        <Typography>
                            {t("students.importStudents.importDatabaseProblem")}
                        </Typography>
                        {importDatabaseError && (
                            <Box
                                bgcolor={COLORS.VERY_LIGHT_GREY_4}
                                display="flex"
                                mt={3}
                                px={3}
                                py={2}
                            >
                                <Box width={100} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("common.row")}:
                                    </Box>
                                </Box>
                                <Box width={150} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("common.UPN")}:
                                    </Box>
                                </Box>
                                <Box width={200} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("students.importStudents.fullNameInDb")}:
                                    </Box>
                                </Box>
                                <Box width={200} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("students.importStudents.fullNameInFile")}:
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <Box
                            maxHeight={280}
                            overflow="scroll"
                            onScroll={handleScrollToBottom}
                            bgcolor={COLORS.VERY_LIGHT_GREY_4}
                            pb={2}
                            mb={4}
                        >
                            {importDatabaseError &&
                                importDatabaseError.map((ide, i) => {
                                    return (
                                        <Box key={i} display="flex" px={3} py={2}>
                                            <Box width={100} mr={3}>
                                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                                    {ide.line}
                                                </Box>
                                            </Box>
                                            <Box width={150} mr={3}>
                                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                                    {ide.uniquePupilNumber}
                                                </Box>
                                            </Box>
                                            <Box width={200} mr={3}>
                                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                                    {ide.fullNameInDb}
                                                </Box>
                                            </Box>
                                            <Box width={200} mr={3}>
                                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                                    {ide.fullNameInFile}
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            <Box display="flex" px={3} py={2}>
                                <Box width={100} mr={3}>
                                    <Box fontWeight="fontWeightMedium" mt={0.25}></Box>
                                </Box>
                                <Box width={150} mr={3}>
                                    <Box fontWeight="fontWeightMedium" mt={0.25}></Box>
                                </Box>
                                <Box width={200} mr={3}>
                                    <Box fontWeight="fontWeightMedium" mt={0.25}></Box>
                                </Box>
                                <Box width={200} mr={3}>
                                    <Box fontWeight="fontWeightMedium" mt={0.25}></Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box mb={4}>
                            <Typography>{t("students.importStudents.listHint")}</Typography>
                            <Typography>{t("students.importStudents.scrollHint")}</Typography>
                        </Box>

                        <Button
                            onClick={() => {
                                setProceedIsLocked(true);
                                createMultipleClasses(importDatabaseErrorFile, true);
                            }}
                            disabled={proceedIsLocked}
                            startIcon={<Icon path={mdiAutorenew} />}
                            disableRipple
                            color="primary"
                        >
                            {t("students.importStudents.theSameBtn")}
                        </Button>

                        <Button onClick={handleImportCancel}>
                            {t("students.importStudents.studentsHasDifferentNamesBtn")}
                        </Button>
                    </Dialog>
                )}

                <PromptDialog
                    open={differentNamesOpen}
                    onClose={handleDifferentNamesClose}
                    yesLabel={t("common.continue")}
                    noLabel=""
                >
                    <p>{t("students.importStudents.differentNamesHint1")}</p>
                    <p>{t("students.importStudents.differentNamesHint2")}</p>
                    <p>{t("students.importStudents.differentNamesHint3")}</p>
                </PromptDialog>

                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => createMultipleClassSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<MultipleClassesForm>) => (
                        <Form>
                            <PaperInner>
                                <FieldChangeHandler
                                    onChange={handleSpecificationChange}
                                    value={formProps.values.specification}
                                    setValues={formProps.setValues}
                                />
                                <HeadingCounter number="1">
                                    {t("class.addClass.chooseTrackerHeader")}
                                </HeadingCounter>
                                <Typography component="p" gutterBottom>
                                    {t("class.addClass.multipleClassIntroText")}
                                </Typography>
                                <Field
                                    name={`specification`}
                                    liveOnly={true}
                                    component={SpecificationSelectField}
                                    showParentSpecification={true}
                                    allowedOnly={true}
                                />
                                <ErrorMessage name="specification" />
                                {formProps.values.specification && (
                                    <AttributesConfigList
                                        attributes={formProps.values.attributes}
                                        specificationId={formProps.values.specification}
                                        items={formProps.values.items}
                                        groups={formProps.values.groups}
                                        disabledItems={formProps.values.disabledItems}
                                        errors={formProps.errors}
                                        setFieldValue={formProps.setFieldValue}
                                        setFieldTouched={formProps.setFieldTouched}
                                        initialAttributes={[]}
                                    />
                                )}
                                {formProps.errors && formProps.errors["attributes"] && (
                                    <ErrorMessage name="attributes" />
                                )}
                            </PaperInner>
                            {formProps.values.specification && (
                                <>
                                    <PaperInner border="top">
                                        <HeadingCounter number="2">
                                            {t("class.addClass.chooseCohort")}
                                        </HeadingCounter>
                                        <Grid container spacing={4}>
                                            <Grid item sm={4}>
                                                <Field
                                                    name="cohort"
                                                    label={t("class.addClass.cohort")}
                                                    component={TextField}
                                                    select
                                                >
                                                    {cohorts &&
                                                        cohorts.map(cohort => (
                                                            <MenuItem
                                                                disabled={
                                                                    cohort.id !== activeCohortId
                                                                    // && cohort.id !== migrationCohort
                                                                }
                                                                key={cohort.id}
                                                                value={cohort.id}
                                                            >
                                                                {cohort.name}
                                                            </MenuItem>
                                                        ))}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </PaperInner>
                                    <PaperInner border="top">
                                        <HeadingCounter number="3">
                                            {t("class.addClass.uploadFile")}
                                        </HeadingCounter>
                                        <Typography component="p" gutterBottom>
                                            {t("class.addClass.chooseFileMessage")}
                                        </Typography>
                                        <Box display="flex" mt={3}>
                                            <Button
                                                onClick={() => {
                                                    if (formProps.values.specification) {
                                                        getMultipleClassesTemplate(
                                                            formProps.values.specification,
                                                        );
                                                    }
                                                }}
                                                color="primary"
                                            >
                                                {t("class.addClass.downloadMultipleClassesBtn")}
                                            </Button>
                                            <Field
                                                name={`file`}
                                                component={DragDropFileUpload}
                                                showList={false}
                                                label={t("class.addClass.uploadMultipleClasses")}
                                                accept={`.xlsx`}
                                                type="button"
                                                multiple={false}
                                            />
                                        </Box>
                                        <Box mt={4}>
                                            <Button
                                                disabled={formProps.isSubmitting}
                                                onClick={formProps.submitForm}
                                                color="primary"
                                            >
                                                {t("class.addClass.addClassesButton")}{" "}
                                                {formProps.isSubmitting && (
                                                    <Box ml={2}>
                                                        <CircularProgress size={15} />
                                                    </Box>
                                                )}
                                            </Button>

                                            <GenericErrorMessage
                                                errors={formProps.errors}
                                                submitCount={formProps.submitCount}
                                            />
                                        </Box>
                                    </PaperInner>
                                </>
                            )}
                        </Form>
                    )}
                </FormikRef>
            </Paper>
        </AppContainer>
    );
};

export default AddClassManualXlsUploadContainer;
