import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmSpecification } from ".";
import { OrmSpecificationActions, OrmSpecificationActionTypes } from "./actions";

export const specificationReducer: ModelReducer = {
    [OrmSpecificationActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmSpecificationActions.bulkImport>,
        session,
    ) => {
        const { data, qualification } = action.payload;
        const { Specification } = session;

        Specification.all()
            .filter((value: OrmSpecification) => value.qualification === qualification)
            .delete();

        data.forEach(ct => Specification.create(ormToRef(ct, { defaults: { qualification } })));
    },
};
