import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { StudentModelActions, StudentModelActionTypes } from "./actions";

export const studentReducer: ModelReducer = {
    [StudentModelActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof StudentModelActions.bulkImport>,
        session,
    ) => {
        const { Student } = session;
        Student.all().delete();
        if (action.payload && action.payload.length > 0) {
            action.payload.forEach(s => {
                Student.create(ormToRef(s, {}));
            });
        }
    },
    [StudentModelActionTypes.CLEAR]: (
        action: ReturnType<typeof StudentModelActions.clear>,
        session,
    ) => {
        const { Student } = session;
        Student.all().delete();
    },
    [StudentModelActionTypes.UPSERT]: (
        action: ReturnType<typeof StudentModelActions.upsert>,
        session,
    ) => {
        const { Student } = session;
        Student.upsert(
            ormToRef(action.payload, {
                defaults: {},
            }),
        );
    },
};
