import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSubscriptionHistory } from "src/orm/models/SubscriptionHistory";
import { getSchoolAccountId } from "src/services/url";

export const getPaymentDetailsEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscriptions/{subscription}/payment-details/`,
    method: ApiRequests.GET,
};

export type PaymentDetailsResponse = OrmSubscriptionHistory;
