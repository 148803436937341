import StudentMassActionsModal from "../components/StudentMassActionModal";
import ActionsTableButton from "src/components/ActionTableButton";
import AppContainer from "src/components/AppContainer";
import Icon from "src/components/Icon";
import useDebounce from "src/hooks/useDebounce";
import TablePage, { TablePageFilterType } from "src/components/TablePage";
import ImportStudentsModal from "../components/ImportStudentsModal";
import {
    mdiAccountMultipleMinus,
    mdiAccountMultiplePlus,
    mdiCheck,
    mdiClose,
    mdiEye,
    mdiLinkOff,
    mdiPublish,
} from "@mdi/js";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { SchoolUserRole } from "src/orm/models/User";
import {
    ROUTE_MY_SCHOOL_PARENTS,
    ROUTE_MY_SCHOOL_STUDENT_ADD,
    ROUTE_MY_SCHOOL_STUDENT_EDIT,
} from "src/routes";
import { useStudentList } from "../hooks/useStudentList";
import { StudentModel } from "../model/StudentModel";
import { PaginationState, RowSelectionState } from "@tanstack/react-table";
import { useRemoveStudentMutation } from "../hooks/useRemoveMutation";
import { useStudentActivateMutation } from "../hooks/useActivateMutation";
import { useStudentDeactivateMutation } from "../hooks/useDeactivateMutation";
import { useStudentResendInvitationMutation } from "../hooks/useResendInvitationMutation";
import { useStudentInviteByIdMutation } from "../hooks/useInviteByIdMutation";
import { useStudentUninviteMutation } from "../hooks/useUninviteMutation";
import { useSearchTag } from "src/modules/tagging/hooks/useSearchTag";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const StudentListContainer = () => {
    const { t } = useTranslation();
    const { data: accountInfo } = useAccountInfo();
    const location = useLocation();
    const { data: authUser } = useProfile();
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const { attachSchoolId } = useSchoolNavigate();
    const hasSync = !!accountInfo?.hasGroupCallSynchronization;
    const hasPupilApp = !!accountInfo?.hasStudentPortalEnabled;

    const [allStudentsCount, setAllStudentsCount] = useState<number>(0);

    // delete student dialog
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);

    // import dialog
    const [importStudentsDialogOpened, setImportStudentsDialogOpened] = useState<boolean>(false);

    // mass action modal
    const [massActionModalOpened, setMassActionModalOpened] = useState<boolean>(false);

    // row selection (checkboxes)
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    // filters and pagination
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 50,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    // columns definition
    const [columns, setColumns] = useState([]);

    // filter types definition
    const [filters, setFilters] = useState<TablePageFilterType[]>([]);
    const debouncedFilters = useDebounce(filters, 200);

    // student list
    const {
        data: students,
        isLoading,
        refetch: getStudentList,
    } = useStudentList(
        { page: pagination.pageIndex + 1, perPage: pagination.pageSize },
        debouncedFilters.reduce((filters, df) => {
            if (df.value) {
                filters[df.id] = searchParams[df.id] || df.value;
            }

            return filters;
        }, {}),
    );

    const defaultCohortId = accountInfo?.activeCohortId;
    const [tagSearch, setTagSearch] = useState<string>("");
    const debouncedTagSearch = useDebounce(tagSearch, 200);
    const { data: tagOptions } = useSearchTag(defaultCohortId, debouncedTagSearch);

    //student actions
    const { mutate: removeStudent } = useRemoveStudentMutation(() => getStudentList());
    const { mutate: activateStudent } = useStudentActivateMutation(() => getStudentList());
    const { mutate: deactivateStudent } = useStudentDeactivateMutation(() => getStudentList());
    const { mutate: resendInvitation } = useStudentResendInvitationMutation();
    const { mutate: inviteStudentById } = useStudentInviteByIdMutation(() => getStudentList());
    const { mutate: uninviteStudent } = useStudentUninviteMutation(() => getStudentList());

    const tableActions = [
        getMenuActionProps("edit", {
            linkCallback: (rowData: StudentModel) =>
                attachSchoolId(ROUTE_MY_SCHOOL_STUDENT_EDIT).replace(":student", `${rowData.id}`),
        }),
        getMenuActionProps("activate", {
            text: t("students.list.activate"),
            icon: <Icon path={mdiAccountMultiplePlus} />,
            onAction: (su: StudentModel) => (su.id ? activateStudent({ id: su.id }) : null),
            showAction: (su: StudentModel) => su.status === "deactivated",
        }),
        getMenuActionProps("resendInvitation", {
            text: t("students.list.resendInvitation"),
            icon: <Icon path={mdiAccountMultiplePlus} />,
            onAction: (rowData: StudentModel) =>
                rowData.id ? resendInvitation({ id: rowData.id }) : null,
            showAction: (rowData: StudentModel) =>
                !!(hasPupilApp && rowData.status === "invited" && rowData.email),
        }),
        getMenuActionProps("invite", {
            text: t("students.list.invite"),
            icon: <Icon path={mdiAccountMultiplePlus} />,
            onAction: (rowData: StudentModel) =>
                rowData.id ? inviteStudentById({ id: rowData.id }) : null,
            showAction: (rowData: StudentModel) =>
                !!(hasPupilApp && rowData.status === "added" && rowData.email),
        }),
        getMenuActionProps("deactivate", {
            text: t("students.list.deactivate"),
            icon: <Icon path={mdiAccountMultipleMinus} />,
            onAction: (rowData: StudentModel) =>
                rowData.id ? deactivateStudent({ id: rowData.id }) : null,
            showAction: (rowData: StudentModel) => hasPupilApp && rowData.status === "confirmed",
        }),
        getMenuActionProps("uninvite", {
            text: t("students.list.uninvite"),
            icon: <Icon path={mdiAccountMultipleMinus} />,
            onAction: (rowData: StudentModel) =>
                rowData.id ? uninviteStudent({ id: rowData.id }) : null,
            showAction: (rowData: StudentModel) =>
                !!(hasPupilApp && rowData.status === "invited" && rowData.email),
        }),
        getMenuActionProps("delete", {
            onAction: (su: StudentModel) => (su.id ? handleDeleteDialogOpen(su.id) : null),
            showAction: (su: StudentModel) =>
                !(su.groupCallStatus === "active") || !accountInfo?.hasGroupCallSynchronization,
        }),
        getMenuActionProps("show", {
            icon: <Icon path={mdiEye} />,
            text: t("students.list.showParents"),
            linkCallback: (rowData: StudentModel) =>
                attachSchoolId(ROUTE_MY_SCHOOL_PARENTS) +
                "?page=1&perPage=50&studentUpn=" +
                rowData.uniquePupilNumber,
            showAction: (su: StudentModel) => !!(su.parents && su.parents.length > 0),
        }),
    ];

    const handleDeleteDialogOpen = (id: number): void => {
        setRemoveDialogOpened(true);
        setRemoveId(id);
    };

    const handleDeleteDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            removeStudent({ id: removeId });
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    useEffect(() => {
        if (students?.count && !filters.some(({ value }) => value) && allStudentsCount === 0) {
            setAllStudentsCount(students.count);
        }
    }, [students?.count, filters]);
    useEffect(() => {
        if (columns.length === 0 && accountInfo && students) {
            setColumns([
                {
                    id: "uniquePupilNumber",
                    header: t("students.list.uniquePupil"),
                    cell: ({ row }) => {
                        return <Box fontWeight={600}>{row.original.uniquePupilNumber}</Box>;
                    },
                },
                {
                    id: "firstName",
                    header: t("students.list.firstName"),
                    accessorKey: "firstName",
                },
                {
                    id: "lastName",
                    header: t("students.list.lastName"),
                    accessorKey: "lastName",
                },

                ...(hasSync
                    ? [
                          {
                              id: "groupCallId",
                              header: t("students.list.groupCallId"),
                              accessorKey: "groupCallId",
                              initiallyHidden: true,
                          },
                          {
                              id: "groupCallStatus",
                              label: t("students.list.groupCallStatusStudents"),
                              header: () => (
                                  <Box textAlign={"center"}>
                                      {t("students.list.groupCallStatusStudents")}
                                  </Box>
                              ),
                              cell: ({ row }) => {
                                  const { original: o } = row;

                                  return (
                                      <Box textAlign={"center"}>
                                          <Tooltip
                                              title={t(
                                                  "students.list.groupCallStatus" +
                                                      o.groupCallStatus,
                                              ).replaceAll("MIS_Name", accountInfo?.misName || "")}
                                          >
                                              <Box component="span">
                                                  {o.groupCallStatus === "active" ? (
                                                      <Icon path={mdiCheck} />
                                                  ) : o.groupCallStatus === "manual" ? (
                                                      <Icon path={mdiLinkOff} />
                                                  ) : (
                                                      <Icon path={mdiClose} />
                                                  )}
                                              </Box>
                                          </Tooltip>
                                      </Box>
                                  );
                              },
                              initiallyHidden: true,
                          },
                      ]
                    : []),
                ...(hasPupilApp
                    ? [
                          {
                              id: "userIdentifier",
                              header: t("students.list.userIdentifier"),
                              accessorKey: "userIdentifier",
                          },
                          {
                              id: "status",
                              header: t("students.list.status"),
                              cell: ({ row }) => {
                                  if (row.original.status) {
                                      return (
                                          <Box>
                                              {t("students.list.appStatus" + row.original.status)}
                                          </Box>
                                      );
                                  }
                                  return "-";
                              },
                          },
                          {
                              id: "parents",
                              header: t("students.list.parents"),
                              cell: ({ row }) => {
                                  if (row.original.parents) {
                                      return (
                                          <Box fontWeight={600}>{row.original.parents.length}</Box>
                                      );
                                  }
                                  return "-";
                              },
                              initiallyHidden: true,
                          },
                          {
                              id: "email",
                              header: t("students.list.email"),
                              accessorKey: "email",
                              initiallyHidden: true,
                          },
                      ]
                    : []),
                {
                    id: "yearGroup",
                    header: t("students.list.yearGroup"),
                    accessorKey: "yearGroup",
                    initiallyHidden: true,
                },
                {
                    // actions definition (always use id "actions")
                    id: "actions",
                    cell: ({ row }) => (
                        <Box textAlign={"right"}>
                            <ActionsTableButton
                                actions={
                                    authUser.userRole === SchoolUserRole.SCHOOL_ADMIN ||
                                    authUser.userRole === SchoolUserRole.OWNER
                                        ? tableActions
                                        : []
                                }
                                rowData={row.original}
                                forceDotMenu={true}
                            />
                        </Box>
                    ),
                },
            ]);
        }
    }, [students, accountInfo]);

    useEffect(() => {
        setFilters([
            {
                id: "name",
                type: "text",
                label: t("students.list.filters.name"),
                value: searchParams["name"] || "",
            },
            {
                id: "uniquePupilNumber",
                type: "text",
                label: t("students.list.filters.uniquePupilNumber"),
                value: searchParams["uniquePupilNumber"] || "",
            },
            ...(hasSync
                ? [
                      {
                          id: "groupCallStatus",
                          type: "select",
                          label: t("students.list.filters.groupCallStatus"),
                          options: [
                              { label: t("common.noneFilter"), value: "" },
                              {
                                  label: t("parent.list.groupCallStatusStudentActive"),
                                  value: "active",
                              },
                              {
                                  label: t("parent.list.groupCallStatusStudentManual"),
                                  value: "manual",
                              },
                              {
                                  label: t("parent.list.groupCallStatusStudentDeleted").replaceAll(
                                      "MIS_Name",
                                      accountInfo?.misName || "",
                                  ),
                                  value: "deleted",
                              },
                          ],
                          value: searchParams["groupCallStatus"] || "",
                          initiallyHidden: true,
                      },
                  ]
                : []),
            ...(hasPupilApp
                ? [
                      {
                          id: "userIdentifier",
                          type: "text",
                          label: t("students.list.filters.appUserIdentifier"),
                          value: searchParams["userIdentifier"] || "",
                          initiallyHidden: true,
                      },
                      {
                          id: "status",
                          type: "select",
                          label: t("students.list.filters.appStatus"),
                          options: [
                              { label: t("common.noneFilter"), value: "" },
                              { label: t("students.list.filters.statusAdded"), value: "added" },
                              {
                                  label: t("students.list.filters.statusInvited"),
                                  value: "invited",
                              },
                              {
                                  label: t("students.list.filters.statusConfirmed"),
                                  value: "confirmed",
                              },
                              {
                                  label: t("students.list.filters.statusDeactivated"),
                                  value: "deactivated",
                              },
                          ],
                          value: searchParams["status"] || "",
                          initiallyHidden: true,
                      },
                      {
                          id: "parentName",
                          type: "text",
                          label: t("students.list.filters.parentName"),
                          value: searchParams["parentName"] || "",
                      },
                      {
                          id: "parentUserIdentifier",
                          type: "text",
                          label: t("students.list.filters.parentUserIdentifier"),
                          value: searchParams["parentUserIdentifier"] || "",
                      },
                      ...(hasSync
                          ? [
                                {
                                    id: "parentGroupCallStatus",
                                    type: "select",
                                    label: t("students.list.filters.parentGroupCallStatus"),
                                    options: [
                                        { label: t("common.noneFilter"), value: "" },
                                        {
                                            label: t("parent.list.groupCallStatusParentActive"),
                                            value: "active",
                                        },
                                        {
                                            label: t("parent.list.groupCallStatusParentManual"),
                                            value: "manual",
                                        },
                                        {
                                            label: t(
                                                "parent.list.groupCallStatusParentDeleted",
                                            ).replaceAll("MIS_Name", accountInfo?.misName || ""),
                                            value: "deleted",
                                        },
                                    ],
                                    value: searchParams["parentGroupCallStatus"] || "",
                                    initiallyHidden: true,
                                },
                            ]
                          : []),
                  ]
                : []),
            {
                id: "yearGroup",
                type: "text",
                label: t("students.list.filters.yearGroup"),
                value: searchParams["yearGroup"] || "",
                initiallyHidden: true,
            },
            {
                id: "form",
                type: "text",
                label: t("students.list.filters.form"),
                value: searchParams["form"] || "",
                initiallyHidden: true,
            },
            {
                id: "tagId",
                type: "autocomplete",
                label: t("students.list.filters.tag"),
                value: searchParams["tagId"] || "",
                initiallyHidden: true,
                options: tagOptions || [],
                onInputChange: (_e, v) => setTagSearch(v),
            },
            {
                id: "onlyWithoutDateOfBirth",
                type: "boolean",
                label: t("students.list.filters.onlyWithoutDateOfBirth"),
                value: !!(searchParams["onlyWithoutDateOfBirth"] === "true"),
            },
        ]);
    }, [accountInfo, tagOptions]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("students.tab")}
            </Typography>
            <TablePage
                headerNavButtons={
                    <>
                        {authUser.userRole === SchoolUserRole.SCHOOL_ADMIN ||
                        authUser.userRole === SchoolUserRole.OWNER ? (
                            <>
                                {!hasSync && (
                                    <Button
                                        component={Link}
                                        to={attachSchoolId(ROUTE_MY_SCHOOL_STUDENT_ADD)}
                                        color="primary"
                                        id="addButton"
                                    >
                                        {t("students.list.addButton")}
                                    </Button>
                                )}
                                {hasPupilApp && (
                                    <Button
                                        variant="text"
                                        disableRipple
                                        onClick={() => setMassActionModalOpened(true)}
                                        id="appStudentActionsButton"
                                    >
                                        {t("students.list.studentActions")}
                                    </Button>
                                )}
                                <Button
                                    variant="text"
                                    startIcon={<Icon path={mdiPublish} />}
                                    disableRipple
                                    onClick={() => setImportStudentsDialogOpened(true)}
                                    id="importStudentsButton"
                                >
                                    {t("students.list.importStudents")}
                                </Button>
                            </>
                        ) : null}
                    </>
                }
                isLoading={isLoading}
                data={students?.data}
                count={students?.count || 0}
                columns={columns}
                rowSelection={rowSelection}
                handleRowSelection={setRowSelection}
                filters={filters}
                handleFiltersChange={setFilters}
                pagination={pagination}
                handlePaginationChange={setPagination}
            />
            <PromptDeleteConfirm open={removeDialogOpened} onClose={handleDeleteDialogClose}>
                <Typography variant="h3">{t("students.list.deleteStudentPromptHeader")}</Typography>
                <Box pt={3}>{t("students.list.deleteStudentPromptType")}</Box>
            </PromptDeleteConfirm>
            <ImportStudentsModal
                open={importStudentsDialogOpened}
                handleClose={() => {
                    setImportStudentsDialogOpened(false);
                    getStudentList();
                }}
            />
            <StudentMassActionsModal
                students={students?.data}
                opened={massActionModalOpened}
                rowSelection={rowSelection}
                count={allStudentsCount}
                handleClose={() => {
                    setMassActionModalOpened(false);
                    getStudentList();
                }}
            />
        </AppContainer>
    );
};

export default StudentListContainer;
