import PaperInner from "src/components/PaperInner";
import { Box, FormControlLabel, Grid, MenuItem, Radio, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Field, useFormikContext } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import { useEffect, useState } from "react";
import { TFunction } from "i18next";
import * as yup from "yup";
import HeadingCounter from "src/components/HeadingCounter";
import SpecificationSelectField from "src/forms/SpecificationSelectField";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import useDebounce from "src/hooks/useDebounce";
import { useTitleForUrlMutation } from "../../hooks/useTitleForUrlMutation";
import { useClassListByCohort } from "src/modules/class/hooks/useClassList";
import { OrmClassTrackerGroup } from "src/orm/models/ClassTrackerGroup";
import { LinkDetailedModel } from "../../model/LinkModel";
import { useTrackerPath } from "src/modules/class/hooks/useTrackerPath";

export interface LinkAddEditFormShape {
    specification: number | null;
    academicYear: number | null;
    yearGroup: number | null;
    url: string;
    title: string;
    description: string;
    status: "active" | "inactive";
}

export const getEmptyLinkForm = (
    cohort: number,
    specification?: number,
    yearGroup?: number,
): LinkAddEditFormShape => ({
    specification: specification || null,
    academicYear: cohort,
    yearGroup: yearGroup || null,
    url: "",
    title: "",
    description: "",
    status: "active",
});

const MAX_TITLE_LENGTH = 50;

export const addEditLinkSchema = (t: TFunction) => {
    return yup.object().shape({
        title: yup.string().required(t("link.addEdit.titleReq")).max(MAX_TITLE_LENGTH),
        specification: yup.number().required(t("common.form.validation.specification.required")),
        academicYear: yup.number().required(t("common.form.validation.academicYear.required")),
        yearGroup: yup
            .number()
            .required(t("common.form.validation.yearGroup.required"))
            .typeError(t("common.form.validation.yearGroup.required")),
        url: yup.string().required(t("link.addEdit.urlReq")).url(t("link.addEdit.urlStructure")),
        description: yup.string().required(t("link.addEdit.descriptionReq")),
    });
};
const urlRegExpFromYup = new RegExp(
    //eslint-disable-next-line
    /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
);

const LinkAddEditForm = ({ linkDetails }: { linkDetails?: LinkDetailedModel }) => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<LinkAddEditFormShape>();
    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const [selectedQualification, setSelectedQualification] = useState<number | null>(null);
    const [filteredYearGroups, setFilteredYearGroups] = useState<number[]>([]);
    const {
        data: classList,
        refetch: getClasses,
        isStale,
    } = useClassListByCohort(values.academicYear, {
        qualificationId: selectedQualification,
        yearGroup: undefined,
        page: 1,
        perPage: 100000,
    });
    useEffect(() => {
        if (selectedQualification) {
            getClasses();
        }
    }, [selectedQualification]);

    useEffect(() => {
        if (linkDetails?.yearGroup) {
            setFilteredYearGroups([linkDetails?.yearGroup]);
        }
    }, [linkDetails?.yearGroup]);

    useEffect(() => {
        if (classList?.data) {
            const filteredYgs = classList?.data
                ?.reduce((yearGroups: number[], singleClass: OrmClassTrackerGroup) => {
                    if (
                        singleClass?.specification?.id === values.specification &&
                        !yearGroups.find(yg => yg === singleClass.yearGroup)
                    ) {
                        return yearGroups.concat(singleClass.yearGroup);
                    }
                    return yearGroups;
                }, [])
                .sort((a, b) => a - b);

            setFilteredYearGroups(filteredYgs);

            if (!filteredYgs.includes(values.yearGroup) && filteredYgs.length > 0) {
                setFieldValue("yearGroup", filteredYgs[0]);
            }
        }
    }, [classList?.data, values.specification, isStale]);

    const debouncedUrl = useDebounce(values.url, 500);

    const { mutate: getTitleAndDescription } = useTitleForUrlMutation(
        (data?: { description: string; title: string }) => {
            if (data) {
                setFieldValue("title", values.title || data.title || "");
                setFieldValue("description", values.description || data.description || "");
            }
        },
    );
    useEffect(() => {
        if (debouncedUrl && urlRegExpFromYup.test(debouncedUrl)) {
            getTitleAndDescription(debouncedUrl);
        }
    }, [debouncedUrl]);

    const { data: trackerPath, refetch } = useTrackerPath(linkDetails?.specification.id);

    useEffect(() => {
        if (linkDetails?.specification.id) {
            refetch();
        }
    }, [linkDetails?.specification.id]);

    return (
        <Form>
            <PaperInner border="bottom">
                <HeadingCounter number="1">{t("link.addEdit.targetTracker")}</HeadingCounter>
                <Typography component="p" variant="overline" sx={{ mb: 3 }}>
                    {t("link.addEdit.targetTrackerHint")}
                </Typography>
                <Box mb={4}>
                    <Field
                        locked={!!linkDetails?.id}
                        trackerPath={trackerPath}
                        name={`specification`}
                        component={SpecificationSelectField}
                        allowedQualificationsOnly={true}
                        allowedOnly={true}
                        showWithClassesOnly={true}
                        cohort={values.academicYear}
                        handleQualificationChange={(qualification: number) => {
                            setSelectedQualification(qualification);
                            setFilteredYearGroups([]);
                        }}
                        handleSpecificationChange={specification => {
                            setFieldValue("specification", specification.id);
                        }}
                        id="SpecificationSelectField"
                    />
                </Box>
                <Grid container spacing={4}>
                    {values.academicYear && (
                        <Grid item sm={4}>
                            <Field
                                name="academicYear"
                                label={t("link.addEdit.cohort")}
                                component={TextField}
                                select
                                disabled
                                id="academicYearField"
                            >
                                {cohorts?.map(cohort => (
                                    <MenuItem key={cohort.id} value={cohort.id}>
                                        {cohort.name}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    )}
                    {values.specification && (
                        <Grid item sm={4}>
                            <Field
                                name="yearGroup"
                                label={t("link.addEdit.yearGroup")}
                                component={TextField}
                                select
                                disabled={!!linkDetails?.id}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="yearGroupField"
                            >
                                {filteredYearGroups.map(year => (
                                    <MenuItem key={year} value={year}>
                                        {year === 0 ? t("common.yg0") : year}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    )}
                </Grid>
            </PaperInner>
            {values.specification && values.yearGroup >= 0 && values.academicYear && (
                <PaperInner border="bottom">
                    <HeadingCounter number="2">{t("link.addEdit.configureLink")}</HeadingCounter>
                    <Grid container sm={6} spacing={4}>
                        <Grid item>
                            <Field
                                name="status"
                                label="Radio Group"
                                component={RadioGroup}
                                row
                                id="statusField"
                            >
                                <FormControlLabel
                                    value={"active"}
                                    control={<Radio />}
                                    label={t("link.addEdit.statusActive")}
                                    id="status-active-radio"
                                />
                                <FormControlLabel
                                    value={"inactive"}
                                    control={<Radio />}
                                    label={t("link.addEdit.statusInactive")}
                                    id="status-inactive-radio"
                                />
                            </Field>
                        </Grid>
                        <Grid item sm={12}>
                            <Field
                                name="url"
                                label={t("link.addEdit.url")}
                                component={TextField}
                                id="urlField"
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Field
                                name="title"
                                label={t("link.addEdit.title")}
                                component={TextField}
                                inputProps={{ maxLength: MAX_TITLE_LENGTH }}
                                id="titleField"
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Field
                                name="description"
                                label={t("link.addEdit.description")}
                                component={TextField}
                                multiline
                                id="descriptionField"
                            />
                        </Grid>
                    </Grid>
                </PaperInner>
            )}
        </Form>
    );
};

export default LinkAddEditForm;
