import { useFormContext, Controller } from "react-hook-form";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormControlLabelProps,
    CheckboxProps,
    SxProps,
} from "@mui/material";
import { RefAttributes, forwardRef } from "react";
import { Theme } from "@mui/material";
import { useTranslation } from "react-i18next";

interface RHFCheckboxProps extends Omit<FormControlLabelProps, "control"> {
    name: string;
    color?: CheckboxProps["color"];
    sx?: SxProps<Theme>;
}

export const RHFCheckbox: React.ForwardRefExoticComponent<
    Omit<RHFCheckboxProps & RefAttributes<any>, "ref"> & RefAttributes<any>
> = forwardRef(({ name, color, sx, ...other }, ref) => {
    const { control } = useFormContext();
    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Checkbox
                                color={color}
                                {...field}
                                checked={field.value}
                                disabled={other.disabled}
                            />
                        );
                    }}
                />
            }
            label={other.label}
            inputRef={ref}
            sx={sx}
        />
    );
});
RHFCheckbox.displayName = "RHFCheckbox";

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, "control" | "label"> {
    name: string;
    options: { id: string; label: string }[];
    handleClick?: () => void;
}

export function RHFMultiCheckbox({ name, options, handleClick, ...other }: RHFMultiCheckboxProps) {
    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                const onSelected = (option: string) =>
                    field.value.find((v: any) => v.id === option)
                        ? field.value.filter(
                              (value: { id: string; label: string }) => value.id !== option,
                          )
                        : [...field.value, { id: option, label: t(option) }];

                return (
                    <FormGroup>
                        {options.map(option => (
                            <FormControlLabel
                                key={option.id}
                                control={
                                    <Checkbox
                                        checked={!!field.value.find((v: any) => v.id === option.id)}
                                        onChange={() => {
                                            if (handleClick) {
                                                handleClick();
                                            }
                                            field.onChange(onSelected(option.id));
                                        }}
                                    />
                                }
                                label={option.label}
                                {...other}
                            />
                        ))}
                    </FormGroup>
                );
            }}
        />
    );
}
