import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { SubjectParam } from "src/forms/types";
import {
    commonAllowedQualificationsEndpoint,
    CommonQualificationsResponse,
} from "../../api/allowedQualifications";

export enum CommonQualificationActionsTypes {
    GET_ALLOWED_QUALIFICATION_LIST = "[common] GET ALLOWED QUALIFICATION LIST",
    GET_ALLOWED_QUALIFICATION_LIST_SUCCESS = "[common] GET ALLOWED QUALIFICATION LIST SUCCESS",
}

export const CommonQualificationApiEndpoints = {
    getAllowedQualificationList: createActionObject(
        commonAllowedQualificationsEndpoint,
        CommonQualificationActionsTypes.GET_ALLOWED_QUALIFICATION_LIST_SUCCESS,
    ),
};

export const CommonQualificationRawActions = (
    CommonEndpoints: Record<Keys<typeof CommonQualificationApiEndpoints>, ApiActionMeta>,
) => ({
    getAllowedQualificationList: (subjectAreaId: number, hasClasses) =>
        createApiAction(
            CommonQualificationActionsTypes.GET_ALLOWED_QUALIFICATION_LIST,
            { values: { hasClasses }, params: { subjectArea: subjectAreaId } },
            CommonEndpoints.getAllowedQualificationList,
        ),
    getAllowedQualificationListSuccess: (
        payload: ApiCallbackPayload<CommonQualificationsResponse, {}, SubjectParam>,
    ) =>
        createAction(
            CommonQualificationActionsTypes.GET_ALLOWED_QUALIFICATION_LIST_SUCCESS,
            payload,
        ),
});
