import { Box, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { TFunction } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterTypes, FiltersShape } from "src/modules/common/components/Grid/GridStudentFilter";
import { isValueDefined } from "src/services/utilsGPT";

interface OwnProps {
    handleFiltersChange: (filter: FiltersShape) => void;
    columnName: string;
    columnDisplayName: string;
    filterType: FilterTypes;
    noDataEnabled?: boolean;
    onSubmit?: () => void;
    rangeMin?: number;
    rangeMax?: number;
}

export const rangeValueDisplay = (
    t: TFunction<"common", undefined, "common">,
    minValue: string,
    maxValue: string,
): string =>
    minValue === "no-data"
        ? t("common.noData")
        : (minValue !== "" ? `<span>${t("common.filterMin")}:</span> ` + minValue : "") +
          (minValue !== "" && maxValue !== "" ? ", " : "") +
          (maxValue !== "" ? `<span>${t("common.filterMax")}:</span> ` + maxValue : "");

const RangeFilterComponent: FC<OwnProps> = ({
    handleFiltersChange,
    columnName,
    columnDisplayName,
    noDataEnabled,
    onSubmit,
    filterType,
    rangeMin,
    rangeMax,
}) => {
    const { t } = useTranslation();
    const [minimumValue, setMinimumValue] = useState<string | number>("");
    const [maximumValue, setMaximumValue] = useState<string | number>("");
    const [showWithNoData, setShowWithNoData] = useState<boolean>(false);

    return (
        <>
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="32px">
                <TextField
                    value={minimumValue}
                    margin="normal"
                    type="number"
                    disabled={!!showWithNoData}
                    SelectProps={{
                        displayEmpty: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={t("common.from")}
                    onChange={e => setMinimumValue(e.target.value as string)}
                />
                <TextField
                    value={maximumValue}
                    margin="normal"
                    disabled={!!showWithNoData}
                    type="number"
                    SelectProps={{
                        displayEmpty: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={t("common.to")}
                    onChange={e => setMaximumValue(e.target.value as string)}
                />
            </Box>
            {noDataEnabled && (
                <Box>
                    <Box>
                        {columnName && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showWithNoData}
                                        onChange={() => {
                                            setShowWithNoData(!showWithNoData);
                                        }}
                                        name=""
                                    />
                                }
                                label={t("common.showOnlyWithNoData")}
                            />
                        )}
                    </Box>
                </Box>
            )}
            <Box pt={2}>
                <Button
                    color="primary"
                    disabled={
                        !!(
                            (!columnName ||
                                (columnName && minimumValue === "" && maximumValue === "")) &&
                            !showWithNoData
                        )
                    }
                    onClick={() => {
                        let newMinimumValue: any = showWithNoData
                            ? "no-data"
                            : parseFloat(`${minimumValue}`);
                        let newMaximumValue: any = showWithNoData
                            ? undefined
                            : parseFloat(`${maximumValue}`);

                        if (
                            newMinimumValue !== "no-data" &&
                            !isNaN(newMinimumValue as number) &&
                            !isNaN(newMaximumValue as number) &&
                            newMinimumValue > newMaximumValue
                        ) {
                            [newMinimumValue, newMaximumValue] = [newMaximumValue, newMinimumValue];
                        }
                        if (newMinimumValue !== "no-data" && !isNaN(newMinimumValue as number)) {
                            if (rangeMin !== undefined && newMinimumValue < rangeMin)
                                newMinimumValue = rangeMin;
                            if (rangeMax !== undefined && newMinimumValue > rangeMax)
                                newMinimumValue = rangeMax;
                            newMinimumValue = (Math.round(newMinimumValue * 4) / 4).toFixed(2);
                        }
                        if (!isNaN(newMaximumValue as number)) {
                            if (rangeMin !== undefined && newMaximumValue < rangeMin)
                                newMaximumValue = rangeMin;
                            if (rangeMax !== undefined && newMaximumValue > rangeMax)
                                newMaximumValue = rangeMax;
                            newMaximumValue = (Math.round(newMaximumValue * 4) / 4).toFixed(2);
                        }

                        const minDefined =
                            isValueDefined(newMinimumValue) || newMinimumValue === "no-data";
                        const maxDefined = isValueDefined(newMaximumValue);
                        if (minDefined || maxDefined) {
                            const minValue = minDefined ? newMinimumValue : "";
                            const maxValue = maxDefined ? newMaximumValue : "";
                            handleFiltersChange({
                                [columnName]: {
                                    type: filterType,
                                    attribute: columnName,
                                    attributeDisplay: columnDisplayName,
                                    values: [
                                        {
                                            value: minValue,
                                            valueTo: maxValue,
                                            valueDisplay: rangeValueDisplay(t, minValue, maxValue),
                                        },
                                    ],
                                },
                            });
                        }
                        onSubmit && onSubmit();
                        setMinimumValue("");
                        setMaximumValue("");
                        setShowWithNoData(false);
                    }}
                >
                    {t("common.filterAdd")}
                </Button>
            </Box>
        </>
    );
};

export default RangeFilterComponent;
