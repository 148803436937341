import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { STUDENT_LIST_QUERY } from "./useStudentList";
import { getSchoolAccountId } from "src/services/url";

export interface RemoveStudentRequest {
    id: number;
}

const apiRemoveStudent = async (values: RemoveStudentRequest): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`school/${getSchoolAccountId()}/student/${values.id}/`);
};

export const useRemoveStudentMutation = (onSuccess: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: RemoveStudentRequest) => apiRemoveStudent(values),
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [STUDENT_LIST_QUERY] });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(err.response.data?.error || t("students.list.errorRemovingStudent"), {
                ...SnackbarErrorOptions,
            });
        },
    });
};
