import PromptDialog from "src/forms/PromptDialog";
import useDebounce from "src/hooks/useDebounce";
import Icon from "../Icon";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {
    ColumnDef,
    PaginationState,
    RowSelectionState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DebouncedInput } from "../DebouncedInput";
import { mdiTableColumn, mdiTableEye } from "@mdi/js";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export interface TablePageFilterType {
    id: string;
    type: string | "text" | "boolean" | "select" | "";
    label: string;
    value: string | number | boolean;
    initiallyHidden?: boolean;
    options?: { label: string; value: string }[];

    onInputChange?: (e: any, value: any) => void;
}

interface OwnProps {
    data: any[];
    columns: (ColumnDef<any> & { initiallyHidden?: boolean })[];
    count: number;
    isLoading?: boolean;
    headerNavButtons: ReactNode;
    pagination: PaginationState;
    handlePaginationChange: (pagination: PaginationState) => void;
    filters?: TablePageFilterType[];
    handleFiltersChange: (filters: TablePageFilterType[]) => void;
    rowSelection?: RowSelectionState;
    handleRowSelection?: (rowSelection: RowSelectionState) => void;
}

const cloneObject = obj => JSON.parse(JSON.stringify(obj));

const TablePage: FC<OwnProps> = ({
    data,
    columns,
    count,
    isLoading,
    headerNavButtons,
    pagination,
    handlePaginationChange,
    filters,
    handleFiltersChange,
    rowSelection,
    handleRowSelection,
}) => {
    const debouncedIsLoading = useDebounce(isLoading, 500);

    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const returnUrl = searchParams["returnUrl"] || "";

    const enableFiltersVisibility = !!(
        filters?.find(f => f.initiallyHidden) ||
        filters.filter(f => f.initiallyHidden === undefined).length === filters.length
    );

    const enableColumnsVisibility = !!columns?.find(c => c.initiallyHidden);
    const hasSelectionEnabled = !!handleRowSelection;

    // columns visibility compared with hidden columns
    const [columnVisibility, setColumnVisibility] = useState({});

    // filters visibility compared with hidden columns
    const [filterVisibility, setFilterVisibility] = useState({});

    // all filters from inputs
    const debouncedFilters = useDebounce(filters, 200);

    // dialogs
    const [columnsDialogOpen, setColumnsDialogOpen] = useState<boolean>(false);
    const [filtersDialogOpen, setFiltersDialogOpen] = useState<boolean>(false);

    // helper for selecting all rows after pagination changes to "all"
    const [selectAllAfterDataFetch, setSelectAllAfterDataFetch] = useState<boolean>(false);

    // anchor element for "select all" dropdown menu
    const tableHeadRowRef = useRef<HTMLTableRowElement>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    // main table hook
    const table = useReactTable({
        data: data || [],
        columns: [
            ...(hasSelectionEnabled
                ? [
                      {
                          id: "select",
                          header: ({
                              table: { setPageIndex, setPageSize, resetRowSelection, getRowModel },
                          }) =>
                              table.getRowModel().rows.length > 0 &&
                              debouncedFilters.find(gf => gf.value) ? (
                                  <>
                                      <Menu
                                          anchorEl={tableHeadRowRef.current}
                                          open={menuOpen}
                                          onClose={() => setMenuOpen(false)}
                                          anchorOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                          }}
                                          autoFocus={false}
                                          sx={{ mt: 2, ml: 6.5 }}
                                      >
                                          <MenuItem
                                              onClick={() => {
                                                  getRowModel().rows.forEach(row => {
                                                      row.toggleSelected();
                                                  });

                                                  setMenuOpen(false);
                                              }}
                                          >
                                              {`${t("common.selectAll")} ${data?.length} ${t(
                                                  "common.onThisPage",
                                              )}`}
                                          </MenuItem>
                                          <MenuItem
                                              onClick={() => {
                                                  setSelectAllAfterDataFetch(true);
                                                  setPageIndex(0);
                                                  setPageSize(count);
                                                  resetRowSelection();
                                                  setMenuOpen(false);
                                              }}
                                          >
                                              {`${t("common.selectAll")} ${count} ${t(
                                                  "common.records",
                                              )}`}
                                          </MenuItem>
                                      </Menu>
                                      <Checkbox
                                          {...{
                                              checked: table.getIsAllRowsSelected(),
                                              indeterminate: table.getIsSomeRowsSelected(),
                                              onChange: () => {
                                                  if (table.getIsAllRowsSelected()) {
                                                      table.resetRowSelection();
                                                  } else {
                                                      if (data?.length === count) {
                                                          table.toggleAllRowsSelected();
                                                      } else {
                                                          setMenuOpen(true);
                                                      }
                                                  }
                                              },
                                          }}
                                      />
                                  </>
                              ) : (
                                  <></>
                              ),
                          cell: ({ row }) => (
                              <Checkbox
                                  {...{
                                      checked: row.getIsSelected(),
                                      disabled: !row.getCanSelect(),
                                      indeterminate: row.getIsSomeSelected(),
                                      onChange: row.getToggleSelectedHandler(),
                                  }}
                              />
                          ),
                      } as ColumnDef<any, any>,
                  ]
                : []),
        ].concat(columns),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        manualPagination: true,
        onRowSelectionChange: handleRowSelection,
        onPaginationChange: handlePaginationChange,
        state: {
            rowSelection,
            columnVisibility: enableColumnsVisibility ? columnVisibility : undefined,
            pagination: { pageIndex: pagination.pageIndex, pageSize: pagination.pageSize },
        },
        onColumnVisibilityChange: enableColumnsVisibility ? setColumnVisibility : undefined,
        debugTable: true,
    });

    // pagination
    const { pageSize, pageIndex } = table.getState().pagination;

    useEffect(() => {
        if (!debouncedIsLoading) {
            const convertedSearchFilter = {};

            filters.forEach(df => {
                if (df.value) {
                    convertedSearchFilter[df.id] = df.value;
                }
            });

            table.resetRowSelection();

            const filterString = new URLSearchParams(convertedSearchFilter).toString();
            navigate(
                location.pathname +
                    "?" +
                    filterString +
                    (returnUrl ? `&returnUrl=` + encodeURIComponent(returnUrl) : ""),
            );
        }
    }, [filters, pageSize, pageIndex, debouncedIsLoading]);

    useEffect(() => {
        if (data?.length && selectAllAfterDataFetch) {
            table.toggleAllRowsSelected();
            setSelectAllAfterDataFetch(false);
        }
    }, [data, selectAllAfterDataFetch]);

    useEffect(() => {
        if (
            !debouncedIsLoading &&
            count &&
            columns.length > 0 &&
            Object.keys(columnVisibility).length === 0
        ) {
            setColumnVisibility(
                Object.fromEntries(columns.map(hc => [hc.id, !hc.initiallyHidden])),
            );
        }
    }, [count, columns, debouncedIsLoading]);

    useEffect(() => {
        if (
            !debouncedIsLoading &&
            columns.length > 0 &&
            Object.keys(filterVisibility).length !== Object.keys(filters).length
        ) {
            setFilterVisibility(
                Object.fromEntries(filters.map(hc => [hc.id, !hc.initiallyHidden])),
            );
        }
    }, [count, columns, filters, debouncedIsLoading, filterVisibility]);

    return (
        <>
            <Box mb={4} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    {headerNavButtons}
                </Box>
                <Box display="flex" alignItems="center">
                    {enableColumnsVisibility && (
                        <Button
                            variant="text"
                            disableRipple
                            startIcon={<Icon path={mdiTableColumn} />}
                            onClick={() => setColumnsDialogOpen(true)}
                            id="adjustColumnsButton"
                        >
                            {t("common.adjustColumnsBtn")}
                        </Button>
                    )}
                    {enableFiltersVisibility && (
                        <Button
                            variant="text"
                            disableRipple
                            startIcon={<Icon path={mdiTableEye} />}
                            onClick={() => setFiltersDialogOpen(true)}
                            id="adjustFiltersBUtton"
                        >
                            {t("common.adjustFiltersBtn")}
                        </Button>
                    )}
                    <PromptDialog
                        yesLabel={""}
                        noLabel=""
                        open={columnsDialogOpen}
                        onClose={() => setColumnsDialogOpen(false)}
                    >
                        <Typography variant="h3">{t("common.adjustColumns")}</Typography>
                        <Box pt={3} pb={0}>
                            {table
                                ?.getAllLeafColumns()
                                .filter(
                                    column =>
                                        column.columnDef.id !== "actions" &&
                                        column.columnDef.id !== "select",
                                )
                                .map(column => (
                                    <Box key={column.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={column.getIsVisible()}
                                                    onChange={column.getToggleVisibilityHandler()}
                                                />
                                            }
                                            label={
                                                column.columnDef["label"] || column.columnDef.header
                                            }
                                        />
                                    </Box>
                                ))}
                        </Box>
                    </PromptDialog>
                    <PromptDialog
                        yesLabel={""}
                        noLabel=""
                        open={filtersDialogOpen}
                        onClose={() => setFiltersDialogOpen(false)}
                    >
                        <Typography variant="h3">{t("common.adjustFilters")}</Typography>
                        <Box pt={3} pb={0}>
                            {filters?.map(f => (
                                <Box key={f.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!(filterVisibility[f.id] === true)}
                                                onChange={() => {
                                                    const newFilterVisibility = {
                                                        ...filterVisibility,
                                                        [f.id]: !filterVisibility[f.id],
                                                    };
                                                    setFilterVisibility(newFilterVisibility);
                                                }}
                                            />
                                        }
                                        label={f.label}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </PromptDialog>
                </Box>
            </Box>

            <Grid container spacing={3}>
                {filters &&
                    filters
                        .filter(f => !!filterVisibility[f.id])
                        .map(gf => {
                            if (gf.type === "boolean") {
                                return (
                                    <Grid item sm={3} key={gf.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!filters.find(o => o.id === gf.id)?.value
                                                    }
                                                    onChange={() => {
                                                        const objIndex = filters.findIndex(
                                                            o => o.id === gf.id,
                                                        );
                                                        const newFilters = cloneObject(filters);
                                                        newFilters[objIndex].value =
                                                            !newFilters[objIndex].value;
                                                        handleFiltersChange(newFilters);
                                                    }}
                                                    name=""
                                                />
                                            }
                                            label={gf.label}
                                        />
                                    </Grid>
                                );
                            }
                            if (gf.type === "select") {
                                return (
                                    <Grid item sm={3} key={gf.id}>
                                        <TextField
                                            select
                                            fullWidth
                                            key={gf.id}
                                            margin="none"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {
                                                const objIndex = filters.findIndex(
                                                    o => o.id === gf.id,
                                                );
                                                const newFilters = cloneObject(filters);
                                                newFilters[objIndex].value = e.target.value;
                                                handleFiltersChange(newFilters);
                                            }}
                                            name=""
                                            label={gf.label}
                                            value={gf.value}
                                        >
                                            {gf.options.map(o => (
                                                <MenuItem key={o.value} value={o.value}>
                                                    {o.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                );
                            }
                            if (gf.type === "autocomplete") {
                                return (
                                    <Grid item sm={3} key={gf.id}>
                                        <Autocomplete
                                            freeSolo
                                            options={gf.options}
                                            onInputChange={(event, newInputValue, reason) => {
                                                if (reason === "reset") {
                                                    const objIndex = filters.findIndex(
                                                        o => o.id === gf.id,
                                                    );
                                                    const newFilters = cloneObject(filters);
                                                    newFilters[objIndex].value = "";
                                                    handleFiltersChange(newFilters);
                                                }
                                                gf.onInputChange &&
                                                    gf.onInputChange(event, newInputValue);
                                            }}
                                            onChange={(_e, value) => {
                                                const objIndex = filters.findIndex(
                                                    o => o.id === gf.id,
                                                );
                                                const newFilters = cloneObject(filters);
                                                newFilters[objIndex].value =
                                                    (
                                                        value as {
                                                            label: string;
                                                            value: string;
                                                        }
                                                    )?.value || "";
                                                handleFiltersChange(newFilters);
                                            }}
                                            renderInput={params => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        label={gf.label}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                );
                            }

                            return (
                                <Grid item sm={3} key={gf.id}>
                                    <DebouncedInput
                                        key={gf.id}
                                        label={gf.label}
                                        value={`${gf.value}`}
                                        onChange={value => {
                                            const objIndex = filters.findIndex(o => o.id === gf.id);
                                            const newFilters = cloneObject(filters);
                                            newFilters[objIndex].value = value;
                                            handleFiltersChange(newFilters);
                                        }}
                                        debounce={1000}
                                    />
                                </Grid>
                            );
                        })}
            </Grid>
            <Paper sx={{ marginTop: 4 }}>
                <Box p={6} pt={3}>
                    <TableContainer sx={{ border: 0 }}>
                        <Table>
                            <TableHead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <TableRow
                                        key={headerGroup.id}
                                        sx={{
                                            "& > th:first-child": hasSelectionEnabled && {
                                                pt: 0.75,
                                                pr: 0.5,
                                                pb: 0.5,
                                                pl: 0.5,
                                                width: 53,
                                            },
                                        }}
                                        ref={tableHeadRowRef}
                                    >
                                        {headerGroup.headers.map(header => (
                                            <TableCell key={header.id} colSpan={header.colSpan}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                          header.column.columnDef.header,
                                                          header.getContext(),
                                                      )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableHead>
                            <TableBody>
                                {debouncedIsLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length}>
                                            <Box textAlign={"center"} p={4}>
                                                <CircularProgress />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    table.getRowModel().rows.map(row => (
                                        <TableRow
                                            key={row.id}
                                            sx={{
                                                "& > td:first-child": hasSelectionEnabled && {
                                                    p: 0.5,
                                                    width: 53,
                                                },
                                                "& > td:last-child": {
                                                    width: 46,
                                                    pt: 0.5,
                                                    pr: 0.5,
                                                    pb: 0.5,
                                                    pl: 0.5,
                                                },
                                            }}
                                        >
                                            {row.getVisibleCells().map(cell => (
                                                <TableCell
                                                    key={cell.id}
                                                    width={
                                                        cell.column.columnDef.id === "actions"
                                                            ? 50
                                                            : undefined
                                                    }
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext(),
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!debouncedIsLoading && (
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50, { label: "All", value: count }]}
                            component="div"
                            count={count}
                            rowsPerPage={pageSize}
                            page={pageIndex}
                            onPageChange={(_, page) => {
                                table.setPageIndex(page);
                            }}
                            onRowsPerPageChange={e => {
                                const size = e.target.value ? Number(e.target.value) : 50;

                                table.setPageSize(size);
                            }}
                            ActionsComponent={TablePaginationActions}
                        />
                    )}
                </Box>
            </Paper>
        </>
    );
};

export default TablePage;
