import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { AttributesRequest } from "./uploadAndSend";
import { StudentModel } from "src/orm/models/Student";
import { getSchoolAccountId } from "src/services/url";

export const editClassEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker/{classTracker}/`,
    method: ApiRequests.PUT,
};

export interface EditClassRequest {
    name: string;
    yearGroup: number | null;
    cohort: ListObject<number | null>;
    teachers: ListObject<number | null>[];
    attributes: AttributesRequest[];
    students: StudentModel[];
}
