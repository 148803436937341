import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { AttributesRequest } from "./uploadAndSend";
import { getSchoolAccountId } from "src/services/url";

export const bulkEditClassesEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker-group/{classTrackerGroup}/attributes/bulk-edit`,
    method: ApiRequests.PUT,
};

export interface BulkEditClassesRequest {
    attributes: AttributesRequest[];
    classTrackers: ListObject<number>[];
}
