import { Button } from "@mui/material";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const SaveIgnoreTimestampChangeBar = props => {
    const { t }: { t: TFunction } = useTranslation();
    return (
        <>
            {`${props.lastCommitedBy?.firstName} ${props.lastCommitedBy?.lastName} ${t(
                "tracker.grid.justEditedStudentInfo",
            )} `}
            <Button onClick={() => window.location.reload()} style={{ marginLeft: 10 }}>
                {t("tracker.grid.reloadGrid")}
            </Button>
            <Button onClick={props.ignoreEditChanges}>{t("tracker.grid.ignoreChanges")}</Button>
        </>
    );
};

export default SaveIgnoreTimestampChangeBar;
