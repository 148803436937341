import { ofType } from "@martin_hotell/rex-tils";
import { OrmSubjectAreaActions } from "src/orm/models/SubjectArea/actions";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CommonActions, CommonActionTypes } from "../actions";

const subjectAreaListSuccessEpic = (action$: ActionsObservable<CommonActions>) =>
    action$.pipe(
        ofType(CommonActionTypes.GET_SUBJECT_AREA_LIST_SUCCESS),
        map((action: ReturnType<typeof CommonActions.getSubjectAreaListSuccess>) => action.payload),
        mergeMap(payload => of(OrmSubjectAreaActions.bulkImport(payload.response))),
    );

const allowedSubjectAreaListSuccessEpic = (action$: ActionsObservable<CommonActions>) =>
    action$.pipe(
        ofType(CommonActionTypes.GET_ALLOWED_SUBJECT_AREA_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof CommonActions.getAllowedSubjectAreaListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload =>
            of(
                // OrmSubjectAreaActions.bulkImport(payload.response),
                CommonActions.setAllowedSubjectAreas(payload.response),
            ),
        ),
    );
export const subjectAreaEpics = combineEpics(
    subjectAreaListSuccessEpic,
    allowedSubjectAreaListSuccessEpic,
);
