import { useEffect } from "react";
import { Box, Theme, TextField, MenuItem } from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";
import COLORS from "src/styles/colors";
import { AnalysisTypes } from "../Common/AnalysisTypeModal";
import AnalysisChooseDropdownMenu from "../Common/AnalysisTypeDropdownMenu";
import { ViewGrades } from "../../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { KS4ViewGrades, KS4ViewTypes } from "../../api/KS4Grade/getValues";
import { useTranslation } from "react-i18next";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";
import { useState } from "react";
import GridDataTypeButtons, {
    DataTypeTypes,
} from "src/modules/common/components/Grid/GridDataTypeButtons";
import GridFiltersToggleButton from "src/modules/common/components/Grid/GridFiltersToggleButton";
import GridToolsToggleButton from "src/modules/common/components/Grid/GridToolsToggleButton";
import GridSnapshotCompareInfo from "src/modules/common/components/Grid/GridSnapshotCompareInfo";
import { useAnalysisSnapshots } from "../../hooks/useAnalysisSnapshots";
import GridHeaderBack from "src/modules/common/components/Grid/GridHeaderBack";
import SnapshotModal, {
    ShortSnapshotItem,
    SnapshotModalActions,
} from "src/modules/common/components/SnapshotModal";
import { SchoolUserRole } from "src/orm/models/User";
import { SnapshotData } from "src/context/TrackerContext";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { ROUTE_CLASS_CLASS_LIST } from "src/routes";

const SourceTextField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: 0,
            "& .MuiFormLabel-root.MuiFormLabel-filled": {
                color: COLORS.LIGHT_GREY_1,
                fontWeight: 400,
                transform: "scale(0.78)",
            },
            "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                display: "none",
            },
            "& .MuiInputBase-root": {
                color: COLORS.GREY_3,
                fontFamily: PRIMARY_FONT_FAMILY,
                fontSize: theme.typography.pxToRem(16),
                fontWeight: 600,
            },
            "& .MuiInputBase-input": {
                padding: "0 40px 0 0",
            },
        },
    }),
)(TextField);

const AnalysisHeader = ({
    cohort,
    yearGroup,
    analysisType,
    viewGrade,
    dataType,
    viewType,
    handleDataTypeChange,
    handleViewGradeChange,
    handleViewTypeChange,
    handleFiltersVisible,
    handleToolsVisible,
    isLoadingGrid,
    toolsVisible,
    filtersVisible,
    hideSnapshotCompare,
    handleSnapshotParamsChange,
}: {
    cohort: number;
    yearGroup: number;
    analysisType: AnalysisTypes;
    viewGrade?: string;
    dataType?: DataTypeTypes;
    handleDataTypeChange?: (type: DataTypeTypes) => void;
    handleViewGradeChange?: (viewGrade: ViewGrades | KS4ViewGrades) => void;
    handleViewTypeChange?: (viewType: KS4ViewTypes) => void;
    viewType?: KS4ViewTypes;
    handleToolsVisible?: (isChecked: boolean) => void;
    handleFiltersVisible?: (isChecked: boolean) => void;
    isLoadingGrid?: boolean;
    toolsVisible?: boolean;
    filtersVisible?: boolean;
    hideSnapshotCompare?: boolean;
    handleSnapshotParamsChange?: (snapshotParams: SnapshotData) => void;
}) => {
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const [snapshotModalOpen, setSnapshotModalOpen] = useState<boolean>(false);
    const { data: authUser } = useProfile();

    const {
        snapshotData,
        snapshots,
        setSnapshotData,
        createCohortSnapshot,
        removeCohortSnapshot,
        refetchSnapshots,
    } = useAnalysisSnapshots(cohort);

    const handleTypeChange = (changeType: DataTypeTypes) => {
        if (!changeType && dataType === DataTypeTypes.SNAPSHOT) {
            setSnapshotModalOpen(true);
        } else if (changeType && changeType === DataTypeTypes.SNAPSHOT) {
            setSnapshotModalOpen(true);
        } else if (handleDataTypeChange) {
            handleDataTypeChange(changeType);
            setSnapshotData(null);
        }
    };

    const getSnapshotAvailableTypes = () => {
        const actions: SnapshotModalActions[] = [];
        if (
            authUser?.userRole === SchoolUserRole.SCHOOL_ADMIN ||
            authUser?.userRole === SchoolUserRole.OWNER ||
            authUser?.isLeaderOfAll ||
            authUser?.leaderOfSubjectAreas?.length > 0
        ) {
            actions.push(SnapshotModalActions.CREATE);
        }
        actions.push(SnapshotModalActions.VIEW_LIST);

        if (!hideSnapshotCompare) {
            actions.push(SnapshotModalActions.COMPARE);
        }
        return actions;
    };

    useEffect(() => {
        if (cohort) refetchSnapshots(); //eslint-disable-next-line
    }, [cohort]);

    useEffect(() => {
        setSnapshotData(null);
        //eslint-disable-next-line
    }, [analysisType]);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
        >
            <GridHeaderBack
                onClick={() => {
                    navigate(ROUTE_CLASS_CLASS_LIST);
                }}
            />
            <Box display="flex" justifyContent="space-between" flex={1} py={1.5} px={2.5}>
                <Box display="flex" alignItems="center">
                    <AnalysisChooseDropdownMenu
                        cohort={cohort}
                        yearGroup={yearGroup}
                        analysisType={analysisType}
                    />
                    {viewGrade &&
                        handleViewGradeChange &&
                        ((analysisType === AnalysisTypes.KS4_GRADE_ANALYSIS &&
                            dataType !== DataTypeTypes.FORECAST) ||
                            analysisType === AnalysisTypes.SUBJECT_ANALYSIS ||
                            (analysisType === AnalysisTypes.STUDENTS_GRADE_SUMMARY &&
                                dataType !== DataTypeTypes.FORECAST)) && (
                            <Box
                                display="flex"
                                alignItems="center"
                                borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                                minHeight={40}
                                pl={3}
                                ml={1.75}
                            >
                                <SourceTextField
                                    label={t("analysis.gradeSummary.source")}
                                    select
                                    SelectProps={{
                                        displayEmpty: true,
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                            },
                                            getContentAnchorEl: null,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="none"
                                    name="viewGrade"
                                    value={viewGrade}
                                    onChange={e =>
                                        handleViewGradeChange(`${e.target.value}` as ViewGrades)
                                    }
                                >
                                    <MenuItem value={ViewGrades.CURRENT}>
                                        {t("analysis.gradeSummary.sourceCurrentGrade")}
                                    </MenuItem>
                                    <MenuItem value={ViewGrades.PROJECTED}>
                                        {t("analysis.gradeSummary.sourceProjectedGrade")}
                                    </MenuItem>
                                    {analysisType === AnalysisTypes.STUDENTS_GRADE_SUMMARY && (
                                        <MenuItem value={ViewGrades.TARGET}>
                                            {t("analysis.gradeSummary.sourceTargetGrade")}
                                        </MenuItem>
                                    )}
                                    <MenuItem value={ViewGrades.TEACHER}>
                                        {t("analysis.gradeSummary.sourceTeacher")}
                                    </MenuItem>
                                </SourceTextField>
                            </Box>
                        )}
                    {analysisType === AnalysisTypes.KS4_GRADE_ANALYSIS &&
                        viewType &&
                        handleViewTypeChange && (
                            <Box
                                display="flex"
                                alignItems="center"
                                borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                                minHeight={40}
                                pl={3}
                                ml={1.75}
                            >
                                <SourceTextField
                                    label={t("analysis.ks4Grade.type")}
                                    select
                                    SelectProps={{ displayEmpty: true }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="none"
                                    name="viewType"
                                    value={viewType}
                                    onChange={e =>
                                        handleViewTypeChange(`${e.target.value}` as KS4ViewTypes)
                                    }
                                >
                                    <MenuItem value={KS4ViewTypes.A8}>
                                        {t("analysis.ks4Grade.typeA8")}
                                    </MenuItem>
                                    <MenuItem value={KS4ViewTypes.P8}>
                                        {t("analysis.ks4Grade.typeP8")}
                                    </MenuItem>
                                </SourceTextField>
                            </Box>
                        )}
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Box display="flex" alignItems="center">
                        {handleToolsVisible && toolsVisible !== undefined && (
                            <GridToolsToggleButton
                                disabled={isLoadingGrid || false}
                                dataType={dataType || DataTypeTypes.LIVE}
                                isChecked={toolsVisible}
                                handleChange={() => handleToolsVisible(!toolsVisible)}
                            />
                        )}
                        {handleFiltersVisible && filtersVisible !== undefined && (
                            <GridFiltersToggleButton
                                disabled={isLoadingGrid || false}
                                isChecked={filtersVisible}
                                handleChange={() => handleFiltersVisible(!filtersVisible)}
                            />
                        )}
                        {(analysisType === AnalysisTypes.KS4_GRADE_ANALYSIS ||
                            analysisType === AnalysisTypes.SUBJECT_ANALYSIS ||
                            analysisType === AnalysisTypes.ATL_ANALYSIS ||
                            analysisType === AnalysisTypes.STUDENTS_GRADE_SUMMARY) && (
                            <GridDataTypeButtons
                                dataType={dataType as DataTypeTypes}
                                hasForecast={analysisType === AnalysisTypes.KS4_GRADE_ANALYSIS}
                                handleChange={handleTypeChange}
                                isLoadingGrid={isLoadingGrid}
                            />
                        )}
                    </Box>
                    <GridSnapshotCompareInfo snapshotData={snapshotData} snapshots={snapshots} />
                </Box>
            </Box>
            <SnapshotModal
                snapshots={snapshots as ShortSnapshotItem[]}
                availableTypes={getSnapshotAvailableTypes()}
                hasForecast={analysisType === AnalysisTypes.KS4_GRADE_ANALYSIS}
                open={snapshotModalOpen}
                handleClose={() => {
                    setSnapshotModalOpen(false);
                }}
                handleCreateSnapshot={name => {
                    if (cohort) {
                        createCohortSnapshot({ name });
                    }
                }}
                handleSnapshotRemove={snapshotId => {
                    if (cohort) {
                        removeCohortSnapshot({ id: snapshotId });
                    }
                }}
                handleSnapshotDataChange={(snapshotData: SnapshotData) => {
                    if (snapshotData && handleDataTypeChange) {
                        handleDataTypeChange(DataTypeTypes.SNAPSHOT);
                        setSnapshotData(snapshotData);
                        handleSnapshotParamsChange && handleSnapshotParamsChange(snapshotData);
                    }
                    setSnapshotModalOpen(false);
                }}
            />
        </Box>
    );
};

export default AnalysisHeader;
