import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { AnalysisKS4GradeValuesObject } from "./getValues";
import { getSchoolAccountId } from "src/services/url";

export const getAnalysisKS4GradeSnapshotCompareEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/ks4-grade/{cohort}/{yearGroup}/snapshot/compare/{viewGrade}/{viewType}/{dataSource1}/{dataSource2}`,
    method: ApiRequests.GET,
};

export interface KS4GradeSnapshotCompareResponse {
    values1: AnalysisKS4GradeValuesObject;
    values2: AnalysisKS4GradeValuesObject;
}
