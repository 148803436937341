import { TFunction } from "i18next";
import * as yup from "yup";

export const newPasswordSchema = (t: TFunction) =>
    yup.object().shape({
        password: yup
            .string()
            .min(8, t("common.form.validation.password.min"))
            .matches(/[a-zA-Z]/, t("common.form.validation.password.match"))
            .matches(/[0-9]/, t("common.form.validation.password.matchNum"))
            .required(t("common.form.validation.password.required")),
        repeatPassword: yup
            .string()
            .oneOf([yup.ref("password"), ""], t("common.form.validation.repeatPassword.repeat"))
            .required(t("common.form.validation.repeatPassword.required")),
    });
