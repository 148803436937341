import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { LoginResponse } from "./useLogin";
import { useAuthContext } from "src/context/AuthContext";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { ROUTE_AUTH_CONFIRMED, ROUTE_AUTH_POST_LOGIN } from "src/routes";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { Storage } from "src/services/storage";

const apiConfirm = async (hash: string): Promise<AxiosResponse<LoginResponse>> => {
    return await axiosInstance.post(`api/auth/confirm/${hash}/`);
};

export const useConfirm = (
    onError?: (err: AxiosError<{ error?: string; errors?: any }>) => void,
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { setToken } = useAuthContext();
    const { navigate } = useSchoolNavigate();

    return useMutation({
        mutationFn: (hash: string) => apiConfirm(hash),
        onSuccess: (res: AxiosResponse<LoginResponse>) => {
            setToken(res.data.token);
            // setUserContext(res.data.userContext);
            if (res.data?.userContext?.schoolAccounts?.length === 1) {
                Storage.setItem("schoolAccountId", res.data?.userContext?.schoolAccounts[0].id);
                navigate(ROUTE_AUTH_CONFIRMED);
            } else {
                navigate(ROUTE_AUTH_POST_LOGIN);
            }
            navigate(0);
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("common.unknownError"), {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError) {
                onError(err);
            }
        },
    });
};
