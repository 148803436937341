import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { OrmSpecification } from "src/orm/models/Specification";
import { getSchoolAccountId } from "src/services/url";

export const SPECYFICATIONS_LIST_QUERY = "specificationsList";

export const useSpecificationsList = (qualificationId: number) => {
    return useQuery({
        queryKey: [SPECYFICATIONS_LIST_QUERY, qualificationId],
        queryFn: async (): Promise<OrmSpecification[]> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/common/qualifications/${qualificationId}/specifications/`,
            );
            return data || [];
        },
    });
};
