import { FC, useRef } from "react";
import clsx from "clsx";

interface OwnProps {
    value: string | null;
}

export const PinnedRowRenderer: FC<OwnProps> = ({ value }) => {
    const truncatedRef = useRef() as any;
    const truncateValue = value ? `${value}`.slice(0, 22) : "";

    return (
        <div
            title={value}
            className={clsx(
                "ag-value-cell-label ag-value-cell-label--pinned",
                truncateValue && truncateValue.length > 3 && "ag-value-cell-label--long-string",
            )}
        >
            <div ref={truncatedRef}>{truncateValue}</div>
        </div>
    );
};
