import { Typography, Box, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RHFTextField } from "src/components/hookFormComponents";

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: 700,
    },
}));

const ContactStep = ({ schoolTitles }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Box mb={2.5}>
                <Typography gutterBottom className={classes.bold}>
                    {t("auth.register.contactStep.header")}
                </Typography>
                <Typography>{t("auth.register.contactStep.message")}</Typography>
            </Box>
            <RHFTextField name="mobile" label={t("common.form.label.mobileOpt")} />
            <RHFTextField name="phone" label={t("common.form.label.phoneOpt")} />
            {schoolTitles && schoolTitles.length > 0 ? (
                <RHFTextField select name="schoolTitle" label={t("common.form.label.schoolTitle")}>
                    {schoolTitles.map(st => (
                        <MenuItem value={st} key={st}>
                            {st}
                        </MenuItem>
                    ))}
                </RHFTextField>
            ) : (
                <></>
            )}
        </Fragment>
    );
};

export default ContactStep;
