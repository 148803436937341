/* eslint-disable no-constant-condition */
import { Box } from "@mui/material";
import {
    CellKeyDownEvent,
    CellValueChangedEvent,
    ColumnApi,
    ColumnState,
    GridApi,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { TFunction } from "i18next";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "src/hooks/usePrevious";
import { AnalysisAtlValuesFlatObject } from "../../api/Atl/getValues";
import { useAnalysisAtlData } from "../../hooks/useAnalysisAtlData";
import { generateAtlAverageRow } from "../../services/analysisAverageRow";
import { analysisFrameworkComponentsList } from "../../services/analysisGridConfig";
import { navigateToNextCell } from "../../services/analysisGridNavigation";
import {
    calculateAnalysisHeaderHeight,
    ANALYSIS_INITIAL_STUDENT_PARAMS,
    generateAtlHeader,
} from "../../services/analysisHeaderGenerator";
import { getGridBorderColor } from "../../services/analysisColourHandler";
import DotsProgress from "src/components/DotsProgress";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import AnalysisToolsPanel from "./ToolsPanel/AnalysisToolsPanel";
import AnalysisFilterPanel from "./ToolsPanel/AnalysisFilterPanel";
import { filterRowFromFilters } from "src/modules/common/services/valueHandler";
import { useAnalysisAtlSnapshots } from "../../hooks/useAnalysisAtlSnapshots";
import { exportAnalysisAtlToExcel } from "../../services/analysisDataExport";

import useDebounce from "src/hooks/useDebounce";
import { CELL_SIZE_MIN } from "src/modules/tracker/services/headerGenerator";
import { sortByTwoParams } from "src/services/array";
import { useAnalysisTagList } from "src/modules/tagging/hooks/Analysis/useAnalysisTagList";
import { useAnalysisStudentTags } from "src/modules/tagging/hooks/Analysis/useAnalysisStudentTags";
import {
    AnalysisStudentTagRows,
    useAnalysisStudentTagMutation,
} from "src/modules/tagging/hooks/Analysis/useAnalysisStudentTagMutation";
import { TagsToSendType } from "src/modules/tracker/services/valueHandler";
import PromptDialog from "src/forms/PromptDialog";
import { sortTagsColumnState } from "src/modules/tracker/components/Grid/TrackerGrid";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useAnalysisUserSettingsStore } from "../../hooks/useAnalysisUserSettingsStore";

export const ATL_VIEW_TYPE = "atl";

export interface UpdateRowResponse {
    data: { [key: string]: any };
    errors: { [key: string]: any }[];
}

const AtlAnalysisGrid = ({
    cohort,
    yearGroup,
    dataType,
    filtersVisible,
    toolsVisible,
    handleIsLoading,
}: {
    cohort: number;
    yearGroup: number;
    dataType: DataTypeTypes;
    filtersVisible?: boolean;
    toolsVisible?: boolean;
    handleIsLoading?: (isLoading: boolean) => void;
}) => {
    const { t }: { t: TFunction } = useTranslation();
    const [values, setValues] = useState<AnalysisAtlValuesFlatObject[] | null>(null);
    const [header, setHeader] = useState<any[] | null>(null);
    const [filteredValues, setFilteredValues] = useState<any[] | undefined>(undefined);
    const [flatHeader, setFlatHeader] = useState<any[] | null>(null);
    const [averageRow, setAverageRow] = useState<AnalysisAtlValuesFlatObject | null>(null);
    const [isExcelExportMode, setIsExcelExportMode] = useState<boolean>(false);
    const [collapsedColumns, setCollapsedColumns] = useState<{ [key: string]: boolean }>({});
    const gridApi = useRef() as { current: GridApi };
    const columnApi = useRef() as { current: ColumnApi };
    const excelGridApi = useRef() as { current: GridApi };
    const [gridFilters, setGridFilters] = useState<any>(null);
    const [tagsToSend, setTagsToSend] = useState<TagsToSendType[]>([]);
    const debouncedTagsToSend = useDebounce(tagsToSend, 2000);
    const [allTagsWaiting, setAllTagsWaiting] = useState<{
        colId: string;
        value: any;
        type: "tag" | "link";
    } | null>(null);
    const [isReloading, setIsReloading] = useState<boolean>(false);
    const initialColumnOrder = useRef<ColumnState[]>();
    const {
        analysisAtlHeader,
        analysisAtlValues,
        analysisRelated,
        analysisAtlCompareValues,
        getAnalysisData,
        clearAnalysisData,
        isDataFetched,
        isLoading,
        analysisStudents,
        setIsLoading,
        refetchValues,
        getSnapshotCompareData,
        getSnapshotData,
    } = useAnalysisAtlData([]);
    const { snapshotData, setSnapshotData } = useAnalysisAtlSnapshots(cohort);

    const { setAnalysisUserSettings, ...analysisUserSettings } = useAnalysisUserSettingsStore();

    const { data: tags, isSuccess: isTagsSuccess } = useAnalysisTagList(cohort, yearGroup);
    const { data: studentTags, refetch: refetchStudentTags } = useAnalysisStudentTags(
        cohort,
        yearGroup,
    );

    useEffect(() => {
        if (studentTags) {
            gridApi?.current?.refreshCells({ columns: ["tagAvg", "tagAllSubjects"], force: true });
        }
    }, [studentTags]);

    const { mutate: saveTags } = useAnalysisStudentTagMutation(() => refetchStudentTags());

    const applayTagsOrder = tagsOrder => {
        if (tagsOrder) {
            const columnState: { colId: string }[] =
                initialColumnOrder.current || columnApi.current.getColumnState() || [];

            columnApi.current.applyColumnState({
                state: sortTagsColumnState(columnState, tagsOrder),
                applyOrder: true,
            });
        }
    };
    // on ready
    const onGridReady = params => {
        gridApi.current = params.api as GridApi;
        columnApi.current = params.columnApi as ColumnApi;
        initialColumnOrder.current = params.columnApi.getColumnState() || [];
        applayTagsOrder(analysisUserSettings?.tagsOrder);
    };
    const handleOnColumnMoved = params => {
        if (params.finished && params.column) {
            const columnState = params.columnApi.getColumnState();
            const tagsOrder = columnState
                .filter(({ colId }) => colId.indexOf("tag-") === 0)
                .map(({ colId }) => colId);
            setAnalysisUserSettings({ ...analysisUserSettings, tagsOrder });
            applayTagsOrder(tagsOrder);
        }
    };
    const handleOnColumnVisible = params => {
        initialColumnOrder.current = params.columnApi.getColumnState() || [];
    };

    // on second ready
    const onExcelGridReady = params => {
        excelGridApi.current = params.api;

        if (isExcelExportMode) {
            exportAnalysisAtlToExcel(excelGridApi.current);
            excelGridApi.current.destroy();
            setIsExcelExportMode(false);
        }
    };
    const prevDataType = usePrevious(dataType);

    const handleFiltersChange = filters => {
        setGridFilters(filters);
    };

    // handle expand groups
    const handleGroupOpen = (field: string, expanded: boolean) => {
        if (analysisUserSettings) {
            setCollapsedColumns(prevCollapsed => {
                // let newCollapsed = prevCollapsed;
                if (analysisUserSettings?.collapsedColumns) {
                    return {
                        ...analysisUserSettings.collapsedColumns,
                        ...prevCollapsed,
                        [field]: expanded,
                    };
                } else {
                    return {
                        ...prevCollapsed,
                        [field]: expanded,
                    };
                }
            });
        }
    };

    const handleTagsLinksAllColumnSelect = (colId, value) => {
        setAllTagsWaiting({ colId, value, type: "tag" });
    };

    const deboundedCollapsed = useDebounce(collapsedColumns, 500);

    useEffect(() => {
        // grid filters without "student_"
        const filteredNew = values?.filter(initialValues => {
            return filterRowFromFilters(gridFilters || {}, initialValues);
        });

        if (!filteredNew) {
            setFilteredValues([]);
        } else {
            setFilteredValues(filteredNew);
        }
    }, [gridFilters, values]);
    const { data: userProfile } = useProfile();

    // main context
    const analysisContext = {
        columnConfig: analysisAtlHeader?.columns,
        studentColumns: analysisStudents || ANALYSIS_INITIAL_STUDENT_PARAMS,
        relatedData: analysisRelated,
        userSettings: analysisUserSettings,
        userProfile,
        cohort,
        yearGroup,
        dataType,
        viewType: ATL_VIEW_TYPE,
        snapshotData,
        compareValues: analysisAtlCompareValues?.values2 || null,
        flatHeader,
        handleFiltersChange,
        handleExportToExcel: () => setIsExcelExportMode(true),
        handleGroupOpen,
        tags,
        handleTagsLinksAllColumnSelect,
    };

    // change single value handler
    const handleValueChange = (e: CellValueChangedEvent) => {
        const oldValue = e.oldValue === null ? "" : e.oldValue;
        const value = e.newValue;

        const colId = e.column.getColId();
        const newValue = e.newValue?.whileUpdate ? e.newValue?.value : e.newValue;
        if (oldValue !== newValue && e.data.row && colId) {
            if (colId.indexOf("tag-") === 0) {
                setTagsToSend(prevState =>
                    prevState.concat({ row: e.data.row, column: colId, value }),
                );
            }
        }
    };

    useEffect(() => {
        if (handleIsLoading) handleIsLoading(isLoading);
    }, [isLoading, handleIsLoading]);

    useEffect(() => {
        if (!isLoading) {
            setIsReloading(true);
            refetchValues(cohort, yearGroup, []);
        }
    }, []);

    // toggle grid filters & tools
    useEffect(() => {
        if (filtersVisible && gridApi) {
            gridApi.current?.openToolPanel("studentFilterPanel");
        }
        if (toolsVisible && gridApi) {
            gridApi.current?.openToolPanel("toolsPanel");
        }
        if (!toolsVisible && !filtersVisible && gridApi) {
            gridApi.current?.closeToolPanel();
        }
    }, [filtersVisible, toolsVisible, gridApi]);

    // collapse columns
    useEffect(() => {
        if (deboundedCollapsed && Object.keys(deboundedCollapsed).length > 0) {
            // const newUserSettings = { ...analysisUserSettings, collapsedColumns: undefined }; // RESET COLLAPSABLE
            const newUserSettings = { ...analysisUserSettings, collapsedColumns };
            setAnalysisUserSettings(newUserSettings);
        }
    }, [deboundedCollapsed]);

    // get snapshot data
    useEffect(() => {
        if (dataType === DataTypeTypes.SNAPSHOT) {
            setIsLoading(true);
            clearAnalysisData();
            setValues(null);
            if (snapshotData?.snapshot && getSnapshotData) {
                getSnapshotData(cohort, yearGroup, parseInt(`${snapshotData?.snapshot}`), []);
            }
            if (snapshotData?.compare && getSnapshotCompareData) {
                getSnapshotCompareData(
                    cohort,
                    yearGroup,
                    snapshotData.compare.dataSource1,
                    snapshotData.compare.dataSource2,
                );
            }
        }
    }, [snapshotData, dataType, yearGroup, cohort]);

    // get data + user settings on change type, cohort etc
    useEffect(() => {
        if (dataType !== DataTypeTypes.SNAPSHOT && cohort && yearGroup > -1) {
            clearAnalysisData();
            setIsLoading(true);
            setHeader(null);
            setValues(null);
            getAnalysisData(cohort, yearGroup);
        }
    }, [cohort, yearGroup, dataType]);
    // clear when new data type
    useEffect(() => {
        if (prevDataType && prevDataType !== dataType) {
            if (dataType !== DataTypeTypes.SNAPSHOT) {
                clearAnalysisData();
                setValues(null);
                setHeader(null);
                setFlatHeader(null);
                setAverageRow(null);
                setIsLoading(true);
                setSnapshotData(null);
                getAnalysisData(cohort, yearGroup);
            }
        }
        if (gridApi?.current) gridApi.current.redrawRows();
    }, [dataType, gridApi?.current]);

    // remove loader on snapshot change
    useEffect(() => {
        if (isLoading && dataType === DataTypeTypes.SNAPSHOT) {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }, [analysisAtlValues]);

    const prevTags = usePrevious(tags);
    // set data when fetched
    useEffect(() => {
        if (!isLoading && isDataFetched && isTagsSuccess) {
            //generate header
            if (
                analysisAtlHeader &&
                (!header || JSON.stringify(prevTags) !== JSON.stringify(tags))
            ) {
                const flatHeader = [];
                setHeader(
                    generateAtlHeader(
                        analysisAtlHeader,
                        t,
                        flatHeader,
                        dataType,
                        analysisUserSettings,
                        tags,
                    ),
                );
                if (flatHeader && flatHeader.length > 0) {
                    setFlatHeader(flatHeader);
                }
            }
            //generate values
            let tmpValues = [] as AnalysisAtlValuesFlatObject[];
            const typedValues =
                dataType === DataTypeTypes.SNAPSHOT && snapshotData?.compare
                    ? analysisAtlCompareValues?.values1
                    : analysisAtlValues;

            if (typedValues) {
                Object.keys(typedValues).forEach(key => {
                    if (typedValues) tmpValues.push({ ...typedValues[key], row: key });
                });

                tmpValues = sortByTwoParams(
                    [...tmpValues],
                    "student_lastName",
                    "student_firstName",
                );

                setValues(tmpValues);
                setFilteredValues(tmpValues);
            }

            //generate average
            setAverageRow(generateAtlAverageRow(tmpValues, analysisAtlHeader, t));
        }
    }, [
        isLoading,
        isReloading,
        analysisAtlHeader,
        analysisAtlValues,
        analysisAtlCompareValues,
        dataType,
        isTagsSuccess,
        tags,
    ]);

    // sort values
    useEffect(() => {
        let tmpValues = [] as AnalysisAtlValuesFlatObject[];
        const typedValues =
            dataType === DataTypeTypes.SNAPSHOT && snapshotData?.compare
                ? analysisAtlCompareValues?.values1
                : analysisAtlValues;
        if (typedValues && Object.keys(typedValues).length > 0) {
            Object.keys(typedValues).forEach(key => {
                if (typedValues) tmpValues.push({ ...typedValues[key], row: key });
            });
            tmpValues = sortByTwoParams([...tmpValues], "student_lastName", "student_firstName");
        }

        if (tags && tags.length > 0) {
            Object.keys(tmpValues).forEach(ctvKey => {
                tags.forEach(tag => {
                    tmpValues[ctvKey]["tag-" + tag.id] = 0;
                });
            });
        }

        if (studentTags && Object.keys(studentTags).length > 0) {
            Object.keys(studentTags).forEach(stKey => {
                studentTags[stKey].forEach(tagId => {
                    const studentIndex = tmpValues.findIndex(tv => tv.row === stKey);
                    if (
                        tmpValues[studentIndex] !== undefined &&
                        tmpValues[studentIndex]["tag-" + tagId] !== undefined
                    ) {
                        tmpValues[studentIndex]["tag-" + tagId] = 1;
                    }
                });
            });
        }

        //totu
        setValues(tmpValues);
        setFilteredValues(tmpValues);
        setIsReloading(false);
    }, [analysisAtlValues, analysisAtlCompareValues, dataType, tags, studentTags]);

    useEffect(() => {
        if (debouncedTagsToSend?.length > 0) {
            const rows: AnalysisStudentTagRows = {};
            debouncedTagsToSend.forEach((ddts: TagsToSendType) => {
                rows[ddts.row] = [
                    ...(rows[ddts.row] || []).concat({
                        tagId: parseInt(ddts.column.replace("tag-", "")),
                        enabled: ddts.value.value !== undefined ? !!ddts.value.value : !!ddts.value,
                    }),
                ];
            });

            saveTags({ cohort, yearGroup, rows });
        }
    }, [debouncedTagsToSend]);

    useEffect(() => {
        if (filteredValues && analysisAtlHeader) {
            setAverageRow(generateAtlAverageRow(filteredValues, analysisAtlHeader, t));
        }
    }, [filteredValues, analysisAtlHeader]);

    useEffect(() => {
        if (header) {
            if (analysisUserSettings?.tagsVisible !== null && analysisUserSettings?.selectedTags) {
                columnApi?.current?.setColumnsVisible(
                    analysisUserSettings?.selectedTags?.map(id => `tag-${id}`),
                    analysisUserSettings?.tagsVisible,
                );
                gridApi?.current?.refreshHeader();
            }
        }
    }, [
        header,
        analysisUserSettings?.tagsVisible,
        analysisUserSettings?.selectedTags,
        gridApi?.current,
        columnApi?.current,
        isTagsSuccess,
    ]);

    const getRowId = useCallback(params => params.data.student_id, []);

    const components = useMemo(() => ({ ...analysisFrameworkComponentsList }), []);

    const sideBar = useMemo(
        () => ({
            toolPanels: [
                {
                    id: "studentFilterPanel",
                    labelKey: "studentFilterPanel",
                    labelDefault: "",
                    iconKey: "student-filter-panel",
                    toolPanel: AnalysisFilterPanel,
                },
                {
                    id: "toolsPanel",
                    labelKey: "toolsPanel",
                    labelDefault: "",
                    iconKey: "tools-panel",
                    toolPanel: AnalysisToolsPanel,
                },
            ],
        }),
        [],
    );

    return (
        <>
            {header &&
            values &&
            isDataFetched &&
            averageRow &&
            isLoading === false &&
            !isReloading ? (
                <Box
                    className="ag-theme-alpine ag-theme-alpine-analysis"
                    flex={1}
                    width="100%"
                    position="relative"
                    borderLeft={`10px solid ${getGridBorderColor(dataType)}`}
                >
                    <AgGridReact
                        columnDefs={header}
                        rowData={filteredValues}
                        onGridReady={onGridReady}
                        onColumnVisible={handleOnColumnVisible}
                        onColumnMoved={handleOnColumnMoved}
                        components={components}
                        sideBar={sideBar}
                        enableRangeSelection={true}
                        processCellForClipboard={params => {
                            if (params.value === -1) return "U";
                            return params.value;
                        }}
                        context={analysisContext}
                        getRowId={getRowId}
                        rowBuffer={15}
                        pinnedTopRowData={[averageRow]}
                        enableBrowserTooltips={true}
                        gridOptions={{
                            headerHeight: calculateAnalysisHeaderHeight(analysisAtlHeader),
                            groupHeaderHeight: 0,
                            onCellKeyDown: (event: CellKeyDownEvent) => {
                                navigateToNextCell(event, gridApi.current, columnApi.current);
                            },
                            animateRows: false,
                            suppressColumnMoveAnimation: true,
                            suppressAutoSize: true,
                            onCellValueChanged: handleValueChange,
                            suppressContextMenu: true,
                            autoSizePadding: 1,
                            rowHeight:
                                snapshotData?.compare &&
                                snapshotData?.compare.dataSource1 !==
                                    snapshotData?.compare.dataSource2
                                    ? 56
                                    : CELL_SIZE_MIN,
                        }}
                    />
                    {isExcelExportMode && averageRow && filteredValues && (
                        <AgGridReact
                            onGridReady={onExcelGridReady}
                            columnDefs={header}
                            context={analysisContext}
                            rowData={[averageRow].concat(filteredValues)}
                            gridOptions={{ domLayout: "print" }}
                            containerStyle={{ display: "none" }}
                        />
                    )}
                </Box>
            ) : (
                <Box
                    bgcolor="#f9f9f9"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flex={1}
                    top={80}
                    width="100%"
                    height="100%"
                    position="absolute"
                    zIndex={500}
                >
                    <DotsProgress />
                </Box>
            )}{" "}
            <PromptDialog
                open={!!allTagsWaiting?.colId}
                onClose={confirmed => {
                    if (confirmed) {
                        const newValues = [...filteredValues].map(fv => {
                            setTagsToSend(prevState =>
                                prevState.concat({
                                    row: fv.row,
                                    column: allTagsWaiting?.colId,
                                    value: allTagsWaiting?.value,
                                }),
                            );
                            return {
                                ...fv,
                                [allTagsWaiting?.colId]: {
                                    value: allTagsWaiting?.value,
                                    whileUpdate: true,
                                },
                            };
                        });
                        setFilteredValues(newValues);
                    }

                    setAllTagsWaiting(null);
                }}
            >
                {t("tracker.grid.allTagsWaiting")}
            </PromptDialog>
        </>
    );
};

export default AtlAnalysisGrid;
