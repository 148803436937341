import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { useArchivedTrackerRelatedData } from "./useArchivedTrackerRelatedData";
import { useArchivedTrackerValues } from "./useArchivedTrackerValues";
import { useArchivedTrackerHeader } from "./useArchivedTrackerHeader";
import { useClassDetails } from "src/modules/class/hooks/useClassDetails";
import { useMount } from "src/hooks/useMount";
import { ClassTrackerModel } from "src/modules/tracker/models/ClassTrackerModel";
import { ClassTrackerHeaderObject } from "src/modules/tracker/dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "src/modules/tracker/dto/TrackerRelated";
import { ClassTrackerValuesObject } from "src/modules/tracker/dto/TrackerValues";

export interface ArchivedTrackerData {
    refetchRelatedData: () => void;
    relatedData: ClassTrackerRelatedObject;
    isLoadingRelatedData: boolean;
    isSuccessRelatedData: boolean;
    refetchValues: () => void;
    values: ClassTrackerValuesObject;
    isLoadingValues: boolean;
    isSuccessValues: boolean;
    refetchHeader: () => void;
    header: ClassTrackerHeaderObject;
    isLoadingHeader: boolean;
    isSuccessHeader: boolean;
    classDetails: ClassTrackerModel;
    isLoadingClassDetails: boolean;
    isSuccessClassDetails: boolean;
    isRefetchingTracker: boolean;
    isLoadingTracker: boolean;
    isSuccessTracker: boolean;
}

export const useArchivedTrackerData = (id: number, tier: TiersTypes) => {
    const {
        refetch: refetchRelatedData,
        data: relatedData,
        isLoading: isLoadingRelatedData,
        isSuccess: isSuccessRelatedData,
        isRefetching: isRefetchingRelatedData,
    } = useArchivedTrackerRelatedData(id);
    const {
        refetch: refetchValues,
        data: values,
        isLoading: isLoadingValues,
        isSuccess: isSuccessValues,
        isRefetching: isRefetchingValues,
    } = useArchivedTrackerValues(id, tier as TiersTypes);
    const {
        refetch: refetchHeader,
        data: header,
        isLoading: isLoadingHeader,
        isSuccess: isSuccessHeader,
        isRefetching: isRefetchingHeader,
    } = useArchivedTrackerHeader(id, tier as TiersTypes);
    const {
        data: classDetails,
        isLoading: isLoadingClassDetails,
        isSuccess: isSuccessClassDetails,
        isRefetching: isRefetchingDetails,
    } = useClassDetails(id);

    useMount(() => {
        refetchHeader();
        refetchRelatedData();
        refetchValues();
    });
    const isRefetchingTracker =
        isRefetchingDetails || isRefetchingHeader || isRefetchingRelatedData || isRefetchingValues;

    const isLoadingTracker =
        isLoadingClassDetails ||
        isLoadingHeader ||
        isLoadingRelatedData ||
        isLoadingValues ||
        isRefetchingTracker;

    const isSuccessTracker =
        isSuccessClassDetails || isSuccessHeader || isSuccessRelatedData || isSuccessValues;

    return {
        refetchRelatedData,
        relatedData,
        isLoadingRelatedData,
        isSuccessRelatedData,
        isRefetchingRelatedData,
        refetchValues,
        values,
        isLoadingValues,
        isSuccessValues,
        isRefetchingValues,
        refetchHeader,
        header,
        isLoadingHeader,
        isSuccessHeader,
        isRefetchingHeader,
        classDetails,
        isLoadingClassDetails,
        isSuccessClassDetails,
        isRefetchingDetails,
        isRefetchingTracker,
        isLoadingTracker,
        isSuccessTracker,
    };
};
