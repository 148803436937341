import { ListObject } from "src/forms/types";
import clsx from "clsx";
import Icon from "src/components/Icon";
import { mdiChevronDown } from "@mdi/js";
import { ColDef, GridApi } from "ag-grid-community";
import PinnedTopRenderer from "./PinnedTopRenderer";
import { FC } from "react";

interface OwnProps {
    value: number | string;
    rowIndex: number;
    classTrackers: ListObject<number>[];
    context: any;
    api: GridApi;
    node: any;
    data: any;
    colDef: ColDef;
}

export const MoveTiersRenderer: FC<OwnProps> = ({
    value,
    rowIndex,
    context,
    api: gridApi,
    colDef,
    data,
    node,
}) => {
    const { field } = colDef;

    if (node.rowPinned) {
        return (
            <PinnedTopRenderer
                field={field}
                value={field && data ? data[field] : ""}
                context={context}
            />
        );
    }

    const { moveStudentsBulkEditMode } = context;

    if (moveStudentsBulkEditMode) {
        return (
            <div
                onDoubleClick={() => {
                    gridApi.startEditingCell({ rowIndex: rowIndex, colKey: "moveStudentToTier" });
                }}
                className="ag-student-data-cell ag-student-data-cell--border-left"
            >
                {value || ""}
            </div>
        );
    }

    return rowIndex >= 0 ? (
        <div className={clsx("ag-student-data-cell ag-student-data-cell--border-left")}>
            {value ? <strong>{value}</strong> : "Please select"}
            <div className="ag-student-data-cell__icon">
                <Icon path={mdiChevronDown} size="16px" />
            </div>
        </div>
    ) : null;
};
