import { Modules } from "src/types";

export const SCHOOL_PREFIX = `/school/:schoolAccountId`;

export const ROUTE_HOME_MAINTENANCE = `/${Modules.MAINTENANCE}`;

export const ROUTE_NOT_FOUND = `/404`;

/* AUTH MODULE */
export const ROUTE_AUTH_BASE = `/${Modules.AUTH}`;
export const ROUTE_AUTH_RESET_PASSWORD = `/${Modules.AUTH}/reset-password`;
export const ROUTE_AUTH_REGISTER = `/${Modules.AUTH}/register`;
export const ROUTE_AUTH_CONFIRM = `/${Modules.AUTH}/external/confirm/:hash`;
export const ROUTE_AUTH_INVITATION = `/${Modules.AUTH}/external/invite/:hash`;
export const ROUTE_AUTH_NEW_PASSWORD = `/${Modules.AUTH}/external/reset/:hash`;
export const ROUTE_AUTH_LOGIN = `/${Modules.AUTH}/login`;
export const ROUTE_AUTH_LOGIN_AS = `/${Modules.AUTH}/login-as-school-user/:token`;
export const ROUTE_AUTH_CONFIRMED = `/${Modules.AUTH}/confirmed`;
export const ROUTE_AUTH_INVITED = `/${Modules.AUTH}/invited`;
export const ROUTE_AUTH_POST_LOGIN = `/${Modules.AUTH}/post-login`;
export const ROUTE_MERGE_ACCOUNT = `/${Modules.AUTH}/external/merge-account/:hash`;
export const ROUTE_LOGOUT = `/${Modules.AUTH}/logout`;

/* PARENT APP PROXY */
export const ROUTE_PARENT_APP_PROXY = `/${Modules.AUTH}/pupil-progress-app-proxy/:action/:userId/:hash`;
export const ROUTE_PARENT_APP_PROXY_REG = `/${Modules.AUTH}/pupil-progress-app-proxy/:action/:hash`;

/* TRUST ROUTES */
export const ROUTE_TRUST_DASHBOARD = `/mat/:trust/dashboard`;
export const ROUTE_TRUST_USERS = `/mat/:trust/users`;
export const ROUTE_TRUST_USER_DETAILS = `/mat/:trust/users/:id`;

/* CLASS MODULE */
export const ROUTE_CLASS_CLASS_LIST = SCHOOL_PREFIX + `/${Modules.CLASS}`;
export const ROUTE_CLASS_CLASS_LIST_COHORT = SCHOOL_PREFIX + `/${Modules.CLASS}/cohort/:cohort`;

/* ADD CLASSES */
export const ROUTE_CLASS_ADD_MANUAL_XLS = SCHOOL_PREFIX + `/${Modules.CLASS}/manual-xls`;
export const ROUTE_CLASS_ADD_MANUAL_EXISTING_STUDENTS =
    SCHOOL_PREFIX + `/${Modules.CLASS}/manual-existing-students`;
export const ROUTE_CLASS_ADD_CLASS_SYNCHRONIZE = SCHOOL_PREFIX + `/${Modules.CLASS}/synchronise`;
export const ROUTE_CLASS_ADD_CLASS_UNSYNCHRONIZE =
    SCHOOL_PREFIX + `/${Modules.CLASS}/unsynchronised`;
export const ROUTE_CLASS_ADD_CLASS_AUTOSUGGEST = SCHOOL_PREFIX + `/${Modules.CLASS}/autosuggest`;
export const ROUTE_CLASS_ADD_OTHER_UPLOAD = SCHOOL_PREFIX + `/${Modules.CLASS}/manual-upload`;
export const ROUTE_CLASS_ADD_OTHER_DEMO = SCHOOL_PREFIX + `/${Modules.CLASS}/other-demo`;

/* EDIT CLASSES */
export const ROUTE_CLASS_BULK_EDIT =
    SCHOOL_PREFIX +
    `/${Modules.CLASS}/specification/:specification/group/:classTrackerGroup/bulk-edit`;
export const ROUTE_CLASS_GROUP_CALL_CONVERT =
    SCHOOL_PREFIX + `/${Modules.CLASS}/specification/:specification/convert`;
export const ROUTE_CLASS_EDIT_CLASS = SCHOOL_PREFIX + `/${Modules.CLASS}/:class/edit`;
export const ROUTE_CLASS_PAST_PAPER_EDIT =
    SCHOOL_PREFIX + `/${Modules.CLASS}/cohort/:cohort/past-paper/:pastPaper/edit`;
export const ROUTE_CLASS_END_OF_TOPIC_TEST_EDIT =
    SCHOOL_PREFIX + `/${Modules.CLASS}/cohort/:cohort/end-of-topic-test/:endOfTopicTest/edit`;

/* ASSESSMENTS */
export const ROUTE_CLASS_ASSESSMENT_LIST =
    SCHOOL_PREFIX + `/${Modules.CLASS}/cohort/:cohort/assessment/list`;
export const ROUTE_CLASS_ASSESSMENT_CREATE =
    SCHOOL_PREFIX + `/${Modules.CLASS}/cohort/:cohort/assessment/create`;

/* MIGRATIONS */
export const ROUTE_CLASS_MIGRATE = SCHOOL_PREFIX + `/${Modules.CLASS}/cohort/:cohort/migrate`;
export const ROUTE_CLASS_MIGRATE_SYNC =
    SCHOOL_PREFIX + `/${Modules.CLASS}/cohort/:cohort/migrate-sync`;

/* SCHOOL MODULE */
export const ROUTE_MY_SCHOOL = SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school`;
export const ROUTE_MY_SCHOOL_SCHOOL_DETAILS =
    SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/school-details/`;
export const ROUTE_MY_SCHOOL_STUDENTS = SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/students/`;
export const ROUTE_MY_SCHOOL_PARENTS = SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/parents/`;
export const ROUTE_MY_SCHOOL_PARENTS_EXPORT = `${ROUTE_MY_SCHOOL_PARENTS}download/:hash/`;
export const ROUTE_MY_SCHOOL_STAFF = SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/staff/`;
export const ROUTE_MY_SCHOOL_SNAPSHOTS = SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/snapshots/`;
export const ROUTE_MY_SCHOOL_ATL =
    SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/learning-characteristics/`;
export const ROUTE_MY_SCHOOL_STAFF_ADD = SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/staff/add`;
export const ROUTE_MY_SCHOOL_STAFF_EDIT =
    SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/staff/:staff/edit`;
export const ROUTE_MY_SCHOOL_STUDENT_ADD =
    SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/student/add`;
export const ROUTE_MY_SCHOOL_STUDENT_EDIT =
    SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/student/:student/edit`;
export const ROUTE_MY_SCHOOL_PARENT_ADD = SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/parent/add`;
export const ROUTE_MY_SCHOOL_PARENT_EDIT =
    SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/parent/:parent/edit`;
export const ROUTE_MY_SCHOOL_TAGS = SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/tags/`;
export const ROUTE_MY_SCHOOL_TAG_ADD = SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/tag/add`;
export const ROUTE_MY_SCHOOL_TAG_EDIT =
    SCHOOL_PREFIX + `/${Modules.SCHOOL}/my-school/tag/:tag/edit`;

/* REPORT MODULE */
export const ROUTE_REPORT_TEMPLATE_LIST = SCHOOL_PREFIX + `/${Modules.REPORT}/report-template/list`;
export const ROUTE_REPORT_TEMPLATE_ADD = SCHOOL_PREFIX + `/${Modules.REPORT}/report-template/add`;
export const ROUTE_REPORT_TEMPLATE_EDIT =
    SCHOOL_PREFIX + `/${Modules.REPORT}/report-template/:id/edit`;
export const ROUTE_REPORT_DOWNLOAD = SCHOOL_PREFIX + `/${Modules.REPORT}/download/:hash`;
export const ROUTE_REPORT_STUDENT_REPORTS = SCHOOL_PREFIX + `/${Modules.REPORT}/student-report`;
export const ROUTE_REPORT_STUDENT_REPORTS_COHORT =
    SCHOOL_PREFIX + `/${Modules.REPORT}/cohort/:cohort/year-group/:yearGroup/student-report`;
export const ROUTE_REPORT_STUDENT_REPORTS_TAB =
    SCHOOL_PREFIX + `/${Modules.REPORT}/cohort/:cohort/year-group/:yearGroup/student-report/:tab`;
export const ROUTE_REPORT_SNAPSHOT_LIST = SCHOOL_PREFIX + `/${Modules.REPORT}/snapshot-report/list`;
export const ROUTE_REPORT_SNAPSHOT_CREATE =
    SCHOOL_PREFIX + `/${Modules.REPORT}/snapshot-report/create`;
export const ROUTE_REPORT_LINKS_LIST = SCHOOL_PREFIX + `/${Modules.REPORT}/links/list`;
export const ROUTE_REPORT_LINKS_ADD = SCHOOL_PREFIX + `/${Modules.REPORT}/links/add`;
export const ROUTE_REPORT_LINKS_EDIT = SCHOOL_PREFIX + `/${Modules.REPORT}/links/:id/edit`;

/* SUBSCRIPTION MODULE */
export const ROUTE_SUBSCRIPTION_LIST = SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}`;
export const ROUTE_SUBSCRIPTION_LIST_TAB = SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}/tab/:tab`;
export const ROUTE_SUBSCRIPTION_LIST_INVOICE_FILTER =
    SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}/tab/:tab/:filter`;
export const ROUTE_REQUEST_NEW_TRIAL = SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}/new-trial`;
export const ROUTE_UPGRADE_TO_WHOLE_SCHOOL =
    SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}/upgrade-to-whole-school`;
export const ROUTE_BUY_AFTER_TRIAL_SUBSCRIPTION =
    SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}/:id/buy-after-trial`;
export const ROUTE_UPGRADE_SUBSCRIPTION = SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}/:id/upgrade`;
export const ROUTE_EXTEND_SUBSCRIPTION = SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}/:id/extend`;
export const ROUTE_BUY_SUBSCRIPTION = SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}/buy`;
export const ROUTE_MAKE_PAYMENT = SCHOOL_PREFIX + `/${Modules.SUBSCRIPTION}/:id/make-payment`;

/* TRACKER MODULE */
export const ROUTE_TRACKER_CLASS_TRACKER =
    SCHOOL_PREFIX +
    `/${Modules.TRACKER}/:subject/:qualification/:specification/:cohort/:yearGroup/class-tracker/:classTracker/tier/:tier`;
export const ROUTE_TRACKER_CLASS_TRACKER_MOVE_STUDENTS =
    SCHOOL_PREFIX +
    `/${Modules.TRACKER}/:subject/:qualification/:specification/:cohort/:yearGroup/class-tracker/:classTracker/tier/:tier/moveStudents/:moveStudents`;
export const ROUTE_TRACKER_CLASS_TRACKER_BULK_EDIT =
    SCHOOL_PREFIX +
    `/${Modules.TRACKER}/:subject/:qualification/:specification/:cohort/:yearGroup/class-tracker/:classTracker/tier/:tier/bulkEdit/:bulkEdit`;
export const ROUTE_TRACKER_CLASS_TRACKER_GROUP =
    SCHOOL_PREFIX +
    `/${Modules.TRACKER}/:subject/:qualification/:specification/:cohort/:yearGroup/class-tracker-group/:classTrackerGroup/tier/:tier/`;
export const ROUTE_TRACKER_CLASS_TRACKER_GROUP_MOVE_STUDENTS =
    SCHOOL_PREFIX +
    `/${Modules.TRACKER}/:subject/:qualification/:specification/:cohort/:yearGroup/class-tracker-group/:classTrackerGroup/tier/:tier/moveStudents/:moveStudents`;
export const ROUTE_TRACKER_CLASS_TRACKER_GROUP_BULK_EDIT =
    SCHOOL_PREFIX +
    `/${Modules.TRACKER}/:subject/:qualification/:specification/:cohort/:yearGroup/class-tracker-group/:classTrackerGroup/tier/:tier/bulkEdit/:bulkEdit`;

/* ARCHIVE TRACKER */
export const ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER =
    SCHOOL_PREFIX +
    `/${Modules.TRACKER}/cohort/:cohort/archive-class-tracker/:classTracker/tier/:tier`;
export const ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER_GROUP =
    SCHOOL_PREFIX +
    `/${Modules.TRACKER}/cohort/:cohort/archive-class-tracker-group/:classTrackerGroup/tier/:tier/`;

/* USER MODULE */
export const ROUTE_USER_ACCOUNT = SCHOOL_PREFIX + `/${Modules.USER}/account`;

/* ANALYSIS MODULE */
export const ROUTE_ANALYSIS = SCHOOL_PREFIX + `/${Modules.ANALYSIS}`;
export const ROUTE_ANALYSIS_TAB =
    SCHOOL_PREFIX + `/${Modules.ANALYSIS}/cohort/:cohort/year-group/:yearGroup/:tab`;
export const ROUTE_ANALYSIS_STUDENTS_GRADE_SUMMARY =
    SCHOOL_PREFIX +
    `/${Modules.ANALYSIS}/cohort/:cohort/year-group/:yearGroup/students-grade-summary`;
export const ROUTE_ANALYSIS_KS4_GRADE_ANALYSIS =
    SCHOOL_PREFIX + `/${Modules.ANALYSIS}/cohort/:cohort/year-group/:yearGroup/ks4-grade-analysis`;
export const ROUTE_ANALYSIS_SUBJECT_ANALYSIS =
    SCHOOL_PREFIX + `/${Modules.ANALYSIS}/cohort/:cohort/year-group/:yearGroup/subject-analysis`;
export const ROUTE_ANALYSIS_ATL_ANALYSIS =
    SCHOOL_PREFIX + `/${Modules.ANALYSIS}/cohort/:cohort/year-group/:yearGroup/atl-analysis`;
