import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    Select,
    Typography,
    FormControl,
    InputLabel,
    RadioGroup,
} from "@mui/material";
import { ApiData, ApiStatus } from "src/api/constants";
import { shouldLoadData } from "src/api/helpers";
import { apiUrl, HTTP_ERROR_CONFLICT, HTTP_NOT_FOUND } from "src/config/globals";
import { usePrevious } from "src/hooks/usePrevious";
import { subjectAreaSelector } from "src/modules/common/selectors/SubjectAreaSelectors";
import { CommonActions } from "src/modules/common/store/actions";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { ROUTE_SUBSCRIPTION_LIST } from "src/routes";
import { get } from "src/services/ajax";
import { filterNotNullable } from "src/services/object";
import { AppState } from "src/store/reducers";
import { useSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import SummaryBox from "../components/SummaryBox";
import { SubscriptionsActions } from "../store/actions";
import AppContainer from "src/components/AppContainer";
import HeadingCounter from "src/components/HeadingCounter";
import COLORS from "src/styles/colors";
import PaperInner from "src/components/PaperInner";
import { useMount } from "src/hooks/useMount";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { Radio } from "@mui/material";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useQualificationsList } from "src/modules/tagging/components/subjectRows/hooks/useQualificationsList";
import { getSchoolAccountId } from "src/services/url";

const dispatchActions = (dispatch: Dispatch) => ({
    getSubjectAreas: () => {
        dispatch(CommonActions.getSubjectAreaList());
    },
    createTrial: (subjectAreaId: number) => {
        dispatch(SubscriptionsActions.createTrialSubscription(subjectAreaId));
    },
    createNahtSupported: (subjectAreaId: number) => {
        dispatch(SubscriptionsActions.createNahtSupportedSubscription(subjectAreaId));
    },
});

export enum SubscriptionTypes {
    TRIAL = "trial",
    NAHT = "naht",
}

const TrialSubscription = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { navigate } = useSchoolNavigate();
    const [trialSubjectAreas, setTrialSubjectAreas] = useState<number[]>([]);
    const [selectedSubjectArea, setSelectedSubjectArea] = useState<number>(0);
    const { data: accountInfo } = useAccountInfo();
    const [subscriptionType, setSubscriptionType] = useState<string>(SubscriptionTypes.TRIAL);

    const { getSubjectAreas, createTrial, createNahtSupported } = dispatchActions(dispatch);
    const { data: qualifications } = useQualificationsList(selectedSubjectArea);
    const {
        apiList,
        apiCreate,
        apiCreateNaht,
        subjectAreas,
    }: {
        apiList: ApiData;
        apiCreate: ApiData;
        apiCreateNaht: ApiData;
        subjectAreas: OrmSubjectArea[];
    } = useSelector((state: AppState) => ({
        apiList: state.api.common.getSubjectAreaList,
        apiCreate: state.api.subscription.createTrialSubscription,
        apiCreateNaht: state.api.subscription.createNahtSupportedSubscription,
        subjectAreas: subjectAreaSelector(state),
    }));
    const prevApiStatus = usePrevious(apiCreate.status);
    const prevApiNahtStatus = usePrevious(apiCreateNaht.status);

    let filteredSubjectAreas: OrmSubjectArea[] = [];
    const subjectArea = subjectAreas.find(sa => sa.id === selectedSubjectArea);

    const handleChangeSubjectArea: any = e => {
        const subjectAreaId = parseInt(e.target.value);
        setSelectedSubjectArea(subjectAreaId);
    };

    const handleStartTrial = () => createTrial(selectedSubjectArea);

    const handleCreateNahtSupported = () => createNahtSupported(selectedSubjectArea);

    useMount(() => {
        const getTrialSa = () => {
            get(
                apiUrl(`school/${getSchoolAccountId()}/subject-area-for-trial`),
                undefined,
                undefined,
            ).subscribe(res => {
                if (res.response.length > 0) setTrialSubjectAreas(res.response);
            });
        };
        getTrialSa();
    });

    if (subjectAreas && trialSubjectAreas) {
        filteredSubjectAreas = subjectAreas.filter(
            (fsa: OrmSubjectArea) => fsa.id && trialSubjectAreas.includes(fsa.id),
        );
    }

    const handleError = useCallback(
        (apiData: ApiData) => {
            if (apiData && apiData.error) {
                switch (apiData.error.status) {
                    case HTTP_ERROR_CONFLICT:
                        enqueueSnackbar(t("subscription.trial.trialFailedConflict"), {
                            ...SnackbarErrorOptions,
                        });
                        break;
                    case HTTP_NOT_FOUND:
                        enqueueSnackbar(t("subscription.trial.trialFailedNotFound"), {
                            ...SnackbarErrorOptions,
                        });
                        break;
                    default:
                        enqueueSnackbar(t("subscription.trial.trialFailed"), {
                            ...SnackbarErrorOptions,
                        });
                }
            }
        },
        [enqueueSnackbar, t],
    );

    useEffect(() => {
        if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.SUCCESS) {
            navigate(ROUTE_SUBSCRIPTION_LIST);
            enqueueSnackbar(t("subscription.trial.trialAdded"), { variant: "success" });
        } else if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.ERROR) {
            handleError(apiCreate);
        }
    }, [apiCreate, enqueueSnackbar, handleError, prevApiStatus, t]);

    useEffect(() => {
        if (prevApiNahtStatus === ApiStatus.LOADING && apiCreateNaht.status === ApiStatus.SUCCESS) {
            navigate(ROUTE_SUBSCRIPTION_LIST);
            enqueueSnackbar(t("subscription.trial.nahtSupportedAdded"), { variant: "success" });
        } else if (
            prevApiNahtStatus === ApiStatus.LOADING &&
            apiCreateNaht.status === ApiStatus.ERROR
        ) {
            handleError(apiCreateNaht);
        }
    }, [apiCreateNaht, apiCreateNaht.status, enqueueSnackbar, handleError, prevApiNahtStatus, t]);

    useEffect(() => {
        if (shouldLoadData(apiList)) getSubjectAreas();
    }, [apiList]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("subscription.trial.startNewSubscription")}
            </Typography>
            <Paper>
                <PaperInner>
                    <Grid container>
                        <Grid item sm={4}>
                            <HeadingCounter number={1}>
                                {t("subscription.add.chooseSubject")}
                            </HeadingCounter>
                            <Box mb={4}>
                                <FormControl>
                                    <InputLabel id="subjectAreaLabel">
                                        {t("subscription.add.subjectArea")}
                                    </InputLabel>
                                    <Select
                                        id="subjectAreaLabel"
                                        name="subjectArea"
                                        fullWidth
                                        value={selectedSubjectArea}
                                        onChange={handleChangeSubjectArea}
                                    >
                                        <MenuItem key={0} value={0}>
                                            {t("common.noneSelect")}
                                        </MenuItem>
                                        {filterNotNullable(filteredSubjectAreas).map(obj => (
                                            <MenuItem key={obj.id} value={obj.id}>
                                                {obj.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box mb={4}>
                                <HeadingCounter number={2}>
                                    {t("subscription.add.subscriptionType")}
                                </HeadingCounter>

                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="subscriptionType"
                                        name="subscriptionType"
                                        value={subscriptionType}
                                        onChange={e => {
                                            setSubscriptionType(e.target.value);
                                        }}
                                    >
                                        <FormControlLabel
                                            value={SubscriptionTypes.TRIAL}
                                            control={<Radio />}
                                            label={
                                                <Box>
                                                    <strong>{t("subscription.trial.free")}</strong>{" "}
                                                    {t("subscription.add.subscriptionTypeTrial")}
                                                </Box>
                                            }
                                        />
                                        {accountInfo?.canStartNahtSupportedSubscription && (
                                            <>
                                                <FormControlLabel
                                                    value={SubscriptionTypes.NAHT}
                                                    control={<Radio />}
                                                    label={
                                                        <Box>
                                                            {t(
                                                                "subscription.add.subscriptionTypeNaht",
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t(
                                                            "subscription.add.subscriptionTypeNahtHint",
                                                        ),
                                                    }}
                                                ></div>
                                            </>
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Box>

                            {qualifications?.length > 0 && (
                                <>
                                    <HeadingCounter number={3}>
                                        {t("subscription.add.chooseCourses")}
                                    </HeadingCounter>
                                    <Box mb={4}>
                                        {filterNotNullable(qualifications).map(qual => (
                                            <FormControlLabel
                                                key={qual.id}
                                                control={
                                                    <Checkbox
                                                        disabled={true}
                                                        checked={true}
                                                        name={qual.name}
                                                    />
                                                }
                                                label={qual.name}
                                            />
                                        ))}
                                    </Box>
                                </>
                            )}
                        </Grid>
                        <Grid item sm={3} />
                        <Grid item xs={5}>
                            <Box pt={3} pr={4} pb={4} pl={4} bgcolor={COLORS.VERY_LIGHT_GREY_1}>
                                <Typography variant="h3" component="h2" gutterBottom>
                                    {t("subscription.summary.newSubscription")}
                                </Typography>
                                {subjectArea !== undefined ? (
                                    <Fragment>
                                        {subjectArea && subjectArea.id && (
                                            <SummaryBox
                                                subjectArea={subjectArea}
                                                isTrial={
                                                    subscriptionType === SubscriptionTypes.TRIAL
                                                }
                                                isNaht={
                                                    accountInfo?.canStartNahtSupportedSubscription &&
                                                    subscriptionType === SubscriptionTypes.NAHT
                                                }
                                            />
                                        )}
                                        <Box mt={4}>
                                            <Button
                                                disabled={
                                                    !subjectArea || qualifications?.length === 0
                                                }
                                                color="primary"
                                                onClick={() => {
                                                    if (
                                                        accountInfo?.canStartNahtSupportedSubscription &&
                                                        subscriptionType === SubscriptionTypes.NAHT
                                                    ) {
                                                        handleCreateNahtSupported();
                                                    } else {
                                                        handleStartTrial();
                                                    }
                                                }}
                                            >
                                                {subscriptionType === SubscriptionTypes.NAHT
                                                    ? t("subscription.trial.startNaht")
                                                    : t("subscription.trial.startTrial")}
                                            </Button>
                                        </Box>
                                    </Fragment>
                                ) : (
                                    t("subscription.add.chooseSubject")
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </PaperInner>
            </Paper>
        </AppContainer>
    );
};

export default TrialSubscription;
