import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const commonAnalysisYearGroupsEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis-cohort-year-group/`,
    method: ApiRequests.GET,
};

export interface CommonCohortAnalysisYearGroupResponse {
    id: number;
    name: string;
    yearGroup: number[];
    archived?: boolean;
}
