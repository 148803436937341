import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const CUSTOM_FIELDS_DEFAULT_CONFIG_QUERY = "customFieldsDefaultConfigQuery";

export interface CustomFieldsDefaultConfig {
    fieldType: string;
    name: string;
    values: string[];
}

export type CustomFieldsDefaultConfigResponse = CustomFieldsDefaultConfig[];

export const useCustomFieldsDefaultConfig = () => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CUSTOM_FIELDS_DEFAULT_CONFIG_QUERY, schoolAccountId],
        queryFn: async (): Promise<CustomFieldsDefaultConfigResponse | null> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/custom-fields/default-config`,
            );
            return data || null;
        },
    });
};
