import Dialog from "src/components/Dialog";
import {
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useClassTrackerLinksList } from "src/modules/links/hooks/ClassTracker/useClassTrackerLinks";
import { useClassTrackerGroupLinksList } from "src/modules/links/hooks/ClassTrackerGroup/useClassTrackerGroupLinks";

interface OwnProps {
    opened: boolean;
    selectedLinks: number[];
    classTrackerId?: number;
    classTrackerGroupId?: number;
    handleLinkModalClose: (confirmed: boolean, selectedLinks?: number[]) => void;
    handleUpsertLink: (linkId: number) => void;
}

const LinksModal: FC<OwnProps> = ({
    opened,
    selectedLinks,
    classTrackerId,
    classTrackerGroupId,
    handleLinkModalClose,
    handleUpsertLink,
}) => {
    const { t } = useTranslation();
    const [checkedLinks, setCheckedLinks] = useState<number[]>(selectedLinks || []);
    const [filter, setFilter] = useState<string>("");

    const { data: classTrackerLinks } = useClassTrackerLinksList(classTrackerId || 0);
    const { data: classTrackerGroupLinks } = useClassTrackerGroupLinksList(
        classTrackerGroupId || 0,
    );

    const links = classTrackerLinks?.length > 0 ? classTrackerLinks : classTrackerGroupLinks;

    return (
        <Dialog
            open={opened}
            onClose={() => handleLinkModalClose(false)}
            title={t("tracker.grid.links.header")}
            actions={
                <>
                    <Button
                        onClick={() => handleLinkModalClose(true, checkedLinks)}
                        color="primary"
                    >
                        {t("tracker.grid.links.saveBtn")}
                    </Button>
                    <Button onClick={() => handleUpsertLink(0)} color="primary">
                        {t("tracker.grid.links.addLink")}
                    </Button>
                </>
            }
        >
            <TextField
                label={t("tracker.grid.links.filter")}
                value={filter}
                onChange={e => setFilter(e.target.value)}
            ></TextField>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("tracker.grid.links.visible")}</TableCell>
                        <TableCell>{t("tracker.grid.links.linkName")}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {links
                        ?.filter(link => {
                            if (filter === "") return true;

                            return link.title.indexOf(filter) > -1;
                        })
                        .map(link => (
                            <TableRow key={link.id}>
                                <TableCell>
                                    <Checkbox
                                        checked={!!checkedLinks.find(st => st === link.id)}
                                        onClick={() => {
                                            if (checkedLinks.find(st => st === link.id)) {
                                                setCheckedLinks(
                                                    checkedLinks.filter(st => st !== link.id),
                                                );
                                            } else {
                                                setCheckedLinks(checkedLinks.concat(link.id));
                                            }
                                        }}
                                    ></Checkbox>
                                </TableCell>
                                <TableCell>{link.title}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleUpsertLink(link.id)}>
                                        {t("tracker.grid.links.editLink")}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Dialog>
    );
};

export default LinksModal;
