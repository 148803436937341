import axiosInstance from "src/config/axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import { PagedList } from "src/types";
import { isBoolean } from "@martin_hotell/rex-tils";
import { AssessmentListModel } from "../models/AssessmentListModel";
import { getSchoolAccountId } from "src/services/url";

export const ASSESSMENTS_QUERY = "assessmentsQuery";

export type AssessmentListResponse = PagedList<AssessmentListModel>;

export type AssessmentListFilters = {
    subjectAreaId?: number;
    qualificationId?: number;
    yearGroup?: number;
    page?: number;
    perPage?: number;
};

export const useAssessments = (cohortId: number, filters: AssessmentListFilters) => {
    const schoolAccountId = getSchoolAccountId();

    return useInfiniteQuery({
        queryKey: [
            ASSESSMENTS_QUERY,
            schoolAccountId,
            `${cohortId}`,
            filters?.subjectAreaId ? `${filters.subjectAreaId}` : "",
            filters?.qualificationId ? `${filters.qualificationId}` : "",
            filters?.yearGroup > -1 ? `${filters.yearGroup}` : "",
        ],
        queryFn: async ({ pageParam }): Promise<AssessmentListResponse | null> => {
            let page = pageParam;
            if (isBoolean(pageParam)) {
                page = 1;
            }

            if (cohortId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/assessments/by-cohort/${cohortId}/`,
                    {
                        params: { ...filters, page },
                    },
                );

                return data;
            }
            return null;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage?.nextPage ? allPages?.length + 1 : undefined;
        },
    });
};
