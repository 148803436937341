import { SubscriptionsActions, SubscriptionsActionTypes } from "./actions";
import { CreatePaymentIntentResponse } from "../api/Payment/createPaymentIntent";
import { ReminderResponse } from "../api/getReminder";

export interface SubscriptionState {
    paymentIntent: CreatePaymentIntentResponse | null;
    lastInvoice: string;
    reminder: ReminderResponse | null;
}

export const initialState: SubscriptionState = {
    paymentIntent: null,
    reminder: null,
    lastInvoice: "",
};

export const reducer = (
    state: SubscriptionState = initialState,
    action: SubscriptionsActions,
): SubscriptionState => {
    switch (action.type) {
        case SubscriptionsActionTypes.SET_PAYMENT_INTENT:
            return {
                ...state,
                paymentIntent: action.payload,
            };
        case SubscriptionsActionTypes.GET_REMINDER_SUCCESS:
            return {
                ...state,
                reminder: action.payload.response,
            };
        case SubscriptionsActionTypes.CLEAR_PAYMENT_INTENT:
            return {
                ...state,
                paymentIntent: null,
            };
        // case SubscriptionsActionTypes.SET_INVOICE:
        //     return {
        //         ...state,
        //         lastInvoice: action.payload.invoice,
        //     };

        default:
            return state;
    }
};
