import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { OrmReportTemplateActions } from "src/orm/models/ReportTemplate/actions";
import { ReportActions, ReportActionTypes } from "../actions";

const getReportTemplatesSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.GET_REPORT_TEMPLATE_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.getReportTemplateListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(OrmReportTemplateActions.bulkImport(payload.response))),
    );

const getReportTemplateDetailsSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.GET_REPORT_TEMPLATE_DETAILS_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.getReportTemplateDetailsSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(OrmReportTemplateActions.upsert(payload.response))),
    );

const createReportTemplateSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.CREATE_REPORT_TEMPLATE_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.createReportTemplateSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(OrmReportTemplateActions.upsert(payload.response))),
    );

const updateReportTemplateSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.UPDATE_REPORT_TEMPLATE_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.updateReportTemplateSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(OrmReportTemplateActions.upsert(payload.response))),
    );

const removeReportTemplateSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.REMOVE_REPORT_TEMPLATE_SUCCESS),
        mergeMap(() => of(OrmReportTemplateActions.clear())),
    );

const setAsDefaultReportTemplateSuccessEpic = (action$: ActionsObservable<ReportActions>) =>
    action$.pipe(
        ofType(ReportActionTypes.DEFAULT_REPORT_TEMPLATE_SUCCESS),
        map(
            (action: ReturnType<typeof ReportActions.setAsDefaultReportTemplateSuccess>) =>
                action.payload,
        ),
        mergeMap(() => of(OrmReportTemplateActions.clear(), ReportActions.getReportTemplateList())),
    );

export const reportTemplateEpics = combineEpics(
    setAsDefaultReportTemplateSuccessEpic,
    getReportTemplatesSuccessEpic,
    getReportTemplateDetailsSuccessEpic,
    createReportTemplateSuccessEpic,
    updateReportTemplateSuccessEpic,
    removeReportTemplateSuccessEpic,
);
