import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { UpsertSnapshotRequest } from "./useCreateMutation";
import { getSchoolAccountId } from "src/services/url";

const apiRenameSnapshot = async (
    id: number,
    values: UpsertSnapshotRequest,
): Promise<AxiosResponse> => {
    return await axiosInstance.put(
        `school/${getSchoolAccountId()}/cohort/${id}/snapshots/`,
        values,
    );
};

export const useRenameSnapshotMutation = (id: number, onSuccess?) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: UpsertSnapshotRequest) => apiRenameSnapshot(id, values),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(err.response.data?.error || t("school.snapshots.errorRenaming"), {
                ...SnackbarErrorOptions,
            });
        },
    });
};
