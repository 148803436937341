import AppContainer from "src/components/AppContainer";
import FieldChangeHandler from "src/forms/FieldChangeHandler";
import FormikRef from "src/forms/FormikRef";
import SpecificationSelectField from "src/forms/SpecificationSelectField";
import AttributesConfigList from "../../components/Common/AttributesConfigList";
import ChooseStudents from "../../components/SingleClass/ChooseStudents";
import ClassDetailsForm from "../../components/SingleClass/forms/ClassDetailsForm";
import PaperInner from "src/components/PaperInner";
import HeadingCounter from "src/components/HeadingCounter";
import ErrorMessage from "src/forms/ErrorMessage";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { Button, Typography, Box, Paper } from "@mui/material";
import { ApiData, ApiStatus } from "src/api/constants";
import { HTTP_NO_CONTENT } from "src/config/globals";
import { FileType } from "src/forms/types";
import { usePrevious } from "src/hooks/usePrevious";
import {
    AttributeGroup,
    AttributeItem,
    AttributeValueExtended,
} from "src/orm/models/SpecificationAttribute";
import { StudentModel } from "src/orm/models/Student";
import { OrmUser } from "src/orm/models/User";
import { FormError, getErrorMessage } from "src/services/error";
import { AppState } from "src/store/reducers";
import { Field, Form, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { createRef, Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ClassActions } from "../../store/actions";
import { createSingleClassSchema } from "../../components/ClassTracking/forms/addClassSchema";
import { ROUTE_CLASS_CLASS_LIST, ROUTE_CLASS_CLASS_LIST_COHORT } from "src/routes";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export interface SingleClassForm {
    specification: number | null;
    name: string;
    attributes: AttributeValueExtended[];
    items: AttributeItem[];
    groups: AttributeGroup[];
    disabledItems: number[];
    yearGroup: number;
    cohort: number | null;
    chooseCohort: number;
    chooseYearGroup: number;
    chooseClass: number;
    teachers: OrmUser[];
    students: StudentModel[];
    tmpStudentsImportFile: FileType[] | null;
    tmpStudentsAutocomplete: string;
}

const dispatchActions = (dispatch: Dispatch) => ({
    createSingleClass: values => {
        dispatch(ClassActions.createSingleClass(values));
    },
    clearStudents: () => {
        dispatch(ClassActions.clearStudents());
    },
});

const AddClassManualExistingStudentsContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const form = createRef();
    const { navigate } = useSchoolNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { data: accountInfo } = useAccountInfo();
    const activeCohortId = accountInfo?.activeCohortId || null;
    const { createSingleClass, clearStudents } = dispatchActions(dispatch);
    const { apiCreate }: { apiCreate: ApiData } = useSelector((state: AppState) => ({
        apiCreate: state.api.class.createSingleClass,
    }));
    const [redirectCohort, setRedirectCohort] = useState<string>("");

    const convertFormDataToRequest = (values: SingleClassForm) => ({
        name: values.name,
        yearGroup: values.yearGroup === -1 ? null : values.yearGroup,
        cohort: {
            id: values.cohort === 0 ? null : values.cohort,
        },
        specification: {
            id: values.specification,
        },
        teachers: values.teachers.map(teacher => ({ id: teacher })),
        attributes: values.attributes
            .filter(attr => {
                return (
                    (attr.isSelected === true || attr.isMandatory === true) &&
                    attr.attributeItemId &&
                    !values.disabledItems?.includes(attr.attributeItemId)
                );
            })
            .map(attr => ({
                customName: attr.customName || null,
                attributeValue: { id: attr.id },
            })),
        students: values.students.map(student => ({
            ...student,
            id: student.id || undefined,
        })),
    });

    const handleFormError = (error: FormError, currentForm) => {
        if (error.formError && "items" in error.formError) {
            const { items } = error.formError;
            if (items) {
                Object.keys(items).forEach(id => {
                    const index = currentForm.values.items.findIndex(
                        (i: AttributeItem) => i.id === parseInt(id),
                    );
                    const tmpItems: string[] = [];
                    tmpItems[index] = items[`${id}`];
                    error.formError = { items: tmpItems };
                });
            }
        }
        if (error.formError && "groups" in error.formError) {
            const { groups } = error.formError;
            if (groups) {
                Object.keys(groups).forEach(id => {
                    const index = currentForm.values.groups.findIndex(
                        (g: AttributeGroup) => g.id === parseInt(id),
                    );
                    const tmpGroups: string[] = [];
                    tmpGroups[index] = groups[`${id}`];
                    error.formError = { groups: tmpGroups };
                });
            }
        }
        currentForm.setErrors(error.formError);
    };

    const { data } = useCoreValues();
    const cohorts = data?.cohorts;

    const handleErrorResponse = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    if (data.error.response?.errors?.students) {
                        Object.keys(data.error.response.errors.students).forEach(key => {
                            enqueueSnackbar(data.error.response.errors.students[key], {
                                ...SnackbarErrorOptions,
                            });
                        });
                    } else {
                        const error: FormError = getErrorMessage(data);
                        if (error.message)
                            enqueueSnackbar(error.message, {
                                ...SnackbarErrorOptions,
                            });
                        if (error.formError) handleFormError(error, currentForm);
                        break;
                    }
                }
            }
        },
        [enqueueSnackbar, form],
    );
    const getDefaultCohort = () => {
        if (cohorts && cohorts.length > 0) {
            return cohorts.find(c => c.id === activeCohortId)?.id || cohorts[0].id;
        }
        return null;
    };
    const initialValues: SingleClassForm = {
        specification: null,
        name: "",
        attributes: [],
        items: [],
        groups: [],
        disabledItems: [],
        yearGroup: -1,
        cohort: getDefaultCohort(),
        chooseCohort: 0,
        chooseYearGroup: 0,
        chooseClass: 0,
        teachers: [],
        students: [],
        tmpStudentsImportFile: null,
        tmpStudentsAutocomplete: "",
    };

    const handleSubmit = (values: SingleClassForm) => {
        createSingleClassSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid) {
                    const convertedValues = convertFormDataToRequest(values);
                    setRedirectCohort(`${values.cohort}`);
                    createSingleClass(convertedValues);
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    const handleSpecificationChange = (
        newValue: number,
        oldValue: number,
        args: { setValues: (any) => void; resetForm: () => void },
    ) => {
        if (newValue !== oldValue) {
            const { setValues, resetForm } = args;
            resetForm();
            clearStudents();
            setValues({ ...initialValues, students: [], specification: newValue });
        }
    };

    const prevApiStatus = usePrevious(apiCreate.status);

    useEffect(() => {
        if (prevApiStatus === ApiStatus.LOADING && apiCreate.responseStatus === HTTP_NO_CONTENT) {
            if (redirectCohort) {
                navigate(ROUTE_CLASS_CLASS_LIST_COHORT.replace(":cohort", redirectCohort));
            } else {
                navigate(ROUTE_CLASS_CLASS_LIST);
            }
            enqueueSnackbar(t("class.addClass.classAdded"), { variant: "success" });
        } else if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.ERROR) {
            handleErrorResponse(apiCreate);
        }
    }, [apiCreate, enqueueSnackbar, handleErrorResponse, prevApiStatus, redirectCohort, t]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.addClass.headerManualExistingStudents")}
            </Typography>
            <Paper>
                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => createSingleClassSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<SingleClassForm>) => (
                        <Form>
                            <PaperInner>
                                <FieldChangeHandler
                                    onChange={handleSpecificationChange}
                                    value={formProps.values.specification}
                                    setValues={formProps.setValues}
                                    resetForm={formProps.resetForm}
                                />
                                <HeadingCounter number="1">
                                    {t("class.addClass.chooseTrackerHeader")}
                                </HeadingCounter>
                                <Typography component="p" gutterBottom>
                                    {t("class.addClass.singleClassIntro").replaceAll(
                                        "MIS_Name",
                                        accountInfo?.misName || "",
                                    )}
                                </Typography>
                                <Field
                                    name={`specification`}
                                    liveOnly={true}
                                    component={SpecificationSelectField}
                                    showParentSpecification={true}
                                    allowedOnly={true}
                                />
                                {formProps.values.specification && (
                                    <AttributesConfigList
                                        attributes={formProps.values.attributes}
                                        specificationId={formProps.values.specification}
                                        items={formProps.values.items}
                                        groups={formProps.values.groups}
                                        disabledItems={formProps.values.disabledItems}
                                        errors={formProps.errors}
                                        setFieldValue={formProps.setFieldValue}
                                        setFieldTouched={formProps.setFieldTouched}
                                        initialAttributes={[]}
                                    />
                                )}
                                {formProps.errors && formProps.errors["attributes"] && (
                                    <ErrorMessage name="attributes" />
                                )}
                            </PaperInner>
                            {formProps.values.specification && (
                                <Fragment>
                                    <PaperInner border="top">
                                        <HeadingCounter number="2">
                                            {t("class.addClass.classDetails")}
                                        </HeadingCounter>
                                        <ClassDetailsForm />
                                    </PaperInner>
                                    <PaperInner border="top">
                                        <HeadingCounter number="3">
                                            {t("class.addClass.chooseStudents")}
                                        </HeadingCounter>
                                        <ChooseStudents {...formProps} />
                                        <Box mt={4}>
                                            <Button
                                                disabled={formProps.isSubmitting}
                                                onClick={formProps.submitForm}
                                                color="primary"
                                            >
                                                {t("class.addClass.addClassButton")}
                                            </Button>
                                            <GenericErrorMessage
                                                errors={formProps.errors}
                                                submitCount={formProps.submitCount}
                                            />
                                        </Box>
                                    </PaperInner>
                                </Fragment>
                            )}
                        </Form>
                    )}
                </FormikRef>
            </Paper>
        </AppContainer>
    );
};

export default AddClassManualExistingStudentsContainer;
