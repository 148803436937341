import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSubscription } from "src/orm/models/Subscription";
import { getSchoolAccountId } from "src/services/url";

export const getSubscriptionListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscriptions/`,
    method: ApiRequests.GET,
};

export type SubscriptionsResponse = OrmSubscription[];
