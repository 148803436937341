import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { FileType } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export interface ImportStudentsRequest {
    file: FileType;
    overwrite?: boolean;
}

export interface ImportStudentsError {
    class: string;
    error: string;
    fileLine: number;
    invalidValue: string;
    propertyPath: string;
}

export interface ImportStudentsDatabaseError {
    fullNameInDb: string;
    fullNameInFile: string;
    line: number;
    uniquePupilNumber: string;
}

const apiImportStudent = async (values: ImportStudentsRequest): Promise<AxiosResponse> => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/student/import/`, values);
};

export const useStudentImportMutation = (
    onSuccess: (res?: ImportStudentsDatabaseError[]) => void,
    onError: (err: ImportStudentsError) => void,
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: ImportStudentsRequest) => apiImportStudent(values),
        onSuccess: res => {
            enqueueSnackbar(t("students.importStudents.importSuccess"), {
                variant: "success",
            });

            if (onSuccess && res.status === HttpStatusCode.NonAuthoritativeInformation) {
                onSuccess(res.data);
            }
            if (onSuccess && res.status === HttpStatusCode.Accepted) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<ImportStudentsError>) => {
            if (onError) onError(err.response?.data);
        },
    });
};
