import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { CustomisedAssessmentModel } from "../../models/CustomisedAssessmentModel";
import { getSchoolAccountId } from "src/services/url";

export const CUSTOMISED_ASSESSMENT_DETAILS_QUERY = "customisedAssessmentDetailsQuery";

export const useCustomisedAssessmentDetails = (id: number) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [CUSTOMISED_ASSESSMENT_DETAILS_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<CustomisedAssessmentModel | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/assessments/customised/${id}/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
        retry: false,
    });
};
