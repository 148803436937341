import PromptDialog from "src/forms/PromptDialog";
import AppContainer from "src/components/AppContainer";
import { useState, useEffect } from "react";
import { Typography, Paper, Grid, Button, Box, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import StudentAddEditForm from "../components/forms/StudentAddEditForm";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useParams } from "react-router";
import { useStudentDetails } from "../hooks/useStudentDetails";
import { useStudentRestrictedProps } from "../hooks/useStudentProps";
import { useStudentMergeMutation } from "../hooks/useMergeMutation";
import { ROUTE_MY_SCHOOL_STUDENTS } from "src/routes";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import useDebounce from "src/hooks/useDebounce";
import { SearchStudentsResponse, useGetSearchStudents } from "../hooks/useGetSearchStudents";

const StudentUpsertContainer = () => {
    const { navigate } = useSchoolNavigate();
    const { data: accountInfo } = useAccountInfo();

    const { student } = useParams();
    const studentId = student ? parseInt(student) : 0;
    const { t } = useTranslation();
    const [mergeWarningOpen, setMergeWarningOpen] = useState<boolean>(false);

    const { data: studentRestrictedProps, refetch: getStudentRestrictedProps } =
        useStudentRestrictedProps();

    const { data: studentDetails } = useStudentDetails(studentId);
    useEffect(() => {
        if (
            studentDetails &&
            (studentDetails.groupCallStatus === "active" ||
                studentDetails.groupCallStatus === "deleted")
        ) {
            getStudentRestrictedProps();
        }
    }, [studentDetails]);

    const { mutate: mergeStudent } = useStudentMergeMutation(() =>
        navigate(ROUTE_MY_SCHOOL_STUDENTS),
    );

    const [tmpStudentAutocomplete, setTmpStudentAutocomplete] =
        useState<SearchStudentsResponse | null>(null);
    //autocomplete
    const [autocompleteInput, setAutocompleteInput] = useState<string>("");
    const debouncedAutocompleteInput = useDebounce(autocompleteInput, 500);
    const { data: students, refetch } = useGetSearchStudents(debouncedAutocompleteInput);
    useEffect(() => {
        refetch();
    }, [debouncedAutocompleteInput]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {studentId ? t("students.addEdit.headerEdit") : t("students.addEdit.headerAdd")}
            </Typography>
            <Paper>
                <>
                    {accountInfo?.hasGroupCallSynchronization && studentDetails?.groupCallId && (
                        <Box pl={6} pt={4}>
                            <Grid container spacing={4}>
                                <Grid item sm={6}>
                                    <Typography variant="h6" component="h6">
                                        {`${t("school.staff.addEdit.dataSyncId")}: `}
                                        <span style={{ color: "#000" }}>
                                            {studentDetails.groupCallId}
                                        </span>
                                    </Typography>
                                    <Autocomplete
                                        freeSolo
                                        options={
                                            students?.filter(
                                                (val: SearchStudentsResponse) =>
                                                    val.id !== studentId,
                                            ) || []
                                        }
                                        getOptionLabel={(student: SearchStudentsResponse) =>
                                            `${student.firstName} ${student.lastName}`
                                        }
                                        onInputChange={(_event, newInputValue) => {
                                            setAutocompleteInput(newInputValue);
                                        }}
                                        onChange={(_e, value) => {
                                            setTmpStudentAutocomplete(
                                                value as SearchStudentsResponse,
                                            );
                                        }}
                                        renderInput={params => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    placeholder={t(
                                                        "class.addClass.searchStudentAutoPlaceholder",
                                                    )}
                                                    label={t(
                                                        "class.addClass.searchStudentAutoPlaceholder",
                                                    )}
                                                    helperText={t(
                                                        "class.addClass.searchStudentAutoPlaceholderHelperText",
                                                    )}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            );
                                        }}
                                        forcePopupIcon={false}
                                        sx={{ mb: 3 }}
                                    />
                                    <Button
                                        disabled={!tmpStudentAutocomplete}
                                        onClick={() => {
                                            setMergeWarningOpen(true);
                                        }}
                                    >
                                        {t("students.addEdit.merge")}
                                    </Button>
                                </Grid>
                                <Grid item sm={6}></Grid>
                            </Grid>
                            <PromptDialog
                                open={mergeWarningOpen}
                                onClose={confirmed => {
                                    if (confirmed && studentDetails.id) {
                                        mergeStudent({
                                            linkedStudent: {
                                                id: studentDetails.id,
                                            },
                                            unLinkedStudent: {
                                                id: tmpStudentAutocomplete.id,
                                            },
                                        });
                                    }
                                    setMergeWarningOpen(false);
                                }}
                                yesLabel={t("common.continue")}
                                noLabel={t("common.cancel")}
                            >
                                <h1>{t("students.importStudents.warningHead")}</h1>
                                <p>
                                    {t("students.importStudents.warning1")
                                        .replace(
                                            ":nonSyncedStudent",
                                            `${tmpStudentAutocomplete?.firstName || ""} ${
                                                tmpStudentAutocomplete?.lastName || ""
                                            } (${tmpStudentAutocomplete?.uniquePupilNumber || ""})`,
                                        )
                                        .replace(
                                            ":syncedStudent",
                                            `${studentDetails.firstName} ${studentDetails.lastName} (${studentDetails.uniquePupilNumber})`,
                                        )}
                                </p>
                                <p>
                                    {t("students.importStudents.warning2")
                                        .replace(
                                            ":nonSyncedStudent",
                                            `${tmpStudentAutocomplete?.firstName || ""} ${
                                                tmpStudentAutocomplete?.lastName || ""
                                            }`,
                                        )
                                        .replace(
                                            ":syncedStudent",
                                            `${studentDetails.firstName} ${studentDetails.lastName}`,
                                        )}
                                </p>
                                <p>{t("students.importStudents.warningcontinue")}</p>
                            </PromptDialog>
                        </Box>
                    )}
                    <StudentAddEditForm
                        hasGroupCallSync={accountInfo?.hasGroupCallSynchronization}
                        studentRestrictedProps={
                            studentDetails?.groupCallId ? studentRestrictedProps : []
                        }
                        studentDetails={studentDetails}
                    />
                </>
            </Paper>
        </AppContainer>
    );
};

export default StudentUpsertContainer;
