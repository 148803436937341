import { CircularProgress, Box, Typography } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import AuthContainer from "src/components/AuthContainer";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useMount } from "src/hooks/useMount";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "src/context/AuthContext";
import { useLoginAs } from "../hooks/useLoginAs";

const LoginAsContainer = () => {
    const params = useParams();
    const [error, setError] = useState<string | null>(null);

    const { t }: { t: TFunction } = useTranslation();
    const queryClient = useQueryClient();
    const { clearAuthContext } = useAuthContext();

    const handleError = useCallback(
        errorMessage => {
            if (errorMessage) {
                setError(errorMessage);
            } else {
                setError(t("common.unknownError"));
            }
        },
        [t],
    );
    const { mutate: loginAs } = useLoginAs(handleError);

    useEffect(() => {
        if (params.token) {
            loginAs(params.token);
        }
    }, [handleError, params.token]);

    useMount(() => {
        queryClient.clear();
        clearAuthContext();
    });

    return (
        <AuthContainer>
            <Box style={{ textAlign: "center" }}>
                {error ? (
                    <Typography component="h3" variant="h3">
                        {error}
                    </Typography>
                ) : (
                    <CircularProgress />
                )}
            </Box>
        </AuthContainer>
    );
};

export default LoginAsContainer;
