import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { OrmClassTrackerGroup } from "src/orm/models/ClassTrackerGroup";
import { getSchoolAccountId } from "src/services/url";

export const SNAPSHOT_CLASS_TRACKER_GROUPS_QUERY = "snapshotClassTrackerGroups";

export const useSnapshotClassTrackerGroups = (snapshotId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [SNAPSHOT_CLASS_TRACKER_GROUPS_QUERY, schoolAccountId, snapshotId],
        queryFn: async (): Promise<OrmClassTrackerGroup[] | null> => {
            if (snapshotId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/school-snapshot/${snapshotId}/class-tracker-groups/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
