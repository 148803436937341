export const en = {
    translation: {
        common: {
            select: "Select",
            row: "Row",
            value: "Value",
            dismiss: "Dismiss",
            logoAlt: "Pupil Progress Logo",
            continue: "Continue",
            back: "Back",
            goBack: "Go back",
            title: "Title",
            removed: "Removed",
            add: "Add",
            noAccess: "No access",
            mr: "Mr",
            mrs: "Mrs",
            yg0: "Reception",
            showOnlyWithNoData: "Show only with no data",
            ok: "Ok",
            none: "None",
            saved: "Saved!",
            emptyString: "",
            details: "Details",
            copyList: "Copy student List",
            unit: "Unit",
            click: "Click",
            copiedToClipboard: "Data copied to clipboard",
            filterByTag: "Filter by tag",
            filterByTagColumn: "Filter by tag",
            selectedTags: "Selected tags",
            request: "Request",
            close: "Close",
            academicYear: "Academic Year",
            button: "button",
            UPN: "UPN",
            allSubjects: "Number of “All subject” Tags",
            saveChanges: "Save Changes",
            miss: "Miss",
            records: "records",
            onThisPage: "on this page",
            configureLinks: "Configure links",
            ms: "Ms",
            dr: "Dr",
            prof: "Prof",
            configure: "Configure",
            configureTags: "Configure tags",
            tiers: "Tiers",
            noData: "No data",
            mtgAboveTarget: "Above target",
            mtgOnTarget: "On target",
            mtgOneGradeAway: "One grade away",
            mtgOffTarget: "Off target",
            mtgNotSet: "Not set",
            trackerExists: "This tracker already exists",
            existingClassesHint:
                "Existing classes that will be affected if your choose to create new classes",
            trackerBlocked: "Your tracker is just recalculating at the moment. Please wait...",
            trackerStatusBlockedMsg:
                "Tracker is blocked. You're in preview mode. Please wait for tracker beeing unlocked...",
            trackerStatusUnblocked: "Tracker has beed unblocked, please refresh",
            refresh: "Refresh",
            mx: "Mx",
            selectAll: "Select all",

            deselectAll: "Deselect all",
            deselectAllClasses: "Deselect All Classes",
            selectAllClasses: "Select All Classes",
            addWithoutVisibility: "Add to all, but hide for now",
            noneFilter: "[None]",
            allFilter: "All",
            httpNotFound: "Not found",
            all: "All",
            uploading: "Uploading",
            allClasses: "All Classes",
            allQualifications: "All Qualifications",
            allSpecifications: "All Specifications",
            reportsAnalysis: "Reports / Analysis",
            noClassesAvailable: "No classes available",
            yes: "Yes",
            no: "No",
            cancel: "Cancel",
            remove: "Remove",
            min: "min",
            max: "max",
            required: "required",
            save: "Save",
            delete: "Delete",
            firstName: "First Name",
            lastName: "Last Name",
            edit: "Edit",
            male: "Male",
            female: "Female",
            "non-binary": "Non binary / Non conforming",
            date: "Date",
            home: "Home",
            from: "From",
            to: "To",
            thankYou: "Thank you",
            noDataInTracker: "There are no students assigned to this class",
            login: "Login",
            tryAgain: "Try again",
            register: "Register",
            validationFailed: "Validation failed. Try again.",
            genericValidation: "Unable to save due to form errors",
            unknownError: "Unknown Error. Try Again.",
            trial: "Confirm your account",
            needHelp: "Need Help?",
            uploadFile: "Upload file",
            noneSelect: "[None]",
            noDataSelected: "No data selected",
            errorUploadingSnackbar: "Error occured while uploading file",
            appNewVersion: `There’s a new version of this app available. Please hard reload this page or try to empty your browser cache.`,
            teachers: "Teachers",
            leaders: "Leaders",
            yearGroup: "Year Group",
            userName: "Name",
            year: "Year",
            month: "Month",
            week: "Week",
            day: "Day",
            years: "Years",
            months: "Months",
            weeks: "Weeks",
            days: "Days",
            course: "Course",
            courses: "Courses",
            subscription: "Subscription",
            subjectArea: "Subject Area",
            qualification: "Qualification",
            specification: "Specification",
            addFilter: "Add filter",
            saveClose: "Save & Close",
            showToolsSwitch: "Tools",
            showFiltersSwitch: "Filters",
            filterByStudent: "Filter by student",
            filterByGrade: "Filter by grade",
            filterByAtl: "Filter by Learning Characteristics",
            filterByAtlMin: "Minimum",
            filterByAtlMax: "Maximum",
            filterBySpecification: "Filter by specification",
            filterBySummary: "Filter by summary column",
            filterBySummaryColumn: "Summary Column",
            filterByGradeColumn: "Grade Column",
            filterBySpecificationColumn: "Specification",
            filterAllSpecifications: "All Specifications",
            filterByGradeMin: "Minimum Grade",
            filterByGradeMax: "Maximum Grade",
            filterByDistanceMin: "Minimum Distance",
            filterByDistanceMax: "Maximum Distance",
            filterByMTG: "Filter by comparison to MTG",
            filterStudentAttribute: "Student attribute",
            filterAdd: "Add filter",
            filterExistingFilters: "Existing Filters",
            filterMin: "Min",
            filterMax: "Max",
            tier: "Tier",
            tierNameF: "Foundation",
            tierNameI: "Intermediate",
            tierNameH: "Higher",
            "tierName-": "No tier",
            toolsAssessments: "Assessments",
            adjustColumns: "Adjust columns",
            adjustColumnsBtn: "Adjust columns",
            adjustFiltersBtn: "Adjust filters",
            adjustFilters: "Adjust filters",
            studentAttributesFilter: {
                existingFilters: "Existing Filters",
                header: "Filter students",
                add: "Add filter",
                set: "Edit filters",
                noActiveFilters: "No active filters",
                filtered: "Filtered",
                all: "All students",
                unavailable: "Unavailable",
                typeform: "Form",
                typeethnicities: "Ethnicity",
                typegender: "Gender",
                typesenNeeds: "Sen Need",
                typesenStatuses: "Sen Status",
                typepupilPremium: "Pupil Premium",
                typefsm: "FSM",
                typelookedAfter: "Ever In Care",
                typeeal: "EAL",
                typepriorAbility: "Prior Ability",
                typenativeSpeaker: "Native Speaker",
                typefromDateJoined: "Date Joined From",
                typetoDateJoined: "Date Joined Before",
                studentAttribute: "Student Attribute",
                fromDateJoined: "Date Joined From",
                toDateJoined: "Date Joined Before",
                value: "Value",
            },
            snapshotModal: {
                title: "Snapshots",
                hint: "Choose how you want to use snapshots:",
                typecompare: "Compare",
                typecompareText:
                    "See the progress made between two points in time - select from Live Tracking, Forecast or Snapshots",
                typecreate: "Create",
                typecreateText: "Take a snapshot of Live Tracking for the whole year group",
                typelist: "View",
                typelistText: "Open a historic view of your Live Tracking",
                createSnapshotHint: "Name snapshot",
                snapshotCreated: "Snapshot created",
                name: "Name",
                created: "Created",
                hasReports: "Has reports?",
            },

            form: {
                validation: {
                    name: { required: "Name is a required field" },
                    title: { required: "Title name is a required field" },
                    module: { required: "Module is a required field" },
                    firstName: { required: "First name is a required field" },
                    lastName: { required: "Last name is a required field" },
                    email: { required: "Email is a required field", type: "E-mail must be valid" },
                    unit: { required: "Unit is required" },
                    yearGroup: { required: "Year group is required" },
                    cohort: { required: "Academic Year is required" },
                    specification: { required: "Specification is required" },
                    classTracker: { required: "Class tracker is required" },
                    responseToken: { required: "Please fill in the reCAPTCHA" },
                    tierUnit: { one: "At least one unit must be selected" },
                    ks4Aps: "Must be in X.XX number format",
                    ks4Agaps: "Must be in X.XX number format",
                    readingAge: "Must be in XX:XX number format",
                    maxValue: {
                        required: "Max Value is required",
                        type: "Max Value must be valid",
                    },
                    personalisedAssesmentQuestions: {
                        required: "Personal Assessment Questions are required",
                    },
                    examDate: {
                        required: "Exam date is required",
                        type: "Exam date must be valid",
                    },
                    newPassword: {
                        min: "Min 8 password characters are required",
                        match: "Invalid password",
                        matchNum: "Min one number is required",
                        required: "Password is a required field",
                    },
                    password: {
                        min: "Min 8 password characters are required",
                        match: "Invalid password",
                        matchNum: "Min one number is required",
                        required: "Password is a required field",
                    },
                    repeatPassword: {
                        repeat: "Passwords don't match",
                        required: "Repeat password is a required field",
                    },
                    phone: {
                        min: "Min 9 numbers are required",
                        max: "Max 20 numbers are allowed",
                        match: "Invalid phone number",
                    },
                    mobile: {
                        min: "Min 9 numbers are required",
                        max: "Max 20 numbers are allowed",
                        match: "Invalid mobile phone number",
                        matchMobile: "Invalid mobile phone number",
                    },
                    attributes: {
                        min: "Min 1 attribute should be selected",
                    },
                    subscriptionLength: {
                        required: "Length is required",
                    },
                },
                label: {
                    title: "Title",
                    name: "Name",
                    examDate: "Exam date",
                    firstName: "First Name",
                    lastName: "Last Name",
                    email: "E-mail",
                    phone: "Phone",
                    phoneOpt: "Phone (optional)",
                    mobile: "Mobile",
                    mobileOpt: "Mobile (optional)",
                    jobTitle: "Job Title",
                    staffCode: "Staff Code",
                    maxValue: "Max value",
                    password: "Password",
                    repeatPassword: "Repeat password",
                    address: "Address",
                    address2: "Address 2",
                    schoolTitle: "Current Role",
                    postcode: "Postcode",
                    city: "Town / City",
                    comment: "Comment",
                    dateJoined: "Date joined",
                    dateOfBirth: "Date of Birth",
                    addNewEmail: "Add new email",
                },
            },
            roles: {
                ROLE_SCHOOL_ADMIN: "School Admin",
                ROLE_OWNER: "Owner",
                ROLE_FINANCE: "Finance Manager",
                ROLE_TEACHER: "Teacher",
            },
        },
        maintenance: {
            header: "Come back soon!",
            text1: "Pupil Progress is currently undergoing scheduled maintenance and upgrades but will return shortly.",
            text2: "Thank you for your patience.",
        },
        error404: {
            title: "Page not found",
            hint: "We're sorry, the page at the provided address was not found.",
        },
        mobileLock: {
            text1: "Pupil Progress is best experiencced on larger resolution.",
            text2: "Please try to login to your account on Desktop browser instead.",
        },
        account: {
            header: "My Pupil Progress Account",
            yourDetails: "Your Details",
            changePassword: "Change Password",
            schoolAccount: "School & Account",
            profile: "Profile",
            noProfiles: "No profiles",
            passwordHint: `Please don't change any of the above two fields if you don't want to change
            your password`,
            accountType: "Your Account Type",
            yourSchool: "Your school",
            allSubjectAreas: "All Subject Areas",
            availableSubjectAreas: "Available Subject Areas",
            leaderOfSubjects: "Leader of:",
            teacherOfSubjects: "Teacher of:",
            save: "Save Profile",
            manageSubjectAreas: "Manage Subject Areas",
            profileSaved: "Profile data saved successfully",
            urn: "URN",
            myAccountTab: {
                header: "My Account",
                emails: "Emails",
                resetPassword: "Reset Password",
                deleteAccount: "Delete account",
                addEmailSuccess: "Email added",
                primaryEmail: "Preferred email",
                additionalEmails: "Additional Emails",
                pendingConfirmation: "pending",
                addEmailBtn: "Add email",
                changePassword: "Change Password",
                changePasswordSuccess: "Password changed",
                deleteAccountBtn: "Delete my account",
                deleteAccountHint:
                    "Deleting your account will remove your access to all of the schools you currently have access to. It will not affect any student data you have entered.",
                deleteAccountPrompt: `Type "delete" if you are sure you want to delete your account. This action is irreversible.`,
                setPrimaryEmailSuccess: "Primary email changed",
                setPrimaryEmailBtn: "Make preferred",
                deleteBtn: "Delete",
                deleteMergeRequestBtn: "Delete merge request",
                resendEmailSuccess: "Email resend",
                resendEmail: "Resend email",
            },
            myProfilesTab: {
                header: "My Profiles",
            },
        },
        trust: {
            dashboard: {
                header: "Dashboard",
                cohort: "Academic year",
                yearGroup: "Year Group",
                showAdmins: "Show Admins",
                school: "School",
                numberOfStudents: "Number of students in cohort",
                currentGrade: "Current grade",
                avgUnitGrade: "Average Unit Grade",
                teacherPredicted: "Teacher Predicted Grade",
                goToSchool: "Go to school",
                noCohortsMessage: "Add a Year 10 or Year 11 tracker to see your P8 / A8 summary",
            },
            users: {
                header: "Trust Users",
                inviteAdminBtn: "Invite Trust Admin",
                firstLastName: "First / Last name",
                school: "School",
                schools: "Schools",
                status: "Status",
                Trust: "Trust",
                title: "Title",
                autocompletePlaceholder: "Search by name...",
                firstName: "First Name",
                lastName: "Last Name",
                primaryEmail: "Primary Email",
                details: "Details",
                inviteModalHeader: "Invite Trust Admin",
                inviteBtn: "Invite",
                account: "Account",
                role: "Role",
                grantAccess: "Grant Access",
                revokeAccess: "Revoke Access",
                editRole: "Edit Role",
            },
            details: {
                header: "Trust Users",
            },
        },
        analysis: {
            types: {
                atlAnalysis: "Learning Characteristics",
                atlAnalysisText:
                    "Overview of all students Learning Characteristic scores across all subjects",
                studentsGradeSummary: "Grades Summary",
                studentsGradeSummaryText: "Overview of students grades across all subjects",
                studentReport: "Student Reports",
                studentReportText:
                    "Download individual student reports across classes and subjects",
                ks4Headlines: "KS4 Headlines",
                ks4HeadlinesText: "Key figures for Performance measures including P8 and A8",
                ks4GradeAnalysis: "KS4 Grade Analysis",
                ks4GradeAnalysisText:
                    "Overview of students grades to calculate Progress 8, Attainment 8 and EBacc scores",
                ks4SubjectBreakdown: "KS4 Subject Breakdown",
                ks4SubjectBreakdownText:
                    "Overview of KS4 performance indicators across all subjects",
                subjectAnalysis: "Subject Analysis",
                subjectAnalysisText: "Summary of attainment and progress across your subject area",
                classAnalysis: "Class Analysis",
                classAnalysisText: "Attainment and progress measures for your class",
            },
            header: {
                liveTrackingBtn: "Live Tracking",
                snapshotBtn: "Snapshot",
            },
            modal: {
                header: "Analysis",
                hint: "Please choose a Year Group & Analysis report that you want to look at",
                studentsGradeSummaryBtn: "Students Grade Summary",
                ks4GradeAnalysisBtn: "KS4 Grade Analysis",
                studentReportBtn: "Student Reports",
                cohort: "Academic Year",
                yearGroup: "Year Group",
                yearGroupSelectNone: "Please select...",
                continue: "Continue",
            },
            snapshot: {
                creatingHint: `Creating School-Level Snapshot can only be done by an Administrator.`,
                takeMeToCreate: `Take me to create a snapshot`,
            },
            modalAdjust: {
                title: "Add filter",
                addFilter: "Add filter",
                saveCloseBtn: "Save & Close",
                selectGrades:
                    "Select the tracker and grades that you want to use to filter your columns.",
                byAddingMore: `By adding more than one filter it will show only students whose grades meet all
                 the filters selected.`,
            },
            grid: {
                specification: "Specification",
                averageRowKs4: "Average (only students counted in A8/P8)",
                averageRow: "Average",
            },
            gradeSummary: {
                studentFilter: "Student",
                source: "Source",
                sourceProjectedGrade: "Average Unit Grades",
                sourceCurrentGrade: "Current Grades",
                sourceTargetGrade: "Minimum Target Grades",
                sourceTeacher: "Teacher Predicted Grades",
                downloadExcel: "Download as XLS",
                filterAdjust: "Adjust",
                filterLabel: "Filter",
            },
            ks4Grade: {
                ebaccAPS: "Ebacc APS",
                a8Score: "A8 Score",
                p8Score: "P8 Score",
                studentFilter: "Student",
                source: "Source",
                sourceProjectedGrade: "Average Unit Grades",
                sourceCurrentGrade: "Current Grades",
                sourceTeacher: "Teacher Predicted Grades",
                type: "Type:",
                typeA8: "A8",
                typeP8: "P8",
                downloadExcel: "Download as XLS",
                forecastLoadDataBtn: "Load Data",
                clearForecastBtn: "Clear Forecast",
                clearForecastPrompt: "You are about to clear all data from Forecast. Continue?",
                filterAdjust: "Adjust",
                filterLabel: "Filter",
                addFilter: "Add filter",
                liveTrackingBtn: "Live Tracking",
                forecastBtn: "Forecast",
                snapshotBtn: "Snapshot",
                configureColumnsBtn: "Configure Columns",
                includeda8e: "Included in A8/P8 & Ebacc",
                includeda8: "Included in A8/P8",
                includede: "Included in Ebacc APS",
                loadData: "Load data",
                copyHint:
                    "You are about to load (copy) data from Live Tracking sheet into Forecast sheet",
                copyHint2:
                    "Please choose if you want to load data from the whole tracker, or only from selected elements",
                dataSource: "Data source",
                dataSourceall: "Whole tracker",
                dataSourceenma: "English & Math Element",
                dataSourceebacc: "Ebacc Element",
                dataSourceopen: "Open Element",
            },
            subjectAnalysis: {
                hint: "Please select Subject area and Qualification for which you want to run class summary analysis:",
                subjectArea: "Subject Area",
                qualification: "Qualification",
                specification: "Specification",
                classes: "Classes",
                showAnalysisBtn: "Show Analysis",
                classSummary: "Class Summary",
                subgroupAnalysis: "Subgroup analysis",
                gradeBreakdown: "Grade breakdown",
                a8Score: "A8 Score",
                p8Score: "P8 Score",
                grade95: "Grade 9-5",
                grade94: "Grade 9-4",
                cumulative: "Cumulative",
                subject: "Subject",
                teacher: "Teacher",
                class: "Class",
                numberOfStudents: "Number of students",
                subgroup: "Sub-Group",
                grade: "Grade",
                averageUnitGrade: "Average Unit <1/>Grade",
                minimumTargetGrade: "Minimum Target <1/>Grade",
                aboveTarget: "above target",
                onTarget: "on target",
                oneGradeAway: "1 grade away",
                offTarget: "off target",
                allClasses: "All classes",
                downloadExcelBtn: "Download as XLS",
                numberTargetNotSet: "Minimum Target Grade not set",
                numberOfStudentsWorkingAtThisGrade: "Number of students <1/>working at this grade",
                numberOfStudentsAtThisGradeOrAbove: "Number of students at <1/>this grade or above",
                ofStudents: "of students at this <1/>grade or above",
                aimAccordingToMinimumTargetGrade:
                    "if all students achieve <1/>their Minimum Target Grade",
                chooseDifferentCourse: "Choose a different course",
            },
        },
        subscription: {
            owned: "Owned",
            requested: "Requested",
            invoices: "Invoices",
            status: {
                active: "Active",
                expired: "Expired",
                trial: "Trial",
                cancelled: "Cancelled",
                pending: "Pending",
                paid: "Paid",
                "not invited yet": "Not invited yet",
            },
            type: {
                single: "Single",
                fullSuite: "Full Suite",
                wholeSchool: "Whole school",
            },
            list: {
                header: "Subscriptions",
                upgrade: "Upgrade",
                extend: "Extend subscription",
                nahtExtendMessage:
                    "Do you wish to extend this NAHT-Enabled Subscription for the Next Academic Year?",
                extendNaht: "Extend NAHT subscription",
                allAcademicYear: "Academic Year",
                showInvoice: "Show invoice",
                decline: "Decline resubscription",
                buy: "Buy subscription",
                subjectArea: "Subject Area / Course",
                convertYes: "Yes, convert",
                convertNo: "No, cancel",
                convertNaht: "Convert to NAHT-Enabled Subscription",
                convertNahtDialogHeader: "Convert to NAHT-Enabled Subscription",
                convertNahtDialogMessage: `You are about to convert this Trial subscription to NAHT-Enabled Subscription.`,
                convertNahtDialogHint:
                    "<strong>Note</strong> You can have up to 2 NAHT Enabled subscriptions in your account.",
                errorConvertNaht: "Error while converting to NAHT-Enabled Subscription",
                errorExtendNaht: "Error while extending NAHT-Enabled Subscription ",
                status: "Status",
                buySubscription: "Buy subscription",
                dueDate: "Due Date",
                upgradeToWholeSchool: "Upgrade to whole school subscription",
                requestWholeSchoolBtn: "Request Whole School Subscription",
                declineMessage: "Are you sure you want to decline subscription?",
            },
            confirmed: {
                header: "You've sent a request for a new subscription",
                requestedFollowing: "You've requestede the following subscription",
                whatHappensHeader: "What happens next?",
                whatHappensMessage: `We've sent an invitation to your school's Finance Manager to join your account
                and cover costs of subscription.`,
                whatHappensFMMessage: `Your school's Finance Manager has to review the subscription request 
                - once the payment is fulfilled the subscription will be active`,
                whatHappensThanks: "Thank you for your order!",
                additionalMessage: `You will have to make sure that your subscription is paid by {trialDate} (end of 
                your trial period) in order to continue using our platform`,
            },
            extend: {
                header: "Extend Subscription",
                currentlyOwn: "You currently own the following subscription:",
                chooseSubject: "Subject Area",
                chooseCourses: "Course(s)",
                choosePeriod: "Choose subscription period",
                newSubscription: "Your extended subscription:",
                extendSubscription: "Request Subscription",
                added: "Subscription extended",
            },
            makePayment: {
                header: "Make Payment",
                successHeader: "Payment Successfull",
                placingOrder: "You're placing an order for the following subscription:",
                requestedBy: "Requested by",
                useBillingAddress: "Use school's address as billing address",
                nameOnCard: "Name on card",
                cardNumber: "Card number",
                expiryDate: "Expiry date",
                expiryDateM: "Expiry date MM",
                expiryDateY: "Expiry date YY",
                cvv: "CVV",
                schoolName: "School name",
                failure: "Something wen't wrong with payment",
                cardDetails: "Payment card details",
                billingAddress: "Billing address",
                note: "Note",
                charged: `Once you click the below button you'll be charged £{amount}. Your subscription will be then
                automatically enabled on your school's account for all other users. Please note, that this is a
                non-recurring, non-refundable order.`,
                customNote: `You can also add a custom note to the invoice that we shall issue upon successfully
                charging you for the above subscription (e.g. with PO number)`,
                paymentSuccess: `You have successfully purchased the following subscription:`,
                thankyou: "Thank You!",
                chargedFor: "We've successfully charged your card for £{amount}.",
                noCharge: "You have not been charged due to the discount coupon",
                successMessage: `The above subscription has been added to your active subscriptions and is now
                available to all other school's users.`,
                downloadInvoice: "Download Invoice",
            },
            requestedList: {
                requestedBy: "Requested by",
                dateTime: "Date & Time",
                subjectAreaCourse: "Subject Area / Course",
                amount: "Amount",
                valid: "Valid Until",
                reject: "Reject",
                approve: "Approve and make payment",
                rejectPrompt: "Are you sure you want to reject subscription?",
            },
            invoiceList: {
                purchased: "Purchased on",
                subjectAreaCourse: "Subject Area / Course",
                totalPrice: "Total price",
                filter: "Filter by subscription",
                download: "Download invoice",
            },
            trial: {
                freeTrial: "FREE trial",
                startTrial: "Start New Free Trial",
                startNaht: "Start New NAHT-Enabled Subscription",
                startNewSubscription: "Start New Subscription",
                free: "FREE",
                trialAdded: "Trial subscription created successfully",
                nahtSupportedAdded: "NAHT-Enabled Subscription created successfully",
                trialFailed: "Failed to create trial subscription",
                trialFailedNotFound: "Failed to create trial subscription. Subject area not found.",
                trialFailedConflict:
                    "Failed to create trial subscription. Trial subscription exists.",
            },
            upgrade: {
                header: "Upgrade subscription",
                wholeSchoolHeader: "Buy Whole School Subscription",
                wholeSchoolRebateHeader: `Whole School Subscription is a special subscription that covers ALL subject
                areas and ALL courses on our platform.`,
                wholeSchoolRebateMessage: `If you decide to order it - we will automatically reduce its price by the
                total value of all other active subscriptions that you currently own.`,
                wholeSchoolRebateHint: `Your rebate from currently owned subscriptions - which will all get automatically
                cancelled once you place the below order`,
                annually: "Annually",
                monthly: "Monthly",
                subscriptionPeriodDiscount: "save 10%!",
                P1YSubscription: `1 Year subscription`,
                P2YSubscription: `2 Years subscription`,
                P3YSubscription: `3 Years subscription`,
                P1WSubscription: "1 Week subscription",
                P2WSubscription: "2 Weeks subscription",
                P3WSubscription: "3 Week subscription",
                P1MSubscription: "1 Month subscription",
                P2MSubscription: "2 Months subscription",
                P3MSubscription: "3 Months subscription",
                P4MSubscription: "4 Months subscription",
                P5MSubscription: "5 Months subscription",
                P6MSubscription: "6 Months subscription",
                P7MSubscription: "7 Months subscription",
                P8MSubscription: "8 Months subscription",
                P9MSubscription: "9 Months subscription",
                P10MSubscription: "10 Months subscription",
                P11MSubscription: "11 Months subscription",
                subscriptionPeriodHint:
                    "Please choose for how long you want to buy your subscription for.  Note that the longer the pre-paid subscription period, the less you will pay per month.",
                validUntil: "valid until",
                currentlyOwn: "You currently own the following subscription:",
                upgradeToWholeSchoolButton: "Request Subscription",
                wholeschoolCreated: "Whole School Subscription created successfully",
                pricesStartsAt: "Our prices for a whole school start at £900",
                pricesVariable: `Prices are variable based on your school's size and the range of courses you wish to track.
                We have flexible options to suit your needs and budget.`,
                pricesBookConsultation: "Book a consultation call with us to discuss your options.",
                scheduleACallBtn: "Schedule a call",
            },
            buy: {
                header: "Buy Subscription",
                buyButton: "Request Subscription",
            },
            buyAfterTrial: {
                header: "Buy Subscription",
                requestButton: "Request subscription",
            },
            add: {
                subjectArea: "Subject Area",
                chooseSubject: "Choose subject area",
                subscriptionType: "Supscription type",
                subscriptionTypeFree: "FREE",
                subscriptionTypeNaht: "NAHT-Enabled Subscription",
                subscriptionTypeNahtHint: `<i>As a NAHT member, your school is entitled to a Pupil Progress subscription 
                for two new subjects <strong> at zero additional cost </strong></i>`,
                subscriptionTypeTrial: "Trial (valid for 30 days)",
                chooseCourses: "Course(s)",
                choosePeriod: "Choose subscription period",
                yourNewSubscr: "Your new subscription",
                noCourses: "No courses",
                noLimits: "No limits",
                allCourses: "All courses",
                allSubjectAreas: "All Subject Areas",
                minQualifications: "Min 1 Course is required",
                nolimits: "No limits",
            },
            summary: {
                newSubscription: "Your new subscription:",
                trialPeriod: "30 days",
                nahtPeriod: "Next Year",
                freeTrial: "FREE trial",
                trialAllCourses: "All Courses",
                validUntil: "Valid until",
                subtotal: "Subtotal:",
                rebate: "Rebate:",
                discount: "Discount:",
                applyCoupon: "Apply coupon",
                couponName: "Have a coupon?",
                grandTotal: "Grand Total:",
                includingVAT: "including VAT",
                excludingVAT: "Total excl. VAT",
                outside: "Outside scope of UK VAT",
                vatAmount: "VAT Amount (20%)",
                grandTotalInclVAT: "Total incl. VAT",
                byClicking: `By clicking on the below you're sending a request to your school's finance manager to purchase
                this subscription on behalf of your school:`,
                inviteFinanceManager: "Invite Finance Manager:",
                isNoFinanceManagerMessage: `Please enter full name and email address of the person that you want to invite
                to join your account as finance manager - a user will be responsible for setting up payments for
                subscriptions and billing management`,
            },
        },
        link: {
            tab: "Links",
            addEdit: {
                headerAdd: "Add Link",
                headerEdit: "Edit Link",
                removeLink: "Are you sure you want to delete this Link?",
                targetTracker: "Target tracker",
                targetTrackerHint: "Where you want your link to be added to.",
                configureLink: "Configure Link",
                yearGroup: "Year group",
                cohort: "Academic Year",
                title: "Title",
                description: "Description",
                url: "URL",
                statusActive: "Active",
                statusInactive: "Inactive",
                titleReq: "Title is a required field",
                urlReq: "URL is a required field",
                urlStructure: "URL is not valid",
                descriptionReq: "Description is a required field",
                linkCreated: "Link created",
            },
            list: {
                addButton: "Add Link",
                table: {
                    editButton: "Edit",
                    title: "Title",
                    isActive: {
                        label: "is Active?",
                        true: "Yes",
                        false: "No",
                    },
                    students: "Students",
                    url: "URL",
                    makeActive: "Make Active",
                    makeInactive: "Make Inactive",
                    up: "position up",
                    down: "position down",
                },
            },
        },
        tag: {
            tab: "Tags",
            list: {
                backToTracker: "Back to tracker",
                backToAnalysis: "Back to analysis",
                name: "Name",
                author: "Author / Owner",
                status: "Status",
                subjects: "Subjects",
                yearGroups: "Year Groups",
                students: "Students",
                allSubjects: "All Subjects",
                allYearGroups: "All Year Groups",
                addButton: "Add Tag",
                filters: {
                    cohort: "Academic Year",
                    yearGroup: "Year Group",
                    subjectArea: "Subject Area",
                    qualification: "Course",
                    all: "All",
                    allYearGroups: "All Year Groups",
                    allSubjects: "All Subjects",
                },
                deleteTagPromptType: `Type "delete" if you are sure you want to remove this tag. This action is irreversible.`,
                deleteTagPromptHeader: "Remove tag",
                makeActive: "Make Active",
                makeInactive: "Make Inactive",
                makeLocked: "Lock Tag",
                showStudents: "Show Students",
            },
            status: {
                active: "Active",
                activeHint: "Can be viewed and edited on the trackers",
                inactive: "Inactive",
                inactiveHint: "Cannot be viewed on any trackers",
                locked: "Locked",
                lockedHint: "Can be viewed on the trackers, but not edited",
            },
            accessLevel: {
                all: "All",
                leader: "Leader",
                author: "Author",
            },
            addEdit: {
                tagCreated: "Tag created",
                headerAdd: "Add Tag",
                headerEdit: "Edit Tag",
                name: "Name",
                status: "Status",
                accessLevel: {
                    header: "Who can set value when this tag is used in a tracker?",
                    all: "All teachers",
                    leader: "Leader of",
                    author: "Only Me",
                },
                subjects: "Subjects",
                yearGroups: "Year Groups",
                all: "All",
                allHint: "This tag will be accessible on all trackers and in Analysis",
                selected: "Selected",
                selectedHint:
                    "This tag will only be accessible on the selected trackers and in Analysis",
                nameReq: "Name is required",
                addSpecification: "Add",
                yearGroupsReq: "At least one year group must be selected",
                specificationsReq: "At least one specification must be added",
            },
        },
        parent: {
            tab: "Parents",
            export: {
                link: "Link",
                hint: "If the download does not start automatically, please click on the link below",
            },
            status: {
                invited: "Invited",
                added: "Added",
                deactivated: "Deactivated",
                confirmed: "Confirmed",
            },
            requestModal: {
                requestBtn: "Request",
                criteria: "Criteria",
                allParents: "All parents",
                selectedParents: "Parents of selected students",
                selectedParentsCount: "only :count selected parents",
                allParentsCount: "all :count parents",
                subjectArea: "[Student] Subject Area",
                yearGroup: "[Student] Year group",
                selectSpecific: "Select specific entries",
                parentsOfSelectedStudents: "",
                form: "[Student] Form",
                firstname: "First name",
                lastname: "Last name",
                students: "Students",
                action: "Action",
                requestType: "Request Type",
                actionInvite: "Invite/Resend Invites to Parents",
                actionInviteDialog: {
                    header: "By clicking continue you will be sending emails to :count parents to download the Pupil Progress App. It will only send to parents if they have:",
                    condition1: "an email address",
                    condition2: "connected to students with a Date of Birth",
                    pleaseConfirm: "Please confirm",
                    sendInvites: "Send invites",
                    cancel: "Cancel",
                },
                actionDownloadParentReport: "Download Parents list",
                noneYg: "Select Year Group",
                exportSuccess: "Export successfull",
                noneSa: "Select Subject",
                inviteSuccess: "Invite sent",
            },
            list: {
                filters: {
                    name: "Name",
                    status: "Status",
                    studentName: "Student name",
                    studentUpn: "Student UPN",
                    userIdentifier: "User ID",
                    groupCallStatus: "Parent sync status",
                    parentGroupCallStatus: "Parent sync status",
                    studentYearGroup: "Student Year Group",
                    studentForm: "Student Form",
                    studentGroupCallStatus: "Student sync status",
                    filteruserIdentifier: "Filter by User ID",
                    filtergroupCallStatus: "Filter by Parent autosync status",
                    filterstudentGroupCallStatus: "Filter by Student sync status",
                    filterstudentYearGroup: "Filter by Student Year Group",
                    statusNonInvitable: "Non Invitable",
                    statusNotInvited: "Not Invited",
                    statusInvited: "Invited",
                    statusConfirmed: "Confirmed",
                    statusDeactivated: "Deactivated",
                    groupCallStatusActive: "Parent details synced daily",
                    groupCallStatusManual: "Not syncing parent details",
                },
                appStatusinvited: "Invited",
                appStatusconfirmed: "Confirmed",
                "appStatusnon-invitable": "Non invitable",
                "appStatusnot-invited": "Not invited",
                appStatusdeactivated: "Deactivated",
                activated: "Parent activated",
                activateError: "Error while activating parent",
                activate: "Activate",
                deactivate: "Deactivate",
                deactivated: "Parent deactivated",
                groupCallId: "Sync Id",
                groupCallStatus: "Sync Status",
                parentActions: "App Parent Actions",
                parentRemoved: "Parent removed",
                invitationsSent: "Invitations sent",
                uninvited: "Parent uninvited",
                status: "Status",
                adjustFilters: "Adjust filters",
                filter: "Filter by parent name",
                filterByStatus: "Filter by parent status",
                filterByStudentName: "Filter by student name",
                filterByStudentUPN: "Filter by student UPN",
                resendInvitation: "Resend Invitation",
                resendSuccess: "Invitation sent",
                groupCallStatusStudents: "Sync status",
                username: "Name",
                numberOfStudentsAndYearGroups: "Students (Year Groups)",
                groupCallStatusStudentActive: "Student details synced daily",
                groupCallStatusStudentManual: "Not syncing student details",
                groupCallStatusStudentDeleted: "Student no longer on the MIS_Name",
                groupCallStatusParentActive: "Parent details synced daily",
                groupCallStatusParentManual: "Not syncing parent details",
                groupCallStatusParentDeleted: "Parent no longer on the MIS_Name",
                groupCallStatusactive: "Parent details synced daily",
                groupCallStatusmanual: "Not syncing parent details",
                groupCallStatusActive: "Parent details synced daily",
                groupCallStatusManual: "Not syncing parent details",
                groupCallStatusdeleted: "Parent no longer on the {{MIS_Name}}",
                invite: "Send Invite",
                userIdentifier: "User ID",
                firstName: "First name",
                lastName: "Last name",
                phone: "Phone",
                email: "Email",
                uninvite: "Withdraw invite",
                numberOfStudents: "Number of students",
                addButton: "Add parent",
                deleteParentPromptHeader: "Delete parent",
                deleteParentPromptType: `Are you sure, you want do delete this parent? Type "delete" to confirm.`,
            },
            addEdit: {
                parentCreated: "Parent created",
                parentEdited: "Parent edited",
                parentUpdateError: "Parent update error",
                headerEdit: "Edit Parent User",
                headerAdd: "Add Parent User",
                firstNameReq: "First name is required",
                lastNameReq: "Last name is required",
                emailReq: "Email is required",
                phoneReq: "Phone is required",
                title: "Title",
                parentMerged: "Parents merged",
                parentMergeError: "Error while merging parents",
                dataSyncId: "DataSync ID",
                studentsUpdated: "Students updated",
                searchParentAutoPlaceholder: "Search by parents name",
                searchParentAutoPlaceholderHelperText:
                    "To find a parent user start typing their name above",
                warningHead: "Caution: Data may be overwritten",
                warning1:
                    "You will be merging :nonSyncedParent (non-synced parent) with :syncedParent (synced parent).",
                warning2:
                    "The data from :nonSyncedParent will replace all existing data for :syncedParent, including blanks.",
                warningcontinue: "Do you wish to continue with merge?",
                merge: "Merge",
                firstName: "First Name",
                lastName: "Last Name",
                email: "Email is required",
                phone: "Phone is required",
                exportSuccess: "Export succeeded",
                studentName: "Name",
                studentUpn: "Upn",
                studentStatus: "Status",
                searchStudentAutoPlaceholder: "Search by students name",
                searchStudentAutoPlaceholderHelperText:
                    "To find a student start typing their name above",
            },
        },
        students: {
            tab: "Students",
            importStudents: {
                header: "Import Students",
                importSuccess: "File uploaded",
                message: `Please upload filled students template list (XLS). Please note that uploading students details
                at school level requires more student information e.g. candidate numbers, gender, Pupil Premium.`,
                importFailed: "Import failed",
                importFailedProblem: "There's a problem with the XLS file that you’ve uploaded:",
                importDatabaseProblem: `We have found some existing students in your school's database with the same UPN (Unique Pupil Numbers) 
                    as ones that you're trying to upload`,
                downloadXlsTemplate: "Download XLS Template",
                uploadImportFile: "Upload Import File",
                help: "Do you need help uploading your students?",
                contactUs: "Contact Us",
                fullNameInDb: "Name in your school's databse",
                fullNameInFile: "Name in your file",
                proceedUpdate: "Add new students & update existing student's information",
                proceedDontUpdate: "Add new students only",
                enterConfirmHint: `You're about to change the names and sub-group information of the students above for the whole school.
                    Type in the word "confirm" in the box below to continue.`,
                enterConfirmPlaceholder: 'Please enter "confirm"',
                confirmAndUpdate: "Confirm and update student's data",
                listHint:
                    "Are all the students in the list above the same in our database as your upload?",
                scrollHint: "Scroll down the list to proceed.",
                studentsHasDifferentNamesBtn: "No - some students have different names",
                theSameBtn: "Yes - they are the same",
                differentNamesHint1:
                    "Check your upload file has the correct unique pupil number (UPN) for each student.",
                differentNamesHint2: "Every student should have a different UPN.",
                differentNamesHint3: "Once you have updated them, re-upload your file.",

                warningHead: "Caution: Data may be overwritten",
                warning1:
                    "You will be merging :nonSyncedStudent (non-synced student) with :syncedStudent (synced student).",
                warning2:
                    "The data from :nonSyncedStudent will replace all existing data in all subjects for :syncedStudent, including blanks.",
                warningcontinue: "Do you wish to continue with merge?",
            },
            requestModal: {
                criteria: "Criteria",
                allStudents: "All students",
                allStudentsCount: "all :count records",
                selectedStudents: "Selected students",
                selectedStudentsCount: "only :count selected rows",
                action: "Action",
                actionInvite: "Invite",
                actionInviteDialog: {
                    header: "By clicking continue you will be sending emails to :count students to download the Pupil Progress App. It will only send to students if they have an email address.",
                    pleaseConfirm: "Please confirm",
                    sendInvites: "Send invites",
                    cancel: "Cancel",
                },
                actionDownloadParentReport: "Download students list",
            },
            list: {
                addButton: "Add student",
                uniquePupil: "Unique pupil number",
                firstName: "First Name",
                lastName: "Last Name",
                gcse: "GCSE Exam Year",
                filter: "Filter by name",
                studentActions: "App Student Actions",
                deactivate: "Deactivate",
                activate: "Activate",
                groupCallId: "Sync Id",
                email: "E-mail",
                yearGroup: "Year Group",
                uniquePupilNumber: "Filter by UPN",
                invite: "Invite",
                uninvite: "Uninvite",
                resendInvitation: "Resend invitation",
                errorDownloadingStudentImportTemplate:
                    "Error while downloading students import template",
                errorRemoving: "Error removing stundent",
                parentName: "Filter by parent name",
                parentUserIdentifier: "Filter by parent ID",
                status: "App status",
                userIdentifier: "App username",
                onlyWithoutDate: "Only without date of birth",
                parents: "Parents",
                showParents: "Show parents",
                importStudents: "Import Students from XLS",
                exportStudents: "Export Students",
                exportStudentsFailed: "Export Students failed",
                deleteStudentPrompt: "Are you sure you want to delete student",
                deleteStudentPromptType: `Type "delete" if you are sure you want to remove this student. This action is irreversible.`,
                deleteStudentPromptHeader: "Remove student",
                appStatusinvited: "Invited",
                appStatusadded: "Added",
                appStatusconfirmed: "Confirmed",
                appStatusdeactivated: "Deactivated",
                groupCallStatusactive: "Student details synced daily",
                groupCallStatusmanual: "Not syncing student details",
                groupCallStatusdeleted: "Student no longer on the MIS_Name",
                groupCallStatusStudents: "Sync status",
                filters: {
                    name: "Name",
                    uniquePupilNumber: "Unique Pupil Number",
                    parentName: "Parent name",
                    parentUserIdentifier: "App Parent username",
                    parentGroupCallStatus: "Parent sync status",
                    groupCallStatus: "Sync status",
                    userIdentifier: "Username",
                    status: "Status",
                    yearGroup: "Year group",
                    form: "Form",
                    appUserIdentifier: "App User",
                    appStatus: "App status",
                    onlyWithoutDateOfBirth: "Only without date of birth",
                    statusAdded: "Added",
                    statusInvited: "Invited",
                    statusConfirmed: "Confirmed",
                    statusDeactivated: "Deactivated",
                    tag: "Tag",
                },
            },
            addEdit: {
                headerAdd: "Add New Student",
                headerEdit: "Edit Student",
                studentInvited: "Students invited",
                firstNameReq: "First name is a required field",
                lastNameReq: "Last name is a required field",
                uniqueNumberReq: "Unique number is a required field",
                uniquePupilNumber: "Unique Pupil Number",
                candidateNumber: "Candidate Number",
                admissionNumber: "Admission number",
                academicHouse: "Academic House",
                email: "E-mail",
                firstName: "First Name",
                studentDeactivated: "Student deactivated",
                yearGroup: "Year Group",
                status: "Status",
                studentMerged: "Students merged",
                errorCreatingStudent: "Error while creating student",
                userIdentifier: "User identifier",
                studentUpdated: "Student updated",
                studentCreated: "Student created",
                studentActivated: "Student activated",
                studentInvitationResend: "Student invitation resent",
                studentParentError: "Error while saving parent users",
                studentParentsEdited: "Student parents edited",
                studentMergeError: "Error while merging students",
                lastName: "Last Name",
                parentName: "Name",
                ethnicity: "Ethnicity",
                merge: "Merge students",
                searchParentAutoPlaceholder: "Search parent",
                searchParentAutoPlaceholderHelperText: "Search parent by first name or last name",
                delete: "Delete",
                gender: "Gender",
                userId: "User ID",
                readingAge: "Reading Age",
                nativeSpeaker: "Native Speaker",
                dateJoined: "Date Joined",
                form: "Form",
                dateOfBirth: "Date of Birth",
                senNeed: "SEN Need",
                senStatus: "SEN Status",
                priorAbilityH: "Higher",
                priorAbilityM: "Middle",
                priorAbilityL: "Lower",
                pupilPremium: "Pupil Premium",
                fsm: "FSM",
                lookedAfter: "Ever In Care",
                eal: "EAL",
                priorAbility: "Prior Attainment",
                ks2English: "KS2 English",
                ks2Math: "KS2 Math",
                ks2FineLevel: "KS2 Fine Level",
                ks2EnglishScaled: "KS2 English Reading Scaled",
                ks2MathScaled: "KS2 Math Scaled",
                ks2FineLevelScaled: "KS2 Average Scaled",
                ks2FineLevelScaledMatches:
                    "KS2 Fine Level Scaled must be less than 120 and be in X.X number format (0.5 interval)",
                ks2EnglishScaledMatches: "Invalid value. Must be 'N' or number between 59 and 120",
                ks2MathScaledMatches: "Invalid value. Must be 'N' or number between 59 and 120",
                ks2FineLevelScaledMin: "KS2 Fine Level Scaled must be greater than 0",
                ks2FineLevelScaledMax: "KS2 Fine Level Scaled must be less than 120",
                addStudentButton: "Add Student",
                editStudentButton: "Edit Student",
                studentEdited: "Student edited",
                studentAdded: "Student created",
                cats: "Cognitive Ability Tests (CATs)",
                catVerbal: "Verbal",
                catNonVerbal: "Non-verbal",
                catMean: "Mean",
                catQuantitative: "Quantitive",
                catSpatial: "Spatial",
                ks4Scores: "KS4 Attainment Scores",
                ks2Scores: "KS2 Scores",
                catScores: "Cognitive Ability Test (CAT) Scores",
                ks4Aps: "KS4 Academic Average Point Score",
                ks4Agaps: "KS4 Applied General Average Point Score",
                errorSavingParents: "Error while saving parents",
                errorMergingStudent: "Error merging student",
                studentRestrictedPropsHint:
                    "Some attributes may be overwritten if a value is different in the MIS_Name",
            },
        },
        school: {
            customFields: {
                dataWillBeLost:
                    "Data atached to that Learning Characteristics will be lost. Are you sure?",
                tab: "Learning Characteristics",
                saveAllBtn: "Add Selected Learning Characteristics to Trackers",
                saveWarning:
                    "All Learning Characteristics can only be defined and saved once - if you want to make further edits you will need to contact our support team. <p/> <p> Your choice for all six Learning Characteristics are about to be saved and added to your trackers.<p/> <p> Type “continue” to proceed",
                saveSuccess: "Updating the Learning Characteristics displayed on your trackers",
                yearGroups: "Year Groups: ",
                addNewSet: "Add new set to the following Year Groups:",
                displayedName: "Learning Characteristic Name",
                addNewSetting: "Enable for specific Year Groups",
                downBtn: "Down",
                upBtn: "Up",
                marks: "Marks",
                saveYes: "Add to trackers",
                description: "Descriptor",
                useDefault: "Use default values",
                enableField: "Enable field",
                enableFieldAll: "Enable for all Year Groups",
                enableFieldExcept: "Enable for all Year Groups, except :yearGroups",
                noPreviousData: "No previous data",
                valuesLocked:
                    "These values have been locked. To make changes, please contact our support team on <a href='mailto:support@pupilprogress.com'>support@pupilprogress.com</a> or using the chat icon",
            },
            myschool: {
                header: "My school",
            },
            schoolDetails: {
                tab: "School details",
                contactHint: "School details doesn't look right?",
                contactUs: "Contact us",
                uploadLogo: "Upload school logo",
                deleteLogo: "Delete logo",
                removeLogoConfirm: "Are you sure you want to delete school logo?",
                privateUrl: "Privacy policy URL",
                contactEmail: "Contact email",
                savePrivateUrl: "Save",
                saveEdit: "School details has been saved.",
                fineGrades: "Enable fine grades",
                lockMtg: "Lock MTGs",
                setNewLockMtgList: "Choose Year Groups with locked MTGs",
            },
            subjectAreas: {
                tab: "Subject Areas",
                teachersHint:
                    "can create, use and see all classes associated with any qualification from this subject area",
                leaderHint: `can create and see all classes associated with any qualification from this subject area 
                - as well as manage subject area teachers`,
                search: "Search by person's name ... ",
                addteacher: "Add teacher",
                addleader: "Add leader",
                filterPlaceholder: "Subject Area",
                admin: "Administrator",
            },
            snapshots: {
                tab: "Snapshots",
                rename: "Name your snapshot",
                cohortFilterLabel: "Academic Year",
                createNewSnapshot: "Add new school-level snapshot",
                note: "Creating snapshots at school level adds Year Group level snapshots for all your classes and year groups. School-level snapshots are used in Analysis.",
                created: "Created",
                isCreating: "creating...",
                deleteSnapshotPromptType: `Type "delete" if you are sure you want to remove this snapshot. This action is irreversible.`,
                deleteSnapshotPromptHeader: "Delete snapshot",
                errorRemoving: "Error while removing snapshot",
                errorCreating: "Error while creating snapshot",
                name: "Name",
                hasReports: "Published Reports?",
                showPublishedReports: "Show Published Reports",
                renameSnapshotDialogHeader: "Name your snapshot",
            },

            staff: {
                tab: "Staff",
                user: {
                    status: {
                        active: "Active",
                        inactive: "Inactive",
                        deactivated: "Deactivated",
                        pending: "Pending",
                        confirmation: "Requires confirmation",
                        invited: "Invited",
                        "not invited yet": "Not invited yet",
                    },
                },
                addEdit: {
                    addHeader: "Add New Staff",
                    editHeader: "Edit Staff",
                    title: "Title",
                    firstName: "First Name",
                    lastName: "Last Name",
                    userRole: "User Role",
                    email: "E-mail",
                    jobTitle: "Job title",
                    schoolTitle: "Current Role",
                    userCode: "Staff code",
                    teacherOfHeader: "Teacher of",
                    merge: "Merge",
                    dataSyncId: "DataSync ID",
                    leaderOfHeader: "Leader of",
                    subjectArea: "Subject Area",
                    staffMerged: "Staff merged",
                    staffMergeError: "Error while merging staff",
                    all: "ALL",
                    editStaffButton: "Edit Staff",
                    titleReq: "Title is a required field",
                    firstNameReq: "First name is a required field",
                    lastNameReq: "Last name is a required field",
                    emailReq: "Email is a required field",
                    emailType: "Email is not valid",
                    staffAdded: "Staff added",
                    staffEdited: "Staff edited",
                    searchStaffAutoPlaceholder: "Search by staff name",
                    searchStaffAutoPlaceholderHelperText:
                        "To find a staff start typing their name above",
                    subjectAreasSynchronizationEnabled: "Sync Subject Areas",
                },
                list: {
                    addButton: "Add Staff",
                    resendInvitations: "Resend Invitation Emails",
                    inviteManager: "Invite Finance Manager",
                    importStaff: "Import staff from xls",
                    filterByName: "Filter by name",
                    filterByStatus: "Filter by status",
                    filterByStatusSync: "Filter by Sync status",
                    status: "Status",
                    role: "Role",
                    details: "Details",
                    teacher: "Teacher Of",
                    leader: "Leader Of",
                    makeActiveAction: "Make active",
                    makeInactiveAction: "Make Inactive",
                    upgradeAdmin: "Upgrade to administrator",
                    downgradeUser: "Downgrade to user",
                    makeFinance: "Make finance manager",
                    groupCallStatus: "DataSync Link",
                    groupCallStatusClasses: "Classlist sync status",
                    groupCallStatusStaff: "Staff sync status",
                    groupCallStatusStudents: "Student sync status",
                    groupCallStatusClassActive: "Class lists synced daily",
                    groupCallStatusClassManual: "Not syncing class lists",
                    groupCallStatusClassDeleted: "Class no longer on the MIS_Name",
                    groupCallStatusStaffActive: "Staff details synced daily",
                    groupCallStatusStaffManual: "Not syncing staff details",
                    groupCallStatusStaffDeleted: "Staff no longer on the MIS_Name",
                    groupCallStatusStudentactive: "Student details synced daily",
                    groupCallStatusStudentmanual: "Not syncing student details",
                    deleteUserPromptHeader: "Remove staff",
                    deleteUserPrompt: `Type "delete" if you are sure you want to remove this user. This action is irreversible.`,
                    importFailed: "Import failed!",
                    importFailedProblem:
                        "There's a problem with the XLS file that you’ve uploaded:",
                    importSuccess: "Staff imported successfully",
                    invitePendingPrompt:
                        "Are you sure you want to send emails to all staff with pending status?",
                    inviteFinance: "Invite Finance Manager",
                    inviteFinanceMessage: `Please enter name and email adresses of the person you want to invite to join your
                    account as finance manager - a user that will be responsible for setting up payment for subscription and 
                    billing management`,
                    inviteFinanceTitle: "Title",
                    inviteFinanceFirstName: "First name",
                    inviteFinanceLastName: "Last name",
                    inviteFinanceEmail: "Email",
                    inviteFinanceTitleReq: "Title is a required field",
                    inviteFinanceFirstnameReq: "First name is a required field",
                    inviteFinanceLastnameReq: "Last name is a required field",
                    inviteFinanceEmailReq: "Email is a required field",
                    inviteFinanceEmailEmail: "Email is not valid",
                    inviteFinanceButton: "Send Invitation Email",
                    financeInvited: "Invitation email has been sent",
                    invitationEmail: "Send Invitation Email",
                    invitationSent: "Invitation email has been sent",
                    activated: "Account activated",
                    deactivated: "Account deactivated",
                    invitationError: "Error occured while sending invitation email",
                    deleteError: "Error occured while removing staff",
                    importStaffPrompt: "Import Staff",
                    importStaffPromptMessage: `Please upload all users that you want to add using the staff template list (XLS)
                    available below`,
                    downloadXlsTemplate: "Download XLS Template",
                    uploadImportFile: "Upload Import File",
                    confirm: "Confirm",
                },
            },
        },
        tracker: {
            maintenance:
                "Further changes cannot be made while we are updating your tracker with requested changes. You will be redirected shortly.",
            moveStudents: {
                currentClass: "Current class",
                moveTo: "Move to",
                moveToTier: "Move to tier",
                setAllTiers: "Set all to :tier",
            },
            changeGradeBoundaries: {
                max: "Max",
                grade: "Grade",
                changeTitle: "Change Grade Boundaries",
                series: "Grade Boundaries Series",
                changeTextFieldLabel: "Grade Boundaries Series",
                adjustBoundaries: "Adjust Grade Boundaries",
                adjust: "Adjust %",
                adjustHint: "Your row data will be unaffected when you change grade boundaries",
                saveClose: "Save & Close",
                changed: "Grade Boundaries changed",
            },
            snapshot: {
                viewHeader: "Snapshots",
                creatingHint: `Creating snapshots is only possible at Year Group level and can only be done by a Leader of Subject`,
                source: "Source",
                liveTracking: "Live Tracking",
                forecast: "Forecast",
                viewSnapshotBtn: "View",
                removeSnapshotBtn: "Remove",
                removed: "Snapshot removed",
                compareHeader: "Compare snapshots",
                compareBtn: "Compare",
                createSnapshotHeader: "Create Snapshot",
                compareSnapshotHeader: "Compare Snapshots",
                removeSnapshotMessage: "Are you sure you want to delete snapshot?",
                snapshotCreatedMsg: "Snapshot will be generated shortly. Please wait...",
            },
            forecast: {
                clearForecast: "Clear forecast",
                chooseClearForecast: `Choose if you want to load/clear data for whole tracker or 
                only within selected units:`,
                dataSource: "Data Source",
                wholeTreackerOption: "Whole tracker",
                errorClearingDataMsg: "Error while clearing tracker forecast data",
                loadData: "Load Data",
                checkHint1:
                    "You are about to load (copy) data from Live Tracking sheet into forecast sheet.",
                checkHint2: `Please choose if you want to load (copy) data from the whole tracker, or
                only from selected unit:`,
                forceHint1Part1: `Some data in the selected unit (`,
                forceHint1Part2: `) overlaps with existing data in your Forecast sheet`,
                forceHint2: "Do you want to overwrite overlapping data in your Forecast sheet?",
                forceOverwrite: "Yes, overwrite",
                keepExisting: "No, keep existing data",
                errorWhileForceLoadMsg: "Error loading Forecast data",
            },
            grid: {
                classTracker: "Class name",
                yearGroupOverview: "Year Group Overview",
                tier: "Tier",
                exitBulkEditBtn: "Exit Bulk Edit",
                toolsTracker: "Tracker",
                toolsStudents: "Students",
                needHelpBtn: "Need help?",
                findStudent: "Find Student",
                viewSnapshot: "View Snapshot",
                geadeBoundarySeries: "Grade Boundaries",
                allTagsWaiting:
                    "You’re going to assign the selected tag/link to all students in the tracker. Are you sure?",
                gradeBoundaryChangeBtn: "Change",
                downloadBtn: "Download",
                bulkEditBtn: "Bulk edit",
                editTrackerOptionsBtn: "Edit tracker options",
                downloadClassReports: "Download class reports",
                selectedSeries: "Compare to PiXL Waves",
                subjectAnalysis: "Subject analysis",
                pixlSeriesRemoved: "PiXL series selected for this tracker have been removed",
                addAssessment: "Add Assessments",
                editAssessmentBtn: "Edit Assessments",
                moveStudents: "Move students",
                moveStudentsTiers: "Move tiers or class",
                moveStudentsTiersSynced: "Move students' tier",
                moveStudentsHint: "This student is currently being moved to a different class",
                studentsMovedError: "Error while moving students",
                closeMoveStudents: "Close move students",
                studentsMoved: "Students moved",
                simpleEditBtn: "Simple edit",
                saveBulkEdit: "Save changes",
                discardBulkEdit: "Exit without saving",
                report: "Report",
                liveTrackingBtn: "Live Tracking",
                forecastBtn: "Forecast",
                snapshotsBtn: "Snapshots",
                forecastLoadDataBtn: "Load Data",
                clearForecastBtn: "Clear Forecast",
                yearGroup: "Year Group",
                topicSummary: "Topic Summary",
                averageRow: "Average",
                justEditedStudentInfo: "has edited student data few seconds ago",
                reloadGrid: "Reload grid",
                ignoreChanges: "Ignore changes",
                saveChangesBtn: "Save changes",
                tagsToggle: "Tagging",
                links: {
                    header: "Configure Linking",
                    headerAlt: "Create New Link",
                    hint: "Select which linking columns you wish to display on the tracker.",
                    filter: "Filter",
                    visible: "Show",
                    tagName: "Name",
                    linkName: "Name",
                    saveBtn: "Save & Close",
                    addLink: "Create New Link",
                    editLink: "Edit Link",
                },
                tags: {
                    header: "Configure Tagging",
                    headerAlt: "Create New Tag",
                    hint: "Select which tagging columns you wish to display on the tracker.",
                    filter: "Filter",
                    visible: "Show",
                    tagName: "Name",
                    students: "Students",
                    saveBtn: "Save & Close",
                    addTag: "Create New Tag",
                    editTag: "Edit Tag",
                    goToTagsList: "Go to Tags List",
                },
                linksToggle: "Linking",
                configureColumns: {
                    dateOfBirth: "Date of Birth",
                    email: "E-mail",
                    yearGroup: "Year Group",
                    header: "Configure Columns",
                    id: "Id",
                    firstName: "First name",
                    lastName: "Last name",
                    uniquePupilNumber: "Unique pupil number",
                    candidateNumber: "Candidate number",
                    admissionNumber: "Admission number",
                    form: "Form",
                    readingAge: "Reading age",
                    isMoving: "Is moving",
                    nativeSpeaker: "Native speaker",
                    dateJoined: "Date joined",
                    pupilPremium: "Pupil Premium",
                    fsm: "FSM",
                    lookedAfter: "Ever In Care",
                    fromDateJoined: "Date Joined From",
                    toDateJoined: "Date Joined Before",
                    ethnicity_name: "Ethnicity",
                    ethnicityName: "Ethnicity",
                    ethnicity_id: "Ethnicity Id",
                    eal: "EAL",
                    gender: "Gender",
                    priorAbility: "Prior Attainment",
                    ks2English: "KS2 English",
                    ks2Math: "KS2 Math",
                    ks2FineLevel: "KS2 Fine Level",
                    ks2EnglishScaled: "KS2 English Scaled",
                    ks2MathScaled: "KS2 Math Scaled",
                    ks2FineLevelScaled: "KS2 Average Scaled",
                    cats: "Cognitive Ability Tests (CATs)",
                    catVerbal: "Verbal",
                    academicHouse: "Academic house",
                    catNonVerbal: "Non-verbal",
                    catMean: "Mean",
                    catQuantitative: "Quantitive",
                    catSpatial: "Spatial",
                    ks4Aps: "KS4 Academic Average Point Score",
                    ks4Agaps: "KS4 Applied General Average Point Score",
                    senStatus: "Sen status",
                    ethnicity: "Ethnicity",
                    senNeed: "Sen need",
                    gradeId: "Grade",
                    student_id: "Id",
                    student_firstName: "First name",
                    student_lastName: "Last name",
                    student_uniquePupilNumber: "Unique pupil number",
                    student_candidateNumber: "Candidate number",
                    student_admissionNumber: "Admission number",
                    student_form: "Form",
                    student_readingAge: "Reading age",
                    student_academicHouse: "Academic house",
                    student_nativeSpeaker: "Native speaker",
                    student_dateJoined: "Date joined",
                    student_pupilPremium: "Pupil Premium",
                    student_fsm: "FSM",
                    student_lookedAfter: "Ever In Care",
                    student_ethnicity_name: "Ethnicity",
                    student_ethnicityName: "Ethnicity",
                    student_ethnicity_id: "Ethnicity Id",
                    student_eal: "EAL",
                    student_gender: "Gender",
                    student_priorAbility: "Prior Attainment",
                    student_ks2English: "KS2 English",
                    student_ks2Math: "KS2 Math",
                    student_ks2FineLevel: "KS2 Fine Level",
                    student_ks2EnglishScaled: "KS2 English Scaled",
                    student_ks2MathScaled: "KS2 Math Scaled",
                    student_ks2FineLevelScaled: "KS2 Average Scaled",
                    student_cats: "Cognitive Ability Tests (CATs)",
                    student_catVerbal: "Verbal",
                    student_catNonVerbal: "Non-verbal",
                    student_catMean: "Mean",
                    student_catQuantitative: "Quantitive",
                    student_catSpatial: "Spatial",
                    student_ks4Aps: "KS4 Academic Average Point Score",
                    student_ks4Agaps: "KS4 Applied General Average Point Score",
                    student_senStatus: "Sen status",
                    student_ethnicity: "Ethnicity",
                    student_senNeed: "Sen need",
                    student_gradeId: "Grade",
                    save: "Configure columns",
                    classTracker: "Class name",
                    classTracker_id: "Class tracker Id",
                    classTrackerId: "Class name",
                    classTrackerName: "Class name",
                },
            },
        },
        parentAppProxy: {
            header: "First you need to install the app on your mobile device.",
            text1: "Then re-click the link in the email on the same mobile device.",
        },
        report: {
            actions: {
                studentsSelected: "{x} students selected",
                requestBtn: "Request Report",
                refreshBtn: "Retry request",
                onlySummary: "Summary only",
                locked: "You've already requested 5 other reports which are still pending",
                lockedWait:
                    "Please wait until you receive download links for previous reports before you request a new one.",
            },
            snapshot: {
                create: {
                    header: "Publish New Report",
                    cohortFilterLabel: "Academic Year",
                    subjectReportTab: "Subject Report",
                    ygReportTab: "Year Group Report",
                    currentlySelected: ":selected selected",
                    selectCtgButton: "Save selected",
                    selectHeader: "Select Groups for report",
                    changeSelection: "Change selection",
                    allTrackers: "All trackers",
                    selectedTrackers: "Selected Tracker Groups",
                    summaryReportHint:
                        "Publish a simple overall top level summary for each student which shows all the subjects a student is taking along with their overall grades",
                    subjectReportHint:
                        "Publish a detailed report showing a breakdown of student attainment in one specific course",
                    allSubjectReportHint:
                        "Publish a detailed report showing a breakdown of student attainment in each of the subjects they are taking",

                    publishBtn: "Publish Report to App",
                    subjectArea: "Subject Area",
                    qualification: "Qualification",
                    specification: "Specification",
                    noSnapshots: "Cannot publish reports due to lack of snapshots",
                    noSubjects: "Cannot publish reports due to lack of subjects",
                    yearGroup: "Year Group",
                    yearGroupRequired: "Year Group is required",
                    snapshot: "Snapshot (report title)",
                    hint1: `First, please choose a course for which you want to publish a report:`,
                    hint2: `And then select YG and YG-level snapshot:`,
                    publishTypeNow: "Publish now",
                    publishTypeFuture: "Publish in the future",
                    reportTemplate: "Report Template",
                    reportTemplateRequired: "Report Template Required",
                    snapshotRequired: "Snapshot Required",
                    subjectReportSuccess: "Subject Report created",
                    subjectReportError: "Error while creating Subject Report",
                    summaryReport: "Summary Report",
                    allSubjectsReport: "All Subjects Report",
                    subjectReport: "Subject Report",
                    publishFutureDate: "Publish date",
                },
                list: {
                    header: "App Reports",
                    createBtn: "Publish New Report",
                    cancel: "Cancel Report",
                    filterSnapshotName: "Filter by Snapshot name",
                    filterSubjectArea: "Filter by Subject Area",
                    filterCourse: "Filter by Course",
                    filterYearGroup: "Filter by Year Group",
                    filterType: "Filter by Type",
                    filterStatus: "Filter by Status",
                    filterAcademicYear: "Filter by Academic Year",
                    academicYear: "Academic Year",
                    status: "Status",
                    publicationDate: "Publication Date",
                    type: "Type",
                    yearGroup: "Year Group",
                    snapshotName: "Snapshot / Report Name",
                    goToSnapshotBtn: "Go to snapshot",
                    publishBtn: "Re-publish",
                    withdrawBtn: "Withdraw",
                    reportType: "Report Type",
                    reportTypeStatescheduled: "Scheduled",
                    reportTypeStatequeued: "Queued",
                    reportTypeStatecancelled: "Canceled",
                    reportTypeStateprocessing: "Processing",
                    reportTypeStateready: "Ready",
                    reportTypeStatepublished: "Published",
                    reportTypeStatewithdrawn: "Withdrawn",
                    reportTypeStateerror: "Error",
                    publishedAt: "Published At",
                    reporyType: "Report Type",
                    reportTypeYearGroup: "Year Group",
                    reportTypesubject: "Subject",
                    reportTypesummary: "Summary",
                    "reportTypeall-subjects": "All subjects",
                    publish: "Publish",
                    withdraw: "Withdraw",
                    deletePromptHeader: "Delete Snapshot Report",
                    deletePromptType: `Are you sure you want to delete this report? Type "delete" to confirm.`,
                },
            },
            studentReport: {
                header: "Downloadable Reports",
                download:
                    "If the download does not start automatically, please click on the link below",
                link: "Link",
                note: `Please note that once you request a report for one or more students it may take us a while
                    to prepare it for you - so we will let you know by email when it is ready for you to download.`,
                tabs: {
                    byStudent: "By Student",
                    bySubject: "By Subject",
                    byAttribute: "By Attribute",
                    byYearGroup: "By Year Group",
                    reportTemplate: "Report Template",
                    byTag: "By Tag",
                },
                byTag: {
                    header: "Choose Tag",
                    hint: `You are able to download report for all students from choosed Tag`,
                    autocompletePlaceholder: "Search by tag's name...",
                    requestSuccess: "Report request has been created",
                    requestError: "Error while requesting report. Please wait...",
                    source: "Source",
                    liveTracking: "Live Tracking",
                    requestErrorConflict:
                        "Error while requesting report. Academic Year is archiving now. Please wait...",
                },
                byStudent: {
                    header: "Choose Student",
                    hint: `You will request a single report across all subject areas & classes for selected student`,
                    autocompletePlaceholder: "Search by student's name...",
                    requestSuccess: "Report request has been created",
                    requestError: "Error while requesting report. Please wait...",
                    source: "Source",
                    liveTracking: "Live Tracking",
                    requestErrorConflict:
                        "Error while requesting report. Academic Year is archiving now. Please wait...",
                },
                bySubject: {
                    header: "Choose Subject",
                    hint: `You can also narrow down to a specific class or student within that class`,
                    selectSubjectArea: "Select subject area",
                    selectClass: "Select class",
                    selectStudent: "Select student",
                    selectQualification: "Select qualification",
                    selectSpecification: "Select specification",
                    requestSuccess: "Report request has been created",
                },
                byAttribute: {
                    header: "Choose Attribute(s)",
                    hint: `Select student(s) by their attributes and request reports for all matching pupils at once`,
                    ethnicity: "Ethnicity",
                    gender: "Gender",
                    academicHouse: "Academic House",
                    form: "Form",
                    senNeed: "SEN Need",
                    source: "Source",
                    senStatus: "SEN Status",
                    priorAbilityH: "Higher",
                    priorAbilityM: "Middle",
                    priorAbilityL: "Lower",
                    priorAbilityAll: "All",
                    priorAbilityEveryone: "Everyone",
                    pupilPremium: "Pupil Premium",
                    fsm: "FSM",
                    lookedAfter: "Ever In Care",
                    eal: "EAL",
                    giftedTalented: "Gifted & Talented",
                    priorAbility: "Prior Attainment",
                    all: "All",
                    everyone: "everyone",
                    informationNotObtained: "Information not obtained",
                    refused: "Refused",
                },
                byYearGroup: {
                    header: "By Year Group",
                    yearGroup: "Year Group",
                    downloadHint: "You are about to download report for all students from",
                    allTrackerGroups: "All Tracker Groups",
                    selectedTrackerGroups: "Selected Class Tracker Groups",
                    chooseAgain: "Choose again",
                },
            },
            reportTemplate: {
                tab: "Report Templates",
                errorRemoving: "Error while removing Report Template",
                createNewBtn: "Add New Report Template",
                createdMessage: "Report template created",
                updatedMessage: "Report template saved",
                name: "Name",
                addReportTemplateButton: "Add Report Template",
                isDefault: "Is Default?",
                setAsDefault: "Set as default",
                deleteConfirmation: "Are you sure you want to delete report template?",
                addEdit: {
                    name: "Name",
                    filesHeader: "Files",
                    fileType: "",
                    individualType: "Individual Student Report File Name",
                    mergeType: "Merge all reports into one file",
                    gradesHeader: "Grades",
                    hideMtg: "Hide MTG",
                    colourHeader: "Colour settings",
                    hideDistanceFromMtgColours: "Hide Distance From MTG Colour",
                    fileNamePattern: "{FirstName} {UniquePupilNumber}",
                    fileNamePatternHint: "Possible values: ",
                    hideCurrentGrade: "Hide Current Grade",
                    hideAverageUnitGrade: "Hide Average Unit Grade",
                    hideTpg: "Hide Teacher Predicted Grade",
                    headerEdit: "Edit Report Template",
                    headerAdd: "Add New Report Template",
                    editReportTemplateButton: "Save Template",
                    addReportTemplateButton: "Add Template",
                    reportTemplateCustomFieldsFieldType: "Field Type",
                    customFieldHeader: "Learning Characteristics",
                    customFieldHint:
                        "Select which Learning Characteristics you wish to display as a new column on the summary page of the report.",
                    customFieldHint2:
                        "Only Learning Characteristic that are enabled for the selected year group will show on the summary report.",
                    customFieldHidden: "Hide",
                    customFieldFromLabel: "Display as text",
                    customFieldTypeattitude: "Attitude to Learning",
                    customFieldTyperesilience: "Resilience",
                    customFieldTypeeffort: "Effort",
                    customFieldTypehome: "Home Learning",
                    customFieldTypeindependent: "Independent Learning",
                    customFieldTypeorganisation: "Organisation",
                    fileNamePreview: "File name preview:",
                },
            },
        },
        class: {
            header: "Trackers",
            addClassButton: "Add New Class",
            migrateClassesButton: "Copy trackers from previous Academic Year",
            showStudents: "Show Students",
            sendClassLists: "Send class lists",
            yourClasses: "Your Classes",
            pastPapers: "Published Assessments",
            endOfTopicTests: "Customised Assessments",
            assessment: {
                header: "Assessment list",
                saveChanges: "Save changes",
                quickEdit: "Quick edit",
                uncheckAllAssessments: "Hide all assessments",
                discardChanges: "Discard changes",
                backToTracker: "Back to tracker",
                recoveryModal: {
                    header: "Assessments recovery",
                    hint1: "We've recovered the following assessments from your last session.",
                    hint2: "Please select the ones you wish continue editing:",
                    subjectArea: "Subject area",
                    qualification: "Qualification",
                    specification: "Specification",
                    academicYear: "Academic Year",
                    yearGroup: "Year Group",
                    numberOfClasses: "Number of classes",
                    recoverBtn: "Recover selected assessments",
                    deleteBtn: "Delete these unsaved assessments and start again",
                },
                modal: {
                    header: "Add assessments",
                    hint: "Choose the type of assessment you want to add",
                    ppTitle: "Published assessment",
                    ppText: "Select a published assessment - add columns for an assessment published by the exam board we have already built.",
                    eottTitle: "Customised assessment",
                    eottText:
                        "Build customised assessment - add your own columns and grade boundaries.",
                    copyTitle: "Copy existing assessment",
                    copyText1:
                        "Use if you want to copy an assessment from another tracker or year group.",
                    copyText2: "You can also copy and then modify a Published  Assessment.",
                },
                list: {
                    cohortFilterLabel: "Academic Year",
                    addAssessmentBtn: "Add Assessments",
                    countTowards: `Count Towards <br />Summary Grades`,
                    gradeBoundaries: "Grade Boundaries",
                    name: "Name",
                    type: "Type",
                    gradeShift: "Grade Boundaries",
                    hiddenColumns: "Hide Grade for this Assessment",
                    showQlaInReports: "Show QLA in reports",
                    gradeShiftMin10: "Much Easier (-10%)",
                    gradeShiftMin5: "Easier (-5%)",
                    gradeShift0: "Standard (0%)",
                    gradeShift5: "Harder (+5%)",
                    gradeShift10: "Much Harder (+10%)",
                    gradeShiftCustom: "Custom Grade Boundaries",
                    noAssessments: "No assessments",
                },
                add: {
                    header: "Add Assessments",
                    yearGroup: "Year Group",
                    academicYear: "Academic Year",
                    targetTracker: "Target tracker",
                    waitingForTracker: "Waiting for tracker...",
                    tierWarning:
                        "Recommended: Choose a unit for <b><u>all</u> tiers</b> if students are likely to move tiers - <a target='_blank' href='https://helpcentre.pupilprogress.com/en/articles/73037-how-do-i-change-the-tier-a-student-is-on'>see article</a>",
                    targetTrackerHint: "Where you want your new assessment to be added to.",
                    customisedTargetTrackerHint:
                        "We advise adding to both tiers so the data still shows when students switch tiers.",
                    copyAssessment: "Copy assessment",
                    hiddenColumns: "Hide Grade for this Assessment",
                    delete: "Delete",
                    showQlaInReports: "Show QLA in Reports",
                    selectSource: "Select source",
                    selectSourceHint: "Where you are copying the assessment from.",
                    successCreated: "Assessments will be created shortly",
                    noAssessments: "No Assessments available",
                    gradeBoundaryValuesError:
                        "Check your Custom grade Boundary Marks are increasing along with the increasing grade",
                    createAssessments: "Create assessments",
                    assessmentType: "Assessment type",
                    selectModule: "Select module",
                    selectDeselectAll: "Select / deselect all",
                    hideForNow: "Add to all but hide for now",
                    noClasses: "No classes for selected specification & year group",
                    noUnits: "No units enabled for this assessment type",
                    assessment: "Assessment",
                    unit: "Unit",
                    convertsTo: "converts to",
                    unitSelect: "Select unit",
                    name: "Name",
                    saveAssessmentsWarning: `Remember to save the form before moving to another page. The "Save and collapse" option saves data in the cache. To add assessments, click the "create assessments" button`,
                    saveAndCollapse: "Save and collapse",
                    numberOfClasses: "Number of classes",
                    chooseClassHint:
                        "Choose classes in which you want to set this assessment as visible",
                    assessmentpp: "Published assessment",
                    assessmenteott: "Customised assessment",
                    copySelected: "Copy selected",
                    assessmentSelect: "Select assessment",
                    assessmentName: "Assessment Name",
                    changeType: "Choose a different assessment type",
                    tier: "Tier",
                    selectSpecification: "Please select specification",
                    countsTowardGrade: "Count towards Unit and Summary Grades",
                    repeatableStructure:
                        "This unit has a repeatable structure, so you don't need to choose a paper associated to a specific year.",
                    selectUnit: "Select Unit",
                    module: "Assessment Series",
                    addAssessment: "Add Another Assessment",
                    moduleType: "Published Assessment Type",
                    configure: "Configure Assessment",
                    pleaseSelectSpecification: "Please select specification",
                    targetClasses: "Target classes",
                    gradeBoundaries: "Grade boundaries",
                    pleaseSelectYg: "Please select specification & Year Group",
                    dataEntry: "Data entry",
                    singleMark: "Single mark",
                    qla: "Question level Analysis (QLA) | Section Level Analysis (SLA)",
                    question: "Question",
                    maxValue: "Max value",
                    gradeBoundaryType: "Grade boundary Type",
                    sameAsTracker: "Same as tracker",
                    gradeBoundaryFactor: "Grade Boundaries shift",
                    noTopics: "No topics selected",
                    custom: "Custom Grade Boundaries",
                    classChooseHint:
                        "Choose classes in which you want to set this assessment as visible",
                    removeQLA: "Remove",
                    up: "Move up",
                    down: "Move down",
                    duplicate: "Duplicate",
                    gradeShift: "Grade Boundaries shift",
                    gradeShiftMin10: "Much Easier (-10%)",
                    gradeShiftMin5: "Easier (-5%)",
                    gradeShift0: "Standard (0%)",
                    gradeShift5: "Harder (+5%)",
                    gradeShift10: "Much Harder (+10%)",
                    gradeShiftHint: `Do you want to shift the grade boundaries so it is easier or 
                    harder to achieve a grade?`,
                    requireMark: "Mark",
                    totalMaxMarks: "Total max marks",
                    customHint1: `Enter the <b>Raw Mark</b> grade boundaries you wish to use for this assessment - see <a target="_blank" href="https://support.pupilprogress.com/helpcentre/how-can-i-add-my-own-grade-boundaries">How can I add my own custom grade boundaries to my assessment?</a> for more guidance.`,
                    customHint2: `If you are <b>capping</b> the top grades (e.g. for tiers), then enter a raw mark that exceeds the maximum raw mark for the test to make it unreachable.`,
                    customHint3: `If you want to skip lower grades, then put them all on the same lowest mark - the highest of these grades will then be awarded.`,
                    customHint4: `Your students' <b>Raw Marks</b> will be converted to an equivalent scaled mark to match the grade boundaries set for your tracker so they contribute an appropriate marks towards the Summary Grades.`,
                },
            },
            endOfTopicTest: {
                header: "Customised Assessments",
                table: {
                    addButton: "Add Customised Assessment",
                    name: "Name",
                    classes: "Class(es)",
                    course: "Course",
                    unit: "Unit",
                    countsTowardGrade: "Count Towards Current <1/>& Projected Grade",
                    onTracker: "On tracker",
                    gradeShift: "Grade Boundaries",
                    gradeShiftMin10: "Much Easier (-10%)",
                    gradeShiftMin5: "Easier (-5%)",
                    gradeShift0: "Standard (0%)",
                    gradeShift5: "Harder (+5%)",
                    gradeShift10: "Much Harder (+10%)",
                    gradeShiftCustom: "Custom Grade Boundaries",
                    deleteConfirmation: "Are you sure you want to delete this assessment?",
                    removeSuccess: "Assessment removed",
                    warningHint: `This will remove this assessment and <b> all the marks </b> on it from all classes in this year group. 
                    Once the assessment is removed <b> you will not be able to restore any marks entered</b>.`,
                    warningConfirm: `If you want to continue removing the assessment and all its data, please type the word "delete" in the box below`,
                    removeError: "Error while removing assessment",
                },
                edit: { header: "Edit Customised Assessment" },
                add: {
                    header: "Add Customised Assessment",
                    targetClasses: "Target Classes",
                    targetUnit: "Target Unit",
                    selectUnit: "Select Unit",
                    unit: "Unit",
                    removeQLA: "Remove Question",
                    name: "Name",
                    mark: "Raw mark",
                    removeTier: "Remove",
                    gradeBoundaries: "Grade boundaries",
                    specHasTiers:
                        "Choose which tiers and units you want to add the assessment to - you can add it to more than one tier",
                    addTierBtn: "Add tier",
                    countsTowardGrade: "Counts towards Unit and Summary Grades",
                    configureEndOfTopicTest: "Configure assessment",
                    dataEntry: "Data Entry",
                    singleMark: "Single Mark",
                    qla: "Question level Analysis (QLA) | Section Level Analysis (SLA)",
                    topic: "Topic",
                    umsConversion: `Enter the maximum raw marks for the test. The tracker will scale and convert 
                    your raw to UMS Marks to match the unit`,
                    maxValue: "Max value",
                    question: "Question",
                    questionAdd: "Add Another Question",
                    addButton: "Save assessment",
                    waitingForTracker: "Waiting for tracker",
                    noClassesPromptWarning: `You have not selected any classes and so the assessment you 
                    have created will not be visible on any tracker. Do you wish to continue?`,
                    success: "Customised Assessment successfully created",
                    classChooseHint:
                        "Choose classes in which you want to set this assessment as visible",
                    classChooseHint2: "Please note",
                    classChooseHint3: ` - if you deselect a class, the assessment will be hidden and not used on the tracker.
                    Any data on the assessment will still be saved if you want to restore it at a later date.`,
                    gradeShift: "Grade boundaries shift",
                    tracker: "Same as tracker",
                    gradeShiftHint: `Do you want to shift the grade boundaries so it is easier or 
                    harder to achieve a grade?`,
                    yearGroup: "Year Group",
                    cohort: "Academic Year",
                    customGrade: "Custom grade",
                    customHint1: `Enter the <b>Raw Mark</b> grade boundaries you wish to use for this assessment - see <a target="_blank" href="https://support.pupilprogress.com/helpcentre/how-can-i-add-my-own-grade-boundaries">How can I add my own custom grade boundaries to my assessment?</a> for more guidance.`,
                    customHint2: `If you are <b>capping</b> the top grades (e.g. for tiers), then enter a raw mark that exceeds the maximum raw mark for the test to make it unreachable.`,
                    customHint3: `Your students' <b>Raw Marks</b> will be converted to an equivalent scaled mark to match the grade boundaries set for your tracker so they contribute an appropriate marks towards the Summary Grades.`,
                    customHint4: `If you want to <b>skip lower grades</b>, then put them all on the same lowest mark - the highest of these grades will then be awarded.`,
                },
            },
            pastPaper: {
                header: "Published Assessments",
                table: {
                    name: "Name",
                    classes: "Class(es)",
                    course: "Course",
                    unit: "Unit",
                    onTracker: "On tracker",
                    countsTowardGrade: "Count towards Current <1/>& Projected Grade",
                    addButton: "Add Published Assessment",
                    deleteConfirmation: "Are you sure you want to delete this exam paper?",
                    removeSuccess: "Published Assessment removed",
                    removeError: "Error while removing Published Assessment",
                    warning: "Warning!",
                    warningHint: `This will remove this exam paper and <b> all the marks </b> on it from all classes in this year group. 
                    Once the exam paper is removed <b> you will not be able to restore any marks entered</b>.`,
                    warningConfirm: `If you want to continue removing the paper and all its data, please type the word "delete" in the box below`,
                },
                edit: { header: "Edit Published Assessment" },
                add: {
                    header: "Add New Published Assessment",
                    targetUnit: "Target Unit",
                    unit: "Select Unit",
                    tier: "Select Tier",
                    showQlaInReports: "Show QLA in reports",
                    noUnits: "No units for selected specification",
                    countsTowardGrade: "Counts towards Unit and Summary Grades",
                    module: "Select module",
                    configurePastPaper: "Configure Published Assessment",
                    isRepeatable: `The exam paper structure for this unit is the same every year. You can enter a name including the Year of 
                    the paper you are using and it will create another exam paper on your tracker with the QLA structure.`,
                    targetClasses: "Target Classes",
                    cohort: "Academic Year",
                    yearGroup: "Year Group",
                    classChooseHint:
                        "Choose classes in which you want to set this exam paper as visible",
                    classChooseHint2: "Please note - ",
                    classChooseHint3: `if you deselect a class, the exam paper will be hidden and not used in grade calculations. 
                    Any data on the exam paper will still be saved if you want to re-tick it at a later date.`,
                    addButton: "Save Published Assessment",
                    waitingForTracker: "Waiting for tracker",
                    success: "Published Assessment successfully saved",
                    gradeBoundaries: "Grade boundaries",
                    gradeShift: "Grade boundaries shift",
                    gradeShiftHint: `Do you want to shift the grade boundaries so it is easier or 
                    harder to achieve a grade?`,
                    customGrade: "Custom grade boundaries",
                    gradeBoundaryType: "Grade boundary type",
                    pleaseSelectClasses:
                        "Please select at least one class or add exam paper without visibility",
                    customHint1: `Enter the <b>Raw Mark</b> grade boundaries you wish to use for this assessment - see <a target="_blank" href="https://support.pupilprogress.com/helpcentre/how-can-i-add-my-own-grade-boundaries">How can I add my own custom grade boundaries to my assessment?</a> for more guidance.`,
                    customHint2: `If you are <b>capping</b> the top grades (e.g. for tiers), then enter a raw mark that exceeds the maximum raw mark for the test to make it unreachable.`,
                    customHint3: `Your students' <b>Raw Marks</b> will be converted to an equivalent scaled mark to match the grade boundaries set for your tracker so they contribute an appropriate marks towards the Summary Grades.`,
                    customHint4: `If you want to <b>skip lower grades</b>, then put them all on the same lowest mark - the highest of these grades will then be awarded.`,
                },
            },
            list: {
                cohortFilterLabel: "Academic Year",
                yearGroupFilterLabel: "Year Group",
                subjectAreaFilterLabel: "Subject Area",
                qualificationFilterLabel: "Course",
                teacherFilterLabel: "Teacher",
                yearGroup: "Year group",
                classesUnsynced: "Class tracker group has been unsynced",
                openSubjectAnalysis: "Open Subject Analysis | Year Group",
                openYearGroupOverview: "Open year group overview",
                openYearGroupOverviewF: "Open year group overview | Foundation",
                openYearGroupOverviewH: "Open year group overview | Higher",
                openYearGroupOverviewI: "Open year group overview | Intermediate",
                downloadReport: "Download Reports | Year Group",
                downloadSubjectReport: "Download Reports | Class",
                createSnapshots: "Create year group snapshots",
                bulkEdit: "Edit Tracker Options | Year Group",
                synchroniseYourClasslists: "Synchronise your classlists",
                unsynchroniseYourClasslists: "Unsynchronise your classlists",
                snapshotCreated: "Snapshot has been created",
                snapshotError: "Error while creating snapshot",
                unsynchroniseMessage: "Are you sure you want to unsync group?",
                deleteClassPromptType: `Type "delete" if you are sure you want to remove this class. This action is irreversible.`,
                deleteClassPromptHeader: "Remove class",
                table: {
                    name: "Name",
                    lastUpdated: "Last Updated",
                    teachers: "Teacher(s)",
                    editClassBtn: "Edit Tracker Options | Class",
                    students: "Students",
                    unsync: "Unsync",
                    liveTracking: "Live Tracking",
                    updatingTracker: "Updating Tracker",
                    subscriptionExpired: "Subscription expired",
                    archived: "Archived Tracker",
                    unsyncClass: "Remove Class",
                    downloadReport: "Download Class Report",
                },
            },
            bulkEditClass: {
                header: "Bulk edit class",
            },
            groupCallClass: {
                header: "Synchronise your classlists",
                hint: "This will convert your existing classes so they match the classes on MIS_Name:",
                hintList1: "No data on your trackers will be deleted or lost",
                hintList2:
                    "Any students not on MIS_Name will be put in to an un-synchronised class so you still have access to their data",
                hintList3:
                    "You will not be able to manage your class lists on the trackers anymore",
                chooseTrackerHeader: "Class tracker",
                editClassButton: "Save changes",
                editClassButtonAndStay: "Save changes and stay",
                classList: "Class list",
                thereAreNoClasses1:
                    "There are no classes that match this subject choice that are available for Classlist Syncing.",
                thereAreNoClasses2:
                    "Please contact our support team if you think there should be classes you can choose.",
            },
            editClass: {
                header: "Edit Class",
                chooseTrackerHeader: "Class tracker",
                classDetails: "Class details",
                isSyncedClassHeader: "Managed by Class List Synchronising with MIS_Name",
                chooseStudents: "Students list",
                analysisSettings: "Analysis settings",
                editClassButton: "Save changes",
                editClassButtonAndStay: "Save changes and go to next specification",
                skip: "Skip to next specification",
                classList: "Class list",
            },
            migrateModal: {
                header: `Copy trackers to new Academic Year`,
                hint1: `This will copy your trackers that are continuing their exam courses from last year, along with all their existing assessment data.`,
                migrateTitle: `Migrate your data`,
                migrateHint: `Copies your current classes to the higher year group with all their data, assessments
                and snapshots on the same tracker as <b>unsynced classes</b>`,
                recommended: "RECOMMENDED",
                before: "Best for moving classes before :before",
                syncTitle: "Migrate and Sync",
                syncHint:
                    "Copies your current classes to the higher year group with all their data, assessments and snapshots on same tracker as <b>synced classes</b>",
                after: "Best for moving classes after :after",
            },
            migrate: {
                header: "Migrate your data",
                syncHeader: "Migrate and sync",
                syncHint: `This is best for:
                <ul><li>Year 9 (KS4 only) into Year 10</li>
                <li>Year 10 into Year 11 </li>
                <li>Year 12 into Year 13</li></ul>
                `,
                // "Best for moving Year 9 (KS4 only) into Year 10, Year 10 into 11 and 12 into 13 after September 1st ",
                hint: "Best for moving Year 10 into 11 and Year 12 into 13",
                choose: "Choose source year group",
                chooseHint: `Copies your current classes with all their data, assessments and snapshots on the same tracker <b>as unsynced classes.</b></br> When your new classlist are ready on MIS_Name, then you can synchronize the trackers to the new class list`,
                syncChooseHint:
                    "Copies your current classes with all their data, assessments and snapshots on the same tracker <b>as synced classes</b>",
                sourceAcademicYear: "Source Academic Year",
                sourceYearGroup: "Source Year Group",
                chooseClasses: "Choose source classes",
                chooseNewClasses: "Choose new classes",
                selectAll: "Select all",
                createBtn: "Create New Unsynced classes",
                success: "Classes will be created shortly...",
                migrateButton: "Create new unsynced classes",
                migrateSyncButton: "Create new synced classes",
            },
            addModal: {
                header: "Add New Class",
                chooseHow: "Choose how you want to add your class:",
                "manual-xlsTitle": "Using XLS file",
                "manual-xlsHint": `Upload an Excel file with all your classes and students in one go. 
                Uploaded students can be then used by you and other school users in other classes.`,
                "manual-existing-studentsTitle": "Using existing students",
                "manual-existing-studentsHint": `Create a new class and add students from a list of previously 
                uploaded names or copy students from another class list.`,
                "manual-uploadTitle": "We will upload it for you",
                "manual-uploadHint":
                    "Send us your class lists and we will upload them for you within 24hrs",
                "other-demoTitle": "Setup Demo Class",
                "other-demoHint":
                    "Don’t have your class lists to hand? Try out the tracker for your course with our demo class",
                synchroniseTitle: "Add classes that sync daily",
                synchroniseHint: "This will create and synchronise your class list with MIS_Name",
                synchroniseRecommended: "Recommended",
                autosuggestTitle: "Auto-suggest trackers",
                autosuggestHint:
                    "Create new classes based on the courses you tracked last academic year",
                unsynchronisedTitle: "Add manually managed classes",
                unsynchronisedHint:
                    "Create classes from MIS_Name that will not be synced to MIS_Name and you can manually manage the student list",
                manualTab: "Manually",
                misTab: " Connected Classes",
                otherTab: "Demo",
            },
            addClass: {
                header: "Add New Class",
                uploadTab: "We will upload it for you",
                singleTab: "Using existing students",
                headerMisSynchroniseClass: "Synchronise with MIS_Name",
                headerManualXls: "Using XLS file",
                headerManualExistingStudents: "Using existing students",
                headerOtherUploadClass: "We will upload it for you",
                headerOtherDemoClass: "Setup Demo Class",
                headerAutosuggest: "Auto-suggest trackers",
                classCreatedSuccess: "Class trackers will be created shortly",
                headerMisUnSynchroniseClass: "Unsynchronised from MIS_Name",
                autoSuggest: {
                    discontinued:
                        "This tracker is no longer available. Please use different tracker for these classes",
                    chooseNewClasses: "Choose New Classes",
                    autoSuggestHint: "This is best for Year 1-10 and 12",
                    autoSuggestHint2:
                        "Create <b>new</b> classes with <b>no data</b> based on the courses you tracked last academic year",
                    autoSuggestHint3:
                        "If a subject, exam boards or course has changed then don't create them here, choose Synchronise with MIS_Name",
                    autoSuggestHint3Btn: "Synchronise with MIS_Name",
                    addClassButton: "Create new trackers",
                    sourceAcedemicYear: "Source academic year",
                    targetAcedemicYear: "Target academic year",
                    sourceYearGroup: "Source Year Group",
                    success: "Class trackers will be created shortly",
                },
                academicYear: "Academic Year",
                chooseTrackerHeader: "Choose tracker",
                chooseCohort: "Choose academic year",
                noYearGroups: "No classes",
                chooseCohortAndName: "Choose name & academic year",
                uploadAnother: "Upload another class",
                convertHint:
                    "In order to add those DataSync classes, please convert existing classes to DataClass ",
                convertBtn: "Convert",
                createDemoClassBtn: "Setup Demo Class",
                thankYouForUpload:
                    "We will notify you by email when we finish uploading your class!",
                chooseTrackerMessage: `Simply choose your Subject Area, Qualification & Specification and then send your class
                list in an excel or pdf file including student names and candidate numbers - and we will create them for you,
                ready for you to start tracking your data`,
                uploadClassTrackerMessage: `Send us your class lists in an excel file and we will set them up ready for you to start tracking your data within 24 hours.`,
                uploadClassRequired: "Required:",
                uploadClassOptional: "Optional:",
                uploadClassReqUpn:
                    "<b>Unique Pupil Number</b> - <a target='_blank' href='https://support.pupilprogress.com/helpcentre/why-do-we-need-upns-and-sub-cat-data'>find out more</a>",
                uploadClassReqFirstLastName: "First name & Surname (in separate columns)",
                uploadClassReqClassName: "Class names",
                uploadClassOptionalCN: "Candidate number",
                uploadClassOptionalKS2:
                    "KS2 English and KS2 Maths or KS2 fine level if you want the tracker to calculate P8 scores",
                uploadClassOptionalSEN:
                    "Any subgroup data such as ethnicity, SEN that you want to include",
                unsyncIntro:
                    "The class lists and teachers will be created based on MIS_Name. After this, the list of students will then be <b>manually managed </b>and<b> will not update </b>automatically.",
                singleClassIntro:
                    "The class lists and teachers will be created based on MIS_Name. The list of students will be synchronised on a daily basis.",
                chooseFileMessage: `Please open the downloaded multiple classes template file and follow the guidance in the yellow 
                boxes to fill all necessary details for all students in each class. Make sure you delete the yellow rows, then upload 
                the file using the button below`,
                downloadMultipleClassesBtn: "Download multiple classes template",
                addNewClassesBtn: "Add new classes",
                toLowWeight: "Too low weight of selected elements",
                notEnought: "Not enough items selected",
                tooManyItems: "Too many items selected",
                uploadMultipleClasses: "Upload Multiple Classes",
                uploadClassList: "Drag 'n' drop some files here, or click to select files",
                addClassButton: "Add new class",
                addClassesButton: "Add new classes",
                specificationRequired: "Specification required",
                uploadFile: "Upload your files",
                classDetails: "Class details",
                chooseStudents: "Choose students",
                chooseClass: "Choose class list to copy from",
                chooseStudentsMessage: "Choose how you want to add students to your class:",
                importFromClass: "Copy students from another class",
                importStudents: "Import students",
                chooseThisOption: "Choose this option",
                noClasses: "No classes selected",
                clearSelection: "Clear selection",
                demoClassIntroText: "Select your course below for your demo class",
                multipleClassIntroText:
                    "You can add multiple classes to a course using this option. Start by selecting your course below.",
                importStudentsModal: `Download and open the class template file using the button below. Enter the first name, last name and Unique 
                Pupil Number for each student. When you're ready, please upload the file using a button below`,
                importFailed: "Import failed!",
                importFailedProblem: "There is a problem with the XLS file that you've uploaded:",
                downloadXlsxTemplate: "Download Excel file",
                uploadImportFile: "Upload your file",
                chooseStudentsRegister:
                    "Choose your students from the list of students already added to Pupil Progress",
                uploadExcelStudents:
                    "Download the excel file, and follow the instructions to upload the class",
                className: "Class name",
                yearGroup: "Year group",
                selectClass: "Select Class",
                cohort: "Academic Year",
                teachers: "Teacher(s)",
                noTeachers: "Can't find any teachers? You might need to add them first",
                studentsSelected: "students selected",
                changeSelection: "Change Selection",
                searchStudentAutoPlaceholder: "Search by students name",
                searchStudentAutoPlaceholderHelperText:
                    "To find a student start typing their name above",
                mtgSelect: "Minimum Target Grade",
                classAdded: "Class added successfully",
                studentsName: "Name",
                studentsUniquePupilNumber: "Unique pupil number",
                studentsMTG: "Minimum Target Grade",
                minOneFile: "Minimum one file attachment is required",
                cohortRequired: "Academic Year is required",
                cohortType: "Academic Year is required",
                cohortMinOne: "Please select Academic Year",
                yearGroupRequired: "Year Group is required",
                yearGroupType: "Year Group is required",
                yearGroupMinOne: "Please select Year Group",
                studentsRequired: "Please select students",
                customName: "Custom name",
                deleteStudentPrompt: "Are you sure you want to delete this Student?",
                deleteStudentEditPromptBold:
                    "Do not use this if you are trying to move students to another class.",
                deleteStudentEditPrompt: `This will remove this student and all their data from this class.
                    Once the student is removed you will not be able to restore the student`,
                deleteStudentEditPromptDelete: `If you are not trying to move students, but are trying to delete them, 
                please type the word "delete" in the box below.`,
                removeStudentBtn: "Remove Student",
                subjectArea: "Subject Area",
                qualification: "Qualification",
                specification: "Specification",
                removeSuccess: "Class successfully removed",
                unsyncSuccess: "Class successfully removed",
                course: "Course",
                files: "Files",
                editCourseBtn: "Edit course",
                collapseCourseBtn: "Collapse tab",
                deleteBtn: "Delete",
                addAnotherCourseBtn: "Add another course",
                furtherDetails:
                    "Do you want to share any further details? Please add a note here...",
            },
        },
        videoTutorial: {
            menu: {
                header: "Walkthrough Videos",
                btn: "Visit Help & Support Center",
                productBoardBtn: "Our Development Board",
            },
            modalSimple: {
                header: "Don't want to miss new features or got a question you want to ask?",
                youCanAccess:
                    "You can access all our walkthrough videos about newly added featured as well as support center link using the help menu in top-right corner.",
            },
        },
        navbar: {
            linksList: "Links",
            language: "Language",
            classesTracking: "Tracking",
            yourClasses: "Your Classes",
            assessments: "Assessments",
            trustDashboard: "Dashboard",
            trustUsers: "Users",
            // pastPapers: "Published Assessments",
            // endOfTopicTests: "Customised Assessments",
            reports: "Reports",
            publishedReports: "App Reports",
            atl: "Learning Characteristics",
            downloadableReports: "Downloadable Reports",
            mySchool: "My School",
            subjectAreas: "Subject Areas",
            schoolDetails: "School Details",
            students: "Students",
            parents: "Parents",
            staff: "Staff",
            snapshots: "Snapshots",
            subscriptions: "Subscriptions",
            myAccount: "My Account",
            reportTemplates: "Report templates",
            signOut: "Sign Out",
            analysis: "Analysis",
            reminderDoLike: "Do you like our service? Don't forget to extend your licence!",
            reminderMoreThan3: "You have {subsCount} subscriptions that will expire soon",
            reminderWillExp: `Your subscription for "{subjectAreaName}" will expire in {expiryDays} days!`,
            tags: "Tags",
            myTrusts: "My Trusts",
            mySchools: "My Schools",
        },
        footer: {
            title: "Need Help?",
            termsOfService: "Terms of Service",
            privacyPolicy: "Privacy Policy",
            cookiesPolicy: "Cookie Policy",
            dataProcessingAgreement: "Data Processing Agreement",
            resourceCentre: "Resource Centre",
            blog: "Blog",
            copy1: "Pupil Progress Ltd registered in England and Wales under the company registration number 08986421",
            copy2: ":year Pupil Progress Ltd. All rights reserved.",
        },
        auth: {
            mergeAccount: {
                signIn: "Sign in",
                header: "Congratulations - you merged accounts!",
            },
            changeEmail: {
                header: "Processing email change",
                success: "Email successfully changed. Please log in.",
                error: "Error while changeing email address. Please re-run the change process from beginning",
                goToLoginBtn: "Go to login page",
                invalidHahs: "Invalid link. Please re-run the change process from beginning",
            },
            resetPassword: {
                message:
                    "Please enter your email address. We will send you a link to reset password for your account.",
                success: "Link to reset password has been sent.",
                button: "Reset password",
                emailNotFound: "Email not found",
                backToLogin: "Back to login",
                newPasswordHeader: "Please enter your new password",
                newPasswordSuccess: "Password has been changed",
            },
            login: {
                signIn: "Sign in",
                forgotPassword: "Forgot password?",
                newToPupilProgress: "New to Pupil Progress?",
                clickHereToRegister: "Click here to Register",
                chooseProfile: "Choose your profile",
                schools: "Schools",
                trusts: "Trusts",
                myAccount: "My Account",
                signOut: "Sign Out",
            },
            invite: {
                steps: {
                    details: "1/4 Your details",
                    credentials: "2/4 Login credentials",
                    terms: "3/4 Service terms",
                    finish: "4/4 All done",
                },
                finishedStep: {
                    header: "Congratulations - you are all set up!",
                    message1: `If you want to start using the system on your own
                    first - you can now start creating your first class: `,
                    addClassButton: "Add class",
                    financeMessage: `If you want to start using the system check the 
                    subscriptions`,
                    goToSubscriptionsButton: "Go to subscriptions",
                    letsGetStartedMsg:
                        "Click on the button below to proceed to the classes & trackers list",
                    letsGetStartedBtn: "Let's get started",
                },
            },
            register: {
                schema: {
                    school: "School is a required field",
                    subjectArea: "Min 1 Subject area should be selected",
                    terms: "Terms acceptance is required",
                    schoolExists: "School exists. User must be verified",
                },
                steps: {
                    createAccount: "Create your account",
                    details: "(3/8) Your details",
                    credentials: "(4/8) Login credentials",
                    school: "(1/8) Your school",
                    subjectArea: "(2/8) Subject areas",
                    terms: "(5/8) Service terms",
                    confirm: "(6/8) Confirm e-mail address",
                    contact: "(7/8) Contact details",
                    finish: "(8/8) All done",
                },
                detailsStep: {
                    header: `Please enter your details to create your account.`,
                },
                contactStep: {
                    header: "Congratulations - your account is now confirmed!",
                    message: `If you enter your phone number, we can contact you in the future in case of any support issues`,
                },
                contactConfirmedStep: {
                    header: "Account",
                    message: "Your account has been already confirmed",
                },
                credentialsStep: {
                    header: "Please enter your e-mail & password:",
                    createNewPassword: "To confirm your account, please create a new password:",
                },
                completedStep: {
                    header: "Thank you!",
                    message1: "We have sent an email with confirmation link to: ",
                    message2:
                        "In order to complete the sign-up process, please click the confirmation link. If you do not receive a confirmation email, please check your spam folder.",
                },
                schoolStep: {
                    autoPlaceholder: "School name",
                    autoLabel: "Please choose your school",
                    caption: "Can't find your school?",
                    contactUs: "Contact Us",
                    userRole: {
                        label: "Are you a teacher, parent or student?",
                        teacher: "Teacher",
                        parent: "Parent",
                        student: "Student",
                        info: "This page is only for Teachers. You need to login through the app. Check your email for an invite to the Pupil Progress App and click the link in the email.",
                    },
                },
                subjectAreaStep: {
                    fieldPlaceholder: "Subject Area",
                    fieldLabel: "Please choose subject areas you're interested in.",
                    fieldLabelHint: "You can choose more than one subject area if you wish.",
                    grantHeading: `Grant-Enabled Access`,
                    grantHint: `Please choose <strong>up to 2</strong> subject areas for which you'll receive a zero cost Full-Suite
                    subscription (valid until {grantSupportedValid})`,
                    grantHint2: `Not sure what Subjects to use? Start with a Free Trial and then add Grant-Enabled Access later`,
                    grantAdditional: `For additional Terms of Use please click 
                    <a target="_blank" href="https://resources.pupilprogress.com/grant-enabled-access-terms-conditions">here</a>`,
                    promoSubscriptionsHeading: "Partner-enabled subscriptions",
                    nahtHeading: "NAHT-enabled Subscription",
                    pixlHeading: "PiXL Waves powered by Pupil Progress",
                    pixlHint1: "Join 1000s of schools, trusts and teachers",
                    pixlHint2:
                        "For PiXL subscribed schools, maximise your membership with full suite access in exam board specific tracking for English and Maths",
                    pixlValid: "(valid until 30th August)",
                    nahtHint: `Choose up to 2 subject areas for your zero-cost Full Suite subscription (valid for 1 year) for attending a NAHT event. <br /><br />For additional Terms of Use please <a target="_blank" href="https://resources.pupilprogress.com/naht-enabled-subscription-faqs">click here</a>.`,
                    trialHeading: `FREE Trial subscriptions`,
                    trialHint: `<strong>What other subjects could benefit? </strong><br/><br/>
                    Explore the wider impact for your school and colleagues. Choose 3 subjects for a trial period of 30 days at zero cost.`,
                },
                termsStep: {
                    terms: "Please accept our <1>terms of service</1>",
                    termsLabel: "I have read and agree to terms of service",
                },
                finishedStep: {
                    header: "Congratulations - you're all set up!",
                    message1: `If you want to start using the system on your own
                    first - you can now start creating your first class: `,
                    addClassButton: "Add class",
                    setupTrackerBtn: "Setup Your Tracker",
                    message2: `If you plan to add classes for other teachers too - 
                    make sure you invite them to join your account first:`,
                    addTeacherButton: "Add other teachers",
                    letsGetStartedMsg:
                        "Click on the button below to proceed to the classes & trackers list",
                    letsGetStartedBtn: "Let's get started",
                },
            },
        },
    },
};
