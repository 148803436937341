import { ofType } from "@martin_hotell/rex-tils";
import { OrmGradeActions } from "src/orm/models/Grade/actions";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CommonActions, CommonActionTypes } from "../actions";

const gradeListSuccessEpic = (action$: ActionsObservable<CommonActions>) =>
    action$.pipe(
        ofType(CommonActionTypes.GET_GRADE_LIST_SUCCESS),
        map((action: ReturnType<typeof CommonActions.getGradeListSuccess>) => action.payload),
        mergeMap(payload =>
            of(
                OrmGradeActions.bulkImport({
                    data: payload.response,
                    specificationId: payload.request.payload.params.specification,
                }),
            ),
        ),
    );

export const gradeEpics = combineEpics(gradeListSuccessEpic);
