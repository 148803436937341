import AppContainer from "src/components/AppContainer";
import TrackingFilters from "src/modules/class/components/Common/TrackingFilters";
import { Box, Button, Divider, MenuItem, Paper, TextField, Theme, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { ROUTE_REPORT_LINKS_ADD, ROUTE_REPORT_LINKS_LIST } from "src/routes";
import { filterNotNullable } from "src/services/object";
import { useClassFilters } from "../hooks/useClassFilters";
import { makeStyles } from "@mui/styles";
import { SchoolUserRole } from "src/orm/models/User";
import { Link } from "react-router-dom";
import PaperInner from "src/components/PaperInner";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { OrmQualification } from "src/orm/models/Qualification";
import { OrmSpecification } from "src/orm/models/Specification";
import TrackingHeaderTrackerPath from "src/modules/class/components/Common/TrackingHeaderTrackerPath";
import LinksTable from "../components/LinksTable";
import { useLinksList } from "../hooks/useLinksList";
import InfiniteScroll from "react-infinite-scroll-component";
import DotsProgress from "src/components/DotsProgress";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export const LINKS_GROUPS_PER_PAGE = 10;

const useStyles = makeStyles((theme: Theme) => ({
    dividerVertical: {
        height: 42,
        margin: theme.spacing(0, 5),
    },
}));

const LinksListContainer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { navigate, attachSchoolId } = useSchoolNavigate();
    const {
        cohort: cohortParam,
        yearGroup: yearGroupParam,
        subjectArea: subjectAreaParam,
        qualification: qualificationParam,
    } = Object.fromEntries(new URLSearchParams(location.search));

    const { data } = useCoreValues();
    const cohorts = data?.cohorts;

    const { data: accountInfo } = useAccountInfo();
    const defaultCohortId = accountInfo?.activeCohortId;
    const currCohort = filterNotNullable(cohorts).find(
        c => c.id === (parseInt(cohortParam || "") || defaultCohortId),
    );

    const handleCohortChange = (cohortId: number) => {
        navigate(ROUTE_REPORT_LINKS_LIST + `?cohort=${cohortId}`);
        setSelectedFilters({
            yearGroup: "",
            subjectArea: "",
            qualification: "",
            page: 1,
            perPage: LINKS_GROUPS_PER_PAGE,
        });
    };
    useEffect(() => {
        if (defaultCohortId && !cohortParam) {
            handleCohortChange(defaultCohortId);
        }
    }, [defaultCohortId, cohorts]);

    const initialFilter = {
        yearGroup: yearGroupParam || "",
        subjectArea: subjectAreaParam || "",
        qualification: qualificationParam || "",
        page: 1,
        perPage: LINKS_GROUPS_PER_PAGE,
    };
    const [selectedFilters, setSelectedFilters] = useState(initialFilter);

    const resolveSearchQuery = (type, value) => {
        const filresCopy = { ...selectedFilters };
        filresCopy[type] = value;
        let { subjectArea, qualification, yearGroup } = filresCopy;

        const selectedSubjectArea = classFilters.find(({ id }) => `${id}` == subjectArea);
        //selected subjectArea value - not All
        if (type === "subjectArea" && selectedSubjectArea) {
            subjectArea = value;

            const selectedQualification = selectedSubjectArea.qualifications.find(
                ({ id }) => `${id}` == qualification,
            );

            if (selectedQualification) {
                //qualification is set
                if (!selectedQualification.yearGroups.find(id => `${id}` == yearGroup)) {
                    yearGroup = "";
                }
            } else {
                //qualification is All
                qualification = "";
                if (
                    !selectedSubjectArea.qualifications
                        .flatMap(({ yearGroups }) => yearGroups)
                        .find(id => `${id}` == yearGroup)
                ) {
                    yearGroup = "";
                }
            }
        }
        if (type === "qualification" && qualification) {
            if (selectedSubjectArea) {
                //subjectArea is set
                if (
                    !selectedSubjectArea.qualifications
                        .find(({ id }) => `${id}` == qualification)
                        .yearGroups.find(id => `${id}` == yearGroup)
                ) {
                    yearGroup = "";
                }
            } else {
                if (
                    !classFilters
                        .flatMap(({ qualifications }) => qualifications)
                        .find(({ id }) => `${id}` == qualification)
                        .yearGroups.find(id => `${id}` == yearGroup)
                ) {
                    yearGroup = "";
                }
            }
        }
        return { subjectArea, qualification, yearGroup };
    };

    const handleFilterChange = (type: string, value: string) => {
        const { subjectArea, qualification, yearGroup } = resolveSearchQuery(type, value);
        setSelectedFilters({ ...selectedFilters, subjectArea, qualification, yearGroup, page: 1 });
        navigate(
            location.pathname +
                `?cohort=${
                    currCohort?.id || 0
                }&subjectArea=${subjectArea}&qualification=${qualification}&yearGroup=${yearGroup}`,
        );
    };
    const { data: userProfile } = useProfile();
    const canEditLinks = !!(
        userProfile?.userRole === SchoolUserRole.SCHOOL_ADMIN ||
        userProfile?.userRole === SchoolUserRole.OWNER ||
        userProfile?.isLeaderOfAll ||
        (userProfile?.leaderOfSubjectAreas && userProfile?.leaderOfSubjectAreas.length > 0)
    ); //insted of it mabye we can use canEdit from request (linksListPaged?.pages[0]?.data[0]?.canEdit)

    const perpareParamsForRequest = useCallback(() => {
        return {
            page: selectedFilters.page,
            perPage: selectedFilters.perPage,
            subjectAreaId: parseInt(selectedFilters.subjectArea) || undefined,
            qualificationId: parseInt(selectedFilters.qualification) || undefined,
            yearGroup:
                selectedFilters.yearGroup !== "" ? parseInt(selectedFilters.yearGroup) : undefined,
        };
    }, [selectedFilters]);
    const { data: classFilters, isLoading: isLoadingClassFilters } = useClassFilters(
        currCohort?.id || 0,
    );
    const {
        data: linksListPaged,
        refetch: refetchList,
        hasNextPage,
        fetchNextPage,
    } = useLinksList(currCohort?.id, perpareParamsForRequest());

    useEffect(() => {
        if (currCohort) {
            refetchList();
        }
    }, [selectedFilters]);

    const linksList = linksListPaged?.pages?.flatMap(({ data }) => data) || [];

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("link.tab")}
            </Typography>
            <Box>
                <Box mb={4}>
                    {canEditLinks && (
                        <Button
                            component={Link}
                            to={attachSchoolId(ROUTE_REPORT_LINKS_ADD)}
                            color="primary"
                            id="addButton"
                        >
                            {t("link.list.addButton")}
                        </Button>
                    )}
                </Box>
                <Box mb={4} display="flex">
                    <Box width={120}>
                        <TextField
                            className="cohortSelect"
                            label={t("class.list.cohortFilterLabel")}
                            select
                            SelectProps={{ displayEmpty: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="none"
                            name="cohort"
                            fullWidth
                            value={currCohort?.id || 0}
                            onChange={e => handleCohortChange(parseInt(e.target.value))}
                            id="cohortSelect"
                        >
                            {filterNotNullable(cohorts).map(obj => (
                                <MenuItem
                                    key={obj.id}
                                    value={obj.id}
                                    id={`cohortSelectItem-${obj.id}`}
                                >
                                    {obj.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    {!isLoadingClassFilters && classFilters?.length > 0 ? (
                        <>
                            <Divider
                                orientation="vertical"
                                flexItem
                                className={classes.dividerVertical}
                            />
                            <TrackingFilters
                                selectedFilters={selectedFilters}
                                filters={classFilters}
                                handleFilterChange={handleFilterChange}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Box>

                <InfiniteScroll
                    dataLength={linksList.length || 0}
                    next={() => {
                        hasNextPage && fetchNextPage();
                    }}
                    hasMore={hasNextPage}
                    loader={<DotsProgress />}
                >
                    {linksList.map(linksGroupData => (
                        <Box mb={2} key={linksGroupData.id}>
                            <Paper>
                                <PaperInner variant="subjectArea" color="lightGrey" border="bottom">
                                    <Box display="flex">
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            flex={1}
                                            className="trackingHeader"
                                        >
                                            <TrackingHeaderTrackerPath
                                                subjectArea={
                                                    linksGroupData.subjectArea as OrmSubjectArea
                                                }
                                                specification={
                                                    linksGroupData.specification as OrmSpecification
                                                }
                                                qualification={
                                                    linksGroupData.qualification as OrmQualification
                                                }
                                                yearGroup={linksGroupData.yearGroup}
                                            />
                                        </Box>
                                    </Box>
                                </PaperInner>
                                <LinksTable
                                    canEdit={linksGroupData.canEdit}
                                    linksList={linksGroupData.reportLinks}
                                    refetchLinks={refetchList}
                                />
                            </Paper>
                        </Box>
                    ))}
                </InfiniteScroll>
            </Box>
        </AppContainer>
    );
};

export default LinksListContainer;
