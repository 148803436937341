import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { SpecificationParam } from "src/forms/types";
import { commonGradeEndpoint, CommonGradeResponse } from "../../api/grades";

export enum CommonGradeActionsTypes {
    GET_GRADE_LIST = "[common] GET GRADE LIST",
    GET_GRADE_LIST_SUCCESS = "[common] GET GRADE LIST SUCCESS",
}

export const CommonGradeApiEndpoints = {
    getGradeList: createActionObject(
        commonGradeEndpoint,
        CommonGradeActionsTypes.GET_GRADE_LIST_SUCCESS,
    ),
};

export const CommonGradeRawActions = (
    CommonEndpoints: Record<Keys<typeof CommonGradeApiEndpoints>, ApiActionMeta>,
) => ({
    getGradeList: (specification: number) =>
        createApiAction(
            CommonGradeActionsTypes.GET_GRADE_LIST,
            { values: {}, params: { specification } },
            CommonEndpoints.getGradeList,
        ),
    getGradeListSuccess: (
        payload: ApiCallbackPayload<CommonGradeResponse, {}, SpecificationParam>,
    ) => createAction(CommonGradeActionsTypes.GET_GRADE_LIST_SUCCESS, payload),
});
