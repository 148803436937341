import { ActionsUnion, createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiCallbackPayload } from "src/api/constants";
import {
    createActionObject,
    createApiAction,
    paramRequest,
    processEndpoints,
} from "src/api/helpers";
import { OrmClass } from "src/orm/models/Class";
import { Modules } from "src/types";
import { userClassesListEndpoint } from "../api/classes";
import { userTeachersListEndpoint, UserTeachersListResponse } from "../api/teachers";
import { adminsListEndpoint, AdminListResponse } from "../api/admins";
import { ProfileResponse } from "../hooks/useProfile";

export enum UserActionsTypes {
    GET_USER_TEACHERS_LIST = "[user] GET USER TECHERS LIST",
    GET_USER_TEACHERS_LIST_SUCCESS = "[user] GET USER TEACHERS LIST SUCCESS",
    GET_USER_CLASSES = "[user] GET USER CLASSES",
    GET_USER_CLASSES_SUCCESS = "[user] GET USER CLASSES SUCCESS",
    GET_USER_PROFILE = "[user] GET USER PROFILE",
    GET_USER_PROFILE_SUCCESS = "[user] GET USER PROFILE SUCCESS",
    SET_USER_PROFILE = "[user] SET USER PROFILE",
    GET_ADMIN_LIST = "[user] GET ADMIN LIST",
    GET_ADMIN_LIST_SUCCESS = "[user] GET ADMIN LIST SUCCESS",
    SET_ADMIN_IDS = "[user] SET ADMIN IDS",
}

const _endpoints = {
    getUserTeachersList: createActionObject(
        userTeachersListEndpoint,
        UserActionsTypes.GET_USER_TEACHERS_LIST_SUCCESS,
    ),
    getAdminList: createActionObject(adminsListEndpoint, UserActionsTypes.GET_ADMIN_LIST_SUCCESS),
    getUsersClasses: createActionObject(
        userClassesListEndpoint,
        UserActionsTypes.GET_USER_CLASSES_SUCCESS,
    ),
};

export type UserEndpointKeys = Keys<typeof _endpoints>;

export const UserEndpoints = processEndpoints<UserEndpointKeys>(Modules.USER, _endpoints);

export const UserActions = {
    getUserTeachersList: () =>
        createApiAction(
            UserActionsTypes.GET_USER_TEACHERS_LIST,
            undefined,
            UserEndpoints.getUserTeachersList,
        ),
    getUserTeachersListSuccess: (payload: ApiCallbackPayload<UserTeachersListResponse>) =>
        createAction(UserActionsTypes.GET_USER_TEACHERS_LIST_SUCCESS, payload),
    getAdminList: () =>
        createApiAction(UserActionsTypes.GET_ADMIN_LIST, undefined, UserEndpoints.getAdminList),
    getAdminListSuccess: (payload: ApiCallbackPayload<AdminListResponse>) =>
        createAction(UserActionsTypes.GET_ADMIN_LIST_SUCCESS, payload),
    getUserClassesList: (cohort?: string, yearGroup?: string) =>
        createApiAction(
            UserActionsTypes.GET_USER_CLASSES,
            paramRequest({ cohort: cohort || "", yearGroup: yearGroup || "" }),
            UserEndpoints.getUsersClasses,
        ),
    getUserClassesListSuccess: (payload: ApiCallbackPayload<OrmClass[]>) =>
        createAction(UserActionsTypes.GET_USER_CLASSES_SUCCESS, payload),
    setUserProfile: (payload: ProfileResponse) =>
        createAction(UserActionsTypes.SET_USER_PROFILE, payload),
    setAdminIds: (payload: AdminListResponse) =>
        createAction(UserActionsTypes.SET_ADMIN_IDS, payload),
};

export type UserActions = ActionsUnion<typeof UserActions>;
