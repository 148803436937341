import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TagModel } from "../../model/TagModel";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_TAG_LIST_QUERY = "classTrackerGroupTagListQuery";

export const useClassTrackerGroupTagList = (classTrackerGroupId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CLASS_TRACKER_GROUP_TAG_LIST_QUERY, schoolAccountId, classTrackerGroupId],
        queryFn: async (): Promise<TagModel[] | null> => {
            if (classTrackerGroupId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/tags/`,
                );

                return data || null;
            }
            return null;
        },
    });
};
