import { Fragment } from "react";
import { OrmSubscriptionHistory } from "src/orm/models/SubscriptionHistory";
import { useTranslation } from "react-i18next";
import { Typography, Paper } from "@mui/material";
// import { Dispatch } from "redux";
// import { SubscriptionsActions } from "../store/actions";
// import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import { AppState } from "src/store/reducers";
// import { ApiData, ApiStatus } from "src/api/constants";
import SummaryBox from "../components/SummaryBox";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { OrmQualification } from "src/orm/models/Qualification";
import PaperInner from "src/components/PaperInner";

interface OwnProps {
    paymentDetails: OrmSubscriptionHistory;
}

// const dispatchActions = (dispatch: Dispatch) => ({
//     createInvoice: (subscriptionHistoryId: number, intentId: string, description: string) => {
//         dispatch(SubscriptionsActions.createInvoice(subscriptionHistoryId, intentId, description));
//     },
// });

const PaymentSuccess = ({ paymentDetails }: OwnProps) => {
    const { t } = useTranslation();
    // const dispatch = useDispatch();
    // const { createInvoice } = dispatchActions(dispatch);
    // const {
    //     apiInvoice,
    //     invoice,
    // }: {
    //     apiInvoice: ApiData;
    //     invoice: string;
    // } = useSelector(
    //     (state: AppState) => ({
    //         apiInvoice: state.api.subscription.createInvoice,
    //         invoice: state.subscription.lastInvoice,
    //     }),
    //     shallowEqual
    // );

    // const price = paymentDetails && paymentDetails.price ? parseFloat(paymentDetails.price) : null;

    // const discount =
    //     paymentDetails && paymentDetails.couponValue
    //         ? parseFloat(paymentDetails.couponValue)
    //         : null;

    // const grandTotal = (price !== null ? price : 0) - (discount !== null ? discount : 0);

    // useEffect(() => {
    //     if (apiInvoice.status === ApiStatus.NONE && paymentDetails.id) {
    //         createInvoice(paymentDetails.id, intentId, description || "");
    //     }
    // }, [apiInvoice.status, createInvoice, description, intentId, paymentDetails.id]);

    return (
        <>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("subscription.makePayment.successHeader")}
            </Typography>
            <Paper>
                <PaperInner variant="subscription" border="bottom" color="lightGrey">
                    {paymentDetails.id && (
                        <Fragment>
                            <Typography variant="h5" component="h2" gutterBottom>
                                {t("subscription.makePayment.placingOrder")}
                            </Typography>
                            <SummaryBox
                                subjectArea={paymentDetails.subjectArea as OrmSubjectArea}
                                qualifications={paymentDetails.qualifications as OrmQualification[]}
                                period={paymentDetails.subscriptionLength}
                                type={paymentDetails.subscriptionType}
                                oneline={true}
                            />
                        </Fragment>
                    )}
                </PaperInner>
                <PaperInner>
                    <Typography variant="h4" component="h2" gutterBottom>
                        {t("subscription.makePayment.thankyou")}
                    </Typography>

                    {paymentDetails.totalPrice && (
                        <Typography>
                            {t("subscription.makePayment.chargedFor").replace(
                                "{amount}",
                                `${paymentDetails.totalPrice}`,
                            )}
                        </Typography>
                    )}
                    <Typography>{t("subscription.makePayment.successMessage")}</Typography>
                    {/* <Box mt={3}>
                        {invoice ? (
                            <Button color="primary" target="blank" href={invoice}>
                                {t("subscription.makePayment.downloadInvoice")}
                            </Button>
                        ) : (
                            <CircularProgress />
                        )}
                    </Box> */}
                </PaperInner>
            </Paper>
        </>
    );
};

export default PaymentSuccess;
