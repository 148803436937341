import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const PUBLISHED_ASSESSMENT_LIVE_TRACKING_DATA_QUERY = "publishedAssessmentTrackingDataQuery";

export interface PublishedAssessmentLiveTrackingData {
    question: string;
    maxValue: number;
    topics: { id: number; name: string }[];
}

export const usePublishedAssessmentTrackingData = (id: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [PUBLISHED_ASSESSMENT_LIVE_TRACKING_DATA_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<PublishedAssessmentLiveTrackingData[] | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/assessments/published/${id}/live-tracking-data/`,
                );

                return data;
            }
            return null;
        },
    });
};
