import { Box, Chip, FormControl } from "@mui/material";
import { InputBaseProps as MuiInputBaseProps } from "@mui/material/InputBase";
import { apiUrl } from "src/config/globals";
import { get } from "src/services/ajax";
import { ControllerStateAndHelpers } from "downshift";
import { FieldProps, getIn } from "formik";
import { useState, useEffect } from "react";
import { useEventCallback } from "rxjs-hooks";
import { debounceTime, filter, tap, withLatestFrom } from "rxjs/operators";
import DownshiftRender from "../Downshift";
import { serialize } from "src/services/url";

interface OptionType {
    id: string;
    name: string;
}

export interface AutocompleteProps
    extends FieldProps,
        Omit<MuiInputBaseProps, "error" | "name" | "onChange" | "value"> {
    apiPathName: string;
    label?: string;
    tooltip?: React.ReactNode;
    width?: number;
    multiple?: boolean;
    helperText?: string;
    clearOnClick?: boolean;
    resultCallback?: (res) => OptionType[];
    exclude?: number[];
    otherParams?: any;
}

export const AutocompleteField: React.ComponentType<AutocompleteProps> = (
    props: AutocompleteProps,
) => {
    const {
        apiPathName,
        placeholder,
        label,
        disabled,
        multiple,
        helperText,
        clearOnClick,
        resultCallback,
        exclude,
        otherParams,
    } = props;
    const { name, value } = props.field;
    const { touched, errors, setFieldValue, setFieldError, setFieldTouched } = props.form;
    const fieldError = getIn(errors, name);
    const showError = getIn(touched, name) && !!fieldError;
    const formHelperText = showError ? fieldError : helperText;
    const [results, setResults] = useState<OptionType[]>([]);

    useEffect(() => {
        if (exclude && exclude?.length > 0) {
            const newRes = results.filter(
                r => !exclude.find((eId: number) => eId === parseInt(r.id)),
            );
            if (JSON.stringify(newRes) !== JSON.stringify(results)) {
                setResults(newRes);
            }
        }
    }, [exclude, results]);

    const [onValueChange] = useEventCallback(value$ => {
        const getSuggestions = (filterValue: string) => {
            get(
                apiUrl(
                    `${apiPathName}/?filter=${filterValue}${
                        otherParams ? "&" + serialize(otherParams) : ""
                    }`,
                ),
                undefined,
                undefined,
            ).subscribe(
                res => {
                    setFieldTouched(name, true, false);
                    if (res.response.length > 0) {
                        setResults(
                            resultCallback
                                ? resultCallback(res.response)
                                : res.response.filter(val => !!val.name),
                        );
                    } else {
                        setResults([]);
                        setFieldError(name, "No results");
                    }
                },
                err => {
                    setFieldTouched(name, true, false);
                    setFieldError(name, err.message);
                },
            );
        };

        return value$.pipe(
            tap(() => {
                setResults([]);
                setFieldError(name, "");
            }),
            debounceTime(500),
            filter((value: string) => !!value && value.length > 2),
            withLatestFrom((value: string) => {
                getSuggestions(value);
            }),
        );
    });

    const handleChange = (
        val: OptionType,
        stateAndHelpers: ControllerStateAndHelpers<OptionType>,
    ) => {
        if (multiple) {
            if (val && !value.some(el => el.id === val.id)) {
                setFieldValue(name, [...value, val]);
                stateAndHelpers.clearSelection();
            }
        } else {
            setFieldValue(name, val);
        }
    };

    const handleRemove = id => {
        setFieldValue(
            name,
            value.filter(val => val.id !== id),
        );
    };

    return (
        <FormControl
            error={showError}
            required={props.required}
            onClick={() => (clearOnClick ? setFieldValue(name, null) : null)}
            margin={props.margin}
        >
            <Box width="100%" maxWidth={props.width}>
                <DownshiftRender
                    itemToString={options => (options ? options.name : "")}
                    onInputValueChange={onValueChange}
                    availableItems={results}
                    selectedItem={value ? value : null}
                    onChange={handleChange}
                    placeholder={placeholder}
                    label={label}
                    disabled={disabled}
                    multiple={multiple}
                    helperText={formHelperText}
                    error={showError}
                />
            </Box>
            {multiple && value && value.length > 0 && (
                <Box mt={2}>
                    {value.map(item => (
                        <Chip
                            key={item.id}
                            label={item.name}
                            onClick={item.url ? () => window.open(item.url, "_blank") : undefined}
                            onDelete={() => handleRemove(item.id)}
                        />
                    ))}
                </Box>
            )}
        </FormControl>
    );
};

AutocompleteField.displayName = "FormikAutocompleteField";
