import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";
import { StaffUserModel } from "src/modules/school/models/StaffUserModel";
import { SCHOOL_STAFF_LIST_QUERY } from "./useStaffList";
import { useTranslation } from "react-i18next";

export interface InviteFinanceRequest {
    email: string;
    title: string;
    firstName: string;
    lastName: string;
    password: string;
}

const apiInviteFinance = async (values: InviteFinanceRequest): Promise<AxiosResponse> => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/staff/invite-finance-manager/`,
        values,
    );
};

export const useInviteFinance = (onSuccess?, onError?) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: InviteFinanceRequest) => apiInviteFinance(values),
        onSuccess: (res: AxiosResponse<StaffUserModel[]>) => {
            queryClient.invalidateQueries({
                queryKey: [SCHOOL_STAFF_LIST_QUERY, getSchoolAccountId()],
            });
            enqueueSnackbar(t("school.staff.list.financeInvited"), { variant: "success" });
            if (onSuccess) {
                onSuccess(res.data);
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            if (onError) {
                onError();
            }
            enqueueSnackbar(err.response.data?.error, {
                ...SnackbarErrorOptions,
            });
        },
    });
};
