import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export interface LastUpdatedClassTrackersStatus {
    id: number;
    status: string;
}

export const STATUSES_QUERY = "statusesQuery";

export const useSatuses = (cohort: number) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [STATUSES_QUERY, schoolAccountId, cohort],
        queryFn: async (): Promise<LastUpdatedClassTrackersStatus[]> => {
            if (cohort) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker/by-cohort/${cohort}/statuses/`,
                );

                return data || [];
            }
            return [];
        },
    });
};
