import { absenceValuesArray } from "src/modules/tracker/services/valueHandler";
import { AnalysisGradeSummaryValuesFlatObject } from "../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { getAnalysisGradeValue } from "./analysisValueHandler";

export const prepareEmptyAverageRow = columns => {
    const averageRow = {};
    if (columns) {
        // process only first row (average row)
        Object.keys(columns).forEach(key => {
            if (key.includes("spec", 0)) {
                averageRow[key] = {
                    value: 0,
                    data: [],
                    counter: 0,
                    round: null,
                    gradeTypeId: columns[key].gradeTypeId,
                };
            }
        });
    }

    return averageRow;
};

export const prepareEmptyKS4AverageRow = columns => {
    const averageRow = {};
    if (columns) {
        // process only first row (average row)
        Object.keys(columns).forEach(key => {
            if (!key.includes("student")) {
                averageRow[key] = {
                    value: 0,
                    data: [],
                    counter: 0,
                    round: null,
                };
            }
        });
    }

    return averageRow;
};

export const getAverageColumnList = (
    rowsData: AnalysisGradeSummaryValuesFlatObject[] | null,
    columns,
) => {
    const averageRow: AnalysisGradeSummaryValuesFlatObject = {};

    if (columns && rowsData && rowsData.length > 0) {
        Object.keys(rowsData[0]).forEach(key => {
            if (key.includes("comp") || key.includes("spec") || key.includes("agg")) {
                averageRow[key] = 0;
            }
        });
    }
    return averageRow;
};

export const getAverageKS4ColumnList = (
    rowsData: AnalysisGradeSummaryValuesFlatObject[] | null,
    columns,
) => {
    const averageRow: AnalysisGradeSummaryValuesFlatObject = {};

    if (columns && rowsData && rowsData.length > 0) {
        Object.keys(rowsData[0]).forEach(key => {
            if (key.includes("comp") || key.includes("spec") || key.includes("agg")) {
                averageRow[key] = 0;
            }
        });
    }
    return averageRow;
};

// main generate average function
export const generateAnalysisAverageRow = (
    values,
    ananalysisGradeSummaryHeader,
    analysisGradeSummaryRelated,
    viewType,
    t,
) => {
    const averageRow = prepareEmptyAverageRow(ananalysisGradeSummaryHeader.columns);
    const resultAverage = getAverageColumnList(values, ananalysisGradeSummaryHeader.columns);

    values.forEach(row => {
        Object.keys(row).forEach(key => {
            if (
                key.includes("spec", 0) &&
                row[key] !== undefined &&
                row[key] !== null &&
                averageRow[key]
            ) {
                if (!absenceValuesArray.includes(`${row[key]}`)) {
                    averageRow[key].value = averageRow[key]
                        ? averageRow[key].value + (!isNaN(row[key]) ? parseInt(row[key]) : row[key])
                        : row[key];
                    averageRow[key].data.push(row[key]);
                    averageRow[key].counter = averageRow[key] ? averageRow[key].counter + 1 : 1;
                }
            }
        });
    });

    if (Object.keys(averageRow).length > 0) {
        Object.keys(averageRow).forEach(arKey => {
            const averageObj = averageRow[arKey];

            if (averageObj.value && averageObj.data.length > 0) {
                resultAverage[arKey] = getAnalysisGradeValue(
                    Math.round(averageObj.value / averageObj.counter),
                    analysisGradeSummaryRelated,
                    averageObj.gradeTypeId,
                    viewType,
                );
            } else {
                resultAverage[arKey] = null;
            }
        });
    }
    if (Object.keys(resultAverage).length > 0) {
        Object.keys(resultAverage).forEach(rarkey => {
            resultAverage[rarkey] =
                `${resultAverage[rarkey]}` === "-2"
                    ? "X"
                    : `${resultAverage[rarkey]}` === "-3"
                      ? "A"
                      : resultAverage[rarkey];
        });
    }

    resultAverage["student_firstName"] = t("analysis.grid.averageRow");

    return resultAverage;
};

export const generateAtlAverageRow = (values, ananalysisGradeSummaryHeader, t) => {
    const averageRow = prepareEmptyKS4AverageRow(ananalysisGradeSummaryHeader.columns);
    const resultAverage = getAverageKS4ColumnList(values, ananalysisGradeSummaryHeader.columns);

    values.forEach(row => {
        Object.keys(row).forEach(key => {
            if (
                row[key] !== undefined &&
                row[key] !== null &&
                averageRow[key] &&
                !absenceValuesArray.includes(`${row[key]}`)
            ) {
                averageRow[key].value = averageRow[key]
                    ? averageRow[key].value + (!isNaN(row[key]) ? parseFloat(row[key]) : row[key])
                    : row[key];
                averageRow[key].data.push(row[key]);
                averageRow[key].counter = averageRow[key] ? averageRow[key].counter + 1 : 1;
            }
        });
    });

    if (Object.keys(averageRow).length > 0) {
        Object.keys(averageRow).forEach(arKey => {
            const averageObj = averageRow[arKey];

            if (averageObj.value && averageObj.data.length > 0) {
                resultAverage[arKey] = Math.ceil(averageObj.value / averageObj.counter);
            } else {
                resultAverage[arKey] = null;
            }
        });
    }

    resultAverage["student_firstName"] = t("analysis.grid.averageRow");

    return resultAverage;
};

export const generateAnalysisKS4AverageRow = (values, ananalysisGradeSummaryHeader, t) => {
    const averageRow = prepareEmptyKS4AverageRow(ananalysisGradeSummaryHeader.columns);
    const resultAverage = getAverageKS4ColumnList(values, ananalysisGradeSummaryHeader.columns);

    values.forEach(row => {
        Object.keys(row).forEach(key => {
            if (
                row[key] !== undefined &&
                row[key] !== null &&
                !absenceValuesArray.includes(`${row[key]}`) &&
                averageRow[key]
            ) {
                if (
                    (row.metaColumnsConfig &&
                        row.metaColumnsConfig[key] &&
                        row.metaColumnsConfig[key]["a8"]) ||
                    key.indexOf("computed") === 0 ||
                    key.indexOf("aggregate") === 0
                ) {
                    averageRow[key].value = averageRow[key]
                        ? averageRow[key].value +
                          (!isNaN(row[key]) ? parseFloat(row[key]) : row[key])
                        : row[key];
                    averageRow[key].data.push(row[key]);

                    averageRow[key].counter = averageRow[key] ? averageRow[key].counter + 1 : 1;
                }
            }
        });
    });

    if (Object.keys(averageRow).length > 0) {
        Object.keys(averageRow).forEach(arKey => {
            const averageObj = averageRow[arKey];

            if (averageObj.value && averageObj.data.length > 0) {
                resultAverage[arKey] = (
                    Math.round((averageObj.value / averageObj.counter) * 100) / 100
                ).toFixed(2);
            } else {
                resultAverage[arKey] = null;
            }
        });
    }

    resultAverage["student_firstName"] = t("analysis.grid.averageRowKs4");

    return resultAverage;
};
