import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { SnapshotReportListResponse } from "src/modules/report/api/SnapshotReport/getList";
import { OrmSnapshotReport } from ".";

export enum OrmSnapshotReportActionTypes {
    BULK_IMPORT = "[orm] SNAPSHOT REPORT BULK IMPORT",
    UPSERT = "[orm] SNAPSHOT REPORT UPSERT",
}

export const OrmSnapshotReportActions = {
    bulkImport: (payload: SnapshotReportListResponse) =>
        createAction(OrmSnapshotReportActionTypes.BULK_IMPORT, payload),
    upsert: (payload: OrmSnapshotReport) =>
        createAction(OrmSnapshotReportActionTypes.UPSERT, payload),
};

export type OrmSnapshotReportActions = ActionsUnion<typeof OrmSnapshotReportActions>;
