import { attr, Model } from "redux-orm";

const fields = {
    id: attr(),
    name: attr(),
    subjectArea: attr(),
};

export interface OrmQualification {
    id: number | null;
    name: string;
    subjectArea: number | null;
    numberOfLiveTrackers?: number;
    hasClasses?: boolean;
}

class Qualification extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "Qualification";
    static fields = fields;
    static emptyRef: OrmQualification = {
        id: null,
        name: "",
        subjectArea: null,
    };

    get ormRef(): OrmQualification {
        return this.ref as any;
    }
}

export default Qualification;
