import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getAnalysisStudentsForClassTrackerEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/common/{cohort}/{yearGroup}/class-tracker/{classTracker}/analysis-students/`,
    method: ApiRequests.GET,
};

export interface AnalysisStudentForClassTrackerResponse {
    id: number;
    firstName: string;
    lastName: string;
    uniquePupilNumber: string;
}
