import { GetSnapshotReportListFilter } from "../api/SnapshotReport/getList";
import { SnapshotReportFilterResponse } from "../api/SnapshotReport/getListFilters";
import { SearchSnapshotFiltersResponse } from "../api/SnapshotReport/searchSnapshotFilters";
import { ClassesStudentNumberForSubject } from "../api/StudentReport/getClassesBySubject";
import { ReportActions, ReportActionTypes } from "./actions";

export interface ReportState {
    yearGroupReportStudentNumber: number;
    analysisClassesWithStudentNumber: ClassesStudentNumberForSubject[] | null;
    attributeReportStudentNumber: number;
    reportValidationUrl: string | null;
    snapshotReportListFilter: {
        count: number;
        nextPage: number | null;
        requestParams?: GetSnapshotReportListFilter | null;
    };
    snapshotReportCourseFilters: SnapshotReportFilterResponse | null;
    searchSnapshotFilters: SearchSnapshotFiltersResponse | null;
}

export const initialState: ReportState = {
    yearGroupReportStudentNumber: 0,
    reportValidationUrl: null,
    analysisClassesWithStudentNumber: null,
    attributeReportStudentNumber: 0,
    snapshotReportListFilter: {
        count: 0,
        nextPage: null,
        requestParams: null,
    },
    snapshotReportCourseFilters: null,
    searchSnapshotFilters: null,
};

export const reducer = (state: ReportState = initialState, action: ReportActions): ReportState => {
    switch (action.type) {
        case ReportActionTypes.GET_SEARCH_SNAPSHOT_FILTERS_SUCCESS:
            return {
                ...state,
                searchSnapshotFilters: action.payload.response,
            };
        case ReportActionTypes.GET_STUDENT_COUNT_FOR_YEAR_GROUP_SUCCESS:
            return {
                ...state,
                yearGroupReportStudentNumber: action.payload.response.numberOfStudents,
            };
        case ReportActionTypes.GET_STUDENT_CLASSES_BY_SUBJECT_SUCCESS:
            return {
                ...state,
                analysisClassesWithStudentNumber: action.payload.response,
            };
        case ReportActionTypes.GET_STUDENT_COUNT_FOR_ATTRIBUTE_REPORT_SUCCESS:
            return {
                ...state,
                attributeReportStudentNumber: action.payload.response.numberOfStudents,
            };
        case ReportActionTypes.VALIDATE_STUDENT_REPORT_SUCCESS:
            return {
                ...state,
                reportValidationUrl: action.payload.response.url,
            };
        case ReportActionTypes.SET_SNAPSHOT_REPORT_FILTER:
            return {
                ...state,
                snapshotReportListFilter: {
                    count: action.payload.count,
                    nextPage: action.payload.nextPage,
                    requestParams: action.payload.requestParams,
                },
            };
        case ReportActionTypes.SET_SNAPSHOT_REPORT_COURSE_FILTER:
            return {
                ...state,
                snapshotReportCourseFilters: action.payload,
            };
        default:
            return state;
    }
};
