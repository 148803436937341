import { ActionsUnion, Keys } from "@martin_hotell/rex-tils";
import { processEndpoints } from "src/api/helpers";
import { Modules } from "src/types";
import {
    CommonGradeActionsTypes,
    CommonGradeApiEndpoints,
    CommonGradeRawActions,
} from "./Grade/actions";
import {
    CommonQualificationActionsTypes,
    CommonQualificationApiEndpoints,
    CommonQualificationRawActions,
} from "./Qualification/actions";
import {
    CommonSpecificationActionsTypes,
    CommonSpecificationApiEndpoints,
    CommonSpecificationRawActions,
} from "./Specification/actions";
import {
    CommonSpecificationAttributeActionsTypes,
    CommonSpecificationAttributeApiEndpoints,
    CommonSpecificationAttributeRawActions,
} from "./SpecificationAttribute/actions";
import {
    CommonSubjectAreaActionsTypes,
    CommonSubjectAreaApiEndpoints,
    CommonSubjectAreaRawActions,
} from "./SubjectArea/actions";
import {
    CommonTopicRawActions,
    CommonTopicActionsTypes,
    CommonTopicApiEndpoints,
} from "./Topic/actions";

export const CommonActionTypes = {
    ...CommonSubjectAreaActionsTypes,
    ...CommonQualificationActionsTypes,
    ...CommonSpecificationActionsTypes,
    ...CommonSpecificationAttributeActionsTypes,
    ...CommonGradeActionsTypes,
    ...CommonTopicActionsTypes,
};

const _endpoints = {
    ...CommonSubjectAreaApiEndpoints,
    ...CommonQualificationApiEndpoints,
    ...CommonSpecificationApiEndpoints,
    ...CommonSpecificationAttributeApiEndpoints,
    ...CommonGradeApiEndpoints,
    ...CommonTopicApiEndpoints,
};

export type CommonEndpointKeys = Keys<typeof _endpoints>;

export const CommonEndpoints = processEndpoints<CommonEndpointKeys>(Modules.COMMON, _endpoints);

export const CommonActions = {
    ...CommonSubjectAreaRawActions(CommonEndpoints),
    ...CommonQualificationRawActions(CommonEndpoints),
    ...CommonSpecificationRawActions(CommonEndpoints),
    ...CommonSpecificationAttributeRawActions(CommonEndpoints),
    ...CommonGradeRawActions(CommonEndpoints),
    ...CommonTopicRawActions(CommonEndpoints),
};

export type CommonActions = ActionsUnion<typeof CommonActions>;
