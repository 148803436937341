import { useEffect, useState } from "react";
import { SchoolUserRole } from "src/orm/models/User";
import { useAccountInfo } from "./useAccountInfo";
import { useProfile } from "src/modules/user/hooks/useProfile";

interface RolesPermissions {
    canManageAssessments: boolean;
}

export const useRoles = (): RolesPermissions => {
    const [canManageAssessments, setCanManageAssessments] = useState<boolean>(false);
    const { data: accountInfo } = useAccountInfo();
    const { data: userProfile } = useProfile();

    useEffect(() => {
        if (userProfile) {
            setCanManageAssessments(
                !!(
                    userProfile.userRole === SchoolUserRole.OWNER ||
                    userProfile.userRole === SchoolUserRole.SCHOOL_ADMIN ||
                    userProfile?.isLeaderOfAll ||
                    (userProfile?.leaderOfSubjectAreas &&
                        userProfile?.leaderOfSubjectAreas.length > 0)
                ),
            );
        }
    }, [userProfile, accountInfo]);

    return { canManageAssessments };
};
