import logotype from "src/assets/logotype.svg";
import COLORS from "src/styles/colors";
import Icon from "src/components/Icon";
import {
    Box,
    AppBar,
    Theme,
    TextField,
    MenuItem,
    IconButton,
    Typography,
    Button,
    Divider,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { ReactNode, ReactElement, FC } from "react";
import { useTranslation } from "react-i18next";
import { mdiHelpCircleOutline, mdiChevronDown, mdiLoginVariant, mdiAccountOutline } from "@mdi/js";
import { PRIMARY_FONT_FAMILY } from "../../../styles/theme";
import { Link, NavLink, NavLinkProps } from "react-router-dom";
import {
    ROUTE_TRUST_DASHBOARD,
    ROUTE_TRUST_USERS,
    ROUTE_LOGOUT,
    ROUTE_USER_ACCOUNT,
    ROUTE_CLASS_CLASS_LIST,
} from "src/routes";
import { useSchoolNavigate } from "../hooks/useSchoolNavigate";
import { useUserContext } from "src/modules/user/hooks/useUserContext";
import { useChangeLanguage } from "src/modules/user/hooks/useChangeLanguage";
import { Storage } from "src/services/storage";
import { getTrustAccountId } from "src/services/url";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    ".logo": {
        "& > img": {
            display: "block",
            width: "100%",
            height: "auto",
        },
    },
    ".language": {
        fontFamily: PRIMARY_FONT_FAMILY,
        paddingLeft: theme.spacing(2.5),
        marginLeft: theme.spacing(2.5),
        width: 160,
        position: "relative",
        "&:before": {
            content: "''",
            width: 1,
            height: 28,
            backgroundColor: COLORS.VERY_LIGHT_GREY_3,
            position: "absolute",
            top: "50%",
            left: 0,
            transform: "translateY(-50%)",
        },
    },
    ".user": {
        fontFamily: PRIMARY_FONT_FAMILY,
        paddingLeft: theme.spacing(2.5),
        marginLeft: theme.spacing(1),
        position: "relative",
        cursor: "pointer",
        "&:before": {
            content: "''",
            width: 1,
            height: 28,
            backgroundColor: COLORS.VERY_LIGHT_GREY_3,
            position: "absolute",
            top: "50%",
            left: 0,
            transform: "translateY(-50%)",
        },
        "& strong:not(.MuiButton-icon), & span:not(.MuiButton-icon)": {
            display: "block",
            fontFamily: "inherit",
            fontWeight: 600,
            lineHeight: 1,
        },
        "& strong:not(.MuiButton-icon)": {
            color: COLORS.GREY_1,
            fontSize: theme.typography.pxToRem(14),
        },
        "& span:not(.MuiButton-icon)": {
            color: COLORS.BLUE_3,
            fontSize: theme.typography.pxToRem(12),
            marginTop: theme.spacing(0.5),
        },
        "&:hover .userMenu": {
            opacity: 1,
            visibility: "visible",
        },
        "& .MuiButton-root": {
            justifyContent: "flex-start",
            padding: theme.spacing(1.5, 3),
        },
    },
    ".helpBtn": {
        padding: theme.spacing(1),
        marginLeft: theme.spacing(-0.75),
        marginRight: theme.spacing(1.5),
    },
    ".nav": {
        listStyle: "none",
        margin: 0,
        padding: 0,
        height: 70,
    },
    ".navItem": {
        display: "flex",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1,
        position: "relative",
        "& > a .MuiBadge-badge": {
            right: -7,
            top: "50%",
            transition: theme.transitions.create(["opacity"], {
                duration: theme.transitions.duration.complex,
            }),
        },
        "&:hover": {
            "& > a": {
                color: COLORS.BLUE_2,
            },
            "& > a .MuiBadge-badge": {
                opacity: 0,
            },
        },
        "& + &": {
            marginLeft: theme.spacing(2),
        },
        "&:hover > div": {
            opacity: 1,
            visibility: "visible",
        },
    },
    ".navLink": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        background: "none",
        border: "0 none",
        outline: "0 none",
        color: COLORS.GREY_1,
        fontFamily: PRIMARY_FONT_FAMILY,
        fontSize: "inherit",
        fontWeight: 700,
        padding: theme.spacing(0, 2),
        textDecoration: "none",
        transition: theme.transitions.create(["opacity", "bottom"], {
            duration: theme.transitions.duration.complex,
        }),
        position: "relative",
        "&:hover": {
            color: COLORS.BLUE_2,
        },
        "&.active": {
            color: COLORS.BLUE_2,
        },
        "&.active:before": {
            content: "''",
            backgroundColor: COLORS.BLUE_1,
            opacity: 1,
            height: 4,
            position: "absolute",
            right: 0,
            bottom: 0,
            left: 0,
        },
    },
}));

// TODO: @mariusz large refactor of the user menu and extraction into one common style
const SubMenu = styled(Box)(({ theme }) => ({
    width: 254,
    background: "#fff",
    boxShadow: "0 4px 14px 0 rgb(208 208 208 / 50%)",
    borderRadius: 4,
    listStyle: "none",
    margin: 0,
    padding: theme.spacing(1.75, 3),
    display: "flex",
    opacity: 0,
    visibility: "hidden",
    flexDirection: "column",
    position: "absolute",
    top: "75%",
    left: 0,
    zIndex: 10,
    transition: theme.transitions.create(["opacity"], {
        duration: theme.transitions.duration.complex,
    }),
    "&.userMenu": {
        backgroundColor: "transparent",
        boxShadow: "none",
        borderRadius: 0,
        padding: 0,
        paddingTop: 10,
        display: "flex",
        alignItems: "flex-end",
        left: "auto",
        right: 0,
        top: "75%",
        "& > div": {
            width: 254,
            background: "#fff",
            boxShadow: "0 4px 14px 0 rgb(208 208 208 / 50%)",
            borderRadius: 4,
            listStyle: "none",
            margin: 0,
            padding: theme.spacing(1, 0),
            display: "flex",
            flexDirection: "column",
        },
    },
    "&:not(.userMenu) a, &:not(.userMenu) button": {
        margin: 0,
        border: 0,
        outline: 0,
        backgroundColor: "transparent",
        cursor: "pointer",
        display: "block",
        fontFamily: PRIMARY_FONT_FAMILY,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 700,
        color: "#7a94a8",
        lineHeight: 1.3,
        padding: theme.spacing(0.75, 0),
        textAlign: "left",
    },
    "& a:hover,& button:hover": {
        color: theme.palette.primary.main,
    },
    "&.userMenu a:not(.MuiButton-root)": {
        backgroundColor: "transparent",
        cursor: "pointer",
        display: "block",
        fontFamily: PRIMARY_FONT_FAMILY,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 700,
        color: "#626F7A",
        lineHeight: 1.3,
        padding: theme.spacing(0.5, 3),
        marginBottom: theme.spacing(1),
        textAlign: "left",
    },
    "&.userMenu a:not(.MuiButton-root):hover": {
        color: "#447BBE",
    },
}));

const LangTextField = withStyles((theme: Theme) => ({
    root: {
        marginBottom: 0,
        "& label": {
            fontFamily: PRIMARY_FONT_FAMILY,
            fontWeight: 600,
        },
        "& .MuiInput-underline:before, & .MuiInput-underline:after": {
            display: "none",
        },
        "& .MuiInputBase-root": {
            color: COLORS.GREY_1,
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: theme.typography.pxToRem(16),
            fontWeight: 600,
        },
        "& .MuiInputBase-input": {
            padding: 0,
        },
    },
}))(TextField);
interface NavProps {
    children: ReactNode;
}

const Nav: FC<NavProps> = ({ children }): ReactElement => {
    return (
        <Box component="nav">
            <Box component="ul" display="flex" className="nav">
                {children}
            </Box>
        </Box>
    );
};

interface NavItemProps {
    children: ReactNode;
    submenu?: any;
    onClick?: () => void;
}

const NavItem: FC<NavItemProps & NavLinkProps> = ({
    children,
    submenu,
    ...props
}): ReactElement => {
    return (
        <Box component="li" className="navItem">
            <NavLink {...props} className="navLink">
                {children}{" "}
                {submenu && submenu.length > 0 && <Icon size="20px" path={mdiChevronDown} />}
            </NavLink>
        </Box>
    );
};

const TrustNavbar = () => {
    const { t } = useTranslation();
    const { data: userContext } = useUserContext();
    const { navigate, attachTrustId, attachSchoolId } = useSchoolNavigate();
    const trustId = getTrustAccountId();

    const { mutate: changeLanguage } = useChangeLanguage();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (userContext?.language !== event.target.value) {
            changeLanguage(event.target.value as string);
        }
    };

    return (
        <>
            <StyledAppBar>
                <Box
                    display="flex"
                    px={3}
                    minWidth={1280}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" alignItems="center">
                        <Box component="figure" width={120} m={0} className="logo">
                            <img src={logotype} alt={t("common.logoAlt") as string} />
                        </Box>
                        <Box className="language">
                            <LangTextField
                                label={t("navbar.language")}
                                value={userContext?.language || "en"}
                                select
                                onChange={handleChange}
                            >
                                <MenuItem value="en">English</MenuItem>
                                <MenuItem value="cy">Welsh</MenuItem>
                            </LangTextField>
                        </Box>
                    </Box>

                    <Nav>
                        {userContext?.trusts?.length > 0 ? (
                            <>
                                <NavItem
                                    className={({ isActive }) =>
                                        isActive ? "isActive" : undefined
                                    }
                                    to={attachTrustId(ROUTE_TRUST_DASHBOARD)}
                                >
                                    {t("navbar.trustDashboard")}
                                </NavItem>
                                <NavItem
                                    className={({ isActive }) =>
                                        isActive ? "isActive" : undefined
                                    }
                                    to={attachTrustId(ROUTE_TRUST_USERS)}
                                >
                                    {t("navbar.trustUsers")}
                                </NavItem>
                            </>
                        ) : (
                            <></>
                        )}
                    </Nav>
                    <Box display="flex" alignItems="center">
                        <IconButton
                            className="helpBtn"
                            component="a"
                            href={process.env.REACT_APP_HELP_CENTRE_URL}
                            target="_blank"
                        >
                            <Icon size="20px" path={mdiHelpCircleOutline} />
                        </IconButton>
                        <Box
                            className="user"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            {userContext && (
                                <Box>
                                    <Typography component="strong">{`${userContext?.email}`}</Typography>
                                    <Typography component="span">{`${userContext?.trusts?.find(t => t.id === parseInt(`${trustId}`))?.name || ""}`}</Typography>
                                </Box>
                            )}

                            <Box ml={1}>
                                <Icon size="20px" path={mdiChevronDown} />
                            </Box>
                            <SubMenu className="userMenu">
                                <Box>
                                    <Button
                                        component={Link as any}
                                        to={attachSchoolId(ROUTE_USER_ACCOUNT)}
                                        variant="text"
                                        startIcon={
                                            <Icon path={mdiAccountOutline} color="currentColor" />
                                        }
                                        disableRipple
                                    >
                                        {t("navbar.myAccount")}
                                    </Button>
                                    <Divider sx={{ m: 0 }} />
                                    {userContext?.trusts?.length > 0 && (
                                        <>
                                            <Typography
                                                sx={{
                                                    px: 3,
                                                    pt: 1.5,
                                                    pb: 0.5,
                                                    fontSize: 8,
                                                    fontFamily: PRIMARY_FONT_FAMILY,
                                                    fontWeight: 700,
                                                    color: COLORS.GREY_2,
                                                }}
                                            >
                                                {t("navbar.myTrusts")}
                                            </Typography>
                                            <Box sx={{ position: "relative" }}>
                                                <Box
                                                    sx={{
                                                        backgroundImage:
                                                            "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
                                                        height: 10,
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 18,
                                                        left: 0,
                                                        zIndex: 10,
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        maxHeight: 154,
                                                        overflowY: "auto",
                                                        position: "relative",
                                                    }}
                                                >
                                                    {userContext?.trusts.map(t => (
                                                        <NavLink
                                                            key={t.id}
                                                            to={ROUTE_TRUST_DASHBOARD.replace(
                                                                ":trust",
                                                                `${t.id}`,
                                                            )}
                                                            onClick={() => {
                                                                setTimeout(() => {
                                                                    navigate(0);
                                                                }, 0);
                                                            }}
                                                        >
                                                            {t.name}
                                                        </NavLink>
                                                    ))}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        backgroundImage:
                                                            "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
                                                        height: 18,
                                                        position: "absolute",
                                                        right: 18,
                                                        bottom: 1,
                                                        left: 0,
                                                        zIndex: 10,
                                                    }}
                                                />
                                                <Divider sx={{ m: 0 }} />
                                            </Box>
                                        </>
                                    )}
                                    {userContext?.schoolAccounts?.length > 0 && (
                                        <>
                                            <Typography
                                                sx={{
                                                    px: 3,
                                                    pt: 1.5,
                                                    pb: 0.5,
                                                    fontSize: 8,
                                                    fontFamily: PRIMARY_FONT_FAMILY,
                                                    fontWeight: 700,
                                                    color: COLORS.GREY_2,
                                                }}
                                            >
                                                {t("navbar.mySchools")}
                                            </Typography>
                                            <Box sx={{ position: "relative" }}>
                                                <Box
                                                    sx={{
                                                        backgroundImage:
                                                            "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
                                                        height: 10,
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 18,
                                                        left: 0,
                                                        zIndex: 10,
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        maxHeight: 154,
                                                        overflowY: "auto",
                                                        position: "relative",
                                                    }}
                                                >
                                                    {userContext?.schoolAccounts.map(sa => (
                                                        <NavLink
                                                            key={sa.id}
                                                            to={ROUTE_CLASS_CLASS_LIST.replace(
                                                                ":schoolAccountId",
                                                                `${sa.id}`,
                                                            )}
                                                            onClick={() => {
                                                                Storage.setItem(
                                                                    "schoolAccountId",
                                                                    `${sa.id}`,
                                                                );
                                                                setTimeout(() => {
                                                                    navigate(0);
                                                                }, 0);
                                                            }}
                                                        >
                                                            {sa.name}
                                                        </NavLink>
                                                    ))}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        backgroundImage:
                                                            "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
                                                        height: 18,
                                                        position: "absolute",
                                                        right: 18,
                                                        bottom: 1,
                                                        left: 0,
                                                        zIndex: 10,
                                                    }}
                                                />
                                                <Divider sx={{ m: 0 }} />
                                            </Box>
                                        </>
                                    )}
                                    <Button
                                        onClick={() => navigate(ROUTE_LOGOUT)}
                                        variant="text"
                                        startIcon={
                                            <Icon path={mdiLoginVariant} color="currentColor" />
                                        }
                                        disableRipple
                                    >
                                        {t("navbar.signOut")}
                                    </Button>
                                </Box>
                            </SubMenu>
                        </Box>
                    </Box>
                </Box>
            </StyledAppBar>
        </>
    );
};

export default TrustNavbar;
