import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction, paramRequest } from "src/api/helpers";
import { SnapshotData } from "src/context/TrackerContext";
import {
    commonAnalysisYearGroupsEndpoint,
    CommonCohortAnalysisYearGroupResponse,
} from "src/modules/common/api/analysisCohortYearGroups";
import {
    AnalysisRelatedDataObject,
    getAnalysisCommonRelatedDataEndpoint,
} from "../../api/Common/getRelatedData";
import {
    AnalysisStudentForClassTrackerResponse,
    getAnalysisStudentsForClassTrackerEndpoint,
} from "../../api/Common/getStudentsForClassTracker";

export enum AnalysisCommonActionsTypes {
    CLEAR_ALL_ANALYSIS_DATA = "[analysis] CLEAR ALL ANALYSIS DATA",
    GET_ANALYSIS_RELATED = "[analysis] GET ANALYSIS RELATED",
    GET_ANALYSIS_RELATED_SUCCESS = "[analysis] GET ANALYSIS RELATED SUCCESS",
    SET_ANALYSIS_RELATED = "[analysis] SET ANALYSIS RELATED",
    GET_ANALYSIS_STUDENTS_FOR_CLASS_TRACKER = "[analysis] GET ANALYSIS STUDENTS FOR CLASS TRACKER",
    GET_ANALYSIS_STUDENTS_FOR_CLASS_TRACKER_SUCCESS = "[analysis] GET ANALYSIS STUDENTS FOR CLASS TRACKER SUCCESS",
    SET_ANALYSIS_STUDENTS_FOR_CLASS_TRACKER = "[analysis] SET ANALYSIS STUDENTS FOR CLASS TRACKER",
    GET_ANALYSIS_COHORT_YEAR_GROUPS = "[analysis] GET ANALYSIS COHORT YEAR GROUPS",
    GET_ANALYSIS_COHORT_YEAR_GROUPS_SUCCESS = "[analysis] GET ANALYSIS COHORT YEAR GROUPS SUCCESS",
    SET_ANALYSIS_SNAPSHOT_DATA = "[analysis] SET ANALYSIS SNAPSHOT DATA",
}

export const AnalysisCommonApiEndpoints = {
    getAnalysisRelated: createActionObject(
        //we have rq version
        getAnalysisCommonRelatedDataEndpoint,
        AnalysisCommonActionsTypes.GET_ANALYSIS_RELATED_SUCCESS,
    ),
    getAnalysisStudentsForClassTracker: createActionObject(
        getAnalysisStudentsForClassTrackerEndpoint,
        AnalysisCommonActionsTypes.GET_ANALYSIS_STUDENTS_FOR_CLASS_TRACKER_SUCCESS,
    ),
    getAnalysisCohortYearGroups: createActionObject(
        commonAnalysisYearGroupsEndpoint,
        AnalysisCommonActionsTypes.GET_ANALYSIS_COHORT_YEAR_GROUPS_SUCCESS,
    ),
};

export const AnalysisCommonRawActions = (
    AnalysisCommonEndpoints: Record<Keys<typeof AnalysisCommonApiEndpoints>, ApiActionMeta>,
) => ({
    clearAllAnalysisData: () => createAction(AnalysisCommonActionsTypes.CLEAR_ALL_ANALYSIS_DATA),
    getAnalysisRelated: (cohort: number, yearGroup: number) =>
        createApiAction(
            AnalysisCommonActionsTypes.GET_ANALYSIS_RELATED,
            paramRequest({ cohort, yearGroup }),
            AnalysisCommonEndpoints.getAnalysisRelated,
        ),
    getAnalysisRelatedSuccess: (payload: ApiCallbackPayload<AnalysisRelatedDataObject>) =>
        createAction(AnalysisCommonActionsTypes.GET_ANALYSIS_RELATED_SUCCESS, payload),
    setAnalysisRelated: (payload: AnalysisRelatedDataObject) =>
        createAction(AnalysisCommonActionsTypes.SET_ANALYSIS_RELATED, payload),
    getAnalysisStudentsForClassTracker: (cohort: number, yearGroup: number, classTracker: number) =>
        createApiAction(
            AnalysisCommonActionsTypes.GET_ANALYSIS_STUDENTS_FOR_CLASS_TRACKER,
            { values: {}, params: { cohort, yearGroup, classTracker } },
            AnalysisCommonEndpoints.getAnalysisStudentsForClassTracker,
        ),
    getAnalysisStudentsForClassTrackerSuccess: (
        payload: ApiCallbackPayload<AnalysisStudentForClassTrackerResponse[]>,
    ) =>
        createAction(
            AnalysisCommonActionsTypes.GET_ANALYSIS_STUDENTS_FOR_CLASS_TRACKER_SUCCESS,
            payload,
        ),
    setAnalysisStudentsForClassTracker: (payload: AnalysisStudentForClassTrackerResponse[]) =>
        createAction(AnalysisCommonActionsTypes.SET_ANALYSIS_STUDENTS_FOR_CLASS_TRACKER, payload),
    getAnalysisCohortYearGroups: () =>
        createApiAction(
            AnalysisCommonActionsTypes.GET_ANALYSIS_COHORT_YEAR_GROUPS,
            undefined,
            AnalysisCommonEndpoints.getAnalysisCohortYearGroups,
        ),
    getAnalysisCohortYearGroupsSuccess: (
        payload: ApiCallbackPayload<CommonCohortAnalysisYearGroupResponse[]>,
    ) => createAction(AnalysisCommonActionsTypes.GET_ANALYSIS_COHORT_YEAR_GROUPS_SUCCESS, payload),
    setSnapshotData: (snapshotDAta: SnapshotData) =>
        createAction(AnalysisCommonActionsTypes.SET_ANALYSIS_SNAPSHOT_DATA, snapshotDAta),
});
