import SelectClassTrackerGroupsModal from "../SelectClassTrackerGroupsModal";
import DateTimePicker, { getConvertedDateTime } from "src/components/DateTimePicker";
import { FC, useEffect, useState } from "react";
import { Box, Button, FormControlLabel, Grid, MenuItem, Radio, Typography } from "@mui/material";
import { Field, Form, useFormikContext } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { usePrevious } from "src/hooks/usePrevious";
import { ReportTemplateList } from "../../../api/ReportTemplate/getList";
import { CreateSummarySnapshotReportRequest } from "src/modules/report/api/SnapshotReport/createSummary";
import { useSnapshotClassTrackerGroups } from "src/modules/school/hooks/query/CohortSnapshot/useSnapshotClassTrackerGroups";
import * as yup from "yup";
import { SearchSchoolSnapshotFiltersResponse } from "src/modules/report/hooks/useSearchSchoolSnapshotFilters";

export interface SummaryReportFormShape {
    yearGroup: number;
    schoolSnapshot: number;
    tmpPublishType: string;
    publishedAt: string | null;
    reportTemplate: number;
    reportType: "all" | "selected";
    classTrackerGroups: { id: number; visible: boolean }[] | null;
}

export const summaryReportFormInitialValues: SummaryReportFormShape = {
    yearGroup: 0,
    schoolSnapshot: 0,
    tmpPublishType: "now",
    publishedAt: null,
    reportTemplate: 0,
    reportType: "all",
    classTrackerGroups: null,
};

export const summaryReportReportSchema = (t: TFunction) =>
    yup.object().shape({
        schoolSnapshot: yup
            .number()
            .min(1, t("report.snapshot.create.snapshotRequired"))
            .required(t("report.snapshot.create.snapshotRequired")),
        reportTemplate: yup
            .number()
            .min(1, t("report.snapshot.create.reportTemplateRequired"))
            .required(t("report.snapshot.create.reportTemplateRequired")),
        yearGroup: yup
            .number()
            .min(1, t("report.snapshot.create.yearGroupRequired"))
            .required(t("report.snapshot.create.yearGroupRequired")),
    });

interface OwnProps {
    snapshots: SearchSchoolSnapshotFiltersResponse | null;
    cohort: number | null;
    reportTemplates: ReportTemplateList | null;
}

export const convertFormValuesToReportRequest = (
    values: SummaryReportFormShape,
): CreateSummarySnapshotReportRequest => {
    return {
        schoolSnapshot: { id: values.schoolSnapshot },
        yearGroup: values.yearGroup,
        publishedAt:
            values.tmpPublishType === "now"
                ? new Date().toISOString()
                : getConvertedDateTime(values.publishedAt),
        reportTemplate: { id: values.reportTemplate },
        classTrackerGroups:
            values.reportType === "all"
                ? null
                : values.classTrackerGroups
                  ? values.classTrackerGroups
                        .filter(ctg => ctg.visible)
                        .map(ctg => ({ id: ctg.id }))
                  : null,
    };
};

const SummaryReportForm: FC<OwnProps> = ({ snapshots, cohort, reportTemplates }) => {
    const { values, isSubmitting, setValues, setFieldValue } =
        useFormikContext<SummaryReportFormShape>();
    const { t } = useTranslation();
    const yearGroupList = snapshots?.find(s => s.id === values?.schoolSnapshot)?.yearGroups || [];
    const prevCohort = usePrevious(cohort);
    const [selectGroupsOpen, setSelectGroupsOpen] = useState<boolean>(false);

    const {
        data: snapshotClassTrackerGroups,
        isSuccess,
        refetch: refetchClassTrackerGroups,
    } = useSnapshotClassTrackerGroups(parseInt(`${values.schoolSnapshot}`));

    useEffect(() => {
        if (cohort && prevCohort && prevCohort !== cohort) {
            setValues(summaryReportFormInitialValues);
        }
    }, [cohort]);

    useEffect(() => {
        if (reportTemplates && reportTemplates.length > 0 && values?.reportTemplate === 0) {
            setFieldValue("reportTemplate", reportTemplates[0].id);
        }
    }, [reportTemplates]);

    useEffect(() => {
        setFieldValue("classTrackerGroups", null);
    }, [values.schoolSnapshot]);

    useEffect(() => {
        if (values.reportType === "selected") {
            refetchClassTrackerGroups();
            setSelectGroupsOpen(true);
        }
        if (values.reportType === "selected") {
            setFieldValue("classTrackerGroups", null);
        }
    }, [values.reportType]);

    return (
        <Form>
            {snapshots && snapshots.length > 0 ? (
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Field
                            name={"schoolSnapshot"}
                            label={t("report.snapshot.create.snapshot")}
                            select
                            component={TextField}
                            fullWidth
                            disabled={isSubmitting}
                            displayEmpty
                            placeholder={t("report.snapshot.create.snapshot")}
                        >
                            <MenuItem value={0}>{t("common.none")}</MenuItem>
                            {snapshots &&
                                snapshots.length > 0 &&
                                snapshots.map(s => {
                                    return (
                                        <MenuItem key={s.id} value={s.id}>
                                            {s.name}
                                        </MenuItem>
                                    );
                                })}
                        </Field>
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name={"yearGroup"}
                            label={t("report.snapshot.create.yearGroup")}
                            select
                            component={TextField}
                            fullWidth
                            disabled={isSubmitting || !values?.schoolSnapshot}
                            displayEmpty
                            placeholder={t("report.snapshot.create.yearGroup")}
                        >
                            <MenuItem value={0}>{t("common.none")}</MenuItem>
                            {yearGroupList &&
                                yearGroupList.length > 0 &&
                                yearGroupList.map(yg => {
                                    return (
                                        <MenuItem key={yg} value={yg}>
                                            {yg}
                                        </MenuItem>
                                    );
                                })}
                        </Field>
                    </Grid>
                    {values?.schoolSnapshot ? (
                        <>
                            {values.yearGroup !== null && (
                                <Grid item xs={12}>
                                    <Field
                                        name={"reportType"}
                                        label={""}
                                        component={RadioGroup}
                                        row
                                    >
                                        <FormControlLabel
                                            value={`all`}
                                            control={<Radio />}
                                            label={<>{t("report.snapshot.create.allTrackers")}</>}
                                        />
                                        <FormControlLabel
                                            value={`selected`}
                                            control={<Radio />}
                                            label={
                                                <>{t("report.snapshot.create.selectedTrackers")}</>
                                            }
                                        />
                                    </Field>
                                    {values.reportType === "selected" &&
                                    values?.classTrackerGroups?.length > 0 ? (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: 2,
                                                mt: 3,
                                                alignItems: "center",
                                            }}
                                        >
                                            <Button
                                                onClick={() => {
                                                    setFieldValue("classTrackerGroups", null);
                                                    setSelectGroupsOpen(true);
                                                }}
                                            >
                                                {t("report.snapshot.create.changeSelection")}
                                            </Button>
                                            <Typography variant="caption">
                                                <em>{`${t(
                                                    "report.snapshot.create.currentlySelected",
                                                ).replace(
                                                    ":selected",
                                                    `${values.classTrackerGroups.length}`,
                                                )}`}</em>
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Field
                                    name={"tmpPublishType"}
                                    label={""}
                                    component={RadioGroup}
                                    row
                                >
                                    <FormControlLabel
                                        value={`now`}
                                        control={<Radio />}
                                        label={<>{t("report.snapshot.create.publishTypeNow")}</>}
                                    />
                                    <FormControlLabel
                                        value={`future`}
                                        control={<Radio />}
                                        label={<>{t("report.snapshot.create.publishTypeFuture")}</>}
                                    />
                                </Field>
                            </Grid>
                            {values?.tmpPublishType === "future" ? (
                                <Grid item xs={4}>
                                    <DateTimePicker
                                        name="publishedAt"
                                        label={t("report.snapshot.create.publishFutureDate")}
                                        disabled={
                                            isSubmitting || !!(values?.tmpPublishType !== "future")
                                        }
                                    />
                                </Grid>
                            ) : (
                                <></>
                            )}
                            <Grid item xs={4}>
                                <Field
                                    name={"reportTemplate"}
                                    label={t("report.snapshot.create.reportTemplate")}
                                    select
                                    component={TextField}
                                    fullWidth
                                    displayEmpty
                                    placeholder={t("report.snapshot.create.reportTemplate")}
                                >
                                    {reportTemplates &&
                                        reportTemplates.length > 0 &&
                                        reportTemplates.map(s => {
                                            return (
                                                <MenuItem key={s.id} value={s.id || 0}>
                                                    {s.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Field>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            ) : (
                <></>
            )}
            {isSuccess && selectGroupsOpen && (
                <SelectClassTrackerGroupsModal
                    open={selectGroupsOpen}
                    classTrackerGroups={
                        values.yearGroup !== undefined && values.yearGroup !== null
                            ? snapshotClassTrackerGroups.filter(
                                  sctg => sctg.yearGroup === values.yearGroup,
                              )
                            : snapshotClassTrackerGroups
                    }
                    handleSelectGroups={groups => {
                        setFieldValue("classTrackerGroups", groups);
                        setSelectGroupsOpen(false);
                    }}
                    handleClose={() => {
                        setSelectGroupsOpen(false);
                        setFieldValue("classTrackerGroups", null);
                        setFieldValue("reportType", "all");
                    }}
                />
            )}
        </Form>
    );
};

export default SummaryReportForm;
