import { Dispatch } from "redux";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { HighlightValuesState } from "../store/reducers";

const dispatchActions = (dispatch: Dispatch) => ({
    setHighlightValues: (payload: HighlightValuesState[]) => {
        dispatch(TrackerActions.setHighlightValuesState(payload));
    },
});

export const useHighlightGradeValues = () => {
    const dispatch = useDispatch();

    const { setHighlightValues } = dispatchActions(dispatch);

    const {
        highlihtValuesState,
    }: {
        highlihtValuesState: HighlightValuesState[];
    } = useSelector(
        (state: AppState) => ({
            highlihtValuesState: state.tracker.highlihtValuesState,
        }),
        shallowEqual,
    );

    const handleHighlightGradeClick = (columns, rowIndex, clickedHash) => {
        const newToggled = [...highlihtValuesState];
        newToggled.push({ columns, rowIndex, clickedHash });
        setHighlightValues(newToggled);
    };

    const handleRemoveHighlightGradeClick = rowIndex => {
        const newToggled = [...highlihtValuesState].filter(p => p.rowIndex !== rowIndex);
        setHighlightValues(newToggled);
    };

    return {
        handleHighlightGradeClick,
        handleRemoveHighlightGradeClick,
        highlihtValuesState,
    };
};
