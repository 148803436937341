import { FC, useEffect } from "react";
import { useLocation } from "react-router";
import { SCHOOL_PREFIX } from "src/routes";
import { Storage } from "src/services/storage";
import { useSchoolNavigate } from "../hooks/useSchoolNavigate";
import NotFoundContainer from "./NotFoundContainer";

const RedirecterContainer: FC = () => {
    const location = useLocation();
    const { navigate } = useSchoolNavigate();

    useEffect(() => {
        const prefixWithSchoolId = SCHOOL_PREFIX.replace(
            ":schoolAccountId",
            Storage.getItem("schoolAccountId"),
        );

        const hasSchoolPrefix = location?.pathname?.indexOf("/school/") === 0;

        if (
            !hasSchoolPrefix &&
            Storage.getItem("schoolAccountId") &&
            Storage.getItem("schoolAccountId") !== null &&
            Storage.getItem("schoolAccountId") !== "null"
        ) {
            // is an old url, try to redirect to school prefixed page
            navigate(prefixWithSchoolId + location.pathname);
        }
    }, [location]);

    return <NotFoundContainer />;
};

export default RedirecterContainer;
