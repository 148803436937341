import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";

export interface DeactivateStaffRequest {
    id: number;
    schoolAccountId?: number;
}

const apiDeactivateStaff = async (id: number, schoolAccountId?: number): Promise<AxiosResponse> => {
    if (schoolAccountId) {
        return await axiosInstance.post(`school/${schoolAccountId}/staff/${id}/deactivation/`);
    }
    return await axiosInstance.post(`staff/${id}/deactivation/`);
};

export const useStaffDeactivateMutation = (onSuccess?) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: DeactivateStaffRequest) =>
            apiDeactivateStaff(values.id, values.schoolAccountId),
        onSuccess: () => {
            enqueueSnackbar(t("school.staff.list.deactivated"), { variant: "success" });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(err.response.data?.error || t("school.staff.errorCreating"), {
                ...SnackbarErrorOptions,
            });
        },
    });
};
