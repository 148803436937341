import axiosInstance from "src/config/axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import { PagedList, Pagination } from "src/types";
import { isBoolean } from "@martin_hotell/rex-tils";
import { LinkModel } from "../model/LinkModel";
import { getSchoolAccountId } from "src/services/url";

type LinkListResponse = {
    reportLinks: LinkModel[];
    id: number;
    yearGroup: number;
    archived: boolean;
    canEdit: boolean;
    numberOfStudents: number;
    specification: {
        id: number;
        name: string;
        parentName?: string;
    };
    qualification: {
        id: number;
        name: string;
    };
    subjectArea: {
        id: number;
        name: string;
        parentName?: string;
        colour: string;
    };
};

export type LinkListRequestFilters = {
    yearGroup: number;
    subjectAreaId: number;
    qualificationId: number;
} & Pagination;

export const LINKS_LIST_QUERY = "linkListQuery";

export const useLinksList = (
    cohortId: number | undefined,
    { perPage, ...filters }: LinkListRequestFilters,
) => {
    const schoolAccountId = getSchoolAccountId();

    return useInfiniteQuery({
        queryKey: [LINKS_LIST_QUERY, schoolAccountId, cohortId, filters],
        queryFn: async ({ pageParam }): Promise<PagedList<LinkListResponse> | null> => {
            let page = pageParam;
            if (isBoolean(pageParam)) {
                page = 1;
            }

            if (cohortId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/report-links/by-academic-year/${cohortId}/`,
                    {
                        params: { ...filters, perPage, page },
                    },
                );
                return data || null;
            }
            return null;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.nextPage ? allPages.length + 1 : undefined;
        },
        enabled: false,
    });
};
