import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { Storage } from "src/services/storage";
import { bugsnagClient } from "src/services/bugsnagClient";
import { getSchoolAccountId } from "src/services/url";

export const HUBSPOT_QUERY = "HubspotQuery";

export const useHubspot = () => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [HUBSPOT_QUERY, schoolAccountId],
        queryFn: async (): Promise<{ tocken: string } | null> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/user/hubspot-token/`,
            );
            Storage.setItem("hstoken", data.token || "");
            if (!data.token) {
                const errorMessage: any = {
                    severity: "warning",
                    metaData: {
                        payload: "No hubspot token returned",
                        ajax: {
                            status: 200,
                            url: "/user/hubspot-token/",
                            method: "get",
                            responseType: "json",
                        },
                    },
                };
                bugsnagClient.notify("No hubspot token returned", errorMessage);
            }
            return data || null;
        },
    });
};
