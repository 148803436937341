import { AttributePair, FiltersShape } from "src/modules/common/components/Grid/GridStudentFilter";

export const mergeAttributePairToFilters = (
    filters: FiltersShape,
    key: string,
    value: AttributePair,
    replaceCurrentFilterValue?: boolean,
): FiltersShape => {
    const newFilters = { ...filters };
    if (newFilters[key] && !replaceCurrentFilterValue) {
        let newValues = [...newFilters[key].values];

        value.values.forEach(newValue => {
            if (!newValues.find(v => JSON.stringify(v) === JSON.stringify(newValue))) {
                newValues = [...newValues, newValue];
            }
        });
        newFilters[key] = {
            ...newFilters[key],
            values: newValues,
        };
    } else {
        newFilters[key] = value;
    }
    return newFilters;
};

export const mergeFilters = (filters1: FiltersShape, filters2: FiltersShape): FiltersShape => {
    let newFilters = { ...filters1 };
    Object.keys(filters2).forEach(key => {
        newFilters = mergeAttributePairToFilters(newFilters, key, filters2[key]);
    });
    return newFilters;
};
