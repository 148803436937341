import Icon from "src/components/Icon";
import AddClassModal from "../components/ClassTracking/AddClassModal";
import DotsProgress from "src/components/DotsProgress";
import TrackingFilters from "../components/Common/TrackingFilters";
import PaperInner from "src/components/PaperInner";
import TrackingHeader from "../components/Common/TrackingHeader";
import ClassTrackerTable from "../components/ClassTracking/ClassTrackerTable";
import InfiniteScroll from "react-infinite-scroll-component";
import MigrateClassModal from "../components/ClassTracking/MigrateClassModal";
import AppContainer from "src/components/AppContainer";
import { Box, Button, Divider, MenuItem, Paper, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mdiContentDuplicate } from "@mdi/js";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Dispatch } from "redux";
import { ApiData, ApiStatus } from "src/api/constants";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { filterNotNullable } from "src/services/object";
import { AppState } from "src/store/reducers";
import { ClassTrackerListFilters } from "../api/Classes/getClassTrackers";
import { ClassActions } from "../store/actions";
import { useLocation, useNavigate } from "react-router";
import { ClassTrackerFilterResponse } from "../api/Classes/filters";
import { OrmClassTrackerGroup } from "src/orm/models/ClassTrackerGroup";
import { classTrackerGroupSelector } from "src/modules/class/selectors/ClassTrackerGroupSelectors";
import { useResponse } from "src/hooks/useResponse";
import { FormError, getErrorMessage } from "src/services/error";
import { HTTP_NO_CONTENT } from "src/config/globals";
import { StudentReportBySubjectRequest } from "src/modules/report/api/StudentReport/bySubject";
import { TrackerStatus } from "src/orm/models/ClassTracker";
import { ROUTE_CLASS_CLASS_LIST_COHORT, ROUTE_CLASS_MIGRATE } from "src/routes";
import { ReportActions } from "src/modules/report/store/actions";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { useClassTrackerGroupUnsyncMutation } from "../hooks/ClassTrackerGroup/useClassTrackerGroupUnsync";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { AnalysisTypes } from "src/modules/analysis/components/Common/AnalysisTypeModal";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const AJAX_LOAD_INTERVAL_MS = 20000;

const useStyles = makeStyles((theme: Theme) => ({
    dividerVertical: {
        height: 42,
        margin: theme.spacing(0, 5),
    },
}));

const dispatchActions = (dispatch: Dispatch) => ({
    getClassTrackerFilters: (cohort: number) => {
        dispatch(ClassActions.getClassTrackerFilters(cohort));
    },
    getClassTrackerGroupList: (cohort: number, filters?: ClassTrackerListFilters) => {
        dispatch(ClassActions.getClassTrackerGroupList(cohort, filters));
    },
    clearClassTracker: () => {
        dispatch(TrackerActions.clearClassTracker());
    },
    clearClassTrackerList: () => {
        dispatch(TrackerActions.clearClassTrackerList());
    },
    removeClass: (classTracker: number) => {
        dispatch(ClassActions.removeClass(classTracker));
    },
    unsyncClassTracker: (classTracker: number) => {
        dispatch(ClassActions.unsyncClassTracker(classTracker));
    },
    setPagerReset: () => {
        dispatch(ClassActions.setPagerReset());
    },
    setLastFilteringOptions: (cohort: number, filters?: ClassTrackerListFilters) => {
        dispatch(ClassActions.setLastFilteringOptions(cohort, filters));
    },
    getLastUpdatedClassTrackers: (cohort: number) => {
        dispatch(ClassActions.getLastUpdatedClassTrackers(cohort));
    },
    getStudentReportBySubject: (
        cohort: number,
        yearGroup: number,
        values: StudentReportBySubjectRequest,
    ) => {
        dispatch(ReportActions.getStudentReportBySubject(cohort, yearGroup, values));
    },
});

export const CLASSES_PER_PAGE = 20;

const ClassTrackingListContainer: FC = () => {
    const dispatch = useDispatch();
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();
    const { cohort: cohortParam } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const location = useLocation();

    const { data } = useCoreValues(); // TODO

    const cohorts = data?.cohorts || [];

    const currCohort = filterNotNullable(cohorts).find(c => c.id === parseInt(cohortParam || ""));
    const prevCohort = filterNotNullable(cohorts).find(
        c => c.id === parseInt(cohortParam || "") - 1,
    );

    const { data: profile } = useProfile();
    const routerHistory = useNavigate();
    const { data: accountInfo } = useAccountInfo();

    const defaultCohortId = accountInfo?.activeCohortId;

    const {
        subjectArea: subjectParam,
        qualification: qualParam,
        yearGroup: ygParam,
        addClass,
    } = Object.fromEntries(new URLSearchParams(location.search));

    const initialFilter = {
        yearGroup: ygParam || "",
        subjectArea: subjectParam || "",
        qualification: qualParam || "",
        page: 1,
        perPage: CLASSES_PER_PAGE,
    };

    const [addClassOpen, setAddClassOpen] = useState<boolean>(false);
    const [migrateClassOpen, setMigrateClassOpen] = useState<boolean>(false);
    const [selectedFilters, setSelectedFilters] = useState(initialFilter);

    const {
        getClassTrackerGroupList,
        getClassTrackerFilters,
        clearClassTracker,
        clearClassTrackerList,
        getLastUpdatedClassTrackers,
        unsyncClassTracker,
        removeClass,
        setPagerReset,
        setLastFilteringOptions,
        getStudentReportBySubject,
    } = dispatchActions(dispatch);

    const {
        classTrackerGroups,
        classTrackerFilters,
        apiRemoveClass,
        apiReport,
        apiSubjectReport,
        apiUnsync,
        hasNextPage,
    }: {
        classTrackerGroups: OrmClassTrackerGroup[];
        classTrackerFilters: ClassTrackerFilterResponse | null;
        apiRemoveClass: ApiData;
        apiUnsync: ApiData;
        apiReport: ApiData;
        apiSubjectReport: ApiData;
        hasNextPage: boolean;
    } = useSelector(
        (state: AppState) => ({
            classTrackerGroups: classTrackerGroupSelector(state, ct => {
                return (
                    ct.cohortId === currCohort?.id &&
                    (selectedFilters.qualification && ct.qualification
                        ? ct.qualification.id === parseInt(selectedFilters.qualification)
                        : true) &&
                    (selectedFilters.subjectArea && ct.subjectArea
                        ? ct.subjectArea.id === parseInt(selectedFilters.subjectArea)
                        : true) &&
                    (selectedFilters.yearGroup !== "null" &&
                    selectedFilters.yearGroup !== undefined &&
                    selectedFilters.yearGroup !== "" &&
                    ct.yearGroup !== null &&
                    ct.yearGroup !== undefined
                        ? ct.yearGroup === parseInt(selectedFilters.yearGroup)
                        : true)
                );
            }),
            classTrackerFilters: state.class.classTrackerFilters,
            apiRemoveClass: state.api.class.removeClass,
            apiReport: state.api.report.getStudentReportByYearGroup,
            apiSubjectReport: state.api.report.getStudentReportBySubject,
            apiUnsync: state.api.class.unsyncClassTracker,
            hasNextPage: state.class.classListHasNextPage,
        }),
        shallowEqual,
    );

    const resetStates = () => {
        clearClassTracker();
        setSelectedFilters(initialFilter);
    };

    const resolveSearchQuery = (type, value) => {
        const filresCopy = { ...initialFilter };
        filresCopy[type] = value;
        let { subjectArea, qualification, yearGroup } = filresCopy;

        const selectedSubjectArea = classTrackerFilters.find(({ id }) => `${id}` == subjectArea);
        //selected subjectArea value - not All
        if (type === "subjectArea" && selectedSubjectArea) {
            subjectArea = value;

            const selectedQualification = selectedSubjectArea.qualifications.find(
                ({ id }) => `${id}` == qualification,
            );

            if (selectedQualification) {
                //qualification is set
                if (!selectedQualification.yearGroups.find(id => `${id}` == yearGroup)) {
                    yearGroup = "";
                }
            } else {
                //qualification is All
                qualification = "";
                if (
                    !selectedSubjectArea.qualifications
                        .flatMap(({ yearGroups }) => yearGroups)
                        .find(id => `${id}` == yearGroup)
                ) {
                    yearGroup = "";
                }
            }
        }
        if (type === "qualification" && qualification) {
            if (selectedSubjectArea) {
                //subjectArea is set
                if (
                    !selectedSubjectArea.qualifications
                        .find(({ id }) => `${id}` == qualification)
                        .yearGroups.find(id => `${id}` == yearGroup)
                ) {
                    yearGroup = "";
                }
            } else {
                if (
                    !classTrackerFilters
                        .flatMap(({ qualifications }) => qualifications)
                        .find(({ id }) => `${id}` == qualification)
                        .yearGroups.find(id => `${id}` == yearGroup)
                ) {
                    yearGroup = "";
                }
            }
        }
        return { subjectArea, qualification, yearGroup };
    };

    const perpareParamsForRequest = useCallback(() => {
        const params = {
            page: selectedFilters.page,
            perPage: selectedFilters.perPage,
            subjectAreaId: parseInt(selectedFilters.subjectArea) || undefined,
            qualificationId: parseInt(selectedFilters.qualification) || undefined,
            yearGroup:
                selectedFilters.yearGroup !== "" ? parseInt(selectedFilters.yearGroup) : undefined,
        };

        return params;
    }, [selectedFilters]);

    useEffect(() => {
        if (currCohort) {
            setLastFilteringOptions(currCohort?.id || 0, perpareParamsForRequest());
            getClassTrackerGroupList(currCohort.id, { ...perpareParamsForRequest() });
        }
        // eslint-disable-next-line
    }, [selectedFilters]);

    const handleFilterChange = (type: string, value: string) => {
        setPagerReset();
        const { subjectArea, qualification, yearGroup } = resolveSearchQuery(type, value);
        setSelectedFilters({ ...selectedFilters, subjectArea, qualification, yearGroup, page: 1 });
        if (!subjectArea && !qualification && !yearGroup) clearClassTrackerList();
        navigate(
            location.pathname +
                `?subjectArea=${subjectArea}&qualification=${qualification}&yearGroup=${yearGroup}`,
        );
        if (currCohort) {
            setLastFilteringOptions(currCohort?.id || 0, perpareParamsForRequest());
            getClassTrackerGroupList(currCohort.id, { ...perpareParamsForRequest() });
        }
    };

    const handleCohortChange = (cohortId: number) => {
        setPagerReset();
        resetStates();
        navigate(ROUTE_CLASS_CLASS_LIST_COHORT.replace(":cohort", `${cohortId}`));
        getClassTrackerFilters(cohortId);
    };

    const shouldHideYearGroupButton = ctg => {
        if (profile?.availableToAllSubjectAreas === true) {
            return false;
        } else {
            const ctgSubjectAreaId = ctg.subjectArea.id;
            if (profile?.availableSubjectAreas) {
                let shouldHide = true;
                profile.availableSubjectAreas.forEach(asa => {
                    if (asa.id === ctgSubjectAreaId) {
                        shouldHide = false;
                    }
                });
                return shouldHide;
            }
        }

        return true;
    };

    const handleRemoveUnsyncResponse = useCallback(
        (data, successMessage) => {
            switch (data.status) {
                case ApiStatus.SUCCESS: {
                    enqueueSnackbar(successMessage, {
                        variant: "success",
                    });
                    navigate(0);
                    break;
                }
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
        },
        [enqueueSnackbar, routerHistory],
    );

    const { mutate: unsyncGroup } = useClassTrackerGroupUnsyncMutation(() => {
        // setTimeout(() => {
        resetStates();
        clearClassTrackerList();
        // }, 1000);
        enqueueSnackbar(t("class.list.classesUnsynced"), { variant: "success" });
    });

    useEffect(() => {
        if (currCohort) {
            getClassTrackerFilters(currCohort.id);
        }
        //eslint-disable-next-line
    }, [cohorts]);

    useResponse(
        () => handleRemoveUnsyncResponse(apiRemoveClass, t("class.addClass.removeSuccess")),
        apiRemoveClass,
    );

    useResponse(
        () => handleRemoveUnsyncResponse(apiUnsync, t("class.addClass.unsyncSuccess")),
        apiUnsync,
    );

    useEffect(() => {
        clearClassTrackerList();
        resetStates();
        if (currCohort && prevCohort && currCohort.id && prevCohort.id !== currCohort.id) {
            getClassTrackerGroupList(currCohort.id, { ...perpareParamsForRequest() });
        }
    }, [currCohort?.id]);

    useEffect(() => {
        if (addClass) {
            setAddClassOpen(true);
        }
    }, [addClass]);

    // reload data interval
    useEffect(() => {
        const interval = setInterval(() => {
            if (currCohort) {
                getLastUpdatedClassTrackers(currCohort.id);
            }
        }, AJAX_LOAD_INTERVAL_MS);

        return () => {
            clearInterval(interval);
        };
    }, [currCohort]);

    useResponse(() => {
        if (
            apiReport.status === ApiStatus.SUCCESS &&
            apiReport.responseStatus === HTTP_NO_CONTENT
        ) {
            enqueueSnackbar(t("report.studentReport.byStudent.requestSuccess"), {
                variant: "success",
            });
        }
        if (apiReport.status === ApiStatus.ERROR) {
            enqueueSnackbar(
                apiReport.error?.response.error || t("report.studentReport.byStudent.requestError"),
                {
                    ...SnackbarErrorOptions,
                },
            );
        }
    }, apiReport);

    useResponse(() => {
        if (
            apiSubjectReport.status === ApiStatus.SUCCESS &&
            apiSubjectReport.responseStatus === HTTP_NO_CONTENT
        ) {
            enqueueSnackbar(t("report.studentReport.byStudent.requestSuccess"), {
                variant: "success",
            });
        }
        if (apiSubjectReport.status === ApiStatus.ERROR) {
            enqueueSnackbar(
                apiSubjectReport.error?.response?.error ||
                    t("report.studentReport.byStudent.requestError"),
                {
                    ...SnackbarErrorOptions,
                },
            );
        }
    }, apiSubjectReport);

    useEffect(() => {
        if (defaultCohortId && !cohortParam) {
            handleCohortChange(defaultCohortId);
            navigate(
                ROUTE_CLASS_CLASS_LIST_COHORT.replace(":cohort", `${defaultCohortId}`) +
                    location.search || "",
            );
        }
        //eslint-disable-next-line
    }, [defaultCohortId, cohorts]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.header")}
            </Typography>
            <Box>
                <Box mb={4}>
                    {accountInfo?.canManageClassesAndTracking && (
                        <Button
                            className="addClassButton"
                            onClick={() => setAddClassOpen(true)}
                            color="primary"
                        >
                            {t("class.addClassButton")}
                        </Button>
                    )}
                    {currCohort &&
                    currCohort.id === accountInfo?.activeCohortId &&
                    accountInfo.allowClassMigrations ? (
                        <Button
                            className="migrateButton"
                            onClick={() => {
                                if (accountInfo?.hasGroupCallSynchronization) {
                                    setMigrateClassOpen(true);
                                } else {
                                    navigate(
                                        ROUTE_CLASS_MIGRATE.replace(":cohort", `${currCohort.id}`),
                                    );
                                }
                            }}
                            variant="text"
                            startIcon={<Icon path={mdiContentDuplicate} />}
                            disableRipple
                        >
                            {t("class.migrateClassesButton")}
                        </Button>
                    ) : null}
                </Box>
                {addClassOpen && (
                    <AddClassModal
                        open={addClassOpen}
                        handleClose={confirm => {
                            if (addClass && !confirm) {
                                navigate(location.pathname);
                            }
                            setAddClassOpen(false);
                        }}
                    />
                )}
                {migrateClassOpen && (
                    <MigrateClassModal
                        cohort={cohortParam}
                        open={migrateClassOpen}
                        handleClose={() => setMigrateClassOpen(false)}
                    />
                )}
                <Box mb={4} display="flex">
                    <Box width={120}>
                        <TextField
                            className="cohortSelect"
                            label={t("class.list.cohortFilterLabel")}
                            select
                            SelectProps={{ displayEmpty: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="none"
                            name="cohort"
                            fullWidth
                            value={currCohort?.id || 0}
                            onChange={e => handleCohortChange(parseInt(e.target.value))}
                        >
                            {filterNotNullable(cohorts).map(obj => (
                                <MenuItem key={obj.id} value={obj.id}>
                                    {obj.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    {classTrackerFilters && classTrackerFilters.length > 0 ? (
                        <>
                            <Divider
                                orientation="vertical"
                                flexItem
                                className={classes.dividerVertical}
                            />
                            <TrackingFilters
                                selectedFilters={selectedFilters}
                                filters={classTrackerFilters}
                                handleFilterChange={handleFilterChange}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Box>
                <InfiniteScroll
                    dataLength={classTrackerGroups.length}
                    next={() => {
                        setSelectedFilters({ ...selectedFilters, page: selectedFilters.page + 1 });
                    }}
                    hasMore={hasNextPage}
                    loader={<DotsProgress />}
                >
                    {classTrackerGroups &&
                        classTrackerGroups.map(ctg => (
                            <Box mb={2} key={ctg.id}>
                                <Paper>
                                    <PaperInner
                                        variant="subjectArea"
                                        color="lightGrey"
                                        border="bottom"
                                    >
                                        <Box display="flex">
                                            <TrackingHeader
                                                bulkEditTrackerId={
                                                    ctg.classTrackers?.length > 0 && !ctg.archived
                                                        ? ctg.classTrackers[0].id
                                                        : null
                                                }
                                                cohortId={ctg.cohortId}
                                                classTrackerGroupId={ctg.id}
                                                subjectArea={ctg.subjectArea}
                                                specification={ctg.specification}
                                                qualification={ctg.qualification}
                                                yearGroup={ctg.yearGroup}
                                                handleUnsyncGroup={groupId => {
                                                    unsyncGroup({ id: groupId });
                                                }}
                                                hasGroupCallClassTrackers={
                                                    ctg.hasGroupCallClassTrackers
                                                }
                                                isDisabled={
                                                    ctg.classTrackers.length > 0 &&
                                                    ctg.classTrackers.filter(
                                                        c =>
                                                            c.isDisabled === true ||
                                                            (c.status !== TrackerStatus.LIVE &&
                                                                c.status !==
                                                                    TrackerStatus.ARCHIVED),
                                                    ).length > 0
                                                }
                                                hideYearGroupBtn={shouldHideYearGroupButton(ctg)}
                                                showSubjectAnalysis={profile?.accessToAnalysisTypes?.includes(
                                                    AnalysisTypes.SUBJECT_ANALYSIS,
                                                )}
                                                handleDownloadReport={getStudentReportBySubject}
                                                tiers={ctg.tiers}
                                                archived={ctg.archived}
                                            />
                                        </Box>
                                    </PaperInner>
                                    <ClassTrackerTable
                                        yearGroup={ctg.yearGroup}
                                        specification={ctg.specification}
                                        qualification={ctg.qualification}
                                        subjectArea={ctg.subjectArea}
                                        isCurrentDefaultCohort={
                                            !!(
                                                defaultCohortId &&
                                                currCohort?.id &&
                                                currCohort?.id >= defaultCohortId
                                            )
                                        }
                                        cohort={currCohort?.id || 0}
                                        classTrackers={ctg.classTrackers}
                                        handleRemoveClass={removeClass}
                                        handleUnsyncClass={unsyncClassTracker}
                                        handleDownloadReport={getStudentReportBySubject}
                                        defaultTier={ctg.defaultTier}
                                        hasTiers={ctg.tiers && ctg.tiers.length > 0}
                                        archived={ctg.archived}
                                    />
                                </Paper>
                            </Box>
                        ))}
                </InfiniteScroll>
            </Box>
        </AppContainer>
    );
};

export default ClassTrackingListContainer;
