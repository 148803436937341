import { FormHelperText, Box, BoxProps } from "@mui/material";
import { ErrorMessage as ErrorMessageFormik, ErrorMessageProps } from "formik";
import { FC, ReactElement } from "react";

interface OwnProps {
    name: string;
}

const ErrorMessage: FC<OwnProps & ErrorMessageProps & BoxProps> = ({ ...props }): ReactElement => {
    return (
        <Box {...props}>
            <ErrorMessageFormik
                render={msg => <FormHelperText error>{msg}</FormHelperText>}
                {...props}
            />
        </Box>
    );
};

export default ErrorMessage;
