import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TrustUserModel } from "../models/TrustUserModel";
import { PagedList, Pagination } from "src/types";

export const TRUST_USERS_QUERY = "trustUsersQuery";

export interface TrustUsersFilters {
    name?: string;
    schoolAccountId?: number;
}

export const useTrustUsers = (
    trustId: number,
    pagination: Pagination,
    filters?: TrustUsersFilters,
) => {
    return useQuery({
        queryKey: [TRUST_USERS_QUERY, trustId, pagination, filters],
        queryFn: async (): Promise<PagedList<TrustUserModel> | null> => {
            if (trustId) {
                const { data } = await axiosInstance.get(`trust/${trustId}/user/`, {
                    params: filters,
                });

                return data || null;
            }
            return null;
        },
    });
};
