/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, MenuItem, TextField, ListSubheader, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ApiData, ApiStatus } from "src/api/constants";
import { usePrevious } from "src/hooks/usePrevious";
import { qualificationWithFilterSelector } from "src/modules/common/selectors/QualificationSelectors";
import { specificationWithFilterSelector } from "src/modules/common/selectors/SpecificationSelectors";
import { subjectAreaWithFilterSelector } from "src/modules/common/selectors/SubjectAreaSelectors";
import { CommonActions } from "src/modules/common/store/actions";
import { OrmQualification } from "src/orm/models/Qualification";
import { OrmSpecification } from "src/orm/models/Specification";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { filterNotNullable } from "src/services/object";
import { AppState } from "src/store/reducers";
import { FieldProps } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { useMount } from "src/hooks/useMount";
import { TrackerPathItem, getTrackerPathLeafeId } from "src/modules/class/hooks/useTrackerPath";
import { useQualificationsList } from "src/modules/tagging/components/subjectRows/hooks/useQualificationsList";

const useStyles = makeStyles((theme: Theme) => ({
    submenu: {
        paddingLeft: theme.spacing(4),
    },
}));

const dispatchActions = (dispatch: Dispatch) => ({
    getSubjectAreas: () => {
        dispatch(CommonActions.getSubjectAreaList());
    },
    getAllowedOnlySubjectAreas: (hasClasses?: boolean) => {
        dispatch(CommonActions.getAllowedSubjectAreaList(hasClasses));
    },
    getSpecifications: (qualificationId: number, hasClasses?: boolean) => {
        dispatch(CommonActions.getSpecificationList(qualificationId, hasClasses));
    },
    getAllowedQualifications: (subjectAreaId: number, hasClasses?: boolean) => {
        dispatch(CommonActions.getAllowedQualificationList(subjectAreaId, hasClasses));
    },
});

interface OwnProps {
    name: string;
    liveOnly?: boolean;
    allowedOnly?: boolean;
    showParentSpecification?: boolean;
    trackerPath?: TrackerPathItem[];
    specificationParam?: number;
    allowedQualificationsOnly?: boolean;
    showWithClassesOnly?: boolean;
    handleQualificationChange?: (qualificationId: number | null) => void;
    handleSubjectAreaChange?: (subjectAreaId: number | null) => void;
    handleSpecificationChange?: (
        specification?: OrmSpecification & { subjectArea?: number },
    ) => void;
    handleNextSpecification?: (specification?: OrmSpecification | null) => void;
    locked?: boolean;
}

const SpecificationSelectField = (props: FieldProps & OwnProps) => {
    const classes = useStyles();
    const fieldName = props.field.name;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedSubjectArea, setSelectedSubjectArea] = useState<number | null>(null);
    const [selectedQualification, setSelectedQualification] = useState<number | null>(null);
    const [selectedSpecification, setSelectedSpecification] = useState<number | null>(null);
    const [handledSpecification, setHandledSpecification] = useState<OrmSpecification | null>(null);
    const [nextSpecification, setNextSpecification] = useState<OrmSpecification | null>(null);

    useEffect(() => {
        if (!props.specificationParam && props.trackerPath) {
            setSelectedSubjectArea(getTrackerPathLeafeId(props.trackerPath, "SubjectArea"));
            setSelectedQualification(getTrackerPathLeafeId(props.trackerPath, "Qualification"));
            setSelectedSpecification(getTrackerPathLeafeId(props.trackerPath, "Specification"));
        }
    }, [props.trackerPath]);

    useEffect(() => {
        if (props.handleNextSpecification) {
            props.handleNextSpecification(nextSpecification);
        }
    }, [nextSpecification]);

    const {
        getSubjectAreas,
        getSpecifications,
        getAllowedOnlySubjectAreas,
        getAllowedQualifications,
    } = dispatchActions(dispatch);

    const { data: qualifications } = useQualificationsList(selectedSubjectArea);

    const prevSubjectArea = usePrevious(selectedSubjectArea);
    const prevQualification = usePrevious(selectedQualification);
    const allowedSubjectAreas: number[] = useSelector(
        (state: AppState) => state.common.allowedSubjectAreas,
    );

    const {
        apiSubjectAreas,
        apiAllowedSubjectAreas,
        allowedSubjectAreasList,
        subjectAreas,
        specifications,
        allowedQualifications,
    }: {
        apiSubjectAreas: ApiData;
        apiAllowedSubjectAreas: ApiData;
        allowedSubjectAreasList: OrmSubjectArea[];
        subjectAreas: OrmSubjectArea[];
        qualifications: OrmQualification[];
        specifications: OrmSpecification[];
        allowedQualifications: { [key: string]: OrmQualification[] } | null;
    } = useSelector(
        (state: AppState) => ({
            apiSubjectAreas: state.api.common.getSubjectAreaList,
            apiAllowedSubjectAreas: state.api.common.getAllowedSubjectAreaList,
            allowedSubjectAreasList: subjectAreaWithFilterSelector(state, sa => {
                if (allowedSubjectAreas && sa.id) {
                    return allowedSubjectAreas.includes(sa.id);
                }
                return true;
            }),
            subjectAreas: subjectAreaWithFilterSelector(state, sa => {
                // if (props.allowedOnly === true && allowedSubjectAreas && sa.id) {
                //     return allowedSubjectAreas.includes(sa.id);
                // }
                return true;
            }),
            qualifications: qualificationWithFilterSelector(state, q => {
                return selectedSubjectArea !== null && q.subjectArea === selectedSubjectArea;
            }),
            specifications: specificationWithFilterSelector(state, s => {
                return selectedQualification !== null && s.qualification === selectedQualification;
            }),
            allowedQualifications: state.common.allowedQualifications,
        }),
        shallowEqual,
    );

    const handleDropdownChange = (e, type) => {
        if (type === "subjectArea") {
            setSelectedSubjectArea(e.target.value);
            setSelectedQualification(null);
            setSelectedSpecification(null);
            if (props.handleSubjectAreaChange) {
                props.handleSubjectAreaChange(parseInt(e.target.value));
            }
            props.form.setFieldValue(fieldName, null);
        }
        if (type === "qualification") {
            setSelectedQualification(e.target.value);
            setSelectedSpecification(null);
            props.form.setFieldValue(fieldName, null);
        }
        if (type === "specification") {
            setSelectedSpecification(e.target.value);
            props.form.setFieldValue(fieldName, e.target.value);
        }
    };

    const prevApiSubjectAreasStatus = usePrevious(apiSubjectAreas.status);
    const prevApiAllowedSubjectAreasStatus = usePrevious(apiAllowedSubjectAreas.status);

    useEffect(() => {
        if (
            subjectAreas.length === 0 ||
            (subjectAreas.length > 0 &&
                subjectAreas.find(
                    sa => sa.numberOfLiveTrackers !== undefined && sa.numberOfLiveTrackers > 0,
                ) === undefined)
        ) {
            if (
                !prevApiSubjectAreasStatus &&
                (apiSubjectAreas.status === ApiStatus.NONE ||
                    apiSubjectAreas.status === ApiStatus.SUCCESS)
            ) {
                getSubjectAreas();
            }

            if (
                props.allowedOnly === true &&
                !prevApiAllowedSubjectAreasStatus &&
                (apiAllowedSubjectAreas.status === ApiStatus.NONE ||
                    apiAllowedSubjectAreas.status === ApiStatus.SUCCESS)
            ) {
                getAllowedOnlySubjectAreas(props.showWithClassesOnly || undefined);
            }
        }

        if (selectedSubjectArea && prevSubjectArea !== selectedSubjectArea) {
            if (props.allowedQualificationsOnly) {
                getAllowedQualifications(
                    selectedSubjectArea,
                    props.showWithClassesOnly || undefined,
                );
            }
        }
        if (
            (selectedQualification && prevQualification !== selectedQualification) ||
            (selectedQualification && !specifications)
        ) {
            getSpecifications(selectedQualification, props.showWithClassesOnly || undefined);
        }
    }, [
        apiAllowedSubjectAreas,
        apiSubjectAreas,
        prevApiAllowedSubjectAreasStatus,
        prevApiSubjectAreasStatus,
        prevQualification,
        prevSubjectArea,
        props.allowedOnly,
        props.showWithClassesOnly,
        selectedQualification,
        selectedSubjectArea,
        subjectAreas,
        props.allowedQualificationsOnly,
    ]);

    const availableSubjectAreas = (props.allowedOnly ? allowedSubjectAreasList : subjectAreas).map(
        option => {
            if (option.rgt - option.lft > 1) option.disabled = true;
            return option;
        },
    );

    const prevTrackerPath = usePrevious(props.trackerPath) as TrackerPathItem[] | undefined;

    useMount(() => {
        if (props.specificationParam) {
            setSelectedSpecification(props.specificationParam);
        }
    });

    useEffect(() => {
        let subjectArea: number | null = null;
        let qualification: number | null = null;

        if (props.trackerPath && prevTrackerPath) {
            const prevSpec = getTrackerPathLeafeId(prevTrackerPath, "Specification");
            const currSpec = getTrackerPathLeafeId(props.trackerPath, "Specification");

            if (prevSpec && currSpec && prevSpec !== currSpec) {
                subjectArea = getTrackerPathLeafeId(props.trackerPath, "SubjectArea");
                qualification = getTrackerPathLeafeId(props.trackerPath, "Qualification");
            }

            if (subjectArea && qualification && currSpec) {
                setSelectedSubjectArea(subjectArea);
                setSelectedQualification(qualification);
                setSelectedSpecification(currSpec);
            }
        }
        if (props.specificationParam && props.trackerPath) {
            subjectArea = getTrackerPathLeafeId(props.trackerPath, "SubjectArea");
            qualification = getTrackerPathLeafeId(props.trackerPath, "Qualification");
            setSelectedSubjectArea(subjectArea);
            setSelectedQualification(qualification);
            setSelectedSpecification(props.specificationParam);
        }
    }, [props.trackerPath, props.specificationParam]);

    const prevHandledSpecification: OrmSpecification | null = usePrevious(handledSpecification);

    useEffect(() => {
        if (props.handleSpecificationChange && selectedSpecification && specifications.length > 0) {
            const specification = specifications.find(s => s.id === selectedSpecification);
            const specificationIndex = specifications.findIndex(
                s => s.id === selectedSpecification,
            );

            if (specification && prevHandledSpecification?.id !== specification?.id) {
                setHandledSpecification(specification);
                if (props.handleNextSpecification) {
                    setNextSpecification(specifications?.[specificationIndex + 1] || null);
                }
            }
        }
    }, [selectedSpecification, specifications]);

    useEffect(() => {
        if (handledSpecification && props.handleSpecificationChange) {
            props.handleSpecificationChange({
                ...handledSpecification,
                subjectArea: selectedSubjectArea,
            });
        }
    }, [handledSpecification]);

    useEffect(() => {
        if (selectedQualification && props.handleQualificationChange) {
            props.handleQualificationChange(selectedQualification);
        }
    }, [selectedQualification]);

    return (
        <Grid container spacing={4}>
            <Grid item sm={4}>
                <TextField
                    name="subjectAreaSelect"
                    label={t("class.addClass.subjectArea")}
                    select
                    disabled={props.locked || subjectAreas.length === 0}
                    value={selectedSubjectArea || ""}
                    onChange={e => handleDropdownChange(e, "subjectArea")}
                >
                    {filterNotNullable(availableSubjectAreas)
                        .filter(
                            sa =>
                                (props.liveOnly &&
                                    sa.numberOfLiveTrackers &&
                                    sa.numberOfLiveTrackers > 0) ||
                                !props.liveOnly,
                        )
                        .map(obj => {
                            if (obj.disabled) {
                                return (
                                    <ListSubheader key={obj.id}>{obj.name || obj.id}</ListSubheader>
                                );
                            }
                            return (
                                <MenuItem
                                    disabled={obj.disabled}
                                    className={obj.lvl && obj.lvl > 0 ? classes.submenu : undefined}
                                    key={obj.id}
                                    value={obj.id}
                                >
                                    {obj.name || obj.id}
                                </MenuItem>
                            );
                        })}
                </TextField>
            </Grid>
            <Grid item sm={4}>
                <TextField
                    name="qualificationSelect"
                    label={t("class.addClass.qualification")}
                    select
                    disabled={
                        props.locked ||
                        (props.allowedQualificationsOnly &&
                        allowedQualifications &&
                        allowedQualifications[selectedSubjectArea]
                            ? allowedQualifications[selectedSubjectArea].length === 0
                            : qualifications?.length === 0)
                    }
                    value={selectedQualification || ""}
                    onChange={e => handleDropdownChange(e, "qualification")}
                >
                    {filterNotNullable(
                        props.allowedQualificationsOnly &&
                            allowedQualifications &&
                            allowedQualifications[selectedSubjectArea]
                            ? allowedQualifications[selectedSubjectArea]
                            : qualifications,
                    )
                        .filter(
                            q =>
                                (props.liveOnly &&
                                    q.numberOfLiveTrackers &&
                                    q.numberOfLiveTrackers > 0) ||
                                !props.liveOnly,
                        )
                        .map(obj => (
                            <MenuItem key={obj.id} value={obj.id}>
                                {obj.name || obj.id}
                            </MenuItem>
                        ))}
                </TextField>
            </Grid>
            <Grid item sm={4}>
                <TextField
                    name="specificationSelect"
                    label={t("class.addClass.specification")}
                    select
                    disabled={props.locked || specifications.length === 0}
                    value={selectedSpecification || ""}
                    onChange={e => handleDropdownChange(e, "specification")}
                >
                    {filterNotNullable(specifications)
                        .filter(
                            s =>
                                !s.discontinued &&
                                ((props.liveOnly && s.isTrackerLive) || !props.liveOnly),
                        )
                        .map(obj => (
                            <MenuItem key={obj.id} value={obj.id}>
                                {obj.name
                                    ? props.showParentSpecification && obj.parentName
                                        ? `${obj.parentName} - ${obj.name}`
                                        : obj.name
                                    : obj.id}
                            </MenuItem>
                        ))}
                </TextField>
            </Grid>
        </Grid>
    );
};

export default SpecificationSelectField;
