/* eslint-disable react/display-name */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import { NAV_KEYS } from "src/modules/tracker/services/gridNavigation";
import { usePrevious } from "src/hooks/usePrevious";
import { getAllUniqueCharacters } from "src/services/utilsGPT";

interface OwnProps {
    value: number | string | boolean | { value: string | number | boolean };
    api: GridApi;
    options: { value: number; option: string; colour?: string }[];
    allowAbsent: boolean;
    context: any;
}

const TextConversionEditor = forwardRef(
    ({ value: propsValue, options, allowAbsent, api: gridApi, context }: OwnProps, ref) => {
        const [value, setValue] = useState(
            typeof propsValue === "object" && propsValue !== null
                ? propsValue.value
                : propsValue || "",
        );

        // let availableValues = options.map(o => o.option.toLowerCase());
        // availableValues = availableValues
        //     .concat(availableValues.map(v => `${v}`.toUpperCase()))
        //     .concat("");

        let availableValues = getAllUniqueCharacters(options.map(o => o.option)).concat("");
        // let availableValues = generateWordCombinations(prevAvailableValues, 4).concat("");

        if (allowAbsent) {
            availableValues = availableValues.concat(["a", "A", "x", "X", "u", "U"]);
        }

        const refInput = useRef(null) as any;
        const { isBlocked, isGradeVBoundaryProcessing } = context;
        const prevValue = usePrevious(value);

        useEffect(() => {
            if (gridApi && (isBlocked || isGradeVBoundaryProcessing)) {
                gridApi.stopEditing(true);
            }
        }, [isBlocked, isGradeVBoundaryProcessing, gridApi]);

        useEffect(() => {
            refInput.current.focus();
            refInput.current.select();
            //eslint-disable-next-line
        }, []);

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    if (prevValue !== value) {
                        return { value: `${value}`.toUpperCase(), whileUpdate: true };
                    }
                    return value;
                },
            };
        });

        const handleOnChange = (e: any) => {
            const newValue = e.target.value;

            let stringValue = "";
            newValue.split("").forEach(nv => {
                if (availableValues.includes(nv)) {
                    stringValue = stringValue + nv;
                }
            });

            setValue(stringValue);
            // const partial: string[] = [];
            // availableValues.forEach(av => {
            //     const splited = av.split("");
            //     splited.forEach((s, i) => {
            //         if (
            //             i === 0 &&
            //             !availableValues.find(av => av === s) &&
            //             !partial.find(p => p === s)
            //         ) {
            //             partial.push(s);
            //         }
            //     });
            // });

            // if (availableValues.concat(partial).includes(newValue))
        };

        return (
            <div className="ag-editor-cell">
                <input
                    className="ag-editor-cell__input"
                    onChange={handleOnChange}
                    ref={refInput}
                    onKeyDown={e => {
                        if (NAV_KEYS.includes(e.key)) {
                            e.preventDefault();
                            gridApi?.stopEditing();
                        }
                    }}
                    value={value === false && allowAbsent ? "A" : value !== null ? `${value}` : ""}
                    type="text"
                    maxLength={4}
                />
            </div>
        );
    },
);

export default TextConversionEditor;
