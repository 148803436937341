import { useAnalysisSnapshots } from "../useAnalysisSnapshots";
import { useAnalysisGradeSummaryHeader } from "./useAnalysisGradeSummaryHeader";
import { useAnalysisCommonRelatedData } from "../Common/useAnalysisCommonRelatedData";
import { ViewGrades, useAnalysisGradeSummaryValues } from "./useAnalysisGradeSummaryValues";
import { useAnalysisGradeSummarySnapshotValues } from "./useAnalysisGradeSummarySnapshotValues";
import { useAnalysisGradeSummarySnapshotCompare } from "./useAnalysisGradeSummarySnapshotCompare";
import { prepareStudentFilters } from "../../services/analysisValueHandler";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { CELL_SIZE_MIN } from "src/modules/tracker/services/headerGenerator";
import { useEffect, useState } from "react";

export interface StudentAttributePair {
    attribute: string;
    attributeDisplay: string;
    value: string;
    valueDisplay: string;
}

export const useAnalysisGradeSummaryData = (
    dataType: DataTypeTypes,
    cohort: number,
    yearGroup: number,
    viewGrade: ViewGrades,
    studentFilters: StudentAttributePair[] = [],
) => {
    const { snapshotData } = useAnalysisSnapshots(cohort);
    const { data: analysisGradeSummaryHeader, isLoading: isHeaderLoading } =
        useAnalysisGradeSummaryHeader(cohort, yearGroup);
    const { data: analysisRelated, isLoading: isRelatedLoading } = useAnalysisCommonRelatedData(
        cohort,
        yearGroup,
    );
    const preparedStudentFilters = prepareStudentFilters(studentFilters);
    const { data: analysisGradeSummaryValues, isLoading: isValuesLoading } =
        useAnalysisGradeSummaryValues(cohort, yearGroup, viewGrade, preparedStudentFilters);
    const { data: analysisGradeSummarySnapshotValues, isLoading: isSnapshotValuesLoading } =
        useAnalysisGradeSummarySnapshotValues(
            cohort,
            yearGroup,
            viewGrade,
            snapshotData?.snapshot,
            preparedStudentFilters,
        );

    const { data: analysisGradeSummaryCompareValues, isLoading: isSnapshotCompareLoading } =
        useAnalysisGradeSummarySnapshotCompare(
            cohort,
            yearGroup,
            viewGrade,
            snapshotData?.compare?.dataSource1,
            snapshotData?.compare?.dataSource2,
            preparedStudentFilters,
        );

    const isDataFetched =
        !!analysisGradeSummaryHeader &&
        ((dataType === DataTypeTypes.LIVE && !!analysisGradeSummaryValues) ||
            (dataType === DataTypeTypes.SNAPSHOT &&
                !!snapshotData?.snapshot &&
                !!analysisGradeSummarySnapshotValues) ||
            (dataType === DataTypeTypes.SNAPSHOT &&
                !!snapshotData?.compare &&
                !!analysisGradeSummaryCompareValues));

    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        if (isLoading)
            setIsLoading(
                isHeaderLoading ||
                    isRelatedLoading ||
                    (dataType === DataTypeTypes.LIVE && isValuesLoading) ||
                    (dataType === DataTypeTypes.SNAPSHOT &&
                        !!snapshotData?.compare &&
                        isSnapshotCompareLoading) ||
                    (dataType === DataTypeTypes.SNAPSHOT &&
                        !!snapshotData?.snapshot &&
                        isSnapshotValuesLoading),
            );
    }, [
        isHeaderLoading,
        isRelatedLoading,
        dataType,
        snapshotData,
        isValuesLoading,
        isSnapshotCompareLoading,
        isSnapshotValuesLoading,
        isLoading,
    ]);

    const analysisGradeSummaryBasicValues =
        dataType === DataTypeTypes.SNAPSHOT
            ? snapshotData?.compare
                ? analysisGradeSummaryCompareValues?.values1
                : analysisGradeSummarySnapshotValues
            : analysisGradeSummaryValues;

    const analysisGradeSummaryValuesToCompare = analysisGradeSummaryCompareValues?.values2 || null;

    const analysisStudents =
        analysisGradeSummaryBasicValues && Object.keys(analysisGradeSummaryBasicValues).length > 0
            ? Object.keys(
                  analysisGradeSummaryBasicValues[Object.keys(analysisGradeSummaryBasicValues)[0]],
              ).filter(key => {
                  return key.indexOf("student") === 0;
              })
            : [];

    const rowHeight =
        dataType === DataTypeTypes.LIVE ||
        (dataType === DataTypeTypes.SNAPSHOT && !!snapshotData?.snapshot)
            ? CELL_SIZE_MIN
            : 56;

    return {
        isLoading,
        isDataFetched,
        analysisGradeSummaryHeader,
        analysisGradeSummaryValuesToCompare,
        analysisGradeSummaryBasicValues,
        analysisRelated,
        analysisStudents,
        rowHeight,
        setIsLoading,
    };
};
