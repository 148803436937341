import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";
import { StaffUserModel } from "src/modules/school/models/StaffUserModel";

const apiConfirmationStaff = async (staff: number): Promise<AxiosResponse> => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/staff/${staff}/confirmation/`);
};

export const useConfirmationStaff = (onSuccess?) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (staff: number) => apiConfirmationStaff(staff),
        onSuccess: (res: AxiosResponse<StaffUserModel>) => {
            if (onSuccess) {
                onSuccess(res.data);
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(err.response.data?.error, {
                ...SnackbarErrorOptions,
            });
        },
    });
};
