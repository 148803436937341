import HeadingCounter from "src/components/HeadingCounter";
import SpecificationSelectField from "src/forms/SpecificationSelectField";
import MultipleSelectField from "src/forms/MultipleSelect";
import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { AssessmentCreateFormShape } from "./AddAssessmentForm";
import { AssessmentCreateTypes } from "../AssessmentCreateModal";
import { usePublishedAssessmentListForCopy } from "../../hooks/Published/useListForCopy";
import { useCustomisedAssessmentListForCopy } from "../../hooks/Customised/useListForCopy";
import { AssessmentForCopyModel } from "../../models/AssessmentListModel";
import { useMount } from "src/hooks/useMount";
import { useMultipleAssessmentsData } from "../../hooks/useMultipleAssessmentsData";
import { emptyCustomisedAssessmentValues } from "../../containers/AssessmentCreateContainer";
import { CustomisedAssessmentModel } from "../../models/CustomisedAssessmentModel";
import { PublishedAssessmentLiveTrackingData } from "../../hooks/Published/useLiveTrackingData";
import { TrackerPathItem, getTrackerPathLeafeId } from "src/modules/class/hooks/useTrackerPath";

export interface CopyAssessmentFormShape {
    specification: number | null;
    qualification: number | null;
    subjectArea: number | null;
    academicYear: number;
    yearGroup: number;
    assessmentType: "pp" | "eott" | null;
    assessmentNames: { id: number }[];
}

interface OwnProps {
    cohort: number;
    initialSpecification: number;
    initialQualification: number;
    initialSubjectArea: number;
    handleClose: (type: AssessmentCreateTypes) => void;
    trackerPath?: TrackerPathItem[];
}

const CopyAssessmentForm: FC<OwnProps> = ({
    cohort,
    initialSpecification,
    initialQualification,
    initialSubjectArea,
    handleClose,
    trackerPath,
}) => {
    const { t } = useTranslation();

    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const yearGroups = data?.yearGroups;

    const { values, setFieldValue } = useFormikContext<AssessmentCreateFormShape>();
    const [selectedAssessments, setSelectedAssessments] = useState<{ id: number }[]>([]);

    const [selectedSubjectArea, setSelectedSubjectArea] = useState<number | null>(
        initialSubjectArea,
    );
    const [selectedQualification, setSelectedQualification] = useState<number | null>(
        initialQualification,
    );
    const [selectedSpecification, setSelectedSpecification] = useState<number | null>(
        initialSpecification,
    );

    useEffect(() => {
        if (trackerPath) {
            setSelectedSubjectArea(getTrackerPathLeafeId(trackerPath, "SubjectArea"));
            setSelectedQualification(getTrackerPathLeafeId(trackerPath, "Qualification"));
            setSelectedSpecification(getTrackerPathLeafeId(trackerPath, "Specification"));
        }
    }, [trackerPath]);

    const assessmentQueries = useMultipleAssessmentsData(
        selectedAssessments,
        values.copyAssessmentForm.assessmentType,
    );

    const { data: ppListForCopy, refetch: refetchPPListForCopy } =
        usePublishedAssessmentListForCopy(parseInt(`${values.copyAssessmentForm.specification}`));

    const {
        data: eottListForCopy,
        refetch: refetchEottListForCopy,
        isSuccess: isEottCopySuccess,
        isFetched,
    } = useCustomisedAssessmentListForCopy(
        parseInt(`${values.copyAssessmentForm.specification}`),
        values.copyAssessmentForm.academicYear,
        values.copyAssessmentForm.yearGroup,
    );

    const getAssessmentList = () => {
        const list = [];

        values.copyAssessmentForm.assessmentType === "pp" &&
            ppListForCopy?.forEach((assessment: AssessmentForCopyModel) => {
                list.push({
                    id: assessment.id,
                    name:
                        assessment.unitName +
                        " | " +
                        assessment.name +
                        (assessment.tier && assessment.tier !== "-" ? ` | ${assessment.tier}` : ""),
                });
            });

        values.copyAssessmentForm.assessmentType === "eott" &&
            eottListForCopy?.forEach((assessment: AssessmentForCopyModel) => {
                list.push({
                    id: assessment.id,
                    name:
                        assessment.unitName +
                        " | " +
                        assessment.name +
                        (assessment.tier && assessment.tier !== "-" ? ` | ${assessment.tier}` : ""),
                });
            });

        return list;
    };

    useEffect(() => {
        const { assessmentType, specification, academicYear, yearGroup } =
            values.copyAssessmentForm;

        if (assessmentType === "pp" && specification) {
            refetchPPListForCopy();
        }
        if (assessmentType === "eott" && specification && academicYear && yearGroup !== null) {
            refetchEottListForCopy();
        }
    }, [values.copyAssessmentForm]);

    useEffect(() => {
        if (
            assessmentQueries.length > 0 &&
            assessmentQueries.filter(aq => aq.status === "success").length ===
                assessmentQueries.length
        ) {
            const customisedAssessmentList = [...values.customisedAssessments];

            if (values.copyAssessmentForm.assessmentType === "eott") {
                assessmentQueries.forEach(assessmentQuery => {
                    const customisedAssessment = assessmentQuery.data as CustomisedAssessmentModel;
                    customisedAssessmentList.push({
                        ...emptyCustomisedAssessmentValues,
                        gradeBoundaryFactor: customisedAssessment.gradeBoundaryFactor,
                        examDate: customisedAssessment.examDate,
                        dataEntryType:
                            customisedAssessment.personalisedAssesmentQuestions.length > 1
                                ? "qla"
                                : "single",
                        tierUnits: customisedAssessment.tierUnits.map(tu => ({
                            tier: tu.tier,
                            unit: null,
                        })),
                        personalisedAssesmentQuestions:
                            customisedAssessment.personalisedAssesmentQuestions.map(paq => ({
                                ...paq,
                                topics:
                                    values.copyAssessmentForm.specification !== values.specification
                                        ? []
                                        : paq.topics.map(t => t.id),
                            })),
                        gradeBoundaryValues: customisedAssessment.gradeBoundaryValues,
                        gradeBoundaryType:
                            customisedAssessment.gradeBoundaryValues.length > 0
                                ? "custom"
                                : customisedAssessment.gradeBoundaryFactor === 0
                                  ? "tracker"
                                  : "factor",
                        name: customisedAssessment.name,
                        countsTowardGrade: customisedAssessment.countsTowardGrade,
                        visibleClassTrackers: [],
                        hideForNow: false,
                    } as any);
                });
            }

            if (values.copyAssessmentForm.assessmentType === "pp") {
                assessmentQueries.forEach(assessmentQuery => {
                    const customisedAssessment =
                        assessmentQuery.data as PublishedAssessmentLiveTrackingData[];
                    customisedAssessmentList.push({
                        ...emptyCustomisedAssessmentValues,
                        dataEntryType: customisedAssessment.length > 1 ? "qla" : "single",
                        personalisedAssesmentQuestions: customisedAssessment.map(ltd => ({
                            ...ltd,
                            topics:
                                values.copyAssessmentForm.specification !== values.specification
                                    ? []
                                    : ltd.topics.map(t => t.id),
                        })) || [{ question: "", maxValue: 0, topics: [] }],
                        savedAndCollapsed: false,
                    });
                });
            }
            setFieldValue("customisedAssessments", customisedAssessmentList);
            handleClose("eott");
        }
    }, [assessmentQueries]);

    useMount(() => {
        setFieldValue("copyAssessmentForm.subjectArea", selectedSubjectArea);
        setFieldValue("copyAssessmentForm.qualifiation", selectedQualification);
        setFieldValue("copyAssessmentForm.specification", selectedSpecification);
    });

    useEffect(() => {
        if (
            values.copyAssessmentForm.subjectArea === selectedSubjectArea &&
            values.copyAssessmentForm.qualification === selectedQualification &&
            values.copyAssessmentForm.specification === selectedSpecification
        ) {
            setSelectedSubjectArea(null);
            setSelectedQualification(null);
            setSelectedSpecification(null);
        }
    }, [
        values.copyAssessmentForm.subjectArea,
        values.copyAssessmentForm.qualification,
        values.copyAssessmentForm.specification,
    ]);

    return (
        <Box p={6}>
            <HeadingCounter number={2}>{t("class.assessment.add.selectSource")}</HeadingCounter>
            <Typography component="p" variant="overline" sx={{ mb: 3 }}>
                {t("class.assessment.add.selectSourceHint")}
            </Typography>
            <Box sx={{ width: "calc(33% - 16px)" }}>
                <Field
                    name="copyAssessmentForm.assessmentType"
                    label={t("class.assessment.add.assessmentType")}
                    component={TextField}
                    select
                >
                    <MenuItem value={"pp"}>{t("class.assessment.add.assessmentpp")}</MenuItem>
                    <MenuItem value={"eott"}>{t("class.assessment.add.assessmenteott")}</MenuItem>
                </Field>
            </Box>
            <Box sx={{ pt: 4 }}>
                <Field
                    name={`copyAssessmentForm.specification`}
                    component={SpecificationSelectField}
                    cohort={cohort}
                    allowedOnly={!!(values.copyAssessmentForm.assessmentType === "eott")}
                    trackerPath={
                        trackerPath
                            ? trackerPath
                            : selectedSpecification
                              ? [
                                    {
                                        id: selectedSubjectArea,
                                        type: "subjectArea",
                                    },
                                    {
                                        id: selectedQualification,
                                        type: "qualification",
                                    },
                                    {
                                        id: selectedSpecification,
                                        type: "specification",
                                    },
                                ]
                              : undefined
                    }
                    handleQualificationChange={qualification => {
                        setFieldValue("copyAssessmentForm.qualification", qualification);
                        setFieldValue("copyAssessmentForm.assessmentName", null);
                    }}
                    handleSubjectAreaChange={subjectArea => {
                        setFieldValue("copyAssessmentForm.subjectArea", subjectArea);
                        setFieldValue("copyAssessmentForm.assessmentName", null);
                    }}
                    handleSpecificationChange={specification => {
                        setFieldValue("copyAssessmentForm.specification", specification.id);
                        setFieldValue("copyAssessmentForm.assessmentName", null);
                    }}
                />
            </Box>

            {values.copyAssessmentForm.assessmentType === "eott" ? (
                <Box sx={{ pt: 4 }}>
                    <Grid container spacing={4}>
                        <Grid item sm={4}>
                            <Field
                                name="copyAssessmentForm.academicYear"
                                label={t("class.assessment.add.academicYear")}
                                component={TextField}
                                select
                            >
                                {cohorts.map(academicYear => {
                                    return (
                                        <MenuItem key={academicYear.id} value={academicYear.id}>
                                            {academicYear.name}
                                        </MenuItem>
                                    );
                                })}
                            </Field>
                        </Grid>
                        <Grid item sm={4}>
                            <Field
                                name="copyAssessmentForm.yearGroup"
                                label={t("class.assessment.add.yearGroup")}
                                component={TextField}
                                select
                            >
                                {yearGroups?.map(year => (
                                    <MenuItem key={year.id} value={year.id}>
                                        {year.name}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <></>
            )}

            <Box sx={{ pt: 4 }}>
                {values.copyAssessmentForm.specification &&
                ((values.copyAssessmentForm.assessmentType === "pp" && ppListForCopy?.length > 0) ||
                    (values.copyAssessmentForm.assessmentType === "eott" &&
                        eottListForCopy?.length > 0)) ? (
                    <Field
                        name="copyAssessmentForm.assessmentNames"
                        label={t("class.assessment.add.assessmentName")}
                        component={MultipleSelectField}
                        options={getAssessmentList()}
                        sx={{ width: "calc(33% - 16px)" }}
                    ></Field>
                ) : values.copyAssessmentForm.specification &&
                  ((values.copyAssessmentForm.assessmentType === "pp" &&
                      ppListForCopy?.length === 0) ||
                      (values.copyAssessmentForm.assessmentType === "eott" &&
                          eottListForCopy?.length === 0)) ? (
                    t("class.assessment.add.noAssessments")
                ) : isFetched && !isEottCopySuccess ? (
                    t("class.assessment.add.noAssessments")
                ) : (
                    <></>
                )}
            </Box>
            {values.copyAssessmentForm.assessmentNames.length > 0 ? (
                <Button
                    onClick={() => {
                        setSelectedAssessments(values.copyAssessmentForm.assessmentNames);
                    }}
                    sx={{ marginTop: 4 }}
                >
                    {t("class.assessment.add.copySelected")}
                </Button>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default CopyAssessmentForm;
