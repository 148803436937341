import { StudentFilterRequest } from "../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { KS4_GRADE_VIEW_TYPE } from "../components/Grid/KS4GradeAnalysisGrid";

export interface StudentAttributePair {
    attribute: string;
    attributeDisplay: string;
    value: string;
    valueDisplay: string;
}

export type AnalysisTrackerValueType = number | string | null;

export interface AnalysisCellState {
    errors: boolean;
    whileUpdate: boolean;
    lastUpdatedAt: string;
    prevValue: AnalysisTrackerValueType;
    currentValue: AnalysisTrackerValueType;
    readOnly?: boolean;
}

export enum AnalysisCompareDataSourceTypes {
    LIVE_TRACKING = "live-tracking",
    FORECAST = "forecast",
    SNAPSHOT = "snapshot",
}

export enum SubjectAnalysisCharTypes {
    ABOVE_TARGET = "aboveTarget",
    ON_TARGET = "onTarget",
    ONE_GRADE_AWAY = "oneGradeAway",
    OFF_TARGET = "offTarget",
    NOT_SET = "numberTargetNotSet",
}

const initialStudentRequest = {
    form: undefined,
    ethnicities: undefined,
    gender: undefined,
    senNeeds: undefined,
    senStatuses: undefined,
    pupilPremium: undefined,
    fsm: undefined,
    lookedAfter: undefined,
    eal: undefined,
    priorAbility: undefined,
    nativeSpeaker: undefined,
    fromDateJoined: undefined,
    toDateJoined: undefined,
};

export const prepareStudentFilters = (
    studentFilters: StudentAttributePair[],
): StudentFilterRequest => {
    const newRequest: StudentFilterRequest = { ...initialStudentRequest };
    Object.keys(newRequest).forEach(key => {
        const entries = studentFilters.filter(sf => sf.attribute === key);
        const isMultiple = entries.length > 1;
        if (isMultiple) {
            newRequest[key] = entries.map(e => e.value).join(",");
        } else if (entries.length === 1) {
            newRequest[key] = entries[0].value;
        }
    });
    return newRequest;
};

export const getAnalysisGradeValue = (value, relatedData, gradeTypeId, viewType, a8?) => {
    if (value === false) return "A";
    if (parseInt(`${value}`) === -1 && viewType === KS4_GRADE_VIEW_TYPE && a8) {
        return "0.00";
    }
    if (parseInt(`${value}`) === -1 && viewType !== KS4_GRADE_VIEW_TYPE) {
        return "U";
    }

    if (relatedData) {
        const gradeType = relatedData.gradeTypes.find(g => g.id === gradeTypeId);

        if (gradeType) {
            const grades = gradeType.courseGrades;

            return grades?.find(g => g.id === value)?.name || value;
        }
    }

    return value;
};

export const normalizeValue = (value: string | number): AnalysisTrackerValueType => {
    let conversedValue: AnalysisTrackerValueType = null;

    if (value == null) return null;
    if (typeof value === "string") {
        if (isNaN(value as unknown as number)) {
            conversedValue = value;
        } else {
            conversedValue = value === null ? null : `${parseFloat(value)}`;
        }
    } else {
        conversedValue = value;
    }

    return conversedValue === "" ? null : conversedValue;
};

export const prepareDataToSend = debouncedCellStates => {
    const dataToSend: { [key: number]: { [key: string]: any } } = {};
    const columnsToRefresh: string[] = [];
    debouncedCellStates.forEach(cs => {
        let idParam = "";
        Object.keys(cs).forEach(key => {
            if (key.includes("student_id")) idParam = key;
        });

        const currentId = cs[idParam].currentValue;
        if (currentId) {
            dataToSend[currentId] = {};
            Object.keys(cs).forEach(csKey => {
                if (
                    cs[csKey].whileUpdate ||
                    (cs[csKey].currentValue !== cs[csKey].prevValue && cs[csKey].error === false)
                ) {
                    columnsToRefresh.push(csKey);
                    dataToSend[currentId][csKey] = normalizeValue(cs[csKey].currentValue);
                }
            });
        }
    });

    return { dataToSend, columnsToRefresh };
};

export const generateInitialCellStates = (rowData, columns?) => {
    const initialCellStates = [] as { [key: string]: AnalysisCellState }[];
    const sorted = [...rowData];
    sorted.sort((a, b) =>
        a.student_lastName?.toLowerCase() > b.student_lastName?.toLowerCase()
            ? 1
            : b.student_lastName?.toLowerCase() > a.student_lastName?.toLowerCase()
              ? -1
              : 0,
    );

    rowData.forEach(prd => {
        const newRow = { ...prd } as any;

        Object.keys(prd).forEach(cellKey => {
            newRow[cellKey] = {
                errors: false,
                whileUpdate: false,
                lastUpdatedAt: new Date().toISOString(),
                prevValue: newRow[cellKey],
                currentValue: newRow[cellKey],
                readOnly: columns[cellKey]?.readOnly,
            };
        });
        initialCellStates.push(newRow);
    });

    return initialCellStates;
};
export const prepareDataForBulkOrMultipleUpdate = (dataToSend, dataSet) => {
    const bulkEditData = {};

    Object.keys(dataToSend).forEach(studentId => {
        if (Object.keys(dataToSend[studentId]).length > 0 && dataSet) {
            const row = dataSet.find(ds => ds.student_id === parseInt(studentId));
            if (row) {
                bulkEditData[row.row as string] = dataToSend[studentId];
            }
        }
    });

    return bulkEditData;
};
