import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { STUDENT_DETAILS_QUERY } from "./useStudentDetails";
import { StudentModel } from "../model/StudentModel";
import { getSchoolAccountId } from "src/services/url";

export interface MergeStudentRequest {
    linkedStudent: ListObject<number>;
    unLinkedStudent: ListObject<number>;
}

const apiMergeStudent = async (
    values: MergeStudentRequest,
): Promise<AxiosResponse<StudentModel>> => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/student/merge/`, values);
};

export const useStudentMergeMutation = (onSuccess: () => void, onError?: (err) => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: MergeStudentRequest) => apiMergeStudent(values),
        onSuccess: data => {
            queryClient.setQueryData([STUDENT_DETAILS_QUERY, data.data?.id], data?.data);
            enqueueSnackbar(t("students.addEdit.studentMerged"), { variant: "success" });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(
                    err.response.data?.error || t("students.addEdit.studentMergeError"),
                    {
                        ...SnackbarErrorOptions,
                    },
                );
            }
            if (onError) {
                onError(err.response.data);
            }
        },
    });
};
