import { Button, MenuItem, Typography, Grid, Box } from "@mui/material";
import FormikRef from "src/forms/FormikRef";
import { Field, Form } from "formik";
import { TextField } from "formik-mui";
import { TFunction } from "i18next";
import { createRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Dialog from "src/components/Dialog";
import { useInviteFinance } from "../hooks/query/Staff/useInviteFinance";

export const inviteSchema = (t: TFunction) =>
    yup.object().shape({
        // title: yup.string().required(t("school.staff.list.inviteFinanceTitleReq")),
        firstName: yup.string().required(t("school.staff.list.inviteFinanceFirstnameReq")),
        lastName: yup.string().required(t("school.staff.list.inviteFinanceLastnameReq")),
        email: yup
            .string()
            .email(t("school.staff.list.inviteFinanceEmailEmail"))
            .required(t("school.staff.list.inviteFinanceEmailReq")),
    });

const InviteFinanceModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
    const form = createRef();
    const { t } = useTranslation();

    const handleErrorResponse = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
        },
        [form],
    );

    const { mutate: inviteFinance } = useInviteFinance(handleClose, handleErrorResponse);

    const handleSubmit = values => {
        inviteFinance({ ...values, title: values.title || null });
    };

    const handleSubmitForm = () => {
        const currentForm = form.current as any;
        currentForm.submitForm();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            title={t("school.staff.list.inviteFinance")}
            actions={
                <Button onClick={handleSubmitForm} color="primary">
                    {t("school.staff.list.inviteFinanceButton")}
                </Button>
            }
        >
            <Typography gutterBottom>{t("school.staff.list.inviteFinanceMessage")}</Typography>
            <Box mt={2.5}>
                <FormikRef
                    ref={form}
                    initialValues={{
                        title: "Mr",
                        firstName: "",
                        lastName: "",
                        email: "",
                    }}
                    validationSchema={inviteSchema(t)}
                    validateOnChange={true}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    <Form>
                        <Grid container spacing={4}>
                            <Grid item sm={4}>
                                <Field
                                    name="title"
                                    select
                                    label={t("school.staff.list.inviteFinanceTitle")}
                                    component={TextField}
                                    autoFocus
                                >
                                    <MenuItem value={`Mr`}>{t("common.mr")}</MenuItem>
                                    <MenuItem value={`Mrs`}>{t("common.mrs")}</MenuItem>
                                    <MenuItem value={`Miss`}>{t("common.miss")}</MenuItem>
                                    <MenuItem value={`Ms`}>{t("common.ms")}</MenuItem>
                                    <MenuItem value={`Dr`}>{t("common.dr")}</MenuItem>
                                    <MenuItem value={`Prof`}>{t("common.prof")}</MenuItem>
                                    <MenuItem value={`Mx`}>{t("common.mx")}</MenuItem>
                                </Field>
                                <Field
                                    name="email"
                                    label={t("school.staff.list.inviteFinanceEmail")}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <Field
                                    name="firstName"
                                    label={t("school.staff.list.inviteFinanceFirstName")}
                                    component={TextField}
                                />
                                <Field
                                    name="lastName"
                                    label={t("school.staff.list.inviteFinanceLastName")}
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </FormikRef>
            </Box>
        </Dialog>
    );
};

export default InviteFinanceModal;
