import { Component } from "react";

interface OwnProps {
    [key: string]: any;
    value: any;
    onChange: (newValue: any, oldValue: any, args: object) => void;
}

class FieldChangeHandler extends Component<OwnProps> {
    shouldComponentUpdate(nextProps) {
        return this.props.value !== nextProps.value;
    }

    componentDidUpdate(prevProps) {
        const { value, onChange, ...args } = this.props;
        onChange(value, prevProps.value, args);
    }

    render() {
        return null;
    }
}

export default FieldChangeHandler;
