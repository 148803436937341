import { useFormContext, Controller } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import { FC, forwardRef, useState, useEffect, RefAttributes } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { parse } from "date-fns";

interface IProps {
    name: string;
}

const WrappedTimePicker: FC<any> = ({ fieldValue: value, setValue, ...props }) => {
    useEffect(() => {
        setValue(
            value instanceof Date
                ? value
                : value.length > 5
                  ? parse(value === "00:00:00" ? "00:00:00" : value, "HH:mm:ss", new Date())
                  : parse(value === "00:00" ? "00:00" : value, "HH:mm", new Date()),
        );
    }, [value]);

    return <TimePicker {...props} />;
};

const RHFTimePicker: React.ForwardRefExoticComponent<
    Omit<IProps & TextFieldProps & RefAttributes<any>, "ref"> & RefAttributes<any>
> = forwardRef(({ name, ...other }, ref) => {
    const { control } = useFormContext();
    const [value, setValue] = useState<Date | null>(null);
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <WrappedTimePicker
                        fieldValue={field.value}
                        setValue={(val: Date) => setValue(val)}
                        label={other.label}
                        ampm={false}
                        ampmInClock={false}
                        disableOpenPicker={true}
                        showToolbar={true}
                        value={value}
                        onChange={(newValue: Date) => {
                            setValue(newValue);
                        }}
                        renderInput={(params: any) => (
                            <TextField
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                                inputRef={ref}
                                {...params}
                                {...other}
                                {...field}
                            />
                        )}
                    />
                );
            }}
        />
    );
});
RHFTimePicker.displayName = "RHFTimePicker";
export default RHFTimePicker;
