import { mdiAccountOutline, mdiLoginVariant } from "@mdi/js";
import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AuthContainer from "src/components/AuthContainer";
import Icon from "src/components/Icon";
import { useAuthContext } from "src/context/AuthContext";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useUserContext } from "src/modules/user/hooks/useUserContext";
import {
    ROUTE_CLASS_CLASS_LIST,
    ROUTE_LOGOUT,
    ROUTE_TRUST_DASHBOARD,
    ROUTE_USER_ACCOUNT,
} from "src/routes";
import { Storage } from "src/services/storage";
import AccountButton from "../components/Common/AccountButton";

const PostLoginContainer = () => {
    const routerNavigate = useNavigate();
    const { t } = useTranslation();
    const { authState } = useAuthContext();
    const { navigate } = useSchoolNavigate();
    const { data: userContext } = useUserContext();

    useEffect(() => {
        if (userContext && authState) {
            if (userContext.trusts.length === 1) {
                Storage.setItem("trustId", userContext.trusts[0].id);
                routerNavigate(
                    ROUTE_TRUST_DASHBOARD.replace(":trust", `${userContext.trusts[0].id}`),
                );
            } else if (userContext.schoolAccounts.length === 1) {
                Storage.setItem("schoolAccountId", userContext.schoolAccounts[0].id);
                navigate(ROUTE_CLASS_CLASS_LIST);
                navigate(0);
            } else if (
                userContext.schoolAccounts.length === 0 &&
                userContext.schoolAccounts.length === 0
            ) {
                navigate(ROUTE_USER_ACCOUNT);
                navigate(0);
            }
        }
    }, [userContext, authState]);

    if (userContext?.schoolAccounts.length > 1 || userContext?.trusts.length > 1) {
        return (
            <AuthContainer>
                <Typography
                    component="h1"
                    variant="h1"
                    color="secondary"
                    sx={{ color: "secondary.main", textAlign: "center", mb: 5 }}
                >
                    {t("auth.login.chooseProfile")}
                </Typography>
                <Typography variant="h3">{t("auth.login.schools")}:</Typography>
                {userContext?.schoolAccounts.length > 0 && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 1, mb: 3 }}>
                        {userContext?.schoolAccounts.map(account => (
                            <AccountButton
                                key={account.id}
                                logo={account.logoUrl}
                                title={account.name}
                                onClick={() => {
                                    Storage.setItem("schoolAccountId", `${account.id}`);
                                    navigate(
                                        ROUTE_CLASS_CLASS_LIST.replace(
                                            ":schoolAccountId",
                                            `${account.id}`,
                                        ),
                                    );
                                    navigate(0);
                                }}
                            />
                        ))}
                    </Box>
                )}
                {userContext?.trusts.length > 0 ? (
                    <>
                        <Typography variant="h3">{t("auth.login.trusts")}:</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 1 }}>
                            {userContext?.trusts.map(trust => (
                                <AccountButton
                                    key={trust.id}
                                    title={trust.name}
                                    onClick={() => {
                                        Storage.setItem("trustId", `${trust.id}`);
                                        navigate(
                                            ROUTE_TRUST_DASHBOARD.replace(":trust", `${trust.id}`),
                                        );
                                        navigate(0);
                                    }}
                                />
                            ))}
                        </Box>
                    </>
                ) : (
                    <></>
                )}
                <Divider sx={{ mb: 3 }} />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    {userContext?.schoolAccounts.length > 0 ? (
                        <Button
                            component={Link as any}
                            to={ROUTE_USER_ACCOUNT.replace(
                                ":schoolAccountId",
                                `${userContext?.schoolAccounts[0].id}`,
                            )}
                            variant="text"
                            startIcon={<Icon path={mdiAccountOutline} />}
                            disableRipple
                            onClick={() => {
                                navigate(0);
                            }}
                        >
                            {t("auth.login.myAccount")}
                        </Button>
                    ) : (
                        <></>
                    )}

                    <Button
                        component={Link as any}
                        to={ROUTE_LOGOUT}
                        variant="text"
                        startIcon={<Icon path={mdiLoginVariant} />}
                        disableRipple
                    >
                        {t("auth.login.signOut")}
                    </Button>
                </Box>
            </AuthContainer>
        );
    }

    return (
        <Box padding={20} textAlign={"center"}>
            <CircularProgress />
        </Box>
    );
};

export default PostLoginContainer;
