import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmTopicActions, OrmTopicActionTypes } from "./actions";

export const topicReducer: ModelReducer = {
    [OrmTopicActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmTopicActions.bulkImport>,
        session,
    ) => {
        const { Topic } = session;
        Topic.all()
            .filter(t => t.specificationId === action.payload.specificationId)
            .delete();
        action.payload.data.forEach(t =>
            Topic.create(
                ormToRef(t, { defaults: { specificationId: action.payload.specificationId } }),
            ),
        );
    },
};
