import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { UserTeachersListResponse } from "src/modules/user/api/teachers";
import { OrmUser } from ".";

export enum OrmUserActionTypes {
    BULK_IMPORT = "[orm] User BULK IMPORT",
    UPSERT = "[orm] User UPSERT",
    MASS_MERGE = "[orm] User MASS MERGE",
}

export const OrmUserActions = {
    bulkImport: (payload: UserTeachersListResponse) => {
        return createAction(OrmUserActionTypes.BULK_IMPORT, payload);
    },
    upsert: (payload: OrmUser) => {
        return createAction(OrmUserActionTypes.UPSERT, payload);
    },
    massMerge: (payload: UserTeachersListResponse) => {
        return createAction(OrmUserActionTypes.MASS_MERGE, payload);
    },
};

export type OrmUserActions = ActionsUnion<typeof OrmUserActions>;
