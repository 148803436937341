import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

export interface RemoveSnapshotRequest {
    id: number;
}

const apiRemoveSnapshot = async (values: RemoveSnapshotRequest): Promise<AxiosResponse> => {
    return await axiosInstance.delete(
        `school/${getSchoolAccountId()}/school-snapshot/${values.id}/`,
    );
};

export const useRemoveSnapshotMutation = (onSuccess?) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: RemoveSnapshotRequest) => apiRemoveSnapshot(values),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
            enqueueSnackbar(t("common.removed"), { variant: "success" });
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(err.response.data?.error || t("school.snapshots.errorRemoving"), {
                ...SnackbarErrorOptions,
            });
        },
    });
};
