import { IconButton, Menu, Button, Theme, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mdiDotsVertical } from "@mdi/js";
import { FC, ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import ActionMenuItem from "./ActionMenuItem";
import { ActionMenuItemProps } from "./types";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        height: 46,
        width: 46,
    },
    singleAction: {
        height: "auto",
        width: "auto",
        padding: theme.spacing(1.5, 1.5, 1.5, 2),
    },
    menu: {
        minWidth: 240,
    },
}));

interface OwnProps {
    rowData: object;
    actions: ActionMenuItemProps[];
    forceDotMenu?: boolean;
}

const ActionsTableButton: FC<OwnProps> = ({ actions, rowData, forceDotMenu }): ReactElement => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const onCloseHandler = (callback: ((rowData: object) => void) | undefined) => () => {
        handleClose();
        callback && callback(rowData);
    };

    const visibleActions = actions.filter(
        action => !action.showAction || action.showAction(rowData),
    );

    return (
        <div
            className={clsx(
                "actionMenuArea",
                classes.root,
                visibleActions.length === 1 && !forceDotMenu ? classes.singleAction : "",
            )}
        >
            {visibleActions.length > 1 || (forceDotMenu && visibleActions.length === 1) ? (
                <Box className="actionMenu">
                    <IconButton onClick={handleClick}>
                        <Icon size="22px" path={mdiDotsVertical} />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={anchorEl !== null}
                        onClose={handleClose}
                        elevation={8}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        classes={{
                            paper: classes.menu,
                        }}
                    >
                        {visibleActions.map((action, index) => (
                            <ActionMenuItem
                                key={index}
                                text={action.text}
                                icon={action.icon}
                                onClick={onCloseHandler(action.onAction)}
                                link={action.linkCallback && action.linkCallback(rowData)}
                            />
                        ))}
                    </Menu>
                </Box>
            ) : (
                visibleActions.length === 1 && (
                    <Box className="actionMenu">
                        {visibleActions[0].linkCallback ? (
                            <Button
                                component={Link as any}
                                to={
                                    visibleActions[0].linkCallback &&
                                    visibleActions[0].linkCallback(rowData)
                                }
                                variant="text"
                                startIcon={<Icon path={visibleActions[0].icon.props.path} />}
                                disableRipple
                            >
                                {visibleActions[0].text}
                            </Button>
                        ) : (
                            <Button
                                variant="text"
                                startIcon={<Icon path={visibleActions[0].icon.props.path} />}
                                disableRipple
                                onClick={onCloseHandler(visibleActions[0].onAction)}
                            >
                                {visibleActions[0].text}
                            </Button>
                        )}
                    </Box>
                )
            )}
        </div>
    );
};

export default ActionsTableButton;
