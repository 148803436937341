import { makeStyles } from "@mui/styles";
import { Icon as MDIIcon } from "@mdi/react";
import { IconProps } from "@mdi/react/dist/IconProps";
import { FC, ReactElement } from "react";

const useStyles = makeStyles(() => ({
    svg: {
        fill: "currentColor",
        width: "1em",
        height: "1em",
        display: "inline-block",
        flexShrink: 0,
        userSelect: "none",
    },
}));

export const mdiResubscribe =
    "M9 4c-1.1 0-2.1.2-3.1.6-.9.4-1.8 1-2.6 1.7C2.6 7.1 2 8 1.6 8.9c-.4 1-.6 2-.6 3.1s.2 2.1.6 3.1c.4.9 1 1.8 1.7 2.6.8.7 1.7 1.3 2.6 1.7 1 .4 2 .6 3.1.6s2.1-.2 3.1-.6c.9-.4 1.8-1 2.6-1.7.7-.8 1.3-1.7 1.7-2.6.4-1 .6-2 .6-3.1s-.2-2.1-.6-3.1c-.4-.9-1-1.8-1.7-2.6-.8-.7-1.7-1.3-2.6-1.7-1-.4-2-.6-3.1-.6m12 8c0 2.6-1.7 4.8-4 5.7v2c3.5-.8 6-4 6-7.7s-2.5-6.9-6-7.7v2c2.3.9 4 3.1 4 5.7z";

const Icon: FC<IconProps> = ({ size = "18px", ...props }): ReactElement => {
    const classes = useStyles();
    return <MDIIcon {...props} size={size} className={classes.svg} />;
};

export default Icon;
