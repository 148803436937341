import { Box, Container, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactNode, FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import COLORS from "src/styles/colors";
// import { darken } from "@mui/styles";
import { APP_VERSION, PUPIL_PROGRESS_LANDING_URL } from "src/config/globals";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: COLORS.VERY_LIGHT_GREY_2,
        padding: theme.spacing(4.5, 0),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3, 0),
        },
    },
    containerMobile: {
        "& > .MuiContainer-root": {
            maxWidth: 1280,
            width: "100%",
        },
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    footerNav: {
        listStyle: "none",
        margin: theme.spacing(0, 0, 2.5),
        padding: 0,
    },
    footerNavItem: {
        fontSize: 10,
        lineHeight: 1,
        "& + &": {
            borderLeft: `1px solid ${COLORS.LIGHT_GREY_1}`,
            marginLeft: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down("sm")]: {
                marginLeft: theme.spacing(1),
                paddingLeft: theme.spacing(1),
            },
        },
    },
    footerNavLink: {
        color: COLORS.GREY_3,
        textDecoration: "none",
        transition: theme.transitions.create(["color"], {
            duration: theme.transitions.duration.complex,
        }),
        "&:hover": {
            // color: darken(COLORS.GREY_3, 0.5),
        },
    },
}));

interface FooterNavProps {
    children: ReactNode;
}

const FooterNav: FC<FooterNavProps> = ({ children }): ReactElement => {
    const classes = useStyles();
    return (
        <Box component="nav">
            <Box component="ul" display="flex" className={classes.footerNav}>
                {children}
            </Box>
        </Box>
    );
};

interface FooterProps {
    mobile?: boolean;
}

const Footer: FC<FooterProps> = ({ mobile }): ReactElement => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box
            className={clsx(classes.container, mobile && classes.containerMobile)}
            component="footer"
        >
            <Container maxWidth="lg">
                <Box className={classes.wrapper}>
                    <FooterNav>
                        <Box component="li" className={classes.footerNavItem}>
                            <a
                                href={`${PUPIL_PROGRESS_LANDING_URL}/terms-of-service/`}
                                target="blank"
                            >
                                {t("footer.termsOfService")}
                            </a>
                        </Box>
                        <Box component="li" className={classes.footerNavItem}>
                            <a
                                href={`${PUPIL_PROGRESS_LANDING_URL}/privacy-policy/`}
                                target="blank"
                            >
                                {t("footer.privacyPolicy")}
                            </a>
                        </Box>
                        <Box component="li" className={classes.footerNavItem}>
                            <a href={`${PUPIL_PROGRESS_LANDING_URL}/cookie-policy/`} target="blank">
                                {t("footer.cookiesPolicy")}
                            </a>
                        </Box>
                        <Box component="li" className={classes.footerNavItem}>
                            <a
                                href={`${PUPIL_PROGRESS_LANDING_URL}/data-processing-agreement/`}
                                target="blank"
                            >
                                {t("footer.dataProcessingAgreement")}
                            </a>
                        </Box>
                        <Box component="li" className={classes.footerNavItem}>
                            <a href={`${PUPIL_PROGRESS_LANDING_URL}/blog/`} target="blank">
                                {t("footer.blog")}
                            </a>
                        </Box>
                    </FooterNav>
                    <Box
                        component="p"
                        fontSize={10}
                        lineHeight={1.66}
                        m={0}
                        p={0}
                        textAlign="center"
                        color={COLORS.LIGHT_GREY_1}
                    >
                        {t("footer.copy1")}
                        <br />
                        {t("footer.copy2").replace(":year", `${new Date().getFullYear()}`)}{" "}
                        {` (v${APP_VERSION})`}{" "}
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
