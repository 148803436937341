import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSubscription } from "src/orm/models/Subscription";
import { getSchoolAccountId } from "src/services/url";

export const createTrialSubscriptionEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subject-area/{subjectArea}/create-trial/`,
    method: ApiRequests.POST,
};

export type CreateTrialSubscriptionResponse = OrmSubscription[];
