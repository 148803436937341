import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction, paramRequest } from "src/api/helpers";
import {
    getClassTrackerGroupSnapshotListEndpoint,
    SnapshotList,
} from "../../api/Snapshot/ClassTrackerGroup/getList";
import {
    getClassTrackerGroupSnapshotValuesEndpoint,
    SnapshotClassTrackerGroupValuesObject,
} from "../../api/Snapshot/ClassTrackerGroup/getValues";
import { compareClassTrackerGroupSnapshotEndpoint } from "../../api/Snapshot/ClassTrackerGroup/compare";
import { getStudentSnapshotReportDownloadEndpoint } from "../../api/Snapshot/ClassTracker/getStudentSnapshotReport";
import { HighlightValuesState } from "../reducers";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { SnapshotData } from "src/context/TrackerContext";

export enum SnapshotActionsTypes {
    GET_CLASS_TRACKER_GROUP_SNAPSHOT_LIST = "[tracker] GET CLASS TRACKER GROUP SNAPSHOT LIST",
    GET_CLASS_TRACKER_GROUP_SNAPSHOT_LIST_SUCCESS = "[tracker] GET CLASS TRACKER GROUP SNAPSHOT LIST SUCCESS",
    GET_CLASS_TRACKER_GROUP_SNAPSHOT_VALUES = "[tracker] GET CLASS TRACKER GROUP SNAPSHOT VALUES",
    GET_CLASS_TRACKER_GROUP_SNAPSHOT_VALUES_SUCCESS = "[tracker] GET CLASS TRACKER GROUP SNAPSHOT VALUES SUCCESS",
    COMPARE_CLASS_TRACKER_GROUP_SNAPSHOTS = "[tracker] COMPARE CLASS TRACKER GROUP SNAPSHOTS",
    COMPARE_CLASS_TRACKER_GROUP_SNAPSHOTS_SUCCESS = "[tracker] COMPARE CLASS TRACKER GROUP SNAPSHOTS SUCCESS",
    SET_CLASS_TRACKER_SNAPSHOTS = "[tracker] SET CLASS TRACKER SNAPSHOTS",
    SET_CLASS_TRACKER_GROUP_SNAPSHOTS = "[tracker] SET CLASS TRACKER GROUP SNAPSHOTS",
    GET_STUDENT_SNAPSHOT_REPORT = "[tracker] GET STUDENT SNAPSHOT REPORT",
    GET_STUDENT_SNAPSHOT_REPORT_SUCCESS = "[tracker] GET STUDENT SNAPSHOT REPORT SUCCESS",
    SET_REPORT_DOWNLOAD_STATE = "[tracker] SET REPORT DOWNLOAD STATE",
    SET_HIGHLIGHT_VALUES_STATE = "[tracker] SET HIGHLIGHT VALUES STATE",
    REMOVE_REPORT_DOWNLOAD_STATE = "[tracker] REMOVE REPORT DOWNLOAD STATE",
    SET_SNAPSHOT_DATA = "[tracker] SET SNAPSHOT DATA",
}

export const SnapshotApiEndpoints = {
    getClassTrackerGroupSnapshotList: createActionObject(
        getClassTrackerGroupSnapshotListEndpoint,
        SnapshotActionsTypes.GET_CLASS_TRACKER_GROUP_SNAPSHOT_LIST_SUCCESS,
    ),
    getClassTrackerGroupSnapshotValues: createActionObject(
        getClassTrackerGroupSnapshotValuesEndpoint,
        SnapshotActionsTypes.GET_CLASS_TRACKER_GROUP_SNAPSHOT_VALUES_SUCCESS,
    ),
    compareClassTrackerGroupSnapshots: createActionObject(
        compareClassTrackerGroupSnapshotEndpoint,
        SnapshotActionsTypes.COMPARE_CLASS_TRACKER_GROUP_SNAPSHOTS_SUCCESS,
    ),
    // getClassTrackerSnapshotList: createActionObject(
    //     getClassTrackerSnapshotListEndpoint,
    //     SnapshotActionsTypes.GET_CLASS_TRACKER_SNAPSHOT_LIST_SUCCESS,
    // ),
    // getClassTrackerSnapshotValues: createActionObject(
    //     getClassTrackerSnapshotValuesEndpoint,
    //     SnapshotActionsTypes.GET_CLASS_TRACKER_SNAPSHOT_VALUES_SUCCESS,
    // ),
    // compareClassTrackerSnapshots: createActionObject(
    //     compareClassTrackerSnapshotEndpoint,
    //     SnapshotActionsTypes.COMPARE_CLASS_TRACKER_SNAPSHOTS_SUCCESS,
    // ),
    getStudentSnapshotReport: createActionObject(
        getStudentSnapshotReportDownloadEndpoint,
        SnapshotActionsTypes.GET_STUDENT_SNAPSHOT_REPORT_SUCCESS,
    ),
};

export const SnapshotRawActions = (
    SnapshotEndpoints: Record<Keys<typeof SnapshotApiEndpoints>, ApiActionMeta>,
) => ({
    getClassTrackerGroupSnapshotList: (classTrackerGroup: number) =>
        createApiAction(
            SnapshotActionsTypes.GET_CLASS_TRACKER_GROUP_SNAPSHOT_LIST,
            paramRequest({ classTrackerGroup }),
            SnapshotEndpoints.getClassTrackerGroupSnapshotList,
        ),
    getClassTrackerGroupSnapshotListSuccess: (payload: ApiCallbackPayload<SnapshotList[]>) =>
        createAction(SnapshotActionsTypes.GET_CLASS_TRACKER_GROUP_SNAPSHOT_LIST_SUCCESS, payload),
    getClassTrackerGroupSnapshotValues: (
        classTrackerGroup: number,
        snapshot: number,
        tier: TiersTypes,
    ) =>
        createApiAction(
            SnapshotActionsTypes.GET_CLASS_TRACKER_GROUP_SNAPSHOT_VALUES,
            { values: {}, params: { classTrackerGroup, snapshot, tier } },
            SnapshotEndpoints.getClassTrackerGroupSnapshotValues,
        ),
    getClassTrackerGroupSnapshotValuesSuccess: (
        payload: ApiCallbackPayload<SnapshotClassTrackerGroupValuesObject>,
    ) =>
        createAction(SnapshotActionsTypes.GET_CLASS_TRACKER_GROUP_SNAPSHOT_VALUES_SUCCESS, payload),
    compareClassTrackerGroupSnapshots: (
        classTrackerGroup: number,
        dataSource1: string,
        dataSource2: string,
        tier?: TiersTypes,
    ) =>
        createApiAction(
            SnapshotActionsTypes.COMPARE_CLASS_TRACKER_GROUP_SNAPSHOTS,
            {
                values: {},
                params: { classTrackerGroup, dataSource1, dataSource2, tier: tier || "-" },
            },
            SnapshotEndpoints.compareClassTrackerGroupSnapshots,
        ),
    compareClassTrackerGroupSnapshotsSuccess: (
        payload: ApiCallbackPayload<SnapshotClassTrackerGroupValuesObject>,
    ) => createAction(SnapshotActionsTypes.COMPARE_CLASS_TRACKER_GROUP_SNAPSHOTS_SUCCESS, payload),
    setClassTrackerGroupSnapshots: (payload: SnapshotList[]) =>
        createAction(SnapshotActionsTypes.SET_CLASS_TRACKER_GROUP_SNAPSHOTS, payload),
    setClassTrackerSnapshots: (payload: SnapshotList[]) =>
        createAction(SnapshotActionsTypes.SET_CLASS_TRACKER_SNAPSHOTS, payload),
    getStudentSnapshotReportDownload: (
        classTracker: number,
        id: number,
        snapshot: number,
        fileName: string,
    ) =>
        createApiAction(
            SnapshotActionsTypes.GET_STUDENT_SNAPSHOT_REPORT,
            { values: { fileName }, params: { classTracker, id, snapshot } },
            SnapshotEndpoints.getStudentSnapshotReport,
        ),
    getStudentSnapshotReportDownloadSuccess: (payload: ApiCallbackPayload<Blob>) =>
        createAction(SnapshotActionsTypes.GET_STUDENT_SNAPSHOT_REPORT_SUCCESS, payload),
    setReportDownloadState: (classTrackerId: number, studentId: number) =>
        createAction(SnapshotActionsTypes.SET_REPORT_DOWNLOAD_STATE, { classTrackerId, studentId }),
    setHighlightValuesState: (payload: HighlightValuesState[]) =>
        createAction(SnapshotActionsTypes.SET_HIGHLIGHT_VALUES_STATE, payload),
    setSnapshotData: (payload: SnapshotData | null) =>
        createAction(SnapshotActionsTypes.SET_SNAPSHOT_DATA, payload),
    removeReportDownloadState: (classTrackerId: number, studentId: number) =>
        createAction(SnapshotActionsTypes.REMOVE_REPORT_DOWNLOAD_STATE, {
            classTrackerId,
            studentId,
        }),
});
