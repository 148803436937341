import { ofType } from "@martin_hotell/rex-tils";
import { OrmSpecificationActions } from "src/orm/models/Specification/actions";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CommonActions, CommonActionTypes } from "../actions";

const specificationListSuccessEpic = (action$: ActionsObservable<CommonActions>) =>
    action$.pipe(
        ofType(CommonActionTypes.GET_SPECIFICATION_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof CommonActions.getSpecificationListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload =>
            of(
                OrmSpecificationActions.bulkImport({
                    data: payload.response,
                    qualification: payload.request.payload.params.qualification,
                }),
            ),
        ),
    );

export const specificationEpics = combineEpics(specificationListSuccessEpic);
