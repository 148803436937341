import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { StudentModel } from "src/orm/models/Student";
import { getSchoolAccountId } from "src/services/url";

export const getStudentsByGroupEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker-group/{classTrackerGroup}/students/`,
    method: ApiRequests.GET,
};

export type StudentsByGroupResponse = StudentModel[];
