import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getLiveGradeBoundarySeriesEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker-group/{classTrackerGroup}/grade-boundaries-series/`,
    method: ApiRequests.GET,
};

export interface GradeBoundariesSeriesObject {
    id: number;
    name: string;
    isDefault: boolean;
    position: number;
    status: string;
}
