import { attr, Model } from "redux-orm";

const fields = {
    id: attr(),
    name: attr(),
};

export interface OrmClass {
    id?: number;
    name: string;
}

class Class extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "Class";
    static fields = fields;
    static emptyRef: OrmClass = {
        id: 0,
        name: "",
    };

    get ormRef(): OrmClass {
        return this.ref as any;
    }
}

export default Class;
