import { Box, Button, Typography } from "@mui/material";
import { TFunction } from "i18next";

import { useTranslation } from "react-i18next";
import PaperInner from "src/components/PaperInner";

const UploadClassSuccess = ({
    handleResetForm,
    handleUploaded,
}: {
    handleResetForm: () => void;
    handleUploaded: (uploaded: boolean) => void;
}) => {
    const { t }: { t: TFunction } = useTranslation();
    return (
        <PaperInner>
            <Typography component="h2" variant="h4" gutterBottom>
                {t("common.thankYou")}
            </Typography>
            <Typography>{t("class.addClass.thankYouForUpload")}</Typography>
            <Box mt={4}>
                <Button
                    onClick={() => {
                        handleResetForm();
                        handleUploaded(false);
                    }}
                >
                    {t("class.addClass.uploadAnother")}
                </Button>
            </Box>
        </PaperInner>
    );
};

export default UploadClassSuccess;
