import TrackerClassGroupDetails from "./TrackerClassGroupDetails";
import GridFiltersToggleButton from "src/modules/common/components/Grid/GridFiltersToggleButton";
import COLORS from "src/styles/colors";
import GridSnapshotCompareInfo from "src/modules/common/components/Grid/GridSnapshotCompareInfo";
import TierHeaderSelect from "../Common/TierHeaderSelect";
import GridHeaderBack from "src/modules/common/components/Grid/GridHeaderBack";
import GridToolsToggleButton from "src/modules/common/components/Grid/GridToolsToggleButton";
import GridDataTypeButtons, {
    DataTypeTypes,
} from "src/modules/common/components/Grid/GridDataTypeButtons";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Box } from "@mui/material";
import { ClassTrackerListFilters } from "src/modules/class/api/Classes/getClassTrackers";
import { ROUTE_CLASS_CLASS_LIST_COHORT, ROUTE_TRACKER_CLASS_TRACKER_GROUP } from "src/routes";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { ClassTrackerRelatedObject } from "../../dto/TrackerRelated";
import { SnapshotData, useTrackerContext } from "src/context/TrackerContext";
import { useClassTrackerGroupSnapshots } from "../../hooks/query/ClassTrackerGroup/Snapshots/useSnapshots";
import SnapshotModal, {
    ShortSnapshotItem,
    SnapshotModalActions,
} from "src/modules/common/components/SnapshotModal";
import { SchoolUserRole } from "src/orm/models/User";
import { generateTrackerUrl } from "src/services/url";
import { useRemoveSnapshotMutation } from "../../hooks/query/ClassTracker/Snapshots/useRemoveSnapshotMutation";
import { useCreateSnapshotMutation } from "../../hooks/query/ClassTracker/Snapshots/useCreateSnapshotMutation";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

interface OwnProps {
    classTrackerGroupId?: number;
    dataType?: DataTypeTypes;
    handleToolsVisible?: (isChecked: boolean) => void;
    handleFiltersVisible: (isChecked: boolean) => void;
    handleDataTypeChange?: (type: DataTypeTypes) => void;
    isLoadingGrid?: boolean;
    relatedData: ClassTrackerRelatedObject | null;
    lastFilterOptions?: { cohort: number; filters?: ClassTrackerListFilters };
    toolsVisible?: boolean;
    filtersVisible?: boolean;
    tier: TiersTypes;
    archived?: boolean;
    hasForecast?: boolean;
    bulkEdit?: boolean;
}

const TrackerGroupHeader: FC<OwnProps> = ({
    classTrackerGroupId,
    dataType,
    handleDataTypeChange,
    handleToolsVisible,
    handleFiltersVisible,
    relatedData,
    isLoadingGrid,
    lastFilterOptions,
    toolsVisible,
    filtersVisible,
    tier,
    archived,
    hasForecast,
}) => {
    const { navigate } = useSchoolNavigate();
    const { moveStudents, cohort } = useParams() as any;
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [showTools, setShowTools] = useState<boolean>(!!moveStudents);
    const [snapshotModalOpen, setSnapshotModalOpen] = useState<boolean>(false);
    const { trackerState: trackerContext, setSnapshotData } = useTrackerContext();
    const { snapshotData } = trackerContext;
    const { data: groupSnapshots, refetch: refetchSnapshots } =
        useClassTrackerGroupSnapshots(classTrackerGroupId);
    const { mutate: createYgSnapshot } = useCreateSnapshotMutation(() => {
        refetchSnapshots();
        setTimeout(() => {
            refetchSnapshots();
        }, 10000);
    });
    const { mutate: removeSnapshot } = useRemoveSnapshotMutation(() => refetchSnapshots());

    const { data: profile } = useProfile();

    const getSnapshotAvailableTypes = () => {
        const actions: SnapshotModalActions[] = [];
        if (
            !archived &&
            (profile?.userRole === SchoolUserRole.SCHOOL_ADMIN ||
                profile?.userRole === SchoolUserRole.OWNER ||
                profile?.isLeaderOfAll ||
                (profile?.leaderOfSubjectAreas &&
                    profile?.leaderOfSubjectAreas.length > 0 &&
                    !!profile.leaderOfSubjectAreas.find(
                        losa => losa.id === relatedData?.subjectArea?.id,
                    )))
        ) {
            actions.push(SnapshotModalActions.CREATE);
        }
        actions.push(SnapshotModalActions.VIEW_LIST);
        actions.push(SnapshotModalActions.COMPARE);

        return actions;
    };

    const urlWithFilters = lastFilterOptions
        ? ROUTE_CLASS_CLASS_LIST_COHORT.replace(":cohort", `${lastFilterOptions.cohort}`) +
          `?subjectArea=${lastFilterOptions.filters?.subjectAreaId || ""}&qualification=${
              lastFilterOptions.filters?.qualificationId || ""
          }&yearGroup=${lastFilterOptions.filters?.yearGroup || ""}`
        : "/";

    const handleTypeChange = (changeType: DataTypeTypes) => {
        if (!changeType && dataType === DataTypeTypes.SNAPSHOT) {
            setSnapshotModalOpen(true);
        } else if (changeType && changeType === DataTypeTypes.SNAPSHOT) {
            setSnapshotModalOpen(true);
        } else if (handleDataTypeChange) {
            handleDataTypeChange(changeType);
        }
    };

    useEffect(() => {
        handleToolsVisible && handleToolsVisible(showTools);
    }, [showTools]);

    useEffect(() => {
        handleFiltersVisible(showFilters);
    }, [showFilters]);

    useEffect(() => {
        setShowTools(false);
        setShowFilters(false);
    }, [moveStudents]);

    useEffect(() => {
        if (classTrackerGroupId) refetchSnapshots();
    }, [classTrackerGroupId]);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
        >
            <GridHeaderBack
                onClick={() => {
                    if (lastFilterOptions) {
                        navigate(urlWithFilters);
                    } else {
                        navigate("/");
                    }
                }}
            />
            <Box display="flex" justifyContent="space-between" flex={1} py={1.5} px={2.5}>
                <Box display="flex" alignItems="center">
                    {relatedData ? (
                        <TrackerClassGroupDetails classTrackerRelatedData={relatedData} />
                    ) : (
                        <></>
                    )}
                </Box>
                {relatedData &&
                Object.keys(relatedData?.tierConfig) &&
                Object.keys(relatedData?.tierConfig).length > 1 ? (
                    <Box display="flex" flexDirection="column" justifyContent="center">
                        <TierHeaderSelect
                            tiers={Object.keys(relatedData?.tierConfig).map(key => key)}
                            selectedTier={tier}
                            handleChange={tier => {
                                if (tier !== null) {
                                    navigate(
                                        generateTrackerUrl(
                                            ROUTE_TRACKER_CLASS_TRACKER_GROUP,
                                            classTrackerGroupId,
                                            cohort,
                                            relatedData?.subjectArea?.name,
                                            relatedData?.qualification?.name,
                                            relatedData?.specification,
                                            tier,
                                            relatedData?.yearGroup,
                                        ),
                                    );
                                    navigate(0);
                                }
                            }}
                        />
                    </Box>
                ) : (
                    <></>
                )}
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Box display="flex" alignItems="center">
                        {handleToolsVisible && (
                            <GridToolsToggleButton
                                disabled={isLoadingGrid || false}
                                dataType={dataType || DataTypeTypes.LIVE}
                                isChecked={toolsVisible || false}
                                handleChange={() => handleToolsVisible(!toolsVisible)}
                            />
                        )}
                        <GridFiltersToggleButton
                            disabled={isLoadingGrid || false}
                            isChecked={filtersVisible || false}
                            handleChange={() => handleFiltersVisible(!filtersVisible)}
                        />
                        <GridDataTypeButtons
                            dataType={dataType as DataTypeTypes}
                            hasForecast={hasForecast}
                            handleChange={handleTypeChange}
                            isLoadingGrid={isLoadingGrid}
                        />
                    </Box>
                    <GridSnapshotCompareInfo
                        snapshotData={snapshotData}
                        snapshots={groupSnapshots}
                    />
                </Box>
            </Box>
            <SnapshotModal
                snapshots={groupSnapshots as ShortSnapshotItem[]}
                availableTypes={getSnapshotAvailableTypes()}
                open={snapshotModalOpen}
                handleClose={() => {
                    setSnapshotModalOpen(false);
                }}
                handleCreateSnapshot={name => {
                    if (classTrackerGroupId) {
                        createYgSnapshot({ classTrackerGroupId, name });
                    }
                }}
                handleSnapshotRemove={snapshotId => {
                    if (classTrackerGroupId) {
                        removeSnapshot({ classTrackerGroupId, snapshotId });
                    }
                }}
                handleSnapshotDataChange={(snapshotData: SnapshotData) => {
                    if (snapshotData && handleDataTypeChange) {
                        handleDataTypeChange(DataTypeTypes.SNAPSHOT);
                        setSnapshotData(snapshotData);
                    }
                    setSnapshotModalOpen(false);
                }}
            />
        </Box>
    );
};

export default TrackerGroupHeader;
