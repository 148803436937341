import axiosInstance from "src/config/axios";
import { QueryState, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ASSESSMENTS_QUERY, AssessmentListFilters } from "./useList";
import { AssessmentListModel } from "../models/AssessmentListModel";
import { getSchoolAccountId } from "src/services/url";

export interface AssessmentRequest {
    units: {
        id: number;
        assessments: {
            id: number;
            countsTowardGrade: boolean;
            hiddenColumns: boolean;
            showQlaInReports: boolean;
            visibleClassTrackerIds: number[];
        }[];
    }[];
}

export interface AssessmentWGroupRequest extends AssessmentRequest {
    classTrackerGroupId: number;
}

const apiUpdateAssessment = async (
    classTrackerGroupId: number,
    values: AssessmentRequest,
): Promise<AxiosResponse<AssessmentListModel>> => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/assessments/${classTrackerGroupId}/`,
        values,
    );
};

export const useAssessmentMutation = (
    cohort: number,
    filters: AssessmentListFilters,
    onSuccess,
    onError,
) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: AssessmentWGroupRequest) =>
            apiUpdateAssessment(values.classTrackerGroupId, {
                units: values.units,
            }),

        onSuccess: res => {
            const groupId = (res.data as AssessmentListModel).id;
            const queryKey = [
                ASSESSMENTS_QUERY,
                getSchoolAccountId(),
                `${cohort}`,
                filters.subjectAreaId ? `${filters.subjectAreaId}` : "",
                filters.qualificationId ? `${filters.qualificationId}` : "",
                filters.yearGroup > -1 ? `${filters.yearGroup}` : "",
            ];
            const list: QueryState<{ pages: { data: AssessmentListModel[] }[] }> =
                queryClient.getQueryState(queryKey);

            if (list?.data) {
                const pages = [...list.data.pages];

                let pageIndex: number | null = null;
                let groupIndex: number | null = null;
                pages.forEach((page, pI) => {
                    const groupI = page.data.findIndex(d => d.id === groupId);
                    if (groupI > -1) {
                        groupIndex = groupI;
                        pageIndex = pI;
                    }
                });

                const pagesCopy = [...pages];
                (pagesCopy[pageIndex].data[groupIndex] as any) = res.data;
                queryClient.setQueryData(queryKey, { ...list.data, pages: pagesCopy });
            }

            enqueueSnackbar(t("common.saved"), { variant: "success" });
            if (onSuccess) {
                onSuccess(res.data as AssessmentListModel);
            }
        },
        onError: (err: any) => {
            if (onError && err?.response?.data?.error) {
                onError(err?.response?.data?.error);
            }
        },
    });
};
