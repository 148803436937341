import AppContainer from "src/components/AppContainer";
import HeadingCounter from "src/components/HeadingCounter";
import PaperInner from "src/components/PaperInner";
import COLORS from "src/styles/colors";
import FormikRef from "src/forms/FormikRef";
import MigrateForm, { MigrateFormShape } from "../../components/Migrate/forms/MigrateForm";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { useMigrateInfo } from "src/modules/class/hooks/Migrate/useMigrateInfo";
import { useMigrateClassesMutation } from "../../hooks/Migrate/useMigrateClassesMutation";
import { ROUTE_CLASS_CLASS_LIST_COHORT } from "src/routes";
import { useSnackbar } from "notistack";
import { useMigrateClassesAndSyncMutation } from "../../hooks/Migrate/useMigrateClassesAndSyncMutation";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const MigrateContainer = () => {
    const { data: accountInfo } = useAccountInfo();
    const form = useRef();
    const { navigate } = useSchoolNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { cohort } = useParams();
    const [selectedYearGroup, setSelectedYearGroup] = useState<number | "">("");

    const location = useLocation();
    const isSync = !!location.pathname.includes("-sync");

    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const yearGroups = data?.yearGroups;

    const { data: migrationInfo, refetch: refetchMigrateInfo } = useMigrateInfo(
        selectedYearGroup || null,
        isSync,
    );

    const { mutate: migrate } = useMigrateClassesMutation(() => {
        enqueueSnackbar(t("class.migrate.success"), { variant: "success" });
        navigate(ROUTE_CLASS_CLASS_LIST_COHORT.replace(":cohort", cohort));
    });

    const { mutate: migrateAndSync } = useMigrateClassesAndSyncMutation(() => {
        enqueueSnackbar(t("class.migrate.success"), { variant: "success" });
        navigate(ROUTE_CLASS_CLASS_LIST_COHORT.replace(":cohort", cohort));
    });

    const isSomeClassSelected = (values: MigrateFormShape) => {
        return values?.pendingClasses.reduce((isSelected, specClass) => {
            if (!isSelected && !!specClass.classes.find(c => c.selected)) {
                return true;
            }
            return isSelected;
        }, false);
    };

    useEffect(() => {
        if (selectedYearGroup !== null) {
            refetchMigrateInfo();
        }
    }, [selectedYearGroup]);

    const handleSubmit = values => {
        if (isSync) {
            const converted = {
                specifications: values.pendingClasses
                    .filter(pc => pc.classes.find(c => c.selected))
                    .map(pc => ({
                        specification: { id: pc.specification.id },
                        groups: pc.classes.filter(c => c.selected).map(c => ({ id: c.id })),
                    })),
                yearGroup: parseInt(`${selectedYearGroup}`),
                targetCohort: {
                    id: parseInt(cohort),
                },
            };

            migrateAndSync(converted);
        } else {
            const converted = values.pendingClasses.filter(sac => {
                const isSomeClassSelected = sac.classes.find(c => c.selected);
                if (isSomeClassSelected) {
                    return true;
                }
                return false;
            });

            const pendingClasses = [];
            converted.forEach(conv => {
                conv.classes
                    .filter(c => c.selected)
                    .forEach(c => {
                        pendingClasses.push(c.id);
                    });
            });

            migrate({
                pendingClasses: pendingClasses.map(pc => ({ id: pc })),
            });
        }
    };

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {isSync ? t("class.migrate.syncHeader") : t("class.migrate.header")}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {isSync ? (
                    <div dangerouslySetInnerHTML={{ __html: t("class.migrate.syncHint") }}></div>
                ) : (
                    t("class.migrate.hint")
                )}
            </Typography>
            <Paper sx={{ mt: 6 }}>
                <PaperInner>
                    <HeadingCounter number="1">{t("class.migrate.choose")}</HeadingCounter>
                    <Box sx={{ mb: 4 }}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: isSync
                                    ? t("class.migrate.syncChooseHint")
                                    : t("class.migrate.chooseHint").replaceAll(
                                          "MIS_Name",
                                          accountInfo?.misName || "",
                                      ),
                            }}
                        ></div>
                    </Box>
                    <Grid spacing={4} container>
                        <Grid item xs={3}>
                            <Box>
                                <Box color={COLORS.GREY_2} fontSize={10} lineHeight={1.27}>
                                    {t("class.migrate.sourceAcademicYear")}
                                </Box>
                                <Box fontSize={14} fontWeight="fontWeightNormal" lineHeight={1.82}>
                                    {
                                        cohorts?.find(
                                            c => parseInt(`${c.id}`) === parseInt(cohort) - 1,
                                        )?.name
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            {yearGroups?.length > 0 && (
                                <FormControl fullWidth>
                                    <InputLabel>{t("class.migrate.sourceYearGroup")}</InputLabel>
                                    <Select
                                        name="selectedSubjectArea"
                                        fullWidth
                                        label={t("class.migrate.sourceYearGroup")}
                                        value={selectedYearGroup}
                                        onChange={e =>
                                            setSelectedYearGroup(e.target.value as number)
                                        }
                                    >
                                        {yearGroups?.map(yg => (
                                            <MenuItem key={yg.id} value={yg.id}>
                                                {yg.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>

                    {migrationInfo && migrationInfo.length > 0 && (
                        <FormikRef
                            ref={form}
                            initialValues={{
                                pendingClasses: migrationInfo?.map(pc => {
                                    return {
                                        specification: { id: pc.specification.id },
                                        classes: isSync
                                            ? pc.pendingGroupCallGroups.map(pct => ({
                                                  ...pct,
                                                  selected: false,
                                              }))
                                            : pc.pendingClassTrackers.map(pct => ({
                                                  ...pct,
                                                  selected: false,
                                              })),
                                    };
                                }),
                            }}
                            onSubmit={handleSubmit}
                            enableReinitialize={true}
                        >
                            {formProps => (
                                <Box mt={4}>
                                    <HeadingCounter number="2">
                                        {t("class.migrate.chooseNewClasses")}
                                    </HeadingCounter>
                                    <MigrateForm classList={migrationInfo} isSync={isSync} />
                                    <Box mt={4}>
                                        <Button
                                            disabled={
                                                formProps.isSubmitting ||
                                                !isSomeClassSelected(formProps.values)
                                            }
                                            onClick={formProps.submitForm}
                                            color="primary"
                                        >
                                            {isSync
                                                ? t("class.migrate.migrateSyncButton")
                                                : t("class.migrate.migrateButton")}
                                        </Button>
                                        <GenericErrorMessage
                                            errors={formProps.errors}
                                            submitCount={formProps.submitCount}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </FormikRef>
                    )}
                </PaperInner>
            </Paper>
        </AppContainer>
    );
};

export default MigrateContainer;
