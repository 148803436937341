import { attr, Model } from "redux-orm";
import { OrmQualification } from "../Qualification";

const fields = {
    id: attr(),
    name: attr(),
    code: attr(),
    colour: attr(),
    lvl: attr(),
    lft: attr(),
    rgt: attr(),
    qualifications: attr(),
    canModifyTeachersAndLeaders: attr(),
};

export interface OrmSubjectArea {
    id: number | null;
    name: string;
    code: string;
    colour: string;
    lvl: number;
    lft: number;
    rgt: number;
    disabled?: boolean;
    numberOfLiveTrackers?: number;
    qualifications?: OrmQualification[];
    canModifyTeachersAndLeaders?: boolean;
    hasClasses?: boolean;
}

class SubjectArea extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "SubjectArea";
    static fields = fields;
    static emptyRef: OrmSubjectArea = {
        id: null,
        name: "",
        code: "",
        colour: "",
        lvl: 0,
        lft: 0,
        rgt: 0,
    };

    get ormRef(): OrmSubjectArea {
        return this.ref as any;
    }
}

export default SubjectArea;
