import { attr, Model } from "redux-orm";
import { OrmSpecification } from "../Specification";
import { OrmSubjectArea } from "../SubjectArea";
import { OrmQualification } from "../Qualification";
import { OrmClassTracker } from "../ClassTracker";
import { sortTiers } from "src/services/array";

const fields = {
    id: attr(),
    yearGroup: attr(),
    subjectArea: attr(),
    specification: attr(),
    qualification: attr(),
    hasAllObjects: attr(),
    cohortId: attr(),
    classTrackers: attr(),
    tiers: attr(),
    defaultTier: attr(),
    archived: attr(),
};

export type TiersTypes = "-" | "H" | "I" | "F";

export interface OrmClassTrackerGroup {
    id: number | null;
    cohortId: number | null;
    yearGroup: number | null;
    specification: OrmSpecification | null;
    qualification: OrmQualification | null;
    subjectArea: OrmSubjectArea | null;
    hasAllObjects: boolean;
    classTrackers: OrmClassTracker[];
    tiers?: TiersTypes[];
    defaultTier?: TiersTypes;
    archived?: boolean;
    hasGroupCallClassTrackers?: boolean;
}

export const emptyClassTrackerGroup = {
    id: null,
    cohortId: null,
    yearGroup: null,
    specification: null,
    qualification: null,
    subjectArea: null,
    hasAllObjects: true,
    classTrackers: [],
};

class ClassTrackerGroup extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "ClassTrackerGroup";
    static fields = fields;
    static emptyRef: OrmClassTrackerGroup = emptyClassTrackerGroup;

    get ormRef(): OrmClassTrackerGroup {
        const sortedTiers = sortTiers((this.ref as any).tiers || []);

        return { ...this.ref, tiers: sortedTiers } as any;
    }

    get ormRefDetails(): OrmClassTrackerGroup {
        return this.ormRef as any;
    }
}

export default ClassTrackerGroup;
