import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";
import { StaffUserModel } from "src/modules/school/models/StaffUserModel";
import { StaffAddEditForm } from "src/modules/school/containers/StaffAddEditContainer";
import { SCHOOL_STAFF_LIST_QUERY } from "./useStaffList";
import { useTranslation } from "react-i18next";
import { SCHOOL_STAFF_DETAILS_QUERY } from "./useStaffDetails";

const apiEditStaff = async (id: number, values: StaffAddEditForm): Promise<AxiosResponse> => {
    return await axiosInstance.put(`school/${getSchoolAccountId()}/staff/${id}/`, values);
};

export const useEditStaff = (onSuccess?, onError?) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: StaffAddEditForm & { id: number }) => apiEditStaff(values.id, values),
        onSuccess: (res: AxiosResponse<StaffUserModel>) => {
            queryClient.invalidateQueries({
                queryKey: [SCHOOL_STAFF_LIST_QUERY, getSchoolAccountId()],
            });
            queryClient.invalidateQueries({
                queryKey: [SCHOOL_STAFF_DETAILS_QUERY, getSchoolAccountId(), res.data.id],
            });
            enqueueSnackbar(t("school.staff.addEdit.staffEdited"), { variant: "success" });
            if (onSuccess) {
                onSuccess(res.data);
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            if (onError) {
                onError();
            }
            enqueueSnackbar(err.response.data?.error, {
                ...SnackbarErrorOptions,
            });
        },
    });
};
