import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContainer from "src/components/AuthContainer";
import { Typography, Box } from "@mui/material";
import COLORS from "src/styles/colors";
import Footer from "src/modules/common/components/Footer";
import { Storage } from "src/services/storage";

const MaintenanceContainer = () => {
    const { t } = useTranslation();
    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        const tmpMesasge = Storage.getItem("maintenanceMessage");
        if (tmpMesasge) {
            setMessage(tmpMesasge);
        }
        setTimeout(() => {
            window.location.replace("/");
            Storage.removeItem("maintenanceMessage");
        }, 60000);
        return () => {
            Storage.removeItem("maintenanceMessage");
        };
    }, []);

    return (
        <>
            <AuthContainer>
                <Box textAlign="center">
                    <Typography variant={"h2"}>{message || t("maintenance.header")}</Typography>
                    <Box my={4}>
                        <Typography variant="h5">{t("maintenance.text1")}</Typography>
                    </Box>
                    <Typography variant="h5">
                        <Box color={COLORS.BLUE_3} component="span">
                            {t("maintenance.text2")}
                        </Box>
                    </Typography>
                </Box>
            </AuthContainer>
            <Footer mobile />
        </>
    );
};

export default MaintenanceContainer;
