import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { TagAccessLevelEnum, TagModel, TagStatusEnum } from "../model/TagModel";
import { TAG_LIST_QUERY } from "./useTagsList";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

export interface TagCreateRequest {
    name: string;
    accessLevel: TagAccessLevelEnum;
    status: TagStatusEnum;
    yearGroups: number[];
    specifications: {
        id: number;
    }[];
}

const apiCreateTag = async (values: TagCreateRequest) => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/tag/`, values);
};
export const useCreateTagMutation = (onSuccess: (data: TagModel) => void, onError?) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: TagCreateRequest) => apiCreateTag(values),
        onSuccess: ({ data }: { data: TagModel }) => {
            queryClient.invalidateQueries({ queryKey: [TAG_LIST_QUERY] });
            enqueueSnackbar(t("tag.addEdit.tagCreated"), { variant: "success" });
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error && typeof err?.response?.data?.error === "string") {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
