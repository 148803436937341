import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";
import { useTranslation } from "react-i18next";

const apiInviteEmail = async (staff: number): Promise<AxiosResponse> => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/staff/${staff}/invitation-email/`,
    );
};

export const useInviteEmail = (onSuccess?) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (staff: number) => apiInviteEmail(staff),
        onSuccess: () => {
            enqueueSnackbar(t("school.staff.list.invitationSent"), { variant: "success" });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(t("school.staff.list.invitationError"), {
                ...SnackbarErrorOptions,
            });
        },
    });
};
