import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmClassActions, OrmClassActionTypes } from "./actions";

export const classReducer: ModelReducer = {
    [OrmClassActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmClassActions.bulkImport>,
        session,
    ) => {
        const { Class } = session;

        Class.all().delete();
        action.payload.forEach(c => Class.create(ormToRef(c, {})));
    },
};
