import LoginContainer from "src/modules/auth/containers/LoginContainer";
import { useState, ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { SchoolUserRole } from "src/orm/models/User";
import { useAuthContext } from "src/context/AuthContext";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { Storage } from "src/services/storage";
import { Box, CircularProgress } from "@mui/material";

export type AuthGuardProps = {
    children: ReactNode;
};

export default function AdminGuard({ children }: AuthGuardProps) {
    const { pathname } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
    const { authState } = useAuthContext();
    const { data: profile, isSuccess, isPending } = useProfile();
    const [isInitial, setIsInitial] = useState(true);

    useEffect(() => {
        if (isInitial) {
            setIsInitial(false);
        }
    }, [isInitial]);

    if (isInitial || isPending || !authState || !profile) {
        return (
            <Box textAlign={"center"} p={20}>
                <CircularProgress />
            </Box>
        );
    }

    if (
        (!profile && !isPending) ||
        (!authState.token && !Storage.getItem("token")) ||
        (profile?.userRole !== SchoolUserRole.SCHOOL_ADMIN &&
            profile?.userRole !== SchoolUserRole.OWNER)
    ) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <LoginContainer />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return isSuccess ? <>{children}</> : <></>;
}
