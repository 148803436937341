/* eslint-disable @typescript-eslint/no-explicit-any */
// first import all models
import { ORM } from "redux-orm";
import Class from "./models/Class";
import Grade from "./models/Grade";
import Qualification from "./models/Qualification";
import Specification from "./models/Specification";
import SpecificationAttribute from "./models/SpecificationAttribute";
import Student from "./models/Student";
import SubjectArea from "./models/SubjectArea";
import Subscription from "./models/Subscription";
import SubscriptionHistory from "./models/SubscriptionHistory";
import User from "./models/User";
import Invoice from "./models/Invoice";
import ClassTracker from "./models/ClassTracker";
import Topic from "./models/Topic";
import ClassTrackerGroup from "./models/ClassTrackerGroup";
import ReportTemplate from "./models/ReportTemplate";
import SchoolCustomField from "./models/SchoolCustomField";
import SnapshotReport from "./models/SnapshotReport";

const orm = new ORM();
// and register them

orm.register(
    SubjectArea as any,
    Qualification as any,
    Specification as any,
    SpecificationAttribute as any,
    User as any,
    Class as any,
    Student as any,
    Grade as any,
    Subscription as any,
    SubscriptionHistory as any,
    Invoice as any,
    ClassTracker as any,
    Topic as any,
    ClassTrackerGroup as any,
    ReportTemplate as any,
    SchoolCustomField as any,
    SnapshotReport as any,
);

export default orm;
