import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmReportTemplate } from "src/orm/models/ReportTemplate";
import { getSchoolAccountId } from "src/services/url";

export const getReportTemplateListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/report-template/`,
    method: ApiRequests.GET,
};

export type ReportTemplateList = OrmReportTemplate[];
