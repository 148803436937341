import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ColumnNode, HeaderNodeWithChildren } from "src/modules/tracker/dto/TrackerHeader";
import { getSchoolAccountId } from "src/services/url";

export const getAnalysisKS4GradeHeaderEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/ks4-grade/{cohort}/{yearGroup}/header/`,
    method: ApiRequests.GET,
};

export interface AnalysisKS4GradeHeaderObject {
    header: HeaderNodeWithChildren[];
    maxLevel: number;
    columns: {
        [key: string]: ColumnNode;
    };
}
