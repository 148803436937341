import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "src/modules/auth/hooks/useLogin";

export const USER_CONTEXT_QUERY = "userContextQuery";

export const useUserContext = () => {
    return useQuery({
        queryKey: [USER_CONTEXT_QUERY],
        queryFn: async (): Promise<UserContext | null> => {
            const { data } = await axiosInstance.get(`user/context/`);

            return data || null;
        },
    });
};
