import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSubscription } from "src/orm/models/Subscription";
import { getSchoolAccountId } from "src/services/url";

export const createNahtSupportedSubscriptionEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subject-area/{subjectArea}/create-naht-supported/`,
    method: ApiRequests.POST,
};

export type CreateNahtSupportedSubscriptionResponse = OrmSubscription[];
