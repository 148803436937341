import { create } from "zustand";
import { ClassTrackerUserSettingsObject } from "../ClassTracker/useTrackerUserSettings";

export type ClassTrackerGroupUserSettingsStoreObject = ClassTrackerUserSettingsObject & {
    setClassTrackerGroupUserSettings: (newSettings: ClassTrackerUserSettingsObject) => void;
};

export const useTrackerGroupUserSettingsStore = create<ClassTrackerGroupUserSettingsStoreObject>(
    set => ({
        setClassTrackerGroupUserSettings: (newSettings: ClassTrackerUserSettingsObject) =>
            set(state => ({ ...state, ...newSettings })),
    }),
);
