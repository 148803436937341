import useDebounce from "src/hooks/useDebounce";
import TrackerHeader from "../components/Header/TrackerHeader";
import TrackerGrid, { TrackerContext } from "../components/Grid/TrackerGrid";
import { Box } from "@mui/material";
import { CellValueChangedEvent } from "ag-grid-community";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import { ApiData, ApiStatus } from "src/api/constants";
import {
    HTTP_ERROR_CONFLICT,
    HTTP_FAILED_DEPENDENCY,
    HTTP_SUCCESS_ACCEPTED,
    HTTP_TOO_MANY_REQUESTS,
    apiUrl,
} from "src/config/globals";
import { usePrevious } from "src/hooks/usePrevious";
import { useResponse } from "src/hooks/useResponse";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { ROUTE_TRACKER_CLASS_TRACKER } from "src/routes";
import { useAllGroupTrackers } from "../hooks/useAllGroupTrackers";
import { useForecast } from "../hooks/useForecast";
import { useStudentMove } from "../hooks/useStudentMove";
import { useTrackerInitialConfigData } from "../hooks/useTrackerInitialConfigData";
import { useTrackerValues } from "../hooks/useTrackerValues";
import { getTrackerBorderColor } from "../services/colorHandler";
import { calculateHeaderHeight, getFlatHeaderStructure } from "../services/headerGenerator";
import {
    convertDataToSend,
    DataToSendType,
    getRowIndexByRow,
    LinksToSendType,
    mergeStudentColumnData,
    mergeStudentsToValues,
    sendTrackerData,
    TagsToSendType,
    TrackerValueType,
} from "../services/valueHandler";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "src/store/reducers";
import { TrackerStatus } from "src/orm/models/ClassTracker";
import { useMount } from "src/hooks/useMount";
import { ReportActions } from "src/modules/report/store/actions";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { sortArrayBy } from "src/services/array";
import { filterRowFromFilters } from "src/modules/common/services/valueHandler";
import { useTrackerSnapshotData } from "../hooks/query/ClassTracker/useTrackerSnapshotData";
import { ClassTrackerHeaderObject } from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";
import { ClassTrackerValuesObject } from "../dto/TrackerValues";
import { useTrackerCompareData } from "../hooks/query/ClassTracker/useTrackerCompareData";
import { generateTrackerUrl, getSchoolAccountId } from "src/services/url";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useClassTrackerTagList } from "src/modules/tagging/hooks/ClassTracker/useClassTrackerTagList";
import { useClassTrackerStudentTags } from "src/modules/tagging/hooks/ClassTracker/useClassTrackerStudentTags";
import {
    ClassTrackerStudentTagRows,
    useClassTrackerStudentTagMutation,
} from "src/modules/tagging/hooks/ClassTracker/useClassTrackerStudentTagMutation";
import { useClassTrackerLinksList } from "src/modules/links/hooks/ClassTracker/useClassTrackerLinks";
import {
    ClassTrackerStudentLinkRows,
    useClassTrackerStudentLinksList,
} from "src/modules/links/hooks/ClassTracker/useClassTrackerStudentLinks";
import { useClassTrackerStudentLinksMutation } from "src/modules/links/hooks/ClassTracker/useClassTrackerStudentLinksMutation";
import PromptDialog from "src/forms/PromptDialog";
import { useClassTrackerPixlSeriesList } from "../hooks/query/ClassTrackerGroup/useTrackerPixlSeriesList";
import { useTrackerUserSettings } from "../hooks/query/ClassTracker/useTrackerUserSettings";
import { useEditTrackerUserSettings } from "../hooks/query/ClassTracker/useEditTrackerUserSettings";
import { useTrackerUserSettingsStore } from "../hooks/query/ClassTracker/useTrackerUserSettingsStore";
import { useBeforeUnload } from "react-router-dom";
import { baseHeaders } from "src/services/ajax";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export interface UpdateRowResponse {
    data: { [key: string]: any };
    errors: { [key: string]: any }[];
}

const ClassTrackerContainer = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {
        classTracker,
        cohort,
        bulkEdit,
        moveStudents,
        tier,
        subject,
        qualification,
        specification,
        yearGroup,
    } = useParams();

    const classTrackerId: number | null = classTracker ? parseInt(classTracker) : null;
    const cohortId: number | null = cohort ? parseInt(cohort) : null;
    const {
        classTrackerHeader: gridHeader,
        classTrackerRelatedData: gridRelated,
        classDetails,
        getLastUpdatedStatuses,
        getClassTrackerHeader,
        apiHeader,
        apiRelatedData,
        apiValues,
    } = useTrackerInitialConfigData(classTrackerId, cohortId, tier as TiersTypes);

    //userSettings
    const { data: userSettings } = useTrackerUserSettings(classTrackerId);
    const { mutate: setTrackerUserSettings } = useEditTrackerUserSettings(classTrackerId);
    const { setClassTrackerUserSettings, ...gridUserSettings } = useTrackerUserSettingsStore();
    useEffect(() => {
        setClassTrackerUserSettings(userSettings);
    }, [userSettings]);

    const gridUserSettingsRef = useRef(gridUserSettings);
    gridUserSettingsRef.current = gridUserSettings;
    useEffect(() => {
        return () => setTrackerUserSettings(gridUserSettingsRef.current);
    }, []);

    useBeforeUnload(
        useCallback(() => {
            fetch(
                `${apiUrl("")}school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/user-settings/`,
                {
                    keepalive: true,
                    method: "POST",
                    headers: baseHeaders(),
                    body: JSON.stringify(gridUserSettings),
                },
            );
        }, [gridUserSettings]),
    );
    ////////

    const dispatch = useDispatch();
    const { navigate } = useSchoolNavigate();
    const subscriber = useRef(null) as any;
    const location = useLocation();
    const prevTier = usePrevious(tier);

    const [currentHeader, setCurrentHeader] = useState<ClassTrackerHeaderObject | null>(null);
    const [currentRelated, setCurrentRelated] = useState<ClassTrackerRelatedObject | null>(null);
    const [currentValues, setCurrentValues] = useState<ClassTrackerValuesObject | null>(null);

    const [rowsToRefresh, setRowsToRefresh] = useState<string[] | null>(null);
    const [rowsToRedraw, setRowsToRedraw] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [savingMoveTo, setSavingMoveTo] = useState<boolean>(false);
    const [gridFilters, setGridFilters] = useState<any>(null);
    const [dataType, setDataType] = useState<DataTypeTypes>(DataTypeTypes.LIVE);

    const [dataToSend, setDataToSend] = useState<DataToSendType[]>([]);
    const [tagsToSend, setTagsToSend] = useState<TagsToSendType[]>([]);
    const [linksToSend, setLinksToSend] = useState<LinksToSendType[]>([]);
    const [pixlData, setPixlData] = useState<any>(null);

    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
    const [toolsVisible, setToolsVisible] = useState<boolean>(false);
    const [triggerBulkSave, setTriggerBulkSave] = useState<boolean>(false);
    const [atlColumnsMap, setAtlColumnsMap] = useState({});
    const [expandedColumns, setExpandedColumns] = useState<{ [key: string]: boolean }>({});
    const [moveStudentsList, setMoveStudentsList] = useState<
        { studentId: number; moveToId?: number; tier?: TiersTypes }[] | null
    >(null);
    const [dataSet, setDataSet] = useState<{ [key: string]: TrackerValueType }[] | null>(null);
    const [initialDataSet, setInitialDataSet] = useState<
        { [key: string]: TrackerValueType }[] | null
    >(null);
    const debouncedDataToSend = useDebounce(dataToSend, 2000);
    const debouncedTagsToSend = useDebounce(tagsToSend, 2000);
    const debouncedLinksToSend = useDebounce(linksToSend, 2000);

    const [allTagsLinksWaiting, setAllTagsLinksWaiting] = useState<{
        colId: string;
        value: any;
        type: "tag" | "link";
    } | null>(null);

    const deboundedExpanded = useDebounce(expandedColumns, 500);
    const prevDataType = usePrevious(dataType);
    const lastFilterOptions = useSelector(
        (state: AppState) => state.class.classTrackerLastFilteringOptions,
    );
    const lastTrackerUpdatedStatuses = useSelector(
        (state: AppState) => state.class.lastUpdatedClassTrackers,
    );
    const [isBlocked, setIsBlocked] = useState<boolean>(!!currentRelated?.isBlocked);
    const [isAsyncProcessing, setIsAsyncProcessing] = useState<boolean>(
        !!currentRelated?.isBlocked,
    );
    const {
        classTrackerValues,
        getClassTrackerValues,
        getStudentsForClassTracker,
        apiStudents,
        clearStudents,
        classTrackerStudents,
    } = useTrackerValues();

    const [isGradeVBoundaryProcessing, setIsGradeBoundaryProcessing] = useState<boolean>(false);

    const { data: userProfile } = useProfile();
    const classTrackerRoute = generateTrackerUrl(
        ROUTE_TRACKER_CLASS_TRACKER,
        classTrackerId,
        cohortId,
        subject,
        qualification,
        specification,
        tier,
        yearGroup,
    );

    const {
        snapshotData,
        setSnapshotData,
        header: snapshotHeader,
        relatedData: snapshotRelatedData,
        values: snapshotValues,
        isSuccessAll: isSuccessTrackerSnapshot,
    } = useTrackerSnapshotData(classTrackerId, tier as TiersTypes);

    const {
        header: compareHeader,
        relatedData: compareRelatedData,
        values: compareValues,
        isSuccessAll: isSuccessTrackerCompare,
        removeValues: removeCompareValues,
    } = useTrackerCompareData(classTrackerId, tier as TiersTypes);

    const { getForecastClassTrackerValues, apiTrackerValues: apiTrackerForecastValues } =
        useForecast();
    const { apiMoveStudents, moveStudentsToClass } = useStudentMove();

    // tags
    const { data: tags, isLoading: tagsIsLoading } = useClassTrackerTagList(classTrackerId);
    const { data: studentTags, refetch: refetchStudentTags } =
        useClassTrackerStudentTags(classTrackerId);

    const { mutate: saveTags } = useClassTrackerStudentTagMutation(() => {
        refetchStudentTags();
        let newRowsToRedraw = rowsToRedraw?.length > 0 ? [...rowsToRedraw] : [];
        tagsToSend.map(tts => {
            const rowI = dataSet.findIndex(ctv => ctv.row === tts.row);
            newRowsToRedraw = newRowsToRedraw.filter(nrtr => nrtr !== rowI).concat(rowI as any);
        });
        setRowsToRedraw(newRowsToRedraw);
        setTagsToSend([]);
    });

    // links
    const { data: links, isLoading: linksIsLoading } = useClassTrackerLinksList(classTrackerId);
    const { data: studentLinks, refetch: refetchStudentLinks } =
        useClassTrackerStudentLinksList(classTrackerId);
    const { mutate: saveLinks } = useClassTrackerStudentLinksMutation(() => {
        refetchStudentLinks();
    });
    const { data: pixlSeriesList } = useClassTrackerPixlSeriesList(classDetails?.id);

    // all trackers list helper
    const { allClassTrackers } = useAllGroupTrackers(
        classDetails.specification?.id,
        classDetails.classTrackerGroup?.id,
    );

    const isGridDataFetched = !!(
        currentHeader &&
        apiHeader.status === ApiStatus.SUCCESS &&
        currentRelated &&
        apiRelatedData.status === ApiStatus.SUCCESS &&
        apiStudents.status === ApiStatus.SUCCESS &&
        gridUserSettings &&
        classDetails &&
        (dataType !== DataTypeTypes.FORECAST
            ? apiValues.status === ApiStatus.SUCCESS
            : apiTrackerForecastValues.status === ApiStatus.SUCCESS) &&
        currentValues &&
        !tagsIsLoading &&
        !linksIsLoading
    );

    // change single value handler
    const handleValueChange = (e: CellValueChangedEvent) => {
        if (!isBlocked && !isGradeVBoundaryProcessing) {
            const oldValue = e.oldValue === null ? "" : e.oldValue;
            const value = e.newValue;
            const colId = e.column.getColId();
            const newValue = e.newValue?.whileUpdate ? e.newValue?.value : e.newValue;
            if (oldValue !== newValue && e.data.row && colId) {
                if (colId.indexOf("tag-") === 0) {
                    setTagsToSend(prevState =>
                        prevState.concat({ row: e.data.row, column: colId, value }),
                    );
                } else if (colId.indexOf("link-") === 0) {
                    setLinksToSend(prevState =>
                        prevState.concat({ row: e.data.row, column: colId, value }),
                    );
                } else {
                    setDataToSend(prevState =>
                        prevState.concat({
                            row: e.data.row,
                            column: colId,
                            value,
                        }),
                    );
                }

                if (subscriber && subscriber.current) {
                    subscriber.current.unsubscribe();
                }
            }
        }
    };

    // handling move to
    const handleMoveStudentTo = (studentId: number, moveToId: number) => {
        const entry = moveStudentsList?.find(msl => msl.studentId === studentId);
        if (!entry) {
            setMoveStudentsList(prev => {
                return (prev || []).concat({ studentId, moveToId });
            });
        } else {
            const listIndex = moveStudentsList?.findIndex(msl => msl.studentId === studentId);
            if (moveStudentsList && listIndex !== undefined && listIndex > -1) {
                const newList = [...moveStudentsList];
                newList[listIndex].moveToId = moveToId;
                setMoveStudentsList(newList);
            }
        }
    };

    const handleTagsLinksAllColumnSelect = (colId, value, type) => {
        setAllTagsLinksWaiting({ colId, value, type });
    };

    const handleMoveStudentTierTo = (
        studentId: number,
        classId: number,
        moveToTier: TiersTypes,
    ) => {
        const entry = moveStudentsList?.find(msl => msl.studentId === studentId);

        if (!entry) {
            setMoveStudentsList(prev => {
                return (prev || []).concat({
                    studentId,
                    moveToId: classId,
                    tier: moveToTier || (tier as TiersTypes),
                });
            });
        } else {
            const listIndex = moveStudentsList?.findIndex(msl => msl.studentId === studentId);
            if (moveStudentsList && listIndex !== undefined && listIndex > -1) {
                const newList = [...moveStudentsList];
                newList[listIndex].tier = moveToTier || (tier as TiersTypes);
                setMoveStudentsList(newList);
            }
        }
    };

    // saving move to flag
    const handleOnSaveMoveClick = useCallback(() => {
        setSavingMoveTo(true);
    }, [dataSet, moveStudentsList]);

    // change columns handler
    const handleConfigureColumnsChange = () => {
        setIsLoading(true);
    };

    const handleFiltersChange = filters => {
        setToolsVisible(!!bulkEdit);
        setGridFilters(filters);
    };

    // api on save response handler
    const handleMultipleUpdateResponse = (response: UpdateRowResponse) => {
        if (dataSet) {
            const newDataSet: { [key: string]: TrackerValueType }[] = [...dataSet];
            Object.keys(response.data).forEach(rowKey => {
                const rowIndex = getRowIndexByRow(dataSet, rowKey);
                const row = response.data[rowKey];
                const rowError = response.errors.find(e => e.row === rowKey);
                if (rowIndex !== null && rowIndex > -1) {
                    newDataSet[rowIndex] = { ...newDataSet[rowIndex], ...row };
                    if (rowError) {
                        newDataSet[rowIndex][rowError.column] = rowError.value;
                    }
                }
                Object.entries(response.data[rowKey]).forEach(([key, value]) => {
                    if (key.includes("target-grid") && rowIndex > -1) {
                        const targetGridArray = value as any[];
                        targetGridArray.forEach((v, i) => {
                            newDataSet[rowIndex][`${key}-${i}`] = v === true ? "" : v;
                        });
                    }
                });
            });
            setDataSet(newDataSet);
            setInitialDataSet(newDataSet);
            setDataToSend([]);
            const rowsToRefresh: string[] = [];
            Object.keys(response.data).forEach(rowKey => {
                const rowIndex = getRowIndexByRow(dataSet, rowKey);
                if (rowIndex !== null && rowIndex > -1) rowsToRefresh.push(rowIndex);
            });

            setRowsToRefresh(rowsToRefresh);
        }
    };

    // handle move students
    const handleMoveStudents = (apiData: ApiData) => {
        if (apiData.responseStatus === HTTP_SUCCESS_ACCEPTED) {
            enqueueSnackbar(t("tracker.grid.studentsMoved"), { variant: "success" });
            navigate(classTrackerRoute);
            navigate(0);
        } else {
            enqueueSnackbar(t("tracker.grid.studentsMovedError"), {
                ...SnackbarErrorOptions,
            });
        }
    };

    const resolveDataChangeError = err => {
        if (err.status === HTTP_FAILED_DEPENDENCY && typeof err?.response?.error === "string") {
            enqueueSnackbar(err?.response?.error, {
                ...SnackbarErrorOptions,
            });
        }
        if (err.status === HTTP_TOO_MANY_REQUESTS) {
            setTimeout(() => setDataToSend(prevState => prevState), 1000);
        }
        if (err.status === HTTP_ERROR_CONFLICT) {
            enqueueSnackbar(t("tracker.maintenance"), {
                ...SnackbarErrorOptions,
            });
            setTimeout(() => navigate("/"), 3000);
        }
        if (bulkEdit) {
            navigate(classTrackerRoute);
            navigate(0);
        }
    };

    // request data change
    const requestDataChange = useCallback(
        (parsedData: undefined | any) => {
            const dataToProcess: any = (parsedData as any) || debouncedDataToSend;

            if (dataToProcess && dataToProcess.length > 0) {
                const convertedDataToSend = convertDataToSend(
                    dataToProcess,
                    gridRelated?.fineGradeEnabled,
                );

                if (classTrackerId && Object.keys(convertedDataToSend).length > 0) {
                    sendTrackerData(
                        classTrackerId,
                        subscriber,
                        convertedDataToSend,
                        dataType === DataTypeTypes.FORECAST,
                        res => {
                            if (bulkEdit) {
                                navigate(classTrackerRoute);
                                navigate(0);
                            } else {
                                handleMultipleUpdateResponse(res.response);
                            }
                            // if (dataType === DataTypeTypes.LIVE && classTrackerId) {
                            //     getClassTrackerValues(classTrackerId, tier as TiersTypes);
                            // } // TODO: WHY
                        },
                        err => resolveDataChangeError(err),
                        tier as TiersTypes,
                    );
                }
            }
        },
        [debouncedDataToSend, dataToSend, classTrackerId],
    );

    const handleSetAllTiers = useCallback(
        (tier: TiersTypes) => {
            if (dataSet) {
                const newDataset = [...dataSet].map(row => {
                    return { ...row, moveStudentToTier: tier };
                });
                setDataSet(newDataset);
                newDataset.forEach(nds => {
                    handleMoveStudentTierTo(nds["student_id"], nds["student_classTrackerId"], tier);
                });
            }
        },
        [dataSet],
    );

    useMount(() => {
        dispatch(ReportActions.getReportTemplateList());
    });

    useEffect(() => {
        setCurrentHeader(gridHeader);
    }, [gridHeader]);

    useEffect(() => {
        setCurrentRelated(gridRelated);
    }, [gridRelated]);

    useEffect(() => {
        const cpClassTrackerValues = { ...classTrackerValues };
        if (tags && tags.length > 0 && cpClassTrackerValues) {
            Object.keys(cpClassTrackerValues).forEach(ctvKey => {
                tags.forEach(tag => {
                    cpClassTrackerValues[ctvKey]["tag-" + tag.id] = 0;
                });
            });
        }
        if (studentTags && Object.keys(studentTags).length > 0) {
            Object.keys(studentTags).forEach(stKey => {
                studentTags[stKey].forEach(tagId => {
                    if (cpClassTrackerValues[stKey]) {
                        cpClassTrackerValues[stKey]["tag-" + tagId] = 1;
                    }
                });
            });
        }

        setCurrentValues(cpClassTrackerValues);
    }, [classTrackerValues, tags, studentTags]);

    useEffect(() => {
        const cpClassTrackerValues =
            dataType === DataTypeTypes.SNAPSHOT && snapshotData?.compare
                ? mergeStudentsToValues(compareValues?.values1, classTrackerStudents)
                : mergeStudentsToValues(classTrackerValues, classTrackerStudents);

        if (links && links.length > 0 && cpClassTrackerValues) {
            Object.keys(cpClassTrackerValues).forEach(ctvKey => {
                links.forEach(link => {
                    cpClassTrackerValues[ctvKey]["link-" + link.id] = 0;
                });
            });
        }
        if (studentLinks && Object.keys(studentLinks).length > 0 && cpClassTrackerValues) {
            Object.keys(studentLinks).forEach(stKey => {
                studentLinks[stKey].forEach(linkId => {
                    if (cpClassTrackerValues[stKey]) {
                        cpClassTrackerValues[stKey]["link-" + linkId] = 1;
                    }
                });
            });
        }

        setCurrentValues(cpClassTrackerValues);
    }, [classTrackerValues, links, studentLinks, compareValues, classTrackerStudents]);

    useEffect(() => {
        if (gridRelated && gridHeader) {
            const newMap = {};
            const atlConfig = gridRelated.atlConfigs;
            const atlColumns = gridHeader?.header?.[0].children.find(
                c => c?.headerConfig?.type === "atl",
            )?.children;

            if (atlColumns && atlConfig && Object.keys(atlColumnsMap).length === 0) {
                atlConfig.forEach(atlC => {
                    if (atlColumns.find(a => a.name === atlC.fieldType)) {
                        newMap[atlC.fieldType] = atlColumns.find(
                            a => a.name === atlC.fieldType,
                        )?.hash;
                    }
                });
                setAtlColumnsMap(newMap);
            }
        }
    }, [gridRelated, gridHeader]);

    useEffect(() => {
        if (!isLoading) {
            if (currentValues && currentHeader) {
                const rowData = mergeStudentColumnData(currentValues, currentHeader, bulkEdit);
                sortArrayBy(rowData, "lastFirstName");
                setDataSet(rowData);
                setInitialDataSet(rowData);
            }
        }
    }, [isLoading, currentValues, currentHeader]);

    useEffect(() => {
        if (isSuccessTrackerSnapshot) {
            if (snapshotValues && snapshotHeader) {
                const rowData = mergeStudentColumnData(snapshotValues, snapshotHeader, false);
                sortArrayBy(rowData, "lastFirstName");
                setDataSet(rowData);
                setInitialDataSet(rowData);
                setCurrentValues(snapshotValues);
            }
        }
    }, [isSuccessTrackerSnapshot, snapshotHeader, snapshotValues]);

    useEffect(() => {
        if (isSuccessTrackerSnapshot && snapshotHeader) {
            setCurrentHeader(snapshotHeader);
        }
    }, [isSuccessTrackerSnapshot, snapshotHeader]);

    useEffect(() => {
        if (isSuccessTrackerSnapshot && snapshotRelatedData) {
            setCurrentRelated(snapshotRelatedData);
        }
    }, [isSuccessTrackerSnapshot, snapshotRelatedData]);

    useEffect(() => {
        if (isSuccessTrackerCompare) {
            if (compareValues && compareHeader) {
                const rowData = mergeStudentColumnData(
                    compareValues.values1,
                    compareHeader,
                    bulkEdit,
                );
                sortArrayBy(rowData, "lastFirstName");
                setDataSet(rowData);
                setInitialDataSet(rowData);
                setCurrentValues(
                    mergeStudentsToValues(compareValues.values1, classTrackerStudents),
                );
            }
        }
    }, [isSuccessTrackerCompare, compareHeader, compareValues, classTrackerStudents]);

    useEffect(() => {
        if (isSuccessTrackerCompare && compareHeader) {
            setCurrentHeader(compareHeader);
        }
    }, [isSuccessTrackerCompare, snapshotHeader]);

    useEffect(() => {
        if (isSuccessTrackerCompare && compareRelatedData) {
            setCurrentRelated(compareRelatedData);
        }
    }, [isSuccessTrackerCompare, compareRelatedData]);

    useEffect(() => {
        if (gridUserSettings?.filters && !gridFilters && location) {
            navigate({
                pathname: location.pathname,
                search: "?" + gridUserSettings.filters,
            });
        }
    }, [gridUserSettings]);

    useEffect(() => {
        if (triggerBulkSave && dataToSend?.length > 0) {
            requestDataChange(dataToSend);
        } else if (triggerBulkSave && dataToSend?.length === 0) {
            setTriggerBulkSave(false);
            navigate(classTrackerRoute);
            navigate(0);
        }
    }, [dataToSend, triggerBulkSave]);

    // tag
    useEffect(() => {
        if (debouncedTagsToSend?.length > 0) {
            const rows: ClassTrackerStudentTagRows = {};
            debouncedTagsToSend.forEach((ddts: TagsToSendType) => {
                rows[ddts.row] = [
                    ...(rows[ddts.row] || []).concat({
                        tagId: parseInt(ddts.column.replace("tag-", "")),
                        enabled: ddts.value.value !== undefined ? !!ddts.value.value : !!ddts.value,
                    }),
                ];
            });

            saveTags({ id: classTrackerId, rows });
        }
    }, [debouncedTagsToSend]);

    // links
    useEffect(() => {
        if (debouncedLinksToSend?.length > 0) {
            const rows: ClassTrackerStudentLinkRows = {};
            debouncedLinksToSend.forEach((ddts: LinksToSendType) => {
                rows[ddts.row] = [
                    ...(rows[ddts.row] || []).concat({
                        reportLinkId: parseInt(ddts.column.replace("link-", "")),
                        enabled: ddts.value.value !== undefined ? !!ddts.value.value : !!ddts.value,
                    }),
                ];
            });

            saveLinks({ id: classTrackerId, rows });
        }
    }, [debouncedLinksToSend]);

    useEffect(() => {
        if (gridFilters !== null) {
            const filteredNew = initialDataSet?.filter(initialValues => {
                return filterRowFromFilters(
                    gridFilters,
                    initialValues,
                    atlColumnsMap,
                    gridHeader,
                    gridRelated?.fineGradeEnabled,
                );
            });

            if (!filteredNew) {
                setDataSet([]);
            } else {
                setDataSet(filteredNew);
            }
        }
    }, [gridFilters, initialDataSet]);

    useEffect(() => {
        return () => {
            clearStudents();
            closeSnackbar();
            setSnapshotData(null);
            removeCompareValues();
        };
    }, []);

    // save move to
    useEffect(() => {
        if (savingMoveTo) {
            const convertedValues: {
                student: { id: number };
                classTracker?: { id: number };
                tier?: TiersTypes;
            }[] = [];
            moveStudentsList?.forEach(ds => {
                if (
                    (ds.moveToId || ds.tier) &&
                    !convertedValues.find(cv => cv.student.id === ds.studentId)
                ) {
                    convertedValues.push({
                        student: { id: parseInt(`${ds.studentId}`) },
                        classTracker: ds.moveToId ? { id: parseInt(`${ds.moveToId}`) } : undefined,
                        tier: ds.tier || (tier as TiersTypes),
                    });
                }
            });

            if (convertedValues.length === 0) {
                navigate(classTrackerRoute);
                navigate(0);
            } else {
                if (classDetails.classTrackerGroup?.id) {
                    setSavingMoveTo(false);
                    moveStudentsToClass(classDetails.classTrackerGroup?.id, convertedValues);
                }
            }
        }
    }, [moveStudentsList, savingMoveTo]);

    // handle expand save to user settings
    useEffect(() => {
        if (deboundedExpanded && Object.keys(deboundedExpanded).length > 0) {
            let newUserSettings: any = { ...gridUserSettings, expandedColumns };

            if (tier && tier !== "-") {
                newUserSettings = {
                    ...gridUserSettings,
                    expandedColumns,
                    ["expandedColumns" + tier]: { ...expandedColumns },
                };
            }
            if (
                JSON.stringify(newUserSettings) !== JSON.stringify(gridUserSettings) &&
                classTrackerId
            ) {
                setClassTrackerUserSettings(newUserSettings);
            }
        }
    }, [deboundedExpanded]);

    // debounced new data to send effect
    useEffect(() => {
        if (!bulkEdit && !moveStudents) {
            requestDataChange(debouncedDataToSend);
        }
    }, [debouncedDataToSend, classTrackerId]);

    // main data getter for view modes
    useEffect(() => {
        setIsLoading(true);
        getStudentsForClassTracker(classTrackerId, tier as TiersTypes);
        if (
            prevDataType &&
            prevDataType === DataTypeTypes.SNAPSHOT &&
            dataType !== DataTypeTypes.SNAPSHOT
        ) {
            setSnapshotData(null);
        }
        if (
            prevDataType &&
            prevDataType !== DataTypeTypes.LIVE &&
            dataType === DataTypeTypes.LIVE &&
            classTrackerId
        ) {
            getClassTrackerHeader(classTrackerId, tier as TiersTypes);
        }
        if (dataType === DataTypeTypes.LIVE && classTrackerId) {
            getClassTrackerValues(classTrackerId, tier as TiersTypes);
        }
        if (
            classTrackerId &&
            ((prevDataType &&
                prevDataType !== DataTypeTypes.FORECAST &&
                dataType === DataTypeTypes.FORECAST) ||
                (prevTier && prevTier !== tier))
        ) {
            getForecastClassTrackerValues(classTrackerId, tier as TiersTypes);
        }
    }, [dataType, tier]);

    // handle move students
    useResponse(() => handleMoveStudents(apiMoveStudents), apiMoveStudents);

    useEffect(() => {
        let timeout;
        if (isGridDataFetched && isLoading) {
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isGridDataFetched, isLoading]);

    useEffect(() => {
        let interval;
        if ((isAsyncProcessing || isGradeVBoundaryProcessing) && cohortId) {
            getLastUpdatedStatuses(cohortId);
            interval = setInterval(() => getLastUpdatedStatuses(cohortId), 3000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isAsyncProcessing, isGradeVBoundaryProcessing]);

    useEffect(() => {
        // refresh rows when compare values arrive
        if (compareValues) {
            const rowsToRefresh: string[] = [];
            Object.keys(compareValues?.values1).forEach(rowKey => {
                const rowIndex = getRowIndexByRow(dataSet, rowKey);
                if (rowIndex !== null && rowIndex > -1) rowsToRefresh.push(rowIndex);
            });

            setRowsToRefresh(rowsToRefresh);
        }
    }, [compareValues]);

    useEffect(() => {
        // to refactor last tracker update (sugrestion: websockets)
        const trackerStatus = lastTrackerUpdatedStatuses.find(ct => ct.id === classTrackerId);

        if (trackerStatus && trackerStatus.status === TrackerStatus.LIVE && classTrackerId) {
            if (isAsyncProcessing) {
                getForecastClassTrackerValues(classTrackerId, tier as TiersTypes);
                setIsAsyncProcessing(false);
                setIsBlocked(false);
            }
            if (isGradeVBoundaryProcessing) {
                setIsAsyncProcessing(false);
                setIsGradeBoundaryProcessing(false);
                navigate(0);
            }
        }
    }, [lastTrackerUpdatedStatuses, classTrackerId, isAsyncProcessing, isGradeVBoundaryProcessing]);

    useEffect(() => {
        setFiltersVisible(false);
        setToolsVisible(false);
    }, [bulkEdit, moveStudents, dataType]);

    // global context for tracker
    const trackerContext: TrackerContext = {
        cohortId,
        classTrackerId,
        classTrackerGroupId: classDetails?.classTrackerGroup?.id || null,
        dataToSend,
        gridUserSettings,
        filtersVisible,
        toolsVisible,
        expanded:
            tier && tier !== "-" && gridUserSettings
                ? gridUserSettings["expandedColumns" + tier]
                : gridUserSettings?.expandedColumns || {},
        initialExpandSettings: gridUserSettings?.expandedColumns ? false : true,
        compareValues: compareValues?.values2 || null,
        flatHeader: getFlatHeaderStructure(currentHeader),
        relatedData: currentRelated,
        headerHeight: calculateHeaderHeight(currentHeader),
        dataType,
        bulkEdit: !!bulkEdit,
        moveStudents: !!moveStudents,
        userProfile,
        classDetails,
        columns: currentHeader?.columns,
        allClassTrackers,
        snapshotData,
        isBlocked,
        isGradeVBoundaryProcessing,
        tier: tier as TiersTypes,
        gridFilters,
        handleBulkEditSave: () => setTriggerBulkSave(true),
        handleFiltersChange,
        handleBlocked: locked => (locked === false ? setIsBlocked(false) : setIsBlocked(true)),
        handleDataAsyncLoaded: () => setIsAsyncProcessing(true),
        handleGradeBoundariesProcessing: () => setIsGradeBoundaryProcessing(true),
        handleConfigureColumnsChange,
        handleSetAllTiers,
        handleMoveStudentTo,
        handleMoveStudentTierTo,
        requestDataChange,
        handleOnSaveMoveClick,
        handlePixlSeriesChange: (selectedSeriesId, pixlData) => {
            setClassTrackerUserSettings({
                selectedSeriesId,
            });
            setPixlData(pixlData);
        },
        handleTagsVisible: visible =>
            setClassTrackerUserSettings({
                tagsVisible: visible,
            }),
        tagsVisible: gridUserSettings?.tagsVisible,
        selectedLinks: gridUserSettings?.selectedLinks || [],
        tags,
        handleTagsLinksAllColumnSelect,
        linksVisible: gridUserSettings?.linksVisible,
        handleLinksVisible: visible =>
            setClassTrackerUserSettings({
                linksVisible: visible,
            }),
        links,
        pixlData,
        pixlSeriesList,
    };

    return (
        <Box component="main" height="100vh" pt={0} pb={3} display="flex" flexDirection="column">
            <TrackerHeader
                tier={tier as TiersTypes}
                classDetails={classDetails}
                dataType={dataType}
                hasForecast={true}
                bulkEdit={!!bulkEdit}
                handleDataTypeChange={dataType => setDataType(dataType)}
                handleFiltersVisible={isChecked => {
                    setFiltersVisible(isChecked && !bulkEdit);
                    if (toolsVisible && isChecked) {
                        setToolsVisible(!isChecked || !!bulkEdit);
                    }
                }}
                handleToolsVisible={isChecked => {
                    setToolsVisible(isChecked);
                    if (filtersVisible && isChecked) {
                        setFiltersVisible(!isChecked);
                    }
                }}
                isLoadingGrid={isLoading}
                lastFilterOptions={lastFilterOptions || undefined}
                toolsVisible={toolsVisible}
                filtersVisible={filtersVisible}
            />
            <Box
                className="ag-theme-alpine classTrackerGrid"
                flex={1}
                width={isLoading ? 0 : "100%"}
                borderLeft={`10px solid ${getTrackerBorderColor(dataType)}`}
            >
                {classDetails && (
                    <TrackerGrid
                        isLoading={isLoading}
                        rowsToRefresh={rowsToRefresh}
                        rowsToRedraw={rowsToRedraw}
                        gridHeader={currentHeader}
                        gridRelated={currentRelated}
                        gridValues={currentValues}
                        dataSet={dataSet}
                        initialDataSet={initialDataSet}
                        isBlocked={isBlocked}
                        isSyncedClass={classDetails?.groupCallStatus === "active"}
                        trackerContext={trackerContext}
                        handleValueChange={handleValueChange}
                        handleChangeExpandedColumns={expanded => setExpandedColumns(expanded)}
                        handleIgnoreTimestamps={dataSet => {
                            setDataSet(dataSet);
                            setInitialDataSet(dataSet);
                        }}
                    />
                )}
            </Box>
            <PromptDialog
                open={!!allTagsLinksWaiting?.colId}
                onClose={confirmed => {
                    if (confirmed) {
                        const newValues = [...dataSet].map(fv => {
                            if (allTagsLinksWaiting?.type === "tag") {
                                setTagsToSend(prevState =>
                                    prevState.concat({
                                        row: fv.row as string,
                                        column: allTagsLinksWaiting?.colId,
                                        value: allTagsLinksWaiting?.value,
                                    }),
                                );
                            } else {
                                setLinksToSend(prevState =>
                                    prevState.concat({
                                        row: fv.row as string,
                                        column: allTagsLinksWaiting?.colId,
                                        value: allTagsLinksWaiting?.value,
                                    }),
                                );
                            }

                            return {
                                ...fv,
                                [allTagsLinksWaiting?.colId]: {
                                    value: allTagsLinksWaiting?.value,
                                    whileUpdate: true,
                                },
                            };
                        });
                        setDataSet(newValues);
                    }

                    setAllTagsLinksWaiting(null);
                }}
            >
                {t("tracker.grid.allTagsWaiting")}
            </PromptDialog>
        </Box>
    );
};

export default ClassTrackerContainer;
