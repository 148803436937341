import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CommonActions, CommonActionTypes } from "../actions";
import { OrmTopicActions } from "src/orm/models/Topic/actions";

const topicListSuccessEpic = (action$: ActionsObservable<CommonActions>) =>
    action$.pipe(
        ofType(CommonActionTypes.GET_TOPIC_LIST_SUCCESS),
        map((action: ReturnType<typeof CommonActions.getTopicListSuccess>) => action.payload),
        mergeMap(payload =>
            of(
                OrmTopicActions.bulkImport({
                    data: payload.response,
                    specificationId: payload.request.payload.params.specification,
                }),
            ),
        ),
    );

export const topicEpics = combineEpics(topicListSuccessEpic);
