import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmReportTemplateActions, OrmReportTemplateActionTypes } from "./actions";

export const reportTemplateReducer: ModelReducer = {
    [OrmReportTemplateActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmReportTemplateActions.bulkImport>,
        session,
    ) => {
        const { ReportTemplate } = session;
        ReportTemplate.all().delete();
        action.payload.forEach(ct => ReportTemplate.create(ormToRef(ct, {})));
    },
    [OrmReportTemplateActionTypes.UPSERT]: (
        action: ReturnType<typeof OrmReportTemplateActions.upsert>,
        session,
    ) => {
        const { ReportTemplate } = session;
        ReportTemplate.upsert(
            ormToRef(action.payload, {
                defaults: {},
            }),
        );
    },
    [OrmReportTemplateActionTypes.CLEAR]: (
        action: ReturnType<typeof OrmReportTemplateActions.clear>,
        session,
    ) => {
        const { ReportTemplate } = session;
        ReportTemplate.all().delete();
    },
};
