import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { PagedList } from "src/types";
import { OrmClassTrackerGroup } from "src/orm/models/ClassTrackerGroup";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_LIST_QUERY = "classListQuery";

export interface ClassTrackerListFilters {
    subjectAreaId?: number;
    qualificationId?: number;
    yearGroup?: number;
    page?: number;
    perPage?: number;
}

export const useClassListByCohort = (cohortId: number, filters: ClassTrackerListFilters) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            CLASS_LIST_QUERY,
            schoolAccountId,
            cohortId,
            filters.subjectAreaId,
            filters.qualificationId,
            filters.yearGroup,
        ],
        queryFn: async (): Promise<PagedList<OrmClassTrackerGroup> | null> => {
            // TODO class tracker group
            if (cohortId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker/by-cohort/${cohortId}/`,
                    {
                        params: { ...filters },
                    },
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
