import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiltersShape } from "src/modules/common/components/Grid/GridStudentFilter";
import { RelatedDataAtlConfig } from "src/modules/tracker/dto/TrackerRelated";

interface OwnProps {
    atlConfigs?: RelatedDataAtlConfig[];
    handleFiltersChange: (filter: FiltersShape) => void;
}

const AtlFilter: FC<OwnProps> = ({ handleFiltersChange, atlConfigs }) => {
    const { t } = useTranslation();
    const [atlColumn, setAtlColumn] = useState<string>("");
    const [atlFrom, setAtlFrom] = useState<string>("");
    const [atlTo, setAtlTo] = useState<string>("");

    return (
        <>
            <FormControl fullWidth margin="normal">
                <InputLabel shrink>{t("common.filterByAtl")}</InputLabel>
                <Select
                    value={atlColumn}
                    displayEmpty
                    label={t("common.filterByAtl")}
                    onChange={e => {
                        setAtlColumn(e.target.value as string);
                    }}
                >
                    <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                    {atlConfigs.map(option => (
                        <MenuItem key={option.fieldType} value={option.fieldType}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink>{t("common.filterByAtlMin")}</InputLabel>
                    <Select
                        value={atlFrom}
                        displayEmpty
                        disabled={!atlColumn}
                        label={t("common.filterByAtlMin")}
                        onChange={e => setAtlFrom(e.target.value as string)}
                    >
                        <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                        {atlConfigs
                            .find(atlConfig => atlConfig.fieldType === atlColumn)
                            ?.values.map((atlValue, i) => (
                                <MenuItem
                                    key={i}
                                    value={
                                        atlConfigs.find(
                                            atlConfig => atlConfig.fieldType === atlColumn,
                                        )?.values.length - i
                                    }
                                >
                                    {atlValue}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>
            <Box>
                <FormControl fullWidth margin="normal">
                    <InputLabel shrink>{t("common.filterByAtlMax")}</InputLabel>
                    <Select
                        value={atlTo}
                        displayEmpty
                        disabled={!atlColumn}
                        label={t("common.filterByAtlMax")}
                        onChange={e => setAtlTo(e.target.value as string)}
                    >
                        <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                        {atlConfigs
                            .find(atlConfig => atlConfig.fieldType === atlColumn)
                            ?.values.map((atlValue, i) => (
                                <MenuItem
                                    key={i}
                                    value={
                                        atlConfigs.find(
                                            atlConfig => atlConfig.fieldType === atlColumn,
                                        )?.values.length - i
                                    }
                                >
                                    {atlValue}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>
            <Box pt={2}>
                <Button
                    color="primary"
                    disabled={!!(atlColumn === "")}
                    onClick={() => {
                        handleFiltersChange({
                            ["atl-" + atlColumn]: {
                                type: "atl",
                                attribute: "atl-" + atlColumn,
                                attributeDisplay: `${
                                    atlConfigs.find(o => o && o.fieldType === atlColumn)?.name
                                }`,
                                values: [{ value: atlFrom, valueTo: atlTo, valueDisplay: "" }],
                            },
                        });
                        setAtlColumn("");
                        setAtlFrom("");
                        setAtlTo("");
                    }}
                >
                    {t("common.filterAdd")}
                </Button>
            </Box>
        </>
    );
};

export default AtlFilter;
