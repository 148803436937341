import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const MIGRATE_INFO = "migrateInfo";

export interface MigrationInfoObject {
    pendingClassTrackers?: {
        id: number;
        name: string;
    }[];
    pendingGroupCallGroups?: {
        id: number;
        name: string;
    }[];
    subjectArea: {
        parentName: string;
        colour: string;
        id: number;
        name: string;
    };
    qualification: {
        id: number;
        name: string;
    };
    specification: {
        parentName: string;
        id: number;
        name: string;
    };
    discontinued: boolean;
    hasSubscription: boolean;
    existingClassTrackers?: {
        id: number;
        name: string;
        groupCallStatus: string;
    }[];
}

export const useMigrateInfo = (yearGroup: number | null, isSync: boolean) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [MIGRATE_INFO, schoolAccountId, yearGroup],
        queryFn: async (): Promise<MigrationInfoObject[] | null> => {
            if (yearGroup !== null) {
                const { data } = await axiosInstance.get(
                    isSync
                        ? `school/${getSchoolAccountId()}/migrate-classes/migrate-and-sync/${yearGroup}/info/`
                        : `school/${getSchoolAccountId()}/migrate-classes/migrate/${yearGroup}/info/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
