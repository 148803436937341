import {
    Box,
    Typography,
    Grid,
    TextField,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApiStatus } from "src/api/constants";
import { HTTP_ERROR_CONFLICT, HTTP_NO_CONTENT } from "src/config/globals";
import { useResponse } from "src/hooks/useResponse";
import { AppState } from "src/store/reducers";
import { useUserReportValidation } from "../../hooks/useUserReportValidation";
import ReportActionsContainer from "./ReportActionsContainer";
import { ReportActions } from "../../store/actions";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { ListObject } from "src/forms/types";
import SelectClassTrackerGroupsModal from "../SnapshotReport/SelectClassTrackerGroupsModal";
import { ClassTrackerListFilters } from "src/modules/class/hooks/useClassList";
import { ClassActions } from "src/modules/class/store/actions";
import { classTrackerGroupSelector } from "src/modules/class/selectors/ClassTrackerGroupSelectors";
import { useCohortSnapshotList } from "src/modules/school/hooks/query/CohortSnapshot/useCohortSnapshotList";

const dispatchActions = (dispatch: Dispatch) => ({
    getClassTrackerGroupList: (cohort: number, filters?: ClassTrackerListFilters) => {
        dispatch(ClassActions.getClassTrackerGroupList(cohort, filters));
    },
    getStudentReportByYearGroup: (
        cohort,
        yearGroup,
        onlySummary,
        reportTemplate,
        classTrackerGroups,
        schoolSnapshot,
    ) => {
        dispatch(
            ReportActions.getStudentReportByYearGroup(
                cohort,
                yearGroup,
                onlySummary,
                reportTemplate,
                classTrackerGroups && classTrackerGroups.length > 0
                    ? classTrackerGroups.map(ctg => ({ id: ctg.id }))
                    : null,
                schoolSnapshot ? { id: schoolSnapshot } : null,
            ),
        );
    },
    getStudentCountForYearGroup: (cohort, yearGroup) => {
        dispatch(ReportActions.getStudentCountForYearGroup(cohort, yearGroup));
    },
});

const StudentReportByYearGroupTab = ({ cohort, yearGroup, reportTemplates }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { getStudentReportByYearGroup, getStudentCountForYearGroup, getClassTrackerGroupList } =
        dispatchActions(dispatch);
    const [selectedReportTemplate, setSelectedReportTemplate] = useState<number>(0);
    const [locked, setLocked] = useState(false);
    const [selectedClassTrackerGroups, setSelectedClassTrackerGroups] = useState<
        ListObject<number>[] | null
    >(null);
    const [selectedGroupsRadio, setSelectedGroupsRadio] = useState<"all" | "selected">("all");
    const [selectedSnapshot, setSelectedSnapshot] = useState<number | null>(0);
    const [groupModalOpen, setGroupModalOpen] = useState<boolean>(false);
    const { data: snapshots, refetch: refetchSnapshots } = useCohortSnapshotList(cohort, {
        onlyCreated: true,
    });
    const { apiValidation, checkValidation } = useUserReportValidation();
    const { apiReport, studentCount, classTrackerGroups } = useSelector(
        (state: AppState) => ({
            apiReport: state.api.report.getStudentReportByYearGroup,
            studentCount: state.report.yearGroupReportStudentNumber,
            classTrackerGroups: classTrackerGroupSelector(state, ct => {
                return ct.cohortId === cohort && ct.yearGroup !== null && ct.yearGroup !== undefined
                    ? ct.yearGroup === parseInt(yearGroup)
                    : true;
            }),
        }),
        shallowEqual,
    );

    useEffect(() => {
        checkValidation();
        getStudentCountForYearGroup(cohort, yearGroup);
        getClassTrackerGroupList(cohort, { yearGroup, page: 1, perPage: 1000 });
        refetchSnapshots();
    }, [cohort, yearGroup]);

    useEffect(() => {
        if (reportTemplates && reportTemplates.length > 0 && selectedReportTemplate === 0) {
            const defaultTemplate = reportTemplates.find(rt => rt.isDefault);
            setSelectedReportTemplate(defaultTemplate ? defaultTemplate.id : reportTemplates[0].id);
        }
    }, [reportTemplates]);

    const requestReport = (onlySummary: boolean) => {
        if (cohort && yearGroup > -1) {
            getStudentReportByYearGroup(
                cohort,
                yearGroup,
                onlySummary,
                selectedReportTemplate,
                selectedGroupsRadio === "all" ? null : selectedClassTrackerGroups || null,
                selectedSnapshot,
            );
        }
    };

    useEffect(() => {
        if (apiValidation.status === ApiStatus.ERROR && apiReport.status === ApiStatus.ERROR) {
            setLocked(true);
        }
        if (
            apiValidation.status === ApiStatus.SUCCESS &&
            apiValidation.responseStatus === HTTP_NO_CONTENT &&
            apiReport.status === ApiStatus.ERROR
        ) {
            setLocked(false);
        }
    }, [apiReport, apiValidation]);

    useEffect(() => {
        if (selectedGroupsRadio === "selected") setGroupModalOpen(true);
    }, [selectedGroupsRadio]);

    useResponse(() => {
        if (
            apiReport.status === ApiStatus.SUCCESS &&
            apiReport.responseStatus === HTTP_NO_CONTENT
        ) {
            setLocked(false);
            enqueueSnackbar(t("report.studentReport.byStudent.requestSuccess"), {
                variant: "success",
            });
        }

        if (apiReport.status === ApiStatus.ERROR) {
            if (apiReport.responseStatus === HTTP_ERROR_CONFLICT) {
                enqueueSnackbar(t("report.studentReport.byStudent.requestErrorConflict"), {
                    ...SnackbarErrorOptions,
                });
            }
            checkValidation();
        }
    }, apiReport);
    const filteredSnapshots = snapshots?.filter(({ isCreated }) => isCreated);
    return (
        <Box pr="9.5%">
            <Typography variant="h6" gutterBottom>
                {t("report.studentReport.byYearGroup.header")}
            </Typography>
            <Box sx={{ width: "50%" }}>
                <Grid container spacing={4}>
                    <Grid item sm={12}>
                        <Typography>
                            {`${t("report.studentReport.byYearGroup.downloadHint")} ${
                                yearGroup === 0
                                    ? t("common.yg0")
                                    : t("report.studentReport.byYearGroup.yearGroup") +
                                      " " +
                                      yearGroup
                            }`}
                        </Typography>
                    </Grid>{" "}
                    {filteredSnapshots?.length > 0 && (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.byStudent.source")}
                                onChange={event =>
                                    setSelectedSnapshot(parseInt(`${event.target.value}`))
                                }
                                fullWidth
                                value={selectedSnapshot}
                                select
                            >
                                <MenuItem key={0} value={0}>
                                    {t("report.studentReport.byStudent.liveTracking")}
                                </MenuItem>
                                {filteredSnapshots.map(s => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                    <Grid item sm={8}>
                        <RadioGroup
                            defaultValue="member"
                            name="radio-buttons-group"
                            onChange={() =>
                                setSelectedGroupsRadio(
                                    selectedGroupsRadio === "all" ? "selected" : "all",
                                )
                            }
                        >
                            <FormControlLabel
                                value="all"
                                control={<Radio checked={selectedGroupsRadio === "all"} />}
                                label={t("report.studentReport.byYearGroup.allTrackerGroups")}
                            />
                            <FormControlLabel
                                value="selected"
                                control={<Radio checked={selectedGroupsRadio === "selected"} />}
                                label={t("report.studentReport.byYearGroup.selectedTrackerGroups")}
                            />
                        </RadioGroup>
                        {selectedGroupsRadio === "selected" &&
                        selectedClassTrackerGroups?.length > 0 ? (
                            <>
                                <Typography>{`${t(
                                    "report.snapshot.create.currentlySelected",
                                ).replace(
                                    ":selected",
                                    `${selectedClassTrackerGroups.length}`,
                                )}`}</Typography>
                                <Button
                                    onClick={() => {
                                        setSelectedClassTrackerGroups(null);
                                        setGroupModalOpen(true);
                                    }}
                                >
                                    {t("report.studentReport.byYearGroup.chooseAgain")}
                                </Button>
                            </>
                        ) : (
                            <></>
                        )}
                        {groupModalOpen && (
                            <SelectClassTrackerGroupsModal
                                open={groupModalOpen}
                                classTrackerGroups={classTrackerGroups}
                                handleSelectGroups={groups => {
                                    setSelectedClassTrackerGroups(groups);
                                    setGroupModalOpen(false);
                                }}
                                handleClose={() => {
                                    setGroupModalOpen(false);
                                    setSelectedClassTrackerGroups(null);
                                    setSelectedGroupsRadio("all");
                                }}
                            />
                        )}
                    </Grid>
                    {reportTemplates && reportTemplates.length > 0 ? (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.tabs.reportTemplate")}
                                value={selectedReportTemplate}
                                onChange={e => {
                                    setSelectedReportTemplate(parseInt(e.target.value));
                                }}
                                select
                            >
                                {reportTemplates.map(s => {
                                    return (
                                        <MenuItem value={s.id} key={s.id}>
                                            {s.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid item sm={12}>
                        <ReportActionsContainer
                            studentsCount={studentCount}
                            refreshReportHandler={() => checkValidation()}
                            requestReportHandler={(onlySummary: boolean) =>
                                requestReport(onlySummary)
                            }
                            locked={locked}
                            showOnlySummaryCheckbox={true}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default StudentReportByYearGroupTab;
