import { MultipleClassesRequest } from "../api/Classes/multipleClasses";
import { ClassActions, ClassActionTypes } from "./actions";
import { ClassTrackerFilterResponse } from "../api/Classes/filters";
import { ClassTrackerListFilters } from "../api/Classes/getClassTrackers";
import { LastUpdatedClassTrackerStatuses } from "../api/Classes/getLastUpdated";
import { ImportStudentsDatabaseError } from "src/modules/student/hooks/useImportMutation";

export interface ClassState {
    currentUploadId: number | null;
    studentImportDatabaseError: ImportStudentsDatabaseError[] | null;
    studentImportDatabaseErrorFile: MultipleClassesRequest | null;
    tmpDemoClassId: number | null;
    classTrackerFilters: ClassTrackerFilterResponse | null;
    classListHasNextPage: boolean;
    classTrackerLastFilteringOptions: { cohort: number; filters?: ClassTrackerListFilters } | null;
    lastUpdatedClassTrackers: LastUpdatedClassTrackerStatuses;
}

export const initialState: ClassState = {
    currentUploadId: null,
    studentImportDatabaseError: null,
    studentImportDatabaseErrorFile: null,
    tmpDemoClassId: null,
    classTrackerFilters: null,
    classListHasNextPage: true,
    classTrackerLastFilteringOptions: null,
    lastUpdatedClassTrackers: [],
};

export const reducer = (state: ClassState = initialState, action: ClassActions): ClassState => {
    switch (action.type) {
        case ClassActionTypes.SET_LAST_FILTERING_OPTIONS:
            return {
                ...state,
                classTrackerLastFilteringOptions: action.payload,
            };
        case ClassActionTypes.GET_LAST_UPDATED_CLASS_TRACKERS_SUCCESS:
            return {
                ...state,
                lastUpdatedClassTrackers: action.payload.response,
            };
        case ClassActionTypes.CLEAR_LAST_UPDATED_CLASS_TRACKERS_SUCCESS:
            return {
                ...state,
                lastUpdatedClassTrackers: [],
            };
        case ClassActionTypes.SET_CURRENT_UPLOAD_ID:
            return {
                ...state,
                currentUploadId: action.payload,
            };
        case ClassActionTypes.SET_PAGER_RESET:
            return {
                ...state,
                classListHasNextPage: true,
            };
        case ClassActionTypes.GET_CLASS_TRACKER_GROUP_LIST_SUCCESS:
            return {
                ...state,
                classListHasNextPage: action.payload.response.nextPage,
            };
        case ClassActionTypes.GET_CLASS_TRACKER_FILTERS_SUCCESS:
            return {
                ...state,
                classTrackerFilters: action.payload.response,
            };
        case ClassActionTypes.CREATE_DEMO_CLASS_SUCCESS:
            return {
                ...state,
                tmpDemoClassId: action.payload.response.id,
            };
        case ClassActionTypes.CLEAR_TMP_DEMO_CLASS_ID:
            return {
                ...state,
                tmpDemoClassId: null,
            };
        case ClassActionTypes.CLEAR_CURRENT_UPLOAD_ID:
            return {
                ...state,
                currentUploadId: null,
            };
        case ClassActionTypes.SET_IMPORT_DATABASE_ERROR:
            return {
                ...state,
                studentImportDatabaseError: action.payload,
            };
        case ClassActionTypes.SET_IMPORT_DATABASE_ERROR_FILE:
            return {
                ...state,
                studentImportDatabaseErrorFile: action.payload,
            };
        case ClassActionTypes.CLEAR_IMPORT_DATABASE_ERROR:
            return {
                ...state,
                studentImportDatabaseError: null,
                studentImportDatabaseErrorFile: null,
            };
        default:
            return state;
    }
};
