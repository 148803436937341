import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { SubjectAnalysisClassDataResponse } from "./useSubjectAnalysisClassData";
import { getSchoolAccountId } from "src/services/url";
export const SUBJECT_ANALYSIS_CLASS_SNAPSHOT_DATA_QUERY = "subjectAnalysisClassSnapshotDataQuery";

export const useSubjectAnalysisClassSnapshotData = (
    cohort: number,
    yearGroup: number,
    specification: number,
    snapshot: number,
    classTrackers?: number[],
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            SUBJECT_ANALYSIS_CLASS_SNAPSHOT_DATA_QUERY,
            schoolAccountId,
            cohort,
            yearGroup,
            snapshot,
            specification,
        ],
        queryFn: async (): Promise<SubjectAnalysisClassDataResponse[]> => {
            if (snapshot) {
                const filteredClassTrackers =
                    classTrackers &&
                    classTrackers.length > 0 &&
                    classTrackers.filter(ct => ct !== 0);
                const classTrackerIds =
                    filteredClassTrackers && filteredClassTrackers.length > 0
                        ? filteredClassTrackers.join(",")
                        : undefined;
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/analysis/subject-analysis/${cohort}/${yearGroup}/snapshot/${snapshot}/specifications/${specification}/classes/values/`,
                    { params: { classTrackerIds } },
                );
                return data || [];
            }
            return [];
        },
    });
};
