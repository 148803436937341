import i18next from "i18next";
// date-fns does not support the iso 8601 durations :( below is a workaround
export const convertIsoPeriodToString = (isoDate: string) => {
    const converted = isoDate.replace("P", "");
    const period = converted.slice(-1);
    const length = converted.replace(period, "");

    switch (period) {
        case "Y":
            return length === "1"
                ? `${length} ${i18next.t("common.year")}`
                : `${length} ${i18next.t("common.years")}`;
        case "M":
            return length === "1"
                ? `${length} ${i18next.t("common.month")}`
                : `${length} ${i18next.t("common.months")}`;
        case "W":
            return length === "1"
                ? `${length} ${i18next.t("common.week")}`
                : `${length} ${i18next.t("common.weeks")}`;
        case "D":
            return length === "1"
                ? `${length} ${i18next.t("common.day")}`
                : `${length} ${i18next.t("common.days")}`;
    }

    return period;
};
