import { Box } from "@mui/material";
import {
    mdiCheckboxMultipleBlankOutline,
    mdiDownload,
    mdiFinance,
    mdiInboxMultipleOutline,
    mdiLink,
} from "@mdi/js";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionsTableButton from "src/components/ActionTableButton";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import Icon from "src/components/Icon";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { OrmQualification } from "src/orm/models/Qualification";
import { OrmSpecification } from "src/orm/models/Specification";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import {
    ROUTE_ANALYSIS_SUBJECT_ANALYSIS,
    ROUTE_CLASS_BULK_EDIT,
    ROUTE_CLASS_GROUP_CALL_CONVERT,
    ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER_GROUP,
    ROUTE_TRACKER_CLASS_TRACKER_GROUP,
} from "src/routes";
import TrackingHeaderTrackerPath from "./TrackingHeaderTrackerPath";
import { useLocation } from "react-router";
import { StudentReportBySubjectRequest } from "src/modules/report/api/StudentReport/bySubject";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import PromptDialog from "src/forms/PromptDialog";
import { generateTrackerUrl } from "src/services/url";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

interface OwnProps {
    subjectArea: OrmSubjectArea | null;
    qualification: OrmQualification | null;
    specification: OrmSpecification | null;
    yearGroup: number | null;
    bulkEditTrackerId?: number | null;
    cohortId: number | null;
    classTrackerGroupId?: number | null;
    isDisabled?: boolean;
    hideYearGroupBtn?: boolean;
    handleUnsyncGroup?: (groupId: number) => void;
    hasGroupCallClassTrackers?: boolean;
    handleDownloadReport?: (
        cohort: number,
        yearGroup: number,
        values: StudentReportBySubjectRequest,
    ) => void;
    tiers?: TiersTypes[];
    archived?: boolean;
    showSubjectAnalysis?: boolean;
}

const TrackingHeader: FC<OwnProps> = ({
    subjectArea,
    qualification,
    specification,
    yearGroup,
    cohortId,
    classTrackerGroupId,
    isDisabled,
    hideYearGroupBtn,
    bulkEditTrackerId,
    handleDownloadReport,
    hasGroupCallClassTrackers,
    handleUnsyncGroup,
    tiers,
    archived,
    showSubjectAnalysis,
}) => {
    const { attachSchoolId } = useSchoolNavigate();
    const { t } = useTranslation();
    const { data: accountInfo } = useAccountInfo();
    const location = useLocation();
    const {
        subjectArea: sSubject,
        qualification: sQual,
        yearGroup: sYearGroup,
    } = Object.fromEntries(new URLSearchParams(location.search));
    const [unsynchroniseOpen, setUnsynchroniseOpen] = useState<boolean>(false);
    const [unsyncId, setUnsyncId] = useState<number | null>(null);

    const handleUnsynchroniseOpen = confirm => {
        if (confirm && unsyncId && handleUnsyncGroup) {
            handleUnsyncGroup(unsyncId);
        }
        setUnsyncId(null);
        setUnsynchroniseOpen(false);
    };

    return (
        <Box display="flex" justifyContent="space-between" flex={1} className="trackingHeader">
            <TrackingHeaderTrackerPath
                subjectArea={subjectArea}
                specification={specification}
                qualification={qualification}
                yearGroup={yearGroup}
            />
            <Box display="flex" alignItems="center" height={24}>
                {cohortId && classTrackerGroupId && (
                    <ActionsTableButton
                        actions={[
                            ...(tiers && tiers.length > 0
                                ? tiers.map(tier =>
                                      getMenuActionProps("openYearGroup", {
                                          text: t("class.list.openYearGroupOverview" + tier),
                                          icon: <Icon path={mdiCheckboxMultipleBlankOutline} />,
                                          showAction: () =>
                                              !hideYearGroupBtn &&
                                              !isDisabled &&
                                              !!classTrackerGroupId,
                                          linkCallback: () =>
                                              generateTrackerUrl(
                                                  archived
                                                      ? attachSchoolId(
                                                            ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER_GROUP,
                                                        )
                                                      : attachSchoolId(
                                                            ROUTE_TRACKER_CLASS_TRACKER_GROUP,
                                                        ),
                                                  classTrackerGroupId,
                                                  cohortId,
                                                  subjectArea.name,
                                                  qualification.name,
                                                  specification,
                                                  tier,
                                                  yearGroup,
                                              ),
                                      }),
                                  )
                                : [
                                      getMenuActionProps("openYearGroup", {
                                          text: t("class.list.openYearGroupOverview"),
                                          icon: <Icon path={mdiCheckboxMultipleBlankOutline} />,
                                          showAction: () =>
                                              !hideYearGroupBtn &&
                                              !isDisabled &&
                                              !!classTrackerGroupId,
                                          linkCallback: () =>
                                              generateTrackerUrl(
                                                  archived
                                                      ? attachSchoolId(
                                                            ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER_GROUP,
                                                        )
                                                      : attachSchoolId(
                                                            ROUTE_TRACKER_CLASS_TRACKER_GROUP,
                                                        ),
                                                  classTrackerGroupId,
                                                  cohortId,
                                                  subjectArea.name,
                                                  qualification.name,
                                                  specification,
                                                  "-",
                                                  yearGroup,
                                              ),
                                      }),
                                  ]),
                            getMenuActionProps("convert", {
                                text: t("class.list.synchroniseYourClasslists"),
                                icon: <Icon path={mdiLink} />,
                                linkCallback: () =>
                                    attachSchoolId(ROUTE_CLASS_GROUP_CALL_CONVERT).replace(
                                        ":specification",
                                        specification ? `${specification.id}` : "",
                                    ),
                                showAction: () => !!accountInfo?.hasGroupCallSynchronization,
                            }),
                            getMenuActionProps("convert", {
                                text: t("class.list.unsynchroniseYourClasslists"),
                                icon: <Icon path={mdiLink} />,
                                onAction: (rowData: any) => {
                                    if (rowData.hasGroupCallClassTrackers) {
                                        setUnsyncId(rowData.classTrackerGroupId);
                                        setUnsynchroniseOpen(true);
                                    }
                                },
                                showAction: () => !!hasGroupCallClassTrackers,
                            }),
                            getMenuActionProps("bulkEdit", {
                                text: t("class.list.bulkEdit"),
                                icon: <Icon path={mdiInboxMultipleOutline} />,
                                linkCallback: () =>
                                    attachSchoolId(ROUTE_CLASS_BULK_EDIT)
                                        .replace(":classTrackerGroup", `${classTrackerGroupId}`)
                                        .replace(
                                            ":specification",
                                            specification ? `${specification.id}` : "",
                                        ) +
                                    `?fSubjectArea=${sSubject || ""}&fQualification=${
                                        sQual || ""
                                    }&fYearGroup=${sYearGroup || ""}&fCohort=${
                                        cohortId || ""
                                    }&fFirstClassId=${bulkEditTrackerId || ""}`,
                                showAction: () => !!bulkEditTrackerId,
                            }),

                            getMenuActionProps("downloadReport", {
                                text: t("class.list.downloadReport"),
                                icon: <Icon path={mdiDownload} />,
                                onAction: () => {
                                    if (
                                        handleDownloadReport &&
                                        yearGroup !== undefined &&
                                        yearGroup !== null &&
                                        cohortId &&
                                        specification?.id &&
                                        qualification?.id &&
                                        subjectArea?.id
                                    ) {
                                        handleDownloadReport(cohortId, yearGroup, {
                                            specification: { id: specification.id },
                                            qualification: { id: qualification.id },
                                            subjectArea: { id: subjectArea.id },
                                            classTracker: null,
                                            analysisStudents: [],
                                        });
                                    }
                                },
                            }),
                            getMenuActionProps("openSubjectAnalysis", {
                                text: t("class.list.openSubjectAnalysis"),
                                icon: <Icon path={mdiFinance} />,
                                linkCallback: () =>
                                    attachSchoolId(ROUTE_ANALYSIS_SUBJECT_ANALYSIS)
                                        .replace(":cohort", `${cohortId}`)
                                        .replace(":yearGroup", `${yearGroup}`) +
                                    `?subjectArea=${subjectArea?.id || ""}&qualification=${
                                        qualification?.id || ""
                                    }&specification=${specification?.id || ""}`,
                                showAction: () => showSubjectAnalysis,
                            }),
                        ]}
                        rowData={{ classTrackerGroupId, hasGroupCallClassTrackers } as any}
                    />
                )}
            </Box>
            <PromptDialog open={unsynchroniseOpen} onClose={handleUnsynchroniseOpen}>
                {t("class.list.unsynchroniseMessage")}
            </PromptDialog>
        </Box>
    );
};

export default TrackingHeader;
