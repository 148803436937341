import { MenuItem } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { Fragment, FC } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { TFunction } from "i18next";

export interface FinanceManagerFormFields {
    firstName: string;
    lastName: string;
    email: string;
    title: string;
}

export const financeManagerSchema = (t: TFunction) =>
    yup.object().shape({
        email: yup.string().email(t("common.form.validation.email.type")),
        firstName: yup.string().when("email", ([email]) => {
            return email
                ? yup.string().required(t("common.form.validation.lastName.required"))
                : yup.string();
        }),
        lastName: yup.string().when("email", ([email]) => {
            return email
                ? yup.string().required(t("common.form.validation.lastName.required"))
                : yup.string();
        }),
        title: yup.string(),
    });

interface OwnProps {
    financeManager: FinanceManagerFormFields;
}

const FinancialManagerForm: FC<OwnProps> = ({ financeManager }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Field
                name="financeManager.title"
                select
                label={t("common.title")}
                component={TextField}
                autoFocus
            >
                <MenuItem value={`Mr`}>{t("common.mr")}</MenuItem>
                <MenuItem value={`Mrs`}>{t("common.mrs")}</MenuItem>
                <MenuItem value={`Miss`}>{t("common.miss")}</MenuItem>
                <MenuItem value={`Ms`}>{t("common.ms")}</MenuItem>
                <MenuItem value={`Dr`}>{t("common.dr")}</MenuItem>
                <MenuItem value={`Prof`}>{t("common.prof")}</MenuItem>
                <MenuItem value={`Mx`}>{t("common.mx")}</MenuItem>
            </Field>
            <Field
                label={t("common.form.label.firstName")}
                name={`financeManager.firstName`}
                component={TextField}
            />
            <Field
                label={t("common.form.label.lastName")}
                name={`financeManager.lastName`}
                component={TextField}
            />
            <Field
                label={t("common.form.label.email")}
                name={`financeManager.email`}
                validate={email =>
                    (financeManager.firstName !== "" || financeManager.lastName !== "") &&
                    email === ""
                        ? t("common.form.validation.email.required")
                        : undefined
                }
                component={TextField}
            />
        </Fragment>
    );
};

export default FinancialManagerForm;
