import { MenuItem, TextField } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiltersShape } from "src/modules/common/components/Grid/GridStudentFilter";
import { HeaderNode } from "src/modules/tracker/dto/TrackerHeader";
import RangeFilterComponent from "./RangeFilterComponent";

interface OwnProps {
    handleFiltersChange: (filter: FiltersShape) => void;
    columns: HeaderNode[];
}

const SummaryFilter: FC<OwnProps> = ({ handleFiltersChange, columns }) => {
    const { t } = useTranslation();
    const [summaryColumn, setSummaryColumn] = useState<string>("");

    return (
        <>
            <TextField
                value={summaryColumn}
                label={t("common.filterBySummaryColumn")}
                onChange={e => setSummaryColumn(e.target.value as string)}
                margin="normal"
                select
                SelectProps={{
                    displayEmpty: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            >
                <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                {columns &&
                    columns.map(s => {
                        return (
                            <MenuItem key={s.hash} value={s.hash}>
                                {s.name}
                            </MenuItem>
                        );
                    })}
            </TextField>
            <RangeFilterComponent
                handleFiltersChange={handleFiltersChange}
                columnName={summaryColumn}
                columnDisplayName={columns.find(o => o && o.hash === summaryColumn)?.name || ""}
                filterType="summary"
                noDataEnabled={true}
                onSubmit={() => setSummaryColumn("")}
            />
        </>
    );
};

export default SummaryFilter;
