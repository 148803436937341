import clsx from "clsx";
import COLORS from "src/styles/colors";
import Truncate from "src/components/Trunacte";
import Icon from "src/components/Icon";
import { useState } from "react";
import { AnalysisHeaderNodes } from "src/modules/analysis/services/analysisHeaderGenerator";
import { useMount } from "src/hooks/useMount";
import { mdiMinus, mdiPlus } from "@mdi/js";
import { CELL_SIZE_MIN } from "src/modules/tracker/services/headerGenerator";

const DefaultHeaderRenderer = props => {
    const [expandState, setExpandState] = useState<string>("collapsed");
    const subjectArea = props.headerConfig?.subjectArea;
    const subjectAreaColour = props.headerConfig?.chipColour;
    const nodeType = props.nodeType;
    const level = props.level;
    const field = props.column?.colId || props.columnGroup?.groupId;
    const isExpandable = !!props?.columnGroup?.getProvidedColumnGroup().expandable;

    const expandOrCollapse = () => {
        const currentState = props?.columnGroup?.getProvidedColumnGroup().isExpanded();

        props.setExpanded(!currentState);

        if (props.context.handleGroupOpen) {
            props.context.handleGroupOpen(field, !currentState);
        }
        const newState = expandState === "collapsed" ? "expanded" : "collapsed";
        setExpandState(newState);
    };

    useMount(() => {
        if (props.context.userSettings) {
            const collapsedColumns = props.context.userSettings.collapsedColumns;

            if (collapsedColumns && collapsedColumns[field] && collapsedColumns[field] === true) {
                props.setExpanded(true);
                setExpandState("expanded");
            }
        }
    });

    return (
        <div
            className={clsx(
                "ag-default-header",
                nodeType === AnalysisHeaderNodes.QUAL && "ag-default-header--qual",
            )}
            title={props.displayName}
        >
            <div
                style={{
                    top: `${(level - 1) * CELL_SIZE_MIN}px`,
                    backgroundColor: props.columnColour || COLORS.VERY_LIGHT_GREY_8,
                }}
                className="ag-default-header__row"
            >
                {subjectArea && (
                    <div className="ag-default-header__above-label">
                        <div className="ag-default-header__chip-container">
                            <div
                                className="ag-default-header__chip"
                                style={{ backgroundColor: subjectAreaColour || "transparent" }}
                            >
                                {subjectArea}
                            </div>
                        </div>
                    </div>
                )}
                {isExpandable && (
                    <div onClick={expandOrCollapse} className="ag-expand-icon">
                        <Icon
                            size="14px"
                            path={expandState === "collapsed" ? mdiMinus : mdiPlus}
                            color={COLORS.WHITE}
                        />
                    </div>
                )}
                <div
                    className={clsx(
                        "ag-default-header__label",
                        isExpandable && "ag-default-header__label--expandable",
                    )}
                >
                    {subjectArea ? (
                        <Truncate lines={nodeType === AnalysisHeaderNodes.QUAL ? 2 : 1}>
                            {props.displayName}
                        </Truncate>
                    ) : (
                        <div className="cell-truncate">{props.displayName}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DefaultHeaderRenderer;
