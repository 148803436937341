import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";

export interface TrustCohortsYearGroup {
    id: number;
    name: string;
    yearGroup: number[];
}

export const TRUST_COHORTS_YEARGROUPS_QUERY = "trustCohortYearGroupsQuery";

export const useTrustCohortsAndYearGroups = (trustId: number) => {
    return useQuery({
        queryKey: [TRUST_COHORTS_YEARGROUPS_QUERY, trustId],
        queryFn: async (): Promise<TrustCohortsYearGroup[] | null> => {
            if (trustId) {
                const { data } = await axiosInstance.get(
                    `trust/${trustId}/common/cohorts-and-year-groups/`,
                );

                const convert =
                    data?.map(d => {
                        const ygs: number[] = [];

                        if (d.yearGroup.find((yg: number) => yg === 10)) {
                            ygs.push(10);
                        }
                        if (d.yearGroup.find((yg: number) => yg === 11)) {
                            ygs.push(11);
                        }

                        return {
                            ...d,
                            yearGroup: ygs,
                        };
                    }) || null;

                return convert;
            }
            return null;
        },
    });
};
