import { Button, Typography, Box } from "@mui/material";
import { ROUTE_AUTH_LOGIN } from "src/routes";
import { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { resetPasswordSchema } from "../components/NewPassword/resetPasswordSchema";
import AuthContainer from "src/components/AuthContainer";
import { mdiArrowLeft } from "@mdi/js";
import Icon from "src/components/Icon";
import { useResetPassword } from "../hooks/useResetPassword";
import { FormProvider, RHFTextField } from "src/components/hookFormComponents";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const ResetPasswordContainer = () => {
    const [passwordReset, setPasswordReset] = useState<boolean>(false);

    const { t } = useTranslation();

    const methods = useForm({
        resolver: yupResolver(resetPasswordSchema(t)),
        mode: "all",
        defaultValues: {
            email: "",
        },
    });
    const {
        formState: { isSubmitting },
    } = methods;

    const { mutate: resetPassword } = useResetPassword(() => setPasswordReset(true));

    const handleSubmit = (values: { email: string }) => {
        resetPassword(values.email.toLowerCase());
    };

    return (
        <AuthContainer>
            {passwordReset ? (
                <Fragment>
                    <Box mb={3.5}>
                        <Typography>{t("auth.resetPassword.success")}</Typography>
                    </Box>
                    <Button
                        variant="text"
                        component={Link}
                        to={ROUTE_AUTH_LOGIN}
                        startIcon={<Icon path={mdiArrowLeft} />}
                        disableRipple
                    >
                        {t("auth.resetPassword.backToLogin")}
                    </Button>
                </Fragment>
            ) : (
                <Fragment>
                    <Box mb={3.5}>
                        <Typography>{t("auth.resetPassword.message")}</Typography>
                    </Box>
                    <FormProvider methods={methods} onSubmit={handleSubmit}>
                        <RHFTextField
                            name="email"
                            type="email"
                            label={t("common.form.label.email")}
                            autoFocus
                            margin="normal"
                        />
                        <Box
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            justifyContent="space-between"
                            width={1}
                            mt={5}
                        >
                            <Button color="primary" type="submit" disabled={isSubmitting}>
                                {t("auth.resetPassword.button")}
                            </Button>
                        </Box>
                    </FormProvider>
                </Fragment>
            )}
        </AuthContainer>
    );
};

export default memo(ResetPasswordContainer);
