import { Box, Grid, TextField, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApiData, ApiStatus } from "src/api/constants";
import ErrorMessage from "src/forms/ErrorMessage";
import { usePrevious } from "src/hooks/usePrevious";
import { attributeWithFilterSelector } from "src/modules/common/selectors/SpecificationAttributeSelectors";
import { CommonActions } from "src/modules/common/store/actions";
import {
    AttributeGroup,
    AttributeItem,
    AttributeValue,
    AttributeValueExtended,
    InitialAttribute,
    OrmSpecificationAttribute,
} from "src/orm/models/SpecificationAttribute";
import { sortByKey } from "src/services/object";
import { AppState } from "src/store/reducers";
import COLORS from "src/styles/colors";
import {
    getExtendedAttributeValue,
    isSelected,
    shouldDisableContainsAll,
    shouldDisableContainsAny,
    shouldDisableEquals,
} from "./attributesFormHelper";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-mui";

const dispatchActions = (dispatch: Dispatch) => ({
    getSpecificationAttributes: (specificationId: number) => {
        dispatch(CommonActions.getSpecificationAttributes(specificationId));
    },
});

const AttributesConfigList = ({
    specificationId,
    attributes,
    items,
    groups,
    disabledItems,
    initialAttributes,
    errors,
    setFieldValue,
    setFieldTouched,
    namePrefix,
}: {
    specificationId: number | null;
    attributes: AttributeValueExtended[];
    items: AttributeItem[];
    groups: AttributeGroup[];
    disabledItems: number[];
    initialAttributes?: InitialAttribute[];
    errors;
    setFieldValue: (field: string, value: any) => void;
    setFieldTouched: (field: string, value: any) => void;
    namePrefix?: string;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { getSpecificationAttributes } = dispatchActions(dispatch);
    const [loadedAttributes, setLoadedAttributes] = useState<number[]>([]);
    const [canShowAttributes, setCanShowAttributes] = useState<boolean>(false);
    const {
        apiAttributes,
        attribute,
    }: {
        apiAttributes: ApiData;
        attribute: OrmSpecificationAttribute;
    } = useSelector(
        (state: AppState) => ({
            apiAttributes: state.api.common.getSpecificationAttributes,
            attribute: attributeWithFilterSelector(
                state,
                q => specificationId !== null && q.specification === specificationId,
            ),
        }),
        shallowEqual,
    );

    const getItemValues = (itemId: number | null) =>
        itemId
            ? attribute.values
                  .filter(v => v.attributeItem && v.attributeItem.id === itemId)
                  .sort(sortByKey("position"))
            : [];

    const getGroupItems = (groupId: number | null) =>
        groupId
            ? attribute.items
                  .filter(i => i.attributeGroup && i.attributeGroup.id === groupId)
                  .sort(sortByKey("position"))
            : [];

    const getCustomName = (valueId: number) => {
        const value = attributes.find(av => av.id === valueId);

        return value?.customName ? value.customName : "";
    };

    const itemsWithNoGroup = attribute.items
        .filter(i => i.attributeGroup === null)
        .sort(sortByKey("position"));

    const prevSpecification = usePrevious(specificationId);

    const handleCustomNameChange =
        (valueId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
                namePrefix ? `${namePrefix}.attributes` : `attributes`,
                attributes.map(value => {
                    const minified = getExtendedAttributeValue(value);

                    return value.id === valueId && value.allowCustomName
                        ? { ...minified, customName: event.target.value }
                        : { ...minified };
                }),
            );
        };

    const shouldRenderGroup = (group: AttributeGroup): boolean =>
        attribute.items.filter(
            it =>
                it.attributeGroup &&
                it.attributeGroup.id === group.id &&
                it.id &&
                !disabledItems?.includes(it.id),
        ).length > 0;

    const shouldRenderItem = (itemId: number): boolean =>
        disabledItems && disabledItems.find((di: number) => di === itemId) ? false : true;

    useEffect(() => {
        if (
            [ApiStatus.NONE, ApiStatus.ERROR].includes(apiAttributes.status) ||
            (specificationId !== null &&
                prevSpecification !== specificationId &&
                !loadedAttributes.find(s => s === specificationId))
        ) {
            if (specificationId) {
                getSpecificationAttributes(specificationId);
                setLoadedAttributes([...loadedAttributes, specificationId]);
            }

            setFieldTouched(namePrefix ? `${namePrefix}.groups` : `groups`, undefined);
            setFieldTouched(namePrefix ? `${namePrefix}.items` : `items`, undefined);
        }
    }, [
        apiAttributes,
        attribute,
        getSpecificationAttributes,
        loadedAttributes,
        namePrefix,
        prevSpecification,
        setFieldTouched,
        specificationId,
    ]);

    const setVisibleItems = useCallback(() => {
        attribute.items.forEach(aItem => {
            if (aItem.visibilityRule !== null) {
                let shouldDisableItem = false;
                switch (aItem.visibilityRule.type) {
                    case "equals":
                        shouldDisableItem = shouldDisableEquals(aItem.visibilityRule, attributes);
                        break;
                    case "contains-any":
                        shouldDisableItem = shouldDisableContainsAny(
                            aItem.visibilityRule,
                            attributes,
                        );
                        break;
                    case "contains-all":
                        shouldDisableItem = shouldDisableContainsAll(
                            aItem.visibilityRule,
                            attributes,
                        );
                        break;
                }
                if (
                    shouldDisableItem &&
                    disabledItems.find(di => di === aItem.id) === undefined &&
                    aItem.id
                ) {
                    setFieldValue(namePrefix ? `${namePrefix}.disabledItems` : `disabledItems`, [
                        ...disabledItems,
                        aItem.id,
                    ]);
                } else if (!shouldDisableItem && disabledItems.find(di => di === aItem.id)) {
                    setFieldValue(
                        namePrefix ? `${namePrefix}.disabledItems` : `disabledItems`,
                        disabledItems.filter(di => di !== aItem.id),
                    );
                }
            }
        });
    }, [attribute.items, attributes, disabledItems, namePrefix, setFieldValue]);

    const getItemErrorIndex = (itemId: number) =>
        items.findIndex(formItem => formItem.id === itemId);

    const getGroupErrorIndex = (groupId: number) => {
        return groups.findIndex(formItem => formItem.id === groupId);
    };

    useEffect(() => {
        if (attributes) setVisibleItems();
    }, [attributes, setVisibleItems]);

    useEffect(() => {
        if (attribute && attribute.values) {
            setFieldValue(
                namePrefix ? `${namePrefix}.attributes` : `attributes`,
                attribute.values.map(v => getExtendedAttributeValue(v)),
            );
            setFieldValue(namePrefix ? `${namePrefix}.items` : `items`, attribute.items);
            setFieldValue(namePrefix ? `${namePrefix}.groups` : `groups`, attribute.groups);
        }
    }, [attribute, namePrefix, setFieldValue, specificationId]);

    const handleInitialValues = useCallback(
        (attributes, initialValues) => {
            if (initialValues && initialValues.length > 0) {
                const newInitial = attributes.map(a => {
                    const initial = initialValues.find(
                        iv => iv.attributeValue && iv.attributeValue.id === a.id,
                    );
                    if (initial) {
                        return {
                            ...a,
                            isSelected: true,
                            isMandatory: a.isMandatory,
                            customName: initial.customName || "",
                            classTrackerAttributeValueId: initial.id,
                        };
                    }
                    return {
                        ...a,
                        isMandatory: a.isMandatory,
                        isSelected: a.isMandatory || false,
                    };
                });
                setFieldValue(namePrefix ? `${namePrefix}.attributes` : `attributes`, newInitial);
                setFieldValue(
                    namePrefix ? `${namePrefix}.initialEditAttributes` : `initialEditAttributes`,
                    [],
                );
            } else {
                setFieldValue(
                    namePrefix ? `${namePrefix}.initialEditAttributes` : `initialEditAttributes`,
                    [],
                );
            }
            setCanShowAttributes(true);
        },
        [namePrefix, setFieldValue],
    );

    const prevAttributesLength = usePrevious(attributes?.length > 0 ? attributes.length : 0);

    useEffect(() => {
        if (attributes && attributes.length > 0 && prevAttributesLength !== attributes.length) {
            handleInitialValues(attributes, initialAttributes);
        }
    }, [attributes]);

    const renderCheckboxField = (value: AttributeValue, vIndex: number) => {
        const valueIndex = attributes.findIndex(attr => attr.id === value.id);
        return (
            <Box key={value.id || vIndex}>
                <Field
                    type="checkbox"
                    component={CheckboxWithLabel}
                    name={
                        namePrefix
                            ? `${namePrefix}.attributes[${valueIndex}].isSelected`
                            : `attributes[${valueIndex}].isSelected`
                    }
                    key={value.id || vIndex}
                    readOnly={value.isMandatory}
                    disabled={value.isMandatory}
                    Label={{
                        label: (
                            <Fragment>
                                {value.name}
                                {value.weight !== null && value.weight > 0 && (
                                    <Typography variant="overline" component="span">
                                        &nbsp;(weight: {value.weight})
                                    </Typography>
                                )}
                                {value.allowCustomName &&
                                isSelected(value.id, attributes) === true ? (
                                    <Box mt={1.25}>
                                        <TextField
                                            placeholder={t("class.addClass.customName")}
                                            value={getCustomName(value.id) || ""}
                                            onChange={handleCustomNameChange(value.id)}
                                        />
                                    </Box>
                                ) : null}
                            </Fragment>
                        ),
                    }}
                />
            </Box>
        );
    };
    if (!canShowAttributes) return <></>;

    return (
        <>
            {itemsWithNoGroup?.length > 0 && (
                <Box mt={5}>
                    <Grid container spacing={4}>
                        {itemsWithNoGroup.map(item => {
                            const values = getItemValues(item.id);
                            return values.length > 0 && item.id && shouldRenderItem(item.id) ? (
                                <Grid item sm={4} key={item.id}>
                                    <Box mb={2.5}>
                                        {item.name && (
                                            <Typography component="h3" variant="h5">
                                                {item.name}
                                            </Typography>
                                        )}
                                        {item.intro && (
                                            <Typography component="p" variant="subtitle1">
                                                {item.intro}
                                            </Typography>
                                        )}
                                    </Box>
                                    {values.map((value, vIndex) =>
                                        renderCheckboxField(value, vIndex),
                                    )}{" "}
                                    {getItemErrorIndex(item.id) > -1 && errors?.items && (
                                        <div style={{ color: COLORS.RED_1 }}>
                                            {errors?.items[getItemErrorIndex(item.id)]}
                                        </div>
                                    )}
                                </Grid>
                            ) : null;
                        })}
                    </Grid>
                </Box>
            )}
            {attribute.groups.map(
                (group, gIndex) =>
                    group.id &&
                    shouldRenderGroup(group) && (
                        <Box mt={5} key={group.id || gIndex}>
                            <Grid container>
                                <Grid item sm={8}>
                                    <Box mb={2.5}>
                                        {group.name && (
                                            <Typography variant="h2" component="h2">
                                                {group.name}
                                            </Typography>
                                        )}
                                        {group.description && (
                                            <Typography component="p" variant="subtitle1">
                                                {group.description}
                                            </Typography>
                                        )}
                                        {getGroupErrorIndex(group.id) > -1 && errors.groups ? (
                                            <ErrorMessage
                                                name={
                                                    namePrefix
                                                        ? `${namePrefix}.groups`
                                                        : `groups` + getGroupErrorIndex(group.id)
                                                }
                                            />
                                        ) : null}
                                    </Box>
                                </Grid>
                                <Grid item sm={4} />
                                {getGroupItems(group.id).map(item =>
                                    item.id && shouldRenderItem(item.id) ? (
                                        <Grid item sm={4} key={item.id}>
                                            <Box mb={1.5}>
                                                {item.name && (
                                                    <Typography component="h3" variant="h5">
                                                        {item.name}
                                                    </Typography>
                                                )}
                                                {item.intro && (
                                                    <Typography component="p" variant="subtitle1">
                                                        {item.intro}
                                                    </Typography>
                                                )}
                                            </Box>
                                            {getItemValues(item.id).map((value, vIndex) => (
                                                <Box key={value.id || vIndex}>
                                                    {renderCheckboxField(value, vIndex)}
                                                </Box>
                                            ))}
                                            {getItemErrorIndex(item.id) > -1 && errors?.items && (
                                                <div style={{ color: COLORS.RED_1 }}>
                                                    {errors?.items[getItemErrorIndex(item.id)]}
                                                </div>
                                            )}
                                        </Grid>
                                    ) : null,
                                )}
                            </Grid>
                        </Box>
                    ),
            )}
        </>
    );
};

export default AttributesConfigList;
