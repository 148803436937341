import { Box, Typography, Button, MenuItem, Autocomplete, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mdiEmail } from "@mdi/js";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import { RHFTextField } from "src/components/hookFormComponents";
import { SearchSchoolsResponse, useGetSearchSchools } from "../../hooks/useGetSearchSchools";
import useDebounce from "src/hooks/useDebounce";
import { useFormContext } from "react-hook-form";

export enum UserRole {
    TEACHER = "teacher",
    PARENT = "parent",
    STUDENT = "student",
}
const SchoolStep = () => {
    const { getValues, setValue } = useFormContext();
    const { t } = useTranslation();
    const [autocompleteInput, setAutocompleteInput] = useState<string>("");
    const debouncedAutocompleteInput = useDebounce(autocompleteInput, 500);
    const { data: schools, refetch } = useGetSearchSchools(debouncedAutocompleteInput);
    useEffect(() => {
        refetch();
    }, [debouncedAutocompleteInput]);
    return (
        <Fragment>
            <Box mb={2.5}>
                <Typography>{t("auth.register.schoolStep.autoLabel")}</Typography>
            </Box>
            <Autocomplete
                freeSolo
                options={schools || []}
                getOptionLabel={(school: SearchSchoolsResponse) => school.name}
                onInputChange={(_event, newInputValue) => {
                    setAutocompleteInput(newInputValue);
                }}
                onChange={(_e, value) => {
                    setValue("school", value);
                }}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            label={t("auth.register.schoolStep.autoPlaceholder")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    );
                }}
                forcePopupIcon={false}
                sx={{ mb: 3 }}
            />
            <RHFTextField
                name="userRole"
                select
                fullWidth
                label={t("auth.register.schoolStep.userRole.label")}
            >
                {Object.keys(UserRole).map(key => (
                    <MenuItem key={key} value={UserRole[key]}>
                        {t("auth.register.schoolStep.userRole." + UserRole[key])}
                    </MenuItem>
                ))}
            </RHFTextField>
            {getValues("userRole") === UserRole.STUDENT ||
            getValues("userRole") === UserRole.PARENT ? (
                <Box mt={2}>
                    <Typography>{t("auth.register.schoolStep.userRole.info")}</Typography>
                </Box>
            ) : null}
            <Box color={COLORS.GREY_3} mt={4}>
                <Typography component="span" color="inherit">
                    {t("auth.register.schoolStep.caption")}
                </Typography>
                <Box pl={3} component="span">
                    <Button
                        href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
                        variant="text"
                        startIcon={<Icon path={mdiEmail} />}
                        disableRipple
                        color="inherit"
                    >
                        {t("auth.register.schoolStep.contactUs")}
                    </Button>
                </Box>
            </Box>
        </Fragment>
    );
};
export default SchoolStep;
