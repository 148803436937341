import { FC, useRef } from "react";
import AllSubjectsReportForm from "./forms/AllSubjectsReportForm";
import FormikRef from "src/forms/FormikRef";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApiData, ApiStatus } from "src/api/constants";
import { useMount } from "src/hooks/useMount";
import { useResponse } from "src/hooks/useResponse";
import { ROUTE_REPORT_SNAPSHOT_LIST } from "src/routes";
import { AppState } from "src/store/reducers";
import { ReportTemplateList } from "../../api/ReportTemplate/getList";
import { reportTemplateListSelector } from "../../selectors/ReportTempatesSelectors";
import { ReportActions } from "../../store/actions";
import {
    AllSubjectsReportFormShape,
    allSubjectsReportSchema,
    convertFormValuesToReportRequest,
    snapshotReportFormInitialValues,
} from "./forms/AllSubjectsReportForm";
import { CreateAllSubjectsSnapshotReportRequest } from "../../api/SnapshotReport/createAllSubjects";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSearchSchoolSnapshotFilters } from "../../hooks/useSearchSchoolSnapshotFilters";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

interface OwnProps {
    cohort: number | null;
}

const dispatchActions = (dispatch: Dispatch) => ({
    getReportTemplateList: () => {
        dispatch(ReportActions.getReportTemplateList());
    },
    createAllSubjectsReport: (values: CreateAllSubjectsSnapshotReportRequest) => {
        dispatch(ReportActions.createAllSubjectsSnapshotReport(values));
    },
});

const AllSubjectsReportTab: FC<OwnProps> = ({ cohort }) => {
    const dispatch = useDispatch();
    const form = useRef() as any;
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { getReportTemplateList, createAllSubjectsReport } = dispatchActions(dispatch);
    const {
        apiCreate,
        reportTemplates,
    }: {
        apiCreate: ApiData;
        reportTemplates: ReportTemplateList | null;
    } = useSelector(
        (state: AppState) => ({
            apiCreate: state.api.report.createAllSubjectsSnapshotReport,
            reportTemplates: reportTemplateListSelector(state),
        }),
        shallowEqual,
    );

    const { data: snapshots, isSuccess } = useSearchSchoolSnapshotFilters(cohort, {
        onlyCreated: true,
    });

    const handleSubmit = values => {
        const converted = convertFormValuesToReportRequest(values);
        if (converted) {
            createAllSubjectsReport(converted);
        }
    };

    useMount(() => {
        getReportTemplateList();
    });

    useResponse(() => {
        const currentForm = form?.current as any;
        if (apiCreate.status === ApiStatus.SUCCESS) {
            enqueueSnackbar(t("report.snapshot.create.subjectReportSuccess"), {
                variant: "success",
            });
            navigate(ROUTE_REPORT_SNAPSHOT_LIST);
        }
        if (apiCreate.status === ApiStatus.ERROR) {
            if (apiCreate.error?.response.error) {
                enqueueSnackbar(apiCreate.error?.response.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("report.snapshot.create.subjectReportError"), {
                    ...SnackbarErrorOptions,
                });
            }
        }
        currentForm.setSubmitting(false);
    }, apiCreate);

    return (
        <Box>
            <Typography variant="body1" gutterBottom>
                {t("report.snapshot.create.allSubjectReportHint")}
            </Typography>
            {isSuccess && (!snapshots || snapshots.length === 0) ? (
                <Typography variant="h5">{t("report.snapshot.create.noSnapshots")}</Typography>
            ) : (
                <></>
            )}
            {isSuccess ? (
                <FormikRef
                    ref={form}
                    initialValues={snapshotReportFormInitialValues}
                    validationSchema={allSubjectsReportSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<AllSubjectsReportFormShape>) => (
                        <>
                            <AllSubjectsReportForm
                                {...formProps}
                                cohort={cohort}
                                snapshots={snapshots}
                                reportTemplates={reportTemplates}
                            />
                            <Box
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                justifyContent="space-between"
                                width={1}
                                mt={5}
                            >
                                <Button
                                    color="primary"
                                    disabled={
                                        formProps.isSubmitting ||
                                        !snapshots ||
                                        snapshots.length === 0
                                    }
                                    onClick={formProps.submitForm}
                                >
                                    {t("report.snapshot.create.publishBtn")}
                                </Button>
                            </Box>
                        </>
                    )}
                </FormikRef>
            ) : (
                <CircularProgress />
            )}
        </Box>
    );
};
export default AllSubjectsReportTab;
