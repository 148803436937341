import { UserActions, UserActionsTypes } from "./actions";
import { filterNotNullable } from "src/services/object";

export interface UserState {
    adminIds: number[] | null;
}

export const initialState: UserState = {
    adminIds: null,
};

export const reducer = (state: UserState = initialState, action: UserActions): UserState => {
    switch (action.type) {
        case UserActionsTypes.SET_ADMIN_IDS:
            return {
                ...state,
                adminIds: filterNotNullable(action.payload as { id: number }[]).map(u => u.id),
            };
        default:
            return state;
    }
};
