import FileSaver from "file-saver";
import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { NotificationActions } from "src/notifications/store/actions";
import { map, mergeMap } from "rxjs/operators";
import { TrackerActionTypes, TrackerActions } from "../actions";
import { OrmClassTrackerActions } from "src/orm/models/ClassTracker/actions";

const getClassTrackerHeaderSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_CLASS_TRACKER_HEADER_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getClassTrackerHeaderSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerHeader(payload.response))),
    );

const getClassTrackerValuesSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_CLASS_TRACKER_VALUES_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getClassTrackerValuesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerValues(payload.response))),
    );

const getClassTrackerLiveGradeBoundariesSuccessEpic = (
    action$: ActionsObservable<TrackerActions>,
) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_CLASS_LIVE_GRADE_BOUNDARIES_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getClassTrackerLiveGradeBoundariesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassLiveGradeBoundaries(payload.response))),
    );

const getClassTrackerRelatedSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_CLASS_TRACKER_RELATED_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getClassTrackerRelatedSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerRelated(payload.response))),
    );

const clearClassTrackerListSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.CLEAR_CLASS_TRACKER_LIST),
        mergeMap(() => of(OrmClassTrackerActions.clear())),
    );

const getStudentReportDownloadEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_STUDENT_REPORT_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getStudentReportDownloadSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            const { fileName } = payload.request.payload.values as {
                fileName: string;
            };
            FileSaver.saveAs(payload.response, `${fileName}.pdf`);
            return of(
                TrackerActions.removeReportDownloadState(
                    payload.request.payload.params.classTracker,
                    payload.request.payload.params.id,
                ),
                NotificationActions.enqueueSnackbar({
                    message: "Student report downloaded",
                    variant: "success",
                }),
            );
        }),
    );

export const classTrackerEpics = combineEpics(
    getClassTrackerHeaderSuccessEpic,
    getClassTrackerValuesSuccessEpic,
    getClassTrackerRelatedSuccessEpic,
    getClassTrackerLiveGradeBoundariesSuccessEpic,
    getStudentReportDownloadEpic,
    clearClassTrackerListSuccessEpic,
);
