import Dialog from "src/components/Dialog";
import { useEffect, useCallback } from "react";
import { Button, MenuItem, Grid } from "@mui/material";
import { ApiData, ApiStatus } from "src/api/constants";
import { usePrevious } from "src/hooks/usePrevious";
import { classesSelector } from "src/modules/user/selectors/ClassesSelectors";
import { studentsSelector } from "src/modules/user/selectors/StudentsSelectors";
import { UserActions } from "src/modules/user/store/actions";
import { OrmClass } from "src/orm/models/Class";
import { StudentModel } from "src/orm/models/Student";
import { AppState } from "src/store/reducers";
import { Field, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ClassActions } from "../../store/actions";
import { SingleClassForm } from "../../containers/AddClass/ManualExistingStudentsContainer";
import { useResponse } from "src/hooks/useResponse";
import { useSnackbar } from "notistack";
import { getErrorMessage, FormError } from "src/services/error";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

const dispatchActions = (dispatch: Dispatch) => ({
    getClasses: (cohort?, yearGroup?) => {
        dispatch(UserActions.getUserClassesList(cohort, yearGroup));
    },
    getStudents: classTracker => {
        dispatch(ClassActions.getStudentsByClass(classTracker));
    },
});

const ChooseClassModal = ({
    open,
    handleClose,
    values: formValues,
    setFieldValue,
}: FormikProps<SingleClassForm> & { open: boolean; handleClose: () => void }) => {
    const dispatch = useDispatch();
    const { getClasses, getStudents } = dispatchActions(dispatch);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const yearGroups = data?.yearGroups;
    const {
        apiStudents,
        classes,
        students,
    }: {
        apiClasses: ApiData;
        apiStudents: ApiData;
        classes: OrmClass[];
        students: StudentModel[];
    } = useSelector(
        (state: AppState) => ({
            apiClasses: state.api.user.getUsersClasses,
            apiStudents: state.api.class.getStudentsByClass,
            classes: classesSelector(state),
            students: studentsSelector(state),
        }),
        shallowEqual,
    );

    const prevCohort = usePrevious(formValues.chooseCohort);
    const prevYearGroup = usePrevious(formValues.chooseYearGroup);

    useEffect(() => {
        if (
            formValues.chooseCohort &&
            formValues.chooseYearGroup !== null &&
            formValues.chooseYearGroup > -1 &&
            (formValues.chooseCohort !== prevCohort || formValues.chooseYearGroup !== prevYearGroup)
        ) {
            getClasses(formValues.chooseCohort, formValues.chooseYearGroup);
        }
    }, [
        formValues.chooseCohort,
        formValues.chooseYearGroup,
        getClasses,
        prevCohort,
        prevYearGroup,
    ]);

    const handleClassChoose = () => {
        if (formValues.chooseClass !== 0) {
            getStudents(formValues.chooseClass);
        }
    };
    const handleErrorResponse = useCallback(
        (apiData: ApiData) => {
            if (apiData.status === ApiStatus.ERROR) {
                const error: FormError = getErrorMessage(apiData);
                if (error.message)
                    enqueueSnackbar(error.message, {
                        ...SnackbarErrorOptions,
                    });
            }
        },
        [enqueueSnackbar],
    );
    useEffect(() => {
        if (
            students.length > 0 &&
            formValues.students.length === 0 &&
            formValues.chooseClass !== 0
        ) {
            setFieldValue("students", students);
            handleClose();
        }
    }, [formValues.chooseClass, formValues.students.length, handleClose, setFieldValue, students]);

    useResponse(() => handleErrorResponse(apiStudents), apiStudents);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            title={t("class.addClass.chooseClass")}
            actions={
                <Button
                    onClick={handleClassChoose}
                    disabled={formValues.chooseClass === 0}
                    color="primary"
                >
                    {t("common.continue")}
                </Button>
            }
        >
            <Grid container spacing={4}>
                <Grid item sm={4}>
                    <Field
                        name="chooseCohort"
                        label={t("class.addClass.cohort")}
                        component={TextField}
                        select
                        margin="none"
                    >
                        {cohorts &&
                            cohorts.map(cohort => (
                                <MenuItem key={cohort.id} value={cohort.id}>
                                    {cohort.name}
                                </MenuItem>
                            ))}
                    </Field>
                </Grid>
                <Grid item sm={4}>
                    <Field
                        name="chooseYearGroup"
                        label={t("class.addClass.yearGroup")}
                        component={TextField}
                        select
                        margin="none"
                    >
                        <MenuItem key={-1} value={-1}>
                            [None]
                        </MenuItem>
                        {yearGroups.map(year => (
                            <MenuItem key={year.id} value={year.id}>
                                {year.name}
                            </MenuItem>
                        ))}
                    </Field>
                </Grid>
                <Grid item sm={4}></Grid>
                <Grid item sm={4}>
                    <Field
                        name="chooseClass"
                        label={t("class.addClass.selectClass")}
                        component={TextField}
                        select
                        margin="none"
                    >
                        <MenuItem key={0} value={0}>
                            [None]
                        </MenuItem>
                        {classes.map(cla => (
                            <MenuItem key={cla.id} value={cla.id}>
                                {cla.name}
                            </MenuItem>
                        ))}
                    </Field>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ChooseClassModal;
