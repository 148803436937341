import COLORS from "src/styles/colors";
import Icon from "src/components/Icon";
import Dialog from "src/components/Dialog";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Box, Button, Chip, Typography } from "@mui/material";
import { ChooseButton } from "src/modules/common/components/ChooseButton";
import { useState } from "react";
import { mdiPlusCircleMultipleOutline, mdiPlusCircleOutline } from "@mdi/js";
import { MIGRATION_SYNC_DATE } from "src/config/globals";
import { ROUTE_CLASS_MIGRATE, ROUTE_CLASS_MIGRATE_SYNC } from "src/routes";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const MigrateClassModal = ({
    cohort,
    open,
    handleClose,
}: {
    cohort: string;
    open: boolean;
    handleClose: () => void;
}) => {
    const { t } = useTranslation();
    const [selectedAction, setSelectedAction] = useState<"migrate" | "sync" | null>(null);
    const { navigate } = useSchoolNavigate();
    const syncDay = MIGRATION_SYNC_DATE?.split("/")[0] || null;
    const syncMonth = MIGRATION_SYNC_DATE?.split("/")[1] || null;
    const syncDate = new Date(new Date().getFullYear(), parseInt(syncMonth) - 1, parseInt(syncDay));
    const isBefore = new Date(Date.now()) < syncDate;

    return (
        <Dialog
            open={open}
            className="migrateClassModal"
            onClose={() => handleClose()}
            title={t("class.migrateModal.header")}
            actions={
                selectedAction ? (
                    <Button
                        onClick={() => {
                            navigate(
                                (selectedAction === "migrate"
                                    ? ROUTE_CLASS_MIGRATE
                                    : ROUTE_CLASS_MIGRATE_SYNC
                                ).replace(":cohort", cohort),
                            );
                            handleClose();
                        }}
                        color="primary"
                    >
                        {t("common.continue")}
                    </Button>
                ) : null
            }
            contentOverflow="visible"
        >
            <Box mb={4}>
                <Typography gutterBottom>{t("class.migrateModal.hint1")}</Typography>
                <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr"
                    gap="1px 1px"
                    marginX={-6}
                    marginTop={5}
                    bgcolor={COLORS.VERY_LIGHT_GREY_7}
                >
                    <Box bgcolor={COLORS.VERY_LIGHT_GREY_1}>
                        <ChooseButton
                            onClick={() => {
                                setSelectedAction("migrate");
                            }}
                            className={selectedAction === "migrate" ? "active" : undefined}
                        >
                            <div className="row">
                                <div
                                    className={clsx(
                                        "icon",
                                        selectedAction === "migrate" && "active",
                                    )}
                                >
                                    <Icon path={mdiPlusCircleOutline} size="inherit" />
                                </div>

                                <div className="title">{t(`class.migrateModal.migrateTitle`)}</div>
                            </div>
                            <div
                                className={clsx(
                                    "subtitle",
                                    selectedAction === "migrate" && "active",
                                )}
                                dangerouslySetInnerHTML={{
                                    __html: t(`class.migrateModal.migrateHint`),
                                }}
                            ></div>
                            {isBefore && (
                                <Box mt={2} mb={2}>
                                    <Chip
                                        style={{ backgroundColor: "#e6d884", color: "#fff" }}
                                        label={t("class.migrateModal.recommended")}
                                    />
                                </Box>
                            )}

                            <Box sx={{ fontSize: "11px" }} mt={!isBefore ? 2 : 0}>
                                {t("class.migrateModal.before").replace(
                                    ":before",
                                    MIGRATION_SYNC_DATE,
                                )}
                            </Box>
                        </ChooseButton>
                    </Box>
                    <Box bgcolor={COLORS.VERY_LIGHT_GREY_1}>
                        <ChooseButton
                            onClick={() => {
                                setSelectedAction("sync");
                            }}
                            className={selectedAction === "sync" ? "active" : undefined}
                        >
                            <div className="row">
                                <div
                                    className={clsx("icon", selectedAction === "sync" && "active")}
                                >
                                    <Icon path={mdiPlusCircleMultipleOutline} size="inherit" />
                                </div>

                                <div className="title">{t(`class.migrateModal.syncTitle`)}</div>
                            </div>
                            <div
                                className={clsx("subtitle", selectedAction === "sync" && "active")}
                                dangerouslySetInnerHTML={{
                                    __html: t(`class.migrateModal.syncHint`),
                                }}
                            ></div>
                            {!isBefore && (
                                <Box mt={2} mb={2}>
                                    <Chip
                                        style={{ backgroundColor: "#e6d884", color: "#fff" }}
                                        label={t("class.migrateModal.recommended")}
                                    />
                                </Box>
                            )}
                            <Box sx={{ fontSize: "11px" }} mt={isBefore ? 2 : 0}>
                                {t("class.migrateModal.after").replace(
                                    ":after",
                                    MIGRATION_SYNC_DATE,
                                )}
                            </Box>
                        </ChooseButton>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export default MigrateClassModal;
