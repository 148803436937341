import {
    ROUTE_ANALYSIS_ATL_ANALYSIS,
    ROUTE_ANALYSIS_KS4_GRADE_ANALYSIS,
    ROUTE_ANALYSIS_STUDENTS_GRADE_SUMMARY,
    ROUTE_ANALYSIS_SUBJECT_ANALYSIS,
} from "src/routes";
import StudentsGradeSummaryContainer from "../containers/StudentsGradeSummaryContainer";
import KS4GradeAnalysisContainer from "../containers/KS4GradeAnalysisContainer";
import SubjectAnalysisContainer from "../containers/SubjectAnalysisContainer";
import AtlAnalysisContainer from "../containers/AtlAnalysisContainer";

export const AnalysisRoutes = [
    {
        path: ROUTE_ANALYSIS_STUDENTS_GRADE_SUMMARY,
        element: <StudentsGradeSummaryContainer />,
    },
    {
        path: ROUTE_ANALYSIS_KS4_GRADE_ANALYSIS,
        element: <KS4GradeAnalysisContainer />,
    },
    {
        path: ROUTE_ANALYSIS_ATL_ANALYSIS,
        element: <AtlAnalysisContainer />,
    },
    {
        path: ROUTE_ANALYSIS_SUBJECT_ANALYSIS,
        element: <SubjectAnalysisContainer />,
    },
];
