import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const upgradeToWholeSchoolEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscriptions/upgrade-to-whole-school/submit/`,
    method: ApiRequests.POST,
};

export interface UpgradeTpWholeSchoolRequest {
    period: number;
    financeManager: {
        firstName: string;
        lastName: string;
        email: string;
        title: string;
    };
    couponName: string;
}
