import Tag from "src/components/Tag";

interface OwnProps {
    value: any;
    context;
    node;
}

const AnalysisTagAllSubjectsRenderer = ({ node, context }: OwnProps) => {
    let counter: number = 0;

    const allSubjectTags = context?.tags?.filter(t => t.specifications.length === 0);

    const studentSelectedTagsIds: number[] = [];
    Object.keys(node.data).forEach(key => {
        if (key.indexOf("tag-") === 0 && node.data[key] === 1) {
            studentSelectedTagsIds.push(parseInt(key.replace("tag-", "")));
        }
    });

    studentSelectedTagsIds.forEach(ssti => {
        if (allSubjectTags.map(ast => ast.id).includes(ssti)) {
            counter++;
        }
    });

    return (
        <div
            className="ag-student-data-cell ag-student-data-cell--highlight ag-student-data-cell--border-left"
            style={{ height: "100%" }}
        >
            {counter > 0 && !node.rowPinned && <Tag variant="contained">{counter}</Tag>}
        </div>
    );
};

export default AnalysisTagAllSubjectsRenderer;
