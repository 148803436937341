import * as api from "src/api/reducers";
import * as common from "src/modules/common/store/reducers";
import * as schoolReducer from "src/modules/school/store/reducers";
import * as trackerReducer from "src/modules/tracker/store/reducers";
import * as userReducer from "src/modules/user/store/reducers";
import * as notifications from "src/notifications/store/reducers";
import * as subscriptionReducer from "src/modules/subscription/store/reducers";
import * as classReducer from "src/modules/class/store/reducers";
import * as analysisReducer from "src/modules/analysis/store/reducers";
import * as reportReducer from "src/modules/report/store/reducers";
import * as orm from "src/orm/reducers";
import { combineReducers } from "redux";
import { ORMCommonState } from "redux-orm";

export interface AppState {
    api: api.ApiState;
    orm: ORMCommonState;
    notifications: notifications.SnackbarState;
    common: common.CommonState;
    school: schoolReducer.SchoolState;
    user: userReducer.UserState;
    tracker: trackerReducer.ClassTrackerState;
    subscription: subscriptionReducer.SubscriptionState;
    class: classReducer.ClassState;
    analysis: analysisReducer.AnalysisState;
    report: reportReducer.ReportState;
}

export const rootReducer = combineReducers<AppState>({
    api: api.reducer,
    orm: orm.reducer,
    notifications: notifications.reducer,
    common: common.reducer,
    school: schoolReducer.reducer,
    user: userReducer.reducer,
    tracker: trackerReducer.reducer,
    subscription: subscriptionReducer.reducer,
    class: classReducer.reducer,
    analysis: analysisReducer.reducer,
    report: reportReducer.reducer,
});
