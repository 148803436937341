import Icon from "src/components/Icon";
import ConfigureColumnsModal from "../../Common/ConfigureColumnsModal";
import COLORS from "src/styles/colors";
import TagsModal from "../../Common/TagsModal";
import TagsUpsertModal from "../../Common/TagsUpsertModal";
import LinksModal from "../../Common/LinksModal";
import LinksUpsertModal from "../../Common/LinksUpsertModal";
import { FC, useState } from "react";
import { Button, Switch, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mdiContentCopy, mdiCog, mdiViewCarousel } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { CustomFormControlLabel } from "src/modules/common/components/Grid/GridToolsToggleButton";
import { withStyles, createStyles } from "@mui/styles";
import { ColumnApi, GridApi } from "ag-grid-enterprise";
import { useSnackbar } from "notistack";
import { DataTypeTypes } from "../../Common/TierHeaderSelect";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useClassTrackerTagList } from "src/modules/tagging/hooks/ClassTracker/useClassTrackerTagList";
import { useClassTrackerGroupTagList } from "src/modules/tagging/hooks/ClassTrackerGroup/useClassTrackerGroupTagList";
import { useTrackerUserSettingsStore } from "src/modules/tracker/hooks/query/ClassTracker/useTrackerUserSettingsStore";
import { ClassTrackerUserSettingsObject } from "src/modules/tracker/hooks/query/ClassTracker/useTrackerUserSettings";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useTrackerGroupUserSettingsStore } from "src/modules/tracker/hooks/query/ClassTrackerGroup/useTrackerGroupUserSettingsStore";

interface OwnProps {
    handleOnClick: () => {};
    classTrackerId: number;
    classTrackerGroupId?: number;
    availableStudentColumns: string[];
    context: any;
    columnApi?: ColumnApi;
    api?: GridApi;
}

const useStyles = makeStyles((theme: Theme) => ({
    configButton: {
        position: "absolute",
        top: 28,
        left: 22,
        zIndex: 200,
        marginLeft: "0 !important",
    },
    copyButton: {
        position: "absolute",
        top: 68,
        left: 22,
        zIndex: 200,
        marginLeft: "0 !important",
    },
    configureContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(1.5),
        borderTop: `1px solid ${theme.palette.divider}`,
        width: "calc(100% - 44px)",
        position: "absolute",
        top: 108,
        left: 22,
        zIndex: 200,
    },
    configureItem: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));

const CustomSwitch = withStyles(() =>
    createStyles({
        switchBase: {
            "&$checked": {
                color: COLORS.GREEN_1,
            },
            "&$checked + $track": {
                backgroundColor: COLORS.GREEN_1,
            },
        },
        checked: {},
        track: {},
    }),
)(Switch);

export const ColumnConfigurationHeader: FC<OwnProps> = ({
    classTrackerId,
    classTrackerGroupId,
    availableStudentColumns,
    context,
    api: gridApi,
    columnApi,
}) => {
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [tagModalOpened, setTagModalOpened] = useState<boolean>(false);
    const [linksModalOpened, setLinksModalOpened] = useState<boolean>(false);

    const [tagUpsertModalId, setTagUpsertModalId] = useState<number | null>(null);
    const [linksUpsertModalId, setLinksUpsertModalId] = useState<number | null>(null);

    const { data: accountInfo } = useAccountInfo();

    const { refetch: refetchClassTrackerTagList } = useClassTrackerTagList(classTrackerId);
    const { refetch: refetchClassTrackerGroupTagList } =
        useClassTrackerGroupTagList(classTrackerGroupId);
    const refetchTagList = classTrackerId
        ? refetchClassTrackerTagList
        : refetchClassTrackerGroupTagList;

    const { setClassTrackerUserSettings, ...classTrackerUserSettings } =
        useTrackerUserSettingsStore();

    const setTrackerUserSettings = (newSettings: ClassTrackerUserSettingsObject) => {
        setClassTrackerUserSettings(newSettings);
        refetchTagList();
    };

    const { setClassTrackerGroupUserSettings, ...classTrackerGroupUserSettings } =
        useTrackerGroupUserSettingsStore();
    const setTrackerGroupUserSettings = (newSettings: ClassTrackerUserSettingsObject) => {
        setClassTrackerGroupUserSettings(newSettings);
        refetchTagList();
    };

    const userSettings = classTrackerId ? classTrackerUserSettings : classTrackerGroupUserSettings;

    const setNewTrackerSettings = newUserSettings => {
        if (JSON.stringify(newUserSettings) !== JSON.stringify(userSettings)) {
            if (classTrackerId) {
                setTrackerUserSettings(newUserSettings);
            } else if (classTrackerGroupId) {
                setTrackerGroupUserSettings(newUserSettings);
            }
        }
    };

    const handleTagModalClose = (confirmed: boolean, selectedTags?: number[]) => {
        if (confirmed && selectedTags) {
            const visibleTags = context?.tags
                ?.filter(tag => !!selectedTags.includes(tag.id))
                .map(tag => `tag-${tag.id}`);
            columnApi?.setColumnsVisible(visibleTags, true);

            const hiddenTags = context?.tags
                ?.filter(tag => !selectedTags.includes(tag.id))
                .map(tag => `tag-${tag.id}`);
            columnApi?.setColumnsVisible(hiddenTags, false);

            const newUserSettings: any = { ...userSettings, selectedTags };

            setNewTrackerSettings(newUserSettings);
        }
        setTagModalOpened(false);
    };

    const handleCreateTag = (newTagId: number) => {
        const newUserSettings: any = {
            ...userSettings,
            selectedTags: userSettings?.selectedTags
                ? [...userSettings.selectedTags, newTagId]
                : [newTagId],
        };
        setNewTrackerSettings(newUserSettings);
    };

    const handleLinksModalClose = (confirmed: boolean, selectedLinks?: number[]) => {
        if (confirmed && selectedLinks) {
            const visibleLinks = context?.links
                ?.filter(link => !!selectedLinks?.includes(link.id))
                .map(link => `link-${link.id}`);
            columnApi?.setColumnsVisible(visibleLinks, true);

            const hiddenLinks = context?.links
                ?.filter(link => !selectedLinks?.includes(link.id))
                .map(link => `link-${link.id}`);
            columnApi?.setColumnsVisible(hiddenLinks, false);
            const newUserSettings: any = { ...userSettings, selectedLinks };

            setNewTrackerSettings(newUserSettings);
        }
        setLinksModalOpened(false);
    };

    return (
        <>
            <ConfigureColumnsModal
                availableStudentColumns={availableStudentColumns}
                classTrackerUserSettings={userSettings}
                classTrackerId={classTrackerId}
                classTrackerGroupId={classTrackerGroupId}
                handleColumnConfigPassData={values => {
                    if (context.handleColumnConfigPassData) {
                        context.handleColumnConfigPassData(values);
                    }
                }}
                handleClose={saved => {
                    if (saved && context.handleConfigureColumnsChange) {
                        context.handleConfigureColumnsChange();
                    }
                    setModalOpen(false);
                }}
                open={modalOpen}
            />
            <Button
                id="column-config-btn"
                onClick={() => {
                    setModalOpen(true);
                }}
                variant="text"
                startIcon={<Icon path={mdiViewCarousel} />}
                disableRipple
                className={classes.configButton}
            >
                {t("tracker.grid.configureColumns.header")}
            </Button>
            <Button
                onClick={() => {
                    let text: string = "";
                    gridApi?.getRenderedNodes().forEach(node => {
                        text =
                            text +
                            `${node?.data?.["student_firstName"]} ${node?.data?.["student_lastName"]}\n`;
                    });

                    navigator?.clipboard.writeText(text);
                    enqueueSnackbar(t("common.copiedToClipboard"), { variant: "success" });
                }}
                variant="text"
                startIcon={<Icon path={mdiContentCopy} />}
                disableRipple
                className={classes.copyButton}
            >
                {t("common.copyList")}
            </Button>
            <div className={classes.configureContainer}>
                <div className={classes.configureItem}>
                    {context.dataType === DataTypeTypes.LIVE && !context?.archived && (
                        <CustomFormControlLabel
                            control={
                                <CustomSwitch
                                    name="tagsVisible"
                                    checked={context.tagsVisible}
                                    onChange={() => context.handleTagsVisible(!context.tagsVisible)}
                                />
                            }
                            label={t("tracker.grid.tagsToggle")}
                            id="tagsToggle"
                        />
                    )}

                    {context?.tagsVisible &&
                        !context?.archived &&
                        context.dataType === DataTypeTypes.LIVE && (
                            <Button
                                onClick={() => {
                                    setTagModalOpened(true);
                                }}
                                variant="text"
                                startIcon={<Icon path={mdiCog} />}
                                disableRipple
                                id="tagsConfigureButton"
                            >
                                {t("common.configure")}
                            </Button>
                        )}
                </div>
                <div className={classes.configureItem}>
                    {!context?.archived &&
                        (accountInfo?.hasParentPortalEnabled ||
                            accountInfo?.hasStudentPortalEnabled) && (
                            <CustomFormControlLabel
                                control={
                                    <CustomSwitch
                                        name="linksVisible"
                                        checked={context.linksVisible}
                                        onChange={() =>
                                            context.handleLinksVisible(!context.linksVisible)
                                        }
                                    />
                                }
                                label={t("tracker.grid.linksToggle")}
                                id="linksToggle"
                            />
                        )}
                    {context.linksVisible && !context?.archived && (
                        <Button
                            onClick={() => {
                                setLinksModalOpened(true);
                            }}
                            variant="text"
                            startIcon={<Icon path={mdiCog} />}
                            disableRipple
                            id="linksConfigureButton"
                        >
                            {t("common.configure")}
                        </Button>
                    )}
                </div>
            </div>

            {context.tagsVisible && (
                <>
                    <TagsModal
                        classTrackerId={classTrackerId}
                        classTrackerGroupId={classTrackerGroupId}
                        opened={tagModalOpened}
                        handleTagModalClose={handleTagModalClose}
                        selectedTags={userSettings.selectedTags}
                        handleUpsertTag={tagId => {
                            setTagUpsertModalId(tagId);
                        }}
                    />
                    <TagsUpsertModal
                        classTrackerId={classTrackerId}
                        opened={tagUpsertModalId !== null}
                        tagId={tagUpsertModalId}
                        handleClose={() => {
                            setTagUpsertModalId(null);
                        }}
                        handleCreateTag={handleCreateTag}
                    />
                </>
            )}
            {context.linksVisible && (
                <>
                    <LinksModal
                        classTrackerId={classTrackerId}
                        classTrackerGroupId={classTrackerGroupId}
                        opened={linksModalOpened}
                        handleLinkModalClose={handleLinksModalClose}
                        selectedLinks={context.selectedLinks || []}
                        handleUpsertLink={linkId => {
                            setLinksUpsertModalId(linkId);
                        }}
                    />
                    <LinksUpsertModal
                        classTrackerId={classTrackerId}
                        opened={linksUpsertModalId !== null}
                        linkId={linksUpsertModalId}
                        specificationId={
                            context?.relatedData?.specification.id ||
                            context?.classDetails?.specification.id
                        }
                        yearGroup={context?.relatedData?.yearGroup}
                        handleClose={reload => {
                            if (reload) {
                                navigate(0);
                            }
                            setLinksUpsertModalId(null);
                        }}
                    />
                </>
            )}
        </>
    );
};
