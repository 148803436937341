import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmClass } from "src/orm/models/Class";
import { getSchoolAccountId } from "src/services/url";

export const userClassesListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/search/classes/?cohortId={cohort}&yearGroup={yearGroup}`,
    method: ApiRequests.GET,
};

export type UserClassesListResponse = OrmClass[];
