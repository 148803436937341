import { ofType } from "@martin_hotell/rex-tils";
import { OrmClassActions } from "src/orm/models/Class/actions";
import { OrmUserActions } from "src/orm/models/User/actions";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { UserActions, UserActionsTypes } from "./actions";

const userTeachersListSuccessEpic = (action$: ActionsObservable<UserActions>) =>
    action$.pipe(
        ofType(UserActionsTypes.GET_USER_TEACHERS_LIST_SUCCESS),
        map((action: ReturnType<typeof UserActions.getUserTeachersListSuccess>) => action.payload),
        mergeMap(payload => of(OrmUserActions.bulkImport(payload.response))),
    );

const adminListSuccessEpic = (action$: ActionsObservable<UserActions>) =>
    action$.pipe(
        ofType(UserActionsTypes.GET_ADMIN_LIST_SUCCESS),
        map((action: ReturnType<typeof UserActions.getAdminListSuccess>) => action.payload),
        mergeMap(payload =>
            of(
                OrmUserActions.massMerge(payload.response),
                UserActions.setAdminIds(payload.response),
            ),
        ),
    );

const userClassesListSuccessEpic = (action$: ActionsObservable<UserActions>) =>
    action$.pipe(
        ofType(UserActionsTypes.GET_USER_CLASSES_SUCCESS),
        map((action: ReturnType<typeof UserActions.getUserClassesListSuccess>) => action.payload),
        mergeMap(payload => of(OrmClassActions.bulkImport(payload.response))),
    );

export const userEpics = combineEpics(
    userTeachersListSuccessEpic,
    userClassesListSuccessEpic,
    adminListSuccessEpic,
);
