import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { STUDENT_DETAILS_QUERY } from "./useStudentDetails";
import { StudentModel } from "../model/StudentModel";
import { STUDENT_LIST_QUERY } from "./useStudentList";
import { getSchoolAccountId } from "src/services/url";

export interface UpdateStudentRequest {
    id?: number;
    uniquePupilNumber: string;
    firstName: string;
    lastName: string;
    gender: string;
    pupilPremium: boolean;
    fsm: boolean;
    eal: boolean;
    email: string | null;
    senStatus: string;
    ethnicity: ListObject<string>;
    admissionNumber: string;
    dateOfBirth: string;
    academicHouse: string;
    form: string;
    lookedAfter: boolean;
    dateJoined: string;
    ks4Aps: string;
    ks4Agaps: string;
    candidateNumber: string;
    priorAbility: string;
    senNeed: ListObject<string>;
    ks2English: string;
    ks2Math: string;
    ks2FineLevel: string;
    catMean: number;
    catVerbal: number;
    catNonVerbal: number;
    catQuantitative: number;
    catSpatial: number;
    nativeSpeaker: boolean;
    readingAge: string;
}

const apiUpdateStudent = async (
    values: UpdateStudentRequest,
): Promise<AxiosResponse<StudentModel>> => {
    return await axiosInstance.put(`school/${getSchoolAccountId()}/student/${values.id}/`, values);
};

export const useStudentUpdateMutation = (onSuccess: () => void, onError?: (err) => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: UpdateStudentRequest) => apiUpdateStudent(values),
        onSuccess: data => {
            queryClient.invalidateQueries({
                queryKey: [STUDENT_DETAILS_QUERY, getSchoolAccountId(), data.data?.id],
            });
            queryClient.refetchQueries({ queryKey: [STUDENT_LIST_QUERY] });
            enqueueSnackbar(t("students.addEdit.studentUpdated"), { variant: "success" });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(
                    err.response.data?.error || t("students.addEdit.errorCreatingStudent"),
                    {
                        ...SnackbarErrorOptions,
                    },
                );
            }
            if (onError) {
                onError(err.response.data);
            }
        },
    });
};
