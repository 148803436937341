import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { EMAIL_QUERY } from "./useEmail";
import { AxiosError } from "axios";

export interface AddEmailRequest {
    emial: string;
}

const apiAddEmail = async (values: AddEmailRequest) => {
    return await axiosInstance.post(`user/email/`, values);
};
export const useAddEmailMutation = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: AddEmailRequest) => apiAddEmail(values),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [EMAIL_QUERY] });
            enqueueSnackbar(t("account.myAccountTab.addEmailSuccess"), { variant: "success" });
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("common.unknownError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
