import { Button, Typography, Box } from "@mui/material";
import { AjaxErrorCustom, ApiData, ApiStatus } from "src/api/constants";
import { DragDropFileUpload } from "src/forms/DragDropFileUpload";
import FormikObserver from "src/forms/FormikObserver";
import FormikRef from "src/forms/FormikRef";
import { FileType } from "src/forms/types";
import { usePrevious } from "src/hooks/usePrevious";
import { AppState } from "src/store/reducers";
import { Field, Form } from "formik";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { SchoolActions } from "../store/actions";
import Dialog from "src/components/Dialog";
import { useSnackbar } from "notistack";
import COLORS from "src/styles/colors";

const dispatchActions = (dispatch: Dispatch) => ({
    getImportTeachersTemplate: () => {
        dispatch(SchoolActions.getImportTeachersTemplate());
    },
    importTeachers: (importFile: FileType[]) => {
        const file = importFile.shift();
        if (file) {
            dispatch(SchoolActions.importTeachers(file));
        }
    },
});

const ImportStaffModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
    const form = createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [importError, setImportError] = useState<AjaxErrorCustom | null>(null);
    const { getImportTeachersTemplate, importTeachers } = dispatchActions(dispatch);
    const { apiImport }: { apiImport: ApiData } = useSelector(
        (state: AppState) => ({
            apiImport: state.api.school.importTeachers,
        }),
        shallowEqual,
    );

    const prevImportStatus = usePrevious(apiImport.status);

    useEffect(() => {
        if (prevImportStatus === ApiStatus.LOADING && apiImport.status === ApiStatus.SUCCESS) {
            handleClose();
            enqueueSnackbar(t("school.staff.list.importSuccess"), { variant: "success" });
        }
    }, [apiImport.status, enqueueSnackbar, handleClose, prevImportStatus, t]);

    useEffect(() => {
        if (prevImportStatus === ApiStatus.LOADING && apiImport.status === ApiStatus.ERROR) {
            setImportError(apiImport.error || null);
        }
    }, [apiImport, prevImportStatus]);

    const handleFileChange = values => {
        if (values.file !== null) {
            importTeachers(values.file);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            title={t("school.staff.list.importStaffPrompt")}
            actions={
                importError ? (
                    <Button onClick={() => setImportError(null)} color="primary">
                        {t("common.tryAgain")}
                    </Button>
                ) : (
                    <FormikRef
                        ref={form}
                        initialValues={{
                            file: null,
                        }}
                        validateOnChange={true}
                        onSubmit={() => {}}
                        enableReinitialize={true}
                    >
                        {formProps => (
                            <Form>
                                <FormikObserver
                                    values={formProps.values}
                                    onChange={handleFileChange}
                                />
                                <Button onClick={() => getImportTeachersTemplate()} color="primary">
                                    {t("school.staff.list.downloadXlsTemplate")}
                                </Button>
                                <Field
                                    name={`file`}
                                    component={DragDropFileUpload}
                                    showList={false}
                                    label={t("class.addClass.uploadImportFile")}
                                    accept={`.xlsx`}
                                    type="button"
                                    multiple={false}
                                />
                            </Form>
                        )}
                    </FormikRef>
                )
            }
        >
            {importError ? (
                <>
                    <Typography>
                        <Box component="strong">{t("school.staff.list.importFailed")}</Box>
                    </Typography>
                    <Typography>{t("school.staff.list.importFailedProblem")}</Typography>
                    <Box p={4} mt={4} bgcolor={COLORS.VERY_LIGHT_GREY_4}>
                        <Typography color="error">
                            <Box component="strong">{importError.response.error}</Box>
                        </Typography>
                        <Box
                            display="flex"
                            borderTop={`1px solid ${COLORS.VERY_LIGHT_GREY_3}`}
                            mt={2.5}
                            pt={2.5}
                        >
                            {importError.response.fileLine && (
                                <Box width={100} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("common.row")}:
                                    </Box>
                                    <Box fontWeight="fontWeightMedium" mt={0.25}>
                                        {importError.response.fileLine}
                                    </Box>
                                </Box>
                            )}
                            {importError.response.invalidValue && (
                                <Box width={100} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("common.value")}:
                                    </Box>
                                    <Box fontWeight="fontWeightMedium" mt={0.25}>
                                        {importError.response.invalidValue}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </>
            ) : (
                <Typography gutterBottom>
                    {t("school.staff.list.importStaffPromptMessage")}
                </Typography>
            )}
        </Dialog>
    );
};

export default ImportStaffModal;
