import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export interface AnalysisSpecFilter {
    filters: {
        spec: string;
        gradeTo: number;
        gradeFrom: number;
    };
}

// custom settings - will grow
export interface AnalysisUserSettingsObject {
    columnConfig?: {
        [key: string]: boolean;
    };
    expandedColumns?: {
        [key: string]: boolean;
    };
    specFilter?: AnalysisSpecFilter;
    collapsedColumns?: {
        [key: string]: boolean;
    };
    filters?: string;
    selectedTags?: number[];
    tagsVisible?: boolean;
    tagsOrder?: string[];
}

export const USER_SETTINGS_QUERY = "userSettingsQuery";

export const useUserSettings = (cohort: number, yearGroup: number, viewType: string) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [USER_SETTINGS_QUERY, schoolAccountId, cohort, yearGroup, viewType],
        queryFn: async (): Promise<AnalysisUserSettingsObject | null> => {
            if (cohort && viewType && yearGroup > -1) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/analysis/common/${cohort}/${yearGroup}/${viewType}/user-settings/`,
                );

                return data || null;
            }
            return null;
        },
    });
};
