import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { ClassTrackerHeaderObject } from "src/modules/tracker/dto/TrackerHeader";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_COMPARE_HEADER_QUERY = "classTrackerGroupCompareHeader";

export const useClassTrackerGroupCompareHeader = (
    id: number,
    tier: TiersTypes,
    dataSource1: "live-tracking" | "forecast" | string,
    dataSource2: "live-tracking" | "forecast" | string,
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            CLASS_TRACKER_GROUP_COMPARE_HEADER_QUERY,
            schoolAccountId,
            id,
            tier,
            dataSource1,
            dataSource2,
        ],
        queryFn: async (): Promise<ClassTrackerHeaderObject | null> => {
            if (id && tier && dataSource1 && dataSource2) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker-group/${id}/${
                        tier || "-"
                    }/compare/${dataSource1}/${dataSource2}/header/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
