import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { TrackerActionTypes, TrackerActions } from "../actions";
import FileSaver from "file-saver";
import { NotificationActions } from "src/notifications/store/actions";
import { HTTP_SUCCESS_NON_AUTHORIATIVE } from "src/config/globals";

const getForecastClassTrackerValuesSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_FORECAST_CLASS_TRACKER_VALUES_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getForecastClassTrackerValuesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerValues(payload.response))),
    );

const getForecastClassTrackerGroupValuesSuccessEpic = (
    action$: ActionsObservable<TrackerActions>,
) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_FORECAST_CLASS_TRACKER_GROUP_VALUES_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getForecastClassTrackerGroupValuesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerValues(payload.response))),
    );

const getForecastClassTrackerGroupUnitsSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_FORECAST_CLASS_TRACKER_GROUP_UNITS_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getForecastClassTrackerGroupUnitsSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerGroupUnits(payload.response))),
    );

const loadForecastClassTrackerCheckSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.LOAD_FORECAST_CLASS_TRACKER_CHECK_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.loadForecastClassTrackerCheckSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            const response = payload as any;
            if (response.status === HTTP_SUCCESS_NON_AUTHORIATIVE) {
                return of(TrackerActions.setLoadForecastOverlapUnits(response.response));
            } else {
                return of();
            }
        }),
    );

const clearForecastClassTrackerValuesSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.CLEAR_FORECAST_CLASS_TRACKER_VALUES_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.clearForecastClassTrackerValuesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(TrackerActions.setClassTrackerValues(payload.response));
        }),
    );

const loadForecastClassTrackerGroupCheckSuccessEpic = (
    action$: ActionsObservable<TrackerActions>,
) =>
    action$.pipe(
        ofType(TrackerActionTypes.LOAD_FORECAST_CLASS_TRACKER_GROUP_CHECK_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.loadForecastClassTrackerGroupCheckSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            const response = payload as any;
            if (response.status === HTTP_SUCCESS_NON_AUTHORIATIVE) {
                return of(TrackerActions.setLoadForecastOverlapUnits(response.response));
            } else {
                return of();
            }
        }),
    );

const clearForecastClassTrackerGroupValuesSuccessEpic = (
    action$: ActionsObservable<TrackerActions>,
) =>
    action$.pipe(
        ofType(TrackerActionTypes.CLEAR_FORECAST_CLASS_TRACKER_GROUP_VALUES_SUCCESS),
        map(
            (
                action: ReturnType<
                    typeof TrackerActions.clearForecastClassTrackerGroupValuesSuccess
                >,
            ) => action.payload,
        ),
        mergeMap(payload => {
            return of(TrackerActions.setClassTrackerValues(payload.response));
        }),
    );

const getStudentForecastReportDownloadEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_STUDENT_FORECAST_REPORT_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getStudentForecastReportDownloadSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            const { fileName } = payload.request.payload.values as {
                fileName: string;
            };
            FileSaver.saveAs(payload.response, `${fileName}.pdf`);
            return of(
                NotificationActions.enqueueSnackbar({
                    message: "Student report downloaded",
                    variant: "success",
                }),
            );
        }),
    );

export const forecastClassTrackerEpics = combineEpics(
    getForecastClassTrackerValuesSuccessEpic,
    getForecastClassTrackerGroupValuesSuccessEpic,
    getForecastClassTrackerGroupUnitsSuccessEpic,
    loadForecastClassTrackerCheckSuccessEpic,
    clearForecastClassTrackerValuesSuccessEpic,
    loadForecastClassTrackerGroupCheckSuccessEpic,
    clearForecastClassTrackerGroupValuesSuccessEpic,
    getStudentForecastReportDownloadEpic,
);
