import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TagModel } from "src/modules/tagging/model/TagModel";

interface OwnProps {
    tags: TagModel[];
    handleTagFiltersChange: (tags: TagModel[]) => void;
}

const TagFilter: FC<OwnProps> = ({ tags, handleTagFiltersChange }) => {
    const { t } = useTranslation();
    const [selectedTags, setSelectedTags] = useState<number[]>([]);

    return (
        <>
            <FormControl fullWidth margin="normal">
                <InputLabel shrink>{t("common.filterByTagColumn")}</InputLabel>
                <Select
                    value={selectedTags}
                    multiple
                    displayEmpty
                    label={t("common.filterByTagColumn")}
                    onChange={e => {
                        setSelectedTags(e.target.value as number[]);
                    }}
                >
                    <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                    {tags?.map(option =>
                        option ? (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ) : (
                            <></>
                        ),
                    )}
                </Select>
            </FormControl>

            <Box pt={2}>
                <Button
                    color="primary"
                    onClick={() => {
                        const sTags = tags?.filter(t => selectedTags.includes(t.id));

                        handleTagFiltersChange(sTags);
                    }}
                >
                    {t("common.filterAdd")}
                </Button>
            </Box>
        </>
    );
};

export default TagFilter;
