import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { FileType } from "src/forms/types";
import { OrmUser } from "src/orm/models/User";
import { getSchoolAccountId } from "src/services/url";

export const importTeachersEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/staff/import-teachers/`,
    method: ApiRequests.POST,
};

export interface ImportTeachersRequest {
    file: FileType;
}

export type ImportTeachersResponse = OrmUser[];
