import {
    Box,
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField as MuiTextField,
    Tooltip,
} from "@mui/material";
import { mdiArrowUp, mdiArrowDown, mdiDelete } from "@mdi/js";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "src/components/Icon";
import { OrmSchoolCustomField } from "src/orm/models/SchoolCustomField";
import { CustomFieldsDefaultConfigResponse } from "../hooks/query/CustomFields/useCustomFieldsDefaultConfig";

interface OwnProps {
    customFieldIdx: number;
    configIdx: number;
    config: OrmSchoolCustomField;
    canSaveConfigs: boolean;
    customFieldsDefaultConfig: CustomFieldsDefaultConfigResponse;
    handleElementRemove: (
        customFieldIdx: number,
        configIdx: number,
        fieldConfigIdx: number,
    ) => void;
    handleElementMove: (
        customFieldIdx: number,
        configIdx: number,
        fieldConfigIdx: number,
        up: boolean,
    ) => void;
    handleAddValue: (customFieldIdx: number, configIdx: number, value: string) => void;
}

const CustomFieldConfigItem: FC<OwnProps> = ({
    customFieldIdx,
    configIdx,
    config,
    canSaveConfigs,
    customFieldsDefaultConfig,
    handleElementRemove,
    handleElementMove,
    handleAddValue,
}) => {
    const { t } = useTranslation();
    const [tmpValue, setTmpValue] = useState<string>("");
    const addNewValue = (configIdx: number) => {
        handleAddValue(customFieldIdx, configIdx, tmpValue);
        setTmpValue("");
    };

    return (
        <>
            {config.isEnabled &&
                customFieldsDefaultConfig &&
                customFieldsDefaultConfig[customFieldIdx] &&
                config.useDefaultValues && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("school.customFields.marks")}</TableCell>
                                <TableCell>{t("school.customFields.description")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customFieldsDefaultConfig[customFieldIdx].values.map(
                                (defaultConfig, defConIdx) => (
                                    <TableRow key={defConIdx}>
                                        <TableCell width={60}>
                                            {customFieldsDefaultConfig[customFieldIdx].values
                                                .length - defConIdx}
                                        </TableCell>
                                        <TableCell>{defaultConfig}</TableCell>
                                    </TableRow>
                                ),
                            )}
                        </TableBody>
                    </Table>
                )}

            {!config.useDefaultValues && (
                <Box>
                    <Field
                        disabled={!canSaveConfigs}
                        name={`fields[${customFieldIdx}].configurations[${configIdx}].displayedName`}
                        label={t("school.customFields.displayedName")}
                        component={TextField}
                    />
                    <Box mt={4}>
                        <Grid container spacing={1}>
                            <Grid item sm={6}>
                                <MuiTextField
                                    fullWidth
                                    value={tmpValue}
                                    placeholder={t("school.customFields.description")}
                                    disabled={!canSaveConfigs || config.useDefaultValues}
                                    onChange={e => setTmpValue(e.target.value as string)}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Button
                                    disabled={
                                        !canSaveConfigs ||
                                        config.useDefaultValues ||
                                        !tmpValue[configIdx]
                                    }
                                    onClick={() => addNewValue(configIdx)}
                                >
                                    {t("common.add")}
                                </Button>
                            </Grid>
                        </Grid>

                        <Box>
                            {config.fieldValues?.length > 0 ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t("school.customFields.marks")}</TableCell>
                                            <TableCell>
                                                {t("school.customFields.description")}
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {config.fieldValues.map((_, fieldValueIdx) => (
                                            <TableRow key={fieldValueIdx}>
                                                <TableCell width={60}>
                                                    {config.fieldValues.length - fieldValueIdx}
                                                </TableCell>
                                                <TableCell>
                                                    {config.fieldValues[fieldValueIdx]}
                                                </TableCell>
                                                <TableCell width={120}>
                                                    <Box
                                                        display="grid"
                                                        gridTemplateColumns="repeat(3, 30px)"
                                                        gap={2}
                                                    >
                                                        <Box>
                                                            {fieldValueIdx > 0 ? (
                                                                <Tooltip
                                                                    title={
                                                                        t(
                                                                            "school.customFields.upBtn",
                                                                        ) || ""
                                                                    }
                                                                >
                                                                    <IconButton
                                                                        aria-label={t(
                                                                            "school.customFields.upBtn",
                                                                        )}
                                                                        onClick={() => {
                                                                            handleElementMove(
                                                                                customFieldIdx,
                                                                                configIdx,
                                                                                fieldValueIdx,
                                                                                true,
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            !canSaveConfigs ||
                                                                            config.useDefaultValues
                                                                        }
                                                                        disableFocusRipple
                                                                        disableRipple
                                                                        size="small"
                                                                    >
                                                                        <Icon
                                                                            size={1}
                                                                            path={mdiArrowUp}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Box>
                                                        <Box>
                                                            {fieldValueIdx <
                                                            config.fieldValues.length - 1 ? (
                                                                <Tooltip
                                                                    title={
                                                                        t(
                                                                            "school.customFields.downBtn",
                                                                        ) || ""
                                                                    }
                                                                >
                                                                    <IconButton
                                                                        aria-label={t(
                                                                            "school.customFields.downBtn",
                                                                        )}
                                                                        onClick={() => {
                                                                            handleElementMove(
                                                                                customFieldIdx,
                                                                                configIdx,
                                                                                fieldValueIdx,
                                                                                false,
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            !canSaveConfigs ||
                                                                            config.useDefaultValues
                                                                        }
                                                                        disableFocusRipple
                                                                        disableRipple
                                                                        size="small"
                                                                    >
                                                                        <Icon
                                                                            size={1}
                                                                            path={mdiArrowDown}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Box>
                                                        <Box>
                                                            <Tooltip
                                                                title={t("common.delete") || ""}
                                                            >
                                                                <IconButton
                                                                    aria-label={t("common.delete")}
                                                                    onClick={() =>
                                                                        handleElementRemove(
                                                                            customFieldIdx,
                                                                            configIdx,
                                                                            fieldValueIdx,
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        !canSaveConfigs ||
                                                                        config.useDefaultValues
                                                                    }
                                                                    disableFocusRipple
                                                                    disableRipple
                                                                    size="small"
                                                                >
                                                                    <Icon
                                                                        size={1}
                                                                        path={mdiDelete}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default CustomFieldConfigItem;
