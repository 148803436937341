import { Button, Paper, Typography } from "@mui/material";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import AppContainer from "src/components/AppContainer";
import FormikRef from "src/forms/FormikRef";
import TagAddEditForm, {
    TagAddEditFormShape,
    addEditTagSchema,
    emptyTagForm,
} from "../components/forms/TagAddEditForm";
import { useCreateTagMutation } from "../hooks/useCreateMutation";
import { ROUTE_MY_SCHOOL_TAGS } from "src/routes";
import { TagEditRequest, useEditTagMutation } from "../hooks/useEditMutation";
import { useTagDetails } from "../hooks/useTagDetails";
import PaperInner from "src/components/PaperInner";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const TagUpsertContainer = () => {
    const { t } = useTranslation();
    const { tag } = useParams();
    const tagId = tag ? parseInt(tag) : 0;
    const form = useRef();
    const { navigate } = useSchoolNavigate();

    const handleErrorResponse = useCallback(
        errors => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            if (errors) currentForm.setErrors(errors);
        },
        [form],
    );

    const { mutate: addTag } = useCreateTagMutation(
        () => navigate(ROUTE_MY_SCHOOL_TAGS),
        ({ errors }) => handleErrorResponse(errors),
    );
    const { mutate: editTag } = useEditTagMutation(
        () => navigate(ROUTE_MY_SCHOOL_TAGS),
        ({ errors }) => handleErrorResponse(errors),
    );

    const { data: tagDetails } = useTagDetails(tagId);

    const initialValues = tagId
        ? {
              ...emptyTagForm,
              ...tagDetails,
              allYearGroups: tagDetails?.yearGroups?.length > 0 ? "selected" : "all",
              allSubjects: tagDetails?.specifications?.length > 0 ? "selected" : "all",
              yearGroups: tagDetails?.yearGroups || [],
              specifications: tagDetails?.specifications || [],
          }
        : emptyTagForm;

    const handleSubmit = ({ name, accessLevel, status, ...restValues }: TagAddEditFormShape) => {
        const data: TagEditRequest = {
            id: tagId,
            name,
            accessLevel,
            status,
            yearGroups:
                restValues.allYearGroups === "all" || restValues.yearGroups.length === 0
                    ? null
                    : restValues.yearGroups,
            specifications:
                restValues.allSubjects === "all" || restValues.specifications.length === 0
                    ? null
                    : restValues.specifications.map(({ specificationId }) => ({
                          id: specificationId,
                      })),
        };

        if (tagId) {
            editTag(data);
        } else {
            addTag(data);
        }
    };

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {tagId ? t("tag.addEdit.headerEdit") : t("tag.addEdit.headerAdd")}
            </Typography>
            <Paper>
                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => addEditTagSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {({ submitForm, errors, submitCount, isSubmitting }) => (
                        <>
                            <TagAddEditForm />
                            <PaperInner border="top">
                                <Button
                                    onClick={submitForm}
                                    color="primary"
                                    disabled={isSubmitting}
                                    id="saveChangesButton"
                                >
                                    {t("common.saveChanges")}
                                </Button>
                                <GenericErrorMessage errors={errors} submitCount={submitCount} />
                            </PaperInner>
                        </>
                    )}
                </FormikRef>
            </Paper>
        </AppContainer>
    );
};
export default TagUpsertContainer;
