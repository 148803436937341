import { FC, useState } from "react";
import { Box, Chip, Typography, styled } from "@mui/material";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import COLORS from "src/styles/colors";
import { useTranslation } from "react-i18next";
import Icon from "src/components/Icon";
import { mdiChevronDown } from "@mdi/js";
import ChangeClassStyleMenu from "../Common/ChangeClassStyleMenu";

const ClassChooseButton = styled("button")(() => ({
    cursor: "pointer",
    color: COLORS.GREY_1,
    display: "flex",
    alignItems: "center",
    border: "0 none",
    outline: "0 none",
    padding: 0,
    margin: 0,
    backgroundColor: "transparent",
}));
interface OwnProps {
    classDetails: OrmClassTracker;
}

const TrackerClassDetails: FC<OwnProps> = ({ classDetails }) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Chip
                style={{ backgroundColor: classDetails.subjectArea?.colour }}
                label={classDetails.subjectArea?.name}
            />
            <Box
                display="inline-flex"
                flexDirection="column"
                borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                minHeight={45}
                pl={3}
                ml={3}
            >
                <Box fontSize={11} lineHeight={1.36} color={COLORS.LIGHT_GREY_1} mb={0.375}>
                    {`${classDetails.qualification?.name} > ${classDetails.specification?.name} > ${
                        classDetails.yearGroup === 0
                            ? t("common.yg0")
                            : t("common.yearGroup") + " " + classDetails.yearGroup
                    }`}
                </Box>
                <ClassChooseButton onClick={handleClick}>
                    <>
                        <Box display="flex" alignItems="center">
                            <Typography component="h1" variant="h1">
                                {`${classDetails.name}`}
                            </Typography>
                            {classDetails.isDemo && (
                                <Box ml={2}>
                                    <Chip size="small" label="DEMO" />
                                </Box>
                            )}
                        </Box>
                        <Icon path={mdiChevronDown} size="30px" />
                    </>
                </ClassChooseButton>
                <ChangeClassStyleMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    classDetailsYearGroup={classDetails?.yearGroup}
                    classDetailsId={classDetails?.id}
                    classDetailsGualificationId={classDetails?.qualification?.id}
                    classDetailsSpecificationId={classDetails?.specification?.id}
                />
            </Box>
        </>
    );
};

export default TrackerClassDetails;
