import { SortCallback } from "./types";

export const stableSort = <T>(array: T[], cmp: SortCallback<T>): T[] => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
};

export const nullableSort = <T>(a: T | null, b: T | null, sort: SortCallback<T>): number => {
    if (a === null) {
        return b === null ? 0 : -1;
    }

    return b === null ? 1 : sort(a, b);
};

export const sortCallbacks: { [key: string]: SortCallback<any> } = {
    numeric: (a: number, b: number): number => a - b,
    string: (a: string, b: string): number => a.localeCompare(b),
    nullableNumeric: (a: number | null, b: number | null): number =>
        nullableSort<number>(a, b, sortCallbacks.numeric),
    nullableString: (a: string | null, b: string | null): number =>
        nullableSort<string>(a, b, sortCallbacks.string),
};

export const sortKey =
    <T>(key: string, sortCallback: SortCallback<T[keyof T]>) =>
    (a: T, b: T): number =>
        sortCallback(a[key], b[key]);

export const nullableSortKey =
    <T>(key: string, sortCallback: SortCallback<T[keyof T]>) =>
    (a: T, b: T): number =>
        nullableSort(a[key], b[key], sortCallback);
