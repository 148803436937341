import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction, paramRequest } from "src/api/helpers";
import { getClassTrackerHeaderEndpoint } from "../../api/ClassTracker/getClassTrackerHeader";
import { getClassTrackerValuesEndpoint } from "../../api/ClassTracker/getClassTrackerValues";
import { getTrackerRelatedEndpoint } from "../../api/ClassTracker/getClassTrackerRelated";
import {
    getLiveGradeBoundarySeriesEndpoint,
    GradeBoundariesSeriesObject,
} from "../../api/GradeBoundaries/getLiveGradeBoundarySeries";
import { changeGradeBoundaryEndpoint } from "../../api/GradeBoundaries/changeGradeBoundary";
import { getStudentReportDownloadEndpoint } from "../../api/getStudentReport";
import { ClassTrackerParam, IdParam } from "src/forms/types";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { ClassTrackerHeaderObject } from "../../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../../dto/TrackerRelated";
import { ClassTrackerValuesObject } from "../../dto/TrackerValues";

export enum ClassTrackerActionsTypes {
    GET_CLASS_TRACKER_HEADER = "[tracker] GET CLASS TRACKER HEADER",
    GET_CLASS_TRACKER_HEADER_SUCCESS = "[tracker] GET CLASS TRACKER HEADER SUCCESS",
    GET_CLASS_TRACKER_FORECAST_HEADER = "[tracker] GET CLASS TRACKER FORECAST HEADER",
    GET_CLASS_TRACKER_FORECAST_HEADER_SUCCESS = "[tracker] GET CLASS TRACKER FORECAST HEADER SUCCESS",
    GET_CLASS_TRACKER_VALUES = "[tracker] GET CLASS TRACKER VALUES",
    GET_CLASS_TRACKER_VALUES_SUCCESS = "[tracker] GET CLASS TRACKER VALUES SUCCESS",
    GET_CLASS_TRACKER_USER_SETTINGS = "[tracker] GET CLASS TRACKER USER SETTINGS",
    GET_CLASS_TRACKER_USER_SETTINGS_SUCCESS = "[tracker] GET CLASS TRACKER USER SETTINGS SUCCESS",
    GET_CLASS_TRACKER_RELATED = "[tracker] GET CLASS TRACKER RELATED",
    GET_CLASS_TRACKER_RELATED_SUCCESS = "[tracker] GET CLASS TRACKER RELATED SUCCESS",
    SET_CLASS_TRACKER_RELATED = "[tracker] SET CLASS TRACKER RELATED",
    SET_CLASS_TRACKER_HEADER = "[tracker] SET CLASS TRACKER HEADER",
    SET_CLASS_TRACKER_VALUES = "[tracker] SET CLASS TRACKER VALUES",
    GET_CLASS_LIVE_GRADE_BOUNDARIES = "[tracker] GET CLASS LIVE GRADE BOUNDARIES",
    GET_CLASS_LIVE_GRADE_BOUNDARIES_SUCCESS = "[tracker] GET CLASS LIVE GRADE BOUNDARIES SUCCESS",
    SET_CLASS_LIVE_GRADE_BOUNDARIES = "[tracker] SET CLASS LIVE GRADE BOUNDARIES",
    CHANGE_CLASS_GRADE_BOUNDARY = "[tracker] CHANGE CLASS GRADE BOUNDARY",
    CHANGE_CLASS_GRADE_BOUNDARY_SUCCESS = "[tracker] CHANGE CLASS GRADE BOUNDARY SUCCESS",
    CLEAR_CLASS_TRACKER = "[tracker] CLEAR CLASS TRACKER",
    CLEAR_CLASS_TRACKER_VALUES = "[tracker] CLEAR CLASS TRACKER VALUES",
    CLEAR_CLASS_TRACKER_HEADER = "[tracker] CLEAR CLASS TRACKER HEADER",
    CLEAR_CLASS_TRACKER_LIST = "[tracker] CLEAR CLASS TRACKER LIST",
    CLEAR_CLASS_TRACKER_RELATED = "[tracker] CLEAR CLASS TRACKER RELATED",
    GET_STUDENT_REPORT = "[tracker] GET STUDENT REPORT",
    GET_STUDENT_REPORT_SUCCESS = "[tracker] GET STUDENT REPORT SUCCESS",
}

export const ClassTrackerApiEndpoints = {
    getClassTrackerHeader: createActionObject(
        getClassTrackerHeaderEndpoint,
        ClassTrackerActionsTypes.GET_CLASS_TRACKER_HEADER_SUCCESS,
    ),
    getClassTrackerValues: createActionObject(
        getClassTrackerValuesEndpoint,
        ClassTrackerActionsTypes.GET_CLASS_TRACKER_VALUES_SUCCESS,
    ),
    getClassTrackerRelated: createActionObject(
        getTrackerRelatedEndpoint,
        ClassTrackerActionsTypes.GET_CLASS_TRACKER_RELATED_SUCCESS,
    ),
    getClassTrackerLiveGradeBoundaries: createActionObject(
        getLiveGradeBoundarySeriesEndpoint,
        ClassTrackerActionsTypes.GET_CLASS_LIVE_GRADE_BOUNDARIES_SUCCESS,
    ),
    changeClassGradeBoudary: createActionObject(
        changeGradeBoundaryEndpoint,
        ClassTrackerActionsTypes.CHANGE_CLASS_GRADE_BOUNDARY_SUCCESS,
    ),
    getStudentReport: createActionObject(
        getStudentReportDownloadEndpoint,
        ClassTrackerActionsTypes.GET_STUDENT_REPORT_SUCCESS,
    ),
};

export const ClassTrackerRawActions = (
    ClassTrackerEndpoints: Record<Keys<typeof ClassTrackerApiEndpoints>, ApiActionMeta>,
) => ({
    getClassTrackerHeader: (classTracker: number, tier: TiersTypes) =>
        createApiAction(
            ClassTrackerActionsTypes.GET_CLASS_TRACKER_HEADER,
            { values: {}, params: { classTracker, tier } },
            ClassTrackerEndpoints.getClassTrackerHeader,
        ),
    getClassTrackerHeaderSuccess: (payload: ApiCallbackPayload<ClassTrackerHeaderObject>) =>
        createAction(ClassTrackerActionsTypes.GET_CLASS_TRACKER_HEADER_SUCCESS, payload),
    setClassTrackerHeader: (payload: ClassTrackerHeaderObject) =>
        createAction(ClassTrackerActionsTypes.SET_CLASS_TRACKER_HEADER, payload),
    getClassTrackerValues: (classTracker: number, tier: TiersTypes) =>
        createApiAction(
            ClassTrackerActionsTypes.GET_CLASS_TRACKER_VALUES,
            { params: { classTracker, tier }, values: {} },
            ClassTrackerEndpoints.getClassTrackerValues,
        ),
    getClassTrackerValuesSuccess: (payload: ApiCallbackPayload<ClassTrackerValuesObject>) =>
        createAction(ClassTrackerActionsTypes.GET_CLASS_TRACKER_VALUES_SUCCESS, payload),
    getClassTrackerLiveGradeBoundaries: (classTrackerGroup: number) =>
        createApiAction(
            ClassTrackerActionsTypes.GET_CLASS_LIVE_GRADE_BOUNDARIES,
            paramRequest({ classTrackerGroup }),
            ClassTrackerEndpoints.getClassTrackerLiveGradeBoundaries,
        ),
    getClassTrackerLiveGradeBoundariesSuccess: (
        payload: ApiCallbackPayload<GradeBoundariesSeriesObject[]>,
    ) => createAction(ClassTrackerActionsTypes.GET_CLASS_LIVE_GRADE_BOUNDARIES_SUCCESS, payload),
    setClassLiveGradeBoundaries: (payload: GradeBoundariesSeriesObject[] | null) =>
        createAction(ClassTrackerActionsTypes.SET_CLASS_LIVE_GRADE_BOUNDARIES, payload),
    setClassTrackerValues: (payload: ClassTrackerValuesObject) =>
        createAction(ClassTrackerActionsTypes.SET_CLASS_TRACKER_VALUES, payload),
    getClassTrackerRelated: (classTracker: number) =>
        createApiAction(
            ClassTrackerActionsTypes.GET_CLASS_TRACKER_RELATED,
            paramRequest({ classTracker }),
            ClassTrackerEndpoints.getClassTrackerRelated,
        ),
    getClassTrackerRelatedSuccess: (payload: ApiCallbackPayload<ClassTrackerRelatedObject>) =>
        createAction(ClassTrackerActionsTypes.GET_CLASS_TRACKER_RELATED_SUCCESS, payload),
    setClassTrackerRelated: (payload: ClassTrackerRelatedObject) =>
        createAction(ClassTrackerActionsTypes.SET_CLASS_TRACKER_RELATED, payload),
    changeClassGradeBoundary: (
        classTrackerGroup: number,
        series: number,
        gradeBoundaryFactor: number,
    ) =>
        createApiAction(
            ClassTrackerActionsTypes.CHANGE_CLASS_GRADE_BOUNDARY,
            { values: { gradeBoundaryFactor }, params: { classTrackerGroup, series } },
            ClassTrackerEndpoints.changeClassGradeBoudary,
        ),
    changeClassGradeBoundarySuccess: () =>
        createAction(ClassTrackerActionsTypes.CHANGE_CLASS_GRADE_BOUNDARY_SUCCESS),
    clearClassTrackerList: () => createAction(ClassTrackerActionsTypes.CLEAR_CLASS_TRACKER_LIST),
    clearClassTracker: () => createAction(ClassTrackerActionsTypes.CLEAR_CLASS_TRACKER),
    clearClassTrackerValues: () =>
        createAction(ClassTrackerActionsTypes.CLEAR_CLASS_TRACKER_VALUES),
    clearClassTrackerHeader: () =>
        createAction(ClassTrackerActionsTypes.CLEAR_CLASS_TRACKER_HEADER),
    clearClassTrackerRelated: () =>
        createAction(ClassTrackerActionsTypes.CLEAR_CLASS_TRACKER_RELATED),
    getStudentReportDownload: (classTracker: number, id: number, fileName: string) =>
        createApiAction(
            ClassTrackerActionsTypes.GET_STUDENT_REPORT,
            { values: { fileName }, params: { classTracker, id } },
            ClassTrackerEndpoints.getStudentReport,
        ),
    getStudentReportDownloadSuccess: (
        payload: ApiCallbackPayload<Blob, {}, ClassTrackerParam & IdParam>,
    ) => createAction(ClassTrackerActionsTypes.GET_STUDENT_REPORT_SUCCESS, payload),
});
