import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { SCHOOL_DETAILS_QUERY, SchoolDetailsResponse } from "./useSchoolDetails";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface SchoolEditRequest {
    privacyPolicyUrl: string;
    contactEmail: string;
    fineGrades: boolean;
    yearGroupsWithLockedMtg: number[];
}

const apiEditSchoolDetails = async (
    schoolAccountId,
    values: SchoolEditRequest,
): Promise<AxiosResponse<SchoolDetailsResponse>> => {
    return await axiosInstance.put(`school/${schoolAccountId}/account/`, values);
};

export const useEditSchoolDetails = (schoolAccount?: number) => {
    const schoolAccountId = schoolAccount || getSchoolAccountId() || null;

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: SchoolEditRequest) => apiEditSchoolDetails(schoolAccountId, values),

        onSuccess: data => {
            queryClient.setQueryData([SCHOOL_DETAILS_QUERY, schoolAccountId], data?.data);
            enqueueSnackbar(t("school.schoolDetails.saveEdit"), { variant: "success" });
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            /* if (
                apiEditSchool.status === ApiStatus.ERROR &&
                apiEditSchool.error?.response?.errors["privacyPolicyUrl"]
            ) {
                enqueueSnackbar(apiEditSchool.error?.response.errors["privacyPolicyUrl"], {
                    ...SnackbarErrorOptions,
                });
            }
            if (
                apiEditSchool.status === ApiStatus.ERROR &&
                apiEditSchool.error?.response?.errors["fineGrades"]
            ) {
                enqueueSnackbar(apiEditSchool.error?.response.errors["fineGrades"], {
                    ...SnackbarErrorOptions,
                });
            }
            if (
                apiEditSchool.status === ApiStatus.ERROR &&
                apiEditSchool.error?.response?.errors["contactEmail"]
            ) {
                enqueueSnackbar(apiEditSchool.error?.response.errors["contactEmail"], {
                    ...SnackbarErrorOptions,
                });
            }*/
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("common.unknownError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
