import FileSaver from "file-saver";
import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

export interface ExportStudentsRequest {
    ids?: number[] | null;
}

const apiExportStudentEndpoint = async (values: ExportStudentsRequest): Promise<AxiosResponse> => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/student/export/`,
        { ext: "xlsx", ...values },
        { responseType: "blob" },
    );
};

export const useExportStudentsMutation = (onSuccess?: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: ExportStudentsRequest) => apiExportStudentEndpoint(values),
        onSuccess: res => {
            FileSaver.saveAs(res.data, `Pupil Progress Student Export.xlsx`);

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(err.response.data?.error || t("students.list.exportStudentsFailed"), {
                ...SnackbarErrorOptions,
            });
        },
    });
};
