import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ClassTrackerStudentTagModel } from "../../model/TagModel";
import { getSchoolAccountId } from "src/services/url";

export const ANALYSIS_STUDENT_TAG_LIST_QUERY = "analysisStudentTagListQuery";

export const useAnalysisStudentTags = (cohort: number, yearGroup: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [ANALYSIS_STUDENT_TAG_LIST_QUERY, schoolAccountId, cohort, yearGroup],
        queryFn: async (): Promise<ClassTrackerStudentTagModel[] | null> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/analysis/student-tags/${cohort}/${yearGroup}/`,
            );

            return data || null;
        },
    });
};
