import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { FileType } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const uploadAndSendClassFilesEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker/upload-and-send/{id}/`,
    method: ApiRequests.POST,
};

export interface UploadClassFilesRequest {
    file: FileType;
}
