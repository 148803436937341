import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AnalysisActionTypes, AnalysisActions } from "../actions";

const getAnalysisAtlHeaderSuccessEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_ATL_HEADER_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.getAnalysisAtlHeaderSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisAtlHeader(payload.response))),
    );

const getAnalysisAtlValuesSuccessEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_ATL_VALUES_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.getAnalysisAtlValuesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisAtlValues(payload.response))),
    );

const getAnalysisAtlSnapshotSuccessEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_ATL_SNAPSHOT_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.getAnalysisAtlSnapshotSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisAtlValues(payload.response))),
    );

const getAnalysisAtlSnapshotCompareSuccessEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_ATL_SNAPSHOT_COMPARE_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.getAnalysisAtlSnapshotCompareSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisAtlCompare(payload.response))),
    );

export const analysisAtlEpics = combineEpics(
    getAnalysisAtlHeaderSuccessEpic,
    getAnalysisAtlValuesSuccessEpic,
    getAnalysisAtlSnapshotSuccessEpic,
    getAnalysisAtlSnapshotCompareSuccessEpic,
);
