import clsx from "clsx";
import COLORS from "src/styles/colors";
import { darken } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { AnalysisHeaderNodes } from "src/modules/analysis/services/analysisHeaderGenerator";
import Icon from "@mdi/react";
import { mdiSortAscending, mdiSortDescending } from "@mdi/js";

const SpecHeaderRenderer = ({ column, setSort, ...props }) => {
    const { t }: { t: TFunction } = useTranslation();
    const subjectArea = props.headerConfig?.subjectArea;
    const subjectAreaColour = props.headerConfig?.chipColour;
    const nodeType = props.nodeType;
    const level = props.level;
    const isDisplayField = props.headerConfig?.isDisplay || false;
    const sortDirection = column.getSort();
    return (
        <div
            className={clsx(
                "ag-spec-header",
                nodeType === AnalysisHeaderNodes.BUCKET && "ag-spec-header--bucket",
            )}
            title={props.displayName}
            onClick={
                column.getColDef().sortable
                    ? () => {
                          setSort(!sortDirection ? "asc" : sortDirection === "asc" ? "desc" : null);
                      }
                    : undefined
            }
        >
            <div
                style={{
                    backgroundColor:
                        (isDisplayField ? darken(props.columnColour, 0.02) : props.columnColour) ||
                        COLORS.VERY_LIGHT_GREY_8,
                }}
                className={clsx(
                    "ag-spec-header-row",
                    level && `ag-spec-header-row--level-${level}`,
                )}
            >
                <div className="ag-spec-header-vertical">
                    <div className="ag-spec-header-group">
                        {nodeType === AnalysisHeaderNodes.SPEC && (
                            <div className="ag-spec-header-above-label">
                                {subjectArea ? (
                                    <div className="ag-default-header__chip-container">
                                        <div
                                            className="ag-default-header__chip"
                                            style={{
                                                backgroundColor: subjectAreaColour || "transparent",
                                            }}
                                        >
                                            {subjectArea}
                                        </div>
                                    </div>
                                ) : (
                                    `${t("analysis.grid.specification")}:`
                                )}
                            </div>
                        )}
                        <div className="ag-spec-header-label ag-spec-header-label--truncate">
                            {props.displayName}
                        </div>
                    </div>
                    {sortDirection && (
                        <b className="ag-column-header-label__sort">
                            {sortDirection === "asc" ? (
                                <Icon path={mdiSortAscending} size="18px" />
                            ) : (
                                <Icon path={mdiSortDescending} size="18px" />
                            )}
                        </b>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SpecHeaderRenderer;
