import { TextField } from "@mui/material";
import { FC } from "react";

interface OwnProps {
    label?: React.ReactNode;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    value?: string | number;
    id?: string;
    placeholder?: string;
    disabled?: boolean;
}

const FilterTextField: FC<OwnProps> = ({
    label,
    id,
    value,
    placeholder,
    disabled = false,
    ...props
}) => (
    <TextField
        margin="none"
        label={label}
        disabled={disabled}
        value={value || ""}
        placeholder={placeholder}
        id={id}
        InputLabelProps={{
            shrink: true,
        }}
        {...props}
    />
);

export default FilterTextField;
