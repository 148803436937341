import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { QualificationParam } from "src/forms/types";
import {
    commonSpecificationsEndpoint,
    CommonSpecificationsResponse,
} from "../../api/specifications";

export enum CommonSpecificationActionsTypes {
    GET_SPECIFICATION_LIST = "[common] GET SPECIFICATION LIST",
    GET_SPECIFICATION_LIST_SUCCESS = "[common] GET SPECIFICATION LIST SUCCESS",
}

export const CommonSpecificationApiEndpoints = {
    getSpecificationList: createActionObject(
        commonSpecificationsEndpoint,
        CommonSpecificationActionsTypes.GET_SPECIFICATION_LIST_SUCCESS,
    ),
};

export const CommonSpecificationRawActions = (
    CommonEndpoints: Record<Keys<typeof CommonSpecificationApiEndpoints>, ApiActionMeta>,
) => ({
    getSpecificationList: (qualificationId: number, hasClasses?: boolean) =>
        createApiAction(
            CommonSpecificationActionsTypes.GET_SPECIFICATION_LIST,
            { params: { qualification: qualificationId }, values: { hasClasses } },
            CommonEndpoints.getSpecificationList,
        ),
    getSpecificationListSuccess: (
        payload: ApiCallbackPayload<CommonSpecificationsResponse, {}, QualificationParam>,
    ) => createAction(CommonSpecificationActionsTypes.GET_SPECIFICATION_LIST_SUCCESS, payload),
});
