import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { StudentModel } from "../model/StudentModel";
import { STUDENT_LIST_QUERY } from "./useStudentList";
import { getSchoolAccountId } from "src/services/url";

export interface StudentAddEditRequest {
    id?: number;
    uniquePupilNumber: string;
    firstName: string;
    lastName: string;
    gender: string;
    pupilPremium: boolean;
    fsm: boolean;
    eal: boolean;
    email: string | null;
    senStatus: string;
    ethnicity: ListObject<string>;
    admissionNumber: string;
    dateOfBirth: string;
    academicHouse: string;
    form: string;
    lookedAfter: boolean;
    dateJoined: string;
    yearGroup: number;
    ks4Aps: string;
    ks4Agaps: string;
    candidateNumber: string;
    priorAbility: string;
    senNeed: ListObject<string>;
    ks2English: string;
    ks2Math: string;
    ks2FineLevel: string;
    catMean: number;
    catVerbal: number;
    catNonVerbal: number;
    catQuantitative: number;
    catSpatial: number;
    nativeSpeaker: boolean;
    readingAge: string;
    ks2EnglishScaled: string;
    ks2MathScaled: string;
    ks2FineLevelScaled: string;
}

const apiCreateStudent = async (
    values: StudentAddEditRequest,
): Promise<AxiosResponse<StudentModel>> => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/student/`, values);
};

export const useStudentCreateMutation = (onSuccess: () => void, onError?: (err) => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: StudentAddEditRequest) => apiCreateStudent(values),
        onSuccess: () => {
            enqueueSnackbar(t("students.addEdit.studentCreated"), { variant: "success" });
            queryClient.refetchQueries({ queryKey: [STUDENT_LIST_QUERY] });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(
                    err.response.data?.error || t("students.addEdit.errorCreatingStudent"),
                    {
                        ...SnackbarErrorOptions,
                    },
                );
            }
            if (onError) {
                onError(err.response.data);
            }
        },
    });
};
