/* eslint-disable react/display-name */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import { MenuItem } from "@mui/material";
import { usePrevious } from "src/hooks/usePrevious";
import { StyledCustomInput, StyledCustomSelect } from "./StyledCustomEditors";
import { absenceValuesArray } from "src/modules/tracker/services/valueHandler";

interface OwnProps {
    value: string;
    data;
    api: GridApi;
    allowUnderscore?: boolean;
    allowX?: boolean;
    context: any;
    hasFineGrades;
    colDef;
}

const MtgEditor = forwardRef(
    (
        {
            value: propsValue,
            api: gridApi,
            context,
            allowUnderscore,
            hasFineGrades,
            allowX,
            data,
            colDef,
        }: OwnProps,
        ref,
    ) => {
        const [value, setValue] = useState(
            propsValue ? propsValue + (data?.metaColumnsConfig[colDef.field]?.fineGrade || "") : "",
        );

        const [open, setOpen] = useState<boolean>(true);
        const refInput = useRef(null) as any;
        const prevValue = usePrevious(value);

        const handleChange = (value: string) => {
            setValue(value);
            setOpen(false);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleOpen = () => {
            setOpen(true);
        };

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    let fineGrade = "x";
                    if (`${value}`?.includes("+")) {
                        fineGrade = "+";
                    } else if (
                        !absenceValuesArray.includes(`${value}`) &&
                        `${value}`?.includes("-")
                    ) {
                        fineGrade = "-";
                    }

                    return { value, whileUpdate: true, fineGrade };
                },
            };
        });

        useEffect(() => {
            if (prevValue !== undefined && prevValue !== value) {
                gridApi.stopEditing();
            }
            //eslint-disable-next-line
        }, [value]);

        const grades = [];
        const tmpGrades =
            context.relatedData?.courseGrades && context.relatedData?.courseGrades.length > 0
                ? context.relatedData?.courseGrades
                : context.relatedData?.grades;

        tmpGrades.forEach(g => {
            if (hasFineGrades) {
                grades.push({ name: g.name + "-", id: g.id + "-" });
                grades.push(g);
                grades.push({ name: g.name + "+", id: g.id + "+" });
            } else {
                grades.push(g);
            }
        });

        return (
            <div className="ag-editor-cell">
                <StyledCustomSelect
                    ref={refInput}
                    value={value || ""}
                    onChange={e => handleChange(e.target.value as string)}
                    input={<StyledCustomInput />}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    fullWidth
                >
                    <MenuItem value="">[None]</MenuItem>
                    {grades?.map((grade: { id: number; name: string }) => (
                        <MenuItem key={grade.id} value={grade.id}>
                            {grade.name}
                        </MenuItem>
                    ))}
                    {allowUnderscore && <MenuItem value={"-1"}>U</MenuItem>}
                    {allowX && <MenuItem value={"-2"}>X</MenuItem>}
                    {allowX && <MenuItem value={"-3"}>A</MenuItem>}
                </StyledCustomSelect>
            </div>
        );
    },
);

export default MtgEditor;
