import { useCallback, useState, useEffect } from "react";
import { get, baseHeaders } from "src/services/ajax";
import { apiUrl, HTTP_SUCCESS } from "src/config/globals";
import { Author } from "src/orm/models/User";
import { differenceInSeconds } from "date-fns";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { getSchoolAccountId } from "src/services/url";

const CHECK_TIMESTAMPS_INTERVAL = 30000;
const SLEEPING_TIMEOUT = 360000;

export const useUpdateTimestamps = (classTrackerId, isArchive) => {
    const [blockUpdate, setBlockUpdate] = useState<boolean>(false);
    const [updateTimestamps, setUpdateTimestamps] = useState<{
        [key: string]: { committedAt: string; author: Author };
    } | null>(null);

    const { data: authUser } = useProfile();
    const getEditTimestamps = useCallback(() => {
        if (classTrackerId && !isArchive) {
            get(
                apiUrl(
                    `school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/timestamps/`,
                ),
                undefined,
                baseHeaders(),
            ).subscribe(
                res => {
                    if (res.status === HTTP_SUCCESS) {
                        setUpdateTimestamps(res.response);
                    } else {
                        setBlockUpdate(true);
                    }
                },
                () => {
                    setUpdateTimestamps(null);
                    setBlockUpdate(true);
                },
            );
        }
    }, [classTrackerId]);

    // start with timestamps
    useEffect(() => {
        const checkTimesInterval = setInterval(() => {
            if (!blockUpdate) {
                getEditTimestamps();
            }
        }, CHECK_TIMESTAMPS_INTERVAL);

        const sleepingTimeout = setTimeout(() => {
            setBlockUpdate(true);
            clearInterval(checkTimesInterval);
        }, SLEEPING_TIMEOUT);

        return () => {
            clearInterval(checkTimesInterval);
            clearTimeout(sleepingTimeout);
        };
    }, []);

    const getLastCommitData = useCallback(
        dataSet => {
            let hasBeenEdited = false;
            let author: Author | null = null;

            if (updateTimestamps && dataSet) {
                Object.keys(updateTimestamps).forEach(key => {
                    const lastCommitedAt = updateTimestamps[key].committedAt;
                    const lastCommitedBy: Author | null = updateTimestamps[key].author;
                    const row = dataSet.find(fcd => fcd.row === key);

                    if (row) {
                        const lastLoadedOrSelfEditedAt =
                            row.lastSessionUpdatedAt || row.rowState.lastUpdatedAt;

                        if (authUser && lastCommitedBy && lastCommitedBy.id !== authUser.id) {
                            const differenceNow = differenceInSeconds(
                                new Date(lastCommitedAt),
                                new Date(lastLoadedOrSelfEditedAt),
                            );
                            if (differenceNow > 0) {
                                hasBeenEdited = true;
                                author = lastCommitedBy;
                            }
                        }
                    }
                });
            }
            return { hasBeenEdited, author };
        },
        [authUser, updateTimestamps],
    );

    const hasBeenEditedByAnotherUser = dataSet => getLastCommitData(dataSet).hasBeenEdited;
    const getCommitAuthor = dataSet => getLastCommitData(dataSet).author;

    return {
        updateTimestamps,
        hasBeenEditedByAnotherUser,
        getCommitAuthor,
    };
};
