import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getClassesStudentNumberForSubjectEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/student-report/{cohort}/{yearGroup}/by-subject/`,
    method: ApiRequests.GET,
};

export interface ClassesStudentNumberForSubject {
    id: number;
    fullname: string;
    qualifications: {
        id: number;
        name: string;
        specifications: {
            id: number;
            fullname: string;
            classes: {
                id: number;
                name: string;
                numberOfStudents: number;
                isDemo: boolean;
            }[];
        }[];
    }[];
}
