import SpecificationAttribute, {
    OrmSpecificationAttribute,
} from "src/orm/models/SpecificationAttribute";
import {
    selectAll,
    selectAllWithFilter,
    selectByGivenId,
    selectOneWithFilter,
} from "src/orm/selectors";

export const attributesSelector = selectAll<OrmSpecificationAttribute>(SpecificationAttribute);

export const attributesWithFilterSelector =
    selectAllWithFilter<OrmSpecificationAttribute>(SpecificationAttribute);

export const attributeWithFilterSelector =
    selectOneWithFilter<OrmSpecificationAttribute>(SpecificationAttribute);

export const attributeDetailsSelector =
    selectByGivenId<OrmSpecificationAttribute>(SpecificationAttribute);
