import { Box, Typography } from "@mui/material";
import { ReactElement, FC, ReactNode } from "react";
import { theme } from "src/styles/theme";
import COLORS from "src/styles/colors";

interface HeadingCounterProps {
    children?: string | ReactNode;
    number: string | number | null;
    variant?: "outline" | "contained";
    size?: "normal" | "big";
}

const HeadingCounter: FC<HeadingCounterProps> = ({
    children,
    number,
    variant = "outline",
    size = "normal",
}): ReactElement => {
    return (
        <Box color={COLORS.BLUE_2} display="flex" alignItems="flex-start">
            {number && (
                <Box
                    component="span"
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    color={variant === "outline" ? COLORS.BLUE_1 : COLORS.WHITE}
                    bgcolor={variant === "contained" ? COLORS.BLUE_1 : undefined}
                    border={variant === "outline" ? "2px solid currentColor" : undefined}
                    borderRadius={size === "big" ? 16 : 12}
                    width={size === "big" ? 32 : 24}
                    height={size === "big" ? 32 : 24}
                    fontSize={size === "big" ? 18 : 14}
                    fontWeight={theme.typography.fontWeightBold}
                    mr={size === "big" ? 2.75 : 1}
                >
                    {number}
                </Box>
            )}
            {children && (
                <>
                    {size === "big" ? (
                        children
                    ) : (
                        <Typography variant="h2" color="inherit" gutterBottom>
                            {children}
                        </Typography>
                    )}
                </>
            )}
        </Box>
    );
};

export default HeadingCounter;
