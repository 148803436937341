import { Tab, Tabs, Typography, Badge } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InvoicesList from "../components/InvoicesList";
import OwnedList from "../components/OwnedList";
import RequestedList from "../components/RequestedList";
import { SchoolUserRole } from "src/orm/models/User";
import AppContainer from "src/components/AppContainer";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { ROUTE_SUBSCRIPTION_LIST_TAB } from "src/routes";
import { useParams } from "react-router";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export enum SubscriptionsActions {
    OWNED = "owned",
    REQUESTED = "requested",
    INVOICES = "invoices",
}

const SubscriptionList = () => {
    const params = useParams();
    const { data: authUser } = useProfile();
    const { data: accountInfo } = useAccountInfo();
    const { navigate } = useSchoolNavigate();
    let initialTab: string =
        authUser?.userRole !== SchoolUserRole.FINANCE
            ? SubscriptionsActions.OWNED
            : SubscriptionsActions.REQUESTED;
    if (params && params.tab) {
        initialTab = params.tab;
    }

    const [currentTab, setCurrentTab] = useState<string>(initialTab);
    const { t } = useTranslation();
    const tabs = [] as { key: string; label: string }[];
    tabs.push({ key: SubscriptionsActions.OWNED, label: t("subscription.owned") });
    tabs.push({ key: SubscriptionsActions.REQUESTED, label: t("subscription.requested") });
    if (
        authUser?.userRole !== SchoolUserRole.TEACHER &&
        authUser?.userRole !== SchoolUserRole.SCHOOL_ADMIN
    ) {
        tabs.push({ key: SubscriptionsActions.INVOICES, label: t("subscription.invoices") });
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
        navigate(ROUTE_SUBSCRIPTION_LIST_TAB.replace(":tab", value));
    };

    const hideBadge = !accountInfo || !accountInfo.numberOfRequestedSubscriptions;

    useEffect(() => {
        if (currentTab !== initialTab) setCurrentTab(initialTab);
    }, [currentTab, initialTab, params]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("subscription.list.header")}
            </Typography>
            <Tabs value={currentTab} onChange={handleTabChange}>
                {tabs.map(tab => (
                    <Tab
                        label={
                            <Badge
                                color="primary"
                                badgeContent={
                                    !hideBadge &&
                                    accountInfo &&
                                    accountInfo.numberOfRequestedSubscriptions
                                }
                                invisible={hideBadge || tab.key !== SubscriptionsActions.REQUESTED}
                            >
                                {tab.label}
                            </Badge>
                        }
                        key={tab.key}
                        value={tab.key}
                    />
                ))}
            </Tabs>
            {currentTab === SubscriptionsActions.OWNED && <OwnedList accountInfo={accountInfo} />}
            {currentTab === SubscriptionsActions.REQUESTED && <RequestedList />}
            {currentTab === SubscriptionsActions.INVOICES && <InvoicesList />}
        </AppContainer>
    );
};

export default SubscriptionList;
