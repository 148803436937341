import { useFormContext, Controller } from "react-hook-form";
import {
    Radio,
    RadioGroup,
    FormHelperText,
    RadioGroupProps,
    FormControlLabel,
    styled,
} from "@mui/material";
import { ReactElement } from "react";

const ColumnRadioList = styled(RadioGroup)(({ theme }) => ({
    flex: 1,
    "& > .MuiFormControlLabel-root": {
        width: "100%",
    },
    "& > .MuiFormControlLabel-root + .MuiFormControlLabel-root": {
        marginTop: theme.spacing(-1),
    },
}));

type RadioGroupMargin = "dense" | "normal";

interface IProps {
    name: string;
    options: string[];
    getOptionLabel?: (ReactElement | string)[];
    margin?: RadioGroupMargin;
    disabled?: boolean;
}

export default function RHFRadioGroup({
    name,
    options,
    getOptionLabel,
    margin = "dense",
    disabled,
    ...other
}: IProps & RadioGroupProps) {
    const { control } = useFormContext();
    const RadioGroupComponent = margin === "dense" ? ColumnRadioList : RadioGroup;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <>
                    <RadioGroupComponent {...field} row {...other}>
                        {options.map((option, index) => (
                            <FormControlLabel
                                key={index}
                                value={option}
                                control={<Radio disabled={disabled} />}
                                label={getOptionLabel?.length ? getOptionLabel[index] : option}
                            />
                        ))}
                    </RadioGroupComponent>

                    {!!error && (
                        <FormHelperText error sx={{ px: 2 }}>
                            {error.message}
                        </FormHelperText>
                    )}
                </>
            )}
        />
    );
}
