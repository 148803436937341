import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const uploadAndSendClassEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker/upload-and-send/data/`,
    method: ApiRequests.POST,
};

export interface AttributesRequest {
    customName: string | null;
    attributeValue: ListObject<number>;
}

export interface UploadClassRequest {
    specification: number;
    attributes: AttributesRequest[];
    fileNames: { tmpFileName: string; fileName: string }[];
}
