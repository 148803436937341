import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_PIXL_SERIES_AVG_QUERY = "classTrackerPixlSeriesAvgQuery";

export const useClassTrackerPixlSeriesAvg = (
    classTrackerId: number,
    tier: TiersTypes,
    seriesId: number,
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            CLASS_TRACKER_PIXL_SERIES_AVG_QUERY,
            schoolAccountId,
            classTrackerId,
            tier,
            seriesId,
        ],
        queryFn: async (): Promise<any | null> => {
            if (classTrackerId && tier && seriesId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/${tier}/pixl-waves-series/${seriesId}/`,
                );

                return data;
            }
            return null;
        },
    });
};
