import { VisibilityRuleData } from "src/forms/types";
import { AttributeValue, AttributeValueExtended } from "src/orm/models/SpecificationAttribute";
import { isArraysEqual } from "src/services/array";

export const getExtendedAttributeValue = (
    attributeValue: AttributeValueExtended,
): AttributeValueExtended => ({
    ...attributeValue,
    attributeItemId: attributeValue.attributeItemId
        ? attributeValue.attributeItemId
        : attributeValue.attributeItem.id || null,
    customName: attributeValue.customName || "",
});

export const isSelected = (
    attributeValueId: number,
    formAttributeValues: AttributeValue[],
): boolean => {
    const attributeValue = formAttributeValues.find(
        (aValue: AttributeValue) => aValue.id === attributeValueId,
    );

    return attributeValue
        ? attributeValue.isMandatory === true || attributeValue.isSelected === true
        : false;
};

export const shouldDisableEquals = (
    validationRule: VisibilityRuleData,
    formAttributeValues: AttributeValueExtended[],
): boolean => {
    const equalityArray: number[] = [];
    validationRule.values.forEach(value => equalityArray.push(value.id));
    const selectedArray: number[] = formAttributeValues
        .filter(
            aValue =>
                aValue.attributeItemId === validationRule.attributeItem.id &&
                aValue.isSelected === true,
        )
        .map(aValue => aValue.id);

    return !isArraysEqual(equalityArray, selectedArray);
};

export const shouldDisableContainsAll = (
    validationRule: VisibilityRuleData,
    formAttributeValues: AttributeValue[],
): boolean =>
    !validationRule.values.reduce(
        (prevValue: boolean, currentValue: AttributeValue) =>
            !isSelected(currentValue.id, formAttributeValues) && prevValue === true
                ? false
                : prevValue,
        true,
    );

export const shouldDisableContainsAny = (
    validationRule: VisibilityRuleData,
    formAttributeValues: AttributeValue[],
): boolean =>
    validationRule.values.reduce(
        (prevValue: boolean, currentValue: AttributeValue) =>
            isSelected(currentValue.id, formAttributeValues) && prevValue === true
                ? false
                : prevValue,
        true,
    );
