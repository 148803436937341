import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface MergeParentsRequest {
    linkedParent: ListObject<number>;
    unlinkedParent: ListObject<number>;
}

const apiMergeMutation = async (values: MergeParentsRequest): Promise<AxiosResponse> => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/parents/merge/`, values);
};

export const useParentMergeMutation = (onSuccess: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: MergeParentsRequest) => apiMergeMutation(values),
        onSuccess: () => {
            enqueueSnackbar(t("parent.addEdit.parentMerged"), { variant: "success" });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: () => {
            enqueueSnackbar(t("parent.addEdit.parentMergeError"), {
                ...SnackbarErrorOptions,
            });
        },
    });
};
