import AppContainer from "src/components/AppContainer";
import PaperInner from "src/components/PaperInner";
import ActionsTableButton from "src/components/ActionTableButton";
import { useTrustCohortsAndYearGroups } from "../hooks/useTrustCohortsAndYearGroups";
import { useParams } from "react-router";
import {
    Box,
    Button,
    CircularProgress,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { TrustDashboardItem, useTrustDashboardData } from "../hooks/useTrustDashboardData";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { ROUTE_CLASS_CLASS_LIST, ROUTE_TRUST_USERS } from "src/routes";
import { mdiArrowRight, mdiBookAlertOutline } from "@mdi/js";
import Icon from "src/components/Icon";
import { useUserContext } from "src/modules/user/hooks/useUserContext";
import { Storage } from "src/services/storage";

const TrustDashboardContainer = () => {
    const params = useParams();
    const { t } = useTranslation();
    const { data: userContext } = useUserContext();
    const { data: cohortsYgs, isPending } = useTrustCohortsAndYearGroups(parseInt(params.trust));

    const [selectedCohortId, setSelectedCohortId] = useState<number>(0);
    const [selectedYearGroup, setSelectedYearGroup] = useState<number>(10);

    const { data: dashboardData, isPending: isPendingDashboardData } = useTrustDashboardData(
        parseInt(params.trust),
        selectedCohortId,
        selectedYearGroup,
    );

    const handleGoToSchool = (schoolAccountId: number) => {
        Storage.setItem("schoolAccountId", `${schoolAccountId}`);
        window.open(ROUTE_CLASS_CLASS_LIST.replace(":schoolAccountId", `${schoolAccountId}`));
    };

    useEffect(() => {
        if (cohortsYgs?.length > 0) {
            const currentYear = new Date().getFullYear();
            let cohort = cohortsYgs.find(c => c.id === currentYear);
            if (!cohort) {
                cohort = cohortsYgs.find(c => c.id === currentYear - 1);
                if (cohort) {
                    setSelectedCohortId(cohort.id);
                } else {
                    setSelectedCohortId(cohortsYgs[0].id);
                }
            }
            if (cohortsYgs[0].yearGroup.find(yg => yg === 11)) {
                setSelectedYearGroup(11);
            } else {
                setSelectedYearGroup(cohortsYgs[0].yearGroup[0]);
            }
        }
    }, [cohortsYgs]);

    if (isPending) {
        return (
            <Box padding={16} textAlign={"center"}>
                <CircularProgress />
            </Box>
        );
    }

    const hasAccessToSchool = (schoolAccountId: number) => {
        return !!userContext?.schoolAccounts?.find(sa => sa.id === schoolAccountId);
    };

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("trust.dashboard.header")}
            </Typography>
            <Paper>
                <PaperInner variant="filter" color="lightGrey" border="bottom">
                    <Box sx={{ display: "flex", gap: 3 }}>
                        <Box sx={{ width: 140 }}>
                            <TextField
                                label={t("trust.dashboard.cohort")}
                                value={selectedCohortId}
                                onChange={e => setSelectedCohortId(parseInt(`${e.target.value}`))}
                                select
                            >
                                {cohortsYgs?.map(cy => (
                                    <MenuItem key={cy.id} value={cy.id}>
                                        {cy.name || cy.id}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        {selectedCohortId > 0 && (
                            <Box sx={{ width: 140 }}>
                                <TextField
                                    label={t("trust.dashboard.yearGroup")}
                                    value={selectedYearGroup}
                                    onChange={e =>
                                        setSelectedYearGroup(parseInt(`${e.target.value}`))
                                    }
                                    disabled={
                                        cohortsYgs[
                                            cohortsYgs.findIndex(cy => cy.id === selectedCohortId)
                                        ]?.yearGroup.length === 0
                                    }
                                    select
                                >
                                    {cohortsYgs[
                                        cohortsYgs.findIndex(cy => cy.id === selectedCohortId)
                                    ]?.yearGroup?.map(yg => (
                                        <MenuItem key={yg} value={yg}>
                                            {yg}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        )}
                    </Box>
                </PaperInner>
                <PaperInner>
                    {dashboardData?.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ border: 0, pt: 0 }}></TableCell>
                                    <TableCell sx={{ border: 0, pt: 0 }}></TableCell>
                                    <TableCell
                                        sx={{
                                            border: 0,
                                            textAlign: "center",
                                            fontSize: 13,
                                            pt: 0,
                                        }}
                                        colSpan={3}
                                    >
                                        A8
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: 0,
                                            textAlign: "center",
                                            fontSize: 13,
                                            pt: 0,
                                        }}
                                        colSpan={3}
                                    >
                                        P8
                                    </TableCell>
                                    <TableCell sx={{ border: 0, pt: 0 }}></TableCell>
                                    <TableCell sx={{ border: 0, pt: 0 }}></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            verticalAlign: "bottom",
                                        }}
                                    >
                                        {t("trust.dashboard.school")}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            verticalAlign: "bottom",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                lineHeight: 1.36,
                                                textOrientation: "mixed",
                                                writingMode: "vertical-rl",
                                                transform: "rotate(180deg)",
                                                maxHeight: 110,
                                            }}
                                        >
                                            {t("trust.dashboard.numberOfStudents")}
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            backgroundColor: "rgba(216,220,228,0.2)",
                                            verticalAlign: "bottom",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                lineHeight: 1.36,
                                                textOrientation: "mixed",
                                                writingMode: "vertical-rl",
                                                transform: "rotate(180deg)",
                                                maxHeight: 110,
                                            }}
                                        >
                                            {t("trust.dashboard.currentGrade")}
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            backgroundColor: "rgba(216,220,228,0.2)",
                                            verticalAlign: "bottom",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                lineHeight: 1.36,
                                                textOrientation: "mixed",
                                                writingMode: "vertical-rl",
                                                transform: "rotate(180deg)",
                                                maxHeight: 110,
                                            }}
                                        >
                                            {t("trust.dashboard.avgUnitGrade")}
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            backgroundColor: "rgba(216,220,228,0.2)",
                                            verticalAlign: "bottom",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                lineHeight: 1.36,
                                                textOrientation: "mixed",
                                                writingMode: "vertical-rl",
                                                transform: "rotate(180deg)",
                                                maxHeight: 110,
                                            }}
                                        >
                                            {t("trust.dashboard.teacherPredicted")}
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            verticalAlign: "bottom",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                lineHeight: 1.36,
                                                textOrientation: "mixed",
                                                writingMode: "vertical-rl",
                                                transform: "rotate(180deg)",
                                                maxHeight: 110,
                                            }}
                                        >
                                            {t("trust.dashboard.currentGrade")}
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            verticalAlign: "bottom",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                lineHeight: 1.36,
                                                textOrientation: "mixed",
                                                writingMode: "vertical-rl",
                                                transform: "rotate(180deg)",
                                                maxHeight: 110,
                                            }}
                                        >
                                            {t("trust.dashboard.avgUnitGrade")}
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            verticalAlign: "bottom",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                lineHeight: 1.36,
                                                textOrientation: "mixed",
                                                writingMode: "vertical-rl",
                                                transform: "rotate(180deg)",
                                                maxHeight: 110,
                                            }}
                                        >
                                            {t("trust.dashboard.teacherPredicted")}
                                        </Box>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dashboardData.map(dd => (
                                    <TableRow key={dd.id}>
                                        <TableCell sx={{ minWidth: 200 }}>
                                            <Box sx={{ fontWeight: "fontWeightMedium" }}>
                                                {dd.name}
                                            </Box>
                                        </TableCell>
                                        <TableCell>{dd.numberOfStudents}</TableCell>
                                        <TableCell>{dd.currentA8}</TableCell>
                                        <TableCell>{dd.projectedA8}</TableCell>
                                        <TableCell>{dd.teacherA8}</TableCell>
                                        <TableCell>{dd.currentP8}</TableCell>
                                        <TableCell>{dd.projectedP8}</TableCell>
                                        <TableCell>{dd.teacherP8}</TableCell>
                                        <TableCell
                                            sx={{
                                                pt: 1.25,
                                                pb: 1.25,
                                                verticalAlign: "middle",
                                                width: 200,
                                            }}
                                        >
                                            {hasAccessToSchool(dd.id) ? (
                                                <Button
                                                    onClick={() => handleGoToSchool(dd.id)}
                                                    startIcon={<Icon path={mdiArrowRight} />}
                                                    sx={{ py: 0.875, px: 1.625 }}
                                                >
                                                    {t("trust.dashboard.goToSchool")}
                                                </Button>
                                            ) : (
                                                <></>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: 66,
                                                pt: 0.5,
                                                pr: 0.5,
                                                pb: 0.5,
                                                pl: 2,
                                                textAlign: "right",
                                            }}
                                        >
                                            {hasAccessToSchool(dd.id) ? (
                                                <ActionsTableButton
                                                    actions={[
                                                        getMenuActionProps("show", {
                                                            text: t("trust.dashboard.showAdmins"),
                                                            linkCallback: (
                                                                rowData: TrustDashboardItem,
                                                            ) =>
                                                                ROUTE_TRUST_USERS.replace(
                                                                    ":trust",
                                                                    `${params.trust}`,
                                                                ) +
                                                                "?schoolAccountId=" +
                                                                rowData.id,
                                                        }),
                                                    ]}
                                                    rowData={dd}
                                                    forceDotMenu={true}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <></>
                    )}

                    {isPending || isPendingDashboardData ? (
                        <Box textAlign={"center"}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <></>
                    )}

                    {!cohortsYgs ||
                    cohortsYgs.length === 0 ||
                    (selectedCohortId > 0 &&
                        cohortsYgs &&
                        cohortsYgs[cohortsYgs.findIndex(cy => cy.id === selectedCohortId)]
                            ?.yearGroup.length === 0) ? (
                        <Box sx={{ display: "flex", gap: 2, color: "#C2C4C8" }}>
                            <Icon path={mdiBookAlertOutline} size="24px" color="inherit" />
                            <Typography>
                                <em>{t("trust.dashboard.noCohortsMessage")}</em>
                            </Typography>
                        </Box>
                    ) : (
                        <></>
                    )}
                </PaperInner>
            </Paper>
        </AppContainer>
    );
};

export default TrustDashboardContainer;
