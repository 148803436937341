import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RHFTextField } from "src/components/hookFormComponents";

const NewPasswordForm = () => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={4}>
            <Grid item sm={4}>
                <RHFTextField
                    password
                    name="password"
                    label={t("common.form.label.password")}
                    autoFocus
                />
            </Grid>
            <Grid item sm={4}>
                <RHFTextField
                    password
                    name="repeatPassword"
                    label={t("common.form.label.repeatPassword")}
                    autoComplete="off"
                />
            </Grid>
        </Grid>
    );
};

export default NewPasswordForm;
