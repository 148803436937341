import SelectableRenderer from "../components/Grid/CellRenderer/SelectableRenderer";
import StudentDataRenderer from "../components/Grid/CellRenderer/StudentDataRenderer";
import MtgRenderer from "../components/Grid/CellRenderer/MtgRenderer";
import MoveStudentsEditor from "../components/Grid/CellEditor/MoveStudentsEditor";
import DefaultValueEditor from "../components/Grid/CellEditor/DefaultValueEditor";
import SelectableEditor from "../components/Grid/CellEditor/SelectableEditor";
import TextConversionEditor from "../components/Grid/CellEditor/TextConversionEditor";
import MtgEditor from "../components/Grid/CellEditor/MtgEditor";
import StudentAttributeEditor from "../components/Grid/CellEditor/StudentAttributeEditor";
import BtecTrackedFieldEditor from "../components/Grid/CellEditor/BtecTrackedFieldEditor";
import MoveTiersEditor from "../components/Grid/CellEditor/MoveTiersEditor";
import { DefaultValueRenderer } from "../components/Grid/CellRenderer/DefaultValueRenderer";
import { StudentAttributeRenderer } from "../components/Grid/CellRenderer/StudentAttributeRenderer";
import { PinnedRowRenderer } from "../components/Grid/CellRenderer/PinnedRowRenderer";
import { TextConversionRenderer } from "../components/Grid/CellRenderer/TextConversionRenderer";
import { ReportDownloadRenderer } from "../components/Grid/CellRenderer/ReportDownloadRenderer";
import { BtecTrackedFieldRenderer } from "../components/Grid/CellRenderer/BtecTrackedFieldRenderer";
import { CurrentGradeRenderer } from "../components/Grid/CellRenderer/CurrentGradeRenderer";
import { MoveStudentsRenderer } from "../components/Grid/CellRenderer/MoveStudentsRenderer";
import { CurrentClassRenderer } from "../components/Grid/CellRenderer/CurrentClassRenderer";
import { MoveTiersRenderer } from "../components/Grid/CellRenderer/MoveTiersRenderer";
import { StudentIsMovingRenderer } from "../components/Grid/CellRenderer/StudentIsMovingRenderer";
import { DistanceToMtgDescriptionRenderer } from "../components/Grid/CellRenderer/DistanceToMtgDescriptionRenderer";
import TagDataRenderer from "../components/Grid/CellRenderer/TagDataRenderer";
import TagDataEditor from "../components/Grid/CellEditor/TagDataEditor";
import TagAvgRenderer from "../components/Grid/CellRenderer/TagAvgRenderer";
import LinkDataEditor from "../components/Grid/CellEditor/LinkDataEditor";
import LinkDataRenderer from "../components/Grid/CellRenderer/LinkDataRenderer";

export enum GridFrameworkComponents {
    CURRENT_CLASS_RENDERER = "currentClassRenderer",
    MOVE_STUDENTS_RENDERER = "moveStudentsRenderer",
    MOVE_TIERS_RENDERER = "moveTiersRenderer",
    MOVE_STUDENTS_EDITOR = "moveStudentsEditor",
    MOVE_TIERS_EDITOR = "moveTiersEditor",
    DEFAULT_VALUE_EDITOR = "defaultValueEditor",
    DEFAULT_VALUE_RENDERER = "defaultValueRenderer",
    SELECTABLE_EDITOR = "selectableEditor",
    SELECTABLE_RENDERER = "selectableRenderer",
    TEXT_CONVERSION_EDITOR = "textConversionEditor",
    TEXT_CONVERSION_RENDERER = "textConversionRenderer",
    STUDENT_DATA_RENDERER = "studentDataRenderer",
    TAG_AVG_RENDERER = "tagAvgRenderer",
    TAG_DATA_RENDERER = "tagDataRenderer",
    TAG_DATA_EDITOR = "tagDataEditor",
    LINK_DATA_RENDERER = "linkDataRenderer",
    LINK_DATA_EDITOR = "linkDataEditor",
    STUDENT_IS_MOVING_RENDERER = "studentIsMovingRenderer",
    MTG_RENDERER = "mtgRenderer",
    MTG_EDITOR = "mtgEditor",
    STUDENT_ATTRIBUTE_RENDERER = "studentAttributeRenderer",
    STUDENT_ATTRIBUTE_EDITOR = "studentAttributeEditor",
    PINNED_ROW_RENDERER = "pinnedRowRenderer",
    UNIT_GROUP_HEADER = "unitGroupHeader",
    REPORT_DOWNLOAD_RENDERER = "reportDownloadRenderer",
    BTEC_TRACKED_FIELD_RENDERER = "btecTrackedFieldRenderer",
    BTEC_TRACKED_FIELD_EDITOR = "btecTrackedFieldEditor",
    CURRENT_GRADE_RENDERER = "currentGradeRenderer",
    DISTANCE_MTG_DESCRIPTION_RENDERER = "distanceMtgDescriptionRenderer",
}

export const frameworkComponentsList = {
    [GridFrameworkComponents.CURRENT_CLASS_RENDERER]: CurrentClassRenderer,
    [GridFrameworkComponents.MOVE_STUDENTS_RENDERER]: MoveStudentsRenderer,
    [GridFrameworkComponents.MOVE_STUDENTS_EDITOR]: MoveStudentsEditor,
    [GridFrameworkComponents.MOVE_TIERS_RENDERER]: MoveTiersRenderer,
    [GridFrameworkComponents.MOVE_TIERS_EDITOR]: MoveTiersEditor,
    [GridFrameworkComponents.DEFAULT_VALUE_EDITOR]: DefaultValueEditor,
    [GridFrameworkComponents.DEFAULT_VALUE_RENDERER]: DefaultValueRenderer,
    [GridFrameworkComponents.SELECTABLE_EDITOR]: SelectableEditor,
    [GridFrameworkComponents.SELECTABLE_RENDERER]: SelectableRenderer,
    [GridFrameworkComponents.TEXT_CONVERSION_EDITOR]: TextConversionEditor,
    [GridFrameworkComponents.TEXT_CONVERSION_RENDERER]: TextConversionRenderer,
    [GridFrameworkComponents.STUDENT_DATA_RENDERER]: StudentDataRenderer,
    [GridFrameworkComponents.STUDENT_IS_MOVING_RENDERER]: StudentIsMovingRenderer,
    [GridFrameworkComponents.MTG_RENDERER]: MtgRenderer,
    [GridFrameworkComponents.TAG_DATA_RENDERER]: TagDataRenderer,
    [GridFrameworkComponents.TAG_DATA_EDITOR]: TagDataEditor,
    [GridFrameworkComponents.LINK_DATA_RENDERER]: LinkDataRenderer,
    [GridFrameworkComponents.LINK_DATA_EDITOR]: LinkDataEditor,
    [GridFrameworkComponents.TAG_AVG_RENDERER]: TagAvgRenderer,
    [GridFrameworkComponents.MTG_EDITOR]: MtgEditor,
    [GridFrameworkComponents.STUDENT_ATTRIBUTE_EDITOR]: StudentAttributeEditor,
    [GridFrameworkComponents.STUDENT_ATTRIBUTE_RENDERER]: StudentAttributeRenderer,
    [GridFrameworkComponents.PINNED_ROW_RENDERER]: PinnedRowRenderer,
    [GridFrameworkComponents.REPORT_DOWNLOAD_RENDERER]: ReportDownloadRenderer,
    [GridFrameworkComponents.BTEC_TRACKED_FIELD_EDITOR]: BtecTrackedFieldEditor,
    [GridFrameworkComponents.BTEC_TRACKED_FIELD_RENDERER]: BtecTrackedFieldRenderer,
    [GridFrameworkComponents.CURRENT_GRADE_RENDERER]: CurrentGradeRenderer,
    [GridFrameworkComponents.DISTANCE_MTG_DESCRIPTION_RENDERER]: DistanceToMtgDescriptionRenderer,
};
