import { StringMap } from "@martin_hotell/rex-tils";
import { ClassEndpoints } from "src/modules/class/store/actions";
import { CommonEndpoints } from "src/modules/common/store/actions";
import { SubscriptionsEndpoints } from "src/modules/subscription/store/actions";
import { UserEndpoints } from "src/modules/user/store/actions";
import { CustomMap, Modules } from "src/types";
import { combineReducers, Reducer } from "redux";
import { ApiActions, ApiActionTypes } from "./actions";
import { ApiData, ApiStatus } from "./constants";
import { SchoolEndpoints } from "src/modules/school/store/actions";
import { TrackerEndpoints } from "src/modules/tracker/store/actions";
import { AnalysisEndpoints } from "src/modules/analysis/store/actions";
import { ReportEndpoints } from "src/modules/report/store/actions";

const initialState: ApiData = { status: ApiStatus.NONE, error: null };

const statusMap: StringMap<ApiStatus> = {
    REQUEST: ApiStatus.LOADING,
    SUCCESS: ApiStatus.SUCCESS,
    ERROR: ApiStatus.ERROR,
};

export const apiReducer: Reducer<ApiData, ApiActions> = (
    state: ApiData = initialState,
    action: ApiActions,
): ApiData => {
    const { payload }: { payload: any } = action;
    switch (action.type) {
        case ApiActionTypes.API_REQUEST:
            return { status: statusMap.REQUEST, error: null, responseStatus: null };
        case ApiActionTypes.API_SUCCESS:
            return {
                status: statusMap.SUCCESS,
                error: null,
                responseStatus: payload.responseStatus || null,
            };
        case ApiActionTypes.API_ERROR:
            return {
                status: statusMap.ERROR,
                error: {
                    response: action.payload.error.response,
                    request: action.payload.error.request,
                    status: action.payload.error.status,
                },
            };
        default:
            return state;
    }
};

const createFilteredReducer =
    (prefix: string, key: string): Reducer<ApiData, ApiActions> =>
    (state: ApiData = initialState, action: ApiActions): ApiData => {
        if (
            !action.payload ||
            !action.payload.meta ||
            action.payload.meta.prefix !== prefix ||
            action.payload.meta.key !== key
        ) {
            return state;
        }

        return apiReducer(state, action);
    };

type ApiReducer<D> = CustomMap<D, Reducer<ApiData, ApiActions>>;

// common
const commonReducers: ApiReducer<typeof CommonEndpoints> = {};
Object.keys(CommonEndpoints).forEach(key => {
    commonReducers[key] = createFilteredReducer(Modules.COMMON, key);
});

// class
const classReducers: ApiReducer<typeof ClassEndpoints> = {};
Object.keys(ClassEndpoints).forEach(key => {
    classReducers[key] = createFilteredReducer(Modules.CLASS, key);
});

// tracker
const trackerReducers: ApiReducer<typeof TrackerEndpoints> = {};
Object.keys(TrackerEndpoints).forEach(key => {
    trackerReducers[key] = createFilteredReducer(Modules.TRACKER, key);
});

// school
const schoolReducers: ApiReducer<typeof SchoolEndpoints> = {};
Object.keys(SchoolEndpoints).forEach(key => {
    schoolReducers[key] = createFilteredReducer(Modules.SCHOOL, key);
});
// subscription
const subscriptionReducers: ApiReducer<typeof SubscriptionsEndpoints> = {};
Object.keys(SubscriptionsEndpoints).forEach(key => {
    subscriptionReducers[key] = createFilteredReducer(Modules.SUBSCRIPTION, key);
});

// user
const userReducers: ApiReducer<typeof UserEndpoints> = {};
Object.keys(UserEndpoints).forEach(key => {
    userReducers[key] = createFilteredReducer(Modules.USER, key);
});

// analysis
const analysisReducers: ApiReducer<typeof AnalysisEndpoints> = {};
Object.keys(AnalysisEndpoints).forEach(key => {
    analysisReducers[key] = createFilteredReducer(Modules.ANALYSIS, key);
});

// report
const reportReducers: ApiReducer<typeof ReportEndpoints> = {};
Object.keys(ReportEndpoints).forEach(key => {
    reportReducers[key] = createFilteredReducer(Modules.REPORT, key);
});

export const reducer = combineReducers({
    [Modules.COMMON]: combineReducers(commonReducers),
    [Modules.CLASS]: combineReducers(classReducers),
    [Modules.SCHOOL]: combineReducers(schoolReducers),
    [Modules.SUBSCRIPTION]: combineReducers(subscriptionReducers),
    [Modules.USER]: combineReducers(userReducers),
    [Modules.TRACKER]: combineReducers(trackerReducers),
    [Modules.ANALYSIS]: combineReducers(analysisReducers),
    [Modules.REPORT]: combineReducers(reportReducers),
});

export type ApiState = ReturnType<typeof reducer>;
