/* eslint-disable no-console */
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import store from "src/store";

export const bugsnagClient: any =
    process.env.REACT_APP_BUGSNAG_KEY && process.env.NODE_ENV !== "test"
        ? Bugsnag.start({
              apiKey: `${process.env.REACT_APP_BUGSNAG_KEY}`,
              appVersion: `${process.env.REACT_APP_VERSION}${process.env.REACT_APP_VERSION_SUFFIX}`,
              releaseStage: process.env.REACT_APP_RELEASE_STAGE,
              plugins: [new BugsnagPluginReact(React)],
              onError: event => {
                  try {
                      const { auth } = store.getState() as any;
                      if (auth) {
                          event.setUser(auth.user?.id, auth.user?.email);
                      }
                  } catch (e) {
                      console.error(e);
                  }
              },
          })
        : {
              app: {
                  name: "pupil",
              },
              device: { id: "test" },
              notify: err => {
                  console.log("[bugsnag]", err);
              },
              use: () => {
                  console.log("[bugsnag] Console Mode");
              },
              getPlugin:
                  () =>
                  ({ children }) =>
                      children,
          };
