import FormikRef from "src/forms/FormikRef";
import FieldChangeHandler from "src/forms/FieldChangeHandler";
import PromptDialog from "src/forms/PromptDialog";
import PaperInner from "src/components/PaperInner";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import DotsProgress from "src/components/DotsProgress";
import AppContainer from "src/components/AppContainer";
import { useEffect, useCallback, useState, createRef } from "react";
import {
    Paper,
    Grid,
    Box,
    Typography,
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { Dispatch } from "redux";
import { SchoolActions } from "../store/actions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ApiData, ApiStatus } from "src/api/constants";
import { AppState } from "src/store/reducers";
import { Form, Field, FormikProps } from "formik";
import { DragDropFileUpload } from "src/forms/DragDropFileUpload";
import { usePrevious } from "src/hooks/usePrevious";
import { getErrorMessage, FormError } from "src/services/error";
import { useSnackbar } from "notistack";
import { FileType } from "src/forms/types";
import { mdiEmail, mdiDelete } from "@mdi/js";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolDetails } from "../hooks/query/SchoolDetails/useSchoolDetails";
import LockMtgModal from "../components/LockMtgModal";
import { useEditSchoolDetails } from "../hooks/query/SchoolDetails/useEditSchoolDetails";

interface UploadLogoForm {
    logoFile: FileType[] | null;
}

const dispatchActions = (dispatch: Dispatch) => ({
    uploadSchoolLogo: values => {
        dispatch(SchoolActions.uploadSchoolLogo(values));
    },
    deleteSchoolLogo: () => {
        dispatch(SchoolActions.deleteSchoolLogo());
    },
});

const SchoolDetailsContainer = () => {
    const dispatch = useDispatch();
    const form = createRef();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [privacyUrlInput, setPrivacyUrlInput] = useState<string>("");
    const [contactEmailInput, setContactEmailInput] = useState<string>("");
    const [fineGradesChecked, setFineGradesChecked] = useState<boolean>(false);
    const [yearGroupsWithLockedMtg, setYearGroupsWithLockedMtg] = useState<number[]>([]);
    const [lockMtgModalOpen, setLockMtgModalOpen] = useState<boolean>(false);
    const { uploadSchoolLogo, deleteSchoolLogo } = dispatchActions(dispatch);
    const {
        apiUpload,
        apiDelete,
    }: {
        apiUpload: ApiData;
        apiDelete;
    } = useSelector(
        (state: AppState) => ({
            apiUpload: state.api.school.uploadSchoolLogo,
            apiDelete: state.api.school.deleteSchoolLogo,
        }),
        shallowEqual,
    );
    const { data: schoolDetails, refetch } = useSchoolDetails();
    const { mutate: editSchoolDetails } = useEditSchoolDetails();

    const handleDeleteDialogOpen = (): void => {
        setRemoveDialogOpened(true);
    };

    const handleDeleteDialogClose = (confirmed: boolean): void => {
        if (confirmed === true) {
            deleteSchoolLogo();
        }
        setRemoveDialogOpened(false);
    };
    const handleLogoChange = (values: UploadLogoForm) => {
        if (values.logoFile !== null) uploadSchoolLogo(values.logoFile[0]);
    };

    const handleErrorResponse = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.formError)
                        enqueueSnackbar(error.formError.logoFile, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
        },
        [enqueueSnackbar, form],
    );

    const handlePrivateUrlSave = () => {
        editSchoolDetails({
            privacyPolicyUrl: privacyUrlInput,
            contactEmail: contactEmailInput,
            fineGrades: fineGradesChecked,
            yearGroupsWithLockedMtg,
        });
    };

    useEffect(() => {
        if (schoolDetails && schoolDetails.fineGrades) {
            setFineGradesChecked(true);
        }
        if (schoolDetails && schoolDetails.privacyPolicyUrl) {
            setPrivacyUrlInput(schoolDetails.privacyPolicyUrl);
        }
        if (schoolDetails && schoolDetails.contactEmail) {
            setContactEmailInput(schoolDetails.contactEmail);
        }
        if (schoolDetails && schoolDetails.yearGroupsWithLockedMtg) {
            setYearGroupsWithLockedMtg(schoolDetails.yearGroupsWithLockedMtg);
        }
        //eslint-disable-next-line
    }, [schoolDetails]);

    const prevApiUploadStatus = usePrevious(apiUpload.status);
    const prevApiDeleteStatus = usePrevious(apiDelete.status);

    useEffect(() => {
        if (prevApiUploadStatus === ApiStatus.LOADING && apiUpload.status === ApiStatus.ERROR) {
            handleErrorResponse(apiUpload);
        }
        if (prevApiUploadStatus === ApiStatus.LOADING && apiUpload.status === ApiStatus.SUCCESS) {
            refetch();
        }
        //eslint-disable-next-line
    }, [apiUpload, prevApiUploadStatus]);

    useEffect(() => {
        if (prevApiDeleteStatus === ApiStatus.LOADING && apiDelete.status === ApiStatus.SUCCESS) {
            refetch();
            (form?.current as any)?.setFieldValue("logoFile", null);
        }
        //eslint-disable-next-line
    }, [apiDelete, prevApiDeleteStatus]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("school.schoolDetails.tab")}
            </Typography>
            <Paper>
                {schoolDetails ? (
                    <Grid container>
                        <Grid item sm={6}>
                            <PaperInner>
                                <Box display="flex" alignItems="center" height={1}>
                                    {schoolDetails?.logoUrl && (
                                        <Box component="figure" mt={0} mr={4} mb={0} ml={0}>
                                            <img
                                                alt={schoolDetails.name}
                                                src={schoolDetails.logoUrl}
                                            />
                                        </Box>
                                    )}
                                    <Box
                                        fontSize={14}
                                        fontWeight="fontWeightMedium"
                                        lineHeight={1.72}
                                        flex={1}
                                    >
                                        <Box>{schoolDetails.name}</Box>
                                        <Box>{schoolDetails.address1}</Box>
                                        <Box>{schoolDetails.address2}</Box>
                                        <Box>{schoolDetails.city}</Box>
                                        <Box>{schoolDetails.postcode}</Box>
                                        <Box>{schoolDetails.country.name}</Box>
                                    </Box>
                                </Box>
                            </PaperInner>
                        </Grid>
                        <Grid item sm={6}>
                            <PaperInner border="left">
                                <Typography gutterBottom>
                                    {t("school.schoolDetails.contactHint")}
                                </Typography>
                                <Box></Box>
                                <Button
                                    href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
                                    variant="text"
                                    startIcon={<Icon path={mdiEmail} />}
                                    disableRipple
                                >
                                    {t("school.schoolDetails.contactUs")}
                                </Button>
                                <Box
                                    display="flex"
                                    width="fit-content"
                                    borderTop={`1px solid ${COLORS.LIGHT_GREY_2}`}
                                    mt={4.75}
                                    pt={4.25}
                                >
                                    <FormikRef
                                        ref={form}
                                        initialValues={{ logoFile: null }}
                                        onSubmit={() => {}}
                                        enableReinitialize={true}
                                    >
                                        {(formProps: FormikProps<UploadLogoForm>) => {
                                            return (
                                                <Form>
                                                    <FieldChangeHandler
                                                        onChange={handleLogoChange}
                                                        value={formProps.values}
                                                        setValues={formProps.setValues}
                                                    />
                                                    <Field
                                                        name={`logoFile`}
                                                        component={DragDropFileUpload}
                                                        showList={false}
                                                        label={t("school.schoolDetails.uploadLogo")}
                                                        accept={`.jpg,.jpeg,.png`}
                                                        type="button"
                                                        multiple={false}
                                                        icon="camera"
                                                        buttonGutter="none"
                                                    />
                                                </Form>
                                            );
                                        }}
                                    </FormikRef>
                                    {schoolDetails.logoUrl !== null && (
                                        <Box ml={7}>
                                            <Button
                                                onClick={handleDeleteDialogOpen}
                                                variant="text"
                                                startIcon={<Icon path={mdiDelete} />}
                                                disableRipple
                                            >
                                                {t("school.schoolDetails.deleteLogo")}
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                                <Box mt={2.75} pt={4.25}>
                                    <Box mr={12}>
                                        <TextField
                                            onChange={e => setPrivacyUrlInput(e.target.value)}
                                            label={t("school.schoolDetails.privateUrl")}
                                            value={privacyUrlInput}
                                        />
                                    </Box>
                                    <Box mr={12}>
                                        <TextField
                                            onChange={e => setContactEmailInput(e.target.value)}
                                            label={t("school.schoolDetails.contactEmail")}
                                            value={contactEmailInput}
                                        />
                                    </Box>
                                    <Box mr={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={fineGradesChecked}
                                                    onChange={() =>
                                                        setFineGradesChecked(!fineGradesChecked)
                                                    }
                                                    value={fineGradesChecked}
                                                />
                                            }
                                            label={t("school.schoolDetails.fineGrades")}
                                        />
                                    </Box>
                                    <Box mt={2} mb={4}>
                                        <Button onClick={() => setLockMtgModalOpen(true)}>
                                            {t("school.schoolDetails.lockMtg")}
                                        </Button>
                                    </Box>
                                    <Box mt={2}>
                                        <Button
                                            onClick={handlePrivateUrlSave}
                                            color="secondary"
                                            disableRipple
                                        >
                                            {t("school.schoolDetails.savePrivateUrl")}
                                        </Button>
                                    </Box>
                                </Box>
                            </PaperInner>
                        </Grid>
                    </Grid>
                ) : (
                    <Box pt={4} pb={4}>
                        <DotsProgress />
                    </Box>
                )}
                <PromptDialog open={removeDialogOpened} onClose={handleDeleteDialogClose}>
                    {t("school.schoolDetails.removeLogoConfirm")}
                </PromptDialog>
                <LockMtgModal
                    modalOpen={lockMtgModalOpen}
                    yearGroupsWithLockedMtg={yearGroupsWithLockedMtg}
                    modalClose={(confirm, newYearGroupsWithLockedMtg) => {
                        if (confirm) {
                            setYearGroupsWithLockedMtg(newYearGroupsWithLockedMtg);
                        }
                        setLockMtgModalOpen(false);
                    }}
                />
            </Paper>
        </AppContainer>
    );
};

export default SchoolDetailsContainer;
