import { Box, Container } from "@mui/material";
import { useLocation } from "react-router";
import { Storage } from "src/services/storage";

const AppContainer = ({ children }) => {
    const currentHasReminder: boolean | undefined = Storage.getItem("hasReminder");
    const location = useLocation();

    return (
        <Box
            component="main"
            className={`mainComponent mainComponent-${location?.pathname.replaceAll("/", "-")}`}
            pt={currentHasReminder ? 3 : 12}
            pb={10}
            minHeight="calc(100vh - 182px)"
        >
            <Container maxWidth="lg">{children}</Container>
        </Box>
    );
};

export default AppContainer;
