import { useEffect } from "react";
import { useHubspot } from "./useHubspot";
import { useProfile } from "src/modules/user/hooks/useProfile";

export const useHubspotLoad = () => {
    const { data: hubspotInfo, isSuccess } = useHubspot();
    const { data: userProfile } = useProfile();

    useEffect(() => {
        if (userProfile?.id) {
            let settings: { identificationEmail: string; identificationToken?: string } = {
                identificationEmail: userProfile.email,
            };

            if (isSuccess && hubspotInfo?.tocken) {
                settings = {
                    identificationEmail: userProfile.email,
                    identificationToken: hubspotInfo.tocken,
                };
                if (
                    window &&
                    (window as any).HubSpotConversations &&
                    (window as any).HubSpotConversations.widget
                ) {
                    (window as any).hsConversationsSettings = settings;
                    (window as any).HubSpotConversations.widget.load();
                }
            }
        }
    }, [userProfile?.id, isSuccess, hubspotInfo?.tocken, window]);
};
