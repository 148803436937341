import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { FileType } from "src/forms/types";
import { getImportTeacherTemplateEndpoint } from "../../api/Staff/getImportTeacherTemplate";
import { importTeachersEndpoint, ImportTeachersResponse } from "../../api/Staff/importTeachers";
import { StaffFilterParams } from "../reducers";

export enum StaffActionsTypes {
    GET_IMPORT_TEACHERS_TEMPLATE = "[school] GET IMPORT TEACHERS TEMPLATE",
    GET_IMPORT_TEACHERS_TEMPLATE_SUCCESS = "[school] GET IMPORT TEACHERS TEMPLATE SUCCESS",
    IMPORT_TEACHERS = "[school] IMPORT TEACHERS",
    IMPORT_TEACHERS_SUCCESS = "[school] IMPORT TEACHERS SUCCESS",
    SET_STAFF_FILTER = "[school] SET STAFF FILTER",
}

export const StaffApiEndpoints = {
    getImportTeachersTemplate: createActionObject(
        getImportTeacherTemplateEndpoint,
        StaffActionsTypes.GET_IMPORT_TEACHERS_TEMPLATE_SUCCESS,
    ),

    importTeachers: createActionObject(
        importTeachersEndpoint,
        StaffActionsTypes.IMPORT_TEACHERS_SUCCESS,
    ),
};

export const StaffRawActions = (
    StaffEndpoints: Record<Keys<typeof StaffApiEndpoints>, ApiActionMeta>,
) => ({
    setSaffFilter: (payload: { requestParams: StaffFilterParams | null }) =>
        createAction(StaffActionsTypes.SET_STAFF_FILTER, payload),
    getImportTeachersTemplate: () =>
        createApiAction(
            StaffActionsTypes.GET_IMPORT_TEACHERS_TEMPLATE,
            undefined,
            StaffEndpoints.getImportTeachersTemplate,
        ),
    getImportTeachersTemplateSuccess: (payload: ApiCallbackPayload<Blob>) =>
        createAction(StaffActionsTypes.GET_IMPORT_TEACHERS_TEMPLATE_SUCCESS, payload),
    importTeachers: (importFile: FileType) =>
        createApiAction(
            StaffActionsTypes.IMPORT_TEACHERS,
            { values: { file: importFile }, params: {} },
            StaffEndpoints.importTeachers,
        ),
    importTeachersSuccess: (payload: ApiCallbackPayload<ImportTeachersResponse>) =>
        createAction(StaffActionsTypes.IMPORT_TEACHERS_SUCCESS, payload),
});
