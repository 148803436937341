import COLORS from "src/styles/colors";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "src/styles/theme";
import { ShortSnapshotItem } from "../SnapshotModal";
import { SnapshotData } from "src/context/TrackerContext";

export enum CommonCompareDataSourceTypes {
    LIVE_TRACKING = "live-tracking",
    FORECAST = "forecast",
}

interface OwnProps {
    snapshots: ShortSnapshotItem[] | null;
    handleCompare: (snapshotData: SnapshotData) => void;
    disableForecast?: boolean;
}

const CompareSnapshots: FC<OwnProps> = ({ handleCompare, snapshots, disableForecast }) => {
    const { t } = useTranslation();
    const [selectedDataSource1, setSelectedDataSource1] = useState<string>(
        CommonCompareDataSourceTypes.LIVE_TRACKING,
    );
    const [selectedDataSource2, setSelectedDataSource2] = useState<string>(
        disableForecast
            ? CommonCompareDataSourceTypes.LIVE_TRACKING
            : CommonCompareDataSourceTypes.FORECAST,
    );

    return (
        <Box>
            <Box
                color={COLORS.BLUE_2}
                mb={4.25}
                fontSize={theme.typography.pxToRem(18)}
                fontWeight={theme.typography.fontWeightMedium}
                lineHeight={1.33}
            ></Box>
            <Grid spacing={4} container>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <InputLabel id="dataSource1">
                            {t("tracker.snapshot.source") + " 1"}
                        </InputLabel>
                        <Select
                            labelId="dataSource1"
                            fullWidth
                            label={t("tracker.snapshot.source") + " 1"}
                            value={selectedDataSource1}
                            onChange={e => setSelectedDataSource1(e.target.value as string)}
                        >
                            <MenuItem
                                key={CommonCompareDataSourceTypes.LIVE_TRACKING}
                                value={CommonCompareDataSourceTypes.LIVE_TRACKING}
                            >
                                {t("tracker.snapshot.liveTracking")}
                            </MenuItem>
                            {!disableForecast && (
                                <MenuItem
                                    key={CommonCompareDataSourceTypes.FORECAST}
                                    value={CommonCompareDataSourceTypes.FORECAST}
                                >
                                    {t("tracker.snapshot.forecast")}
                                </MenuItem>
                            )}
                            {snapshots &&
                                snapshots.map(s => (
                                    <MenuItem key={s.id} value={`${s.id}`}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl>
                        <InputLabel id="dataSource2">
                            {t("tracker.snapshot.source") + " 2"}
                        </InputLabel>
                        <Select
                            labelId="dataSource2"
                            name="dataSource2"
                            fullWidth
                            value={selectedDataSource2}
                            onChange={e => setSelectedDataSource2(e.target.value as string)}
                        >
                            <MenuItem
                                key={CommonCompareDataSourceTypes.LIVE_TRACKING}
                                value={CommonCompareDataSourceTypes.LIVE_TRACKING}
                            >
                                {t("tracker.snapshot.liveTracking")}
                            </MenuItem>
                            {!disableForecast && (
                                <MenuItem
                                    key={CommonCompareDataSourceTypes.FORECAST}
                                    value={CommonCompareDataSourceTypes.FORECAST}
                                >
                                    {t("tracker.snapshot.forecast")}
                                </MenuItem>
                            )}
                            {snapshots &&
                                snapshots.map(s => (
                                    <MenuItem key={s.id} value={`${s.id}`}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Box mt={0.875}>
                        <Button
                            disabled={selectedDataSource1 === selectedDataSource2}
                            onClick={() =>
                                handleCompare({
                                    snapshot: null,
                                    compare: {
                                        dataSource1: `${selectedDataSource1}`,
                                        dataSource2: `${selectedDataSource2}`,
                                    },
                                })
                            }
                        >
                            {t("tracker.snapshot.compareBtn")}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CompareSnapshots;
