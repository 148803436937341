import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { PagedList, Pagination } from "src/types";
import { TagModel } from "../model/TagModel";
import { getSchoolAccountId } from "src/services/url";

export interface TagListRequestFilters {
    cohort?: number;
    allYearGroups?: boolean;
    yearGroup?: number;
    allSubjectAreas?: boolean;
    subjectAreaId?: number;
    qualificationId?: number;
}

export const TAG_LIST_QUERY = "tagListQuery";

export const useTagsList = (pagination: Pagination, filters: TagListRequestFilters) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [TAG_LIST_QUERY, schoolAccountId, pagination, filters],
        queryFn: async (): Promise<PagedList<TagModel> | null> => {
            const { data } = await axiosInstance.get(`school/${getSchoolAccountId()}/tag/`, {
                params: { ...pagination, ...filters },
            });

            return data || null;
        },
    });
};
