import { attr, Model } from "redux-orm";
import { ListObject } from "src/forms/types";
import { OrmSubjectArea } from "../SubjectArea";

const fields = {
    id: attr(),
    invoiceUrl: attr(),
    totalPrice: attr(),
    couponName: attr(),
    subscriptionLength: attr(),
    subscription: attr(),
    paymentDate: attr(),
    startDate: attr(),
};

export interface OrmInvoice {
    id: number;
    invoiceUrl: string;
    totalPrice: string;
    couponName?: string;
    subscriptionLength: string;
    subscription: {
        id: number;
        subscriptionType: string;
        subjectArea: OrmSubjectArea | null;
        qualifications: ListObject<number>[];
    };
    startDate?: string;
    paymentDate?: string;
}

class Invoice extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "Invoice";
    static fields = fields;
    static emptyRef: OrmInvoice = {
        id: 0,
        invoiceUrl: "",
        totalPrice: "",
        couponName: "",
        subscriptionLength: "",
        subscription: {
            id: 0,
            subscriptionType: "",
            subjectArea: null,
            qualifications: [],
        },
    };

    get ormRef(): OrmInvoice {
        return this.ref as any;
    }

    get ormRefDetails(): OrmInvoice {
        return this.ormRef as any;
    }
}

export default Invoice;
