import { Box, Typography, Grid, TextField, MenuItem, Chip } from "@mui/material";
import { mdiClose } from "@mdi/js";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApiStatus } from "src/api/constants";
import Icon from "src/components/Icon";
import { HTTP_NO_CONTENT } from "src/config/globals";
import { usePrevious } from "src/hooks/usePrevious";
import { useResponse } from "src/hooks/useResponse";
import { AppState } from "src/store/reducers";
import { StudentReportBySubjectRequest } from "../../api/StudentReport/bySubject";
import { useUserReportValidation } from "../../hooks/useUserReportValidation";
import { AnalysisActions } from "../../../analysis/store/actions";
import ReportActionsContainer from "./ReportActionsContainer";
import { ReportActions } from "../../store/actions";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useCohortSnapshotList } from "src/modules/school/hooks/query/CohortSnapshot/useCohortSnapshotList";

const dispatchActions = (dispatch: Dispatch) => ({
    getSubjectAreasWithClases: (cohort: number, yearGroup: number) => {
        dispatch(ReportActions.getStudentClassesBySubject(cohort, yearGroup));
    },
    getStudentReportBySubject: (
        cohort: number,
        yearGroup: number,
        values: StudentReportBySubjectRequest,
    ) => {
        dispatch(ReportActions.getStudentReportBySubject(cohort, yearGroup, values));
    },
    getStudentsForClassTracker: (cohort: number, yearGroup: number, classTracker: number) => {
        dispatch(
            AnalysisActions.getAnalysisStudentsForClassTracker(cohort, yearGroup, classTracker),
        );
    },
});

const StudentReportBySubjectTab = ({ cohort, yearGroup, reportTemplates }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedSubject, setSelectedSubject] = useState<number>(0);
    const [selectedQualification, setSelectedQualification] = useState<number>(0);
    const [selectedSpecification, setSelectedSpecification] = useState<number>(0);
    const [selectedClass, setSelectedClass] = useState<number>(0);
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    const [selectedSnapshot, setSelectedSnapshot] = useState<number | null>(0);
    const [selectedReportTemplate, setSelectedReportTemplate] = useState<number>(0);
    const { getSubjectAreasWithClases, getStudentReportBySubject, getStudentsForClassTracker } =
        dispatchActions(dispatch);
    const [locked, setLocked] = useState(false);
    const { apiValidation, checkValidation } = useUserReportValidation();
    const { apiReport, analysisClassTrackerStudents, analysisClassesWithStudentNumber } =
        useSelector(
            (state: AppState) => ({
                apiReport: state.api.report.getStudentReportBySubject,
                analysisClassTrackerStudents: state.analysis.analysisClassTrackerStudents,
                analysisClassesWithStudentNumber: state.report.analysisClassesWithStudentNumber,
            }),
            shallowEqual,
        );
    const { data: snapshots, refetch: refetchSnapshots } = useCohortSnapshotList(cohort, {
        onlyCreated: true,
    });

    const prepareRequest = () => {
        return {
            subjectArea: { id: selectedSubject },
            qualification: selectedQualification ? { id: selectedQualification } : null,
            specification: selectedSpecification ? { id: selectedSpecification } : null,
            classTracker: selectedClass ? { id: selectedClass } : null,
            analysisStudents:
                selectedStudents.length > 0 ? selectedStudents.map(sId => ({ id: sId })) : [],
            reportTemplate: selectedReportTemplate ? { id: selectedReportTemplate } : undefined,
            schoolSnapshot: selectedSnapshot ? { id: selectedSnapshot } : null,
        };
    };

    useEffect(() => {
        if (reportTemplates && reportTemplates.length > 0 && selectedReportTemplate === 0) {
            const defaultTemplate = reportTemplates.find(rt => rt.isDefault);
            setSelectedReportTemplate(defaultTemplate ? defaultTemplate.id : reportTemplates[0].id);
        }
    }, [reportTemplates]);

    useEffect(() => {
        checkValidation();
        if (cohort && yearGroup > -1) {
            getSubjectAreasWithClases(cohort, yearGroup);
            setSelectedSubject(0);
            setSelectedSpecification(0);
            setSelectedQualification(0);
            setSelectedClass(0);
            setSelectedStudents([]);
            refetchSnapshots();
        }
    }, [cohort, yearGroup]);

    const requestReport = () => {
        if (cohort && yearGroup > -1 && selectedSubject) {
            const values = prepareRequest();
            getStudentReportBySubject(cohort, yearGroup, values);
        } else {
            enqueueSnackbar(t("common.noDataSelected"), {
                ...SnackbarErrorOptions,
            });
        }
    };

    useEffect(() => {
        if (apiValidation.status === ApiStatus.ERROR && apiReport.status === ApiStatus.ERROR) {
            setLocked(true);
        }
        if (
            apiValidation.status === ApiStatus.SUCCESS &&
            apiValidation.responseStatus === HTTP_NO_CONTENT &&
            apiReport.status === ApiStatus.ERROR
        ) {
            setLocked(false);
        }
    }, [apiReport, apiValidation]);

    useResponse(() => {
        if (
            apiReport.status === ApiStatus.SUCCESS &&
            apiReport.responseStatus === HTTP_NO_CONTENT
        ) {
            setLocked(false);
            enqueueSnackbar(t("report.studentReport.bySubject.requestSuccess"), {
                variant: "success",
            });
        }
        checkValidation();
    }, apiReport);

    const filteredQualifications = selectedSubject
        ? analysisClassesWithStudentNumber?.find(s => s.id === selectedSubject)?.qualifications
        : null;

    const filteredSpecifications =
        selectedSubject && selectedQualification && filteredQualifications
            ? filteredQualifications.find(q => q.id === selectedQualification)?.specifications
            : null;

    const filteredClasses =
        selectedSubject && selectedQualification && selectedSpecification && filteredSpecifications
            ? filteredSpecifications.find(s => s.id === selectedSpecification)?.classes
            : null;

    const prevSubjectArea = usePrevious(selectedSubject);
    const prevQualification = usePrevious(selectedQualification);
    const prevSpecification = usePrevious(selectedSpecification);
    const prevSelectedClass = usePrevious(selectedClass);

    useEffect(() => {
        if (prevSubjectArea !== undefined && prevSubjectArea !== selectedSubject) {
            setSelectedQualification(0);
            setSelectedSpecification(0);
            setSelectedClass(0);
            setSelectedStudents([]);
        }
        if (prevQualification !== undefined && prevQualification !== selectedQualification) {
            setSelectedSpecification(0);
            setSelectedClass(0);
            setSelectedStudents([]);
        }
        if (prevSpecification !== undefined && prevSpecification !== selectedSpecification) {
            setSelectedClass(0);
            setSelectedStudents([]);
        }
        if (
            prevSelectedClass !== undefined &&
            selectedClass &&
            prevSelectedClass !== selectedClass
        ) {
            getStudentsForClassTracker(cohort, yearGroup, selectedClass);
            setSelectedStudents([]);
        }
        checkValidation();
    }, [
        selectedSubject,
        selectedQualification,
        selectedSpecification,
        selectedClass,
        selectedStudents,
    ]);

    let studentCount = 0;

    if (selectedStudents.length > 0) studentCount = selectedStudents.length;

    if (selectedStudents.length === 0 && selectedClass === 0 && selectedSubject > 0) {
        studentCount =
            filteredQualifications?.reduce((studentQualCount: number, qual) => {
                if (qual?.specifications && qual?.specifications.length > 0) {
                    return (
                        studentQualCount +
                        (qual?.specifications.reduce((studentSpecCount: number, spec) => {
                            if (spec.classes && spec.classes.length > 0) {
                                return (
                                    studentSpecCount +
                                    (spec.classes.reduce((studentClassCount: number, cla) => {
                                        if (cla.numberOfStudents) {
                                            return studentClassCount + cla.numberOfStudents;
                                        }
                                        return studentClassCount;
                                    }, 0) || 0)
                                );
                            }
                            return studentSpecCount;
                        }, 0) || 0)
                    );
                }

                return studentQualCount;
            }, 0) || 0;
    }

    if (selectedStudents.length === 0 && selectedClass === 0 && selectedQualification > 0) {
        studentCount =
            filteredSpecifications?.reduce((studentSpecCount: number, spec) => {
                if (spec?.classes && spec?.classes.length > 0) {
                    return (
                        studentSpecCount +
                        (spec?.classes.reduce((studentClassCount: number, cla) => {
                            if (cla.numberOfStudents) {
                                return studentClassCount + cla.numberOfStudents;
                            }
                            return studentClassCount;
                        }, 0) || 0)
                    );
                }

                return studentSpecCount;
            }, 0) || 0;
    }

    if (selectedStudents.length === 0 && selectedClass === 0 && selectedSpecification > 0) {
        studentCount =
            filteredClasses?.reduce((studentClassCount: number, cla) => {
                if (cla.numberOfStudents) {
                    return studentClassCount + cla.numberOfStudents;
                }
                return studentClassCount;
            }, 0) || 0;
    }

    if (selectedStudents.length === 0 && selectedClass && filteredClasses) {
        studentCount = filteredClasses.find(fc => fc.id === selectedClass)?.numberOfStudents || 0;
    }
    const filteredSnapshots = snapshots?.filter(({ isCreated }) => isCreated);
    return (
        <Box pr="9.5%">
            <Typography variant="h6" gutterBottom>
                {t("report.studentReport.bySubject.header")}
            </Typography>
            <Box sx={{ width: "50%" }}>
                <Grid container spacing={4}>
                    <Grid item sm={12}>
                        <Typography>{t("report.studentReport.bySubject.hint")}</Typography>
                    </Grid>
                    {filteredSnapshots?.length > 0 && (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.byStudent.source")}
                                onChange={event =>
                                    setSelectedSnapshot(parseInt(`${event.target.value}`))
                                }
                                fullWidth
                                value={selectedSnapshot}
                                select
                            >
                                <MenuItem key={0} value={0}>
                                    {t("report.studentReport.byStudent.liveTracking")}
                                </MenuItem>
                                {filteredSnapshots.map(s => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                    <Grid item sm={8}>
                        <TextField
                            label={t("report.studentReport.bySubject.selectSubjectArea")}
                            value={selectedSubject}
                            onChange={e => {
                                setSelectedSubject(e.target.value ? parseInt(e.target.value) : 0);
                            }}
                            select
                        >
                            <MenuItem value={0}>{t("common.noneSelect")}</MenuItem>
                            {analysisClassesWithStudentNumber?.map(subjectArea => {
                                return (
                                    <MenuItem value={subjectArea.id} key={subjectArea.id}>
                                        {subjectArea.fullname}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>

                    {selectedSubject > 0 && (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.bySubject.selectQualification")}
                                value={selectedQualification}
                                onChange={e => {
                                    setSelectedQualification(parseInt(e.target.value));
                                }}
                                select
                            >
                                <MenuItem value={0}>{t("common.allQualifications")}</MenuItem>
                                {filteredQualifications?.map(fQ => {
                                    return (
                                        <MenuItem value={fQ.id} key={fQ.id}>
                                            {fQ.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                    )}
                    {selectedQualification > 0 && (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.bySubject.selectSpecification")}
                                value={selectedSpecification}
                                onChange={e => {
                                    setSelectedSpecification(parseInt(e.target.value));
                                }}
                                select
                            >
                                <MenuItem value={0}>{t("common.allSpecifications")}</MenuItem>
                                {filteredSpecifications?.map(s => {
                                    return (
                                        <MenuItem value={s.id} key={s.id}>
                                            {s.fullname}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                    )}
                    {selectedSpecification > 0 && (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.bySubject.selectClass")}
                                value={selectedClass}
                                onChange={e => {
                                    setSelectedClass(parseInt(e.target.value));
                                }}
                                select
                            >
                                <MenuItem value={0}>{t("common.allClasses")}</MenuItem>
                                {filteredClasses?.map(fClass => {
                                    return (
                                        <MenuItem value={fClass.id} key={fClass.id}>
                                            {fClass.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                    )}
                    {selectedClass > 0 && (
                        <Grid item sm={8}>
                            <TextField
                                select
                                value={
                                    selectedStudents && selectedStudents.length > 0
                                        ? selectedStudents
                                        : []
                                }
                                SelectProps={{
                                    multiple: true,
                                    displayEmpty: true,
                                    onChange: e => setSelectedStudents(e.target.value as number[]),
                                    renderValue: () =>
                                        t("report.studentReport.bySubject.selectStudent"),
                                }}
                            >
                                {analysisClassTrackerStudents?.map(obj => (
                                    <MenuItem key={obj.id} value={obj.id}>
                                        {`${obj.firstName} ${obj.lastName} (${obj.uniquePupilNumber})`}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {selectedStudents &&
                                selectedStudents.length > 0 &&
                                selectedStudents.map(ss => {
                                    const option = analysisClassTrackerStudents?.find(
                                        o => o.id === ss,
                                    );
                                    if (option) {
                                        return (
                                            <Chip
                                                key={ss}
                                                style={{ marginRight: 5, marginBottom: 6 }}
                                                label={`${option.firstName} ${option.lastName} (${option.uniquePupilNumber})`}
                                                onDelete={() => {
                                                    setSelectedStudents([
                                                        ...selectedStudents.filter(s => s !== ss),
                                                    ]);
                                                }}
                                                deleteIcon={
                                                    <Box component="span">
                                                        <Icon path={mdiClose} />
                                                    </Box>
                                                }
                                            />
                                        );
                                    }
                                    return null;
                                })}
                        </Grid>
                    )}
                    {reportTemplates && reportTemplates.length > 0 ? (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.tabs.reportTemplate")}
                                value={selectedReportTemplate}
                                onChange={e => {
                                    setSelectedReportTemplate(parseInt(e.target.value));
                                }}
                                select
                            >
                                {reportTemplates.map(s => {
                                    return (
                                        <MenuItem value={s.id} key={s.id}>
                                            {s.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid item sm={12}>
                        <ReportActionsContainer
                            studentsCount={studentCount}
                            refreshReportHandler={() => {
                                checkValidation();
                            }}
                            requestReportHandler={() => requestReport()}
                            locked={locked}
                            showOnlySummaryCheckbox={false}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default StudentReportBySubjectTab;
