import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

// for new Subject report
export const getSearchSnapshotFiltersEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/search/snapshot/{cohort}/filters/`,
    method: ApiRequests.GET,
};

export interface SearchSnapshotFiltersResponse {
    subjectAreas: {
        id: number;
        name: string;
        qualifications: {
            id: number;
            name: string;
            specifications: { id: number; name: string; yearGroups: number[] }[];
        }[];
    }[];
}
