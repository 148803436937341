import AssessmentsListContainer from "../containers/AssessmentsListContainer";
import EditEndOfTopicTestContainer from "../containers/EditEndOfTopicTestContainer";
import EditPastPaperContainer from "../containers/EditPastPaperContainer";
import AssessmentCreateContainer from "../containers/AssessmentCreateContainer";
import {
    ROUTE_CLASS_END_OF_TOPIC_TEST_EDIT,
    ROUTE_CLASS_PAST_PAPER_EDIT,
    ROUTE_CLASS_ASSESSMENT_LIST,
    ROUTE_CLASS_ASSESSMENT_CREATE,
} from "src/routes";

export const AssessmentRoutes = [
    {
        path: ROUTE_CLASS_END_OF_TOPIC_TEST_EDIT,
        element: <EditEndOfTopicTestContainer />,
    },
    {
        path: ROUTE_CLASS_PAST_PAPER_EDIT,
        element: <EditPastPaperContainer />,
    },
    {
        path: ROUTE_CLASS_ASSESSMENT_LIST,
        element: <AssessmentsListContainer />,
    },
    {
        path: ROUTE_CLASS_ASSESSMENT_CREATE,
        element: <AssessmentCreateContainer />,
    },
];
