import { ApiRequests, ApiEndpoint } from "src/api/constants";
import { ClassTrackerValuesObject } from "src/modules/tracker/dto/TrackerValues";
import { getSchoolAccountId } from "src/services/url";

export const getClassTrackerGroupSnapshotValuesEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker-group/{classTrackerGroup}/{tier}/snapshot/{snapshot}/values/`,
    method: ApiRequests.GET,
};

export type SnapshotClassTrackerGroupValuesObject = ClassTrackerValuesObject;
