import { Keys, createAction } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { schoolLogoEndpoint } from "../../api/Details/schoolLogo";
import { FileType } from "src/forms/types";
import { deleteSchoolLogoEndpoint } from "../../api/Details/deleteSchoolLogo";
import { SchoolDetailsResponse } from "../../hooks/query/SchoolDetails/useSchoolDetails";

export enum SchoolActionsTypes {
    SET_SCHOOL_DETAILS = "[school] SET SCHOOL DETAILS",
    CLEAR_SCHOOL_DETAILS = "[school] CLEAR SCHOOL DETAILS",
    UPLOAD_SCHOOL_LOGO = "[school] UPLOAD SCHOOL LOGO",
    UPLOAD_SCHOOL_LOGO_SUCCESS = "[school] UPLOAD SCHOOL LOGO SUCCESS",
    DELETE_SCHOOL_LOGO = "[school] DELETE SCHOOL LOGO",
    DELETE_SCHOOL_LOGO_SUCCESS = "[school] DELETE SCHOOL LOGO SUCCESS",
}

export const SchoolApiEndpoints = {
    uploadSchoolLogo: createActionObject(
        schoolLogoEndpoint,
        SchoolActionsTypes.UPLOAD_SCHOOL_LOGO_SUCCESS,
    ),
    deleteSchoolLogo: createActionObject(
        deleteSchoolLogoEndpoint,
        SchoolActionsTypes.DELETE_SCHOOL_LOGO_SUCCESS,
    ),
};

export const SchoolRawActions = (
    SchoolEndpoints: Record<Keys<typeof SchoolApiEndpoints>, ApiActionMeta>,
) => ({
    setSchoolDetails: (
        payload: SchoolDetailsResponse, //TODO to react query
    ) => createAction(SchoolActionsTypes.SET_SCHOOL_DETAILS, payload),
    clearSchoolDetails: () => createAction(SchoolActionsTypes.CLEAR_SCHOOL_DETAILS),
    uploadSchoolLogo: (logoFile: FileType) =>
        createApiAction(
            SchoolActionsTypes.UPLOAD_SCHOOL_LOGO,
            { values: { logoFile: logoFile }, params: {} },
            SchoolEndpoints.uploadSchoolLogo,
        ),
    uploadSchoolLogoSuccess: (payload: ApiCallbackPayload<SchoolDetailsResponse>) =>
        createAction(SchoolActionsTypes.UPLOAD_SCHOOL_LOGO_SUCCESS, payload),
    deleteSchoolLogo: () =>
        createApiAction(
            SchoolActionsTypes.DELETE_SCHOOL_LOGO,
            undefined,
            SchoolEndpoints.deleteSchoolLogo,
        ),
    deleteSchoolLogoSuccess: (payload: ApiCallbackPayload<SchoolDetailsResponse>) =>
        createAction(SchoolActionsTypes.DELETE_SCHOOL_LOGO_SUCCESS, payload),
});
