import LoginContainer from "src/modules/auth/containers/LoginContainer";
import { useState, ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "src/context/AuthContext";
import { Storage } from "src/services/storage";
import { Box, CircularProgress } from "@mui/material";

export type AuthGuardProps = {
    children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
    const { pathname } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
    const { authState } = useAuthContext();
    const [isInitial, setIsInitial] = useState(true);

    useEffect(() => {
        if (isInitial) {
            setIsInitial(false);
        }
    }, [isInitial]);

    if (isInitial || !authState) {
        return (
            <Box textAlign={"center"} p={20}>
                <CircularProgress />
            </Box>
        );
    }

    if (!authState.token && !Storage.getItem("token")) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <LoginContainer />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
}
