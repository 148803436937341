import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload, ApiPayload } from "src/api/constants";
import { createActionObject, createApiAction, paramRequest } from "src/api/helpers";
import { CohortParam } from "src/forms/types";
import { OrmSnapshotReport } from "src/orm/models/SnapshotReport";
import {
    createSummarySnapshotReportEndpoint,
    CreateSummarySnapshotReportRequest,
} from "../../api/SnapshotReport/createSummary";
import {
    createAllSubjectsSnapshotReportEndpoint,
    CreateAllSubjectsSnapshotReportRequest,
} from "../../api/SnapshotReport/createAllSubjects";
import {
    getSnapshotReportListEndpoint,
    GetSnapshotReportListFilter,
    SnapshotReportListResponse,
} from "../../api/SnapshotReport/getList";
import {
    getSnapshotReportsFiltersEndpoint,
    SnapshotReportFilterResponse,
} from "../../api/SnapshotReport/getListFilters";
import { publishSnapshotReportEndpoint } from "../../api/SnapshotReport/publish";
import { removeSnapshotReportEndpoint } from "../../api/SnapshotReport/remove";
import {
    getSearchSnapshotFiltersEndpoint,
    SearchSnapshotFiltersResponse,
} from "../../api/SnapshotReport/searchSnapshotFilters";
import { withdrawSnapshotReportEndpoint } from "../../api/SnapshotReport/withdraw";
import {
    createSubjectSnapshotReportEndpoint,
    CreateSubjectSnapshotReportRequest,
} from "../../api/SnapshotReport/createSubject";
import { cancelSnapshotReportEndpoint } from "../../api/SnapshotReport/cancelReport";

export enum SnapshotReportActionsTypes {
    GET_SNAPSHOT_REPORT_LIST = "[report] GET SNAPSHOT REPORT LIST",
    GET_SNAPSHOT_REPORT_LIST_SUCCESS = "[report] GET SNAPSHOT REPORT LIST SUCCESS",
    GET_SNAPSHOT_REPORT_LIST_FILTERS = "[report] GET SNAPSHOT REPORT LIST FILTERS",
    GET_SNAPSHOT_REPORT_LIST_FILTERS_SUCCESS = "[report] GET SNAPSHOT REPORT LIST FILTERS SUCCESS",
    PUBLISH_SNAPSHOT_REPORT = "[report] PUBLISH SNAPSHOT REPORT",
    PUBLISH_SNAPSHOT_REPORT_SUCCESS = "[report] PUBLISH SNAPSHOT REPORT SUCCESS",
    REMOVE_SNAPSHOT_REPORT = "[report] REMOVE SNAPSHOT REPORT",
    REMOVE_SNAPSHOT_REPORT_SUCCESS = "[report] REMOVE SNAPSHOT REPORT SUCCESS",
    WITHDRAW_SNAPSHOT_REPORT = "[report] WITHDRAW SNAPSHOT REPORT",
    WITHDRAW_SNAPSHOT_REPORT_SUCCESS = "[report] WITHDRAW SNAPSHOT REPORT SUCCESS",
    SET_SNAPSHOT_REPORT_FILTER = "[report] SET SNAPSHOT REPORT FILTER",
    SET_SNAPSHOT_REPORT_COURSE_FILTER = "[report] SET SNAPSHOT REPORT COURSE FILTER",
    GET_SEARCH_SNAPSHOT_FILTERS = "[report] GET SEARCH SNAPSHOT FILTERS",
    GET_SEARCH_SNAPSHOT_FILTERS_SUCCESS = "[report] GET SEARCH SNAPSHOT FILTERS SUCCESS",
    CREATE_SUMMARY_SNAPSHOT_REPORT = "[report] CREATE SUMMARY SNAPSHOT REPORT",
    CREATE_SUMMARY_SNAPSHOT_REPORT_SUCCESS = "[report] CREATE SUMMARY SNAPSHOT REPORT SUCCESS",
    CREATE_ALL_SUBJECTS_SNAPSHOT_REPORT = "[report] CREATE ALL SUBJECTS SNAPSHOT REPORT",
    CREATE_ALL_SUBJECTS_SNAPSHOT_REPORT_SUCCESS = "[report] CREATE ALL SUBJECTS SNAPSHOT REPORT SUCCESS",
    CREATE_SINGLE_SUBJECT_SNAPSHOT_REPORT = "[report] CREATE SINGLE SUBJECT SNAPSHOT REPORT",
    CREATE_SINGLE_SUBJECT_SNAPSHOT_REPORT_SUCCESS = "[report] CREATE SINGLE SUBJECT SNAPSHOT REPORT SUCCESS",
    CANCEL_SNAPSHOT_REPORT = "[report] CANCEL SNAPSHOT REPORT",
    CANCEL_SNAPSHOT_REPORT_SUCCESS = "[report] CANCEL SNAPSHOT REPORT SUCCESS",
}

export const SnapshotReportApiEndpoints = {
    getSnapshotReportList: createActionObject(
        getSnapshotReportListEndpoint,
        SnapshotReportActionsTypes.GET_SNAPSHOT_REPORT_LIST_SUCCESS,
    ),
    getSnapshotReportListFilters: createActionObject(
        getSnapshotReportsFiltersEndpoint,
        SnapshotReportActionsTypes.GET_SNAPSHOT_REPORT_LIST_FILTERS_SUCCESS,
    ),
    createSummarySnapshotReport: createActionObject(
        createSummarySnapshotReportEndpoint,
        SnapshotReportActionsTypes.CREATE_SUMMARY_SNAPSHOT_REPORT_SUCCESS,
    ),
    createAllSubjectsSnapshotReport: createActionObject(
        createAllSubjectsSnapshotReportEndpoint,
        SnapshotReportActionsTypes.CREATE_ALL_SUBJECTS_SNAPSHOT_REPORT_SUCCESS,
    ),
    createSubjectSnapshotReport: createActionObject(
        createSubjectSnapshotReportEndpoint,
        SnapshotReportActionsTypes.CREATE_SINGLE_SUBJECT_SNAPSHOT_REPORT_SUCCESS,
    ),
    publishSnapshotReport: createActionObject(
        publishSnapshotReportEndpoint,
        SnapshotReportActionsTypes.PUBLISH_SNAPSHOT_REPORT_SUCCESS,
    ),
    removeSnapshotReport: createActionObject(
        removeSnapshotReportEndpoint,
        SnapshotReportActionsTypes.REMOVE_SNAPSHOT_REPORT_SUCCESS,
    ),
    cancelSnapshotReport: createActionObject(
        cancelSnapshotReportEndpoint,
        SnapshotReportActionsTypes.CANCEL_SNAPSHOT_REPORT_SUCCESS,
    ),
    withdrawSnapshotReport: createActionObject(
        withdrawSnapshotReportEndpoint,
        SnapshotReportActionsTypes.WITHDRAW_SNAPSHOT_REPORT_SUCCESS,
    ),
    getSearchSnapshotFilters: createActionObject(
        getSearchSnapshotFiltersEndpoint,
        SnapshotReportActionsTypes.GET_SEARCH_SNAPSHOT_FILTERS_SUCCESS,
    ),
};

export const SnapshotReportRawActions = (
    SnapshotReportEndpoints: Record<Keys<typeof SnapshotReportApiEndpoints>, ApiActionMeta>,
) => ({
    getSnapshotReportList: (payload: ApiPayload<GetSnapshotReportListFilter, CohortParam>) =>
        createApiAction(
            SnapshotReportActionsTypes.GET_SNAPSHOT_REPORT_LIST,
            payload,
            SnapshotReportEndpoints.getSnapshotReportList,
        ),
    getSnapshotReportListSuccess: (payload: ApiCallbackPayload<SnapshotReportListResponse>) =>
        createAction(SnapshotReportActionsTypes.GET_SNAPSHOT_REPORT_LIST_SUCCESS, payload),
    getSnapshotReportListFilters: (cohort: number) =>
        createApiAction(
            SnapshotReportActionsTypes.GET_SNAPSHOT_REPORT_LIST_FILTERS,
            paramRequest({ cohort }),
            SnapshotReportEndpoints.getSnapshotReportListFilters,
        ),
    getSnapshotReportListFiltersSuccess: (
        payload: ApiCallbackPayload<SnapshotReportFilterResponse>,
    ) => createAction(SnapshotReportActionsTypes.GET_SNAPSHOT_REPORT_LIST_SUCCESS, payload),
    setSnapshotReportListFilter: (payload: SnapshotReportFilterResponse) =>
        createAction(SnapshotReportActionsTypes.SET_SNAPSHOT_REPORT_COURSE_FILTER, payload),
    setSnapshotReportfilter: (
        payload: SnapshotReportListResponse & {
            requestParams?: GetSnapshotReportListFilter | null;
        },
    ) => createAction(SnapshotReportActionsTypes.SET_SNAPSHOT_REPORT_FILTER, payload),
    removeSnapshotReport: (id: number) =>
        createApiAction(
            SnapshotReportActionsTypes.REMOVE_SNAPSHOT_REPORT,
            { params: { id }, values: {} },
            SnapshotReportEndpoints.removeSnapshotReport,
        ),
    removeSnapshotReportSuccess: () =>
        createAction(SnapshotReportActionsTypes.REMOVE_SNAPSHOT_REPORT_SUCCESS),
    cancelSnapshotReport: (id: number) =>
        createApiAction(
            SnapshotReportActionsTypes.CANCEL_SNAPSHOT_REPORT,
            { params: { id }, values: {} },
            SnapshotReportEndpoints.cancelSnapshotReport,
        ),
    cancelSnapshotReportSuccess: () =>
        createAction(SnapshotReportActionsTypes.CANCEL_SNAPSHOT_REPORT_SUCCESS),
    publishSnapshotReport: (id: number) =>
        createApiAction(
            SnapshotReportActionsTypes.PUBLISH_SNAPSHOT_REPORT,
            { params: { id }, values: {} },
            SnapshotReportEndpoints.publishSnapshotReport,
        ),
    publishSnapshotReportSuccess: () =>
        createAction(SnapshotReportActionsTypes.PUBLISH_SNAPSHOT_REPORT_SUCCESS),
    withdrawSnapshotReport: (id: number) =>
        createApiAction(
            SnapshotReportActionsTypes.WITHDRAW_SNAPSHOT_REPORT,
            { params: { id }, values: {} },
            SnapshotReportEndpoints.withdrawSnapshotReport,
        ),
    withdrawSnapshotReportSuccess: () =>
        createAction(SnapshotReportActionsTypes.WITHDRAW_SNAPSHOT_REPORT_SUCCESS),
    getSearchSnapshotFilters: (cohort: number) =>
        createApiAction(
            SnapshotReportActionsTypes.GET_SEARCH_SNAPSHOT_FILTERS,
            paramRequest({ cohort }),
            SnapshotReportEndpoints.getSearchSnapshotFilters,
        ),
    getSearchSnapshotFiltersSuccess: (payload: ApiCallbackPayload<SearchSnapshotFiltersResponse>) =>
        createAction(SnapshotReportActionsTypes.GET_SEARCH_SNAPSHOT_FILTERS_SUCCESS, payload),
    createSummarySnapshotReport: (values: CreateSummarySnapshotReportRequest) =>
        createApiAction(
            SnapshotReportActionsTypes.CREATE_SUMMARY_SNAPSHOT_REPORT,
            { params: {}, values: { ...values } },
            SnapshotReportEndpoints.createSummarySnapshotReport,
        ),
    createSummarySnapshotReportSuccess: (payload: ApiCallbackPayload<OrmSnapshotReport>) =>
        createAction(SnapshotReportActionsTypes.CREATE_SUMMARY_SNAPSHOT_REPORT_SUCCESS, payload),
    createAllSubjectsSnapshotReport: (values: CreateAllSubjectsSnapshotReportRequest) =>
        createApiAction(
            SnapshotReportActionsTypes.CREATE_ALL_SUBJECTS_SNAPSHOT_REPORT,
            { params: {}, values: { ...values } },
            SnapshotReportEndpoints.createAllSubjectsSnapshotReport,
        ),
    createAllSubjectsSnapshotReportSuccess: (payload: ApiCallbackPayload<OrmSnapshotReport>) =>
        createAction(
            SnapshotReportActionsTypes.CREATE_ALL_SUBJECTS_SNAPSHOT_REPORT_SUCCESS,
            payload,
        ),
    createSingleSubjectSnapshotReport: (values: CreateSubjectSnapshotReportRequest) =>
        createApiAction(
            SnapshotReportActionsTypes.CREATE_SINGLE_SUBJECT_SNAPSHOT_REPORT,
            { params: {}, values: { ...values } },
            SnapshotReportEndpoints.createSubjectSnapshotReport,
        ),
    createSingleSubjectSnapshotReportSuccess: (payload: ApiCallbackPayload<OrmSnapshotReport>) =>
        createAction(
            SnapshotReportActionsTypes.CREATE_SINGLE_SUBJECT_SNAPSHOT_REPORT_SUCCESS,
            payload,
        ),
});
