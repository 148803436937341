import { attr, Model } from "redux-orm";

const fields = {
    id: attr(),
    name: attr(),
    lvl: attr(),
    lft: attr(),
};

export interface OrmTopic {
    id?: number;
    name: string;
    lvl: number;
    lft: number;
    rgt?: number;
    specificationId?: number;
}

class Topic extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "Topic";
    static fields = fields;
    static emptyRef: OrmTopic = {
        name: "",
        lvl: 0,
        lft: 0,
    };

    get ormRef(): OrmTopic {
        return this.ref as any;
    }

    get ormRefDetails(): OrmTopic {
        return this.ref as any;
    }
}

export default Topic;
