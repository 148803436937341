import { Button, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ROUTE_CLASS_CLASS_LIST } from "src/routes";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: 700,
    },
}));

const FinishedStep = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { attachSchoolId } = useSchoolNavigate();

    return (
        <Fragment>
            <Typography gutterBottom className={classes.bold}>
                {t("auth.register.finishedStep.header")}
            </Typography>
            <Typography gutterBottom>{t("auth.register.finishedStep.message1")}</Typography>
            <Box mb={4.5}>
                <Button
                    component={Link}
                    to={attachSchoolId(ROUTE_CLASS_CLASS_LIST) + "?addClass=1"} // todo: check
                    color="primary"
                >
                    {t("auth.register.finishedStep.setupTrackerBtn")}
                </Button>
            </Box>
        </Fragment>
    );
};

export default FinishedStep;
