import DefaultValueEditor from "../components/Grid/CellEditor/DefaultValueEditor";
import AnalysisTagDataEditor from "../components/Grid/CellEditor/TagDataEditor";
import AnalysisAverageRenderer from "../components/Grid/CellRenderer/AnalysisAverageRenderer";
import AnalysisDefaultGradeRenderer from "../components/Grid/CellRenderer/AnalysisDefaultGradeRenderer";
import AnalysisDummyRenderer from "../components/Grid/CellRenderer/AnalysisDummyRenderer";
import PinnedTopRenderer from "../components/Grid/CellRenderer/PinnedTopRenderer";
import StudentDataRenderer from "../components/Grid/CellRenderer/StudentDataRenderer";
import AnalysisTagAllSubjectsRenderer from "../components/Grid/CellRenderer/TagAllSubjectsRenderer";
import AnalysisTagAvgRenderer from "../components/Grid/CellRenderer/TagAvgRenderer";
import AnalysisTagDataRenderer from "../components/Grid/CellRenderer/TagDataRenderer";

export enum AnalysisGridFrameworkComponents {
    ANALYSIS_DEFAULT_GRADE_RENDERER = "analysisDefaultGradeRenderer",
    ANALYSIS_AVERAGE_RENDERER = "analysisAverageRenderer",
    ANALYSIS_DUMMY_RENDERER = "analysisDummyRenderer",
    STUDENT_DATA_RENDERER = "studentDataRenderer",
    PINNED_TOP_RENDERER = "pinnedTopRenderer",
    DEFAULT_VALUE_EDITOR = "defaultValueEditor",
    TAG_DATA_RENDERER = "tagDataRenderer",
    TAG_DATA_EDITOR = "tagDataEditor",
    TAG_AVG_RENDERER = "tagAvgRenderer",
    TAG_ALLSUBJECTS_RENDERER = "tagAllSubjectsRenderer",
}

export const analysisFrameworkComponentsList = {
    [AnalysisGridFrameworkComponents.ANALYSIS_DEFAULT_GRADE_RENDERER]: AnalysisDefaultGradeRenderer,
    [AnalysisGridFrameworkComponents.ANALYSIS_DUMMY_RENDERER]: AnalysisDummyRenderer,
    [AnalysisGridFrameworkComponents.STUDENT_DATA_RENDERER]: StudentDataRenderer,
    [AnalysisGridFrameworkComponents.PINNED_TOP_RENDERER]: PinnedTopRenderer,
    [AnalysisGridFrameworkComponents.DEFAULT_VALUE_EDITOR]: DefaultValueEditor,
    [AnalysisGridFrameworkComponents.ANALYSIS_AVERAGE_RENDERER]: AnalysisAverageRenderer,
    [AnalysisGridFrameworkComponents.TAG_DATA_EDITOR]: AnalysisTagDataEditor,
    [AnalysisGridFrameworkComponents.TAG_DATA_RENDERER]: AnalysisTagDataRenderer,
    [AnalysisGridFrameworkComponents.TAG_AVG_RENDERER]: AnalysisTagAvgRenderer,
    [AnalysisGridFrameworkComponents.TAG_ALLSUBJECTS_RENDERER]: AnalysisTagAllSubjectsRenderer,
};
