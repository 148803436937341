import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";

export const GET_SEARCH_SCHOOLS_QUERY = "getSearchSchoolsQuery";

export interface SearchSchoolsResponse {
    id: number;
    name: string;
    city: string;
    isMember?: boolean;
    enableNaht?: boolean;
}

export const useGetSearchSchools = (filter: string) => {
    return useQuery({
        queryKey: [GET_SEARCH_SCHOOLS_QUERY],
        queryFn: async (): Promise<SearchSchoolsResponse[] | null> => {
            if (filter) {
                const { data } = await axiosInstance.get(
                    `api/common/search/schools/?filter=${filter}`,
                );
                return data || null;
            }
            return null;
        },
    });
};
