import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_PIXL_SERIES_LIST_QUERY = "classTrackerPixlSeriesListQuery";

export const useClassTrackerPixlSeriesList = (classTrackerId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CLASS_TRACKER_PIXL_SERIES_LIST_QUERY, schoolAccountId, classTrackerId],
        queryFn: async (): Promise<ListObject[] | null> => {
            if (classTrackerId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/pixl-waves-series/`,
                );

                return data;
            }
            return null;
        },
    });
};
