import TrackerGrid, { TrackerContext } from "../components/Grid/TrackerGrid";
import useDebounce from "src/hooks/useDebounce";
import TrackerGroupHeader from "../components/Header/TrackerGroupHeader";
import { Box } from "@mui/material";
import { CellValueChangedEvent } from "ag-grid-community";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import { ApiData, ApiStatus } from "src/api/constants";
import {
    HTTP_ERROR_CONFLICT,
    HTTP_FAILED_DEPENDENCY,
    HTTP_SUCCESS_ACCEPTED,
    HTTP_TOO_MANY_REQUESTS,
    apiUrl,
} from "src/config/globals";
import { usePrevious } from "src/hooks/usePrevious";
import { useResponse } from "src/hooks/useResponse";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { ROUTE_TRACKER_CLASS_TRACKER_GROUP } from "src/routes";
import { useForecast } from "../hooks/useForecast";
import { useStudentMove } from "../hooks/useStudentMove";
import { useTrackerValues } from "../hooks/useTrackerValues";
import { getTrackerBorderColor } from "../services/colorHandler";
import { calculateHeaderHeight, getFlatHeaderStructure } from "../services/headerGenerator";
import {
    convertDataToSend,
    DataToSendType,
    getRowIndexByRow,
    LinksToSendType,
    mergeStudentColumnData,
    mergeStudentsToValues,
    sendTrackerData,
    sendTrackerGroupData,
    TagsToSendType,
    TrackerValueType,
} from "../services/valueHandler";
import { useTrackerGroupInitialConfigData } from "../hooks/useTrackerGroupInitialConfigData";
import { AppState } from "src/store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { filterRowFromFilters } from "src/modules/common/services/valueHandler";
import { useMount } from "src/hooks/useMount";
import { ReportActions } from "src/modules/report/store/actions";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { ClassTrackerHeaderObject } from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";
import { ClassTrackerValuesObject } from "../dto/TrackerValues";
import { sortArrayBy } from "src/services/array";
import { useTrackerGroupSnapshotData } from "../hooks/query/ClassTrackerGroup/useTrackerGroupSnapshotData";
import { useTrackerGroupCompareData } from "../hooks/query/ClassTrackerGroup/useTrackerGroupCompareData";
import { generateTrackerUrl, getSchoolAccountId } from "src/services/url";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useClassTrackerGroupTagList } from "src/modules/tagging/hooks/ClassTrackerGroup/useClassTrackerGroupTagList";
import { useClassTrackerGroupStudentTags } from "src/modules/tagging/hooks/ClassTrackerGroup/useClassTrackerGroupStudentTags";
import {
    ClassTrackerGroupStudentTagRows,
    useClassTrackerGroupStudentTagMutation,
} from "src/modules/tagging/hooks/ClassTrackerGroup/useClassTrackerGroupStudentTagMutation";
import { useClassTrackerGroupLinksList } from "src/modules/links/hooks/ClassTrackerGroup/useClassTrackerGroupLinks";
import { useClassTrackerGroupStudentLinksList } from "src/modules/links/hooks/ClassTrackerGroup/useClassTrackerGroupStudentLinks";
import { useClassTrackerGroupStudentLinksMutation } from "src/modules/links/hooks/ClassTrackerGroup/useClassTrackerGroupStudentLinksMutation";
import { ClassTrackerStudentLinkRows } from "src/modules/links/hooks/ClassTracker/useClassTrackerStudentLinks";
import PromptDialog from "src/forms/PromptDialog";
import { useClassTrackerGroupPixlSeriesList } from "../hooks/query/ClassTrackerGroup/useTrackerGroupPixlSeriesList";
import { useTrackerGroupUserSettings } from "../hooks/query/ClassTrackerGroup/useTrackerGroupUserSettings";
import { useEditTrackerGroupUserSettings } from "../hooks/query/ClassTrackerGroup/useEditTrackerGroupUserSettings";
import { UpdateRowResponse } from "./ClassTrackerContainer";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useTrackerGroupUserSettingsStore } from "../hooks/query/ClassTrackerGroup/useTrackerGroupUserSettingsStore";
import { baseHeaders } from "src/services/ajax";
import { useBeforeUnload } from "react-router-dom";

const YearGroupTrackerContainer = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {
        classTrackerGroup,
        cohort,
        moveStudents,
        tier,
        bulkEdit,
        subject,
        qualification,
        specification,
        yearGroup,
    } = useParams();
    const cohortId: number | null = cohort ? parseInt(cohort) : null;
    const classTrackerGroupId: number | null = classTrackerGroup
        ? parseInt(classTrackerGroup)
        : null;
    const {
        classTrackerHeader: gridHeader,
        classTrackerRelatedData: gridRelated,
        getClassTrackerGroupRelated,
        getClassTrackerGroupHeader,
        apiHeader,
        apiRelatedData,
        apiValues,
        clearClassTrackerRelated: clearRelated,
    } = useTrackerGroupInitialConfigData(classTrackerGroupId || undefined, tier as TiersTypes);

    //userSettings
    const { data: userSettings } = useTrackerGroupUserSettings(classTrackerGroupId);
    const { mutate: setTrackerGroupUserSettings } =
        useEditTrackerGroupUserSettings(classTrackerGroupId);
    const { setClassTrackerGroupUserSettings, ...gridUserSettings } =
        useTrackerGroupUserSettingsStore();
    useEffect(() => {
        setClassTrackerGroupUserSettings(userSettings);
    }, [userSettings]);

    const gridUserSettingsRef = useRef(gridUserSettings);
    gridUserSettingsRef.current = gridUserSettings;
    useEffect(() => {
        return () => setTrackerGroupUserSettings(gridUserSettingsRef.current);
    }, []);

    useBeforeUnload(
        useCallback(() => {
            fetch(
                `${apiUrl("")}school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/user-settings/`,
                {
                    keepalive: true,
                    method: "POST",
                    headers: baseHeaders(),
                    body: JSON.stringify(gridUserSettings),
                },
            );
        }, [gridUserSettings]),
    );
    //////

    const { navigate } = useSchoolNavigate();
    const dispatch = useDispatch();
    const subscriber = useRef(null) as any;
    const location = useLocation();
    const prevTier = usePrevious(tier);

    const [currentHeader, setCurrentHeader] = useState<ClassTrackerHeaderObject | null>(null);
    const [currentRelated, setCurrentRelated] = useState<ClassTrackerRelatedObject | null>(null);
    const [currentValues, setCurrentValues] = useState<ClassTrackerValuesObject | null>(null);
    const [pixlData, setPixlData] = useState<any>(null);

    const [rowsToRefresh, setRowsToRefresh] = useState<string[] | null>(null);
    const [rowsToRedraw, setRowsToRedraw] = useState<number[]>([]);
    const [triggerBulkSave, setTriggerBulkSave] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [savingMoveTo, setSavingMoveTo] = useState<boolean>(false);
    const [gridFilters, setGridFilters] = useState<any>(null);
    const [dataType, setDataType] = useState<DataTypeTypes>(DataTypeTypes.LIVE);

    const [dataToSend, setDataToSend] = useState<DataToSendType[]>([]);
    const [tagsToSend, setTagsToSend] = useState<TagsToSendType[]>([]);
    const [linksToSend, setLinksToSend] = useState<LinksToSendType[]>([]);

    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
    const [toolsVisible, setToolsVisible] = useState<boolean>(false);
    const [atlColumnsMap, setAtlColumnsMap] = useState({});
    const [expandedColumns, setExpandedColumns] = useState<{ [key: string]: boolean }>({});
    const [moveStudentsList, setMoveStudentsList] = useState<
        { studentId: number; moveToId?: number; tier?: TiersTypes }[] | null
    >(null);
    const [dataSet, setDataSet] = useState<{ [key: string]: TrackerValueType }[] | null>(null);
    const [initialDataSet, setInitialDataSet] = useState<
        { [key: string]: TrackerValueType }[] | null
    >(null);
    const debouncedDataToSend = useDebounce(dataToSend, 2000);
    const debouncedTagsToSend = useDebounce(tagsToSend, 2000);
    const debouncedLinksToSend = useDebounce(linksToSend, 2000);

    const [allTagsLinksWaiting, setAllTagsLinksWaiting] = useState<{
        colId: string;
        value: any;
        type: "tag" | "link";
    } | null>(null);

    const deboundedExpanded = useDebounce(expandedColumns, 500);
    const prevDataType = usePrevious(dataType);
    const lastFilterOptions = useSelector(
        (state: AppState) => state.class.classTrackerLastFilteringOptions,
    );
    const [isBlocked, setIsBlocked] = useState<boolean>(!!gridRelated?.isBlocked);
    const [isAsyncProcessing, setIsAsyncProcessing] = useState<boolean>(!!gridRelated?.isBlocked);
    const {
        classTrackerGroupValues: classTrackerValues,
        getClassTrackerGroupValues,
        getStudentsForClassTrackerGroup,
        apiStudentsGroup,
        clearStudents,
        classTrackerStudents,
    } = useTrackerValues();
    const [isGradeVBoundaryProcessing, setIsGradeBoundaryProcessing] = useState<boolean>(false);
    const { data: userProfile } = useProfile();
    const { data: pixlSeriesList } = useClassTrackerGroupPixlSeriesList(classTrackerGroupId);
    const {
        snapshotData,
        setSnapshotData,
        header: snapshotHeader,
        relatedData: snapshotRelatedData,
        values: snapshotValues,
        isSuccessAll: isSuccessTrackerSnapshot,
    } = useTrackerGroupSnapshotData(classTrackerGroupId, tier as TiersTypes);

    const {
        header: compareHeader,
        relatedData: compareRelatedData,
        values: compareValues,
        isSuccessAll: isSuccessTrackerCompare,
        removeValues: removeCompareValues,
    } = useTrackerGroupCompareData(classTrackerGroupId, tier as TiersTypes);

    const {
        getForecastClassTrackerGroupValues,
        apiTrackerGroupValues: apiTrackerGroupForecastValues,
    } = useForecast();
    const { apiMoveStudents, moveStudentsToClass } = useStudentMove();

    // tags
    const { data: tags, isLoading: tagsIsLoading } =
        useClassTrackerGroupTagList(classTrackerGroupId);
    const { data: studentTags, refetch: refetchStudentTags } =
        useClassTrackerGroupStudentTags(classTrackerGroupId);
    const { mutate: saveTags } = useClassTrackerGroupStudentTagMutation(() => {
        refetchStudentTags();
        let newRowsToRedraw = rowsToRedraw?.length > 0 ? [...rowsToRedraw] : [];
        tagsToSend.map(tts => {
            const rowI = dataSet.findIndex(ctv => ctv.row === tts.row);
            newRowsToRedraw = newRowsToRedraw.filter(nrtr => nrtr !== rowI).concat(rowI as any);
        });

        setRowsToRedraw(newRowsToRedraw);
        setTagsToSend([]);
    });

    // links
    const { data: links, isLoading: linksIsLoading } =
        useClassTrackerGroupLinksList(classTrackerGroupId);
    const { data: studentLinks, refetch: refetchStudentLinks } =
        useClassTrackerGroupStudentLinksList(classTrackerGroupId);
    const { mutate: saveLinks } = useClassTrackerGroupStudentLinksMutation(() => {
        refetchStudentLinks();
    });

    const classTrackerRoute = generateTrackerUrl(
        ROUTE_TRACKER_CLASS_TRACKER_GROUP,
        classTrackerGroupId,
        cohortId,
        subject,
        qualification,
        specification,
        tier,
        yearGroup,
    );

    const isGridDataFetched = !!(
        currentHeader &&
        apiHeader.status === ApiStatus.SUCCESS &&
        currentRelated &&
        apiRelatedData.status === ApiStatus.SUCCESS &&
        apiStudentsGroup.status === ApiStatus.SUCCESS &&
        gridUserSettings &&
        (dataType !== DataTypeTypes.FORECAST
            ? apiValues.status === ApiStatus.SUCCESS
            : apiTrackerGroupForecastValues.status === ApiStatus.SUCCESS) &&
        currentValues &&
        !tagsIsLoading &&
        !linksIsLoading
    );

    // change single value handler
    const handleValueChange = (e: CellValueChangedEvent) => {
        const oldValue = e.oldValue === null ? "" : e.oldValue;

        const colId = e.column.getColId();
        const newValue = e.newValue?.whileUpdate ? e.newValue?.value : e.newValue;
        const value = e.newValue;

        if (oldValue !== newValue && e.data.row && colId) {
            const classTracker = e.data["student_classTrackerId"];

            if (colId.indexOf("tag-") === 0) {
                setTagsToSend(prevState =>
                    prevState.concat({ row: e.data.row, column: colId, value: newValue }),
                );
            } else if (colId.indexOf("link-") === 0) {
                setLinksToSend(prevState =>
                    prevState.concat({ row: e.data.row, column: colId, value: newValue }),
                );
            } else {
                setDataToSend(prevState =>
                    prevState.concat({
                        row: e.data.row,
                        column: e.column.getColId(),
                        classTracker,
                        value,
                    }),
                );
            }

            if (subscriber && subscriber.current) {
                if (dataType === DataTypeTypes.LIVE && subscriber.current[classTracker]) {
                    subscriber.current[classTracker].unsubscribe();
                } else {
                    subscriber.current.unsubscribe();
                }
            }
        }
    };

    const handleTagsLinksAllColumnSelect = (colId, value, type) => {
        setAllTagsLinksWaiting({ colId, value, type });
    };

    // handling move to
    const handleMoveStudentTo = (studentId: number, moveToId: number) => {
        const entry = moveStudentsList?.find(msl => msl.studentId === studentId);
        if (!entry) {
            setMoveStudentsList(prev => {
                return (prev || []).concat({ studentId, moveToId });
            });
        } else {
            const listIndex = moveStudentsList?.findIndex(msl => msl.studentId === studentId);
            if (moveStudentsList && listIndex !== undefined && listIndex > -1) {
                const newList = [...moveStudentsList];
                newList[listIndex].moveToId = moveToId;
                setMoveStudentsList(newList);
            }
        }
    };

    const handleMoveStudentTierTo = (
        studentId: number,
        classId: number,
        moveToTier: TiersTypes,
    ) => {
        const entry = moveStudentsList?.find(msl => msl.studentId === studentId);

        if (!entry) {
            setMoveStudentsList(prev => {
                return (prev || []).concat({
                    studentId,
                    moveToId: classId,
                    tier: moveToTier || (tier as TiersTypes),
                });
            });
        } else {
            const listIndex = moveStudentsList?.findIndex(msl => msl.studentId === studentId);
            if (moveStudentsList && listIndex !== undefined && listIndex > -1) {
                const newList = [...moveStudentsList];
                newList[listIndex].tier = moveToTier || (tier as TiersTypes);
                setMoveStudentsList(newList);
            }
        }
    };

    // saving move to flag
    const handleOnSaveMoveClick = useCallback(() => {
        setSavingMoveTo(true);
    }, [dataSet, moveStudentsList]);

    // change columns handler
    const handleConfigureColumnsChange = () => {
        setIsLoading(true);
    };

    const handleFiltersChange = filters => {
        setToolsVisible(!!bulkEdit);
        setGridFilters(filters);
    };

    // api on save response handler
    const handleMultipleUpdateResponse = (response: UpdateRowResponse) => {
        if (dataSet) {
            const newDataSet: { [key: string]: TrackerValueType }[] = [...dataSet];
            Object.keys(response.data).forEach(rowKey => {
                const rowIndex = getRowIndexByRow(dataSet, rowKey);
                const row = response.data[rowKey];
                const rowError = response.errors.find(e => e.row === rowKey);
                if (rowIndex !== null && rowIndex > -1) {
                    newDataSet[rowIndex] = { ...newDataSet[rowIndex], ...row };
                    if (rowError) {
                        newDataSet[rowIndex][rowError.column] = rowError.value;
                    }
                }
                Object.entries(response.data[rowKey]).forEach(([key, value]) => {
                    if (key.includes("target-grid") && rowIndex > -1) {
                        const targetGridArray = value as any[];
                        targetGridArray.forEach((v, i) => {
                            newDataSet[rowIndex][`${key}-${i}`] = v === true ? "" : v;
                        });
                    }
                });
            });

            setDataSet(newDataSet);
            setInitialDataSet(newDataSet);
            setDataToSend([]);
            const rowsToRefresh: string[] = [];
            Object.keys(response.data).forEach(rowKey => {
                const rowIndex = getRowIndexByRow(dataSet, rowKey);
                if (rowIndex !== null && rowIndex > -1) rowsToRefresh.push(rowIndex);
            });
            setRowsToRefresh(rowsToRefresh);
        }
    };

    // handle move students
    const handleMoveStudents = (apiData: ApiData) => {
        if (apiData.responseStatus === HTTP_SUCCESS_ACCEPTED) {
            enqueueSnackbar(t("tracker.grid.studentsMoved"), { variant: "success" });
            navigate(classTrackerRoute);
            navigate(0);
        } else {
            enqueueSnackbar(t("tracker.grid.studentsMovedError"), {
                ...SnackbarErrorOptions,
            });
        }
    };

    const resolveDataChangeError = err => {
        if (err.status === HTTP_FAILED_DEPENDENCY && typeof err?.response?.error === "string") {
            enqueueSnackbar(err?.response?.error, {
                ...SnackbarErrorOptions,
            });
        }
        if (err.status === HTTP_TOO_MANY_REQUESTS) {
            setTimeout(() => setDataToSend(prevState => prevState), 1000);
        }
        if (err.status === HTTP_ERROR_CONFLICT) {
            enqueueSnackbar(t("tracker.maintenance"), {
                ...SnackbarErrorOptions,
            });
            setTimeout(() => navigate("/"), 3000);
        }
    };

    // request data change
    const requestDataChange = useCallback(
        (parsedData: undefined | any) => {
            const dataToProcess: any = (parsedData as any) || debouncedDataToSend;

            if (dataToProcess && dataToProcess.length > 0) {
                if (dataType === DataTypeTypes.LIVE) {
                    const classTrackersToProcess: number[] = [];
                    const classTrackersProcessed: number[] = [];

                    dataToProcess.forEach(dtp => {
                        if (!classTrackersToProcess.find(cttp => cttp === dtp.classTracker)) {
                            classTrackersToProcess.push(dtp.classTracker);
                        }
                    });
                    if (classTrackersToProcess.length > 0) {
                        let responseAll: any = {};

                        classTrackersToProcess.forEach(classTrackerId => {
                            const convertedDataToSend = convertDataToSend(
                                dataToProcess.filter(dtp => dtp.classTracker === classTrackerId),
                                gridRelated?.fineGradeEnabled,
                            );
                            if (Object.keys(convertedDataToSend).length > 0) {
                                sendTrackerData(
                                    classTrackerId,
                                    subscriber,
                                    convertedDataToSend,
                                    false,
                                    r => {
                                        responseAll = {
                                            data: { ...responseAll.data, ...r.response.data },
                                            errors: [...(responseAll.errors || [])].concat(
                                                r.response.errors,
                                            ),
                                        };
                                        classTrackersProcessed.push(classTrackerId);
                                        classTrackersToProcess.sort();
                                        classTrackersProcessed.sort();

                                        if (
                                            classTrackersToProcess.toString() ===
                                            classTrackersProcessed.toString()
                                        ) {
                                            if (bulkEdit) {
                                                navigate(classTrackerRoute);
                                                navigate(0);
                                            } else {
                                                handleMultipleUpdateResponse(responseAll);
                                            }
                                        }
                                        if (
                                            dataType === DataTypeTypes.LIVE &&
                                            classTrackerGroupId
                                        ) {
                                            getClassTrackerGroupValues(
                                                classTrackerGroupId,
                                                tier as TiersTypes,
                                            );
                                        }
                                    },
                                    err => resolveDataChangeError(err),
                                    tier as TiersTypes,
                                );
                            }
                        });
                    }
                }

                if (dataType === DataTypeTypes.FORECAST) {
                    const convertedDataToSend = convertDataToSend(
                        dataToProcess,
                        gridRelated?.fineGradeEnabled,
                    );
                    if (classTrackerGroupId && Object.keys(convertedDataToSend).length > 0) {
                        sendTrackerGroupData(
                            classTrackerGroupId,
                            subscriber,
                            convertedDataToSend,
                            res => {
                                handleMultipleUpdateResponse(res.response);
                            },
                            err => resolveDataChangeError(err),
                        );
                    }
                }
            }
        },
        [debouncedDataToSend, classTrackerGroupId],
    );

    const handleSetAllTiers = useCallback(
        (tier: TiersTypes) => {
            if (dataSet) {
                const newDataset = [...dataSet].map(row => {
                    return { ...row, moveStudentToTier: tier };
                });
                setDataSet(newDataset);
                newDataset.forEach(nds => {
                    handleMoveStudentTierTo(nds["student_id"], nds["student_classTrackerId"], tier);
                });
            }
        },
        [dataSet],
    );

    useMount(() => {
        dispatch(ReportActions.getReportTemplateList());
    });

    useEffect(() => {
        setCurrentHeader(gridHeader);
    }, [gridHeader]);

    useEffect(() => {
        setCurrentRelated(gridRelated);
    }, [gridRelated]);

    useEffect(() => {
        const cpClassTrackerValues = { ...classTrackerValues };
        if (tags && tags.length > 0 && cpClassTrackerValues) {
            Object.keys(cpClassTrackerValues).forEach(ctvKey => {
                tags.forEach(tag => {
                    cpClassTrackerValues[ctvKey]["tag-" + tag.id] = 0;
                });
            });
        }
        if (studentTags && Object.keys(studentTags).length > 0) {
            Object.keys(studentTags).forEach(stKey => {
                studentTags[stKey].forEach(tagId => {
                    if (cpClassTrackerValues[stKey]) {
                        cpClassTrackerValues[stKey]["tag-" + tagId] = 1;
                    }
                });
            });
        }

        setCurrentValues(cpClassTrackerValues);
    }, [classTrackerValues, tags, studentTags]);

    useEffect(() => {
        const cpClassTrackerValues = { ...classTrackerValues };
        if (links && links.length > 0 && cpClassTrackerValues) {
            Object.keys(cpClassTrackerValues).forEach(ctvKey => {
                links.forEach(link => {
                    cpClassTrackerValues[ctvKey]["link-" + link.id] = 0;
                });
            });
        }
        if (studentLinks && Object.keys(studentLinks).length > 0 && cpClassTrackerValues) {
            Object.keys(studentLinks).forEach(stKey => {
                studentLinks[stKey].forEach(linkId => {
                    if (cpClassTrackerValues[stKey]) {
                        cpClassTrackerValues[stKey]["link-" + linkId] = 1;
                    }
                });
            });
        }

        setCurrentValues(cpClassTrackerValues);
    }, [classTrackerValues, links, studentLinks]);

    useEffect(() => {
        if (gridRelated && gridHeader) {
            const newMap = {};
            const atlConfig = gridRelated.atlConfigs;
            const atlColumns = gridHeader?.header?.[0].children.find(
                c => c?.headerConfig?.type === "atl",
            )?.children;

            if (atlColumns && atlConfig && Object.keys(atlColumnsMap).length === 0) {
                atlConfig.forEach(atlC => {
                    if (atlColumns.find(a => a.name === atlC.fieldType)) {
                        newMap[atlC.fieldType] = atlColumns.find(
                            a => a.name === atlC.fieldType,
                        )?.hash;
                    }
                });
                setAtlColumnsMap(newMap);
            }
        }
    }, [gridRelated, gridHeader]);

    useEffect(() => {
        if (!isLoading) {
            if (currentValues && currentHeader) {
                const rowData = mergeStudentColumnData(currentValues, currentHeader, bulkEdit);
                sortArrayBy(rowData, "lastFirstName");
                setDataSet(rowData);
                setInitialDataSet(rowData);
            }
        }
    }, [isLoading, currentValues, currentHeader]);

    useEffect(() => {
        if (isSuccessTrackerSnapshot) {
            if (snapshotValues && snapshotHeader) {
                const rowData = mergeStudentColumnData(snapshotValues, snapshotHeader, bulkEdit);
                sortArrayBy(rowData, "lastFirstName");
                setDataSet(rowData);
                setInitialDataSet(rowData);
                setCurrentValues(snapshotValues);
            }
        }
    }, [isSuccessTrackerSnapshot, snapshotHeader, snapshotValues]);

    useEffect(() => {
        if (isSuccessTrackerSnapshot && snapshotHeader) {
            setCurrentHeader(snapshotHeader);
        }
    }, [isSuccessTrackerSnapshot, snapshotHeader]);

    useEffect(() => {
        if (isSuccessTrackerSnapshot && snapshotRelatedData) {
            setCurrentRelated(snapshotRelatedData);
        }
    }, [isSuccessTrackerSnapshot, snapshotRelatedData]);

    useEffect(() => {
        if (isSuccessTrackerCompare) {
            if (compareValues && compareHeader) {
                const rowData = mergeStudentColumnData(
                    compareValues.values1,
                    compareHeader,
                    bulkEdit,
                );
                sortArrayBy(rowData, "lastFirstName");
                setDataSet(rowData);
                setInitialDataSet(rowData);
                setCurrentValues(
                    mergeStudentsToValues(compareValues.values1, classTrackerStudents),
                );
            }
        }
    }, [isSuccessTrackerCompare, compareHeader, compareValues, classTrackerStudents]);

    useEffect(() => {
        if (isSuccessTrackerCompare && compareHeader) {
            setCurrentHeader(compareHeader);
        }
    }, [isSuccessTrackerCompare, snapshotHeader]);

    useEffect(() => {
        if (isSuccessTrackerCompare && compareRelatedData) {
            setCurrentRelated(compareRelatedData);
        }
    }, [isSuccessTrackerCompare, compareRelatedData]);

    useEffect(() => {
        if (gridUserSettings?.filters && !gridFilters && location) {
            navigate({
                pathname: location.pathname,
                search: "?" + gridUserSettings.filters,
            });
        }
    }, [gridUserSettings]);

    useEffect(() => {
        if (triggerBulkSave && dataToSend?.length > 0) {
            requestDataChange(dataToSend);
        } else if (triggerBulkSave && dataToSend?.length === 0) {
            setTriggerBulkSave(false);
            navigate(classTrackerRoute);
            navigate(0);
        }
    }, [dataToSend, triggerBulkSave]);

    // save tags
    useEffect(() => {
        if (debouncedTagsToSend?.length > 0) {
            const rows: ClassTrackerGroupStudentTagRows = {};
            debouncedTagsToSend.forEach((ddts: TagsToSendType) => {
                rows[ddts.row] = [
                    ...(rows[ddts.row] || []).concat({
                        tagId: parseInt(ddts.column.replace("tag-", "")),
                        enabled: ddts.value.value !== undefined ? !!ddts.value.value : !!ddts.value,
                    }),
                ];
            });

            saveTags({ id: classTrackerGroupId, rows });
        }
    }, [debouncedTagsToSend]);

    // save links
    useEffect(() => {
        if (debouncedLinksToSend?.length > 0) {
            const rows: ClassTrackerStudentLinkRows = {};
            debouncedLinksToSend.forEach((ddts: LinksToSendType) => {
                rows[ddts.row] = [
                    ...(rows[ddts.row] || []).concat({
                        reportLinkId: parseInt(ddts.column.replace("link-", "")),
                        enabled: ddts.value.value !== undefined ? !!ddts.value.value : !!ddts.value,
                    }),
                ];
            });

            saveLinks({ id: classTrackerGroupId, rows });
        }
    }, [debouncedLinksToSend]);

    useEffect(() => {
        if (gridFilters !== null) {
            const filteredNew = initialDataSet?.filter(initialValues => {
                return filterRowFromFilters(
                    gridFilters,
                    initialValues,
                    atlColumnsMap,
                    gridHeader,
                    gridRelated?.fineGradeEnabled,
                );
            });
            if (!filteredNew) {
                setDataSet([]);
            } else {
                setDataSet(filteredNew);
            }
        }
    }, [gridFilters, initialDataSet]);

    useEffect(() => {
        // refresh rows when compare values arrive
        if (compareValues) {
            const rowsToRefresh: string[] = [];
            Object.keys(compareValues?.values1).forEach(rowKey => {
                const rowIndex = getRowIndexByRow(dataSet, rowKey);
                if (rowIndex !== null && rowIndex > -1) rowsToRefresh.push(rowIndex);
            });

            setRowsToRefresh(rowsToRefresh);
        }
    }, [compareValues]);

    useEffect(() => {
        return () => {
            clearStudents();
            closeSnackbar();
            setSnapshotData(null);
            removeCompareValues();
        };
    }, []);

    // save move to
    useEffect(() => {
        if (savingMoveTo) {
            const convertedValues: {
                student: { id: number };
                classTracker?: { id: number };
                tier?: TiersTypes;
            }[] = [];
            moveStudentsList?.forEach(ds => {
                if (
                    (ds.moveToId || ds.tier) &&
                    !convertedValues.find(cv => cv.student.id === ds.studentId)
                ) {
                    convertedValues.push({
                        student: { id: parseInt(`${ds.studentId}`) },
                        classTracker: ds.moveToId ? { id: parseInt(`${ds.moveToId}`) } : undefined,
                        tier: ds.tier || (tier as TiersTypes),
                    });
                }
            });

            if (convertedValues.length === 0) {
                navigate(classTrackerRoute);
                navigate(0);
            } else {
                if (classTrackerGroupId) {
                    setSavingMoveTo(false);
                    moveStudentsToClass(classTrackerGroupId, convertedValues);
                }
            }
        }
    }, [moveStudentsList, savingMoveTo]);

    // handle expand save to user settings
    useEffect(() => {
        if (deboundedExpanded && Object.keys(deboundedExpanded).length > 0) {
            let newUserSettings: any = { ...gridUserSettings, expandedColumns };

            if (tier && tier !== "-") {
                newUserSettings = {
                    ...gridUserSettings,
                    expandedColumns,
                    ["expandedColumns" + tier]: { ...expandedColumns },
                };
            }
            if (
                JSON.stringify(newUserSettings) !== JSON.stringify(gridUserSettings) &&
                classTrackerGroupId
            ) {
                setClassTrackerGroupUserSettings(newUserSettings);
            }
        }
    }, [deboundedExpanded]);

    // debounced new data to send effect
    useEffect(() => {
        if (!bulkEdit && !moveStudents) {
            requestDataChange(debouncedDataToSend);
        }
    }, [debouncedDataToSend, classTrackerGroupId]);

    // main data getter for view modes
    useEffect(() => {
        setIsLoading(true);
        getStudentsForClassTrackerGroup(classTrackerGroupId, tier as TiersTypes);
        if (
            prevDataType &&
            prevDataType === DataTypeTypes.SNAPSHOT &&
            dataType !== DataTypeTypes.SNAPSHOT
        ) {
            setSnapshotData(null);
        }
        if (
            prevDataType &&
            prevDataType !== DataTypeTypes.LIVE &&
            dataType === DataTypeTypes.LIVE &&
            classTrackerGroupId
        ) {
            getClassTrackerGroupHeader(classTrackerGroupId, tier as TiersTypes);
        }
        if (dataType === DataTypeTypes.LIVE && classTrackerGroupId) {
            getClassTrackerGroupValues(classTrackerGroupId, tier as TiersTypes);
        }
        if (
            prevDataType &&
            classTrackerGroupId &&
            ((prevDataType !== DataTypeTypes.FORECAST && dataType === DataTypeTypes.FORECAST) ||
                (prevTier && prevTier !== tier))
        ) {
            getForecastClassTrackerGroupValues(classTrackerGroupId, tier as TiersTypes);
        }
    }, [dataType, tier]);

    // handle move students
    useResponse(() => handleMoveStudents(apiMoveStudents), apiMoveStudents);

    // loader off (when dataset changes)
    useEffect(() => {
        let timeout;
        if (isGridDataFetched && isLoading) {
            timeout = setTimeout(() => {
                setIsLoading(false);
            }, 3000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isGridDataFetched, isLoading]);

    useEffect(() => {
        let interval;
        if (classTrackerGroupId && (isAsyncProcessing || isGradeVBoundaryProcessing)) {
            getClassTrackerGroupRelated(classTrackerGroupId);
            interval = setInterval(() => {
                getClassTrackerGroupRelated(classTrackerGroupId);
            }, 6000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isAsyncProcessing, isGradeVBoundaryProcessing]);

    const prevApiRelatedStatus = usePrevious(apiRelatedData.status);

    useEffect(() => {
        if (
            prevApiRelatedStatus === ApiStatus.LOADING &&
            apiRelatedData.status === ApiStatus.SUCCESS &&
            gridRelated &&
            isBlocked &&
            isAsyncProcessing &&
            classTrackerGroupId &&
            gridRelated.classTrackers
        ) {
            const classesBlocked: boolean = gridRelated?.classTrackers?.reduce(
                (prevBlocked: boolean, classTracker: { id: number; isBlocked: boolean }) => {
                    if (classTracker.isBlocked && !prevBlocked) {
                        return true;
                    }
                    return prevBlocked;
                },
                false,
            );

            if (!classesBlocked) {
                getForecastClassTrackerGroupValues(classTrackerGroupId, tier as TiersTypes);
                setIsAsyncProcessing(false);
                setIsBlocked(false);
            }
        }
    }, [apiRelatedData, gridRelated, dataType, classTrackerGroupId, isBlocked, isAsyncProcessing]);

    useEffect(() => {
        if (
            prevApiRelatedStatus === ApiStatus.LOADING &&
            apiRelatedData.status === ApiStatus.SUCCESS &&
            gridRelated?.isBlocked === false &&
            isGradeVBoundaryProcessing
        ) {
            navigate(0);
        }
    }, [isGradeVBoundaryProcessing, gridRelated, apiRelatedData]);

    useEffect(() => {
        setFiltersVisible(false);
        setToolsVisible(false);
    }, [moveStudents, dataType]);

    // global context for tracker
    const trackerContext: TrackerContext = {
        cohortId,
        classTrackerGroupId,
        dataToSend,
        gridUserSettings,
        filtersVisible,
        toolsVisible,
        expanded:
            tier && tier !== "-" && gridUserSettings
                ? gridUserSettings["expandedColumns" + tier]
                : gridUserSettings?.expandedColumns || {},
        initialExpandSettings: gridUserSettings?.expandedColumns ? false : true,
        compareValues: compareValues?.values2 || null,
        flatHeader: getFlatHeaderStructure(gridHeader),
        relatedData: gridRelated,
        headerHeight: calculateHeaderHeight(gridHeader),
        dataType,
        bulkEdit: !!bulkEdit,
        moveStudents: !!moveStudents,
        columns: gridHeader?.columns,
        snapshotData,
        userProfile,
        isBlocked,
        isGradeVBoundaryProcessing,
        tier: tier as TiersTypes,
        gridFilters,
        yearGroupOverview: true,
        handlePixlSeriesChange: (selectedSeriesId, pixlData) => {
            setClassTrackerGroupUserSettings({
                selectedSeriesId,
            });
            setPixlData(pixlData);
        },
        pixlData,
        handleBulkEditSave: () => setTriggerBulkSave(true),
        handleFiltersChange,
        handleBlocked: locked => (locked === false ? setIsBlocked(false) : setIsBlocked(true)),
        handleDataAsyncLoaded: () => {
            clearRelated();
            setTimeout(() => {
                setIsAsyncProcessing(true);
            }, 1000); // to refactor
        },
        handleGradeBoundariesProcessing: () => {
            clearRelated();
            setTimeout(() => {
                setIsGradeBoundaryProcessing(true);
            }, 1000); // to refactor
        },
        handleConfigureColumnsChange,
        handleSetAllTiers,
        handleMoveStudentTo,
        handleMoveStudentTierTo,
        requestDataChange,
        handleOnSaveMoveClick,
        handleTagsVisible: visible =>
            setClassTrackerGroupUserSettings({
                tagsVisible: visible,
            }),
        tagsVisible: gridUserSettings?.tagsVisible,
        selectedLinks: gridUserSettings?.selectedLinks || [],
        tags,
        linksVisible: gridUserSettings?.linksVisible,
        handleLinksVisible: visible =>
            setClassTrackerGroupUserSettings({
                linksVisible: visible,
            }),
        links,
        handleTagsLinksAllColumnSelect,
        pixlSeriesList,
    };

    return (
        <Box component="main" height="100vh" pt={0} pb={3} display="flex" flexDirection="column">
            <TrackerGroupHeader
                tier={tier as TiersTypes}
                classTrackerGroupId={classTrackerGroupId || undefined}
                dataType={dataType}
                bulkEdit={!!bulkEdit}
                hasForecast={false}
                handleDataTypeChange={dataType => setDataType(dataType)}
                handleFiltersVisible={isChecked => {
                    setFiltersVisible(isChecked && !bulkEdit);
                    if (toolsVisible && isChecked) {
                        setToolsVisible(!isChecked || !!bulkEdit);
                    }
                }}
                handleToolsVisible={isChecked => {
                    setToolsVisible(isChecked);
                    if (filtersVisible && isChecked) {
                        setFiltersVisible(!isChecked);
                    }
                }}
                relatedData={currentRelated}
                isLoadingGrid={isLoading}
                lastFilterOptions={lastFilterOptions || undefined}
                toolsVisible={toolsVisible}
                filtersVisible={filtersVisible}
            />
            <Box
                className="ag-theme-alpine classTrackerGrid"
                flex={1}
                width={isLoading ? 0 : "100%"}
                borderLeft={`10px solid ${getTrackerBorderColor(dataType)}`}
            >
                <TrackerGrid
                    isLoading={isLoading || isGradeVBoundaryProcessing}
                    gridHeader={currentHeader}
                    rowsToRefresh={rowsToRefresh}
                    gridRelated={currentRelated}
                    rowsToRedraw={rowsToRedraw}
                    gridValues={currentValues}
                    dataSet={dataSet}
                    initialDataSet={initialDataSet}
                    isBlocked={isBlocked}
                    isSyncedClass={
                        !gridRelated?.classTrackers?.find(ct => ct.groupCallStatus !== "active")
                    }
                    trackerContext={trackerContext}
                    handleValueChange={handleValueChange}
                    handleChangeExpandedColumns={expanded => setExpandedColumns(expanded)}
                    handleIgnoreTimestamps={dataSet => {
                        setDataSet(dataSet);
                        setInitialDataSet(dataSet);
                    }}
                />
            </Box>
            <PromptDialog
                open={!!allTagsLinksWaiting?.colId}
                onClose={confirmed => {
                    if (confirmed) {
                        const newValues = [...dataSet].map(fv => {
                            if (allTagsLinksWaiting?.type === "tag") {
                                setTagsToSend(prevState =>
                                    prevState.concat({
                                        row: fv.row as string,
                                        column: allTagsLinksWaiting?.colId,
                                        value: allTagsLinksWaiting?.value,
                                    }),
                                );
                            } else {
                                setLinksToSend(prevState =>
                                    prevState.concat({
                                        row: fv.row as string,
                                        column: allTagsLinksWaiting?.colId,
                                        value: allTagsLinksWaiting?.value,
                                    }),
                                );
                            }

                            return {
                                ...fv,
                                [allTagsLinksWaiting?.colId]: {
                                    value: allTagsLinksWaiting?.value,
                                    whileUpdate: true,
                                },
                            };
                        });
                        setDataSet(newValues);
                    }

                    setAllTagsLinksWaiting(null);
                }}
            >
                {t("tracker.grid.allTagsWaiting")}
            </PromptDialog>
        </Box>
    );
};

export default YearGroupTrackerContainer;
