import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmGrade } from "src/orm/models/Grade";
import { getSchoolAccountId } from "src/services/url";

export const commonGradeEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/common/specification/{specification}/grades/`,
    method: ApiRequests.GET,
};

export type CommonGradeResponse = OrmGrade[];
