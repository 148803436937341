import {
    ROUTE_MY_SCHOOL_PARENTS,
    ROUTE_MY_SCHOOL_PARENTS_EXPORT,
    ROUTE_MY_SCHOOL_PARENT_ADD,
    ROUTE_MY_SCHOOL_PARENT_EDIT,
} from "src/routes";
import ParentUserListContainer from "../containers/List";
import ParentUserUpsertContainer from "../containers/Upsert";
import ParentUserExportContainer from "../containers/Export";

export const ParentRoutes = [
    {
        path: ROUTE_MY_SCHOOL_PARENTS_EXPORT,
        element: <ParentUserExportContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_PARENT_EDIT,
        element: <ParentUserUpsertContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_PARENT_ADD,
        element: <ParentUserUpsertContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_PARENTS,
        element: <ParentUserListContainer />,
    },
];
