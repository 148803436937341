import { ListItemIcon, ListItemText, MenuItem, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { forwardRef, ReactElement, ReactNode, RefAttributes } from "react";
import { Link } from "react-router-dom";
import COLORS from "src/styles/colors";

const useStyles = makeStyles((theme: Theme) => ({
    menuItem: {
        color: COLORS.GREY_2,
        minHeight: theme.spacing(4),
        paddingTop: theme.spacing(0.5),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(3),
        "& + &": {
            marginTop: theme.spacing(1),
        },
    },
    menuIcon: {
        minWidth: theme.spacing(3),
    },
    menuText: {
        fontSize: 13,
        fontWeight: 600,
        textTransform: "uppercase",
        whiteSpace: "normal",
        marginTop: 0,
        marginBottom: 0,
    },
}));

interface OwnProps {
    text: ReactNode;
    icon: ReactElement;
    onClick: React.MouseEventHandler;
    link?: string;
}

interface LinkProps {
    component: any; // ReactNode
    to: string;
}
const ActionMenuItem: React.ForwardRefExoticComponent<OwnProps & RefAttributes<any>> = forwardRef(
    ({ onClick, text, icon, link }, ref) => {
        const classes = useStyles();
        const linkProps: Partial<LinkProps> = {};
        if (link) {
            linkProps.component = Link;
            linkProps.to = link;
        }

        return (
            <MenuItem
                ref={ref}
                onClick={onClick}
                className={classes.menuItem + " actionMenuItem "}
                {...linkProps}
            >
                <ListItemIcon className={classes.menuIcon}>{icon}</ListItemIcon>
                <ListItemText primary={text} disableTypography className={classes.menuText} />
            </MenuItem>
        );
    },
);

ActionMenuItem.displayName = "ActionMenuItem";

export default ActionMenuItem;
