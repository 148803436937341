import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const SEARCH_SCHOOL_SNAPSHOT_FILTERS_QUERY = "searchSchoolSnapshotFiltersQuery";

export interface SearchSchoolSnapshotFilters {
    id: number;
    name: string;
    yearGroups: number[];
}

export type SearchSchoolSnapshotFiltersResponse = SearchSchoolSnapshotFilters[];

export const useSearchSchoolSnapshotFilters = (cohort: number, params?: { [key: string]: any }) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [SEARCH_SCHOOL_SNAPSHOT_FILTERS_QUERY, schoolAccountId, cohort, params],
        queryFn: async (): Promise<SearchSchoolSnapshotFiltersResponse | null> => {
            if (cohort) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/search/school-snapshot/${cohort}/with-year-groups/`,
                    { params },
                );

                return data;
            }
            return null;
        },
    });
};
