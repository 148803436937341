import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { useMount } from "src/hooks/useMount";
import { ClassTrackerHeaderObject } from "src/modules/tracker/dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "src/modules/tracker/dto/TrackerRelated";
import { ClassTrackerValuesObject } from "src/modules/tracker/dto/TrackerValues";
import { useArchivedTrackerGroupRelatedData } from "./useArchivedTrackerGroupRelatedData";
import { useArchivedTrackerGroupValues } from "./useArchivedTrackerGroupValues";
import { useArchivedTrackerGroupHeader } from "./useArchivedTrackerGroupHeader";

export interface ArchivedTrackerData {
    refetchRelatedData: () => void;
    relatedData: ClassTrackerRelatedObject;
    isLoadingRelatedData: boolean;
    isSuccessRelatedData: boolean;
    refetchValues: () => void;
    values: ClassTrackerValuesObject;
    isLoadingValues: boolean;
    isSuccessValues: boolean;
    refetchHeader: () => void;
    header: ClassTrackerHeaderObject;
    isLoadingHeader: boolean;
    isSuccessHeader: boolean;
    isRefetchingTracker: boolean;
    isLoadingTracker: boolean;
    isSuccessTracker: boolean;
}

export const useArchivedTrackerGroupData = (id: number, tier: TiersTypes) => {
    const {
        refetch: refetchRelatedData,
        data: relatedData,
        isLoading: isLoadingRelatedData,
        isSuccess: isSuccessRelatedData,
        isRefetching: isRefetchingRelatedData,
    } = useArchivedTrackerGroupRelatedData(id);
    const {
        refetch: refetchValues,
        data: values,
        isLoading: isLoadingValues,
        isSuccess: isSuccessValues,
        isRefetching: isRefetchingValues,
    } = useArchivedTrackerGroupValues(id, tier as TiersTypes);
    const {
        refetch: refetchHeader,
        data: header,
        isLoading: isLoadingHeader,
        isSuccess: isSuccessHeader,
        isRefetching: isRefetchingHeader,
    } = useArchivedTrackerGroupHeader(id, tier as TiersTypes);

    useMount(() => {
        refetchHeader();
        refetchRelatedData();
        refetchValues();
    });
    const isRefetchingTracker = isRefetchingHeader || isRefetchingRelatedData || isRefetchingValues;

    const isLoadingTracker =
        isLoadingHeader || isLoadingRelatedData || isLoadingValues || isRefetchingTracker;

    const isSuccessTracker = isSuccessHeader || isSuccessRelatedData || isSuccessValues;

    return {
        refetchRelatedData,
        relatedData,
        isLoadingRelatedData,
        isSuccessRelatedData,
        isRefetchingRelatedData,
        refetchValues,
        values,
        isLoadingValues,
        isSuccessValues,
        isRefetchingValues,
        refetchHeader,
        header,
        isLoadingHeader,
        isSuccessHeader,
        isRefetchingHeader,
        isRefetchingTracker,
        isLoadingTracker,
        isSuccessTracker,
    };
};
