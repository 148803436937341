import { ofType } from "@martin_hotell/rex-tils";
import { NotificationActions } from "src/notifications/store/actions";
import FileSaver from "file-saver";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SchoolActions, SchoolActionTypes } from "../actions";

const getImportTeachersTemplateSuccessEpic = (action$: ActionsObservable<SchoolActions>) =>
    action$.pipe(
        ofType(SchoolActionTypes.GET_IMPORT_TEACHERS_TEMPLATE_SUCCESS),
        map(
            (action: ReturnType<typeof SchoolActions.getImportTeachersTemplateSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            FileSaver.saveAs(payload.response, `Pupil Progress Stafflist upload.xlsx`);
            return of(
                NotificationActions.enqueueSnackbar({
                    message: "Template downloaded",
                    variant: "success",
                }),
            );
        }),
    );

export const staffEpics = combineEpics(getImportTeachersTemplateSuccessEpic);
