import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { usePrevious } from "src/hooks/usePrevious";
import {
    generateHeader,
    calculateAnalysisHeaderHeight,
    ANALYSIS_INITIAL_STUDENT_PARAMS,
} from "../../services/analysisHeaderGenerator";
import { analysisFrameworkComponentsList } from "../../services/analysisGridConfig";
import { Box } from "@mui/material";
import { CellValueChangedEvent, ColumnApi, ColumnState, GridApi } from "ag-grid-community";
import { generateAnalysisAverageRow } from "../../services/analysisAverageRow";
import { getGridBorderColor } from "../../services/analysisColourHandler";
import DotsProgress from "src/components/DotsProgress";
import { processAnalysisCopyToClipboard } from "src/modules/tracker/services/clipboard";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import AnalysisFilterPanel from "./ToolsPanel/AnalysisFilterPanel";
import AnalysisToolsPanel from "./ToolsPanel/AnalysisToolsPanel";
import { exportAnalysisToExcel } from "../../services/analysisDataExport";
import { filterRowFromFilters } from "src/modules/common/services/valueHandler";
import { useLocation } from "react-router";
import { sortByTwoParams } from "src/services/array";
import { useAnalysisTagList } from "src/modules/tagging/hooks/Analysis/useAnalysisTagList";
import { useAnalysisStudentTags } from "src/modules/tagging/hooks/Analysis/useAnalysisStudentTags";
import {
    AnalysisStudentTagRows,
    useAnalysisStudentTagMutation,
} from "src/modules/tagging/hooks/Analysis/useAnalysisStudentTagMutation";
import { TagsToSendType } from "src/modules/tracker/services/valueHandler";
import useDebounce from "src/hooks/useDebounce";
import PromptDialog from "src/forms/PromptDialog";
import { sortTagsColumnState } from "src/modules/tracker/components/Grid/TrackerGrid";
import { useAnalysisGradeSummaryData } from "../../hooks/GradeSumary/useAnalysisGradeSummaryData";
import {
    AnalysisGradeSummaryValuesFlatObject,
    ViewGrades,
} from "../../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { useAnalysisSnapshots } from "../../hooks/useAnalysisSnapshots";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useAnalysisUserSettingsStore } from "../../hooks/useAnalysisUserSettingsStore";

export const GRADE_SUMMARY_VIEW_TYPE = "grade-summary";

const StudentsGradeSummaryGrid = ({
    cohort,
    yearGroup,
    viewGrade,
    dataType,
    filtersVisible,
    toolsVisible,
    handleIsLoading,
}: {
    cohort: number;
    yearGroup: number;
    viewGrade: ViewGrades;
    dataType: DataTypeTypes;
    filtersVisible?: boolean;
    toolsVisible?: boolean;
    handleIsLoading?: (isLoading: boolean) => void;
}) => {
    const { t }: { t: TFunction } = useTranslation();
    const [values, setValues] = useState<AnalysisGradeSummaryValuesFlatObject[] | null>(null);
    const [header, setHeader] = useState<any[] | null>(null);
    const [filteredValues, setFilteredValues] = useState<any[] | undefined>(undefined);
    const [flatHeader, setFlatHeader] = useState<any[] | null>(null);
    const [averageRow, setAverageRow] = useState<AnalysisGradeSummaryValuesFlatObject | null>(null);
    const gridApi = useRef() as unknown as { current: GridApi };
    const columnApi = useRef() as unknown as { current: ColumnApi };
    const [gridFilters, setGridFilters] = useState<any>(null);
    const [tagsToSend, setTagsToSend] = useState<TagsToSendType[]>([]);
    const debouncedTagsToSend = useDebounce(tagsToSend, 2000);
    const initialColumnOrder = useRef<ColumnState[]>();
    const [allTagsWaiting, setAllTagsWaiting] = useState<{
        colId: string;
        value: any;
        type: "tag" | "link";
    } | null>(null);
    const { navigate } = useSchoolNavigate();

    const location = useLocation();
    const { data: userProfile } = useProfile();

    const { setAnalysisUserSettings, ...analysisUserSettings } = useAnalysisUserSettingsStore();

    const {
        isLoading,
        isDataFetched,
        analysisGradeSummaryHeader,
        analysisGradeSummaryValuesToCompare,
        analysisGradeSummaryBasicValues,
        analysisRelated,
        analysisStudents,
        rowHeight,
        setIsLoading,
    } = useAnalysisGradeSummaryData(dataType, cohort, yearGroup, viewGrade);

    const { data: tags } = useAnalysisTagList(cohort, yearGroup);
    const { data: studentTags, refetch: refetchStudentTags } = useAnalysisStudentTags(
        cohort,
        yearGroup,
    );

    useEffect(() => {
        if (studentTags) {
            gridApi?.current?.refreshCells({ columns: ["tagAvg", "tagAllSubjects"], force: true });
        }
    }, [studentTags]);

    const { mutate: saveTags } = useAnalysisStudentTagMutation(() => {
        refetchStudentTags();
    });

    const prevCohort = usePrevious(cohort);
    const prevYG = usePrevious(yearGroup);
    const { snapshotData } = useAnalysisSnapshots(cohort);
    useEffect(() => {
        if (!isLoading) setIsLoading(true);
        if (prevCohort !== cohort || prevYG !== yearGroup) {
            setHeader(null);
            setValues(null);
        }
    }, [dataType, yearGroup, viewGrade, cohort, snapshotData]);

    useEffect(() => {
        if (handleIsLoading) handleIsLoading(isLoading);
    }, [isLoading, handleIsLoading]);

    const applayTagsOrder = tagsOrder => {
        if (tagsOrder) {
            const columnState: { colId: string }[] =
                initialColumnOrder.current || columnApi.current.getColumnState() || [];

            columnApi.current.applyColumnState({
                state: sortTagsColumnState(columnState, tagsOrder),
                applyOrder: true,
            });
        }
    };

    const onGridReady = params => {
        gridApi.current = params.api;
        columnApi.current = params.columnApi;
        initialColumnOrder.current = params.columnApi.getColumnState() || [];
        applayTagsOrder(analysisUserSettings?.tagsOrder);
    };
    const handleOnColumnMoved = params => {
        if (params.finished && params.column) {
            const columnState = params.columnApi.getColumnState();
            const tagsOrder = columnState
                .filter(({ colId }) => colId.indexOf("tag-") === 0)
                .map(({ colId }) => colId);
            setAnalysisUserSettings({ ...analysisUserSettings, tagsOrder });
            applayTagsOrder(tagsOrder);
        }
    };
    const handleOnColumnVisible = params => {
        initialColumnOrder.current = params.columnApi.getColumnState() || [];
    };

    const onFirstRendered = useCallback(() => {
        if (gridApi.current) {
            setTimeout(() => {
                gridApi.current.redrawRows();
            }, 2000);
        }
    }, [gridApi]);

    const prevViewGrade = usePrevious(viewGrade);

    useEffect(() => {
        if ((prevCohort !== cohort || prevYG !== yearGroup) && viewGrade) {
            setIsLoading(true);
            setHeader(null);
            setValues(null);
        }

        if (prevCohort === cohort && prevYG === yearGroup && prevViewGrade !== viewGrade) {
            if (dataType === DataTypeTypes.LIVE) {
                setIsLoading(true);
                setValues(null);
            } else if (dataType === DataTypeTypes.SNAPSHOT) {
                setValues(null);
                setAverageRow(null);
            }
        }
    }, [cohort, yearGroup, viewGrade]);

    const prevDataType = usePrevious(dataType);

    useEffect(() => {
        if (prevDataType && prevDataType !== dataType) {
            if (dataType === DataTypeTypes.SNAPSHOT) {
                // setSnapshotModalOpen(true);
            } else {
                setValues(null);
                setHeader(null);
                setFlatHeader(null);
                setAverageRow(null);
                setIsLoading(true);
            }
        }
    }, [dataType]);
    const prevTags = usePrevious(tags);
    useEffect(() => {
        if (!isLoading && isDataFetched) {
            if (
                analysisGradeSummaryHeader &&
                (!header || JSON.stringify(prevTags) !== JSON.stringify(tags))
            ) {
                const flatHeader = [];
                setHeader(
                    generateHeader(
                        analysisGradeSummaryHeader as any,
                        t,
                        flatHeader,
                        DataTypeTypes.LIVE,
                        analysisUserSettings,
                        tags,
                    ),
                );
                if (flatHeader && flatHeader.length > 0) {
                    setFlatHeader(flatHeader);
                }
            }
        }
    }, [isLoading, analysisGradeSummaryHeader, tags]);

    useEffect(() => {
        if (header) {
            if (analysisUserSettings?.tagsVisible !== null && analysisUserSettings?.selectedTags) {
                columnApi?.current?.setColumnsVisible(
                    analysisUserSettings?.selectedTags?.map(id => `tag-${id}`),
                    analysisUserSettings?.tagsVisible,
                );

                gridApi?.current?.refreshHeader();
            }
        }
    }, [
        header,
        analysisUserSettings?.selectedTags,
        analysisUserSettings?.tagsVisible,
        gridApi?.current,
        columnApi?.current,
    ]);

    useEffect(() => {
        if (debouncedTagsToSend?.length > 0) {
            const rows: AnalysisStudentTagRows = {};
            debouncedTagsToSend.forEach((ddts: TagsToSendType) => {
                rows[ddts.row] = [
                    ...(rows[ddts.row] || []).concat({
                        tagId: parseInt(ddts.column.replace("tag-", "")),
                        enabled: ddts.value.value !== undefined ? !!ddts.value.value : !!ddts.value,
                    }),
                ];
            });

            saveTags({ cohort, yearGroup, rows });
        }
    }, [debouncedTagsToSend]);

    useEffect(() => {
        if (!isLoading && isDataFetched) {
            let tmpValues = [] as AnalysisGradeSummaryValuesFlatObject[];
            if (
                analysisGradeSummaryBasicValues &&
                Object.keys(analysisGradeSummaryBasicValues).length > 0
            ) {
                Object.keys(analysisGradeSummaryBasicValues).forEach(key => {
                    if (analysisGradeSummaryBasicValues)
                        tmpValues.push({ ...analysisGradeSummaryBasicValues[key], row: key });
                });
                tmpValues = sortByTwoParams(
                    [...tmpValues],
                    "student_lastName",
                    "student_firstName",
                );
            }

            if (tags && tags.length > 0) {
                Object.keys(tmpValues).forEach(ctvKey => {
                    tags.forEach(tag => {
                        tmpValues[ctvKey]["tag-" + tag.id] = 0;
                    });
                });
            }

            if (studentTags && Object.keys(studentTags).length > 0) {
                Object.keys(studentTags).forEach(stKey => {
                    studentTags[stKey].forEach(tagId => {
                        const studentIndex = tmpValues.findIndex(tv => tv.row === stKey);
                        if (
                            tmpValues[studentIndex] !== undefined &&
                            tmpValues[studentIndex]["tag-" + tagId] !== undefined
                        ) {
                            tmpValues[studentIndex]["tag-" + tagId] = 1;
                        }
                    });
                });
            }
            setValues(tmpValues);
        }
    }, [dataType, tags, studentTags, analysisGradeSummaryBasicValues, isLoading]);

    useEffect(() => {
        const row = gridApi?.current?.getPinnedTopRow(0);
        if (row && averageRow) row.setData(averageRow);
    }, [averageRow, gridApi]);

    // toggle grid filters & tools
    useEffect(() => {
        if (filtersVisible && gridApi) {
            gridApi.current?.openToolPanel("studentFilterPanel");
        }
        if (toolsVisible && gridApi) {
            gridApi.current?.openToolPanel("toolsPanel");
        }
        if (!toolsVisible && !filtersVisible && gridApi) {
            gridApi.current?.closeToolPanel();
        }
    }, [filtersVisible, toolsVisible, gridApi]);

    const getRowId = useCallback(params => params.data.student_id, []);
    const components = useMemo(() => ({ ...analysisFrameworkComponentsList }), []);

    // average
    useEffect(() => {
        if (filteredValues && filteredValues.length > 0 && analysisGradeSummaryHeader) {
            setAverageRow(
                generateAnalysisAverageRow(
                    filteredValues,
                    analysisGradeSummaryHeader,
                    analysisRelated,
                    GRADE_SUMMARY_VIEW_TYPE,
                    t,
                ),
            );
        }
    }, [filteredValues, analysisGradeSummaryHeader, analysisRelated, t]);

    const sideBar = useMemo(
        () => ({
            toolPanels: [
                {
                    id: "studentFilterPanel",
                    labelKey: "studentFilterPanel",
                    labelDefault: "",
                    iconKey: "student-filter-panel",
                    toolPanel: AnalysisFilterPanel,
                },
                {
                    id: "toolsPanel",
                    labelKey: "toolsPanel",
                    labelDefault: "",
                    iconKey: "tools-panel",
                    toolPanel: AnalysisToolsPanel,
                },
            ],
        }),
        [],
    );

    const handleFiltersChange = filters => {
        setGridFilters(filters);
    };

    const prevValues = usePrevious(values);
    const prevGridFilters = usePrevious(gridFilters);
    useEffect(() => {
        // grid filters without "student_"
        if ((prevValues !== values && values) || prevGridFilters !== gridFilters) {
            const filteredNew = values?.filter(initialValues => {
                return filterRowFromFilters(gridFilters || {}, initialValues);
            });

            if (!filteredNew) {
                setFilteredValues([]);
            } else {
                setFilteredValues(filteredNew);
            }
        }
    }, [gridFilters, values]);

    useEffect(() => {
        if (analysisUserSettings?.filters && location) {
            navigate({
                pathname: location.pathname,
                search: location.search + "&" + analysisUserSettings.filters,
            });
        }
    }, [analysisUserSettings?.filters]);

    const handleExportToExcel = () => {
        exportAnalysisToExcel(
            gridApi.current,
            analysisGradeSummaryHeader?.columns,
            analysisRelated,
            GRADE_SUMMARY_VIEW_TYPE,
        );
    };

    // change single value handler
    const handleValueChange = (e: CellValueChangedEvent) => {
        const oldValue = e.oldValue === null ? "" : e.oldValue;
        const value = e.newValue;

        const colId = e.column.getColId();
        const newValue = e.newValue?.whileUpdate ? e.newValue?.value : e.newValue;
        if (oldValue !== newValue && e.data.row && colId) {
            if (colId.indexOf("tag-") === 0) {
                setTagsToSend(prevState =>
                    prevState.concat({ row: e.data.row, column: colId, value }),
                );
            }
        }
    };

    const handleTagsLinksAllColumnSelect = (colId, value) => {
        setAllTagsWaiting({ colId, value, type: "tag" });
    };

    const analysisContext = {
        columnConfig: analysisGradeSummaryHeader?.columns,
        studentColumns: analysisStudents || ANALYSIS_INITIAL_STUDENT_PARAMS,
        relatedData: analysisRelated,
        userSettings: analysisUserSettings,
        cohort,
        yearGroup,
        viewGrade,
        dataType,
        flatHeader,
        viewType: GRADE_SUMMARY_VIEW_TYPE,
        compareValues: analysisGradeSummaryValuesToCompare,
        analysisType: "grades",
        handleFiltersChange,
        handleExportToExcel,
        columns: analysisGradeSummaryHeader?.columns,
        tags,
        handleTagsLinksAllColumnSelect,
        userProfile,
    };
    return (
        <>
            {isLoading ? (
                <Box
                    bgcolor="#f9f9f9"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flex={1}
                    top={80}
                    width="100%"
                    height="100%"
                    position="absolute"
                    zIndex={500}
                >
                    <DotsProgress />
                </Box>
            ) : (
                <></>
            )}

            {header && filteredValues && isDataFetched && averageRow && isLoading === false ? (
                <Box
                    className="ag-theme-alpine ag-theme-alpine-analysis"
                    flex={1}
                    width="100%"
                    position="relative"
                    borderLeft={`10px solid ${getGridBorderColor(dataType)}`}
                >
                    <AgGridReact
                        columnDefs={header}
                        rowData={filteredValues}
                        onGridReady={onGridReady}
                        onColumnVisible={handleOnColumnVisible}
                        onColumnMoved={handleOnColumnMoved}
                        onFirstDataRendered={onFirstRendered}
                        sideBar={sideBar}
                        components={components}
                        context={analysisContext}
                        getRowId={getRowId}
                        rowBuffer={15}
                        enableRangeSelection={true}
                        pinnedTopRowData={[averageRow]}
                        suppressColumnMoveAnimation={true}
                        processCellForClipboard={params => {
                            if (params.value === -1) return "U";
                            return processAnalysisCopyToClipboard(
                                params,
                                analysisRelated,
                                analysisGradeSummaryHeader,
                            );
                        }}
                        enableBrowserTooltips={true}
                        gridOptions={{
                            headerHeight: calculateAnalysisHeaderHeight(analysisGradeSummaryHeader),
                            groupHeaderHeight: 0,
                            animateRows: false,
                            suppressColumnMoveAnimation: true,
                            suppressAutoSize: true,
                            suppressContextMenu: true,
                            onCellValueChanged: handleValueChange,
                            autoSizePadding: 1,
                            rowHeight: rowHeight,
                        }}
                    />{" "}
                    <PromptDialog
                        open={!!allTagsWaiting?.colId}
                        onClose={confirmed => {
                            if (confirmed) {
                                const newValues = [...filteredValues].map(fv => {
                                    setTagsToSend(prevState =>
                                        prevState.concat({
                                            row: fv.row,
                                            column: allTagsWaiting?.colId,
                                            value: allTagsWaiting?.value,
                                        }),
                                    );
                                    return {
                                        ...fv,
                                        [allTagsWaiting?.colId]: {
                                            value: allTagsWaiting?.value,
                                            whileUpdate: true,
                                        },
                                    };
                                });
                                setFilteredValues(newValues);
                            }

                            setAllTagsWaiting(null);
                        }}
                    >
                        {t("tracker.grid.allTagsWaiting")}
                    </PromptDialog>
                </Box>
            ) : (
                <></>
            )}
        </>
    );
};

export default StudentsGradeSummaryGrid;
