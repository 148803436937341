import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { OrmSchoolCustomField, SchoolCustomFieldTypes } from "src/orm/models/SchoolCustomField";
import { CUSTOM_FIELDS_LIST_QUERY, CustomFieldByCohortList } from "./useCustomFieldsList";
import { getSchoolAccountId } from "src/services/url";

export interface CustomFieldByCohortField {
    fieldType: SchoolCustomFieldTypes;
    configurations: OrmSchoolCustomField[];
}

export interface CustomFieldByCohortRequest {
    fields: CustomFieldByCohortField[];
}

const apiCustomFieldsEdit = async (
    values: CustomFieldByCohortRequest,
): Promise<AxiosResponse<CustomFieldByCohortList>> => {
    return await axiosInstance.put(`school/${getSchoolAccountId()}/school-custom-fields/`, values);
};

export const useCustomFieldsEditMutation = (
    onSuccess: (data: CustomFieldByCohortList) => void,
    onError?: any,
) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: CustomFieldByCohortRequest) => apiCustomFieldsEdit(values),
        onSuccess: ({ data }: { data: CustomFieldByCohortList }) => {
            queryClient.invalidateQueries({ queryKey: [CUSTOM_FIELDS_LIST_QUERY] });
            enqueueSnackbar(t("school.customFields.saveSuccess"), { variant: "success" });
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError) {
                onError(err?.response?.data?.error);
            }
        },
    });
};
