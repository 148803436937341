import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { ROUTE_LOGOUT } from "src/routes";

const apiRemoveAccount = async (): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`user/`);
};

export const useRemoveAccount = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { navigate } = useSchoolNavigate();
    return useMutation({
        mutationFn: () => apiRemoveAccount(),
        onSuccess: () => {
            enqueueSnackbar(t("common.removed"), { variant: "success" });
            navigate(ROUTE_LOGOUT);
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
