import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export interface CommonAccountInfo {
    canUpgradeToWholeSchool: boolean;
    hasFinanceManager: boolean;
    canStartNewFreeTrial: boolean;
    canStartNewSubscription: boolean;
    numberOfRequestedSubscriptions: number;
    canManageClassesAndTracking: boolean;
    isWholeSchoolActive?: boolean;
    hasStudents?: boolean;
    canStartNahtSupportedSubscription?: boolean;
    allowClassMigrations?: boolean;
    hasGroupCallSynchronization?: boolean;
    activeCohortId?: number;
    customFieldsEditionEnabled?: boolean;
    hasParentPortalEnabled?: boolean;
    misName?: string;
    hasStudentPortalEnabled?: boolean;
}

export const ACCOUNT_INFO_QUERY = "AccountInfoQuery";

export const useAccountInfo = () => {
    const schoolAccountId = getSchoolAccountId() || null;

    return useQuery({
        queryKey: [ACCOUNT_INFO_QUERY, schoolAccountId],
        queryFn: async (): Promise<CommonAccountInfo | null> => {
            if (schoolAccountId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/common/account/info/`,
                );
                return data || null;
            }
            return null;
        },
    });
};
