import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const PARENT_EXPORTED_FILE_QUERY = "parentExportedFileQuery";

export const useParentExportedFile = (hash: string) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [PARENT_EXPORTED_FILE_QUERY, schoolAccountId, hash],
        queryFn: async (): Promise<{ link: string } | null> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/parents/export/${hash}`,
            );

            return data || null;
        },
    });
};
