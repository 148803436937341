import { ActionsUnion, Keys } from "@martin_hotell/rex-tils";
import { processEndpoints } from "src/api/helpers";
import { Modules } from "src/types";
import { ClassesActionsTypes, ClassesApiEndpoints, ClassesRawActions } from "./Classes/actions";

export const ClassActionTypes = {
    ...ClassesActionsTypes,
};

const _endpoints = {
    ...ClassesApiEndpoints,
};

export type ClassEndpointKeys = Keys<typeof _endpoints>;

export const ClassEndpoints = processEndpoints<ClassEndpointKeys>(Modules.CLASS, _endpoints);

export const ClassActions = {
    ...ClassesRawActions(ClassEndpoints),
};

export type ClassActions = ActionsUnion<typeof ClassActions>;
