import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { STUDENT_DETAILS_QUERY } from "./useStudentDetails";
import { getSchoolAccountId } from "src/services/url";

export interface EditStudentParentsRequest {
    id: number;
    parents: ListObject<number>[];
}

const apiCreateStudentParents = async (
    values: EditStudentParentsRequest,
): Promise<AxiosResponse> => {
    return await axiosInstance.put(`school/${getSchoolAccountId()}/student/${values.id}/parents/`, {
        parents: values.parents,
    });
};

export const useStudentParentsMutation = (onSuccess: () => void, onError?: (err) => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: EditStudentParentsRequest) => apiCreateStudentParents(values),
        onSuccess: data => {
            queryClient.setQueryData([STUDENT_DETAILS_QUERY, data.data?.id], data?.data);

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(
                    err.response.data?.error || t("students.addEdit.errorSavingParents"),
                    {
                        ...SnackbarErrorOptions,
                    },
                );
            }
            if (onError) {
                onError(err.response.data);
            }
        },
    });
};
