import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmGradeActions, OrmGradeActionTypes } from "./actions";

export const gradeReducer: ModelReducer = {
    [OrmGradeActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmGradeActions.bulkImport>,
        session,
    ) => {
        const { Grade } = session;
        Grade.all().delete();
        action.payload.data.forEach(t =>
            Grade.create(
                ormToRef(t, { defaults: { specificationId: action.payload.specificationId } }),
            ),
        );
    },
};
