import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { TFunction } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiltersShape } from "src/modules/common/components/Grid/GridStudentFilter";

export const getInitialMtgOptions = (t: TFunction) => [
    { value: 1, label: t("common.mtgAboveTarget"), selected: false, colour: "rgb(6, 147, 227)" },
    { value: 0, label: t("common.mtgOnTarget"), selected: false, colour: "rgb(112, 173, 71)" },
    {
        value: -1,
        label: t("common.mtgOneGradeAway"),
        selected: false,
        colour: "rgb(255, 165, 47)",
    },
    { value: -2, label: t("common.mtgOffTarget"), selected: false, colour: "rgb(217, 46, 32)" },
    { value: null, label: t("common.mtgNotSet"), selected: false, colour: "rgb(0, 0, 0)" },
];

interface OwnProps {
    handleFiltersChange: (filter: FiltersShape) => void;
    handleOptionChange?: (value: string) => void;
    columns: ({
        id: any;
        name: string;
    } | null)[];
    excludedMtgFilterColumnsIds?: string[];
}

type MtgOption = { value: number | null; label: string; selected: boolean; colour: string };

const MTGFilter: FC<OwnProps> = ({
    handleFiltersChange,
    handleOptionChange,
    columns,
    excludedMtgFilterColumnsIds,
}) => {
    const { t } = useTranslation();

    const [gradeColumn, setGradeColumn] = useState<string>("");
    const [mtgOptions, setMtgOptions] = useState<MtgOption[]>(getInitialMtgOptions(t));

    return (
        <>
            <FormControl fullWidth margin="normal">
                <InputLabel shrink>{t("common.filterByGradeColumn")}</InputLabel>
                <Select
                    value={gradeColumn}
                    displayEmpty
                    label={t("common.filterByGradeColumn")}
                    onChange={e => {
                        handleOptionChange && handleOptionChange(e.target.value as string);
                        setGradeColumn(e.target.value as string);
                    }}
                >
                    <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                    {columns
                        .filter(option => !(excludedMtgFilterColumnsIds || []).includes(option.id))
                        .map(option =>
                            option ? (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ) : (
                                <></>
                            ),
                        )}
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                {mtgOptions.map((mtgOption, oI) => {
                    return (
                        <FormControlLabel
                            key={mtgOption.value}
                            control={
                                <Checkbox
                                    disabled={!gradeColumn}
                                    checked={mtgOption.selected}
                                    onChange={() => {
                                        const newOptions = [...mtgOptions];
                                        newOptions[oI].selected = !newOptions[oI].selected;
                                        setMtgOptions(newOptions);
                                    }}
                                />
                            }
                            label={
                                <span style={{ color: mtgOption.colour }}>{mtgOption.label}</span>
                            }
                        />
                    );
                })}
            </FormControl>
            <Box pt={2}>
                <Button
                    color="primary"
                    onClick={() => {
                        handleFiltersChange({
                            ["mtg-" + gradeColumn]: {
                                type: "mtg",
                                attribute: "mtg-" + gradeColumn,
                                attributeDisplay: `${
                                    columns.find(o => o && o.id === gradeColumn)?.name
                                }`,
                                values: [
                                    {
                                        value: mtgOptions
                                            .filter(mtgOption => mtgOption.selected)
                                            .map(mtgOption => {
                                                return mtgOption.value === null
                                                    ? "N"
                                                    : mtgOption.value;
                                            })
                                            .join(","),
                                        valueDisplay: "",
                                    },
                                ],
                            },
                        });
                        setGradeColumn("");
                        setMtgOptions(getInitialMtgOptions(t));
                    }}
                >
                    {t("common.filterAdd")}
                </Button>
            </Box>
        </>
    );
};

export default MTGFilter;
