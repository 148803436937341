import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material";
import { PARENT_PORTAL_DEEPLINK } from "src/config/globals";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router";
import Logotype from "src/assets/logotype.svg";
import AppStore from "src/assets/app-store.svg";
import GooglePlay from "src/assets/google-play.svg";

const ParentAppProxyContainer = () => {
    const { t } = useTranslation();
    const { action, hash, userId } = useParams();
    const [ready, setReady] = useState<boolean>(false);
    const [deeplink, setDeeplink] = useState<string>("");

    useEffect(() => {
        if (window) {
            let url: string = PARENT_PORTAL_DEEPLINK || "";
            if (action === "forget") {
                url = url + "ForgotPasswordNew/" + userId + "/" + hash;
            }
            if (action === "register") {
                url = url + "RegisterInvite/" + hash;
            }

            if (!deeplink || deeplink !== url) {
                setDeeplink(url);
            }

            if (isMobile) {
                window.location.replace(url);

                setTimeout(() => {
                    setReady(true);
                }, 2000);
            } else {
                setReady(true);
            }
        }
    }, [window]);

    return (
        <>
            {ready ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "#fff",
                        paddingX: 3,
                        position: "fixed",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 100,
                        width: "100vw",
                        height: "100vh",
                    }}
                >
                    <Box component="figure" width={208} mt={0} mr="auto" mb={4} ml="auto">
                        <img width={208} src={Logotype} alt={t("common.logoAlt")} />
                    </Box>
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: { xs: 24, md: 32 },
                            fontWeight: 400,
                            lineHeight: 1.375,
                            textAlign: "center",
                        }}
                    >
                        {t("parentAppProxy.header")}
                    </Typography>

                    {/* <textarea value={deeplink} rows={3} /> */}
                    {/* <br /> */}
                    {/* {deeplink ? (
                        <Button
                            onClick={() => {
                                window.location.replace(deeplink);
                            }}
                        >
                            Go to Pupil Progress App
                        </Button>
                    ) : (
                        ""
                    )} */}
                    <Box sx={{ display: "flex", gap: 2, marginTop: 4, marginBottom: 4 }}>
                        <a
                            href="https://apps.apple.com/us/app/pupil-progress-app/id6472656119"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={AppStore} alt="" />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.pupilprogress.app"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={GooglePlay} alt="" />
                        </a>
                    </Box>
                    <Typography
                        sx={{ fontSize: { xs: 16, md: 20 }, lineHeight: 1.4, textAlign: "center" }}
                    >
                        {t("parentAppProxy.text1")}
                    </Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        bgcolor: "#fff",
                        position: "fixed",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 100,
                        width: "100vw",
                        height: "100vh",
                    }}
                />
            )}
        </>
    );
};

export default ParentAppProxyContainer;
