import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmSubjectAreaActions, OrmSubjectAreaActionTypes } from "./actions";

export const subjectAreaReducer: ModelReducer = {
    [OrmSubjectAreaActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmSubjectAreaActions.bulkImport>,
        session,
    ) => {
        const { SubjectArea } = session;
        SubjectArea.all().delete();
        action.payload.forEach(ct => SubjectArea.create(ormToRef(ct, {})));
    },
};
