import { Box, Button, ButtonProps, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { mdiChevronLeft } from "@mdi/js";
import Icon from "src/components/Icon";
import logo from "src/assets/logo.svg";
import COLORS from "src/styles/colors";
import { useSchoolDetails } from "src/modules/school/hooks/query/SchoolDetails/useSchoolDetails";
import { useUserContext } from "src/modules/user/hooks/useUserContext";

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    backgroundColor: COLORS.WHITE,
    paddingRight: theme.spacing(2.5),
    boxShadow: "none",
    "& > svg": {
        margin: 0,
    },
    "&:hover": {
        backgroundColor: COLORS.VERY_LIGHT_GREY_4,
    },
    "& .logo": {
        width: 35,
        height: 35,
    },
    "& .school-logo": {
        height: 35,
        marginLeft: 20,
    },
}));

const GridHeaderBack = props => {
    const { t } = useTranslation();
    const { data: schoolDetails } = useSchoolDetails();
    const { data: userContext } = useUserContext();
    return (
        <Box
            bgcolor={COLORS.WHITE}
            borderRight={`1px solid ${COLORS.LIGHT_GREY_2}`}
            alignItems="center"
            justifyContent="center"
        >
            <StyledButton {...props}>
                <Icon color={COLORS.LIGHT_GREY_1} path={mdiChevronLeft} size={1.85} />
                <img src={logo} alt={t("common.logoAlt")} className="logo" />
                {userContext?.schoolAccounts?.length > 1 ? (
                    <img
                        className="school-logo"
                        alt={schoolDetails?.name}
                        src={schoolDetails?.logoUrl}
                    />
                ) : (
                    <></>
                )}
            </StyledButton>
        </Box>
    );
};

export default GridHeaderBack;
