import COLORS from "src/styles/colors";
import { useTheme } from "@mui/material";
import { useImperativeHandle } from "react";

export const StripeInput = props => {
    const { component: Component, inputRef, ...other } = props;

    const theme = useTheme();
    // implement `InputElement` interface
    useImperativeHandle(inputRef, () => ({
        focus: () => {
            // logic to focus the rendered component from 3rd party belongs here
        },
    }));

    const styles = {
        base: {
            color: COLORS.GREY_1,
            fontSize: "1rem",
            fontFamily: theme.typography.fontFamily,
            fontSmoothing: "antialiased",
            "::placeholder": {
                color: COLORS.LIGHT_GREY_1,
            },
        },
        invalid: {
            color: COLORS.RED_1,
        },
    };

    // `Component` will be your `SomeThirdPartyComponent` from below
    return <Component style={styles} {...other} />;
};
