import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import Tag from "src/components/Tag";
import { useAnalysisTagList } from "src/modules/tagging/hooks/Analysis/useAnalysisTagList";

const TagsTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        maxWidth: 220,
        color: "var(--ag-data-color)",
        fontSize: theme.typography.pxToRem(12),
        border: `1px solid #dfe0e2`,

        "& ul": {
            margin: 0,
            padding: theme.spacing(1),
        },
        "& ul li": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            position: "relative",
            padding: 0,
            margin: 0,
        },
        "& ul li + li": {
            margin: theme.spacing(0.25, 0, 0),
        },
    },
}));
interface OwnProps {
    node;
    context;
}

const TagAvgRenderer = ({ node, context }: OwnProps) => {
    const { data: tags } = useAnalysisTagList(context?.cohort, context?.yearGroup);

    const data = node.data;

    const tagsList = [];
    Object.keys(data).forEach(paramKey => {
        if (
            paramKey.indexOf("tag-") === 0 &&
            (data[paramKey] === 1 || data[paramKey]?.["value"] === 1)
        ) {
            tagsList.push(paramKey);
        }
    });

    return (
        <TagsTooltip
            title={
                tagsList?.length > 0 && (
                    <>
                        <ul>
                            {tagsList.map(tag => (
                                <li key={tag.id}>
                                    &rsaquo;&nbsp;&nbsp;{" "}
                                    {tags.find(({ id }) => tag === "tag-" + id).name}
                                </li>
                            ))}
                        </ul>
                    </>
                )
            }
            placement="right"
        >
            <div className="ag-student-data-cell" style={{ height: "100%" }}>
                {tagsList.length > 0 && (
                    <Tag arrow="right" variant={"contained"}>
                        {tagsList.length}
                    </Tag>
                )}
            </div>
        </TagsTooltip>
    );
};

export default TagAvgRenderer;
