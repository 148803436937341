import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";

export type StaffRestrictedPropsResponse = string[];

export const SCHOOL_STAFF_RESTRICTED_PROPS_QUERY = "schoolStaffRestrictedPropsQuery";

export const useStaffRestrictedProps = () => {
    return useQuery({
        queryKey: [SCHOOL_STAFF_RESTRICTED_PROPS_QUERY],
        queryFn: async (): Promise<StaffRestrictedPropsResponse | null> => {
            const { data } = await axiosInstance.get(`api/group-call/staff/restricted-props/`);
            return data || null;
        },
    });
};
