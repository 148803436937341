import { ofType } from "@martin_hotell/rex-tils";
import { OrmSpecificationAttributeActions } from "src/orm/models/SpecificationAttribute/actions";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CommonActions, CommonActionTypes } from "../actions";

const specificationAttributesSuccessEpic = (action$: ActionsObservable<CommonActions>) =>
    action$.pipe(
        ofType(CommonActionTypes.GET_SPECIFICATION_ATTRIBUTES_SUCCESS),
        map(
            (action: ReturnType<typeof CommonActions.getSpecificationAttributesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(
                OrmSpecificationAttributeActions.bulkImport({
                    data: payload.response,
                    specification: payload.request.payload.params.specification,
                }),
            );
        }),
    );

export const specificationAttributeEpics = combineEpics(specificationAttributesSuccessEpic);
