import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { AssessmentForCopyModel } from "../../models/AssessmentListModel";
import { getSchoolAccountId } from "src/services/url";

export const CUSTOMISED_ASSESSMENT_LIST_FOR_COPY_QUERY = "customisedAssessmentListForCopyQuery";

export const useCustomisedAssessmentListForCopy = (
    id: number,
    cohortId: number,
    yearGroup: number,
) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [CUSTOMISED_ASSESSMENT_LIST_FOR_COPY_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<AssessmentForCopyModel[] | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/assessments/customised/specification/${id}/${cohortId}/${yearGroup}/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
        retry: false,
    });
};
