import { Model } from "redux-orm";

export const modelProxy = <M extends Model<any>, P>(model: M): P | {} => (model ? model.ref : {});

interface OrmToRefOptions {
    fk?: string[];
    many?: string[];
    defaults?: object;
}

export const ormToRef = (ormObject: object, options: OrmToRefOptions): object => {
    const { fk = [], many = [], defaults = {} } = options;
    const keys = Object.keys(ormObject).filter(k => !fk.includes(k) && !many.includes(k));
    const ref: object = { ...defaults };
    keys.forEach(k => {
        ref[k] = ormObject[k];
    });
    fk.forEach(k => {
        ref[k] = ormObject[k] && ormObject[k].id ? ormObject[k].id : null;
    });
    many.forEach(k => {
        ref[k] = ormObject[k] ? ormObject[k].map(obj => obj.id) : [];
    });

    return ref;
};

export const mergeEmptyDetails = <T extends object>(ormObject: Partial<T>, emptyObject: T): T => {
    const result: T = { ...emptyObject };
    Object.entries(ormObject).forEach(([key, value]) => {
        if (value !== undefined) {
            result[key] = value;
        }
    });

    return result;
};
