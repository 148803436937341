import { Tooltip, darken } from "@mui/material";
import { ColDef } from "ag-grid-community";
import clsx from "clsx";
import { Component } from "react";
import { AnalysisColourConfigDetailsObject } from "src/modules/analysis/api/Common/getRelatedData";
import { getAnalysisMtgColour } from "src/modules/analysis/services/analysisColourHandler";
import { getAnalysisGradeValue } from "src/modules/analysis/services/analysisValueHandler";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { ColourConfigDetailsObject } from "src/modules/tracker/dto/TrackerHeader";
import { getContrastText } from "src/modules/tracker/services/colorHandler";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import COLORS from "src/styles/colors";
import { KS4_GRADE_VIEW_TYPE } from "../KS4GradeAnalysisGrid";
import PinnedTopRenderer from "./PinnedTopRenderer";
import Icon from "src/components/Icon";
import { mdiTableEdit } from "@mdi/js";

interface OwnProps {
    value: boolean | string;
    data: any;
    context: any;
    colDef: ColDef;
    columnColour?: string;
    headerConfig: any;
    node?: any;
    rowIndex: number;
    readOnly?: boolean;
}

export default class AnalysisDefaultGradeRenderer extends Component<OwnProps> {
    render() {
        const column: string | undefined = this.props.colDef?.field;

        let tagSubjectCounter: number = 0;
        const tagSubjectNames: string = "";

        const subjectTags = this.props.context?.tags?.filter(t => t.specifications.length > 0);

        const studentSelectedTagsIds: number[] = [];
        Object.keys(this.props.node.data).forEach(key => {
            if (key.indexOf("tag-") === 0 && this.props.node.data[key] === 1) {
                studentSelectedTagsIds.push(parseInt(key.replace("tag-", "")));
            }
        });

        studentSelectedTagsIds.forEach(ssti => {
            if (
                subjectTags.map(ast => ast.id).includes(ssti) &&
                !!subjectTags?.specifications?.find(
                    s => s === parseInt(column.replace("spec_", "")),
                )
            ) {
                tagSubjectCounter++;
            }
        });

        if (this.props.node.rowPinned) {
            return (
                <PinnedTopRenderer
                    value={column && this.props.data ? this.props.data[column] : ""}
                    columnColour={this.props.columnColour}
                />
            );
        }
        const viewType = this.props.context.viewType;
        // const dataType = this.props.context.dataType;

        const { context, rowIndex } = this.props;
        let whileUpdate = false;

        if (
            context &&
            context.cellStates &&
            context.cellStates[rowIndex] &&
            column &&
            context.cellStates[rowIndex][column]
        ) {
            whileUpdate = context.cellStates[rowIndex][column].whileUpdate;
        }
        let gradeTypeId: number | null = null;
        if (this.props.context.columnConfig && column) {
            gradeTypeId = this.props.context.columnConfig[column]?.gradeTypeId;
        }

        let colourConfig = [];
        if (gradeTypeId && this.props.context.relatedData?.colourConfig) {
            colourConfig = this.props.context.relatedData?.colourConfig[gradeTypeId];
        }

        let metaColumnsConfig: {
            mtgDistance: number;
            a8?: string;
            ebacc?: string;
            tier?: TiersTypes;
            fineGrade?: string;
        } | null = null;

        if (column && this.props.data.metaColumnsConfig) {
            metaColumnsConfig = this.props.data.metaColumnsConfig[column];
        }

        const backgroundColor = this.props.columnColour;
        const colour = getAnalysisMtgColour(metaColumnsConfig, colourConfig);
        const field = this.props.colDef?.field;

        let compareRow = null;
        if (this.props.context.compareValues && context.dataType === DataTypeTypes.SNAPSHOT) {
            const values = this.props.context.compareValues;

            compareRow = values[this.props.data.row];
        }

        let compareValue: number | null = null;

        if (column && compareRow) {
            if (
                (this.props.value !== null || this.props.value !== undefined) &&
                !isNaN(parseInt(`${this.props.value}`))
            ) {
                let compareFrom = this.props.value as any;
                const compareTo = (!compareRow[column] ? 0 : compareRow[column]) as any;

                if (`${this.props.value}` === `-1`) {
                    compareFrom = 0;
                }
                compareValue = compareFrom - compareTo;
            }
            if (!this.props.value && compareRow[column] && !isNaN(parseInt(compareRow[column]))) {
                const compareTo = parseInt(compareRow[column]);
                if (compareTo > 0) {
                    compareValue = 0 - compareTo;
                }
            }
        }

        if (this.props.context.compareValues && context.dataType === DataTypeTypes.SNAPSHOT) {
            return (
                <div
                    className="ag-value-cell-compare-row"
                    style={{
                        backgroundColor: backgroundColor || COLORS.VERY_LIGHT_GREY_8,
                    }}
                >
                    {compareValue !== null &&
                        compareValue !== undefined &&
                        `${this.props.value}` !== `-1` && (
                            <div
                                className={clsx(
                                    "ag-value-cell-compared-row",
                                    "ag-value-cell-compare",
                                    `ag-value-cell-compare--${
                                        compareValue < 0
                                            ? "red"
                                            : compareValue > 0
                                              ? "blue"
                                              : "green"
                                    }`,
                                )}
                            >
                                {viewType === KS4_GRADE_VIEW_TYPE
                                    ? compareValue.toFixed(2)
                                    : Math.round(compareValue)}
                            </div>
                        )}

                    <div
                        className={clsx(
                            "ag-value-cell-label",
                            `ag-value-cell-label--${
                                compareValue !== null &&
                                compareValue !== undefined &&
                                compareValue < 0
                                    ? "red"
                                    : compareValue !== null &&
                                        compareValue !== undefined &&
                                        compareValue > 0
                                      ? "blue"
                                      : "green"
                            }`,
                        )}
                    >
                        {getAnalysisGradeValue(
                            this.props.value,
                            this.props.context.relatedData,
                            gradeTypeId,
                            viewType,
                        )}
                        {this.props.value &&
                        field &&
                        metaColumnsConfig &&
                        (metaColumnsConfig.a8 || metaColumnsConfig.ebacc) ? (
                            <span className="ag-value-cell-hint ag-value-cell-hint--compare">
                                {metaColumnsConfig.a8 ? "8" : ""}
                                {metaColumnsConfig.a8 && metaColumnsConfig.ebacc ? "+" : ""}
                                {metaColumnsConfig.ebacc ? "E" : ""}
                            </span>
                        ) : (
                            ""
                        )}
                        {this.props.value &&
                        field &&
                        metaColumnsConfig &&
                        metaColumnsConfig.tier &&
                        metaColumnsConfig.tier !== "-" ? (
                            <span className="ag-value-cell-hint ag-value-cell-hint--compare">
                                {metaColumnsConfig.tier}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            );
        }

        const isEditable =
            !this.props.readOnly && field && Object.keys(this.props.data).includes(field);
        const displayedValue = getAnalysisGradeValue(
            this.props.value,
            this.props.context.relatedData,
            gradeTypeId,
            viewType,
            this.props.context.a8 || false,
        );
        return (
            <Tooltip title={!displayedValue && isEditable ? "No data" : ""}>
                <div
                    style={{
                        backgroundColor:
                            colour && this.props.value !== null && this.props.value !== undefined
                                ? (colour as AnalysisColourConfigDetailsObject).colour
                                : !isEditable
                                  ? backgroundColor || COLORS.VERY_LIGHT_GREY_8
                                  : darken(backgroundColor || COLORS.VERY_LIGHT_GREY_8, 0.04),

                        color:
                            colour?.colour && (colour?.opacity === 100 || colour?.opacity === null)
                                ? getContrastText(colour?.colour)
                                : undefined,
                        cursor: !isEditable ? "not-allowed" : "default",
                    }}
                    className="ag-value-cell-label"
                >
                    <>
                        <div className="ag-value-cell-label--truncate">
                            {`${this.props.value}` === "-2"
                                ? "X"
                                : `${this.props.value}` === "-1"
                                  ? "U"
                                  : `${this.props.value}` === "-3"
                                    ? "A"
                                    : displayedValue}
                            {metaColumnsConfig?.fineGrade || ""}
                            {tagSubjectCounter > 0 ? (
                                <div>
                                    {tagSubjectCounter}{" "}
                                    <Tooltip title={tagSubjectNames}>
                                        <>{tagSubjectNames}</>
                                    </Tooltip>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>

                        {this.props.value &&
                        field &&
                        metaColumnsConfig &&
                        (metaColumnsConfig.a8 || metaColumnsConfig.ebacc) ? (
                            <span
                                className="ag-value-cell-hint"
                                style={{
                                    color: getContrastText(
                                        colour &&
                                            this.props.value !== null &&
                                            this.props.value !== undefined
                                            ? (colour as ColourConfigDetailsObject).colour
                                            : backgroundColor || COLORS.VERY_LIGHT_GREY_8,
                                    ),
                                }}
                            >
                                {metaColumnsConfig.a8 ? "8" : ""}
                                {metaColumnsConfig.a8 && metaColumnsConfig.ebacc ? "+" : ""}
                                {metaColumnsConfig.ebacc ? "E" : ""}
                            </span>
                        ) : (
                            ""
                        )}
                        {this.props.value &&
                        field &&
                        metaColumnsConfig &&
                        metaColumnsConfig.tier &&
                        metaColumnsConfig.tier !== "-" ? (
                            <span
                                className="ag-value-cell-hint"
                                style={{
                                    color: getContrastText(
                                        colour &&
                                            this.props.value !== null &&
                                            this.props.value !== undefined
                                            ? (colour as ColourConfigDetailsObject).colour
                                            : backgroundColor || COLORS.VERY_LIGHT_GREY_8,
                                    ),
                                }}
                            >
                                {metaColumnsConfig.tier}
                            </span>
                        ) : (
                            ""
                        )}
                        {whileUpdate && (
                            <span
                                className={`ag-value-cell-status${
                                    whileUpdate ? " ag-value-cell-status--update" : ""
                                }`}
                            />
                        )}

                        {!displayedValue && isEditable && <Icon path={mdiTableEdit} size={0.8} />}
                    </>
                </div>
            </Tooltip>
        );
    }
}
