import { Button, Box } from "@mui/material";
import Icon from "src/components/Icon";
import { mdiDownload } from "@mdi/js";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import COLORS from "src/styles/colors";

const SnapshotActionButtons = ({ exportAsExcel }: { exportAsExcel: () => void }) => {
    const { t }: { t: TFunction } = useTranslation();

    return (
        <Box
            px={4}
            py={3}
            borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
            display="flex"
            flexDirection="column"
        >
            <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27} mb={1}>
                {t("tracker.grid.toolsTracker")}
            </Box>
            <Button
                variant="text"
                onClick={exportAsExcel}
                startIcon={<Icon path={mdiDownload} />}
                disableRipple
            >
                {t("tracker.grid.downloadBtn")}
            </Button>
        </Box>
    );
};

export default SnapshotActionButtons;
