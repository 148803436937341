import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { StyledMenu, StyledMenuItem } from "src/components/StyledMenu";
import { useClassListByCohort } from "src/modules/class/hooks/useClassList";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

import { OrmClassTracker } from "src/orm/models/ClassTracker";
import { OrmClassTrackerGroup } from "src/orm/models/ClassTrackerGroup";
import {
    ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER,
    ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER_GROUP,
    ROUTE_TRACKER_CLASS_TRACKER,
    ROUTE_TRACKER_CLASS_TRACKER_GROUP,
} from "src/routes";
import { generateTrackerUrl } from "src/services/url";
import COLORS from "src/styles/colors";

interface OwnProps {
    anchorEl: null | HTMLElement;
    setAnchorEl: Dispatch<SetStateAction<HTMLElement>>;
    classDetailsYearGroup?: number;
    classDetailsId?: number;
    classDetailsGualificationId?: number;
    classDetailsSpecificationId?: number;
}

const ChangeClassStyleMenu: FC<OwnProps> = ({
    anchorEl,
    setAnchorEl,
    classDetailsYearGroup,
    classDetailsId,
    classDetailsGualificationId,
    classDetailsSpecificationId,
}) => {
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const { cohort, tier } = useParams();

    const [selectedYearGroup, setSelectedYearGroup] = useState<number>(classDetailsYearGroup);
    const [classTrackersList, setClassTrackersList] = useState<OrmClassTracker[]>([]);
    const [selectedClass, setSelectedClass] = useState<number>(classDetailsId);
    const [classListBySpecyfication, setClassListBySpecyfication] = useState<
        OrmClassTrackerGroup[]
    >([]);
    const [tiersList, setTiersList] = useState<string[]>([]);
    const [selectedTier, setSelectedTier] = useState<"H" | "F" | "I" | "-">(
        tier as "H" | "F" | "I" | "-",
    );

    const { data: classList, refetch: getClasses } = useClassListByCohort(parseInt(cohort), {
        qualificationId: classDetailsGualificationId,
        yearGroup: undefined,
        page: 1,
        perPage: 100000,
    });

    useEffect(() => {
        getClasses();
    }, [classDetailsId]);

    useEffect(() => {
        if (classList)
            setClassListBySpecyfication(
                classList.data.filter(
                    ({ specification }) => specification.id === classDetailsSpecificationId,
                ),
            );
    }, [classList]);

    useEffect(() => {
        if (classListBySpecyfication)
            setClassTrackersList(
                classListBySpecyfication?.find(({ yearGroup }) => yearGroup === selectedYearGroup)
                    ?.classTrackers,
            );
    }, [classListBySpecyfication]);

    useEffect(() => {
        const enableTiers =
            selectedClass === -1
                ? classListBySpecyfication?.find(({ yearGroup }) => yearGroup === selectedYearGroup)
                      ?.tiers || []
                : classTrackersList?.find(({ id }) => id === selectedClass)?.tiers || [];
        setTiersList(enableTiers);
        if (selectedClass !== classDetailsId) {
            setSelectedTier(
                enableTiers.length > 0 ? (enableTiers[0].charAt(0) as "H" | "F" | "I") : "-",
            );
        } else {
            setSelectedTier(tier as "H" | "F" | "I" | "-");
        }
    }, [selectedClass, classTrackersList]);

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedYearGroup(classDetailsYearGroup);
        setSelectedClass(classDetailsId);
    };

    const hanadleSelectYearGroup = (yG: number) => {
        setSelectedYearGroup(yG);
        const cTList = classListBySpecyfication?.find(
            ({ yearGroup }) => yearGroup === yG,
        )?.classTrackers;
        setClassTrackersList(cTList);

        if (
            cTList.length > 0 &&
            selectedClass !== -1 &&
            cTList.find(cT => cT.id === selectedClass) === undefined
        ) {
            setSelectedClass(cTList[0].id);
        }
    };
    const handleTrackerChange = () => {
        if (classListBySpecyfication.length > 0) {
            const {
                archived,
                subjectArea,
                qualification,
                specification,
                id: classTrackerGroupId,
            } = classListBySpecyfication.find(({ yearGroup }) => yearGroup === selectedYearGroup);
            if (selectedClass === -1) {
                navigate(
                    generateTrackerUrl(
                        archived
                            ? ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER_GROUP
                            : ROUTE_TRACKER_CLASS_TRACKER_GROUP,
                        classTrackerGroupId,
                        parseInt(cohort),
                        subjectArea.name,
                        qualification.name,
                        specification,
                        selectedTier,
                        selectedYearGroup,
                    ),
                );
                navigate(0);
            } else {
                navigate(
                    generateTrackerUrl(
                        archived
                            ? ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER
                            : ROUTE_TRACKER_CLASS_TRACKER,
                        selectedClass,
                        parseInt(cohort),
                        subjectArea.name,
                        qualification.name,
                        specification,
                        selectedTier,
                        selectedYearGroup,
                    ),
                );
                navigate(0);
            }
        }
    };

    return (
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            width={250}
        >
            <StyledMenuItem disableRipple disableTouchRipple>
                <Box display="flex" width="100%">
                    <Box
                        paddingTop={1.375}
                        paddingX={2.5}
                        paddingBottom={2.125}
                        borderLeft={`1px solid ${COLORS.VERY_LIGHT_GREY_5}`}
                        flex={1}
                    >
                        <TextField
                            label={t("tracker.grid.yearGroup")}
                            select
                            SelectProps={{ displayEmpty: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="none"
                            value={selectedYearGroup}
                            onChange={e => hanadleSelectYearGroup(parseInt(`${e.target.value}`))}
                        >
                            {classListBySpecyfication?.map(({ yearGroup }) => (
                                <MenuItem key={yearGroup} value={yearGroup}>
                                    {yearGroup === 0 ? t("common.yg0") : yearGroup}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Box>
            </StyledMenuItem>
            <StyledMenuItem disableRipple disableTouchRipple>
                <Box display="flex" width="100%">
                    <Box
                        paddingTop={1.375}
                        paddingX={2.5}
                        paddingBottom={2.125}
                        borderLeft={`1px solid ${COLORS.VERY_LIGHT_GREY_5}`}
                        flex={1}
                    >
                        <TextField
                            label={t("tracker.grid.classTracker")}
                            select
                            SelectProps={{ displayEmpty: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="none"
                            value={selectedClass}
                            onChange={e => setSelectedClass(parseInt(`${e.target.value}`))}
                        >
                            <MenuItem key={-1} value={-1}>
                                {t("tracker.grid.yearGroupOverview")}
                            </MenuItem>
                            {classTrackersList?.map(cT => {
                                return (
                                    <MenuItem disabled={cT.isDisabled} key={cT.id} value={cT.id}>
                                        {cT.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Box>
                </Box>
            </StyledMenuItem>
            {tiersList.length > 0 && (
                <StyledMenuItem disableRipple disableTouchRipple>
                    <Box display="flex" width="100%">
                        <Box
                            paddingTop={1.375}
                            paddingX={2.5}
                            paddingBottom={2.125}
                            borderLeft={`1px solid ${COLORS.VERY_LIGHT_GREY_5}`}
                            flex={1}
                        >
                            <TextField
                                label={t("tracker.grid.tier")}
                                select
                                SelectProps={{ displayEmpty: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="none"
                                value={selectedTier}
                                onChange={e => setSelectedTier(e.target.value as "H" | "F" | "I")}
                            >
                                {tiersList.map(t => {
                                    return (
                                        <MenuItem key={t} value={t.charAt(0) as "H" | "F" | "I"}>
                                            {t}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Box>
                    </Box>
                </StyledMenuItem>
            )}
            <StyledMenuItem disableRipple disableTouchRipple>
                <Box paddingY={2.25} paddingX={2.5}>
                    <Button onClick={handleTrackerChange} color="primary">
                        {t("common.continue")}
                    </Button>
                </Box>
            </StyledMenuItem>
        </StyledMenu>
    );
};
export default ChangeClassStyleMenu;
