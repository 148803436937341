import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getReminderEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscriptions/reminder/`,
    method: ApiRequests.GET,
};

export type ReminderResponse = {
    subjectAreaName: string;
    subscriptionType: string;
    daysToExpiry: number;
    type: string;
}[];
