import COLORS from "src/styles/colors";
import Icon from "src/components/Icon";
import { Box, Container, IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ReminderResponse } from "src/modules/subscription/api/getReminder";
import { SubscriptionsActions } from "src/modules/subscription/store/actions";
import { AppState } from "src/store/reducers";
import { Storage } from "src/services/storage";
import { mdiClose } from "@mdi/js";
import { useAuthContext } from "src/context/AuthContext";
import { getSchoolAccountId } from "src/services/url";

enum ReminderType {
    RED = "red",
    ORANGE = "orange",
    YELLOW = "yellow",
}

const getAccentColor = (type: string) => {
    switch (type) {
        case ReminderType.RED:
            return COLORS.RED_1;
        case ReminderType.YELLOW:
            return COLORS.YELLOW_3;
        case ReminderType.ORANGE:
            return COLORS.ORANGE_2;
        default:
            return COLORS.BLUE_3;
    }
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: "#E8E9EB",
        paddingTop: 71,
    },
    row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    reminder: {
        color: COLORS.BLUE_3,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600,
        lineHeight: 1.16,
        textAlign: "center",
        padding: theme.spacing(1, 3),
        "& + &": {
            borderTop: `1px solid ${COLORS.LIGHT_GREY_2}`,
        },
    },
    reminderBtn: {
        fontSize: theme.typography.pxToRem(14),
        padding: 0,
        marginLeft: theme.spacing(1),
        marginRight: 0,
        width: 17,
        height: 17,
        "&:hover": {
            backgroundColor: "transparent",
        },
        "& svg": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
    },
}));

const dispatchActions = (dispatch: Dispatch) => ({
    getReminder: () => {
        dispatch(SubscriptionsActions.getReminder());
    },
});

const Reminder = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const reminderConfirmed: string[] = Storage.getItem("reminderConfirmed");
    const [confirmed, setConfirmed] = useState<string[] | null>(reminderConfirmed || null);
    const { authState } = useAuthContext();
    const { getReminder } = dispatchActions(dispatch);
    const {
        reminder,
    }: {
        reminder: ReminderResponse | null;
    } = useSelector((state: AppState) => ({
        reminder: state.subscription.reminder,
    }));

    const handleReminderClose = (subjectAreaName: string) => {
        const newConfirmed = confirmed ? [...confirmed, subjectAreaName] : [subjectAreaName];
        Storage.setItem("reminderConfirmed", newConfirmed);
        setConfirmed(newConfirmed);
    };

    const handleAllClose = () => {
        if (reminder) {
            const newConfirmed: string[] = [];
            reminder
                .filter(r => {
                    if (confirmed) {
                        return !confirmed.includes(r.subjectAreaName);
                    }
                    return true;
                })
                .forEach(r => {
                    newConfirmed.push(r.subjectAreaName as string);
                });
            if (newConfirmed.length > 0) {
                Storage.setItem("reminderConfirmed", newConfirmed);
                setConfirmed(newConfirmed);
            }
        }
    };

    useEffect(() => {
        // const currentHasReminder: boolean | undefined = Storage.getItem("hasReminder");
        if (reminder && reminder.length > 0) {
            Storage.setItem("hasReminder", true);
        } else {
            Storage.setItem("hasReminder", false);
        }
    }, [reminder]);

    useEffect(() => {
        if (authState.token && getSchoolAccountId()) {
            getReminder();
        }
    }, [authState]);

    const reminderCount = reminder
        ? reminder.filter(r => {
              if (confirmed) {
                  return !confirmed.includes(r.subjectAreaName);
              }
              return true;
          }).length
        : 0;

    if (reminderCount > 3) {
        return (
            <Box className={classes.root}>
                <Box className={classes.reminder}>
                    <Container>
                        <Box className={classes.row}>
                            {t("navbar.reminderDoLike")}{" "}
                            <Box component="span" ml={1}>
                                <strong>
                                    {t("navbar.reminderMoreThan3").replace(
                                        "{subsCount}",
                                        `${reminderCount}`,
                                    )}
                                </strong>
                            </Box>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleAllClose()}
                                disableFocusRipple
                                disableRipple
                                size="small"
                                className={classes.reminderBtn}
                            >
                                <Icon size={0.65} path={mdiClose} />
                            </IconButton>
                        </Box>
                    </Container>
                </Box>
            </Box>
        );
    }

    return reminder && reminder?.length > 0 ? (
        <Box className={classes.root}>
            {reminder
                .filter(r => {
                    if (confirmed) {
                        return !confirmed.includes(r.subjectAreaName);
                    }
                    return true;
                })
                .map((r, i) => {
                    return (
                        <Box key={i} className={classes.reminder}>
                            <Container>
                                <Box className={classes.row}>
                                    {t("navbar.reminderDoLike")}{" "}
                                    {/* ReminderType => possible types*/}
                                    <Box
                                        component="span"
                                        ml={1}
                                        style={{ color: getAccentColor(r.type) }}
                                    >
                                        {t("navbar.reminderWillExp")
                                            .replace(
                                                "{subjectAreaName}",
                                                r.subjectAreaName === null
                                                    ? r.subscriptionType
                                                    : r.subjectAreaName,
                                            )
                                            .replace("{expiryDays}", `${r.daysToExpiry}`)}
                                    </Box>
                                    {r.type !== ReminderType.RED && (
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleReminderClose(r.subjectAreaName)}
                                            disableFocusRipple
                                            disableRipple
                                            size="small"
                                            className={classes.reminderBtn}
                                        >
                                            <Icon size={0.65} path={mdiClose} />
                                        </IconButton>
                                    )}
                                </Box>
                            </Container>
                        </Box>
                    );
                })}
        </Box>
    ) : null;
};

export default Reminder;
