import AppContainer from "src/components/AppContainer";
import PaperInner from "src/components/PaperInner";
import FormikRef from "src/forms/FormikRef";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import ConvertGroupCallClassForm, {
    GroupCallClassFormFields,
} from "../components/ClassTracking/forms/ConvertGroupCallClassForm";
import { Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { addDataSyncClassSchema } from "../components/ClassTracking/forms/addClassSchema";
import { useGroupCallClassList } from "../hooks/MIS/useGroupCallClassList";
import {
    CreateGroupCallClassRequest,
    useGroupCallClassesCreateMutation,
} from "../hooks/MIS/useGroupCallClassesCreateMutation";
import { FormError, getErrorMessage } from "src/services/error";
import { ROUTE_CLASS_CLASS_LIST, ROUTE_CLASS_GROUP_CALL_CONVERT } from "src/routes";
import { useTrackerPath } from "../hooks/useTrackerPath";
import { SpecificationModel } from "src/modules/tracker/models/SpecificationModel";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const convertFormDataToRequest = (
    values: GroupCallClassFormFields,
): CreateGroupCallClassRequest => ({
    yearGroups: values.yearGroups
        .filter(yg => yg.classes && yg.classes.length > 0 && yg.classes.some(c => c.selected))
        .map(yg => ({
            yearGroup: yg.yearGroup,
            classes: yg.classes.filter(c => c.selected && !c.synchronized).map(c => ({ id: c.id })),
        })),
    attributes: values.attributes
        .filter(attr => {
            return (
                (attr.isSelected === true || attr.isMandatory === true) &&
                attr.attributeItemId &&
                !values.disabledItems?.includes(attr.attributeItemId)
            );
        })
        .map(attr => ({
            id: attr.classTrackerAttributeValueId || undefined,
            customName: attr.customName || null,
            attributeValue: { id: attr.id },
        })),
    syncWithMis: true,
});

const ConvertGroupCallClassContainer = () => {
    const { data: accountInfo } = useAccountInfo();
    const form = createRef() as any;
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { specification: startingSpec } = useParams<{ specification: string }>();
    const [shouldRedirectToList, setShouldRedirectToList] = useState<boolean>(false);
    const [selectedSpecification, setSelectedSpecification] = useState<number | null>(
        parseInt(startingSpec),
    );
    const [nextSpecification, setNextSpecification] = useState<SpecificationModel | null>(null);

    const { data: trackerPath, refetch: refetchTrackerPath } =
        useTrackerPath(selectedSpecification);

    const { data: groupCallClasses, refetch } = useGroupCallClassList(selectedSpecification);

    const {
        mutate: createClasses,
        isSuccess,
        isPending,
    } = useGroupCallClassesCreateMutation(err => {
        const currentForm = form.current as any;
        currentForm.setSubmitting(false);
        const error: FormError = getErrorMessage(err);
        if (error.message)
            enqueueSnackbar(error.message, {
                ...SnackbarErrorOptions,
            });
        if (error.formError) currentForm.setErrors(error.formError);
    });

    const initialValues: GroupCallClassFormFields = {
        attributes: [],
        items: [],
        groups: [],
        disabledItems: [],
        initialEditAttributes: [],
        yearGroups: [],
        specification: parseInt(startingSpec),
    };

    const handleSubmit = (values: GroupCallClassFormFields) => {
        addDataSyncClassSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid) {
                    const convertedValues = convertFormDataToRequest(values);
                    createClasses({
                        ...convertedValues,
                        specificationId: values.specification,
                    });
                } else {
                    const currentForm = form.current as any;
                    currentForm.setSubmitting(false);
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    useEffect(() => {
        if (selectedSpecification) {
            refetchTrackerPath();
        }
    }, [selectedSpecification]);

    useEffect(() => {
        if (trackerPath) {
            refetch();
        }
    }, [trackerPath]);

    useEffect(() => {
        if (isSuccess) {
            if (shouldRedirectToList) {
                navigate(ROUTE_CLASS_CLASS_LIST);
            } else if (nextSpecification?.id) {
                navigate(
                    ROUTE_CLASS_GROUP_CALL_CONVERT.replace(
                        ":specification",
                        `${nextSpecification?.id}`,
                    ),
                );
                navigate(0);
            }

            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            enqueueSnackbar(t("class.addClass.classCreatedSuccess"), { variant: "success" });
        }
    }, [isSuccess]);

    const hasAnyManualClass = groupCallClasses
        ? groupCallClasses.reduce((currentAny, gcc) => {
              if (gcc.hasManualClasses && !currentAny) {
                  return true;
              }
              return currentAny;
          }, false)
        : false;

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.groupCallClass.header")}
            </Typography>
            <Paper>
                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => addDataSyncClassSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<GroupCallClassFormFields>) => (
                        <Box mt={4}>
                            <PaperInner variant="paddingTopNone">
                                <Typography>
                                    {t("class.groupCallClass.hint").replaceAll(
                                        "MIS_Name",
                                        accountInfo?.misName || "",
                                    )}
                                </Typography>
                                <ul>
                                    <li>{t("class.groupCallClass.hintList1")}</li>
                                    <li>
                                        {t("class.groupCallClass.hintList2").replaceAll(
                                            "MIS_Name",
                                            accountInfo?.misName || "",
                                        )}
                                    </li>
                                    <li>{t("class.groupCallClass.hintList3")}</li>
                                </ul>
                            </PaperInner>
                            <ConvertGroupCallClassForm
                                handleNextSpecificationChange={(spec: SpecificationModel) =>
                                    setNextSpecification(spec)
                                }
                                selectedSpecification={selectedSpecification}
                                handleSpecificationChange={(specificationId: number) =>
                                    setSelectedSpecification(specificationId)
                                }
                                trackerPath={trackerPath}
                                groupCallClasses={groupCallClasses}
                                convert={true}
                            />
                            <PaperInner variant="paddingTopNone">
                                {hasAnyManualClass && (
                                    <Button
                                        disabled={isPending}
                                        onClick={() => {
                                            setShouldRedirectToList(true);
                                            formProps.submitForm();
                                        }}
                                        color="primary"
                                    >
                                        {t("class.editClass.editClassButton")}
                                        {isPending && (
                                            <Box ml={2}>
                                                <CircularProgress size={18} />
                                            </Box>
                                        )}
                                    </Button>
                                )}

                                {nextSpecification?.id && hasAnyManualClass && (
                                    <Button
                                        disabled={isPending}
                                        color="inherit"
                                        sx={{ marginLeft: 2 }}
                                        onClick={formProps.submitForm}
                                    >
                                        {t("class.editClass.editClassButtonAndStay")}
                                        {isPending && (
                                            <Box ml={2}>
                                                <CircularProgress size={18} />
                                            </Box>
                                        )}
                                    </Button>
                                )}
                                {nextSpecification?.id && !hasAnyManualClass && (
                                    <Button
                                        disabled={isPending}
                                        color="success"
                                        sx={{ marginLeft: hasAnyManualClass ? 2 : undefined }}
                                        onClick={() => {
                                            setSelectedSpecification(nextSpecification.id);
                                        }}
                                    >
                                        {t("class.editClass.skip")}
                                        {isPending && (
                                            <Box ml={2}>
                                                <CircularProgress size={18} />
                                            </Box>
                                        )}
                                    </Button>
                                )}
                                <GenericErrorMessage
                                    errors={formProps.errors}
                                    submitCount={formProps.submitCount}
                                />
                            </PaperInner>
                        </Box>
                    )}
                </FormikRef>
            </Paper>
        </AppContainer>
    );
};

export default ConvertGroupCallClassContainer;
