import { TFunction } from "i18next";
import * as yup from "yup";

export const accountSchema = (t: TFunction) =>
    yup.object().shape({
        title: yup
            .string()
            .nullable()
            .optional()
            .transform((curr, orig) => (orig === "" ? null : curr)),
        firstName: yup.string().required(t("common.form.validation.firstName.required")),
        lastName: yup.string().required(t("common.form.validation.lastName.required")),
        phone: yup
            .string()
            .nullable()
            .optional()
            .transform((curr, orig) => (orig === "" ? null : curr))
            .min(9, t("common.form.validation.phone.min"))
            .max(20, t("common.form.validation.phone.max"))
            .matches(/^0?[1-9]\d+([ -]\d+)*$|^$/, t("common.form.validation.phone.match")),
        mobile: yup
            .string()
            .nullable()
            .optional()
            .transform((curr, orig) => (orig === "" ? null : curr))
            .min(9, t("common.form.validation.phone.min"))
            .max(20, t("common.form.validation.phone.max"))
            .matches(/^0?[1-9]\d+([ -]\d+)*$|^$/, t("common.form.validation.phone.match")),
        userRole: yup.string(),
        schoolTitle: yup
            .string()
            .nullable()
            .optional()
            .transform((curr, orig) => (orig === "" ? null : curr)),
        jobTitle: yup.string(),
        userCode: yup.string(),
    });
