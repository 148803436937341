import axiosInstance from "src/config/axios";
import { useQueries } from "@tanstack/react-query";
import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { CustomisedAssessmentUnitModel } from "../../models/AssessmentUnitModel";
import { getSchoolAccountId } from "src/services/url";

export const CUSTOMISED_ASSESSMENT_UNITS_QUERY = "customisedAssessmentUnitsQuery";

export const useCustomisedAssessmentUnits = (specificationId: number, tiers: TiersTypes[]) => {
    const schoolAccountId = getSchoolAccountId();
    return useQueries({
        queries: tiers.map(tier => {
            return {
                queryKey: [
                    CUSTOMISED_ASSESSMENT_UNITS_QUERY,
                    schoolAccountId,
                    specificationId,
                    tier,
                ],
                queryFn: async (): Promise<CustomisedAssessmentUnitModel[] | null> => {
                    if (specificationId) {
                        const { data } = await axiosInstance.get(
                            `school/${getSchoolAccountId()}/assessments/customised/${specificationId}/${tier}/units/`,
                        );

                        return data;
                    }
                    return null;
                },
                enabled: false,
            };
        }),
    });
};
