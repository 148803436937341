import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const TRACKER_PATH_QUERY = "trackerPathQuery";

export interface TrackerPathItem {
    id: number;
    name: string;
    type: string;
    parentName?: string;
}

export const useTrackerPath = (specificationId?: number | null) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [TRACKER_PATH_QUERY, schoolAccountId, specificationId],
        queryFn: async (): Promise<TrackerPathItem[] | null> => {
            if (specificationId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/common/specification/${specificationId}/tracker-path/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};

export const getTrackerPathLeafe = (
    trackerPath: TrackerPathItem[] | null,
    leafeType: "SubjectArea" | "Qualification" | "Specification",
): TrackerPathItem | null => trackerPath?.filter(({ type }) => type === leafeType).pop() || null;

export const getTrackerPathLeafeId = (
    trackerPath: TrackerPathItem[] | null,
    leafeType: "SubjectArea" | "Qualification" | "Specification",
): number | null => trackerPath?.filter(({ type }) => type === leafeType)?.pop()?.id || null;
