import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { ClassTrackerHeaderObject } from "src/modules/tracker/dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "src/modules/tracker/dto/TrackerRelated";
import { ClassTrackerCompareValues } from "src/modules/tracker/dto/TrackerValues";
import { SnapshotData, useTrackerContext } from "src/context/TrackerContext";
import { useEffect } from "react";
import { useClassTrackerCompareHeader } from "../ClassTracker/Compare/useCompareHeader";
import { useClassTrackerCompareValues } from "../ClassTracker/Compare/useCompareValues";
import { useArchivedTrackerRelatedData } from "./useArchivedTrackerRelatedData";

export interface CompareArchivedTrackerData {
    refetchCompareRelatedData: () => void;
    relatedData: ClassTrackerRelatedObject;
    isLoadingRelatedData: boolean;
    isRefetchingRelatedData: boolean;
    isSuccessRelatedData: boolean;
    refetchCompareValues: () => void;
    values: ClassTrackerCompareValues;
    isLoadingValues: boolean;
    isRefetchingValues: boolean;
    isSuccessValues: boolean;
    refetchCompareHeader: () => void;
    header: ClassTrackerHeaderObject;
    isLoadingHeader: boolean;
    isRefetchingHeader: boolean;
    isSuccessHeader: boolean;
    isRefetchingAll: boolean;
    isLoadingAll: boolean;
    isSuccessAll: boolean;
    fetchCompareData: () => void;
    snapshotData: SnapshotData | null;
    setSnapshotData: (data: SnapshotData) => void;
}

export const useArchivedTrackerCompareData = (
    id: number,
    tier: TiersTypes,
): CompareArchivedTrackerData => {
    const { trackerState, setSnapshotData } = useTrackerContext();
    const { snapshotData } = trackerState;

    const {
        refetch: refetchCompareHeader,
        data: header,
        isLoading: isLoadingHeader,
        isSuccess: isSuccessHeader,
        isRefetching: isRefetchingHeader,
    } = useClassTrackerCompareHeader(
        id,
        tier,
        snapshotData?.compare?.dataSource1 || null,
        snapshotData?.compare?.dataSource2 || null,
    );
    const {
        refetch: refetchCompareValues,
        data: values,
        isLoading: isLoadingValues,
        isSuccess: isSuccessValues,
        isRefetching: isRefetchingValues,
    } = useClassTrackerCompareValues(
        id,
        tier,
        snapshotData?.compare?.dataSource1 || null,
        snapshotData?.compare?.dataSource2 || null,
    );
    const {
        refetch: refetchCompareRelatedData,
        data: relatedData,
        isLoading: isLoadingRelatedData,
        isSuccess: isSuccessRelatedData,
        isRefetching: isRefetchingRelatedData,
    } = useArchivedTrackerRelatedData(id, snapshotData?.compare?.dataSource1 || null);

    const fetchCompareData = () => {
        refetchCompareHeader();
        refetchCompareValues();
        refetchCompareRelatedData();
    };

    const isRefetchingAll = isRefetchingRelatedData || isRefetchingHeader || isRefetchingValues;

    const isLoadingAll = isLoadingRelatedData || isLoadingHeader || isLoadingValues;

    const isSuccessAll = isSuccessRelatedData || isSuccessHeader || isSuccessValues;

    useEffect(() => {
        if (snapshotData?.compare) {
            fetchCompareData();
        }
    }, [snapshotData]);

    return {
        refetchCompareRelatedData,
        relatedData,
        isLoadingRelatedData,
        isSuccessRelatedData,
        isRefetchingRelatedData,
        refetchCompareValues,
        values,
        isLoadingValues,
        isSuccessValues,
        isRefetchingValues,
        refetchCompareHeader,
        header,
        isLoadingHeader,
        isSuccessHeader,
        isRefetchingHeader,
        isRefetchingAll,
        isLoadingAll,
        isSuccessAll,
        fetchCompareData,
        snapshotData,
        setSnapshotData,
    };
};
