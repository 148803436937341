import { Box } from "@mui/material";
import { FC } from "react";
import DotsProgress from "src/components/DotsProgress";

interface OwnProps {
    message?: string;
    size?: string;
}

const GridBlockedOverlay: FC<OwnProps> = ({ message, size }) => {
    return (
        <Box
            bgcolor="#f9f9f9"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
            width="100%"
            height={size === "small" ? "40px" : "100%"}
            position="absolute"
            zIndex={500}
        >
            {!message ? <DotsProgress /> : message}
        </Box>
    );
};

export default GridBlockedOverlay;
