import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

export enum LinksQuickEditOptions {
    POSITION_UP = "up",
    POSITION_DOWN = "down",
    SET_ACTIVE = "activate",
    SET_DEACTIVATE = "deactivate",
}

const apiLinksQuickEdit = async (id: number, editOption: LinksQuickEditOptions) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/report-links/${id}/${editOption}/`,
    );
};

export const useLinksQuickEditMutation = (onSuccess: () => void, onError?) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: ({ id, editOption }: { id: number; editOption: LinksQuickEditOptions }) =>
            apiLinksQuickEdit(id, editOption),

        onSuccess: () => {
            enqueueSnackbar(t("common.saved"), { variant: "success" });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error && typeof err?.response?.data?.error === "string") {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
