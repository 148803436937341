import { FormControlLabel, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import { CheckboxProps } from "formik-mui";

import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    marginRight: {
        marginRight: theme.spacing(4.5),
    },
    marginNone: {
        marginRight: 0,
    },
}));

const CheckboxField = ({
    label,
    field,
    form: { isSubmitting },
    disabled,
    gutter = "right",
    ...props
}: CheckboxProps & {
    label?: string;
    disabled?: boolean;
    gutter?: "none" | "right";
}) => {
    const classes = useStyles();

    return (
        <FormControlLabel
            className={clsx(
                gutter === "none" && classes.marginNone,
                gutter === "right" && classes.marginRight,
            )}
            control={
                <Checkbox
                    disabled={disabled !== undefined ? disabled : isSubmitting}
                    checked={field.value}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    {...props}
                />
            }
            label={label}
        />
    );
};

export default CheckboxField;
