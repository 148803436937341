import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";
export const SUBJECT_ANALYSIS_CLASS_DATA_QUERY = "subjectAnalysisClassDataQuery";

export interface SubjectAnalysisClassDataResponse {
    id: number | null;
    name: string;
    teachers: { [key: string]: any }[];
    numberOfStudents: number;
    numberCurrentAboveTarget: number;
    numberCurrentOnTarget: number;
    numberCurrenOneGradeAway: number;
    numberCurrentOffTarget: number;
    numberProjectedAboveTarget: number;
    numberProjectedOnTarget: number;
    numberProjectedOneGradeAway: number;
    numberProjectedOffTarget: number;
    numberTeacherAboveTarget: number;
    numberTeacherOnTarget: number;
    numberTeacherOneGradeAway: number;
    numberTeacherOffTarget: number;
    numberTargetNotSet: number;

    avgCurrentA8?: number;
    avgCurrentP8?: number;
    numberCurrentGrade95?: number;
    numberCurrentGrade94?: number;
    avgProjectedA8?: number;
    avgProjectedP8?: number;
    numberProjectedGrade95?: number;
    numberProjectedGrade94?: number;
    avgTeacherA8?: number;
    avgTeacherP8?: number;
    numberTeacherGrade95?: number;
    numberTeacherGrade94?: number;
    avgTargetA8?: number;
    avgTargetP8?: number;
    numberTargetGrade95?: number;
    numberTargetGrade94?: number;
}

export const useSubjectAnalysisClassData = (
    cohort: number,
    yearGroup: number,
    specification: number,
    classTrackers?: number[],
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            SUBJECT_ANALYSIS_CLASS_DATA_QUERY,
            schoolAccountId,
            cohort,
            yearGroup,
            specification,
        ],
        queryFn: async (): Promise<SubjectAnalysisClassDataResponse[]> => {
            const filteredClassTrackers =
                classTrackers && classTrackers.length > 0 && classTrackers.filter(ct => ct !== 0);
            const classTrackerIds =
                filteredClassTrackers && filteredClassTrackers.length > 0
                    ? filteredClassTrackers.join(",")
                    : undefined;
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/analysis/subject-analysis/${cohort}/${yearGroup}/specifications/${specification}/classes/values/`,
                { params: { classTrackerIds } },
            );
            return data || [];
        },
    });
};
