import Dialog from "src/components/Dialog";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Button, Box, Typography } from "@mui/material";
import { ForecastUnitList } from "../../api/Forecast/ClassTrackerGroup/getUnitList";

const LoadForecastForceModal = ({
    open,
    handleClose,
    overlapingUnits,
    dataSource,
}: {
    open: boolean;
    handleClose: (confirm: boolean, dataSource?: string) => void;
    overlapingUnits: ForecastUnitList | null;
    dataSource?: string | null;
}) => {
    const { t }: { t: TFunction } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            title={t("tracker.forecast.loadData")}
            actions={
                <>
                    <Button
                        color="primary"
                        onClick={() =>
                            handleClose(
                                true,
                                dataSource && dataSource === "all"
                                    ? dataSource
                                    : `${overlapingUnits?.id || "all"}`,
                            )
                        }
                    >
                        {t("tracker.forecast.forceOverwrite")}
                    </Button>
                    <Button onClick={() => handleClose(false)}>
                        {t("tracker.forecast.keepExisting")}
                    </Button>
                </>
            }
        >
            <Typography gutterBottom>
                {t("tracker.forecast.forceHint1Part1")}
                {overlapingUnits && overlapingUnits.name}
                {t("tracker.forecast.forceHint1Part2")}
            </Typography>
            <Typography>
                <Box component="strong">{t("tracker.forecast.forceHint2")}</Box>
            </Typography>
        </Dialog>
    );
};

export default LoadForecastForceModal;
