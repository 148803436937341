import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { PARENT_LIST_QUERY } from "./useParentsList";
import { getSchoolAccountId } from "src/services/url";

const apiRemoveParent = async (values: ListObject<number>): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`school/${getSchoolAccountId()}/parents/${values.id}/`);
};

export const useParentRemoveMutation = (onSuccess: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: ListObject<number>) => apiRemoveParent(values),
        onSuccess: () => {
            enqueueSnackbar(t("parent.list.parentRemoved"), { variant: "success" });
            queryClient.invalidateQueries({ queryKey: [PARENT_LIST_QUERY] });
            queryClient.refetchQueries({ queryKey: [PARENT_LIST_QUERY] });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error || t("parent.list.activateError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
