import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

export interface StudentDeactivateRequest {
    id?: number;
}

const apiResendStudentInvitation = async (values: StudentDeactivateRequest) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/student/${values.id}/resend-invitation/`,
        values,
    );
};

export const useStudentResendInvitationMutation = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: StudentDeactivateRequest) => apiResendStudentInvitation(values),
        onSuccess: () => {
            enqueueSnackbar(t("students.addEdit.studentInvitationResend"), { variant: "success" });
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
