import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const TAG_SEARCH_QUERY = "tagSearch";

export const useSearchTag = (cohort: number, name: string) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [TAG_SEARCH_QUERY, schoolAccountId, name],
        queryFn: async (): Promise<{ label: string; value: string }[]> => {
            if (cohort && name) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/search/tag/${cohort}/`,
                    {
                        params: {
                            name,
                        },
                    },
                );

                return data?.map(({ id, name }) => ({ label: name, value: id })) || [];
            }
            return [];
        },
    });
};
