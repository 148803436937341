import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FC, ReactElement, useState } from "react";
import ActionsTableButton from "../ActionTableButton";
import { ActionMenuItemProps } from "../ActionTableButton/types";
import { Order, SortCallback, TableColumnProps } from "./types";
import { sortCallbacks, sortKey, stableSort } from "./utils";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    tableContainer: {},
    tableMarginDefault: {
        padding: theme.spacing(3, 6, 5),
    },
    tableMarginTrackersList: {
        padding: theme.spacing(0, 3),
    },
    tableMarginNone: {
        padding: 0,
    },
    tableCellAction: {
        textAlign: "right",
        width: 120,
        padding: theme.spacing(0.5, 0.5, 0.5, 2),
    },
    paginationToolbar: {},
    paginationSelect: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
}));

interface OwnProps<T extends {} = any> {
    className?: string;
    columns: TableColumnProps<T>[];
    defaultSort?: string;
    data: T[];
    showPaginator?: boolean;
    actions?: ActionMenuItemProps<T>[];
    actionsText?: any;
    rowsPerPage?: 10 | 20 | 50 | 100 | 200 | 500;
    showRowsPerPageOptions?: boolean;
    margin?: "default" | "none" | "trackers-list";
    forceDotMenu?: boolean;
    handlePageChange?: (page: number) => void;
    handlePerPageChange?: (perPage: number) => void;
    initialPage?: number;
}

const CustomTable: FC<OwnProps> = ({
    className,
    columns,
    defaultSort,
    data,
    showPaginator,
    actions,
    actionsText,
    rowsPerPage,
    showRowsPerPageOptions,
    margin = "default",
    forceDotMenu,
    handlePageChange,
    handlePerPageChange,
    initialPage,
}): ReactElement => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState(defaultSort);
    const [page, setPage] = useState<number>(initialPage ? initialPage - 1 : 0);
    const [numberOfRowsPerPage, setRowsPerPage] = useState<number>(rowsPerPage || 50);

    const getColumn = key => columns.find(col => col.key === key);

    const createSortHandler = (property: string) => () => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
        if (handlePageChange) {
            handlePageChange(newPage + 1);
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        const perPage: number = parseInt(event.target.value, 10);
        setPage(Math.min(page, Math.floor(data.length / perPage)));
        setRowsPerPage(perPage);
        if (handlePerPageChange) {
            handlePerPageChange(perPage);
        }
    };

    function getSorting<T>(order: Order, orderBy: any): SortCallback<T> {
        let callback: SortCallback<T> = () => 0;
        if (orderBy === undefined) {
            return callback;
        }

        const col = getColumn(orderBy);
        if (!col) {
            return callback;
        }

        if (col.sort) {
            callback = sortKey<T>(orderBy, sortCallbacks[col.sort]);
        }

        if (col.sortCallback) {
            callback = col.sortCallback;
        }

        return order === "asc" ? callback : (a: T, b: T) => -callback(a, b);
    }

    const sorted = stableSort(data, getSorting(order, orderBy));
    let currentPage = 0;
    if (data.length > 0)
        currentPage = Math.min(page, Math.ceil(data.length / numberOfRowsPerPage) - 1);
    const slice = showPaginator
        ? sorted.slice(
              currentPage * numberOfRowsPerPage,
              currentPage * numberOfRowsPerPage + numberOfRowsPerPage,
          )
        : sorted;

    return (
        <div
            className={clsx(
                classes.tableContainer,
                margin === "default" && classes.tableMarginDefault,
                margin === "none" && classes.tableMarginNone,
                margin === "trackers-list" && classes.tableMarginTrackersList,
            )}
        >
            <Table className={className}>
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell
                                key={column.key}
                                sortDirection={orderBy === column.key ? order : false}
                                align={column.align}
                            >
                                {column.sort || column.sortCallback ? (
                                    <TableSortLabel
                                        active={orderBy === column.key}
                                        direction={order}
                                        onClick={createSortHandler(column.key)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                ) : (
                                    column.label
                                )}
                            </TableCell>
                        ))}
                        {!!actions && <TableCell>&nbsp;</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {slice.map((row, index) => {
                        return (
                            <TableRow key={index} hover>
                                {columns.map(column => {
                                    return (
                                        <TableCell
                                            key={column.key}
                                            align={column.align}
                                            size={column.size}
                                            className={column.className}
                                        >
                                            {column.cellDataGetter
                                                ? column.cellDataGetter({
                                                      dataKey: column.dataKey || column.key,
                                                      columnData: column.columnData,
                                                      rowData: row,
                                                  })
                                                : row[column.dataKey || column.key]}
                                        </TableCell>
                                    );
                                })}
                                {!!actions && (
                                    <TableCell className={classes.tableCellAction}>
                                        {actionsText && actionsText(row) !== null ? (
                                            actionsText(row)
                                        ) : (
                                            <ActionsTableButton
                                                actions={actions}
                                                rowData={row}
                                                forceDotMenu={forceDotMenu}
                                            />
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {showPaginator && (
                <TablePagination
                    component="div"
                    count={data.length}
                    rowsPerPageOptions={
                        showRowsPerPageOptions === false ? [] : [10, 20, 50, 100, 200, 500]
                    }
                    rowsPerPage={numberOfRowsPerPage}
                    page={currentPage}
                    backIconButtonProps={{
                        "aria-label": "Previous Page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "Next Page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        toolbar: classes.paginationToolbar,
                        select: classes.paginationSelect,
                    }}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} of ${count.toLocaleString()}`
                    }
                />
            )}
        </div>
    );
};

export default CustomTable;
