import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TrustUserModel } from "../models/TrustUserModel";

export const TRUST_USER_DETIALS_QUERY = "trustUserDetailsQuery";

export const useTrustUserDetails = (trustId: number, trustUserId: number) => {
    return useQuery({
        queryKey: [TRUST_USER_DETIALS_QUERY, trustId, trustUserId],
        queryFn: async (): Promise<TrustUserModel | null> => {
            if (trustId && trustUserId) {
                const { data } = await axiosInstance.get(`trust/${trustId}/user/${trustUserId}/`);

                return data || null;
            }
            return null;
        },
    });
};
