/* eslint-disable react/display-name */
import { ColDef, GridApi } from "ag-grid-community";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { usePrevious } from "src/hooks/usePrevious";
import { NAV_KEYS } from "src/modules/tracker/services/gridNavigation";
// import { NAV_KEYS } from "src/modules/tracker/services/gridNavigation";

interface OwnProps {
    value: number | string | { value: any };
    column: { colDef: ColDef; colId };
    allowAbsent: boolean;
    allowX?: boolean;
    api?: GridApi;
    data: any;
    context: any;
    charPress: any;
    columnType;
}

const DefaultValueEditor = forwardRef(
    (
        {
            value: propsValue,
            column,
            allowAbsent,
            api: gridApi,
            context,
            allowX,
            columnType,
        }: OwnProps,
        ref,
    ) => {
        const { isBlocked, isGradeVBoundaryProcessing } = context;
        const isAtl = columnType === "custom-field";

        const [value, setValue] = useState(
            typeof propsValue === "object" && propsValue !== null ? propsValue.value : propsValue,
        );

        const prevValue = usePrevious(value);
        const refInput = useRef(null) as any;

        useEffect(() => {
            if (gridApi && (isBlocked || isGradeVBoundaryProcessing)) {
                gridApi.stopEditing(true);
            }
        }, [isBlocked, isGradeVBoundaryProcessing, gridApi]);

        useEffect(() => {
            refInput.current.focus();
            refInput.current.select();
            //eslint-disable-next-line
        }, []);

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    if (prevValue !== undefined && prevValue !== value) {
                        return { value, whileUpdate: true };
                    }
                    return value;
                },
            };
        });

        const getAbsenceValue = newValue => {
            return value === null && newValue === ""
                ? null
                : newValue === "X" || newValue === "x"
                  ? "-2"
                  : "-3";
        };

        const handleOnChange = (e: any) => {
            const newValue = e.target.value;

            if (!isNaN(newValue)) {
                const headerParams = column.colDef.headerComponentParams;

                const { maxValue, columnType } = headerParams;

                if (
                    (columnType === "mark" || columnType === "custom-field") &&
                    parseInt(`${newValue}`) > parseInt(`${maxValue}`)
                ) {
                    setValue(maxValue);
                } else {
                    setValue(newValue);
                }
                if (parseInt(`${newValue}`) === 0 && columnType === "custom-field") {
                    setValue(null);
                }
                if (newValue === " ") {
                    setValue(null);
                }
            } else if ((isAtl || allowX) && ["", "x", "X", "a", "A"].includes(newValue)) {
                setValue(getAbsenceValue(newValue));
            } else if (
                isNaN(newValue) &&
                allowAbsent &&
                ["", "a", "A", "u", "U", "x", "X"].includes(newValue)
            ) {
                setValue(value === null && newValue === "" ? null : newValue);
            }
        };

        return (
            <div className="ag-editor-cell">
                <input
                    className="ag-editor-cell__input"
                    onChange={handleOnChange}
                    onKeyDown={e => {
                        if (NAV_KEYS.includes(e.key)) {
                            e.preventDefault();
                            gridApi?.stopEditing();
                        }
                    }}
                    ref={refInput}
                    value={`${value}` === "-2" ? "X" : `${value}` === "-3" ? "A" : value}
                    type="text"
                />
            </div>
        );
    },
);

export default DefaultValueEditor;
