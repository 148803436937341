import { Dispatch } from "redux";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ApiData } from "src/api/constants";
import { AppState } from "src/store/reducers";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";

const dispatchActions = (dispatch: Dispatch) => ({
    getForecastClassTrackerValues: (classTrackerId: number, tier: TiersTypes) => {
        dispatch(TrackerActions.getForecastClassTrackerValues(classTrackerId, tier));
    },
    getForecastClassTrackerGroupValues: (classTrackerGroupId: number, tier: TiersTypes) => {
        dispatch(TrackerActions.getForecastClassTrackerGroupValues(classTrackerGroupId, tier));
    },
});

export const useForecast = (): {
    getForecastClassTrackerValues: (classTrackerId: number, tier: TiersTypes) => void;
    getForecastClassTrackerGroupValues: (classTrackerGroupId: number, tier: TiersTypes) => void;
    apiTrackerValues: ApiData;
    apiTrackerGroupValues: ApiData;
    apiTrackerClearValues: ApiData;
    apiTrackerLoadValuesCheck: ApiData;
    apiTrackerLoadValuesForce: ApiData;
} => {
    const dispatch = useDispatch();

    const {
        apiTrackerValues,
        apiTrackerGroupValues,
        apiTrackerClearValues,
        apiTrackerLoadValuesCheck,
        apiTrackerLoadValuesForce,
    }: {
        apiTrackerValues: ApiData;
        apiTrackerGroupValues: ApiData;
        apiTrackerClearValues: ApiData;
        apiTrackerLoadValuesCheck: ApiData;
        apiTrackerLoadValuesForce: ApiData;
    } = useSelector(
        (state: AppState) => ({
            apiTrackerValues: state.api.tracker.getForecastClassTrackerValues,
            apiTrackerGroupValues: state.api.tracker.getForecastClassTrackerGroupValues,
            apiTrackerClearValues: state.api.tracker.clearForecastClassTrackerValues,
            apiTrackerLoadValuesCheck: state.api.tracker.loadForecastClassTrackerCheck,
            apiTrackerLoadValuesForce: state.api.tracker.loadForecastClassTrackerForce,
        }),
        shallowEqual,
    );

    const { getForecastClassTrackerValues, getForecastClassTrackerGroupValues } =
        dispatchActions(dispatch);

    return {
        getForecastClassTrackerValues,
        getForecastClassTrackerGroupValues,
        apiTrackerValues,
        apiTrackerGroupValues,
        apiTrackerClearValues,
        apiTrackerLoadValuesCheck,
        apiTrackerLoadValuesForce,
    };
};
