import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";

export const EMAIL_QUERY = "emailQuery";

export interface EmailResponse {
    emails: {
        id: number;
        email: string;
        isPrimary: boolean;
        confirmed: boolean;
    }[];
    mergeEmails: {
        id: number;
        email: string;
    }[];
}

export const useEmail = () => {
    return useQuery({
        queryKey: [EMAIL_QUERY],
        queryFn: async (): Promise<EmailResponse | null> => {
            const { data } = await axiosInstance.get(`user/email/`);
            return data || null;
        },
    });
};
