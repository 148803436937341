import CustomTable from "src/components/CustomTable";
import PromptDialog from "src/forms/PromptDialog";
import { useState } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ROUTE_REPORT_LINKS_EDIT } from "src/routes";
import { TFunction } from "i18next";
import { CellDataGetterParams } from "src/components/CustomTable/types";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { LinkModel } from "../model/LinkModel";
import { useRemoveLinkMutation } from "../hooks/useRemoveMutation";
import { LinksQuickEditOptions, useLinksQuickEditMutation } from "../hooks/useQuickEditMutation";
import Icon from "src/components/Icon";
import { mdiArrowDown, mdiArrowUp, mdiCheckCircleOutline, mdiCloseCircleOutline } from "@mdi/js";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const useStyles = makeStyles(() => ({
    tableCellTitle: {
        width: 400,
    },
    tableCellIsActive: {
        width: 120,
    },
    tableCellStudents: {
        width: 120,
    },
}));

interface OwnProps {
    linksList: LinkModel[];
    canEdit?: boolean;
    refetchLinks: () => void;
}

const LinksTable = ({ linksList, canEdit, refetchLinks }: OwnProps) => {
    const { t }: { t: TFunction } = useTranslation();
    const classes = useStyles();
    const { attachSchoolId } = useSchoolNavigate();
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);

    const { mutate: removeLink } = useRemoveLinkMutation(() => refetchLinks());
    const { mutate: quickEdit } = useLinksQuickEditMutation(() => refetchLinks());

    const handleRemoveDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            removeLink({ id: removeId });
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };
    const maxPosition = Math.max(...linksList.map(({ position }) => position));
    const minPosition = Math.min(...linksList.map(({ position }) => position));
    return (
        <Box className="classTrackingTable">
            <CustomTable
                className="classTracker"
                data={linksList.sort((a, b) => a.position - b.position)}
                showPaginator
                showRowsPerPageOptions={false}
                margin="trackers-list"
                columns={[
                    {
                        key: "title",
                        label: t("link.list.table.title"),
                        className: classes.tableCellTitle,
                    },
                    {
                        key: "active",
                        label: t("link.list.table.isActive.label"),
                        cellDataGetter: ({ rowData }: CellDataGetterParams<LinkModel>) =>
                            rowData.active
                                ? t("link.list.table.isActive.true")
                                : t("link.list.table.isActive.false"),
                        className: classes.tableCellIsActive,
                    },
                    {
                        key: "numberOfStudents",
                        label: t("link.list.table.students"),
                        className: classes.tableCellStudents,
                    },
                    {
                        key: "url",
                        label: t("link.list.table.url"),
                        cellDataGetter: ({ rowData }: CellDataGetterParams<LinkModel>) => (
                            <a href={rowData.url} target={"blank"}>
                                {rowData.url}
                            </a>
                        ),
                    },
                ]}
                actions={[
                    getMenuActionProps("edit", {
                        text: t("link.list.table.editButton"),
                        linkCallback: (rowData: LinkModel) =>
                            attachSchoolId(ROUTE_REPORT_LINKS_EDIT).replace(":id", `${rowData.id}`),
                        showAction: () => canEdit,
                    }),
                    getMenuActionProps("delete", {
                        onAction: (rowData: LinkModel) => {
                            setRemoveDialogOpened(true);
                            setRemoveId(rowData.id);
                        },
                        showAction: () => canEdit,
                    }),
                    getMenuActionProps("active", {
                        icon: <Icon path={mdiCheckCircleOutline} />,
                        text: t("link.list.table.makeActive"),
                        onAction: (rowData: LinkModel) =>
                            quickEdit({
                                id: rowData.id,
                                editOption: LinksQuickEditOptions.SET_ACTIVE,
                            }),
                        showAction: (rowData: LinkModel) => canEdit && !rowData.active,
                    }),
                    getMenuActionProps("inactive", {
                        icon: <Icon path={mdiCloseCircleOutline} />,
                        text: t("link.list.table.makeInactive"),
                        onAction: (rowData: LinkModel) =>
                            quickEdit({
                                id: rowData.id,
                                editOption: LinksQuickEditOptions.SET_DEACTIVATE,
                            }),
                        showAction: (rowData: LinkModel) => canEdit && rowData.active,
                    }),
                    getMenuActionProps("positionUp", {
                        icon: <Icon path={mdiArrowUp} />,
                        text: t("link.list.table.up"),
                        onAction: (rowData: LinkModel) =>
                            quickEdit({
                                id: rowData.id,
                                editOption: LinksQuickEditOptions.POSITION_UP,
                            }),
                        showAction: (rowData: LinkModel) =>
                            canEdit && rowData.position !== minPosition,
                    }),
                    getMenuActionProps("positionDown", {
                        icon: <Icon path={mdiArrowDown} />,
                        text: t("link.list.table.down"),
                        onAction: (rowData: LinkModel) =>
                            quickEdit({
                                id: rowData.id,
                                editOption: LinksQuickEditOptions.POSITION_DOWN,
                            }),
                        showAction: (rowData: LinkModel) =>
                            canEdit && rowData.position !== maxPosition,
                    }),
                ]}
            />
            <PromptDialog open={removeDialogOpened} onClose={handleRemoveDialogClose}>
                {t("link.addEdit.removeLink")}
            </PromptDialog>
        </Box>
    );
};

export default LinksTable;
