import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";
import { StaffUserModel } from "src/modules/school/models/StaffUserModel";

export type StaffListResponse = StaffUserModel[];

export const SCHOOL_STAFF_LIST_QUERY = "schoolStaffListQuery";

export const useStaffList = () => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [SCHOOL_STAFF_LIST_QUERY, schoolAccountId],
        queryFn: async (): Promise<StaffListResponse | null> => {
            const { data } = await axiosInstance.get(`school/${schoolAccountId}/staff/`);
            return data || null;
        },
    });
};
