import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mdiDownload } from "@mdi/js";
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import clsx from "clsx";
import { Fragment, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import Icon from "src/components/Icon";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import COLORS from "src/styles/colors";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";
import { ViewGrades } from "../../hooks/GradeSumary/useAnalysisGradeSummaryValues";

import {
    CustomTableCell,
    CustomTableRow,
    VerticalCustomTableCell,
} from "../../containers/SubjectAnalysisContainer";
import { exportSubjectAnalysisSubgroupToExcel } from "../../services/analysisDataExport";
import { useSubjectAnalysisStudentData } from "../../hooks/SubjectAnalysis/useSubjectAnalysisStudentData";
import { useSubjectAnalysisStudentSnapshotData } from "../../hooks/SubjectAnalysis/useSubjectAnalysisStudentSnapshotData";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(0, 0, 3),
    },
    container: {
        display: "flex",
        flex: 1,
        width: "100%",
        borderTop: `1px solid ${COLORS.LIGHT_GREY_2}`,
        overflow: "hidden",
        backgroundColor: COLORS.WHITE,
    },
    content: {
        flex: 1,
        overflowY: "auto",
        position: "relative",
    },
    inner: {
        padding: theme.spacing(6, 10, 6, 4),
        [theme.breakpoints.up(1400)]: {
            padding: theme.spacing(8, 27.5, 8, 12.5),
        },
    },
    innerScroll: {
        overflowX: "auto",
    },
    innerBigRight: {
        padding: theme.spacing(8, 27.5, 8, 12.5),
    },
    innerBorderBottom: {
        borderBottom: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
    },
    innerBorderLeft: {
        borderLeft: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
        height: "100%",
    },
    header: {
        display: "flex",
        alignItems: "flex-start",
    },
    subjectMeta: {
        display: "flex",
        padding: theme.spacing(3, 4),
        borderRadius: 2,
        backgroundColor: COLORS.VERY_LIGHT_GREY_1,
        margin: theme.spacing(0, 7, 0, 0),
    },
    subjectMetaItem: {
        display: "flex",
        flexDirection: "column",

        "& + &": {
            borderLeft: `1px solid ${COLORS.LIGHT_GREY_2}`,
            padding: theme.spacing(0, 0, 0, 5),
            margin: theme.spacing(0, 0, 0, 5),
        },
    },
    subjectMetaBadge: {
        margin: theme.spacing(0.75, 0, 0),
    },
    subjectMetaLabel: {
        color: COLORS.GREY_3,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: 400,
        fontFamily: PRIMARY_FONT_FAMILY,
    },
    subjectMetaText: {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        margin: theme.spacing(0.25, 0, 0),
    },
    tableVerticalBottom: {
        lineHeight: 1,
        verticalAlign: "bottom",
    },
    tableBorderLeft: {
        borderLeft: `1px solid ${COLORS.LIGHT_GREY_2}`,
    },
    tableBackground: {
        backgroundColor: "rgba(232, 233, 235, 0.2)",
    },
    tableColorOpacity: {
        color: "#a5acb1 !important",
    },
}));

const SubjectAnalysisSubGroup = ({
    cohort,
    yearGroup,
    specification,
    viewGrade,
    dataType,
    snapshot,
    classTrackers,
}: {
    cohort: number;
    yearGroup: number;
    specification: number | null;
    viewGrade: ViewGrades;
    dataType: DataTypeTypes;
    snapshot?: number;
    classTrackers?: number[];
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { data: subjectAnalysisStudentBasicData, refetch: getSubjectAnalysisStudentData } =
        useSubjectAnalysisStudentData(cohort, yearGroup, specification, classTrackers);
    const {
        data: subjectAnalysisStudentsSnapshotData,
        refetch: getSubjectAnalysisStudentSnapshotData,
    } = useSubjectAnalysisStudentSnapshotData(
        cohort,
        yearGroup,
        specification,
        snapshot,
        classTrackers,
    );
    const subjectAnalysisStudentData =
        dataType === DataTypeTypes.SNAPSHOT
            ? subjectAnalysisStudentsSnapshotData
            : subjectAnalysisStudentBasicData;

    const gridApi = useRef() as unknown as { current: GridApi };

    const hasA8Score = !!(
        subjectAnalysisStudentData?.length > 0 &&
        subjectAnalysisStudentData[0].avgCurrentA8 !== undefined &&
        subjectAnalysisStudentData[0].avgCurrentA8 !== null
    );
    const hasP8Score = !!(
        subjectAnalysisStudentData?.length > 0 &&
        subjectAnalysisStudentData[0].avgCurrentP8 !== undefined &&
        subjectAnalysisStudentData[0].avgCurrentP8 !== null
    );

    const onGridReady = params => {
        gridApi.current = params.api;
    };

    useEffect(() => {
        if (dataType === DataTypeTypes.LIVE && specification) {
            getSubjectAnalysisStudentData();
        }
        if (dataType === DataTypeTypes.SNAPSHOT && snapshot && specification) {
            getSubjectAnalysisStudentSnapshotData();
        }
    }, [cohort, yearGroup, specification, viewGrade, dataType, snapshot, classTrackers]);

    const columnDefs = [
        {
            field: "attributeName",
            headerName: t("analysis.subjectAnalysis.subgroup"),
        },
        {
            field: "attributeValue",
            headerName: "",
        },
        {
            field: "numberStud",
            headerName: "",
            children: [
                {
                    field: "numberOfStudents",
                    headerName: t("analysis.subjectAnalysis.numberOfStudents"),
                },
            ],
        },
        {
            field: "a8Score",
            headerName: t("analysis.subjectAnalysis.a8Score"),
            children: [
                {
                    field:
                        viewGrade === ViewGrades.CURRENT
                            ? "avgCurrentA8"
                            : viewGrade === ViewGrades.PROJECTED
                              ? "avgProjectedA8"
                              : "avgTeacherA8",
                    headerName: t("analysis.subjectAnalysis.averageUnitGrade"),
                },
                {
                    field: "avgTargetA8",
                    headerName: t("analysis.subjectAnalysis.minimumTargetGrade"),
                },
            ],
        },
        {
            field: "p8Score",
            headerName: t("analysis.subjectAnalysis.p8Score"),
            children: [
                {
                    field:
                        viewGrade === ViewGrades.CURRENT
                            ? "avgCurrentP8"
                            : viewGrade === ViewGrades.PROJECTED
                              ? "avgProjectedP8"
                              : "avgTeacherP8",
                    headerName: t("analysis.subjectAnalysis.averageUnitGrade"),
                },
                {
                    field: "avgTargetP8",
                    headerName: t("analysis.subjectAnalysis.minimumTargetGrade"),
                },
            ],
        },
        {
            field:
                viewGrade === ViewGrades.CURRENT
                    ? "numberCurrentAboveTarget"
                    : viewGrade === ViewGrades.PROJECTED
                      ? "numberProjectedAboveTarget"
                      : "numberTeacherAboveTarget",
            headerName: "% " + t("analysis.subjectAnalysis.aboveTarget"),
        },
        {
            field:
                viewGrade === ViewGrades.CURRENT
                    ? "numberCurrentOnTarget"
                    : viewGrade === ViewGrades.PROJECTED
                      ? "numberProjectedOnTarget"
                      : "numberTeacherOnTarget",
            headerName: "% " + t("analysis.subjectAnalysis.onTarget"),
        },
        {
            field:
                viewGrade === ViewGrades.CURRENT
                    ? "numberCurrenOneGradeAway"
                    : viewGrade === ViewGrades.PROJECTED
                      ? "numberProjectedOneGradeAway"
                      : "numberTeacherOneGradeAway",
            headerName: "% " + t("analysis.subjectAnalysis.oneGradeAway"),
        },
        {
            field:
                viewGrade === ViewGrades.CURRENT
                    ? "numberCurrentOffTarget"
                    : viewGrade === ViewGrades.PROJECTED
                      ? "numberProjectedOffTarget"
                      : "numberTeacherOffTarget",
            headerName: "% " + t("analysis.subjectAnalysis.offTarget"),
        },
        {
            field: "numberTargetNotSet",
            headerName: "% " + t("analysis.subjectAnalysis.numberTargetNotSet"),
        },
    ];

    const rowSpans = {};

    if (subjectAnalysisStudentData) {
        subjectAnalysisStudentData.forEach(sas => {
            if (sas.numberOfStudents > 0) {
                if (rowSpans[sas.attributeName]) {
                    rowSpans[sas.attributeName] = rowSpans[sas.attributeName] + 1;
                } else {
                    rowSpans[sas.attributeName] = 1;
                }
            }

            // rowSpans[sas.attributeName] = rowSpans[sas.attributeName]
            //     ? sas.numberOfStudents > 0
            //         ? rowSpans[sas.attributeName] + 1
            //         : rowSpans[sas.attributeName]
            //     : 1;
        });
    }
    const currentAttriibuteCount = {};
    let oddRow = false;

    const getTableGradeLabel = () => {
        return viewGrade === ViewGrades.CURRENT ? (
            <Trans i18nKey="analysis.subjectAnalysis.currentUnitGrade">
                Current Unit <br />
                Grade
            </Trans>
        ) : viewGrade === ViewGrades.PROJECTED ? (
            <Trans i18nKey="analysis.subjectAnalysis.averageUnitGrade">
                Average Unit <br />
                Grade
            </Trans>
        ) : (
            <Trans i18nKey="analysis.subjectAnalysis.teacherUnitGrade">
                Teacher Predicted <br />
                Grade
            </Trans>
        );
    };
    return (
        <Box className={classes.inner}>
            <Typography variant="h6">{t("analysis.subjectAnalysis.subgroupAnalysis")}</Typography>
            <AgGridReact
                onGridReady={onGridReady}
                columnDefs={columnDefs as any[]}
                rowData={subjectAnalysisStudentData || []}
                gridOptions={{ domLayout: "print" }}
                containerStyle={{ display: "none" }}
            />

            <Box className={classes.innerScroll}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell size="small" />
                            <CustomTableCell size="small" colSpan={2} />
                            {hasA8Score && (
                                <CustomTableCell size="small" colSpan={2}>
                                    {t("analysis.subjectAnalysis.a8Score")}
                                </CustomTableCell>
                            )}
                            {hasP8Score && (
                                <CustomTableCell size="small" colSpan={2}>
                                    {t("analysis.subjectAnalysis.p8Score")}
                                </CustomTableCell>
                            )}
                            <CustomTableCell size="small" />
                            <CustomTableCell size="small" />
                            <CustomTableCell size="small" />
                            <CustomTableCell size="small" />
                            <CustomTableCell size="small" />
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tableVerticalBottom} colSpan={2}>
                                {t("analysis.subjectAnalysis.subgroup")}
                            </TableCell>
                            <VerticalCustomTableCell
                                className={clsx(classes.tableBackground, classes.tableBorderLeft)}
                            >
                                <span>{t("analysis.subjectAnalysis.numberOfStudents")}</span>
                            </VerticalCustomTableCell>
                            {hasA8Score && (
                                <Fragment>
                                    <VerticalCustomTableCell
                                        className={clsx(
                                            classes.tableBackground,
                                            classes.tableBorderLeft,
                                        )}
                                    >
                                        <span>{getTableGradeLabel()}</span>
                                    </VerticalCustomTableCell>
                                    <VerticalCustomTableCell className={classes.tableBackground}>
                                        <span>
                                            <Trans i18nKey="analysis.subjectAnalysis.minimumTargetGrade">
                                                Minimum Target <br />
                                                Grade
                                            </Trans>
                                        </span>
                                    </VerticalCustomTableCell>
                                </Fragment>
                            )}
                            {hasP8Score && (
                                <Fragment>
                                    <VerticalCustomTableCell className={classes.tableBorderLeft}>
                                        <span>{getTableGradeLabel()}</span>
                                    </VerticalCustomTableCell>
                                    <VerticalCustomTableCell>
                                        <span>
                                            <Trans i18nKey="analysis.subjectAnalysis.minimumTargetGrade">
                                                Minimum Target <br />
                                                Grade
                                            </Trans>
                                        </span>
                                    </VerticalCustomTableCell>
                                </Fragment>
                            )}
                            <VerticalCustomTableCell className={classes.tableBorderLeft}>
                                <Box component="span" color={COLORS.BLUE_4}>
                                    % {t("analysis.subjectAnalysis.aboveTarget")}
                                </Box>
                            </VerticalCustomTableCell>
                            <VerticalCustomTableCell>
                                <Box component="span" color={COLORS.GREEN_2}>
                                    % {t("analysis.subjectAnalysis.onTarget")}
                                </Box>
                            </VerticalCustomTableCell>
                            <VerticalCustomTableCell>
                                <Box component="span" color={COLORS.ORANGE_1}>
                                    % {t("analysis.subjectAnalysis.oneGradeAway")}
                                </Box>
                            </VerticalCustomTableCell>
                            <VerticalCustomTableCell>
                                <Box component="span" color={COLORS.RED_2}>
                                    % {t("analysis.subjectAnalysis.offTarget")}
                                </Box>
                            </VerticalCustomTableCell>
                            <VerticalCustomTableCell>
                                <Box component="span" color={COLORS.GREY_1}>
                                    % {t("analysis.subjectAnalysis.numberTargetNotSet")}
                                </Box>
                            </VerticalCustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subjectAnalysisStudentData &&
                            subjectAnalysisStudentData
                                .filter(data => data.numberOfStudents > 0)
                                .map((dataRow, index) => {
                                    currentAttriibuteCount[dataRow.attributeName] =
                                        currentAttriibuteCount[dataRow.attributeName] + 1 || 1;

                                    if (currentAttriibuteCount[dataRow.attributeName] === 1)
                                        oddRow = !oddRow;

                                    const row = (
                                        <Fragment>
                                            {currentAttriibuteCount[dataRow.attributeName] ===
                                                1 && (
                                                <CustomTableCell
                                                    variant={index === 0 ? "head" : "body"}
                                                    align={index === 0 ? undefined : "right"}
                                                    colSpan={index === 0 ? 2 : 1}
                                                    rowSpan={rowSpans[dataRow.attributeName] || 1}
                                                >
                                                    {dataRow.attributeName}
                                                </CustomTableCell>
                                            )}
                                            {index > 0 && (
                                                <CustomTableCell
                                                    padding="none"
                                                    className={
                                                        currentAttriibuteCount[
                                                            dataRow.attributeName
                                                        ] === rowSpans[dataRow.attributeName]
                                                            ? "last"
                                                            : undefined
                                                    }
                                                >
                                                    {dataRow.attributeValue}
                                                </CustomTableCell>
                                            )}
                                            <CustomTableCell
                                                variant="body"
                                                className={clsx(
                                                    classes.tableBorderLeft,
                                                    dataRow.numberOfStudents === 0 &&
                                                        classes.tableColorOpacity,
                                                )}
                                            >
                                                {dataRow.numberOfStudents}
                                            </CustomTableCell>
                                            {hasA8Score && (
                                                <Fragment>
                                                    <CustomTableCell
                                                        variant={index === 0 ? "head" : "body"}
                                                        className={clsx(
                                                            classes.tableBackground,
                                                            classes.tableBorderLeft,
                                                            viewGrade === ViewGrades.CURRENT
                                                                ? dataRow.avgCurrentA8
                                                                : viewGrade === ViewGrades.PROJECTED
                                                                  ? dataRow.avgProjectedA8
                                                                  : dataRow.avgTeacherA8 === 0 &&
                                                                    index > 0 &&
                                                                    classes.tableColorOpacity,
                                                        )}
                                                    >
                                                        {viewGrade === ViewGrades.CURRENT
                                                            ? dataRow.avgCurrentA8
                                                            : viewGrade === ViewGrades.PROJECTED
                                                              ? dataRow.avgProjectedA8
                                                              : dataRow.avgTeacherA8}
                                                    </CustomTableCell>
                                                    <CustomTableCell
                                                        variant={index === 0 ? "head" : "body"}
                                                        className={clsx(
                                                            classes.tableBackground,
                                                            dataRow.avgTargetA8 === 0 &&
                                                                index > 0 &&
                                                                classes.tableColorOpacity,
                                                        )}
                                                    >
                                                        {dataRow.avgTargetA8}
                                                    </CustomTableCell>
                                                </Fragment>
                                            )}
                                            {hasP8Score && (
                                                <Fragment>
                                                    <CustomTableCell
                                                        variant={index === 0 ? "head" : "body"}
                                                        className={clsx(
                                                            classes.tableBorderLeft,
                                                            viewGrade === ViewGrades.CURRENT
                                                                ? dataRow.avgCurrentP8
                                                                : viewGrade === ViewGrades.PROJECTED
                                                                  ? dataRow.avgProjectedP8
                                                                  : dataRow.avgTeacherP8 === 0 &&
                                                                    index > 0 &&
                                                                    classes.tableColorOpacity,
                                                        )}
                                                    >
                                                        {viewGrade === ViewGrades.CURRENT
                                                            ? dataRow.avgCurrentP8
                                                            : viewGrade === ViewGrades.PROJECTED
                                                              ? dataRow.avgProjectedP8
                                                              : dataRow.avgTeacherP8}
                                                    </CustomTableCell>
                                                    <CustomTableCell
                                                        variant={index === 0 ? "head" : "body"}
                                                        className={clsx(
                                                            dataRow.avgTargetP8 === 0 && index > 0
                                                                ? classes.tableColorOpacity
                                                                : undefined,
                                                        )}
                                                    >
                                                        {dataRow.avgTargetP8}
                                                    </CustomTableCell>
                                                </Fragment>
                                            )}
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    classes.tableBorderLeft,
                                                    Math.round(
                                                        ((viewGrade === ViewGrades.CURRENT
                                                            ? dataRow.numberCurrentAboveTarget
                                                            : viewGrade === ViewGrades.PROJECTED
                                                              ? dataRow.numberProjectedAboveTarget
                                                              : dataRow.numberTeacherAboveTarget) /
                                                            dataRow.numberOfStudents) *
                                                            100,
                                                    ) === 0 && index > 0
                                                        ? classes.tableColorOpacity
                                                        : undefined,
                                                )}
                                            >
                                                {(Math.round(
                                                    ((viewGrade === ViewGrades.CURRENT
                                                        ? dataRow.numberCurrentAboveTarget
                                                        : viewGrade === ViewGrades.PROJECTED
                                                          ? dataRow.numberProjectedAboveTarget
                                                          : dataRow.numberTeacherAboveTarget) /
                                                        dataRow.numberOfStudents) *
                                                        100,
                                                ) || 0) + "%"}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    Math.round(
                                                        ((viewGrade === ViewGrades.CURRENT
                                                            ? dataRow.numberCurrentOnTarget
                                                            : viewGrade === ViewGrades.PROJECTED
                                                              ? dataRow.numberProjectedOnTarget
                                                              : dataRow.numberTeacherOnTarget) /
                                                            dataRow.numberOfStudents) *
                                                            100,
                                                    ) === 0 && index > 0
                                                        ? classes.tableColorOpacity
                                                        : undefined,
                                                )}
                                            >
                                                {(Math.round(
                                                    ((viewGrade === ViewGrades.CURRENT
                                                        ? dataRow.numberCurrentOnTarget
                                                        : viewGrade === ViewGrades.PROJECTED
                                                          ? dataRow.numberProjectedOnTarget
                                                          : dataRow.numberTeacherOnTarget) /
                                                        dataRow.numberOfStudents) *
                                                        100,
                                                ) || 0) + "%"}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    Math.round(
                                                        ((viewGrade === ViewGrades.CURRENT
                                                            ? dataRow.numberCurrenOneGradeAway
                                                            : viewGrade === ViewGrades.PROJECTED
                                                              ? dataRow.numberProjectedOneGradeAway
                                                              : dataRow.numberTeacherOneGradeAway) /
                                                            dataRow.numberOfStudents) *
                                                            100,
                                                    ) === 0 && index > 0
                                                        ? classes.tableColorOpacity
                                                        : undefined,
                                                )}
                                            >
                                                {(Math.round(
                                                    ((viewGrade === ViewGrades.CURRENT
                                                        ? dataRow.numberCurrenOneGradeAway
                                                        : viewGrade === ViewGrades.PROJECTED
                                                          ? dataRow.numberProjectedOneGradeAway
                                                          : dataRow.numberTeacherOneGradeAway) /
                                                        dataRow.numberOfStudents) *
                                                        100,
                                                ) || 0) + "%"}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    Math.round(
                                                        ((viewGrade === ViewGrades.CURRENT
                                                            ? dataRow.numberCurrentOffTarget
                                                            : viewGrade === ViewGrades.PROJECTED
                                                              ? dataRow.numberProjectedOffTarget
                                                              : dataRow.numberTeacherOffTarget) /
                                                            dataRow.numberOfStudents) *
                                                            100,
                                                    ) === 0 && index > 0
                                                        ? classes.tableColorOpacity
                                                        : undefined,
                                                )}
                                            >
                                                {(Math.round(
                                                    ((viewGrade === ViewGrades.CURRENT
                                                        ? dataRow.numberCurrentOffTarget
                                                        : viewGrade === ViewGrades.PROJECTED
                                                          ? dataRow.numberProjectedOffTarget
                                                          : dataRow.numberTeacherOffTarget) /
                                                        dataRow.numberOfStudents) *
                                                        100,
                                                ) || 0) + "%"}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                variant={index === 0 ? "head" : "body"}
                                                className={clsx(
                                                    Math.round(
                                                        (dataRow.numberTargetNotSet /
                                                            dataRow.numberOfStudents) *
                                                            100,
                                                    ) === 0 && index > 0
                                                        ? classes.tableColorOpacity
                                                        : undefined,
                                                )}
                                            >
                                                {(Math.round(
                                                    (dataRow.numberTargetNotSet /
                                                        dataRow.numberOfStudents) *
                                                        100,
                                                ) || 0) + "%"}
                                            </CustomTableCell>
                                        </Fragment>
                                    );

                                    return oddRow ? (
                                        <CustomTableRow key={index}>{row}</CustomTableRow>
                                    ) : (
                                        <TableRow key={index}>{row}</TableRow>
                                    );
                                })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <CustomTableCell colSpan={15}>
                                <Button
                                    variant="text"
                                    onClick={() =>
                                        exportSubjectAnalysisSubgroupToExcel(gridApi.current)
                                    }
                                    startIcon={<Icon path={mdiDownload} />}
                                    disableRipple
                                >
                                    {t("analysis.subjectAnalysis.downloadExcelBtn")}
                                </Button>
                            </CustomTableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Box>
        </Box>
    );
};
export default SubjectAnalysisSubGroup;
