import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { ClassTrackerListResponse } from "src/modules/class/api/Classes/getClassTrackers";
import { OrmClassTracker } from ".";

export enum OrmClassTrackerActionTypes {
    BULK_IMPORT = "[orm] CLASS TRACKER BULK IMPORT",
    UPSERT = "[orm] CLASS TRACKER UPSERT",
    CLEAR = "[orm] CLASS TRACKER LIST CLEAR",
}

export const OrmClassTrackerActions = {
    bulkImport: (payload: ClassTrackerListResponse, cohort?: number) => {
        return createAction(OrmClassTrackerActionTypes.BULK_IMPORT, { data: payload, cohort });
    },
    upsert: (payload: OrmClassTracker) => createAction(OrmClassTrackerActionTypes.UPSERT, payload),
    clear: () => createAction(OrmClassTrackerActionTypes.CLEAR),
};

export type OrmClassTrackerActions = ActionsUnion<typeof OrmClassTrackerActions>;
