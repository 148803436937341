import axios, { HttpStatusCode } from "axios";
import { apiUrl } from "src/config/globals";
import { ROUTE_AUTH_LOGIN, ROUTE_HOME_MAINTENANCE, ROUTE_LOGOUT } from "src/routes";
import { baseHeaders } from "src/services/ajax";

const axiosInstance = axios.create({
    baseURL: apiUrl(""),
    timeout: 60000,
    headers: baseHeaders(),
});

axiosInstance.interceptors.request.use(async (request: any) => {
    (request.headers as any) = baseHeaders();
    return request;
});

axiosInstance.interceptors.request.use(async (request: any) => {
    (request.headers as any) = baseHeaders();

    const params = {};
    if (request.params) {
        Object.keys(request.params).forEach(key => {
            if (request.params[key]) {
                params[key] = request.params[key];
            }
        });
    }

    return {
        ...request,
        params,
    };
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (
            error.response.status === HttpStatusCode.Unauthorized &&
            window?.location?.pathname !== ROUTE_AUTH_LOGIN &&
            window?.location?.pathname !== ROUTE_LOGOUT
        ) {
            window.location.href = ROUTE_LOGOUT;
        }

        if (
            error.response.status === HttpStatusCode.ServiceUnavailable &&
            window?.location?.pathname !== ROUTE_HOME_MAINTENANCE
        ) {
            window.location.href = ROUTE_HOME_MAINTENANCE;
        }

        return Promise.reject(error);
    },
);

export default axiosInstance;
