import ContactConfirmedStep from "../components/Register/ContactConfirmedStep";
import ContactStep from "../components/Register/ContactStep";
import FinishedStep from "../components/Register/FinishedStep";
import AuthContainer from "src/components/AuthContainer";
import { Box, Button, Step, Stepper, Typography, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { steps } from "../components/Register/steps";
import { CustomConnector, CustomStepLabel, CustomStepIcon } from "../components/Common/Stepper";
import { TFunction } from "i18next";
import { useParams } from "react-router";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import * as yup from "yup";
import { useConfirm } from "../hooks/useConfirm";
import {
    EditUserProfileAfterConfirmRequest,
    useEditProfileAfterConfirm,
} from "src/modules/user/hooks/useEditProfileAfterConfirm";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "src/components/hookFormComponents";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontWeight: 600,
    },
    stepper: {
        padding: 0,
        margin: theme.spacing(4.5, -2.5, 8.5),
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(4.5, 4, 8.5),
        },
    },
}));

export const confirmSchema = (t: TFunction) =>
    yup.object().shape({
        phone: yup
            .string()
            .nullable()
            .optional()
            .transform((curr, orig) => (orig === "" ? null : curr))
            .min(9, t("common.form.validation.phone.min"))
            .max(20, t("common.form.validation.phone.max"))
            .matches(/^0?[1-9]\d+([ -]\d+)*$|^$/, t("common.form.validation.phone.match")),
        mobile: yup
            .string()
            .nullable()
            .optional()
            .transform((curr, orig) => (orig === "" ? null : curr))
            .min(9, t("common.form.validation.mobile.min"))
            .max(20, t("common.form.validation.mobile.max"))
            .matches(/^0?[1-9]\d+([ -]\d+)*$|^$/, t("common.form.validation.mobile.match"))
            .matches(/^0?7|^$/, t("common.form.validation.mobile.matchMobile")),
        schoolTitle: yup.string().optional(),
    });

const ConfirmContainer = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(6);
    const [hashSent, setHashSent] = useState<boolean>(false);
    const [hashUsed, setHashUsed] = useState<boolean>(false);
    const params = useParams();
    const { t } = useTranslation();

    const methods = useForm({
        resolver: yupResolver(confirmSchema(t)),
    });
    const {
        formState: { isSubmitting },
        reset,
    } = methods;

    const { mutate: confirm, status: confirmStatus } = useConfirm(() => setHashUsed(true));
    const { mutate: profileUpdate, isError: profileUpdateError } = useEditProfileAfterConfirm(() =>
        setActiveStep(activeStep + 1),
    );
    const { data, refetch } = useCoreValues();

    useEffect(() => {
        if (!data?.staffProperties) {
            refetch();
        }
    }, [data]);

    const schoolTitles = data?.staffProperties?.currentRoles;

    useEffect(() => {
        if (hashSent === false && params.hash && confirmStatus === "idle") {
            setHashSent(true);
            confirm(params.hash);
        }
    }, [confirmStatus, confirm, hashSent, params.hash]);

    useEffect(() => {
        if (profileUpdateError) reset();
    }, [profileUpdateError, reset]);

    const handleSubmit = (values: EditUserProfileAfterConfirmRequest) => {
        if (values.phone || values.mobile || values.schoolTitle) {
            profileUpdate(values);
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 6:
                return (
                    <FormProvider methods={methods} onSubmit={handleSubmit}>
                        <ContactStep schoolTitles={schoolTitles} />
                        <Box
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            justifyContent="flex-end"
                            width={1}
                            mt={5}
                        >
                            <Button type="submit" disabled={isSubmitting} color="primary">
                                {t("common.continue")}
                            </Button>
                        </Box>
                    </FormProvider>
                );
            case 7:
                return <FinishedStep />;
            default:
                return null;
        }
    };

    return (
        <AuthContainer>
            <Typography
                component="h1"
                variant="h1"
                align="center"
                color="secondary"
                className={classes.heading}
            >
                {t("common.trial")}
            </Typography>
            <Stepper
                className={classes.stepper}
                activeStep={activeStep}
                alternativeLabel
                connector={<CustomConnector />}
            >
                {steps(t).map(label => (
                    <Step key={label} className="stepper-item">
                        <CustomStepLabel StepIconComponent={CustomStepIcon}>
                            {label}
                        </CustomStepLabel>
                    </Step>
                ))}
            </Stepper>
            {hashUsed ? <ContactConfirmedStep /> : <Box>{getStepContent(activeStep)}</Box>}
        </AuthContainer>
    );
};

export default ConfirmContainer;
