enum COLORS {
    WHITE = "#fff",
    WHITE_A80 = "rgba(255,255,255,0.8)",
    BLACK = "#000",

    GREY_1 = "#4B4E50", // default color
    GREY_2 = "#76787B",
    GREY_3 = "#8A8B8D",
    GREY_4 = "#626F7A",
    GREY_5 = "#929C9E",

    LIGHT_GREY_1 = "#C2C4C8",
    LIGHT_GREY_2 = "#D7DBE2",
    LIGHT_GREY_3 = "#D8D8D8",
    LIGHT_GREY_4 = "#B8BFC6",

    VERY_LIGHT_GREY_1 = "#F9F9F9", // body background
    VERY_LIGHT_GREY_2 = "#F1F1EF", // footer background
    VERY_LIGHT_GREY_3 = "#E8E9EB",
    VERY_LIGHT_GREY_4 = "#FAFBFC",
    VERY_LIGHT_GREY_5 = "#F2F3F4",
    VERY_LIGHT_GREY_6 = "#FAFBFC",
    VERY_LIGHT_GREY_7 = "#EDF2F7",
    VERY_LIGHT_GREY_8 = "#EAE9F2",

    BLUE_1 = "#5F9BE3",
    BLUE_2 = "#447BBE",
    BLUE_3 = "#7294A6",
    BLUE_4 = "#1D93E3",
    GREEN_1 = "#AFDC42",
    GREEN_2 = "#71AC49",
    YELLOW_1 = "#E6D884",
    YELLOW_2 = "#EACF60",
    YELLOW_3 = "#F3C300",
    ORANGE_1 = "#FAA62F",
    ORANGE_2 = "#E68700",
    CYAN_1 = "#5BD7DC",
    RED_1 = "#E24936",
    RED_2 = "#D92E20",
}

export default COLORS;
