import { Dispatch } from "redux";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useMount } from "../../../hooks/useMount";
import { AppState } from "src/store/reducers";
import { AnalysisActions } from "../store/actions";
import { prepareStudentFilters } from "../services/analysisValueHandler";
import { CohortSnapshotModel } from "src/modules/school/models/CohortSnapshotModel";
import { useCohortSnapshotList } from "src/modules/school/hooks/query/CohortSnapshot/useCohortSnapshotList";

export interface SnapShotData {
    compare: { dataSource1: string; dataSource2: string } | null;
    snapshot: string | null;
}

export interface StudentAttributePair {
    attribute: string;
    attributeDisplay: string;
    value: string;
    valueDisplay: string;
}

const dispatchActions = (dispatch: Dispatch) => ({
    getSnapshotValues: (
        cohort: number,
        yearGroup: number,
        snapshot: number,
        studentFilters: StudentAttributePair[],
    ) => {
        dispatch(
            AnalysisActions.getAnalysisAtlSnapshot(
                cohort,
                yearGroup,
                snapshot,
                prepareStudentFilters(studentFilters),
            ),
        );
    },
    getSnapshotCompareValues: (
        cohort: number,
        yearGroup: number,
        dataSource1: string,
        dataSource2: string,
        studentFilters: StudentAttributePair[],
    ) => {
        dispatch(
            AnalysisActions.getAnalysisAtlSnapshotCompare(
                cohort,
                yearGroup,
                dataSource1,
                dataSource2,
                prepareStudentFilters(studentFilters),
            ),
        );
    },
    setSnapshotData: (snapShotData: SnapShotData) => {
        dispatch(AnalysisActions.setSnapshotData(snapShotData));
    },
});

export const useAnalysisAtlSnapshots = (
    cohortId: number,
): {
    snapshots: CohortSnapshotModel[];
    snapshotData: SnapShotData | null;
    setSnapshotData: (data: SnapShotData | null) => void;
    getSnapshotValues: (
        cohort: number,
        yearGroup: number,
        snapshot: number,
        studentFilters: StudentAttributePair[],
    ) => void;
    getSnapshotCompareValues: (
        cohort: number,
        yearGroup: number,
        dataSource1: string,
        dataSource2: string,
        studentFilters: StudentAttributePair[],
    ) => void;
    refetchSnapshots: () => void;
} => {
    const dispatch = useDispatch();

    const { getSnapshotCompareValues, getSnapshotValues, setSnapshotData } =
        dispatchActions(dispatch);

    const { data: snapshots, refetch: fetchCohortSnapshots } = useCohortSnapshotList(cohortId);

    useMount(() => {
        fetchCohortSnapshots();
    });

    const { snapshotData }: { snapshotData: SnapShotData | null } = useSelector(
        (state: AppState) => ({
            snapshotData: state.analysis.snapshotData,
        }),
        shallowEqual,
    );

    const refetchSnapshots = () => {
        fetchCohortSnapshots();
    };

    return {
        snapshots,
        snapshotData,
        setSnapshotData,
        getSnapshotValues,
        getSnapshotCompareValues,
        refetchSnapshots,
    };
};
