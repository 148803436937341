import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction, paramRequest } from "src/api/helpers";
import { SpecificationParam } from "src/forms/types";
import {
    commonSpecificationAttributesEndpoint,
    CommonSpecificationAttributesResponse,
} from "../../api/specificationAttributes";

export enum CommonSpecificationAttributeActionsTypes {
    GET_SPECIFICATION_ATTRIBUTES = "[common] GET SPECIFICATION ATTRIBUTES",
    GET_SPECIFICATION_ATTRIBUTES_SUCCESS = "[common] GET SPECIFICATION ATTRIBUTES SUCCESS",
}

export const CommonSpecificationAttributeApiEndpoints = {
    getSpecificationAttributes: createActionObject(
        commonSpecificationAttributesEndpoint,
        CommonSpecificationAttributeActionsTypes.GET_SPECIFICATION_ATTRIBUTES_SUCCESS,
    ),
};

export const CommonSpecificationAttributeRawActions = (
    CommonEndpoints: Record<Keys<typeof CommonSpecificationAttributeApiEndpoints>, ApiActionMeta>,
) => ({
    getSpecificationAttributes: (specificationId: number) =>
        createApiAction(
            CommonSpecificationAttributeActionsTypes.GET_SPECIFICATION_ATTRIBUTES,
            paramRequest({ specification: specificationId }),
            CommonEndpoints.getSpecificationAttributes,
        ),
    getSpecificationAttributesSuccess: (
        payload: ApiCallbackPayload<CommonSpecificationAttributesResponse, {}, SpecificationParam>,
    ) =>
        createAction(
            CommonSpecificationAttributeActionsTypes.GET_SPECIFICATION_ATTRIBUTES_SUCCESS,
            payload,
        ),
});
