import {
    ROUTE_MY_SCHOOL_TAGS,
    ROUTE_MY_SCHOOL_TAG_ADD,
    ROUTE_MY_SCHOOL_TAG_EDIT,
} from "src/routes";
import TagListContainer from "../containers/List";
import TagUpsertContainer from "../containers/Upsert";

export const TagRoutes = [
    {
        path: ROUTE_MY_SCHOOL_TAGS,
        element: <TagListContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_TAG_EDIT,
        element: <TagUpsertContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_TAG_ADD,
        element: <TagUpsertContainer />,
    },
];
