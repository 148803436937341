import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import clsx from "clsx";

interface TagProps {
    children: string | number;
    variant?: "contained" | "outlined";
    arrow?: "top" | "right" | "bottom" | "left";
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "inline-flex",
        flexShrink: 0,
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(0, 0.5),
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightMedium,
        borderRadius: 2,
        minWidth: 17,
        minHeight: 17,
        position: "relative",
        "& > span": {
            lineHeight: 1,
            position: "relative",
            zIndex: 2,
        },
    },
    rootContained: {
        backgroundColor: "#E0EFF2",
    },
    rootOutlined: {
        backgroundColor: "rgba(224,239,242,0.3)",
        border: `1px dashed ${theme.palette.secondary.main}`,
    },
    arrow: {
        "&::before": {
            content: "''",
            backgroundColor: "inherit",
            borderRadius: 2,
            width: 13,
            height: 13,
            position: "absolute",
            transform: "rotate(45deg)",
            transformOrigin: "50% 50% 0",
            zIndex: 1,
        },
    },
    arrowTop: {
        marginTop: 5,
        "&::before": {
            top: -5,
            // left: 2,
        },
    },
    arrowRight: {
        marginRight: 5,
        "&::before": {
            // top: 2,
            right: -5,
        },
    },
    arrowBottom: {
        marginBottom: 5,
        "&::before": {
            bottom: -5,
            // left: 2,
        },
    },
    arrowLeft: {
        marginLeft: 5,
        "&::before": {
            // top: 2,
            left: -5,
        },
    },
}));

const Tag = ({ children, arrow, variant = "contained" }: TagProps) => {
    const classes = useStyles();

    return (
        <span
            className={clsx(
                classes.root,
                variant === "contained" && classes.rootContained,
                variant === "outlined" && classes.rootOutlined,
                !!arrow && classes.arrow,
                variant === "contained" && arrow === "top" && classes.arrowTop,
                variant === "contained" && arrow === "right" && classes.arrowRight,
                variant === "contained" && arrow === "bottom" && classes.arrowBottom,
                variant === "contained" && arrow === "left" && classes.arrowLeft,
            )}
        >
            <span>{children}</span>
        </span>
    );
};

export default Tag;
