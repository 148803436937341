import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { commonGetTopicsEndpoint, CommonTopicResponse } from "../../api/getTopics";
import { SpecificationParam } from "src/forms/types";

export enum CommonTopicActionsTypes {
    GET_TOPIC_LIST = "[common] GET TOPIC LIST",
    GET_TOPIC_LIST_SUCCESS = "[common] GET TOPIC LIST SUCCESS",
}

export const CommonTopicApiEndpoints = {
    getTopicList: createActionObject(
        commonGetTopicsEndpoint,
        CommonTopicActionsTypes.GET_TOPIC_LIST_SUCCESS,
    ),
};

export const CommonTopicRawActions = (
    CommonEndpoints: Record<Keys<typeof CommonTopicApiEndpoints>, ApiActionMeta>,
) => ({
    getTopicList: (specification: number) =>
        createApiAction(
            CommonTopicActionsTypes.GET_TOPIC_LIST,
            { values: {}, params: { specification } },
            CommonEndpoints.getTopicList,
        ),
    getTopicListSuccess: (
        payload: ApiCallbackPayload<CommonTopicResponse, {}, SpecificationParam>,
    ) => createAction(CommonTopicActionsTypes.GET_TOPIC_LIST_SUCCESS, payload),
});
