import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mdiAutorenew } from "@mdi/js";
import Icon from "src/components/Icon";
import { theme } from "src/styles/theme";
import COLORS from "src/styles/colors";

const ReportActions = ({
    studentsCount,
    requestReportHandler,
    refreshReportHandler,
    locked,
    showOnlySummaryCheckbox,
}) => {
    const { t } = useTranslation();
    const [isDummyLoad, setIsDummyLoad] = useState(false);
    const [onlySummary, setOnlySummary] = useState<boolean>(false);

    useEffect(() => {
        if (isDummyLoad) {
            setTimeout(() => {
                setIsDummyLoad(false);
            }, 1000);
        }
    }, [isDummyLoad]);

    return (
        <Box>
            {showOnlySummaryCheckbox && (
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="onlySummary"
                                value={onlySummary}
                                checked={onlySummary}
                                onChange={() => setOnlySummary(!onlySummary)}
                            />
                        }
                        label={t("report.actions.onlySummary")}
                    />
                </Box>
            )}
            <Box
                mb={1}
                color={COLORS.GREY_2}
                fontFamily={theme.typography.fontFamilyPrimary}
                fontSize={theme.typography.pxToRem(11)}
            >
                {t("report.actions.studentsSelected").replace("{x}", studentsCount)}
            </Box>
            <Button
                color="primary"
                onClick={() => requestReportHandler(onlySummary)}
                disabled={locked && isDummyLoad}
            >
                {t("report.actions.requestBtn")}
            </Button>
            {locked && (
                <Fragment>
                    <Button
                        variant="text"
                        startIcon={<Icon path={mdiAutorenew} />}
                        disableRipple
                        onClick={() => {
                            setIsDummyLoad(true);
                            refreshReportHandler();
                        }}
                    >
                        {t("report.actions.refreshBtn")}{" "}
                        {isDummyLoad && (
                            <Box ml={1}>
                                <CircularProgress size={16} />
                            </Box>
                        )}
                    </Button>
                    <Box
                        mt={1}
                        color={COLORS.RED_1}
                        fontFamily={theme.typography.fontFamilyPrimary}
                        fontSize={theme.typography.pxToRem(11)}
                        lineHeight={1.36}
                    >
                        <Typography component="p" variant="inherit">
                            {t("report.actions.locked")}
                        </Typography>
                        <Typography component="p" variant="inherit">
                            {t("report.actions.lockedWait")}
                        </Typography>
                    </Box>
                </Fragment>
            )}
        </Box>
    );
};

export default ReportActions;
