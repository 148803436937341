import { Middleware } from "redux";
import { ApiActions, ApiRequestPayload } from "./actions";
import { isApiActionMeta } from "./helpers";

export type ApiRequestAction = ReturnType<typeof ApiActions.apiRequest>;

export const apiMiddleware: Middleware = store => next => action => {
    const result = next(action);
    if (action && action.meta && isApiActionMeta(action.meta)) {
        const payload: ApiRequestPayload = {
            payload: action.payload || { values: [] },
            meta: action.meta,
        };
        const newAction = ApiActions.apiRequest(payload);
        store.dispatch(newAction);
    }

    return result;
};
