import clsx from "clsx";
import Dialog from "src/components/Dialog";
import COLORS from "src/styles/colors";
import AssessmentLabel from "./AssessmentLabel";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, styled } from "@mui/material";
import Icon from "src/components/Icon";
import { mdiFlipToFront } from "@mdi/js";

export type AssessmentCreateTypes = "pp" | "eott" | "copy";

export const ChooseButton = styled("button")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    backgroundColor: COLORS.VERY_LIGHT_GREY_1,
    cursor: "pointer",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    textAlign: "left",
    border: "0 none",
    outline: "0 none",
    width: "100%",
    height: "100%",
    position: "relative",
    padding: theme.spacing(3.75, 5),
    "&:hover": {
        backgroundColor: COLORS.VERY_LIGHT_GREY_5,
    },
    "&.active": {
        backgroundColor: COLORS.VERY_LIGHT_GREY_5,
        "&::before": {
            content: "''",
            backgroundColor: COLORS.BLUE_1,
            width: 3,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            pointerEvents: "none",
        },
    },
    "& .row": {
        display: "flex",
        alignItems: "center",
    },
    "& .icon": {
        color: COLORS.GREY_2,
        fontSize: 24,
        width: 24,
        height: 24,
        marginRight: theme.spacing(1.25),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(-0.25),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .icon.active": {
        color: COLORS.GREY_1,
    },
    "& .title": {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
    },
    "& .subtitle": {
        color: COLORS.GREY_2,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1.5,
        marginTop: theme.spacing(1),
    },
    "&. subtitle.active": {
        color: COLORS.GREY_1,
    },
}));

interface OwnProps {
    open: boolean;
    handleClose: (confirm: boolean, selectedType?: AssessmentCreateTypes) => void;
}

const AssessmentCreateModal: FC<OwnProps> = ({ open, handleClose }) => {
    const { t } = useTranslation();

    const [selectedType, setSelectedType] = useState<AssessmentCreateTypes | null>(null);

    return (
        <Dialog
            className="assessmentCreateModal"
            open={open}
            onClose={() => handleClose(false)}
            title={t("class.assessment.modal.header")}
            contentOverflow="visible"
        >
            <Fragment>
                <Typography gutterBottom>{t("class.assessment.modal.hint")}</Typography>

                <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr"
                    gap="1px 1px"
                    marginX={-6}
                    marginTop={5}
                    bgcolor={COLORS.VERY_LIGHT_GREY_7}
                >
                    <Box bgcolor={COLORS.VERY_LIGHT_GREY_1}>
                        <ChooseButton
                            onClick={() => {
                                setSelectedType("pp");
                                handleClose(true, "pp");
                            }}
                            className={selectedType === "pp" ? "active" : undefined}
                        >
                            <div className="row">
                                <div className={clsx("icon", selectedType === "pp" && "active")}>
                                    <AssessmentLabel variant="pp" size="medium" />
                                </div>
                                <div className="title">{t(`class.assessment.modal.ppTitle`)}</div>
                            </div>
                            <div className={clsx("subtitle", selectedType === "pp" && "active")}>
                                {t(`class.assessment.modal.ppText`)}
                            </div>
                        </ChooseButton>
                    </Box>
                    <Box bgcolor={COLORS.VERY_LIGHT_GREY_1}>
                        <ChooseButton
                            onClick={() => {
                                setSelectedType("eott");
                                handleClose(true, "eott");
                            }}
                            className={selectedType === "eott" ? "active" : undefined}
                        >
                            <div className="row">
                                <div className={clsx("icon", selectedType === "eott" && "active")}>
                                    <AssessmentLabel variant="eott" size="medium" />
                                </div>
                                <div className="title">{t(`class.assessment.modal.eottTitle`)}</div>
                            </div>
                            <div className={clsx("subtitle", selectedType === "eott" && "active")}>
                                {t(`class.assessment.modal.eottText`)}
                            </div>
                        </ChooseButton>
                    </Box>

                    <Box bgcolor={COLORS.VERY_LIGHT_GREY_1}>
                        <ChooseButton
                            onClick={() => {
                                setSelectedType("copy");
                                handleClose(true, "copy");
                            }}
                            className={selectedType === "copy" ? "active" : undefined}
                        >
                            <div className="row">
                                <div className="icon">
                                    <Icon path={mdiFlipToFront} size={1} />
                                </div>
                                <div className="title">{t(`class.assessment.modal.copyTitle`)}</div>
                            </div>
                            <div className={clsx("subtitle", selectedType === "copy" && "active")}>
                                <div>{t(`class.assessment.modal.copyText1`)}</div>
                                <div>{t(`class.assessment.modal.copyText2`)}</div>
                            </div>
                        </ChooseButton>
                    </Box>
                </Box>
            </Fragment>
        </Dialog>
    );
};

export default AssessmentCreateModal;
