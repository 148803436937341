import AnalysisHeader from "../components/Header/AnalysisHeader";
import KS4GradeAnalysisGrid, { KS4_GRADE_VIEW_TYPE } from "../components/Grid/KS4GradeAnalysisGrid";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { usePrevious } from "src/hooks/usePrevious";
import { KS4ViewGrades, KS4ViewTypes } from "../api/KS4Grade/getValues";
import { AnalysisTypes } from "../components/Common/AnalysisTypeModal";
import { useAnalysisSnapshots } from "../hooks/useAnalysisSnapshots";
import { useLocation, useParams } from "react-router";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { ViewGrades } from "../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { SnapshotData } from "src/context/TrackerContext";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useAnalysisUserSettingsApiConnector } from "../hooks/useAnalysisUserSettingsStore";

const KS4GradeAnalysisContainer = () => {
    const location = useLocation();
    const { navigate } = useSchoolNavigate();
    const query = new URLSearchParams(location.search);
    const { cohort: cohortParam, yearGroup: yearGroupParam } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const queryViewGrade = query && (query.get("viewGrade") as ViewGrades);

    const [viewGrade, setViewGrade] = useState<KS4ViewGrades>(
        queryViewGrade && queryViewGrade !== ViewGrades.TARGET
            ? (queryViewGrade as unknown as KS4ViewGrades)
            : KS4ViewGrades.PROJECTED,
    );
    const [viewType, setViewType] = useState<KS4ViewTypes>(
        (query && (query.get("viewType") as KS4ViewTypes)) || KS4ViewTypes.A8,
    );
    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
    const [toolsVisible, setToolsVisible] = useState<boolean>(false);
    const [dataType, setDataType] = useState<DataTypeTypes>(
        (query && (query.get("dataType") as DataTypeTypes)) || DataTypeTypes.LIVE,
    );
    const [snapshotParams, setSnapshotParams] = useState<SnapshotData>(() => {
        const dataSource1 = (query && query.get("dataSource1")) || undefined;
        const dataSource2 = (query && query.get("dataSource2")) || undefined;
        return {
            snapshot: (query && query.get("snapshotDataSource")) || null,
            compare: dataSource1 && dataSource2 ? { dataSource1, dataSource2 } : null,
        };
    });
    const { refetchSnapshots, setSnapshotData } = useAnalysisSnapshots(parseInt(cohortParam || ""));

    const prevCohort = usePrevious(cohortParam);

    const { analysisUserSettings } = useAnalysisUserSettingsApiConnector(
        parseInt(cohortParam || ""),
        parseInt(yearGroupParam || ""),
        KS4_GRADE_VIEW_TYPE,
    );

    useEffect(() => {
        if (prevCohort) {
            refetchSnapshots();
        }
    }, [cohortParam]);

    useEffect(() => {
        setSnapshotData(snapshotParams);
    }, [snapshotParams]);

    useEffect(() => {
        navigate(
            `${location.pathname}?dataType=${dataType}&viewGrade=${viewGrade}&viewType=${viewType}${
                snapshotParams?.compare?.dataSource1 && snapshotParams?.compare?.dataSource2
                    ? "&dataSource1=" +
                      snapshotParams.compare?.dataSource1 +
                      "&dataSource2=" +
                      snapshotParams.compare?.dataSource2
                    : ""
            }${snapshotParams?.snapshot ? "&snapshotDataSource=" + snapshotParams.snapshot : ""}${
                analysisUserSettings?.filters ? "&" + analysisUserSettings?.filters : ""
            }`,
        );
    }, [dataType, viewGrade, viewType, snapshotParams]);

    return (
        <Box component="main" height="100vh" pt={0} pb={3} display="flex" flexDirection="column">
            <AnalysisHeader
                cohort={parseInt(`${cohortParam}`)}
                yearGroup={parseInt(yearGroupParam || "")}
                analysisType={AnalysisTypes.KS4_GRADE_ANALYSIS}
                viewGrade={viewGrade}
                dataType={dataType}
                viewType={viewType}
                handleDataTypeChange={dataType => {
                    setDataType(dataType);
                    setFiltersVisible(false);
                    setToolsVisible(false);
                    setSnapshotParams({ snapshot: null, compare: null });
                }}
                handleViewGradeChange={viewGrade => {
                    if (viewGrade !== null) {
                        setViewGrade(viewGrade as KS4ViewGrades);
                        setFiltersVisible(false);
                        setToolsVisible(false);
                    }
                }}
                handleViewTypeChange={viewType => {
                    if (viewType !== null) {
                        setViewType(viewType as KS4ViewTypes);
                        setFiltersVisible(false);
                        setToolsVisible(false);
                    }
                }}
                handleFiltersVisible={isChecked => {
                    setFiltersVisible(isChecked);
                    if (toolsVisible && isChecked) {
                        setToolsVisible(!isChecked);
                    }
                }}
                handleToolsVisible={isChecked => {
                    setToolsVisible(isChecked);
                    if (filtersVisible && isChecked) {
                        setFiltersVisible(!isChecked);
                    }
                }}
                filtersVisible={filtersVisible}
                toolsVisible={toolsVisible}
                isLoadingGrid={isLoading}
                handleSnapshotParamsChange={setSnapshotParams}
            />
            <KS4GradeAnalysisGrid
                cohort={parseInt(cohortParam || "")}
                yearGroup={parseInt(yearGroupParam || "")}
                viewGrade={viewGrade}
                viewType={viewType}
                filtersVisible={filtersVisible}
                toolsVisible={toolsVisible}
                dataType={dataType}
                handleIsLoading={isLoading => setIsLoading(isLoading)}
            />
        </Box>
    );
};

export default KS4GradeAnalysisContainer;
