import { attr, Model } from "redux-orm";

export enum SchoolCustomFieldTypes {
    ATTITUDE = "attitude",
    EFFORT = "effort",
    RESILIENCE = "resilience",
    HOME_LEARNING = "home",
    INDEPENDENT_LEARNING = "independent",
    ORGANISATION = "organisation",
}

const fields = {
    id: attr(),
    fieldType: attr(),
    isEnabled: attr(),
    displayedName: attr(),
    useDefaultValues: attr(),
    fieldValues: attr(),
    allYearGroups: attr(),
    yearGroups: attr(),
};

export interface OrmSchoolCustomFieldShort {
    fieldType?: SchoolCustomFieldTypes;
    hidden?: boolean;
    fromLabel?: boolean;
}

export interface OrmSchoolCustomField extends OrmSchoolCustomFieldShort {
    id?: number | null;
    isEnabled: boolean;
    displayedName: string;
    useDefaultValues: boolean;
    fieldValues: string[];
    allYearGroups: boolean;
    yearGroups: number[];
}

export const emptySchoolCustomField = {
    id: null,
    fieldType: "attitude" as SchoolCustomFieldTypes,
    isEnabled: false,
    displayedName: "",
    useDefaultValues: true,
    fieldValues: [],
    allYearGroups: false,
    yearGroups: [],
};

class SchoolCustomField extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "SchoolCustomField";
    static fields = fields;
    static emptyRef: OrmSchoolCustomField = emptySchoolCustomField;

    get ormRef(): SchoolCustomField {
        return this.ref as any;
    }

    get ormRefDetails(): OrmSchoolCustomField {
        return this.ormRef as any;
    }
}

export default SchoolCustomField;
