import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmInvoiceActionTypes, OrmInvoiceActions } from "./actions";

export const invoiceReducer: ModelReducer = {
    [OrmInvoiceActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmInvoiceActions.bulkImport>,
        session,
    ) => {
        const { Invoice } = session;
        Invoice.all().delete();
        action.payload.forEach(c => Invoice.create(ormToRef(c, {})));
    },
};
