import { Box, Button, Step, Stepper, Typography, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ListObject } from "src/forms/types";
import { useSnackbar } from "notistack";
import { createRef, memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CompletedStep from "../components/Register/CompletedStep";
import CredentialsStep from "../components/Register/CredentialsStep";
import DetailsStep from "../components/Register/DetailsStep";
import SchoolStep, { UserRole } from "../components/Register/SchoolStep";
import { steps } from "../components/Register/steps";
import SubjectAreaStep from "../components/Register/SubjectAreaStep";
import TermsStep from "../components/Register/TermsStep";
import {
    registerSchema,
    step1Schema,
    step2Schema,
    step3Schema,
    step4Schema,
    step5Schema,
} from "../components/Register/registerSchema";
import AuthContainer from "src/components/AuthContainer";
import { mdiArrowLeft } from "@mdi/js";
import Icon from "src/components/Icon";
import { CustomConnector, CustomStepLabel, CustomStepIcon } from "../components/Common/Stepper";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useRegister } from "../hooks/useRegister";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "src/components/hookFormComponents";

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontWeight: 600,
    },
    stepper: {
        margin: theme.spacing(4.5, -2.5, 8.5),
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(4.5, 4, 8.5),
        },
    },
}));

export interface RegisterFormValues {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    repeatPassword: string;
    subjectAreas: ListObject<number>[];
    pixlSupported: ListObject<number>[];
    school: ListObject<number>;
    terms: boolean;
    userRole: UserRole;
}

const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
        case 0:
            return <SchoolStep />;
        case 1:
            return <SubjectAreaStep />;
        case 2:
            return <DetailsStep />;
        case 3:
            return <CredentialsStep />;
        case 4:
            return <TermsStep />;
        case 5:
            return <CompletedStep />;
        default:
            return null;
    }
};

const RegisterContainer = () => {
    const form = createRef();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);

    const stepsConfig = [
        { fields: ["school", "userRole"], schema: step3Schema(t) },
        { fields: ["subjectAreas", "pixlSupported"], schema: step4Schema(t) },
        { fields: ["firstName", "lastName"], schema: step1Schema(t) },
        { fields: ["email", "password", "repeatPassword"], schema: step2Schema(t) },
        { fields: ["terms"], schema: step5Schema(t) },
    ];

    const getValidationSchema = (activeStep: number) => {
        if (activeStep < stepsConfig.length) {
            return stepsConfig[activeStep].schema;
        }
        return null;
    };

    const stepValidator = getValidationSchema(activeStep);

    const handleNextStep = () =>
        setActiveStep(activeStep > stepsConfig.length ? stepsConfig.length : activeStep + 1);

    const handleResponse = useCallback(() => {
        if (status === "success" && activeStep < stepsConfig.length) {
            handleNextStep();
        }
    }, [enqueueSnackbar, form, handleNextStep, activeStep]);

    const { mutate: register, status } = useRegister();

    useEffect(() => {
        handleResponse();
    }, [status]);

    const handleSubmit = values => {
        registerSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid) {
                    register(values);
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    const handlePreviousStep = () =>
        setActiveStep(activeStep => (activeStep === 0 ? 0 : activeStep - 1));

    const classes = useStyles();

    const methods = useForm({
        resolver: yupResolver(stepValidator as any),
        mode: "all",
        defaultValues: {
            title: t("common.mr"),
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            repeatPassword: "",
            subjectAreas: [],
            pixlSupported: [],
            nahtSupported: [],
            school: null,
            terms: false,
            promoType: null,
            userRole: null,
        },
    });
    const {
        formState: { isSubmitting },
        watch,
    } = methods;

    return (
        <AuthContainer>
            <Typography
                component="h1"
                variant="h1"
                align="center"
                color="secondary"
                className={classes.heading}
            >
                {t("auth.register.steps.createAccount")}
            </Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit}>
                <Stepper
                    className={classes.stepper}
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<CustomConnector />}
                >
                    {steps(t).map(label => (
                        <Step key={label} className="stepper-item">
                            <CustomStepLabel StepIconComponent={CustomStepIcon}>
                                {label}
                            </CustomStepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box>{getStepContent(activeStep)}</Box>
                {activeStep !== 5 && (
                    <Box
                        display="flex"
                        flexDirection={{ xs: "column-reverse", md: "row" }}
                        justifyContent="space-between"
                        width={1}
                        mt={5}
                        className="column-reverse"
                    >
                        {activeStep < stepsConfig.length && watch("school") && (
                            <Button
                                variant="text"
                                disabled={activeStep === 0}
                                onClick={handlePreviousStep}
                                startIcon={<Icon path={mdiArrowLeft} />}
                                disableRipple
                            >
                                {t("common.back")}
                            </Button>
                        )}
                        {activeStep < 4 &&
                            watch("school") &&
                            watch("userRole") == UserRole.TEACHER && (
                                <Button color="primary" onClick={handleNextStep}>
                                    {t("common.continue")}
                                </Button>
                            )}
                        {activeStep === 4 && (
                            <Button color="primary" type="submit" disabled={isSubmitting}>
                                {t("common.continue")}
                            </Button>
                        )}
                    </Box>
                )}
            </FormProvider>
        </AuthContainer>
    );
};

export default memo(RegisterContainer);
