import { MenuItem, Typography, Box } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RHFTextField } from "src/components/hookFormComponents";

const DetailsStep = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Box mb={2.5}>
                <Typography>
                    <Box
                        dangerouslySetInnerHTML={{ __html: t("auth.register.detailsStep.header") }}
                    ></Box>
                </Typography>
            </Box>
            <RHFTextField
                name="title"
                select
                label={t("common.form.label.title")}
                autoFocus
                margin="normal"
            >
                <MenuItem value={null}>{t("common.none")}</MenuItem>
                <MenuItem value={`Mr`}>{t("common.mr")}</MenuItem>
                <MenuItem value={`Mrs`}>{t("common.mrs")}</MenuItem>
                <MenuItem value={`Miss`}>{t("common.miss")}</MenuItem>
                <MenuItem value={`Ms`}>{t("common.ms")}</MenuItem>
                <MenuItem value={`Dr`}>{t("common.dr")}</MenuItem>
                <MenuItem value={`Prof`}>{t("common.prof")}</MenuItem>
                <MenuItem value={`Mx`}>{t("common.mx")}</MenuItem>
            </RHFTextField>
            <RHFTextField
                name="firstName"
                label={t("common.form.label.firstName")}
                margin="normal"
            />
            <RHFTextField name="lastName" label={t("common.form.label.lastName")} margin="normal" />
        </Fragment>
    );
};

export default DetailsStep;
