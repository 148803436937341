import Icon from "../Icon";
import { FC, useEffect, useState } from "react";
import { FieldAttributes, useField, useFormikContext } from "formik";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import { mdiClose } from "@mdi/js";
import { DatePickerProps } from "@mui/lab";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { DATETIMEPICKER_FORMAT } from "src/types";

interface FormDateFieldProps extends DatePickerProps<Date> {
    name: string;
    label?: string;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
}

export const getConvertedDateTime = date => {
    const splitedDateTime = date ? date.split(" ") : null;

    const splitedDate =
        splitedDateTime && splitedDateTime[0] ? splitedDateTime[0].split("/") : null;

    const splitedTime =
        splitedDateTime && splitedDateTime[1] ? splitedDateTime[1].split(":") : null;

    const formatedDate =
        splitedDate && splitedDate.length === 3 && splitedTime && splitedTime.length === 3
            ? new Date(
                  parseInt(splitedDate[2]),
                  parseInt(splitedDate[1]) - 1,
                  parseInt(splitedDate[0]),
                  parseInt(splitedTime[0]),
                  parseInt(splitedTime[1]),
              )
            : null;

    return formatedDate?.toISOString() || "";
};

export const getDateFormated = (value: string) => {
    const regex = new RegExp(`\\d{4}\\-(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])*`, "");
    const res = regex.test(value);

    if (res) {
        return new Date(value);
    }

    const splitedDateTime = value ? value.split(" ") : null;

    const splitedDate =
        splitedDateTime && splitedDateTime[0] ? splitedDateTime[0].split("/") : null;

    const splitedTime =
        splitedDateTime && splitedDateTime[1] ? splitedDateTime[1].split(":") : null;

    const formatedDate =
        splitedDate && splitedDate.length === 3 && splitedTime && splitedTime.length === 3
            ? new Date(
                  parseInt(splitedDate[2]),
                  parseInt(splitedDate[1]) - 1,
                  parseInt(splitedDate[0]),
                  parseInt(splitedTime[0]),
                  parseInt(splitedTime[1]),
              )
            : null;

    return formatedDate;
};

const DateTimePicker: FC<FieldAttributes<FormDateFieldProps>> = ({
    name,
    label,
    helperText,
    required,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const [date, setDate] = useState<Date | null>(
        field.value ? getDateFormated(field.value) : null,
    );

    const handleDateClear = (e, fieldName) => {
        setDate(null);
        setFieldValue(fieldName, "");
    };

    useEffect(() => {
        if (field.value && !date) {
            setDate(new Date(getDateFormated(field.value)));
        }
    }, [field.value]);

    useEffect(() => {
        setFieldValue(field.name, date ? format(new Date(date), "dd/MM/yyyy H:mm:ss") : "");
    }, [date]);

    const renderInput = (props: TextFieldProps): any => (
        <TextField
            {...props}
            margin="none"
            inputRef={props.inputRef}
            name={props.name}
            id={props.id}
            defaultValue={props.defaultValue}
            label={label}
            error={meta.touched && !!meta.error?.length}
            fullWidth
            required={required}
            helperText={meta.touched && meta.error ? meta.error : helperText}
            type={props.type}
            onBlur={props.onBlur}
            InputProps={{
                margin: "none",
                endAdornment: (
                    <IconButton
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDateClear(e, name);
                        }}
                        size="small"
                    >
                        <Icon path={mdiClose} />
                    </IconButton>
                ),
            }}
        />
    );

    return (
        <MobileDateTimePicker
            {...(props as any)}
            ampm={false}
            inputFormat={DATETIMEPICKER_FORMAT}
            emptyLabel="Select Date"
            value={date}
            showToolbar={false}
            onChange={(val: any) => {
                if (val) setDate(val);
            }}
            renderInput={renderInput}
        />
    );
};

export default DateTimePicker;
