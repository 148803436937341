import { apiUrl } from "src/config/globals";
import { ListObject } from "src/forms/types";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { GradeTypes } from "src/orm/models/Grade";
import { baseHeaders, post } from "src/services/ajax";
import { ColumnTypeGradeTypes } from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";
import { ColumnConfigTypes, NodeTypes } from "./headerGenerator";
import { getSchoolAccountId } from "src/services/url";
// import { removeMetaIfAllUndefined } from "src/services/object";

export type TrackerValueType = number | string | boolean | null | object;

export const UNDERPASS_INDEX = -1; // label U
export const NOT_PARTICIPATING_INDEX = -2; // label X
export const ABSENCE_INDEX = -3; // label A

export const absenceValuesLabels = {
    [UNDERPASS_INDEX]: "U",
    [NOT_PARTICIPATING_INDEX]: "X",
    [ABSENCE_INDEX]: "A",
};

export const absenceValuesArray = [
    `${UNDERPASS_INDEX}`,
    `${NOT_PARTICIPATING_INDEX}`,
    `${ABSENCE_INDEX}`,
];

export interface DataToSendType {
    row: string;
    column: string;
    value: any;
    classTracker?: number;
}

export interface TagsToSendType {
    row: string;
    column: string;
    value: { value: number; whileUpdate: boolean };
}

export interface LinksToSendType {
    row: string;
    column: string;
    value: { value: number; whileUpdate: boolean };
}

export interface ConvertedDataToSendType {
    [key: string]: { [key: string]: TrackerValueType };
}

export interface YearGroupOverviewDataToSendType {
    [key: string]: { [key: string]: { [key: string]: TrackerValueType } };
}

export interface CellState {
    errors: boolean;
    whileUpdate: boolean;
    lastUpdatedAt: string;
    prevValue: TrackerValueType;
    currentValue: TrackerValueType;
    readOnly?: boolean;
}

export enum TrackerCompareDataSourceTypes {
    LIVE_TRACKING = "live-tracking",
    FORECAST = "forecast",
    SNAPSHOT = "snapshot",
}

export interface YearGroupForecastBulkEditRequest {
    classTracker: ListObject<number>;
    matrixData: { [key: string]: { [key: string]: any } };
}

export const mergeStudentsToValues = (classTrackerValues, students) => {
    const newValues = { ...classTrackerValues };
    Object.keys(newValues).forEach(key => {
        const id = parseInt(key.replace("row", ""));
        const convertedStudentsResponseForTracker = {};

        const student = students.find(s => s.id === id);

        if (student) {
            Object.keys(student).forEach(studentKey => {
                convertedStudentsResponseForTracker["student_" + studentKey] = student[studentKey];
            });
            newValues[key] = { ...newValues[key], ...convertedStudentsResponseForTracker };
        }
    });
    return newValues;
};

export const getRowIndexByRow = (dataSet, rowKey) => {
    if (dataSet) {
        const index = dataSet.findIndex(ds => ds && ds.row === rowKey);
        return index > -1 ? index : null;
    }
    return -1;
};

export const sendTrackerData = (
    classTrackerId: number,
    subscriber: any,
    dataToSend: ConvertedDataToSendType,
    isForecast: boolean,
    onSuccess: (res) => void,
    onError: (err) => void,
    tier?: TiersTypes,
) => {
    let url = `school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/${tier || "-"}/bulk-edit/`;
    if (isForecast) {
        url = `school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/${tier || "-"}/forecast/bulk-edit/`;
    }
    subscriber.current = post(apiUrl(url), { ...dataToSend }, baseHeaders()).subscribe(
        res => {
            onSuccess(res);
        },
        err => {
            onError(err);
        },
    );
};

export const sendTrackerGroupData = (
    classTrackerGroupId: number,
    subscriber: any,
    dataToSend: ConvertedDataToSendType,
    onSuccess: (res) => void,
    onError: (err) => void,
) => {
    const url = `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/forecast/bulk-edit/`;

    subscriber.current = post(apiUrl(url), { ...dataToSend }, baseHeaders()).subscribe(
        res => {
            onSuccess(res);
        },
        err => {
            onError(err);
        },
    );
};

export const getAvailableStudentParams = classTrackerValues => {
    if (classTrackerValues) {
        const firstRow = classTrackerValues[Object.keys(classTrackerValues)[0]];

        const excludedStudentColumns = [
            "student_ethnicity_id",
            "id",
            "classTracker_id",
            "classTrackerId",
            "ethnicity_id",
        ];

        if (firstRow) {
            return Object.keys(firstRow)
                .filter(
                    key => key.indexOf("student_") === 0 && !excludedStudentColumns.includes(key),
                )
                .map(key => key.replace("student_", ""))
                .filter(key => isNaN(parseInt(key)));
        }
    }

    return [];
};

export const getAvailableStudentParamsBuColumns = studentColumns => {
    if (studentColumns) {
        const excludedStudentColumns = [
            "student_ethnicity_id",
            "id",
            "classTracker_id",
            "classTrackerId",
            "ethnicity_id",
        ];

        return studentColumns
            .filter(key => key.indexOf("student_") === 0 && !excludedStudentColumns.includes(key))
            .map(key => key.replace("student_", ""))
            .filter(key => isNaN(parseInt(key)));
    }

    return [];
};

export const normalizeValue = (
    value: string | number | boolean,
    isFineGrade?: boolean,
): TrackerValueType => {
    let conversedValue: TrackerValueType = null;

    if (typeof value === "string") {
        if (isNaN(value as unknown as number)) {
            conversedValue = value.toUpperCase();
        } else {
            conversedValue = parseInt(value);
        }
    } else {
        conversedValue = value;
    }

    if (isFineGrade && !absenceValuesArray.includes(`${conversedValue}`)) {
        conversedValue = parseInt(`${conversedValue}`.replace("-", "").replace("+", ""));
    }

    return conversedValue === "" ? null : conversedValue === "FALSE" ? false : conversedValue;
};

export const generateInitialCellStates = (rowData, columns?) => {
    const initialCellStates = [] as { [key: string]: CellState }[];
    rowData.forEach(prd => {
        const newRow = { ...prd } as any;

        Object.keys(prd).forEach(cellKey => {
            newRow[cellKey] = {
                errors: false,
                whileUpdate: false,
                lastUpdatedAt: new Date().toISOString(),
                prevValue: newRow[cellKey],
                currentValue: newRow[cellKey],
                readOnly: columns[cellKey]?.readOnly,
            };
        });
        initialCellStates.push(newRow);
    });

    return initialCellStates;
};

export const mergeStudentColumnData = (classTrackerValues, classTrackerHeader, bulkEdit?) => {
    const partialRowData = classTrackerValues
        ? Object.entries(classTrackerValues).map(([key, value]) => {
              const rowValue: any = value;
              if (bulkEdit) {
                  Object.keys(rowValue).forEach(key => {
                      const columnConfig = classTrackerHeader.columns[key];
                      if (
                          key.indexOf("column") === 0 &&
                          columnConfig &&
                          columnConfig.readOnly === true
                      ) {
                          rowValue[key] = "";
                      }
                      if (key.indexOf("target-grid") === 0) {
                          rowValue[key] = [];
                      }
                  });
              }

              return {
                  ...rowValue,
                  row: key,
                  moveStudentTo: "",
              };
          })
        : [];

    const sortedParialRowData = partialRowData.sort((a, b) => {
        if (
            a["student_lastName"] &&
            a["student_firstName"] &&
            b["student_firstName"] &&
            b["student_lastName"]
        ) {
            const result = a["student_lastName"].localeCompare(b["student_lastName"]);
            return result !== 0
                ? result
                : a["student_firstName"].localeCompare(b["student_firstName"]);
        }

        return 0;
    });

    // handle target grid data (split array to columns)
    Object.entries(classTrackerValues).forEach(([rowKey, value]) => {
        const rowValue: any = value;
        Object.entries(rowValue).forEach(([key, v]) => {
            const values: any = v;
            if (key.includes("target-grid")) {
                const rowData = partialRowData.find(p => p.row === rowKey);
                if (rowData && values) {
                    values.forEach((v, index) => {
                        rowData[`${key}-${index}`] = v === true ? "" : v;
                    });
                }
            }
        });
    });

    // combine student data with row data
    return sortedParialRowData.map((row: Record<string, any>) => {
        return {
            ...row,
            id: row.student_id,
            rowState: {
                whileUpdate: false,
                lastUpdatedAt: new Date().toISOString(),
                errors: null,
            },
        };
    });
};

export const getGradeIdByName = (name, relatedData) => {
    if (relatedData.courseGrades && relatedData.courseGrades.length > 0) {
        const grade = relatedData.courseGrades.find(cg => `${cg.name}` === `${name}`);
        return grade?.id || null;
    } else if (relatedData.grades && relatedData.grades.length > 0) {
        const grade = relatedData.grades.find(cg => `${cg.name}` === `${name}`);
        return grade?.id || null;
    }

    return null;
};

export const getGradeValue = (
    value,
    relatedData: ClassTrackerRelatedObject,
    unitType?: string | null,
    gradeType?: ColumnTypeGradeTypes | null,
) => {
    if (relatedData?.gradeType.type === GradeTypes.COMBINED) {
        if (unitType === "standard") {
            const gradeValue = relatedData.grades.find(g => g.id === value);
            return gradeValue?.name || null;
        } else if (unitType === "summary" && relatedData?.courseGrades) {
            const gradeValue = relatedData?.courseGrades.find(g => g.id === value);
            return gradeValue?.name || null;
        }
    } else {
        if (gradeType && gradeType === ColumnTypeGradeTypes.UNIT && relatedData?.grades) {
            const gradeValue = relatedData.grades.find(g => g.id === value);
            return gradeValue?.name || null;
        }
        if (gradeType && gradeType === ColumnTypeGradeTypes.COURSE && relatedData?.courseGrades) {
            const gradeValue = relatedData.courseGrades.find(g => g.id === value);
            return gradeValue?.name || null;
        }

        // this should be removed
        if (relatedData?.grades) {
            let gradeValue = relatedData.grades.find(g => g.id === value);
            if (relatedData.courseGrades && relatedData.courseGrades.length > 0) {
                gradeValue = relatedData.courseGrades.find(g => g.id === value);
            }

            return gradeValue?.name || null;
        }
    }

    return null;
};

export const getFieldValue = (
    value,
    relatedData: ClassTrackerRelatedObject,
    unitType?: string | null,
    columnConfigType?: string | null,
    columnType?: string | null,
    gradeType?: ColumnTypeGradeTypes | null,
) => {
    if (value === false) return "A";
    if (
        (columnConfigType &&
            (columnConfigType === ColumnConfigTypes.PROJECTED_GRADE ||
                columnConfigType === ColumnConfigTypes.CURRENT_GRADE ||
                columnConfigType === ColumnConfigTypes.OTHER_GRADE ||
                columnConfigType === ColumnConfigTypes.TEACHER_JUDGEMENT ||
                columnConfigType === ColumnConfigTypes.BTEC_PROJECTED_GRADE)) ||
        columnType === NodeTypes.CUSTOM_FIELD
    ) {
        if (value == -1) return "U";
        if (value == -2) return "X";
        if (value == -3) return "A";
    }
    return columnType === "grade" ? getGradeValue(value, relatedData, unitType, gradeType) : value;
};

export const getLiteFieldValue = (
    value,
    relatedData: ClassTrackerRelatedObject,
    unitType?: string | null,
    columnConfigType?: string | null,
    columnType?: string | null,
    gradeType?: ColumnTypeGradeTypes | null,
) => {
    return value === false
        ? "A"
        : `${value}` === "-1"
          ? "U"
          : columnType === "grade"
            ? getGradeValue(value, relatedData, unitType, gradeType)
            : value;
};

export const convertDataToSend = (dataToSend: DataToSendType[], fineGradeEnabled?: boolean) => {
    let convertedDataToSend: any = {};

    dataToSend.forEach(d => {
        let value: any = d.value;
        const isFineGrade = !!value?.["fineGrade"] || false;

        if (d?.value?.value) {
            value = d.value.value;
        }

        convertedDataToSend = {
            ...convertedDataToSend,
            [d.row]: {
                values: {
                    ...(convertedDataToSend?.[d.row]?.values || {}),
                    [d.column]: normalizeValue(
                        value?.value !== undefined ? value.value : value,
                        isFineGrade,
                    ),
                },
                meta: fineGradeEnabled
                    ? {
                          ...(convertedDataToSend?.[d.row]?.meta || {}),
                          [d.column]:
                              isFineGrade && d.value["fineGrade"] && d.value["fineGrade"] !== "x"
                                  ? { fineGrade: d.value["fineGrade"] }
                                  : undefined,
                      }
                    : undefined,
            },
        };
    });

    return convertedDataToSend;
};

export const convertDataToSendLite = (dataToSend: DataToSendType[], gridHeader, relatedData) => {
    let convertedDataToSend: ConvertedDataToSendType = {};

    dataToSend.forEach(d => {
        const columnData = gridHeader?.columns[d.column];
        const isGrade = columnData?.columnType === "grade" || false;
        const value = isGrade ? getGradeIdByName(d.value, relatedData) : normalizeValue(d.value);
        convertedDataToSend = {
            ...convertedDataToSend,
            [d.row]: { ...convertedDataToSend[d.row], [d.column]: value },
        };
    });
    return convertedDataToSend;
};

export const prepareDataForBulkOrMultipleUpdate = (dataToSend, dataSet) => {
    const bulkEditData = {};

    Object.keys(dataToSend).forEach(studentId => {
        if (Object.keys(dataToSend[studentId]).length > 0 && dataSet) {
            const row = dataSet.find(ds => ds.id === parseInt(studentId));
            if (row) {
                bulkEditData[row.row as string] = dataToSend[studentId];
            }
        }
    });

    return bulkEditData;
};
