import { FC } from "react";
import {
    Typography,
    Button,
    Grid,
    Radio,
    FormControlLabel,
    Box,
    Chip,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField as MuiTextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { TFunction } from "i18next";
import { Form, Field, FormikProps } from "formik";
import { TextField, RadioGroup } from "formik-mui";
import PaperInner from "src/components/PaperInner";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import CheckboxField from "src/forms/CheckboxField";
import ErrorMessage from "src/forms/ErrorMessage";
import { OrmSchoolCustomFieldShort } from "src/orm/models/SchoolCustomField";

export interface ReportTemplateFormShape {
    id?: number;
    name: string;
    fileType: "individual" | "merge";
    fileNamePattern: string | null;
    hideMtg: boolean;
    hideCurrentGrade: boolean;
    hideAverageUnitGrade: boolean;
    hideTpg: boolean;
    reportTemplateCustomFields: OrmSchoolCustomFieldShort[];
    hideDistanceFromMtgColours: boolean;
}

export const reportTemplateSchema = (t: TFunction) => {
    return yup.object().shape({
        name: yup.string().required(t("report.reportTemplate.addEdit.name")),
    });
};

interface OwnProps extends FormikProps<ReportTemplateFormShape> {
    values: ReportTemplateFormShape;
}
const fileNamePatternExampleMapper = {
    FirstName: "John",
    LastName: "Smith",
    UniquePupilNumber: "J380212310024",
    AdmissionNumber: "12345678",
    CandidateNumber: "4175",
    Form: "11X",
};
const ReportTemplateForm: FC<OwnProps> = ({
    values,
    setFieldValue,
    isSubmitting,
    submitForm,
    errors,
    submitCount,
}) => {
    const { t } = useTranslation();

    const handleAddToPattern = key => {
        setFieldValue(
            "fileNamePattern",
            // eslint-disable-next-line
            values.fileNamePattern + `${values.fileNamePattern ? "-" : ""}` + `{${key}}`,
        );
    };
    const getFileNamePatternExample = () => {
        let example = values.fileNamePattern;
        Object.entries(fileNamePatternExampleMapper).forEach(
            ([key, value]) => (example = example.replace(`{${key}}`, value)),
        );
        return example;
    };
    return (
        <Form>
            <PaperInner border="bottom">
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <Field
                            name="name"
                            label={t("report.reportTemplate.addEdit.name")}
                            component={TextField}
                        />
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner border="bottom">
                <Box pb={2}>
                    <Typography variant="h2">
                        {t("report.reportTemplate.addEdit.filesHeader")}
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item sm={12}>
                            <Typography gutterBottom>
                                {t("report.reportTemplate.addEdit.fileType")}
                            </Typography>
                            <Field name="fileType" label="Radio Group" component={RadioGroup} row>
                                <FormControlLabel
                                    value={"merge"}
                                    control={<Radio />}
                                    label={t("report.reportTemplate.addEdit.mergeType")}
                                />{" "}
                                <FormControlLabel
                                    value={"individual"}
                                    control={<Radio />}
                                    label={t("report.reportTemplate.addEdit.individualType")}
                                />
                                {values.fileType === "individual" && (
                                    <Box mt={2}>
                                        <Field
                                            name="fileNamePattern"
                                            label={t(
                                                "report.reportTemplate.addEdit.fileNamePattern",
                                            )}
                                            component={TextField}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <Typography variant="h4">
                                            {t("report.reportTemplate.addEdit.fileNamePreview")}
                                        </Typography>
                                        {getFileNamePatternExample()}
                                        <Box mb={4}>
                                            <Typography variant="h4">
                                                {t(
                                                    "report.reportTemplate.addEdit.fileNamePatternHint",
                                                )}
                                            </Typography>
                                            {[
                                                "FirstName",
                                                "LastName",
                                                "UniquePupilNumber",
                                                "AdmissionNumber",
                                                "CandidateNumber",
                                                "Form",
                                            ].map(key => {
                                                return (
                                                    <Chip
                                                        key={key}
                                                        color="primary"
                                                        style={{ marginRight: 4 }}
                                                        label={key}
                                                        disabled={values.fileNamePattern.includes(
                                                            key,
                                                        )}
                                                        onClick={() => {
                                                            handleAddToPattern(key);
                                                        }}
                                                    />
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Box>
            </PaperInner>
            <PaperInner border="bottom">
                <Box pb={2}>
                    <Typography variant="h2">
                        {t("report.reportTemplate.addEdit.gradesHeader")}
                    </Typography>
                    {values.hideMtg !== undefined && (
                        <Field
                            name={`hideMtg`}
                            component={CheckboxField}
                            label={t("report.reportTemplate.addEdit.hideMtg")}
                        />
                    )}
                    {values.hideCurrentGrade !== undefined && (
                        <Field
                            name={`hideCurrentGrade`}
                            component={CheckboxField}
                            label={t("report.reportTemplate.addEdit.hideCurrentGrade")}
                        />
                    )}
                    {values.hideAverageUnitGrade !== undefined && (
                        <Field
                            name={`hideAverageUnitGrade`}
                            component={CheckboxField}
                            label={t("report.reportTemplate.addEdit.hideAverageUnitGrade")}
                        />
                    )}
                    {values.hideTpg !== undefined && (
                        <Field
                            name={`hideTpg`}
                            component={CheckboxField}
                            label={t("report.reportTemplate.addEdit.hideTpg")}
                        />
                    )}
                    <ErrorMessage name={"hideMtg"} />
                    <ErrorMessage name={"hideCurrentGrade"} />
                    <ErrorMessage name={"hideAverageUnitGrade"} />
                </Box>
            </PaperInner>
            <PaperInner border="bottom">
                <Box pb={2}>
                    <Typography variant="h2">
                        {t("report.reportTemplate.addEdit.colourHeader")}
                    </Typography>
                    {values.hideDistanceFromMtgColours !== undefined && (
                        <Field
                            name={`hideDistanceFromMtgColours`}
                            component={CheckboxField}
                            label={t("report.reportTemplate.addEdit.hideDistanceFromMtgColours")}
                        />
                    )}
                </Box>
            </PaperInner>
            <PaperInner border="bottom">
                <Box pb={2}>
                    <Typography variant="h2" gutterBottom>
                        {t("report.reportTemplate.addEdit.customFieldHeader")}
                    </Typography>
                    <Typography component="h3">
                        {t("report.reportTemplate.addEdit.customFieldHint")}
                    </Typography>
                    <Typography component="h3">
                        {t("report.reportTemplate.addEdit.customFieldHint2")}
                    </Typography>
                    <Box mt={2}>
                        {values.reportTemplateCustomFields &&
                        values.reportTemplateCustomFields.length > 0 ? (
                            <Table>
                                <TableBody>
                                    {values.reportTemplateCustomFields.map((customField, i) => {
                                        return (
                                            <TableRow key={customField.fieldType || i}>
                                                <TableCell>
                                                    <MuiTextField
                                                        label={t(
                                                            "report.reportTemplate.addEdit.reportTemplateCustomFieldsFieldType",
                                                        )}
                                                        value={t(
                                                            `report.reportTemplate.addEdit.customFieldType${customField.fieldType}`,
                                                        )}
                                                        disabled
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Field
                                                        name={`reportTemplateCustomFields.${i}.hidden`}
                                                        component={CheckboxField}
                                                        label={t(
                                                            "report.reportTemplate.addEdit.customFieldHidden",
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Field
                                                        name={`reportTemplateCustomFields.${i}.fromLabel`}
                                                        component={CheckboxField}
                                                        label={t(
                                                            "report.reportTemplate.addEdit.customFieldFromLabel",
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Box>
            </PaperInner>

            <PaperInner border="bottom">
                <Box mt={4}>
                    <Button disabled={isSubmitting} onClick={submitForm} color="primary">
                        {t("common.saveChanges")}
                    </Button>
                    <GenericErrorMessage errors={errors} submitCount={submitCount} />
                </Box>
            </PaperInner>
        </Form>
    );
};

export default ReportTemplateForm;
