import { ColDef } from "ag-grid-community";
import { FC } from "react";
import PinnedTopRenderer from "./PinnedTopRenderer";
import { ColumnNode } from "src/modules/tracker/dto/TrackerHeader";
import { getCellColour, hexToRGB } from "src/modules/tracker/services/colorHandler";

interface OwnProps {
    value: boolean | string | { value: string | boolean; whileUpdate: boolean };
    cellConfig: ColumnNode | null;
    context: any;
    parentUnitColour: string | undefined;
    colDef: ColDef;
    node: any;
    data: any;
    choices: { value: number; option: string; colour?: string }[];
    allowAbsent: boolean;
}

export const TextConversionRenderer: FC<OwnProps> = ({
    parentUnitColour,
    cellConfig,
    value: firstValue,
    context,
    colDef,
    data,
    node,
    choices,
    allowAbsent,
}) => {
    const { field } = colDef;

    if (node.rowPinned) {
        return (
            <PinnedTopRenderer
                field={field}
                value={field && data ? data[field] : ""}
                context={context}
            />
        );
    }

    let value: any = "";
    let whileUpdate = false;

    if (typeof firstValue === "object" && firstValue !== null && firstValue?.value !== undefined) {
        value = firstValue.value;
        whileUpdate = true;
    } else {
        value = firstValue;
        whileUpdate = false;
    }

    const colour = getCellColour(value, cellConfig, parentUnitColour, context.relatedData, false);

    const backgroundColor =
        value === null || value === ""
            ? "transparent"
            : colour?.colour && colour?.opacity !== null && colour?.opacity < 100
              ? hexToRGB(parentUnitColour, colour.opacity)
              : colour.colour;

    let availableValues = choices?.map(c => c.option);

    if (allowAbsent) {
        availableValues = availableValues.concat(["a", "A", "x", "X", "u", "U"]);
    }

    const displayName = value === false ? "A" : value;

    return (
        <div title={displayName} className={`ag-value-cell-label`} style={{ backgroundColor }}>
            {displayName}
            {whileUpdate && (
                <span
                    className={`ag-value-cell-status${
                        whileUpdate ? " ag-value-cell-status--update" : ""
                    }`}
                />
            )}
            {value && !availableValues.includes(value) ? (
                <span className={`ag-value-cell-status${" ag-value-cell-status--error"}`} />
            ) : (
                <></>
            )}
        </div>
    );
};
