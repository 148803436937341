import { Button, Grid } from "@mui/material";
import { AutocompleteField } from "src/forms/Autocomplete";
import PromptDialog from "src/forms/PromptDialog";
import { StudentModel } from "src/orm/models/Student";
import { Field, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ClassActions } from "../../store/actions";
import { SingleClassForm } from "../../containers/AddClass/ManualExistingStudentsContainer";
import Dialog from "src/components/Dialog";
import StudentsTable from "../Common/StudentsTable";
import { getSchoolAccountId } from "src/services/url";

const dispatchActions = (dispatch: Dispatch) => ({
    clearStudents: () => {
        dispatch(ClassActions.clearStudents());
    },
});
const ChooseStudentsModal = ({
    open,
    handleClose,
    values,
    setFieldValue,
}: FormikProps<SingleClassForm> & { open: boolean; handleClose: () => void }) => {
    const dispatch = useDispatch();
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);
    const { t } = useTranslation();
    const { clearStudents } = dispatchActions(dispatch);

    const handleRemoveDialogOpen = (itemId: number): void => {
        setRemoveDialogOpened(true);
        setRemoveId(itemId);
    };
    const handleRemoveDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            const newStudents = values.students
                .map((s, index) => ({ ...s, index }))
                .filter((student: StudentModel & { index: number }) => student.id !== removeId);
            if (newStudents.length === 0) {
                clearStudents();
            }
            setFieldValue("students", newStudents);
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    useEffect(() => {
        if (values.tmpStudentsAutocomplete !== "") {
            setFieldValue("students", [...values.students, values.tmpStudentsAutocomplete]);
            setFieldValue("tmpStudentsAutocomplete", "");
        }
    }, [setFieldValue, values.students, values.tmpStudentsAutocomplete]);

    const getCurrentStudentsList = () => values.students.map(s => s.id);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                title={t("class.addClass.chooseStudents")}
                underTitle={
                    <Grid container>
                        <Grid item sm={4}>
                            <Field
                                apiPathName={`school/${getSchoolAccountId()}/search/students`}
                                name="tmpStudentsAutocomplete"
                                placeholder={t("class.addClass.searchStudentAutoPlaceholder")}
                                label={t("class.addClass.searchStudentAutoPlaceholder")}
                                helperText={t(
                                    "class.addClass.searchStudentAutoPlaceholderHelperText",
                                )}
                                component={AutocompleteField}
                                multiple={false}
                                exclude={getCurrentStudentsList()}
                                resultCallback={results => {
                                    return results.map(val => ({
                                        ...val,
                                        name: `${val.firstName} ${val.lastName}`,
                                        displayName: `${val.firstName} ${val.lastName}`,
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                actions={
                    <Button onClick={handleClose} color="primary">
                        {t("common.continue")}
                    </Button>
                }
            >
                <StudentsTable
                    students={values.students.map((s, index) => ({ ...s, index }))}
                    handleDelete={handleRemoveDialogOpen}
                />
            </Dialog>
            <PromptDialog open={removeDialogOpened} onClose={handleRemoveDialogClose}>
                {t("class.addClass.deleteStudentPrompt")}
            </PromptDialog>
        </>
    );
};
export default ChooseStudentsModal;
