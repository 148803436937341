import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ClassTrackerRelatedObject } from "src/modules/tracker/dto/TrackerRelated";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_SNAPSHOT_RELATED_QUERY = "classTrackerSnapshotRelatedData";

export const useClassTrackerSnapshotRelatedData = (id: number, snapshotId: string) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CLASS_TRACKER_SNAPSHOT_RELATED_QUERY, schoolAccountId, id, snapshotId],
        queryFn: async (): Promise<ClassTrackerRelatedObject | null> => {
            if (id && snapshotId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker/${id}/snapshot/${snapshotId}/related-data/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
