import { AnyAction } from "redux";
import { ORM, ORMCommonState } from "redux-orm";
import orm from ".";
import { classReducer } from "./models/Class/reducers";
import { gradeReducer } from "./models/Grade/reducers";
import { qualificationReducer } from "./models/Qualification/reducers";
import { specificationReducer } from "./models/Specification/reducers";
import { specificationAttributeReducer } from "./models/SpecificationAttribute/reducers";
import { studentReducer } from "./models/Student/reducers";
import { subjectAreaReducer } from "./models/SubjectArea/reducers";
import { subscriptionReducer } from "./models/Subscription/reducers";
import { subscriptionHistoryReducer } from "./models/SubscriptionHistory/reducers";
import { userReducer } from "./models/User/reducers";
import { ModelReducer } from "./types";
import { invoiceReducer } from "./models/Invoice/reducers";
import { classTrackerReducer } from "./models/ClassTracker/reducers";
import { topicReducer } from "./models/Topic/reducers";
import { classTrackerGroupReducer } from "./models/ClassTrackerGroup/reducers";
import { reportTemplateReducer } from "./models/ReportTemplate/reducers";

import { snapshotReportReducer } from "./models/SnapshotReport/reducers";

const createReducer = (orm: ORM<ORMCommonState>, ...reducers: ModelReducer[]) => {
    return (state: ORMCommonState, action: AnyAction) => {
        const session = orm.session(state || orm.getEmptyState());
        reducers.forEach(r => {
            // eslint-disable-next-line no-prototype-builtins
            if (r.hasOwnProperty(action.type)) {
                r[action.type](action, session);
            }
        });
        return session.state;
    };
};

export const reducer = createReducer(
    orm,
    subjectAreaReducer,
    qualificationReducer,
    specificationReducer,
    specificationAttributeReducer,
    userReducer,
    studentReducer,
    gradeReducer,
    classReducer,
    subscriptionReducer,
    subscriptionHistoryReducer,
    invoiceReducer,
    classTrackerReducer,
    subjectAreaReducer,
    topicReducer,
    classTrackerGroupReducer,
    reportTemplateReducer,
    snapshotReportReducer,
);
