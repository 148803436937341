import { ColumnConfigTypes } from "./headerGenerator";

const mapTypes = [ColumnConfigTypes.MTG, ColumnConfigTypes.TEACHER_JUDGEMENT];

const shouldMapValues = params => {
    const headerParams = params.column.getColDef().headerComponentParams;
    if (headerParams) {
        const columnConfigType = headerParams.columnConfigType;
        const columnType = headerParams.columnType;
        if (mapTypes.includes(columnConfigType) && columnType === "grade") {
            return true;
        }
    }
    return false;
};

const getGrades = relatedData =>
    relatedData?.courseGrades && relatedData?.courseGrades.length > 0
        ? relatedData?.courseGrades
        : relatedData?.grades;

export const processAnalysisCopyToClipboard = (params, relatedData, header) => {
    const columns = header?.columns;
    if (columns) {
        const gradeTypeId = columns[params.column.colId]?.gradeTypeId;

        const grades = relatedData.gradeTypes.find(gt => gt.id === gradeTypeId);
        if (grades) {
            const courseGrades = grades.courseGrades || grades.grades;
            const value = courseGrades
                ? courseGrades.find(g => g.id === parseInt(params.value))
                : null;
            if (value) return value.name;
        }
    }

    return params.value;
};

export const processCopyToClipboard = (params, relatedData) => {
    let finalValue = typeof params.value === "object" ? params.value?.value : params.value;
    if (shouldMapValues(params)) {
        const grades = getGrades(relatedData);
        const value = grades.find(g => g.id === parseInt(finalValue));
        if (value) finalValue = value.name;
    }
    if (relatedData.fineGradeEnabled) {
        const colId = params.column.getColId();
        const fineGrade = params.node?.data?.metaColumnsConfig?.[colId]?.fineGrade || "";
        return (finalValue || "") + fineGrade;
    }
    return finalValue ?? "";
};

export const processClipboardPaste = (params, relatedData) => {
    let finalValue = params.value;
    let paramsValue = params.value;
    let fineGrade = "";
    if (relatedData.fineGradeEnabled) {
        const lastChar = paramsValue.slice(-1);
        if (["-", "+"].includes(lastChar)) {
            fineGrade = lastChar;
            paramsValue = paramsValue.slice(0, -1);
        }
    }
    if (shouldMapValues(params)) {
        const grades = getGrades(relatedData);
        const value = grades.find(g => g.name === paramsValue);
        if (value) {
            finalValue = value.id;
        } else {
            finalValue = null;
        }
    }
    if (paramsValue === "") {
        finalValue = null;
    }
    if (relatedData.fineGradeEnabled) {
        return { value: finalValue, whileUpdate: true, fineGrade };
    }
    return finalValue;
};
