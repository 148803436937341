import { useFormContext, Controller } from "react-hook-form";
import {
    Button,
    DialogActions,
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
    styled,
} from "@mui/material";
import { FC, RefAttributes, forwardRef } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Tooltip } from "@mui/material";
import { mdiClose, mdiInformationVariant } from "@mdi/js";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import { format, parse } from "date-fns";

const StyledDialogActions = styled(DialogActions)({
    paddingTop: "0 !important",
});

const DatePickerActionBar = ({ onAccept, onCancel }: PickersActionBarProps) => {
    const { t } = useTranslation();
    return (
        <StyledDialogActions>
            <Button color="primary" size="medium" onClick={onCancel}>
                {t("common.cancel")}
            </Button>
            <Button color="primary" size="medium" onClick={onAccept}>
                {t("common.ok")}
            </Button>
        </StyledDialogActions>
    );
};
interface IProps {
    name: string;
    tooltip?: string;
}

const WrappedDatePicker: FC<any> = ({ ...props }) => {
    return (
        <MobileDatePicker
            DialogProps={{
                sx: {
                    ".MuiDayPicker-slideTransition": {
                        minHeight: 230,
                    },
                },
            }}
            {...props}
        />
    );
};

const RHFDatePicker: React.ForwardRefExoticComponent<
    Omit<IProps & TextFieldProps & RefAttributes<any>, "ref"> & RefAttributes<any>
> = forwardRef(({ name, tooltip, ...other }, ref) => {
    const { control, setValue, getValues } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <WrappedDatePicker
                        fieldValue={field.value}
                        label={other.label}
                        showToolbar={false}
                        inputFormat="dd/MM/yyyy"
                        value={
                            getValues(name)
                                ? parse(getValues(name), "dd/MM/yyyy", new Date())
                                : null
                        }
                        onChange={(newValue: Date) => {
                            if (newValue) {
                                setValue(name, format(newValue, "dd/MM/yyyy"));
                            }
                        }}
                        components={{
                            ActionBar: DatePickerActionBar,
                        }}
                        renderInput={(params: any) => (
                            <>
                                <TextField
                                    fullWidth
                                    helperText={error?.message}
                                    {...other}
                                    {...field}
                                    inputRef={ref}
                                    {...params}
                                    error={!!error}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {(tooltip || params.inputProps.value !== "") && (
                                                    <InputAdornment position="end">
                                                        {params.inputProps.value !== "" && (
                                                            <IconButton
                                                                edge="end"
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    setValue(name, "");
                                                                }}
                                                            >
                                                                <Icon path={mdiClose} />
                                                            </IconButton>
                                                        )}
                                                        {tooltip && (
                                                            <Tooltip title={tooltip || ""}>
                                                                <IconButton edge="end">
                                                                    <Icon
                                                                        path={mdiInformationVariant}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </InputAdornment>
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                            </>
                        )}
                    />
                );
            }}
        />
    );
});
RHFDatePicker.displayName = "RHFDatePicker";
export default RHFDatePicker;
