import COLORS from "src/styles/colors";
import { styled } from "@mui/material";

export const ChooseButton = styled("button")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    backgroundColor: COLORS.VERY_LIGHT_GREY_1,
    cursor: "pointer",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    textAlign: "left",
    border: "0 none",
    outline: "0 none",
    width: "100%",
    height: "100%",
    position: "relative",
    padding: theme.spacing(3.75, 5),
    "&:hover": {
        backgroundColor: COLORS.VERY_LIGHT_GREY_5,
    },
    "&.active": {
        backgroundColor: COLORS.VERY_LIGHT_GREY_5,
        "&::before": {
            content: "''",
            backgroundColor: COLORS.BLUE_1,
            width: 3,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            pointerEvents: "none",
        },
    },
    "& .row": {
        display: "flex",
        alignItems: "center",
    },
    "& .icon": {
        color: COLORS.GREY_2,
        fontSize: 24,
        width: 24,
        height: 24,
        marginRight: theme.spacing(1.75),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(-0.25),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .icon.active": {
        color: COLORS.GREY_1,
    },
    "& .title": {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
    },
    "& .subtitle": {
        color: COLORS.GREY_2,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1.5,
        marginTop: theme.spacing(1),
    },
    "&. subtitle.active": {
        color: COLORS.GREY_1,
    },
}));
