import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";

export const SUBJECT_AREAS_LIST_QUERY = "subjectAreasList";

export const useSubjectAreasList = () => {
    return useQuery({
        queryKey: [SUBJECT_AREAS_LIST_QUERY],
        queryFn: async (): Promise<OrmSubjectArea[]> => {
            const { data } = await axiosInstance.get(`api/common/subject-areas/`);
            return data || [];
        },
    });
};
