import Dialog from "src/components/Dialog";
import CompareSnapshots from "./Snapshot/CompareSnapshots";
import CreateSnapshot from "./Snapshot/CreateSnapshot";
import SnapshotModalMenu from "./Snapshot/SnapshotModalMenu";
import ViewSnapshots from "./Snapshot/ViewSnapshots";
import { FC, useEffect, useState } from "react";
import { mdiBlur, mdiBlurLinear, mdiCrosshairs } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { SnapshotData } from "src/context/TrackerContext";

export enum SnapshotModalActions {
    MENU = "menu",
    CREATE = "create",
    VIEW_LIST = "list",
    COMPARE = "compare",
}

export const IconTypes = {
    [SnapshotModalActions.CREATE]: mdiBlur,
    [SnapshotModalActions.COMPARE]: mdiBlurLinear,
    [SnapshotModalActions.VIEW_LIST]: mdiCrosshairs,
};

export interface ShortSnapshotItem {
    id: number;
    created: number;
    name: string;
    hasReports: boolean;
    canRemove?: boolean;
}

interface OwnProps {
    open: boolean;
    handleClose: () => void;
    snapshots: ShortSnapshotItem[];
    availableTypes: SnapshotModalActions[];
    hasForecast?: boolean;
    handleSnapshotDataChange: (snapshotData: SnapshotData) => void;
    handleCreateSnapshot: (name: string) => void;
    handleSnapshotRemove: (id: number) => void;
}

const SnapshotModal: FC<OwnProps> = ({
    open,
    availableTypes,
    snapshots,
    hasForecast,
    handleClose,
    handleSnapshotDataChange,
    handleCreateSnapshot,
    handleSnapshotRemove,
}) => {
    const { t } = useTranslation();
    const [modalAction, setModalAction] = useState<SnapshotModalActions>(SnapshotModalActions.MENU);

    const isActionTypeAvailable = (type: SnapshotModalActions) => {
        return !!availableTypes.find(at => at === type);
    };

    const modalTitle =
        modalAction === SnapshotModalActions.CREATE
            ? t("tracker.snapshot.createSnapshotHeader")
            : modalAction === SnapshotModalActions.COMPARE
              ? t("tracker.snapshot.compareSnapshotHeader")
              : t("tracker.snapshot.viewHeader");

    useEffect(() => {
        if (open && modalAction !== SnapshotModalActions.MENU)
            setModalAction(SnapshotModalActions.MENU);
    }, [open]);

    return (
        <Dialog
            className="snapshotModal"
            open={open}
            onClose={() => handleClose()}
            title={modalTitle}
            contentOverflow="visible"
            actions={
                modalAction !== SnapshotModalActions.MENU ? (
                    <Button
                        color="primary"
                        onClick={() => setModalAction(SnapshotModalActions.MENU)}
                    >
                        {t("common.back")}
                    </Button>
                ) : (
                    <></>
                )
            }
        >
            {modalAction === SnapshotModalActions.MENU && (
                <SnapshotModalMenu
                    handleTypeChage={type => setModalAction(type)}
                    availableTypes={availableTypes}
                />
            )}
            {isActionTypeAvailable(SnapshotModalActions.CREATE) &&
                modalAction === SnapshotModalActions.CREATE && (
                    <CreateSnapshot
                        handleCreateSnapshot={name => {
                            handleCreateSnapshot(name);
                            handleClose();
                            // setModalAction(SnapshotModalActions.VIEW_LIST);
                        }}
                    />
                )}
            {isActionTypeAvailable(SnapshotModalActions.VIEW_LIST) &&
                modalAction === SnapshotModalActions.VIEW_LIST && (
                    <ViewSnapshots
                        snapshots={snapshots}
                        handleSnapshotView={snapshotId => {
                            handleSnapshotDataChange({ snapshot: `${snapshotId}`, compare: null });
                        }}
                        handleSnapshotRemove={handleSnapshotRemove}
                    />
                )}
            {isActionTypeAvailable(SnapshotModalActions.COMPARE) &&
                modalAction === SnapshotModalActions.COMPARE && (
                    <CompareSnapshots
                        disableForecast={!hasForecast}
                        snapshots={snapshots}
                        handleCompare={snapshotData => {
                            handleSnapshotDataChange(snapshotData);
                        }}
                    />
                )}
        </Dialog>
    );
};

export default SnapshotModal;
