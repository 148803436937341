import { MenuItem, Paper, TextField, MenuList } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Downshift from "downshift";

const useStyles = makeStyles(() => ({
    paper: {
        position: "absolute",
        zIndex: 1,
        left: 0,
        right: 0,
    },
    menuList: {
        overflow: "auto",
        maxHeight: 300,
    },
}));

const renderInput = inputProps => {
    const { placeholder, label, helperText, error, disabled, InputProps } = inputProps;

    return (
        <TextField
            fullWidth
            placeholder={placeholder}
            label={label}
            disabled={disabled}
            helperText={helperText}
            error={error}
            inputProps={{ ...InputProps }}
            margin="none"
        />
    );
};

const renderSuggestion = params => {
    const { item, index, itemProps, highlightedIndex } = params;
    const isHighlighted = highlightedIndex === index;
    return (
        <MenuItem {...itemProps} key={item.id} selected={isHighlighted} component="div">
            {item.displayName ? item.displayName : item.name}
        </MenuItem>
    );
};

function DownshiftRender(props) {
    const classes = useStyles();
    const { availableItems, disabled, placeholder, label, helperText, error, multiple, ...rest } =
        props;
    return (
        <Downshift {...rest}>
            {({
                getInputProps,
                getItemProps,
                selectedItem,
                clearSelection,
                highlightedIndex,
                inputValue,
                isOpen,
            }) => (
                <div>
                    {renderInput({
                        placeholder,
                        label,
                        helperText,
                        disabled,
                        error,
                        InputProps: {
                            ...getInputProps({
                                value: inputValue ? inputValue : "",
                                onChange: e => {
                                    if (e.target.value === "" && !multiple) {
                                        clearSelection();
                                    }
                                },
                            }),
                        },
                    })}
                    {isOpen && (
                        <Paper elevation={8} className={classes.paper}>
                            {availableItems.length > 0 && (
                                <MenuList className={classes.menuList}>
                                    {availableItems.map((item, index) =>
                                        renderSuggestion({
                                            item,
                                            index,
                                            itemProps: getItemProps({
                                                item,
                                            }),
                                            highlightedIndex,
                                            selectedItem,
                                        }),
                                    )}
                                </MenuList>
                            )}
                        </Paper>
                    )}
                </div>
            )}
        </Downshift>
    );
}

export default DownshiftRender;
