import { shallowEqual, useSelector } from "react-redux";
import { classTrackerWithFilterSelector } from "../selectors/ClassTrackerSelectors";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import { AppState } from "src/store/reducers";

export const useAllGroupTrackers = (specificationId, classTrackerGroupId) => {
    const { classTrackers: allClassTrackers }: { classTrackers: OrmClassTracker[] } = useSelector(
        (state: AppState) => ({
            classTrackers: classTrackerWithFilterSelector(state, ct => {
                return (
                    ct.specification?.id === specificationId &&
                    ct.classTrackerGroupId === classTrackerGroupId
                );
            }),
        }),
        shallowEqual,
    );
    return { allClassTrackers };
};
