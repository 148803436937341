import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ColumnNode, HeaderNodeWithChildren } from "src/modules/tracker/dto/TrackerHeader";
import { getSchoolAccountId } from "src/services/url";

export const ANALYSIS_GRADE_SUMMARY_HEADER_QUERY = "analysisGradeSummaryHeaderQuery";

export interface AnalysisGradeSummaryHeaderObject {
    header: HeaderNodeWithChildren[];
    maxLevel: number;
    columns: {
        [key: string]: ColumnNode;
    };
}

export const useAnalysisGradeSummaryHeader = (cohort: number, yearGroup: number) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [ANALYSIS_GRADE_SUMMARY_HEADER_QUERY, schoolAccountId, cohort, yearGroup],
        queryFn: async (): Promise<AnalysisGradeSummaryHeaderObject | null> => {
            if (cohort && yearGroup) {
                const { data } = await axiosInstance.get(
                    `school/${schoolAccountId}/analysis/grade-summary/${cohort}/${yearGroup}/header/`,
                );
                return data || null;
            }
            return null;
        },
    });
};
