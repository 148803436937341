import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { PublishedAssessmentUnitModel } from "../../models/AssessmentUnitModel";
import { getSchoolAccountId } from "src/services/url";

export const PUBLISHED_ASSESSMENT_UNITS_QUERY = "publishedAssessmentUnitsQuery";

export const usePublishedAssessmentUnits = (specificationId: number, tier: TiersTypes) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [PUBLISHED_ASSESSMENT_UNITS_QUERY, schoolAccountId, specificationId, tier],
        queryFn: async (): Promise<PublishedAssessmentUnitModel[] | null> => {
            if (specificationId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/assessments/published/${specificationId}/${tier || "-"}/units/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
