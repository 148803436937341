import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmQualification } from ".";
import { OrmQualificationActions, OrmQualificationActionTypes } from "./actions";

export const qualificationReducer: ModelReducer = {
    [OrmQualificationActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmQualificationActions.bulkImport>,
        session,
    ) => {
        const { data, subjectArea } = action.payload;
        const { Qualification } = session;
        Qualification.all()
            .filter((value: OrmQualification) => value.subjectArea === subjectArea)
            .delete();

        data.forEach(q => Qualification.create(ormToRef(q, { defaults: { subjectArea } })));
    },
};
