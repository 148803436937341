import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmSpecificationAttribute } from ".";
import { OrmSpecificationAttributeActions, OrmSpecificationAttributeActionTypes } from "./actions";

export const specificationAttributeReducer: ModelReducer = {
    [OrmSpecificationAttributeActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmSpecificationAttributeActions.bulkImport>,
        session,
    ) => {
        const { data, specification } = action.payload;
        const { SpecificationAttribute } = session;

        const specificationAttribute = SpecificationAttribute.all().filter(
            sa => sa.specification === specification,
        );

        if (specificationAttribute?.count() === 0) {
            SpecificationAttribute.all()
                .filter((value: OrmSpecificationAttribute) => value.specification === specification)
                .delete();

            SpecificationAttribute.create(ormToRef(data, { defaults: { specification } }));
        }
    },
};
