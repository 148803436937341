import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { getXeroInvoiceEndpoint } from "../../api/getXeroInvoice";
import { getInvoiceListEndpoint, InvoiceListResponse } from "../../api/invoiceList";

export enum InvoiceActionsTypes {
    GET_INVOICE_LIST = "[subscription] GET INVOICE LIST",
    GET_INVOICE_LIST_SUCCESS = "[subscription] GET INVOICE LIST SUCCESS",
    GET_XERO_INVOICE = "[subscription] GET XERO INVOICE",
    GET_XERO_INVOICE_SUCCESS = "[subscription] GET XERO INVOICE SUCCESS",
}

export const InvoiceApiEndpoints = {
    getInvoiceList: createActionObject(
        getInvoiceListEndpoint,
        InvoiceActionsTypes.GET_INVOICE_LIST_SUCCESS,
    ),
    getXeroInvoice: createActionObject(
        getXeroInvoiceEndpoint,
        InvoiceActionsTypes.GET_XERO_INVOICE_SUCCESS,
    ),
};

export const InvoiceRawActions = (
    InvoiceEndpoints: Record<Keys<typeof InvoiceApiEndpoints>, ApiActionMeta>,
) => ({
    getInvoiceList: () =>
        createApiAction(
            InvoiceActionsTypes.GET_INVOICE_LIST,
            undefined,
            InvoiceEndpoints.getInvoiceList,
        ),
    getInvoiceListSuccess: (payload: ApiCallbackPayload<InvoiceListResponse>) =>
        createAction(InvoiceActionsTypes.GET_INVOICE_LIST_SUCCESS, payload),
    getXeroInvoice: (id: string) =>
        createApiAction(
            InvoiceActionsTypes.GET_XERO_INVOICE,
            {
                values: {},
                params: { id },
            },
            InvoiceEndpoints.getXeroInvoice,
        ),
    getXeroInvoiceSuccess: (payload: ApiCallbackPayload<Blob>) =>
        createAction(InvoiceActionsTypes.GET_XERO_INVOICE_SUCCESS, payload),
});
