import { Fragment, useState, useEffect } from "react";
import { Typography, Paper, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SubscriptionsActions } from "../store/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { subscriptionHistorySingleWithFilterSelector } from "../selectors/SubscriptionSelectors";
import { AppState } from "src/store/reducers";
import { Dispatch } from "redux";
import { OrmSubscriptionHistory } from "src/orm/models/SubscriptionHistory";
import { useMount } from "src/hooks/useMount";
import PaymentSuccess from "./PaymentSuccess";
import MakePaymentForm from "../components/MakePaymentForm";
import AppContainer from "src/components/AppContainer";
import { ApiData, ApiStatus } from "src/api/constants";
import SummaryBox from "../components/SummaryBox";
import { OrmQualification } from "src/orm/models/Qualification";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { theme } from "src/styles/theme";
import COLORS from "src/styles/colors";
import PaperInner from "src/components/PaperInner";
import AlreadyPaid from "./AlreadyPaid";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router";
import { useSchoolDetails } from "src/modules/school/hooks/query/SchoolDetails/useSchoolDetails";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY as string);

const dispatchActions = (dispatch: Dispatch) => ({
    getPaymentDetails: (subscriptionId: number) => {
        dispatch(SubscriptionsActions.getPaymentDetails(subscriptionId));
    },
    confirmPaymentIntent: (intentId, subscriptionId) => {
        dispatch(SubscriptionsActions.confirmPaymentIntent(intentId, subscriptionId));
    },
    clearPaymentIntent: () => {
        dispatch(SubscriptionsActions.clearPaymentIntent());
    },
});

const MakePayment = () => {
    const params = useParams();
    const subscriptionId = params.id;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [paymentCompleted, setPaymentCompleted] = useState<boolean>(false);
    const [paidByCoupon, setPaidBuCoupon] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const { getPaymentDetails, clearPaymentIntent } = dispatchActions(dispatch);
    const {
        paymentDetails,
        apiConfirm,
    }: {
        paymentDetails: OrmSubscriptionHistory;
        apiConfirm: ApiData;
    } = useSelector(
        (state: AppState) => ({
            paymentDetails: subscriptionHistorySingleWithFilterSelector(
                state,
                (sh: OrmSubscriptionHistory) => `${sh.subscriptionId}` === `${subscriptionId}`,
            ),
            apiConfirm: state.api.subscription.confirmPaymentIntent,
        }),
        shallowEqual,
    );

    const { data: schoolDetails } = useSchoolDetails();

    useMount(() => {
        getPaymentDetails(parseInt(subscriptionId || ""));
    });

    useEffect(() => {
        if (apiConfirm.status === ApiStatus.SUCCESS) {
            setPaymentCompleted(true);
            clearPaymentIntent();
        }
    }, [apiConfirm, clearPaymentIntent]);

    return (
        <AppContainer>
            {!paymentCompleted && (
                <Fragment>
                    <Typography variant="h1" component="h1" gutterBottom>
                        {t("subscription.makePayment.header")}
                    </Typography>
                    <Paper>
                        <PaperInner variant="subscription" border="bottom" color="lightGrey">
                            <Box display="flex">
                                {paymentDetails.id > 0 && (
                                    <Box>
                                        <Typography variant="h5" component="h2" gutterBottom>
                                            {t("subscription.makePayment.placingOrder")}
                                        </Typography>
                                        <SummaryBox
                                            subjectArea={
                                                paymentDetails.subjectArea as OrmSubjectArea
                                            }
                                            qualifications={
                                                paymentDetails.qualifications as OrmQualification[]
                                            }
                                            period={paymentDetails.subscriptionLength}
                                            type={paymentDetails.subscriptionType}
                                            oneline={true}
                                        />
                                    </Box>
                                )}
                                {paymentDetails.id > 0 && paymentDetails.schoolUser && (
                                    <Box mt={4.625} ml={4}>
                                        <Box
                                            fontSize={14}
                                            fontWeight={theme.typography.fontWeightMedium}
                                            lineHeight={1.75}
                                            mb={0.5}
                                        >
                                            {t("subscription.makePayment.requestedBy")}
                                        </Box>
                                        <Box>
                                            <Fragment>
                                                <Box fontSize={14} mb={0.375}>
                                                    {`${paymentDetails.schoolUser.firstName} ${paymentDetails.schoolUser.lastName}`}
                                                </Box>
                                                <Box
                                                    fontSize={11}
                                                    fontStyle="italic"
                                                    color={COLORS.BLUE_3}
                                                >
                                                    {paymentDetails.schoolUser.email}
                                                </Box>
                                            </Fragment>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </PaperInner>
                        {paymentDetails?.id > 0 && schoolDetails && (
                            <Elements stripe={stripePromise}>
                                <Fragment>
                                    <MakePaymentForm
                                        paymentDetails={paymentDetails}
                                        schoolDetails={schoolDetails}
                                        isProcessing={isProcessing}
                                        setIsProcessing={setIsProcessing}
                                        handlePaymentComplete={() => {
                                            setPaymentCompleted(true);
                                        }}
                                        onAlreadyPaidHandle={() => {
                                            setPaymentCompleted(true);
                                            setPaidBuCoupon(true);
                                            clearPaymentIntent();
                                        }}
                                    />
                                </Fragment>
                            </Elements>
                        )}
                    </Paper>
                </Fragment>
            )}
            {paidByCoupon && paymentDetails && (
                <AlreadyPaid
                    subjectArea={paymentDetails.subjectArea}
                    qualifications={paymentDetails.qualifications || []}
                    period={paymentDetails.subscriptionLength}
                    type={paymentDetails.subscriptionType}
                />
            )}
            {paymentCompleted && <PaymentSuccess paymentDetails={paymentDetails} />}
        </AppContainer>
    );
};

export default MakePayment;
