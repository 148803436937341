import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmTopic } from "src/orm/models/Topic";
import { getSchoolAccountId } from "src/services/url";

export const commonGetTopicsEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/common/specification/{specification}/topics/`,
    method: ApiRequests.GET,
};

export type CommonTopicResponse = OrmTopic[];
