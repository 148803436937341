// TODELETE (refactor TrackingHeader)

import { Box, Chip } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { OrmQualification } from "src/orm/models/Qualification";
import { OrmSpecification } from "src/orm/models/Specification";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import COLORS from "src/styles/colors";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";

interface OwnProps {
    subjectArea: OrmSubjectArea | null;
    qualification: OrmQualification | null;
    specification: OrmSpecification | null;
    yearGroup: number | null;
}

const TrackingHeaderTrackerPath: FC<OwnProps> = ({
    subjectArea,
    qualification,
    specification,
    yearGroup,
}) => {
    const { t } = useTranslation();

    return (
        <Box display="flex">
            {subjectArea && subjectArea.name && (
                <Chip style={{ backgroundColor: subjectArea.colour }} label={subjectArea.name} />
            )}
            {qualification && qualification.name && (
                <Box
                    display="inline-flex"
                    alignItems="center"
                    height={24}
                    fontSize={16}
                    fontWeight="fontWeightBold"
                    fontFamily={PRIMARY_FONT_FAMILY}
                    borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                    pl={2}
                    ml={2}
                >
                    {qualification.name}
                </Box>
            )}
            {specification && specification.name && (
                <Box
                    display="inline-flex"
                    alignItems="center"
                    height={24}
                    fontSize={16}
                    fontWeight="fontWeightBold"
                    fontFamily={PRIMARY_FONT_FAMILY}
                    borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                    pl={2}
                    ml={2}
                >
                    {specification.parentName ? `${specification.parentName} - ` : ""}
                    {specification.name}
                </Box>
            )}
            {yearGroup !== null && yearGroup !== undefined && yearGroup > -1 && (
                <Box
                    display="inline-flex"
                    alignItems="center"
                    height={24}
                    fontSize={16}
                    fontWeight="fontWeightBold"
                    fontFamily={PRIMARY_FONT_FAMILY}
                    borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                    pl={2}
                    ml={2}
                >
                    {yearGroup === 0
                        ? t("common.yg0")
                        : `${t("class.list.yearGroup")} ${yearGroup}`}
                </Box>
            )}
        </Box>
    );
};

export default TrackingHeaderTrackerPath;
