import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PaperInner from "src/components/PaperInner";
import HeadingCounter from "src/components/HeadingCounter";
import { useEmail } from "../hooks/useEmail";
import COLORS from "src/styles/colors";
import AddEmailForm from "./AddEmailForm";
import Icon from "src/components/Icon";
import { mdiDelete, mdiSend, mdiStarOutline } from "@mdi/js";
import { useRemoveMergeRequestEmail } from "../hooks/useRemoveMergeRequestEmail";
import { useRemoveEmail } from "../hooks/useRemoveEmail";
import ChangePasswordForm from "./ChangePasswordForm";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";
import { useState } from "react";
import { useRemoveAccount } from "../hooks/useRemoveAccount";
import { useMakeEmailPrimary } from "../hooks/useMakeEmailPrimary";
import { useResendMergeRequestEmail } from "../hooks/useResendMergeRequestEmail";
import { useResendEmail } from "../hooks/useResendEmail";

const MyAccountTab = () => {
    const { t } = useTranslation();
    const { data: emails } = useEmail();
    const { mutate: removeMergeRequestEmail } = useRemoveMergeRequestEmail();
    const { mutate: removeEmail } = useRemoveEmail();
    const { mutate: resendMergeRequestEmail } = useResendMergeRequestEmail();
    const { mutate: resendEmail } = useResendEmail();
    const { mutate: setPrimaryEmail } = useMakeEmailPrimary();

    const primaryEmail = emails?.emails?.find(({ isPrimary }) => isPrimary)?.email;
    const renderPendingEmail = (id: number, email: string, isMergeEmail?: boolean) => (
        <Box
            key={id}
            sx={{
                borderColor: "divider",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                py: 2,
            }}
        >
            <Typography sx={{ fontWeight: "fontWeightMedium", color: COLORS.GREY_3 }}>
                {email}{" "}
                <Typography
                    component="em"
                    sx={{ fontWeight: "fontWeightNormal", color: COLORS.GREY_3 }}
                >
                    ({t("account.myAccountTab.pendingConfirmation")})
                </Typography>
            </Typography>
            <Box sx={{ mt: 1 }}>
                <Button
                    variant="text"
                    startIcon={<Icon path={mdiDelete} />}
                    disableRipple
                    onClick={() => (isMergeEmail ? removeMergeRequestEmail(id) : removeEmail(id))}
                >
                    {isMergeEmail
                        ? t("account.myAccountTab.deleteMergeRequestBtn")
                        : t("account.myAccountTab.deleteBtn")}
                </Button>
                <Button
                    variant="text"
                    startIcon={<Icon path={mdiSend} />}
                    disableRipple
                    onClick={() => (isMergeEmail ? resendMergeRequestEmail(id) : resendEmail(id))}
                >
                    {t("account.myAccountTab.resendEmail")}
                </Button>
            </Box>
        </Box>
    );

    const { mutate: deleteAccount } = useRemoveAccount();
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const handleDeleteDialogClose = (confirmed: boolean): void => {
        if (confirmed === true) {
            deleteAccount();
        }
        setRemoveDialogOpened(false);
    };

    return (
        <>
            <PaperInner border="bottom">
                <HeadingCounter number="1">{t("account.myAccountTab.emails")}</HeadingCounter>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <Box color={COLORS.GREY_4} fontSize={11}>
                            {t("account.myAccountTab.primaryEmail")}
                        </Box>
                        <Typography fontSize={18} fontWeight="fontWeightMedium" mt={0.25}>
                            {primaryEmail}
                        </Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Box color={COLORS.GREY_4} fontSize={11}>
                            {t("account.myAccountTab.additionalEmails")}
                        </Box>
                        {emails && (
                            <Box
                                sx={{
                                    borderColor: "divider",
                                    borderTopWidth: 1,
                                    borderTopStyle: "solid",
                                    mt: 2,
                                }}
                            >
                                {emails?.emails
                                    .filter(({ isPrimary }) => !isPrimary)
                                    .map(({ confirmed, id, email }) => {
                                        if (confirmed) {
                                            return (
                                                <Box
                                                    key={id}
                                                    sx={{
                                                        borderColor: "divider",
                                                        borderBottomWidth: 1,
                                                        borderBottomStyle: "solid",
                                                        py: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{ fontWeight: "fontWeightMedium" }}
                                                    >
                                                        {email}
                                                    </Typography>
                                                    <Box sx={{ display: "flex", mt: 1 }}>
                                                        <Button
                                                            variant="text"
                                                            startIcon={<Icon path={mdiDelete} />}
                                                            disableRipple
                                                            onClick={() => removeEmail(id)}
                                                        >
                                                            {t("account.myAccountTab.deleteBtn")}
                                                        </Button>
                                                        <Button
                                                            variant="text"
                                                            startIcon={
                                                                <Icon path={mdiStarOutline} />
                                                            }
                                                            disableRipple
                                                            onClick={() => setPrimaryEmail(id)}
                                                        >
                                                            {t(
                                                                "account.myAccountTab.setPrimaryEmailBtn",
                                                            )}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            );
                                        } else {
                                            return renderPendingEmail(id, email);
                                        }
                                    })}
                                {emails?.mergeEmails.map(({ id, email }) => {
                                    return renderPendingEmail(id, email, true);
                                })}
                            </Box>
                        )}
                    </Grid>
                    <Grid item sm={4}>
                        <AddEmailForm />
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner border="bottom">
                <HeadingCounter number="2">
                    {t("account.myAccountTab.resetPassword")}
                </HeadingCounter>
                <ChangePasswordForm />
            </PaperInner>
            <PaperInner>
                <HeadingCounter number="3">
                    {t("account.myAccountTab.deleteAccount")}
                </HeadingCounter>
                <Button type="submit" color="inherit" onClick={() => setRemoveDialogOpened(true)}>
                    {t("account.myAccountTab.deleteAccountBtn")}
                </Button>
                <Typography
                    sx={{ color: COLORS.BLUE_3, mt: 1.5, fontSize: 12, fontStyle: "italic" }}
                >
                    {t("account.myAccountTab.deleteAccountHint")}
                </Typography>
                <PromptDeleteConfirm open={removeDialogOpened} onClose={handleDeleteDialogClose}>
                    <Typography variant="h3">{t("account.myAccountTab.deleteAccount")}</Typography>
                    <Box pt={3}>{t("account.myAccountTab.deleteAccountPrompt")}</Box>
                </PromptDeleteConfirm>
            </PaperInner>
        </>
    );
};

export default MyAccountTab;
