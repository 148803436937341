import Icon from "src/components/Icon";
import clsx from "clsx";
import { mdiChevronDown } from "@mdi/js";
import { FC } from "react";

interface OwnProps {
    value: string;
    attributeValues: { id: number; name: string }[];
    context: any;
}

export const StudentAttributeRenderer: FC<OwnProps> = ({ value, attributeValues, context }) => {
    const attributeValue = (attributeValues || []).find(g => g.id === parseInt(value)) || null;
    const isComparing = context.snapshotData && context.snapshotData.compare !== null;

    return (
        <div
            className={clsx(
                "ag-student-data-cell ag-student-data-cell--border-left",
                isComparing && "ag-student-data-cell--compare",
            )}
        >
            {attributeValue?.name}
            <div className="ag-student-data-cell__icon">
                <Icon path={mdiChevronDown} size="16px" />
            </div>
        </div>
    );
};
