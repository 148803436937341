import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { AssessmentFilterModel } from "../models/AssessmentFilterModel";
import { getSchoolAccountId } from "src/services/url";

export const ASSESSMENT_FILTERS_QUERY = "assessmentFiltersQuery";

export type AssessmentFiltersResponse = AssessmentFilterModel[];

export const useAssessmentFilters = (cohortId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [ASSESSMENT_FILTERS_QUERY, schoolAccountId, cohortId],
        queryFn: async (): Promise<AssessmentFiltersResponse | null> => {
            if (cohortId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/assessments/by-cohort/${cohortId}/filters/`,
                );

                return data;
            }
            return null;
        },
    });
};
