import { apiMiddleware } from "src/api/middleware";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./epics";
import { rootReducer } from "./reducers";

const configureStore = () => {
    const epicMiddleware = createEpicMiddleware();

    const store = createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(apiMiddleware, epicMiddleware)),
    );

    epicMiddleware.run(rootEpic);

    return store;
};

const store = configureStore();

export default store;
