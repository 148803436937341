import { Fragment, ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "src/components/Dialog";
import {
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    MenuItem,
    TextField,
    Button,
    Theme,
    createStyles,
    Box,
    CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { GradeBoundariesSeriesObject } from "../../api/GradeBoundaries/getLiveGradeBoundarySeries";
import COLORS from "src/styles/colors";
import { SeriesDataObject } from "../Grid/ToolsPanel/ToolsPanel";
import { ClassTrackerRelatedObject } from "../../dto/TrackerRelated";

const RightColumn = withStyles(() =>
    createStyles({
        root: {
            position: "relative",
            "&:before": {
                content: "''",
                position: "absolute",
                pointerEvents: "none",
            },
        },
    }),
)(Grid);

const AdjustButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: COLORS.WHITE,
            width: 42,
            height: 42,
            minWidth: "unset",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            margin: 0,
            color: COLORS.LIGHT_GREY_1,
            fontFamily: theme.typography.fontFamilyPrimary,
            fontSize: theme.typography.pxToRem(40),
        },
        outlined: {
            border: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
        },
    }),
)(Button);

const VerticalCustomTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            fontSize: theme.typography.pxToRem(11),
            fontWeight: 700,
            lineHeight: 1.4,
            padding: theme.spacing(2.25, 2, 2.25, 0),
            verticalAlign: "bottom",
            maxHeight: 160,

            "& > span": {
                writingMode: "vertical-rl",
                transform: "rotate(180deg)",
            },
        },
    }),
)(TableCell);

const CustomTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            color: COLORS.BLUE_3,
            fontSize: theme.typography.pxToRem(14),
            fontStyle: "italic",
            padding: theme.spacing(0.875, 2, 0.875, 0),
        },
        body: {
            color: COLORS.GREY_1,
            fontSize: theme.typography.pxToRem(14),
            padding: theme.spacing(0.875, 2, 0.875, 0),
            whiteSpace: "nowrap",
        },
    }),
)(TableCell);

const ChangeGradeBoundaryModal = ({
    open,
    handleClose,
    relatedData,
    setGradeBoundaryFactor,
    setGradeBoundary,
    seriesData,
    selectedGradeBoundary,
    liveGradeBoundaries,
    editMode,
    isLoading,
}: {
    open: boolean;
    handleClose: (confirmed) => void;
    relatedData: ClassTrackerRelatedObject | null;
    setGradeBoundaryFactor: (factor: number) => void;
    setGradeBoundary: (gradeBoundaryId: number) => void;
    seriesData: SeriesDataObject | null;
    selectedGradeBoundary: number | null;
    liveGradeBoundaries: GradeBoundariesSeriesObject[] | null;
    editMode: boolean;
    isLoading?: boolean;
}) => {
    const handleGradeBoundaryChange: ChangeEventHandler<HTMLInputElement> = e => {
        setGradeBoundary(parseInt(e.target.value));
    };

    const decreaseGradeBoundaryFactor = seriesData => {
        const currentFactor = seriesData?.gradeBoundaryFactor || 0;
        if (currentFactor - 1 >= -10) {
            setGradeBoundaryFactor(currentFactor - 1);
        }
    };

    const increaseGradeBoundaryFactor = seriesData => {
        const currentFactor = seriesData?.gradeBoundaryFactor || 0;
        if (currentFactor + 1 <= 10) {
            setGradeBoundaryFactor(currentFactor + 1);
        }
    };

    const { t } = useTranslation();

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                title={t("tracker.grid.geadeBoundarySeries")}
                verticalBackground="right"
                verticalBackgroundCols={editMode ? 5 : undefined}
            >
                <Grid container>
                    <Grid item sm={editMode ? 6 : 12}>
                        {seriesData?.units && seriesData?.units?.length > 0 && (
                            <Box style={{ overflowX: "auto" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <VerticalCustomTableCell>
                                                <span>
                                                    {t("tracker.changeGradeBoundaries.grade")}
                                                </span>
                                            </VerticalCustomTableCell>
                                            {seriesData.units.map(unit => (
                                                <VerticalCustomTableCell key={unit.id}>
                                                    <span>{unit.name}</span>
                                                </VerticalCustomTableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {t("tracker.changeGradeBoundaries.max")}
                                            </CustomTableCell>
                                            {seriesData.units.map(unit => (
                                                <CustomTableCell key={unit.id}>
                                                    {unit.maxValue}
                                                </CustomTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {relatedData?.grades &&
                                            relatedData.grades.map((grade, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <CustomTableCell>
                                                            <Box fontWeight="fontWeightMedium">
                                                                {grade.name}
                                                            </Box>
                                                        </CustomTableCell>
                                                        {seriesData.units.map((unit, i) => (
                                                            <CustomTableCell key={i}>
                                                                {unit.values[index] || 0}
                                                            </CustomTableCell>
                                                        ))}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </Box>
                        )}
                    </Grid>
                    {editMode && (
                        <>
                            <Grid item sm={1} />
                            <RightColumn item sm={5}>
                                <Box mb={6}>
                                    <Typography component="h3" variant="h2" gutterBottom>
                                        {t("tracker.changeGradeBoundaries.changeTitle")}
                                    </Typography>
                                    <TextField
                                        label={t(
                                            "tracker.changeGradeBoundaries.changeTextFieldLabel",
                                        )}
                                        name="selectSeries"
                                        id="selectSeries"
                                        fullWidth
                                        value={selectedGradeBoundary}
                                        onChange={handleGradeBoundaryChange}
                                        select
                                    >
                                        {liveGradeBoundaries &&
                                            liveGradeBoundaries.map(lgb => (
                                                <MenuItem key={lgb.id} value={lgb.id}>
                                                    {lgb.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Box>
                                <Box mb={6}>
                                    <Typography component="h3" variant="h2" gutterBottom>
                                        {t("tracker.changeGradeBoundaries.adjustBoundaries")}
                                    </Typography>
                                    <Box display="flex">
                                        <AdjustButton
                                            disabled={seriesData?.gradeBoundaryFactor === -10}
                                            onClick={() => {
                                                decreaseGradeBoundaryFactor(seriesData);
                                            }}
                                            variant="outlined"
                                        >
                                            -
                                        </AdjustButton>
                                        <Box mr={3} ml={3} width={58}>
                                            <TextField
                                                name="adjust"
                                                label={t("tracker.changeGradeBoundaries.adjust")}
                                                disabled={true}
                                                value={seriesData?.gradeBoundaryFactor}
                                                margin="none"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                        <AdjustButton
                                            disabled={seriesData?.gradeBoundaryFactor === 10}
                                            onClick={() => {
                                                increaseGradeBoundaryFactor(seriesData);
                                            }}
                                            variant="outlined"
                                        >
                                            +
                                        </AdjustButton>
                                    </Box>
                                    <Box
                                        component="div"
                                        color={COLORS.GREY_3}
                                        fontSize={12}
                                        fontStyle="italic"
                                        lineHeight={1.67}
                                        mt={2.5}
                                    >
                                        {t("tracker.changeGradeBoundaries.adjustHint")}
                                    </Box>
                                </Box>
                                <Button
                                    color="primary"
                                    disabled={isLoading}
                                    onClick={() => handleClose(true)}
                                >
                                    {t("tracker.changeGradeBoundaries.saveClose")}
                                    {isLoading && (
                                        <Box ml={2}>
                                            <CircularProgress size={16} />
                                        </Box>
                                    )}
                                </Button>
                            </RightColumn>
                        </>
                    )}
                </Grid>
            </Dialog>
        </Fragment>
    );
};

export default ChangeGradeBoundaryModal;
