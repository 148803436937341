import Icon from "@mdi/react";
import clsx from "clsx";
import { mdiRepeat } from "@mdi/js";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { CELL_SIZE_MIN } from "src/modules/tracker/services/headerGenerator";

interface OwnProps {
    value: string;
    context: any;
}

export const StudentIsMovingRenderer: FC<OwnProps> = ({ value, context }) => {
    const isComparing = context.snapshotData && context.snapshotData.compare !== null;

    const { t }: { t: TFunction } = useTranslation();

    return value ? (
        <div
            style={{ height: isComparing ? 56 : CELL_SIZE_MIN }}
            title={t("tracker.grid.moveStudentsHint") || ""}
            className={clsx(
                "ag-student-data-cell",
                "ag-student-data-cell--icon",
                isComparing && "ag-student-data-cell--compare",
            )}
        >
            <span className="ag-student-data-cell__icon-tooltip">
                <Icon path={mdiRepeat} size="18px" />
            </span>
        </div>
    ) : (
        <div
            style={{ height: isComparing ? 56 : CELL_SIZE_MIN }}
            className={clsx("ag-student-data-cell", isComparing && "ag-student-data-cell--compare")}
        ></div>
    );
};
