import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { InvoiceListResponse } from "src/modules/subscription/api/invoiceList";

export enum OrmInvoiceActionTypes {
    BULK_IMPORT = "[orm] INVOICE BULK IMPORT",
}

export const OrmInvoiceActions = {
    bulkImport: (payload: InvoiceListResponse) =>
        createAction(OrmInvoiceActionTypes.BULK_IMPORT, payload),
};

export type OrmInvoiceActions = ActionsUnion<typeof OrmInvoiceActions>;
