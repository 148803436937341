import Dialog from "src/components/Dialog";
import AssessmentLabel from "./AssessmentLabel";
import HeadingCounter from "src/components/HeadingCounter";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import clsx from "clsx";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AssessmentCreateFormShape } from "./forms/AddAssessmentForm";
import { Storage } from "src/services/storage";
import { Box, Button, Checkbox, Theme, Typography, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mdiSwapVertical } from "@mdi/js";
import { getTrackerPathLeafe, useTrackerPath } from "src/modules/class/hooks/useTrackerPath";

const useStyles = makeStyles((theme: Theme) => ({
    classMeta: {},
    classMetaItem: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        "& + &": {
            marginTop: theme.spacing(1.5),
        },
    },
    classMetaLabel: {
        color: COLORS.GREY_3,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: 400,
    },
    classMetaText: {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        margin: theme.spacing(0.25, 0, 0),
    },
    classMetaTextBig: {
        fontSize: theme.typography.pxToRem(16),
    },
    classMetaTextNormal: {
        fontWeight: 400,
    },
    classMetaType: {
        display: "flex",
        alignItems: "center",
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        marginTop: theme.spacing(0.25),
    },
    classMetaTypeBig: {
        fontSize: theme.typography.pxToRem(16),
        marginTop: 0,
    },
}));

const StyledRow = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3, 2.5),
    display: "flex",
    justifyContent: "space-between",
    gap: 24,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& > *": {
        flex: 1,
    },
    "&:first-of-type": {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

interface OwnProps {
    open: boolean;
    handleRemove: () => void;
    handleRecover: (selectedPPIndex: number[], selectedCUIndex: number[]) => void;
    handleClose: () => void;
}

const AssessmentRecoveryModal: FC<OwnProps> = ({
    open,
    handleRemove,
    handleRecover,
    handleClose,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [recoveryData] = useState<AssessmentCreateFormShape>(
        Storage.getItem("addAssessmentForm"),
    );

    const [selectedPublishedAssessments, setSelectedPublishedAssessments] = useState<number[]>([]);
    const [selectedCustomisedAssessments, setSelectedCustomisedAssessments] = useState<number[]>(
        [],
    );

    const { refetch, data: trackerPath } = useTrackerPath(recoveryData?.specification);

    useEffect(() => {
        if (recoveryData?.specification) {
            refetch();
        }
    }, [recoveryData]);

    const getActions = () => {
        const actions = [];
        if (selectedPublishedAssessments.length > 0 || selectedCustomisedAssessments.length > 0) {
            actions.push(
                <Button
                    key={0}
                    onClick={() => {
                        handleRecover(selectedPublishedAssessments, selectedCustomisedAssessments);
                    }}
                >
                    {t("class.assessment.recoveryModal.recoverBtn")}
                </Button>,
            );
        }
        actions.push(
            <Button
                onClick={() => {
                    handleRemove();
                }}
                key={1}
                variant="text"
                startIcon={<Icon path={mdiSwapVertical} />}
                disableRipple
            >
                {t("class.assessment.recoveryModal.deleteBtn")}
            </Button>,
        );
        return actions;
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            title={t("class.assessment.recoveryModal.header")}
            contentOverflow="visible"
            actions={getActions()}
        >
            <Typography>{t("class.assessment.recoveryModal.hint1")}</Typography>
            <Typography sx={{ mb: 3 }}>{t("class.assessment.recoveryModal.hint2")}</Typography>
            {recoveryData && trackerPath ? (
                <>
                    <StyledRow>
                        <Box>
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.assessment.recoveryModal.subjectArea")}
                                </Box>
                                <Box
                                    className={clsx(
                                        classes.classMetaText,
                                        classes.classMetaTextNormal,
                                    )}
                                >
                                    {getTrackerPathLeafe(trackerPath, "SubjectArea")?.name}
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.assessment.recoveryModal.qualification")}
                                </Box>
                                <Box
                                    className={clsx(
                                        classes.classMetaText,
                                        classes.classMetaTextNormal,
                                    )}
                                >
                                    {getTrackerPathLeafe(trackerPath, "Qualification")?.name}
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.assessment.recoveryModal.specification")}
                                </Box>
                                <Box
                                    className={clsx(
                                        classes.classMetaText,
                                        classes.classMetaTextNormal,
                                    )}
                                >
                                    {getTrackerPathLeafe(trackerPath, "Specification")?.name}
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.assessment.recoveryModal.academicYear")}
                                </Box>
                                <Box
                                    className={clsx(
                                        classes.classMetaText,
                                        classes.classMetaTextNormal,
                                    )}
                                >
                                    {recoveryData.academicYear}
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.assessment.recoveryModal.yearGroup")}
                                </Box>
                                <Box
                                    className={clsx(
                                        classes.classMetaText,
                                        classes.classMetaTextNormal,
                                    )}
                                >
                                    {recoveryData.yearGroup}
                                </Box>
                            </Box>
                        </Box>
                    </StyledRow>
                </>
            ) : (
                <></>
            )}
            {(recoveryData?.customisedAssessments || []).map((customised, ci) => {
                return (
                    <StyledRow key={ci} sx={{ backgroundColor: COLORS.VERY_LIGHT_GREY_4 }}>
                        <Box sx={{ display: "flex" }}>
                            <Box sx={{ width: 44, ml: -1.125, mr: 2, mt: -0.25 }}>
                                <Checkbox
                                    checked={selectedCustomisedAssessments.includes(ci)}
                                    onClick={() => {
                                        if (
                                            selectedCustomisedAssessments.findIndex(c => c === ci) >
                                            -1
                                        ) {
                                            setSelectedCustomisedAssessments(prev =>
                                                prev.filter(p => p !== ci),
                                            );
                                        } else {
                                            setSelectedCustomisedAssessments(prev =>
                                                prev.concat(ci),
                                            );
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25 }}>
                                <HeadingCounter number={ci + 1} variant="contained" size="big" />
                            </Box>
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.assessment.add.assessment")}
                                    <Box className={classes.classMetaType}>
                                        <AssessmentLabel
                                            variant="eott"
                                            size={"small"}
                                            sx={{
                                                mr: 0.75,
                                            }}
                                        />
                                        {customised.name}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            {customised.tierUnits.map(tu => (
                                <Fragment key={`${tu.unit}` + `${tu.tier}`}>
                                    <Box className={classes.classMetaItem}>
                                        {tu.tier !== "-" ? (
                                            <Box className={classes.classMetaLabel}>
                                                {`${t("common.tierName" + tu.tier)} ${t(
                                                    "common.unit",
                                                )}`}
                                            </Box>
                                        ) : (
                                            ""
                                        )}

                                        <Box
                                            className={clsx(
                                                classes.classMetaText,
                                                classes.classMetaTextNormal,
                                            )}
                                        >
                                            {tu.unitName}
                                        </Box>
                                    </Box>
                                </Fragment>
                            ))}
                        </Box>
                        <Box>
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.assessment.recoveryModal.numberOfClasses")}
                                </Box>
                                <Box
                                    className={clsx(
                                        classes.classMetaText,
                                        classes.classMetaTextNormal,
                                    )}
                                >
                                    {customised.hideForNow
                                        ? customised.visibleClassTrackers.length
                                        : customised.visibleClassTrackers.filter(vct => vct.visible)
                                              .length}
                                </Box>
                            </Box>
                        </Box>
                    </StyledRow>
                );
            })}
            {(recoveryData?.publishedAssessments || []).map((published, pi) => {
                return (
                    <StyledRow key={pi} sx={{ backgroundColor: COLORS.VERY_LIGHT_GREY_4 }}>
                        <Box sx={{ display: "flex" }}>
                            <Box sx={{ width: 44, ml: -1.125, mr: 2, mt: -0.25 }}>
                                <Checkbox
                                    checked={selectedPublishedAssessments.includes(pi)}
                                    onClick={() => {
                                        if (
                                            selectedPublishedAssessments.findIndex(c => c === pi) >
                                            -1
                                        ) {
                                            setSelectedPublishedAssessments(prev =>
                                                prev.filter(p => p !== pi),
                                            );
                                        } else {
                                            setSelectedPublishedAssessments(prev =>
                                                prev.concat(pi),
                                            );
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25 }}>
                                <HeadingCounter number={pi + 1} variant="contained" size="big" />
                            </Box>
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.assessment.add.assessment")}
                                    <Box className={classes.classMetaType}>
                                        <AssessmentLabel
                                            variant="pp"
                                            size={"small"}
                                            sx={{
                                                mr: 0.75,
                                            }}
                                        />
                                        {published.name}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <>
                                <Box className={classes.classMetaItem}>
                                    {published.tier !== "-" ? (
                                        <Box className={classes.classMetaLabel}>
                                            {`${t("common.tierName" + published.tier)} ${t(
                                                "common.unit",
                                            )}`}
                                        </Box>
                                    ) : (
                                        ""
                                    )}

                                    <Box
                                        className={clsx(
                                            classes.classMetaText,
                                            classes.classMetaTextNormal,
                                        )}
                                    >
                                        {published.unitName}
                                    </Box>
                                </Box>
                            </>
                        </Box>
                        <Box>
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.assessment.recoveryModal.numberOfClasses")}
                                </Box>
                                <Box
                                    className={clsx(
                                        classes.classMetaText,
                                        classes.classMetaTextNormal,
                                    )}
                                >
                                    {published.hideForNow
                                        ? published.visibleClassTrackers.length
                                        : published.visibleClassTrackers.filter(vct => vct.visible)
                                              .length}
                                </Box>
                            </Box>
                        </Box>
                    </StyledRow>
                );
            })}
        </Dialog>
    );
};

export default AssessmentRecoveryModal;
