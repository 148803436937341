import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const SUBJECT_ANALYSIS_SUBJECT_AREAS_WITH_SPECYFICATION_QUERY =
    "subjectAnalysisSubjectAreasWithSpecificationsQuery";
export interface SubjectAnalysisQualification {
    id: number;
    name: string;
    specifications: ListObject<number>[];
}

export interface SubjectAnalysisSubjectArea {
    id: number;
    name: string;
    colour: string;
    qualifications: SubjectAnalysisQualification[];
}

export type SubjectAnalysisSubjectAreaListResponse = SubjectAnalysisSubjectArea[];

export const useSubjectAnalysisSubjectAreasWithSpecifications = (
    cohort: number,
    yearGroup: number,
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            SUBJECT_ANALYSIS_SUBJECT_AREAS_WITH_SPECYFICATION_QUERY,
            schoolAccountId,
            cohort,
            yearGroup,
        ],
        queryFn: async (): Promise<SubjectAnalysisSubjectAreaListResponse> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/analysis/subject-analysis/${cohort}/${yearGroup}/specifications/`,
            );
            return data || [];
        },
    });
};
