import { Button } from "@mui/material";
import { OptionsObject, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const snackbarErrorAction = snackbarId => {
    const { closeSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return (
        <Button
            color="error"
            onClick={() => {
                closeSnackbar(snackbarId);
            }}
        >
            {t("common.dismiss")}
        </Button>
    );
};

export const SnackbarErrorOptions = {
    variant: "error",
    autoHideDuration: 60000,
    action: snackbarErrorAction,
} as OptionsObject;
