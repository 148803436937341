import { Typography, Box } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RHFTextField } from "src/components/hookFormComponents";

const CredentialsStep = ({ withEmail = true }: { withEmail?: boolean }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Box mb={2.5}>
                {withEmail ? (
                    <Typography>{t("auth.register.credentialsStep.header")}</Typography>
                ) : (
                    <Typography>{t("auth.register.credentialsStep.createNewPassword")}</Typography>
                )}
            </Box>
            {withEmail && (
                <RHFTextField
                    type="email"
                    name="email"
                    label={t("common.form.label.email")}
                    autoFocus
                    margin="normal"
                />
            )}
            <RHFTextField
                name="password"
                label={t("common.form.label.password")}
                password
                autoComplete="off"
                margin="normal"
            />
            <RHFTextField
                name="repeatPassword"
                label={t("common.form.label.repeatPassword")}
                password
                autoComplete="off"
                margin="normal"
            />
        </Fragment>
    );
};

export default CredentialsStep;
