import COLORS from "src/styles/colors";
import Icon from "src/components/Icon";
import { Box, styled, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconTypes, SnapshotModalActions } from "../SnapshotModal";

const ChooseMenuButton = styled("button")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    backgroundColor: COLORS.VERY_LIGHT_GREY_1,
    cursor: "pointer",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    textAlign: "left",
    border: "0 none",
    outline: "0 none",
    width: "100%",
    height: "100%",
    position: "relative",
    padding: theme.spacing(3.75, 5),
    "&:hover": {
        backgroundColor: COLORS.VERY_LIGHT_GREY_5,
    },
    "& .row": {
        display: "flex",
        alignItems: "center",
    },
    "& .icon": {
        color: COLORS.GREY_2,
        fontSize: 24,
        width: 24,
        height: 24,
        marginRight: theme.spacing(1.75),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(-0.25),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .iconActive": {
        color: COLORS.GREY_1,
    },
    "& .title": {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
    },
    "& .subtitle": {
        color: COLORS.GREY_2,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1.5,
        marginTop: theme.spacing(1),
    },
}));

interface OwnProps {
    availableTypes: SnapshotModalActions[];
    handleTypeChage: (type: SnapshotModalActions) => void;
}

const SnapshotTypeChooseMenu: FC<OwnProps> = ({ availableTypes, handleTypeChage }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography gutterBottom>{t("common.snapshotModal.hint")}</Typography>
            <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                gap="1px 1px"
                marginX={-6}
                marginTop={5}
                bgcolor={COLORS.VERY_LIGHT_GREY_7}
            >
                {availableTypes &&
                    availableTypes.length > 0 &&
                    availableTypes.map(type => (
                        <Box key={type} bgcolor={COLORS.VERY_LIGHT_GREY_1}>
                            <ChooseMenuButton
                                onClick={() => {
                                    handleTypeChage(type);
                                }}
                            >
                                <div className="row">
                                    <div className="icon">
                                        <Icon path={IconTypes[type]} size="inherit" />
                                    </div>
                                    <div className="title">
                                        {t(`common.snapshotModal.type${type}`)}
                                    </div>
                                </div>
                                <div className="subtitle">
                                    {t(`common.snapshotModal.type${type}Text`)}
                                </div>
                            </ChooseMenuButton>
                        </Box>
                    ))}
            </Box>
        </>
    );
};

export default SnapshotTypeChooseMenu;
