import { TFunction } from "i18next";
import * as yup from "yup";

const step1Shape = (t: TFunction) => ({
    firstName: yup.string().required(t("common.form.validation.firstName.required")),
    lastName: yup.string().required(t("common.form.validation.lastName.required")),
});
const step2Shape = (t: TFunction) => ({
    email: yup
        .string()
        .email(t("common.form.validation.email.type"))
        .required(t("common.form.validation.email.required")),
    password: yup
        .string()
        .min(8, t("common.form.validation.password.min"))
        .matches(/[a-zA-Z]/, t("common.form.validation.password.match"))
        .matches(/[0-9]/, t("common.form.validation.password.matchNum"))
        .required(t("common.form.validation.password.required")),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref("password"), ""], t("common.form.validation.repeatPassword.repeat"))
        .required(t("common.form.validation.repeatPassword.required")),
});
const step2WithoutEmailShape = (t: TFunction) => ({
    password: yup
        .string()
        .min(8, t("common.form.validation.password.min"))
        .matches(/[a-zA-Z]/, t("common.form.validation.password.match"))
        .matches(/[0-9]/, t("common.form.validation.password.matchNum"))
        .required(t("common.form.validation.password.required")),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref("password"), ""], t("common.form.validation.repeatPassword.repeat"))
        .required(t("common.form.validation.repeatPassword.required")),
});
const step3Shape = (t: TFunction) => ({
    school: yup
        .object()
        .required()
        .test("school", t("auth.register.schema.school"), (value: { id; name }) => {
            return value.id !== null && value.name;
        })
        .typeError(t("auth.register.schema.school")),
});
const step4Shape = (t: TFunction) => ({
    subjectAreas: yup
        .array()
        .when(["pixlSupported", "nahtSupported"], ([pixlSupported, nahtSupported]) => {
            return pixlSupported &&
                pixlSupported.length === 0 &&
                nahtSupported &&
                nahtSupported.length === 0
                ? yup.array().min(1, t("auth.register.schema.subjectArea"))
                : yup.array(); // TODO @michal
        }),
    pixlSupported: yup
        .array()
        .max(2)
        .when(["subjectAreas", "nahtSupported"], ([subjectAreas, nahtSupported]) => {
            return (nahtSupported && nahtSupported.length > 0) ||
                (subjectAreas && subjectAreas.length > 0)
                ? yup.array()
                : yup.array().required(t("auth.register.schema.subjectArea")); // TODO @michal
        }),
    nahtSupported: yup
        .array()
        .max(2)
        .when(["subjectAreas", "pixlSupported"], ([subjectAreas, pixlSupported]) => {
            return (pixlSupported && pixlSupported.length > 0) ||
                (subjectAreas && subjectAreas.length > 0)
                ? yup.array()
                : yup.array().required(t("auth.register.schema.subjectArea")); // TODO @michal
        }),
});
const step5Shape = (t: TFunction) => ({
    terms: yup.boolean().oneOf([true], t("auth.register.schema.terms")),
});

export const step1Schema = (t: TFunction) => yup.object().shape(step1Shape(t));
export const step2Schema = (t: TFunction) => yup.object().shape(step2Shape(t));
export const step2WithoutEmailSchema = (t: TFunction) =>
    yup.object().shape(step2WithoutEmailShape(t));
export const step3Schema = (t: TFunction) => yup.object().shape(step3Shape(t));
export const step4Schema = t =>
    yup.object().shape(step4Shape(t), [
        ["nahtSupported", "pixlSupported"],
        ["subjectAreas", "pixlSupported"],
        ["subjectAreas", "nahtSupported"],
    ]);
export const step5Schema = (t: TFunction) => yup.object().shape(step5Shape(t));
export const registerSchema = (t: TFunction) =>
    yup.object().shape(
        {
            ...step1Shape(t),
            ...step2Shape(t),
            ...step3Shape(t),
            ...step4Shape(t),
            ...step5Shape(t),
        },
        [
            ["nahtSupported", "pixlSupported"],
            ["subjectAreas", "pixlSupported"],
            ["subjectAreas", "nahtSupported"],
        ],
    );
export const inviteSchema = (t: TFunction) =>
    yup.object().shape({
        ...step1Shape(t),
        ...step2WithoutEmailShape(t),
        ...step5Shape(t),
    });
