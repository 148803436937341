import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { CoreValues } from "../dto/CoreValues";
import { getSchoolAccountId } from "src/services/url";

export const CORE_VALUES = "coreValues";

export const useCoreValues = () => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [CORE_VALUES, schoolAccountId],
        queryFn: async (): Promise<CoreValues | null> => {
            if (schoolAccountId) {
                const { data } = await axiosInstance.get(`school/${getSchoolAccountId()}/config/`);

                return data
                    ? {
                          ...data,
                          studentYearGroups: data.yearGroups,
                          yearGroups: data.yearGroups?.filter(({ id }) => id != -1),
                      }
                    : null;
            }
            return null;
        },
    });
};
