import { AnalysisActions, AnalysisActionTypes } from "./actions";
import { AnalysisKS4GradeHeaderObject } from "../api/KS4Grade/getHeader";
import { AnalysisKS4GradeValuesObject } from "../api/KS4Grade/getValues";
import { AnalysisRelatedDataObject } from "../api/Common/getRelatedData";
import { KS4GradeSnapshotCompareResponse } from "../api/KS4Grade/getSnapshotCompare";
import { AnalysisStudentForClassTrackerResponse } from "../api/Common/getStudentsForClassTracker";
import { CommonCohortAnalysisYearGroupResponse } from "src/modules/common/api/analysisCohortYearGroups";
import { AnalysisAtlHeaderObject } from "../api/Atl/getHeader";
import { AnalysisAtlValuesObject } from "../api/Atl/getValues";
import { AtlSnapshotCompareResponse } from "../api/Atl/getSnapshotCompare";
import { SnapshotData } from "src/context/TrackerContext";

export interface AnalysisState {
    analysisStudents: string[] | null;
    analysisKS4GradeCompare: KS4GradeSnapshotCompareResponse | null;
    analysisRelated: AnalysisRelatedDataObject | null;
    analysisKS4GradeHeader: AnalysisKS4GradeHeaderObject | null;
    analysisKS4GradeValues: AnalysisKS4GradeValuesObject | null;
    analysisClassTrackerStudents: AnalysisStudentForClassTrackerResponse[] | null;
    analysisCohortYearGroups: CommonCohortAnalysisYearGroupResponse[] | null;
    snapshotData: SnapshotData | null;
    analysisAtlHeader: AnalysisAtlHeaderObject | null;
    analysisAtlValues: AnalysisAtlValuesObject | null;
    analysisAtlCompare: AtlSnapshotCompareResponse | null;
}

export const initialState: AnalysisState = {
    analysisStudents: null,
    analysisKS4GradeCompare: null,
    analysisRelated: null,
    analysisKS4GradeHeader: null,
    analysisKS4GradeValues: null,
    analysisClassTrackerStudents: null,
    analysisCohortYearGroups: null,
    snapshotData: null,
    analysisAtlHeader: null,
    analysisAtlValues: null,
    analysisAtlCompare: null,
};

export const reducer = (
    state: AnalysisState = initialState,
    action: AnalysisActions,
): AnalysisState => {
    switch (action.type) {
        case AnalysisActionTypes.SET_ANALYSIS_SNAPSHOT_DATA:
            return {
                ...state,
                snapshotData: action.payload,
            };
        case AnalysisActionTypes.GET_ANALYSIS_COHORT_YEAR_GROUPS_SUCCESS:
            return {
                ...state,
                analysisCohortYearGroups: action.payload.response,
            };
        case AnalysisActionTypes.SET_ANALYSIS_STUDENTS_FOR_CLASS_TRACKER:
            return {
                ...state,
                analysisClassTrackerStudents: action.payload,
            };
        case AnalysisActionTypes.SET_ANALYSIS_KS4_GRADE_COMPARE:
            return {
                ...state,
                analysisStudents:
                    Object.keys(action.payload.values1).length > 0
                        ? Object.keys(
                              action.payload.values1[Object.keys(action.payload.values1)[0]],
                          ).filter(key => {
                              return key.indexOf("student") === 0;
                          })
                        : [],
                analysisKS4GradeCompare: action.payload,
            };
        case AnalysisActionTypes.SET_ANALYSIS_ATL_COMPARE:
            return {
                ...state,
                analysisStudents:
                    Object.keys(action.payload.values1).length > 0
                        ? Object.keys(
                              action.payload.values1[Object.keys(action.payload.values1)[0]],
                          ).filter(key => {
                              return key.indexOf("student") === 0;
                          })
                        : [],
                analysisAtlCompare: action.payload,
            };
        case AnalysisActionTypes.SET_ANALYSIS_RELATED:
            return {
                ...state,
                analysisRelated: action.payload,
            };
        case AnalysisActionTypes.SET_ANALYSIS_KS4_GRADE_HEADER:
            return {
                ...state,
                analysisKS4GradeHeader: action.payload,
            };
        case AnalysisActionTypes.SET_ANALYSIS_ATL_HEADER:
            return {
                ...state,
                analysisAtlHeader: action.payload,
            };
        case AnalysisActionTypes.SET_ANALYSIS_KS4_GRADE_VALUES:
            return {
                ...state,
                analysisStudents:
                    Object.keys(action.payload).length > 0
                        ? Object.keys(action.payload[Object.keys(action.payload)[0]]).filter(
                              key => {
                                  return key.indexOf("student") === 0;
                              },
                          )
                        : [],
                analysisKS4GradeValues: action.payload,
            };
        case AnalysisActionTypes.SET_ANALYSIS_ATL_VALUES:
            return {
                ...state,
                analysisStudents:
                    Object.keys(action.payload).length > 0
                        ? Object.keys(action.payload[Object.keys(action.payload)[0]]).filter(
                              key => {
                                  return key.indexOf("student") === 0;
                              },
                          )
                        : [],
                analysisAtlValues: action.payload,
            };
        case AnalysisActionTypes.CLEAR_ALL_ANALYSIS_DATA:
            return {
                ...state,
                //analysisStudents: null,
                analysisKS4GradeCompare: null,
                analysisRelated: null,
                analysisKS4GradeHeader: null,
                analysisAtlHeader: null,
                analysisAtlValues: null,
                analysisKS4GradeValues: null,
                analysisClassTrackerStudents: null,
            };
        default:
            return state;
    }
};
