import {
    ROUTE_MY_SCHOOL,
    ROUTE_MY_SCHOOL_ATL,
    ROUTE_MY_SCHOOL_SCHOOL_DETAILS,
    ROUTE_MY_SCHOOL_SNAPSHOTS,
    ROUTE_MY_SCHOOL_STAFF,
    ROUTE_MY_SCHOOL_STAFF_ADD,
    ROUTE_MY_SCHOOL_STAFF_EDIT,
} from "src/routes";
import StaffAddEditContainer from "../containers/StaffAddEditContainer";
import SchoolStaffContainer from "../containers/SchoolStaffContainer";
import SchoolDetailsContainer from "../containers/SchoolDetailsContainer";
import SubjectAreaListContainer from "../containers/SubjectAreaListContainer";
import CohortSnapshotListContainer from "../containers/CohortSnapshotListContainer";
import CustomFieldsConfigContainer from "../containers/CustomFieldsConfigContainer";
import AdminGuard from "src/guards/AdminGuard";
import { StudentRoutes } from "src/modules/student/routes/StudentRoutes";
import { ParentRoutes } from "src/modules/parent/routes/ParentRoutes";
import { TagRoutes } from "src/modules/tagging/routes/TagRoutes";

export const SchoolRoutes = [
    ...StudentRoutes,
    ...ParentRoutes,
    ...TagRoutes,
    {
        path: ROUTE_MY_SCHOOL_STAFF_ADD,
        element: <StaffAddEditContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_STAFF_EDIT,
        element: <StaffAddEditContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_SNAPSHOTS,
        element: <CohortSnapshotListContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_STAFF,
        element: <SchoolStaffContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_SCHOOL_DETAILS,
        element: <SchoolDetailsContainer />,
    },
    {
        path: ROUTE_MY_SCHOOL_ATL,
        element: (
            <AdminGuard>
                <CustomFieldsConfigContainer />
            </AdminGuard>
        ),
    },
    {
        path: ROUTE_MY_SCHOOL,
        element: <SubjectAreaListContainer />,
    },
];
