import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import logotype from "src/assets/logotype.svg";

const NotFoundContainer = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box component="figure" width={160} mt={0} mr="auto" mb={4.5} ml="auto">
                <img src={logotype} alt={t("common.logoAlt")} />
            </Box>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("error404.title")}
            </Typography>
            <Typography component="h1" gutterBottom>
                {t("error404.hint")}
            </Typography>
        </Box>
    );
};

export default NotFoundContainer;
