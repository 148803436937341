import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import {
    getStudentReportByAttributeEndpoint,
    StudentByAttributeRequest,
} from "../../api/StudentReport/byAttribute";
import { getStudentReportByStudentEndpoint } from "../../api/StudentReport/byStudent";
import {
    getStudentReportBySubjectEndpoint,
    StudentReportBySubjectRequest,
} from "../../api/StudentReport/bySubject";
import { getStudentReportByYearGroupEndpoint } from "../../api/StudentReport/byYearGroup";
import {
    ClassesStudentNumberForSubject,
    getClassesStudentNumberForSubjectEndpoint,
} from "../../api/StudentReport/getClassesBySubject";
import {
    getStudentNumberForYearGroupEndpoint,
    StudentNumberResponse,
} from "../../api/StudentReport/getStudentNumber";
import { getStudentNumberByAttributeEndpoint } from "../../api/StudentReport/getStudentNumberAttribute";
import {
    validateStudentReportEndpoint,
    ValidateStudentReportResponse,
} from "../../api/StudentReport/validateRequest";
import { validateUserReportRequest } from "../../api/StudentReport/validateUserReport";
import { ListObject } from "src/forms/types";

export enum StudentReportActionsTypes {
    VALIDATE_STUDENT_REPORT = "[report] VALIDATE STUDENT REPORT",
    VALIDATE_STUDENT_REPORT_SUCCESS = "[report] VALIDATE STUDENT REPORT SUCCESS",
    VALIDATE_USER_REPORT = "[report] VALIDATE USER REPORT REQUEST",
    VALIDATE_USER_REPORT_SUCCESS = "[report] VALIDATE USER REPORT REQUEST SUCCESS",
    GET_STUDENT_REPORT_BY_STUDENT = "[report] GET STUDENT REPORT BY STUDENT",
    GET_STUDENT_REPORT_BY_STUDENT_SUCCESS = "[report] GET STUDENT REPORT BY STUDENT SUCCESS",
    GET_STUDENT_REPORT_BY_YEAR_GROUP = "[report] GET STUDENT REPORT BY STUDENT",
    GET_STUDENT_REPORT_BY_YEAR_GROUP_SUCCESS = "[report] GET STUDENT REPORT BY STUDENT SUCCESS",
    GET_STUDENT_COUNT_FOR_YEAR_GROUP = "[report] GET STUDENT COUNT FOR YEAR GROUP",
    GET_STUDENT_COUNT_FOR_YEAR_GROUP_SUCCESS = "[report] GET STUDENT COUNT FOR YEAR GROUP SUCCESS",
    GET_STUDENT_COUNT_FOR_ATTRIBUTE_REPORT = "[report] GET STUDENT COUNT FOR ATTRIBUTE REPORT",
    GET_STUDENT_COUNT_FOR_ATTRIBUTE_REPORT_SUCCESS = "[report] GET STUDENT COUNT FOR ATTRIBUTE REPORT SUCCESS",
    GET_STUDENT_REPORT_BY_ATTRIBUTE = "[report] GET STUDENT REPORT BY ATTRIBUTE",
    GET_STUDENT_REPORT_BY_ATTRIBUTE_SUCCESS = "[report] GET STUDENT REPORT BY ATTRIBUTE SUCCESS",
    GET_STUDENT_REPORT_BY_SUBJECT = "[report] GET STUDENT REPORT BY SUBJECT",
    GET_STUDENT_REPORT_BY_SUBJECT_SUCCESS = "[report] GET STUDENT REPORT BY SUBJECT SUCCESS",
    GET_STUDENT_CLASSES_BY_SUBJECT = "[report] GET STUDENT CLASSES BY SUBJECT",
    GET_STUDENT_CLASSES_BY_SUBJECT_SUCCESS = "[report] GET STUDENT CLASSES BY SUBJECT SUCCESS",
}

export const StudentReportApiEndpoints = {
    validateStudentReport: createActionObject(
        validateStudentReportEndpoint,
        StudentReportActionsTypes.VALIDATE_STUDENT_REPORT_SUCCESS,
    ),
    validateUserReportRequest: createActionObject(
        validateUserReportRequest,
        StudentReportActionsTypes.VALIDATE_USER_REPORT_SUCCESS,
    ),
    getStudentReportByStudent: createActionObject(
        getStudentReportByStudentEndpoint,
        StudentReportActionsTypes.GET_STUDENT_REPORT_BY_STUDENT_SUCCESS,
    ),
    getStudentReportByYearGroup: createActionObject(
        getStudentReportByYearGroupEndpoint,
        StudentReportActionsTypes.GET_STUDENT_REPORT_BY_YEAR_GROUP_SUCCESS,
    ),
    getStudentCountForYearGroup: createActionObject(
        getStudentNumberForYearGroupEndpoint,
        StudentReportActionsTypes.GET_STUDENT_COUNT_FOR_YEAR_GROUP_SUCCESS,
    ),
    getStudentCountForAttributeReport: createActionObject(
        getStudentNumberByAttributeEndpoint,
        StudentReportActionsTypes.GET_STUDENT_COUNT_FOR_ATTRIBUTE_REPORT_SUCCESS,
    ),
    getStudentReportByAttribute: createActionObject(
        getStudentReportByAttributeEndpoint,
        StudentReportActionsTypes.GET_STUDENT_REPORT_BY_ATTRIBUTE_SUCCESS,
    ),
    getStudentReportBySubject: createActionObject(
        getStudentReportBySubjectEndpoint,
        StudentReportActionsTypes.GET_STUDENT_REPORT_BY_SUBJECT_SUCCESS,
    ),
    getStudentClassesBySubject: createActionObject(
        getClassesStudentNumberForSubjectEndpoint,
        StudentReportActionsTypes.GET_STUDENT_CLASSES_BY_SUBJECT_SUCCESS,
    ),
};

export const StudentReportRawActions = (
    StudentReportEndpoints: Record<Keys<typeof StudentReportApiEndpoints>, ApiActionMeta>,
) => ({
    validateStudentReport: (hash: string) =>
        createApiAction(
            StudentReportActionsTypes.VALIDATE_STUDENT_REPORT,
            { values: {}, params: { hash } },
            StudentReportEndpoints.validateStudentReport,
        ),
    validateStudentReportSuccess: (payload: ApiCallbackPayload<ValidateStudentReportResponse>) =>
        createAction(StudentReportActionsTypes.VALIDATE_STUDENT_REPORT_SUCCESS, payload),
    validateUserReportRequest: () =>
        createApiAction(
            StudentReportActionsTypes.VALIDATE_USER_REPORT,
            undefined,
            StudentReportEndpoints.validateUserReportRequest,
        ),
    validateUserReportRequestSuccess: () =>
        createAction(StudentReportActionsTypes.VALIDATE_USER_REPORT_SUCCESS),
    getStudentReportByStudent: (
        cohort: number,
        yearGroup: number,
        students,
        onlySummary?: boolean,
        reportTemplate?: number,
        schoolSnapshot?: { id: number } | null,
    ) =>
        createApiAction(
            StudentReportActionsTypes.GET_STUDENT_REPORT_BY_STUDENT,
            {
                values: {
                    analysisStudents: students,
                    onlySummary: onlySummary || false,
                    reportTemplate: reportTemplate ? { id: reportTemplate } : undefined,
                    schoolSnapshot,
                },
                params: { cohort, yearGroup },
            },
            StudentReportEndpoints.getStudentReportByStudent,
        ),
    getStudentReportByStudentSuccess: () =>
        createAction(StudentReportActionsTypes.GET_STUDENT_REPORT_BY_STUDENT_SUCCESS),
    getStudentReportByYearGroup: (
        cohort: number,
        yearGroup: number,
        onlySummary?: boolean,
        reportTemplate?: number,
        classTrackerGroups?: ListObject<number>[] | null,
        schoolSnapshot?: { id: number } | null,
    ) =>
        createApiAction(
            StudentReportActionsTypes.GET_STUDENT_REPORT_BY_YEAR_GROUP,
            {
                values: {
                    onlySummary: onlySummary || false,
                    classTrackerGroups,
                    reportTemplate: reportTemplate ? { id: reportTemplate } : undefined,
                    schoolSnapshot,
                },
                params: { cohort, yearGroup },
            },
            StudentReportEndpoints.getStudentReportByYearGroup,
        ),
    getStudentReportByYearGroupSuccess: () =>
        createAction(StudentReportActionsTypes.GET_STUDENT_REPORT_BY_YEAR_GROUP_SUCCESS),
    getStudentCountForYearGroup: (cohort: number, yearGroup: number) =>
        createApiAction(
            StudentReportActionsTypes.GET_STUDENT_COUNT_FOR_YEAR_GROUP,
            { values: {}, params: { cohort, yearGroup } },
            StudentReportEndpoints.getStudentCountForYearGroup,
        ),
    getStudentCountForYearGroupSuccess: (payload: ApiCallbackPayload<StudentNumberResponse>) =>
        createAction(StudentReportActionsTypes.GET_STUDENT_COUNT_FOR_YEAR_GROUP_SUCCESS, payload),
    getStudentCountForAttributeReport: (
        cohort: number,
        yearGroup: number,
        values: StudentByAttributeRequest,
    ) =>
        createApiAction(
            StudentReportActionsTypes.GET_STUDENT_COUNT_FOR_ATTRIBUTE_REPORT,
            { values, params: { cohort, yearGroup } },
            StudentReportEndpoints.getStudentCountForAttributeReport,
        ),
    getStudentCountForAttributeReportSuccess: (
        payload: ApiCallbackPayload<StudentNumberResponse>,
    ) =>
        createAction(
            StudentReportActionsTypes.GET_STUDENT_COUNT_FOR_ATTRIBUTE_REPORT_SUCCESS,
            payload,
        ),
    getStudentReportByAttribute: (
        cohort: number,
        yearGroup: number,
        values: StudentByAttributeRequest,
    ) =>
        createApiAction(
            StudentReportActionsTypes.GET_STUDENT_REPORT_BY_ATTRIBUTE,
            { values, params: { cohort, yearGroup } },
            StudentReportEndpoints.getStudentReportByAttribute,
        ),
    getStudentReportByAttributeSuccess: () =>
        createAction(StudentReportActionsTypes.GET_STUDENT_REPORT_BY_ATTRIBUTE_SUCCESS),
    getStudentReportBySubject: (
        cohort: number,
        yearGroup: number,
        values: StudentReportBySubjectRequest,
    ) =>
        createApiAction(
            StudentReportActionsTypes.GET_STUDENT_REPORT_BY_SUBJECT,
            { values, params: { cohort, yearGroup } },
            StudentReportEndpoints.getStudentReportBySubject,
        ),
    getStudentReportBySubjectSuccess: () =>
        createAction(StudentReportActionsTypes.GET_STUDENT_REPORT_BY_SUBJECT_SUCCESS),
    getStudentClassesBySubject: (cohort: number, yearGroup: number) =>
        createApiAction(
            StudentReportActionsTypes.GET_STUDENT_CLASSES_BY_SUBJECT,
            { values: {}, params: { cohort, yearGroup } },
            StudentReportEndpoints.getStudentClassesBySubject,
        ),
    getStudentClassesBySubjectSuccess: (
        payload: ApiCallbackPayload<ClassesStudentNumberForSubject[]>,
    ) => createAction(StudentReportActionsTypes.GET_STUDENT_CLASSES_BY_SUBJECT_SUCCESS, payload),
});
