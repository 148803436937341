import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmInvoice } from "src/orm/models/Invoice";
import { getSchoolAccountId } from "src/services/url";

export const getInvoiceListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/invoices/`,
    method: ApiRequests.GET,
};

export type InvoiceListResponse = OrmInvoice[];
