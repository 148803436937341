export const reloadHubspotChat = () => {
    setTimeout(() => {
        if (
            window &&
            (window as any).HubSpotConversations &&
            (window as any).HubSpotConversations.widget
        ) {
            const status = (window as any).HubSpotConversations.widget.status();
            if (status.loaded) {
                (window as any).HubSpotConversations.widget.refresh();
            } else {
                (window as any).HubSpotConversations.widget.load();
            }
        }
    }, 1500);
};
