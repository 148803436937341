import { Box, CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import AuthContainer from "src/components/AuthContainer";
import { useAuthContext } from "src/context/AuthContext";
import { useMount } from "src/hooks/useMount";
import { ROUTE_AUTH_LOGIN } from "src/routes";

const LogoutContainer = () => {
    const { clearAuthContext } = useAuthContext();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    useMount(() => {
        clearAuthContext();
        setTimeout(() => {
            navigate(ROUTE_AUTH_LOGIN);
        }, 1000);
    });

    useEffect(() => {
        return () => {
            queryClient.clear();
        };
    }, []);

    return (
        <AuthContainer>
            <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
            </Box>
        </AuthContainer>
    );
};

export default LogoutContainer;
