import { ListObject } from "src/forms/types";
import { attr, Model } from "redux-orm";

export enum SchoolUserStatus {
    ACTIVE = "active",
    INACTIVE = "deactivated",
    PENDING = "pending",
    CONFIRMATION = "confirmation",
    INVITED = "invited",
    NOT_INVITED_YET = "not invited yet",
}

export enum SchoolUserRole {
    OWNER = "ROLE_OWNER",
    FINANCE = "ROLE_FINANCE",
    TEACHER = "ROLE_TEACHER",
    SCHOOL_ADMIN = "ROLE_SCHOOL_ADMIN",
}

const fields = {
    id: attr(),
    firstName: attr(),
    lastName: attr(),
    email: attr(),
    status: attr(),
    title: attr(),
    teacherOf: attr(),
    leaderOf: attr(),
    schoolRole: attr(),
    canRemove: attr(),
    userRole: attr(),
    userHash: attr(),
    phone: attr(),
    mobile: attr(),
    language: attr(),
    schoolId: attr(),
    schoolTitle: attr(),
    isLeaderOfAll: attr(),
    isTeacherOfAll: attr(),
    leaderOfSubjectAreas: attr(),
    teacherOfSubjectAreas: attr(),
    groupCallStatus: attr(),
    groupCallId: attr(),
};

export interface Author {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
}
export interface OrmUser {
    id?: number;
    firstName: string;
    lastName: string;
    email?: string;
    status?: string;
    title: string;
    userRole: string;
    userHash: string;
    schoolTitle?: string | null;
    jobTitle?: string | null;
    teacherOf?: string;
    leaderOf?: string;
    schoolRole?: string;
    canRemove?: boolean;
    phone?: string;
    mobile?: string;
    language?: ListObject<string> | null;
    schoolId: number | null;
    isLeaderOfAll?: boolean;
    isTeacherOfAll?: boolean;
    leaderOfSubjectAreas?: ListObject<number>[];
    teacherOfSubjectAreas?: ListObject<number>[];
    userCode?: string;
    loggedInAs?: boolean;
    groupCallStatus?: "manual" | "active" | "deleted";
    groupCallId?: string;
}

class User extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "User";
    static fields = fields;
    static emptyRef: OrmUser = {
        id: 0,
        firstName: "",
        lastName: "",
        title: "",
        userRole: "",
        userHash: "",
        schoolId: null,
    };

    get ormRef(): OrmUser {
        return this.ref as any;
    }

    get ormRefDetails(): OrmUser {
        return this.ormRef as any;
    }
}

export default User;
