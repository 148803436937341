import GradeFilter from "./FilterPanel/GradeFilter";
import useDebounce from "src/hooks/useDebounce";
import GridStudentFilter, {
    AttributePair,
    FiltersShape,
    studentDateFilters,
    studentValueMinMaxFilters,
} from "src/modules/common/components/Grid/GridStudentFilter";
import GridFilterSummary from "src/modules/common/components/Grid/GridFilterSummary";
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAvailableStudentParams } from "src/modules/tracker/services/valueHandler";
import { useLocation } from "react-router";
import { format } from "date-fns";
import { excludedStudentParams } from "src/modules/common/services/gridFilter";
import { TrackerContext } from "../TrackerGrid";
import { useGroupAcademicHouses } from "src/modules/tracker/hooks/query/ClassTrackerGroup/useGroupAcademicHouses";
import { useGroupStudentsForms } from "src/modules/tracker/hooks/query/ClassTrackerGroup/useGroupStudentsForms";
import { isValueDefined } from "src/services/utilsGPT";
import MTGFilter, { getInitialMtgOptions } from "./FilterPanel/MTGFilter";
import AtlFilter from "./FilterPanel/AtlFilter";
import { ColumnConfigTypes } from "src/modules/tracker/services/headerGenerator";
import TagFilter from "./FilterPanel/TagFilter";
import { TagModel } from "src/modules/tagging/model/TagModel";
import { mergeAttributePairToFilters, mergeFilters } from "src/services/utils";
import { rangeValueDisplay } from "src/modules/analysis/components/Grid/ToolsPanel/FilterPanel/RangeFilterComponent";
import { useTrackerUserSettingsStore } from "src/modules/tracker/hooks/query/ClassTracker/useTrackerUserSettingsStore";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useTrackerGroupUserSettingsStore } from "src/modules/tracker/hooks/query/ClassTrackerGroup/useTrackerGroupUserSettingsStore";

export enum GridFilterTypes {
    STUDENT = "student",
    GRADE = "grade",
    MTG = "mtg",
    ATL = "atl",
    TAG = "tag",
}

interface OwnProps {
    context: TrackerContext;
    api: GridApi;
}

const FilterPanel: FC<OwnProps> = ({ context, api: gridApi }) => {
    const location = useLocation();
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();

    const {
        relatedData,
        flatHeader,
        columns: contextColumns,
        handleFiltersChange,
        classTrackerGroupId,
        tier,
        classTrackerId,
    } = context;
    const { setClassTrackerUserSettings: setTrackerUserSettings } = useTrackerUserSettingsStore();

    const { setClassTrackerGroupUserSettings: setTrackerGroupUserSettings } =
        useTrackerGroupUserSettingsStore();

    const setUserSettings = classTrackerId ? setTrackerUserSettings : setTrackerGroupUserSettings;

    const [grades, setGrades] = useState<any>([]);
    const [filterTypeRadio, setFilterTypeRadio] = useState<GridFilterTypes>(
        GridFilterTypes.STUDENT,
    );
    const [filters, setFilters] = useState<FiltersShape>({});
    const debouncedFilters = useDebounce(filters, 400);
    const [studentParams, setStudentParams] = useState<string[]>([]);
    const { data: studentAcademicHouses, refetch: fetchAcademicHouses } =
        useGroupAcademicHouses(classTrackerGroupId);
    const { data: studentForms, refetch: fetchStudentForms } = useGroupStudentsForms(
        classTrackerGroupId,
        tier || "-",
    );

    const excludedMtgFilterColumnsIds = Object.keys(contextColumns).filter(key =>
        [
            ColumnConfigTypes.DISTANCE,
            ColumnConfigTypes.MTG,
            ColumnConfigTypes.TEACHER_JUDGEMENT,
        ].includes(contextColumns[key].columnConfig.type as ColumnConfigTypes),
    );

    const handleOptionChange = option => {
        const header = flatHeader.find(fh => fh.field === option);
        if (
            header?.parentUnitType === "summary" &&
            relatedData?.courseGrades &&
            relatedData.courseGrades.length > 0
        ) {
            setGrades(relatedData.courseGrades);
        } else {
            setGrades(relatedData.grades);
        }
    };

    const updateSearchParams = params => {
        [...excludedStudentParams].forEach(excludedParam => {
            delete params[excludedParam];
        });
        const newSearchParams = new URLSearchParams(params).toString();
        setUserSettings({
            filters: newSearchParams,
        });

        navigate({
            pathname: location.pathname,
            search: "?" + newSearchParams,
        });
    };

    const handleFilters = (filter: FiltersShape) => {
        const newFilter = mergeFilters(filters, filter);

        const currentParams = Object.fromEntries(new URLSearchParams(location.search));
        Object.keys(newFilter).forEach(key => {
            const currentParamValue = [];
            newFilter[key].values.forEach(({ value, valueTo }) => {
                if (
                    newFilter[key].type === "grade" ||
                    (newFilter[key].type === "distance" && isValueDefined(valueTo)) ||
                    (newFilter[key].type === "student" && isValueDefined(valueTo))
                ) {
                    currentParamValue.push(`${value}|${valueTo}`);
                } else {
                    currentParamValue.push(`${value}`);
                }
            });
            currentParams[key] = JSON.stringify(currentParamValue);
        });
        updateSearchParams(currentParams);
    };

    const handleTagFilters = (tags: TagModel[]) => {
        const currentParams = Object.fromEntries(new URLSearchParams(location.search));
        currentParams["tags"] = tags.length > 0 ? tags.map(t => t.id).join(",") : "";

        updateSearchParams(currentParams);
    };

    const handleAtlFilters = (filter: FiltersShape) => {
        const newFilter = mergeFilters(filters, filter);
        const currentParams = Object.fromEntries(new URLSearchParams(location.search));
        Object.keys(newFilter).forEach(key => {
            const currentParamValue = [];
            newFilter[key].values.forEach(({ value, valueTo, valueDisplay }) => {
                if (valueDisplay) {
                    currentParamValue.push(`${value}`);
                } else {
                    currentParamValue.push(`${value}|${valueTo}`);
                }
            });
            currentParams[key] = JSON.stringify(currentParamValue);
        });

        updateSearchParams(currentParams);
    };

    const handleMtgFilters = (filter: FiltersShape) => {
        const newFilter = mergeFilters(filters, filter);

        const currentParams = Object.fromEntries(new URLSearchParams(location.search));
        Object.keys(newFilter).forEach(key => {
            const currentParamValue = [];
            newFilter[key].values.forEach(({ value }) => {
                currentParamValue.push(value ? `${value}` : "N");
            });
            currentParams[key] = JSON.stringify(currentParamValue);
        });

        updateSearchParams(currentParams);
    };

    const handleFiltersRemove = (filterKey: string, valueHash: string) => {
        const newFilters = { ...filters };
        if (newFilters[filterKey]) {
            const currentFilterValues = newFilters[filterKey].values.filter(
                ({ value, valueTo }) => valueHash !== value + "x" + valueTo,
            );
            const currentParams = Object.fromEntries(new URLSearchParams(location.search));
            if (currentFilterValues.length === 0) {
                delete newFilters[filterKey];
                delete currentParams[filterKey];
            } else {
                newFilters[filterKey].values = currentFilterValues;
                currentParams[filterKey] = JSON.stringify(
                    currentFilterValues.map(({ value }) => value),
                );
            }
            setFilters(newFilters);
            updateSearchParams(currentParams);
        }
    };

    const gradeColumns = Object.values(contextColumns).filter(
        (c: any) => c.columnType === "grade" || c.columnType === "distance",
    );

    const columns = gradeColumns.map((gc: any) => {
        const spec = flatHeader.find(s => s.field === gc.hash);

        if (spec) {
            const unit = flatHeader.find((c: any) => c.field === spec.parentUnit);
            const parent = flatHeader.find((c: any) => c.field === spec.parentNode);
            return {
                id: gc.hash,
                name: `${unit ? unit.name + " | " : ""}${parent ? parent.name + " | " : ""}${
                    spec.name
                }`,
                type: gc.columnType,
            };
        }

        return null;
    });

    const getMtgDisplayValue = (option, values) =>
        (
            <>
                {values.map((v, i) => {
                    const mtgOption = getInitialMtgOptions(t).find(option =>
                        `${v}` === "N" ? option.value === null : `${option.value}` === `${v}`,
                    );

                    if (mtgOption) {
                        return (
                            <span key={v} style={{ color: mtgOption.colour }}>
                                {mtgOption.label}
                                {i < values.length - 1 ? ", " : ""}
                            </span>
                        );
                    } else {
                        return "";
                    }
                })}
            </>
        ) as ReactElement;

    const getAtlDisplayValue = (option, values) => {
        const minValue = values[0] || "";
        const maxValue = values[1] || "";

        return (
            (minValue !== ""
                ? "<span>" +
                  t("common.filterMin") +
                  "</span>: " +
                  option.values[option.values.length - minValue]
                : "") +
            (maxValue !== ""
                ? `${minValue ? "," : ""} <span>${t("common.filterMax")}:</span> ` +
                  option.values[option.values.length - maxValue]
                : "")
        );
    };

    const getDisplayValue = (option, minValue, maxValue) => {
        const isGradeOption = option?.type === "grade";

        const getGradeString = value => {
            let fineGrade = "";
            if (relatedData?.fineGradeEnabled) {
                fineGrade = value?.charAt(value.length - 1);
                fineGrade = fineGrade === "-" || fineGrade === "+" ? fineGrade : "";
            }

            let grade = isValueDefined(value) ? value : "";
            if (isGradeOption) {
                grade =
                    value === "-1"
                        ? "U"
                        : (value && grades.find(g => g.id === parseInt(value))?.name) || "";
            }

            return grade ? grade + fineGrade : grade;
        };

        const gradesMin = getGradeString(minValue);
        const gradesMax = getGradeString(maxValue);
        return (
            (gradesMin !== "" ? "<span>" + t("common.filterMin") + "</span>: " + gradesMin : "") +
            (gradesMax !== ""
                ? `${gradesMin ? "," : ""} <span>${t("common.filterMax")}:</span> ` + gradesMax
                : "")
        );
    };

    useEffect(() => {
        setGrades(
            relatedData?.courseGrades.length > 0
                ? relatedData.courseGrades
                : relatedData?.grades || [],
        );
    }, [relatedData]);

    // handle initial filters from search params
    useEffect(() => {
        const currentParams = Object.fromEntries(new URLSearchParams(location.search));

        const getParamData = (paramKey: string): any[] => {
            let paramData = [];
            try {
                paramData = JSON.parse(currentParams[paramKey]);
                if (!Array.isArray(paramData)) {
                    throw new Error("Parameter is not an array!");
                }
            } catch {
                //needed for backward compatibility
                paramData = [currentParams[paramKey]];
            }
            return paramData || [];
        };

        let newFilters = filters;

        const addFilter = (key: string, value: AttributePair) => {
            newFilters = mergeAttributePairToFilters(newFilters, key, value);
        };
        const replaceFilter = (key: string, value: AttributePair) => {
            newFilters = mergeAttributePairToFilters(newFilters, key, value, true);
        };
        if (studentParams && studentParams.length > 0) {
            Object.keys(currentParams).forEach(paramKey => {
                if (studentValueMinMaxFilters.includes(paramKey)) {
                    addFilter(paramKey, {
                        type: "student",
                        attribute: paramKey,
                        attributeDisplay: t(`tracker.grid.configureColumns.` + paramKey),
                        values: getParamData(paramKey).map(v => {
                            const minValue = v.split("|")[0] || "";
                            const maxValue = v.split("|")[1] || "";
                            return {
                                value: minValue,
                                valueTo: maxValue,
                                valueDisplay: rangeValueDisplay(t, minValue, maxValue),
                            };
                        }),
                    });
                } else if (studentDateFilters.includes(paramKey)) {
                    addFilter(paramKey, {
                        type: "student",
                        attribute: paramKey,
                        attributeDisplay: t(`tracker.grid.configureColumns.` + paramKey),
                        values: getParamData(paramKey).map(v => ({
                            value: v,
                            valueDisplay:
                                v === "no-data"
                                    ? t("common.noData")
                                    : format(new Date(v), "dd MMMM yyyy"),
                        })),
                    });
                } else if (studentParams.includes(paramKey)) {
                    addFilter(paramKey, {
                        type: "student",
                        attribute: paramKey,
                        attributeDisplay: t(`tracker.grid.configureColumns.` + paramKey),
                        values: getParamData(paramKey).map(v => ({
                            value: v,
                            valueDisplay: v === "no-data" ? t("common.noData") : v,
                        })),
                    });
                }
            });
        }

        if (context?.tags?.length > 0) {
            Object.keys(currentParams).forEach(paramKey => {
                if (paramKey === "tags") {
                    const selectedTags = context?.tags?.filter(t =>
                        currentParams["tags"].includes(`${t.id}`),
                    );
                    replaceFilter(paramKey, {
                        type: "tags",
                        attribute: "tags",
                        attributeDisplay: t("common.selectedTags"),
                        values: [
                            {
                                value: selectedTags?.map(t => t.id).join(","),
                                valueDisplay: selectedTags?.map(t => t.name).join(", "),
                            },
                        ],
                    });
                }
            });
        }

        if (relatedData?.atlConfigs?.length > 0) {
            Object.keys(currentParams).forEach(paramKey => {
                if (paramKey.indexOf("atl-") === 0) {
                    const option = relatedData.atlConfigs.find(
                        atl => atl && atl.fieldType === paramKey.replace("atl-", ""),
                    );

                    if (option) {
                        addFilter(paramKey, {
                            type: "atl",
                            attribute: paramKey,
                            attributeDisplay: option.name,
                            values: getParamData(paramKey).map(v => ({
                                value: v,
                                valueDisplay: getAtlDisplayValue(option, v.split("|")),
                            })),
                        });
                    }
                }
            });
        }

        if (columns && columns.length > 0) {
            Object.keys(currentParams).forEach(paramKey => {
                if (paramKey.indexOf("mtg-") === 0) {
                    const option = columns.find(o => o && o.id === paramKey.replace("mtg-", ""));
                    if (option) {
                        addFilter(paramKey, {
                            type: "mtg",
                            attribute: paramKey,
                            attributeDisplay: option.name,
                            values: getParamData(paramKey).map(v => ({
                                value: v,
                                valueDisplay: getMtgDisplayValue(option, v.split(",")),
                            })),
                        });
                    }
                } else if (!paramKey.includes("atl-")) {
                    const option = columns.find(o => o && o.id === paramKey);

                    if (option) {
                        addFilter(paramKey, {
                            type: option.type,
                            attribute: paramKey,
                            attributeDisplay: option.name,
                            values: getParamData(paramKey).map(v => {
                                const minValue = v.split("|")[0];
                                const maxValue = v.split("|")[1] || undefined;
                                return {
                                    value: minValue,
                                    valueTo: maxValue,
                                    valueDisplay: getDisplayValue(option, minValue, maxValue),
                                };
                            }),
                        });
                    }
                }
            });
        }
        if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
            setFilters(newFilters);
        }
    }, [studentParams, filters, location, columns, grades]);

    useEffect(() => {
        if (gridApi) {
            const row = gridApi.getDisplayedRowAtIndex(0);
            const data = row ? row.data : undefined;
            const availableStudentParams = getAvailableStudentParams([data]);

            if (availableStudentParams.length > 0 && studentParams.length === 0) {
                setStudentParams(availableStudentParams);
            }

            if (classTrackerGroupId) {
                fetchAcademicHouses();
                fetchStudentForms();
            }
        }
    }, [context, gridApi, studentParams.length, classTrackerGroupId]);

    useEffect(() => {
        if (handleFiltersChange) {
            handleFiltersChange(debouncedFilters);
        }
    }, [debouncedFilters, handleFiltersChange]);

    return (
        <>
            <Box px={5} pt={5} pb={1}>
                <Box mb={3}>
                    <RadioGroup
                        onChange={(_, value) => setFilterTypeRadio(value as GridFilterTypes)}
                        value={filterTypeRadio}
                        name="filter-types"
                        row
                    >
                        <FormControlLabel
                            value={GridFilterTypes.STUDENT}
                            control={<Radio />}
                            label={t("common.filterByStudent")}
                        />
                        <FormControlLabel
                            value={GridFilterTypes.GRADE}
                            control={<Radio />}
                            label={t("common.filterByGrade")}
                        />
                        <FormControlLabel
                            value={GridFilterTypes.MTG}
                            control={<Radio />}
                            label={t("common.filterByMTG")}
                        />
                        <FormControlLabel
                            value={GridFilterTypes.ATL}
                            control={<Radio />}
                            label={t("common.filterByAtl")}
                        />
                        <FormControlLabel
                            value={GridFilterTypes.TAG}
                            control={<Radio />}
                            label={t("common.filterByTag")}
                        />
                    </RadioGroup>
                </Box>
                {filterTypeRadio === GridFilterTypes.STUDENT && (
                    <GridStudentFilter
                        studentParams={studentParams}
                        studentForms={studentForms}
                        studentAcademicHouses={studentAcademicHouses}
                        excludedParams={excludedStudentParams}
                        handleFiltersChange={handleFilters}
                        classTrackers={relatedData?.classTrackers?.map(ct => ct.name)}
                    />
                )}
                {filterTypeRadio === GridFilterTypes.GRADE && columns && (
                    <GradeFilter
                        columns={columns}
                        grades={grades}
                        flatHeader={flatHeader}
                        handleOptionChange={handleOptionChange}
                        handleFiltersChange={handleFilters}
                        hasFineGradesEnabled={
                            relatedData.fineGradeEnabled &&
                            relatedData?.gradeType?.type === "Standard"
                        }
                    />
                )}
                {filterTypeRadio === GridFilterTypes.MTG && columns && (
                    <MTGFilter
                        handleOptionChange={handleOptionChange}
                        handleFiltersChange={handleMtgFilters}
                        columns={columns}
                        excludedMtgFilterColumnsIds={excludedMtgFilterColumnsIds}
                    />
                )}
                {filterTypeRadio === GridFilterTypes.ATL && (
                    <AtlFilter
                        handleFiltersChange={handleAtlFilters}
                        atlConfigs={relatedData?.atlConfigs}
                    />
                )}
                {filterTypeRadio === GridFilterTypes.TAG && (
                    <TagFilter handleTagFiltersChange={handleTagFilters} tags={context.tags} />
                )}
            </Box>
            {filters && Object.keys(filters).length > 0 && (
                <>
                    <Divider />
                    <Box px={5} pb={5}>
                        <Typography variant="h6" gutterBottom>
                            {t("common.filterExistingFilters")}
                        </Typography>
                        <GridFilterSummary
                            filters={filters}
                            handleFiltersRemove={handleFiltersRemove}
                        />
                    </Box>
                </>
            )}
        </>
    );
};
export default FilterPanel;
