import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ClassTrackerRelatedObject } from "src/modules/tracker/dto/TrackerRelated";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_COMPARE_RELATED_DATA_QUERY = "classTrackerGroupCompareRelatedData";

export const useClassTrackerGroupCompareRelatedData = (
    id: number,
    dataSource1: "live-tracking" | "forecast" | string,
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            CLASS_TRACKER_GROUP_COMPARE_RELATED_DATA_QUERY,
            schoolAccountId,
            id,
            dataSource1,
        ],
        queryFn: async (): Promise<ClassTrackerRelatedObject | null> => {
            if (id && dataSource1) {
                let url = `school/${getSchoolAccountId()}/class-tracker-group/${id}/snapshot/${dataSource1}/related-data/`;

                if (dataSource1 === "live-tracking" || dataSource1 === "forecast") {
                    url = `school/${getSchoolAccountId()}/class-tracker-group/${id}/related-data/`;
                }

                const { data } = await axiosInstance.get(url);

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
