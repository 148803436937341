import { attr, Model } from "redux-orm";
import { ListObject } from "src/forms/types";

const fields = {
    id: attr(),
    snapshot: attr(),
    schoolSnapshot: attr(),
    publishedAt: attr(),
    state: attr(),
    reportType: attr(),
};

export enum SnapshotReportState {
    STATE_SCHEDULED = "scheduled",
    STATE_QUEUED = "queued",
    STATE_PUBLISHING = "publishing",
    STATE_PROCESSING = "processing",
    STATE_READY = "ready",
    STATE_PUBLISHED = "published",
    STATE_WITHDRAWN = "withdrawn",
    STATE_ERROR = "error",
    STATE_CANCEL = "cancelled",
}

export enum SnapshotReportType {
    TYPE_SUBJECT = "subject",
    TYPE_ALL_SUBJECT = "all-subjects",
    TYPE_SUMMARY = "summary",
}

export interface OrmSnapshotReport {
    id: number;
    snapshot: ListObject<number> | null;
    schoolSnapshot: ListObject<number> | null;
    publishedAt: string;
    state: SnapshotReportState;
    reportType: SnapshotReportType;
}

class SnapshotReport extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "SnapshotReport";
    static fields = fields;
    static emptyRef: OrmSnapshotReport = {
        id: 0,
        snapshot: null,
        schoolSnapshot: null,
        publishedAt: "",
        state: SnapshotReportState.STATE_QUEUED,
        reportType: SnapshotReportType.TYPE_SUBJECT,
    };

    get ormRef(): OrmSnapshotReport {
        return this.ref as any;
    }
}

export default SnapshotReport;
