import CredentialsStep from "../components/Register/CredentialsStep";
import DetailsStep from "../components/Register/DetailsStep";
import AuthContainer from "src/components/AuthContainer";
import Icon from "src/components/Icon";
import TermsStep from "../components/Register/TermsStep";
import { Box, Button, Step, Stepper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { steps } from "../components/Common/steps";
import {
    step1Schema,
    step5Schema,
    step2WithoutEmailSchema,
    inviteSchema,
} from "../components/Register/registerSchema";
import { mdiArrowLeft } from "@mdi/js";
import { CustomConnector, CustomStepLabel, CustomStepIcon } from "../components/Common/Stepper";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useGetInvitation } from "../hooks/useGetInvitation";
import { useInvite } from "../hooks/useInvite";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "src/components/hookFormComponents";

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontWeight: 600,
    },
    stepper: {
        padding: 0,
        margin: theme.spacing(4.5, -2.5, 8.5),
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(4.5, 4, 8.5),
        },
    },
}));

export interface InvitationFormValues {
    title: string;
    firstName: string;
    lastName: string;
    password: string;
    repeatPassword: string;
    terms: boolean;
}

export const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
        case 0:
            return <DetailsStep />;
        case 1:
            return <CredentialsStep withEmail={false} />;
        case 2:
            return <TermsStep />;
        default:
            return null;
    }
};

const InvitationContainer = () => {
    const classes = useStyles();

    const { t } = useTranslation();
    const { hash } = useParams();

    const { data: invitationUser, status } = useGetInvitation(hash);
    const { mutate: invite } = useInvite();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);

    const stepsConfig = [
        { fields: ["firstName", "lastName"], schema: step1Schema(t) },
        { fields: ["password", "repeatPassword"], schema: step2WithoutEmailSchema(t) },
        { fields: ["terms"], schema: step5Schema(t) },
    ];

    const initialValues = {
        title: (invitationUser && invitationUser.title) || t("common.mr"),
        firstName: (invitationUser && invitationUser.firstName) || "",
        lastName: (invitationUser && invitationUser.lastName) || "",
        password: "",
        repeatPassword: "",
        terms: false,
    };

    const getValidationSchema = (activeStep: number) => {
        if (activeStep > stepsConfig.length - 1) {
            return null;
        }
        return stepsConfig[activeStep].schema;
    };

    const stepValidator = getValidationSchema(activeStep);

    const handleSubmit = (values: InvitationFormValues) => {
        inviteSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid && hash) {
                    invite({
                        hash,
                        values: {
                            title: values.title,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            password: values.password,
                        },
                    });
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    const handleNextStepClick = () =>
        setActiveStep(activeStep > stepsConfig.length ? stepsConfig.length : activeStep + 1);

    const handlePreviousStep = () =>
        setActiveStep(activeStep => (activeStep === 0 ? 0 : activeStep - 1));

    useEffect(() => {
        if (status === "error") {
            enqueueSnackbar(t("common.httpNotFound"), SnackbarErrorOptions);
        }
    }, [status]);

    const methods = useForm({
        resolver: yupResolver(stepValidator as any),
        // mode: "all",
        defaultValues: initialValues,
    });
    const {
        formState: { isSubmitting },
        reset,
    } = methods;

    useEffect(() => {
        if (invitationUser) {
            reset(initialValues);
        }
    }, [invitationUser]);

    return (
        <AuthContainer>
            <FormProvider methods={methods} onSubmit={handleSubmit}>
                <Stepper
                    className={classes.stepper}
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<CustomConnector />}
                >
                    {steps(t).map(label => (
                        <Step key={label} className="stepper-item">
                            <CustomStepLabel StepIconComponent={CustomStepIcon}>
                                {label}
                            </CustomStepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box>{getStepContent(activeStep)}</Box>
                {activeStep !== 5 && (
                    <Box
                        display="flex"
                        flexDirection={{ xs: "column-reverse", md: "row" }}
                        justifyContent="space-between"
                        width={1}
                        mt={5}
                        className="column-reverse"
                    >
                        {activeStep < stepsConfig.length && (
                            <Button
                                variant="text"
                                disabled={activeStep === 0}
                                onClick={handlePreviousStep}
                                startIcon={<Icon path={mdiArrowLeft} />}
                                disableRipple
                            >
                                {t("common.back")}
                            </Button>
                        )}
                        {activeStep < 2 && (
                            <Button color="primary" onClick={handleNextStepClick}>
                                {t("common.continue")}
                            </Button>
                        )}
                        {activeStep === 2 && (
                            <Button color="primary" type="submit" disabled={isSubmitting}>
                                {t("common.continue")}
                            </Button>
                        )}
                    </Box>
                )}
            </FormProvider>
        </AuthContainer>
    );
};

export default memo(InvitationContainer);
