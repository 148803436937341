import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { AssessmentForCopyModel } from "../../models/AssessmentListModel";
import { getSchoolAccountId } from "src/services/url";

export const PUBLISHED_ASSESSMENT_LIST_FOR_COPY_QUERY = "publishedAssessmentListForCopyQuery";

export const usePublishedAssessmentListForCopy = (specificationId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [PUBLISHED_ASSESSMENT_LIST_FOR_COPY_QUERY, schoolAccountId, specificationId],
        queryFn: async (): Promise<AssessmentForCopyModel[] | null> => {
            if (specificationId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/assessments/published/specification/${specificationId}/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
        retry: false,
    });
};
