import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

interface AddTrustUserRequestParams {
    trustId: number;
    schoolUserId: number;
}

const apiAddUserToTrust = async (trustId: number, schoolUserId: number): Promise<AxiosResponse> => {
    return await axiosInstance.post(`trust/${trustId}/user/${schoolUserId}/`);
};

export const useAddUserToTrustMutation = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    return useMutation({
        mutationFn: ({ trustId, schoolUserId }: AddTrustUserRequestParams) =>
            apiAddUserToTrust(trustId, schoolUserId),

        onSuccess: () => {
            enqueueSnackbar(t("common.saved"), { variant: "success" });
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
