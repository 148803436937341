import AssessmentSaHeader from "../components/AssessmentSaHeader";
import COLORS from "src/styles/colors";
import TrackingFilters from "../../class/components/Common/TrackingFilters";
import AssessmentList from "../components/AssessmentList";
// import useDebounce from "src/hooks/useDebounce";
import DotsProgress from "src/components/DotsProgress";
import AppContainer from "src/components/AppContainer";
import { Box, Button, Divider, MenuItem, Paper, TextField, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useLocation, useParams } from "react-router";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { makeStyles } from "@mui/styles";
import { filterNotNullable } from "src/services/object";
import { SchoolUserRole } from "src/orm/models/User";
import { Storage } from "src/services/storage";
import { ROUTE_CLASS_ASSESSMENT_CREATE, ROUTE_CLASS_ASSESSMENT_LIST } from "src/routes";
import { useAssessmentFilters } from "../hooks/useFilters";
import {
    AssessmentListModel,
    AssessmentModel,
    AssessmentUnitModel,
} from "../models/AssessmentListModel";
import { useIsInViewport } from "src/hooks/useIsInViewport";
import { useSnackbar } from "notistack";
import { useAssessments } from "../hooks/useList";
import { AssessmentRequest, useAssessmentMutation } from "../hooks/useQuickEditMutation";
import { useCustomisedAssessmentRemoveMutation } from "../hooks/Customised/useRemoveMutation";
import { usePublishedAssessmentRemoveMutation } from "../hooks/Published/useRemoveMutation";
import AssessmentRemovePrompt from "../components/AssessmentRemovePrompt";
import AssessmentCreateModal from "../components/AssessmentCreateModal";
import AssessmentRecoveryModal from "../components/AssessmentRecoveryModal";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSatuses } from "../hooks/useSatuses";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const useStyles = makeStyles((theme: Theme) => ({
    dividerVertical: {
        height: 42,
        margin: theme.spacing(0, 5),
    },
}));

export const ASSESSMENTS_PER_PAGE = 4;

const AssessmentsListContainer = () => {
    const classes = useStyles();
    const { navigate } = useSchoolNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { data: accountInfo } = useAccountInfo();
    const { cohort } = useParams<{ cohort: string }>();
    const { data } = useCoreValues();
    const cohorts = data?.cohorts || [];
    const currCohort = cohorts
        ? filterNotNullable(cohorts)?.find(c => c.id === parseInt(cohort || ""))
        : null;
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [createDialogOpened, setCreateDialogOpened] = useState<boolean>(false);
    const [recoveryDialogOpened, setRecoveryDialogOpened] = useState<boolean>(false);
    const [removeObject, setRemoveObject] = useState<any>(null);
    const [removeGroup, setRemoveGroup] = useState<any>(null);
    const [editModeGroupId, setEditModeGroupId] = useState<number | null>(null);
    const [quickEditData, setQuickEditData] = useState<AssessmentRequest | null>(null);
    const [listCopy, setListCopy] = useState<any>(null);
    const [isBackTrackerEnable, setBackTrackerEnable] = useState<boolean>(true);
    const [updatingTrackers, setUpdatingTrackers] = useState<number[]>([]);
    const bottomElement = useRef(null);
    const isInViewport = useIsInViewport(bottomElement);

    const {
        subjectArea: subjectParam,
        qualification: qualParam,
        yearGroup: ygParam,
        trackerId,
    } = Object.fromEntries(new URLSearchParams(location.search));

    const initialFilter = {
        yearGroup: ygParam || "",
        subjectArea: subjectParam || "",
        qualification: qualParam || "",
        page: 1,
        perPage: ASSESSMENTS_PER_PAGE,
    };

    const [list, setList] = useState<any>(null);
    const [selectedFilters, setSelectedFilters] = useState(initialFilter);

    const { mutate: removeCustomised, isSuccess: isSuccessRemoveCustomised } =
        useCustomisedAssessmentRemoveMutation();

    const { mutate: removePublished, isSuccess: isSuccessRemovePublished } =
        usePublishedAssessmentRemoveMutation();

    const { data: userProfile } = useProfile();

    const perpareParamsForRequest = useCallback(() => {
        return {
            page: selectedFilters.page,
            perPage: selectedFilters.perPage,
            subjectAreaId: parseInt(selectedFilters.subjectArea) || undefined,
            qualificationId: parseInt(selectedFilters.qualification) || undefined,
            yearGroup:
                selectedFilters.yearGroup !== "" ? parseInt(selectedFilters.yearGroup) : undefined,
        };
    }, [selectedFilters]);

    const resolveSearchQuery = (type, value) => {
        let subjectArea = initialFilter.subjectArea;
        let qualification = initialFilter.qualification;
        let yearGroup = initialFilter.yearGroup;
        if (type === "subjectArea") {
            subjectArea = value;
            qualification = "";
            yearGroup = "";
        }
        if (type === "qualification") {
            qualification = value;
            yearGroup = "";
        }
        if (type === "yearGroup") {
            yearGroup = `${value}`;
        }
        return { subjectArea, qualification, yearGroup };
    };

    const closePrompt = () => {
        setRemoveDialogOpened(false);
        setRemoveObject(null);
        setRemoveGroup(null);
    };

    // eott handle remove dialog
    const handleRemoveDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeObject?.id !== null && removeGroup?.id) {
            if (removeObject.eottId) {
                removeCustomised({ id: removeObject.eottId });
            } else {
                removePublished({ id: removeObject.id });
            }
        } else {
            closePrompt();
        }
    };

    const updateList = () => {
        let newList: AssessmentListModel[] = [];
        assessments?.pages?.forEach((page, pageIndex) => {
            page?.data?.forEach(pageData => {
                const dI = newList.findIndex(
                    nl =>
                        pageData.subjectArea.id === nl.subjectArea.id &&
                        pageData.qualification.id === nl.qualification.id &&
                        pageData.specification.id === nl.specification.id &&
                        pageData.yearGroup === nl.yearGroup,
                );

                if (dI > -1) {
                    const newClassTrackers = [...newList[dI].classTrackers];

                    pageData.classTrackers.forEach(ct => {
                        newClassTrackers.concat(ct);
                    });

                    let newUnits = [...newList[dI].units];
                    pageData.units.forEach(unit => {
                        const uI = newUnits.findIndex(u => u.id === unit.id);
                        if (uI === -1) {
                            newUnits = [...newUnits].concat(unit);
                        } else {
                            unit.assessments.forEach(ass => {
                                const assIndex = newUnits[uI].assessments.findIndex(
                                    a => a.id === ass.id,
                                );
                                if (assIndex > -1) {
                                    // when quick editing assessment -> update
                                    newUnits[uI].assessments[assIndex] = { ...ass };
                                } else {
                                    // when new page is loaded and assessments are added
                                    newUnits[uI].assessments = [...newUnits[uI].assessments].concat(
                                        ass,
                                    );
                                }
                            });
                        }
                    });

                    newList[dI] = {
                        ...newList[dI],
                        units: [...newUnits],
                        classTrackers: [...newClassTrackers],
                        page: pageIndex + 1,
                    };
                } else {
                    newList = [...newList].concat({ ...pageData, page: pageIndex + 1 });
                }
                setList(newList);
            });
        });
    };

    const handleFilterChange = (type: string, value: string) => {
        const { subjectArea, qualification, yearGroup } = resolveSearchQuery(type, value);
        setSelectedFilters({ ...selectedFilters, subjectArea, qualification, yearGroup, page: 1 });
        navigate(
            location.pathname +
                `?subjectArea=${subjectArea}&qualification=${qualification}&yearGroup=${yearGroup}`,
        );
    };

    const handleMoveAssessment = useCallback(
        (unit: AssessmentUnitModel, currentPosition: number, direction: "up" | "down") => {
            const newList = [...list];
            if (editModeGroupId) {
                const groupIndex = newList.findIndex(l => l.id === editModeGroupId);
                const unitIndex = newList[groupIndex]?.units?.findIndex(u => u.id === unit.id);
                if (groupIndex > -1 && unitIndex > -1) {
                    const assessmentArray = [...newList[groupIndex].units[unitIndex].assessments];
                    const toIndex = direction === "up" ? currentPosition - 1 : currentPosition + 1;
                    const element = assessmentArray.splice(currentPosition, 1)[0];
                    assessmentArray.splice(toIndex, 0, element);
                    newList[groupIndex].units[unitIndex].assessments = assessmentArray;
                    setList(newList);
                    setQuickEditData({
                        units: newList[groupIndex].units.map(u => ({
                            id: u.id,
                            assessments: u.assessments.map(a => ({
                                id: a.id,
                                countsTowardGrade: a.countsTowardGrade,
                                visibleClassTrackerIds: a.visibleClassTrackerIds,
                            })),
                        })),
                    });
                }
            }
        },
        [list, editModeGroupId],
    );

    const shouldShowMoveAction = useCallback(
        (where: "up" | "down", group: AssessmentListModel, assessment: AssessmentModel) => {
            const unit = group?.units?.find(
                u => u.assessments.filter(a => a.id === assessment.id).length > 0,
            );
            if (unit) {
                const currentIndex = unit.assessments.findIndex(a => a.id === assessment.id);
                const currentAssessment = unit.assessments.find(a => a.id === assessment.id);

                if (currentIndex === 0 && where === "up") return false; // first item
                if (currentIndex === unit.assessments.length - 1 && where === "down") return false; // last item
                if (
                    currentIndex > 0 &&
                    where === "up" &&
                    currentAssessment.eottId === null &&
                    unit.assessments[currentIndex - 1].eottId !== null
                ) {
                    return false; // previous item is not the same type
                }
                if (
                    currentIndex >= 0 &&
                    where === "down" &&
                    currentAssessment?.eottId !== null &&
                    unit.assessments[currentIndex + 1]?.eottId === null
                ) {
                    return false; // next item is not the same type
                }
            }

            return true;
        },
        [],
    );

    const updateListAfterDelete = useCallback(() => {
        const groupIndex = list.findIndex(l => l.id === removeGroup?.id);
        if (groupIndex > -1) {
            const unitIndex = list[groupIndex].units.findIndex(u =>
                u.assessments.find(a => a.id === removeObject.id),
            );
            if (unitIndex > -1) {
                const newAssessmentList = list[groupIndex].units[unitIndex].assessments.filter(
                    ass => ass.id !== removeObject.id,
                );
                const newList = [...list];
                newList[groupIndex].units[unitIndex].assessments = newAssessmentList;
                setList(newList);
            }
            closePrompt();
        }
    }, [list, removeGroup]);

    useEffect(() => {
        if (isSuccessRemoveCustomised) {
            updateListAfterDelete();
        }
    }, [isSuccessRemoveCustomised]);

    useEffect(() => {
        if (isSuccessRemovePublished) {
            updateListAfterDelete();
        }
    }, [isSuccessRemovePublished]);

    const handleCohortChange = (cohortId: number) => {
        navigate(ROUTE_CLASS_ASSESSMENT_LIST.replace(":cohort", `${cohortId}`));
        setQuickEditData(null);
        setEditModeGroupId(null);
    };

    const handleQuickEditClick = group => {
        if (!editModeGroupId) {
            setEditModeGroupId(group.id);
        } else {
            if (editModeGroupId && quickEditData) {
                saveQuickEdit({
                    classTrackerGroupId: editModeGroupId,
                    units: quickEditData.units.map(unit => ({
                        ...unit,
                        assessments: [
                            ...unit.assessments.map(assessment => ({
                                ...assessment,
                                hiddenColumns: assessment.hiddenColumns || false,
                            })),
                        ],
                    })),
                });
                setBackTrackerEnable(false);
            }
        }
    };

    const handleDiscardChangesClick = () => {
        setList(listCopy);
        setEditModeGroupId(null);
        setQuickEditData(null);
        setListCopy(null);
    };

    const handleAssessmentRemove = (assessment, group) => {
        setRemoveObject(assessment);
        setRemoveGroup(group);
        setRemoveDialogOpened(true);
    };

    const handleUnitsClassesChange = (values, initial) => {
        setQuickEditData(values);
        if (initial && listCopy === null) {
            const arrayCopy = JSON.parse(JSON.stringify(list));

            setListCopy([...arrayCopy]);
        }
    };

    const handleAllUnitsUncheck = () => {
        if (quickEditData) {
            const newUnits = [...quickEditData.units];
            quickEditData.units.forEach(({ assessments }, unitIndex) => {
                assessments.forEach((assessment, assessmentIndex) => {
                    newUnits[unitIndex].assessments[assessmentIndex] = {
                        ...assessment,
                        hiddenColumns: false,
                        showQlaInReports: false,
                        visibleClassTrackerIds: [],
                    };
                });
            });
            setQuickEditData({ units: newUnits });
        }
    };

    const {
        data: assessments,
        hasNextPage,
        fetchNextPage,
        refetch: refetchAssessments,
        isLoading: isAssessmentLoading,
    } = useAssessments(currCohort?.id, {
        ...perpareParamsForRequest(),
    });

    const {
        data: assessmentFilters,
        refetch: refetchFilters,
        isLoading: isLoadingFilters,
    } = useAssessmentFilters(currCohort?.id);
    const { data: statuses, refetch: getStatuses } = useSatuses(currCohort?.id);

    const { mutate: saveQuickEdit } = useAssessmentMutation(
        currCohort?.id,
        { ...perpareParamsForRequest() },
        (res: AssessmentListModel) => {
            setQuickEditData(null);
            setEditModeGroupId(null);
            updateList();
            setUpdatingTrackers(
                trackerId ? [parseInt(trackerId)] : res.classTrackers.map(({ id }) => id),
            );
            getStatuses();
        },
        err => {
            enqueueSnackbar(err, {
                ...SnackbarErrorOptions,
            });
        },
    );

    useEffect(() => {
        let interval;
        const newUpdatingTrackers = updatingTrackers.filter(
            id => !statuses.find(tracker => tracker.id === id && tracker.status === "live"),
        );
        setUpdatingTrackers(newUpdatingTrackers);
        if (newUpdatingTrackers.length > 0) {
            interval = setInterval(() => getStatuses(), 5000);
        } else {
            setBackTrackerEnable(true);
        }
        return () => {
            clearInterval(interval);
        };
    }, [statuses]);

    useEffect(() => {
        if (currCohort) {
            refetchAssessments();
            setQuickEditData(null);
            setEditModeGroupId(null);
        }
    }, [selectedFilters]);

    useEffect(() => {
        refetchAssessments();
        refetchFilters();
    }, [cohort]);

    useEffect(() => {
        updateList();
    }, [assessments?.pages]);

    useEffect(() => {
        if (list && list.length > 0 && isInViewport && !isAssessmentLoading && hasNextPage) {
            fetchNextPage();
        }
    }, [isInViewport, list, isAssessmentLoading]);

    const canManageEots = !!(
        userProfile?.userRole === SchoolUserRole.OWNER ||
        userProfile?.userRole === SchoolUserRole.SCHOOL_ADMIN ||
        userProfile?.isLeaderOfAll ||
        (userProfile?.leaderOfSubjectAreas && userProfile?.leaderOfSubjectAreas.length > 0)
    );

    const isCohortArchived = !!cohorts?.find(c => `${c.id}` === `${cohort}` && c.archived === true);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.assessment.header")}
            </Typography>
            <Box>
                <Box mb={4}>
                    {accountInfo?.canManageClassesAndTracking &&
                        currCohort &&
                        canManageEots &&
                        !isCohortArchived && (
                            <Button
                                className="addAssessmentButton"
                                onClick={() => {
                                    const parsedData = Storage.getItem("addAssessmentForm");

                                    if (
                                        parsedData &&
                                        (parsedData?.publishedAssessments?.length > 0 ||
                                            parsedData?.customisedAssessments?.length > 0)
                                    ) {
                                        setRecoveryDialogOpened(true);
                                    } else {
                                        setCreateDialogOpened(true);
                                    }
                                }}
                                color="primary"
                                disabled={editModeGroupId !== null}
                            >
                                {t("class.assessment.list.addAssessmentBtn")}{" "}
                            </Button>
                        )}
                </Box>
                <Box mb={4} display="flex">
                    <Box width={120} className="addAssessmentCohort">
                        <TextField
                            label={t("class.assessment.list.cohortFilterLabel")}
                            select
                            SelectProps={{ displayEmpty: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="none"
                            name="cohort"
                            fullWidth
                            value={currCohort?.id || 0}
                            onChange={e => handleCohortChange(parseInt(e.target.value))}
                        >
                            {filterNotNullable(cohorts).map(obj => (
                                <MenuItem key={obj.id} value={obj.id}>
                                    {obj.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    {assessmentFilters && assessmentFilters.length > 0 ? (
                        <>
                            <Divider
                                orientation="vertical"
                                flexItem
                                className={classes.dividerVertical}
                            />
                            <TrackingFilters
                                selectedFilters={selectedFilters}
                                filters={assessmentFilters}
                                handleFilterChange={handleFilterChange}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Box>
                {list && list.length > 0 ? (
                    list.map((group: AssessmentListModel) => (
                        <Box key={group.id} sx={{ marginBottom: 2 }} className="assessmentListItem">
                            <Paper
                                sx={{
                                    outline:
                                        editModeGroupId === group.id
                                            ? `2px dashed ${COLORS.BLUE_2}`
                                            : undefined,
                                    pointerEvents:
                                        editModeGroupId !== null && editModeGroupId !== group.id
                                            ? "none"
                                            : undefined,
                                    userSelect:
                                        editModeGroupId !== null && editModeGroupId !== group.id
                                            ? "none"
                                            : undefined,
                                    opacity:
                                        editModeGroupId !== null && editModeGroupId !== group.id
                                            ? 0.5
                                            : undefined,
                                }}
                            >
                                <AssessmentSaHeader
                                    subjectArea={group.subjectArea}
                                    qualification={group.qualification}
                                    specification={group.specification}
                                    yearGroup={group.yearGroup}
                                    groupId={group.id}
                                    handleDiscardChangesClick={handleDiscardChangesClick}
                                    handleQuickEditClick={() => handleQuickEditClick(group)}
                                    editModeGroupId={editModeGroupId}
                                    handleAllUnitsUncheck={handleAllUnitsUncheck}
                                    returnUrlDisabled={!isBackTrackerEnable}
                                />
                                <AssessmentList
                                    cohort={parseInt(cohort)}
                                    handleMoveAssessment={handleMoveAssessment}
                                    handleAssessmentRemove={handleAssessmentRemove}
                                    group={group}
                                    editModeGroupId={editModeGroupId}
                                    editUnitsClasses={quickEditData}
                                    handleUnitsClassesChange={handleUnitsClassesChange}
                                    shouldShowMoveAction={shouldShowMoveAction}
                                />
                            </Paper>
                        </Box>
                    ))
                ) : (
                    <>
                        {!isAssessmentLoading &&
                            !isLoadingFilters &&
                            list !== null &&
                            list?.length === 0 &&
                            t("class.assessment.list.noAssessments")}
                    </>
                )}
                <Box>
                    {hasNextPage || isAssessmentLoading ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flex={1}
                            width="100%"
                            zIndex={50}
                        >
                            <DotsProgress />
                        </Box>
                    ) : (
                        <></>
                    )}
                    <div ref={bottomElement} style={{ height: "50px", width: "100%" }}></div>
                </Box>
            </Box>
            <AssessmentRemovePrompt
                open={removeDialogOpened}
                handleClose={handleRemoveDialogClose}
            />
            <AssessmentCreateModal
                open={createDialogOpened}
                handleClose={(confirm, selectedType) => {
                    setCreateDialogOpened(false);
                    if (confirm) {
                        navigate(
                            ROUTE_CLASS_ASSESSMENT_CREATE.replace(":cohort", `${cohort}`) +
                                "?startingType=" +
                                selectedType,
                        );
                    }
                }}
            />
            <AssessmentRecoveryModal
                open={recoveryDialogOpened}
                handleClose={() => {
                    setRecoveryDialogOpened(false);
                }}
                handleRemove={() => {
                    Storage.removeItem("addAssessmentForm");
                    setRecoveryDialogOpened(false);
                    setCreateDialogOpened(true);
                }}
                handleRecover={(selectedPPIndex, selectedCaIndex) => {
                    setRecoveryDialogOpened(false);
                    navigate(
                        ROUTE_CLASS_ASSESSMENT_CREATE.replace(":cohort", `${cohort}`) +
                            "?recoverPP=" +
                            selectedPPIndex.join(",") +
                            "&recoverCA=" +
                            selectedCaIndex.join(","),
                    );
                }}
            />
        </AppContainer>
    );
};

export default AssessmentsListContainer;
