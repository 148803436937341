import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AnalysisActionTypes, AnalysisActions } from "../actions";

const getAnalysisKS4GradeHeaderSuccessEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_KS4_GRADE_HEADER_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.getAnalysisKS4GradeHeaderSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisKS4GradeHeader(payload.response))),
    );

const getAnalysisKS4GradeValuesSuccessEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_KS4_GRADE_VALUES_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.getAnalysisKS4GradeValuesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisKS4GradeValues(payload.response))),
    );

const getAnalysisKS4ForecastValuesEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_KS4_GRADE_FORECAST_VALUES_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.getAnalysisKS4ForecastValuesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisKS4GradeValues(payload.response))),
    );

const loadAnalysisKs4GradeForecastEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.LOAD_ANALYSIS_KS4_GRADE_FORECAST_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.loadAnalysisKS4GradeForecastSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(AnalysisActions.setAnalysisKS4GradeValues(payload.response));
        }),
    );

const clearAnalysisKs4GradeForecastEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.CLEAR_ANALYSIS_KS4_GRADE_FORECAST_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.clearAnalysisKS4GradeForecastSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(AnalysisActions.setAnalysisKS4GradeValues(payload.response));
        }),
    );

const getAnalysisKS4GradeSnapshotSuccessEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_KS4_GRADE_SNAPSHOT_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.getAnalysisKS4GradeSnapshotSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisKS4GradeValues(payload.response))),
    );

const getAnalysisKS4GradeSnapshotCompareSuccessEpic = (
    action$: ActionsObservable<AnalysisActions>,
) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_KS4_GRADE_SNAPSHOT_COMPARE_SUCCESS),
        map(
            (
                action: ReturnType<
                    typeof AnalysisActions.getAnalysisKS4GradeSnapshotCompareSuccess
                >,
            ) => action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisKS4GradeCompare(payload.response))),
    );

export const analysisKS4GradeEpics = combineEpics(
    getAnalysisKS4GradeHeaderSuccessEpic,
    getAnalysisKS4ForecastValuesEpic,
    loadAnalysisKs4GradeForecastEpic,
    getAnalysisKS4GradeValuesSuccessEpic,
    clearAnalysisKs4GradeForecastEpic,
    getAnalysisKS4GradeSnapshotSuccessEpic,
    getAnalysisKS4GradeSnapshotCompareSuccessEpic,
);
