import { TableCell, TableRow, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "src/components/Icon";
import { mdiDelete } from "@mdi/js";
import { useSubjectAreasList } from "./hooks/useSubjectAreasList";
import { useQualificationsList } from "./hooks/useQualificationsList";
import { useSpecificationsList } from "./hooks/useSpecificationsList";
import DotsProgress from "src/components/DotsProgress";

interface OwnProps {
    subjectAreaId: number;
    qualificationId: number;
    specificationId: number;
    onDelete: (specificationId: number) => void;
}

const SelectedSubjectRow = ({
    subjectAreaId,
    qualificationId,
    specificationId,
    onDelete,
}: OwnProps) => {
    const { t } = useTranslation();

    const { data: subjectAreas, isLoading: isLoadingSubjectAreas } = useSubjectAreasList();
    const { data: qualifications, isLoading: isLoadingQualifications } =
        useQualificationsList(subjectAreaId);
    const { data: specifications, isLoading: isLoadingSpecifications } =
        useSpecificationsList(qualificationId);

    const isLoadingAll =
        isLoadingSubjectAreas || isLoadingQualifications || isLoadingSpecifications;
    return (
        <TableRow hover>
            {isLoadingAll ? (
                <>
                    <TableCell />
                    <TableCell>
                        <DotsProgress />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                </>
            ) : (
                <>
                    <TableCell>
                        {subjectAreas?.find(({ id }) => id == subjectAreaId)?.name}
                    </TableCell>
                    <TableCell>
                        {qualifications?.find(({ id }) => id == qualificationId)?.name}
                    </TableCell>
                    <TableCell>
                        {specifications?.find(({ id }) => id == specificationId)?.name}
                    </TableCell>
                    <TableCell align="right">
                        <Button
                            variant="text"
                            startIcon={<Icon path={mdiDelete} />}
                            disableRipple
                            onClick={() => onDelete(specificationId)}
                            id="deleteSubjectRowButton"
                        >
                            {t("common.delete")}
                        </Button>
                    </TableCell>
                </>
            )}
        </TableRow>
    );
};

export default SelectedSubjectRow;
