import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const createReportTemplateEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/report-template/`,
    method: ApiRequests.POST,
};

export interface ReportTemplateRequest {
    name: string;
    fileNamePattern: string | null;
    mergeIntoOneFile: boolean;
    hideMtg: boolean;
    hideCurrentGrade: boolean;
    hideAverageUnitGrade: boolean;
    hideTpg: boolean;
    showDistanceFromMtgColours: boolean;
}
