import { Button, Box, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "src/components/Icon";
import { mdiAutorenew } from "@mdi/js";
import COLORS from "src/styles/colors";
import { theme } from "src/styles/theme";

const TotalsTable = ({ validatorResponse, setFieldValue }) => {
    const [coupon, setCoupon] = useState<string>("");
    const { t } = useTranslation();

    const vatValue =
        validatorResponse && validatorResponse.vatValue
            ? parseFloat(validatorResponse.vatValue)
            : 0;

    const price =
        validatorResponse && validatorResponse.price ? parseFloat(validatorResponse.price) : null;

    const rebate =
        validatorResponse && validatorResponse.subscriptionsRebate
            ? parseFloat(validatorResponse.subscriptionsRebate)
            : null;

    const discount =
        validatorResponse && validatorResponse.couponRebate
            ? parseFloat(validatorResponse.couponRebate)
            : null;

    const grandTotal =
        (price !== null ? price : 0) -
        (rebate !== null ? rebate : 0) -
        (discount !== null ? discount : 0);

    const grandTotalInclVAT = grandTotal + vatValue;

    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" pb={2} mr={6.5}>
                <Box>{t("subscription.summary.subtotal")}</Box>
                <Box fontWeight={theme.typography.fontWeightBold}>{`£${price || 0}`}</Box>
            </Box>
            <Box
                bgcolor={COLORS.VERY_LIGHT_GREY_5}
                mx={-4}
                pl={4}
                pr={10.5}
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <TextField
                    name={"couponName"}
                    label={t("subscription.summary.couponName")}
                    onChange={e => setCoupon(e.currentTarget.value)}
                    margin="none"
                />
                <Button
                    variant="text"
                    startIcon={<Icon path={mdiAutorenew} />}
                    disableRipple
                    onClick={() => {
                        setFieldValue("couponName", coupon);
                    }}
                >
                    {t("subscription.summary.applyCoupon")}
                </Button>
            </Box>
            {rebate !== null && rebate > 0 && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    py={2}
                    mr={6.5}
                    borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                >
                    <Box>{t("subscription.summary.rebate")}</Box>
                    <Box fontWeight={theme.typography.fontWeightBold}>{`-£${rebate || 0}`}</Box>
                </Box>
            )}
            {discount !== null && discount > 0 && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    py={2}
                    mr={6.5}
                    borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                >
                    <Box>{t("subscription.summary.discount")}</Box>
                    <Box fontWeight={theme.typography.fontWeightBold}>{`-£${discount || 0}`}</Box>
                </Box>
            )}
            {grandTotal > 0 && (
                <>
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        py={2.5}
                        mr={6.5}
                        mt={3.5}
                        fontSize={theme.typography.pxToRem(14)}
                        borderTop={`1px solid ${COLORS.LIGHT_GREY_2}`}
                    >
                        <Box>{t("subscription.summary.excludingVAT")}</Box>
                        <Box display="flex" flexDirection="column" alignItems="flex-end">
                            <Box
                                color={COLORS.GREY_1}
                                fontWeight={theme.typography.fontWeightBold}
                            >{`£${grandTotal.toFixed(2)}`}</Box>
                        </Box>
                    </Box>
                    {vatValue === 0 ? (
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            fontStyle={"italic"}
                            mr={6.5}
                            fontSize={theme.typography.pxToRem(14)}
                        >
                            <Box>{t("subscription.summary.outside")}</Box>
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            mr={6.5}
                            fontSize={theme.typography.pxToRem(14)}
                        >
                            <Box>{t("subscription.summary.vatAmount")}</Box>
                            <Box display="flex" flexDirection="column" alignItems="flex-end">
                                <Box
                                    color={COLORS.GREY_1}
                                    fontWeight={theme.typography.fontWeightBold}
                                >{`£${vatValue.toFixed(2)}`}</Box>
                            </Box>
                        </Box>
                    )}
                </>
            )}
            <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                py={2.5}
                mr={6.5}
                mb={2.5}
                borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                fontSize={theme.typography.pxToRem(18)}
                fontWeight={theme.typography.fontWeightBold}
            >
                <Box>
                    {vatValue === 0
                        ? t("subscription.summary.grandTotal")
                        : t("subscription.summary.grandTotalInclVAT")}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                    <Box color={COLORS.BLUE_2}>{`£${grandTotalInclVAT.toFixed(2)}`}</Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TotalsTable;
