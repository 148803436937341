import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";
import { SCHOOL_STAFF_DETAILS_QUERY } from "./useStaffDetails";
import { StaffUserModel } from "src/modules/school/models/StaffUserModel";

export enum SchoolUserRoleParam {
    FINANCE = "finance",
    TEACHER = "teacher",
    ADMIN = "admin",
}

export interface ChangeStaffRoleRequest {
    staff: number;
    role: SchoolUserRoleParam;
}

const apiChangeStaffRole = async (
    staff: number,
    role: SchoolUserRoleParam,
): Promise<AxiosResponse> => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/staff/${staff}/role/${role}/`);
};

export const useChangeStaffRole = (onSuccess?) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: ChangeStaffRoleRequest) =>
            apiChangeStaffRole(values.staff, values.role),
        onSuccess: (res: AxiosResponse<StaffUserModel>) => {
            queryClient.invalidateQueries({
                queryKey: [SCHOOL_STAFF_DETAILS_QUERY, getSchoolAccountId(), res.data.id],
            });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(err.response.data?.error, {
                ...SnackbarErrorOptions,
            });
        },
    });
};
