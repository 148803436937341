import ClassListFormTile, {
    ClassListTileHeader,
    ClassTileHeaderItem,
} from "../../Common/forms/ClassListFormTile";
import { Box, Grid } from "@mui/material";
import { Form, useFormikContext } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AutoSuggestClassListItem } from "src/modules/class/hooks/MIS/useAutoSuggestClassList";

export interface AutoSuggestFormShape {
    specificationAndClasses: {
        specification: { id: number };
        classes: { id: number; name: string; selected: boolean }[];
    }[];
}

interface OwnProps {
    classList: AutoSuggestClassListItem[];
}

const AutoSuggestForm: FC<OwnProps> = ({ classList }) => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<AutoSuggestFormShape>();

    const handleClassChange = (specificationId: number, classId: number, selected: boolean) => {
        const newClassList = [...values.specificationAndClasses];
        const specIndex = newClassList.findIndex(ncl => ncl.specification.id === specificationId);
        if (specIndex > -1) {
            newClassList[specIndex].classes = newClassList[specIndex].classes.map(c => ({
                ...c,
                selected: c.id === classId ? selected : c.selected,
            }));
        }

        setFieldValue("specificationAndClasses", newClassList);
    };

    const handleClassChangeAll = (specificationId: number) => {
        const newClassList = [...values.specificationAndClasses];
        const specIndex = newClassList.findIndex(ncl => ncl.specification.id === specificationId);
        if (specIndex > -1) {
            const isDeselected = newClassList[specIndex].classes.reduce(
                (isSelected: boolean, currClass) => {
                    if (!currClass.selected && !isSelected) return true;

                    return isSelected;
                },
                false,
            );
            newClassList[specIndex].classes = newClassList[specIndex].classes.map(c => ({
                ...c,
                selected: isDeselected,
            }));
            setFieldValue("specificationAndClasses", newClassList);
        }
    };

    return (
        <Form>
            {classList && classList.length > 0 && (
                <Grid container spacing={4}>
                    {classList
                        .filter(cl => cl.hasSubscription)
                        .map((cl, i) => {
                            return cl.discontinued ? (
                                <Grid item key={i} sm={4}>
                                    <Box height="100%" bgcolor="#f9f9f9">
                                        <ClassListTileHeader item={cl as ClassTileHeaderItem} />
                                        <Box p={3}>
                                            {t("class.addClass.autoSuggest.discontinued")}
                                        </Box>
                                    </Box>
                                </Grid>
                            ) : (
                                <Grid item sm={4} key={i}>
                                    <ClassListFormTile
                                        fieldName="specificationAndClasses"
                                        item={{
                                            ...cl,
                                            disabled: !cl.hasSubscription,
                                            existingClasses: cl.existingClassTrackers,
                                            classes: cl.groupCallGroups,
                                        }}
                                        handleClassChange={handleClassChange}
                                        handleClassChangeAll={handleClassChangeAll}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            )}
        </Form>
    );
};

export default AutoSuggestForm;
