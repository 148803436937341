import ActionsTableButton from "src/components/ActionTableButton";
import COLORS from "src/styles/colors";
import AssessmentLabel from "./AssessmentLabel";
import Icon from "src/components/Icon";
import {
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    styled,
} from "@mui/material";
import { FC, Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { mdiCheck, mdiClose } from "@mdi/js";
import { useTranslation } from "react-i18next";
import {
    AssessmentListModel,
    AssessmentModel,
    AssessmentUnitModel,
} from "../models/AssessmentListModel";

import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { useLocation } from "react-router";
import { ROUTE_CLASS_END_OF_TOPIC_TEST_EDIT, ROUTE_CLASS_PAST_PAPER_EDIT } from "src/routes";
import { hexToRgba } from "src/modules/tracker/services/colorHandler";
import { AssessmentRequest } from "../hooks/useQuickEditMutation";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const AssessmentTableContainer = styled("div")(({ theme }) => ({
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    overflowX: "auto",
}));

const AssessmentTable = styled(Table)(() => ({
    "& .MuiTableHead-root .MuiTableCell-root": {
        verticalAlign: "bottom",
        lineHeight: 1.35,
    },
    "& .MuiTableBody-root .MuiTableCell-root": {
        borderTop: `1px solid ${COLORS.LIGHT_GREY_2}`,
        borderBottom: `1px solid ${COLORS.LIGHT_GREY_2}`,
        verticalAlign: "middle",
    },
}));

const TableStack = styled(Stack)(() => ({
    flexDirection: "row",
    gap: 12,
    minWidth: 340,
    "& > *": {
        width: 20,
    },
}));

const TableClassName = styled("div")(() => ({
    textOrientation: "mixed",
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    maxHeight: 94,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
}));

const TableSummaryGrades = styled("div")(() => ({
    textOrientation: "mixed",
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    maxHeight: 94,
    height: 94,
}));

const TableIconContainer = styled("div")(() => ({
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
        width: 20,
        height: 20,
    },
}));

const TableCheckboxContainer = styled("div")(() => ({
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const TableTierRow = styled(TableRow)(() => ({
    padding: 20,
}));

const TableTierContainer = styled("div")(({ theme }) => ({
    display: "flex",
    fontWeight: theme.typography.fontWeightMedium,
    "& strong": {
        fontWeight: theme.typography.fontWeightMedium,
        color: COLORS.BLUE_3,
        borderRight: `1px solid ${COLORS.LIGHT_GREY_2}`,
        marginRight: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
}));

const TableCellType = styled(TableCell)(() => ({
    minWidth: 70,
    width: 70,
    textAlign: "center",
    borderLeftWidth: 0,
}));

const TableCellName = styled(TableCell)(() => ({
    minWidth: 330,
    width: 330,
}));

const TableCellSummaryGrades = styled(TableCell)(() => ({
    minWidth: 62,
    width: 62,
    textAlign: "center",
    backgroundColor: "rgba(0,0,0,0.05)",
}));

const TableCellClasses = styled(TableCell)(() => ({
    borderRight: `1px solid ${COLORS.LIGHT_GREY_2}`,
    borderLeft: `1px solid ${COLORS.LIGHT_GREY_2}`,
}));

const TableCellGradeBoundaries = styled(TableCell)(() => ({
    minWidth: 130,
    width: 130,
    borderRightWidth: "0 !important",
}));

const TableCellAction = styled(TableCell)(() => ({
    minWidth: 62,
    width: 62,
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 0,
}));

interface OwnProps {
    cohort: number;
    group: AssessmentListModel;
    editModeGroupId: number | null;
    editUnitsClasses: AssessmentRequest | null;
    handleUnitsClassesChange: (values: AssessmentRequest, initial?: boolean) => void;
    handleAssessmentRemove: (assessment: AssessmentListModel, group: any) => void;
    handleMoveAssessment: (
        unit: AssessmentUnitModel,
        currentPosition: number,
        direction: "up" | "down",
    ) => void;
    shouldShowMoveAction: (
        where: "up" | "down",
        group: AssessmentListModel,
        assessment: AssessmentModel,
    ) => boolean;
}

const AssessmentList: FC<OwnProps> = ({
    cohort,
    group,
    editModeGroupId,
    editUnitsClasses,
    handleUnitsClassesChange,
    handleAssessmentRemove,
    handleMoveAssessment,
    shouldShowMoveAction,
}): ReactElement => {
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const location = useLocation();
    const { qualification, subjectArea, yearGroup } = Object.fromEntries(
        new URLSearchParams(location.search),
    );

    const initialActions = [
        getMenuActionProps("edit", {
            onAction: (rowData: AssessmentModel) => {
                navigate(
                    (!rowData.eottId
                        ? ROUTE_CLASS_PAST_PAPER_EDIT
                        : ROUTE_CLASS_END_OF_TOPIC_TEST_EDIT
                    )
                        .replace(":cohort", `${cohort}`)
                        .replace(":endOfTopicTest", `${rowData.eottId}`)
                        .replace(":pastPaper", `${rowData.id}`) +
                        "?" +
                        new URLSearchParams({
                            qualification: qualification || "",
                            subjectArea: subjectArea || "",
                            yearGroup: yearGroup || "",
                        }).toString(),
                );
            },
        }),
        getMenuActionProps("delete", {
            onAction: (rowData: AssessmentListModel) => {
                handleAssessmentRemove(rowData, group);
            },
        }),
    ];

    const [actions, setActions] = useState(initialActions);

    const getAssessment = (unitId: number, assessmentId: number) => {
        const unit = editUnitsClasses?.units?.find(u => u.id === unitId);
        return unit?.assessments?.find(a => a.id === assessmentId) || undefined;
    };

    const getIsClassTrackerChecked = useCallback(
        (unitId: number, assessmentId: number, classTrackerId: number) => {
            if (group && unitId && assessmentId && classTrackerId) {
                return (
                    !!getAssessment(unitId, assessmentId)?.visibleClassTrackerIds?.includes(
                        classTrackerId,
                    ) || false
                );
            }
            return false;
        },
        [editUnitsClasses],
    );

    const getIsCountTowardsGradeChecked = useCallback(
        (unitId: number, assessmentId: number) => {
            if (unitId && assessmentId) {
                return !!getAssessment(unitId, assessmentId)?.countsTowardGrade || false;
            }
            return false;
        },
        [editUnitsClasses],
    );

    const getIsShowQlaInReportsChecked = useCallback(
        (unitId: number, assessmentId: number) => {
            if (unitId && assessmentId) {
                return !!getAssessment(unitId, assessmentId)?.showQlaInReports || false;
            }
            return false;
        },
        [editUnitsClasses],
    );

    const getIsHiddenColumnsChecked = useCallback(
        (unitId: number, assessmentId: number) => {
            if (unitId && assessmentId) {
                return !!getAssessment(unitId, assessmentId)?.hiddenColumns || false;
            }
            return false;
        },
        [editUnitsClasses],
    );

    const handleCheckboxClick = (unitId: number, assessmentId: number, classTrackerId: number) => {
        if (unitId && assessmentId && classTrackerId) {
            const isCurrentlyChecked = getIsClassTrackerChecked(
                unitId,
                assessmentId,
                classTrackerId,
            );
            const newUnitClasses = { ...editUnitsClasses };
            const unitIndex = editUnitsClasses?.units?.findIndex(u => u.id === unitId);
            const assIndex = editUnitsClasses?.units[unitIndex]?.assessments?.findIndex(
                a => a.id === assessmentId,
            );

            if (unitIndex > -1 && assIndex > -1) {
                let newVisibleClassTrackerIds = [
                    ...(newUnitClasses.units[unitIndex]?.assessments[assIndex]
                        ?.visibleClassTrackerIds || []),
                ];

                if (isCurrentlyChecked) {
                    newVisibleClassTrackerIds = newVisibleClassTrackerIds.filter(
                        vcti => vcti !== classTrackerId,
                    );
                } else {
                    newVisibleClassTrackerIds.push(classTrackerId);
                }

                newUnitClasses.units[unitIndex].assessments[assIndex].visibleClassTrackerIds =
                    newVisibleClassTrackerIds;

                handleUnitsClassesChange(newUnitClasses);
            }
        }
    };

    const handleCTGCheckboxClick = (unitId: number, assessmentId: number) => {
        if (unitId && assessmentId) {
            const isCurrentlyChecked = getIsCountTowardsGradeChecked(unitId, assessmentId);
            const newUnitClasses = { ...editUnitsClasses };
            const unitIndex = editUnitsClasses?.units?.findIndex(u => u.id === unitId);
            const assIndex = editUnitsClasses?.units[unitIndex]?.assessments?.findIndex(
                a => a.id === assessmentId,
            );
            if (unitIndex > -1 && assIndex > -1) {
                newUnitClasses.units[unitIndex].assessments[assIndex].countsTowardGrade =
                    !isCurrentlyChecked;
                handleUnitsClassesChange(newUnitClasses);
            }
        }
    };

    const handleShowQlaInReportsClick = (unitId: number, assessmentId: number) => {
        if (unitId && assessmentId) {
            const isCurrentlyChecked = getIsShowQlaInReportsChecked(unitId, assessmentId);
            const newUnitClasses = { ...editUnitsClasses };
            const unitIndex = editUnitsClasses?.units?.findIndex(u => u.id === unitId);
            const assIndex = editUnitsClasses?.units[unitIndex]?.assessments?.findIndex(
                a => a.id === assessmentId,
            );
            if (unitIndex > -1 && assIndex > -1) {
                newUnitClasses.units[unitIndex].assessments[assIndex].showQlaInReports =
                    !isCurrentlyChecked;
                handleUnitsClassesChange(newUnitClasses);
            }
        }
    };

    const handleHiddenColumnsCheckboxClick = (unitId: number, assessmentId: number) => {
        if (unitId && assessmentId) {
            const isCurrentlyChecked = getIsHiddenColumnsChecked(unitId, assessmentId);
            const newUnitClasses = { ...editUnitsClasses };
            const unitIndex = editUnitsClasses?.units?.findIndex(u => u.id === unitId);
            const assIndex = editUnitsClasses?.units[unitIndex]?.assessments?.findIndex(
                a => a.id === assessmentId,
            );
            if (unitIndex > -1 && assIndex > -1) {
                newUnitClasses.units[unitIndex].assessments[assIndex].hiddenColumns =
                    !isCurrentlyChecked;
                handleUnitsClassesChange(newUnitClasses);
            }
        }
    };

    useEffect(() => {
        setActions(initialActions);
    }, [qualification, subjectArea, yearGroup]);

    useEffect(() => {
        if (editModeGroupId === group.id) {
            const getUnit = rowData =>
                group.units.find(u => u.assessments.filter(a => a.id === rowData.id).length > 0);

            const getIndex = (unit, rowData) =>
                unit.assessments.findIndex(a => a.id === rowData.id);

            setActions([
                getMenuActionProps("up", {
                    onAction: (rowData: AssessmentModel) => {
                        const unit = getUnit(rowData);
                        if (unit) {
                            const currentIndex = getIndex(unit, rowData);
                            handleMoveAssessment(unit, currentIndex, "up");
                        }
                    },
                    showAction: (rowData: AssessmentModel) =>
                        shouldShowMoveAction("up", group, rowData),
                }),
                getMenuActionProps("down", {
                    onAction: (rowData: AssessmentModel) => {
                        const unit = getUnit(rowData);
                        if (unit) {
                            const currentIndex = getIndex(unit, rowData);
                            handleMoveAssessment(unit, currentIndex, "down");
                        }
                    },
                    showAction: (rowData: AssessmentModel) =>
                        shouldShowMoveAction("down", group, rowData),
                }),
            ]);
            if (editModeGroupId && !editUnitsClasses && group.id === editModeGroupId) {
                handleUnitsClassesChange(
                    {
                        units: group.units.map(u => ({
                            id: u.id,
                            assessments: u.assessments.map(a => ({
                                id: a.id,
                                countsTowardGrade: a.countsTowardGrade,
                                showQlaInReports: a.showQlaInReports,
                                hiddenColumns: a.hiddenColumns,
                                visibleClassTrackerIds: a.visibleClassTrackerIds,
                            })),
                        })),
                    },
                    true,
                );
            }
        }
        if (!editModeGroupId) {
            setActions([...initialActions]);
        }
    }, [editModeGroupId]);

    return (
        <AssessmentTableContainer>
            <AssessmentTable>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("class.assessment.list.type")}</TableCell>
                        <TableCell>{t("class.assessment.list.name")}</TableCell>
                        <TableCell>
                            <TableSummaryGrades
                                dangerouslySetInnerHTML={{
                                    __html: t("class.assessment.list.countTowards"),
                                }}
                            ></TableSummaryGrades>
                        </TableCell>
                        <TableCell>
                            <TableSummaryGrades
                                dangerouslySetInnerHTML={{
                                    __html: t("class.assessment.list.hiddenColumns"),
                                }}
                            ></TableSummaryGrades>
                        </TableCell>
                        <TableCell>
                            <TableSummaryGrades
                                dangerouslySetInnerHTML={{
                                    __html: t("class.assessment.list.showQlaInReports"),
                                }}
                            ></TableSummaryGrades>
                        </TableCell>
                        <TableCell>
                            <TableStack>
                                {group.classTrackers.map(ct => (
                                    <TableClassName key={ct.id} title={ct.name}>
                                        {ct.name}
                                    </TableClassName>
                                ))}
                            </TableStack>
                        </TableCell>
                        <TableCell>{t("class.assessment.list.gradeBoundaries")}</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {group?.units?.map(unit => (
                        <Fragment key={unit.id}>
                            <TableTierRow sx={{ backgroundColor: hexToRgba(unit.colour, 0.45) }}>
                                <TableCell colSpan={8}>
                                    <TableTierContainer>
                                        {unit.tier !== "-" ? (
                                            <strong>{`${t("common.tierName" + unit.tier)} ${t(
                                                "common.tier",
                                            )}`}</strong>
                                        ) : (
                                            <></>
                                        )}
                                        <span>{unit.name}</span>
                                    </TableTierContainer>
                                </TableCell>
                            </TableTierRow>
                            {unit?.assessments.map(assessment => (
                                <TableRow
                                    key={assessment.id}
                                    sx={{ backgroundColor: hexToRgba(unit.colour, 0.3) }}
                                >
                                    <TableCellType>
                                        <AssessmentLabel
                                            variant={assessment.eottId ? "eott" : "pp"}
                                        />
                                    </TableCellType>
                                    <TableCellName>{assessment.name}</TableCellName>
                                    <TableCellSummaryGrades>
                                        {editModeGroupId && editModeGroupId === group.id ? (
                                            <TableCheckboxContainer sx={{ margin: "0 auto" }}>
                                                <Checkbox
                                                    sx={{ color: `${COLORS.BLUE_2} !important` }}
                                                    checked={getIsCountTowardsGradeChecked(
                                                        unit.id,
                                                        assessment.id,
                                                    )}
                                                    onClick={() =>
                                                        handleCTGCheckboxClick(
                                                            unit.id,
                                                            assessment.id,
                                                        )
                                                    }
                                                />
                                            </TableCheckboxContainer>
                                        ) : (
                                            <TableIconContainer sx={{ margin: "0 auto" }}>
                                                {assessment.countsTowardGrade ? (
                                                    <Icon path={mdiCheck} color={COLORS.BLUE_2} />
                                                ) : (
                                                    <Icon path={mdiClose} color={COLORS.BLUE_2} />
                                                )}
                                            </TableIconContainer>
                                        )}
                                    </TableCellSummaryGrades>
                                    <TableCellSummaryGrades>
                                        {editModeGroupId && editModeGroupId === group.id ? (
                                            <TableCheckboxContainer sx={{ margin: "0 auto" }}>
                                                <Checkbox
                                                    disabled={!assessment.eottId}
                                                    sx={
                                                        !assessment.eottId
                                                            ? undefined
                                                            : {
                                                                  color: `${COLORS.BLUE_2} !important`,
                                                              }
                                                    }
                                                    checked={getIsHiddenColumnsChecked(
                                                        unit.id,
                                                        assessment.id,
                                                    )}
                                                    onClick={() =>
                                                        handleHiddenColumnsCheckboxClick(
                                                            unit.id,
                                                            assessment.id,
                                                        )
                                                    }
                                                />
                                            </TableCheckboxContainer>
                                        ) : (
                                            <TableIconContainer sx={{ margin: "0 auto" }}>
                                                {assessment.hiddenColumns ? (
                                                    <Icon path={mdiCheck} color={COLORS.BLUE_2} />
                                                ) : (
                                                    <Icon path={mdiClose} color={COLORS.BLUE_2} />
                                                )}
                                            </TableIconContainer>
                                        )}
                                    </TableCellSummaryGrades>
                                    <TableCellSummaryGrades>
                                        {editModeGroupId &&
                                        editModeGroupId === group.id &&
                                        (assessment.eottId ||
                                            (!assessment.eottId && !assessment.isRepeated)) ? (
                                            <TableCheckboxContainer sx={{ margin: "0 auto" }}>
                                                <Checkbox
                                                    sx={{ color: `${COLORS.BLUE_2} !important` }}
                                                    checked={getIsShowQlaInReportsChecked(
                                                        unit.id,
                                                        assessment.id,
                                                    )}
                                                    onClick={() =>
                                                        handleShowQlaInReportsClick(
                                                            unit.id,
                                                            assessment.id,
                                                        )
                                                    }
                                                />
                                            </TableCheckboxContainer>
                                        ) : (
                                            <TableIconContainer sx={{ margin: "0 auto" }}>
                                                {assessment.showQlaInReports ? (
                                                    <Icon path={mdiCheck} color={COLORS.BLUE_2} />
                                                ) : (
                                                    <Icon path={mdiClose} color={COLORS.BLUE_2} />
                                                )}
                                            </TableIconContainer>
                                        )}
                                    </TableCellSummaryGrades>
                                    <TableCellClasses>
                                        <TableStack>
                                            {group.classTrackers.map(ct =>
                                                editModeGroupId && editModeGroupId === group.id ? (
                                                    <TableCheckboxContainer key={ct.id}>
                                                        <Checkbox
                                                            checked={getIsClassTrackerChecked(
                                                                unit.id,
                                                                assessment.id,
                                                                ct.id,
                                                            )}
                                                            onClick={() =>
                                                                handleCheckboxClick(
                                                                    unit.id,
                                                                    assessment.id,
                                                                    ct.id,
                                                                )
                                                            }
                                                        />
                                                    </TableCheckboxContainer>
                                                ) : (
                                                    <TableIconContainer key={ct.id}>
                                                        {assessment.visibleClassTrackerIds.includes(
                                                            ct.id,
                                                        ) ? (
                                                            <Icon
                                                                path={mdiCheck}
                                                                color={COLORS.BLUE_3}
                                                            />
                                                        ) : (
                                                            <Icon
                                                                path={mdiClose}
                                                                color={COLORS.LIGHT_GREY_2}
                                                            />
                                                        )}
                                                    </TableIconContainer>
                                                ),
                                            )}
                                        </TableStack>
                                    </TableCellClasses>
                                    <TableCellGradeBoundaries>
                                        {assessment.hasCustomGradeBoundaries &&
                                            t(`class.assessment.list.gradeShiftCustom`)}
                                        {!assessment.hasCustomGradeBoundaries &&
                                        assessment.gradeBoundaryFactor !== undefined &&
                                        assessment.gradeBoundaryFactor !== null ? (
                                            t(
                                                `class.assessment.list.gradeShift${assessment.gradeBoundaryFactor
                                                    .toString()
                                                    .replace("-", "Min")}`,
                                            )
                                        ) : (
                                            <>-</>
                                        )}
                                    </TableCellGradeBoundaries>
                                    <TableCellAction>
                                        <ActionsTableButton
                                            actions={actions}
                                            rowData={assessment}
                                        />
                                    </TableCellAction>
                                </TableRow>
                            ))}
                        </Fragment>
                    ))}
                </TableBody>
            </AssessmentTable>
        </AssessmentTableContainer>
    );
};

export default AssessmentList;
