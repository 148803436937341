import FormikObserver from "src/forms/FormikObserver";
import FormikRef from "src/forms/FormikRef";
import Dialog from "src/components/Dialog";
import COLORS from "src/styles/colors";
import Icon from "src/components/Icon";
import PromptDialog from "src/forms/PromptDialog";
import { Box, Button, Typography, Grid, CircularProgress } from "@mui/material";
import { DragDropFileUpload } from "src/forms/DragDropFileUpload";
import { FileType } from "src/forms/types";
import { Field, Form } from "formik";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mdiAutorenew, mdiEmail } from "@mdi/js";
import { useStudentImportTemplateMutation } from "../hooks/useImportTemplateMutation";
import {
    ImportStudentsDatabaseError,
    ImportStudentsError,
    useStudentImportMutation,
} from "../hooks/useImportMutation";

const ImportStudentsModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
    const form = createRef() as any;
    const { t } = useTranslation();

    const [importError, setImportError] = useState<ImportStudentsError | null>(null);
    const [importDatabaseError, setImportDatabaseError] = useState<
        ImportStudentsDatabaseError[] | null
    >(null);
    const [importErrorModalOpen, setImportErrorModalOpen] = useState<boolean>(false);
    const [importDatabaseErrorModalOpen, setImportDatabaseErrorModalOpen] =
        useState<boolean>(false);

    const [differentNamesOpen, setDifferentNamesOpen] = useState<boolean>(false);
    const [proceedIsLocked, setProceedIsLocked] = useState<boolean>(true);

    const [fileToUpload, setFileToUpload] = useState<FileType[] | null>(null);

    const handleImportCancel = () => {
        setImportDatabaseErrorModalOpen(false);
        setDifferentNamesOpen(true);
        setProceedIsLocked(true);
    };

    const handleScrollToBottom = e => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setProceedIsLocked(false);
        }
    };

    const handleDifferentNamesClose = () => {
        setDifferentNamesOpen(false);
        if (form?.current) {
            form?.current?.setSubmitting(false);
        }
    };

    const handleFileChange = values => {
        if (values.file && values.file !== null && values.file.length > 0) {
            setFileToUpload(values.file);
        }
    };

    const resetUpload = () => {
        setImportError(null);
        setImportDatabaseError(null);
        setImportDatabaseErrorModalOpen(false);
        setImportErrorModalOpen(false);
        setFileToUpload(null);
    };

    const { mutate: getImportStudentsTemplate } = useStudentImportTemplateMutation();

    const { mutate: importStudents, isPending } = useStudentImportMutation(
        res => {
            if (res) {
                setImportDatabaseError(res);
                setImportDatabaseErrorModalOpen(true);
                setImportErrorModalOpen(false);
                setImportError(null);
            } else {
                resetUpload();
                handleClose();
            }
        },
        err => {
            setImportError(err);
            setImportErrorModalOpen(true);
        },
    );

    useEffect(() => {
        if (importDatabaseError && importDatabaseError.length <= 4) {
            setProceedIsLocked(false);
        }
    }, [importDatabaseError]);

    useEffect(() => {
        if (fileToUpload && fileToUpload !== null && fileToUpload.length > 0) {
            const file = [...fileToUpload].shift();

            if (file) {
                importStudents({ file });
            }
        }
    }, [fileToUpload]);

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    resetUpload();
                    handleClose();
                }}
                title={t("students.importStudents.header")}
                actions={
                    importError && (
                        <Button
                            onClick={() => {
                                setImportError(null);
                                setImportDatabaseError(null);
                                setImportDatabaseErrorModalOpen(false);
                                setImportErrorModalOpen(false);
                                setFileToUpload(null);
                            }}
                            color="primary"
                            id="tryAgainButton"
                        >
                            {t("common.tryAgain")}
                        </Button>
                    )
                }
                contentOverflow="visible"
            >
                {isPending && (
                    <Box p={4} textAlign={"center"}>
                        <CircularProgress />
                    </Box>
                )}

                {!isPending &&
                    !importError &&
                    !importErrorModalOpen &&
                    !importDatabaseErrorModalOpen && (
                        <>
                            <Typography gutterBottom>
                                {t("students.importStudents.message")}
                            </Typography>
                            <FormikRef
                                ref={form}
                                initialValues={{
                                    file: null,
                                }}
                                validateOnChange={true}
                                onSubmit={() => {}}
                                enableReinitialize={true}
                            >
                                {formProps => (
                                    <Grid container alignItems="flex-end">
                                        <Grid item sm={7}>
                                            <Form>
                                                <FormikObserver
                                                    values={formProps.values}
                                                    onChange={handleFileChange}
                                                />
                                                <Button
                                                    onClick={() => getImportStudentsTemplate()}
                                                    color="primary"
                                                    id="downloadXlsTemplateButton"
                                                >
                                                    {t(
                                                        "students.importStudents.downloadXlsTemplate",
                                                    )}
                                                </Button>
                                                <Field
                                                    name={`file`}
                                                    component={DragDropFileUpload}
                                                    showList={false}
                                                    label={t(
                                                        "students.importStudents.uploadImportFile",
                                                    )}
                                                    accept={`.xlsx`}
                                                    type="button"
                                                    multiple={false}
                                                    id="uploadImportFileButton"
                                                />
                                            </Form>
                                        </Grid>
                                        <Grid item sm={5}>
                                            <Box
                                                p={3}
                                                bgcolor={COLORS.VERY_LIGHT_GREY_4}
                                                fontStyle="italic"
                                            >
                                                <Typography variant="inherit">
                                                    {t("students.importStudents.help")}
                                                </Typography>
                                                <Box mt={2.5}>
                                                    <Button
                                                        href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
                                                        variant="text"
                                                        startIcon={<Icon path={mdiEmail} />}
                                                        disableRipple
                                                        id="contactUsButton"
                                                    >
                                                        {t("students.importStudents.contactUs")}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </FormikRef>
                        </>
                    )}
                {importError && importErrorModalOpen && (
                    <>
                        <Typography>
                            <Box component="strong">
                                {t("students.importStudents.importFailed")}
                            </Box>
                        </Typography>
                        <Typography>{t("students.importStudents.importFailedProblem")}</Typography>
                        <Box p={4} mt={4} bgcolor={COLORS.VERY_LIGHT_GREY_4}>
                            <Typography color="error">
                                <Box component="strong">{`${importError.error || ""} ${
                                    importError.propertyPath
                                        ? `( ${importError.propertyPath} )`
                                        : ""
                                }`}</Box>
                            </Typography>
                            <Box
                                display="flex"
                                borderTop={`1px solid ${COLORS.VERY_LIGHT_GREY_3}`}
                                mt={2.5}
                                pt={2.5}
                            >
                                {importError?.fileLine && (
                                    <Box width={100} mr={3}>
                                        <Box color={COLORS.GREY_4} fontSize={11}>
                                            {t("common.row")}:
                                        </Box>
                                        <Box fontWeight="fontWeightMedium" mt={0.25}>
                                            {importError.fileLine}
                                        </Box>
                                    </Box>
                                )}
                                {importError.invalidValue && (
                                    <Box width={100} mr={3}>
                                        <Box color={COLORS.GREY_4} fontSize={11}>
                                            {t("common.value")}:
                                        </Box>
                                        <Box fontWeight="fontWeightMedium" mt={0.25}>
                                            {importError.invalidValue}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </>
                )}

                {importDatabaseErrorModalOpen && importDatabaseError && (
                    <>
                        <Typography>
                            {t("students.importStudents.importDatabaseProblem")}
                        </Typography>
                        {importDatabaseError && (
                            <Box
                                bgcolor={COLORS.VERY_LIGHT_GREY_4}
                                display="flex"
                                mt={3}
                                px={3}
                                py={2}
                            >
                                <Box width={100} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("common.row")}:
                                    </Box>
                                </Box>
                                <Box width={150} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("common.UPN")}:
                                    </Box>
                                </Box>
                                <Box width={200} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("students.importStudents.fullNameInDb")}:
                                    </Box>
                                </Box>
                                <Box width={200} mr={3}>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("students.importStudents.fullNameInFile")}:
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <Box
                            maxHeight={280}
                            overflow="scroll"
                            onScroll={handleScrollToBottom}
                            bgcolor={COLORS.VERY_LIGHT_GREY_4}
                            pb={2}
                            mb={4}
                        >
                            {importDatabaseError &&
                                importDatabaseError.map(ide => {
                                    return (
                                        <Box key={ide.line} display="flex" p={3} pb={1}>
                                            <Box width={100} mr={3}>
                                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                                    {ide.line}
                                                </Box>
                                            </Box>
                                            <Box width={150} mr={3}>
                                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                                    {ide.uniquePupilNumber}
                                                </Box>
                                            </Box>
                                            <Box width={200} mr={3}>
                                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                                    {ide.fullNameInDb}
                                                </Box>
                                            </Box>
                                            <Box width={200} mr={3}>
                                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                                    {ide.fullNameInFile}
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                })}
                        </Box>

                        <Box mb={4}>
                            <Typography>{t("students.importStudents.listHint")}</Typography>
                            <Typography>{t("students.importStudents.scrollHint")}</Typography>
                        </Box>

                        <Button
                            onClick={() => {
                                setProceedIsLocked(true);
                                const file = [...fileToUpload].shift();
                                if (file) {
                                    importStudents({ file, overwrite: true });
                                }
                            }}
                            disabled={proceedIsLocked}
                            startIcon={<Icon path={mdiAutorenew} />}
                            disableRipple
                            color="primary"
                            id="theSameStudentButton"
                        >
                            {t("students.importStudents.theSameBtn")}
                        </Button>

                        <Button onClick={handleImportCancel} id="studentsHasDifferentNamesButton">
                            {t("students.importStudents.studentsHasDifferentNamesBtn")}
                        </Button>
                    </>
                )}

                <PromptDialog
                    open={differentNamesOpen}
                    onClose={handleDifferentNamesClose}
                    yesLabel={t("common.continue")}
                    noLabel=""
                >
                    <p>{t("students.importStudents.differentNamesHint1")}</p>
                    <p>{t("students.importStudents.differentNamesHint2")}</p>
                    <p>{t("students.importStudents.differentNamesHint3")}</p>
                </PromptDialog>
            </Dialog>
        </>
    );
};

export default ImportStudentsModal;
