import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const changeCouponCodeEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscriptions/{subscription}/payment-details/`,
    method: ApiRequests.POST,
};

export interface ChangeCouponCodeRequest {
    couponName: string;
}
