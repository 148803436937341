import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { UserClassesListResponse } from "src/modules/user/api/classes";

export enum OrmClassActionTypes {
    BULK_IMPORT = "[orm] CLASS BULK IMPORT",
}

export const OrmClassActions = {
    bulkImport: (payload: UserClassesListResponse) => {
        return createAction(OrmClassActionTypes.BULK_IMPORT, payload);
    },
};

export type OrmClassActions = ActionsUnion<typeof OrmClassActions>;
