import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";
import { StaffUserModel } from "src/modules/school/models/StaffUserModel";
import { ListObject } from "src/forms/types";
import { useTranslation } from "react-i18next";

export interface MergeStaffRequest {
    linkedStaff: ListObject<number>;
    unLinkedStaff: ListObject<number>;
}

const apiMergeStaff = async (values: MergeStaffRequest): Promise<AxiosResponse> => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/staff/merge/`, values);
};

export const useMergeStaff = (onSuccess?) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: MergeStaffRequest) => apiMergeStaff(values),
        onSuccess: (res: AxiosResponse<StaffUserModel[]>) => {
            enqueueSnackbar(t("school.staff.addEdit.staffMerged"), { variant: "success" });
            if (onSuccess) {
                onSuccess(res.data);
            }
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(t("school.staff.addEdit.staffMergeError"), {
                ...SnackbarErrorOptions,
            });
        },
    });
};
