import { Box, Button, CircularProgress } from "@mui/material";
import { mdiDownload, mdiFlipToFront, mdiMinusCircleOutline } from "@mdi/js";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApiStatus } from "src/api/constants";
import Icon from "src/components/Icon";
import PromptDialog from "src/forms/PromptDialog";
import { useResponse } from "src/hooks/useResponse";
import { KS4ViewGrades, KS4ViewTypes } from "src/modules/analysis/api/KS4Grade/getValues";
import { AnalysisActions } from "src/modules/analysis/store/actions";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { AppState } from "src/store/reducers";
import COLORS from "src/styles/colors";
import LoadForecastModal, { AnalysisForecastDataSources } from "../../Forecast/LoadForecastModal";

const dispatchActions = (dispatch: Dispatch) => ({
    clearAnalysisForecast: (cohort: number, yearGroup: number, viewType: KS4ViewTypes) => {
        dispatch(AnalysisActions.clearAnalysisKS4GradeForecast(cohort, yearGroup, viewType));
    },
    loadAnalysisForecast: (
        cohort: number,
        yearGroup: number,
        source: string,
        viewGrade: string,
        viewType: KS4ViewTypes,
    ) => {
        dispatch(
            AnalysisActions.loadAnalysisKS4GradeForecast(
                cohort,
                yearGroup,
                source,
                viewGrade,
                viewType,
            ),
        );
    },
});

const AnalysisToolsPanel = ({ context }) => {
    const { handleExportToExcel, dataType, cohort, yearGroup, a8 } = context;
    const kS4ViewType = a8 ? KS4ViewTypes.A8 : KS4ViewTypes.P8;
    const dispatch = useDispatch();
    const { clearAnalysisForecast, loadAnalysisForecast } = dispatchActions(dispatch);
    const [clearForecastOpen, setClearForecastOpen] = useState<boolean>(false);
    const [loadForecastOpen, setLoadForecastOpen] = useState<boolean>(false);
    const [isForecastLoading, setIsForecastLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { apiClear, apiLoad } = useSelector(
        (state: AppState) => ({
            apiClear: state.api.analysis.clearAnalysisKs4GradeForecast,
            apiForecast: state.api.analysis.getAnalysisKS4ForecastValues,
            apiLoad: state.api.analysis.loadAnalysisKs4GradeForecast,
        }),
        shallowEqual,
    );
    const handleClearForecastClose = (confirm: boolean) => {
        if (confirm) {
            setIsForecastLoading(true);
            clearAnalysisForecast(cohort, yearGroup, kS4ViewType);
        }
    };

    const handleLoadForecastClose = (
        confirm: boolean,
        selectedDataSource?: AnalysisForecastDataSources,
        selectedSource?: KS4ViewGrades,
    ) => {
        if (confirm && selectedDataSource && selectedSource) {
            setIsForecastLoading(true);
            loadAnalysisForecast(
                cohort,
                yearGroup,
                selectedDataSource,
                selectedSource,
                kS4ViewType,
            );
        } else {
            setLoadForecastOpen(false);
        }
    };

    useResponse(() => {
        if (apiClear.status === ApiStatus.SUCCESS) {
            setClearForecastOpen(false);
            setIsForecastLoading(false);
        }
    }, apiClear);

    useResponse(() => {
        if (apiLoad.status === ApiStatus.SUCCESS) {
            setLoadForecastOpen(false);
            setIsForecastLoading(false);
        }
    }, apiLoad);

    return (
        <Box>
            <Box
                px={4}
                py={3}
                borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                display="flex"
                flexDirection="column"
            >
                <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27} mb={1}>
                    {t("common.toolsAssessments")}
                </Box>
                <Button
                    variant="text"
                    startIcon={<Icon path={mdiDownload} />}
                    disableRipple
                    onClick={handleExportToExcel}
                >
                    {t("analysis.ks4Grade.downloadExcel")}
                </Button>
                {dataType === DataTypeTypes.FORECAST && (
                    <Fragment>
                        <Button
                            onClick={() => setLoadForecastOpen(true)}
                            variant="text"
                            disabled={isForecastLoading}
                            startIcon={<Icon path={mdiFlipToFront} />}
                            disableRipple
                        >
                            {t("analysis.ks4Grade.forecastLoadDataBtn")}
                            {isForecastLoading && (
                                <Box ml={2}>
                                    <CircularProgress size={18} />
                                </Box>
                            )}
                        </Button>
                        <Button
                            onClick={() => setClearForecastOpen(true)}
                            variant="text"
                            disabled={isForecastLoading}
                            startIcon={<Icon path={mdiMinusCircleOutline} />}
                            disableRipple
                        >
                            {t("analysis.ks4Grade.clearForecastBtn")}
                            {isForecastLoading && (
                                <Box ml={2}>
                                    <CircularProgress size={18} />
                                </Box>
                            )}
                        </Button>
                    </Fragment>
                )}
            </Box>
            <LoadForecastModal
                open={loadForecastOpen}
                isForecastLoading={isForecastLoading}
                handleClose={handleLoadForecastClose}
            />
            <PromptDialog open={clearForecastOpen} onClose={handleClearForecastClose}>
                {isForecastLoading ? (
                    <CircularProgress size={22} />
                ) : (
                    t("analysis.ks4Grade.clearForecastPrompt")
                )}
            </PromptDialog>
        </Box>
    );
};

export default AnalysisToolsPanel;
