import CustomTable from "src/components/CustomTable";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import { useState } from "react";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
    ROUTE_TRACKER_CLASS_TRACKER,
    ROUTE_CLASS_EDIT_CLASS,
    ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER,
} from "src/routes";
import { Link } from "react-router-dom";
import { OrmClassTracker, TrackerStatus } from "src/orm/models/ClassTracker";
import { TFunction } from "i18next";
import { CellDataGetterParams } from "src/components/CustomTable/types";
import { mdiCheck, mdiLinkOff, mdiViewList, mdiClose, mdiDownload } from "@mdi/js";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { useLocation } from "react-router";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { StudentReportBySubjectRequest } from "src/modules/report/api/StudentReport/bySubject";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { OrmQualification } from "src/orm/models/Qualification";
import { OrmSpecification } from "src/orm/models/Specification";
import { generateTrackerUrl } from "src/services/url";
import { sortTiers } from "src/services/array";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const useStyles = makeStyles(() => ({
    liveTrackingLink: {
        backgroundColor: COLORS.GREEN_1,
        color: COLORS.WHITE,
        boxShadow: "0 1px 2px 0 rgba(0,0,0,0.20), 0 1px 3px 0 rgba(0,0,0,0.10)",
    },
    tableCellLastUpdate: {
        width: 150,
    },
    tableCellTeacher: {
        width: 240,
    },
    tableCellStudents: {
        width: 100,
    },
    tableCellGroupCallStatus: {
        width: 150,
        textAlign: "center",
    },
    tableCellFastAction: {
        width: 190,
        "& .MuiButton-root": {
            width: "100%",
            justifyContent: "flex-start",
        },
        "& .MuiButton-root + .MuiButton-root": {
            marginLeft: 0,
            marginTop: 4,
        },
    },
}));

interface OwnProps {
    isCurrentDefaultCohort: boolean;
    classTrackers: OrmClassTracker[];
    cohort: number;
    subjectArea?: OrmSubjectArea | null;
    yearGroup?: number | null;
    qualification?: OrmQualification | null;
    specification?: OrmSpecification | null;
    handleRemoveClass: (classTracker: number) => void;
    handleUnsyncClass?: (classTracker: number) => void;
    handleDownloadReport?: (
        cohort: number,
        yearGroup: number,
        values: StudentReportBySubjectRequest,
    ) => void;
    defaultTier?: TiersTypes;
    hasTiers?: boolean;
    archived?: boolean;
}

const ClassTrackerTable = ({
    isCurrentDefaultCohort,
    classTrackers,
    cohort,
    yearGroup,
    subjectArea,
    specification,
    qualification,
    handleRemoveClass,
    handleUnsyncClass,
    handleDownloadReport,
    hasTiers,
    archived,
}: OwnProps) => {
    const { t }: { t: TFunction } = useTranslation();
    const { data: accountInfo } = useAccountInfo();
    const classes = useStyles();
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);
    const [unsyncDialogOpened, setUnsyncDialogOpened] = useState<boolean>(false);
    const [unsyncId, setUnsyncId] = useState<number | null>(null);
    const location = useLocation();
    const { attachSchoolId } = useSchoolNavigate();

    const handleRemoveDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            handleRemoveClass(removeId);
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    const handleUnsyncDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && unsyncId !== null && handleUnsyncClass) {
            handleUnsyncClass(unsyncId);
        }
        setUnsyncDialogOpened(false);
        setUnsyncId(null);
    };

    classTrackers.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    const getButtonClassName = rowData => {
        return rowData.status === TrackerStatus.LIVE &&
            !rowData.isDisabled &&
            isCurrentDefaultCohort
            ? classes.liveTrackingLink
            : undefined;
    };

    const getButtonLabel = rowData => {
        return rowData.isDisabled
            ? t("class.list.table.subscriptionExpired")
            : archived
              ? t("class.list.table.archived")
              : !(rowData.status === TrackerStatus.LIVE)
                ? t("class.list.table.updatingTracker")
                : t("class.list.table.liveTracking");
    };

    const getButtonDisabled = rowData => {
        return (
            (rowData.isDisabled && rowData.isDisabled === true) ||
            !(rowData.status === TrackerStatus.LIVE || rowData.status === TrackerStatus.ARCHIVED)
        );
    };

    return (
        <Box className="classTrackingTable">
            <CustomTable
                className="classTracker"
                data={classTrackers}
                showPaginator
                showRowsPerPageOptions={false}
                margin="trackers-list"
                columns={[
                    {
                        key: "name",
                        label: t("class.list.table.name"),
                        cellDataGetter: ({ rowData }: CellDataGetterParams<OrmClassTracker>) => {
                            if (rowData.name) {
                                return (
                                    <Box fontWeight="fontWeightMedium">
                                        {rowData.isDemo ? (
                                            <Box display="inline-block" mr={2}>
                                                <Chip size="small" label={"DEMO"} />
                                            </Box>
                                        ) : (
                                            <></>
                                        )}
                                        {rowData.name}
                                    </Box>
                                );
                            }
                            return "";
                        },
                    },
                    {
                        key: "lastUpdated",
                        label: t("class.list.table.lastUpdated"),
                        className: classes.tableCellLastUpdate,
                    },
                    ...(accountInfo?.hasGroupCallSynchronization
                        ? [
                              {
                                  key: "groupCallStatus",
                                  label: (
                                      <Box textAlign="center">
                                          {t("school.staff.list.groupCallStatusClasses")}
                                      </Box>
                                  ),
                                  className: classes.tableCellGroupCallStatus,
                                  cellDataGetter: ({ rowData }: CellDataGetterParams) => {
                                      switch (rowData.groupCallStatus) {
                                          case "active":
                                              return (
                                                  <Box textAlign="center">
                                                      <Tooltip
                                                          title={
                                                              t(
                                                                  "school.staff.list.groupCallStatusClassActive",
                                                              ) || ""
                                                          }
                                                      >
                                                          <Box component="span">
                                                              <Icon path={mdiCheck} />
                                                          </Box>
                                                      </Tooltip>
                                                  </Box>
                                              );
                                          case "manual":
                                              return (
                                                  <Box textAlign="center">
                                                      <Tooltip
                                                          title={
                                                              t(
                                                                  "school.staff.list.groupCallStatusClassManual",
                                                              ) || ""
                                                          }
                                                      >
                                                          <Box component="span">
                                                              <Icon path={mdiLinkOff} />
                                                          </Box>
                                                      </Tooltip>
                                                  </Box>
                                              );
                                          case "deleted":
                                              return (
                                                  <Box textAlign="center">
                                                      <Tooltip
                                                          title={t(
                                                              "school.staff.list.groupCallStatusClassDeleted",
                                                          ).replaceAll(
                                                              "MIS_Name",
                                                              accountInfo?.misName || "",
                                                          )}
                                                      >
                                                          <Box component="span">
                                                              <Icon path={mdiClose} />
                                                          </Box>
                                                      </Tooltip>
                                                  </Box>
                                              );
                                          default:
                                              return "";
                                      }
                                  },
                              },
                          ]
                        : []),
                    {
                        key: "teachers",
                        label: t("class.list.table.teachers"),
                        cellDataGetter: ({ rowData }: CellDataGetterParams<OrmClassTracker>) => {
                            if (rowData.teachers) {
                                return rowData.teachers.map(t => (
                                    <Box key={t.id}>
                                        {(t.title ? `${t.title} ` : ``) + `${t.lastName}`}
                                    </Box>
                                ));
                            }
                            return "-";
                        },
                        className: classes.tableCellTeacher,
                    },
                    {
                        key: "numberOfStudents",
                        label: t("class.list.table.students"),
                        className: classes.tableCellStudents,
                    },

                    {
                        key: "fastAction",
                        label: "",
                        size: "small",
                        className: classes.tableCellFastAction,
                        cellDataGetter: ({ rowData }: CellDataGetterParams<OrmClassTracker>) => {
                            if (rowData.id) {
                                if (!hasTiers) {
                                    return (
                                        <>
                                            <Button
                                                component={Link}
                                                to={generateTrackerUrl(
                                                    archived
                                                        ? attachSchoolId(
                                                              ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER,
                                                          )
                                                        : attachSchoolId(
                                                              ROUTE_TRACKER_CLASS_TRACKER,
                                                          ),
                                                    rowData.id,
                                                    cohort,
                                                    subjectArea.name,
                                                    qualification.name,
                                                    specification,
                                                    "-",
                                                    yearGroup,
                                                )}
                                                variant="text"
                                                className={
                                                    getButtonClassName(rowData) +
                                                    " classTrackingLink"
                                                }
                                                color="secondary"
                                                startIcon={<Icon path={mdiViewList} />}
                                                disabled={getButtonDisabled(rowData)}
                                                disableRipple
                                                id={`liveTrackingButton`}
                                            >
                                                {getButtonLabel(rowData)}
                                            </Button>
                                            {rowData.maintenanceMessage && (
                                                <Box style={{ marginLeft: 15, fontSize: 10 }}>
                                                    {rowData.maintenanceMessage}
                                                </Box>
                                            )}
                                        </>
                                    );
                                } else if (rowData.tiers && rowData.tiers.length > 0) {
                                    return (
                                        <Box>
                                            {sortTiers(rowData.tiers).map(tierLabel => {
                                                return (
                                                    <Button
                                                        key={tierLabel}
                                                        component={Link}
                                                        to={generateTrackerUrl(
                                                            archived
                                                                ? attachSchoolId(
                                                                      ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER,
                                                                  )
                                                                : attachSchoolId(
                                                                      ROUTE_TRACKER_CLASS_TRACKER,
                                                                  ),
                                                            rowData.id,
                                                            cohort,
                                                            subjectArea.name,
                                                            qualification.name,
                                                            specification,
                                                            tierLabel,
                                                            yearGroup,
                                                        )}
                                                        variant="text"
                                                        className={getButtonClassName(rowData)}
                                                        color="secondary"
                                                        startIcon={<Icon path={mdiViewList} />}
                                                        disabled={getButtonDisabled(rowData)}
                                                        disableRipple
                                                        id={`liveTrackingButton`}
                                                    >
                                                        {rowData.isDisabled
                                                            ? t(
                                                                  "class.list.table.subscriptionExpired",
                                                              )
                                                            : !(
                                                                    rowData.status ===
                                                                    TrackerStatus.LIVE
                                                                )
                                                              ? t(
                                                                    "class.list.table.updatingTracker",
                                                                )
                                                              : tierLabel}
                                                    </Button>
                                                );
                                            })}{" "}
                                            {rowData.maintenanceMessage && (
                                                <Box style={{ marginLeft: 15, fontSize: 10 }}>
                                                    {rowData.maintenanceMessage}
                                                </Box>
                                            )}
                                        </Box>
                                    );
                                }
                            }

                            return "";
                        },
                    },
                ]}
                actions={[
                    getMenuActionProps("edit", {
                        text: t("class.list.table.editClassBtn"),
                        linkCallback: (rowData: OrmClassTracker) => {
                            return (
                                attachSchoolId(ROUTE_CLASS_EDIT_CLASS).replace(
                                    ":class",
                                    `${rowData.id}`,
                                ) +
                                `?returnUrl=` +
                                encodeURIComponent(location.pathname + location.search)
                            );
                        },
                        showAction: (rowData: OrmClassTracker) =>
                            rowData.status === TrackerStatus.LIVE,
                    }),
                    getMenuActionProps("delete", {
                        onAction: (rowData: OrmClassTracker) => {
                            setRemoveDialogOpened(true);
                            setRemoveId(rowData.id);
                        },
                        showAction: (rowData: OrmClassTracker) => {
                            return (
                                (!(rowData.groupCallStatus === "active") ||
                                    !accountInfo?.hasGroupCallSynchronization) &&
                                !archived
                            );
                        },
                    }),
                    getMenuActionProps("delete", {
                        onAction: (rowData: OrmClassTracker) => {
                            setUnsyncDialogOpened(true);
                            setUnsyncId(rowData.id);
                        },
                        showAction: (rowData: OrmClassTracker) => {
                            return !!(
                                rowData.groupCallStatus === "active" &&
                                accountInfo?.hasGroupCallSynchronization &&
                                !archived
                            );
                        },
                    }),
                    getMenuActionProps("downloadReport", {
                        text: t("class.list.downloadSubjectReport"),
                        icon: <Icon path={mdiDownload} />,
                        showAction: () => {
                            return !!(
                                cohort &&
                                subjectArea &&
                                yearGroup &&
                                qualification &&
                                specification
                            );
                        },
                        onAction: (rowData: OrmClassTracker) => {
                            if (
                                handleDownloadReport &&
                                rowData &&
                                cohort &&
                                subjectArea &&
                                yearGroup &&
                                qualification &&
                                specification
                            ) {
                                handleDownloadReport(cohort, yearGroup, {
                                    subjectArea: subjectArea ? { id: subjectArea.id } : null,
                                    qualification: qualification ? { id: qualification.id } : null,
                                    specification: specification ? { id: specification.id } : null,
                                    classTracker: rowData?.id ? { id: rowData.id } : null,
                                    analysisStudents: [],
                                });
                            }
                        },
                    }),
                ]}
            />
            <PromptDeleteConfirm
                open={removeDialogOpened || unsyncDialogOpened}
                onClose={removeDialogOpened ? handleRemoveDialogClose : handleUnsyncDialogClose}
            >
                <Typography variant="h3">{t("class.list.deleteClassPromptHeader")}</Typography>
                <Box pt={3}>{t("class.list.deleteClassPromptType")}</Box>
            </PromptDeleteConfirm>
        </Box>
    );
};

export default ClassTrackerTable;
