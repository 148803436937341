import { ROUTE_TRUST_USERS, ROUTE_TRUST_DASHBOARD, ROUTE_TRUST_USER_DETAILS } from "src/routes";
import TrustUserListContainer from "../containers/UserList";
import TrustDashboardContainer from "../containers/Dashboard";
import TrustUserDetailsContainer from "../containers/UserDetails";

export const TrustRoutes = [
    {
        path: ROUTE_TRUST_USERS,
        element: <TrustUserListContainer />,
    },
    {
        path: ROUTE_TRUST_USER_DETAILS,
        element: <TrustUserDetailsContainer />,
    },
    {
        path: ROUTE_TRUST_DASHBOARD,
        element: <TrustDashboardContainer />,
    },
];
