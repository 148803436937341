import { useFormContext, Controller } from "react-hook-form";
import { IconButton, InputAdornment, TextField, TextFieldProps, Tooltip } from "@mui/material";
import { forwardRef, useState } from "react";
import { mdiEye, mdiEyeOff, mdiInformationVariant } from "@mdi/js";
import Icon from "../Icon";

interface IProps {
    name: string;
    tooltip?: string;
    password?: boolean;
}

const RHFTextField = forwardRef(
    ({ name, tooltip, password, ...other }: IProps & TextFieldProps, ref) => {
        const { control } = useFormContext();
        const [showPassword, setShowPassword] = useState(false);

        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                        type={password ? (showPassword ? "text" : "password") : undefined}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {password ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                <Icon path={showPassword ? mdiEye : mdiEyeOff} />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : undefined}
                                    {tooltip ? (
                                        <InputAdornment position="end">
                                            <Tooltip title={tooltip || ""}>
                                                <IconButton edge="end">
                                                    <Icon path={mdiInformationVariant} />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ) : undefined}
                                </>
                            ),
                        }}
                        {...field}
                        {...other}
                        value={field.value || ""}
                        placeholder={other.placeholder}
                        inputRef={ref}
                    />
                )}
            />
        );
    },
);
RHFTextField.displayName = "RHFTextField";
export default RHFTextField;
