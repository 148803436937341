import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import Icon from "src/components/Icon";
import { mdiDownload, mdiFlipToFront, mdiMinusCircleOutline } from "@mdi/js";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Dispatch } from "redux";
import { TrackerActions } from "../../store/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useMount } from "src/hooks/useMount";
import ClearForecastModal from "./ClearForecastModal";
import { ForecastUnitList } from "../../api/Forecast/ClassTrackerGroup/getUnitList";
import { AppState } from "src/store/reducers";
import { ApiData, ApiStatus } from "src/api/constants";
import { useResponse } from "src/hooks/useResponse";
import { useSnackbar } from "notistack";
import LoadForecastCheckModal from "./LoadForecastCheckModal";
import LoadForecastForceModal from "./LoadForecastForceModal";
import { HTTP_SUCCESS_NON_AUTHORIATIVE } from "src/config/globals";
import COLORS from "src/styles/colors";
import { ClassActions } from "src/modules/class/store/actions";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { useParams } from "react-router";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

const dispatchActions = (dispatch: Dispatch) => ({
    getForecastClassTrackerGroupUnits: (classTrackerGroup: number, tier: TiersTypes) => {
        dispatch(TrackerActions.getForecastClassTrackerGroupUnits(classTrackerGroup, tier));
    },
    loadForecastClassTrackerCheck: (classTracker: number, dataSource: string, tier: TiersTypes) => {
        dispatch(TrackerActions.loadForecastClassTrackerCheck(classTracker, dataSource, tier));
    },
    clearForecastClassTrackerValues: (
        classTracker: number,
        dataSource: string,
        tier: TiersTypes,
    ) => {
        dispatch(TrackerActions.clearForecastClassTrackerValues(classTracker, dataSource, tier));
    },
    loadForecastClassTrackerForce: (classTracker: number, dataSource: string, tier: TiersTypes) => {
        dispatch(TrackerActions.loadForecastClassTrackerForce(classTracker, dataSource, tier));
    },
    loadForecastClassTrackerGroupCheck: (
        classTrackerGroup: number,
        dataSource: string,
        tier: TiersTypes,
    ) => {
        dispatch(
            TrackerActions.loadForecastClassTrackerGroupCheck(classTrackerGroup, dataSource, tier),
        );
    },
    clearForecastClassTrackerGroupValues: (
        classTrackerGroup: number,
        dataSource: string,
        tier: TiersTypes,
    ) => {
        dispatch(
            TrackerActions.clearForecastClassTrackerGroupValues(
                classTrackerGroup,
                dataSource,
                tier,
            ),
        );
    },
    loadForecastClassTrackerGroupForce: (
        classTrackerGroup: number,
        dataSource: string,
        tier: TiersTypes,
    ) => {
        dispatch(
            TrackerActions.loadForecastClassTrackerGroupForce(classTrackerGroup, dataSource, tier),
        );
    },
    clearLastUpdatedClassTrackers: () => {
        dispatch(ClassActions.clearLastUpdatedClassTrackers());
    },
});

const ForecastActionButtons = ({
    exportAsExcel,
    classTrackerGroup,
    tier,
    handleBlocked,
    handleDataAsyncLoaded,
    isGroup,
    gridApi,
}: {
    exportAsExcel: () => void;
    classTrackerGroup?: any;
    handleBlocked?: (locked?: boolean) => void;
    handleDataAsyncLoaded?: () => void;
    isGroup?: boolean;
    gridApi: any;
    tier?: TiersTypes;
}) => {
    const { t }: { t: TFunction } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { classTracker } = useParams<{ classTracker: string }>();
    const dispatch = useDispatch();
    const [tmpDataSource, setTmpDataSource] = useState<string | null>(null);
    const {
        getForecastClassTrackerGroupUnits,
        clearForecastClassTrackerValues,
        loadForecastClassTrackerCheck,
        loadForecastClassTrackerForce,
        clearForecastClassTrackerGroupValues,
        loadForecastClassTrackerGroupCheck,
        clearLastUpdatedClassTrackers,
        loadForecastClassTrackerGroupForce,
    } = dispatchActions(dispatch);
    const [clearForecastOpen, setClearForecastOpen] = useState<boolean>(false);
    const [loadForecastCheckOpen, setLoadForecastCheckOpen] = useState<boolean>(false);
    const [loadForecastForceOpen, setLoadForecastForceOpen] = useState<boolean>(false);
    const {
        classTrackerGroupUnits,
        apiClearForecast,
        apiLoadForecastCheck,
        apiLoadForecastForce,
        apiClearForecastGroup,
        apiLoadForecastGroupCheck,
        apiLoadForecastGroupForce,
        overlapingUnits,
    }: {
        classTrackerGroupUnits: ForecastUnitList[] | null;
        apiClearForecast: ApiData;
        apiLoadForecastCheck: ApiData;
        apiLoadForecastForce: ApiData;
        apiClearForecastGroup: ApiData;
        apiLoadForecastGroupCheck: ApiData;
        apiLoadForecastGroupForce: ApiData;
        overlapingUnits: ForecastUnitList | null;
    } = useSelector(
        (state: AppState) => ({
            classTrackerGroupUnits: state.tracker.classTrackerGroupUnits,
            apiClearForecast: state.api.tracker.clearForecastClassTrackerValues,
            apiLoadForecastCheck: state.api.tracker.loadForecastClassTrackerCheck,
            apiLoadForecastForce: state.api.tracker.loadForecastClassTrackerForce,
            apiClearForecastGroup: state.api.tracker.clearForecastClassTrackerGroupValues,
            apiLoadForecastGroupCheck: state.api.tracker.loadForecastClassTrackerGroupCheck,
            apiLoadForecastGroupForce: state.api.tracker.loadForecastClassTrackerGroupForce,
            overlapingUnits: state.tracker.loadForecastOverlapUnits,
        }),
        shallowEqual,
    );

    useEffect(() => {
        if (overlapingUnits && gridApi?.current) {
            gridApi.current.stopEditing();
        }
    }, [gridApi, overlapingUnits]);

    useMount(() => {
        if (classTrackerGroup) {
            getForecastClassTrackerGroupUnits(classTrackerGroup, tier || "-");
        }
    });

    const handleClearForecastClose = (confirm: boolean, dataSource?: string) => {
        if (confirm && dataSource) {
            // if (handleBlocked) handleBlocked();
            if (isGroup && classTrackerGroup) {
                clearForecastClassTrackerGroupValues(
                    classTrackerGroup,
                    `${dataSource}`,
                    tier || "-",
                );
            } else {
                clearForecastClassTrackerValues(
                    parseInt(classTracker || ""),
                    `${dataSource}`,
                    tier || "-",
                );
            }
        }
        setClearForecastOpen(false);
    };

    const handleLoadForecastCheckClose = (confirm: boolean, dataSource?: string) => {
        if (confirm && dataSource) {
            // if (handleBlocked) handleBlocked();
            setTmpDataSource(`${dataSource}`);
            if (isGroup && classTrackerGroup) {
                loadForecastClassTrackerGroupCheck(classTrackerGroup, `${dataSource}`, tier || "-");
            } else {
                loadForecastClassTrackerCheck(
                    parseInt(classTracker || ""),
                    `${dataSource}`,
                    tier || "-",
                );
            }
        }
        setLoadForecastCheckOpen(false);
    };

    const handleLoadForecastForceClose = (confirm: boolean, dataSource?: string) => {
        if (confirm && dataSource) {
            // if (handleBlocked) handleBlocked();
            if (isGroup && classTrackerGroup) {
                loadForecastClassTrackerGroupForce(classTrackerGroup, `${dataSource}`, tier || "-");
            } else {
                loadForecastClassTrackerForce(
                    parseInt(classTracker || ""),
                    `${dataSource}`,
                    tier || "-",
                );
            }
        }
        if (!confirm) {
            if (handleBlocked) handleBlocked(false);
        }

        setLoadForecastForceOpen(false);
    };

    // CLASS TRACKER RESPONSES
    useResponse(() => {
        if (apiClearForecast.status === ApiStatus.SUCCESS && handleDataAsyncLoaded) {
            // if (handleBlocked) handleBlocked();
            // handleDataAsyncLoaded();
        } else {
            enqueueSnackbar(
                t("tracker.forecast.errorClearingDataMsg", {
                    ...SnackbarErrorOptions,
                }),
            );
        }
    }, apiClearForecast);

    useResponse(() => {
        clearLastUpdatedClassTrackers();
        if (apiLoadForecastCheck.responseStatus === HTTP_SUCCESS_NON_AUTHORIATIVE) {
            setLoadForecastForceOpen(true);
        } else if (apiLoadForecastCheck.status === ApiStatus.SUCCESS && handleDataAsyncLoaded) {
            if (handleBlocked) {
                handleBlocked();
            }

            handleDataAsyncLoaded();
        }
    }, apiLoadForecastCheck);

    useResponse(() => {
        if (apiLoadForecastForce.status === ApiStatus.SUCCESS && handleDataAsyncLoaded) {
            if (handleBlocked) handleBlocked();
            handleDataAsyncLoaded();
        } else {
            enqueueSnackbar(
                t("tracker.forecast.errorWhileForceLoadMsg", {
                    ...SnackbarErrorOptions,
                }),
            );
        }
    }, apiLoadForecastForce);

    // CLASS TRACKER GROUPS RESPONSES
    useResponse(() => {
        if (apiClearForecastGroup.status === ApiStatus.SUCCESS && handleDataAsyncLoaded) {
            // if (handleBlocked) handleBlocked();
            // handleDataAsyncLoaded();
        } else {
            enqueueSnackbar(
                t("tracker.forecast.errorClearingDataMsg", {
                    ...SnackbarErrorOptions,
                }),
            );
        }
    }, apiClearForecastGroup);

    useResponse(() => {
        if (apiLoadForecastGroupCheck.responseStatus === HTTP_SUCCESS_NON_AUTHORIATIVE) {
            setLoadForecastForceOpen(true);
        } else if (
            apiLoadForecastGroupCheck.status === ApiStatus.SUCCESS &&
            handleDataAsyncLoaded
        ) {
            if (handleBlocked) handleBlocked();
            handleDataAsyncLoaded();
        }
    }, apiLoadForecastGroupCheck);

    useResponse(() => {
        if (apiLoadForecastGroupForce.status === ApiStatus.SUCCESS && handleDataAsyncLoaded) {
            if (handleBlocked) handleBlocked();
            handleDataAsyncLoaded();
        } else {
            enqueueSnackbar(
                t("tracker.forecast.errorWhileForceLoadMsg", {
                    ...SnackbarErrorOptions,
                }),
            );
        }
    }, apiLoadForecastGroupForce);

    return (
        <>
            <Box
                px={4}
                py={3}
                borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                display="flex"
                flexDirection="column"
            >
                <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27} mb={1}>
                    {t("tracker.grid.toolsTracker")}
                </Box>
                <Button
                    variant="text"
                    onClick={exportAsExcel}
                    startIcon={<Icon path={mdiDownload} />}
                    disableRipple
                >
                    {t("tracker.grid.downloadBtn")}
                </Button>
                <Button
                    onClick={() => {
                        if (gridApi?.current) {
                            gridApi.current.stopEditing();
                        }
                        setLoadForecastCheckOpen(true);
                    }}
                    variant="text"
                    startIcon={<Icon path={mdiFlipToFront} />}
                    disableRipple
                >
                    {t("tracker.grid.forecastLoadDataBtn")}
                </Button>
                <Button
                    onClick={() => setClearForecastOpen(true)}
                    variant="text"
                    startIcon={<Icon path={mdiMinusCircleOutline} />}
                    disableRipple
                >
                    {t("tracker.grid.clearForecastBtn")}
                </Button>
            </Box>
            <ClearForecastModal
                open={clearForecastOpen}
                handleClose={handleClearForecastClose}
                units={classTrackerGroupUnits}
            />
            <LoadForecastCheckModal
                open={loadForecastCheckOpen}
                handleClose={handleLoadForecastCheckClose}
                units={classTrackerGroupUnits}
            />
            {overlapingUnits && (
                <LoadForecastForceModal
                    dataSource={tmpDataSource}
                    open={loadForecastForceOpen}
                    overlapingUnits={overlapingUnits || null}
                    handleClose={handleLoadForecastForceClose}
                />
            )}
        </>
    );
};

export default ForecastActionButtons;
