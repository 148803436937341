import { attr, Model } from "redux-orm";
import { SubscriptionQualification, SubscriptionSubjectArea } from "../Subscription";
import { OrmUser } from "../User";

const fields = {
    id: attr(),
    dueDate: attr(),
    couponName: attr(),
    couponValue: attr(),
    subscriptionLength: attr(),
    schoolUser: attr(),
    startDate: attr(),
    price: attr(),
    subscriptionId: attr(),
    dateAndTime: attr(),
    subscriptionType: attr(),
    subjectArea: attr(),
    qualifications: attr(),
    totalPrice: attr(),
    vatValue: attr(),
};

export interface OrmSubscriptionHistory {
    id: number;
    dueDate: string;
    couponName: string;
    couponValue: string;
    subscriptionLength: string;
    schoolUser: OrmUser | null;
    startDate: string;
    price: string;
    subscriptionId: number | null;
    dateAndTime: string;
    subscriptionType: string;
    subjectArea: SubscriptionSubjectArea | null;
    qualifications: SubscriptionQualification[];
    totalPrice?: string | null;
    vatValue?: string | null;
}

class SubscriptionHistory extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "SubscriptionHistory";
    static fields = fields;
    static emptyRef: OrmSubscriptionHistory = {
        id: 0,
        dueDate: "",
        couponName: "",
        couponValue: "",
        subscriptionLength: "",
        schoolUser: null,
        startDate: "",
        price: "",
        subscriptionId: null,
        dateAndTime: "",
        subscriptionType: "",
        subjectArea: null,
        qualifications: [],
    };

    get ormRef(): OrmSubscriptionHistory {
        return this.ref as any;
    }

    get ormRefDetails(): OrmSubscriptionHistory {
        return this.ormRef as any;
    }
}

export default SubscriptionHistory;
