import PromptDialog from "src/forms/PromptDialog";
import COLORS from "src/styles/colors";
import CustomTable from "src/components/CustomTable";
import Icon, { mdiResubscribe } from "src/components/Icon";
import { Box, Button, Paper, Chip } from "@mui/material";
import {
    mdiDomain,
    mdiCircleMultipleOutline,
    mdiTriangleOutline,
    mdiFileDocument,
    mdiAutorenew,
} from "@mdi/js";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { CellDataGetterParams } from "src/components/CustomTable/types";
import {
    OrmSubscription,
    SubscriptionStatusLabels,
    SubscriptionTypeLabels,
    SubscriptionTypes,
    SubscriptionStatus,
} from "src/orm/models/Subscription";
import {
    ROUTE_BUY_AFTER_TRIAL_SUBSCRIPTION,
    ROUTE_BUY_SUBSCRIPTION,
    ROUTE_EXTEND_SUBSCRIPTION,
    ROUTE_REQUEST_NEW_TRIAL,
    ROUTE_UPGRADE_SUBSCRIPTION,
    ROUTE_SUBSCRIPTION_LIST_INVOICE_FILTER,
    ROUTE_UPGRADE_TO_WHOLE_SCHOOL,
} from "src/routes";
import { convertIsoPeriodToString } from "src/services/translation";
import { AppState } from "src/store/reducers";
import { format, differenceInDays, isPast } from "date-fns";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { subscriptionsSelector } from "../selectors/SubscriptionSelectors";
import { SubscriptionsActions } from "../store/actions";
import { SubscriptionsActions as SubActions } from "../containers/SubscriptionList";
import { useMount } from "src/hooks/useMount";
import { SchoolUserRole } from "src/orm/models/User";
import { REQUEST_WHOLESCHOOL_URL } from "src/config/globals";
import { ApiData, ApiStatus } from "src/api/constants";
import { useResponse } from "src/hooks/useResponse";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { CommonAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

interface OwnProps {
    accountInfo: CommonAccountInfo | null;
}

const dispatchActions = (dispatch: Dispatch) => ({
    getSubscriptions: () => {
        dispatch(SubscriptionsActions.getSubscriptionList());
    },
    declineResubscription: (subscription: number) => {
        dispatch(SubscriptionsActions.declineResubscription(subscription));
    },
    convertToNaht: (subscription: number) => {
        dispatch(SubscriptionsActions.convertToNahtSubscription(subscription));
    },
    extendNaht: (subscription: number) => {
        dispatch(SubscriptionsActions.extendNahtSubscription(subscription));
    },
});

const OwnedList: FC<OwnProps> = ({ accountInfo }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { getSubscriptions, declineResubscription, convertToNaht, extendNaht } =
        dispatchActions(dispatch);
    const [declineDialogOpened, setDeclineDialogOpened] = useState<boolean>(false);
    const [convertNahtDialogOpened, setConvertNahtDialogOpened] = useState<boolean>(false);
    const [nahtExtendOpened, setNahtExtendOpened] = useState<boolean>(false);
    const [declineId, setDeclineId] = useState<number | null>(null);
    const [convertId, setConvertId] = useState<number | null>(null);
    const [nahtExtendId, setNahtExtendId] = useState<number | null>(null);
    const { attachSchoolId } = useSchoolNavigate();
    const { data: authUser } = useProfile();
    const {
        subscriptions,
        apiConvertNaht,
        apiExtendNaht,
    }: {
        subscriptions: OrmSubscription[];
        apiConvertNaht: ApiData;
        apiExtendNaht: ApiData;
    } = useSelector((state: AppState) => ({
        subscriptions: subscriptionsSelector(state),
        apiConvertNaht: state.api.subscription.convertToNahtSubscription,
        apiExtendNaht: state.api.subscription.extendNahtSubscription,
    }));

    const handleConvertNahtDialogOpen = (id: number): void => {
        setConvertNahtDialogOpened(true);
        setConvertId(id);
    };

    const handleConvertNahtDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && convertId !== null) {
            convertToNaht(convertId);
        }
        setConvertNahtDialogOpened(false);
        setConvertId(null);
    };

    const handleNahtExtendDialogOpen = (id: number): void => {
        setNahtExtendOpened(true);
        setNahtExtendId(id);
    };

    const handleNahtExtendDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && nahtExtendId !== null) {
            extendNaht(nahtExtendId);
        }
        setNahtExtendOpened(false);
        setNahtExtendId(null);
    };

    const handleDeclineDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && declineId !== null) {
            declineResubscription(declineId);
        }
        setDeclineDialogOpened(false);
        setDeclineId(null);
    };

    const getExpiredCancelledColor = (status: string) => {
        if (status === SubscriptionStatus.EXPIRED || status === SubscriptionStatus.CANCELLED) {
            return COLORS.LIGHT_GREY_1;
        }
        return "inherit";
    };

    const getInviceOption = sub => {
        return `${sub.subscriptionType}-${(sub.subjectArea && sub.subjectArea.id) || "0"}-${
            sub.qualifications && sub.qualifications.length > 0
                ? sub.qualifications.map(q => q.name).join("-")
                : "0"
        }`;
    };

    const getStatus = (status: string) => {
        switch (status) {
            case SubscriptionStatus.EXPIRED:
                return (
                    <Box color={COLORS.LIGHT_GREY_1} fontWeight={700}>
                        {SubscriptionStatusLabels(t)[status]}
                    </Box>
                );
            case SubscriptionStatus.CANCELLED:
                return (
                    <Box color={COLORS.YELLOW_1} fontWeight={700}>
                        {SubscriptionStatusLabels(t)[status]}
                    </Box>
                );
            case SubscriptionStatus.PENDING:
                return (
                    <Box color={COLORS.YELLOW_1} fontWeight={700}>
                        {SubscriptionStatusLabels(t)[status]}
                    </Box>
                );
            case SubscriptionStatus.TRIAL:
                return (
                    <Box color={COLORS.BLUE_3} fontWeight={700}>
                        {SubscriptionStatusLabels(t)[status]}
                    </Box>
                );
            default:
                return SubscriptionStatusLabels(t)[status];
        }
    };

    useMount(() => getSubscriptions());

    useResponse(() => {
        if (apiConvertNaht.status === ApiStatus.ERROR) {
            enqueueSnackbar(t("subscription.list.errorConvertNaht"), {
                ...SnackbarErrorOptions,
            });
        }
    }, apiConvertNaht);

    useResponse(() => {
        if (apiExtendNaht.status === ApiStatus.ERROR) {
            enqueueSnackbar(t("subscription.list.errorExtendNaht"), {
                ...SnackbarErrorOptions,
            });
        }
    }, apiExtendNaht);

    return (
        <Fragment>
            {accountInfo &&
                (accountInfo.canStartNewFreeTrial ||
                    accountInfo.canStartNewSubscription ||
                    accountInfo.canUpgradeToWholeSchool) && (
                    <Box
                        mb={4}
                        display="flex"
                        alignItems="center"
                        justifyContent={
                            !accountInfo.canStartNewFreeTrial &&
                            !accountInfo.canStartNewSubscription
                                ? "flex-end"
                                : "space-between"
                        }
                    >
                        {(accountInfo.canStartNewFreeTrial ||
                            accountInfo.canStartNewSubscription) && (
                            <Box>
                                {accountInfo.canStartNewFreeTrial && (
                                    <Button
                                        component={Link}
                                        to={attachSchoolId(ROUTE_REQUEST_NEW_TRIAL)}
                                        color="primary"
                                    >
                                        {t("subscription.trial.startNewSubscription")}
                                    </Button>
                                )}
                                {accountInfo.canStartNewSubscription && (
                                    <Button
                                        component={Link}
                                        to={attachSchoolId(ROUTE_BUY_SUBSCRIPTION)}
                                        color="primary"
                                    >
                                        {t("subscription.list.buySubscription")}
                                    </Button>
                                )}
                            </Box>
                        )}
                        {accountInfo.canUpgradeToWholeSchool && (
                            <Button
                                component={Link}
                                to={attachSchoolId(ROUTE_UPGRADE_TO_WHOLE_SCHOOL)}
                                variant="text"
                                startIcon={<Icon path={mdiDomain} />}
                                disableRipple
                            >
                                {t("subscription.list.upgradeToWholeSchool")}
                            </Button>
                        )}
                        {accountInfo.canUpgradeToWholeSchool === false &&
                            accountInfo.isWholeSchoolActive === false && (
                                <Button
                                    onClick={() => {
                                        window.open(REQUEST_WHOLESCHOOL_URL || "/");
                                    }}
                                    variant="text"
                                    startIcon={<Icon path={mdiDomain} />}
                                    disableRipple
                                >
                                    {t("subscription.list.requestWholeSchoolBtn")}
                                </Button>
                            )}
                    </Box>
                )}
            <Paper>
                <CustomTable
                    data={subscriptions}
                    showPaginator
                    forceDotMenu={true}
                    columns={[
                        {
                            key: "subscription",
                            label: t("common.subscription"),
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSubscription>) => {
                                if (rowData) {
                                    return (
                                        <Box color={getExpiredCancelledColor(rowData.status)}>
                                            <Box fontSize={14} fontWeight={700}>
                                                {
                                                    SubscriptionTypeLabels(t)[
                                                        rowData.subscriptionType
                                                    ]
                                                }
                                                {rowData.qualifications.length > 0 &&
                                                    `(${rowData.qualifications.length} ${t(
                                                        rowData.qualifications.length === 1
                                                            ? "common.course"
                                                            : "common.courses",
                                                    )})`}
                                            </Box>
                                            {rowData.subscriptionLength ? (
                                                <Box mt={0.375} fontSize={11} fontStyle="italic">
                                                    {rowData.subStatus === "PiXL-Enabled"
                                                        ? t("subscription.list.allAcademicYear")
                                                        : convertIsoPeriodToString(
                                                              rowData.subscriptionLength,
                                                          )}
                                                </Box>
                                            ) : null}
                                        </Box>
                                    );
                                }
                                return "";
                            },
                        },
                        {
                            key: "subjectArea",
                            label: t("subscription.list.subjectArea"),
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSubscription>) => {
                                const subjectArea = rowData.subjectArea
                                    ? rowData.subjectArea.name
                                    : "";
                                const qualifications =
                                    rowData.qualifications.length > 0
                                        ? rowData.qualifications.map(q => q.name).join(", ")
                                        : rowData.subscriptionType ===
                                              SubscriptionTypes.FULL_SUITE &&
                                          t("subscription.add.allCourses");
                                if (subjectArea) {
                                    return (
                                        <Fragment>
                                            {subjectArea && (
                                                <Chip
                                                    label={subjectArea}
                                                    style={{
                                                        backgroundColor:
                                                            rowData.status ===
                                                                SubscriptionStatus.EXPIRED ||
                                                            rowData.status ===
                                                                SubscriptionStatus.CANCELLED
                                                                ? COLORS.LIGHT_GREY_1
                                                                : rowData.subjectArea.colour,
                                                    }}
                                                />
                                            )}
                                            {qualifications && (
                                                <Box
                                                    component="div"
                                                    fontStyle={
                                                        rowData.subscriptionType ===
                                                        SubscriptionTypes.FULL_SUITE
                                                            ? "italic"
                                                            : "normal"
                                                    }
                                                    fontSize={12}
                                                    mt={0.625}
                                                    color={getExpiredCancelledColor(rowData.status)}
                                                >
                                                    {qualifications}
                                                </Box>
                                            )}
                                        </Fragment>
                                    );
                                } else {
                                    return (
                                        <Box
                                            fontSize={12}
                                            color={getExpiredCancelledColor(rowData.status)}
                                        >
                                            {rowData.subscriptionType ===
                                            SubscriptionTypes.WHOLE_SCHOOL
                                                ? t("subscription.type.wholeSchool")
                                                : "-"}
                                        </Box>
                                    );
                                }
                            },
                        },
                        {
                            key: "status",
                            label: t("subscription.list.status"),
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSubscription>) => {
                                if (rowData.status) {
                                    return (
                                        <>
                                            <Box>{getStatus(rowData.status)}</Box>
                                            {rowData.subStatus && (
                                                <Box
                                                    mt={0.375}
                                                    fontSize={11}
                                                    fontStyle="italic"
                                                    color={COLORS.BLUE_3}
                                                >
                                                    {rowData.subStatus}
                                                </Box>
                                            )}
                                        </>
                                    );
                                }
                                return "";
                            },
                        },
                        {
                            key: "dueDate",
                            label: t("subscription.list.dueDate"),
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSubscription>) => {
                                const daysLeft = differenceInDays(
                                    new Date(rowData.dueDate),
                                    new Date(),
                                );
                                const isExpired = isPast(new Date(rowData.dueDate));

                                if (rowData.dueDate) {
                                    return (
                                        <Box color={getExpiredCancelledColor(rowData.status)}>
                                            <Box
                                                color={
                                                    daysLeft <= 30
                                                        ? isExpired
                                                            ? "inherit"
                                                            : COLORS.RED_1
                                                        : "inherit"
                                                }
                                            >
                                                {`${format(
                                                    new Date(rowData.dueDate),
                                                    "dd MMMM yyyy",
                                                )}`}
                                            </Box>
                                        </Box>
                                    );
                                }
                                return "";
                            },
                        },
                        {
                            key: "fastAction",
                            label: "",
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSubscription>) => {
                                if (
                                    accountInfo &&
                                    // accountInfo.canUpgradeToWholeSchool === true &&
                                    rowData.isDecline === false
                                ) {
                                    if (
                                        rowData.status === SubscriptionStatus.TRIAL &&
                                        rowData.buyAfterTrial === true
                                    ) {
                                        return (
                                            <Button
                                                component={Link}
                                                to={attachSchoolId(
                                                    ROUTE_BUY_AFTER_TRIAL_SUBSCRIPTION,
                                                ).replace(":id", `${rowData.id}`)}
                                                variant="text"
                                                startIcon={<Icon path={mdiCircleMultipleOutline} />}
                                                disableRipple
                                            >
                                                {t("subscription.list.buy")}
                                            </Button>
                                        );
                                    } else if (rowData.extend) {
                                        return (
                                            <Button
                                                component={Link}
                                                to={attachSchoolId(
                                                    ROUTE_EXTEND_SUBSCRIPTION,
                                                ).replace(":id", `${rowData.id}`)}
                                                variant="text"
                                                startIcon={<Icon path={mdiResubscribe} />}
                                                disableRipple
                                            >
                                                {t("subscription.list.extend")}
                                            </Button>
                                        );
                                    } else if (
                                        rowData.upgradeSingle === true &&
                                        rowData.subscriptionType === SubscriptionTypes.SINGLE
                                    ) {
                                        return (
                                            <Button
                                                component={Link}
                                                to={attachSchoolId(
                                                    ROUTE_UPGRADE_SUBSCRIPTION,
                                                ).replace(":id", `${rowData.id}`)}
                                                variant="text"
                                                startIcon={<Icon path={mdiTriangleOutline} />}
                                                disableRipple
                                            >
                                                {t("subscription.list.upgrade")}
                                            </Button>
                                        );
                                    }
                                }

                                return "";
                            },
                        },
                    ]}
                    actions={[
                        getMenuActionProps("upgrade", {
                            text: t("subscription.list.upgrade"),
                            icon: <Icon path={mdiTriangleOutline} />,
                            linkCallback: (s: OrmSubscription) =>
                                attachSchoolId(ROUTE_UPGRADE_SUBSCRIPTION).replace(
                                    ":id",
                                    `${s.id}`,
                                ),
                            showAction: (s: OrmSubscription) =>
                                s.subscriptionType === SubscriptionTypes.SINGLE &&
                                s.upgradeSingle === true,
                        }),
                        getMenuActionProps("extend", {
                            text: t("subscription.list.extend"),
                            icon: <Icon path={mdiResubscribe} />,
                            linkCallback: (s: OrmSubscription) =>
                                attachSchoolId(ROUTE_EXTEND_SUBSCRIPTION).replace(":id", `${s.id}`),
                            showAction: (s: OrmSubscription) => s.extend === true,
                        }),
                        getMenuActionProps("extend", {
                            text: t("subscription.list.extendNaht"),
                            icon: <Icon path={mdiResubscribe} />,
                            onAction: (s: OrmSubscription) => {
                                handleNahtExtendDialogOpen(s.id);
                            },
                            showAction: () => false,
                        }),
                        getMenuActionProps("showInvoice", {
                            text: t("subscription.list.showInvoice"),
                            icon: <Icon path={mdiFileDocument} />,
                            onAction: (s: OrmSubscription) => {
                                const url = ROUTE_SUBSCRIPTION_LIST_INVOICE_FILTER.replace(
                                    ":tab",
                                    SubActions.INVOICES,
                                ).replace(":filter", getInviceOption(s));
                                return navigate(url);
                            },
                            showAction: (s: OrmSubscription) =>
                                s.status !== SubscriptionStatus.TRIAL &&
                                s.hasInvoices === true &&
                                authUser.userRole !== SchoolUserRole.TEACHER &&
                                authUser.userRole !== SchoolUserRole.SCHOOL_ADMIN,
                        }),
                        getMenuActionProps("buy", {
                            text: t("subscription.list.buy"),
                            icon: <Icon path={mdiCircleMultipleOutline} />,
                            linkCallback: (s: OrmSubscription) =>
                                attachSchoolId(ROUTE_BUY_AFTER_TRIAL_SUBSCRIPTION).replace(
                                    ":id",
                                    `${s.id}`,
                                ),
                            showAction: (s: OrmSubscription) => s.buyAfterTrial === true,
                        }),
                        getMenuActionProps("convert", {
                            text: t("subscription.list.convertNaht"),
                            icon: <Icon path={mdiAutorenew} />,
                            onAction: (s: OrmSubscription) => {
                                s.id && handleConvertNahtDialogOpen(s.id);
                            },
                            showAction: (s: OrmSubscription) =>
                                s.canConvertToNahtSuppported === true,
                        }),
                    ]}
                />
            </Paper>
            <PromptDialog open={declineDialogOpened} onClose={handleDeclineDialogClose}>
                {t("subscription.list.declineMessage")}
            </PromptDialog>
            <PromptDialog
                title={t("subscription.list.convertNahtDialogHeader")}
                open={convertNahtDialogOpened}
                onClose={handleConvertNahtDialogClose}
                yesLabel={t("subscription.list.convertYes")}
                noLabel={t("subscription.list.convertNo")}
            >
                <Box mb={4}>{t("subscription.list.convertNahtDialogMessage")}</Box>
                <Box
                    dangerouslySetInnerHTML={{
                        __html: t("subscription.list.convertNahtDialogHint"),
                    }}
                ></Box>
            </PromptDialog>
            <PromptDialog open={nahtExtendOpened} onClose={handleNahtExtendDialogClose}>
                {t("subscription.list.nahtExtendMessage")}
            </PromptDialog>
        </Fragment>
    );
};

export default OwnedList;
