import { combineEpics } from "redux-observable";
import { classTrackerEpics } from "./ClassTracker/epics";
import { classTrackerGroupEpics } from "./ClassTrackerGroup/epics";
import { forecastClassTrackerEpics } from "./Forecast/epics";
import { snapshotClassTrackerEpics } from "./Snapshot/epics";

export const trackerEpics = combineEpics(
    classTrackerEpics,
    classTrackerGroupEpics,
    forecastClassTrackerEpics,
    snapshotClassTrackerEpics,
);
