export const isValueSet = (value: any) =>
    typeof value !== "undefined" && value !== null && value !== "";

export const isValueDefined = (value: any) =>
    typeof value !== "undefined" && value !== null && value !== "" && !isNaN(value);

export const removeSpecialCharacters = (str: string) => {
    const processed = str
        .trim()
        .replace(/[^\w\s]/g, "-")
        .replace(/\s+/g, "-")
        .replace("---", "-")
        .replace("--", "-");

    return processed;
};

export const getAllUniqueCharacters = (arr: string[]): string[] => {
    const uniqueCharacters = new Set();

    arr.forEach(str => {
        for (let i = 0; i < str.length; i++) {
            uniqueCharacters.add(str.charAt(i).toUpperCase());
            uniqueCharacters.add(str.charAt(i).toLowerCase());
        }
    });

    return Array.from(uniqueCharacters) as string[];
};

export const generateWordCombinations = (letters: string[], maxLength: number) => {
    const combinations = [];

    const totalCombinations = Math.pow(2, letters.length);

    for (let i = 1; i < totalCombinations; i++) {
        let currentWord = "";
        let bitmask = i;

        for (let j = 0; j < letters.length; j++) {
            if (bitmask & 1) {
                currentWord += letters[j];
            }
            bitmask >>= 1;
        }

        if (currentWord.length <= maxLength) {
            combinations.push(currentWord);
        }
    }

    return combinations;
};
