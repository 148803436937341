import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { TrackerActionTypes, TrackerActions } from "../actions";

const getClassTrackerGroupHeaderSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_CLASS_TRACKER_GROUP_HEADER_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getClassTrackerGroupHeaderSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerGroupHeader(payload.response))),
    );

const getClassTrackerGroupValuesSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_CLASS_TRACKER_GROUP_VALUES_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getClassTrackerGroupValuesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerGroupValues(payload.response))),
    );

const getClassTrackerGroupRelatedSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_CLASS_TRACKER_GROUP_RELATED_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getClassTrackerGroupRelatedSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerGroupRelated(payload.response))),
    );

export const classTrackerGroupEpics = combineEpics(
    getClassTrackerGroupHeaderSuccessEpic,
    getClassTrackerGroupValuesSuccessEpic,
    getClassTrackerGroupRelatedSuccessEpic,
);
