import { Tab, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";

interface OwnProps {
    icon?: string | React.ReactElement;
    label?: React.ReactNode;
    onChange?: any;
    onClick?: React.EventHandler<any>;
    selected?: boolean;
    value?: any;
    component?: any;
    to?: string;
}

export default withStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: 64,
            maxWidth: "none",
            padding: theme.spacing(1, 4),
            textTransform: "none",
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 400,
            whiteSpace: "nowrap",
            "&:hover": {
                color: theme.palette.primary.main,
                opacity: 1,
            },
            "&$selected": {
                color: theme.palette.primary.main,
                fontWeight: 600,
            },
            "&:focus": {
                color: theme.palette.primary.main,
            },
        },
        selected: {},
    }),
)((props: OwnProps) => <Tab disableRipple {...props} />);
