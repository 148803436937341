import { SubjectAnalysisCharTypes } from "./analysisValueHandler";
import COLORS from "src/styles/colors";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { AnalysisColourConfigDetailsObject } from "../api/Common/getRelatedData";

export const getAnalysisMtgColour = (metaColumnsConfig, colourConfig) => {
    let colour: AnalysisColourConfigDetailsObject | null = null;
    const mtgDistance = metaColumnsConfig?.mtgDistance;
    if (mtgDistance !== null && mtgDistance !== undefined && colourConfig) {
        if (mtgDistance === -1) {
            colour =
                colourConfig.find(
                    (c: AnalysisColourConfigDetailsObject) => c.mtgDistance === "-1",
                ) || null;
        } else if (mtgDistance > 0) {
            colour =
                colourConfig.find(
                    (c: AnalysisColourConfigDetailsObject) => c.mtgDistance === ">",
                ) || null;
        } else if (mtgDistance < 0) {
            colour =
                colourConfig.find(
                    (c: AnalysisColourConfigDetailsObject) => c.mtgDistance === "<",
                ) || null;
        } else {
            colour =
                colourConfig.find(
                    (c: AnalysisColourConfigDetailsObject) => c.mtgDistance === "==",
                ) || null;
        }
    }

    return colour as AnalysisColourConfigDetailsObject;
};

export const getGridBorderColor = (dataType: DataTypeTypes) => {
    switch (dataType) {
        case DataTypeTypes.LIVE:
            return COLORS.GREEN_1;
        case DataTypeTypes.SNAPSHOT:
            return COLORS.YELLOW_2;
        default:
            return COLORS.CYAN_1;
    }
};

export const getCharBarColor = (charType: SubjectAnalysisCharTypes) => {
    switch (charType) {
        case SubjectAnalysisCharTypes.ABOVE_TARGET:
            return COLORS.BLUE_4;
        case SubjectAnalysisCharTypes.ON_TARGET:
            return COLORS.GREEN_2;
        case SubjectAnalysisCharTypes.ONE_GRADE_AWAY:
            return COLORS.ORANGE_1;
        case SubjectAnalysisCharTypes.OFF_TARGET:
            return COLORS.RED_2;
        case SubjectAnalysisCharTypes.NOT_SET:
            return COLORS.GREY_1;
        default:
            return COLORS.YELLOW_2;
    }
};
