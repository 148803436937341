import { FC } from "react";
import { ColourConfig } from "src/modules/tracker/dto/TrackerHeader";
import { hexToRGB, DEFAULT_FIELD_OPACITY } from "src/modules/tracker/services/colorHandler";

interface OwnProps {
    customColor: string;
    label: string;
    colourConfig: ColourConfig;
}

export const CriterionHeader: FC<OwnProps> = ({ customColor, label }) => (
    <div
        className="ag-column-header-row ag-column-header-row--end"
        style={{
            backgroundColor: hexToRGB(customColor || "#fefefe", DEFAULT_FIELD_OPACITY),
        }}
    >
        <div
            className={`ag-column-header-label ${
                label.length < 3 ? "ag-column-header-label--horizontal" : ""
            }`}
        >
            <b>{label}</b>
        </div>
    </div>
);
