import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { sortArrayBy } from "src/services/array";
import { DataTypeTypes } from "../components/Common/TierHeaderSelect";
import { TiersTypes } from "../models/SpecificationModel";
import { calculateHeaderHeight, getFlatHeaderStructure } from "../services/headerGenerator";
import { mergeStudentColumnData, TrackerValueType } from "../services/valueHandler";
import { ClassTrackerHeaderObject } from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";
import { ClassTrackerValuesObject } from "../dto/TrackerValues";
import { useArchivedTrackerGroupData } from "../hooks/query/ArchivedClassTrackerGroup/useArchivedTrackerGroupData";
import { useTrackerGroupSnapshotData } from "../hooks/query/ClassTrackerGroup/useTrackerGroupSnapshotData";
import { useArchivedTrackerGroupCompareData } from "../hooks/query/ArchivedClassTrackerGroup/useArchivedTrackerGroupCompareData";
import TrackerGroupHeader from "../components/Header/TrackerGroupHeader";
import TrackerGrid, { TrackerContext } from "../components/Grid/TrackerGrid";
import { filterRowFromFilters } from "src/modules/common/services/valueHandler";

const ArchiveClassTrackerGroupContainer = () => {
    const { classTrackerGroup, cohort, tier } = useParams();
    const [dataSet, setDataSet] = useState(null);
    const [dataType, setDataType] = useState<DataTypeTypes>(DataTypeTypes.LIVE);
    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
    const [gridFilters, setGridFilters] = useState<any>(null);
    const [toolsVisible, setToolsVisible] = useState<boolean>(false);
    const [initialDataSet, setInitialDataSet] = useState<
        { [key: string]: TrackerValueType }[] | null
    >(null);

    const [currentHeader, setCurrentHeader] = useState<ClassTrackerHeaderObject | null>(null);
    const [currentRelated, setCurrentRelated] = useState<ClassTrackerRelatedObject | null>(null);
    const [currentValues, setCurrentValues] = useState<ClassTrackerValuesObject | null>(null);

    const [columnConfig, setColumnConfig] = useState<{ [key: string]: boolean }>({
        firstName: true,
        lastName: true,
    });

    const { header, values, relatedData, isLoadingTracker, isSuccessTracker } =
        useArchivedTrackerGroupData(parseInt(classTrackerGroup), (tier || "-") as TiersTypes);

    const {
        snapshotData,
        header: snapshotHeader,
        relatedData: snapshotRelatedData,
        values: snapshotValues,
        isSuccessAll: isSuccessTrackerSnapshot,
    } = useTrackerGroupSnapshotData(parseInt(classTrackerGroup), tier as TiersTypes);

    const {
        header: compareHeader,
        relatedData: compareRelatedData,
        values: compareValues,
        isSuccessAll: isSuccessTrackerCompare,
    } = useArchivedTrackerGroupCompareData(parseInt(classTrackerGroup), tier as TiersTypes);

    useEffect(() => {
        if (isSuccessTrackerSnapshot) {
            if (snapshotValues && snapshotHeader) {
                const rowData = mergeStudentColumnData(snapshotValues, snapshotHeader, false);
                sortArrayBy(rowData, "lastFirstName");
                setDataSet(rowData);
                setInitialDataSet(rowData);
                setCurrentValues(snapshotValues);
            }
        }
    }, [isSuccessTrackerSnapshot, snapshotHeader, snapshotValues]);

    useEffect(() => {
        if (isSuccessTrackerSnapshot && snapshotHeader) {
            setCurrentHeader(snapshotHeader);
        }
    }, [isSuccessTrackerSnapshot, snapshotHeader]);

    useEffect(() => {
        if (isSuccessTrackerSnapshot && snapshotRelatedData) {
            setCurrentRelated(snapshotRelatedData);
        }
    }, [isSuccessTrackerSnapshot, snapshotRelatedData]);
    useEffect(() => {
        if (isSuccessTrackerCompare) {
            if (compareValues && compareHeader) {
                const rowData = mergeStudentColumnData(compareValues.values1, compareHeader, false);
                sortArrayBy(rowData, "lastFirstName");
                setDataSet(rowData);
                setInitialDataSet(rowData);
                setCurrentValues(compareValues.values1);
            }
        }
    }, [isSuccessTrackerCompare, compareHeader, compareValues]);

    useEffect(() => {
        if (isSuccessTrackerCompare && compareHeader) {
            setCurrentHeader(compareHeader);
        }
    }, [isSuccessTrackerCompare, snapshotHeader]);

    useEffect(() => {
        if (isSuccessTrackerCompare && compareRelatedData) {
            setCurrentRelated(compareRelatedData);
        }
    }, [isSuccessTrackerCompare, compareRelatedData]);

    useEffect(() => {
        setCurrentHeader(header);
    }, [header]);

    useEffect(() => {
        setCurrentRelated(relatedData);
    }, [relatedData]);

    useEffect(() => {
        setCurrentValues(values);
    }, [values]);

    const handleColumnConfigPassData = values => {
        setColumnConfig(values);
    };

    const handleFiltersChange = filters => {
        setGridFilters(filters);
        setToolsVisible(false);
    };

    useEffect(() => {
        if (gridFilters !== null) {
            const filteredNew = initialDataSet?.filter(initialValues => {
                return filterRowFromFilters(
                    gridFilters,
                    initialValues,
                    undefined,
                    snapshotHeader,
                    snapshotRelatedData?.fineGradeEnabled,
                );
            });

            if (!filteredNew) {
                setDataSet([]);
            } else {
                setDataSet(filteredNew);
            }
        }
    }, [gridFilters, initialDataSet]);

    // // initial grid data fetch effect
    useEffect(() => {
        if (!isLoadingTracker) {
            if (currentValues && currentHeader) {
                const rowData = mergeStudentColumnData(currentValues, currentHeader, false);
                sortArrayBy(rowData, "lastFirstName");
                setDataSet(rowData);
                setInitialDataSet(rowData);
            }
        }
    }, [isLoadingTracker, currentValues, currentHeader]);

    const trackerContext: TrackerContext = {
        toolsVisible,
        cohortId: parseInt(cohort),
        classTrackerGroupId: parseInt(classTrackerGroup),
        flatHeader: getFlatHeaderStructure(header),
        relatedData,
        filtersVisible,
        headerHeight: calculateHeaderHeight(header),
        dataType,
        isBlocked: isLoadingTracker,
        columns: header?.columns,
        tier: tier as TiersTypes,
        isGradeVBoundaryProcessing: false,
        gridFilters,
        handleColumnConfigPassData,
        handleFiltersChange,
        compareValues: compareValues?.values2 || null,
        snapshotData,
        archived: true,
    };

    return (
        <Box component="main" height="100vh" pt={0} pb={3} display="flex" flexDirection="column">
            <TrackerGroupHeader
                tier={tier as TiersTypes}
                classTrackerGroupId={parseInt(classTrackerGroup) || undefined}
                archived={true}
                dataType={dataType}
                hasForecast={false}
                handleDataTypeChange={dataType => setDataType(dataType)}
                handleFiltersVisible={isChecked => {
                    setFiltersVisible(isChecked);
                    if (toolsVisible && isChecked) {
                        setToolsVisible(!isChecked);
                    }
                }}
                handleToolsVisible={isChecked => {
                    setToolsVisible(isChecked);
                    if (filtersVisible && isChecked) {
                        setFiltersVisible(!isChecked);
                    }
                }}
                relatedData={currentRelated}
                isLoadingGrid={isLoadingTracker}
                filtersVisible={filtersVisible}
                toolsVisible={toolsVisible}
            />
            <Box
                className="ag-theme-alpine classTrackerGrid"
                flex={1}
                width={isLoadingTracker ? 0 : "100%"}
                borderLeft={`10px solid #ccc`}
            >
                {isSuccessTracker && (
                    <TrackerGrid
                        isArchive={true}
                        isLoading={isLoadingTracker}
                        gridHeader={currentHeader}
                        gridRelated={currentRelated}
                        gridValues={currentValues}
                        gridUserSettings={{ columnConfig }}
                        dataSet={dataSet}
                        initialDataSet={initialDataSet}
                        isBlocked={false}
                        trackerContext={trackerContext}
                    />
                )}
            </Box>
        </Box>
    );
};

export default ArchiveClassTrackerGroupContainer;
