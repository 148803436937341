import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { CommonQualificationsResponse } from "src/modules/common/api/allowedQualifications";

export enum OrmQualificationActionTypes {
    BULK_IMPORT = "[orm] QUALIFICATION BULK IMPORT",
}

export const OrmQualificationActions = {
    bulkImport: (payload: { data: CommonQualificationsResponse; subjectArea: number }) => {
        return createAction(OrmQualificationActionTypes.BULK_IMPORT, payload);
    },
};

export type OrmQualificationActions = ActionsUnion<typeof OrmQualificationActions>;
