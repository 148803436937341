import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface ClassTrackerStudentTagRows {
    [key: string]: { tagId: number; enabled: boolean }[];
}

export interface ClassTrackerStudentTagRequest {
    id: number;
    rows: ClassTrackerStudentTagRows;
}

const apiStudentTagsRequest = async (values: ClassTrackerStudentTagRequest) => {
    return await axiosInstance.put(
        `school/${getSchoolAccountId()}/class-tracker/${values.id}/student-tags/`,
        {
            ...values.rows,
        },
    );
};

export const useClassTrackerStudentTagMutation = (onSuccess: () => void, onError?) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: ClassTrackerStudentTagRequest) => apiStudentTagsRequest(values),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error && typeof err?.response?.data?.error === "string") {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
