import { Dispatch } from "redux";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { ReportActions } from "../store/actions";

const dispatchActions = (dispatch: Dispatch) => ({
    validateUserReportRequest: () => {
        dispatch(ReportActions.validateUserReportRequest());
    },
});

export const useUserReportValidation = () => {
    const dispatch = useDispatch();
    const { validateUserReportRequest } = dispatchActions(dispatch);

    const { apiValidation } = useSelector(
        (state: AppState) => ({
            apiValidation: state.api.report.validateUserReportRequest,
        }),
        shallowEqual,
    );

    const checkValidation = () => {
        validateUserReportRequest();
    };

    return {
        apiValidation,
        checkValidation,
    };
};
