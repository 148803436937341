import Dialog from "src/components/Dialog";
import TagAddEditForm, {
    TagAddEditFormShape,
    addEditTagSchema,
    emptyTagForm,
} from "src/modules/tagging/components/forms/TagAddEditForm";
import FormikRef from "src/forms/FormikRef";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useRef } from "react";
import { TagEditRequest, useEditTagMutation } from "src/modules/tagging/hooks/useEditMutation";
import { useCreateTagMutation } from "src/modules/tagging/hooks/useCreateMutation";
import { useTagDetails } from "src/modules/tagging/hooks/useTagDetails";
import { useClassTrackerTagList } from "src/modules/tagging/hooks/ClassTracker/useClassTrackerTagList";

interface OwnProps {
    opened: boolean;
    tagId: number;
    classTrackerId: number;
    handleClose: (reload?: boolean) => void;
    handleCreateTag: (tagId: number) => void;
}

const TagsUpsertModal: FC<OwnProps> = ({
    opened,
    classTrackerId,
    tagId,
    handleClose,
    handleCreateTag,
}) => {
    const { t } = useTranslation();
    const form = useRef();
    const { refetch } = useClassTrackerTagList(classTrackerId);

    const handleErrorResponse = useCallback(
        errors => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            if (errors) currentForm.setErrors(errors);
        },
        [form],
    );

    const { mutate: addTag } = useCreateTagMutation(
        ({ id }) => {
            handleCreateTag(id);
        },
        ({ errors }) => handleErrorResponse(errors),
    );
    const { mutate: editTag } = useEditTagMutation(
        () => {
            refetch();
            handleClose(true);
        },
        ({ errors }) => handleErrorResponse(errors),
    );

    const { data: tagDetails } = useTagDetails(tagId);

    const initialValues = tagId
        ? {
              ...emptyTagForm,
              ...tagDetails,
              allYearGroups: tagDetails?.yearGroups?.length > 0 ? "selected" : "all",
              allSubjects: tagDetails?.specifications?.length > 0 ? "selected" : "all",
              yearGroups: tagDetails?.yearGroups || [],
              specifications: tagDetails?.specifications || [],
          }
        : emptyTagForm;

    const handleSubmit = ({ name, accessLevel, status, ...restValues }: TagAddEditFormShape) => {
        const data: TagEditRequest = {
            id: tagId,
            name,
            accessLevel,
            status,
            yearGroups:
                restValues.allYearGroups === "all" || restValues.yearGroups.length === 0
                    ? null
                    : restValues.yearGroups,
            specifications:
                restValues.allSubjects === "all" || restValues.specifications.length === 0
                    ? null
                    : restValues.specifications.map(({ specificationId }) => ({
                          id: specificationId,
                      })),
        };

        if (tagId) {
            editTag(data);
        } else {
            addTag(data);
        }
    };

    return (
        <FormikRef
            ref={form}
            initialValues={initialValues}
            validationSchema={() => addEditTagSchema(t)}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ submitForm, errors, submitCount, isSubmitting }) => (
                <Dialog
                    open={opened}
                    onClose={() => handleClose()}
                    title={t("tracker.grid.tags.headerAlt")}
                    actions={
                        <>
                            <Button onClick={submitForm} color="primary" disabled={isSubmitting}>
                                {t("tracker.grid.tags.saveBtn")}
                            </Button>
                            <Button onClick={() => handleClose()} color="inherit">
                                {t("common.cancel")}
                            </Button>
                        </>
                    }
                    contentOverflow="visible"
                >
                    <Box sx={{ mx: -6, mt: -3 }}>
                        <TagAddEditForm displayInDialog />
                    </Box>
                    <GenericErrorMessage errors={errors} submitCount={submitCount} />
                </Dialog>
            )}
        </FormikRef>
    );
};

export default TagsUpsertModal;
