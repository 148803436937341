import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { STUDENT_LIST_QUERY } from "./useStudentList";
import { getSchoolAccountId } from "src/services/url";

export interface StudentUninviteRequest {
    id?: number;
}

const apiUninviteStudent = async (values: StudentUninviteRequest) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/student/${values.id}/uninvite/`,
        values,
    );
};

export const useStudentUninviteMutation = (onSuccess: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: StudentUninviteRequest) => apiUninviteStudent(values),
        onSuccess: () => {
            enqueueSnackbar(t("students.addEdit.studentUninvited"), { variant: "success" });
            queryClient.refetchQueries({ queryKey: [STUDENT_LIST_QUERY] });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
