import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import Divider, { DividerProps as MuiDividerProps } from "@mui/material/Divider";

interface DividerVerticalProps {
    height: number;
    margin: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: (props: DividerVerticalProps) => props.height,
        margin: (props: DividerVerticalProps) => `0 ${theme.spacing(props.margin)}px`,
    },
}));

const DividerVertical = (
    props: DividerVerticalProps & Omit<MuiDividerProps, keyof DividerVerticalProps>,
) => {
    const { height = 24, margin = 3, ...other } = props;
    const classes = useStyles({ ...props, height, margin });
    return <Divider orientation="vertical" flexItem className={classes.root} {...other} />;
};

export default DividerVertical;
