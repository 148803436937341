import { Paper, Typography, Button, Box, CircularProgress } from "@mui/material";
import { useEffect, Fragment, useCallback, createRef, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import AppContainer from "src/components/AppContainer";
import { editClassSchema } from "../components/ClassTracking/forms/addClassSchema";
import { FormikProps } from "formik";
import FormikRef from "src/forms/FormikRef";
import { useSnackbar } from "notistack";
import { EditClassRequest } from "../api/Classes/editClass";
import { ClassActions } from "../store/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "src/store/reducers";
import { OrmClassTracker, TrackerStatus } from "src/orm/models/ClassTracker";
import { classTrackerDetailsSelector } from "src/modules/tracker/selectors/ClassTrackerSelectors";
import EditClassForm, {
    EditClassFormFields,
} from "../components/ClassTracking/forms/EditClassForm";
import { useMount } from "src/hooks/useMount";
import { usePrevious } from "src/hooks/usePrevious";
import DotsProgress from "src/components/DotsProgress";
import PaperInner from "src/components/PaperInner";
import { ApiStatus, ApiData } from "src/api/constants";
import { getErrorMessage, FormError } from "src/services/error";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { ListObject } from "src/forms/types";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { useLocation, useParams } from "react-router";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useTrackerPath } from "../hooks/useTrackerPath";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

import { ROUTE_CLASS_CLASS_LIST } from "src/routes";

const dispatchActions = (dispatch: Dispatch) => ({
    getClassDetails: (classTrackerId: number) => {
        dispatch(ClassActions.getClassDetails(classTrackerId));
    },
    clearStudents: () => {
        dispatch(ClassActions.clearStudents());
    },
    editClass: (classTrackerId: number, values: EditClassRequest) => {
        dispatch(ClassActions.editClass(classTrackerId, values));
    },
    clearClassTrackerList: () => {
        dispatch(TrackerActions.clearClassTrackerList());
    },
    getLastUpdatedClassTrackers: (cohort: number) => {
        dispatch(ClassActions.getLastUpdatedClassTrackers(cohort));
    },
});

const convertFormDataToRequest = (values: EditClassFormFields): EditClassRequest => ({
    name: values.name,
    yearGroup: values.yearGroup === 0 ? null : values.yearGroup,
    cohort: {
        id: values.cohort === 0 ? null : values.cohort,
    },
    teachers: values.teachers.map(teacher => {
        return { id: teacher.id || null };
    }),
    attributes: values.attributes
        .filter(attr => {
            return (
                (attr.isSelected === true || attr.isMandatory === true) &&
                attr.attributeItemId &&
                !values.disabledItems?.includes(attr.attributeItemId)
            );
        })
        .map(attr => ({
            id: attr.classTrackerAttributeValueId || undefined,
            customName: attr.customName || null,
            attributeValue: { id: attr.id },
        })),
    students: values.students.map(student => ({
        ...student,
        gradeId: student?.gradeId || null,
        id: student.id || undefined,
    })),
});

const EditClassContainer = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const form = createRef();
    const params = useParams();
    const classTrackerId = params?.class;
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const { navigate } = useSchoolNavigate();
    const { data: accountInfo } = useAccountInfo();
    const searchParams = new URLSearchParams(location.search);
    const returnUrl = decodeURIComponent(searchParams.get("returnUrl"));
    const [isWaitingForTracker, setIsWaitingForTracker] = useState<boolean>(false);
    const { getClassDetails, editClass, clearClassTrackerList, getLastUpdatedClassTrackers } =
        dispatchActions(dispatch);
    const {
        classTracker,
        apiEdit,
    }: {
        classTracker: OrmClassTracker;
        apiEdit: ApiData;
    } = useSelector(
        (state: AppState) => ({
            classTracker: classTrackerDetailsSelector(state, classTrackerId),
            apiEdit: state.api.class.editClass,
        }),
        shallowEqual,
    );
    const interval = useRef() as any;
    const prevClassTrackerStatus = usePrevious(classTracker.status);
    useEffect(() => {
        if (
            isWaitingForTracker &&
            prevClassTrackerStatus !== TrackerStatus.LIVE &&
            classTracker.status === TrackerStatus.LIVE
        ) {
            clearInterval(interval.current);
            setIsWaitingForTracker(false);
            navigate(returnUrl);
        }
    }, [classTracker.status, prevClassTrackerStatus, isWaitingForTracker]);

    const { data: trackerPath, refetch } = useTrackerPath(classTracker?.specification?.id);

    const getTrackerPathParam = name => {
        if (trackerPath) {
            const filtered = trackerPath.filter(tp => tp.type === name);
            if (filtered.length > 1) {
                let nameWithParent = "";
                filtered.forEach((f: ListObject<number>, i) => {
                    nameWithParent = nameWithParent + (i > 0 ? " - " : "") + f.name;
                });
                return { ...filtered[filtered.length - 1], name: nameWithParent };
            }

            return trackerPath?.find(tp => tp.type === name) || null;
        }
        return null;
    };

    useMount(() => {
        getClassDetails(parseInt(classTrackerId || ""));
    });

    const prevSpecificationId = usePrevious(classTracker?.specification?.id);

    useEffect(() => {
        if (prevSpecificationId === undefined && classTracker?.specification?.id) {
            refetch();
        }
        //eslint-disable-next-line
    }, [classTracker?.specification?.id, prevSpecificationId]);

    const selectedSubjectArea = getTrackerPathParam("SubjectArea");
    const selectedQualification = getTrackerPathParam("Qualification");
    const selectedSpecification = getTrackerPathParam("Specification");

    const initialValues: EditClassFormFields = {
        specification: selectedSpecification?.id || null,
        name: classTracker.name || "",
        attributes: [],
        items: [],
        groups: [],
        groupCallStatus: classTracker.groupCallStatus,
        disabledItems: [],
        yearGroup: classTracker.yearGroup || 0,
        cohort: classTracker.cohort?.id || 0,
        chooseCohort: 0,
        chooseYearGroup: 0,
        teachers: classTracker.teachers
            ? classTracker.teachers
                  .filter(t => t.id !== undefined && t.id !== null)
                  .map(t => ({ ...t, id: t.id }))
            : [],
        students:
            classTracker?.students?.map((s, index) => ({
                ...s,
                index,
            })) || [],
        tmpStudentsAutocomplete: "",
        initialEditAttributes: classTracker?.attributes,
    };
    const handleSubmit = (values: EditClassFormFields) => {
        editClassSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid) {
                    const convertedValues = convertFormDataToRequest(values);
                    setIsWaitingForTracker(true);
                    editClass(parseInt(classTrackerId || ""), convertedValues);
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    const prevEditStatus = usePrevious(apiEdit.status);

    const handleErrorResponse = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    if (error.formError) currentForm.setErrors(error.formError);
                    break;
                }
            }
            setIsWaitingForTracker(false);
        },
        [enqueueSnackbar, form],
    );

    useEffect(() => {
        if (prevEditStatus === ApiStatus.LOADING && apiEdit.status === ApiStatus.SUCCESS) {
            if (location.search && returnUrl) {
                if (searchParams.get("await") === "1") {
                    interval.current = setInterval(() => {
                        getLastUpdatedClassTrackers(classTracker.cohort?.id);
                        if (classTracker.cohort?.id)
                            getLastUpdatedClassTrackers(classTracker.cohort?.id);
                    }, 2000);
                } else {
                    navigate(returnUrl);
                    setIsWaitingForTracker(false);
                }
            } else {
                navigate(ROUTE_CLASS_CLASS_LIST);
                setIsWaitingForTracker(false);
            }
        }
        if (prevEditStatus === ApiStatus.LOADING && apiEdit.status === ApiStatus.ERROR) {
            handleErrorResponse(apiEdit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        apiEdit,
        handleErrorResponse,
        location.search,
        prevEditStatus,
        props.history,
        searchParams,
    ]);

    useEffect(() => {
        return () => clearClassTrackerList();
    }, []);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.editClass.header")}
            </Typography>
            <Paper>
                {classTracker?.attributes !== undefined &&
                classTracker?.specification?.id &&
                prevSpecificationId ? (
                    <Fragment>
                        <FormikRef
                            ref={form}
                            initialValues={initialValues}
                            validationSchema={() => editClassSchema(t)}
                            onSubmit={handleSubmit}
                            enableReinitialize={true}
                        >
                            {(formProps: FormikProps<EditClassFormFields>) => (
                                <Fragment>
                                    <Box mt={4}>
                                        <EditClassForm
                                            selectedSubjectArea={selectedSubjectArea}
                                            selectedQualification={selectedQualification}
                                            selectedSpecification={selectedSpecification}
                                            isSyncedClass={
                                                accountInfo.hasGroupCallSynchronization &&
                                                formProps.values.groupCallStatus !== "manual" &&
                                                formProps.values.groupCallStatus !== "deleted"
                                            }
                                            formProps={formProps}
                                        />
                                        <PaperInner variant="paddingTopNone">
                                            <Button
                                                disabled={
                                                    formProps.isSubmitting || isWaitingForTracker
                                                }
                                                onClick={formProps.submitForm}
                                                color="primary"
                                            >
                                                {t("class.editClass.editClassButton")}
                                                {(isWaitingForTracker ||
                                                    formProps.isSubmitting) && (
                                                    <CircularProgress
                                                        size={16}
                                                        sx={{ marginLeft: 2 }}
                                                    />
                                                )}
                                            </Button>
                                            {isWaitingForTracker && (
                                                <Box component={"span"} sx={{ marginLeft: 2 }}>
                                                    {t("class.assessment.add.waitingForTracker")}
                                                </Box>
                                            )}
                                            <GenericErrorMessage
                                                errors={formProps.errors}
                                                submitCount={formProps.submitCount}
                                            />
                                        </PaperInner>
                                    </Box>
                                </Fragment>
                            )}
                        </FormikRef>
                    </Fragment>
                ) : (
                    <PaperInner>
                        <DotsProgress />
                    </PaperInner>
                )}
            </Paper>
        </AppContainer>
    );
};

export default EditClassContainer;
