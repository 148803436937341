import AppContainer from "src/components/AppContainer";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

import FormikRef from "src/forms/FormikRef";
import FieldChangeHandler from "src/forms/FieldChangeHandler";
import SpecificationSelectField from "src/forms/SpecificationSelectField";
import ErrorMessage from "src/forms/ErrorMessage";
import AttributesConfigList from "../../components/Common/AttributesConfigList";
import PaperInner from "src/components/PaperInner";
import HeadingCounter from "src/components/HeadingCounter";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { useCallback, useEffect, createRef } from "react";
import {
    AttributeValueExtended,
    AttributeItem,
    AttributeGroup,
} from "src/orm/models/SpecificationAttribute";
import { ClassActions } from "../../store/actions";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { FormError, getErrorMessage } from "src/services/error";
import { ApiStatus, ApiData } from "src/api/constants";
import { Form, Field, FormikProps } from "formik";
import { TFunction } from "i18next";
import { createMultipleClassSchema } from "../../components/ClassTracking/forms/addClassSchema";
import { usePrevious } from "src/hooks/usePrevious";
import { AppState } from "src/store/reducers";
import { Typography, Button, MenuItem, Box, Grid, CircularProgress, Paper } from "@mui/material";
import { TextField } from "formik-mui";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { DemoClassRequest } from "../../api/Classes/demoClass";
import { useResponse } from "src/hooks/useResponse";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { ROUTE_CLASS_CLASS_LIST } from "src/routes";

export interface DemoClassForm {
    specification: number | null;
    attributes: AttributeValueExtended[];
    items: AttributeItem[];
    groups: AttributeGroup[];
    disabledItems: number[];
    cohort: number | null;
    yearGroup: number | null;
    name: string;
}

const dispatchActions = (dispatch: Dispatch) => ({
    createDemoClass: (values: DemoClassRequest) => {
        dispatch(ClassActions.createDemoClass(values));
    },
    getTrackerValues: (classTrackerId: number, tier: TiersTypes) => {
        dispatch(TrackerActions.getClassTrackerValues(classTrackerId, tier));
    },
    getClassTrackerRelated: (classTrackerId: number) => {
        dispatch(TrackerActions.getClassTrackerRelated(classTrackerId));
    },
    clearTmpDemoClassId: () => {
        dispatch(ClassActions.clearTmpDemoClassId());
    },
});

export const convertFormDataToRequest = (values: DemoClassForm) => {
    return {
        specification: { id: values.specification },
        cohort: {
            id: values.cohort === 0 ? null : values.cohort,
        },
        yearGroup: values.yearGroup,
        name: values.name,
        attributes: values.attributes
            .filter(
                attr =>
                    (attr.isSelected === true || attr.isMandatory === true) &&
                    attr.attributeItemId &&
                    !values.disabledItems?.includes(attr.attributeItemId),
            )
            .map(attr => ({
                customName: attr.customName || null,
                attributeValue: { id: attr.id },
            })),
    };
};

const AddClassOtherDemoClassContainer = () => {
    const dispatch = useDispatch();
    const { t }: { t: TFunction } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { data: accountInfo } = useAccountInfo();
    const form = createRef() as any;
    const { navigate } = useSchoolNavigate();
    const activeCohortId = accountInfo?.activeCohortId || null;
    const { createDemoClass, getClassTrackerRelated, clearTmpDemoClassId } =
        dispatchActions(dispatch);

    const {
        apiCreated,
        apiGetTrackerRelated,
        tmpDemoClassId,
    }: {
        apiCreated: ApiData;
        apiGetTrackerRelated: ApiData;
        tmpDemoClassId: number | null;
    } = useSelector((state: AppState) => ({
        apiCreated: state.api.class.createDemoClass,
        apiGetTrackerRelated: state.api.tracker.getClassTrackerRelated,
        tmpDemoClassId: state.class.tmpDemoClassId,
    }));

    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const yearGroups = data?.yearGroups;

    const handleFormError = (error: FormError, currentForm) => {
        if (error.formError && "items" in error.formError) {
            const { items } = error.formError;
            if (items) {
                Object.keys(items).forEach(id => {
                    const index = currentForm.values.items.findIndex(
                        (i: AttributeItem) => i.id === parseInt(id),
                    );
                    const tmpItems: string[] = [];
                    tmpItems[index] = items[`${id}`];
                    error.formError = { items: tmpItems };
                });
            }
        }
        if (error.formError && "groups" in error.formError) {
            const { groups } = error.formError;
            if (groups) {
                Object.keys(groups).forEach(id => {
                    const index = currentForm.values.groups.findIndex(
                        (g: AttributeGroup) => g.id === parseInt(id),
                    );
                    const tmpGroups: string[] = [];
                    tmpGroups[index] = groups[`${id}`];
                    error.formError = { groups: tmpGroups };
                });
            }
        }
        currentForm.setErrors(error.formError);
    };

    const handleErrorResponse = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.formError) handleFormError(error, currentForm);
                    if (error.formError?.students && Array.isArray(error.formError?.students)) {
                        enqueueSnackbar(error.formError?.students[0], {
                            ...SnackbarErrorOptions,
                        });
                    }
                    break;
                }
            }
        },
        [enqueueSnackbar, form],
    );

    const getDefaultCohort = () => {
        if (cohorts && cohorts.length > 0) {
            return cohorts.find(c => c.id === activeCohortId)?.id || cohorts[0].id;
        }
        return null;
    };

    const initialValues: DemoClassForm = {
        specification: null,
        attributes: [],
        items: [],
        groups: [],
        disabledItems: [],
        cohort: getDefaultCohort(),
        name: "",
        yearGroup: null,
    };

    const handleSubmit = (values: DemoClassForm) => {
        createMultipleClassSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid) {
                    const convertedValues = convertFormDataToRequest(values);
                    createDemoClass(convertedValues as any);
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    const handleSpecificationChange = (
        newValue: number,
        oldValue: number,
        args: { setValues: (values: any) => void },
    ) => {
        if (newValue !== oldValue) {
            const { setValues } = args;
            setValues({ ...initialValues, specification: newValue });
        }
    };

    const prevApiStatus = usePrevious(apiCreated.status);

    useEffect(() => {
        if (prevApiStatus === ApiStatus.LOADING && apiCreated.status === ApiStatus.ERROR) {
            handleErrorResponse(apiCreated);
        }
    }, [apiCreated, handleErrorResponse, prevApiStatus]);

    useEffect(() => {
        return () => {
            clearTmpDemoClassId();
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (tmpDemoClassId && apiCreated.status === ApiStatus.SUCCESS) {
                getClassTrackerRelated(tmpDemoClassId);
            }
        }, 3000);
        return () => {
            clearInterval(interval);
        };
    }, [tmpDemoClassId]);

    useResponse(() => {
        if (apiGetTrackerRelated.status === ApiStatus.SUCCESS && tmpDemoClassId) {
            clearTmpDemoClassId();
            navigate(ROUTE_CLASS_CLASS_LIST);
        }
    }, apiGetTrackerRelated);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.addClass.headerOtherDemoClass")}
            </Typography>
            <Paper>
                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => createMultipleClassSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<DemoClassForm>) => (
                        <Form>
                            <PaperInner>
                                <FieldChangeHandler
                                    onChange={handleSpecificationChange}
                                    value={formProps.values.specification}
                                    setValues={formProps.setValues}
                                />
                                <HeadingCounter number="1">
                                    {t("class.addClass.chooseTrackerHeader")}
                                </HeadingCounter>
                                <Typography component="p" gutterBottom>
                                    {t("class.addClass.demoClassIntroText")}
                                </Typography>
                                <Field
                                    name={`specification`}
                                    liveOnly={true}
                                    component={SpecificationSelectField}
                                    showParentSpecification={true}
                                    allowedOnly={true}
                                />
                                <ErrorMessage name="specification" />
                                {formProps.values.specification && (
                                    <AttributesConfigList
                                        attributes={formProps.values.attributes}
                                        specificationId={formProps.values.specification}
                                        items={formProps.values.items}
                                        groups={formProps.values.groups}
                                        disabledItems={formProps.values.disabledItems}
                                        errors={formProps.errors}
                                        setFieldValue={formProps.setFieldValue}
                                        setFieldTouched={formProps.setFieldTouched}
                                        initialAttributes={[]}
                                    />
                                )}
                                {formProps.errors && formProps.errors["attributes"] && (
                                    <ErrorMessage name="attributes" />
                                )}
                            </PaperInner>
                            {formProps.values.specification && (
                                <>
                                    <PaperInner border="top">
                                        <HeadingCounter number="2">
                                            {t("class.addClass.chooseCohortAndName")}
                                        </HeadingCounter>
                                        <Grid container spacing={4}>
                                            <Grid item sm={4}>
                                                <Field
                                                    name="name"
                                                    label={t("class.addClass.className")}
                                                    component={TextField}
                                                />
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Field
                                                    name="cohort"
                                                    label={t("class.addClass.cohort")}
                                                    component={TextField}
                                                    select
                                                >
                                                    {cohorts &&
                                                        cohorts.map(cohort => (
                                                            <MenuItem
                                                                disabled={
                                                                    cohort.id !== activeCohortId
                                                                    // && cohort.id !== migrationCohort
                                                                }
                                                                key={cohort.id}
                                                                value={cohort.id}
                                                            >
                                                                {cohort.name}
                                                            </MenuItem>
                                                        ))}
                                                </Field>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Field
                                                    name="yearGroup"
                                                    label={t("class.addClass.yearGroup")}
                                                    component={TextField}
                                                    select
                                                >
                                                    <MenuItem key={-1} value={-1}>
                                                        {t("common.noneSelect")}
                                                    </MenuItem>
                                                    {yearGroups.map(year => (
                                                        <MenuItem key={year.id} value={year.id}>
                                                            {year.name}
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </PaperInner>
                                    <PaperInner border="top">
                                        <Box mt={0}>
                                            <Button
                                                disabled={formProps.isSubmitting}
                                                onClick={formProps.submitForm}
                                                color="primary"
                                            >
                                                {t("class.addClass.createDemoClassBtn")}{" "}
                                                {formProps.isSubmitting && (
                                                    <Box ml={2}>
                                                        <CircularProgress size={15} />
                                                    </Box>
                                                )}
                                            </Button>
                                            <GenericErrorMessage
                                                errors={formProps.errors}
                                                submitCount={formProps.submitCount}
                                            />
                                        </Box>
                                    </PaperInner>
                                </>
                            )}
                        </Form>
                    )}
                </FormikRef>
            </Paper>
        </AppContainer>
    );
};

export default AddClassOtherDemoClassContainer;
