import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSubscription } from "src/orm/models/Subscription";
import { getSchoolAccountId } from "src/services/url";

export const declineResubscriptionEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscriptions/{subscription}/decline-resubscription/`,
    method: ApiRequests.POST,
};

export type DeclineResubscriptionResponse = OrmSubscription;
