import Icon from "src/components/Icon";
import { mdiChevronDown } from "@mdi/js";
import { ColDef } from "ag-grid-community";
import PinnedTopRenderer from "./PinnedTopRenderer";
import clsx from "clsx";
import { useEffect, useState } from "react";
import {
    ColourTypes,
    getContrastText,
    // getCellColour,
    getCurrentGradeColour,
    hexToRGB,
} from "src/modules/tracker/services/colorHandler";
import { absenceValuesArray, absenceValuesLabels } from "src/modules/tracker/services/valueHandler";

interface OwnProps {
    value: any;
    context: any;
    node: any;
    colDef: ColDef;
    rowIndex: number;
    data: any;
    cellConfig;
    parentUnitColour: any;
}

const MtgRenderer = ({
    value,
    context,
    node,
    colDef,
    data,
    cellConfig,
    parentUnitColour,
}: OwnProps) => {
    const { field, headerComponentParams } = colDef;

    const { errors } = data;

    const fieldError = errors?.errors.find(e => e.row === data.row && e.column === field);
    const fineGrade = value?.fineGrade || "";
    const whileUpdate = value && value["whileUpdate"] ? value["whileUpdate"] : false;

    if (node.rowPinned) {
        return (
            <PinnedTopRenderer
                field={field}
                value={field && data && data ? data[field] : ""}
                context={context}
            />
        );
    }

    let displayName = "";

    const { compareValues } = context;

    const grades =
        context.relatedData?.courseGrades && context.relatedData?.courseGrades.length > 0
            ? context.relatedData?.courseGrades
            : context.relatedData?.grades;

    const convertedValue = value?.value !== undefined ? value.value : value;
    const isAbsenceValue = absenceValuesArray.includes(`${convertedValue}`);
    if (
        grades &&
        convertedValue !== undefined &&
        convertedValue !== null &&
        convertedValue !== ""
    ) {
        displayName = isAbsenceValue
            ? absenceValuesLabels[`${convertedValue}`]
            : grades.find(g => g.id === parseInt(`${convertedValue}`))?.name || "";
    }
    const savedFineGrade = data?.["metaColumnsConfig"]?.[field]?.fineGrade || "";

    if (fineGrade && !isAbsenceValue) {
        displayName = displayName + (fineGrade === "x" ? "" : fineGrade);
    } else if (savedFineGrade && !isAbsenceValue) {
        displayName =
            displayName + (displayName !== "U" && displayName !== "X" ? savedFineGrade : "");
    }

    const { colourConfig } = cellConfig;

    const [cellColour, setCellColour] = useState<{ colour: string; opacity: number } | null>(null);
    const { metaColumnsConfig } = data;

    useEffect(() => {
        if (headerComponentParams) {
            if (field && metaColumnsConfig) {
                const metaConfig =
                    metaColumnsConfig[field]?.mtgDistance !== undefined
                        ? metaColumnsConfig[field]
                        : undefined;

                if (colourConfig?.type === ColourTypes.MTG) {
                    const gradeColour = getCurrentGradeColour(
                        metaConfig,
                        cellConfig,
                        context.relatedData,
                        parentUnitColour,
                    );
                    setCellColour(gradeColour);
                }
            }
        }
    }, [headerComponentParams, value, metaColumnsConfig]);

    const backgroundColor =
        value !== null &&
        value !== undefined &&
        value !== "" &&
        cellColour?.colour &&
        cellColour.colour !== parentUnitColour
            ? hexToRGB(cellColour?.colour, cellColour?.opacity)
            : colourConfig?.type === ColourTypes.GRADE
              ? hexToRGB(cellColour?.colour, cellColour?.opacity)
              : "transparent";

    if (compareValues) {
        const values = compareValues;
        const compareRow = values[data.row];

        let compareValue: number | null = null;

        if (value !== null || (value !== undefined && !isNaN(parseInt(`${value}`)))) {
            const compareFrom = value as any;
            const compareTo = (
                !compareRow || !compareRow[field] || compareRow[field] < 0 ? 0 : compareRow[field]
            ) as any;
            compareValue = (compareFrom || 0) - (compareTo || 0);
            if (!value && compareRow && compareRow[field] && !isNaN(parseInt(compareRow[field]))) {
                const compareTo = parseInt(compareRow[field]);
                if (compareTo > 0) {
                    compareValue = 0 - (compareTo || 0);
                }
            }
        }
        return (
            <div className="ag-value-cell-compare-row">
                {compareValue !== null && compareValue !== undefined && (
                    <div
                        className={clsx(
                            "ag-value-cell-compare",
                            `ag-value-cell-compare--${
                                compareValue < 0 ? "red" : compareValue > 0 ? "blue" : "green"
                            }`,
                        )}
                    >
                        {!isNaN(compareValue) ? compareValue : ""}
                    </div>
                )}

                <div
                    className={clsx(
                        "ag-value-cell-compared-row",
                        "ag-value-cell-label",
                        `ag-value-cell-label--${
                            compareValue !== null && compareValue !== undefined && compareValue < 0
                                ? "red"
                                : compareValue !== null &&
                                    compareValue !== undefined &&
                                    compareValue > 0
                                  ? "blue"
                                  : "green"
                        }`,
                    )}
                >
                    {displayName}
                </div>
            </div>
        );
    }

    return (
        <div
            title={displayName}
            className="ag-value-cell-row"
            style={{
                backgroundColor,
                cursor: "pointer",
                padding: "8px 24px 8px 10px",
            }}
        >
            <div
                className="ag-value-cell-label"
                style={{
                    padding: 0,
                    textAlign: "center",
                    color: cellColour?.colour ? getContrastText(cellColour.colour) : undefined,
                }}
            >
                {displayName}
            </div>
            <div className="ag-value-cell__icon">
                <Icon path={mdiChevronDown} size="16px" />
            </div>
            {whileUpdate && (
                <span className={`ag-value-cell-status${" ag-value-cell-status--update"}`} />
            )}
            {fieldError && (
                <span
                    className={`ag-value-cell-status${
                        fieldError ? " ag-value-cell-status--error" : ""
                    }`}
                />
            )}
        </div>
    );
};

export default MtgRenderer;
