import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import { getSchoolAccountId } from "src/services/url";

export const getClassTrackersEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker/by-cohort/{cohort}/`,
    method: ApiRequests.GET,
};

export interface ClassTrackerListObject {
    id: number;
    yearGroup: number;
    subjectArea: {
        id: number;
        name: string;
        colour: string;
    };
    qualification: {
        id: number;
        name: string;
        numberOfLiveTrackers: number;
    };
    specification: {
        id: number;
        name: string;
        canAddCustomGradeBoundary: boolean;
        parentName: string;
        isTrackerLive: boolean;
    };
    hasAllObjects: boolean;
    hasGroupCallClassTrackers: boolean;
    classTrackers: OrmClassTracker[];
}

export interface ClassTrackerListResponse {
    data: ClassTrackerListObject[];
    nextPage: boolean;
}

export interface ClassTrackerListFilters {
    subjectAreaId?: number;
    qualificationId?: number;
    yearGroup?: number;
    page?: number;
    perPage?: number;
}
