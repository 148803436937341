import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const createAllSubjectsSnapshotReportEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/snapshot/report/all-subjects/`,
    method: ApiRequests.POST,
};

export interface CreateAllSubjectsSnapshotReportRequest {
    schoolSnapshot: ListObject<number>;
    yearGroup: number;
    publishedAt: string;
    reportTemplate: ListObject<number>;
    classTrackerGroups: ListObject<number>[] | null;
}
