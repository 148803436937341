export const excludedStudentParams = [
    "dateJoined",
    "classTrackerId",
    "id",
    "uniquePupilNumber",
    "candidateNumber",
    "admissionNumber",
    "ks2English",
    "ks2Math",
    "ks2FineLevel",
    "ks2EnglishScaled",
    "ks2MathScaled",
    "ks4Aps",
    "ks4Agaps",
    "catMean",
    "catVerbal",
    "catNonVerbal",
    "catNonVerbal",
    "catSpatial",
    "readingAge",
];
