import {
    hexToRGB,
    DEFAULT_DISPLAY_FIELD_OPACITY,
    DEFAULT_FIELD_OPACITY,
} from "src/modules/tracker/services/colorHandler";
import clsx from "clsx";
import { FC } from "react";
import { Column, SortDirection } from "ag-grid-community";
import { mdiSortAscending, mdiSortDescending } from "@mdi/js";
import Icon from "@mdi/react";

interface OwnProps {
    customColor: string;
    label: string;
    displayMaxMarks?: boolean;
    isDisplayField?: boolean;
    maxValue?: number | string;
    level?: number;
    setSort: (sort: SortDirection, multiSort?: boolean) => void;
    column: Column;
}

export const ColumnHeader: FC<OwnProps> = ({
    customColor,
    label,
    displayMaxMarks,
    isDisplayField,
    maxValue,
    level,
    setSort,
    column,
}) => {
    const sortDirection = column.getSort();
    return (
        <div
            style={{
                backgroundColor: hexToRGB(
                    customColor || "#fefefe",
                    isDisplayField ? DEFAULT_DISPLAY_FIELD_OPACITY : DEFAULT_FIELD_OPACITY,
                ),
            }}
            className={clsx(
                "ag-column-header-row",
                level && `ag-column-header-row--level-${level}`,
            )}
            onClick={
                column.getColDef().sortable
                    ? () => {
                          setSort(!sortDirection ? "asc" : sortDirection === "asc" ? "desc" : null);
                      }
                    : undefined
            }
        >
            <span className="ag-column-header-label ag-column-header-label--truncate">
                <span>{label}</span>
                {displayMaxMarks && maxValue !== null && <i>{`/ ${maxValue}`}</i>}
                {sortDirection && (
                    <b className="ag-column-header-label__sort">
                        {sortDirection === "asc" ? (
                            <Icon path={mdiSortAscending} size="18px" />
                        ) : (
                            <Icon path={mdiSortDescending} size="18px" />
                        )}
                    </b>
                )}
            </span>
        </div>
    );
};
