import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

const apiRemoveSnapshot = async (
    classTrackerGroupId: number,
    snapshotId: number,
): Promise<AxiosResponse> => {
    return await axiosInstance.delete(
        `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/snapshot/${snapshotId}/`,
    );
};

export const useRemoveSnapshotMutation = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({
            classTrackerGroupId,
            snapshotId,
        }: {
            classTrackerGroupId: number;
            snapshotId: number;
        }) => apiRemoveSnapshot(classTrackerGroupId, snapshotId),

        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
            enqueueSnackbar(t("common.removed"), { variant: "success" });
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
