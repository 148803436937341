import useDebounce from "src/hooks/useDebounce";
import AppContainer from "src/components/AppContainer";
import Icon from "src/components/Icon";
import ActionsTableButton from "src/components/ActionTableButton";
import TablePage, { TablePageFilterType } from "src/components/TablePage";
import ParentMassActionsModal from "../components/ParentMassActionModal";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { ROUTE_MY_SCHOOL_PARENT_ADD, ROUTE_MY_SCHOOL_PARENT_EDIT } from "src/routes";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { Link, useLocation } from "react-router-dom";
import {
    mdiAccountMultipleMinus,
    mdiAccountMultiplePlus,
    mdiCheck,
    mdiClose,
    mdiLinkOff,
} from "@mdi/js";
import { useParentActivateMutation } from "src/modules/parent/hooks/useActivateMutation";
import { useParentDeactivateMutation } from "src/modules/parent/hooks/useDeactivateMutation";
import { useParentRemoveMutation } from "src/modules/parent/hooks/useRemoveMutation";
import { useParentByIdInviteMutation } from "src/modules/parent/hooks/useInviteParentMutation";
import { useParentResendInvitationMutation } from "src/modules/parent/hooks/useResendInvitationMutation";
import { useParentUninviteMutation } from "src/modules/parent/hooks/useUninviteMutation";
import { useParentsList } from "../hooks/useParentsList";
import { ParentModel, ParentStatus } from "../model/ParentModel";
import { PaginationState, RowSelectionState } from "@tanstack/react-table";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export interface RequestParentsActions {
    requestType: "invite" | "download";
    criteria: "all" | "selected";
}

const ParentUserListContainer = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { data: accountInfo } = useAccountInfo();
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const hasSync = !!accountInfo?.hasGroupCallSynchronization;
    const [allParentsCount, setAllParentsCount] = useState<number>(0);
    const { attachSchoolId } = useSchoolNavigate();

    // delete parent dialogs
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);

    const [massActionModalOpened, setMassActionModalOpened] = useState<boolean>(false);

    // row selection (checkboxes)
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    // columns definition
    const [columns, setColumns] = useState([]);
    // filters and pagination
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 50,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    // filter types definition
    const [filters, setFilters] = useState<TablePageFilterType[]>([]);

    const debouncedFilters = useDebounce(filters, 500);

    const {
        data: parents,
        refetch: refetchParents,
        isLoading,
    } = useParentsList(
        { page: pagination.pageIndex + 1, perPage: pagination.pageSize },
        debouncedFilters.reduce((filters, df) => {
            filters[df.id] = df.value;
            return filters;
        }, {}),
    );

    // parent actions
    const { mutate: activateParent } = useParentActivateMutation(() => refetchParents());
    const { mutate: deactivateParent } = useParentDeactivateMutation(() => refetchParents());
    const { mutate: removeParent } = useParentRemoveMutation(() => refetchParents());
    const { mutate: inviteParentById } = useParentByIdInviteMutation(() => refetchParents());
    const { mutate: resendInvitation } = useParentResendInvitationMutation();
    const { mutate: unInviteParent } = useParentUninviteMutation(() => refetchParents());

    const tableActions = [
        getMenuActionProps("edit", {
            linkCallback: (rowData: ParentModel) =>
                attachSchoolId(ROUTE_MY_SCHOOL_PARENT_EDIT).replace(":parent", `${rowData.id}`),
        }),
        getMenuActionProps("activate", {
            text: t("parent.list.activate"),
            icon: <Icon path={mdiAccountMultiplePlus} />,
            onAction: (rowData: ParentModel) =>
                rowData.id ? activateParent({ id: rowData.id }) : null,
            showAction: (rowData: ParentModel) => rowData.status === ParentStatus.DEACTIVATED,
        }),
        getMenuActionProps("resendInvitation", {
            text: t("parent.list.resendInvitation"),
            icon: <Icon path={mdiAccountMultiplePlus} />,
            onAction: (rowData: ParentModel) =>
                rowData.id ? resendInvitation({ id: rowData.id }) : null,
            showAction: (rowData: ParentModel) => rowData.status === ParentStatus.INVITED,
        }),
        getMenuActionProps("invite", {
            text: t("parent.list.invite"),
            icon: <Icon path={mdiAccountMultiplePlus} />,
            onAction: (rowData: ParentModel) =>
                rowData.id ? inviteParentById({ id: rowData.id }) : null,
            showAction: (rowData: ParentModel) =>
                rowData.status === ParentStatus.ADDED ||
                rowData.status === ParentStatus.NOT_INVITED,
        }),
        getMenuActionProps("deactivate", {
            text: t("parent.list.deactivate"),
            icon: <Icon path={mdiAccountMultipleMinus} />,
            onAction: (rowData: ParentModel) =>
                rowData.id ? deactivateParent({ id: rowData.id }) : null,
            showAction: (rowData: ParentModel) => rowData.status === ParentStatus.CONFIRMED,
        }),
        getMenuActionProps("uninvite", {
            text: t("parent.list.uninvite"),
            icon: <Icon path={mdiAccountMultipleMinus} />,
            onAction: (rowData: ParentModel) =>
                rowData.id ? unInviteParent({ id: rowData.id }) : null,
            showAction: (rowData: ParentModel) => rowData.status === ParentStatus.INVITED,
        }),
        getMenuActionProps("delete", {
            onAction: (rowData: ParentModel) =>
                rowData.id ? handleDeleteDialogOpen(rowData.id) : null,
            showAction: () => !accountInfo?.hasGroupCallSynchronization,
        }),
    ];

    useEffect(() => {
        if (columns.length === 0 && accountInfo && parents) {
            setColumns([
                {
                    id: "status",
                    header: t("parent.list.status"),
                    cell: ({ row }) => {
                        if (row.original.status) {
                            return <Box>{t("parent.list.appStatus" + row.original.status)}</Box>;
                        }
                        return "-";
                    },
                },
                ...(hasSync
                    ? [
                          {
                              id: "groupCallIdaasId",
                              header: t("parent.list.groupCallId"),
                              accessorKey: "groupCallIdaasId",
                          },
                          {
                              id: "groupCallStatus",
                              label: t("parent.list.groupCallStatus"),
                              header: () => (
                                  <Box textAlign={"center"}>{t("parent.list.groupCallStatus")}</Box>
                              ),
                              cell: ({ row }) => {
                                  const { original: o } = row;

                                  return (
                                      <Box textAlign={"center"}>
                                          <Tooltip
                                              title={t(
                                                  "parent.list.groupCallStatus" + o.groupCallStatus,
                                                  { MIS_Name: accountInfo?.misName || "" },
                                              )}
                                          >
                                              <Box component="span">
                                                  {o.groupCallStatus === "active" ? (
                                                      <Icon path={mdiCheck} />
                                                  ) : o.groupCallStatus === "manual" ? (
                                                      <Icon path={mdiLinkOff} />
                                                  ) : (
                                                      <Icon path={mdiClose} />
                                                  )}
                                              </Box>
                                          </Tooltip>
                                      </Box>
                                  );
                              },
                          },
                      ]
                    : []),
                {
                    id: "userIdentifier",
                    header: t("parent.list.userIdentifier"),
                    accessorKey: "userIdentifier",
                },
                {
                    id: "firstName",
                    header: t("parent.list.firstName"),
                    accessorKey: "firstName",
                },
                {
                    id: "lastName",
                    header: t("parent.list.lastName"),
                    accessorKey: "lastName",
                },
                {
                    id: "numberOfStudentsAndYearGroups",
                    header: t("parent.list.numberOfStudentsAndYearGroups"),
                    accessorKey: "numberOfStudentsAndYearGroups",
                },
                {
                    // actions definition (always use id "actions")
                    id: "actions",
                    cell: ({ row }) => (
                        <Box textAlign={"right"}>
                            <ActionsTableButton
                                actions={tableActions}
                                rowData={row.original}
                                forceDotMenu={true}
                            />
                        </Box>
                    ),
                },
            ]);
        }
    }, [parents, hasSync]);

    const handleDeleteDialogOpen = (id: number): void => {
        setRemoveDialogOpened(true);
        setRemoveId(id);
    };

    const handleDeleteDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            removeParent({ id: removeId });
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    useEffect(() => {
        if (parents?.count && !filters.some(({ value }) => value) && allParentsCount === 0) {
            setAllParentsCount(parents.count);
        }
    }, [parents?.count, filters]);

    useEffect(() => {
        setFilters([
            {
                id: "name",
                type: "text",
                label: t("parent.list.filters.name"),
                value: searchParams["name"] || "",
            },
            {
                id: "userIdentifier",
                type: "text",
                label: t("parent.list.filters.userIdentifier"),
                value: searchParams["userIdentifier"] || "",
            },
            {
                id: "status",
                type: "select",
                label: t("parent.list.filters.status"),
                options: [
                    { label: t("common.noneFilter"), value: "" },
                    { label: t("parent.list.filters.statusNonInvitable"), value: "non-invitable" },
                    { label: t("parent.list.filters.statusNotInvited"), value: "not-invited" },
                    { label: t("parent.list.filters.statusInvited"), value: "invited" },
                    { label: t("parent.list.filters.statusConfirmed"), value: "confirmed" },
                    { label: t("parent.list.filters.statusDeactivated"), value: "deactivated" },
                ],
                value: searchParams["status"] || "",
            },
            ...(hasSync
                ? [
                      {
                          id: "groupCallStatus",
                          type: "select",
                          label: t("students.list.filters.groupCallStatus"),
                          options: [
                              { label: t("common.noneFilter"), value: "" },
                              { label: t("parent.list.groupCallStatusActive"), value: "active" },
                              { label: t("parent.list.groupCallStatusManual"), value: "manual" },
                              {
                                  label: t("parent.list.groupCallStatusParentDeleted").replaceAll(
                                      "MIS_Name",
                                      accountInfo?.misName || "",
                                  ),
                                  value: "deleted",
                              },
                          ],
                          value: searchParams["groupCallStatus"] || "",
                      },
                  ]
                : []),
            {
                id: "studentName",
                type: "text",
                label: t("parent.list.filters.studentName"),
                value: searchParams["studentName"] || "",
                initiallyHidden: true,
            },
            {
                id: "studentUpn",
                type: "text",
                label: t("parent.list.filters.studentUpn"),
                value: searchParams["studentUpn"] || "",
                initiallyHidden: true,
            },
            ...(hasSync
                ? [
                      {
                          id: "studentGroupCallStatus",
                          type: "select",
                          label: t("parent.list.filters.studentGroupCallStatus"),
                          options: [
                              { label: t("common.noneFilter"), value: "" },
                              {
                                  label: t("parent.list.groupCallStatusStudentActive"),
                                  value: "active",
                              },
                              {
                                  label: t("parent.list.groupCallStatusStudentManual"),
                                  value: "manual",
                              },
                              {
                                  label: t("parent.list.groupCallStatusStudentDeleted").replaceAll(
                                      "MIS_Name",
                                      accountInfo?.misName || "",
                                  ),
                                  value: "deleted",
                              },
                          ],
                          value: searchParams["studentGroupCallStatus"] || "",
                          initiallyHidden: true,
                      },
                  ]
                : []),
            {
                id: "studentYearGroup",
                type: "text",
                label: t("parent.list.filters.studentYearGroup"),
                value: searchParams["studentYearGroup"] || "",
                initiallyHidden: true,
            },
            {
                id: "studentForm",
                type: "text",
                label: t("parent.list.filters.studentForm"),
                value: searchParams["studentForm"] || "",
                initiallyHidden: true,
            },
        ]);
    }, [accountInfo, hasSync]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("parent.tab")}
            </Typography>
            <TablePage
                headerNavButtons={
                    <>
                        <Button
                            disableRipple
                            onClick={() => setMassActionModalOpened(true)}
                            id="appParentActionsButton"
                            color="primary"
                        >
                            {t("parent.list.parentActions")}
                        </Button>
                        <Button
                            component={Link}
                            to={attachSchoolId(ROUTE_MY_SCHOOL_PARENT_ADD)}
                            id="addButton"
                            variant="text"
                        >
                            {t("parent.list.addButton")}
                        </Button>
                    </>
                }
                isLoading={isLoading}
                data={parents?.data}
                count={parents?.count || 0}
                columns={columns}
                rowSelection={rowSelection}
                handleRowSelection={setRowSelection}
                filters={filters}
                handleFiltersChange={setFilters}
                pagination={pagination}
                handlePaginationChange={setPagination}
            />
            <PromptDeleteConfirm open={removeDialogOpened} onClose={handleDeleteDialogClose}>
                <Typography variant="h3">{t("parent.list.deleteParentPromptHeader")}</Typography>
                <Box pt={3}>{t("parent.list.deleteParentPromptType")}</Box>
            </PromptDeleteConfirm>
            <ParentMassActionsModal
                opened={massActionModalOpened}
                rowSelection={rowSelection}
                parents={parents?.data}
                count={allParentsCount}
                handleClose={() => setMassActionModalOpened(false)}
            />
        </AppContainer>
    );
};

export default ParentUserListContainer;
