import {
    ROUTE_REPORT_DOWNLOAD,
    ROUTE_REPORT_SNAPSHOT_CREATE,
    ROUTE_REPORT_SNAPSHOT_LIST,
    ROUTE_REPORT_STUDENT_REPORTS,
    ROUTE_REPORT_STUDENT_REPORTS_COHORT,
    ROUTE_REPORT_STUDENT_REPORTS_TAB,
    ROUTE_REPORT_TEMPLATE_ADD,
    ROUTE_REPORT_TEMPLATE_EDIT,
    ROUTE_REPORT_TEMPLATE_LIST,
} from "src/routes";
import ReportTemplateAddEditContainer from "../containers/ReportTemplateAddEditContainer";
import ReportTemplateListContainer from "../containers/ReportTemplateListContainer";
import ReportDownloadContainer from "../containers/ReportDownloadContainer";
import StudentReportsContainer from "../containers/StudentReportsContainer";
import SnapshotReportsListContainer from "../containers/SnapshotReportsListContainer";
import SnapshotReportsCreateContainer from "../containers/SnapshotReportsCreateContainer";
import { LinkRoutes } from "src/modules/links/routes/LinkRoutes";

export const ReportRoutes = [
    ...LinkRoutes,
    { path: ROUTE_REPORT_STUDENT_REPORTS_TAB, element: <StudentReportsContainer /> },
    { path: ROUTE_REPORT_STUDENT_REPORTS_COHORT, element: <StudentReportsContainer /> },
    { path: ROUTE_REPORT_STUDENT_REPORTS, element: <StudentReportsContainer /> },
    { path: ROUTE_REPORT_TEMPLATE_ADD, element: <ReportTemplateAddEditContainer /> },
    { path: ROUTE_REPORT_TEMPLATE_EDIT, element: <ReportTemplateAddEditContainer /> },
    { path: ROUTE_REPORT_SNAPSHOT_LIST, element: <SnapshotReportsListContainer /> },
    { path: ROUTE_REPORT_SNAPSHOT_CREATE, element: <SnapshotReportsCreateContainer /> },
    { path: ROUTE_REPORT_TEMPLATE_LIST, element: <ReportTemplateListContainer /> },
    { path: ROUTE_REPORT_DOWNLOAD, element: <ReportDownloadContainer /> },
];
