import FormikRef from "src/forms/FormikRef";
import AppContainer from "src/components/AppContainer";
import PromptDialog from "src/forms/PromptDialog";
import ParentAddEditForm, {
    addEditParentUserSchema,
    emptyParentForm,
    ParentUserAddEditFormFields,
} from "../components/ParentAddEditForm";
import { useState, useRef } from "react";
import { Typography, Paper, Box, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormikProps, Field } from "formik";
import { ROUTE_MY_SCHOOL_PARENTS } from "src/routes";
import { AutocompleteField } from "src/forms/Autocomplete";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useParams } from "react-router";
import { useParentMergeMutation } from "src/modules/parent/hooks/useMergeMutation";
import { useParentDetails } from "../hooks/useParentDetails";
import { useEditParentsStudentsMutation } from "../hooks/useEditParentStudentsMutation";
import { useParentCreateMutation } from "../hooks/useCreateMutation";
import { useParentEditMutation } from "../hooks/useEditMutation";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getSchoolAccountId } from "src/services/url";

const ParentUserUpsertContainer = () => {
    const { navigate } = useSchoolNavigate();
    const form = useRef() as any;
    const { parent } = useParams();
    const parentUserId: number = parent ? parseInt(parent) : 0;
    const { t } = useTranslation();

    const { mutate: mergeParents } = useParentMergeMutation(() =>
        navigate(ROUTE_MY_SCHOOL_PARENTS),
    );
    const { mutate: editParentStudents } = useEditParentsStudentsMutation(
        () => navigate(ROUTE_MY_SCHOOL_PARENTS),
        formErrors => form?.current?.setErrors(formErrors),
    );
    const { data: parentDetails } = useParentDetails(parentUserId);
    const { mutate: createParent } = useParentCreateMutation(
        () => navigate(ROUTE_MY_SCHOOL_PARENTS),
        formErrors => form?.current?.setErrors(formErrors),
    );
    const { mutate: editParent } = useParentEditMutation(
        () => {
            const currentForm = form.current as any;

            editParentStudents({
                parentId: parentUserId,
                students:
                    currentForm.values.students.length > 0
                        ? currentForm.values.students.map(s => ({ id: s.id }))
                        : [],
            });
        },
        formErrors => {
            const currentForm = form.current as any;

            currentForm.setSubmitting(false);
            if (formErrors) form?.current?.setErrors(formErrors);
        },
    );

    const [mergeWarningOpen, setMergeWarningOpen] = useState<boolean>(false);
    const { data: accountInfo } = useAccountInfo();

    const initialValues: ParentUserAddEditFormFields = parentDetails?.id
        ? {
              ...parentDetails,
              students: parentDetails.students || [],
              tmpStudentsAutocomplete: "",
              tmpParentsAutocomplete: "",
          }
        : { ...emptyParentForm, tmpStudentsAutocomplete: "", tmpParentsAutocomplete: "" };

    const handleSubmit = (values: ParentUserAddEditFormFields) => {
        if (parentUserId) {
            editParent({ id: parentUserId, ...values });
        } else {
            createParent({ ...values });
        }
    };

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {parentUserId ? t("parent.addEdit.headerEdit") : t("parent.addEdit.headerAdd")}
            </Typography>
            <Paper>
                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => addEditParentUserSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {({ values }: FormikProps<ParentUserAddEditFormFields>) => (
                        <>
                            {accountInfo?.hasGroupCallSynchronization &&
                                parentDetails?.groupCallStatus === "active" && (
                                    <Box pl={6} pt={4}>
                                        <Grid container spacing={4}>
                                            <Grid item sm={6}>
                                                <Typography variant="h6" component="h6">
                                                    {`${t("parent.addEdit.dataSyncId")}: `}
                                                    <span style={{ color: "#000" }}>
                                                        {parentDetails.groupCallIdaasId}
                                                    </span>
                                                </Typography>
                                                <Field
                                                    apiPathName={`school/${getSchoolAccountId()}/search/parents`}
                                                    otherParams={{
                                                        isLinked: parentDetails.groupCallIdaasId
                                                            ? 1
                                                            : 0,
                                                    }}
                                                    name="tmpParentsAutocomplete"
                                                    placeholder={t(
                                                        "parent.addEdit.searchParentAutoPlaceholder",
                                                    )}
                                                    label={t(
                                                        "parent.addEdit.searchParentAutoPlaceholder",
                                                    )}
                                                    helperText={t(
                                                        "parent.addEdit.searchParentAutoPlaceholderHelperText",
                                                    )}
                                                    component={AutocompleteField}
                                                    multiple={false}
                                                    resultCallback={results => {
                                                        return results.map(val => ({
                                                            ...val,
                                                            name: `${val.firstName} ${val.lastName}`,
                                                            displayName: `${val.firstName} ${val.lastName}`,
                                                        }));
                                                    }}
                                                />
                                                <Button
                                                    disabled={!values.tmpParentsAutocomplete}
                                                    onClick={() => {
                                                        setMergeWarningOpen(true);
                                                    }}
                                                >
                                                    {t("parent.addEdit.merge")}
                                                </Button>
                                            </Grid>
                                            <Grid item sm={6}></Grid>
                                        </Grid>
                                        <PromptDialog
                                            open={mergeWarningOpen}
                                            onClose={confirmed => {
                                                if (confirmed && parentDetails.id) {
                                                    mergeParents({
                                                        linkedParent: {
                                                            id: parentDetails.id,
                                                        },
                                                        unlinkedParent: {
                                                            id: values.tmpParentsAutocomplete.id,
                                                        },
                                                    });
                                                }
                                                setMergeWarningOpen(false);
                                            }}
                                            yesLabel={t("common.continue")}
                                            noLabel={t("common.cancel")}
                                        >
                                            <h1>{t("parent.addEdit.warningHead")}</h1>
                                            <p>
                                                {t("parent.addEdit.warning1")
                                                    .replace(
                                                        ":nonSyncedParent",
                                                        `${
                                                            values?.tmpParentsAutocomplete
                                                                ?.firstName || ""
                                                        } ${
                                                            values?.tmpParentsAutocomplete
                                                                ?.lastName || ""
                                                        }`,
                                                    )
                                                    .replace(
                                                        ":syncedParent",
                                                        `${parentDetails.firstName} ${parentDetails.lastName}`,
                                                    )}
                                            </p>
                                            <p>
                                                {t("parent.addEdit.warning2")
                                                    .replace(
                                                        ":nonSyncedParent",
                                                        `${
                                                            values?.tmpParentsAutocomplete
                                                                ?.firstName || ""
                                                        } ${
                                                            values?.tmpParentsAutocomplete
                                                                ?.lastName || ""
                                                        }`,
                                                    )
                                                    .replace(
                                                        ":syncedParent",
                                                        `${parentDetails.firstName} ${parentDetails.lastName}`,
                                                    )}
                                            </p>
                                            <p>{t("parent.addEdit.warningcontinue")}</p>
                                        </PromptDialog>
                                    </Box>
                                )}
                            <ParentAddEditForm
                                hasGroupCallSync={!!accountInfo?.hasGroupCallSynchronization}
                                isLinked={
                                    !!(
                                        accountInfo?.hasGroupCallSynchronization &&
                                        parentDetails?.groupCallStatus === "active"
                                    )
                                }
                            />
                        </>
                    )}
                </FormikRef>
            </Paper>
        </AppContainer>
    );
};

export default ParentUserUpsertContainer;
