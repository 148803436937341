import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface MigrateAndSyncClassesRequest {
    specifications: {
        specification: {
            id: number;
        };
        groups: {
            id: 0;
        }[];
    }[];
    yearGroup: number;
    targetCohort: {
        id: number;
    };
}

const apiMigrateSyncClasses = async (values: MigrateAndSyncClassesRequest) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/migrate-classes/migrate-and-sync/`,
        values,
    );
};

export const useMigrateClassesAndSyncMutation = onSuccess => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: MigrateAndSyncClassesRequest) => apiMigrateSyncClasses(values),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
