import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

const apiClassTrackerGroupUnsync = async (id: number) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/class-tracker-group/${id}/unsync/`,
    );
};

export const useClassTrackerGroupUnsyncMutation = onSuccess => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: { id: number }) => apiClassTrackerGroupUnsync(values.id),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
