import { darken } from "@mui/material";
import { ColDef } from "ag-grid-community";
import { Component } from "react";

interface OwnProps {
    value: boolean | string;
    data: any;
    context: any;
    colDef: ColDef;
    columnColour?: string;
    headerConfig?: any;
}

export default class AnalysisDummyRenderer extends Component<OwnProps> {
    render() {
        const backgroundColor = this.props.columnColour;
        const isDisplayField = this.props.headerConfig?.isDisplay || false;

        return (
            <div
                style={{
                    backgroundColor:
                        isDisplayField && backgroundColor
                            ? darken(backgroundColor, 0.02)
                            : backgroundColor,
                }}
                className="ag-value-cell-label"
            >
                {this.props.value}
            </div>
        );
    }
}
