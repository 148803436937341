import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";
import { StaffUserModel } from "src/modules/school/models/StaffUserModel";

export type StaffDetailsResponse = StaffUserModel;

export const SCHOOL_STAFF_DETAILS_QUERY = "schoolStaffDetailsQuery";

export const useStaffDetails = (id: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [SCHOOL_STAFF_DETAILS_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<StaffDetailsResponse | null> => {
            if (id) {
                const { data } = await axiosInstance.get(`school/${schoolAccountId}/staff/${id}/`);

                return data || null;
            }
            return null;
        },
    });
};
