import { Box, Button, MenuItem, Select, FormControl, InputLabel, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AttributePair, FiltersShape } from "src/modules/common/components/Grid/GridStudentFilter";
import { ClassTrackerFlatHeader } from "src/modules/tracker/dto/TrackerHeader";
import { ColumnConfigTypes } from "src/modules/tracker/services/headerGenerator";

interface OwnProps {
    handleFiltersChange: (filter: FiltersShape) => void;
    columns: ({
        id: any;
        name: string;
        type: string;
    } | null)[];

    grades: { id: number; name: string }[];
    handleOptionChange: (value: string) => void;
    flatHeader: ClassTrackerFlatHeader[];
    hasFineGradesEnabled?: boolean;
}
const GradeFilter: FC<OwnProps> = ({
    handleFiltersChange,
    handleOptionChange,
    columns,
    grades,
    flatHeader,
    hasFineGradesEnabled,
}) => {
    const { t } = useTranslation();
    const [gradeColumn, setGradeColumn] = useState<string>("");
    const [minimumGrade, setMinimumGrade] = useState<string | number>("");
    const [maximumGrade, setMaximumGrade] = useState<string | number>("");
    const [localGrades, setLocalGrades] = useState<{ id: number; name: string }[] | null>(null);

    const currentType = (columns.find(o => o.id === gradeColumn)?.type ||
        "grade") as unknown as AttributePair;

    const isTPG = !!(
        flatHeader.find(fh => fh.field === gradeColumn)?.columnConfigType ===
        ColumnConfigTypes.TEACHER_JUDGEMENT
    );

    useEffect(() => {
        const newGrades = [];
        grades?.forEach(grade => {
            if (isTPG && hasFineGradesEnabled) {
                newGrades.push({ name: grade.name + "-", id: grade.id + "-" });
                newGrades.push(grade);
                newGrades.push({ name: grade.name + "+", id: grade.id + "+" });
            } else {
                newGrades.push(grade);
            }
        });
        setLocalGrades(newGrades);
    }, [isTPG, hasFineGradesEnabled, grades]);

    return (
        <>
            <FormControl fullWidth margin="normal">
                <InputLabel shrink>{t("common.filterByGradeColumn")}</InputLabel>
                <Select
                    value={gradeColumn}
                    displayEmpty
                    label={t("common.filterByGradeColumn")}
                    onChange={e => {
                        handleOptionChange(e.target.value as string);
                        setGradeColumn(e.target.value as string);
                    }}
                >
                    <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                    {columns.map(option =>
                        option ? (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ) : (
                            <></>
                        ),
                    )}
                </Select>
            </FormControl>

            {currentType === ("distance" as unknown as AttributePair) ? (
                <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="32px">
                    <FormControl fullWidth margin="normal">
                        <TextField
                            type={"number"}
                            label={t("common.filterByDistanceMin")}
                            value={minimumGrade}
                            onChange={e => setMinimumGrade(e.target.value as string)}
                            margin="normal"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            type={"number"}
                            label={t("common.filterByDistanceMax")}
                            value={maximumGrade}
                            onChange={e => setMaximumGrade(e.target.value as string)}
                            margin="normal"
                        />
                    </FormControl>
                </Box>
            ) : (
                <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="32px">
                    <FormControl fullWidth margin="normal">
                        <InputLabel shrink>{t("common.filterByGradeMin")}</InputLabel>
                        <Select
                            value={`${minimumGrade}`}
                            displayEmpty
                            disabled={!gradeColumn}
                            label={t("common.filterByGradeMin")}
                            onChange={e => setMinimumGrade(e.target.value as string)}
                        >
                            <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                            <MenuItem value={-1}>U</MenuItem>
                            {(localGrades || []).map(grade => (
                                <MenuItem key={grade.id} value={grade.id}>
                                    {grade.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel shrink>{t("common.filterByGradeMax")}</InputLabel>
                        <Select
                            value={`${maximumGrade}`}
                            displayEmpty
                            disabled={!gradeColumn}
                            label={t("common.filterByGradeMax")}
                            onChange={e => setMaximumGrade(e.target.value as string)}
                        >
                            <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                            <MenuItem value={-1}>U</MenuItem>
                            {(localGrades || []).map(grade => (
                                <MenuItem key={grade.id} value={grade.id}>
                                    {grade.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            )}

            <Box pt={2}>
                <Button
                    color="primary"
                    disabled={
                        !!(
                            gradeColumn === "" ||
                            (gradeColumn && minimumGrade === "" && maximumGrade === "")
                        )
                    }
                    onClick={() => {
                        let finalMinGrade = parseInt(minimumGrade as string);
                        let finalMaxGrade = parseInt(maximumGrade as string);
                        if (finalMinGrade > finalMaxGrade)
                            [finalMinGrade, finalMaxGrade] = [finalMaxGrade, finalMinGrade];

                        handleFiltersChange({
                            [gradeColumn]: {
                                type: currentType as any,
                                attribute: gradeColumn,
                                attributeDisplay: `${
                                    columns.find(o => o && o.id === gradeColumn)?.name
                                }`,
                                values: [
                                    {
                                        value: finalMinGrade,
                                        valueTo: finalMaxGrade,
                                        valueDisplay: "",
                                    },
                                ],
                            },
                        });
                        setGradeColumn("");
                        setMinimumGrade("");
                        setMaximumGrade("");
                    }}
                >
                    {t("common.filterAdd")}
                </Button>
            </Box>
        </>
    );
};

export default GradeFilter;
