import Icon from "src/components/Icon";
import CheckboxField from "src/forms/CheckboxField";
import PromptDialog from "src/forms/PromptDialog";
import COLORS from "src/styles/colors";
import CustomFieldConfigItem from "./CustomFieldConfigItem";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Theme,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mdiWindowClose } from "@mdi/js";
import { Field } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrmSchoolCustomField, SchoolCustomFieldTypes } from "src/orm/models/SchoolCustomField";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { CustomFieldsDefaultConfigResponse } from "../hooks/query/CustomFields/useCustomFieldsDefaultConfig";
import { CustomFieldByCohortRequest } from "../hooks/query/CustomFields/useCustomFieldsEditMutation";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        position: "relative",
        padding: theme.spacing(4),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(2),
    },
}));

interface OwnProps {
    fieldType: SchoolCustomFieldTypes;
    values: CustomFieldsFormShape;
    setFieldValue: any;
    errors: any;
    canSave: boolean;
    schoolCustomFields: OrmSchoolCustomField[];
    tab: { key: string; label: string };
    customFieldsDefaultConfig: CustomFieldsDefaultConfigResponse;
    otherYearGroups?: number[];
}

export type CustomFieldsFormShape = CustomFieldByCohortRequest;

const CustomFieldForm: FC<OwnProps> = ({
    schoolCustomFields,
    fieldType,
    values,
    setFieldValue,
    errors,
    canSave,
    tab,
    customFieldsDefaultConfig,
    otherYearGroups,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const customFieldIdx = values.fields.findIndex(f => f.fieldType === fieldType);
    const [ygModalOpen, setYgModalOpen] = useState<boolean>(false);
    const [cantEditPromptOpen, setCantEditPromptOpen] = useState<boolean>(false);
    const [selectedYg, setSelectedYg] = useState<number[]>([]);
    const [selectedYgCopy, setSelectedYgCopy] = useState<number[]>([]);
    const [isYGUnselected, setYGUnselected] = useState<boolean>(false);
    const [ygModeData, setYgEditModeData] = useState<{
        customIdx: number;
        configIdx: number;
    } | null>(null);
    const [removeConfigurationIdx, setRemoveConfigurationIdx] = useState<number | null>(null);
    const [enabledValueToChange, setEnabledValueToChange] = useState<string | null>(null);

    const { data } = useCoreValues();
    const yearGroups = data?.yearGroups;

    const addNewConfiguration = () => {
        let newConfigs = [
            ...values.fields[customFieldIdx].configurations,
        ] as OrmSchoolCustomField[];
        newConfigs = newConfigs.concat({
            displayedName: tab.label,
            isEnabled: false,
            useDefaultValues: true,
            fieldValues: [],
            allYearGroups: false,
            yearGroups: selectedYg,
        });

        setFieldValue(`fields[${customFieldIdx}].configurations`, newConfigs);
    };

    const editYgConfiguration = (
        ygModeData: { customIdx: number; configIdx: number },
        selectedYgs,
    ) => {
        setFieldValue(
            `fields[${ygModeData.customIdx}].configurations[${ygModeData.configIdx}].yearGroups`,
            selectedYgs,
        );
    };

    const removeConfiguration = index => {
        const newArray = values.fields[customFieldIdx].configurations.filter((f, i) => i !== index);
        setFieldValue(`fields[${customFieldIdx}].configurations`, newArray);
    };

    const handleElementRemove = (
        customFieldIdx: number,
        configIdx: number,
        fieldConfigIdx: number,
    ) => {
        const newArray = [
            ...values.fields[customFieldIdx].configurations[configIdx].fieldValues,
        ].filter((f, i) => i !== fieldConfigIdx);
        setFieldValue(
            `fields[${customFieldIdx}].configurations[${configIdx}].fieldValues`,
            newArray,
        );
    };

    const moveArray = (arr: any[], fromIndex: number, toIndex: number) => {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    };

    const handleElementMove = (
        customFieldIdx: number,
        configIdx: number,
        fieldConfigIdx: number,
        up: boolean,
    ) => {
        const newArray = [...values.fields[customFieldIdx].configurations[configIdx].fieldValues];
        moveArray(newArray, fieldConfigIdx, up ? fieldConfigIdx - 1 : fieldConfigIdx + 1);
        setFieldValue(
            `fields[${customFieldIdx}].configurations[${configIdx}].fieldValues`,
            newArray,
        );
    };

    const handleAddValue = (customFieldIdx: number, configIdx: number, value: string) => {
        const newArray = [...values.fields[customFieldIdx].configurations[configIdx].fieldValues];
        newArray.push(value);
        setFieldValue(
            `fields[${customFieldIdx}].configurations[${configIdx}].fieldValues`,
            newArray,
        );
    };

    const handleYearGroupsEdit = (
        customFieldIdx,
        configIdx,
        currentYearGroups,
        configId?: number,
    ) => {
        setYgEditModeData({ customIdx: customFieldIdx, configIdx });
        setSelectedYg(currentYearGroups);
        setSelectedYgCopy(schoolCustomFields.find(({ id }) => id === configId)?.yearGroups || []);
        setYgModalOpen(true);
    };

    const handleDisableEditPopupShow = (canSave: boolean) => {
        if (!canSave) {
            setCantEditPromptOpen(true);
        }
    };

    return (
        <Box>
            {!canSave && schoolCustomFields.length === 0 ? (
                <>{t("school.customFields.noPreviousData")}</>
            ) : (
                <></>
            )}
            {customFieldIdx > -1 && values.fields && values.fields[customFieldIdx] ? (
                <Grid spacing={4} container>
                    {values.fields[customFieldIdx].configurations.map((config, configIdx) => (
                        <Grid item sm={6} key={`${customFieldIdx}-${configIdx}`}>
                            <Paper
                                className={classes.paper}
                                onClick={() => handleDisableEditPopupShow(canSave)}
                            >
                                {canSave && configIdx > 0 ? (
                                    <IconButton
                                        aria-label="close"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            if (config.id) {
                                                setRemoveConfigurationIdx(configIdx);
                                            } else {
                                                removeConfiguration(configIdx);
                                            }
                                        }}
                                        size="small"
                                    >
                                        <Icon
                                            size="32px"
                                            color={COLORS.LIGHT_GREY_1}
                                            path={mdiWindowClose}
                                        />
                                    </IconButton>
                                ) : (
                                    <></>
                                )}
                                <Box>
                                    {config.yearGroups && config.yearGroups.length > 0 ? (
                                        <Box>
                                            {t("school.customFields.yearGroups")}
                                            {config.yearGroups
                                                .map(yg => (yg === 0 ? t("common.yg0") : yg))
                                                .join(",")}
                                            {canSave && (
                                                <Button
                                                    style={{ marginLeft: "10px" }}
                                                    size="small"
                                                    onClick={() =>
                                                        handleYearGroupsEdit(
                                                            customFieldIdx,
                                                            configIdx,
                                                            config.yearGroups,
                                                            config.id,
                                                        )
                                                    }
                                                >
                                                    {t("common.edit")}
                                                </Button>
                                            )}
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                                <Box>
                                    <Field
                                        disabled={!canSave}
                                        name={`fields[${customFieldIdx}].configurations[${configIdx}].isEnabled`}
                                        label={
                                            config.allYearGroups &&
                                            otherYearGroups &&
                                            otherYearGroups.length > 0
                                                ? t(
                                                      "school.customFields.enableFieldExcept",
                                                  ).replace(
                                                      ":yearGroups",
                                                      otherYearGroups
                                                          .map(oyg =>
                                                              oyg === 0 ? t("common.yg0") : oyg,
                                                          )
                                                          .join(","),
                                                  )
                                                : config.allYearGroups
                                                  ? t("school.customFields.enableFieldAll")
                                                  : t("school.customFields.enableField")
                                        }
                                        component={CheckboxField}
                                        onChange={event => {
                                            const valueKey = `fields[${customFieldIdx}]configurations[${configIdx}].isEnabled`;
                                            if (
                                                schoolCustomFields.find(
                                                    ({ id }) => id === config.id,
                                                )?.isEnabled
                                            ) {
                                                setEnabledValueToChange(valueKey);
                                            } else {
                                                setFieldValue(valueKey, event.target.checked);
                                            }
                                        }}
                                    />
                                </Box>
                                {config.isEnabled && (
                                    <Box>
                                        <Field
                                            disabled={!canSave}
                                            name={`fields[${customFieldIdx}].configurations[${configIdx}].useDefaultValues`}
                                            label={t("school.customFields.useDefault")}
                                            component={CheckboxField}
                                        />
                                        <CustomFieldConfigItem
                                            config={config}
                                            customFieldIdx={customFieldIdx}
                                            canSaveConfigs={canSave}
                                            configIdx={configIdx}
                                            handleElementRemove={handleElementRemove}
                                            handleElementMove={handleElementMove}
                                            handleAddValue={handleAddValue}
                                            customFieldsDefaultConfig={customFieldsDefaultConfig}
                                        />
                                    </Box>
                                )}

                                <Box>
                                    {errors?.fields &&
                                        errors.fields[customFieldIdx] &&
                                        errors.fields[customFieldIdx].configurations[configIdx] &&
                                        Object.keys(
                                            errors.fields[customFieldIdx].configurations[configIdx],
                                        ).map(key => (
                                            <Box key={key} style={{ color: "red" }}>
                                                {
                                                    errors.fields[customFieldIdx].configurations[
                                                        configIdx
                                                    ][key]
                                                }
                                            </Box>
                                        ))}
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <></>
            )}
            {canSave && (
                <Box mt={2} mb={4}>
                    <Button disabled={!canSave} onClick={() => setYgModalOpen(true)}>
                        {t("school.customFields.addNewSetting")}
                    </Button>
                </Box>
            )}
            <PromptDialog
                open={removeConfigurationIdx !== null}
                onClose={confirmed => {
                    if (confirmed) {
                        removeConfiguration(removeConfigurationIdx);
                    }
                    setRemoveConfigurationIdx(null);
                }}
            >
                {t("school.customFields.dataWillBeLost")}
            </PromptDialog>
            <PromptDialog
                open={enabledValueToChange !== null}
                onClose={confirmed => {
                    if (confirmed) {
                        setFieldValue(enabledValueToChange, false);
                    }
                    setEnabledValueToChange(null);
                }}
            >
                {t("school.customFields.dataWillBeLost")}
            </PromptDialog>
            <PromptDialog
                open={cantEditPromptOpen}
                yesLabel={t("common.ok")}
                noLabel={""}
                onClose={() => {
                    setCantEditPromptOpen(false);
                }}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: t("school.customFields.valuesLocked"),
                    }}
                ></div>
            </PromptDialog>
            <PromptDialog
                open={ygModalOpen}
                noLabel="Cancel"
                yesLabel={selectedYg.length > 0 ? t("common.add") : ""}
                onClose={confirm => {
                    if (confirm && !selectedYgCopy.every(id => selectedYg.includes(id))) {
                        setYGUnselected(true);
                    } else {
                        if (confirm) {
                            if (ygModeData) {
                                editYgConfiguration(ygModeData, selectedYg);
                            } else {
                                addNewConfiguration();
                            }
                        }
                        setYgEditModeData(null);
                        setSelectedYg([]);
                        setSelectedYgCopy([]);
                        setYgModalOpen(false);
                    }
                }}
            >
                <Typography>{t("school.customFields.addNewSet")}</Typography>
                {yearGroups.map(yg => (
                    <>
                        <FormControlLabel
                            key={yg.id}
                            control={
                                <Checkbox
                                    name="yg"
                                    value={yg.id}
                                    checked={!!(selectedYg.find(syg => syg === yg.id) > -1)}
                                    onChange={(e, checked) => {
                                        let newSelectedYg = [...selectedYg];

                                        if (checked) {
                                            newSelectedYg.push(yg.id);
                                        } else {
                                            newSelectedYg = newSelectedYg.filter(
                                                nsy => nsy !== yg.id,
                                            );
                                        }

                                        setSelectedYg(newSelectedYg);
                                    }}
                                />
                            }
                            label={yg.name}
                        />
                    </>
                ))}
            </PromptDialog>
            <PromptDialog
                open={isYGUnselected}
                onClose={confirmed => {
                    if (confirmed) {
                        if (ygModeData) {
                            editYgConfiguration(ygModeData, selectedYg);
                        }
                        setYgModalOpen(false);
                        setYgEditModeData(null);
                        setSelectedYg([]);
                        setSelectedYgCopy([]);
                    }

                    setYGUnselected(false);
                }}
            >
                {t("school.customFields.dataWillBeLost")}
            </PromptDialog>
        </Box>
    );
};

export default CustomFieldForm;
