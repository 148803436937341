import PaperInner from "src/components/PaperInner";
import AttributesConfigList from "../../Common/AttributesConfigList";
import ErrorMessage from "src/forms/ErrorMessage";
import CheckboxField from "src/forms/CheckboxField";
import HeadingCounter from "src/components/HeadingCounter";
import SpecificationSelectField from "src/forms/SpecificationSelectField";
import { Field, Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, FormGroup, Box, FormControlLabel, Checkbox, Typography } from "@mui/material";
import {
    AttributeValueExtended,
    AttributeItem,
    AttributeGroup,
    InitialAttribute,
} from "src/orm/models/SpecificationAttribute";
import { GroupCallYearGroups } from "src/modules/class/hooks/MIS/useGroupCallClassesCreateMutation";
import { GroupCallClassListItem } from "src/modules/class/hooks/MIS/useGroupCallClassList";
import { FC, useEffect } from "react";
import { TrackerPathItem } from "src/modules/class/hooks/useTrackerPath";
import { SpecificationModel } from "src/modules/tracker/models/SpecificationModel";

export interface GroupCallClassFormFields {
    attributes: AttributeValueExtended[];
    items: AttributeItem[];
    groups: AttributeGroup[];
    disabledItems: number[];
    initialEditAttributes?: InitialAttribute[];
    yearGroups: GroupCallYearGroups[];
    specification?: number | null;
}

interface OwnProps {
    convert: boolean;
    trackerPath: TrackerPathItem[] | null;
    selectedSpecification: number | null;
    groupCallClasses: GroupCallClassListItem[] | null;
    handleSpecificationChange: (specificationId: number | null) => void;
    handleNextSpecificationChange: (specification: SpecificationModel | null) => void;
}

const ConvertGroupCallClassForm: FC<OwnProps> = ({
    convert,
    trackerPath,
    selectedSpecification,
    groupCallClasses,
    handleSpecificationChange,
    handleNextSpecificationChange,
}) => {
    const { t } = useTranslation();
    const { values, errors, setFieldValue, setFieldTouched } =
        useFormikContext<GroupCallClassFormFields>();

    useEffect(() => {
        if (groupCallClasses && groupCallClasses.length > 0) {
            setFieldValue(
                "yearGroups",
                groupCallClasses
                    .filter(gcc => convert && gcc.hasManualClasses)
                    .map(gcc => ({
                        ...gcc,
                        classes: gcc.classes.map(c => ({ ...c, selected: c.synchronized })),
                    })),
            );
        }
    }, [groupCallClasses, convert, selectedSpecification]);

    const isCheckboxChecked = (yearGroup: number) => {
        const yearGroupClasses = values.yearGroups?.find(gcc => gcc.yearGroup === yearGroup);

        return !!yearGroupClasses?.classes.some(c => c.selected === true);
    };

    const handleSelectDeselectAll = (yearGroup: number) => {
        const newYearGroups = [...values.yearGroups];
        const index = newYearGroups.findIndex(nyg => nyg.yearGroup === yearGroup);
        const classes = newYearGroups[index].classes.map(c => ({
            ...c,
            selected: !isCheckboxChecked(yearGroup),
        }));
        newYearGroups[index].classes = classes;
        setFieldValue("yearGroups", newYearGroups);
    };

    useEffect(() => {
        if (!values.specification) {
            handleSpecificationChange(null);
            setFieldValue("yearGroups", []);
        }
    }, [values.specification]);

    return (
        <Form>
            <PaperInner variant="paddingTopNone">
                <HeadingCounter number="1">
                    {t("class.editClass.chooseTrackerHeader")}
                </HeadingCounter>

                <Field
                    name={`specification`}
                    component={SpecificationSelectField}
                    allowedOnly={true}
                    showWithClassesOnly={true}
                    allowedQualificationsOnly={true}
                    handleSpecificationChange={specification => {
                        if (selectedSpecification !== specification.id) {
                            handleSpecificationChange(specification?.id);
                        }
                    }}
                    handleNextSpecification={handleNextSpecificationChange}
                    specificationParam={selectedSpecification}
                    trackerPath={trackerPath}
                />

                {selectedSpecification && (
                    <AttributesConfigList
                        attributes={values.attributes}
                        specificationId={selectedSpecification}
                        items={values.items}
                        groups={values.groups}
                        disabledItems={values.disabledItems}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialAttributes={values.initialEditAttributes}
                    />
                )}
                {errors?.attributes && <ErrorMessage name="attributes" />}
            </PaperInner>
            <PaperInner border="top">
                <HeadingCounter number="2">{t("class.editClass.classList")}</HeadingCounter>
                <FormGroup>
                    <Grid container>
                        {values.yearGroups && values.yearGroups.length > 0 ? (
                            values.yearGroups
                                .filter(yg => yg.classes.length > 0)
                                .map(gcc => {
                                    const index = values.yearGroups.findIndex(
                                        yg => yg.yearGroup === gcc.yearGroup,
                                    );

                                    return (
                                        <Grid key={gcc.yearGroup} item sm={4}>
                                            <Box p={4} height="100%" bgcolor="#f9f9f9">
                                                <Box mb={2}>
                                                    <strong>
                                                        {t("common.yearGroup") +
                                                            " " +
                                                            gcc.yearGroup}
                                                    </strong>
                                                </Box>
                                                <Box>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="selectAll"
                                                                value={true}
                                                                checked={isCheckboxChecked(
                                                                    gcc.yearGroup,
                                                                )}
                                                                onChange={() =>
                                                                    handleSelectDeselectAll(
                                                                        gcc.yearGroup,
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            isCheckboxChecked(gcc.yearGroup) ? (
                                                                <i>
                                                                    {t("common.deselectAllClasses")}
                                                                </i>
                                                            ) : (
                                                                <i>
                                                                    {t("common.selectAllClasses")}
                                                                </i>
                                                            )
                                                        }
                                                    />
                                                </Box>
                                                {gcc.classes.map((c, j) => (
                                                    <Box key={c.id}>
                                                        <Field
                                                            name={`yearGroups.${index}.classes.${j}.selected`}
                                                            component={CheckboxField}
                                                            disabled={
                                                                values.yearGroups[index].classes[j]
                                                                    ? values.yearGroups[index]
                                                                          .classes[j].synchronized
                                                                    : false
                                                            }
                                                            label={c.name}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                            {errors.yearGroups?.[index] &&
                                            typeof errors.yearGroups[index] === "string" ? (
                                                <ErrorMessage name={`yearGroups.${index}`} />
                                            ) : (
                                                <></>
                                            )}
                                            {errors.yearGroups?.[index] &&
                                            typeof errors.yearGroups[index] !== "string" &&
                                            errors.yearGroups[index]["classes"] &&
                                            typeof errors.yearGroups[index]["classes"] ===
                                                "string" ? (
                                                <ErrorMessage
                                                    name={`yearGroups.${index}.classes`}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    );
                                })
                        ) : (
                            <Box>
                                <Typography>
                                    {t("class.groupCallClass.thereAreNoClasses1")}
                                </Typography>
                                <Typography>
                                    {t("class.groupCallClass.thereAreNoClasses2")}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </FormGroup>
                {errors.yearGroups && typeof errors.yearGroups === "string" ? (
                    <ErrorMessage name="yearGroups" />
                ) : (
                    <></>
                )}
            </PaperInner>
        </Form>
    );
};

export default ConvertGroupCallClassForm;
