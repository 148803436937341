import { attr, Model } from "redux-orm";
import { ListObject } from "src/forms/types";
import { ParentModel } from "src/modules/parent/model/ParentModel";

const fields = {
    id: attr(),
    firstName: attr(),
    lastName: attr(),
    uniquePupilNumber: attr(),
    candidateNumber: attr(),
    gender: attr(),
    form: attr(),
    pupilPremium: attr(),
    fsm: attr(),
    lookedAfter: attr(),
    eal: attr(),
    priorAbility: attr(),
    senStatus: attr(),
    ks2English: attr(),
    ks2Math: attr(),
    ks2FineLevel: attr(),
    ks2EnglishScaled: attr(),
    ks2MathScaled: attr(),
    ks2FineLevelScaled: attr(),
    ethnicity: attr(),
    senNeed: attr(),
    gradeId: attr(),
    ks4Aps: attr(),
    ks4Agaps: attr(),
    catMean: attr(),
    catVerbal: attr(),
    catNonVerbal: attr(),
    catQuantitative: attr(),
    catSpatial: attr(),
    readingAge: attr(),
    dateOfBirth: attr(),
    nativeSpeaker: attr(),
    dateJoined: attr(),
    admissionNumber: attr(),
    parents: attr(),
};

export interface StudentShortForParent {
    id: number;
    uniquePupilNumber: string;
    fullName: string;
    status: string;
}

export interface StudentModel {
    id?: number;
    firstName: string;
    lastName: string;
    uniquePupilNumber: string;
    candidateNumber: string;
    gender?: string;
    form?: string;
    pupilPremium?: boolean;
    fsm?: boolean;
    lookedAfter?: boolean;
    eal?: boolean;
    priorAbility?: string;
    senStatus?: string;
    dateOfBirth?: string;
    ks2English?: string;
    ks2Math?: string;
    ks2FineLevel?: string;
    ks2EnglishScaled?: string;
    ks2MathScaled?: string;
    ks2FineLevelScaled?: string;
    ethnicity?: ListObject<string>;
    senNeed?: ListObject<string>;
    gradeId?: number | string | null;
    ks4Aps?: string | null;
    ks4Agaps?: string | null;
    catMean?: number | null;
    catVerbal?: number | null;
    catNonVerbal?: number | null;
    catQuantitative?: number | null;
    catSpatial?: number | null;
    readingAge?: string | null;
    nativeSpeaker?: boolean;
    dateJoined?: string | null;
    admissionNumber?: string;
    groupCallStatus?: "active" | "manual" | "deleted";
    groupCallId?: string | null;
    parents?: ParentModel[] | null;
}

class Student extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "Student";
    static fields = fields;
    static emptyRef: StudentModel = {
        id: 0,
        firstName: "",
        lastName: "",
        uniquePupilNumber: "",
        candidateNumber: "",
        gradeId: null,
    };

    get ormRef(): StudentModel {
        return this.ref as any;
    }

    get ormRefDetails(): StudentModel {
        return this.ormRef as any;
    }
}

export default Student;
