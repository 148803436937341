import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface StudentReportByTagRequest {
    cohort: number;
    yearGroup: number;
    tag: { id: number };
    reportTemplate: { id: number };
    schoolSnapshot?: { id: number };
    onlySummary: boolean;
}

const apiCreateReportByTag = async ({
    cohort,
    yearGroup,
    ...values
}: StudentReportByTagRequest) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/analysis/student-report/${cohort}/${yearGroup}/by-tag/`,
        values,
    );
};
export const useCreateReportByTagMutation = (onSuccess: () => void, onError?) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: StudentReportByTagRequest) => apiCreateReportByTag(values),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error && typeof err?.response?.data?.error === "string") {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
