import RedirecterContainer from "src/modules/common/containers/RedirecterContainer";
import NotFoundContainer from "src/modules/common/containers/NotFoundContainer";
import TrustLayout from "src/layouts/TrustLayout";

// containers
import MaintenanceContainer from "src/modules/maintenance/containers/Maintenance";

// layouts
import AuthLayout from "src/layouts/AuthLayout";
import CommonLayout from "src/layouts/CommonLayout";
import TrackerLayout from "src/layouts/TrackerLayout";

// guards
import AuthGuard from "src/guards/AuthGuard";
import TeacherGuard from "src/guards/TeacherGuard";

// routes
import {
    ROUTE_SUBSCRIPTION_LIST,
    ROUTE_AUTH_LOGIN,
    ROUTE_CLASS_CLASS_LIST,
    SCHOOL_PREFIX,
    // ROUTE_NOT_FOUND,
    ROUTE_TRUST_DASHBOARD,
    ROUTE_AUTH_POST_LOGIN,
} from ".";
import { AuthRoutes } from "src/modules/auth/routes/AuthRoutes";
import { ClassRoutes } from "src/modules/class/routes/ClassRoutes";
import { SchoolRoutes } from "src/modules/school/routes/SchoolRoutes";
import { TrackerRoutes } from "src/modules/tracker/routes/TrackerRoutes";
import { UserRoutes } from "src/modules/user/routes/UserRoutes";
import { AnalysisRoutes } from "src/modules/analysis/routes/AnalysisRoutes";
import { ReportRoutes } from "src/modules/report/routes/ReportRoutes";

// rest
import { SubscriptionRoutes } from "src/modules/subscription/routes/SubscriptionRoutes";
import { Storage } from "src/services/storage";
import { Modules } from "src/types";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { useMount } from "src/hooks/useMount";
import { reloadHubspotChat } from "src/services/hubspot";
import { useIntercom } from "react-use-intercom";
import { AssessmentRoutes } from "src/modules/assessment/routes/AssessmentRoutes";
import { productFruits } from "product-fruits";
import { useAuthContext } from "src/context/AuthContext";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { TrustRoutes } from "src/modules/trust/routes/TrustRoutes";
import { SchoolUserRole } from "src/orm/models/User";
import { getSchoolAccountId, getTrustAccountId } from "src/services/url";

export default function AppRoutes() {
    const location = useLocation();
    // const routerNavigate = useNavigate();
    const { setToken, authState } = useAuthContext();
    // const { navigate } = useSchoolNavigate();
    const { boot } = useIntercom();
    const { data: userProfile } = useProfile();

    const [initial, setInitial] = useState<boolean>(true);

    const schoolAccountId = getSchoolAccountId();
    const trustId = getTrustAccountId();

    useEffect(() => {
        if (userProfile?.userHash) {
            boot({
                userHash: userProfile?.userHash,
                userId: `${userProfile.id}`,
                name: userProfile.firstName + " " + userProfile.lastName,
                email: userProfile.email,
                createdAt: Date.now(),
            });
        }
    }, [userProfile?.userHash]);

    useMount(() => {
        const token: string = Storage.getItem("token");

        if (token) setToken(token);

        if (initial === true) setInitial(false);
    });

    useEffect(() => {
        // if (
        //     schoolAccountId &&
        //     schoolAccountIdFromUrl &&
        //     schoolAccountIdFromUrl !== schoolAccountId
        // ) {
        //     routerNavigate(
        //         ROUTE_SWITCH_ACCOUNT.replace(":type", "school").replace(
        //             ":id",
        //             `${schoolAccountIdFromUrl}`,
        //         ) +
        //             "?returnUrl=" +
        //             location.pathname,
        //         { replace: true },
        //     );
        // } else {
        // if (window) {
        // const prefixWithSchoolId = SCHOOL_PREFIX.replace(":schoolAccountId", schoolAccountId);

        // const hasSchoolPrefix = location?.pathname?.indexOf("/school/") === 0;
        // if (hasSchoolPrefix) {
        //     // has school prefix but it doesnt match with current school
        //     if (location?.pathname.indexOf(prefixWithSchoolId) !== 0) {
        //         // routerNavigate(ROUTE_NOT_FOUND);
        //     }
        // }

        if (window) {
            productFruits.safeExec($productFruits => {
                $productFruits.push(["pageChanged"]);
            });
            window.scrollTo(0, 0);
            reloadHubspotChat();
        }
        // }

        // }
    }, [window, location]);

    if (!window.location) {
        return <></>;
    }

    return useRoutes([
        {
            path: "/",
            element: (
                <Navigate
                    to={
                        authState
                            ? trustId
                                ? ROUTE_TRUST_DASHBOARD.replace(":trust", `${trustId}`)
                                : schoolAccountId
                                  ? userProfile?.userRole === SchoolUserRole.FINANCE
                                      ? ROUTE_SUBSCRIPTION_LIST.replace(
                                            ":schoolAccountId",
                                            `${schoolAccountId}`,
                                        )
                                      : ROUTE_CLASS_CLASS_LIST.replace(
                                            ":schoolAccountId",
                                            `${schoolAccountId}`,
                                        )
                                  : authState?.token
                                    ? ROUTE_AUTH_POST_LOGIN
                                    : ROUTE_AUTH_LOGIN
                            : ROUTE_AUTH_LOGIN
                    }
                    replace
                />
            ),
        },
        {
            path: Modules.MAINTENANCE,
            element: <MaintenanceContainer />,
        },
        {
            path: Modules.AUTH,
            element: <AuthLayout />,
            children: [...AuthRoutes],
        },
        {
            path: "mat",
            element: <TrustLayout />,
            children: [...TrustRoutes],
        },
        {
            path: `${SCHOOL_PREFIX}/${Modules.ANALYSIS}`,
            element: (
                <TeacherGuard>
                    <TrackerLayout />
                </TeacherGuard>
            ),
            children: [...AnalysisRoutes],
        },
        {
            path: `${SCHOOL_PREFIX}/${Modules.USER}`,
            element: (
                <AuthGuard>
                    <>{getSchoolAccountId() ? <CommonLayout /> : <TrustLayout />}</>
                </AuthGuard>
            ),
            children: [...UserRoutes],
        },
        {
            path: `${SCHOOL_PREFIX}/${Modules.CLASS}`,
            element: (
                <TeacherGuard>
                    <CommonLayout />
                </TeacherGuard>
            ),
            children: [...ClassRoutes, ...AssessmentRoutes],
        },
        {
            path: `${SCHOOL_PREFIX}/${Modules.TRACKER}`,
            element: (
                <TeacherGuard>
                    <TrackerLayout />
                </TeacherGuard>
            ),
            children: [...TrackerRoutes],
        },
        {
            path: `${SCHOOL_PREFIX}/${Modules.SCHOOL}`,
            element: (
                <TeacherGuard>
                    <CommonLayout />
                </TeacherGuard>
            ),
            children: [...SchoolRoutes],
        },
        {
            path: `${SCHOOL_PREFIX}/${Modules.REPORT}`,
            element: (
                <TeacherGuard>
                    <CommonLayout />
                </TeacherGuard>
            ),
            children: [...ReportRoutes],
        },
        {
            path: `${SCHOOL_PREFIX}/${Modules.SUBSCRIPTION}`,
            element: (
                <AuthGuard>
                    <CommonLayout />
                </AuthGuard>
            ),
            children: [...SubscriptionRoutes],
        },
        {
            path: `404`,
            element: <NotFoundContainer />,
        },
        {
            path: `*`,
            element: <RedirecterContainer />,
        },
    ]);
}
