import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import clsx from "clsx";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mdiDownload } from "@mdi/js";
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useRef } from "react";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";
import { ViewGrades } from "../../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import {
    CustomTableCell,
    VerticalCustomTableCell,
} from "../../containers/SubjectAnalysisContainer";
import { exportSubjectAnalysisGradeBreakdownToExcel } from "../../services/analysisDataExport";
import { useSubjectAnalysisGradeBreakdownData } from "../../hooks/SubjectAnalysis/useSubjectAnalysisGradeBreakdownData";
import { useSubjectAnalysisGradeBreakdownSnapshotData } from "../../hooks/SubjectAnalysis/useSubjectAnalysisGradeBreakdownSnapshotData";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(0, 0, 3),
    },
    container: {
        display: "flex",
        flex: 1,
        width: "100%",
        borderTop: `1px solid ${COLORS.LIGHT_GREY_2}`,
        overflow: "hidden",
        backgroundColor: COLORS.WHITE,
    },
    content: {
        flex: 1,
        overflowY: "auto",
        position: "relative",
    },
    inner: {
        padding: theme.spacing(6, 10, 6, 4),
        [theme.breakpoints.up(1400)]: {
            padding: theme.spacing(8, 100, 8, 12.5),
        },
    },
    innerScroll: {
        overflowX: "auto",
    },
    innerBigRight: {
        padding: theme.spacing(8, 27.5, 8, 12.5),
    },
    innerBorderBottom: {
        borderBottom: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
    },
    innerBorderLeft: {
        borderLeft: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
        height: "100%",
    },
    header: {
        display: "flex",
        alignItems: "flex-start",
    },
    subjectMeta: {
        display: "flex",
        padding: theme.spacing(3, 4),
        borderRadius: 2,
        backgroundColor: COLORS.VERY_LIGHT_GREY_1,
        margin: theme.spacing(0, 7, 0, 0),
    },
    subjectMetaItem: {
        display: "flex",
        flexDirection: "column",

        "& + &": {
            borderLeft: `1px solid ${COLORS.LIGHT_GREY_2}`,
            padding: theme.spacing(0, 0, 0, 5),
            margin: theme.spacing(0, 0, 0, 5),
        },
    },
    subjectMetaBadge: {
        margin: theme.spacing(0.75, 0, 0),
    },
    subjectMetaLabel: {
        color: COLORS.GREY_3,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: 400,
        fontFamily: PRIMARY_FONT_FAMILY,
    },
    subjectMetaText: {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        margin: theme.spacing(0.25, 0, 0),
    },
    tableVerticalBottom: {
        lineHeight: 1,
        verticalAlign: "bottom",
    },
    tableBorderLeft: {
        borderLeft: `1px solid ${COLORS.LIGHT_GREY_2}`,
    },
    tableBackground: {
        backgroundColor: "rgba(232, 233, 235, 0.2)",
    },
    tableColorOpacity: {
        color: "#a5acb1 !important",
    },
}));

const SubjectAnalysisGradeBreakdown = ({
    cohort,
    yearGroup,
    specification,
    viewGrade,
    dataType,
    snapshot,
    classTrackers,
}: {
    cohort: number;
    yearGroup: number;
    specification: number | null;
    viewGrade: ViewGrades;
    dataType: DataTypeTypes;
    snapshot?: number;
    classTrackers?: number[];
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        data: subjectAnalysisGradeBreakdownBasicData,
        refetch: getSubjectAnalysisGradeBreakdownData,
    } = useSubjectAnalysisGradeBreakdownData(cohort, yearGroup, specification, classTrackers);
    const {
        data: subjectAnalysisGradeBreakdownsSnapshotData,
        refetch: getSubjectAnalysisGradeBreakdownSnapshotData,
    } = useSubjectAnalysisGradeBreakdownSnapshotData(
        cohort,
        yearGroup,
        specification,
        snapshot,
        classTrackers,
    );
    const subjectAnalysisGradeBreakdownData =
        dataType === DataTypeTypes.SNAPSHOT
            ? subjectAnalysisGradeBreakdownsSnapshotData
            : subjectAnalysisGradeBreakdownBasicData;

    const gridApi = useRef() as unknown as { current: GridApi };

    const onGridReady = params => {
        gridApi.current = params.api;
    };

    useEffect(() => {
        if (dataType === DataTypeTypes.LIVE && specification) {
            getSubjectAnalysisGradeBreakdownData();
        }
        if (dataType === DataTypeTypes.SNAPSHOT && snapshot && specification) {
            getSubjectAnalysisGradeBreakdownSnapshotData();
        }
    }, [cohort, yearGroup, specification, viewGrade, dataType, snapshot, classTrackers]);

    const columnDefs = [
        {
            field: "name",
            headerName: t("analysis.subjectAnalysis.grade"),
        },
        {
            field:
                viewGrade === ViewGrades.CURRENT
                    ? "numberCurrentWorkingThisGrade"
                    : viewGrade === ViewGrades.PROJECTED
                      ? "numberProjectedWorkingThisGrade"
                      : "numberTeacherWorkingThisGrade",
            headerName: t("analysis.subjectAnalysis.numberOfStudentWorkingAtThisGrade"),
        },
        {
            field: "cumulative",
            headerName: t("analysis.subjectAnalysis.cumulative"),
            children: [
                {
                    field:
                        viewGrade === ViewGrades.CURRENT
                            ? "numberCurrentWorkingThisGradeOrAbove"
                            : viewGrade === ViewGrades.PROJECTED
                              ? "numberProjectedWorkingThisGradeOrAbove"
                              : "numberTeacherWorkingThisGradeOrAbove",
                    headerName: t("analysis.subjectAnalysis.numberOfStudentsAtThisGradeOrAbove"),
                },
                {
                    field:
                        viewGrade === ViewGrades.CURRENT
                            ? "numberCurrentWorkingThisGradeOrAboveP"
                            : viewGrade === ViewGrades.PROJECTED
                              ? "numberProjectedWorkingThisGradeOrAboveP"
                              : "numberTeacherWorkingThisGradeOrAboveP",
                    headerName: "% " + t("analysis.subjectAnalysis.ofStudents"),
                },
                {
                    field: "numberTargetWorkingThisGradeOrAbove",
                    headerName: t("analysis.subjectAnalysis.aimAccordingToMinimumTargetGrade"),
                },
            ],
        },
    ];

    return (
        <Box className={clsx(classes.inner, classes.innerBorderBottom)}>
            <Typography variant="h6">{t("analysis.subjectAnalysis.gradeBreakdown")}</Typography>
            <AgGridReact
                onGridReady={onGridReady}
                columnDefs={columnDefs as any[]}
                rowData={subjectAnalysisGradeBreakdownData?.grades || []}
                gridOptions={{ domLayout: "print" }}
                containerStyle={{ display: "none" }}
            />
            <Box className={classes.innerScroll}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell size="small" colSpan={2} />
                            <CustomTableCell size="small" colSpan={3}>
                                {t("analysis.subjectAnalysis.cumulative")}
                            </CustomTableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tableVerticalBottom}>
                                {t("analysis.subjectAnalysis.grade")}
                            </TableCell>
                            <VerticalCustomTableCell className={classes.tableBorderLeft}>
                                <span>
                                    <Trans i18nKey="analysis.subjectAnalysis.numberOfStudentsWorkingAtThisGrade">
                                        Number of students <br />
                                        working at this grade
                                    </Trans>
                                    &nbsp;/&nbsp;
                                    {subjectAnalysisGradeBreakdownData?.numberOfStudents}
                                </span>
                            </VerticalCustomTableCell>
                            <VerticalCustomTableCell
                                className={clsx(classes.tableBackground, classes.tableBorderLeft)}
                            >
                                <span>
                                    <Trans i18nKey="analysis.subjectAnalysis.numberOfStudentsAtThisGradeOrAbove">
                                        Number of students at <br />
                                        this grade or above
                                    </Trans>
                                    &nbsp;/&nbsp;
                                    {subjectAnalysisGradeBreakdownData?.numberOfStudents}
                                </span>
                            </VerticalCustomTableCell>
                            <VerticalCustomTableCell className={classes.tableBackground}>
                                <span>
                                    %{" "}
                                    <Trans i18nKey="analysis.subjectAnalysis.ofStudents">
                                        of students at this <br />
                                        grade or above
                                    </Trans>
                                </span>
                            </VerticalCustomTableCell>
                            <VerticalCustomTableCell className={classes.tableBackground}>
                                <span>
                                    %{" "}
                                    <Trans i18nKey="analysis.subjectAnalysis.aimAccordingToMinimumTargetGrade">
                                        if all students achieve <br />
                                        their Minimum Target Grade
                                    </Trans>
                                </span>
                            </VerticalCustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subjectAnalysisGradeBreakdownData?.grades &&
                            subjectAnalysisGradeBreakdownData.grades.map(g => {
                                const numberOfStudentsWorkingAtThisGrade =
                                    viewGrade === ViewGrades.CURRENT
                                        ? g.numberCurrentWorkingThisGrade
                                        : viewGrade === ViewGrades.PROJECTED
                                          ? g.numberProjectedWorkingThisGrade
                                          : g.numberTeacherWorkingThisGrade;
                                const numberOfStudentsAtThisGradeOrAbove =
                                    viewGrade === ViewGrades.CURRENT
                                        ? g.numberCurrentWorkingThisGradeOrAbove
                                        : viewGrade === ViewGrades.PROJECTED
                                          ? g.numberProjectedWorkingThisGradeOrAbove
                                          : g.numberTeacherWorkingThisGradeOrAbove;
                                const percentOfStudentsAtThisGradeOrAbove = Math.round(
                                    ((viewGrade === ViewGrades.CURRENT
                                        ? g.numberCurrentWorkingThisGradeOrAbove
                                        : viewGrade === ViewGrades.PROJECTED
                                          ? g.numberProjectedWorkingThisGradeOrAbove
                                          : g.numberTeacherWorkingThisGradeOrAbove) /
                                        subjectAnalysisGradeBreakdownData.numberOfStudents) *
                                        100,
                                );
                                return (
                                    <TableRow key={g.name}>
                                        <CustomTableCell>
                                            <Box fontWeight="fontWeightBold">{g.name}</Box>
                                        </CustomTableCell>
                                        <CustomTableCell
                                            className={clsx(
                                                classes.tableBorderLeft,
                                                numberOfStudentsWorkingAtThisGrade === 0 &&
                                                    classes.tableColorOpacity,
                                            )}
                                        >
                                            {numberOfStudentsWorkingAtThisGrade}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            className={clsx(
                                                classes.tableBackground,
                                                classes.tableBorderLeft,
                                                numberOfStudentsAtThisGradeOrAbove === 0 &&
                                                    classes.tableColorOpacity,
                                            )}
                                        >
                                            {numberOfStudentsAtThisGradeOrAbove}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            className={clsx(
                                                classes.tableBackground,
                                                percentOfStudentsAtThisGradeOrAbove === 0 &&
                                                    classes.tableColorOpacity,
                                            )}
                                        >
                                            {`${percentOfStudentsAtThisGradeOrAbove}%`}
                                        </CustomTableCell>
                                        <CustomTableCell
                                            className={clsx(
                                                classes.tableBackground,
                                                g.numberTargetWorkingThisGradeOrAbove === 0 &&
                                                    classes.tableColorOpacity,
                                            )}
                                        >
                                            {g.numberTargetWorkingThisGradeOrAbove !== 0
                                                ? Math.round(
                                                      ((g.numberTargetWorkingThisGradeOrAbove *
                                                          100) /
                                                          (subjectAnalysisGradeBreakdownData.numberOfStudents *
                                                              100)) *
                                                          100,
                                                  ) + "%"
                                                : "0%"}
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <CustomTableCell colSpan={15}>
                                <Button
                                    variant="text"
                                    onClick={() =>
                                        exportSubjectAnalysisGradeBreakdownToExcel(
                                            gridApi.current,
                                            subjectAnalysisGradeBreakdownData?.numberOfStudents,
                                        )
                                    }
                                    startIcon={<Icon path={mdiDownload} />}
                                    disableRipple
                                >
                                    {t("analysis.subjectAnalysis.downloadExcelBtn")}
                                </Button>
                            </CustomTableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Box>
        </Box>
    );
};
export default SubjectAnalysisGradeBreakdown;
