import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const getStudentReportByAttributeEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/student-report/{cohort}/{yearGroup}/by-attribute/`,
    method: ApiRequests.POST,
};

export interface StudentByAttributeRequest {
    form: string | null;
    pupilPremium: boolean | null;
    fsm: boolean | null;
    lookedAfter: boolean | null;
    eal: boolean | null;
    priorAbility: string | null;
    senStatus: string | null;
    gender: string | null;
    ethnicity: ListObject<string | null> | null;
    senNeed: ListObject<string | null> | null;
    onlySummary: boolean;
    reportTemplate?: ListObject<string | number | null> | null;
    academicHouse?: string;
    schoolSnapshot?: { id: number } | null;
}
