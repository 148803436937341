import { Button, Grid } from "@mui/material";
import { createRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import FormikRef from "src/forms/FormikRef";
import { Field } from "formik";
import CheckboxField from "src/forms/CheckboxField";
import Dialog from "src/components/Dialog";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useAnalysisUserSettingsStore } from "../../hooks/useAnalysisUserSettingsStore";

interface OwnProps {
    open: boolean;
    availableStudentColumns: string[];
    cohort: number;
    yearGroup: number;
    viewType: string;
    afterModalCloseActions: (saved?) => void;
}

const ConfigureStudentColumnsModal = ({
    open,
    availableStudentColumns,
    cohort,
    yearGroup,
    viewType,
    afterModalCloseActions,
}: OwnProps) => {
    const form = createRef() as any;
    const { t } = useTranslation();

    const configureColumns = {};

    const { setAnalysisUserSettings: setAnalysisUserSettingsStore, ...analysisUserSettings } =
        useAnalysisUserSettingsStore();
    const setAnalysisUserSettings = values => {
        setAnalysisUserSettingsStore(values);
        setTimeout(() => handleModalClose(true), 0);
    };

    const handleModalClose = useCallback(
        (saved?: boolean) => {
            afterModalCloseActions(saved);
        },
        [afterModalCloseActions],
    );
    const columnConfigObject = {};

    if (availableStudentColumns && availableStudentColumns.length > 0) {
        Object.entries(availableStudentColumns).forEach(([, value]) => {
            if (analysisUserSettings && analysisUserSettings.columnConfig) {
                columnConfigObject[value] = !!analysisUserSettings.columnConfig[value];
            } else {
                columnConfigObject[value] = configureColumns[value] || false;
            }
        });
    }
    columnConfigObject["student_firstName"] = true;
    columnConfigObject["student_lastName"] = true;

    const handleSubmitForm = () => {
        const currentForm = form.current as any;
        currentForm.submitForm();
    };

    const handleSubmit = values => {
        if (cohort && yearGroup && viewType) {
            setAnalysisUserSettings({
                columnConfig: values,
            });
        }
    };
    const excludedStudentColumns = [
        "student_ethnicity_id",
        "id",
        "classTracker_id",
        "classTrackerId",
        "ethnicity_id",
    ];
    const { data: accountInfo } = useAccountInfo();
    const hasPupilApp = !!accountInfo?.hasStudentPortalEnabled;
    if (!hasPupilApp && !excludedStudentColumns.includes("student_email")) {
        excludedStudentColumns.push("student_email");
    }
    return (
        <Dialog
            open={open}
            onClose={() => handleModalClose()}
            title={t("tracker.grid.configureColumns.header")}
            actions={
                <Button onClick={handleSubmitForm} color="primary">
                    {t("tracker.grid.configureColumns.save")}
                </Button>
            }
        >
            <Grid container>
                {analysisUserSettings &&
                    availableStudentColumns &&
                    availableStudentColumns.length > 0 && (
                        <FormikRef
                            ref={form}
                            initialValues={columnConfigObject}
                            onSubmit={handleSubmit}
                            enableReinitialize={true}
                        >
                            <>
                                {Object.entries(columnConfigObject)
                                    .filter(([key]) => !excludedStudentColumns.includes(key))
                                    .map(([key]) => (
                                        <Grid key={key} item sm={4}>
                                            <Field
                                                name={key}
                                                label={t(
                                                    `tracker.grid.configureColumns.${key.replace(
                                                        "student_",
                                                        "",
                                                    )}`,
                                                )}
                                                disabled={[
                                                    "student_firstName",
                                                    "student_lastName",
                                                ].includes(key)}
                                                component={CheckboxField}
                                            />
                                        </Grid>
                                    ))}
                            </>
                        </FormikRef>
                    )}
            </Grid>
        </Dialog>
    );
};

export default ConfigureStudentColumnsModal;
