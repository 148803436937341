import { ApiRequestPayload } from "./actions";

export enum ApiRequests {
    GET,
    POST,
    PUT,
    PATCH,
    REMOVE,
    BLOB,
    POSTBLOB,
}

export interface ModuleActionMeta {
    endpoint: string;
    method: ApiRequests;
    onSuccess?: string;
    onFailure?: string;
}

export interface ApiActionMeta extends ModuleActionMeta {
    prefix: string;
    key: string;
}

interface ApiPayloadSimple<V extends object = object> {
    values: V;
    params?: object;
}

interface ApiPayloadWithParams<V extends object, P extends object> {
    values: V;
    params: P;
}

export type ApiPayload<
    V extends object = object,
    P extends object | undefined = undefined,
> = P extends object ? ApiPayloadWithParams<V, P> : ApiPayloadSimple<V>;

export type ApiAction<T extends string, P extends ApiPayload> = Readonly<{
    type: T;
    payload?: P;
    meta: ApiActionMeta;
}>;

export enum ApiStatus {
    NONE,
    LOADING,
    SUCCESS,
    ERROR,
}

export interface AjaxErrorCustom {
    response: Record<string, any>;
    request: Record<string, any>;
    status: number;
}
export interface ApiData {
    status: ApiStatus;
    error: AjaxErrorCustom | null;
    responseStatus?: number | null;
}

export interface ApiEndpoint {
    endpoint: string;
    method: ApiRequests;
}

export interface ApiCallbackPayload<R, V extends object = object, P extends object = object> {
    status?: number;
    request: ApiRequestPayload<V, P>;
    response: R;
}
