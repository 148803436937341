import { Box, Chip } from "@mui/material";
import { OrmQualification } from "src/orm/models/Qualification";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { addMonths, addYears, addWeeks, format } from "date-fns";

import { useTranslation } from "react-i18next";
import COLORS from "src/styles/colors";
import Icon from "src/components/Icon";
import { mdiLibrary, mdiDomain, mdiSchool } from "@mdi/js";
import { SubscriptionSubjectArea, SubscriptionTypes } from "src/orm/models/Subscription";

export interface SummaryBoxProps {
    subjectArea?: OrmSubjectArea | SubscriptionSubjectArea;
    qualifications?: OrmQualification[];
    period?: string;
    isTrial?: boolean;
    isNaht?: boolean;
    isWholeSchool?: boolean;
    oneline?: boolean;
    type?: string;
    startDate?: string;
    dueDate?: string;
}

const SummaryBox = (props: SummaryBoxProps) => {
    const { t } = useTranslation();
    let { isWholeSchool } = props;

    const { subjectArea, qualifications, period, isNaht, isTrial, type, startDate, dueDate } =
        props;

    if (type && type === SubscriptionTypes.WHOLE_SCHOOL) {
        isWholeSchool = true;
    }

    const subscriptionIcon = () => {
        if (isWholeSchool) {
            return <Icon color={COLORS.GREY_3} size="24px" path={mdiDomain} />;
        }

        if (
            isNaht ||
            isTrial ||
            (type && type === SubscriptionTypes.FULL_SUITE) ||
            (qualifications && qualifications.length > 2)
        ) {
            return <Icon color={COLORS.GREY_3} size="24px" path={mdiSchool} />;
        }

        if (qualifications) {
            return <Icon color={COLORS.GREY_3} size="24px" path={mdiLibrary} />;
        }
        return null;
    };

    const getSubscriptionTypeBox = () => {
        if (isWholeSchool) {
            return (
                <>
                    <Box fontSize={14} fontWeight={700}>
                        {t("subscription.type.wholeSchool")}
                    </Box>
                </>
            );
        }

        if (
            isTrial ||
            isNaht ||
            (type && type === SubscriptionTypes.FULL_SUITE) ||
            (qualifications && qualifications.length > 2)
        ) {
            return (
                <>
                    <Box fontSize={14} fontWeight={700}>
                        {t("subscription.type.fullSuite")}
                    </Box>
                </>
            );
        }

        if (qualifications) {
            return (
                <>
                    <Box fontSize={14} fontWeight={700}>{`${t("subscription.type.single")} (${
                        qualifications.length
                    } ${t(
                        qualifications.length === 1 ? "common.course" : "common.courses",
                    )})`}</Box>
                </>
            );
        }
        return null;
    };

    const subscriptionCell = (
        <>
            {getSubscriptionTypeBox()}
            {period && period.slice(-1) === "M" ? (
                <Box mt={0.375} fontSize={11} fontStyle="italic">{`${period.slice(1, -1)} ${t(
                    "common.months",
                )}`}</Box>
            ) : period ? (
                <Box mt={0.375} fontSize={11} fontStyle="italic">
                    {t("subscription.upgrade." + period + "Subscription")}
                </Box>
            ) : (
                ""
            )}
        </>
    );

    const periodCell = isTrial ? (
        <>
            <Box fontSize={11}>{t("subscription.summary.trialPeriod")}</Box>
            <Box fontSize={14} mt={0.375}>
                {t("subscription.summary.freeTrial")}
            </Box>
        </>
    ) : isNaht ? (
        <>
            <Box fontSize={11}>{t("subscription.summary.validUntil")}</Box>
            <Box fontSize={14} mt={0.375}>
                {t("subscription.summary.nahtPeriod")}
            </Box>
        </>
    ) : (
        <>
            <Box fontSize={11} color={COLORS.BLUE_3} fontStyle="italic">
                {t("subscription.summary.validUntil")}
            </Box>
            {period && (
                <Box fontSize={14} mt={0.375}>
                    {dueDate
                        ? format(new Date(dueDate), "dd MMMM yyyy")
                        : format(
                              period.slice(-1) === "M"
                                  ? addMonths(
                                        startDate ? new Date(startDate) : new Date(),
                                        parseInt(period.slice(1, -1)),
                                    )
                                  : period.slice(-1) === "W"
                                    ? addWeeks(
                                          startDate ? new Date(startDate) : new Date(),
                                          parseInt(period.slice(1, -1)),
                                      )
                                    : addYears(
                                          startDate ? new Date(startDate) : new Date(),
                                          parseInt(period.slice(1, -1)),
                                      ),
                              "dd MMMM yyyy",
                          )}
                </Box>
            )}
        </>
    );

    const areasCell = isWholeSchool ? (
        <Box display="flex">
            <Chip
                label={t("subscription.add.allSubjectAreas")}
                style={{
                    backgroundColor: COLORS.BLUE_2,
                }}
            />
            <Box
                fontSize={12}
                lineHeight={1.67}
                ml={2}
                pl={2}
                pt={0.25}
                pb={0.25}
                borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
            >
                <Box fontStyle="italic" fontSize={12} lineHeight={1.67} component="span">
                    {t("subscription.add.allCourses")}
                </Box>
                <Box color={COLORS.BLUE_3} fontSize={12} lineHeight={1.67} component="span">
                    {" "}
                    | {t("subscription.add.noLimits")}
                </Box>
            </Box>
        </Box>
    ) : (
        <Box display="flex">
            {subjectArea && (
                <Chip
                    label={subjectArea.name}
                    style={{
                        backgroundColor: subjectArea.colour,
                    }}
                />
            )}
            <Box
                fontStyle={qualifications ? "normal" : "italic"}
                fontSize={12}
                lineHeight={1.67}
                ml={2}
                pl={2}
                pt={0.25}
                pb={0.25}
                borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
            >
                {qualifications
                    ? qualifications.map((q, index) => {
                          return (
                              <Box
                                  key={index}
                                  display={props.oneline ? "block" : "inline"}
                                  component="span"
                              >{`${q.name}${
                                  qualifications && index < qualifications.length - 1 ? ", " : ""
                              }`}</Box>
                          );
                      })
                    : t("subscription.summary.trialAllCourses")}
            </Box>
        </Box>
    );

    return props.oneline && props.oneline === true ? (
        <Box
            bgcolor={COLORS.WHITE_A80}
            border={`1px solid ${COLORS.LIGHT_GREY_2}`}
            borderRadius={2}
            display="inline-flex"
        >
            <Box display="flex" alignItems="center" p={2}>
                {subscriptionIcon()}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                p={2}
            >
                {subscriptionCell}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                p={2}
            >
                {areasCell}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                p={2}
            >
                {periodCell}
            </Box>
        </Box>
    ) : (
        <Box
            bgcolor={COLORS.WHITE_A80}
            border={`1px solid ${COLORS.LIGHT_GREY_2}`}
            borderRadius={2}
        >
            <Box display="flex">
                <Box p={2} display="flex" alignItems="center">
                    {subscriptionIcon()}
                </Box>
                <Box
                    p={2}
                    display="flex"
                    flexDirection="column"
                    borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                >
                    {subscriptionCell}
                </Box>
                <Box
                    p={2}
                    display="flex"
                    flexDirection="column"
                    borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                >
                    {periodCell}
                </Box>
            </Box>
            <Box p={2} borderTop={`1px solid ${COLORS.LIGHT_GREY_2}`}>
                {areasCell}
            </Box>
        </Box>
    );
};

export default SummaryBox;
