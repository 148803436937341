import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getAnalysisCommonRelatedDataEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/common/{cohort}/{yearGroup}/related-data/`,
    method: ApiRequests.GET,
};

export interface AnalysisColourConfigDetailsObject {
    mtgDistance: string;
    opacity: number;
    threshold: number;
    colour: string;
    gradeId: number;
}

export interface AnalysisRelatedDataObject {
    colourConfig: {
        type: string;
        details: AnalysisColourConfigDetailsObject[];
    }[];
    gradeTypes: [
        {
            id: number;
            name: string;
            courseGrades: [
                {
                    name: string;
                    id: number;
                },
            ];
        },
    ];
}
//we have rq version
//remove after useAnalysisKS4GradeData.ts and useAnalysisAtlData.ts will be on rq
