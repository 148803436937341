import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSpecification } from "src/orm/models/Specification";
import { getSchoolAccountId } from "src/services/url";

export const commonSpecificationsEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/common/qualifications/{qualification}/specifications/`,
    method: ApiRequests.GET,
};

export type CommonSpecificationsResponse = OrmSpecification[];
