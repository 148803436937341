import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

const apiResendEmail = async (id: number): Promise<AxiosResponse> => {
    return await axiosInstance.post(`user/email/${id}/resend-email/`);
};

export const useResendEmail = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (id: number) => apiResendEmail(id),
        onSuccess: () => {
            enqueueSnackbar(t("account.myAccountTab.resendEmailSuccess"), { variant: "success" });
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
