import { ApiData } from "src/api/constants";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { NotificationActions } from "src/notifications/store/actions";

export interface FormError {
    message: string | null;
    formError: any;
}

export const getErrorMessage = (
    apiData: ApiData,
    statuses: number[] = [400, 403, 404, 409, 424],
    callback: (errors) => object = (errors: object) => errors,
): FormError => {
    if (apiData.error && apiData.error.response && statuses.includes(apiData.error.status)) {
        if (apiData.error.response.error) {
            return { message: apiData.error.response.error, formError: null };
        } else if (apiData.error.response.errors) {
            return { formError: callback(apiData.error.response.errors), message: null };
        } else {
            return { message: "Unknown form error", formError: null };
        }
    }
    return { message: null, formError: null };
};

export const handleFormError = (
    apiData: ApiData,
    form,
    enqueueSnackbar: typeof NotificationActions.enqueueSnackbar,
    statuses: number[] = [400, 403, 404, 424],
): void => {
    if (apiData.error && apiData.error.response && statuses.includes(apiData.error.status)) {
        if (apiData.error.response.error) {
            enqueueSnackbar({
                message: apiData.error.response.error,
                ...SnackbarErrorOptions,
            });
        } else if (apiData.error.response.errors) {
            form.setErrors(apiData.error.response.errors);
        } else {
            enqueueSnackbar({
                message: "Unknown form validation error",
                ...SnackbarErrorOptions,
            });
        }
    }
};
