import { useEffect } from "react";
import { Dispatch } from "redux";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import {
    classTrackerDetailsSelector,
    classTrackerWithFilterSelector,
} from "src/modules/tracker/selectors/ClassTrackerSelectors";
import { useMount } from "src/hooks/useMount";
import { ApiData } from "src/api/constants";
import { ClassActions } from "src/modules/class/store/actions";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { ClassTrackerHeaderObject } from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";

const dispatchActions = (dispatch: Dispatch) => ({
    getClassTrackerGroupList: (cohort: number) => {
        dispatch(ClassActions.getClassTrackerGroupList(cohort));
    },
    getClassTrackerHeader: (classTrackerId: number, tier: TiersTypes) => {
        dispatch(TrackerActions.getClassTrackerHeader(classTrackerId, tier));
    },
    getClassTrackerRelatedData: (classTrackerId: number) => {
        dispatch(TrackerActions.getClassTrackerRelated(classTrackerId));
    },
    getClassDetails: (classTrackerId: number) => {
        dispatch(ClassActions.getClassDetails(classTrackerId));
    },
    getLastUpdatedStatuses: (cohortId: number) => {
        dispatch(ClassActions.getLastUpdatedClassTrackers(cohortId));
    },
});

interface ClassTrackerInitialData {
    isFetched?: boolean;
    classTrackerHeader: ClassTrackerHeaderObject | null;
    classTrackerRelatedData: ClassTrackerRelatedObject | null;
    classDetails: OrmClassTracker;
    apiRelatedData: ApiData;
    apiValues: ApiData;
    apiHeader: ApiData;
}

export const useTrackerInitialConfigData = (
    classTrackerId: number | null,
    cohort: number | null,
    tier: TiersTypes,
): ClassTrackerInitialData & {
    getClassTrackerHeader: (classTrackerId: number | null, tier: TiersTypes) => void;
} & { refetchClassDetails: (classTrackerId: number) => void } & {
    getClassTrackerRelatedData: (classTrackerId: number) => void;
} & { getLastUpdatedStatuses: (cohortId: number) => void } => {
    const dispatch = useDispatch();

    const {
        getClassTrackerGroupList,
        getClassTrackerHeader,
        getLastUpdatedStatuses,
        getClassTrackerRelatedData,
        getClassDetails,
    } = dispatchActions(dispatch);

    const {
        classTrackerHeader,
        classTrackerRelatedData,
        classDetails,
        apiRelatedData,
        apiValues,
        apiHeader,
    }: ClassTrackerInitialData = useSelector(
        (state: AppState) => ({
            classTrackerHeader: state.tracker.classTrackerHeader,
            classDetails: classTrackerDetailsSelector(state, classTrackerId || 0),
            classTrackerRelatedData: state.tracker.relatedData,
            apiRelatedData: state.api.tracker.getClassTrackerRelated,
            apiValues: state.api.tracker.getClassTrackerValues,
            apiHeader: state.api.tracker.getClassTrackerHeader,
        }),
        shallowEqual,
    );

    const { classTrackerList }: { classTrackerList: OrmClassTracker[] } = useSelector(
        (state: AppState) => ({
            classTrackerList: classTrackerWithFilterSelector(state, ct => ct.cohortId === cohort),
        }),
        shallowEqual,
    );

    useMount(() => {
        if (!classDetails.id && classTrackerId) {
            getClassDetails(classTrackerId);
        }
        if (classTrackerList.length === 0 && cohort) {
            getClassTrackerGroupList(cohort);
        }
    });

    useEffect(() => {
        if (classDetails.id && classTrackerId) {
            getClassTrackerHeader(classTrackerId, tier);
            getClassTrackerRelatedData(classTrackerId);
        }
    }, [classDetails.id]);

    const refetchClassDetails = classTrackerId => {
        getClassDetails(classTrackerId);
    };

    return {
        classDetails,
        classTrackerHeader,
        classTrackerRelatedData,
        getClassTrackerHeader,
        getClassTrackerRelatedData,
        getLastUpdatedStatuses,
        apiRelatedData,
        apiValues,
        apiHeader,
        refetchClassDetails,
    };
};
