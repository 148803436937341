import { ColumnGroup } from "ag-grid-community";

export const getFlatColumnGroups = (group: ColumnGroup, groupArray: { [key: string]: boolean }) => {
    if (group) {
        groupArray[`cg-${group.getGroupId()}`] = !group.isExpanded();
        const groupChildren = group.getChildren();
        if (groupChildren && groupChildren.length > 0) {
            groupChildren.forEach(cg => {
                if (cg instanceof ColumnGroup) {
                    getFlatColumnGroups(cg, groupArray);
                }
            });
        }
    }
};
