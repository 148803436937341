import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useCallback, useState, createRef } from "react";
import { useTranslation } from "react-i18next";
import FormikRef from "src/forms/FormikRef";
import { Field } from "formik";
import CheckboxField from "src/forms/CheckboxField";
import Dialog from "src/components/Dialog";
import { INITIAL_STUDENTS_COLUMNS } from "../../services/headerGenerator";
import { ClassTrackerUserSettingsObject } from "../../hooks/query/ClassTracker/useTrackerUserSettings";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useTrackerUserSettingsStore } from "../../hooks/query/ClassTracker/useTrackerUserSettingsStore";
import { useTrackerGroupUserSettingsStore } from "../../hooks/query/ClassTrackerGroup/useTrackerGroupUserSettingsStore";

interface OwnProps {
    open: boolean;
    availableStudentColumns: string[];
    classTrackerUserSettings: ClassTrackerUserSettingsObject | null;
    classTrackerId?: number;
    classTrackerGroupId?: number;
    handleClose: (saved?: boolean) => void;
    handleColumnConfigPassData?: (values: { [key: string]: boolean }) => void;
}

const ConfigureColumnsModal = ({
    open,
    availableStudentColumns,
    classTrackerId,
    classTrackerGroupId,
    handleClose,
    handleColumnConfigPassData,
}: OwnProps) => {
    const form = createRef() as any;
    const { t } = useTranslation();

    const { setClassTrackerUserSettings, ...classTrackerUserSettings } =
        useTrackerUserSettingsStore();
    const setTrackerUserSettings = (newSettings: ClassTrackerUserSettingsObject) => {
        setClassTrackerUserSettings(newSettings);
        handleModalClose(true);
    };

    const { setClassTrackerGroupUserSettings, ...classTrackerGroupUserSettings } =
        useTrackerGroupUserSettingsStore();
    const setTrackerGroupUserSettings = (newSettings: ClassTrackerUserSettingsObject) => {
        setClassTrackerGroupUserSettings(newSettings);
        handleModalClose(true);
    };

    const userSettings = classTrackerId ? classTrackerUserSettings : classTrackerGroupUserSettings;
    const setUserSettings = classTrackerId ? setTrackerUserSettings : setTrackerGroupUserSettings;

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const configureColumns = userSettings?.columnConfig || {};

    if (configureColumns && Object.keys(configureColumns).length === 0) {
        INITIAL_STUDENTS_COLUMNS.forEach(initial => {
            configureColumns[initial] = true;
        });
    }

    const handleModalClose = useCallback(
        (saved?: boolean) => {
            handleClose(saved);
        },
        [handleClose],
    );

    const columnConfigObject = {};

    if (availableStudentColumns && availableStudentColumns.length > 0) {
        Object.entries(availableStudentColumns).forEach(([, value]) => {
            columnConfigObject[value] = configureColumns[value] || false;
        });
    }

    const handleSubmitForm = () => {
        setIsSubmitting(true);
        const currentForm = form.current as any;
        currentForm.submitForm();
    };

    const handleSubmit = (values: { [key: string]: boolean }) => {
        if (handleColumnConfigPassData) {
            handleColumnConfigPassData(values);
        }
        setUserSettings({ ...userSettings, columnConfig: values });
    };

    const excludedStudentColumns = [
        "student_ethnicity_id",
        "id",
        "classTracker_id",
        "classTrackerId",
        "ethnicity_id",
        "ethnicityId",
    ];
    const { data: accountInfo } = useAccountInfo();
    const hasPupilApp = !!accountInfo?.hasStudentPortalEnabled;
    if (!hasPupilApp && !excludedStudentColumns.includes("email")) {
        excludedStudentColumns.push("email");
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleModalClose()}
            title={t("tracker.grid.configureColumns.header")}
            actions={
                <Button disabled={isSubmitting} onClick={handleSubmitForm} color="primary">
                    {t("tracker.grid.configureColumns.save")}
                    {isSubmitting && (
                        <Box ml={2}>
                            <CircularProgress size={18} />
                        </Box>
                    )}
                </Button>
            }
        >
            <Grid container>
                {userSettings && availableStudentColumns && availableStudentColumns.length > 0 && (
                    <FormikRef
                        ref={form}
                        initialValues={columnConfigObject}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {formProps => (
                            <>
                                {Object.entries(columnConfigObject)
                                    .filter(([key]) => !excludedStudentColumns.includes(key))
                                    .map(([key]) => (
                                        <Grid key={key} item sm={4}>
                                            <Field
                                                name={key}
                                                label={t(`tracker.grid.configureColumns.${key}`)}
                                                disabled={
                                                    ["firstName", "lastName"].includes(key) ||
                                                    formProps.isSubmitting
                                                }
                                                component={CheckboxField}
                                            />
                                        </Grid>
                                    ))}
                            </>
                        )}
                    </FormikRef>
                )}
            </Grid>
        </Dialog>
    );
};

export default ConfigureColumnsModal;
