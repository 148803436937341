import { TrackerActions, TrackerActionTypes } from "./actions";
import { GradeBoundariesSeriesObject } from "../api/GradeBoundaries/getLiveGradeBoundarySeries";
import { ForecastUnitList } from "../api/Forecast/ClassTrackerGroup/getUnitList";
import { SnapshotList } from "../api/Snapshot/ClassTrackerGroup/getList";
import { ReportDownloadState } from "../hooks/useReportDownloader";
import { ClassTrackerHeaderObject } from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";
import { ClassTrackerValuesObject } from "../dto/TrackerValues";

export interface HighlightValuesState {
    columns?: string[];
    rowIndex?: number;
    clickedHash?: string;
}

export interface ClassTrackerState {
    classTrackerHeader: ClassTrackerHeaderObject | null;
    classTrackerValues: ClassTrackerValuesObject | null;
    relatedData: ClassTrackerRelatedObject | null;
    liveGradeBoundarySeries: GradeBoundariesSeriesObject[] | null;
    classTrackerGroupUnits: ForecastUnitList[] | null;
    loadForecastOverlapUnits: ForecastUnitList | null;
    snapshots: SnapshotList[] | null;
    groupSnapshots: SnapshotList[] | null;
    reportDownloadState: ReportDownloadState[];
    highlihtValuesState: HighlightValuesState[];
    isFetched: boolean;
    // snapshotData: SnapshotData | null;
}

export const initialState: ClassTrackerState = {
    classTrackerHeader: null,
    classTrackerValues: null,
    relatedData: null,
    liveGradeBoundarySeries: null,
    classTrackerGroupUnits: null,
    loadForecastOverlapUnits: null,
    snapshots: null,
    groupSnapshots: null,
    reportDownloadState: [],
    isFetched: false,
    highlihtValuesState: [],
    // snapshotData: null,
};

export const reducer = (
    state: ClassTrackerState = initialState,
    action: TrackerActions,
): ClassTrackerState => {
    switch (action.type) {
        case TrackerActionTypes.SET_REPORT_DOWNLOAD_STATE:
            return {
                ...state,
                reportDownloadState: state.reportDownloadState
                    .filter(
                        rds =>
                            !(
                                rds.classTracker === action.payload.classTrackerId &&
                                rds.id === action.payload.studentId
                            ),
                    )
                    .concat({
                        classTracker: action.payload.classTrackerId,
                        id: action.payload.studentId,
                        isDownloading: true,
                    }),
            };
        case TrackerActionTypes.CLEAR_CLASS_TRACKER_RELATED:
            return {
                ...state,
                relatedData: null,
            };
        case TrackerActionTypes.SET_HIGHLIGHT_VALUES_STATE:
            return {
                ...state,
                highlihtValuesState: action.payload,
            };
        case TrackerActionTypes.REMOVE_REPORT_DOWNLOAD_STATE:
            return {
                ...state,
                reportDownloadState: [...state.reportDownloadState].map(rds => {
                    if (
                        action.payload.classTrackerId === rds.classTracker &&
                        action.payload.studentId === rds.id
                    ) {
                        return { ...rds, isDownloading: false };
                    }
                    return rds;
                }),
            };
        case TrackerActionTypes.SET_CLASS_TRACKER_SNAPSHOTS:
            return {
                ...state,
                snapshots: action.payload,
            };
        case TrackerActionTypes.SET_CLASS_TRACKER_GROUP_SNAPSHOTS:
            return {
                ...state,
                groupSnapshots: action.payload,
            };
        case TrackerActionTypes.SET_CLASS_TRACKER_HEADER:
            return {
                ...state,
                classTrackerHeader: action.payload,
                isFetched: !!(state.classTrackerValues && state.relatedData),
            };
        case TrackerActionTypes.SET_CLASS_TRACKER_GROUP_HEADER:
            return {
                ...state,
                classTrackerHeader: action.payload,
            };
        case TrackerActionTypes.SET_CLASS_TRACKER_VALUES:
            return {
                ...state,
                classTrackerValues: action.payload,
                isFetched: !!(state.classTrackerHeader && state.relatedData),
            };
        case TrackerActionTypes.SET_CLASS_TRACKER_GROUP_VALUES:
            return {
                ...state,
                classTrackerValues: action.payload,
            };
        case TrackerActionTypes.SET_LOAD_FORECAST_OVERLAP_UNITS:
            return {
                ...state,
                loadForecastOverlapUnits: action.payload,
            };
        case TrackerActionTypes.SET_CLASS_TRACKER_RELATED:
            return {
                ...state,
                relatedData: action.payload,
                isFetched: !!(state.classTrackerValues && state.classTrackerHeader),
            };
        case TrackerActionTypes.SET_CLASS_TRACKER_GROUP_RELATED:
            return {
                ...state,
                relatedData: action.payload,
            };
        case TrackerActionTypes.SET_CLASS_LIVE_GRADE_BOUNDARIES:
            return {
                ...state,
                liveGradeBoundarySeries: action.payload,
            };
        case TrackerActionTypes.SET_CLASS_TRACKER_GROUP_UNITS:
            return {
                ...state,
                classTrackerGroupUnits: action.payload,
            };
        // case TrackerActionTypes.SET_SNAPSHOT_DATA:
        //     return {
        //         ...state,
        //         snapshotData: action.payload,
        //     };
        case TrackerActionTypes.CLEAR_CLASS_TRACKER:
            return initialState;
        case TrackerActionTypes.CLEAR_CLASS_TRACKER_VALUES:
            return { ...state, classTrackerValues: null };
        case TrackerActionTypes.CLEAR_CLASS_TRACKER_HEADER:
            return { ...state, classTrackerHeader: null };
        default:
            return state;
    }
};
