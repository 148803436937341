import AnalysisHeader from "../components/Header/AnalysisHeader";
import SubjectAnalysisHeader from "../components/SubjectAnalysis/SubjectAnalysisHeader";
import SubjectAnalysisClassSummary from "../components/SubjectAnalysis/SubjectAnalysisClassSummary";
import SubjectAnalysisSubGroup from "../components/SubjectAnalysis/SubjectAnalysisSubGroup";
import SubjectAnalysisGradeBreakdown from "../components/SubjectAnalysis/SubjectAnalysisGradeBreakdown";
import SubjectAnalysisCourseSelectorBox from "../components/SubjectAnalysis/SubjectAnalysisCourseSelectorBox";
import COLORS from "src/styles/colors";
import { Box, Theme, TableRow, createStyles, TableCell } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { AnalysisTypes } from "../components/Common/AnalysisTypeModal";
import { ViewGrades } from "../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { getGridBorderColor } from "../services/analysisColourHandler";
import { useLocation, useParams } from "react-router";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { useAnalysisSnapshots } from "../hooks/useAnalysisSnapshots";
import { useClassesForSpecification } from "../hooks/SubjectAnalysis/useClassesForSpecification";
import { useSubjectAnalysisSubjectAreasWithSpecifications } from "../hooks/SubjectAnalysis/useSubjectAnalysisSubjectAreasWithSpecifications";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export const VerticalCustomTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 600,
            lineHeight: 1.4,
            padding: theme.spacing(5, 2, 2, 2),
            verticalAlign: "bottom",
            maxHeight: 230,

            "& > span": {
                writingMode: "vertical-rl",
                transform: "rotate(180deg)",
            },
        },
    }),
)(TableCell);

export const CustomTableRow = withStyles(() =>
    createStyles({
        root: {
            backgroundColor: "rgba(232, 233, 235, 0.3)",
        },
    }),
)(TableRow);

export const CustomTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            color: COLORS.BLUE_3,
            fontSize: theme.typography.pxToRem(14),
            fontStyle: "italic",
            lineHeight: 1.7,
            padding: theme.spacing(1.125, 2),
            "& > small": {
                display: "block",
                fontStyle: "italic",
            },
        },
        body: {
            color: COLORS.GREY_1,
            fontSize: theme.typography.pxToRem(14),
            lineHeight: 1.7,
            padding: theme.spacing(1.125, 2),
            whiteSpace: "nowrap",
            "& > small": {
                display: "block",
                fontStyle: "italic",
                color: COLORS.GREY_2,
            },
        },
        footer: {
            borderBottom: "0 none",
            textAlign: "right",
            padding: theme.spacing(3.5, 2, 0),
        },
        sizeSmall: {
            backgroundColor: "transparent",
            color: COLORS.GREY_1,
            border: "0 none",
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 600,
            fontStyle: "normal",
            textAlign: "center",
        },
        alignRight: {
            verticalAlign: "middle",
            fontWeight: 600,
        },
        paddingNone: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 600,
            width: 42,
            padding: theme.spacing(1.75, 2, 1.125),
            "&:not(.last)": {
                borderBottom: "0 none",
            },
        },
    }),
)(TableCell);

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(0, 0, 3),
    },
    container: {
        display: "flex",
        flex: 1,
        width: "100%",
        minWidth: 1200,
        overflow: "hidden",
        backgroundColor: COLORS.WHITE,
    },
    content: {
        flex: 1,
        overflowY: "auto",
        position: "relative",
    },
    inner: {
        padding: theme.spacing(6, 4),
        [theme.breakpoints.up(1400)]: {
            padding: theme.spacing(8, 12.5),
        },
    },
}));

const SubjectAnalysisContainer = () => {
    const classes = useStyles();
    const location = useLocation();
    const { navigate } = useSchoolNavigate();

    const { cohort: cohortParam, yearGroup: yearGroupParam } = useParams();
    const query = new URLSearchParams(location.search);
    const [showReport, setShowReport] = useState<boolean>(false);

    const queryViewGrade = query && (query.get("viewGrade") as ViewGrades);
    const querySubjectArea = query && query.get("subjectArea");
    const queryQualification = query && query.get("qualification");
    const querySpecification = query && query.get("specification");

    const [viewGrade, setViewGrade] = useState<ViewGrades>(
        queryViewGrade && queryViewGrade !== ViewGrades.TARGET
            ? queryViewGrade
            : ViewGrades.PROJECTED,
    );
    const [dataType, setDataType] = useState<DataTypeTypes>(DataTypeTypes.LIVE);
    const [selectedSubject, setSelectedSubject] = useState<number | null>(
        querySubjectArea ? parseInt(querySubjectArea) : null,
    );
    const [selectedQualification, setSelectedQualification] = useState<number | null>(
        queryQualification ? parseInt(queryQualification) : null,
    );
    const [selectedSpecification, setSelectedSpecification] = useState<number | null>(
        querySpecification ? parseInt(querySpecification) : null,
    );
    const [selectedClassTrackers, setSelectedClassTrackers] = useState<number[]>([0]);

    const { data: classesForSpecification, refetch: getClassesForSpecification } =
        useClassesForSpecification(
            parseInt(`${cohortParam}`),
            parseInt(`${yearGroupParam}`),
            selectedSpecification,
        );
    const { data: subjectAnalysisSubjects, refetch: getSubjectAnalysisListWithSpecifications } =
        useSubjectAnalysisSubjectAreasWithSpecifications(
            parseInt(cohortParam || ""),
            parseInt(yearGroupParam || ""),
        );

    useEffect(() => {
        if (cohortParam && yearGroupParam) {
            setSelectedSubject(querySubjectArea ? parseInt(querySubjectArea) : null);
            setSelectedQualification(queryQualification ? parseInt(queryQualification) : null);
            setSelectedSpecification(querySpecification ? parseInt(querySpecification) : null);
            setShowReport(false);
            getSubjectAnalysisListWithSpecifications();
        }
    }, [yearGroupParam, cohortParam, querySubjectArea, queryQualification, querySpecification]);

    const handleChangeSelection = (type, value) => {
        switch (type) {
            case "subject":
                setSelectedSubject(value);
                break;
            case "qualification":
                setSelectedQualification(value);
                break;
            case "specification":
                setSelectedSpecification(value);
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        navigate(
            `${location.pathname}?dataType=${dataType}&viewGrade=${viewGrade}&subjectArea=${
                querySubjectArea || ""
            }&qualification=${queryQualification || ""}&specification=${querySpecification || ""}`,
        );
    }, [dataType, viewGrade, querySubjectArea, querySpecification, queryQualification]);

    useEffect(() => {
        if (selectedSpecification) {
            getClassesForSpecification();
        }
    }, [selectedSpecification, cohortParam, yearGroupParam]);

    const { snapshotData } = useAnalysisSnapshots(parseInt(cohortParam || ""));

    return (
        <Box component="main" className={classes.root}>
            <AnalysisHeader
                cohort={parseInt(cohortParam || "")}
                yearGroup={parseInt(yearGroupParam || "")}
                analysisType={AnalysisTypes.SUBJECT_ANALYSIS}
                viewGrade={viewGrade}
                dataType={dataType}
                isLoadingGrid={!showReport}
                handleFiltersVisible={() => {}}
                hideSnapshotCompare={true}
                handleToolsVisible={() => {}}
                handleDataTypeChange={dataType => setDataType(dataType)}
                handleViewGradeChange={viewGrade => {
                    if (viewGrade !== null) setViewGrade(viewGrade as ViewGrades);
                }}
            />
            {showReport ? (
                <Box
                    className={classes.container}
                    borderLeft={`10px solid ${getGridBorderColor(dataType)}`}
                >
                    <Box className={classes.content}>
                        <SubjectAnalysisHeader
                            subjectAnalysisSubjects={subjectAnalysisSubjects}
                            subjectArea={selectedSubject}
                            qualification={selectedQualification}
                            specification={selectedSpecification}
                            classTrackers={classesForSpecification}
                            handleClassesChange={data => {
                                setSelectedClassTrackers(data);
                            }}
                            selectedClassTrackers={selectedClassTrackers}
                            handleSelectSpecification={() => {
                                setSelectedSubject(null);
                                setSelectedQualification(null);
                                setSelectedSpecification(null);
                                setShowReport(false);
                            }}
                        />
                        <SubjectAnalysisClassSummary
                            subjectAnalysisSubjects={subjectAnalysisSubjects}
                            subjectArea={selectedSubject}
                            cohort={parseInt(cohortParam || "")}
                            yearGroup={parseInt(yearGroupParam || "")}
                            specification={selectedSpecification}
                            classTrackers={selectedClassTrackers}
                            viewGrade={viewGrade}
                            dataType={dataType}
                            snapshot={
                                snapshotData?.snapshot
                                    ? parseInt(snapshotData?.snapshot)
                                    : undefined
                            }
                        />
                        <SubjectAnalysisGradeBreakdown
                            cohort={parseInt(cohortParam || "")}
                            yearGroup={parseInt(yearGroupParam || "")}
                            specification={selectedSpecification}
                            classTrackers={selectedClassTrackers}
                            viewGrade={viewGrade}
                            dataType={dataType}
                            snapshot={
                                snapshotData?.snapshot
                                    ? parseInt(snapshotData?.snapshot)
                                    : undefined
                            }
                        />
                        <SubjectAnalysisSubGroup
                            cohort={parseInt(cohortParam || "")}
                            yearGroup={parseInt(yearGroupParam || "")}
                            specification={selectedSpecification}
                            classTrackers={selectedClassTrackers}
                            viewGrade={viewGrade}
                            dataType={dataType}
                            snapshot={
                                snapshotData?.snapshot
                                    ? parseInt(snapshotData?.snapshot)
                                    : undefined
                            }
                        />
                    </Box>
                </Box>
            ) : (
                <Box
                    className={classes.container}
                    borderLeft={`10px solid ${getGridBorderColor(dataType)}`}
                >
                    <SubjectAnalysisCourseSelectorBox
                        selectedQualification={selectedQualification}
                        selectedSpecification={selectedSpecification}
                        selectedSubject={selectedSubject}
                        subjectAnalysisSubjects={subjectAnalysisSubjects}
                        handleShowReport={() => setShowReport(true)}
                        handleChangeSelection={handleChangeSelection}
                    />
                </Box>
            )}
        </Box>
    );
};

export default SubjectAnalysisContainer;
