import { attr, Model } from "redux-orm";
import { TiersTypes } from "../ClassTrackerGroup";

const fields = {
    id: attr(),
    name: attr(),
    qualification: attr(),
    parentName: attr(),
    isTrackerLive: attr(),
    canAddCustomGradeBoundary: attr(),
    tiers: attr(),
    discontinued: attr(),
};

export interface OrmSpecification {
    id: number | null;
    name: string;
    qualification: number | null;
    isTrackerLive?: boolean;
    parentName?: string;
    canAddCustomGradeBoundary?: boolean;
    hasClasses?: boolean;
    discontinued?: boolean;
    tiers?: TiersTypes[];
}

class Specification extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "Specification";
    static fields = fields;
    static emptyRef: OrmSpecification = {
        id: null,
        name: "",
        qualification: null,
    };

    get ormRef(): OrmSpecification {
        return this.ref as any;
    }

    get ormRefDetails(): OrmSpecification {
        return this.ref as any;
    }
}

export default Specification;
