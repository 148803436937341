import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { CohortSnapshotModel } from "src/modules/school/models/CohortSnapshotModel";
import { getSchoolAccountId } from "src/services/url";

export const COHORT_SNAPSHOT_LIST_QUERY = "cohortSnapshotListQuery";

export const useCohortSnapshotList = (cohort: number, params?: { [key: string]: any }) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [COHORT_SNAPSHOT_LIST_QUERY, schoolAccountId, cohort, params],
        queryFn: async (): Promise<CohortSnapshotModel[] | null> => {
            if (cohort) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/school-snapshot/by-cohort/${cohort}/`,
                    {
                        params,
                    },
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
