import { ColDef } from "ag-grid-community";
import { FC } from "react";

interface OwnProps {
    context: any;
    colDef: ColDef;
    data: any;
    node: any;
}

export const DistanceToMtgDescriptionRenderer: FC<OwnProps> = ({ context, colDef, data, node }) => {
    const { field } = colDef;
    const dependentMtgField = field.replace("-mtgdescription", "");
    const dependentMtgValue = data[dependentMtgField];
    const textMap = context.columns[dependentMtgField]?.columnConfig?.typeConfig?.map;

    const getMappedText = (dependentMtgValue, textMap) => {
        if (dependentMtgValue === null) {
            return "";
        }
        if (dependentMtgValue === -1) {
            return textMap["-1"];
        }
        if (dependentMtgValue === 0) {
            return textMap["=="];
        }

        if (dependentMtgValue < -1) {
            return textMap["<"];
        }

        return textMap[">"];
    };

    const displayName = node.rowPinned === "top" ? "" : getMappedText(dependentMtgValue, textMap);

    return (
        <div title={displayName} className={`ag-value-cell-label`}>
            {displayName}
        </div>
    );
};
