import {
    Box,
    Button,
    Grid,
    MenuItem,
    Paper,
    Select,
    Typography,
    FormControl,
    InputLabel,
    FormHelperText,
    FormGroup,
} from "@mui/material";
import { ApiData, ApiStatus } from "src/api/constants";
import CheckboxField from "src/forms/CheckboxField";
import FormikObserver from "src/forms/FormikObserver";
import FormikRef from "src/forms/FormikRef";
import { usePrevious } from "src/hooks/usePrevious";
import { subjectAreaSelector } from "src/modules/common/selectors/SubjectAreaSelectors";
import { CommonActions } from "src/modules/common/store/actions";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { FormError, getErrorMessage } from "src/services/error";
import { filterNotNullable } from "src/services/object";
import { AppState } from "src/store/reducers";
import { Field, Form, FormikProps } from "formik";
import { TFunction } from "i18next";
import { useSnackbar } from "notistack";
import { createRef, Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import * as yup from "yup";
import FinancialManagerForm, {
    FinanceManagerFormFields,
    financeManagerSchema,
} from "../components/FinancialManagerForm";
import SubscriptionTypeForm from "../components/SubscriptionTypeForm";
import SummaryBox from "../components/SummaryBox";
import TotalsTable from "../components/TotalsTable";
import { SubscriptionValidTypes, useSubscriptionValid } from "../hooks/useSubscriptionValid";
import { SubscriptionsActions } from "../store/actions";
import ConfirmationBox from "../components/ConfirmationBox";
import { SubscriptionTypes } from "src/orm/models/Subscription";
import AppContainer from "src/components/AppContainer";
import HeadingCounter from "src/components/HeadingCounter";
import COLORS from "src/styles/colors";
import PaperInner from "src/components/PaperInner";
import FieldChangeHandler from "src/forms/FieldChangeHandler";
import { get } from "src/services/ajax";
import { apiUrl } from "src/config/globals";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { useMount } from "src/hooks/useMount";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useQualificationsList } from "src/modules/tagging/components/subjectRows/hooks/useQualificationsList";
import { getSchoolAccountId } from "src/services/url";

interface BuySubscriptionForm {
    length: string;
    couponName: string;
    financeManager: FinanceManagerFormFields;
    isAnnualy: boolean;
    qualifications: string[];
    subjectArea: number;
    type: string;
}

export const buySchema = (t: TFunction) =>
    yup.object().shape({
        period: yup.string(),
        financeManager: financeManagerSchema(t),
        isAnnualy: yup.bool(),
        qualifications: yup.array().min(1),
        subjectArea: yup.object().shape({ id: yup.number() }).required(),
    });

const convertToRequestData = values => {
    const qualifications = values.qualifications
        ? values.qualifications.filter(q => q[Object.keys(q)[0]])
        : [];

    const converted = {
        ...values,
        length: parseInt(values.length),
        qualifications:
            qualifications.length < 3 && qualifications.length > 0
                ? qualifications.map(q => ({ id: parseInt(Object.keys(q)[0]) }))
                : undefined,
        isAnnualy: values.isAnnualy,
        type: qualifications.length < 3 ? SubscriptionTypes.SINGLE : SubscriptionTypes.FULL_SUITE,
        subjectArea: values.subjectArea,
        couponName: values.couponName || null,
        financeManager:
            values.financeManager.title &&
            values.financeManager.email &&
            values.financeManager.firstName &&
            values.financeManager.lastName
                ? values.financeManager
                : undefined,
    };

    return converted;
};

const dispatchActions = (dispatch: Dispatch) => ({
    getSubjectAreas: () => {
        dispatch(CommonActions.getSubjectAreaList());
    },
    buySubscription: values => {
        dispatch(SubscriptionsActions.buySubscription(convertToRequestData(values)));
    },
});

const BuySubscription = props => {
    const dispatch = useDispatch();
    const form = createRef();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedSubjectArea, setSelectedSubjectArea] = useState<number>(0);
    const [buySubjectAreas, setBuySubjectAreas] = useState<number[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const { getSubjectAreas, buySubscription } = dispatchActions(dispatch);
    const { data: qualifications } = useQualificationsList(selectedSubjectArea);
    const {
        // apiList,
        apiCreate,
        subjectAreas,
    }: {
        // apiList: ApiData;
        apiCreate: ApiData;
        subjectAreas: OrmSubjectArea[];
    } = useSelector((state: AppState) => ({
        // apiList: state.api.common.getSubjectAreaList,
        apiCreate: state.api.subscription.buySubscription,
        subjectAreas: subjectAreaSelector(state),
    }));
    const prevApiStatus = usePrevious(apiCreate.status);
    const initialValues = {
        length: "1",
        couponName: "",
        financeManager: { firstName: "", lastName: "", email: "", title: "Mr" },
        isAnnualy: true,
        type: SubscriptionTypes.SINGLE,
        qualifications: qualifications?.map(q => ({ [`${q.id}`]: false })) || [],
    };

    const [validState, setValidState] = useState(null);

    const dataToValidator = validState || initialValues;

    const { result: validatorResponse, error: validatorError } = useSubscriptionValid(
        SubscriptionValidTypes.CREATE,
        convertToRequestData({ ...dataToValidator, subjectArea: selectedSubjectArea }),
    );

    const prevValidatorError = usePrevious(validatorError);

    if (validatorError !== null && prevValidatorError !== validatorError) {
        enqueueSnackbar(validatorError, {
            ...SnackbarErrorOptions,
        });
    }

    let subjectArea: OrmSubjectArea | undefined = undefined;
    if (selectedSubjectArea) {
        subjectArea = subjectAreas.find(sa => sa.id === selectedSubjectArea);
    }

    const handleRequestValid = values => {
        const convertedValues = convertToRequestData(values);

        buySchema(t)
            .isValid(convertedValues)
            .then(isValid => {
                if (isValid) {
                    setValidState(values);
                }
            });
    };

    const handleQualificationsChange = useCallback(
        (newValue, prevValue, args: { setFieldValue: (key: string, value: any) => void }) => {
            const newQualLen = newValue.filter(nV => {
                return Object.keys(nV).filter(key => nV[key] === true).length > 0;
            }).length;
            const prevQualLen = prevValue.filter(nV => {
                return Object.keys(nV).filter(key => nV[key] === true).length > 0;
            }).length;
            if (newQualLen >= 3 && newQualLen > prevQualLen) {
                args.setFieldValue(
                    "qualifications",
                    qualifications?.map(q => ({ [`${q.id}`]: true })) || [],
                );
            }
        },
        [qualifications],
    );

    const handleSubmit = values => {
        buySubscription({
            ...values,
            subjectArea: { id: selectedSubjectArea },
        });
    };

    const handleSubjectAreaChange = (e, setFieldValue) => {
        setFieldValue("qualifications", []);
        setSelectedSubjectArea(e.target.value);
    };

    const handleError = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.formError && error.formError["email"]) {
                        currentForm.setFieldError("financeManager.email", error.formError["email"]);
                    }
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
        },
        [enqueueSnackbar, form],
    );

    useEffect(() => {
        if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.SUCCESS) {
            setShowConfirmation(true);
        } else if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.ERROR) {
            handleError(apiCreate);
        }
    }, [apiCreate, enqueueSnackbar, handleError, prevApiStatus, props.history, t]);

    useMount(() => {
        getSubjectAreas();
        get(
            apiUrl(`school/${getSchoolAccountId()}/subject-area-for-buy`),
            undefined,
            undefined,
        ).subscribe(
            res => {
                setBuySubjectAreas([]);
                if (res.response.length > 0) setBuySubjectAreas(res.response);
            },
            () => setBuySubjectAreas([]),
        );
    });

    let filteredSubjectAreas: OrmSubjectArea[] = [];

    if (subjectAreas && buySubjectAreas) {
        filteredSubjectAreas = subjectAreas.filter(
            (fsa: OrmSubjectArea) => fsa.id && buySubjectAreas.includes(fsa.id),
        );
    }
    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("subscription.buy.header")}
            </Typography>
            <FormikRef
                ref={form}
                initialValues={initialValues}
                validationSchema={() => buySchema(t)}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<BuySubscriptionForm>) => {
                    const qualLength = formProps.values.qualifications
                        .filter(q => q[Object.keys(q)[0]])
                        .map(q => ({ id: parseInt(Object.keys(q)[0]) }));

                    const summaryValues = {
                        qualifications: filterNotNullable(qualifications).filter(qual => {
                            const mapped = formProps.values.qualifications
                                .filter(q => q[Object.keys(q)[0]] === true)
                                .map(q => Object.keys(q)[0]);
                            return mapped.includes(qual.id.toString());
                        }),
                        isWholeSchool: false,
                        subjectArea,
                        period: `P${formProps.values["length"]}${
                            formProps.values.isAnnualy ? "Y" : "M"
                        }`,
                        type:
                            qualLength && qualLength.length > 2
                                ? SubscriptionTypes.FULL_SUITE
                                : SubscriptionTypes.SINGLE,
                    };

                    if (showConfirmation) {
                        return (
                            <ConfirmationBox
                                values={{
                                    ...formProps.values,
                                    ...summaryValues,
                                    period: formProps.values.length,
                                }}
                                additionalMessage={null}
                            />
                        );
                    }

                    return (
                        <Form>
                            <FormikObserver
                                values={formProps.values}
                                onChange={handleRequestValid}
                            />
                            <Paper>
                                <PaperInner>
                                    <Grid container>
                                        <Grid item sm={4}>
                                            <HeadingCounter number="1">
                                                {t("subscription.add.chooseSubject")}
                                            </HeadingCounter>
                                            <Box mb={4}>
                                                <FormControl>
                                                    <InputLabel id="subjectAreaLabel">
                                                        {t("subscription.add.subjectArea")}
                                                    </InputLabel>
                                                    <Select
                                                        id="subjectAreaLabel"
                                                        name="subjectArea"
                                                        fullWidth
                                                        value={selectedSubjectArea}
                                                        onChange={e =>
                                                            handleSubjectAreaChange(
                                                                e,
                                                                formProps.setFieldValue,
                                                            )
                                                        }
                                                    >
                                                        <MenuItem key={0} value={0}>
                                                            {t("common.noneSelect")}
                                                        </MenuItem>
                                                        {filterNotNullable(
                                                            filteredSubjectAreas,
                                                        ).map(obj => (
                                                            <MenuItem key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <HeadingCounter number="2">
                                                {t("subscription.add.chooseCourses")}
                                            </HeadingCounter>
                                            <FieldChangeHandler
                                                onChange={handleQualificationsChange}
                                                value={formProps.values.qualifications}
                                                setFieldValue={formProps.setFieldValue}
                                            />
                                            <Box mb={4}>
                                                {formProps.values.qualifications.length > 0 ? (
                                                    <FormGroup>
                                                        {filterNotNullable(qualifications).map(
                                                            (qual, index) => (
                                                                <Field
                                                                    key={qual.id}
                                                                    name={`qualifications.${index}.${qual.id}`}
                                                                    component={CheckboxField}
                                                                    label={qual.name}
                                                                    color="primary"
                                                                />
                                                            ),
                                                        )}
                                                    </FormGroup>
                                                ) : (
                                                    selectedSubjectArea > 0 &&
                                                    t("subscription.add.noCourses")
                                                )}
                                                {selectedSubjectArea !== 0 &&
                                                    qualLength &&
                                                    qualLength.length === 0 && (
                                                        <FormHelperText error>
                                                            {t(
                                                                "subscription.add.minQualifications",
                                                            )}
                                                        </FormHelperText>
                                                    )}
                                            </Box>
                                            <HeadingCounter number="3">
                                                {t("subscription.add.choosePeriod")}
                                            </HeadingCounter>
                                            <Box mb={4}>
                                                <SubscriptionTypeForm
                                                    fieldName="length"
                                                    {...formProps}
                                                    showType={true}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={3} />
                                        <Grid item xs={5}>
                                            <Box
                                                pt={3}
                                                pr={4}
                                                pb={4}
                                                pl={4}
                                                bgcolor={COLORS.VERY_LIGHT_GREY_1}
                                                borderBottom={`1px solid ${COLORS.VERY_LIGHT_GREY_3}`}
                                            >
                                                <Typography
                                                    component="h2"
                                                    variant="h4"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    {t("subscription.summary.newSubscription")}
                                                </Typography>
                                                <SummaryBox {...summaryValues} />
                                            </Box>
                                            <Box
                                                pt={3}
                                                pr={4}
                                                pb={4}
                                                pl={4}
                                                bgcolor={COLORS.VERY_LIGHT_GREY_1}
                                            >
                                                <TotalsTable
                                                    validatorResponse={validatorResponse}
                                                    {...formProps}
                                                />
                                                {validatorResponse &&
                                                validatorResponse.isFinanceManager ? (
                                                    <Box pr={6.5}>
                                                        <Typography variant="overline">
                                                            {t("subscription.summary.byClicking")}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Fragment>
                                                        <Box pr={6.5}>
                                                            <Typography
                                                                variant="h4"
                                                                component="h2"
                                                                color="textSecondary"
                                                                gutterBottom
                                                            >
                                                                {t(
                                                                    "subscription.summary.inviteFinanceManager",
                                                                )}
                                                            </Typography>
                                                            <Typography variant="overline">
                                                                {t(
                                                                    "subscription.summary.isNoFinanceManagerMessage",
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                        <Box mt={2} mr={4}>
                                                            <FinancialManagerForm
                                                                financeManager={
                                                                    formProps.values.financeManager
                                                                }
                                                            />
                                                        </Box>
                                                    </Fragment>
                                                )}
                                                {qualLength.length > 0 &&
                                                    validatorError === null &&
                                                    validatorResponse !== null && (
                                                        <Box mt={4}>
                                                            <Button
                                                                color="primary"
                                                                onClick={formProps.submitForm}
                                                            >
                                                                {t("subscription.buy.buyButton")}
                                                            </Button>
                                                            <GenericErrorMessage
                                                                errors={formProps.errors}
                                                                submitCount={formProps.submitCount}
                                                            />
                                                        </Box>
                                                    )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </PaperInner>
                            </Paper>
                        </Form>
                    );
                }}
            </FormikRef>
        </AppContainer>
    );
};

export default BuySubscription;
