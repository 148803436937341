import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ParentModel } from "../model/ParentModel";
import { getSchoolAccountId } from "src/services/url";

export const PARENT_DETAILS_QUERY = "parentDetailsQuery";

export const useParentDetails = (id: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [PARENT_DETAILS_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<ParentModel | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/parents/${id}`,
                );

                return data || null;
            }

            return null;
        },
    });
};
