import { Select, InputBase, Theme } from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";
import COLORS from "src/styles/colors";

export const StyledCustomSelect = withStyles(() =>
    createStyles({
        root: {
            margin: 0,
        },
        icon: {
            color: COLORS.LIGHT_GREY_1,
            fontSize: 16,
            top: "calc(50% - 9px)",
            right: 6,
        },
    }),
)(Select);

export const StyledCustomInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
        },
        input: {
            position: "relative",
            backgroundColor: "transparent",
            border: "0 none",
            color: COLORS.GREY_2,
            fontSize: 12,
            fontWeight: 600,
            padding: theme.spacing(0.75, 3.5, 0.75, 1.25),
        },
    }),
)(InputBase);

export const StudentAttributeStyledCustomSelect = withStyles(() =>
    createStyles({
        root: {
            margin: 0,
        },
        icon: {
            color: COLORS.LIGHT_GREY_1,
            fontSize: 16,
            top: "calc(50% - 8px)",
            right: 8,
        },
    }),
)(Select);

export const StudentAttributeStyledCustomInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
        },
        input: {
            position: "relative",
            backgroundColor: "transparent",
            border: "0 none",
            color: COLORS.GREY_1,
            fontSize: 13,
            fontWeight: 400,
            padding: theme.spacing(1, 3.5, 1, 2.5),
        },
    }),
)(InputBase);
