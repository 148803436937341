import LoginContainer from "src/modules/auth/containers/LoginContainer";
import { useState, ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { SchoolUserRole } from "src/orm/models/User";
import { PRODUCT_FRUITS_WORKSPACE_ID } from "src/config/globals";
import { ProductFruits } from "react-product-fruits";
import { useAuthContext } from "src/context/AuthContext";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useUserContext } from "src/modules/user/hooks/useUserContext";
import { Storage } from "src/services/storage";
import { Box, CircularProgress } from "@mui/material";
import { getSchoolAccountId } from "src/services/url";

export type AuthGuardProps = {
    children: ReactNode;
};

export default function TeacherGuard({ children }: AuthGuardProps) {
    const { pathname } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
    const [localUserInfo, setLocalUserInfo] = useState();
    const { authState } = useAuthContext();
    const { data: userContext } = useUserContext();
    const { data: profile, isSuccess, isPending } = useProfile();
    const [isInitial, setIsInitial] = useState(true);

    useEffect(() => {
        if (profile?.email && getSchoolAccountId()) {
            const userInfo = {
                username: profile?.email,
                email: profile?.email,
                firstname: profile?.firstName,
                lastname: profile?.lastName,
                props: {
                    schoolAccountId: getSchoolAccountId(),
                },
            };
            if (!localUserInfo && !window?.productFruits) {
                setLocalUserInfo(userInfo as any);
            }
        }
    }, [profile?.email, authState, getSchoolAccountId()]);

    useEffect(() => {
        if (isInitial) {
            setIsInitial(false);
        }
    }, [isInitial]);

    if (isInitial || isPending || !authState || !profile) {
        return (
            <Box textAlign={"center"} p={20}>
                <CircularProgress />
            </Box>
        );
    }

    if (
        (!profile && !isPending) ||
        (!authState.token && !Storage.getItem("token")) ||
        profile?.userRole === SchoolUserRole.FINANCE
    ) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <LoginContainer />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return isSuccess ? (
        <>
            {children}
            <>
                {profile &&
                    localUserInfo &&
                    PRODUCT_FRUITS_WORKSPACE_ID &&
                    userContext &&
                    authState && (
                        <>
                            <ProductFruits
                                workspaceCode={PRODUCT_FRUITS_WORKSPACE_ID}
                                language={userContext?.language}
                                user={localUserInfo}
                            />
                        </>
                    )}
            </>
        </>
    ) : (
        <></>
    );
}
