import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SchoolActions } from "../actions";
import { SubjectAreaActionsTypes } from "./actions";
import { OrmSubjectAreaActions } from "src/orm/models/SubjectArea/actions";
import { OrmUserActions } from "src/orm/models/User/actions";

const getSubjectAreaListSuccessEpic = (action$: ActionsObservable<SchoolActions>) =>
    action$.pipe(
        ofType(SubjectAreaActionsTypes.GET_SUBJECT_AREA_LIST_SUCCESS),
        map((action: ReturnType<typeof SchoolActions.getSubjectAreaListSuccess>) => action.payload),
        mergeMap(payload => {
            return of(
                OrmSubjectAreaActions.bulkImport(payload.response),
                SchoolActions.setSubjectAreaForSubscriptions(payload.response),
            );
        }),
    );

const getSubjectAreaUsersListSuccessEpic = (action$: ActionsObservable<SchoolActions>) =>
    action$.pipe(
        ofType(SubjectAreaActionsTypes.GET_SUBJECT_AREA_USERS_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof SchoolActions.getSubjectAreaUsersListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(
                OrmUserActions.massMerge(payload.response.leaders),
                OrmUserActions.massMerge(payload.response.teachers),
                SchoolActions.setSubjectAreaUsers({
                    data: payload.response,
                    subjectArea: payload.request.payload.params.subjectArea,
                }),
            );
        }),
    );

const setLeaderSuccessEpic = (action$: ActionsObservable<SchoolActions>) =>
    action$.pipe(
        ofType(SubjectAreaActionsTypes.SET_LEADER_SUCCESS),
        map((action: ReturnType<typeof SchoolActions.setLeaderSuccess>) => action.payload),
        mergeMap(payload => {
            return of(
                OrmUserActions.massMerge(payload.response.leaders),
                OrmUserActions.massMerge(payload.response.teachers),
                SchoolActions.setSubjectAreaUsers({
                    data: payload.response,
                    subjectArea: payload.request.payload.params.subjectArea,
                }),
            );
        }),
    );

const setTeacherSuccessEpic = (action$: ActionsObservable<SchoolActions>) =>
    action$.pipe(
        ofType(SubjectAreaActionsTypes.SET_TEACHER_SUCCESS),
        map((action: ReturnType<typeof SchoolActions.setTeacherSuccess>) => action.payload),
        mergeMap(payload => {
            return of(
                OrmUserActions.massMerge(payload.response.leaders),
                OrmUserActions.massMerge(payload.response.teachers),
                SchoolActions.setSubjectAreaUsers({
                    data: payload.response,
                    subjectArea: payload.request.payload.params.subjectArea,
                }),
            );
        }),
    );

const removeLeaderSuccessEpic = (action$: ActionsObservable<SchoolActions>) =>
    action$.pipe(
        ofType(SubjectAreaActionsTypes.REMOVE_LEADER_SUCCESS),
        map((action: ReturnType<typeof SchoolActions.removeLeaderSuccess>) => action.payload),
        mergeMap(payload => {
            return of(
                OrmUserActions.massMerge(payload.response.leaders),
                OrmUserActions.massMerge(payload.response.teachers),
                SchoolActions.setSubjectAreaUsers({
                    data: payload.response,
                    subjectArea: payload.request.payload.params.subjectArea,
                }),
            );
        }),
    );

const removeTeacherSuccessEpic = (action$: ActionsObservable<SchoolActions>) =>
    action$.pipe(
        ofType(SubjectAreaActionsTypes.REMOVE_TEACHER_SUCCESS),
        map((action: ReturnType<typeof SchoolActions.removeTeacherSuccess>) => action.payload),
        mergeMap(payload => {
            return of(
                OrmUserActions.massMerge(payload.response.leaders),
                OrmUserActions.massMerge(payload.response.teachers),
                SchoolActions.setSubjectAreaUsers({
                    data: payload.response,
                    subjectArea: payload.request.payload.params.subjectArea,
                }),
            );
        }),
    );

export const subjectAreaEpics = combineEpics(
    getSubjectAreaListSuccessEpic,
    getSubjectAreaUsersListSuccessEpic,
    setLeaderSuccessEpic,
    setTeacherSuccessEpic,
    removeLeaderSuccessEpic,
    removeTeacherSuccessEpic,
);
