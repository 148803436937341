import { Hidden, styled, ToggleButtonProps } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { mdiRotateLeft, mdiTrendingUp, mdiViewList } from "@mdi/js";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Icon from "src/components/Icon";
import useDebounce from "src/hooks/useDebounce";
import COLORS from "src/styles/colors";

export enum DataTypeTypes {
    FORECAST = "forecast",
    SNAPSHOT = "snapshot",
    LIVE = "live",
}

const CustomToggleButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
    border: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
    color: COLORS.GREY_1,
    fontFamily: theme.typography.fontFamilyPrimary,
    textTransform: "capitalize",
    height: "auto",
    padding: theme.spacing(0.75, 1.75),
    transition: theme.transitions.create(["background-color", "color"], {
        duration: theme.transitions.duration.short,
    }),
    "&.Mui-selected.live-tracking": {
        backgroundColor: COLORS.GREEN_1,
        color: COLORS.WHITE,
        "&:hover": {
            backgroundColor: COLORS.GREEN_1,
        },
    },
    "&.Mui-selected.forecast": {
        backgroundColor: COLORS.CYAN_1,
        color: COLORS.WHITE,
        "&:hover": {
            backgroundColor: COLORS.CYAN_1,
        },
    },
    "&.Mui-selected.snapshots": {
        backgroundColor: COLORS.YELLOW_2,
        color: COLORS.WHITE,
        "&:hover": {
            backgroundColor: COLORS.YELLOW_2,
        },
    },
    "& svg": {
        display: "inherit",
        marginRight: theme.spacing(1),
    },
}));

interface OwnProps {
    dataType: DataTypeTypes;
    hasForecast: boolean;
    handleChange: (value: DataTypeTypes) => void;
    isLoadingGrid?: boolean;
}

const GridDataTypeButtons: FC<OwnProps> = ({
    handleChange,
    dataType,
    hasForecast,
    isLoadingGrid,
}) => {
    const { t } = useTranslation();
    const debounced = useDebounce(isLoadingGrid, 1000);

    return (
        //eslint-disable-next-line
        <ToggleButtonGroup
            value={dataType}
            onChange={(e, v) => {
                if (v !== null) {
                    handleChange(v);
                } else if (dataType === DataTypeTypes.SNAPSHOT && v === null) {
                    handleChange(DataTypeTypes.SNAPSHOT);
                }
            }}
            exclusive
        >
            <CustomToggleButton
                className="live-tracking"
                disabled={isLoadingGrid || debounced}
                value={DataTypeTypes.LIVE}
            >
                <Icon path={mdiViewList} />
                <Hidden mdDown> {t("tracker.grid.liveTrackingBtn")}</Hidden>
            </CustomToggleButton>
            {hasForecast && (
                <CustomToggleButton
                    className="forecast"
                    disabled={isLoadingGrid || debounced}
                    value={DataTypeTypes.FORECAST}
                >
                    <Icon path={mdiTrendingUp} />
                    <Hidden mdDown> {t("tracker.grid.forecastBtn")}</Hidden>
                </CustomToggleButton>
            )}
            <CustomToggleButton
                className="snapshots"
                disabled={isLoadingGrid || debounced}
                value={DataTypeTypes.SNAPSHOT}
            >
                <Icon path={mdiRotateLeft} />
                <Hidden mdDown> {t("tracker.grid.snapshotsBtn")}</Hidden>
            </CustomToggleButton>
        </ToggleButtonGroup>
    );
};

export default GridDataTypeButtons;
