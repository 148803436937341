import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload, ApiPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { SubscriptionParam } from "src/forms/types";
import { buyAfterTrialSubscriptionEndpoint } from "../../api/buyAfterTrialSubscription";
import { buySubscriptionEndpoint } from "../../api/buySubscription";
import { createTrialSubscriptionEndpoint } from "../../api/createTrial";
import { extendSubscriptionEndpoint } from "../../api/extendSubscription";
import { getSubscriptionListEndpoint, SubscriptionsResponse } from "../../api/getList";
import { getRequestedListEndpoint, SubscriptionHistoryResponse } from "../../api/getRequested";
import {
    rejectPendingSubscriptionEndpoint,
    RejectPendingSubscriptionResponse,
} from "../../api/rejectPending";
import { upgradeSubscriptionEndpoint } from "../../api/upgradeSubscription";
import {
    upgradeToWholeSchoolEndpoint,
    UpgradeTpWholeSchoolRequest,
} from "../../api/upgradeToWholeSchool";
import {
    declineResubscriptionEndpoint,
    DeclineResubscriptionResponse,
} from "../../api/declineResubscription";
import {
    getPaymentDetailsEndpoint,
    PaymentDetailsResponse,
} from "../../api/Payment/paymentDetails";
import { getReminderEndpoint, ReminderResponse } from "../../api/getReminder";
import { createNahtSupportedSubscriptionEndpoint } from "../../api/createNahtSupported";
import {
    convertToNahtSubscriptionEndpoint,
    ConvertToNahtSubscriptionResponse,
} from "../../api/convertToNaht";
import { extendNahtSubscriptionEndpoint } from "../../api/extendNaht";

export enum SubscriptionActionsTypes {
    GET_SUBSCRIPTION_LIST = "[subscription] GET SUBSCRIPTION LIST",
    GET_SUBSCRIPTION_LIST_SUCCESS = "[subscription] GET SUBSCRIPTION LIST SUCCESS",
    GET_REQUESTED_LIST = "[subscription] GET REQUESTED LIST",
    GET_REQUESTED_LIST_SUCCESS = "[subscription] GET REQUESTED LIST SUCCESS",
    GET_PAYMENT_DETAILS = "[subscription] GET PAYMENT DETAILS",
    GET_PAYMENT_DETAILS_SUCCESS = "[subscription] GET PAYMENT DETAILS SUCCESS",
    CREATE_TRIAL_SUBSCRIPTION = "[subscription] CREATE TRIAL SUBSCRIPTION",
    CREATE_TRIAL_SUBSCRIPTION_SUCCESS = "[subscription] CREATE TRIAL SUBSCRIPTION SUCCESS",
    UPGRADE_TO_WHOLESCHOOL_SUBSCRIPTION = "[subscription] UPGRADE TO WHOLESCHOOL SUBSCRIPTION",
    UPGRADE_TO_WHOLESCHOOL_SUBSCRIPTION_SUCCESS = "[subscription] UPGRADE TO WHOLESCHOOL SUBSCRIPTION SUCCESS",
    REJECT_PENDING_SUBSCRIPTION = "[subscription] REJECT PENDING SUBSCRIPTION",
    REJECT_PENDING_SUBSCRIPTION_SUCCESS = "[subscription] REJECT PENDING SUBSCRIPTION SUCCESS",
    BUY_AFTER_TRIAL_SUBSCRIPTION = "[subscription] BUY AFTER TRIAL SUBSCRIPTION",
    BUY_AFTER_TRIAL_SUBSCRIPTION_SUCCESS = "[subscription] BUY AFTER TRIAL SUBSCRIPTION SUCCESS",
    BUY_SUBSCRIPTION = "[subscription] BUY SUBSCRIPTION",
    BUY_SUBSCRIPTION_SUCCESS = "[subscription] BUY SUBSCRIPTION SUCCESS",
    UPGRADE_SUBSCRIPTION = "[subscription] UPGRADE SUBSCRIPTION",
    UPGRADE_SUBSCRIPTION_SUCCESS = "[subscription] UPGRADE SUBSCRIPTION SUCCESS",
    EXTEND_SUBSCRIPTION = "[subscription] EXTEND SUBSCRIPTION",
    EXTEND_SUBSCRIPTION_SUCCESS = "[subscription] EXTEND SUBSCRIPTION SUCCESS",
    DECLINE_RESUBSCRIPTION = "[subscription] DECLINE RESUBSCRIPTION",
    DECLINE_RESUBSCRIPTION_SUCCESS = "[subscription] DECLINE RESUBSCRIPTION SUCCESS",
    GET_REMINDER = "[subscription] GET REMINDER",
    GET_REMINDER_SUCCESS = "[subscription] GET REMINDER SUCCESS",
    CREATE_NAHT_SUPPORTED_SUBSCRIPTION = "[subscription] CREATE NAHT SUPPORTED SUBSCRIPTION",
    CREATE_NAHT_SUPPORTED_SUBSCRIPTION_SUCCESS = "[subscription] CREATE NAHT SUPPORTED SUBSCRIPTION SUCCESS",
    CONVERT_SUBSCRIPTION_TO_NAHT = "[subscription] CONVERT SUBSCRIPTION TO NAHT",
    CONVERT_SUBSCRIPTION_TO_NAHT_SUCCESS = "[subscription] CONVERT SUBSCRIPTION TO NAHT SUCCESS",
    EXTEND_NAHT_SUBSCRIPTION = "[subscription] EXTEND NAHT SUBSCRIPTION",
    EXTEND_NAHT_SUBSCRIPTION_SUCCESS = "[subscription] EXTEND NAHT SUBSCRIPTION SUCCESS",
}

export const SubscriptionApiEndpoints = {
    getSubscriptionList: createActionObject(
        getSubscriptionListEndpoint,
        SubscriptionActionsTypes.GET_SUBSCRIPTION_LIST_SUCCESS,
    ),
    getRequestedList: createActionObject(
        getRequestedListEndpoint,
        SubscriptionActionsTypes.GET_REQUESTED_LIST_SUCCESS,
    ),
    getPaymentDetails: createActionObject(
        getPaymentDetailsEndpoint,
        SubscriptionActionsTypes.GET_PAYMENT_DETAILS_SUCCESS,
    ),
    createTrialSubscription: createActionObject(
        createTrialSubscriptionEndpoint,
        SubscriptionActionsTypes.CREATE_TRIAL_SUBSCRIPTION_SUCCESS,
    ),
    buyAfterTrialSubscription: createActionObject(
        buyAfterTrialSubscriptionEndpoint,
        SubscriptionActionsTypes.BUY_AFTER_TRIAL_SUBSCRIPTION_SUCCESS,
    ),
    buySubscription: createActionObject(
        buySubscriptionEndpoint,
        SubscriptionActionsTypes.BUY_SUBSCRIPTION_SUCCESS,
    ),
    upgradeSubscription: createActionObject(
        upgradeSubscriptionEndpoint,
        SubscriptionActionsTypes.UPGRADE_SUBSCRIPTION_SUCCESS,
    ),
    extendSubscription: createActionObject(
        extendSubscriptionEndpoint,
        SubscriptionActionsTypes.EXTEND_SUBSCRIPTION_SUCCESS,
    ),
    upgradeToWholeSchool: createActionObject(
        upgradeToWholeSchoolEndpoint,
        SubscriptionActionsTypes.UPGRADE_TO_WHOLESCHOOL_SUBSCRIPTION_SUCCESS,
    ),
    rejectPendingSubscription: createActionObject(
        rejectPendingSubscriptionEndpoint,
        SubscriptionActionsTypes.REJECT_PENDING_SUBSCRIPTION_SUCCESS,
    ),
    declineResubscription: createActionObject(
        declineResubscriptionEndpoint,
        SubscriptionActionsTypes.DECLINE_RESUBSCRIPTION_SUCCESS,
    ),
    getReminder: createActionObject(
        getReminderEndpoint,
        SubscriptionActionsTypes.GET_REMINDER_SUCCESS,
    ),
    createNahtSupportedSubscription: createActionObject(
        createNahtSupportedSubscriptionEndpoint,
        SubscriptionActionsTypes.CREATE_NAHT_SUPPORTED_SUBSCRIPTION_SUCCESS,
    ),
    convertToNahtSubscription: createActionObject(
        convertToNahtSubscriptionEndpoint,
        SubscriptionActionsTypes.CONVERT_SUBSCRIPTION_TO_NAHT_SUCCESS,
    ),
    extendNahtSubscription: createActionObject(
        extendNahtSubscriptionEndpoint,
        SubscriptionActionsTypes.EXTEND_NAHT_SUBSCRIPTION_SUCCESS,
    ),
};

export const SubscriptionRawActions = (
    SubscriptionEndpoints: Record<Keys<typeof SubscriptionApiEndpoints>, ApiActionMeta>,
) => ({
    getSubscriptionList: () =>
        createApiAction(
            SubscriptionActionsTypes.GET_SUBSCRIPTION_LIST,
            undefined,
            SubscriptionEndpoints.getSubscriptionList,
        ),
    getSubscriptionListSuccess: (payload: ApiCallbackPayload<SubscriptionsResponse>) =>
        createAction(SubscriptionActionsTypes.GET_SUBSCRIPTION_LIST_SUCCESS, payload),
    getReminder: () =>
        createApiAction(
            SubscriptionActionsTypes.GET_REMINDER,
            undefined,
            SubscriptionEndpoints.getReminder,
        ),
    getReminderSuccess: (payload: ApiCallbackPayload<ReminderResponse>) =>
        createAction(SubscriptionActionsTypes.GET_REMINDER_SUCCESS, payload),
    getRequestedList: () =>
        createApiAction(
            SubscriptionActionsTypes.GET_REQUESTED_LIST,
            undefined,
            SubscriptionEndpoints.getRequestedList,
        ),
    getRequestedListSuccess: (payload: ApiCallbackPayload<SubscriptionHistoryResponse>) =>
        createAction(SubscriptionActionsTypes.GET_REQUESTED_LIST_SUCCESS, payload),
    getPaymentDetails: (subscription: number) =>
        createApiAction(
            SubscriptionActionsTypes.GET_PAYMENT_DETAILS,
            { values: {}, params: { subscription } },
            SubscriptionEndpoints.getPaymentDetails,
        ),
    getPaymentDetailsSuccess: (payload: ApiCallbackPayload<PaymentDetailsResponse>) =>
        createAction(SubscriptionActionsTypes.GET_PAYMENT_DETAILS_SUCCESS, payload),

    createTrialSubscription: (subjectArea: number) =>
        createApiAction(
            SubscriptionActionsTypes.CREATE_TRIAL_SUBSCRIPTION,
            { values: {}, params: { subjectArea } },
            SubscriptionEndpoints.createTrialSubscription,
        ),
    createTrialSubscriptionSuccess: (payload: ApiCallbackPayload<SubscriptionsResponse>) =>
        createAction(SubscriptionActionsTypes.CREATE_TRIAL_SUBSCRIPTION_SUCCESS, payload),
    buyAfterTrialSubscription: (subscription: number, payload) =>
        createApiAction(
            SubscriptionActionsTypes.BUY_AFTER_TRIAL_SUBSCRIPTION,
            { values: payload, params: { subscription } },
            SubscriptionEndpoints.buyAfterTrialSubscription,
        ),
    buyAfterTrialSubscriptionSuccess: () =>
        createAction(SubscriptionActionsTypes.BUY_AFTER_TRIAL_SUBSCRIPTION_SUCCESS),
    buySubscription: payload =>
        createApiAction(
            SubscriptionActionsTypes.BUY_SUBSCRIPTION,
            { values: payload, params: {} },
            SubscriptionEndpoints.buySubscription,
        ),
    buySubscriptionSuccess: () => createAction(SubscriptionActionsTypes.BUY_SUBSCRIPTION_SUCCESS),
    upgradeSubscription: (subscription: number, payload) =>
        createApiAction(
            SubscriptionActionsTypes.UPGRADE_SUBSCRIPTION,
            { values: payload, params: { subscription } },
            SubscriptionEndpoints.upgradeSubscription,
        ),
    upgradeSubscriptionSuccess: () =>
        createAction(SubscriptionActionsTypes.UPGRADE_SUBSCRIPTION_SUCCESS),
    extendSubscription: (subscription: number, payload) =>
        createApiAction(
            SubscriptionActionsTypes.EXTEND_SUBSCRIPTION,
            { values: payload, params: { subscription } },
            SubscriptionEndpoints.extendSubscription,
        ),
    extendSubscriptionSuccess: () =>
        createAction(SubscriptionActionsTypes.EXTEND_SUBSCRIPTION_SUCCESS),
    upgradeToWholeSchoolSubscription: (payload: ApiPayload<UpgradeTpWholeSchoolRequest>) =>
        createApiAction(
            SubscriptionActionsTypes.UPGRADE_TO_WHOLESCHOOL_SUBSCRIPTION,
            payload,
            SubscriptionEndpoints.upgradeToWholeSchool,
        ),
    upgradeToWholeSchoolSubscriptionSuccess: () =>
        createAction(SubscriptionActionsTypes.UPGRADE_TO_WHOLESCHOOL_SUBSCRIPTION_SUCCESS),
    rejectPendingSubscription: (subscription: number) =>
        createApiAction(
            SubscriptionActionsTypes.REJECT_PENDING_SUBSCRIPTION,
            { values: {}, params: { subscription } },
            SubscriptionEndpoints.rejectPendingSubscription,
        ),
    rejectPendingSubscriptionSuccess: (
        payload: ApiCallbackPayload<RejectPendingSubscriptionResponse, {}, SubscriptionParam>,
    ) => createAction(SubscriptionActionsTypes.REJECT_PENDING_SUBSCRIPTION_SUCCESS, payload),
    declineResubscription: (subscription: number) =>
        createApiAction(
            SubscriptionActionsTypes.DECLINE_RESUBSCRIPTION,
            { values: {}, params: { subscription } },
            SubscriptionEndpoints.declineResubscription,
        ),
    declineResubscriptionSuccess: (
        payload: ApiCallbackPayload<DeclineResubscriptionResponse, {}, SubscriptionParam>,
    ) => createAction(SubscriptionActionsTypes.DECLINE_RESUBSCRIPTION_SUCCESS, payload),
    createNahtSupportedSubscription: (subjectArea: number) =>
        createApiAction(
            SubscriptionActionsTypes.CREATE_NAHT_SUPPORTED_SUBSCRIPTION,
            { values: {}, params: { subjectArea } },
            SubscriptionEndpoints.createNahtSupportedSubscription,
        ),
    createNahtSupportedSubscriptionSuccess: (payload: ApiCallbackPayload<SubscriptionsResponse>) =>
        createAction(SubscriptionActionsTypes.CREATE_NAHT_SUPPORTED_SUBSCRIPTION_SUCCESS, payload),
    convertToNahtSubscription: (subscription: number) =>
        createApiAction(
            SubscriptionActionsTypes.CONVERT_SUBSCRIPTION_TO_NAHT,
            { values: {}, params: { subscription } },
            SubscriptionEndpoints.convertToNahtSubscription,
        ),
    convertToNahtSubscriptionSuccess: (
        payload: ApiCallbackPayload<ConvertToNahtSubscriptionResponse, {}, SubscriptionParam>,
    ) => createAction(SubscriptionActionsTypes.CONVERT_SUBSCRIPTION_TO_NAHT_SUCCESS, payload),
    extendNahtSubscription: (subscription: number) =>
        createApiAction(
            SubscriptionActionsTypes.EXTEND_NAHT_SUBSCRIPTION,
            { values: {}, params: { subscription } },
            SubscriptionEndpoints.extendNahtSubscription,
        ),
    extendNahtSubscriptionSuccess: (payload: ApiCallbackPayload<SubscriptionsResponse>) =>
        createAction(SubscriptionActionsTypes.EXTEND_NAHT_SUBSCRIPTION_SUCCESS, payload),
});
