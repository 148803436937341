import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import {
    getClassTrackerGroupForecastUnitListEndpoint,
    ForecastUnitList,
} from "../../api/Forecast/ClassTrackerGroup/getUnitList";
import { getClassTrackerGroupForecastValuesEndpoint } from "../../api/Forecast/ClassTrackerGroup/getValues";
import { loadClassTrackerGroupValuesCheckEndpoint } from "../../api/Forecast/ClassTrackerGroup/loadCheck";
import { loadClassTrackerGroupValuesForceEndpoint } from "../../api/Forecast/ClassTrackerGroup/loadForce";
import { clearClassTrackerGroupValuesEndpoint } from "../../api/Forecast/ClassTrackerGroup/clear";
import {
    getClassTrackerForecastValuesEndpoint,
    ForecastClassTrackerValuesObject,
} from "../../api/Forecast/ClassTracker/getValues";
import { loadClassTrackerValuesCheckEndpoint } from "../../api/Forecast/ClassTracker/loadCheck";
import { loadClassTrackerValuesForceEndpoint } from "../../api/Forecast/ClassTracker/loadForce";
import { clearClassTrackerValuesEndpoint } from "../../api/Forecast/ClassTracker/clear";
import { getStudentForecastReportDownloadEndpoint } from "../../api/Forecast/ClassTracker/getStudentForecastReport";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";

export enum ForecastActionsTypes {
    GET_FORECAST_CLASS_TRACKER_GROUP_UNITS = "[tracker] GET FORECAST CLASS TRACKER GROUP UNITS",
    GET_FORECAST_CLASS_TRACKER_GROUP_UNITS_SUCCESS = "[tracker] GET FORECAST CLASS TRACKER GROUP UNITS SUCCESS",
    SET_CLASS_TRACKER_GROUP_UNITS = "[tracker] SET CLASS TRACKER GROUP UNITS",
    GET_FORECAST_CLASS_TRACKER_GROUP_VALUES = "[tracker] GET FORECAST CLASS TRACKER GROUP VALUES",
    GET_FORECAST_CLASS_TRACKER_GROUP_VALUES_SUCCESS = "[tracker] GET FORECAST CLASS TRACKER GROUP VALUES SUCCESS",
    LOAD_FORECAST_CLASS_TRACKER_GROUP_CHECK = "[tracker] LOAD FORECAST CLASS TRACKER GROUP CHECK",
    LOAD_FORECAST_CLASS_TRACKER_GROUP_CHECK_SUCCESS = "[tracker] LOAD FORECAST CLASS TRACKER GROUP CHECK SUCCESS",
    LOAD_FORECAST_CLASS_TRACKER_GROUP_FORCE = "[tracker] LOAD FORECAST CLASS TRACKER GROUP FORCE",
    LOAD_FORECAST_CLASS_TRACKER_GROUP_FORCE_SUCCESS = "[tracker] LOAD FORECAST CLASS TRACKER GROUP FORCE SUCCESS",
    CLEAR_FORECAST_CLASS_TRACKER_GROUP_VALUES = "[tracker] CLEAR FORECAST CLASS TRACKER GROUP FORCE",
    CLEAR_FORECAST_CLASS_TRACKER_GROUP_VALUES_SUCCESS = "[tracker] CLEAR FORECAST CLASS TRACKER GROUP FORCE SUCCESS",
    GET_FORECAST_CLASS_TRACKER_VALUES = "[tracker] GET FORECAST CLASS TRACKER VALUES",
    GET_FORECAST_CLASS_TRACKER_VALUES_SUCCESS = "[tracker] GET FORECAST CLASS TRACKER VALUES SUCCESS",
    LOAD_FORECAST_CLASS_TRACKER_CHECK = "[tracker] LOAD FORECAST CLASS TRACKER CHECK",
    LOAD_FORECAST_CLASS_TRACKER_CHECK_SUCCESS = "[tracker] LOAD FORECAST CLASS TRACKER CHECK SUCCESS",
    LOAD_FORECAST_CLASS_TRACKER_FORCE = "[tracker] LOAD FORECAST CLASS TRACKER FORCE",
    LOAD_FORECAST_CLASS_TRACKER_FORCE_SUCCESS = "[tracker] LOAD FORECAST CLASS TRACKER FORCE SUCCESS",
    CLEAR_FORECAST_CLASS_TRACKER_VALUES = "[tracker] CLEAR FORECAST CLASS TRACKER FORCE",
    CLEAR_FORECAST_CLASS_TRACKER_VALUES_SUCCESS = "[tracker] CLEAR FORECAST CLASS TRACKER FORCE SUCCESS",
    SET_LOAD_FORECAST_OVERLAP_UNITS = "[tracker] SET LOAD FORECAST OVERLAP UNITS",
    GET_STUDENT_FORECAST_REPORT = "[tracker] GET STUDENT FORECAST REPORT",
    GET_STUDENT_FORECAST_REPORT_SUCCESS = "[tracker] GET STUDENT FORECAST REPORT SUCCESS",
}

export const ForecastApiEndpoints = {
    getForecastClassTrackerGroupUnits: createActionObject(
        getClassTrackerGroupForecastUnitListEndpoint,
        ForecastActionsTypes.GET_FORECAST_CLASS_TRACKER_GROUP_UNITS_SUCCESS,
    ),
    getForecastClassTrackerGroupValues: createActionObject(
        getClassTrackerGroupForecastValuesEndpoint,
        ForecastActionsTypes.GET_FORECAST_CLASS_TRACKER_GROUP_VALUES_SUCCESS,
    ),
    loadForecastClassTrackerGroupCheck: createActionObject(
        loadClassTrackerGroupValuesCheckEndpoint,
        ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_GROUP_CHECK_SUCCESS,
    ),
    loadForecastClassTrackerGroupForce: createActionObject(
        loadClassTrackerGroupValuesForceEndpoint,
        ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_GROUP_FORCE_SUCCESS,
    ),
    clearForecastClassTrackerGroupValues: createActionObject(
        clearClassTrackerGroupValuesEndpoint,
        ForecastActionsTypes.CLEAR_FORECAST_CLASS_TRACKER_GROUP_VALUES_SUCCESS,
    ),
    getForecastClassTrackerValues: createActionObject(
        getClassTrackerForecastValuesEndpoint,
        ForecastActionsTypes.GET_FORECAST_CLASS_TRACKER_VALUES_SUCCESS,
    ),
    loadForecastClassTrackerCheck: createActionObject(
        loadClassTrackerValuesCheckEndpoint,
        ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_CHECK_SUCCESS,
    ),
    loadForecastClassTrackerForce: createActionObject(
        loadClassTrackerValuesForceEndpoint,
        ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_FORCE_SUCCESS,
    ),
    clearForecastClassTrackerValues: createActionObject(
        clearClassTrackerValuesEndpoint,
        ForecastActionsTypes.CLEAR_FORECAST_CLASS_TRACKER_VALUES_SUCCESS,
    ),
    getStudentForecastReport: createActionObject(
        getStudentForecastReportDownloadEndpoint,
        ForecastActionsTypes.GET_STUDENT_FORECAST_REPORT_SUCCESS,
    ),
};

export const ForecastRawActions = (
    ForecastEndpoints: Record<Keys<typeof ForecastApiEndpoints>, ApiActionMeta>,
) => ({
    getForecastClassTrackerGroupUnits: (classTrackerGroup: number, tier: TiersTypes) =>
        createApiAction(
            ForecastActionsTypes.GET_FORECAST_CLASS_TRACKER_GROUP_UNITS,
            { params: { classTrackerGroup, tier }, values: {} },
            ForecastEndpoints.getForecastClassTrackerGroupUnits,
        ),
    getForecastClassTrackerGroupUnitsSuccess: (payload: ApiCallbackPayload<ForecastUnitList[]>) =>
        createAction(ForecastActionsTypes.GET_FORECAST_CLASS_TRACKER_GROUP_UNITS_SUCCESS, payload),
    setClassTrackerGroupUnits: (payload: ForecastUnitList[]) =>
        createAction(ForecastActionsTypes.SET_CLASS_TRACKER_GROUP_UNITS, payload),
    setLoadForecastOverlapUnits: (payload: ForecastUnitList) =>
        createAction(ForecastActionsTypes.SET_LOAD_FORECAST_OVERLAP_UNITS, payload),
    getForecastClassTrackerGroupValues: (classTrackerGroup: number, tier: TiersTypes) =>
        createApiAction(
            ForecastActionsTypes.GET_FORECAST_CLASS_TRACKER_GROUP_VALUES,
            { params: { classTrackerGroup, tier }, values: {} },
            ForecastEndpoints.getForecastClassTrackerGroupValues,
        ),
    getForecastClassTrackerGroupValuesSuccess: (
        payload: ApiCallbackPayload<ForecastClassTrackerValuesObject>,
    ) =>
        createAction(ForecastActionsTypes.GET_FORECAST_CLASS_TRACKER_GROUP_VALUES_SUCCESS, payload),
    loadForecastClassTrackerGroupCheck: (
        classTrackerGroup: number,
        dataSource: string,
        tier: TiersTypes,
    ) =>
        createApiAction(
            ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_GROUP_CHECK,
            { values: {}, params: { classTrackerGroup, dataSource, tier } },
            ForecastEndpoints.loadForecastClassTrackerGroupCheck,
        ),
    loadForecastClassTrackerGroupCheckSuccess: (
        payload: ApiCallbackPayload<void | ForecastUnitList>,
    ) =>
        createAction(ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_GROUP_CHECK_SUCCESS, payload),
    loadForecastClassTrackerGroupForce: (
        classTrackerGroup: number,
        dataSource: string,
        tier: TiersTypes,
    ) =>
        createApiAction(
            ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_GROUP_FORCE,
            { values: {}, params: { classTrackerGroup, dataSource, tier } },
            ForecastEndpoints.loadForecastClassTrackerGroupForce,
        ),
    loadForecastClassTrackerGroupForceSuccess: () =>
        createAction(ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_GROUP_FORCE_SUCCESS),
    clearForecastClassTrackerGroupValues: (
        classTrackerGroup: number,
        dataSource: string,
        tier: TiersTypes,
    ) =>
        createApiAction(
            ForecastActionsTypes.CLEAR_FORECAST_CLASS_TRACKER_GROUP_VALUES,
            { values: {}, params: { classTrackerGroup, dataSource, tier } },
            ForecastEndpoints.clearForecastClassTrackerGroupValues,
        ),
    clearForecastClassTrackerGroupValuesSuccess: (
        payload: ApiCallbackPayload<ForecastClassTrackerValuesObject>,
    ) =>
        createAction(
            ForecastActionsTypes.CLEAR_FORECAST_CLASS_TRACKER_GROUP_VALUES_SUCCESS,
            payload,
        ),
    getForecastClassTrackerValues: (classTracker: number, tier: TiersTypes) =>
        createApiAction(
            ForecastActionsTypes.GET_FORECAST_CLASS_TRACKER_VALUES,
            { values: {}, params: { classTracker, tier } },
            ForecastEndpoints.getForecastClassTrackerValues,
        ),
    getForecastClassTrackerValuesSuccess: (
        payload: ApiCallbackPayload<ForecastClassTrackerValuesObject>,
    ) => createAction(ForecastActionsTypes.GET_FORECAST_CLASS_TRACKER_VALUES_SUCCESS, payload),
    loadForecastClassTrackerCheck: (classTracker: number, dataSource: string, tier: TiersTypes) =>
        createApiAction(
            ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_CHECK,
            { values: {}, params: { classTracker, dataSource, tier } },
            ForecastEndpoints.loadForecastClassTrackerCheck,
        ),
    loadForecastClassTrackerCheckSuccess: (payload: ApiCallbackPayload<void | ForecastUnitList>) =>
        createAction(ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_CHECK_SUCCESS, payload),
    loadForecastClassTrackerForce: (classTracker: number, dataSource: string, tier: TiersTypes) =>
        createApiAction(
            ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_FORCE,
            { values: {}, params: { classTracker, dataSource, tier } },
            ForecastEndpoints.loadForecastClassTrackerForce,
        ),
    loadForecastClassTrackerForceSuccess: () =>
        createAction(ForecastActionsTypes.LOAD_FORECAST_CLASS_TRACKER_FORCE_SUCCESS),
    clearForecastClassTrackerValues: (classTracker: number, dataSource: string, tier: TiersTypes) =>
        createApiAction(
            ForecastActionsTypes.CLEAR_FORECAST_CLASS_TRACKER_VALUES,
            { values: {}, params: { classTracker, dataSource, tier } },
            ForecastEndpoints.clearForecastClassTrackerValues,
        ),
    clearForecastClassTrackerValuesSuccess: (
        payload: ApiCallbackPayload<ForecastClassTrackerValuesObject>,
    ) => createAction(ForecastActionsTypes.CLEAR_FORECAST_CLASS_TRACKER_VALUES_SUCCESS, payload),
    getStudentForecastReportDownload: (classTracker: number, id: number, fileName: string) =>
        createApiAction(
            ForecastActionsTypes.GET_STUDENT_FORECAST_REPORT,
            { values: { fileName }, params: { classTracker, id } },
            ForecastEndpoints.getStudentForecastReport,
        ),
    getStudentForecastReportDownloadSuccess: (payload: ApiCallbackPayload<Blob>) =>
        createAction(ForecastActionsTypes.GET_STUDENT_FORECAST_REPORT_SUCCESS, payload),
});
