import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { StudentModel } from "src/modules/student/model/StudentModel";
import { PagedList, Pagination } from "src/types";
import { getSchoolAccountId } from "src/services/url";

export interface StudentListRequestFilters {
    name?: string;
    uniquePupilNumber?: string;
    parentName?: string;
    parentUserIdentifier?: string;
    parentGroupCallStatus?: string;
    onlyWithoutDateOfBirth?: boolean;
    userIdentifier?: string;
    status?: "added" | "invited" | "confirmed" | "deactivated";
    groupCallStatus?: string;
    yearGroup?: number;
    form?: string;
    tagId?: number;
}

export const STUDENT_LIST_QUERY = "studentListQuery";

export const useStudentList = (pagination: Pagination, filters: StudentListRequestFilters) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [STUDENT_LIST_QUERY, schoolAccountId, pagination, filters],
        queryFn: async (): Promise<PagedList<StudentModel> | null> => {
            const { data } = await axiosInstance.get(`school/${getSchoolAccountId()}/student/`, {
                params: { ...pagination, ...filters },
            });

            return data || null;
        },
    });
};
