import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ClassTrackerValuesObject } from "src/modules/tracker/dto/TrackerValues";
import { getSchoolAccountId } from "src/services/url";

export const ARCHIVED_TRACKER_GROUP_VALUES = "archivedTrackerGroupValues";

export const useArchivedTrackerGroupValues = (id: number, tier: string) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [ARCHIVED_TRACKER_GROUP_VALUES, schoolAccountId, id, tier],
        queryFn: async (): Promise<ClassTrackerValuesObject | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/archive/class-tracker-group/${id}/${tier}/values/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
