import {
    ROUTE_REPORT_LINKS_ADD,
    ROUTE_REPORT_LINKS_EDIT,
    ROUTE_REPORT_LINKS_LIST,
} from "src/routes";
import LinksListContainer from "../containers/List";
import LinkUpsertContainer from "../containers/Upsert";

export const LinkRoutes = [
    {
        path: ROUTE_REPORT_LINKS_LIST,
        element: <LinksListContainer />,
    },
    {
        path: ROUTE_REPORT_LINKS_EDIT,
        element: <LinkUpsertContainer />,
    },
    {
        path: ROUTE_REPORT_LINKS_ADD,
        element: <LinkUpsertContainer />,
    },
];
