/* eslint-disable no-constant-condition */
import { Box } from "@mui/material";
import { CellKeyDownEvent, ColumnApi, ColumnState, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { TFunction } from "i18next";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { apiUrl } from "src/config/globals";
import useDebounce from "src/hooks/useDebounce";
import { usePrevious } from "src/hooks/usePrevious";
import { baseHeaders, post } from "src/services/ajax";
import {
    AnalysisKS4GradeValuesFlatObject,
    KS4ViewGrades,
    KS4ViewTypes,
} from "../../api/KS4Grade/getValues";
import { useAnalysisKS4GradeData } from "../../hooks/useAnalysisKS4GradeData";
import { generateAnalysisKS4AverageRow } from "../../services/analysisAverageRow";
import { exportAnalysisKS4ToExcel } from "../../services/analysisDataExport";
import { analysisFrameworkComponentsList } from "../../services/analysisGridConfig";
import { navigateToNextCell } from "../../services/analysisGridNavigation";
import {
    generateHeader,
    calculateKS4AnalysisHeaderHeight,
    ANALYSIS_INITIAL_STUDENT_PARAMS,
    generateExcelHeader,
    AnalysisHeaderNodes,
} from "../../services/analysisHeaderGenerator";
import {
    AnalysisCellState,
    AnalysisTrackerValueType,
    generateInitialCellStates,
    prepareDataForBulkOrMultipleUpdate,
    prepareDataToSend,
} from "../../services/analysisValueHandler";
import { getGridBorderColor } from "../../services/analysisColourHandler";
import DotsProgress from "src/components/DotsProgress";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { useAnalysisSnapshots } from "../../hooks/useAnalysisSnapshots";
import AnalysisToolsPanel from "./ToolsPanel/AnalysisToolsPanel";
import AnalysisFilterPanel from "./ToolsPanel/AnalysisFilterPanel";
import { filterRowFromFilters } from "src/modules/common/services/valueHandler";
import { useLocation } from "react-router";
import { CELL_SIZE_MIN } from "src/modules/tracker/services/headerGenerator";
import { sortByTwoParams } from "src/services/array";
import { useAnalysisTagList } from "src/modules/tagging/hooks/Analysis/useAnalysisTagList";
import { useAnalysisStudentTags } from "src/modules/tagging/hooks/Analysis/useAnalysisStudentTags";
import {
    AnalysisStudentTagRows,
    useAnalysisStudentTagMutation,
} from "src/modules/tagging/hooks/Analysis/useAnalysisStudentTagMutation";
import { TagsToSendType } from "src/modules/tracker/services/valueHandler";
import PromptDialog from "src/forms/PromptDialog";
import { sortTagsColumnState } from "src/modules/tracker/components/Grid/TrackerGrid";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getSchoolAccountId } from "src/services/url";
import { useAnalysisUserSettingsStore } from "../../hooks/useAnalysisUserSettingsStore";

export const KS4_GRADE_VIEW_TYPE = "ks4-grade";

export interface UpdateRowResponse {
    data: { [key: string]: any };
    errors: { [key: string]: any }[];
}

const KS4GradeAnalysisGrid = ({
    cohort,
    yearGroup,
    viewGrade,
    viewType,
    dataType,
    filtersVisible,
    toolsVisible,
    handleIsLoading,
}: {
    cohort: number;
    yearGroup: number;
    viewGrade: KS4ViewGrades;
    viewType: KS4ViewTypes;
    dataType: DataTypeTypes;
    filtersVisible?: boolean;
    toolsVisible?: boolean;
    handleIsLoading?: (isLoading: boolean) => void;
}) => {
    const { t }: { t: TFunction } = useTranslation();
    const [values, setValues] = useState<AnalysisKS4GradeValuesFlatObject[] | null>(null);
    const [header, setHeader] = useState<any[] | null>(null);
    const [excelHeader, setExcelHeader] = useState<any[] | null>(null);
    const [filteredValues, setFilteredValues] = useState<any[] | undefined>(undefined);
    const [flatHeader, setFlatHeader] = useState<any[] | null>(null);
    const [averageRow, setAverageRow] = useState<AnalysisKS4GradeValuesFlatObject | null>(null);
    const { data: userProfile } = useProfile();
    const gridApi = useRef() as { current: GridApi };
    const columnApi = useRef() as { current: ColumnApi };
    const excelGridApi = useRef() as { current: GridApi };
    const initialColumnOrder = useRef<ColumnState[]>();
    const [cellStates, setCellStates] = useState<{ [key: string]: AnalysisCellState }[] | null>(
        null,
    );
    const [collapsedColumns, setCollapsedColumns] = useState<{ [key: string]: boolean }>({});
    const [gridFilters, setGridFilters] = useState<any>(null);
    const [tagsToSend, setTagsToSend] = useState<TagsToSendType[]>([]);
    const debouncedTagsToSend = useDebounce(tagsToSend, 2000);
    const [allTagsWaiting, setAllTagsWaiting] = useState<{
        colId: string;
        value: any;
        type: "tag" | "link";
    } | null>(null);
    const debouncedCellStates = useDebounce(cellStates, 1000);
    const subscriber = useRef(null) as any;
    const [isExcelExportMode, setIsExcelExportMode] = useState<boolean>(false);
    const [isReloading, setIsReloading] = useState<boolean>(false);
    const { navigate } = useSchoolNavigate();

    const location = useLocation();
    const {
        analysisKS4GradeHeader,
        analysisKS4GradeValues,
        analysisRelated,
        analysisKS4GradeCompareValues,
        getAnalysisData,
        getAnalysisForecastData,
        clearAnalysisData,
        isDataFetched,
        isLoading,
        analysisStudents,
        setIsLoading,
        refetchValues,
        getSnapshotCompareData,
        getSnapshotData,
    } = useAnalysisKS4GradeData([]);

    const { setAnalysisUserSettings, ...analysisUserSettings } = useAnalysisUserSettingsStore();

    const { snapshotData, setSnapshotData } = useAnalysisSnapshots(cohort);

    const { data: tags, isSuccess: isTagsSuccess } = useAnalysisTagList(cohort, yearGroup);
    const { data: studentTags, refetch: refetchStudentTags } = useAnalysisStudentTags(
        cohort,
        yearGroup,
    );

    useEffect(() => {
        if (studentTags) {
            gridApi?.current?.refreshCells({ columns: ["tagAvg", "tagAllSubjects"], force: true });
        }
    }, [studentTags]);

    const { mutate: saveTags } = useAnalysisStudentTagMutation(() => refetchStudentTags());

    const applayTagsOrder = tagsOrder => {
        if (tagsOrder) {
            const columnState: { colId: string }[] =
                initialColumnOrder.current || columnApi.current.getColumnState() || [];

            columnApi.current.applyColumnState({
                state: sortTagsColumnState(columnState, tagsOrder),
                applyOrder: true,
            });
        }
    };

    // on ready
    const onGridReady = params => {
        gridApi.current = params.api;
        columnApi.current = params.columnApi;
        initialColumnOrder.current = params.columnApi.getColumnState() || [];
        applayTagsOrder(analysisUserSettings?.tagsOrder);
    };
    const handleOnColumnMoved = params => {
        if (params.finished && params.column) {
            const columnState = params.columnApi.getColumnState();
            const tagsOrder = columnState
                .filter(({ colId }) => colId.indexOf("tag-") === 0)
                .map(({ colId }) => colId);
            setAnalysisUserSettings({ ...analysisUserSettings, tagsOrder });
            applayTagsOrder(tagsOrder);
        }
    };
    const handleOnColumnVisible = params => {
        initialColumnOrder.current = params.columnApi.getColumnState() || [];
    };

    // on second ready
    const onExcelGridReady = params => {
        excelGridApi.current = params.api;

        if (isExcelExportMode) {
            exportAnalysisKS4ToExcel(excelGridApi.current);
            excelGridApi.current.destroy();
            setIsExcelExportMode(false);
        }
    };

    // handle change fields
    const handleAfterValueChanged = (
        oldValue: AnalysisTrackerValueType,
        newValue: AnalysisTrackerValueType,
        field: string,
        rowIndex: number,
    ) => {
        if (
            (`${oldValue}` !== `${newValue}` ||
                `${oldValue}`.toUpperCase() !== `${newValue}`.toUpperCase()) &&
            cellStates
        ) {
            if (subscriber && subscriber.current) subscriber.current.unsubscribe();
            setCellStates(prevState => {
                if (prevState) {
                    const newCellStates = [...prevState];

                    if (field && newCellStates[rowIndex] && newCellStates[rowIndex][field]) {
                        newCellStates[rowIndex][field].whileUpdate = true;
                        newCellStates[rowIndex][field].currentValue =
                            newValue === null ? null : `${newValue}`;
                    }

                    return newCellStates;
                }
                return prevState;
            });
        }
    };

    // handle value change
    const handleValueChange = e => {
        const oldValue = e.oldValue === null ? "" : e.oldValue;
        const colId = e.column.getColId();
        const newValue = e.newValue?.whileUpdate ? e.newValue?.value : e.newValue;
        if (oldValue !== newValue && e.data.row && colId) {
            if (colId.indexOf("tag-") === 0) {
                setTagsToSend(prevState =>
                    prevState.concat({ row: e.data.row, column: colId, value: newValue }),
                );
            }
        }
        handleAfterValueChanged(oldValue, e.newValue, e.colDef.field, e.rowIndex);
    };

    const prevDataType = usePrevious(dataType);

    const getRowIndexByRow = useCallback(
        rowKey => {
            if (values) {
                const index = values.findIndex(ds => ds && ds.row === rowKey);
                return index > -1 ? index : null;
            }
            return null;
        },
        [values],
    );

    // handle multiple api update
    const handleMultipleUpdateResponse = (response: UpdateRowResponse) => {
        if (cellStates && values) {
            setCellStates(prevState => {
                if (prevState) {
                    const newCellStates = [...prevState];
                    Object.keys(response.data).forEach(rowKey => {
                        const rowIndex = getRowIndexByRow(rowKey);
                        const rowError = response.errors.find(e => e.row === rowKey);

                        if (rowIndex !== null && rowIndex > -1) {
                            Object.keys(response.data[rowKey]).forEach(columnKey => {
                                const newCellState: AnalysisCellState = {
                                    errors: rowError?.column === columnKey,
                                    whileUpdate: false,
                                    lastUpdatedAt: new Date().toISOString(),
                                    prevValue: response.data[rowKey][columnKey],
                                    currentValue:
                                        rowError && rowError.column === columnKey
                                            ? rowError.value
                                            : response.data[rowKey][columnKey],
                                    readOnly: cellStates[rowIndex][columnKey].readOnly,
                                };
                                newCellStates[rowIndex][columnKey] = newCellState;
                            });
                        }
                    });
                    return newCellStates;
                }
                return prevState;
            });

            const prepareData = prevDataSet => {
                if (prevDataSet) {
                    const newDataSet: { [key: string]: AnalysisTrackerValueType }[] = [
                        ...prevDataSet,
                    ];
                    Object.keys(response.data).forEach(rowKey => {
                        const rowIndex = getRowIndexByRow(rowKey);
                        const row = response.data[rowKey];
                        const rowError = response.errors.find(e => e.row === rowKey);
                        if (rowIndex !== null && rowIndex > -1) {
                            newDataSet[rowIndex] = { ...newDataSet[rowIndex], ...row };
                            if (rowError) {
                                newDataSet[rowIndex][rowError.column] = rowError.value;
                            }
                        }
                    });

                    return newDataSet;
                }
                return prevDataSet;
            };

            setValues(prevDataSet => prepareData(prevDataSet));
            setFilteredValues(prevDataSet => prepareData(prevDataSet));

            const rowsToRefresh: any[] = [];
            Object.keys(response.data).forEach(rowKey => {
                const rowIndex = getRowIndexByRow(rowKey);
                if (rowIndex !== null && rowIndex > -1) {
                    const rowNode = gridApi.current.getDisplayedRowAtIndex(rowIndex);
                    rowsToRefresh.push(rowNode);
                }
            });

            const currentlyEditing = gridApi.current.getEditingCells();
            gridApi.current.redrawRows({ rowNodes: rowsToRefresh });

            if (currentlyEditing && currentlyEditing.length > 0) {
                setTimeout(() => {
                    gridApi.current.startEditingCell({
                        rowIndex: currentlyEditing[0].rowIndex,
                        colKey: currentlyEditing[0].column,
                    });
                }, 1);
            }
        }
    };

    // handle bulk edit data save
    const handleTrackerMultipleRowDataUpdate = (dataToSend: {
        [key: string]: { [key: string]: string | number };
    }) => {
        const url = `school/${getSchoolAccountId()}/analysis/ks4-grade/${cohort}/${yearGroup}/forecast/a8/bulk-edit/`;

        subscriber.current = post(apiUrl(url), { ...dataToSend }, baseHeaders()).subscribe(res => {
            handleMultipleUpdateResponse(res.response);
        });
    };

    // handle expand groups
    const handleGroupOpen = (field: string, expanded: boolean) => {
        if (analysisUserSettings) {
            setCollapsedColumns(prevCollapsed => {
                // let newCollapsed = prevCollapsed;
                if (analysisUserSettings.collapsedColumns) {
                    return {
                        ...analysisUserSettings.collapsedColumns,
                        ...prevCollapsed,
                        [field]: expanded,
                    };
                } else {
                    return {
                        ...prevCollapsed,
                        [field]: expanded,
                    };
                }
            });
        }
    };

    const deboundedCollapsed = useDebounce(collapsedColumns, 500);

    const handleFiltersChange = filters => {
        setGridFilters(filters);
    };

    useEffect(() => {
        // grid filters without "student_"
        const filteredNew = values?.filter(initialValues => {
            return filterRowFromFilters(gridFilters || {}, initialValues);
        });

        if (!filteredNew) {
            setFilteredValues([]);
        } else {
            setFilteredValues(filteredNew);
        }
    }, [gridFilters, values]);

    useEffect(() => {
        if (analysisUserSettings?.filters && location) {
            navigate({
                pathname: location.pathname,
                search: location.search + "&" + analysisUserSettings.filters,
            });
        }
    }, [analysisUserSettings?.filters]);

    const handleTagsLinksAllColumnSelect = (colId, value) => {
        setAllTagsWaiting({ colId, value, type: "tag" });
    };

    // main context
    const analysisContext = {
        columnConfig: analysisKS4GradeHeader?.columns,
        studentColumns: analysisStudents || ANALYSIS_INITIAL_STUDENT_PARAMS,
        relatedData: analysisRelated,
        userSettings: analysisUserSettings,
        cohort,
        yearGroup,
        viewGrade,
        dataType,
        snapshotData,
        viewType: KS4_GRADE_VIEW_TYPE,
        compareValues: analysisKS4GradeCompareValues?.values2 || null,
        a8: viewType === KS4ViewTypes.A8,
        analysisType: "ks4",
        flatHeader,
        handleGroupOpen,
        handleExportToExcel: () => setIsExcelExportMode(true),
        handleFiltersChange,
        cellStates,
        tagsVisible: analysisUserSettings?.tagsVisible,
        tags,
        handleTagsLinksAllColumnSelect,
        userProfile,
        averageRow,
        summaryColumns:
            analysisKS4GradeHeader?.header?.find(
                ({ nodeType }) => nodeType === AnalysisHeaderNodes.SUMMARY,
            )?.children || [],
    };
    useEffect(() => {
        if (handleIsLoading) handleIsLoading(isLoading);
    }, [isLoading, handleIsLoading]);

    useEffect(() => {
        if (!isLoading) {
            setIsReloading(true);
            refetchValues(cohort, yearGroup, viewGrade, viewType, []);
        }
    }, []);

    // toggle grid filters & tools
    useEffect(() => {
        if (filtersVisible && gridApi) {
            gridApi.current?.openToolPanel("studentFilterPanel");
        }
        if (toolsVisible && gridApi) {
            gridApi.current?.openToolPanel("toolsPanel");
        }
        if (!toolsVisible && !filtersVisible && gridApi) {
            gridApi.current?.closeToolPanel();
        }
    }, [filtersVisible, toolsVisible, gridApi]);

    // get snapshot data
    useEffect(() => {
        // clear when new data type
        if (prevDataType && prevDataType !== dataType) {
            clearAnalysisData();
            setValues(null);
            setHeader(null);
            setExcelHeader(null);
            setFlatHeader(null);
            setAverageRow(null);
            setIsLoading(true);
            setSnapshotData(null);
        }
        if (dataType === DataTypeTypes.SNAPSHOT) {
            setIsLoading(true);
            setIsReloading(true);
            clearAnalysisData();
            if (snapshotData?.snapshot && getSnapshotData) {
                getSnapshotData(
                    cohort,
                    yearGroup,
                    viewGrade,
                    viewType,
                    parseInt(`${snapshotData?.snapshot}`),
                    [],
                );
            }
            if (snapshotData?.compare && getSnapshotCompareData) {
                getSnapshotCompareData(
                    cohort,
                    yearGroup,
                    viewGrade,
                    viewType,
                    snapshotData.compare.dataSource1,
                    snapshotData.compare.dataSource2,
                    [],
                );
            }
        } else {
            if (cohort && yearGroup > -1 && viewGrade && viewType) {
                clearAnalysisData();
                setIsLoading(true);
                setHeader(null);
                setExcelHeader(null);
                setValues(null);
                switch (dataType) {
                    case DataTypeTypes.FORECAST:
                        getAnalysisForecastData(cohort, yearGroup, viewType);
                        break;
                    default:
                        getAnalysisData(cohort, yearGroup, viewGrade, viewType);
                        break;
                }
            }
        }
    }, [snapshotData, dataType, yearGroup, viewGrade, viewType, cohort]);

    // remove loader on snapshot change
    useEffect(() => {
        if (isLoading && dataType === DataTypeTypes.SNAPSHOT) {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }, [analysisKS4GradeValues]);
    const prevTags = usePrevious(tags);
    // set data when fetched
    useEffect(() => {
        if (!isLoading && isDataFetched && isTagsSuccess) {
            //generate header
            if (
                analysisKS4GradeHeader &&
                (!header || JSON.stringify(prevTags) !== JSON.stringify(tags))
            ) {
                const flatHeader = [];
                setHeader(
                    generateHeader(
                        analysisKS4GradeHeader,
                        t,
                        flatHeader,
                        dataType,
                        analysisUserSettings,
                        tags,
                        dataType === DataTypeTypes.FORECAST && viewType === KS4ViewTypes.P8,
                    ),
                );
                setExcelHeader(
                    generateExcelHeader(analysisKS4GradeHeader, t, dataType, analysisUserSettings),
                );
                if (flatHeader && flatHeader.length > 0) {
                    setFlatHeader(flatHeader);
                }
            }
            //generate values
            let tmpValues = [] as AnalysisKS4GradeValuesFlatObject[];
            const typedValues =
                dataType === DataTypeTypes.SNAPSHOT && snapshotData?.compare
                    ? analysisKS4GradeCompareValues?.values1
                    : analysisKS4GradeValues;

            if (typedValues) {
                Object.keys(typedValues).forEach(key => {
                    if (typedValues) tmpValues.push({ ...typedValues[key], row: key });
                });

                tmpValues = sortByTwoParams(
                    [...tmpValues],
                    "student_lastName",
                    "student_firstName",
                );

                setValues(tmpValues);
                setFilteredValues(tmpValues);
            }

            // generate cell state object
            const initialCellStates = generateInitialCellStates(
                tmpValues,
                analysisKS4GradeHeader?.columns,
            );

            setCellStates(initialCellStates);

            //generate average
            setAverageRow(generateAnalysisKS4AverageRow(tmpValues, analysisKS4GradeHeader, t));

            if (dataType === DataTypeTypes.SNAPSHOT && analysisKS4GradeCompareValues?.values1) {
                setIsReloading(false);
            }
        }
    }, [
        tags,
        isLoading,
        isTagsSuccess,
        isReloading,
        analysisKS4GradeHeader,
        analysisKS4GradeValues,
        analysisKS4GradeCompareValues,
        dataType,
    ]);

    // sort values
    useEffect(() => {
        let tmpValues = [] as AnalysisKS4GradeValuesFlatObject[];
        const typedValues =
            dataType === DataTypeTypes.SNAPSHOT && snapshotData?.compare
                ? analysisKS4GradeCompareValues?.values1
                : analysisKS4GradeValues;
        if (typedValues && Object.keys(typedValues).length > 0) {
            Object.keys(typedValues).forEach(key => {
                if (typedValues) tmpValues.push({ ...typedValues[key], row: key });
            });
            tmpValues = sortByTwoParams([...tmpValues], "student_lastName", "student_firstName");
        }

        if (tags && tags.length > 0) {
            Object.keys(tmpValues).forEach(ctvKey => {
                tags.forEach(tag => {
                    tmpValues[ctvKey]["tag-" + tag.id] = 0;
                });
            });
        }

        if (studentTags && Object.keys(studentTags).length > 0) {
            Object.keys(studentTags).forEach(stKey => {
                studentTags[stKey].forEach(tagId => {
                    const studentIndex = tmpValues.findIndex(tv => tv.row === stKey);
                    if (
                        tmpValues[studentIndex] !== undefined &&
                        tmpValues[studentIndex]["tag-" + tagId] !== undefined
                    ) {
                        tmpValues[studentIndex]["tag-" + tagId] = 1;
                    }
                });
            });
        }

        setValues(tmpValues);
        setFilteredValues(tmpValues);
        setIsReloading(false);
    }, [analysisKS4GradeValues, analysisKS4GradeCompareValues, tags, studentTags]);

    // collapse columns
    useEffect(() => {
        if (deboundedCollapsed && Object.keys(deboundedCollapsed).length > 0) {
            // const newUserSettings = { ...analysisUserSettings, collapsedColumns: undefined }; // RESET COLLAPSABLE
            const newUserSettings = { ...analysisUserSettings, collapsedColumns };
            setAnalysisUserSettings(newUserSettings);
        }
    }, [deboundedCollapsed]);

    useEffect(() => {
        if (debouncedTagsToSend?.length > 0) {
            const rows: AnalysisStudentTagRows = {};
            debouncedTagsToSend.forEach((ddts: TagsToSendType) => {
                rows[ddts.row] = [
                    ...(rows[ddts.row] || []).concat({
                        tagId: parseInt(ddts.column.replace("tag-", "")),
                        enabled: ddts.value.value !== undefined ? !!ddts.value.value : !!ddts.value,
                    }),
                ];
            });

            saveTags({ cohort, yearGroup, rows });
        }
    }, [debouncedTagsToSend]);

    useEffect(() => {
        if (header) {
            if (analysisUserSettings?.tagsVisible !== null && analysisUserSettings?.selectedTags) {
                columnApi?.current?.setColumnsVisible(
                    analysisUserSettings?.selectedTags?.map(id => `tag-${id}`),
                    analysisUserSettings?.tagsVisible,
                );
                gridApi?.current?.refreshHeader();
            }
        }
    }, [
        header,
        analysisUserSettings?.tagsVisible,
        analysisUserSettings?.selectedTags,
        gridApi?.current,
        columnApi?.current,
        isTagsSuccess,
        averageRow,
    ]);

    // handle values change + send
    useEffect(() => {
        if (debouncedCellStates) {
            const { dataToSend, columnsToRefresh } = prepareDataToSend(
                cellStates || debouncedCellStates,
            );

            if (columnsToRefresh && columnsToRefresh.length > 0) {
                gridApi.current.refreshCells({ columns: columnsToRefresh, force: true });
            }
            const bulkEditData = prepareDataForBulkOrMultipleUpdate(dataToSend, values);
            if (bulkEditData && Object.keys(bulkEditData).length > 0) {
                handleTrackerMultipleRowDataUpdate(bulkEditData);
            }
            // prepareAndSaveData(debouncedCellStates);
        }
    }, [debouncedCellStates]);

    useEffect(() => {
        if (filteredValues && analysisKS4GradeHeader) {
            setAverageRow(generateAnalysisKS4AverageRow(filteredValues, analysisKS4GradeHeader, t));
        }
    }, [filteredValues, analysisKS4GradeHeader, debouncedCellStates]);

    const getRowId = useCallback(params => params.data.student_id, []);

    const components = useMemo(() => ({ ...analysisFrameworkComponentsList }), []);

    const sideBar = useMemo(
        () => ({
            toolPanels: [
                {
                    id: "studentFilterPanel",
                    labelKey: "studentFilterPanel",
                    labelDefault: "",
                    iconKey: "student-filter-panel",
                    toolPanel: AnalysisFilterPanel,
                },
                {
                    id: "toolsPanel",
                    labelKey: "toolsPanel",
                    labelDefault: "",
                    iconKey: "tools-panel",
                    toolPanel: AnalysisToolsPanel,
                },
            ],
        }),
        [],
    );
    return (
        <>
            {header &&
            values &&
            isDataFetched &&
            averageRow &&
            isLoading === false &&
            !isReloading ? (
                <Box
                    className="ag-theme-alpine ag-theme-alpine-analysis"
                    flex={1}
                    width="100%"
                    position="relative"
                    borderLeft={`10px solid ${getGridBorderColor(dataType)}`}
                >
                    <AgGridReact
                        columnDefs={header}
                        rowData={filteredValues}
                        onGridReady={onGridReady}
                        onColumnVisible={handleOnColumnVisible}
                        onColumnMoved={handleOnColumnMoved}
                        components={components}
                        sideBar={sideBar}
                        enableRangeSelection={true}
                        processCellForClipboard={params => {
                            if (params.value === -1) return "U";
                            return params.value;
                        }}
                        context={analysisContext}
                        getRowId={getRowId}
                        rowBuffer={15}
                        pinnedTopRowData={[averageRow]}
                        enableBrowserTooltips={true}
                        gridOptions={{
                            headerHeight: calculateKS4AnalysisHeaderHeight(analysisKS4GradeHeader),
                            groupHeaderHeight: 0,
                            onCellKeyDown: (event: CellKeyDownEvent) => {
                                navigateToNextCell(event, gridApi.current, columnApi.current);
                            },
                            onCellValueChanged: handleValueChange,
                            animateRows: false,
                            suppressColumnMoveAnimation: true,
                            suppressAutoSize: true,
                            suppressContextMenu: true,
                            autoSizePadding: 1,
                            rowHeight:
                                snapshotData?.compare &&
                                snapshotData?.compare.dataSource1 !==
                                    snapshotData?.compare.dataSource2
                                    ? 56
                                    : CELL_SIZE_MIN,
                        }}
                    />
                    {isExcelExportMode && averageRow && filteredValues && (
                        <AgGridReact
                            onGridReady={onExcelGridReady}
                            columnDefs={excelHeader as any}
                            rowData={[averageRow].concat(filteredValues)}
                            gridOptions={{ domLayout: "print" }}
                            containerStyle={{ display: "none" }}
                        />
                    )}
                    <PromptDialog
                        open={!!allTagsWaiting?.colId}
                        onClose={confirmed => {
                            if (confirmed) {
                                const newValues = [...filteredValues].map(fv => {
                                    setTagsToSend(prevState =>
                                        prevState.concat({
                                            row: fv.row,
                                            column: allTagsWaiting?.colId,
                                            value: allTagsWaiting?.value,
                                        }),
                                    );
                                    return {
                                        ...fv,
                                        [allTagsWaiting?.colId]: {
                                            value: allTagsWaiting?.value,
                                            whileUpdate: true,
                                        },
                                    };
                                });
                                setFilteredValues(newValues);
                            }

                            setAllTagsWaiting(null);
                        }}
                    >
                        {t("tracker.grid.allTagsWaiting")}
                    </PromptDialog>
                </Box>
            ) : (
                <Box
                    bgcolor="#f9f9f9"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flex={1}
                    top={80}
                    width="100%"
                    height="100%"
                    position="absolute"
                    zIndex={500}
                >
                    <DotsProgress />
                </Box>
            )}
        </>
    );
};

export default KS4GradeAnalysisGrid;
