import PaperInner from "src/components/PaperInner";
import HeadingCounter from "src/components/HeadingCounter";
import AttributesConfigList from "../../Common/AttributesConfigList";
import ClassDetailsForm from "../../SingleClass/forms/ClassDetailsForm";
import StudentsTable from "../../Common/StudentsTable";
import ErrorMessage from "src/forms/ErrorMessage";
import { useEffect, useState } from "react";
import { Form, FormikProps, Field } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, Select, MenuItem, Box } from "@mui/material";
import {
    AttributeValueExtended,
    AttributeItem,
    AttributeGroup,
    InitialAttribute,
} from "src/orm/models/SpecificationAttribute";
import { StudentModel } from "src/orm/models/Student";
import { AutocompleteField } from "src/forms/Autocomplete";
import { ListObject } from "src/forms/types";
import { TrackerPathItem } from "src/modules/class/hooks/useTrackerPath";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { getSchoolAccountId } from "src/services/url";

export interface EditClassFormFields {
    specification: number | null;
    name: string;
    attributes: AttributeValueExtended[];
    items: AttributeItem[];
    groups: AttributeGroup[];
    disabledItems: number[];
    yearGroup: number;
    cohort: number;
    chooseCohort: number;
    groupCallStatus?: string;
    chooseYearGroup: number;
    teachers: ListObject<number | undefined>[];
    students: StudentModel[];
    tmpStudentsAutocomplete: string;
    initialEditAttributes?: InitialAttribute[];
}

interface OwnProps {
    selectedSubjectArea: TrackerPathItem | null;
    selectedQualification: TrackerPathItem | null;
    selectedSpecification: TrackerPathItem | null;
    formProps: FormikProps<EditClassFormFields>;
    isSyncedClass?: boolean;
}

const EditClassForm = ({
    formProps,
    selectedSubjectArea,
    selectedQualification,
    selectedSpecification,
    isSyncedClass,
}: OwnProps) => {
    const { data: accountInfo } = useAccountInfo();
    const { t } = useTranslation();
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);

    const handleRemoveDialogOpen = (itemId: number): void => {
        setRemoveDialogOpened(true);
        setRemoveId(itemId);
    };

    const handleRemoveDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            const newStudents = formProps.values.students
                .map((s, index) => ({ ...s, index }))
                .filter((student: StudentModel & { index: number }) => student.id !== removeId);

            formProps.setFieldValue("students", newStudents);
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    useEffect(() => {
        if (formProps.values.tmpStudentsAutocomplete !== "") {
            formProps.setFieldValue("students", [
                ...formProps.values.students,
                formProps.values.tmpStudentsAutocomplete,
            ]);
            formProps.setFieldValue("tmpStudentsAutocomplete", "");
        }
    }, [formProps]);

    return (
        <Form>
            <PaperInner>
                <HeadingCounter number="1">
                    {t("class.editClass.chooseTrackerHeader")}
                </HeadingCounter>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        {selectedSubjectArea?.id && (
                            <Select
                                name="selectedSubjectArea"
                                disabled={true}
                                fullWidth
                                value={selectedSubjectArea?.id}
                            >
                                <MenuItem key={0} value={selectedSubjectArea?.id}>
                                    {selectedSubjectArea?.name}
                                </MenuItem>
                            </Select>
                        )}
                    </Grid>
                    <Grid item sm={4}>
                        {selectedQualification?.id && (
                            <Select
                                name="selectedQualification"
                                disabled={true}
                                fullWidth
                                value={selectedQualification?.id}
                            >
                                <MenuItem key={0} value={selectedQualification?.id}>
                                    {selectedQualification?.name}
                                </MenuItem>
                            </Select>
                        )}
                    </Grid>
                    <Grid item sm={4}>
                        {selectedSpecification?.id && (
                            <Select
                                name="specification"
                                disabled={true}
                                fullWidth
                                value={selectedSpecification?.id}
                            >
                                <MenuItem key={0} value={selectedSpecification?.id}>
                                    {selectedSpecification?.parentName
                                        ? selectedSpecification?.parentName +
                                          " - " +
                                          selectedSpecification?.name
                                        : selectedSpecification?.name}
                                </MenuItem>
                            </Select>
                        )}
                    </Grid>
                    {formProps.errors?.specification && <ErrorMessage name="specification" />}
                </Grid>
                {formProps.values.specification && (
                    <AttributesConfigList
                        attributes={formProps.values.attributes}
                        specificationId={formProps.values.specification}
                        items={formProps.values.items}
                        groups={formProps.values.groups}
                        disabledItems={formProps.values.disabledItems}
                        errors={formProps.errors}
                        setFieldValue={formProps.setFieldValue}
                        setFieldTouched={formProps.setFieldTouched}
                        initialAttributes={formProps.values.initialEditAttributes}
                    />
                )}
                {formProps.errors?.attributes && <ErrorMessage name="attributes" />}
            </PaperInner>
            <PaperInner border="top">
                <HeadingCounter number="2">{t("class.editClass.classDetails")}</HeadingCounter>
                {isSyncedClass &&
                formProps.values.groupCallStatus !== "manual" &&
                formProps.values.groupCallStatus !== "deleted" ? (
                    <Box pt={4} pb={4}>
                        {t("class.editClass.isSyncedClassHeader").replaceAll(
                            "MIS_Name",
                            accountInfo?.misName || "",
                        )}
                    </Box>
                ) : (
                    <ClassDetailsForm lockYearGroup={true} isSyncedClass={isSyncedClass} />
                )}
            </PaperInner>
            <PaperInner border="top">
                <HeadingCounter number="3">{t("class.editClass.chooseStudents")}</HeadingCounter>
                {!isSyncedClass ? (
                    <Grid container>
                        <Grid item sm={4}>
                            <Field
                                apiPathName={`school/${getSchoolAccountId()}/search/students`}
                                name="tmpStudentsAutocomplete"
                                placeholder={t("class.addClass.searchStudentAutoPlaceholder")}
                                label={t("class.addClass.searchStudentAutoPlaceholder")}
                                helperText={t(
                                    "class.addClass.searchStudentAutoPlaceholderHelperText",
                                )}
                                component={AutocompleteField}
                                multiple={false}
                                resultCallback={results => {
                                    return results.map(val => ({
                                        ...val,
                                        name: `${val.firstName} ${val.lastName}`,
                                        displayName: `${val.firstName} ${val.lastName}`,
                                    }));
                                }}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Box pt={4} pb={4}>
                        {t("class.editClass.isSyncedClassHeader").replaceAll(
                            "MIS_Name",
                            accountInfo?.misName || "",
                        )}
                    </Box>
                )}
                <StudentsTable
                    students={formProps.values.students}
                    isSyncedClass={isSyncedClass}
                    handleDelete={handleRemoveDialogOpen}
                />
                {formProps.errors?.students && typeof formProps.errors?.students === "string" ? (
                    <ErrorMessage name={`students`} />
                ) : (
                    formProps.errors?.students &&
                    Object.keys(formProps.errors?.students).map(key => {
                        return <ErrorMessage key={key} name={`students[${key}]`} />;
                    })
                )}
            </PaperInner>
            <PromptDeleteConfirm open={removeDialogOpened} onClose={handleRemoveDialogClose}>
                <Box component="b">{t("class.addClass.deleteStudentEditPromptBold")}</Box> <br />
                {t("class.addClass.deleteStudentEditPrompt")}
                <Box mt={2} mb={2}>
                    {t("class.addClass.deleteStudentEditPromptDelete")}
                </Box>
            </PromptDeleteConfirm>
        </Form>
    );
};

export default EditClassForm;
