import { useEffect, useState } from "react";
import AppContainer from "src/components/AppContainer";
import StudentReportByAttributeTab from "../components/StudentReport/StudentReportByAttributeTab";
import StudentReportByStudentTab from "../components/StudentReport/StudentReportByStudentTab";
import StudentReportBySubjectTab from "../components/StudentReport/StudentReportBySubjectTab";
import StudentReportByYearGroupTab from "../components/StudentReport/StudentReportByYearGroupTab";
import COLORS from "src/styles/colors";
import {
    Box,
    CircularProgress,
    Paper,
    Theme,
    Typography,
    Tab,
    Tabs,
    TextField,
    MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Dispatch } from "redux";
import { useMount } from "src/hooks/useMount";
import { AnalysisActions } from "src/modules/analysis/store/actions";
import { CommonCohortAnalysisYearGroupResponse } from "src/modules/common/api/analysisCohortYearGroups";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { ROUTE_REPORT_STUDENT_REPORTS_TAB } from "src/routes";
import { filterNotNullable } from "src/services/object";
import { AppState } from "src/store/reducers";
import { reportTemplateListSelector } from "../selectors/ReportTempatesSelectors";
import { ReportActions } from "../store/actions";
import { SchoolUserRole } from "src/orm/models/User";
import StudentReportByTagTab from "../components/StudentReport/StudentReportByTagTab";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export enum StudentReportsTabTypes {
    BY_STUDENT = "byStudent",
    BY_SUBJECT = "bySubject",
    BY_ATTRIBUTE = "byAttribute",
    BY_YEAR_GROUP = "byYearGroup",
    BY_TAG = "byTag",
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flex: 1,
        width: "100%",
        overflow: "hidden",
        backgroundColor: COLORS.WHITE,
    },
    tabPanel: {
        flex: 1,
        overflowY: "auto",
        padding: theme.spacing(6, 7),
        position: "relative",
    },
    hint: {
        padding: theme.spacing(3, 6, 3, 3),
        backgroundColor: COLORS.VERY_LIGHT_GREY_4,
        fontStyle: "italic",
        width: "33.33333%",
        position: "absolute",
        top: theme.spacing(14),
        right: theme.spacing(7),
    },
}));

const dispatchActions = (dispatch: Dispatch) => ({
    getAnalysisCohortYearGroups: () => {
        dispatch(AnalysisActions.getAnalysisCohortYearGroups());
    },
    getReportTemplateList: () => {
        dispatch(ReportActions.getReportTemplateList());
    },
});

const StudentReportsContainer = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { navigate } = useSchoolNavigate();
    const { cohort: cohortParam, yearGroup: yearGroupParam, tab: tabParam } = useParams();
    const { t } = useTranslation();
    const { data: accountInfo } = useAccountInfo();
    const { data: profile } = useProfile();

    const { getAnalysisCohortYearGroups, getReportTemplateList } = dispatchActions(dispatch);
    const {
        reportTemplates,
        analysisCohortYearGroups,
    }: {
        analysisCohortYearGroups: CommonCohortAnalysisYearGroupResponse[] | null;
        reportTemplates;
    } = useSelector(
        (state: AppState) => ({
            analysisCohortYearGroups: state.analysis.analysisCohortYearGroups,
            reportTemplates: reportTemplateListSelector(state),
        }),
        shallowEqual,
    );
    const isAdmin =
        profile?.userRole === SchoolUserRole.SCHOOL_ADMIN ||
        profile?.userRole === SchoolUserRole.OWNER;
    const canSeeAllReportTypes =
        isAdmin ||
        (!isAdmin && (profile?.leaderOfSubjectAreas.length > 0 || profile?.isLeaderOfAll));

    const [currentTab, setCurrentTab] = useState<string | StudentReportsTabTypes>(
        tabParam || canSeeAllReportTypes
            ? StudentReportsTabTypes.BY_STUDENT
            : StudentReportsTabTypes.BY_SUBJECT,
    );
    const [cohortId, setCohortId] = useState<number | null>(
        cohortParam ? parseInt(cohortParam) : null,
    );
    const [yearGroup, setYearGroup] = useState<number | null>(
        yearGroupParam ? parseInt(yearGroupParam) : null,
    );
    const [availableYearGroups, setAvailableYearGroups] = useState<number[] | null>(null);

    useMount(() => {
        getReportTemplateList();
        if (!analysisCohortYearGroups) getAnalysisCohortYearGroups();
    });

    useEffect(() => {
        if (analysisCohortYearGroups && analysisCohortYearGroups.length > 0) {
            let cohortId: number | null = null;
            if (
                accountInfo?.activeCohortId &&
                analysisCohortYearGroups.find(acyg => acyg.id === accountInfo?.activeCohortId)
            ) {
                cohortId = accountInfo?.activeCohortId;
            } else {
                cohortId = analysisCohortYearGroups[analysisCohortYearGroups.length - 1].id;
            }
            setCohortId(cohortId);
            const cohortYg = analysisCohortYearGroups.find(acyg => acyg.id === cohortId);

            if (cohortYg) {
                setAvailableYearGroups(cohortYg.yearGroup);
                setYearGroup(cohortYg.yearGroup[0]);
            } else {
                if (analysisCohortYearGroups[0].yearGroup) {
                    setYearGroup(
                        analysisCohortYearGroups[analysisCohortYearGroups.length - 1].yearGroup[0],
                    );
                    setAvailableYearGroups(analysisCohortYearGroups[0].yearGroup);
                }
            }
        }
        //eslint-disable-next-line
    }, [analysisCohortYearGroups]);

    useEffect(() => {
        if (yearGroup && cohortId) {
            navigate(
                ROUTE_REPORT_STUDENT_REPORTS_TAB.replace(":cohort", `${cohortId}`)
                    .replace(":yearGroup", `${yearGroup}`)
                    .replace(":tab", tabParam || StudentReportsTabTypes.BY_STUDENT),
            );
            if (analysisCohortYearGroups && analysisCohortYearGroups.length > 0) {
                const entry = analysisCohortYearGroups.find(acyg => acyg.id === cohortId);
                if (entry) {
                    setAvailableYearGroups(entry.yearGroup);
                }
            }
        }
    }, [yearGroup, cohortId, tabParam, analysisCohortYearGroups]);

    const handleTabChange = (e, value) => {
        setCurrentTab(value);
    };

    const handleCohortChange = cohortId => {
        setCohortId(cohortId);

        const analysisCohortYearGroup = analysisCohortYearGroups?.find(
            acyg => acyg.id === cohortId,
        );
        if (
            analysisCohortYearGroup &&
            analysisCohortYearGroup.yearGroup &&
            analysisCohortYearGroup.yearGroup.length > 0
        ) {
            setAvailableYearGroups(analysisCohortYearGroup.yearGroup);
            setYearGroup(analysisCohortYearGroup.yearGroup[0]);
        } else {
            setAvailableYearGroups([]);
            setYearGroup(null);
        }
    };

    return (
        <AppContainer>
            <Typography variant="h1" gutterBottom>
                {t("report.studentReport.header")}
            </Typography>
            {analysisCohortYearGroups && (
                <>
                    <Box mb={4} display="flex">
                        <Box width={120}>
                            <TextField
                                label={t("common.academicYear")}
                                select
                                SelectProps={{ displayEmpty: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="none"
                                name="cohort"
                                fullWidth
                                value={cohortId || 0}
                                onChange={e => handleCohortChange(parseInt(e.target.value))}
                            >
                                {filterNotNullable(analysisCohortYearGroups)
                                    .filter(acYg => !acYg.archived)
                                    .map(obj => (
                                        <MenuItem key={obj.id} value={obj.id}>
                                            {obj.name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Box>
                        {availableYearGroups && (
                            <Box width={120} ml={2}>
                                <TextField
                                    label={t("common.yearGroup")}
                                    select
                                    SelectProps={{ displayEmpty: true }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="none"
                                    name="yearGroup"
                                    fullWidth
                                    value={yearGroup || 0}
                                    onChange={e => setYearGroup(parseInt(e.target.value))}
                                >
                                    {availableYearGroups.map(yg => (
                                        <MenuItem key={yg} value={yg}>
                                            {yg === 0 ? t("common.yg0") : yg}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        )}
                    </Box>
                </>
            )}
            <Paper>
                {yearGroup > -1 && cohortId ? (
                    <Box className={classes.root}>
                        <Box className={classes.tabPanel}>
                            <Box className={classes.hint}>
                                <Typography variant="inherit">
                                    {t("report.studentReport.note")}
                                </Typography>
                            </Box>
                            <Tabs value={currentTab} onChange={handleTabChange}>
                                <Tab
                                    label={t("report.studentReport.tabs.byStudent")}
                                    value={StudentReportsTabTypes.BY_STUDENT}
                                />
                                <Tab
                                    label={t("report.studentReport.tabs.bySubject")}
                                    value={StudentReportsTabTypes.BY_SUBJECT}
                                />
                                {canSeeAllReportTypes && (
                                    <Tab
                                        label={t("report.studentReport.tabs.byYearGroup")}
                                        value={StudentReportsTabTypes.BY_YEAR_GROUP}
                                    />
                                )}
                                {canSeeAllReportTypes && (
                                    <Tab
                                        label={t("report.studentReport.tabs.byAttribute")}
                                        value={StudentReportsTabTypes.BY_ATTRIBUTE}
                                    />
                                )}
                                {canSeeAllReportTypes && (
                                    <Tab
                                        label={t("report.studentReport.tabs.byTag")}
                                        value={StudentReportsTabTypes.BY_TAG}
                                    />
                                )}
                            </Tabs>
                            {currentTab === StudentReportsTabTypes.BY_SUBJECT && (
                                <StudentReportBySubjectTab
                                    cohort={cohortId}
                                    yearGroup={yearGroup}
                                    reportTemplates={reportTemplates}
                                />
                            )}
                            {currentTab === StudentReportsTabTypes.BY_ATTRIBUTE && (
                                <StudentReportByAttributeTab
                                    cohort={cohortId}
                                    yearGroup={yearGroup}
                                    reportTemplates={reportTemplates}
                                />
                            )}
                            {currentTab === StudentReportsTabTypes.BY_YEAR_GROUP && (
                                <StudentReportByYearGroupTab
                                    cohort={cohortId}
                                    yearGroup={yearGroup}
                                    reportTemplates={reportTemplates}
                                />
                            )}
                            {currentTab === StudentReportsTabTypes.BY_STUDENT && (
                                <StudentReportByStudentTab
                                    cohort={cohortId}
                                    yearGroup={yearGroup}
                                    reportTemplates={reportTemplates}
                                />
                            )}
                            {currentTab === StudentReportsTabTypes.BY_TAG && (
                                <StudentReportByTagTab
                                    cohort={cohortId}
                                    yearGroup={yearGroup}
                                    reportTemplates={reportTemplates}
                                />
                            )}
                        </Box>
                    </Box>
                ) : (
                    <Box p={4} style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </Box>
                )}
            </Paper>
        </AppContainer>
    );
};

export default StudentReportsContainer;
