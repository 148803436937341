import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

const apiRemoveTag = async (id: number): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`school/${getSchoolAccountId()}/tag/${id}/`);
};

export const useRemoveTagMutation = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: { id: number }) => apiRemoveTag(values.id),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
            enqueueSnackbar(t("common.removed"), { variant: "success" });
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
