import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { CommonSpecificationAttributesResponse } from "src/modules/common/api/specificationAttributes";
import { OrmQualificationActions as OQualificationActions } from "../Qualification/actions";

export enum OrmSpecificationAttributeActionTypes {
    BULK_IMPORT = "[orm] SPECIFICATION ATTRIBUTE BULK IMPORT",
}

export const OrmSpecificationAttributeActions = {
    bulkImport: (payload: {
        data: CommonSpecificationAttributesResponse;
        specification: number;
    }) => {
        return createAction(OrmSpecificationAttributeActionTypes.BULK_IMPORT, payload);
    },
};

export type OrmQualificationActions = ActionsUnion<typeof OQualificationActions>;
