import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { ClassTrackerValuesObject } from "src/modules/tracker/dto/TrackerValues";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_SNAPSHOT_VALUES_QUERY = "classTrackerGroupSnapshotValues";

export const useClassTrackerGroupSnapshotValues = (
    id: number,
    tier: TiersTypes,
    snapshotId: string,
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            CLASS_TRACKER_GROUP_SNAPSHOT_VALUES_QUERY,
            schoolAccountId,
            id,
            tier,
            snapshotId,
        ],
        queryFn: async (): Promise<ClassTrackerValuesObject | null> => {
            if (id && tier && snapshotId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker-group/${id}/${tier}/snapshot/${snapshotId}/values/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
