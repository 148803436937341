import {
    ApiAction,
    ApiActionMeta,
    ApiData,
    ApiEndpoint,
    ApiPayload,
    ApiStatus,
    ModuleActionMeta,
} from "./constants";

export const createApiAction = <
    V extends object = object,
    P extends object | undefined = object,
    E extends object = object,
    T extends string = string,
>(
    type: T,
    payload: (ApiPayload<V, P> & E) | undefined,
    meta: ApiActionMeta,
): ApiAction<T, any> => ({
    type,
    payload,
    meta,
});

export const isApiActionMeta = (meta: any): meta is ApiActionMeta =>
    !!meta &&
    !!(meta as ApiActionMeta).prefix &&
    !!(meta as ApiActionMeta).key &&
    !!(meta as ApiActionMeta).endpoint;

export const processEndpoints = <K extends keyof any>(
    prefix: string,
    endpoints: Record<K, ModuleActionMeta>,
): Record<K, ApiActionMeta> => {
    const result: any = {};
    Object.keys(endpoints).forEach(key => {
        result[key] = { ...endpoints[key], prefix, key };
    });

    return result;
};

export const createActionObject = (
    apiEndpoint: ApiEndpoint,
    onSuccess?: string,
    onFailure?: string,
): ModuleActionMeta => {
    return {
        ...apiEndpoint,
        ...(onSuccess && { onSuccess }),
        ...(onFailure && { onFailure }),
    };
};

export const paramRequest = (params: object) => {
    const result: object = {};
    Object.keys(params).forEach(key => {
        result[key] = parseInt(params[key], 10);
    });

    return {
        values: {},
        params: result,
    };
};

export const shouldLoadData = (data: ApiData): boolean => [ApiStatus.NONE].includes(data.status);

export const loadMissingData = (
    data: ApiData,
    list: any[],
    loadDispatcher: (...params) => any,
    ...params: any[]
): void => {
    if ([ApiStatus.NONE, ApiStatus.ERROR].includes(data.status) || list.length === 0) {
        loadDispatcher(...params);
    }
};
