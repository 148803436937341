import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import ChangeGradeBoundaryModal from "../../Common/ChangeGradeBoundaryModal";
import ForecastActionButtons from "../../Forecast/ForecastActionButtons";
import LiveTrackerActionButtons from "../../Common/LiveTrackerActionButtons";
import SnapshotActionButtons from "../../Snapshot/SnapshotActionButtons";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { mdiBorderHorizontal, mdiDownload, mdiTextBoxPlusOutline, mdiFinance } from "@mdi/js";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Dispatch } from "redux";
import { ApiData, ApiStatus } from "src/api/constants";
import { apiUrl, HTTP_NO_CONTENT } from "src/config/globals";
import { useResponse } from "src/hooks/useResponse";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { GradeBoundariesSeriesObject } from "src/modules/tracker/api/GradeBoundaries/getLiveGradeBoundarySeries";
import { exportTrackerToExcel } from "src/modules/tracker/services/dataExport";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { GradeTypes } from "src/orm/models/Grade";
import {
    ROUTE_ANALYSIS_SUBJECT_ANALYSIS,
    ROUTE_CLASS_ASSESSMENT_CREATE,
    ROUTE_CLASS_ASSESSMENT_LIST,
} from "src/routes";
import { baseHeaders, post } from "src/services/ajax";
import { FormError, getErrorMessage } from "src/services/error";
import { AppState } from "src/store/reducers";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { StudentReportBySubjectRequest } from "src/modules/report/api/StudentReport/bySubject";
import { ReportActions } from "src/modules/report/store/actions";
import { SchoolUserRole } from "src/orm/models/User";
import { Storage } from "src/services/storage";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import AssessmentRecoveryModal from "src/modules/assessment/components/AssessmentRecoveryModal";
import AssessmentCreateModal, {
    AssessmentCreateTypes,
} from "src/modules/assessment/components/AssessmentCreateModal";
import { AssessmentCreateFormShape } from "src/modules/assessment/components/forms/AddAssessmentForm";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useClassTrackerPixlSeriesList } from "src/modules/tracker/hooks/query/ClassTrackerGroup/useTrackerPixlSeriesList";
import { useClassTrackerPixlSeriesAvg } from "src/modules/tracker/hooks/query/ClassTrackerGroup/useTrackerPixlSeriesAvg";
import { AnalysisTypes } from "src/modules/analysis/components/Common/AnalysisTypeModal";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { AxiosError, HttpStatusCode } from "axios";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getSchoolAccountId } from "src/services/url";

export interface SeriesDataUnitsObject {
    id: number;
    name: string;
    values: number[];
    maxValue: number;
}

export interface SeriesDataObject {
    gradeBoundaryFactor: number;
    units: SeriesDataUnitsObject[];
}

const dispatchActions = (dispatch: Dispatch) => ({
    getLiveGradeBoundaries: (classTrackerGroupId: number) => {
        dispatch(TrackerActions.getClassTrackerLiveGradeBoundaries(classTrackerGroupId));
    },
    changeClassGradeBoundary: (classTrackerGroupId: number, seriesId: number, factor: number) => {
        dispatch(TrackerActions.changeClassGradeBoundary(classTrackerGroupId, seriesId, factor));
    },
    clearLiveGradeBoundaries: () => {
        dispatch(TrackerActions.setClassLiveGradeBoundaries(null));
    },
    getStudentReportBySubject: (
        cohort: number,
        yearGroup: number,
        values: StudentReportBySubjectRequest,
    ) => {
        dispatch(ReportActions.getStudentReportBySubject(cohort, yearGroup, values));
    },
});

const ToolsPanel = ({ context, api }) => {
    const {
        cohortId,
        classDetails,
        relatedData,
        dataType,
        handleBlocked,
        handleDataAsyncLoaded,
        handleBulkEditSave,
        handleGradeBoundariesProcessing,
        tier,
        archived,
        handlePixlSeriesChange,
        gridUserSettings,
    } = context;

    const { bulkEdit } = useParams();

    const isDemoClass = !!classDetails?.isDemo;
    const { data: accountInfo } = useAccountInfo();
    const [recoveryDialogOpened, setRecoveryDialogOpened] = useState<boolean>(false);
    const [createDialogOpened, setCreateDialogOpened] = useState<boolean>(false);

    const [addAssessmentType, setAddAssessmentType] = useState<AssessmentCreateTypes | null>(null);
    const [selectedSeries, setSelectedSeries] = useState<number>(
        gridUserSettings?.selectedSeriesId || 0,
    );
    const [storageRecovery] = useState<AssessmentCreateFormShape>(
        Storage.getItem("addAssessmentForm") ? Storage.getItem("addAssessmentForm") : null,
    );
    const isSyncedClass = !!accountInfo?.hasGroupCallSynchronization;

    const { data: classTrackerSeriesList } = useClassTrackerPixlSeriesList(classDetails?.id);

    const { data: pixlSeries, error } = useClassTrackerPixlSeriesAvg(
        classDetails?.id,
        tier,
        selectedSeries,
    );

    useEffect(() => {
        const err = error as AxiosError<{ error: string }>;
        if (err?.response?.data?.error) {
            if (err?.response?.status === HttpStatusCode.NotFound) {
                enqueueSnackbar(t("tracker.grid.pixlSeriesRemoved"), { variant: "error" });
            } else {
                enqueueSnackbar(err?.response?.data?.error, { variant: "error" });
            }
            setSelectedSeries(0);
        }
    }, [error]);

    const { navigate } = useSchoolNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        getLiveGradeBoundaries,
        changeClassGradeBoundary,
        clearLiveGradeBoundaries,
        getStudentReportBySubject,
    } = dispatchActions(dispatch);

    const {
        liveGradeBoundaries,
        apiChange,
        apiReport,
    }: {
        liveGradeBoundaries: GradeBoundariesSeriesObject[] | null;
        apiChange: ApiData;
        apiReport: ApiData;
    } = useSelector(
        (state: AppState) => ({
            liveGradeBoundaries: state.tracker.liveGradeBoundarySeries,
            apiChange: state.api.tracker.changeClassGradeBoudary,
            apiReport: state.api.report.getStudentReportBySubject,
        }),
        shallowEqual,
    );
    const { data: userProfile } = useProfile();
    const currentGradeBoundarySeriesName = relatedData?.gradeBoundary.seriesName;

    const [changeBoundaryOpen, setChangeBoundaryOpen] = useState<boolean>(false);
    const [selectedGradeBoundary, setSelectedGradeBoundary] = useState<number | null>(null);
    const [gradeBoundaryFactor, setGradeBoundaryFactor] = useState<number>(
        relatedData?.gradeBoundary.factor || 0,
    );
    const [seriesData, setSeriesData] = useState<SeriesDataObject | null>(null);

    const handleBoundaryOpen = () => setChangeBoundaryOpen(true);

    const handleBoundaryClose = (confirmed: boolean) => {
        if (
            confirmed &&
            selectedGradeBoundary &&
            classDetails &&
            (classDetails.classTrackerGroup || classDetails.classTrackerGroupId)
        ) {
            changeClassGradeBoundary(
                classDetails.classTrackerGroup?.id || classDetails.classTrackerGroupId,
                selectedGradeBoundary,
                gradeBoundaryFactor,
            );
        } else {
            setChangeBoundaryOpen(false);
        }
    };

    const getGradeBoundaryValues = (
        classTrackerGroupId: number,
        selectedGradeBoundary: number,
        gradeBoundaryFactor: number,
        tier?: TiersTypes,
    ) => {
        post(
            apiUrl(
                `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/${
                    tier || "-"
                }/grade-boundaries-series/${selectedGradeBoundary}/`,
            ),
            { gradeBoundaryFactor },
            baseHeaders(),
        ).subscribe(
            res => setSeriesData(res.response),
            err => {
                if (err?.response?.message) {
                    enqueueSnackbar(err.response.message, {
                        ...SnackbarErrorOptions,
                    });
                }
            },
        );
    };

    const handleResponse = useCallback(
        data => {
            switch (data.status) {
                case ApiStatus.SUCCESS: {
                    if (handleGradeBoundariesProcessing) handleGradeBoundariesProcessing();
                    setChangeBoundaryOpen(false);

                    break;
                }
                case ApiStatus.ERROR: {
                    if (data.message)
                        enqueueSnackbar(data.message, {
                            ...SnackbarErrorOptions,
                        });
                    const error: FormError = getErrorMessage(data);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
        },

        [],
    );

    const hasAccessToPastPapers = !!(
        userProfile &&
        userProfile &&
        (userProfile.userRole === SchoolUserRole.OWNER ||
            userProfile.userRole === SchoolUserRole.SCHOOL_ADMIN ||
            userProfile.isLeaderOfAll ||
            userProfile.leaderOfSubjectAreas.length > 0)
    );

    useEffect(() => {
        const currentGradeBoundary = liveGradeBoundaries?.find(
            lgb => lgb.name === currentGradeBoundarySeriesName,
        );
        if (
            liveGradeBoundaries &&
            liveGradeBoundaries.length > 0 &&
            selectedGradeBoundary === null
        ) {
            if (!currentGradeBoundary) {
                setSelectedGradeBoundary(liveGradeBoundaries[0].id);
            } else {
                setSelectedGradeBoundary(currentGradeBoundary.id);
            }
        }
    }, [
        currentGradeBoundarySeriesName,
        liveGradeBoundaries,
        selectedGradeBoundary,
        setSelectedGradeBoundary,
    ]);

    useEffect(() => {
        if (
            selectedGradeBoundary &&
            classDetails &&
            (classDetails.classTrackerGroup || classDetails.classTrackerGroupId)
        ) {
            getGradeBoundaryValues(
                classDetails.classTrackerGroup?.id || classDetails.classTrackerGroupId,
                selectedGradeBoundary,
                gradeBoundaryFactor,
                tier,
            );
        }
    }, [gradeBoundaryFactor, classDetails, selectedGradeBoundary]);

    useEffect(() => {
        if (
            classDetails &&
            (classDetails.classTrackerGroup || classDetails.classTrackerGroupId) &&
            relatedData &&
            relatedData.gradeType.type !== GradeTypes.BTEC
        ) {
            getLiveGradeBoundaries(
                classDetails.classTrackerGroup?.id || classDetails.classTrackerGroupId,
            );
        }
    }, [classDetails, relatedData]);

    useEffect(() => {
        return () => {
            setSelectedGradeBoundary(null);
            clearLiveGradeBoundaries();
        };
    }, []);

    useEffect(() => {
        if (!selectedSeries && handlePixlSeriesChange) handlePixlSeriesChange(null, null);
    }, [selectedSeries]);

    useEffect(() => {
        if (pixlSeries && handlePixlSeriesChange) {
            handlePixlSeriesChange(selectedSeries, pixlSeries);
        }
    }, [pixlSeries]);

    useResponse(() => handleResponse(apiChange), apiChange);

    useResponse(() => {
        if (
            apiReport.status === ApiStatus.SUCCESS &&
            apiReport.responseStatus === HTTP_NO_CONTENT
        ) {
            enqueueSnackbar(t("report.studentReport.byStudent.requestSuccess"), {
                variant: "success",
            });
        }
        if (apiReport.status === ApiStatus.ERROR) {
            enqueueSnackbar(
                apiReport.error?.response.error || t("report.studentReport.byStudent.requestError"),
                {
                    ...SnackbarErrorOptions,
                },
            );
        }
    }, apiReport);

    if (dataType === DataTypeTypes.LIVE && bulkEdit) {
        return (
            <LiveTrackerActionButtons
                isDemoClass={isDemoClass}
                exportAsExcel={() => exportTrackerToExcel(api)}
                saveBulkEdit={handleBulkEditSave}
                tier={tier}
                isSyncedClass={isSyncedClass}
                archived={archived}
            />
        );
    }
    return (
        <Box>
            {!archived && classDetails && hasAccessToPastPapers ? (
                <Box
                    px={4}
                    py={3}
                    borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                    display="flex"
                    flexDirection="column"
                >
                    <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27} mb={1}>
                        {t("common.toolsAssessments")}
                    </Box>
                    <Button
                        variant="text"
                        onClick={() => setCreateDialogOpened(true)}
                        startIcon={<Icon path={mdiTextBoxPlusOutline} />}
                        disableRipple
                    >
                        {t("tracker.grid.addAssessment")}
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => {
                            navigate(
                                ROUTE_CLASS_ASSESSMENT_LIST.replace(":cohort", `${cohortId}`) +
                                    `?subjectArea=${classDetails.subjectArea?.id}&rSubject=${
                                        classDetails?.subjectArea?.name
                                    }&rQual=${
                                        classDetails?.qualification?.name
                                    }&rtier=${tier}&qualification=${
                                        classDetails.qualification?.id
                                    }&yearGroup=${
                                        classDetails.yearGroup
                                    }&sYg=${classDetails.yearGroup}&sSpecification=${
                                        classDetails.specification.id
                                    }&returnUrl=${encodeURIComponent(
                                        location.pathname + location.search,
                                    )}&trackerId=${classDetails?.id}`,
                            );
                        }}
                        startIcon={
                            <Icon path="M10 21H5C3.89 21 3 20.11 3 19V5C3 3.89 3.89 3 5 3H19C20.11 3 21 3.89 21 5V10.33C20.7 10.21 20.37 10.14 20.04 10.14C19.67 10.14 19.32 10.22 19 10.37V5H5V19H10.11L10 19.11V21M7 9H17V7H7V9M7 17H12.11L14 15.12V15H7V17M7 13H16.12L17 12.12V11H7V13M21.7 13.58L20.42 12.3C20.21 12.09 19.86 12.09 19.65 12.3L18.65 13.3L20.7 15.35L21.7 14.35C21.91 14.14 21.91 13.79 21.7 13.58M12 22H14.06L20.11 15.93L18.06 13.88L12 19.94V22Z" />
                        }
                        disableRipple
                    >
                        {t("tracker.grid.editAssessmentBtn")}
                    </Button>
                </Box>
            ) : (
                <></>
            )}
            {!archived && relatedData?.gradeType.type !== GradeTypes.BTEC && (
                <>
                    <Box
                        px={4}
                        py={3}
                        borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                        display="flex"
                        flexDirection="column"
                    >
                        <Box fontFamily={PRIMARY_FONT_FAMILY} mb={1}>
                            <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27}>
                                {t("tracker.grid.geadeBoundarySeries")}
                            </Box>
                            <Box fontSize={15} fontWeight="fontWeightMedium" lineHeight={1.375}>
                                {relatedData?.gradeBoundary.seriesName || ""}
                            </Box>
                        </Box>
                        <Button
                            variant="text"
                            startIcon={<Icon path={mdiBorderHorizontal} />}
                            disableRipple
                            onClick={handleBoundaryOpen}
                        >
                            {t("tracker.grid.gradeBoundaryChangeBtn")}
                        </Button>
                    </Box>
                    <ChangeGradeBoundaryModal
                        open={changeBoundaryOpen}
                        handleClose={c => handleBoundaryClose(c)}
                        relatedData={relatedData}
                        setGradeBoundaryFactor={(factor: number) => setGradeBoundaryFactor(factor)}
                        setGradeBoundary={gradeBoundaryId =>
                            setSelectedGradeBoundary(gradeBoundaryId)
                        }
                        seriesData={seriesData}
                        selectedGradeBoundary={selectedGradeBoundary}
                        liveGradeBoundaries={liveGradeBoundaries}
                        editMode={relatedData?.canEditGradeBoundary || false}
                        isLoading={apiChange.status === ApiStatus.LOADING}
                    />
                </>
            )}

            {dataType && dataType === DataTypeTypes.LIVE && (
                <LiveTrackerActionButtons
                    isDemoClass={isDemoClass}
                    exportAsExcel={() => exportTrackerToExcel(api)}
                    saveBulkEdit={handleBulkEditSave}
                    tier={tier}
                    isSyncedClass={isSyncedClass}
                    archived={archived}
                />
            )}
            {!archived && dataType && dataType === DataTypeTypes.FORECAST && (
                <ForecastActionButtons
                    classTrackerGroup={relatedData?.classTrackerGroup?.id}
                    exportAsExcel={() => exportTrackerToExcel(api)}
                    handleBlocked={handleBlocked}
                    handleDataAsyncLoaded={handleDataAsyncLoaded}
                    gridApi={api}
                    tier={tier}
                />
            )}
            {dataType && dataType === DataTypeTypes.SNAPSHOT && (
                <SnapshotActionButtons exportAsExcel={() => exportTrackerToExcel(api)} />
            )}
            <Box
                px={4}
                py={3}
                borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                display="flex"
                flexDirection="column"
            >
                <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27} mb={1}>
                    {t("common.reportsAnalysis")}
                </Box>
                <Button
                    variant="text"
                    disabled={apiReport.status === ApiStatus.LOADING}
                    onClick={() => {
                        getStudentReportBySubject(cohortId, classDetails.yearGroup, {
                            subjectArea: classDetails.subjectArea?.id
                                ? { id: classDetails.subjectArea?.id }
                                : null,
                            qualification: classDetails.qualification?.id
                                ? { id: classDetails.qualification?.id }
                                : null,
                            specification: classDetails.specification?.id
                                ? { id: classDetails.specification?.id }
                                : null,
                            classTracker: classDetails.id ? { id: classDetails.id } : null,
                            analysisStudents: [],
                        });
                    }}
                    startIcon={<Icon path={mdiDownload} />}
                    disableRipple
                >
                    {t("tracker.grid.downloadClassReports")}
                </Button>
                {userProfile?.accessToAnalysisTypes.includes(AnalysisTypes.SUBJECT_ANALYSIS) && (
                    <Button
                        variant="text"
                        onClick={() => {
                            navigate(
                                ROUTE_ANALYSIS_SUBJECT_ANALYSIS.replace(
                                    ":cohort",
                                    `${cohortId}`,
                                ).replace(":yearGroup", `${classDetails.yearGroup}`) +
                                    `?dataType=live&viewGrade=projected&subjectArea=${
                                        classDetails.subjectArea?.id || ""
                                    }&qualification=${
                                        classDetails.qualification?.id || ""
                                    }&specification=${classDetails.specification?.id || ""}`,
                            );
                        }}
                        startIcon={<Icon path={mdiFinance} />}
                        disableRipple
                    >
                        {t("tracker.grid.subjectAnalysis")}
                    </Button>
                )}
            </Box>

            {classTrackerSeriesList && classTrackerSeriesList.length > 0 ? (
                <Box p={4}>
                    <TextField
                        select
                        label={t("tracker.grid.selectedSeries")}
                        value={selectedSeries}
                        onChange={e => setSelectedSeries(parseInt(`${e.target.value}`))}
                    >
                        <MenuItem value={0}>{t("common.none")}</MenuItem>
                        {classTrackerSeriesList.map(ctgsl => (
                            <MenuItem key={ctgsl.id} value={ctgsl.id}>
                                {ctgsl.name || ctgsl.id}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            ) : (
                <></>
            )}
            <AssessmentCreateModal
                open={createDialogOpened}
                handleClose={(confirm, selectedType) => {
                    setCreateDialogOpened(false);
                    if (confirm) {
                        if (
                            storageRecovery &&
                            (storageRecovery?.publishedAssessments?.length > 0 ||
                                storageRecovery?.customisedAssessments?.length > 0)
                        ) {
                            setRecoveryDialogOpened(true);
                            setAddAssessmentType(selectedType);
                        } else {
                            const addAssesmentURLBasicParams = `&rSubject=${classDetails?.subjectArea?.name}&rQual=${classDetails?.qualification?.name}&rtier=${tier}&trackerId=${classDetails?.id}&sYg=${classDetails.yearGroup}&sSpecification=${classDetails.specification.id}`;
                            navigate(
                                ROUTE_CLASS_ASSESSMENT_CREATE.replace(":cohort", `${cohortId}`) +
                                    `?startingType=${selectedType}${addAssesmentURLBasicParams}`,
                            );
                        }
                    }
                }}
            />
            <AssessmentRecoveryModal
                open={recoveryDialogOpened}
                handleClose={() => {
                    setRecoveryDialogOpened(false);
                }}
                handleRemove={() => {
                    Storage.removeItem("addAssessmentForm");
                    setRecoveryDialogOpened(false);
                    navigate(
                        ROUTE_CLASS_ASSESSMENT_CREATE.replace(":cohort", `${cohortId}`) +
                            `?startingType=${addAssessmentType}&sYg=${classDetails.yearGroup}&sSpecification=${classDetails.specification.id}`,
                    );
                }}
                handleRecover={(selectedPPIndex, selectedCaIndex) => {
                    setRecoveryDialogOpened(false);
                    navigate(
                        ROUTE_CLASS_ASSESSMENT_CREATE.replace(":cohort", `${cohortId}`) +
                            "?recoverPP=" +
                            selectedPPIndex.join(",") +
                            "&recoverCA=" +
                            selectedCaIndex.join(","),
                    );
                }}
            />
        </Box>
    );
};

export default ToolsPanel;
