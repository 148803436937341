import Dialog from "src/components/Dialog";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { OrmClassTrackerGroup } from "src/orm/models/ClassTrackerGroup";

interface OwnProps {
    open: boolean;
    classTrackerGroups: OrmClassTrackerGroup[];
    handleClose: () => void;
    handleSelectGroups: (classTrackers: ListObject<number>[]) => void;
}

const SelectClassTrackerGroupsModal: FC<OwnProps> = ({
    open,
    handleClose,
    handleSelectGroups,
    classTrackerGroups,
}) => {
    const { t } = useTranslation();
    const [localClassTrackerGroups, setLocalClassTrackerGroups] = useState<
        (OrmClassTrackerGroup & { visible: boolean })[]
    >(
        classTrackerGroups && classTrackerGroups.length > 0
            ? classTrackerGroups.map(ctg => ({ ...ctg, visible: true }))
            : [],
    );

    return (
        <Dialog
            className=""
            open={open}
            onClose={() => handleClose()}
            title={t("report.snapshot.create.selectHeader")}
            actions={
                localClassTrackerGroups.filter(lctg => lctg.visible).length > 0 ? (
                    <Button
                        onClick={() =>
                            handleSelectGroups(localClassTrackerGroups.filter(lctg => lctg.visible))
                        }
                    >
                        {t("report.snapshot.create.selectCtgButton")}
                    </Button>
                ) : (
                    <></>
                )
            }
            contentOverflow="visible"
        >
            {localClassTrackerGroups.map((lctg, i) => (
                <FormControlLabel
                    key={lctg.id}
                    control={
                        <Checkbox
                            checked={lctg.visible}
                            onChange={() => {
                                const newLocal = [...localClassTrackerGroups];
                                newLocal[i].visible = !lctg.visible;
                                setLocalClassTrackerGroups(newLocal);
                            }}
                            name=""
                        />
                    }
                    label={`${lctg.subjectArea.name} - ${lctg.qualification.name} - ${lctg.specification.name} - ${lctg.yearGroup}`}
                />
            ))}
        </Dialog>
    );
};

export default SelectClassTrackerGroupsModal;
