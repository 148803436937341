import {
    ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER,
    ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER_GROUP,
    ROUTE_TRACKER_CLASS_TRACKER,
    ROUTE_TRACKER_CLASS_TRACKER_BULK_EDIT,
    ROUTE_TRACKER_CLASS_TRACKER_GROUP,
    ROUTE_TRACKER_CLASS_TRACKER_GROUP_BULK_EDIT,
    ROUTE_TRACKER_CLASS_TRACKER_GROUP_MOVE_STUDENTS,
    ROUTE_TRACKER_CLASS_TRACKER_MOVE_STUDENTS,
} from "src/routes";
import ArchiveClassTrackerContainer from "../containers/ArchiveClassTrackerContainer";
import ArchiveClassTrackerGroupContainer from "../containers/ArchiveClassTrackerGroupContainer";
import ClassTrackerContainer from "../containers/ClassTrackerContainer";
import YearGroupTrackerContainer from "../containers/YearGroupTrackerContainer";

export const TrackerRoutes = [
    {
        path: ROUTE_TRACKER_CLASS_TRACKER_GROUP_BULK_EDIT,
        element: <YearGroupTrackerContainer />,
    },
    {
        path: ROUTE_TRACKER_CLASS_TRACKER_GROUP_MOVE_STUDENTS,
        element: <YearGroupTrackerContainer />,
    },
    {
        path: ROUTE_TRACKER_CLASS_TRACKER_GROUP,
        element: <YearGroupTrackerContainer />,
    },
    {
        path: ROUTE_TRACKER_CLASS_TRACKER_MOVE_STUDENTS,
        element: <ClassTrackerContainer />,
    },
    {
        path: ROUTE_TRACKER_CLASS_TRACKER_BULK_EDIT,
        element: <ClassTrackerContainer />,
    },
    {
        path: ROUTE_TRACKER_CLASS_TRACKER,
        element: <ClassTrackerContainer />,
    },
    {
        path: ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER,
        element: <ArchiveClassTrackerContainer />,
    },
    {
        path: ROUTE_TRACKER_ARCHIVE_CLASS_TRACKER_GROUP,
        element: <ArchiveClassTrackerGroupContainer />,
    },
];
