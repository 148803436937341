import { useEffect, useState } from "react";
import Icon from "src/components/Icon";
import PaperInner from "src/components/PaperInner";
import COLORS from "src/styles/colors";
import AppContainer from "src/components/AppContainer";
import ImportStaffModal from "../components/ImportStaffModal";
import InviteFinanceModal from "../components/InviteFinanceModal";
import CustomTable from "src/components/CustomTable";
import FilterTextField from "src/components/FilterBar/FilterTextField";
import PromptDialog from "src/forms/PromptDialog";
import useDebounce from "src/hooks/useDebounce";
import {
    Box,
    Button,
    Paper,
    Divider,
    Theme,
    Grid,
    TextField,
    Typography,
    Tooltip,
    MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { CellDataGetterParams } from "src/components/CustomTable/types";
import { OrmUser, SchoolUserRole, SchoolUserStatus } from "src/orm/models/User";
import { ROUTE_MY_SCHOOL_STAFF_ADD, ROUTE_MY_SCHOOL_STAFF_EDIT } from "src/routes";
import { filterData } from "src/services/object";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SchoolActions } from "../store/actions";
import { getSchoolAccountId, processUrl } from "src/services/url";
import {
    mdiPublish,
    mdiAccount,
    mdiSend,
    mdiPencil,
    mdiCheck,
    mdiStar,
    mdiStarOutline,
    mdiSeat,
    mdiAccountOutline,
    mdiFinance,
    mdiLinkOff,
    mdiClose,
} from "@mdi/js";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useLocation } from "react-router";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { useStaffActivateMutation } from "../hooks/query/Staff/useStaffActivateMutation";
import { useStaffDeactivateMutation } from "../hooks/query/Staff/useStaffDeactivateMutation";
import { useStaffList } from "../hooks/query/Staff/useStaffList";
import { useRemoveStaff } from "../hooks/query/Staff/useRemoveStaff";
import { useInviteEmail } from "../hooks/query/Staff/useInviteEmail";
import { useInvitePending } from "../hooks/query/Staff/useInvitePending";
import { SchoolUserRoleParam, useChangeStaffRole } from "../hooks/query/Staff/useChangeStaffRole";
import { useConfirmationStaff } from "../hooks/query/Staff/useConfirmationStaff";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
    dividerVertical: {
        height: 24,
        margin: theme.spacing(0, 3),
    },
    active: {
        color: COLORS.GREY_1,
    },
    inactive: {
        color: COLORS.YELLOW_1,
    },
    deactivated: {
        color: COLORS.YELLOW_1,
    },
    pending: {
        color: COLORS.BLUE_3,
    },
    confirmation: {
        color: COLORS.RED_1,
    },
}));

const SchoolStaffContainer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { navigate, attachSchoolId } = useSchoolNavigate();
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const { data: accountInfo } = useAccountInfo();
    const hasGroupCallSync = accountInfo?.hasGroupCallSynchronization || false;
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [inviteFinanceDialogOpened, setInviteFinanceDialogOpened] = useState<boolean>(false);
    const [invitePendingDialogOpened, setInvitePendingDialogOpened] = useState<boolean>(false);
    const [importStaffDialogOpened, setImportStaffDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);
    const [nameFilter, setNameFilter] = useState<string>(searchParams?.name || "");
    const [statusFilter, setStatusFilter] = useState<string>(searchParams?.status || "");
    const [statusSyncFilter, setStatusSyncFilter] = useState<string>(
        searchParams?.statusSync || "",
    );
    const [page, setPage] = useState(searchParams?.page ? parseInt(searchParams?.page) : 1);
    const [perPage, setPerPage] = useState(
        searchParams?.perPage ? parseInt(searchParams.perPage) : 10,
    );
    const { data: authUser } = useProfile();

    const { data: staff, refetch: getStaffList } = useStaffList();

    const { mutate: activateStaff } = useStaffActivateMutation(() =>
        getStaffList(getSchoolAccountId()),
    );
    const { mutate: deactivateStaff } = useStaffDeactivateMutation(() =>
        getStaffList(getSchoolAccountId()),
    );

    const { mutate: removeStaff } = useRemoveStaff();
    const { mutate: inviteEmail } = useInviteEmail();
    const { mutate: invitePending } = useInvitePending();

    const { mutate: changeStaffRole } = useChangeStaffRole();
    const { mutate: confirmStaff } = useConfirmationStaff();

    const debouncedNameFilter = useDebounce(nameFilter, 500);

    const handleDeleteDialogOpen = (id: number): void => {
        setRemoveDialogOpened(true);
        setRemoveId(id);
    };

    const handleDeleteDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            removeStaff(removeId);
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    const handleInvitePendingDialogClose = (confirmed: boolean): void => {
        if (confirmed === true) {
            invitePending();
        }
        setInvitePendingDialogOpened(false);
    };

    const handleNameFilterChange = e => {
        setNameFilter(e.currentTarget.value);
    };

    const handleStatusFilterChange = value => {
        setStatusFilter(value);
    };

    const handleSyncStatusFilterChange = value => {
        setStatusSyncFilter(value);
    };

    useEffect(() => {
        dispatch(
            SchoolActions.setSaffFilter({
                requestParams: {
                    page,
                    perPage,
                    name: nameFilter || "",
                    status: statusFilter || "",
                    statusSync: statusSyncFilter || "",
                },
            }),
        );
    }, [page, perPage, nameFilter, statusFilter, statusSyncFilter]);

    let filteredList = filterData(debouncedNameFilter, staff, ["firstName", "lastName", "id"]);
    filteredList = filterData(statusFilter, filteredList, ["status"], false, true);
    filteredList = filterData(statusSyncFilter, filteredList, ["groupCallStatus"]);

    useEffect(() => {
        if (searchParams) {
            if (
                searchParams.perPage &&
                parseInt(searchParams.perPage) === perPage &&
                searchParams.page &&
                parseInt(searchParams.page) === page &&
                searchParams.name === nameFilter &&
                searchParams.status === statusFilter &&
                searchParams.statusSync === statusSyncFilter
            ) {
                navigate(location.pathname);
            }
        }
    }, [page, perPage, nameFilter, statusFilter, statusSyncFilter, searchParams]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("school.staff.tab")}
            </Typography>
            <Box mb={4} display="flex" alignItems="center" justifyContent="space-between">
                <Button
                    component={Link}
                    to={attachSchoolId(ROUTE_MY_SCHOOL_STAFF_ADD)}
                    color="primary"
                    id="addButton"
                >
                    {t("school.staff.list.addButton")}
                </Button>
                <Box display="flex" alignItems="center">
                    <Button
                        variant="text"
                        startIcon={<Icon path={mdiSend} />}
                        disableRipple
                        onClick={() => setInvitePendingDialogOpened(true)}
                        id="resendInvitationsButton"
                    >
                        {t("school.staff.list.resendInvitations")}
                    </Button>
                    <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                    <Button
                        variant="text"
                        startIcon={<Icon path={mdiAccount} />}
                        disableRipple
                        onClick={() => setInviteFinanceDialogOpened(true)}
                        id="inviteManagerButton"
                    >
                        {t("school.staff.list.inviteManager")}
                    </Button>
                    <Divider orientation="vertical" flexItem className={classes.dividerVertical} />
                    <Button
                        variant="text"
                        startIcon={<Icon path={mdiPublish} />}
                        disableRipple
                        onClick={() => setImportStaffDialogOpened(true)}
                        id="importStaffButton"
                    >
                        {t("school.staff.list.importStaff")}
                    </Button>
                </Box>
            </Box>
            <Paper>
                <PaperInner variant="filter" color="lightGrey" border="bottom">
                    <Grid container spacing={2}>
                        <Grid item sm={4}>
                            <FilterTextField
                                id="filter-by-name"
                                label={t("school.staff.list.filterByName")}
                                value={nameFilter}
                                onChange={handleNameFilterChange}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                label={t("school.staff.list.filterByStatus")}
                                id="filterByStatus"
                                fullWidth
                                value={statusFilter}
                                onChange={e => {
                                    handleStatusFilterChange(e.target.value);
                                }}
                                select
                                margin="none"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem value={""}>{t(`common.noneFilter`)}</MenuItem>
                                <MenuItem value={SchoolUserStatus.ACTIVE}>
                                    {t(`school.staff.user.status.${SchoolUserStatus.ACTIVE}`)}
                                </MenuItem>
                                <MenuItem value={SchoolUserStatus.CONFIRMATION}>
                                    {t(`school.staff.user.status.${SchoolUserStatus.CONFIRMATION}`)}
                                </MenuItem>
                                <MenuItem value={SchoolUserStatus.INACTIVE}>
                                    {t(`school.staff.user.status.${SchoolUserStatus.INACTIVE}`)}
                                </MenuItem>
                                <MenuItem value={SchoolUserStatus.INVITED}>
                                    {t(`school.staff.user.status.${SchoolUserStatus.INVITED}`)}
                                </MenuItem>
                                <MenuItem value={SchoolUserStatus.PENDING}>
                                    {t(`school.staff.user.status.${SchoolUserStatus.PENDING}`)}
                                </MenuItem>
                                <MenuItem value={SchoolUserStatus.NOT_INVITED_YET}>
                                    {t(
                                        `school.staff.user.status.${SchoolUserStatus.NOT_INVITED_YET}`,
                                    )}
                                </MenuItem>
                            </TextField>
                        </Grid>
                        {hasGroupCallSync && (
                            <Grid item sm={4}>
                                <Box mt={0.2}>
                                    <TextField
                                        label={t("school.staff.list.filterByStatusSync")}
                                        id="filter-by-sync-status"
                                        fullWidth
                                        value={statusSyncFilter}
                                        onChange={e => {
                                            handleSyncStatusFilterChange(e.target.value);
                                        }}
                                        select
                                        margin="none"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <MenuItem value={""}>{t(`common.noneFilter`)}</MenuItem>
                                        <MenuItem value="active">
                                            {t("school.staff.list.groupCallStatusStaffActive")}
                                        </MenuItem>
                                        <MenuItem value="manual">
                                            {t("school.staff.list.groupCallStatusStaffManual")}
                                        </MenuItem>
                                        <MenuItem value="deleted">
                                            {t(
                                                "school.staff.list.groupCallStatusStaffDeleted",
                                            ).replaceAll("MIS_Name", accountInfo?.misName || "")}
                                        </MenuItem>
                                    </TextField>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </PaperInner>
                <CustomTable
                    data={filteredList || []}
                    showPaginator
                    showRowsPerPageOptions={true}
                    rowsPerPage={perPage as any}
                    handlePageChange={page => setPage(page)}
                    handlePerPageChange={page => setPerPage(page)}
                    initialPage={page}
                    columns={[
                        {
                            key: "status",
                            label: t("school.staff.list.status"),
                            cellDataGetter: ({ rowData }: CellDataGetterParams<OrmUser>) => {
                                if (rowData.status) {
                                    return (
                                        <Box fontWeight={700} className={classes[rowData.status]}>
                                            {t(`school.staff.user.status.${rowData.status}`)}
                                        </Box>
                                    );
                                }
                                return "-";
                            },
                        },
                        {
                            key: "schoolRole",
                            label: t("school.staff.list.role"),
                        },
                        ...(hasGroupCallSync
                            ? [
                                  {
                                      key: "groupCallStatus",
                                      label: (
                                          <Box textAlign="center">
                                              {t("school.staff.list.groupCallStatusStaff")}
                                          </Box>
                                      ),
                                      cellDataGetter: ({
                                          rowData,
                                      }: CellDataGetterParams<OrmUser>) => {
                                          switch (rowData.groupCallStatus) {
                                              case "active":
                                                  return (
                                                      <Box textAlign="center">
                                                          <Tooltip
                                                              title={
                                                                  t(
                                                                      "school.staff.list.groupCallStatusStaffActive",
                                                                  ) || ""
                                                              }
                                                          >
                                                              <Box component="span">
                                                                  <Icon path={mdiCheck} />
                                                              </Box>
                                                          </Tooltip>
                                                      </Box>
                                                  );
                                              case "manual":
                                                  return (
                                                      <Box textAlign="center">
                                                          <Tooltip
                                                              title={
                                                                  t(
                                                                      "school.staff.list.groupCallStatusStaffManual",
                                                                  ) || ""
                                                              }
                                                          >
                                                              <Box component="span">
                                                                  <Icon path={mdiLinkOff} />
                                                              </Box>
                                                          </Tooltip>
                                                      </Box>
                                                  );
                                              case "deleted":
                                                  return (
                                                      <Box textAlign="center">
                                                          <Tooltip
                                                              title={t(
                                                                  "school.staff.list.groupCallStatusStaffDeleted",
                                                              ).replaceAll(
                                                                  "MIS_Name",
                                                                  accountInfo?.misName || "",
                                                              )}
                                                          >
                                                              <Box component="span">
                                                                  <Icon path={mdiClose} />
                                                              </Box>
                                                          </Tooltip>
                                                      </Box>
                                                  );
                                              default:
                                                  return "";
                                          }
                                      },
                                  },
                              ]
                            : []),
                        {
                            key: "details",
                            label: t("school.staff.list.details"),
                            cellDataGetter: ({ rowData }: CellDataGetterParams<OrmUser>) => {
                                if (rowData.firstName) {
                                    return (
                                        <>
                                            <Box
                                                fontSize={14}
                                            >{`${rowData.firstName} ${rowData.lastName}`}</Box>
                                            {rowData.email && (
                                                <Box
                                                    color={COLORS.BLUE_3}
                                                    mt={0.375}
                                                    fontSize={11}
                                                    fontStyle="italic"
                                                >
                                                    {rowData.email}
                                                </Box>
                                            )}
                                        </>
                                    );
                                }
                                return "-";
                            },
                        },
                        {
                            key: "teacherOf",
                            label: t("school.staff.list.teacher"),
                        },
                        {
                            key: "leaderOf",
                            label: t("school.staff.list.leader"),
                        },
                        {
                            key: "fastAction",
                            label: "",
                            cellDataGetter: ({ rowData }: CellDataGetterParams<OrmUser>) => {
                                if (
                                    rowData.status === SchoolUserStatus.CONFIRMATION &&
                                    rowData.id !== undefined
                                ) {
                                    return (
                                        <Button
                                            variant="text"
                                            startIcon={<Icon path={mdiCheck} />}
                                            disableRipple
                                            onClick={() => confirmStaff(rowData.id)}
                                        >
                                            {t("school.staff.list.confirm")}
                                        </Button>
                                    );
                                }
                                if (rowData.id && rowData.id !== authUser.id) {
                                    return (
                                        <Button
                                            component={Link}
                                            to={processUrl(
                                                attachSchoolId(ROUTE_MY_SCHOOL_STAFF_EDIT),
                                                {
                                                    staff: `${rowData.id}`,
                                                },
                                            )}
                                            variant="text"
                                            startIcon={<Icon path={mdiPencil} />}
                                            disableRipple
                                        >
                                            {t("common.edit")}
                                        </Button>
                                    );
                                }
                                return "";
                            },
                        },
                    ]}
                    actions={[
                        getMenuActionProps("delete", {
                            onAction: (su: OrmUser) =>
                                su.id ? handleDeleteDialogOpen(su.id) : null,
                            showAction: (su: OrmUser) =>
                                su.id !== authUser.id &&
                                su.canRemove === true &&
                                (!(su.groupCallStatus === "active") ||
                                    !accountInfo?.hasGroupCallSynchronization),
                        }),
                        getMenuActionProps("activate", {
                            text: t("school.staff.list.makeActiveAction"),
                            icon: <Icon path={mdiStar} />,
                            onAction: (su: OrmUser) =>
                                su.id
                                    ? activateStaff({
                                          schoolAccountId: getSchoolAccountId(),
                                          id: su.id,
                                      })
                                    : null,
                            showAction: (su: OrmUser) => su.status === SchoolUserStatus.INACTIVE,
                        }),
                        getMenuActionProps("deactivate", {
                            text: t("school.staff.list.makeInactiveAction"),
                            icon: <Icon path={mdiStarOutline} />,
                            onAction: (su: OrmUser) =>
                                su.id
                                    ? deactivateStaff({
                                          schoolAccountId: getSchoolAccountId(),
                                          id: su.id,
                                      })
                                    : null,
                            showAction: (su: OrmUser) =>
                                su.id !== authUser.id && su.status === SchoolUserStatus.ACTIVE,
                        }),
                        getMenuActionProps("upgradeToAdmin", {
                            text: t("school.staff.list.upgradeAdmin"),
                            icon: <Icon path={mdiSeat} />,
                            onAction: (su: OrmUser) =>
                                su.id
                                    ? changeStaffRole({
                                          staff: su.id,
                                          role: SchoolUserRoleParam.ADMIN,
                                      })
                                    : null,
                            showAction: (su: OrmUser) => su.userRole === SchoolUserRole.TEACHER,
                        }),
                        getMenuActionProps("downgradeToUser", {
                            text: t("school.staff.list.downgradeUser"),
                            icon: <Icon path={mdiAccountOutline} />,
                            onAction: (su: OrmUser) =>
                                su.id
                                    ? changeStaffRole({
                                          staff: su.id,
                                          role: SchoolUserRoleParam.TEACHER,
                                      })
                                    : null,
                            showAction: (su: OrmUser) =>
                                su.id !== authUser.id &&
                                su.userRole === SchoolUserRole.SCHOOL_ADMIN,
                        }),
                        getMenuActionProps("makeFinance", {
                            text: t("school.staff.list.makeFinance"),
                            icon: <Icon path={mdiFinance} />,
                            onAction: (su: OrmUser) =>
                                su.id
                                    ? changeStaffRole({
                                          staff: su.id,
                                          role: SchoolUserRoleParam.FINANCE,
                                      })
                                    : null,
                            showAction: (su: OrmUser) =>
                                su.id !== authUser.id &&
                                (su.userRole === SchoolUserRole.SCHOOL_ADMIN ||
                                    su.userRole === SchoolUserRole.TEACHER),
                        }),
                        getMenuActionProps("invitationEmail", {
                            text: t("school.staff.list.invitationEmail"),
                            icon: <Icon path={mdiSend} />,
                            onAction: (su: OrmUser) => (su.id ? inviteEmail(su.id) : null),
                            showAction: (su: OrmUser) =>
                                su.id !== authUser.id &&
                                (su.status === SchoolUserStatus.PENDING ||
                                    su.status === SchoolUserStatus.INVITED ||
                                    su.status === SchoolUserStatus.NOT_INVITED_YET),
                        }),
                        getMenuActionProps("confirm", {
                            text: t("school.staff.list.confirm"),
                            icon: <Icon path={mdiCheck} />,
                            onAction: (su: OrmUser) => (su.id ? confirmStaff(su.id) : null),
                            showAction: (su: OrmUser) =>
                                su.id !== authUser.id &&
                                su.status === SchoolUserStatus.CONFIRMATION,
                        }),
                    ]}
                />
                <PromptDeleteConfirm open={removeDialogOpened} onClose={handleDeleteDialogClose}>
                    <Typography variant="h3">
                        {t("school.staff.list.deleteUserPromptHeader")}
                    </Typography>
                    <Box pt={3}>{t("school.staff.list.deleteUserPrompt")}</Box>
                </PromptDeleteConfirm>
                <PromptDialog
                    open={invitePendingDialogOpened}
                    onClose={handleInvitePendingDialogClose}
                >
                    {t("school.staff.list.invitePendingPrompt")}
                </PromptDialog>
                <InviteFinanceModal
                    open={inviteFinanceDialogOpened}
                    handleClose={() => setInviteFinanceDialogOpened(false)}
                />
                <ImportStaffModal
                    open={importStaffDialogOpened}
                    handleClose={() => {
                        setImportStaffDialogOpened(false);
                        getStaffList();
                    }}
                />
            </Paper>
        </AppContainer>
    );
};

export default SchoolStaffContainer;
