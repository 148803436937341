import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorMessage from "src/forms/ErrorMessage";
import { Fragment } from "react";
import { useTranslation, Trans } from "react-i18next";
import { TERMS_URL } from "src/config/globals";
import { RHFCheckbox } from "src/components/hookFormComponents";
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "underline",
        fontWeight: 600,
    },
}));

const TermsStep = () => {
    const {
        formState: { errors },
    } = useFormContext();
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Box mb={2.5}>
                <Typography>
                    <Trans i18nKey="auth.register.termsStep.terms">
                        Please accept our
                        <a className={classes.link} href={TERMS_URL} target="blank">
                            terms of service
                        </a>
                    </Trans>
                </Typography>
            </Box>
            <Box>
                <RHFCheckbox name="terms" label={t("auth.register.termsStep.termsLabel")} />
                {errors && errors.terms && typeof errors.terms == "string" && (
                    <ErrorMessage name="terms" />
                )}
            </Box>
        </Fragment>
    );
};

export default TermsStep;
