import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ClassTrackerLinkModel } from "../../model/ClassTrackerLinkModel";
import { getSchoolAccountId } from "src/services/url";

export const REPORT_CLASS_TRACKER_LINKS_LIST_QUERY = "reportClassTrackerListQuery";

export const useClassTrackerLinksList = (classTrackerId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [REPORT_CLASS_TRACKER_LINKS_LIST_QUERY, schoolAccountId, classTrackerId],
        queryFn: async (): Promise<ClassTrackerLinkModel[] | null> => {
            if (classTrackerId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/report-links/`,
                );

                return data || null;
            }

            return null;
        },
    });
};
