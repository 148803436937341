import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import {
    AnalysisGradeSummaryValuesObject,
    StudentFilterRequest,
    ViewGrades,
} from "./useAnalysisGradeSummaryValues";
import { getSchoolAccountId } from "src/services/url";

export const ANALYSIS_GRADE_SUMMARY_SNAPSHOT_VALUES_QUERY =
    "AnalysisGradeSummarySnapshotValuesQuery";

export const useAnalysisGradeSummarySnapshotValues = (
    cohort: number,
    yearGroup: number,
    viewGrade: ViewGrades,
    snapshot: string,
    studentFilters: StudentFilterRequest,
) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [
            ANALYSIS_GRADE_SUMMARY_SNAPSHOT_VALUES_QUERY,
            schoolAccountId,
            cohort,
            yearGroup,
            viewGrade,
            snapshot,
            studentFilters,
        ],
        queryFn: async (): Promise<AnalysisGradeSummaryValuesObject | null> => {
            if (cohort && yearGroup && viewGrade && snapshot) {
                const { data } = await axiosInstance.get(
                    `school/${schoolAccountId}/analysis/grade-summary/${cohort}/${yearGroup}/snapshot/${snapshot}/${viewGrade}/values/`,
                    { params: studentFilters },
                );
                return data || null;
            }
            return null;
        },
    });
};
