import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";

export const GET_INVITATION_QUERY = "getInvitationQuery";

export interface InvitationResponse {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
}

export const useGetInvitation = (hash: string) => {
    return useQuery({
        queryKey: [GET_INVITATION_QUERY, hash],
        queryFn: async (): Promise<InvitationResponse | null> => {
            if (hash) {
                console;
                const { data } = await axiosInstance.get(`api/auth/invited/${hash}/`);
                return data || null;
            }
            return null;
        },
    });
};
