import Dialog from "src/components/Dialog";
import COLORS from "src/styles/colors";
import clsx from "clsx";
import Icon from "src/components/Icon";
import { Fragment, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "src/store/reducers";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, TextField, Button, Typography, Grid } from "@mui/material";
import { usePrevious } from "src/hooks/usePrevious";
import { filterNotNullable } from "src/services/object";
import { ROUTE_ANALYSIS_TAB } from "src/routes";
import { mdiBlur, mdiBlurLinear, mdiFinance, mdiAccountStarOutline } from "@mdi/js";
import { AnalysisActions } from "../../store/actions";
import { CommonCohortAnalysisYearGroupResponse } from "src/modules/common/api/analysisCohortYearGroups";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useParams } from "react-router";
import { ChooseButton } from "src/modules/common/components/ChooseButton";
import { SchoolUserRole } from "src/orm/models/User";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export enum AnalysisTypes {
    STUDENTS_GRADE_SUMMARY = "students-grade-summary",
    KS4_GRADE_ANALYSIS = "ks4-grade-analysis",
    ATL_ANALYSIS = "atl-analysis",
    SUBJECT_ANALYSIS = "subject-analysis",
}

export const hiddenAnalysisTypes = ["student-report"] as any;

export const AnalysisTypesLabels = {
    "students-grade-summary": "studentsGradeSummary",
    "ks4-grade-analysis": "ks4GradeAnalysis",
    "subject-analysis": "subjectAnalysis",
    "atl-analysis": "atlAnalysis",
};

export const AnalysisTypesIcons = {
    "students-grade-summary": mdiBlur,
    "ks4-grade-analysis": mdiBlurLinear,
    "subject-analysis": mdiFinance,
    "atl-analysis": mdiAccountStarOutline,
};

const dispatchActions = (dispatch: Dispatch) => ({
    getAnalysisCohortYearGroups: () => {
        dispatch(AnalysisActions.getAnalysisCohortYearGroups());
    },
});

const AnalysisTypeModal = ({ open, handleClose }) => {
    const { t } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const dispatch = useDispatch();
    const { data: accountInfo } = useAccountInfo();
    const activeCohortId = accountInfo?.activeCohortId || null;
    const { data: userProfile, refetch: getUserProfile } = useProfile();

    const { getAnalysisCohortYearGroups } = dispatchActions(dispatch);

    const {
        analysisCohortYearGroups,
    }: { analysisCohortYearGroups: CommonCohortAnalysisYearGroupResponse[] | null } = useSelector(
        (state: AppState) => ({
            analysisCohortYearGroups: state.analysis.analysisCohortYearGroups,
        }),
        shallowEqual,
    );

    const {
        cohort: cohortParam,
        yearGroup: yearGroupParam,
        analysisType: analysisTypeParam,
    } = useParams();

    const [selectedCohort, setSelectedCohort] = useState<number | null>(
        cohortParam ? parseInt(cohortParam) : null,
    );
    const [selectedYearGroup, setSelectedYearGroup] = useState<number>(
        yearGroupParam ? parseInt(yearGroupParam) : -1,
    );
    const [selectedAnalysis, setSelectedAnalysis] = useState<AnalysisTypes | string | null>(
        analysisTypeParam as string,
    );

    let defaultCohort: number | null = null;
    if (analysisCohortYearGroups && analysisCohortYearGroups?.length > 0) {
        defaultCohort =
            analysisCohortYearGroups?.find(c => activeCohortId && c.id === activeCohortId)?.id ||
            null;

        if (selectedCohort === null && defaultCohort) {
            setSelectedCohort(defaultCohort);
        }
        if (!defaultCohort && analysisCohortYearGroups && selectedCohort === null) {
            setSelectedCohort(analysisCohortYearGroups[0].id);
        }
    }

    const prevSelectedCohort: number | null | undefined = usePrevious(selectedCohort);
    const prevSelectedYearGroup: number | undefined = usePrevious(selectedYearGroup);

    const handleCohortChange = (newCohort: string) => {
        if (parseInt(newCohort) !== prevSelectedCohort) {
            setSelectedCohort(parseInt(`${newCohort}`));
            setSelectedYearGroup(0);
            setSelectedAnalysis(null);
        }
    };

    const handleYearGroupChange = (newYearGroup: string) => {
        if (parseInt(newYearGroup) !== prevSelectedYearGroup) {
            setSelectedYearGroup(parseInt(`${newYearGroup}`));
            setSelectedAnalysis(null);
        }
    };

    const handleAnalysisShow = () => {
        if (selectedAnalysis) {
            navigate(
                ROUTE_ANALYSIS_TAB.replace(":cohort", `${selectedCohort}`)
                    .replace(":yearGroup", `${selectedYearGroup}`)
                    .replace(":tab", selectedAnalysis),
            );
            navigate(1);
        }
        handleClose();
    };

    useEffect(() => {
        if (accountInfo) {
            getAnalysisCohortYearGroups();
        }
        if (!userProfile) getUserProfile();
        // eslint-disable-next-line
    }, [accountInfo, userProfile]);

    let yearGroups: number[] = [];
    if (analysisCohortYearGroups && selectedCohort) {
        yearGroups = analysisCohortYearGroups.find(c => c.id === selectedCohort)?.yearGroup || [];
    }

    useEffect(() => {
        if (activeCohortId && activeCohortId !== selectedCohort) {
            const cohort = analysisCohortYearGroups?.find(acy => acy.id === activeCohortId);
            if (cohort) {
                setSelectedCohort(activeCohortId);
            }
        }
        // eslint-disable-next-line
    }, [activeCohortId]);

    return analysisCohortYearGroups && selectedCohort ? (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            title={t("analysis.modal.header")}
            actions={
                selectedAnalysis ? (
                    <Button onClick={handleAnalysisShow} color="primary">
                        {t("analysis.modal.continue")}
                    </Button>
                ) : null
            }
            contentOverflow="visible"
        >
            <Fragment>
                <Typography gutterBottom>{t("analysis.modal.hint")}</Typography>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <TextField
                            label={t("analysis.modal.cohort")}
                            select
                            SelectProps={{ displayEmpty: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="none"
                            name="cohort"
                            value={selectedCohort}
                            onChange={e => handleCohortChange(`${e.target.value}`)}
                        >
                            {analysisCohortYearGroups &&
                                filterNotNullable(analysisCohortYearGroups).map(obj => (
                                    <MenuItem key={obj.id} value={obj.id}>
                                        {obj.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item sm={4}>
                        <TextField
                            label={t("analysis.modal.yearGroup")}
                            select
                            SelectProps={{ displayEmpty: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="none"
                            name="yearGroup"
                            value={selectedYearGroup}
                            onChange={e => handleYearGroupChange(`${e.target.value}`)}
                        >
                            <MenuItem value={-1}>
                                <Box component="span" fontStyle="italic" color={COLORS.GREY_2}>
                                    {t("analysis.modal.yearGroupSelectNone")}
                                </Box>
                            </MenuItem>
                            {yearGroups.map(obj => (
                                <MenuItem key={obj} value={obj}>
                                    {obj === 0 ? t("common.yg0") : obj}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                {selectedYearGroup > -1 && (
                    <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr 1fr"
                        gap="1px 1px"
                        marginX={-6}
                        marginTop={5}
                        bgcolor={COLORS.VERY_LIGHT_GREY_7}
                    >
                        {userProfile &&
                            userProfile.accessToAnalysisTypes
                                .filter(key => !hiddenAnalysisTypes.includes(key))
                                //TODO it is temporary solution, remove it after API changes
                                .filter(
                                    key =>
                                        !(
                                            key == AnalysisTypes.ATL_ANALYSIS &&
                                            userProfile.userRole == SchoolUserRole.TEACHER &&
                                            !(
                                                userProfile.isLeaderOfAll ||
                                                userProfile.leaderOfSubjectAreas.length > 0
                                            )
                                        ),
                                )
                                .map(key => {
                                    return (
                                        <Box key={key} bgcolor={COLORS.VERY_LIGHT_GREY_1}>
                                            <ChooseButton
                                                onClick={() => {
                                                    setSelectedAnalysis(key);
                                                }}
                                                className={
                                                    selectedAnalysis === key ? "active" : undefined
                                                }
                                            >
                                                <div className="row">
                                                    <div
                                                        className={clsx(
                                                            "icon",
                                                            selectedAnalysis === key && "active",
                                                        )}
                                                    >
                                                        <Icon
                                                            path={AnalysisTypesIcons[key]}
                                                            size="inherit"
                                                        />
                                                    </div>
                                                    <div className="title">
                                                        {t(
                                                            `analysis.types.${AnalysisTypesLabels[key]}`,
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={clsx(
                                                        "subtitle",
                                                        selectedAnalysis === key && "active",
                                                    )}
                                                >
                                                    {t(
                                                        `analysis.types.${AnalysisTypesLabels[key]}Text`,
                                                    )}
                                                </div>
                                            </ChooseButton>
                                        </Box>
                                    );
                                })}
                        <Box bgcolor={COLORS.VERY_LIGHT_GREY_1} />
                        <Box bgcolor={COLORS.VERY_LIGHT_GREY_1} />
                    </Box>
                )}
            </Fragment>
        </Dialog>
    ) : null;
};

export default AnalysisTypeModal;
