import { ApiRequests, ApiEndpoint } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getClassTrackerGroupForecastUnitListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker-group/{classTrackerGroup}/{tier}/forecast/units/`,
    method: ApiRequests.GET,
};

export interface ForecastUnitList {
    id?: number;
    name: string;
    unitId: number;
}
