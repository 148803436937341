import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    ClassTrackerUserSettingsObject,
    TRACKER_USER_SETTINGS_QUERY,
} from "./useTrackerUserSettings";
import { getSchoolAccountId } from "src/services/url";

const apiEditTrackerUserSettings = async (
    classTrackerId: number,
    values: ClassTrackerUserSettingsObject,
) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/user-settings/`,
        values,
    );
};
export const useEditTrackerUserSettings = (
    classTrackerId: number,
    onSuccess?: () => void,
    onError?: (err: any) => void,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: ClassTrackerUserSettingsObject) =>
            apiEditTrackerUserSettings(classTrackerId, values),

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [TRACKER_USER_SETTINGS_QUERY, getSchoolAccountId(), classTrackerId],
            });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
