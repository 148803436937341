import { StringMap } from "@martin_hotell/rex-tils";
import {
    mdiChevronDown,
    mdiChevronUp,
    mdiContentCopy,
    mdiDelete,
    mdiSquareEditOutline,
} from "@mdi/js";
import Icon from "../Icon";
import { ActionMenuItemProps } from "./types";

export enum ActionKeys {
    UP = "up",
    DOWN = "down",
}

const standardMenuActionProps: StringMap<Pick<ActionMenuItemProps, "text" | "icon">> = {
    edit: {
        text: "Edit",
        icon: <Icon path={mdiSquareEditOutline} />,
    },
    show: {
        text: "Show",
        icon: <Icon path={mdiSquareEditOutline} />,
    },
    delete: {
        text: "Delete",
        icon: <Icon path={mdiDelete} />,
    },
    up: {
        text: "Move up",
        icon: <Icon path={mdiChevronUp} />,
    },
    down: {
        text: "Move down",
        icon: <Icon path={mdiChevronDown} />,
    },
    duplicate: {
        text: "Duplicate",
        icon: <Icon path={mdiContentCopy} />,
    },
};

export const getMenuActionProps = (
    key: keyof typeof standardMenuActionProps,
    extra: Partial<ActionMenuItemProps>,
): ActionMenuItemProps => ({
    ...standardMenuActionProps[key],
    ...extra,
});
