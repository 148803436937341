import { TFunction } from "i18next";

export const steps = (t: TFunction): string[] => {
    return [
        `${t("auth.invite.steps.details")}`,
        `${t("auth.invite.steps.credentials")}`,
        `${t("auth.invite.steps.terms")}`,
        `${t("auth.invite.steps.finish")}`,
    ];
};
