import { FC, useState } from "react";
import { Box, Chip, Typography, styled } from "@mui/material";
import COLORS from "src/styles/colors";
import { useTranslation } from "react-i18next";
import { ClassTrackerRelatedObject } from "../../dto/TrackerRelated";
import Icon from "src/components/Icon";
import { mdiChevronDown } from "@mdi/js";
import ChangeClassStyleMenu from "../Common/ChangeClassStyleMenu";

const ClassChooseButton = styled("button")(() => ({
    cursor: "pointer",
    color: COLORS.GREY_1,
    display: "flex",
    alignItems: "center",
    border: "0 none",
    outline: "0 none",
    padding: 0,
    margin: 0,
    backgroundColor: "transparent",
}));
interface OwnProps {
    classTrackerRelatedData: ClassTrackerRelatedObject | null;
}

const TrackerClassGroupDetails: FC<OwnProps> = ({ classTrackerRelatedData }) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            {classTrackerRelatedData && (
                <Chip
                    style={{ backgroundColor: classTrackerRelatedData.subjectArea?.colour }}
                    label={classTrackerRelatedData.subjectArea?.name}
                />
            )}
            <Box
                display="inline-flex"
                flexDirection="column"
                borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                minHeight={45}
                pl={3}
                ml={3}
            >
                {classTrackerRelatedData && (
                    <>
                        <Box fontSize={11} lineHeight={1.36} color={COLORS.LIGHT_GREY_1} mb={0.375}>
                            {`${classTrackerRelatedData.qualification?.name} > ${classTrackerRelatedData.specification?.name}`}
                        </Box>
                        <ClassChooseButton onClick={handleClick}>
                            <>
                                <Typography component="h1" variant="h1">
                                    {`${t("tracker.grid.yearGroup")} ${
                                        classTrackerRelatedData.yearGroup || t("common.yg0")
                                    }`}
                                </Typography>
                                <Icon path={mdiChevronDown} size="30px" />
                            </>
                        </ClassChooseButton>
                    </>
                )}
                <ChangeClassStyleMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    classDetailsYearGroup={classTrackerRelatedData?.yearGroup}
                    classDetailsId={-1}
                    classDetailsGualificationId={classTrackerRelatedData?.qualification?.id}
                    classDetailsSpecificationId={classTrackerRelatedData?.specification?.id}
                />
            </Box>
        </>
    );
};

export default TrackerClassGroupDetails;
