import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { getSchoolAccountId } from "src/services/url";

export const allowedSubjectAreasEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/common/allowed-subject-areas/`,
    method: ApiRequests.GET,
};

export type AllowedSubjectAreasResponse = OrmSubjectArea[];
