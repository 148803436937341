import { CommonActions, CommonActionTypes } from "./actions";
import { filterNotNullable } from "src/services/object";
import { OrmQualification } from "src/orm/models/Qualification";
export interface CommonState {
    allowedSubjectAreas: number[];
    allowedQualifications: { [key: string]: OrmQualification[] } | null;
}

export const initialState: CommonState = {
    allowedSubjectAreas: [],
    allowedQualifications: null,
};

export const reducer = (state: CommonState = initialState, action: CommonActions): CommonState => {
    switch (action.type) {
        case CommonActionTypes.GET_ALLOWED_QUALIFICATION_LIST_SUCCESS:
            return {
                ...state,
                allowedQualifications: {
                    ...state.allowedQualifications,
                    [action.payload.request.payload.params.subjectArea]: action.payload.response,
                },
            };
        case CommonActionTypes.SET_ALLOWED_SUBJECT_AREAS:
            return {
                ...state,
                allowedSubjectAreas: filterNotNullable(action.payload).map(sa => sa.id),
            };
        default:
            return state;
    }
};
