import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface StudentDeactivateRequest {
    id?: number;
}

const apiTitleForUrlMutation = async (url: string) => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/url/description-title/`, {
        url,
    });
};

export const useTitleForUrlMutation = (
    onSuccess?: (data?: { description: string; title: string }) => void,
) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (url: string) => apiTitleForUrlMutation(url),
        onSuccess: response => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
