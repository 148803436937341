import { attr, Model } from "redux-orm";

const fields = {
    id: attr(),
    name: attr(),
    specificationId: attr(),
};

export enum GradeTypes {
    BTEC = "BTEC",
    STANDARD = "Standard",
    COMBINED = "Combined",
}

export interface OrmGrade {
    id: number;
    name: string;
    specificationId?: number;
}

class Grade extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "Grade";
    static fields = fields;
    static emptyRef: OrmGrade = {
        id: 0,
        name: "",
    };

    get ormRef(): OrmGrade {
        return this.ref as any;
    }
}

export default Grade;
