import { Dispatch } from "redux";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { TrackerCompareDataSourceTypes } from "src/modules/tracker/services/valueHandler";
import { AppState } from "src/store/reducers";
import { format, fromUnixTime } from "date-fns";
import { usePrevious } from "src/hooks/usePrevious";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { OrmReportTemplate } from "src/orm/models/ReportTemplate";
import { reportTemplateListSelector } from "src/modules/report/selectors/ReportTempatesSelectors";
import { useCallback } from "react";

const dispatchActions = (dispatch: Dispatch) => ({
    getStudentReport: (classTrackerId: number, studentId: number, fileName: string) => {
        dispatch(TrackerActions.getStudentReportDownload(classTrackerId, studentId, fileName));
    },
    getStudentForecastReport: (classTrackerId: number, studentId: number, fileName: string) => {
        dispatch(
            TrackerActions.getStudentForecastReportDownload(classTrackerId, studentId, fileName),
        );
    },
    getStudentSnapshotReport: (
        classTrackerId: number,
        studentId: number,
        snapshotId: number,
        fileName: string,
    ) => {
        dispatch(
            TrackerActions.getStudentSnapshotReportDownload(
                classTrackerId,
                studentId,
                snapshotId,
                fileName,
            ),
        );
    },
    setReportDownloadState: (classTrackerId: number, studentId: number) => {
        dispatch(TrackerActions.setReportDownloadState(classTrackerId, studentId));
    },
});

export interface ReportDownloadState {
    classTracker: number;
    id: number;
    isDownloading: boolean;
}

const availableFilenameTags = [
    "FirstName",
    "LastName",
    "UniquePupilNumber",
    "AdmissionNumber",
    "CandidateNumber",
    "Form",
];

export const useReportDownloader = () => {
    const dispatch = useDispatch();

    const {
        getStudentReport,
        getStudentForecastReport,
        getStudentSnapshotReport,
        setReportDownloadState,
    } = dispatchActions(dispatch);

    const {
        reportDownloadState,
        reportTemplates,
    }: {
        reportDownloadState: ReportDownloadState[];
        reportTemplates: OrmReportTemplate[];
    } = useSelector(
        (state: AppState) => ({
            reportDownloadState: state.tracker.reportDownloadState,
            reportTemplates: reportTemplateListSelector(state),
        }),
        shallowEqual,
    );

    const defaultTemplate = reportTemplates.find(rt => rt.isDefault);

    const prevReportDownloadState = usePrevious(reportDownloadState) as
        | ReportDownloadState[]
        | undefined;

    const resolveNameByTag = (tag, data) => {
        switch (tag) {
            case "FirstName":
                return data.student_firstName || "";
            case "LastName":
                return data.student_lastName || "";
            case "UniquePupilNumber":
                return data.student_uniquePupilNumber || "";
            case "AdmissionNumber":
                return data.student_admissionNumber || "";
            case "CandidateNumber":
                return data.student_candidateNumber || "";
            case "Form":
                return data.student_form || "";
            default:
                return "";
        }
    };

    const getFileName = useCallback(
        data => {
            const escapeRegExp = string => {
                return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            };
            const replaceAll = (str, find, replace) => {
                return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
            };
            if (defaultTemplate && defaultTemplate.fileNamePattern) {
                let newFilename = defaultTemplate.fileNamePattern;

                availableFilenameTags.forEach(tag => {
                    newFilename = replaceAll(newFilename, `{${tag}}`, resolveNameByTag(tag, data));
                });

                return newFilename;
            }
            return `${data.student_firstName}-${data.student_lastName}-${
                data.student_uniquePupilNumber
                    ? data.student_uniquePupilNumber.replace(" ", "-")
                    : data.student_id
            }`;
        },
        [defaultTemplate],
    );

    const getForecastFileName = data => getFileName(data) + "-forecast";

    const getSnapshotFileName = (context, data) => `${getFileName(data)}-snapshot-
        ${format(
            fromUnixTime(
                context.snapshots?.find(
                    s =>
                        s.id ===
                        (context.snapshotData?.snapshot
                            ? parseInt(context.snapshotData?.snapshot)
                            : 0),
                )?.created || 0,
            ),
            "dd-MMMM-yyyy-hh-mm-ss",
        )}`;

    const getCompareSnapshotFileName = (context, data) => `${getFileName(data)}-snapshot-
    ${format(
        fromUnixTime(
            context.snapshots?.find(
                s =>
                    s.id ===
                    (context.snapshotData?.compare.dataSource1
                        ? parseInt(context.snapshotData?.compare.dataSource1)
                        : 0),
            )?.created || 0,
        ),
        "dd-MMMM-yyyy-hh-mm-ss",
    )}`;

    const handleReportDownloadClick = (context, data, classTrackerId, studentId) => {
        setReportDownloadState(classTrackerId, studentId);
        const fileName = getFileName(data);

        switch (context.dataType) {
            case DataTypeTypes.FORECAST:
                getStudentForecastReport(classTrackerId, studentId, getForecastFileName(data));
                break;
            case DataTypeTypes.SNAPSHOT:
                if (context.snapshotData?.snapshot) {
                    getStudentSnapshotReport(
                        classTrackerId,
                        studentId,
                        parseInt(context.snapshotData.snapshot),
                        getSnapshotFileName(context, data),
                    );
                }
                if (
                    context.snapshotData?.compare?.dataSource1 ===
                    TrackerCompareDataSourceTypes.LIVE_TRACKING
                ) {
                    getStudentReport(classTrackerId, studentId, fileName);
                } else if (
                    context.snapshotData?.compare?.dataSource1 ===
                    TrackerCompareDataSourceTypes.FORECAST
                ) {
                    getStudentForecastReport(classTrackerId, studentId, getForecastFileName(data));
                } else if (context.snapshotData?.compare?.dataSource1) {
                    getStudentSnapshotReport(
                        classTrackerId,
                        studentId,
                        parseInt(context.snapshotData?.compare?.dataSource1),
                        getCompareSnapshotFileName(context, data),
                    );
                }
                break;
            default:
                getStudentReport(classTrackerId, studentId, fileName);
                break;
        }
    };

    return {
        handleReportDownloadClick,
        reportDownloadState,
        prevReportDownloadState,
    };
};
