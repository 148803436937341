import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@mui/material";
import PromptDialog from "src/forms/PromptDialog";

const PromptDeleteConfirm = ({ open, onClose, children }) => {
    const { t } = useTranslation();

    const [tmpDeleteInput, setTmpDeleteInput] = useState<string>("");
    const [disableButtons, setDisableButtons] = useState<boolean>(false);
    useEffect(() => {
        setDisableButtons(false);
    }, [open]);

    return (
        <PromptDialog
            yesLabel={t("common.remove")}
            open={open}
            yesDisabled={tmpDeleteInput !== "delete" || disableButtons}
            noDisabled={disableButtons}
            onClose={confirmed => {
                setTmpDeleteInput("");
                setDisableButtons(true);
                onClose(confirmed);
            }}
        >
            {children}
            <Box pt={3} pb={0}>
                <TextField
                    onChange={e => setTmpDeleteInput(`${e.target.value}`)}
                    placeholder="delete"
                    value={tmpDeleteInput}
                    id="deleteTextField"
                />
            </Box>
        </PromptDialog>
    );
};

export default PromptDeleteConfirm;
