import { ApiData, ApiStatus } from "src/api/constants";
import { useEffect } from "react";
import { usePrevious } from "./usePrevious";

export const useResponse = (func, action: ApiData) => {
    const prevActionStatus = usePrevious(action.status);
    useEffect(() => {
        if (prevActionStatus === ApiStatus.LOADING && action.status !== ApiStatus.LOADING) {
            func();
        }
    }, [action.status, func, prevActionStatus]);
};
