import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ActionsTableButton from "src/components/ActionTableButton";
import { ActionMenuItemProps } from "src/components/ActionTableButton/types";
import { TableColumnProps } from "src/components/CustomTable/types";
import { FC, ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    tableContainer: {
        padding: theme.spacing(3, 6, 5),
    },
    tableCellAction: {
        width: 46,
        padding: theme.spacing(0.5, 0.5, 0.5, 2),
    },
    paginationToolbar: {},
    paginationSelect: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
}));

interface OwnProps<T extends {} = any> {
    columns: TableColumnProps<T>[];
    count: number;
    apiPerPage: number;
    apiPage: number;
    data: T[];
    rowActionLink?: (rowData: T) => string | null;
    onPaginatorChange: (page: number, perPage: number) => void;
    actions?: ActionMenuItemProps<T>[];
}

const ApiTable: FC<OwnProps> = (props): ReactElement => {
    const classes = useStyles();

    const handleChangePage = (event: unknown, newPage: number): void => {
        props.onPaginatorChange(newPage + 1, props.apiPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        const perPage: number = parseInt(event.target.value, 10);
        props.onPaginatorChange(1, perPage);
    };

    let currentPage = 0;
    if (props.data.length > 0) {
        currentPage = Math.min(props.apiPage, Math.ceil(props.count / props.apiPerPage));
    }
    const paginationPage = currentPage - 1;

    return (
        <div className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        {props.columns.map(column => (
                            <TableCell key={column.key}>{column.label}</TableCell>
                        ))}
                        {!!props.actions && (
                            <TableCell className={classes.tableCellAction}>&nbsp;</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                {props.columns.map(column => (
                                    <TableCell key={column.key}>
                                        {column.cellDataGetter
                                            ? column.cellDataGetter({
                                                  dataKey: column.dataKey || column.key,
                                                  columnData: column.columnData,
                                                  rowData: row,
                                              })
                                            : row[column.dataKey || column.key]}
                                    </TableCell>
                                ))}
                                {!!props.actions && (
                                    <TableCell className={classes.tableCellAction}>
                                        <ActionsTableButton actions={props.actions} rowData={row} />
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={props.count}
                rowsPerPageOptions={[10, 20, 50, 100]}
                rowsPerPage={props.apiPerPage}
                page={paginationPage > 0 ? paginationPage : 0}
                backIconButtonProps={{
                    "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                    "aria-label": "Next Page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                classes={{
                    toolbar: classes.paginationToolbar,
                    select: classes.paginationSelect,
                }}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} of ${count.toLocaleString()}`
                }
            />
        </div>
    );
};

export default ApiTable;
