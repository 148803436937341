import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: 700,
    },
}));

const ContactConfirmedStep = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Typography gutterBottom className={classes.bold}>
                {t("auth.register.contactConfirmedStep.header")}
            </Typography>
            <Typography>{t("auth.register.contactConfirmedStep.message")}</Typography>
        </Fragment>
    );
};

export default ContactConfirmedStep;
