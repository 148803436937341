import { Box, Theme, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import logotype from "src/assets/logotype.svg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        maxWidth: 540,
        width: "100%",
        margin: "0 auto",
        paddingRight: 5,
        paddingLeft: 5,
        minHeight: "calc(100vh - 134px)",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: "#fff",
            display: "block",
            minHeight: "calc(100vh - 126px)",
            paddingRight: 0,
            paddingLeft: 0,
        },
    },
    paper: {
        padding: theme.spacing(7, 10),
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3, 2),
            border: 0,
        },
    },
    logo: {
        "& > img": {
            display: "block",
            width: "100%",
            height: "auto",
        },
    },
}));

const AuthContainer = ({ children }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box component="main" className={classes.root}>
            <Paper className={classes.paper}>
                <Box
                    component="figure"
                    width={160}
                    mt={0}
                    mr="auto"
                    mb={4.5}
                    ml="auto"
                    className={classes.logo}
                >
                    <img src={logotype} alt={t("common.logoAlt")} />
                </Box>
                {children}
            </Paper>
        </Box>
    );
};

export default AuthContainer;
