import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Paper } from "@mui/material";
import SummaryBox from "../components/SummaryBox";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { OrmQualification } from "src/orm/models/Qualification";
import PaperInner from "src/components/PaperInner";
import { SubscriptionSubjectArea, SubscriptionQualification } from "src/orm/models/Subscription";

interface OwnProps {
    subjectArea: SubscriptionSubjectArea | null;
    qualifications: SubscriptionQualification[];
    period: string;
    type: string;
}

const AlreadyPaid = ({ subjectArea, qualifications, period, type }: OwnProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("subscription.makePayment.successHeader")}
            </Typography>
            <Paper>
                <PaperInner variant="subscription" border="bottom" color="lightGrey">
                    <Fragment>
                        <Typography variant="h5" component="h2" gutterBottom>
                            {t("subscription.makePayment.placingOrder")}
                        </Typography>
                        <SummaryBox
                            subjectArea={subjectArea as OrmSubjectArea}
                            qualifications={qualifications as OrmQualification[]}
                            period={period}
                            type={type}
                            oneline={true}
                        />
                    </Fragment>
                </PaperInner>
                <PaperInner>
                    <Typography variant="h4" component="h2" gutterBottom>
                        {t("subscription.makePayment.thankyou")}
                    </Typography>
                    <Typography>{t("subscription.makePayment.noCharge")}</Typography>
                    <Typography>{t("subscription.makePayment.successMessage")}</Typography>
                </PaperInner>
            </Paper>
        </>
    );
};

export default AlreadyPaid;
