import CustomTable from "src/components/CustomTable";
import { CellDataGetterParams, TableColumnProps } from "src/components/CustomTable/types";
import { AppState } from "src/store/reducers";
import { Fragment, useState, ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { SubscriptionsActions } from "../store/actions";
import { useMount } from "src/hooks/useMount";
import { OrmInvoice } from "src/orm/models/Invoice";
import { invoicesSelector } from "../selectors/InvoiceSelectors";
import { SubscriptionTypeLabels, SubscriptionTypes } from "src/orm/models/Subscription";
import { convertIsoPeriodToString } from "src/services/translation";
import { Box, TextField, MenuItem, Grid, Paper, Button, Chip } from "@mui/material";
import { format } from "date-fns";
import useDebounce from "src/hooks/useDebounce";
import Icon from "src/components/Icon";
import { mdiFileDocument } from "@mdi/js";
import COLORS from "src/styles/colors";
import { SchoolUserRole } from "src/orm/models/User";
import { useParams } from "react-router";
import { useProfile } from "src/modules/user/hooks/useProfile";

const dispatchActions = (dispatch: Dispatch) => ({
    getInvoices: () => {
        dispatch(SubscriptionsActions.getInvoiceList());
    },
    getXeroInvoice: (id: string) => {
        dispatch(SubscriptionsActions.getXeroInvoice(id));
    },
});

const InvoicesList = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { getInvoices, getXeroInvoice } = dispatchActions(dispatch);
    const [filter, setFilter] = useState<string>(params && params.filter ? params.filter : "");
    const { invoices }: { invoices: OrmInvoice[] } = useSelector((state: AppState) => ({
        invoices: invoicesSelector(state),
    }));
    const { data: authUser } = useProfile();
    const debouncedFilter = useDebounce(filter, 500);

    useMount(() => getInvoices());

    const columns: TableColumnProps<OrmInvoice>[] = [
        {
            key: "paymentDate",
            label: t("subscription.invoiceList.purchased"),
            cellDataGetter: ({ rowData }: CellDataGetterParams<OrmInvoice>) => {
                if (rowData.paymentDate) {
                    return (
                        <>
                            <Box fontSize={14}>
                                {format(new Date(rowData.paymentDate), "dd MMMM Y")}
                            </Box>
                            <Box mt={0.375} fontSize={11}>
                                {format(new Date(rowData.paymentDate), "HH:mm")}
                            </Box>
                        </>
                    );
                }
                return "";
            },
        },
        {
            key: "subscription",
            label: t("common.subscription"),
            cellDataGetter: ({ rowData }: CellDataGetterParams<OrmInvoice>) => {
                if (rowData.subscription.subscriptionType) {
                    return (
                        <Fragment>
                            <Box fontSize={14} fontWeight={700}>
                                {SubscriptionTypeLabels(t)[rowData.subscription.subscriptionType]}
                                {rowData.subscription.qualifications.length > 0 &&
                                    `(${rowData.subscription.qualifications.length} ${t(
                                        rowData.subscription.qualifications.length === 1
                                            ? "common.course"
                                            : "common.courses",
                                    )})`}
                            </Box>
                            {rowData.subscriptionLength ? (
                                <Box mt={0.375} fontSize={11} fontStyle="italic">
                                    {convertIsoPeriodToString(rowData.subscriptionLength)}
                                </Box>
                            ) : null}
                        </Fragment>
                    );
                }
                return "";
            },
        },
        {
            key: "subjectArea",
            label: t("subscription.invoiceList.subjectAreaCourse"),
            cellDataGetter: ({ rowData }: CellDataGetterParams<OrmInvoice>) => {
                if (rowData.subscription) {
                    const sub = rowData.subscription;
                    const subjectArea = sub.subjectArea;
                    return (
                        <Fragment>
                            {subjectArea && (
                                <Chip
                                    label={subjectArea.name}
                                    style={{
                                        backgroundColor: subjectArea.colour,
                                    }}
                                />
                            )}
                            {sub.qualifications && (
                                <Box
                                    component="div"
                                    fontStyle={
                                        rowData.subscription.subscriptionType ===
                                        SubscriptionTypes.FULL_SUITE
                                            ? "italic"
                                            : "normal"
                                    }
                                    fontSize={12}
                                    mt={0.625}
                                    color="inherit"
                                >
                                    {sub.qualifications.map(q => (q.name ? q.name : ""))}
                                </Box>
                            )}
                        </Fragment>
                    );
                }
                return "-";
            },
        },
        {
            key: "totalPrice",
            label: t("subscription.invoiceList.totalPrice"),
            cellDataGetter: ({ rowData }: CellDataGetterParams<OrmInvoice>) => {
                if (rowData.totalPrice) {
                    return (
                        <Fragment>
                            <Box
                                color={COLORS.BLUE_2}
                                fontSize={14}
                                fontWeight={700}
                            >{`£${rowData.totalPrice}`}</Box>
                            {rowData.couponName && (
                                <Box
                                    color={COLORS.GREY_3}
                                    mt={0.375}
                                    fontSize={11}
                                    fontStyle="italic"
                                >
                                    {rowData.couponName}
                                </Box>
                            )}
                        </Fragment>
                    );
                }
                return "";
            },
        },
    ];

    if (
        authUser &&
        (authUser.userRole === SchoolUserRole.FINANCE || authUser.userRole === SchoolUserRole.OWNER)
    ) {
        columns.push({
            key: "fastAction",
            label: "",
            align: "right",
            cellDataGetter: ({ rowData }: CellDataGetterParams<OrmInvoice>) => {
                if (rowData.invoiceUrl) {
                    if (rowData.invoiceUrl.includes("/frontend")) {
                        return (
                            <Button
                                onClick={() => {
                                    getXeroInvoice(`${rowData.id}`);
                                }}
                                variant="text"
                                startIcon={<Icon path={mdiFileDocument} />}
                                disableRipple
                            >
                                {t("subscription.invoiceList.download")}
                            </Button>
                        );
                    }

                    return (
                        <Button
                            href={rowData.invoiceUrl}
                            target="_blank"
                            variant="text"
                            startIcon={<Icon path={mdiFileDocument} />}
                            disableRipple
                        >
                            {t("subscription.invoiceList.download")}
                        </Button>
                    );
                }
                return "";
            },
        });
    }

    const filterOptions: { key: string; option: string }[] = [];
    const convertedInvoices = invoices.map(inv => {
        const sub = inv.subscription;
        const optionKey = `${sub.subscriptionType}-${
            (sub.subjectArea && sub.subjectArea.id) || "0"
        }-${
            sub.qualifications && sub.qualifications.length > 0
                ? sub.qualifications.map(q => q.name).join("-")
                : "0"
        }`;
        const option = `${SubscriptionTypeLabels(t)[sub.subscriptionType]}${
            sub.subscriptionType === SubscriptionTypes.SINGLE
                ? ` (${sub.qualifications.length} ${
                      sub.qualifications.length === 1 ? t("common.course") : t("common.courses")
                  })`
                : ""
        } > ${sub.subjectArea && sub.subjectArea.name}${
            sub.qualifications.length > 0
                ? ` (${sub.qualifications.map(q => (q.name ? q.name : ""))})`
                : ""
        }`;

        if (!filterOptions.find(fo => fo.key === optionKey)) {
            filterOptions.push({ key: optionKey, option });
        }

        return { ...inv, filterOption: { key: optionKey, option } };
    });

    const handleFilterChange: ChangeEventHandler<HTMLInputElement> = e => {
        setFilter(e.target.value);
    };

    const filteredList = convertedInvoices.filter(ci => {
        if (debouncedFilter === "") {
            return true;
        } else {
            return ci.filterOption && ci.filterOption.key === debouncedFilter;
        }
    });

    return (
        <Fragment>
            <Grid container>
                <Grid item sm={4}>
                    <Box mb={4}>
                        <TextField
                            id="filter-by-subscription"
                            label={t("subscription.invoiceList.filter")}
                            value={filter}
                            onChange={handleFilterChange}
                            select
                            margin="none"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            SelectProps={{ displayEmpty: true }}
                        >
                            <MenuItem value={""}>{t("common.all")}</MenuItem>
                            {filterOptions.map(option => (
                                <MenuItem value={option.key} key={option.key}>
                                    {option.option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
            </Grid>
            <Paper>
                <CustomTable data={filteredList} showPaginator columns={columns} />
            </Paper>
        </Fragment>
    );
};

export default InvoicesList;
