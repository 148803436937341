import { FC } from "react";
import clsx from "clsx";
import Icon from "src/components/Icon";
import { mdiLabelOutline, mdiLock } from "@mdi/js";
import { GridApi } from "ag-grid-community";
import Tag from "src/components/Tag";

interface OwnProps {
    context: any;
    displayName: string;
    locked: boolean;
    api: GridApi;
    isAllSubjects: boolean;
    column;
}

export const TagHeader: FC<OwnProps> = ({
    displayName,
    locked,
    isAllSubjects,
    api: gridApi,
    column,
}) => {
    let counter: number = 0;
    gridApi.forEachNode(node => {
        if (node?.data[column.colId] === 1) counter++;
    });

    return (
        <div
            className={clsx(
                "ag-column-header-row",
                "ag-column-header-row--bottom-icon",
                isAllSubjects && "ag-column-header-row--highlight",
            )}
        >
            <div
                className={clsx(
                    "ag-column-header-icon",
                    isAllSubjects && "ag-column-header-icon--highlight",
                )}
            >
                <Icon path={mdiLabelOutline} />
            </div>
            <span className="ag-column-header-label ag-column-header-label--tags">
                <span>{displayName}</span>
                <b className="ag-column-header-label__tags">
                    {counter > 0 && (
                        <span className="ag-column-header-label__tag">
                            <Tag arrow="bottom">{counter}</Tag>
                        </span>
                    )}
                    {locked && (
                        <span className="ag-column-header-label__tag-lock-icon">
                            <Icon path={mdiLock} />
                        </span>
                    )}
                </b>
            </span>
        </div>
    );
};
