import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SubscriptionsActions } from "../actions";
import { InvoiceActionsTypes } from "./actions";
import { OrmInvoiceActions } from "src/orm/models/Invoice/actions";
import { NotificationActions } from "src/notifications/store/actions";
import FileSaver from "file-saver";

const getInvoiceListSuccess = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(InvoiceActionsTypes.GET_INVOICE_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.getInvoiceListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(OrmInvoiceActions.bulkImport(payload.response));
        }),
    );

const createInvoiceSuccess = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(InvoiceActionsTypes.GET_XERO_INVOICE_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.getXeroInvoiceSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            FileSaver.saveAs(payload.response, `invoice.pdf`);
            return of(
                NotificationActions.enqueueSnackbar({
                    message: "Invoice downloaded",
                    variant: "success",
                }),
            );
        }),
    );

export const invoiceEpics = combineEpics(getInvoiceListSuccess, createInvoiceSuccess);
