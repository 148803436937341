import { Grid, Box, Button, MenuItem, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { accountSchema } from "../components/accountSchema";
import PaperInner from "src/components/PaperInner";
import HeadingCounter from "src/components/HeadingCounter";
import { useProfile } from "../hooks/useProfile";
import { FormProvider, RHFTextField } from "src/components/hookFormComponents";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { EditUserProfileRequest, useEditProfile } from "../hooks/useEditProfile";
import { useEffect } from "react";

const YourDetailsForm = ({ selectedSchoolAccountId }: { selectedSchoolAccountId?: number }) => {
    const { t } = useTranslation();

    const { data: coreValues } = useCoreValues();
    const { currentRoles } = coreValues?.staffProperties || {};

    const { mutate: editUserProfile } = useEditProfile(selectedSchoolAccountId);

    const { data: profile, isLoading } = useProfile(selectedSchoolAccountId);

    const handleSubmit = (values: EditUserProfileRequest) => {
        editUserProfile(values);
    };

    const methods = useForm({
        resolver: yupResolver(accountSchema(t)),
        mode: "all",
        defaultValues: {
            title: profile?.title || "",
            firstName: profile?.firstName || "",
            lastName: profile?.lastName || "",
            phone: profile?.phone || "",
            mobile: profile?.mobile || "",
            schoolTitle: profile?.schoolTitle || "",
            jobTitle: profile?.jobTitle || "",
            userCode: profile?.userCode || "",
        },
    });

    const { reset } = methods;

    useEffect(() => {
        reset({
            title: profile?.title || "",
            firstName: profile?.firstName || "",
            lastName: profile?.lastName || "",
            phone: profile?.phone || "",
            mobile: profile?.mobile || "",
            schoolTitle: profile?.schoolTitle || "",
            jobTitle: profile?.jobTitle || "",
            userCode: profile?.userCode || "",
        });
    }, [profile]);

    if (isLoading) {
        return (
            <Box padding={4} textAlign={"left"}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit}>
            <PaperInner>
                <Grid container spacing={4}>
                    <Grid item sm={12}>
                        <HeadingCounter number="1">{t("account.yourDetails")}</HeadingCounter>
                        <Grid container spacing={4}>
                            <Grid item sm={6}>
                                <Grid container spacing={1}>
                                    <Grid item sm={12}>
                                        <RHFTextField
                                            name="title"
                                            select
                                            label={t("common.form.label.title")}
                                            autoFocus
                                            margin="normal"
                                        >
                                            <MenuItem value={""}>{t("common.none")}</MenuItem>
                                            <MenuItem value={`Mr`}>{t("common.mr")}</MenuItem>
                                            <MenuItem value={`Mrs`}>{t("common.mrs")}</MenuItem>
                                            <MenuItem value={`Miss`}>{t("common.miss")}</MenuItem>
                                            <MenuItem value={`Ms`}>{t("common.ms")}</MenuItem>
                                            <MenuItem value={`Dr`}>{t("common.dr")}</MenuItem>
                                            <MenuItem value={`Prof`}>{t("common.prof")}</MenuItem>
                                            <MenuItem value={`Mx`}>{t("common.mx")}</MenuItem>
                                        </RHFTextField>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <RHFTextField
                                            name="firstName"
                                            label={t("common.form.label.firstName")}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <RHFTextField
                                            name="lastName"
                                            label={t("common.form.label.lastName")}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <RHFTextField
                                            type="number"
                                            name="phone"
                                            label={t("common.form.label.phoneOpt")}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <RHFTextField
                                            type="number"
                                            name="mobile"
                                            label={t("common.form.label.mobileOpt")}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6}>
                                <Grid container spacing={1}>
                                    <Grid item sm={12}>
                                        <RHFTextField
                                            name="jobTitle"
                                            label={t("common.form.label.jobTitle")}
                                            margin="normal"
                                        />
                                    </Grid>
                                    {currentRoles?.length > 0 ? (
                                        <Grid item sm={12}>
                                            <RHFTextField
                                                name="schoolTitle"
                                                label={t("common.form.label.schoolTitle")}
                                                margin="normal"
                                                select
                                            >
                                                <MenuItem value={""} id={`scoolRole-none-item`}>
                                                    {t("common.none")}
                                                </MenuItem>
                                                {currentRoles.map(st => {
                                                    return (
                                                        <MenuItem
                                                            key={st}
                                                            value={st}
                                                            id={`scoolRole-${st}-item`}
                                                        >
                                                            {st}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </RHFTextField>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                    <Grid item sm={12}>
                                        <RHFTextField
                                            name="userCode"
                                            label={t("common.form.label.staffCode")}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Button type="submit" color="primary">
                        {t("account.save")}
                    </Button>
                </Box>
            </PaperInner>
        </FormProvider>
    );
};

export default YourDetailsForm;
