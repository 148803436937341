import { attr, Model } from "redux-orm";
import { TFunction } from "i18next";

const fields = {
    id: attr(),
    status: attr(),
    dueDate: attr(),
    isDecline: attr(),
    subscriptionType: attr(),
    subjectArea: attr(),
    qualifications: attr(),
    startDate: attr(),
    subscriptionLength: attr(),
    upgradeWholeSchool: attr(),
    extend: attr(),
    upgradeSingle: attr(),
    buyAfterTrial: attr(),
    hasInvoices: attr(),
    vatValue: attr(),
};

export enum SubscriptionStatus {
    ACTIVE = "active",
    EXPIRED = "expired",
    TRIAL = "trial",
    CANCELLED = "cancelled",
    PENDING = "pending",
    PAID = "paid",
}

export const SubscriptionStatusLabels = (t: TFunction) => ({
    [SubscriptionStatus.ACTIVE]: t("subscription.status.active"),
    [SubscriptionStatus.EXPIRED]: t("subscription.status.expired"),
    [SubscriptionStatus.TRIAL]: t("subscription.status.trial"),
    [SubscriptionStatus.CANCELLED]: t("subscription.status.cancelled"),
    [SubscriptionStatus.PENDING]: t("subscription.status.pending"),
    [SubscriptionStatus.PAID]: t("subscription.status.paid"),
});

export enum SubscriptionTypes {
    SINGLE = "single",
    FULL_SUITE = "full_suite",
    WHOLE_SCHOOL = "whole_school",
}

export const SubscriptionTypeLabels = (t: TFunction) => ({
    [SubscriptionTypes.SINGLE]: t("subscription.type.single"),
    [SubscriptionTypes.FULL_SUITE]: t("subscription.type.fullSuite"),
    [SubscriptionTypes.WHOLE_SCHOOL]: t("subscription.type.wholeSchool"),
});

export interface SubscriptionQualification {
    id: number | null;
    name: string;
    numberOfLiveTrackers: number;
}

export interface SubscriptionSubjectArea {
    id: number | null;
    name: string;
    colour?: string;
}

export interface OrmSubscription {
    id: number;
    status: string;
    dueDate: string;
    isDecline: boolean;
    subscriptionType: string;
    subjectArea: SubscriptionSubjectArea;
    qualifications: SubscriptionQualification[];
    startDate: string;
    subscriptionLength: string;
    upgradeWholeSchool: boolean;
    extend: boolean;
    upgradeSingle: boolean;
    buyAfterTrial: boolean;
    hasInvoices?: boolean;
    subStatus?: string | null;
    vatValue?: string | null;
    canConvertToNahtSuppported?: boolean;
    extendNaht?: boolean;
}

class Subscription extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "Subscription";
    static fields = fields;
    static emptyRef: OrmSubscription = {
        id: 0,
        status: "",
        dueDate: "",
        isDecline: true,
        subscriptionType: "",
        subjectArea: { id: null, name: "" },
        qualifications: [],
        startDate: "",
        subscriptionLength: "",
        upgradeWholeSchool: true,
        extend: true,
        extendNaht: false,
        upgradeSingle: true,
        buyAfterTrial: true,
    };

    get ormRef(): OrmSubscription {
        return this.ref as any;
    }
}

export default Subscription;
