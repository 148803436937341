import { Box, MenuItem, TextField, Typography, Grid, Autocomplete } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiStatus } from "src/api/constants";
import { HTTP_NO_CONTENT } from "src/config/globals";
import useDebounce from "src/hooks/useDebounce";
import { useUserReportValidation } from "../../hooks/useUserReportValidation";
import ReportActionsContainer from "./ReportActionsContainer";
import { useCohortSnapshotList } from "src/modules/school/hooks/query/CohortSnapshot/useCohortSnapshotList";
import { useCreateReportByTagMutation } from "src/modules/tagging/hooks/useCreateReportByTagMutation";
import { useSearchTag } from "src/modules/tagging/hooks/useSearchTag";
import { useNumberOfStudentsByTag } from "../../hooks/useNumberOfStudentsByTag";

type TagInfo = { label: string; value: string };
const StudentReportByStudentTab = ({ cohort, yearGroup, reportTemplates }) => {
    const { t } = useTranslation();
    const [selectedReportTemplate, setSelectedReportTemplate] = useState<number>(0);
    const { enqueueSnackbar } = useSnackbar();
    const [locked, setLocked] = useState(false);
    const [selectedTag, setSelectedTag] = useState<TagInfo | undefined>(undefined);
    const [search, setSearch] = useState<string>("");
    const debouncedSearch = useDebounce(search, 500);
    const [selectedSnapshot, setSelectedSnapshot] = useState<number | null>(0);
    const { apiValidation, checkValidation } = useUserReportValidation();

    const { data: snapshots, refetch: refetchSnapshots } = useCohortSnapshotList(cohort, {
        onlyCreated: true,
    });

    const { mutate: createReport, isError: isCreateReportError } = useCreateReportByTagMutation(
        () => {
            setLocked(false);
            enqueueSnackbar(t("report.studentReport.byTag.requestSuccess"), {
                variant: "success",
            });
        },
        checkValidation,
    );

    useEffect(() => {
        if (reportTemplates && reportTemplates.length > 0 && selectedReportTemplate === 0) {
            const defaultTemplate = reportTemplates.find(rt => rt.isDefault);
            setSelectedReportTemplate(defaultTemplate ? defaultTemplate.id : reportTemplates[0].id);
        }
    }, [reportTemplates]);

    const { data: filteredTags } = useSearchTag(cohort, debouncedSearch);

    const { data: numberOfStudents } = useNumberOfStudentsByTag(
        cohort,
        yearGroup,
        parseInt(selectedTag?.value),
    );

    useEffect(() => {
        refetchSnapshots();
        setSelectedTag(undefined);
    }, [cohort, yearGroup]);

    useEffect(() => {
        checkValidation();
    }, [filteredTags, numberOfStudents]);

    const requestReport = (onlySummary: boolean) => {
        if (selectedTag && cohort && yearGroup > -1) {
            createReport({
                cohort,
                yearGroup,
                tag: { id: parseInt(selectedTag.value) },
                reportTemplate: { id: selectedReportTemplate },
                schoolSnapshot: selectedSnapshot ? { id: selectedSnapshot } : undefined,
                onlySummary: !!onlySummary,
            });
        }
    };

    useEffect(() => {
        if (apiValidation.status === ApiStatus.ERROR && isCreateReportError) {
            setLocked(true);
        }
        if (
            apiValidation.status === ApiStatus.SUCCESS &&
            apiValidation.responseStatus === HTTP_NO_CONTENT &&
            isCreateReportError
        ) {
            setLocked(false);
        }
    }, [isCreateReportError, apiValidation]);
    const filteredSnapshots = snapshots?.filter(({ isCreated }) => isCreated);
    return (
        <Box pr="9.5%">
            <Typography variant="h6" gutterBottom>
                {t("report.studentReport.byTag.header")}
            </Typography>
            <Box sx={{ width: "50%" }}>
                <Grid container spacing={4}>
                    <Grid item sm={12}>
                        <Typography>{t("report.studentReport.byTag.hint")}</Typography>
                    </Grid>
                    {filteredSnapshots?.length > 0 && (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.byTag.source")}
                                onChange={event =>
                                    setSelectedSnapshot(parseInt(`${event.target.value}`))
                                }
                                fullWidth
                                value={selectedSnapshot}
                                select
                            >
                                <MenuItem key={0} value={0}>
                                    {t("report.studentReport.byTag.liveTracking")}
                                </MenuItem>
                                {filteredSnapshots.map(s => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}

                    <Grid item sm={8}>
                        <Autocomplete
                            freeSolo
                            options={filteredTags || []}
                            onInputChange={(_event, newInputValue, reason) => {
                                if (reason === "reset") {
                                    setSelectedTag(undefined);
                                }
                                setSearch(newInputValue);
                            }}
                            onChange={(_e, value) => {
                                setSelectedTag(value as TagInfo);
                            }}
                            renderInput={params => {
                                return (
                                    <TextField
                                        {...params}
                                        label={t(
                                            "report.studentReport.byTag.autocompletePlaceholder",
                                        )}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                );
                            }}
                            forcePopupIcon={false}
                        />
                    </Grid>
                    {reportTemplates && reportTemplates.length > 0 ? (
                        <Grid item sm={8}>
                            <TextField
                                label={t("report.studentReport.tabs.reportTemplate")}
                                value={selectedReportTemplate}
                                onChange={e => {
                                    setSelectedReportTemplate(parseInt(e.target.value));
                                }}
                                select
                            >
                                {reportTemplates.map(s => {
                                    return (
                                        <MenuItem value={s.id} key={s.id}>
                                            {s.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid item sm={12}>
                        <ReportActionsContainer
                            studentsCount={numberOfStudents}
                            refreshReportHandler={() => checkValidation()}
                            requestReportHandler={(onlySummary: boolean) =>
                                requestReport(onlySummary)
                            }
                            showOnlySummaryCheckbox={true}
                            locked={locked}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default StudentReportByStudentTab;
