import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";
import { SCHOOL_STAFF_LIST_QUERY } from "./useStaffList";

const apiRemoveStaff = async (id: number): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`school/${getSchoolAccountId()}/staff/${id}/`);
};

export const useRemoveStaff = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => apiRemoveStaff(id),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [SCHOOL_STAFF_LIST_QUERY, getSchoolAccountId()],
            });
            if (onSuccess) {
                onSuccess();
            }
            enqueueSnackbar(t("common.removed"), { variant: "success" });
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
