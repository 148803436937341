import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmClassTrackerActions, OrmClassTrackerActionTypes } from "./actions";

export const classTrackerReducer: ModelReducer = {
    [OrmClassTrackerActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmClassTrackerActions.bulkImport>,
        session,
    ) => {
        const { ClassTracker } = session;

        ClassTracker.all().delete();

        action.payload.data.data.forEach(ct =>
            ClassTracker.create(
                ormToRef(
                    ct,
                    action.payload.cohort ? { defaults: { cohortId: action.payload.cohort } } : {},
                ),
            ),
        );
    },
    [OrmClassTrackerActionTypes.UPSERT]: (
        action: ReturnType<typeof OrmClassTrackerActions.upsert>,
        session,
    ) => {
        const { ClassTracker } = session;
        ClassTracker.upsert(
            ormToRef(action.payload, {
                defaults: { isFullyloaded: true },
            }),
        );
    },
    [OrmClassTrackerActionTypes.CLEAR]: (
        action: ReturnType<typeof OrmClassTrackerActions.clear>,
        session,
    ) => {
        const { ClassTracker, ClassTrackerGroup } = session;
        ClassTracker.all().delete();
        ClassTrackerGroup.all().delete();
    },
};
