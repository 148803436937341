import { MenuItem, TextField } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiltersShape } from "src/modules/common/components/Grid/GridStudentFilter";
import RangeFilterComponent from "./RangeFilterComponent";

interface OwnProps {
    handleFiltersChange: (filter: FiltersShape) => void;
    specifications;
}

const KS4Filter: FC<OwnProps> = ({ handleFiltersChange, specifications }) => {
    const { t } = useTranslation();
    const [specColumn, setSpecColumn] = useState<string>("");

    return (
        <>
            <TextField
                value={specColumn}
                label={t("common.filterBySpecificationColumn")}
                onChange={e => setSpecColumn(e.target.value as string)}
                margin="normal"
                select
                SelectProps={{
                    displayEmpty: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            >
                <MenuItem value={""}>{t("common.noneFilter")}</MenuItem>
                {specifications &&
                    specifications.map(s => {
                        return (
                            <MenuItem key={s.field} value={s.field}>
                                {s.parentNodeName ? s.parentNodeName + " | " : ""}
                                {s.subject ? s.subject + " | " : ""}
                                {s.name}
                            </MenuItem>
                        );
                    })}
            </TextField>
            <RangeFilterComponent
                handleFiltersChange={handleFiltersChange}
                columnName={specColumn}
                columnDisplayName={
                    specifications.find(o => o && o.field === specColumn)?.name || ""
                }
                filterType="summary"
                noDataEnabled={true}
                onSubmit={() => setSpecColumn("")}
                rangeMin={-9}
                rangeMax={9}
            />
        </>
    );
};

export default KS4Filter;
