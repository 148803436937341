import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

const apiCreateSnapshot = async (classTrackerGroupId: number, name: string) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/snapshot/`,
        {
            name,
        },
    );
};
export const useCreateSnapshotMutation = (onSuccess: (data: { id: number }) => void, onError?) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({
            classTrackerGroupId,
            name,
        }: {
            classTrackerGroupId: number;
            name: string;
        }) => apiCreateSnapshot(classTrackerGroupId, name),

        onSuccess: ({ data }: { data: { id: number } }) => {
            enqueueSnackbar(t("tracker.snapshot.snapshotCreatedMsg"), { variant: "success" });
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error && typeof err?.response?.data?.error === "string") {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
                enqueueSnackbar(t("class.list.snapshotError"), {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
