import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export type StudentRestrictedPropsResponse = string[];

export const STUDENT_RESTRICTED_PROPS_QUERY = "studentRestrictedPropsQuery";

export const useStudentRestrictedProps = () => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [STUDENT_RESTRICTED_PROPS_QUERY, schoolAccountId],
        queryFn: async (): Promise<string[] | null> => {
            const { data } = await axiosInstance.get(
                `api/group-call/student/restricted-props/`,
                {},
            );

            return data || null;
        },
        enabled: false,
    });
};
