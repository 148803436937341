import { TFunction } from "i18next";
import * as yup from "yup";

export const loginSchema = (t: TFunction) =>
    yup.object().shape({
        email: yup
            .string()
            .email(t("common.form.validation.email.type"))
            .required(t("common.form.validation.email.required")),
        password: yup.string().required(t("common.form.validation.password.required")),
        responseToken: yup
            .string()
            .min(20, t("common.form.validation.responseToken.required"))
            .typeError(t("common.form.validation.responseToken.required"))
            .required(t("common.form.validation.responseToken.required")),
    });
