import { Box, Button, Chip, MenuItem, Select, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mdiArrowLeft } from "@mdi/js";
import clsx from "clsx";

import { useTranslation } from "react-i18next";
import Icon from "src/components/Icon";
import { ListObject } from "src/forms/types";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import COLORS from "src/styles/colors";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";
import {
    SubjectAnalysisQualification,
    SubjectAnalysisSubjectArea,
    SubjectAnalysisSubjectAreaListResponse,
} from "../../hooks/SubjectAnalysis/useSubjectAnalysisSubjectAreasWithSpecifications";

const useStyles = makeStyles((theme: Theme) => ({
    inner: {
        padding: theme.spacing(4),
        [theme.breakpoints.up(1400)]: {
            padding: theme.spacing(4, 12.5),
        },
    },
    innerBorderBottom: {
        borderBottom: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
    },
    header: {
        display: "flex",
        alignItems: "flex-start",
    },
    subjectMeta: {
        display: "flex",
        padding: theme.spacing(3, 4),
        borderRadius: 2,
        backgroundColor: COLORS.VERY_LIGHT_GREY_1,
        margin: theme.spacing(0, 7, 0, 0),
    },
    subjectMetaItem: {
        display: "flex",
        flexDirection: "column",

        "& + &": {
            borderLeft: `1px solid ${COLORS.LIGHT_GREY_2}`,
            padding: theme.spacing(0, 0, 0, 5),
            margin: theme.spacing(0, 0, 0, 5),
        },
    },
    subjectMetaBadge: {
        margin: theme.spacing(0.75, 0, 0),
    },
    subjectMetaLabel: {
        color: COLORS.GREY_3,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: 400,
        fontFamily: PRIMARY_FONT_FAMILY,
    },
    subjectMetaText: {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        margin: theme.spacing(0.25, 0, 0),
    },
}));

const SubjectAnalysisHeader = ({
    subjectAnalysisSubjects,
    subjectArea,
    qualification,
    specification,
    classTrackers,
    selectedClassTrackers,
    handleSelectSpecification,
    handleClassesChange,
}: {
    subjectAnalysisSubjects: SubjectAnalysisSubjectAreaListResponse | null;
    subjectArea: number | null;
    qualification: number | null;
    specification: number | null;
    classTrackers?: OrmClassTracker[] | null;
    selectedClassTrackers: number[] | null;
    handleSelectSpecification: () => void;
    handleClassesChange?: (data) => void;
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    let subjectAreaObj: SubjectAnalysisSubjectArea | null = null;
    let qualificationObj: SubjectAnalysisQualification | null = null;
    let specificationObj: ListObject<number> | null = null;

    if (subjectAnalysisSubjects) {
        subjectAreaObj = subjectAnalysisSubjects?.find(sa => sa.id === subjectArea) || null;
    }

    if (subjectAreaObj) {
        qualificationObj =
            subjectAreaObj?.qualifications?.find(q => q.id === qualification) || null;
    }

    if (qualificationObj) {
        specificationObj =
            qualificationObj?.specifications?.find(s => s.id === specification) || null;
    }

    return (
        <Box className={clsx(classes.inner, classes.innerBorderBottom)}>
            <Box className={classes.header}>
                {subjectArea && qualification && specification && (
                    <Box className={classes.subjectMeta}>
                        {subjectAreaObj && (
                            <Box className={classes.subjectMetaItem}>
                                <Box className={classes.subjectMetaLabel}>
                                    {t("analysis.subjectAnalysis.subjectArea")}
                                </Box>
                                <Chip
                                    style={{
                                        backgroundColor: subjectAreaObj.colour || COLORS.BLUE_1,
                                    }}
                                    label={subjectAreaObj.name}
                                    className={classes.subjectMetaBadge}
                                />
                            </Box>
                        )}
                        {qualificationObj && (
                            <Box className={classes.subjectMetaItem}>
                                <Box className={classes.subjectMetaLabel}>
                                    {t("analysis.subjectAnalysis.qualification")}
                                </Box>
                                <Box className={classes.subjectMetaText}>
                                    {qualificationObj.name}
                                </Box>
                            </Box>
                        )}
                        {specificationObj && (
                            <Box className={classes.subjectMetaItem}>
                                <Box className={classes.subjectMetaLabel}>
                                    {t("analysis.subjectAnalysis.specification")}
                                </Box>
                                <Box className={classes.subjectMetaText}>
                                    {specificationObj.name}
                                </Box>
                            </Box>
                        )}
                        {classTrackers && handleClassesChange && (
                            <Box className={classes.subjectMetaItem}>
                                <Box className={classes.subjectMetaLabel}>
                                    {t("analysis.subjectAnalysis.classes")}
                                </Box>
                                <Box className={classes.subjectMetaText}>
                                    <Select
                                        multiple
                                        displayEmpty
                                        name="classTrackers"
                                        fullWidth
                                        label="Class trackers"
                                        value={selectedClassTrackers}
                                        onChange={(e, child) => {
                                            const values = e.target.value as any[];
                                            if ((child as any)?.props?.value === "0") {
                                                handleClassesChange([0]);
                                            } else {
                                                handleClassesChange(values.filter(v => v !== 0));
                                            }
                                        }}
                                    >
                                        <MenuItem value="0">
                                            {t("analysis.subjectAnalysis.allClasses")}
                                        </MenuItem>
                                        {classTrackers &&
                                            classTrackers.length > 0 &&
                                            classTrackers.map(ct => {
                                                return (
                                                    <MenuItem key={ct.id} value={ct.id}>
                                                        {ct.name}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
                <Box mt={1}>
                    <Button
                        variant="text"
                        onClick={handleSelectSpecification}
                        startIcon={<Icon path={mdiArrowLeft} />}
                        disableRipple
                    >
                        {t("analysis.subjectAnalysis.chooseDifferentCourse")}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default SubjectAnalysisHeader;
