import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { OrmQualification } from "src/orm/models/Qualification";

export const QUALIFICATIONS_LIST_QUERY = "qualificationsList";

export const useQualificationsList = (subjectAreaId: number) => {
    return useQuery({
        queryKey: [QUALIFICATIONS_LIST_QUERY, subjectAreaId],
        queryFn: async (): Promise<OrmQualification[]> => {
            if (subjectAreaId > 0) {
                const { data } = await axiosInstance.get(
                    `api/common/subject-areas/${subjectAreaId}/qualifications/`,
                );
                return data || [];
            }
            return [];
        },
    });
};
