import { FormControlLabel, Theme, Switch, Badge } from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import COLORS from "src/styles/colors";
import { useLocation } from "react-router";

export const CustomFormControlLabel = withStyles(({ typography }: Theme) =>
    createStyles({
        label: {
            color: COLORS.GREY_2,
            fontSize: typography.pxToRem(13),
            fontWeight: 700,
            textTransform: "uppercase",
        },
    }),
)(FormControlLabel);

const FilterSwitch = withStyles(() =>
    createStyles({
        switchBase: {
            "&$checked": {
                color: COLORS.GREEN_1,
            },
            "&$checked + $track": {
                backgroundColor: COLORS.GREEN_1,
            },
        },
        checked: {},
        track: {},
    }),
)(Switch);

interface OwnProps {
    isChecked: boolean;
    handleChange: () => void;
    disabled: boolean;
}

const GridFiltersToggleButton: FC<OwnProps> = ({ disabled, isChecked, handleChange }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const currentParams = Object.fromEntries(new URLSearchParams(location.search));
    const excludedParams = [
        "dataType",
        "viewGrade",
        "viewType",
        "dataSource1",
        "dataSource2",
        "snapshotDataSource",
    ];
    const numberOfFilters = Object.keys(currentParams)
        .filter(key => !excludedParams.includes(key))
        .reduce((acc, key) => {
            let paramData = [];
            try {
                paramData = JSON.parse(currentParams[key]);
                if (!Array.isArray(paramData)) {
                    throw new Error("Parameter is not an array!");
                }
            } catch {
                //needed for backward compatibility
                paramData = [currentParams[key]];
            }

            return acc + paramData.length;
        }, 0);
    return (
        <CustomFormControlLabel
            disabled={disabled}
            control={
                <FilterSwitch name="filtersVisible" checked={isChecked} onChange={handleChange} />
            }
            label={
                <Badge
                    color="primary"
                    badgeContent={numberOfFilters}
                    invisible={!(numberOfFilters > 0)}
                >
                    {t("common.showFiltersSwitch")}
                </Badge>
            }
        />
    );
};

export default GridFiltersToggleButton;
