import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

const apiResetPassword = async (email: string): Promise<AxiosResponse> => {
    return await axiosInstance.post(`api/auth/reset-password/`, { email });
};

export const useResetPassword = (
    onSuccess?: () => void,
    onError?: (err: AxiosError<{ error?: string; errors?: any }>) => void,
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (email: string) => apiResetPassword(email),
        onSuccess: () => {
            if (onSuccess) onSuccess();
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("common.unknownError"), {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError) onError(err);
        },
    });
};
