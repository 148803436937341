import { AppState } from "src/store/reducers";
import { createSelector, ORMCommonState, SessionWithModels } from "redux-orm";
import orm from ".";
import { OrmModelClass, OrmModelInstance } from "./types";

export const selectorFactory = (...args): ((state: AppState) => any) =>
    createSelector(orm, state => state.orm, ...args) as any;

export const selectAll = <T extends object>(model: OrmModelClass<T>): ((state: AppState) => T[]) =>
    selectorFactory((session: SessionWithModels<ORMCommonState>): T[] =>
        session[model.modelName]
            .all()
            .toModelArray()
            .map(m => m.ormRef),
    );

export const selectAllWithFilter =
    <T extends object>(
        model: OrmModelClass<T>,
    ): ((state: AppState, filterCallback: (model: T) => boolean) => T[]) =>
    (state: AppState, filterCallback: (model: T) => boolean): T[] =>
        selectorFactory((session: SessionWithModels<ORMCommonState>): T[] =>
            session[model.modelName]
                .all()
                .filter(filterCallback)
                .toModelArray()
                .map(m => m.ormRef),
        )(state);

export const selectOneWithFilter =
    <T extends object>(
        model: OrmModelClass<T>,
    ): ((state: AppState, filterCallback: (model: T) => boolean) => T) =>
    (state: AppState, filterCallback: (model: T) => boolean): T =>
        selectorFactory((session: SessionWithModels<ORMCommonState>): T => {
            const result: T = model.emptyRef;
            const filtered = session[model.modelName].all().filter(filterCallback).first();

            if (filtered) {
                return filtered.ormRefDetails;
            }

            return result;
        })(state);

export const selectAllWithCondition =
    <T extends object, ID extends number | string = number>(
        model: OrmModelClass<T>,
        idSelector: (state: AppState) => ID | null,
    ): ((state: AppState, condition: ID) => T[]) =>
    (state: AppState, condition: ID) =>
        idSelector(state) === condition ? selectAll<T>(model)(state) : [];

export const selectAllWithFilterAndCondition =
    <T extends object, ID extends number | string = number>(
        model: OrmModelClass<T>,
        idSelector: (state: AppState) => ID | null,
    ): ((state: AppState, filterCallback: (model: T) => boolean, condition: ID) => T[]) =>
    (state: AppState, filterCallback: (model: T) => boolean, condition: ID): T[] =>
        idSelector(state) === condition ? selectAllWithFilter<T>(model)(state, filterCallback) : [];

export const selectById = <T extends object>(
    model: OrmModelClass<T>,
    idSelector: (state: AppState) => string | number | null,
): ((state: AppState) => T) =>
    selectorFactory(
        idSelector,
        (session: SessionWithModels<ORMCommonState>, id: number | string | null): T => {
            const result: T = model.emptyRef;
            if (id === null) {
                return result;
            }

            const instance: OrmModelInstance<any, T> | null = session[model.modelName].withId(
                id as any,
            );

            if (instance) {
                return instance.ormRefDetails;
            }

            return result;
        },
    );

export const selectByGivenId =
    <T extends object, ID = string | number>(
        model: OrmModelClass<T>,
    ): ((state: AppState, id?: ID) => T) =>
    (state: AppState, id?: ID) =>
        selectorFactory((session: SessionWithModels<ORMCommonState>): T => {
            const result: T = model.emptyRef;
            if (id === undefined) {
                return result;
            }
            const instance: OrmModelInstance<any, T> | null = session[model.modelName].withId(
                id as any,
            );

            if (instance) {
                return instance.ormRefDetails;
            }

            return result;
        })(state);
