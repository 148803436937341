/* eslint-disable react/display-name */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import { MenuItem } from "@mui/material";
import {
    StudentAttributeStyledCustomInput,
    StudentAttributeStyledCustomSelect,
} from "./StyledCustomEditors";
import { usePrevious } from "src/hooks/usePrevious";

interface OwnProps {
    value: string;
    api: GridApi;
    attributeValues: { id: number; name: string }[];
}

const StudentAttributeEditor = forwardRef(
    ({ value: propsValue, api: gridApi, attributeValues }: OwnProps, ref) => {
        const [value, setValue] = useState(propsValue || "");
        const [open, setOpen] = useState<boolean>(true);
        const refInput = useRef(null) as any;

        const handleChange = (value: string) => {
            setValue(value);
            setOpen(false);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleOpen = () => {
            setOpen(true);
        };

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    return value;
                },
            };
        });

        const prevValue = usePrevious(value);

        useEffect(() => {
            if (prevValue !== undefined && prevValue !== value) {
                gridApi.stopEditing();
            }
            //eslint-disable-next-line
        }, [value]);

        return (
            <div className="ag-editor-cell">
                <StudentAttributeStyledCustomSelect
                    ref={refInput}
                    value={value || ""}
                    onChange={e => handleChange(e.target.value as string)}
                    input={<StudentAttributeStyledCustomInput />}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    fullWidth
                >
                    <MenuItem value=""></MenuItem>
                    {attributeValues?.map((grade: { id: number; name: string }) => (
                        <MenuItem key={grade.id} value={grade.id}>
                            {grade.name}
                        </MenuItem>
                    ))}
                </StudentAttributeStyledCustomSelect>
            </div>
        );
    },
);

export default StudentAttributeEditor;
