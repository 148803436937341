import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmSubscriptionHistoryActions, OrmSubscriptionHistoryActionTypes } from "./actions";

export const subscriptionHistoryReducer: ModelReducer = {
    [OrmSubscriptionHistoryActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmSubscriptionHistoryActions.bulkImport>,
        session,
    ) => {
        const { SubscriptionHistory } = session;
        SubscriptionHistory.all().delete();
        action.payload.forEach(s => SubscriptionHistory.create(ormToRef(s, {})));
    },
    [OrmSubscriptionHistoryActionTypes.UPSERT]: (
        action: ReturnType<typeof OrmSubscriptionHistoryActions.upsert>,
        session,
    ) => {
        const { SubscriptionHistory } = session;
        SubscriptionHistory.upsert(
            ormToRef(action.payload, {
                defaults: {},
            }),
        );
    },
};
