import AppContainer from "src/components/AppContainer";
import FormikRef from "src/forms/FormikRef";
import UploadClassSuccess from "../../components/UploadClass/UploadClassSuccess";
import Icon from "src/components/Icon";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import UploadClassCourseSummary from "../../components/UploadClass/UploadClassCourseSummary";
import UploadClassCourseData from "../../components/UploadClass/UploadClassCourseData";
import PaperInner from "src/components/PaperInner";
import { ApiData, ApiStatus } from "src/api/constants";
import { FileType } from "src/forms/types";
import {
    AttributeGroup,
    AttributeItem,
    AttributeValueExtended,
} from "src/orm/models/SpecificationAttribute";
import { AppState } from "src/store/reducers";
import { Field, FieldArray, Form, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { createRef, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ClassActions } from "../../store/actions";
import { uploadClassSchema } from "../../components/ClassTracking/forms/addClassSchema";
import { apiUrl, HTTP_NO_CONTENT } from "src/config/globals";
import { Accordion, AccordionDetails, Box, Button, Grid, Paper, Typography } from "@mui/material";
import { mdiPlus } from "@mdi/js";
import { TextField } from "formik-mui";
import { FormError, getErrorMessage } from "src/services/error";
import { baseHeaders, post } from "src/services/ajax";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface UploadClassFormCourse {
    specification: number | null;
    attributes: AttributeValueExtended[];
    items: AttributeItem[];
    groups: AttributeGroup[];
    disabledItems: number[];
    fileNames: string[];
    realFileNames: { [key: string]: string };
    fileQueue: (FileType & { tmpFileName: string })[];
    files: FileType[];
}

export interface UploadClassForm {
    classToUploadData: UploadClassFormCourse[];
    comment: string;
}
interface UploadErrorStatus {
    status: ApiStatus.NONE | ApiStatus.LOADING | ApiStatus.SUCCESS | ApiStatus.ERROR;
    response: any;
}
const dispatchActions = (dispatch: Dispatch) => ({
    uploadClass: values => {
        dispatch(ClassActions.uploadAndSendClass(values));
    },
});

export const convertSingleCourseToRequest = (classToUploadData: UploadClassFormCourse) => {
    return {
        specification: { id: classToUploadData.specification },
        fileNames: classToUploadData.realFileNames
            ? Object.keys(classToUploadData.realFileNames).map(tmpFileName => ({
                  tmpFileName,
                  fileName: classToUploadData.realFileNames[tmpFileName],
              }))
            : [],
        attributes: classToUploadData.attributes
            .filter(
                attr =>
                    (attr.isSelected === true || attr.isMandatory === true) &&
                    attr.attributeItemId &&
                    !classToUploadData?.disabledItems?.includes(attr.attributeItemId),
            )
            .map(attr => ({
                customName: attr.customName || null,
                attributeValue: { id: attr.id },
            })),
    };
};

export const convertFormDataToRequest = (values: UploadClassForm) => {
    return {
        classToUploadData: values.classToUploadData.map(v => ({
            ...convertSingleCourseToRequest(v),
        })),
        comment: values.comment,
    };
};

const AddClassOtherUploadClassContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const form = createRef() as any;
    const { enqueueSnackbar } = useSnackbar();
    const { uploadClass } = dispatchActions(dispatch);
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [expandedPanel, setExpandedPanel] = useState<number | null>(0);
    const [classToUploadErrors, setClassToUploadErrors] = useState<{
        [key: string]: UploadErrorStatus;
    }>({});
    const {
        apiUpload,
    }: {
        apiUpload: ApiData;
    } = useSelector((state: AppState) => ({
        apiUpload: state.api.class.uploadAndSendClass,
    }));

    const emptyClassToUpload = {
        specification: null,
        attributes: [],
        items: [],
        groups: [],
        disabledItems: [],
        fileNames: [],
        realFileNames: {},
        fileQueue: [],
        files: [],
    };

    const initialValues: UploadClassForm = {
        classToUploadData: [emptyClassToUpload],
        comment: "",
    };

    const handleSubmit = (values: UploadClassForm) => {
        uploadClassSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid) {
                    const convertedValues = convertFormDataToRequest(values);
                    uploadClass(convertedValues);
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    const handleError = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
        },
        [enqueueSnackbar, form],
    );

    const handleClassUploadError = useCallback(
        classToUploadIndexes => {
            if (classToUploadIndexes) {
                classToUploadIndexes.forEach((ctui: number) => {
                    setClassToUploadErrors(prevErrors => ({
                        ...prevErrors,
                        [`${ctui}`]: { status: ApiStatus.NONE, response: null },
                    }));
                });
            }
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
        },
        [form],
    );

    useEffect(() => {
        const currentForm = form.current as any;

        if (apiUpload.responseStatus === HTTP_NO_CONTENT) {
            setUploaded(true);
        } else if (apiUpload.status === ApiStatus.ERROR) {
            if (apiUpload.error?.response.classToUploadError) {
                handleClassUploadError(apiUpload.error?.response.classToUploadError);
            } else if (apiUpload.error?.response.errors) {
                const currentForm = form.current as any;
                if (currentForm) {
                    const error: FormError = getErrorMessage(apiUpload);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    if (error.formError) currentForm.setErrors(error.formError);
                }
            } else {
                handleError(apiUpload);
            }
        }
        currentForm.setSubmitting(false);
    }, [apiUpload]);

    useEffect(() => {
        if (form.current) {
            Object.keys(classToUploadErrors)
                .filter(index => classToUploadErrors[index].status === ApiStatus.NONE)
                .forEach(index => {
                    const formPart = form.current?.values?.classToUploadData[index] || null;
                    if (formPart) {
                        setClassToUploadErrors(prevErrors => ({
                            ...prevErrors,
                            [`${index}`]: { status: ApiStatus.LOADING, response: null },
                        }));
                        post(
                            apiUrl(
                                `school/${getSchoolAccountId()}/class-tracker/upload-and-send/validation/`,
                            ),
                            convertSingleCourseToRequest(formPart),
                            baseHeaders(),
                        ).subscribe(
                            () => {
                                // this should never happen (overall endpoint is throwing an error for course and this one is not?)
                                const currentClassToUploadErrors = { ...classToUploadErrors };
                                delete currentClassToUploadErrors[index];
                                setClassToUploadErrors(currentClassToUploadErrors);
                            },
                            err => {
                                setClassToUploadErrors(prevErrors => ({
                                    ...prevErrors,
                                    [`${index}`]: {
                                        status: ApiStatus.ERROR,
                                        response: err.response.errors,
                                    },
                                }));
                            },
                        );
                    }
                });
            Object.keys(classToUploadErrors)
                .filter(index => classToUploadErrors[index].status === ApiStatus.ERROR)
                .forEach(index => {
                    form.current.setFieldError(
                        `classToUploadData[${index}]`,
                        classToUploadErrors[index].response,
                    );
                });
        }
    }, [classToUploadErrors]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.addClass.headerOtherUploadClass")}
            </Typography>
            <Paper>
                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => uploadClassSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<UploadClassForm>) => (
                        <Form>
                            {uploaded ? (
                                <UploadClassSuccess
                                    handleUploaded={uploaded => setUploaded(uploaded)}
                                    handleResetForm={formProps.resetForm}
                                />
                            ) : (
                                <>
                                    <FieldArray
                                        name="classToUploadData"
                                        render={arrayHelpers => (
                                            <>
                                                {formProps.values.classToUploadData.map(
                                                    (
                                                        ctud: UploadClassFormCourse,
                                                        index: number,
                                                    ) => (
                                                        <Accordion
                                                            key={index}
                                                            expanded={expandedPanel === index}
                                                        >
                                                            <UploadClassCourseSummary
                                                                index={index}
                                                                currentIndex={expandedPanel}
                                                                handlePanelShow={() =>
                                                                    setExpandedPanel(index)
                                                                }
                                                                handlePanelHide={() =>
                                                                    setExpandedPanel(null)
                                                                }
                                                                handleDelete={() => {
                                                                    arrayHelpers.remove(index);
                                                                }}
                                                                specificationId={ctud.specification}
                                                                fileNames={Object.keys(
                                                                    ctud.realFileNames,
                                                                ).map(
                                                                    rfn => ctud.realFileNames[rfn],
                                                                )}
                                                            />
                                                            <AccordionDetails>
                                                                <UploadClassCourseData
                                                                    // values={formProps.values}
                                                                    index={index}
                                                                    classToUploadData={ctud}
                                                                    errors={formProps.errors}
                                                                    setFieldValue={
                                                                        formProps.setFieldValue
                                                                    }
                                                                    setFieldTouched={
                                                                        formProps.setFieldTouched
                                                                    }
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ),
                                                )}

                                                <PaperInner>
                                                    <Box mt={-3} mb={0}>
                                                        <Button
                                                            onClick={() => {
                                                                arrayHelpers.push({
                                                                    ...emptyClassToUpload,
                                                                });
                                                                setExpandedPanel(
                                                                    (formProps.values
                                                                        .classToUploadData.length -
                                                                        1 || 0) + 1,
                                                                );
                                                            }}
                                                            variant="text"
                                                            startIcon={<Icon path={mdiPlus} />}
                                                            disableRipple
                                                        >
                                                            {t(
                                                                "class.addClass.addAnotherCourseBtn",
                                                            )}
                                                        </Button>
                                                    </Box>
                                                </PaperInner>
                                            </>
                                        )}
                                    />
                                    <PaperInner>
                                        <Grid container spacing={4}>
                                            <Grid item sm={8}>
                                                <Box mt={-6}>
                                                    <Field
                                                        label={t("class.addClass.furtherDetails")}
                                                        name={`comment`}
                                                        component={TextField}
                                                        multiline
                                                        rows={6}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        {formProps.values.classToUploadData.length > 0 && (
                                            <Box mt={5}>
                                                <Button
                                                    disabled={formProps.isSubmitting}
                                                    onClick={formProps.submitForm}
                                                    color="primary"
                                                >
                                                    {t("class.sendClassLists")}
                                                </Button>
                                                <GenericErrorMessage
                                                    errors={formProps.errors}
                                                    submitCount={formProps.submitCount}
                                                />
                                            </Box>
                                        )}
                                    </PaperInner>
                                </>
                            )}
                        </Form>
                    )}
                </FormikRef>
            </Paper>
        </AppContainer>
    );
};

export default AddClassOtherUploadClassContainer;
