import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, RHFTextField } from "src/components/hookFormComponents";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddEmailRequest, useAddEmailMutation } from "../hooks/useAddEmail";
import * as yup from "yup";
import { TFunction } from "i18next";
import { useEffect } from "react";

export const addEmailSchema = (t: TFunction) =>
    yup.object().shape({
        email: yup
            .string()
            .email(t("common.form.validation.email.type"))
            .required(t("common.form.validation.email.required")),
    });

const AddEmailForm = () => {
    const { t } = useTranslation();
    const { mutate: addEmail, status } = useAddEmailMutation();

    const handleSubmit = (values: AddEmailRequest) => {
        addEmail(values);
    };

    const methods = useForm({
        resolver: yupResolver(addEmailSchema(t)),
        mode: "onSubmit",
        defaultValues: {
            email: "",
        },
    });
    const { reset } = methods;

    useEffect(() => {
        reset();
    }, [status]);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit}>
            <RHFTextField
                name="email"
                type="email"
                label={t("common.form.label.addNewEmail")}
                margin="normal"
            />
            <Box mt={3}>
                <Button type="submit" color="inherit">
                    {t("account.myAccountTab.addEmailBtn")}
                </Button>
            </Box>
        </FormProvider>
    );
};

export default AddEmailForm;
