import PaperInner from "src/components/PaperInner";
import HeadingCounter from "src/components/HeadingCounter";
import AttributesConfigList from "../../Common/AttributesConfigList";
import ErrorMessage from "src/forms/ErrorMessage";
import CheckboxField from "src/forms/CheckboxField";
import { useEffect } from "react";
import { Field, Form, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, Select, MenuItem, FormGroup } from "@mui/material";
import {
    AttributeValueExtended,
    AttributeItem,
    AttributeGroup,
    InitialAttribute,
} from "src/orm/models/SpecificationAttribute";
import { ListObject } from "src/forms/types";
import { TrackerPathItem } from "src/modules/class/hooks/useTrackerPath";

export interface BulkEditClassFormFields {
    attributes: AttributeValueExtended[];
    items: AttributeItem[];
    groups: AttributeGroup[];
    disabledItems: number[];
    initialEditAttributes?: InitialAttribute[];
    classTrackers: ListObject<number>[];
}

interface OwnProps {
    selectedSubjectArea: TrackerPathItem | null;
    selectedQualification: TrackerPathItem | null;
    selectedSpecification: TrackerPathItem | null;
    classTrackers?: ListObject<number>[];
    formProps: FormikProps<BulkEditClassFormFields>;
}

const BulkEditClassForm = ({
    formProps,
    selectedSubjectArea,
    selectedQualification,
    selectedSpecification,
    classTrackers,
}: OwnProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (classTrackers) {
            formProps.setFieldValue("classTrackers", classTrackers);
        }
    }, [classTrackers]);

    return (
        <Form>
            <PaperInner>
                <HeadingCounter number="1">
                    {t("class.editClass.chooseTrackerHeader")}
                </HeadingCounter>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        {selectedSubjectArea?.id && (
                            <Select
                                name="selectedSubjectArea"
                                disabled={true}
                                fullWidth
                                value={selectedSubjectArea?.id}
                            >
                                <MenuItem key={0} value={selectedSubjectArea?.id}>
                                    {selectedSubjectArea?.name}
                                </MenuItem>
                            </Select>
                        )}
                    </Grid>
                    <Grid item sm={4}>
                        {selectedQualification?.id && (
                            <Select
                                name="selectedQualification"
                                disabled={true}
                                fullWidth
                                value={selectedQualification?.id}
                            >
                                <MenuItem key={0} value={selectedQualification?.id}>
                                    {selectedQualification?.name}
                                </MenuItem>
                            </Select>
                        )}
                    </Grid>
                    <Grid item sm={4}>
                        {selectedSpecification?.id && (
                            <Select
                                name="specification"
                                disabled={true}
                                fullWidth
                                value={selectedSpecification?.id}
                            >
                                <MenuItem key={0} value={selectedSpecification?.id}>
                                    {selectedSpecification?.parentName
                                        ? selectedSpecification?.parentName +
                                          " - " +
                                          selectedSpecification?.name
                                        : selectedSpecification?.name}
                                </MenuItem>
                            </Select>
                        )}
                    </Grid>
                </Grid>
                {selectedSpecification && (
                    <AttributesConfigList
                        attributes={formProps.values.attributes}
                        specificationId={selectedSpecification.id}
                        items={formProps.values.items}
                        groups={formProps.values.groups}
                        disabledItems={formProps.values.disabledItems}
                        errors={formProps.errors}
                        setFieldValue={formProps.setFieldValue}
                        setFieldTouched={formProps.setFieldTouched}
                        initialAttributes={formProps.values.initialEditAttributes}
                    />
                )}
                {formProps.errors?.attributes && <ErrorMessage name="attributes" />}
            </PaperInner>
            <PaperInner border="top">
                <HeadingCounter number="2">{t("class.editClass.classList")}</HeadingCounter>
                <FormGroup>
                    {formProps.values.classTrackers.map((ct, i) => {
                        return (
                            <Field
                                key={ct.id}
                                name={`classTrackers[${i}].id`}
                                component={CheckboxField}
                                label={ct.name}
                                disabled={true}
                                color="primary"
                            />
                        );
                    })}
                </FormGroup>{" "}
            </PaperInner>
        </Form>
    );
};

export default BulkEditClassForm;
