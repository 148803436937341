import FormikRef from "src/forms/FormikRef";
import AppContainer from "src/components/AppContainer";
import PaperInner from "src/components/PaperInner";
import COLORS from "src/styles/colors";
import Icon from "src/components/Icon";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import {
    Button,
    MenuItem,
    Paper,
    Typography,
    Grid,
    Select,
    Box,
    Chip,
    InputLabel,
    FormControl,
    Theme,
    ListSubheader,
    CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ApiData } from "src/api/constants";
import { shouldLoadData } from "src/api/helpers";
import { ListObject } from "src/forms/types";
import { subjectAreaSelector } from "src/modules/common/selectors/SubjectAreaSelectors";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { AppState } from "src/store/reducers";
import { Field, Form, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { useEffect, useCallback, createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { filterNotNullable } from "src/services/object";
import { TFunction } from "i18next";
import { useSnackbar } from "notistack";
import { SchoolUserRole } from "src/orm/models/User";
import { CommonActions } from "src/modules/common/store/actions";
import { theme } from "src/styles/theme";
import { mdiClose } from "@mdi/js";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { AutocompleteField } from "src/forms/Autocomplete";
import { StaffFilterParams } from "../store/reducers";
import { ROUTE_MY_SCHOOL_STAFF } from "src/routes";
import { useParams } from "react-router";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getSchoolAccountId } from "src/services/url";
import * as yup from "yup";
import { useStaffDetails } from "../hooks/query/Staff/useStaffDetails";
import { useMergeStaff } from "../hooks/query/Staff/useMergeStaff";
import { useAddStaff } from "../hooks/query/Staff/useAddStaff";
import { useEditStaff } from "../hooks/query/Staff/useEditStaff";
import { useStaffRestrictedProps } from "../hooks/query/Staff/useStaffRestrictedProps";
import CheckboxField from "src/forms/CheckboxField";

const useStyles = makeStyles((theme: Theme) => ({
    chipsList: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(1.5, 1.5, 0, 0),
        },
    },
    submenu: {
        paddingLeft: theme.spacing(4),
    },
}));

export interface StaffAddEditForm {
    isLeaderOfAll: boolean;
    isTeacherOfAll: boolean;
    leaderOfSubjectAreas?: ListObject<number>[];
    teacherOfSubjectAreas?: ListObject<number>[];
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    userCode: string;
    userRole: string | null;
    schoolTitle: string | null;
    jobTitle: string | null;
    tmpStaffAutocomplete?: any;
    subjectAreasSynchronizationEnabled?: boolean;
}

const dispatchActions = (dispatch: Dispatch) => ({
    getSubjectAreas: () => {
        dispatch(CommonActions.getSubjectAreaList());
    },
    getAllowedSubjectAreaList: () => {
        dispatch(CommonActions.getAllowedSubjectAreaList());
    },
});

const convertToRequestValues = (values: StaffAddEditForm): StaffAddEditForm => {
    return {
        ...values,
        leaderOfSubjectAreas: values.isLeaderOfAll ? undefined : values.leaderOfSubjectAreas,
        teacherOfSubjectAreas: values.isTeacherOfAll ? undefined : values.teacherOfSubjectAreas,
        jobTitle: values.jobTitle || null,
        schoolTitle: values.schoolTitle || null,
        title: values.title || null,
    };
};

export const addEditStaffSchema = (t: TFunction) => {
    return yup.object().shape({
        firstName: yup.string().required(t("school.staff.addEdit.firstNameReq")),
        lastName: yup.string().required(t("school.staff.addEdit.lastNameReq")),
        email: yup
            .string()
            .required(t("school.staff.addEdit.emailReq"))
            .email(t("school.staff.addEdit.emailType")),
        isLeaderOfAll: yup.boolean(),
        isTeacherOfAll: yup.boolean(),
        leaderOfSubjectAreas: yup.array(),
        teacherOfSubjectAreas: yup.array(),
        userCode: yup.string().max(10).nullable().label(t("school.staff.addEdit.userCode")),
        subjectAreasSynchronizationEnabled: yup.boolean(),
    });
};

const StaffAddEditContainer = () => {
    const { staff } = useParams();
    const staffId = staff ? parseInt(staff) : 0;
    const dispatch = useDispatch();
    const { t } = useTranslation("");
    const classes = useStyles();
    const form = createRef();
    const { enqueueSnackbar } = useSnackbar();
    const { data: accountInfo } = useAccountInfo();
    const [teacherOpen, setTeacherOpen] = useState(false);
    const [leaderOpen, setLeaderOpen] = useState(false);
    const { navigate } = useSchoolNavigate();

    const { data: user } = useStaffDetails(staffId);
    const { data: staffRestrictedProps } = useStaffRestrictedProps();

    const { mutate: mergeStaff } = useMergeStaff(() => {
        if (staffFilter && staffFilter.requestParams !== null) {
            const searchQuery = Object.keys(staffFilter.requestParams)
                .map(key => key + "=" + (staffFilter.requestParams as {})[key])
                .join("&");

            navigate(ROUTE_MY_SCHOOL_STAFF + `?` + searchQuery);
        } else {
            navigate(ROUTE_MY_SCHOOL_STAFF);
        }
    });

    const handleErrorResponse = useCallback(() => {
        const currentForm = form.current as any;
        currentForm.setSubmitting(false);
    }, [form]);

    const { mutate: addStaff } = useAddStaff(
        () => navigate(ROUTE_MY_SCHOOL_STAFF),
        () => handleErrorResponse(),
    );

    const { mutate: editStaff } = useEditStaff(
        () => {
            if (staffFilter && staffFilter.requestParams !== null) {
                const searchQuery = Object.keys(staffFilter.requestParams)
                    .map(key => key + "=" + (staffFilter.requestParams as {})[key])
                    .join("&");

                navigate(ROUTE_MY_SCHOOL_STAFF + `?` + searchQuery);
            } else {
                navigate(ROUTE_MY_SCHOOL_STAFF);
            }
        },
        () => handleErrorResponse(),
    );

    const { getSubjectAreas, getAllowedSubjectAreaList } = dispatchActions(dispatch);

    const {
        apiSubjectAreas,
        subjectAreas,
        staffFilter,
        allowedSa,
    }: {
        apiSubjectAreas: ApiData;
        subjectAreas: OrmSubjectArea[];
        staffFilter: { requestParams: StaffFilterParams | null };
        allowedSa: number[];
    } = useSelector(
        (state: AppState) => ({
            apiSubjectAreas: state.api.common.getAllowedSubjectAreaList,
            subjectAreas: subjectAreaSelector(state),
            staffFilter: state.school.staffFilter,
            allowedSa: state.common.allowedSubjectAreas,
        }),
        shallowEqual,
    );

    const initialValues: StaffAddEditForm = user
        ? {
              isLeaderOfAll: user.isLeaderOfAll || false,
              isTeacherOfAll: user.isTeacherOfAll || false,
              leaderOfSubjectAreas: user.leaderOfSubjectAreas,
              teacherOfSubjectAreas: user.teacherOfSubjectAreas,
              title: user.title || "Mr",
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email || "",
              userCode: user.userCode || "",
              schoolTitle: user.schoolTitle || "",
              jobTitle: user.jobTitle || "",
              userRole: user.userRole || "",
              subjectAreasSynchronizationEnabled: user.subjectAreasSynchronizationEnabled || false,
          }
        : {
              isLeaderOfAll: false,
              isTeacherOfAll: false,
              leaderOfSubjectAreas: [],
              teacherOfSubjectAreas: [],
              title: "Mr",
              firstName: "",
              lastName: "",
              email: "",
              userCode: "",
              schoolTitle: "",
              jobTitle: "",
              userRole: "",
          };

    const availableSubjectAreas = subjectAreas.map(option => {
        if (option.rgt - option.lft > 1) option.disabled = true;
        return option;
    });

    const handleSubmit = values => {
        addEditStaffSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid) {
                    if (staffId) {
                        editStaff({ id: staffId, ...convertToRequestValues(values) });
                    } else {
                        addStaff(convertToRequestValues(values));
                    }
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    const handleTeacherOpen = () =>
        availableSubjectAreas && availableSubjectAreas.length > 0 && setTeacherOpen(true);
    const handleTeacherClose = () => setTeacherOpen(false);
    const handleTeacherChange = (e, formProps) => {
        const values = e.target.value;
        if (values.find(v => parseInt(v) === 0) !== undefined) {
            formProps.setFieldValue("isTeacherOfAll", true);
            formProps.setFieldValue("teacherOfSubjectAreas", []);
        } else {
            formProps.setFieldValue("isTeacherOfAll", false);
            formProps.setFieldValue(
                "teacherOfSubjectAreas",
                e.target.value.filter(v => parseInt(v) !== 0).map(id => ({ id })),
            );
        }
        setTeacherOpen(false);
    };

    const handleLeaderOpen = () =>
        availableSubjectAreas && availableSubjectAreas.length > 0 && setLeaderOpen(true);
    const handleLeaderClose = () => setLeaderOpen(false);
    const handleLeaderChange = (e, formProps) => {
        const values = e.target.value.filter(v => v !== undefined);
        if (values.find(v => parseInt(v) === 0) !== undefined) {
            formProps.setFieldValue("isLeaderOfAll", true);
            formProps.setFieldValue("leaderOfSubjectAreas", []);
            formProps.setFieldValue("isTeacherOfAll", true);
            formProps.setFieldValue("teacherOfSubjectAreas", []);
        } else {
            formProps.setFieldValue("isLeaderOfAll", false);
            formProps.setFieldValue("isTeacherOfAll", false);
            formProps.setFieldValue(
                "leaderOfSubjectAreas",
                e.target.value.filter(v => parseInt(v) !== 0).map(id => ({ id })),
            );
            const alreadySelectedTeachers = formProps.values.teacherOfSubjectAreas || [];
            formProps.setFieldValue("teacherOfSubjectAreas", [
                ...alreadySelectedTeachers,
                ...values
                    .filter(
                        v =>
                            v !== 0 &&
                            alreadySelectedTeachers.find(ast => ast.id === v) === undefined,
                    )
                    .map(id => ({ id })),
            ]);
        }
        setLeaderOpen(false);
    };

    const renderChips = (formProps, field, disableDelete: number[]) => {
        return (
            <Box mt={1.5} className={classes.chipsList}>
                {formProps.values[field].map(val => {
                    const option = subjectAreas.find(o => o.id && o.id === parseInt(val.id));
                    if (option) {
                        return (
                            <Chip
                                style={{
                                    background: option.colour || COLORS.BLUE_3,
                                }}
                                key={`selectedCat-${val.id}`}
                                label={option.name}
                                onDelete={
                                    disableDelete?.length > 0 && disableDelete?.includes(val.id)
                                        ? undefined
                                        : () => {
                                              formProps.setFieldValue(
                                                  field,
                                                  formProps.values[field].filter(
                                                      val => val.id !== option.id,
                                                  ),
                                              );
                                          }
                                }
                                deleteIcon={
                                    <Box
                                        component="span"
                                        color={theme.palette.getContrastText(
                                            option.colour || COLORS.GREY_2,
                                        )}
                                    >
                                        <Icon path={mdiClose} />
                                    </Box>
                                }
                            />
                        );
                    }
                    return null;
                })}
            </Box>
        );
    };

    const { data: coreValues } = useCoreValues();
    const { titles, currentRoles } = coreValues?.staffProperties || {};

    useEffect(() => {
        if (shouldLoadData(apiSubjectAreas)) {
            getSubjectAreas();
            getAllowedSubjectAreaList();
        }
    }, [apiSubjectAreas]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {staffId
                    ? t("school.staff.addEdit.editHeader")
                    : t("school.staff.addEdit.addHeader")}
            </Typography>
            <Paper>
                {!staffId || (staffId && user) ? (
                    <FormikRef
                        ref={form}
                        initialValues={initialValues}
                        validationSchema={() => addEditStaffSchema(t)}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {(formProps: FormikProps<StaffAddEditForm>) => {
                            const {
                                teacherOfSubjectAreas,
                                isTeacherOfAll,
                                leaderOfSubjectAreas,
                                isLeaderOfAll,
                            } = formProps.values;

                            return (
                                <Form>
                                    <PaperInner border="bottom">
                                        <Grid container spacing={4}>
                                            {accountInfo?.hasGroupCallSynchronization &&
                                                user?.groupCallId && (
                                                    <>
                                                        <Grid item sm={6}>
                                                            <Typography variant="h6" component="h6">
                                                                {`${t(
                                                                    "school.staff.addEdit.dataSyncId",
                                                                )}: `}
                                                                <span style={{ color: "#000" }}>
                                                                    {user?.groupCallId}
                                                                </span>
                                                            </Typography>
                                                            <Field
                                                                apiPathName={`school/${getSchoolAccountId()}/search/staff`}
                                                                otherParams={{
                                                                    isLinked: user?.groupCallId
                                                                        ? 1
                                                                        : 0,
                                                                }}
                                                                name="tmpStaffAutocomplete"
                                                                placeholder={t(
                                                                    "school.staff.addEdit.searchStaffAutoPlaceholder",
                                                                )}
                                                                label={t(
                                                                    "school.staff.addEdit.searchStaffAutoPlaceholder",
                                                                )}
                                                                helperText={t(
                                                                    "school.staff.addEdit.searchStaffAutoPlaceholderHelperText",
                                                                )}
                                                                component={AutocompleteField}
                                                                multiple={false}
                                                                resultCallback={results => {
                                                                    return results.map(val => ({
                                                                        ...val,
                                                                        name: `${val.firstName} ${val.lastName}`,
                                                                        displayName: `${val.firstName} ${val.lastName}`,
                                                                    }));
                                                                }}
                                                            />
                                                            <Button
                                                                disabled={
                                                                    !formProps.values
                                                                        .tmpStaffAutocomplete
                                                                }
                                                                onClick={() => {
                                                                    if (user?.id) {
                                                                        mergeStaff({
                                                                            linkedStaff: {
                                                                                id: user?.id,
                                                                            },
                                                                            unLinkedStaff: {
                                                                                id: formProps.values
                                                                                    .tmpStaffAutocomplete
                                                                                    .id,
                                                                            },
                                                                        });
                                                                    }
                                                                }}
                                                                id="mergeButton"
                                                            >
                                                                {t("school.staff.addEdit.merge")}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item sm={6}></Grid>
                                                    </>
                                                )}
                                            <Grid item sm={4}>
                                                {titles && titles.length > 0 ? (
                                                    <Field
                                                        name="title"
                                                        select
                                                        label={t("school.staff.addEdit.title")}
                                                        component={TextField}
                                                        disabled={
                                                            staffId &&
                                                            accountInfo?.hasGroupCallSynchronization &&
                                                            !!staffRestrictedProps?.find(
                                                                s => s === "title",
                                                            )
                                                        }
                                                        autoFocus
                                                        id="titleField"
                                                    >
                                                        <MenuItem value={""} id={`title-none-item`}>
                                                            {t("common.none")}
                                                        </MenuItem>
                                                        {titles.map(title => (
                                                            <MenuItem
                                                                key={title}
                                                                value={title}
                                                                id={`title-${title}-item`}
                                                            >
                                                                {title}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                ) : (
                                                    <></>
                                                )}
                                                <Field
                                                    name="email"
                                                    label={t("school.staff.addEdit.email")}
                                                    disabled={
                                                        staffId &&
                                                        accountInfo?.hasGroupCallSynchronization &&
                                                        !!staffRestrictedProps?.find(
                                                            s => s === "email",
                                                        )
                                                    }
                                                    component={TextField}
                                                    id="emailField"
                                                />
                                                {!!(staffId > 0) && (
                                                    <Field
                                                        name="userRole"
                                                        label={t("school.staff.addEdit.userRole")}
                                                        component={TextField}
                                                        disabled={!staffId}
                                                        select
                                                        id="userRoleField"
                                                    >
                                                        <MenuItem value={SchoolUserRole.OWNER}>
                                                            {t(
                                                                "common.roles." +
                                                                    SchoolUserRole.OWNER,
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={SchoolUserRole.SCHOOL_ADMIN}
                                                        >
                                                            {t(
                                                                "common.roles." +
                                                                    SchoolUserRole.SCHOOL_ADMIN,
                                                            )}{" "}
                                                        </MenuItem>
                                                        <MenuItem value={SchoolUserRole.TEACHER}>
                                                            {t(
                                                                "common.roles." +
                                                                    SchoolUserRole.TEACHER,
                                                            )}{" "}
                                                        </MenuItem>
                                                        <MenuItem value={SchoolUserRole.FINANCE}>
                                                            {t(
                                                                "common.roles." +
                                                                    SchoolUserRole.FINANCE,
                                                            )}{" "}
                                                        </MenuItem>
                                                    </Field>
                                                )}

                                                <Field
                                                    name="jobTitle"
                                                    label={t("school.staff.addEdit.jobTitle")}
                                                    component={TextField}
                                                    disabled={
                                                        staffId &&
                                                        accountInfo?.hasGroupCallSynchronization &&
                                                        !!staffRestrictedProps?.find(
                                                            s => s === "jobTitle",
                                                        )
                                                    }
                                                    id="jobTitleField"
                                                />
                                                <Field
                                                    name="userCode"
                                                    label={t("school.staff.addEdit.userCode")}
                                                    component={TextField}
                                                    disabled={
                                                        staffId &&
                                                        accountInfo?.hasGroupCallSynchronization &&
                                                        !!staffRestrictedProps?.find(
                                                            s => s === "userCode",
                                                        )
                                                    }
                                                    id="userCodeField"
                                                />
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Field
                                                    id="firstNameField"
                                                    name="firstName"
                                                    label={t("school.staff.addEdit.firstName")}
                                                    component={TextField}
                                                    disabled={
                                                        staffId &&
                                                        accountInfo?.hasGroupCallSynchronization &&
                                                        !!staffRestrictedProps?.find(
                                                            s => s === "firstName",
                                                        )
                                                    }
                                                />
                                                <Field
                                                    id="lastNameField"
                                                    name="lastName"
                                                    label={t("school.staff.addEdit.lastName")}
                                                    component={TextField}
                                                    disabled={
                                                        staffId &&
                                                        accountInfo?.hasGroupCallSynchronization &&
                                                        !!staffRestrictedProps?.find(
                                                            s => s === "lastName",
                                                        )
                                                    }
                                                />
                                                {currentRoles && currentRoles.length > 0 ? (
                                                    <Field
                                                        id="schoolRoleField"
                                                        name="schoolTitle"
                                                        label={t(
                                                            "school.staff.addEdit.schoolTitle",
                                                        )}
                                                        component={TextField}
                                                        displayEmpty
                                                        disabled={
                                                            staffId &&
                                                            !!staffRestrictedProps?.find(
                                                                s => s === "schoolTitle",
                                                            )
                                                        }
                                                        select
                                                    >
                                                        <MenuItem
                                                            value={""}
                                                            id={`scoolRole-none-item`}
                                                        >
                                                            {t("common.none")}
                                                        </MenuItem>
                                                        {currentRoles.map(st => {
                                                            return (
                                                                <MenuItem
                                                                    key={st}
                                                                    value={st}
                                                                    id={`scoolRole-${st}-item`}
                                                                >
                                                                    {st}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Field>
                                                ) : (
                                                    <></>
                                                )}
                                                <Field
                                                    name={`subjectAreasSynchronizationEnabled`}
                                                    component={CheckboxField}
                                                    label={t(
                                                        "school.staff.addEdit.subjectAreasSynchronizationEnabled",
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </PaperInner>
                                    <Grid container>
                                        <Grid item sm={4}>
                                            <PaperInner>
                                                <Typography
                                                    variant="h6"
                                                    component="h2"
                                                    gutterBottom
                                                >
                                                    {t("school.staff.addEdit.teacherOfHeader")}
                                                </Typography>
                                                <FormControl>
                                                    <InputLabel id="subjectAreaTeacherLabel" />
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        displayEmpty
                                                        id="subjectAreaTeacherLabel"
                                                        name="teacherOfSubjectAreas"
                                                        onOpen={handleTeacherOpen}
                                                        onClose={handleTeacherClose}
                                                        open={teacherOpen}
                                                        value={
                                                            (teacherOfSubjectAreas &&
                                                                teacherOfSubjectAreas.map(v =>
                                                                    v.id ? v.id : false,
                                                                )) ||
                                                            []
                                                        }
                                                        onChange={e =>
                                                            handleTeacherChange(e, formProps)
                                                        }
                                                        renderValue={() =>
                                                            isTeacherOfAll
                                                                ? t("school.staff.addEdit.all")
                                                                : t(
                                                                      "school.staff.addEdit.subjectArea",
                                                                  )
                                                        }
                                                        placeholder={t(
                                                            "school.staff.addEdit.subjectArea",
                                                        )}
                                                    >
                                                        <MenuItem value={0}>ALL</MenuItem>
                                                        {filterNotNullable(availableSubjectAreas)
                                                            .filter(sa => allowedSa.includes(sa.id))
                                                            .map(obj => {
                                                                if (
                                                                    obj.disabled &&
                                                                    obj.disabled === true
                                                                ) {
                                                                    return (
                                                                        <ListSubheader key={obj.id}>
                                                                            {obj.name}
                                                                        </ListSubheader>
                                                                    );
                                                                }

                                                                return (
                                                                    <MenuItem
                                                                        disabled={
                                                                            (obj.disabled &&
                                                                                obj.disabled ===
                                                                                    true) ||
                                                                            (teacherOfSubjectAreas &&
                                                                                teacherOfSubjectAreas
                                                                                    .map(o => o.id)
                                                                                    .includes(
                                                                                        obj.id,
                                                                                    ))
                                                                        }
                                                                        className={
                                                                            obj.lvl && obj.lvl > 0
                                                                                ? classes.submenu
                                                                                : undefined
                                                                        }
                                                                        key={obj.id}
                                                                        value={obj.id}
                                                                    >
                                                                        {obj.name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                </FormControl>
                                                {teacherOfSubjectAreas &&
                                                    teacherOfSubjectAreas.length > 0 &&
                                                    renderChips(
                                                        formProps,
                                                        "teacherOfSubjectAreas",
                                                        (leaderOfSubjectAreas &&
                                                            leaderOfSubjectAreas.map(l => l.id)) ||
                                                            [],
                                                    )}
                                            </PaperInner>
                                        </Grid>
                                        <Grid item sm={2} />
                                        <Grid item xs={4}>
                                            <PaperInner border="left">
                                                <Typography
                                                    variant="h6"
                                                    component="h2"
                                                    gutterBottom
                                                >
                                                    {t("school.staff.addEdit.leaderOfHeader")}
                                                </Typography>
                                                <FormControl>
                                                    <InputLabel id="subjectAreaTeacherLabel" />
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        displayEmpty
                                                        id="subjectAreaTeacherLabel"
                                                        name="leaderOfSubjectAreas"
                                                        onOpen={handleLeaderOpen}
                                                        onClose={handleLeaderClose}
                                                        open={leaderOpen}
                                                        value={
                                                            (leaderOfSubjectAreas &&
                                                                leaderOfSubjectAreas.map(v =>
                                                                    v.id ? v.id : false,
                                                                )) ||
                                                            []
                                                        }
                                                        onChange={e =>
                                                            handleLeaderChange(e, formProps)
                                                        }
                                                        renderValue={() =>
                                                            isLeaderOfAll
                                                                ? t("school.staff.addEdit.all")
                                                                : t(
                                                                      "school.staff.addEdit.subjectArea",
                                                                  )
                                                        }
                                                        placeholder={t(
                                                            "school.staff.addEdit.subjectArea",
                                                        )}
                                                    >
                                                        <MenuItem value={0}>ALL</MenuItem>
                                                        {filterNotNullable(availableSubjectAreas)
                                                            .filter(sa => allowedSa.includes(sa.id))
                                                            .map(obj => {
                                                                if (
                                                                    obj.disabled &&
                                                                    obj.disabled === true
                                                                ) {
                                                                    return (
                                                                        <MenuItem
                                                                            disabled
                                                                            key={obj.id}
                                                                        >
                                                                            {obj.name}
                                                                        </MenuItem>
                                                                    );
                                                                }

                                                                return (
                                                                    <MenuItem
                                                                        disabled={
                                                                            (obj.disabled &&
                                                                                obj.disabled ===
                                                                                    true) ||
                                                                            (leaderOfSubjectAreas &&
                                                                                leaderOfSubjectAreas
                                                                                    .map(o => o.id)
                                                                                    .includes(
                                                                                        obj.id,
                                                                                    ))
                                                                        }
                                                                        className={
                                                                            obj.lvl && obj.lvl > 0
                                                                                ? classes.submenu
                                                                                : undefined
                                                                        }
                                                                        key={obj.id}
                                                                        value={obj.id}
                                                                    >
                                                                        {obj.name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                </FormControl>
                                                {leaderOfSubjectAreas &&
                                                    leaderOfSubjectAreas.length > 0 &&
                                                    renderChips(
                                                        formProps,
                                                        "leaderOfSubjectAreas",
                                                        [],
                                                    )}
                                            </PaperInner>
                                        </Grid>
                                    </Grid>
                                    <PaperInner border="top">
                                        <Button
                                            disabled={formProps.isSubmitting}
                                            onClick={formProps.submitForm}
                                            color="primary"
                                            id="saveChangesButton"
                                        >
                                            {t("common.saveChanges")}
                                        </Button>
                                        <GenericErrorMessage
                                            errors={formProps.errors}
                                            submitCount={formProps.submitCount}
                                        />
                                    </PaperInner>
                                </Form>
                            );
                        }}
                    </FormikRef>
                ) : (
                    <CircularProgress />
                )}
            </Paper>
        </AppContainer>
    );
};

export default StaffAddEditContainer;
