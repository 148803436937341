import { ofType } from "@martin_hotell/rex-tils";
import { NotificationActions } from "src/notifications/store/actions";
import { OrmSubscriptionActions } from "src/orm/models/Subscription/actions";
import { OrmSubscriptionHistoryActions } from "src/orm/models/SubscriptionHistory/actions";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { SubscriptionsActions, SubscriptionsActionTypes } from "../actions";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";

const getSubscriptionListSuccessEpic = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(SubscriptionsActionTypes.GET_SUBSCRIPTION_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.getSubscriptionListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(OrmSubscriptionActions.bulkImport(payload.response));
        }),
    );

const getRequestedListSuccessEpic = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(SubscriptionsActionTypes.GET_REQUESTED_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.getRequestedListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(OrmSubscriptionHistoryActions.bulkImport(payload.response));
        }),
    );

const getPaymentDetailsSuccessEpic = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(SubscriptionsActionTypes.GET_PAYMENT_DETAILS_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.getPaymentDetailsSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            return of(OrmSubscriptionHistoryActions.upsert(payload.response));
        }),
    );

const createTrialSubscriptionSuccessEpic = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(SubscriptionsActionTypes.CREATE_TRIAL_SUBSCRIPTION_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.createTrialSubscriptionSuccess>) =>
                action.payload,
        ),
        mergeMap(payload =>
            of(
                OrmSubscriptionActions.bulkImport(payload.response),
                NotificationActions.enqueueSnackbar({
                    message: "Subscription created",
                    variant: "success",
                }),
            ),
        ),
    );

const rejectPendingSubscriptionSuccessEpic = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(SubscriptionsActionTypes.REJECT_PENDING_SUBSCRIPTION_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.rejectPendingSubscriptionSuccess>) =>
                action.payload,
        ),
        tap(() => {
            const { refetch: getAccountInfo } = useAccountInfo();
            getAccountInfo();
        }),
        mergeMap(payload =>
            of(
                OrmSubscriptionActions.bulkImport(payload.response),
                NotificationActions.enqueueSnackbar({
                    message: "Subscription rejected",
                    variant: "success",
                }),
            ),
        ),
    );

const declineResubscriptionSuccessEpic = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(SubscriptionsActionTypes.DECLINE_RESUBSCRIPTION_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.declineResubscriptionSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(OrmSubscriptionActions.upsert(payload.response))),
    );

const extendNahtSupportedSubscriptionSuccessEpic = (
    action$: ActionsObservable<SubscriptionsActions>,
) =>
    action$.pipe(
        ofType(SubscriptionsActionTypes.EXTEND_NAHT_SUBSCRIPTION_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.extendNahtSubscriptionSuccess>) =>
                action.payload,
        ),
        mergeMap(payload =>
            of(
                OrmSubscriptionActions.bulkImport(payload.response),
                NotificationActions.enqueueSnackbar({
                    message: "Subscription extended",
                    variant: "success",
                }),
            ),
        ),
    );

const createNahtSupportedSubscriptionSuccessEpic = (
    action$: ActionsObservable<SubscriptionsActions>,
) =>
    action$.pipe(
        ofType(SubscriptionsActionTypes.CREATE_NAHT_SUPPORTED_SUBSCRIPTION_SUCCESS),
        map(
            (
                action: ReturnType<
                    typeof SubscriptionsActions.createNahtSupportedSubscriptionSuccess
                >,
            ) => action.payload,
        ),
        mergeMap(payload =>
            of(
                OrmSubscriptionActions.bulkImport(payload.response),
                NotificationActions.enqueueSnackbar({
                    message: "Subscription created",
                    variant: "success",
                }),
            ),
        ),
    );
const convertToNahtSubscriptionSuccessEpic = (action$: ActionsObservable<SubscriptionsActions>) =>
    action$.pipe(
        ofType(SubscriptionsActionTypes.CONVERT_SUBSCRIPTION_TO_NAHT_SUCCESS),
        map(
            (action: ReturnType<typeof SubscriptionsActions.convertToNahtSubscriptionSuccess>) =>
                action.payload,
        ),
        mergeMap(payload =>
            of(
                OrmSubscriptionActions.bulkImport(payload.response),
                NotificationActions.enqueueSnackbar({
                    message: "Subscription converted to Naht-supported",
                    variant: "success",
                }),
            ),
        ),
    );

export const subscriptionEpics = combineEpics(
    getSubscriptionListSuccessEpic,
    rejectPendingSubscriptionSuccessEpic,
    getRequestedListSuccessEpic,
    createTrialSubscriptionSuccessEpic,
    declineResubscriptionSuccessEpic,
    getPaymentDetailsSuccessEpic,
    extendNahtSupportedSubscriptionSuccessEpic,
    createNahtSupportedSubscriptionSuccessEpic,
    convertToNahtSubscriptionSuccessEpic,
);
