import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { TagModel } from "../model/TagModel";
import { TagCreateRequest } from "./useCreateMutation";
import { TAG_LIST_QUERY } from "./useTagsList";
import { TAG_DETAILS_QUERY } from "./useTagDetails";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

export interface TagEditRequest extends TagCreateRequest {
    id?: number;
}

const apiUpdateTag = async (
    id: number,
    values: TagEditRequest,
): Promise<AxiosResponse<TagModel>> => {
    return await axiosInstance.put(`school/${getSchoolAccountId()}/tag/${id}/`, values);
};

export const useEditTagMutation = (onSuccess: (data: TagModel) => void, onError?: any) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: TagEditRequest) => apiUpdateTag(values.id, values),
        onSuccess: ({ data }: { data: TagModel }) => {
            queryClient.invalidateQueries({ queryKey: [TAG_LIST_QUERY] });
            queryClient.invalidateQueries({ queryKey: [TAG_DETAILS_QUERY] });
            enqueueSnackbar(t("common.saved"), { variant: "success" });
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError) {
                onError();
            }
        },
    });
};
