import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { TrackerActionTypes, TrackerActions } from "../actions";
import FileSaver from "file-saver";
import { NotificationActions } from "src/notifications/store/actions";

const getClassTrackerGroupSnapshotListSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_CLASS_TRACKER_GROUP_SNAPSHOT_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getClassTrackerGroupSnapshotListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerGroupSnapshots(payload.response))),
    );

// const getClassTrackerSnapshotListSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
//     action$.pipe(
//         ofType(TrackerActionTypes.GET_CLASS_TRACKER_SNAPSHOT_LIST_SUCCESS),
//         map(
//             (action: ReturnType<typeof TrackerActions.getClassTrackerSnapshotListSuccess>) =>
//                 action.payload,
//         ),
//         mergeMap((payload) => of(TrackerActions.setClassTrackerSnapshots(payload.response))),
//     );

const getClassTrackerGroupSnapshotValuesSuccessEpic = (
    action$: ActionsObservable<TrackerActions>,
) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_CLASS_TRACKER_GROUP_SNAPSHOT_VALUES_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getClassTrackerGroupSnapshotValuesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerValues(payload.response))),
    );

// const getClassTrackerSnapshotValuesSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
//     action$.pipe(
//         ofType(TrackerActionTypes.GET_CLASS_TRACKER_SNAPSHOT_VALUES_SUCCESS),
//         map(
//             (action: ReturnType<typeof TrackerActions.getClassTrackerSnapshotValuesSuccess>) =>
//                 action.payload,
//         ),
//         mergeMap((payload) => of(TrackerActions.setClassTrackerValues(payload.response))),
//     );

const compareClassTrackerGroupSnapshotsSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.COMPARE_CLASS_TRACKER_GROUP_SNAPSHOTS_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.compareClassTrackerGroupSnapshotsSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(TrackerActions.setClassTrackerValues(payload.response))),
    );

// const compareClassTrackerSnapshotsSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
//     action$.pipe(
//         ofType(TrackerActionTypes.COMPARE_CLASS_TRACKER_SNAPSHOTS_SUCCESS),
//         map(
//             (action: ReturnType<typeof TrackerActions.compareClassTrackerSnapshotsSuccess>) =>
//                 action.payload,
//         ),
//         mergeMap((payload) => of(TrackerActions.setClassTrackerValues(payload.response))),
//     );

const getStudentSnapshotReportDownloadEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(TrackerActionTypes.GET_STUDENT_SNAPSHOT_REPORT_SUCCESS),
        map(
            (action: ReturnType<typeof TrackerActions.getStudentSnapshotReportDownloadSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            const { fileName } = payload.request.payload.values as {
                fileName: string;
            };
            FileSaver.saveAs(payload.response, `${fileName}.pdf`);
            return of(
                NotificationActions.enqueueSnackbar({
                    message: "Student report downloaded",
                    variant: "success",
                }),
            );
        }),
    );

export const snapshotClassTrackerEpics = combineEpics(
    getClassTrackerGroupSnapshotValuesSuccessEpic,
    // getClassTrackerSnapshotValuesSuccessEpic,
    compareClassTrackerGroupSnapshotsSuccessEpic,
    // compareClassTrackerSnapshotsSuccessEpic,
    getClassTrackerGroupSnapshotListSuccessEpic,
    // getClassTrackerSnapshotListSuccessEpic,
    getStudentSnapshotReportDownloadEpic,
);
