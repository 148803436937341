import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { ClassTrackerStudentLinkRows } from "./useClassTrackerStudentLinks";
import { getSchoolAccountId } from "src/services/url";

export interface ClassTrackerStudentLinkRequest {
    id: number;
    rows: ClassTrackerStudentLinkRows;
}

const apiStudentLinksRequest = async (values: ClassTrackerStudentLinkRequest) => {
    return await axiosInstance.put(
        `school/${getSchoolAccountId()}/class-tracker/${values.id}/student-report-links/`,
        {
            ...values.rows,
        },
    );
};

export const useClassTrackerStudentLinksMutation = (onSuccess: () => void, onError?) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: ClassTrackerStudentLinkRequest) => apiStudentLinksRequest(values),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error && typeof err?.response?.data?.error === "string") {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
