import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export interface ClassTrackerStudentLinkRows {
    [key: string]: { reportLinkId: number; enabled: boolean }[];
}

export const STUDENT_CLASS_TRACKER_REPORT_LINKS_LIST_QUERY = "studentClassTrackerReportListQuery";

export const useClassTrackerStudentLinksList = (classTrackerId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [STUDENT_CLASS_TRACKER_REPORT_LINKS_LIST_QUERY, schoolAccountId, classTrackerId],
        queryFn: async (): Promise<ClassTrackerStudentLinkRows | null> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/class-tracker/${classTrackerId}/student-report-links/`,
            );

            return data || null;
        },
    });
};
