import PaperInner from "src/components/PaperInner";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import ErrorMessage from "src/forms/ErrorMessage";
import { FC, useCallback, useEffect, useState } from "react";
import {
    Typography,
    Button,
    Grid,
    MenuItem,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    Autocomplete,
    TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { filterNotNullable, isNumeric } from "src/services/object";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { Link } from "react-router-dom";
import { ROUTE_MY_SCHOOL_PARENT_EDIT } from "src/routes";
import * as yup from "yup";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import Icon from "src/components/Icon";
import { mdiHelpCircleOutline } from "@mdi/js";
import { RHFDatePicker, RHFRadioGroup, RHFTextField } from "src/components/hookFormComponents";
import { useWatch, useForm } from "react-hook-form";
import useDebounce from "src/hooks/useDebounce";
import { SearchParentsResponse, useGetSearchParents } from "../../hooks/useGetSearchParents";

import { ROUTE_MY_SCHOOL_STUDENTS } from "src/routes";
import { useParams } from "react-router";
import { useStudentCreateMutation } from "../../hooks/useCreateMutation";
import { useStudentUpdateMutation } from "../../hooks/useUpdateMutation";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "src/components/hookFormComponents";
import { StudentModel } from "../../model/StudentModel";
import { useStudentParentsMutation } from "../../hooks/useParentsMutation";
import { ParentModel } from "src/modules/parent/model/ParentModel";

export interface StudentAddEditFormShape {
    id?: number;
    firstName: string;
    email: string | null;
    lastName: string;
    uniquePupilNumber: string;
    candidateNumber: string;
    admissionNumber: string;
    gender: string | null;
    form: string;
    pupilPremium: boolean | null;
    fsm: boolean | null;
    lookedAfter: boolean | null;
    eal: boolean | null;
    priorAbility: string | null;
    senStatus: string | null;
    ks2English: string | null;
    ks2Math: string | null;
    userIdentifier?: string | null;
    ks2FineLevel: string | null;
    ks2EnglishScaled: string | null;
    ks2MathScaled: string | null;
    ks2FineLevelScaled: string | null;
    ethnicity: string | null;
    senNeed: string | null;
    readingAge: string | null;
    ks4Aps: string | null;
    ks4Agaps: string | null;
    catMean: number | null;
    catVerbal: number | null;
    catNonVerbal: number | null;
    catQuantitative: number | null;
    catSpatial: number | null;
    nativeSpeaker: boolean | null;
    dateJoined: string | null;
    dateOfBirth: string | null;
    academicHouse: string | null;
    parents: any[] | null;
    yearGroup?: number | null;
}
interface OwnProps {
    studentRestrictedProps: string[];
    hasGroupCallSync?: boolean;
    studentDetails: StudentModel;
}

export const emptyStudentForm: StudentAddEditFormShape = {
    firstName: "",
    lastName: "",
    uniquePupilNumber: "",
    candidateNumber: "",
    admissionNumber: "",
    email: "",
    gender: null,
    form: "",
    pupilPremium: null,
    fsm: null,
    lookedAfter: null,
    eal: null,
    priorAbility: null,
    senStatus: null,
    ks2English: null,
    ks2Math: null,
    ks2FineLevel: null,
    ks2EnglishScaled: null,
    ks2MathScaled: null,
    ks2FineLevelScaled: null,
    ethnicity: null,
    senNeed: null,
    ks4Aps: null,
    ks4Agaps: null,
    readingAge: null,
    catMean: null,
    catVerbal: null,
    catNonVerbal: null,
    catQuantitative: null,
    catSpatial: null,
    nativeSpeaker: null,
    dateJoined: null,
    dateOfBirth: null,
    academicHouse: "",
    parents: null,
    yearGroup: -999,
};

const getKs2Options = () => {
    const options: string[] = [];
    for (let i = 0; i <= 6; i = i + 0.1) {
        options.push(i.toString().length === 1 ? i + ".0" : i.toFixed(1));
    }
    return options;
};

export const addEditStudentSchema = (t: TFunction) => {
    return yup.object().shape({
        firstName: yup.string().required(t("students.addEdit.firstNameReq")),
        lastName: yup.string().required(t("students.addEdit.lastNameReq")),
        uniquePupilNumber: yup.string().max(20).required(t("students.addEdit.uniqueNumberReq")),
        catMean: yup
            .number()
            .max(150)
            .nullable()
            .label(t("students.addEdit.catMean"))
            .typeError("dsadas"),
        catVerbal: yup.number().max(150).nullable().label(t("students.addEdit.catVerbal")),
        catNonVerbal: yup.number().max(150).nullable().label(t("students.addEdit.catNonVerbal")),
        catQuantitative: yup
            .number()
            .max(150)
            .nullable()
            .label(t("students.addEdit.catQuantitative")),
        catSpatial: yup.number().max(150).nullable().label(t("students.addEdit.catSpatial")),
        ks4Aps: yup
            .string()
            .matches(/^[0-9]+(\.[0-9]{1,2})?$/, t("common.form.validation.ks4Aps"))
            .nullable()
            .label(t("students.addEdit.ks4Aps")),
        ks4Gaps: yup
            .string()
            .matches(/^[0-9]+(\.[0-9]{1,2})?$/, t("common.form.validation.ks4Gaps"))
            .nullable()
            .label(t("students.addEdit.ks4Gaps")),
        readingAge: yup
            .string()
            .matches(
                /^([0-9][0-9])((:|.|\/)([0-9]|(0[0-9])|((0|1)(0|1))))?$/,
                t("common.form.validation.readingAge"),
            )
            .nullable()
            .label(t("students.addEdit.readingAge")),
        ks2EnglishScaled: yup
            .string()
            .test("ks2englishscaledvalue", t("students.addEdit.ks2EnglishScaledMatches"), v => {
                if (`${v}` === "" || v === null) {
                    return true;
                }
                if (
                    (isNaN(v as unknown as number) && (`${v}` === "N" || `${v}` === "n")) ||
                    (!isNaN(v as unknown as number) && parseInt(v) <= 120 && parseInt(v) >= 59)
                ) {
                    return true;
                }
                return false;
            })
            .nullable()
            .label(t("students.addEdit.ks2EnglishScaled")),
        ks2MathScaled: yup
            .string()
            .test("ks2mathscaledvalue", t("students.addEdit.ks2MathScaledMatches"), v => {
                if (`${v}` === "" || v === null) {
                    return true;
                }
                if (
                    (isNaN(v as unknown as number) && (`${v}` === "N" || `${v}` === "n")) ||
                    (!isNaN(v as unknown as number) && parseInt(v) <= 120 && parseInt(v) >= 59)
                ) {
                    return true;
                }
                return false;
            })
            .nullable()
            .label(t("students.addEdit.ks2MathScaled")),
        ks2FineLevelScaled: yup
            .string()
            .test("ks2finelevelscaledvalue", t("students.addEdit.ks2FineLevelScaledMatches"), v => {
                if (`${v}` === "" || v === null) {
                    return true;
                }
                if ((v as unknown as number) > 120) {
                    return false;
                }
                const regex = new RegExp("^(12[0-0]|1[01][0-9]|[1-9]?[0-9]).(0|5)$", "");
                return regex.test(v);
            })
            .nullable()
            .label(t("students.addEdit.ks2FineLevelScaled")),
        id: yup.number().nullable().optional(),
        candidateNumber: yup.string().nullable().optional(),
        admissionNumber: yup.string().nullable().optional(),
        gender: yup.string().nullable().optional(),
        form: yup.string().nullable().optional(),
        pupilPremium: yup.boolean().nullable().optional(),
        fsm: yup.boolean().nullable().optional(),
        lookedAfter: yup.boolean().nullable().optional(),
        eal: yup.boolean().nullable().optional(),
        priorAbility: yup.string().nullable().optional(),
        senStatus: yup.string().nullable().optional(),
        ks2English: yup.string().nullable().optional(),
        ks2Math: yup.string().nullable().optional(),
        userIdentifier: yup.string().nullable().optional(),
        ks2FineLevel: yup.string().nullable().optional(),
        ethnicity: yup.string().nullable().optional(),
        senNeed: yup.string().nullable().optional(),
        ks4Agaps: yup.string().nullable().optional(),
        nativeSpeaker: yup.boolean().nullable().optional(),
        dateJoined: yup.string().nullable().optional(),
        dateOfBirth: yup.string().nullable().optional(),
        academicHouse: yup.string().nullable().optional(),
        parents: yup.array().nullable().optional(),
        yearGroup: yup.number().nullable().optional(),
    });
};

const StudentAddEditForm: FC<OwnProps> = ({
    studentRestrictedProps,
    hasGroupCallSync,
    studentDetails,
}) => {
    const { t } = useTranslation();

    const { navigate } = useSchoolNavigate();
    const { data: accountInfo } = useAccountInfo();

    const { student } = useParams();
    const studentId = student ? parseInt(student) : 0;

    const { mutate: addStudent } = useStudentCreateMutation(() =>
        navigate(ROUTE_MY_SCHOOL_STUDENTS),
    );
    const { mutate: editStudentParents } = useStudentParentsMutation(() =>
        navigate(ROUTE_MY_SCHOOL_STUDENTS),
    );

    const { mutate: editStudent } = useStudentUpdateMutation(() => {
        if (accountInfo?.hasParentPortalEnabled) {
            editStudentParents({
                id: studentId,
                parents: getValues("parents")
                    ? getValues("parents").map((p: ParentModel) => ({ id: p.id }))
                    : [],
            });
        } else {
            navigate(ROUTE_MY_SCHOOL_STUDENTS);
        }
    });

    const initialValues: StudentAddEditFormShape = studentDetails
        ? {
              ...emptyStudentForm,
              ...studentDetails,
              ethnicity: studentDetails.ethnicity ? studentDetails.ethnicity.id : null,
              senNeed: studentDetails.senNeed ? studentDetails.senNeed.id : null,
          }
        : {
              ...emptyStudentForm,
          };

    const handleSubmit = (values: StudentAddEditFormShape) => {
        const data = {
            ...values,
            pupilPremium: Boolean(values.pupilPremium),
            nativeSpeaker: Boolean(values.nativeSpeaker),
            fsm: Boolean(values.fsm),
            lookedAfter: Boolean(values.lookedAfter),
            dateOfBirth: values.dateOfBirth || null,
            dateJoined: values.dateJoined || null,
            eal: Boolean(values.eal),
            email: values.email || null,
            senNeed: { id: values.senNeed },
            ethnicity: { id: values.ethnicity },
            yearGroup: values.yearGroup >= -1 ? values.yearGroup : null,
            catMean: values.catMean ? parseInt(`${values.catMean}`) : null,
            catVerbal: values.catVerbal ? parseInt(`${values.catVerbal}`) : null,
            catNonVerbal: values.catNonVerbal ? parseInt(`${values.catNonVerbal}`) : null,
            catQuantitative: values.catQuantitative ? parseInt(`${values.catQuantitative}`) : null,
            catSpatial: values.catSpatial ? parseInt(`${values.catSpatial}`) : null,
        };

        if (studentId) {
            editStudent({ id: studentId, ...data });
        } else {
            addStudent(data);
        }
    };

    const methods = useForm({
        resolver: yupResolver(addEditStudentSchema(t)) as any,
        mode: "all",
        defaultValues: initialValues,
    });
    useEffect(() => {
        methods.reset(initialValues);
    }, [studentDetails]);

    const {
        formState: { errors, isSubmitting, submitCount },
        control,
        getValues,
        setValue,
    } = methods;

    const { attachSchoolId } = useSchoolNavigate();

    const [changedStudentRestrictedPropsList, setStudentRestrictedPropsChanged] = useState([]);
    const [tmpParentAutocomplete, setTmpParentAutocomplete] =
        useState<SearchParentsResponse | null>(null);
    const watchingValues = useWatch({
        control,
    });
    useEffect(() => {
        if (studentRestrictedProps) {
            let changsList = [];
            for (const sRP of studentRestrictedProps) {
                if (initialValues[sRP] !== watchingValues[sRP]) {
                    changsList = [...changsList, sRP];
                }
            }
            setStudentRestrictedPropsChanged(changsList);
        }
    }, [watchingValues]);

    const { data } = useCoreValues();
    const yearGroups = data?.studentYearGroups;
    const studentProperties = data?.studentProperties;
    const ethnicityList = studentProperties?.ethnicities;
    const senNeedList = studentProperties?.senNeed;
    const senStatusList = studentProperties?.senStatus;
    const genderList = studentProperties?.genders;
    const priorAbility = studentProperties?.priorAbility;

    const hasPupilApp = !!accountInfo?.hasStudentPortalEnabled;

    const calculateAvgKs2Scaled = useCallback(() => {
        const ks2EnglishScaled = getValues("ks2EnglishScaled")
            ? parseInt(getValues("ks2EnglishScaled"))
            : 0;
        const ks2MathScaled = getValues("ks2MathScaled") ? parseInt(getValues("ks2MathScaled")) : 0;

        if (ks2EnglishScaled && ks2MathScaled === 0) {
            setValue("ks2FineLevelScaled", ks2EnglishScaled.toFixed(1).toString());
        } else if (ks2MathScaled && ks2EnglishScaled === 0) {
            setValue("ks2FineLevelScaled", ks2MathScaled.toFixed(1).toString());
        } else if (
            ks2EnglishScaled &&
            ks2MathScaled &&
            !isNaN(ks2EnglishScaled) &&
            !isNaN(ks2MathScaled)
        ) {
            const ks2FineValue = (ks2EnglishScaled + ks2MathScaled) / 2;
            setValue(
                "ks2FineLevelScaled",
                (Math.round(ks2FineValue * 100) / 100).toFixed(1).toString(),
            );
        }
    }, [watchingValues]);

    const parentsWatchingValues = useWatch({
        control,
        name: ["parents"],
    });

    useEffect(() => {
        if (tmpParentAutocomplete) {
            const parents = getValues("parents") ? [...getValues("parents")] : [];
            parents.push(tmpParentAutocomplete);
            setValue("parents", parents);
            setTmpParentAutocomplete(null);
        }
    }, [parentsWatchingValues, tmpParentAutocomplete]);

    const ks2WatchingValues = useWatch({
        control,
        name: ["ks2English", "ks2Math", "ks2FineLevel"],
    });
    useEffect(() => {
        if (getValues("ks2English") && getValues("ks2Math") && !getValues("ks2FineLevel")) {
            const ks2FineValue =
                (parseFloat(getValues("ks2Math")) + parseFloat(getValues("ks2English"))) / 2;
            setValue("ks2FineLevel", (Math.round(ks2FineValue * 100) / 100).toFixed(1).toString());
        }
    }, [ks2WatchingValues]);

    //autocomplete
    const [autocompleteInput, setAutocompleteInput] = useState<string>("");
    const debouncedAutocompleteInput = useDebounce(autocompleteInput, 500);
    const { data: parents, refetch } = useGetSearchParents(debouncedAutocompleteInput);
    useEffect(() => {
        refetch();
    }, [debouncedAutocompleteInput]);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit}>
            <PaperInner border="bottom">
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="uniquePupilNumber"
                            label={t("students.addEdit.uniquePupilNumber")}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="candidateNumber"
                            label={t("students.addEdit.candidateNumber")}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="admissionNumber"
                            label={t("students.addEdit.admissionNumber")}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField name="firstName" label={t("students.addEdit.firstName")} />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField name="lastName" label={t("students.addEdit.lastName")} />
                    </Grid>
                    {hasPupilApp && (
                        <Grid item sm={4}>
                            <RHFTextField name="email" label={t("students.addEdit.email")} />
                        </Grid>
                    )}

                    {ethnicityList && (
                        <Grid item sm={4}>
                            <RHFTextField
                                name="ethnicity"
                                select
                                label={t("students.addEdit.ethnicity")}
                                placeholder={t("students.addEdit.ethnicity")}
                            >
                                {filterNotNullable(ethnicityList).map(e => (
                                    <MenuItem key={e.id} value={e.id}>
                                        {e.name}
                                    </MenuItem>
                                ))}
                            </RHFTextField>
                        </Grid>
                    )}

                    {genderList && (
                        <Grid item sm={4}>
                            <RHFTextField name="gender" label={t("students.addEdit.gender")} select>
                                {genderList.map(gender => {
                                    return (
                                        <MenuItem key={gender} value={gender}>
                                            {t(`common.${gender}`)}
                                        </MenuItem>
                                    );
                                })}
                            </RHFTextField>
                        </Grid>
                    )}

                    <Grid item sm={4}>
                        <RHFTextField name="form" label={t("students.addEdit.form")} />
                    </Grid>

                    {senNeedList && (
                        <Grid item sm={4}>
                            <RHFTextField
                                name="senNeed"
                                select
                                label={t("students.addEdit.senNeed")}
                            >
                                {filterNotNullable(senNeedList).map(s => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.id}
                                    </MenuItem>
                                ))}
                            </RHFTextField>
                        </Grid>
                    )}

                    {senStatusList && (
                        <Grid item sm={4}>
                            <RHFTextField
                                name="senStatus"
                                label={t("students.addEdit.senStatus")}
                                select
                            >
                                {senStatusList.map(senKey => (
                                    <MenuItem key={senKey} value={senKey}>
                                        {senKey}
                                    </MenuItem>
                                ))}
                            </RHFTextField>
                        </Grid>
                    )}

                    <Grid item sm={4}>
                        <RHFTextField name="readingAge" label={t("students.addEdit.readingAge")} />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFDatePicker name="dateJoined" label={t("students.addEdit.dateJoined")} />
                        {errors?.dateJoined && <ErrorMessage name="dateJoined" />}
                    </Grid>
                    <Grid item sm={4}>
                        <RHFDatePicker
                            name="dateOfBirth"
                            label={t("students.addEdit.dateOfBirth")}
                        />
                        {errors?.dateOfBirth && <ErrorMessage name="dateOfBirth" />}
                    </Grid>
                    {yearGroups && (
                        <Grid item sm={4}>
                            <RHFTextField
                                name="yearGroup"
                                select
                                label={t("students.addEdit.yearGroup")}
                            >
                                <MenuItem value={-999}>{t("common.none")}</MenuItem>
                                {yearGroups.map(yg => (
                                    <MenuItem key={yg.id} value={yg.id}>
                                        {yg.name}
                                    </MenuItem>
                                ))}
                            </RHFTextField>
                        </Grid>
                    )}
                    <Grid item sm={4}>
                        <RHFTextField
                            name="academicHouse"
                            label={t("students.addEdit.academicHouse")}
                        />
                    </Grid>
                    {getValues("userIdentifier") && (
                        <Grid item sm={4}>
                            <RHFTextField
                                name="userIdentifier"
                                label={t("students.addEdit.userIdentifier")}
                                disabled={true}
                            />
                        </Grid>
                    )}
                </Grid>
            </PaperInner>
            {!!getValues("id") && accountInfo?.hasParentPortalEnabled ? (
                <PaperInner border="bottom">
                    <Autocomplete
                        freeSolo
                        options={parents || []}
                        getOptionLabel={(parent: SearchParentsResponse) =>
                            `${parent.firstName} ${parent.lastName}`
                        }
                        onInputChange={(_event, newInputValue) => {
                            setAutocompleteInput(newInputValue);
                        }}
                        onChange={(_e, value) => {
                            setTmpParentAutocomplete(value as SearchParentsResponse);
                        }}
                        renderInput={params => {
                            return (
                                <TextField
                                    {...params}
                                    placeholder={t("students.addEdit.searchParentAutoPlaceholder")}
                                    label={t("students.addEdit.searchParentAutoPlaceholder")}
                                    helperText={t(
                                        "students.addEdit.searchParentAutoPlaceholderHelperText",
                                    )}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            );
                        }}
                        forcePopupIcon={false}
                        sx={{ mb: 3 }}
                    />

                    {getValues("parents") && getValues("parents").length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("students.addEdit.userId")}</TableCell>
                                    <TableCell>{t("students.addEdit.parentName")}</TableCell>
                                    <TableCell>{t("students.addEdit.status")}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getValues("parents").map((parent, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{parent.userIdentifier}</TableCell>
                                        <TableCell>
                                            <Link
                                                to={attachSchoolId(
                                                    ROUTE_MY_SCHOOL_PARENT_EDIT,
                                                ).replace(":parent", parent.id)}
                                            >
                                                {parent.fullName ||
                                                    `${parent.firstName} ${parent.lastName}`}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{parent.status || "-"}</TableCell>
                                        <TableCell>
                                            {!hasGroupCallSync && (
                                                <Button
                                                    onClick={() => {
                                                        setValue(
                                                            "parents",
                                                            getValues("parents")?.filter(
                                                                p => p.id !== parent.id,
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    {t("students.addEdit.delete")}
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <></>
                    )}
                </PaperInner>
            ) : (
                <></>
            )}

            <PaperInner border="bottom">
                <Grid container>
                    <Grid item sm={4}>
                        <Typography gutterBottom>{t("students.addEdit.pupilPremium")}</Typography>
                        <RHFRadioGroup
                            name="pupilPremium"
                            options={["true", "false"]}
                            getOptionLabel={[t("common.yes"), t("common.no")]}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Typography gutterBottom>{t("students.addEdit.fsm")}</Typography>
                        <RHFRadioGroup
                            name="fsm"
                            options={["true", "false"]}
                            getOptionLabel={[t("common.yes"), t("common.no")]}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Typography gutterBottom>{t("students.addEdit.lookedAfter")}</Typography>
                        <RHFRadioGroup
                            name="lookedAfter"
                            options={["true", "false"]}
                            getOptionLabel={[t("common.yes"), t("common.no")]}
                        />
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner border="bottom">
                <Grid container>
                    <Grid item sm={4}>
                        <Typography gutterBottom>{t("students.addEdit.eal")}</Typography>
                        <RHFRadioGroup
                            name="eal"
                            options={["true", "false"]}
                            getOptionLabel={[t("common.yes"), t("common.no")]}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Typography gutterBottom>{t("students.addEdit.nativeSpeaker")}</Typography>
                        <RHFRadioGroup
                            name="nativeSpeaker"
                            options={["true", "false"]}
                            getOptionLabel={[t("common.yes"), t("common.no")]}
                        />
                    </Grid>
                    {priorAbility && (
                        <Grid item sm={4}>
                            <Typography gutterBottom>
                                {t("students.addEdit.priorAbility")}
                            </Typography>
                            <RHFRadioGroup
                                name="priorAbility"
                                options={priorAbility}
                                getOptionLabel={priorAbility.map(paKey => (
                                    <>
                                        {paKey}{" "}
                                        <Box component="span" fontStyle="italic">
                                            ({t(`students.addEdit.priorAbility${paKey}`)})
                                        </Box>
                                    </>
                                ))}
                            />
                        </Grid>
                    )}
                </Grid>
            </PaperInner>
            <PaperInner border="bottom">
                <Box pb={2}>
                    <Typography component="h3">{t("students.addEdit.ks2Scores")}</Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="ks2English"
                            label={t("students.addEdit.ks2English")}
                            select
                        >
                            {getKs2Options().map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                            <MenuItem value={"N"}>{"N"}</MenuItem>
                        </RHFTextField>
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField name="ks2Math" label={t("students.addEdit.ks2Math")} select>
                            {getKs2Options().map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                            <MenuItem value={"N"}>{"N"}</MenuItem>
                        </RHFTextField>
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="ks2FineLevel"
                            label={t("students.addEdit.ks2FineLevel")}
                            select
                        >
                            {getKs2Options().map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                            <MenuItem value={"N"}>{"N"}</MenuItem>
                        </RHFTextField>
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="ks2EnglishScaled"
                            label={t("students.addEdit.ks2EnglishScaled")}
                            inputProps={{
                                onBlur: () => {
                                    calculateAvgKs2Scaled();
                                },
                            }}
                            InputLabelProps={{
                                shrink: !!getValues("ks2EnglishScaled"),
                            }}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="ks2MathScaled"
                            label={t("students.addEdit.ks2MathScaled")}
                            inputProps={{
                                onBlur: () => {
                                    calculateAvgKs2Scaled();
                                },
                            }}
                            InputLabelProps={{
                                shrink: !!getValues("ks2MathScaled"),
                            }}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="ks2FineLevelScaled"
                            label={t("students.addEdit.ks2FineLevelScaled")}
                        />
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner>
                <Box pb={2}>
                    <Typography component="h3">{t("students.addEdit.ks4Scores")}</Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <RHFTextField name="ks4Aps" label={t("students.addEdit.ks4Aps")} />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField name="ks4Agaps" label={t("students.addEdit.ks4Agaps")} />
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner>
                <Box pb={2}>
                    <Typography component="h3">{t("students.addEdit.catScores")}</Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="catMean"
                            type="number"
                            label={t("students.addEdit.catMean")}
                            inputProps={{
                                min: 0,
                                max: 140,
                                onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
                                    if (!isNumeric(e.key)) e.preventDefault();
                                },
                            }}
                            placeholder={t("students.addEdit.catMean")}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="catVerbal"
                            type="number"
                            label={t("students.addEdit.catVerbal")}
                            inputProps={{
                                min: 0,
                                max: 140,
                                onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
                                    if (!isNumeric(e.key)) e.preventDefault();
                                },
                            }}
                            placeholder={t("students.addEdit.catVerbal")}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="catNonVerbal"
                            type="number"
                            label={t("students.addEdit.catNonVerbal")}
                            inputProps={{
                                min: 0,
                                max: 140,
                                onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
                                    if (!isNumeric(e.key)) e.preventDefault();
                                },
                            }}
                            placeholder={t("students.addEdit.catNonVerbal")}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="catQuantitative"
                            type="number"
                            label={t("students.addEdit.catQuantitative")}
                            inputProps={{
                                min: 0,
                                max: 140,
                                onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
                                    if (!isNumeric(e.key)) e.preventDefault();
                                },
                            }}
                            placeholder={t("students.addEdit.catQuantitative")}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <RHFTextField
                            name="catSpatial"
                            type="number"
                            label={t("students.addEdit.catSpatial")}
                            inputProps={{
                                min: 0,
                                max: 140,
                                onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
                                    if (!isNumeric(e.key)) e.preventDefault();
                                },
                            }}
                            placeholder={t("students.addEdit.catSpatial")}
                        />
                    </Grid>
                </Grid>
                <Box mt={4}>
                    <Button color="primary" type="submit" disabled={isSubmitting}>
                        {t("common.saveChanges")}
                    </Button>
                    {changedStudentRestrictedPropsList.length > 0 ? (
                        <Box style={{ marginTop: 10 }}>
                            {t("students.addEdit.studentRestrictedPropsHint").replaceAll(
                                "MIS_Name",
                                accountInfo?.misName || "",
                            )}
                            <Tooltip
                                title={changedStudentRestrictedPropsList.map(v => (
                                    <div key={v}>{t("students.addEdit." + v)}</div>
                                ))}
                            >
                                <Box component="span">
                                    <Icon path={mdiHelpCircleOutline} />
                                </Box>
                            </Tooltip>
                        </Box>
                    ) : (
                        <></>
                    )}
                    <GenericErrorMessage errors={errors} submitCount={submitCount} />
                </Box>
            </PaperInner>
        </FormProvider>
    );
};

export default StudentAddEditForm;
