import { combineEpics } from "redux-observable";
import { userEpics } from "../../user/store/epics";
import { gradeEpics } from "./Grade/epics";
import { specificationEpics } from "./Specification/epics";
import { specificationAttributeEpics } from "./SpecificationAttribute/epics";
import { subjectAreaEpics } from "./SubjectArea/epics";
import { topicEpics } from "./Topic/epics";

export const commonEpics = combineEpics(
    subjectAreaEpics,
    specificationEpics,
    specificationAttributeEpics,
    gradeEpics,
    userEpics,
    topicEpics,
);
