import { ListObject, ValidationData, VisibilityRuleData } from "src/forms/types";
import { attr, Model } from "redux-orm";

const fields = {
    groups: attr(),
    items: attr(),
    values: attr(),
    specification: attr(),
};

export interface AttributeGroup {
    id: number;
    name: string;
    description: string;
    position: number;
    validation: ValidationData;
}

export interface AttributeItem {
    id: number;
    name: string;
    intro: string;
    position: number;
    alphabeticOrder: boolean;
    minOtherSelected: number;
    maxOtherSelected: number;
    validation: ValidationData;
    attributeGroup: ListObject<number>;
    visibilityRule: VisibilityRuleData;
    validationRule: VisibilityRuleData;
}

export interface AttributeValue {
    id: number;
    name: string;
    isMandatory: boolean;
    isSelected: boolean;
    weight: 0;
    allowCustomName: boolean;
    position: number;
    attributeItem: ListObject<number>;
}

export interface InitialAttribute {
    id: number;
    customName: string | null;
    attributeValue: ListObject<number>;
}

export interface AttributeValueExtended extends AttributeValue {
    attributeItemId: number | null;
    customName: string | null;
    classTrackerAttributeValueId?: number | null;
}

export interface OrmSpecificationAttribute {
    groups: AttributeGroup[];
    items: AttributeItem[];
    values: AttributeValueExtended[];
    specification: number | null;
}

class SpecificationAttribute extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "SpecificationAttribute";
    static fields = fields;
    static emptyRef: OrmSpecificationAttribute = {
        groups: [],
        items: [],
        values: [],
        specification: null,
    };

    get ormRef(): OrmSpecificationAttribute {
        return this.ref as any;
    }

    get ormRefDetails(): OrmSpecificationAttribute {
        return this.ref as any;
    }
}

export default SpecificationAttribute;
