import GridHeaderBack from "src/modules/common/components/Grid/GridHeaderBack";
import TrackerClassDetails from "./TrackerClassDetails";
import GridToolsToggleButton from "src/modules/common/components/Grid/GridToolsToggleButton";
import GridDataTypeButtons, {
    DataTypeTypes,
} from "src/modules/common/components/Grid/GridDataTypeButtons";
import GridSnapshotCompareInfo from "src/modules/common/components/Grid/GridSnapshotCompareInfo";
import GridFiltersToggleButton from "src/modules/common/components/Grid/GridFiltersToggleButton";
import COLORS from "src/styles/colors";
import TierHeaderSelect from "../Common/TierHeaderSelect";
import SnapshotModal, {
    ShortSnapshotItem,
    SnapshotModalActions,
} from "src/modules/common/components/SnapshotModal";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Box } from "@mui/material";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import { ClassTrackerListFilters } from "src/modules/class/api/Classes/getClassTrackers";
import {
    ROUTE_CLASS_CLASS_LIST,
    ROUTE_CLASS_CLASS_LIST_COHORT,
    ROUTE_TRACKER_CLASS_TRACKER,
} from "src/routes";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { SchoolUserRole } from "src/orm/models/User";
import { SnapshotData, useTrackerContext } from "src/context/TrackerContext";
import { useClassTrackerSnapshots } from "../../hooks/query/ClassTracker/Snapshots/useSnapshots";
import { generateTrackerUrl } from "src/services/url";
import { useRemoveSnapshotMutation } from "../../hooks/query/ClassTracker/Snapshots/useRemoveSnapshotMutation";
import { useCreateSnapshotMutation } from "../../hooks/query/ClassTracker/Snapshots/useCreateSnapshotMutation";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

interface OwnProps {
    classDetails?: OrmClassTracker;
    dataType?: DataTypeTypes;
    handleToolsVisible?: (isChecked: boolean) => void;
    handleFiltersVisible: (isChecked: boolean) => void;
    handleDataTypeChange?: (type: DataTypeTypes) => void;
    lastFilterOptions?: { cohort: number; filters?: ClassTrackerListFilters };
    isLoadingGrid?: boolean;
    toolsVisible?: boolean;
    filtersVisible?: boolean;
    tier: TiersTypes;
    hasForecast: boolean;
    archived?: boolean;
    bulkEdit?: boolean;
}

const TrackerHeader: FC<OwnProps> = ({
    classDetails,
    dataType,
    handleDataTypeChange,
    handleFiltersVisible,
    handleToolsVisible,
    lastFilterOptions,
    isLoadingGrid,
    toolsVisible,
    filtersVisible,
    hasForecast,
    tier,
    archived,
}) => {
    const { navigate } = useSchoolNavigate();
    const [snapshotModalOpen, setSnapshotModalOpen] = useState<boolean>(false);
    const { cohort } = useParams() as any;
    const { trackerState: trackerContext, setSnapshotData } = useTrackerContext();
    const { snapshotData } = trackerContext;
    const { data: snapshots, refetch: refetchSnapshots } = useClassTrackerSnapshots(
        classDetails?.id,
    );
    const { data: profile } = useProfile();
    const { mutate: createYgSnapshot } = useCreateSnapshotMutation(() => {
        refetchSnapshots();
        setTimeout(() => {
            refetchSnapshots();
        }, 10000);
    });
    const { mutate: removeSnapshot } = useRemoveSnapshotMutation(() => refetchSnapshots());
    const getSnapshotAvailableTypes = () => {
        const actions: SnapshotModalActions[] = [];

        if (
            !archived &&
            (profile?.userRole === SchoolUserRole.SCHOOL_ADMIN ||
                profile?.userRole === SchoolUserRole.OWNER ||
                profile?.isLeaderOfAll ||
                (profile?.leaderOfSubjectAreas &&
                    profile?.leaderOfSubjectAreas.length > 0 &&
                    !!profile.leaderOfSubjectAreas.find(
                        losa => losa.id === classDetails?.subjectArea?.id,
                    )))
        ) {
            actions.push(SnapshotModalActions.CREATE);
        }
        actions.push(SnapshotModalActions.VIEW_LIST);
        actions.push(SnapshotModalActions.COMPARE);

        return actions;
    };

    const urlWithFilters = lastFilterOptions
        ? ROUTE_CLASS_CLASS_LIST_COHORT.replace(":cohort", `${lastFilterOptions.cohort}`) +
          `?subjectArea=${lastFilterOptions.filters?.subjectAreaId || ""}&qualification=${
              lastFilterOptions.filters?.qualificationId || ""
          }&yearGroup=${lastFilterOptions.filters?.yearGroup || ""}`
        : ROUTE_CLASS_CLASS_LIST;

    const handleTypeChange = (changeType: DataTypeTypes) => {
        if (!changeType && dataType === DataTypeTypes.SNAPSHOT) {
            setSnapshotModalOpen(true);
        } else if (changeType && changeType === DataTypeTypes.SNAPSHOT) {
            setSnapshotModalOpen(true);
        } else if (handleDataTypeChange) {
            handleDataTypeChange(changeType);
        }
    };

    useEffect(() => {
        refetchSnapshots();
    }, [classDetails?.id]);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
        >
            <GridHeaderBack
                onClick={() => {
                    navigate(urlWithFilters);
                }}
            />
            <Box
                className="classTrackerHeaderDetails"
                display="flex"
                justifyContent="space-between"
                flex={1}
                py={1.5}
                px={2.5}
            >
                <Box display="flex" alignItems="center">
                    {classDetails && classDetails.id ? (
                        <TrackerClassDetails classDetails={classDetails} />
                    ) : (
                        <></>
                    )}
                </Box>

                {classDetails?.tiers && classDetails.tiers.length > 1 ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        className="classTrackerHeaderTierSelect"
                    >
                        <TierHeaderSelect
                            tiers={classDetails.tiers}
                            selectedTier={tier}
                            handleChange={tier => {
                                if (tier !== null) {
                                    navigate(
                                        generateTrackerUrl(
                                            ROUTE_TRACKER_CLASS_TRACKER,
                                            classDetails?.id,
                                            cohort,
                                            classDetails?.subjectArea?.name,
                                            classDetails?.qualification?.name,
                                            classDetails?.specification,
                                            tier,
                                            classDetails?.yearGroup,
                                        ),
                                    );
                                    navigate(0);
                                }
                            }}
                        />
                    </Box>
                ) : (
                    <></>
                )}
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Box display="flex" alignItems="center" className="classTrackerHeaderTogglers">
                        {handleToolsVisible && (
                            <GridToolsToggleButton
                                disabled={isLoadingGrid || false}
                                dataType={dataType || DataTypeTypes.LIVE}
                                isChecked={toolsVisible || false}
                                handleChange={() => handleToolsVisible(!toolsVisible)}
                            />
                        )}
                        <GridFiltersToggleButton
                            disabled={isLoadingGrid || false}
                            isChecked={filtersVisible || false}
                            handleChange={() => handleFiltersVisible(!filtersVisible)}
                        />
                        <GridDataTypeButtons
                            dataType={dataType as DataTypeTypes}
                            hasForecast={hasForecast}
                            handleChange={handleTypeChange}
                            isLoadingGrid={isLoadingGrid}
                        />
                    </Box>
                    <GridSnapshotCompareInfo snapshotData={snapshotData} snapshots={snapshots} />
                </Box>
            </Box>
            <SnapshotModal
                snapshots={snapshots as ShortSnapshotItem[]}
                availableTypes={getSnapshotAvailableTypes()}
                open={snapshotModalOpen}
                hasForecast={hasForecast}
                handleClose={() => {
                    setSnapshotModalOpen(false);
                }}
                handleCreateSnapshot={name => {
                    if (classDetails?.classTrackerGroupId) {
                        createYgSnapshot({
                            classTrackerGroupId: classDetails?.classTrackerGroupId,
                            name,
                        });
                    }
                }}
                handleSnapshotRemove={snapshotId => {
                    if (classDetails?.classTrackerGroupId) {
                        removeSnapshot({
                            classTrackerGroupId: classDetails?.classTrackerGroupId,
                            snapshotId,
                        });
                    }
                }}
                handleSnapshotDataChange={(snapshotData: SnapshotData) => {
                    if (snapshotData && handleDataTypeChange) {
                        handleDataTypeChange(DataTypeTypes.SNAPSHOT);
                        setSnapshotData(snapshotData);
                    }
                    setSnapshotModalOpen(false);
                }}
            />
        </Box>
    );
};

export default TrackerHeader;
