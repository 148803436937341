import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const getStudentReportByYearGroupEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/student-report/{cohort}/{yearGroup}/by-year-group/`,
    method: ApiRequests.POST,
};

export interface StudentReportByYearGroupRequest {
    onlySummary: boolean;
    classTrackerGroups: ListObject<number>[] | null;
}
