import ReportActionsContainer from "./ReportActionsContainer";
import nullableField from "src/forms/nullableField";
import FormikObserver from "src/forms/FormikObserver";
import FormikRef from "src/forms/FormikRef";
import { Box, MenuItem, Typography, Grid, Divider, FormControlLabel, Radio } from "@mui/material";
import { useSnackbar } from "notistack";
import { createRef, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ApiData, ApiStatus } from "src/api/constants";
import { apiUrl, HTTP_NO_CONTENT } from "src/config/globals";
import { useResponse } from "src/hooks/useResponse";
import { AppState } from "src/store/reducers";
import { useUserReportValidation } from "../../hooks/useUserReportValidation";
import { Field, Form } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import { ReportActions } from "src/modules/report/store/actions";
import { Dispatch } from "redux";
import { filterNotNullable } from "src/services/object";
import { StudentByAttributeRequest } from "../../api/StudentReport/byAttribute";
import { useMount } from "src/hooks/useMount";
import { baseHeaders, get } from "src/services/ajax";
import { OrmReportTemplate } from "src/orm/models/ReportTemplate";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { useCohortSnapshotList } from "src/modules/school/hooks/query/CohortSnapshot/useCohortSnapshotList";
import { getSchoolAccountId } from "src/services/url";

const NullableTextField = nullableField(TextField);
const ALL_OPTIONS_SELECT = "all";

const dispatchActions = (dispatch: Dispatch) => ({
    getStudentCountForAttributeReport: (
        cohort: number,
        yearGroup: number,
        values: StudentByAttributeRequest,
    ) => {
        dispatch(ReportActions.getStudentCountForAttributeReport(cohort, yearGroup, values));
    },
    getStudentReportByAttribute: (
        cohort: number,
        yearGroup: number,
        values: StudentByAttributeRequest,
        onlySummary: boolean,
    ) => {
        dispatch(
            ReportActions.getStudentReportByAttribute(cohort, yearGroup, {
                ...values,
                onlySummary,
            }),
        );
    },
});

export interface StudentByAttributeForm {
    form: string | null;
    pupilPremium: boolean | string;
    fsm: boolean | string;
    lookedAfter: boolean | string;
    eal: boolean | string;
    priorAbility: string | null;
    senStatus: string | null;
    gender: string | null;
    ethnicity: string | null;
    senNeed: string | null;
    onlySummary: boolean;
    reportTemplate?: number;
    academicHouse?: string;
    schoolSnapshot?: number | null;
}

const convertFormDataToRequest = (values: StudentByAttributeForm): StudentByAttributeRequest => ({
    form: values.form === ALL_OPTIONS_SELECT ? null : values.form,
    pupilPremium: values.pupilPremium === "" ? null : values.pupilPremium === "true",
    fsm: values.fsm === "" ? null : values.fsm === "true",
    lookedAfter: values.lookedAfter === "" ? null : values.lookedAfter === "true",
    eal: values.eal === "" ? null : values.eal === "true",
    priorAbility: values.priorAbility === "" ? null : values.priorAbility,
    senStatus: values.senStatus === ALL_OPTIONS_SELECT ? null : values.senStatus,
    gender: values.gender === ALL_OPTIONS_SELECT ? null : values.gender,
    ethnicity: values.ethnicity === ALL_OPTIONS_SELECT ? null : { id: values.ethnicity },
    senNeed: values.senNeed === ALL_OPTIONS_SELECT ? null : { id: values.senNeed },
    onlySummary: values.onlySummary || false,
    reportTemplate: values.reportTemplate ? { id: values.reportTemplate } : undefined,
    academicHouse: values.academicHouse === ALL_OPTIONS_SELECT ? undefined : values.academicHouse,
    schoolSnapshot: values.schoolSnapshot ? { id: values.schoolSnapshot } : null,
});

const StudentReportByAttributeTab = ({
    cohort,
    yearGroup,
    reportTemplates,
}: {
    cohort: number;
    yearGroup: number;
    reportTemplates: OrmReportTemplate[];
}) => {
    const { t } = useTranslation();
    const form = createRef() as any;
    const { enqueueSnackbar } = useSnackbar();
    const [locked, setLocked] = useState(false);
    const [studentForms, setStudentForms] = useState([]);
    const [studentAcademicHouses, setStudentAcademicHouses] = useState([]);
    const { apiValidation, checkValidation } = useUserReportValidation();
    const dispatch = useDispatch();
    const { data: snapshots, refetch: refetchSnapshots } = useCohortSnapshotList(cohort, {
        onlyCreated: true,
    });

    const { getStudentCountForAttributeReport, getStudentReportByAttribute } =
        dispatchActions(dispatch);

    const {
        data: {
            studentProperties: {
                senNeed: senNeedList,
                ethnicities: ethnicityList,
                priorAbility,
                senStatus: senStatusList,
                genders: genderList,
            },
        },
    } = useCoreValues();

    const {
        apiReport,
        studentCount,
    }: {
        apiReport: ApiData;
        studentCount: number;
    } = useSelector(
        (state: AppState) => ({
            apiReport: state.api.report.getStudentReportByAttribute,
            studentCount: state.report.attributeReportStudentNumber,
        }),
        shallowEqual,
    );

    const initialFormData = {
        form: ALL_OPTIONS_SELECT,
        pupilPremium: "",
        fsm: "",
        lookedAfter: "",
        eal: "",
        academicHouse: ALL_OPTIONS_SELECT,
        priorAbility: "",
        senStatus: ALL_OPTIONS_SELECT,
        gender: ALL_OPTIONS_SELECT,
        ethnicity: ALL_OPTIONS_SELECT,
        senNeed: ALL_OPTIONS_SELECT,
        onlySummary: false,
        schoolSnapshot: 0,
        reportTemplate:
            reportTemplates && reportTemplates.length > 0 && reportTemplates[0].id
                ? reportTemplates[0].id
                : undefined,
    };

    useMount(() => {
        checkValidation();
        refetchSnapshots();
        getStudentCountForAttributeReport(
            cohort,
            yearGroup,
            convertFormDataToRequest(initialFormData),
        );
    });

    const handleFormChange = values => {
        getStudentCountForAttributeReport(cohort, yearGroup, convertFormDataToRequest(values));
    };

    useEffect(() => {
        if (reportTemplates && reportTemplates.length > 0 && form && form.current) {
            const defaultTemplate = reportTemplates.find(rt => rt.isDefault);
            form.current.setFieldValue(
                "reportTemplate",
                defaultTemplate ? defaultTemplate.id : reportTemplates[0].id,
            );
        }
    }, [reportTemplates]);

    useEffect(() => {
        if (apiValidation.status === ApiStatus.ERROR && apiReport.status === ApiStatus.ERROR) {
            setLocked(true);
        }
        if (
            apiValidation.status === ApiStatus.SUCCESS &&
            apiValidation.responseStatus === HTTP_NO_CONTENT &&
            apiReport.status === ApiStatus.ERROR
        ) {
            setLocked(false);
        }
    }, [apiReport, apiValidation]);

    useResponse(() => {
        if (
            apiReport.status === ApiStatus.SUCCESS &&
            apiReport.responseStatus === HTTP_NO_CONTENT
        ) {
            setLocked(false);
            enqueueSnackbar(t("report.studentReport.byStudent.requestSuccess"), {
                variant: "success",
            });
        }
        checkValidation();
    }, apiReport);

    useEffect(() => {
        get(
            apiUrl(
                `school/${getSchoolAccountId()}/analysis/student-report/${cohort}/${yearGroup}/student-forms/`,
            ),
            undefined,
            baseHeaders(),
        ).subscribe(res => {
            if (res.response) setStudentForms(res.response);
        });
        get(
            apiUrl(
                `school/${getSchoolAccountId()}/analysis/student-report/${cohort}/${yearGroup}/student-academic-houses/`,
            ),
            undefined,
            baseHeaders(),
        ).subscribe(res => res.response && setStudentAcademicHouses(res.response));
    }, [cohort, yearGroup]);

    const requestReport = (values: StudentByAttributeForm, onlySummary) => {
        if (cohort && yearGroup > -1 && values) {
            getStudentReportByAttribute(
                cohort,
                yearGroup,
                convertFormDataToRequest(values),
                onlySummary,
            );
        }
    };
    const filteredSnapshots = snapshots?.filter(({ isCreated }) => isCreated);
    return (
        <Box pr="9.5%">
            <Typography variant="h6" gutterBottom>
                {t("report.studentReport.byAttribute.header")}
            </Typography>

            <Grid container spacing={4}>
                <Grid item sm={6}>
                    <Typography gutterBottom>
                        {t("report.studentReport.byAttribute.hint")}
                    </Typography>
                </Grid>
                <Grid item sm={6} />
            </Grid>
            <FormikRef
                ref={form}
                initialValues={initialFormData}
                onSubmit={requestReport}
                enableReinitialize={true}
            >
                {formProps => (
                    <Form>
                        <FormikObserver values={formProps.values} onChange={handleFormChange} />

                        <Grid container spacing={4}>
                            {filteredSnapshots?.length > 0 && (
                                <>
                                    <Grid item sm={4}>
                                        <Field
                                            name="schoolSnapshot"
                                            label={t("report.studentReport.byAttribute.source")}
                                            component={TextField}
                                            select
                                        >
                                            <MenuItem key={0} value={0}>
                                                {t("report.studentReport.byStudent.liveTracking")}
                                            </MenuItem>
                                            {filteredSnapshots.map(s => (
                                                <MenuItem key={s.id} value={s.id}>
                                                    {s.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                    <Grid item sm={4}></Grid>
                                    <Grid item sm={4}></Grid>
                                </>
                            )}
                            <Grid item sm={4}>
                                <Field
                                    name="form"
                                    label={t("report.studentReport.byAttribute.form")}
                                    component={TextField}
                                    select
                                >
                                    <MenuItem value={ALL_OPTIONS_SELECT}>
                                        {t("common.all")}
                                    </MenuItem>
                                    {studentForms.map(s => (
                                        <MenuItem key={s} value={s}>
                                            {s}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item sm={4}>
                                <Field
                                    name="academicHouse"
                                    label={t("report.studentReport.byAttribute.academicHouse")}
                                    component={TextField}
                                    select
                                >
                                    <MenuItem value={ALL_OPTIONS_SELECT}>
                                        {t("common.all")}
                                    </MenuItem>
                                    {studentAcademicHouses.map((s, i) => (
                                        <MenuItem key={i} value={s}>
                                            {s}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item sm={4} />
                            <Grid item sm={4}>
                                <Field
                                    name="ethnicity"
                                    select
                                    label={t("report.studentReport.byAttribute.ethnicity")}
                                    component={NullableTextField}
                                    placeholder={t("report.studentReport.byAttribute.ethnicity")}
                                >
                                    <MenuItem value={ALL_OPTIONS_SELECT}>
                                        {t("common.all")}
                                    </MenuItem>
                                    {filterNotNullable(ethnicityList).map(e => (
                                        <MenuItem key={e.id} value={e.id}>
                                            {e.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item sm={4}>
                                <Field
                                    name="gender"
                                    label={t("report.studentReport.byAttribute.gender")}
                                    select
                                    component={NullableTextField}
                                >
                                    <MenuItem value={ALL_OPTIONS_SELECT}>
                                        {t("common.all")}
                                    </MenuItem>
                                    {genderList.map(gender => (
                                        <MenuItem key={gender} value={gender}>
                                            {t(`common.${gender}`)}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item sm={4} />
                            <Grid item sm={4}>
                                <Field
                                    name="senNeed"
                                    select
                                    label={t("report.studentReport.byAttribute.senNeed")}
                                    component={NullableTextField}
                                >
                                    <MenuItem value={ALL_OPTIONS_SELECT}>
                                        {t("common.all")}
                                    </MenuItem>
                                    {filterNotNullable(senNeedList).map(s => (
                                        <MenuItem key={s.id} value={s.id}>
                                            {s.id}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item sm={4}>
                                <Field
                                    name="senStatus"
                                    label={t("report.studentReport.byAttribute.senStatus")}
                                    component={NullableTextField}
                                    select
                                >
                                    <MenuItem value={ALL_OPTIONS_SELECT}>
                                        {t("common.all")}
                                    </MenuItem>
                                    {senStatusList.map(senKey => (
                                        <MenuItem key={senKey} value={senKey}>
                                            {senKey}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item sm={4}></Grid>
                        </Grid>
                        <Divider />
                        <Grid container>
                            <Grid item sm={4}>
                                <Typography gutterBottom>
                                    {t("report.studentReport.byAttribute.pupilPremium")}
                                </Typography>
                                <Field
                                    name="pupilPremium"
                                    label="Radio Group"
                                    component={RadioGroup}
                                    row
                                >
                                    <FormControlLabel
                                        value={""}
                                        control={<Radio />}
                                        label={t("common.all")}
                                    />
                                    <FormControlLabel
                                        value={"true"}
                                        control={<Radio />}
                                        label={t("common.yes")}
                                    />
                                    <FormControlLabel
                                        value={"false"}
                                        control={<Radio />}
                                        label={t("common.no")}
                                    />
                                </Field>
                            </Grid>
                            <Grid item sm={4}>
                                <Typography gutterBottom>
                                    {t("report.studentReport.byAttribute.fsm")}
                                </Typography>
                                <Field name="fsm" label="Radio Group" component={RadioGroup} row>
                                    <FormControlLabel
                                        value={""}
                                        control={<Radio />}
                                        label={t("common.all")}
                                    />
                                    <FormControlLabel
                                        value={"true"}
                                        control={<Radio />}
                                        label={t("common.yes")}
                                    />
                                    <FormControlLabel
                                        value={"false"}
                                        control={<Radio />}
                                        label={t("common.no")}
                                    />
                                </Field>
                            </Grid>
                            <Grid item sm={4}>
                                <Typography gutterBottom>
                                    {t("report.studentReport.byAttribute.lookedAfter")}
                                </Typography>
                                <Field
                                    name="lookedAfter"
                                    label="Radio Group"
                                    component={RadioGroup}
                                    row
                                >
                                    <FormControlLabel
                                        value={""}
                                        control={<Radio />}
                                        label={t("common.all")}
                                    />
                                    <FormControlLabel
                                        value={"true"}
                                        control={<Radio />}
                                        label={t("common.yes")}
                                    />
                                    <FormControlLabel
                                        value={"false"}
                                        control={<Radio />}
                                        label={t("common.no")}
                                    />
                                </Field>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container>
                            <Grid item sm={4}>
                                <Typography gutterBottom>
                                    {t("report.studentReport.byAttribute.eal")}
                                </Typography>
                                <Field name="eal" label="Radio Group" component={RadioGroup} row>
                                    <FormControlLabel
                                        value={""}
                                        control={<Radio />}
                                        label={t("common.all")}
                                    />
                                    <FormControlLabel
                                        value={"true"}
                                        control={<Radio />}
                                        label={t("common.yes")}
                                    />
                                    <FormControlLabel
                                        value={"false"}
                                        control={<Radio />}
                                        label={t("common.no")}
                                    />
                                </Field>
                            </Grid>

                            <Grid item sm={8}>
                                <Typography gutterBottom>
                                    {t("report.studentReport.byAttribute.priorAbility")}
                                </Typography>
                                <Field
                                    name="priorAbility"
                                    label="Radio Group"
                                    component={RadioGroup}
                                    row
                                >
                                    <FormControlLabel
                                        value={""}
                                        control={<Radio />}
                                        label={
                                            <>
                                                {t(
                                                    `report.studentReport.byAttribute.priorAbilityAll`,
                                                )}{" "}
                                                <Box component="span" fontStyle="italic">
                                                    (
                                                    {t(
                                                        `report.studentReport.byAttribute.priorAbilityEveryone`,
                                                    )}
                                                    )
                                                </Box>
                                            </>
                                        }
                                    />
                                    {priorAbility.map(paKey => (
                                        <FormControlLabel
                                            key={paKey}
                                            value={paKey}
                                            control={<Radio />}
                                            label={
                                                <>
                                                    {paKey}{" "}
                                                    <Box component="span" fontStyle="italic">
                                                        (
                                                        {t(`students.addEdit.priorAbility${paKey}`)}
                                                        )
                                                    </Box>
                                                </>
                                            }
                                        />
                                    ))}
                                </Field>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={4}>
                            {reportTemplates && reportTemplates.length > 0 ? (
                                <Grid item sm={4}>
                                    <Field
                                        name="reportTemplate"
                                        select
                                        label={t("report.studentReport.tabs.reportTemplate")}
                                        component={TextField}
                                    >
                                        {filterNotNullable(reportTemplates).map(s => (
                                            <MenuItem key={s.id} value={s.id}>
                                                {s.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                            ) : (
                                <></>
                            )}
                            <Grid item sm={12}>
                                <ReportActionsContainer
                                    studentsCount={studentCount}
                                    refreshReportHandler={() => checkValidation()}
                                    requestReportHandler={(onlySummary: boolean) => {
                                        if (form?.current) {
                                            requestReport(
                                                form?.current?.values as StudentByAttributeForm,
                                                onlySummary,
                                            );
                                        }
                                    }}
                                    locked={locked}
                                    showOnlySummaryCheckbox={true}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </FormikRef>
        </Box>
    );
};

export default StudentReportByAttributeTab;
