import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export interface ClassFilter {
    qualifications: {
        yearGroups: number[];
        id: number;
        name: string;
    }[];
    id: number;
    name: string;
}

export const CLASS_FILTERS = "classFilters";

export const useClassFilters = (cohortId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CLASS_FILTERS, schoolAccountId, cohortId],
        queryFn: async (): Promise<ClassFilter[]> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/class-tracker/by-cohort/${cohortId}/filters/`,
            );

            return data || [];
        },
    });
};
