import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import axiosInstance from "src/config/axios";
import { useSnackbar } from "notistack";
import { useAuthContext } from "src/context/AuthContext";
import { ROUTE_AUTH_POST_LOGIN } from "src/routes";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export interface LoginRequest {
    email: string;
    password: string;
    responseToken: string;
}

export interface UserContext {
    email?: string;
    title: string;
    language: string;
    trusts: ListObject<number>[];
    schoolAccounts: {
        id: number;
        name: string;
        logoUrl: string;
    }[];
    firstName: string;
    lastName: string;
    id: number;
}

export interface LoginResponse {
    token: string;
    userContext: UserContext;
}

const apiLogin = async (values: LoginRequest): Promise<AxiosResponse<LoginResponse>> => {
    return await axiosInstance.post(`api/auth/login/`, values);
};

export const useLogin = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { setToken } = useAuthContext();
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: LoginRequest) => apiLogin(values),
        onSuccess: (res: AxiosResponse<LoginResponse>) => {
            setToken(res.data.token);
            navigate(ROUTE_AUTH_POST_LOGIN);
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("common.unknownError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
