import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

export interface StudentInviteRequest {
    id?: number;
}

const apiInviteStudent = async (values: StudentInviteRequest) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/student/${values.id}/invite/`,
        values,
    );
};

export const useStudentInviteByIdMutation = (onSuccess: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: StudentInviteRequest) => apiInviteStudent(values),
        onSuccess: () => {
            enqueueSnackbar(t("students.addEdit.studentInvited"), { variant: "success" });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
