import Footer from "src/modules/common/components/Footer";
import { Outlet } from "react-router-dom";
import { useUserContext } from "src/modules/user/hooks/useUserContext";

export default function AuthLayout() {
    const { data: userContext } = useUserContext();

    return (
        <>
            <Outlet />
            {userContext && <Footer mobile />}
        </>
    );
}
