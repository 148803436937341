import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";

const AssessmentRemovePrompt = ({ open, handleClose }) => {
    const { t } = useTranslation();
    return (
        <PromptDeleteConfirm open={open} onClose={handleClose}>
            <Typography variant="h3">{t("class.pastPaper.table.warning")}</Typography>
            <Box pt={3}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: t("class.pastPaper.table.warningHint"),
                    }}
                ></div>
            </Box>
            <Box pt={3}>{t("class.pastPaper.table.warningConfirm")}</Box>
        </PromptDeleteConfirm>
    );
};

export default AssessmentRemovePrompt;
