import Footer from "src/modules/common/components/Footer";
import MobileLock from "src/components/MobileLock";
import { Outlet } from "react-router-dom";
import TrustNavbar from "src/modules/common/components/TrustNavbar";

export default function TrustLayout() {
    return (
        <>
            <TrustNavbar />
            <Outlet />
            <Footer />
            <MobileLock />
        </>
    );
}
