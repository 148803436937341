import { Button, Paper, Box, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApiData, ApiStatus } from "src/api/constants";
import CustomTable from "src/components/CustomTable";
import PromptDialog from "src/forms/PromptDialog";
import { useMount } from "src/hooks/useMount";
import { usePrevious } from "src/hooks/usePrevious";
import { useResponse } from "src/hooks/useResponse";
import { AppState } from "src/store/reducers";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { OrmReportTemplate } from "src/orm/models/ReportTemplate";
import { reportTemplateListSelector } from "src/modules/report/selectors/ReportTempatesSelectors";
import { Link } from "react-router-dom";
import { ROUTE_REPORT_TEMPLATE_ADD, ROUTE_REPORT_TEMPLATE_EDIT } from "src/routes";
import { CellDataGetterParams } from "src/components/CustomTable/types";
import Icon from "src/components/Icon";
import { mdiStarOutline } from "@mdi/js";
import { ReportActions } from "../store/actions";
import AppContainer from "src/components/AppContainer";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const dispatchActions = (dispatch: Dispatch) => ({
    getReportTemplateList: () => {
        dispatch(ReportActions.getReportTemplateList());
    },
    removeReportTemplate: (id: number) => {
        dispatch(ReportActions.removeReportTemplate(id));
    },
    setAsDefault: (id: number) => {
        dispatch(ReportActions.setAsDefaultReportTemplate(id));
    },
});

const ReportTemplateListContainer = () => {
    const dispatch = useDispatch();
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);
    const { getReportTemplateList, removeReportTemplate, setAsDefault } = dispatchActions(dispatch);
    const { t }: { t: TFunction } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { attachSchoolId } = useSchoolNavigate();

    const {
        apiRemove,
        reportTemplates,
    }: {
        apiList: ApiData;
        apiRemove: ApiData;
        reportTemplates: OrmReportTemplate[];
    } = useSelector(
        (state: AppState) => ({
            apiList: state.api.report.getReportTemplateList,
            apiRemove: state.api.report.removeReportTemplate,
            reportTemplates: reportTemplateListSelector(state),
        }),
        shallowEqual,
    );

    const handleRemoveDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            removeReportTemplate(removeId);
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    useMount(() => getReportTemplateList());

    const prevApiRemoveStatus = usePrevious(apiRemove.status);

    useResponse(() => {
        if (apiRemove.status === ApiStatus.SUCCESS && prevApiRemoveStatus === ApiStatus.LOADING) {
            getReportTemplateList();
        }
        if (apiRemove.status === ApiStatus.ERROR && prevApiRemoveStatus === ApiStatus.LOADING) {
            enqueueSnackbar(t("report.reportTemplate.errorRemoving"), {
                ...SnackbarErrorOptions,
            });
        }
    }, apiRemove);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("report.reportTemplate.tab")}
            </Typography>
            <Box mb={4}>
                <Button
                    component={Link}
                    to={attachSchoolId(ROUTE_REPORT_TEMPLATE_ADD)}
                    color="primary"
                >
                    {t("report.reportTemplate.addReportTemplateButton")}
                </Button>
            </Box>

            <Paper>
                <CustomTable
                    data={reportTemplates}
                    showPaginator
                    showRowsPerPageOptions={false}
                    columns={[
                        {
                            key: "name",
                            label: t("report.reportTemplate.name"),
                        },
                        {
                            key: "isDefault",
                            label: t("report.reportTemplate.isDefault"),
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmReportTemplate>) => {
                                return rowData.isDefault ? t("common.yes") : t("common.no");
                            },
                        },
                    ]}
                    actions={[
                        getMenuActionProps("edit", {
                            linkCallback: (rowData: OrmReportTemplate) =>
                                attachSchoolId(ROUTE_REPORT_TEMPLATE_EDIT).replace(
                                    ":id",
                                    `${rowData.id}`,
                                ),
                        }),
                        getMenuActionProps("default", {
                            text: t("report.reportTemplate.setAsDefault"),
                            icon: <Icon path={mdiStarOutline} />,
                            onAction: (rowData: OrmReportTemplate) => {
                                if (rowData.id) setAsDefault(rowData.id);
                            },
                            showAction: (rowData: OrmReportTemplate) => !rowData.isDefault,
                        }),
                        getMenuActionProps("delete", {
                            onAction: (rowData: OrmReportTemplate) => {
                                setRemoveDialogOpened(true);
                                setRemoveId(rowData.id);
                            },
                            showAction: (rowData: OrmReportTemplate) => !rowData.isDefault,
                        }),
                    ]}
                />
            </Paper>
            <PromptDialog open={removeDialogOpened} onClose={handleRemoveDialogClose}>
                {t("report.reportTemplate.deleteConfirmation")}
            </PromptDialog>
        </AppContainer>
    );
};

export default ReportTemplateListContainer;
