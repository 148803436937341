import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { EMAIL_QUERY } from "./useEmail";

const apiMakeEmailPrimary = async (id: number): Promise<AxiosResponse> => {
    return await axiosInstance.post(`user/email/${id}/make-primary/`);
};

export const useMakeEmailPrimary = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (id: number) => apiMakeEmailPrimary(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [EMAIL_QUERY] });
            enqueueSnackbar(t("account.myAccountTab.setPrimaryEmailSuccess"), {
                variant: "success",
            });
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
