import ActionsTableButton from "src/components/ActionTableButton";
import Icon from "src/components/Icon";
import HeadingCounter from "src/components/HeadingCounter";
import CheckboxField from "src/forms/CheckboxField";
import ErrorMessage from "src/forms/ErrorMessage";
import Datepicker from "src/components/Datepicker";
import {
    Box,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Radio,
    Typography,
    Grid,
    Button,
    TextField as MuiTextField,
    Divider,
} from "@mui/material";
import { Field, FieldArray, useFormikContext } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrmClassTrackerGroup, TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { CustomisedAssessmentUnitModel } from "../../models/AssessmentUnitModel";
import { OrmTopic } from "src/orm/models/Topic";
import { OrmGrade } from "src/orm/models/Grade";
import { AssessmentCreateFormShape } from "./AddAssessmentForm";
import {
    mdiArrowRight,
    mdiCheckboxMultipleBlankOutline,
    mdiChevronDown,
    mdiChevronUp,
    mdiDelete,
    mdiPlus,
} from "@mdi/js";
import { useCustomisedAssessmentGradeBoundaries } from "../../hooks/Customised/useGradeBoundary";
import { TFunction } from "i18next";
import * as yup from "yup";
import { StyledConvertsLabel, StyledGrid, StyledTotalMaxMarks } from "../ConvertsLabel";
import COLORS from "src/styles/colors";
import { AssessmentTopicSelector } from "../AssessmentTopicSelector";

export interface CustomisedAssessmentFormShape {
    gradeBoundaryFactor: number;
    examDate: string;
    dataEntryType: "single" | "qla";
    tierUnits: {
        tier: TiersTypes;
        unit: number;
        unitName?: string;
    }[];
    hideForNow: boolean;
    personalisedAssesmentQuestions: {
        question: string;
        maxValue: number;
        topics: number[];
    }[];
    gradeBoundaryValues: {
        id: number;
        name?: string;
        requireMark: number;
    }[];
    gradeBoundaryType: "tracker" | "factor" | "custom";
    name: string;
    countsTowardGrade: boolean;
    hiddenColumns: boolean;
    visibleClassTrackers: { id: number; name: string; visible: boolean }[];
    savedAndCollapsed?: boolean;
    showQlaInReports: boolean;
}

export const customisedAssessmentFormSchema = (t: TFunction) =>
    yup.array(
        yup.object({
            name: yup.string().required(t("common.form.validation.name.required")),
            examDate: yup
                .mixed()
                .typeError(t("common.form.validation.examDate.type"))
                .notRequired()
                .nullable(),
            countsTowardGrade: yup.boolean().notRequired(),
            tierUnits: yup
                .array(
                    yup.object({
                        tier: yup.mixed().required(t("common.form.validation.tier.required")),
                        unit: yup.mixed().nullable(),
                    }),
                )
                .test("has-unit", t("common.form.validation.tierUnit.one"), function (value) {
                    return value.some(v => v.unit);
                }),
            gradeBoundaryValues: yup.mixed().when("gradeBoundaryType", ([gradeBoundaryType]) => {
                return gradeBoundaryType === "custom"
                    ? yup.array(
                          yup.object({ requireMark: yup.mixed().required(t("common.required")) }),
                      )
                    : yup.mixed().notRequired().nullable();
            }),
            personalisedAssesmentQuestions: yup
                .array()
                .of(
                    yup.object().shape({
                        question: yup
                            .string()
                            .required(
                                t("common.form.validation.personalisedAssesmentQuestions.required"),
                            ),
                        maxValue: yup
                            .number()
                            .min(1)
                            .max(9999)
                            .required(t("common.form.validation.maxValue.required"))
                            .typeError(t("common.form.validation.maxValue.type"))
                            .label(t("common.form.label.maxValue")),
                    }),
                )
                .min(1),
            gradeBoundaryFactor: yup.string().when("gradeBoundaryType", ([gradeBoundaryType]) => {
                return gradeBoundaryType === "factor"
                    ? yup.string().required()
                    : yup.string().nullable().notRequired();
            }),
        }),
    );

export const questionActions = (arrayHelper, i, t) => {
    return [
        {
            text: t("class.assessment.add.removeQLA"),
            icon: <Icon path={mdiDelete} />,
            onAction: () => arrayHelper.remove(i),
        },
        {
            text: t("class.assessment.add.up"),
            icon: <Icon path={mdiChevronUp} />,
            onAction: () => arrayHelper.move(i, i - 1),
        },
        {
            text: t("class.assessment.add.down"),
            icon: <Icon path={mdiChevronDown} />,
            onAction: () => arrayHelper.move(i, i + 1),
        },
        {
            text: t("class.assessment.add.duplicate"),
            icon: <Icon path={mdiCheckboxMultipleBlankOutline} />,
            onAction: rowData =>
                arrayHelper.push({
                    ...rowData,
                }),
        },
    ];
};

interface OwnProps {
    tiers: TiersTypes[] | null;
    index: number;
    classList?: OrmClassTrackerGroup[];
    caUnits: { tier: TiersTypes; units: CustomisedAssessmentUnitModel[] }[];
    topics: OrmTopic[];
    grades: OrmGrade[];
    canAddCustomGradeBoundary: boolean;
}

const AddCustomisedAssessmentForm: FC<OwnProps> = ({
    classList,
    caUnits,
    tiers,
    index,
    topics,
    grades,
    canAddCustomGradeBoundary,
}) => {
    const { t } = useTranslation();
    const { values, setFieldValue, errors } = useFormikContext<AssessmentCreateFormShape>();
    const previouslySaved = values.customisedAssessments[index].savedAndCollapsed;
    const [classTrackerGroupId, setClassTrackerGroupId] = useState<number | null>(null);
    const [showTierWarning, setShowTierWarning] = useState<boolean>(false);

    const { data: gradeBoundaries, refetch: refetchGradeBoundaries } =
        useCustomisedAssessmentGradeBoundaries({
            classTrackerGroupId,
            tierUnits: values.customisedAssessments[index]?.tierUnits
                .filter(tu => tu.unit)
                .map(tu => ({ ...tu, unit: { id: tu.unit } })),
        });

    useEffect(() => {
        const classTrackerGroup = classList?.find(cl => cl.yearGroup === values.yearGroup);
        if (classTrackerGroup && classTrackerGroupId !== classTrackerGroup.id) {
            setClassTrackerGroupId(classTrackerGroup.id || null);
        }
    }, [classList]);

    useEffect(() => {
        const assessment = values.customisedAssessments[index];
        if (
            assessment.gradeBoundaryType === "custom" &&
            assessment?.tierUnits.filter(tu => tu.tier).length > 0
        ) {
            refetchGradeBoundaries();
        }
    }, [
        classTrackerGroupId,
        values.customisedAssessments[index]?.tierUnits,
        values.customisedAssessments[index].gradeBoundaryType,
    ]);

    useEffect(() => {
        if (values.customisedAssessments[index].hideForNow && classList) {
            setFieldValue(
                `customisedAssessments.${index}.visibleClassTrackers`,
                values.customisedAssessments[index].visibleClassTrackers.map(c => ({
                    ...c,
                    visible: c.visible || false,
                })),
            );
        }
    }, [values.customisedAssessments[index].hideForNow]);

    useEffect(() => {
        if (values.customisedAssessments[index].visibleClassTrackers.length > 0) {
            setFieldValue(
                `customisedAssessments.${index}.hideForNow`,
                !values.customisedAssessments[index].visibleClassTrackers.find(
                    c => c.visible === true,
                ),
            );
        }
    }, [values.customisedAssessments[index].visibleClassTrackers]);

    useEffect(() => {
        if (tiers && !previouslySaved) {
            if (tiers?.length > 0) {
                setFieldValue(
                    `customisedAssessments.${index}.tierUnits`,
                    tiers.map(tier => ({ tier, unit: null })),
                );
            } else {
                setFieldValue(`customisedAssessments.${index}.tierUnits`, {
                    tier: "-",
                    unit: null,
                });
            }
        }
    }, [tiers]);

    useEffect(() => {
        if (grades) {
            if (
                grades?.length > 0 &&
                values.customisedAssessments[index].gradeBoundaryType === "custom"
            ) {
                setFieldValue(
                    `customisedAssessments.${index}.gradeBoundaryValues`,
                    grades.map((grade, gI) => ({
                        ...grade,
                        requireMark:
                            values.customisedAssessments?.[index]?.gradeBoundaryValues?.[gI]
                                ?.requireMark || "",
                    })),
                );
            } else {
                setFieldValue(`customisedAssessments.${index}.gradeBoundaryValues`, []);
            }
        }
    }, [grades, values.customisedAssessments[index].gradeBoundaryType]);

    useEffect(() => {
        if (values.customisedAssessments[index].dataEntryType === "single") {
            setFieldValue(
                `customisedAssessments.${index}.personalisedAssesmentQuestions.0.question`,
                values.customisedAssessments[index].name,
            );
        }
    }, [values.customisedAssessments[index].name]);

    useEffect(() => {
        const classTrackers = [];
        classList
            ?.filter(
                ctg =>
                    ctg.yearGroup === values.yearGroup &&
                    ctg.specification.id === values.specification,
            )
            .forEach(ctg => {
                ctg.classTrackers.forEach((classTracker, cti) =>
                    classTrackers.push({
                        ...classTracker,
                        visible: values.customisedAssessments[index].savedAndCollapsed
                            ? values.customisedAssessments?.[index].visibleClassTrackers?.[cti]
                                  ?.visible
                            : true,
                    }),
                );
            });
        setFieldValue(`customisedAssessments.${index}.visibleClassTrackers`, classTrackers);
    }, [classList, values.yearGroup, values.specification]);

    useEffect(() => {
        const unitsSelected = values.customisedAssessments[index]?.tierUnits.filter(
            tu => tu.unit,
        ).length;

        if (
            unitsSelected > 0 &&
            unitsSelected < values.customisedAssessments[index]?.tierUnits.length &&
            !showTierWarning
        ) {
            setShowTierWarning(true);
        } else {
            setShowTierWarning(false);
        }
    }, [values.customisedAssessments[index]?.tierUnits]);

    const isMultiCheckboxChecked = !values.customisedAssessments[index]?.visibleClassTrackers.find(
        vct => vct.visible === false,
    );

    useEffect(() => {
        if (
            canAddCustomGradeBoundary &&
            values.customisedAssessments[index].gradeBoundaryType !== "custom"
        ) {
            setFieldValue(`customisedAssessments.${index}.gradeBoundaryType`, "custom");
        }
    }, [canAddCustomGradeBoundary]);

    return (
        <>
            <HeadingCounter number={2}>{t("class.assessment.add.targetTracker")}</HeadingCounter>
            <Typography component="p" variant="overline" sx={{ mb: 3 }}>
                {values.customisedAssessments?.[index]?.tierUnits.length > 0 &&
                values.customisedAssessments?.[index]?.tierUnits.find(tu => tu.tier !== "-")
                    ? t("class.assessment.add.customisedTargetTrackerHint")
                    : ""}
            </Typography>

            <Box mb={4}>
                {!values.specification && t("class.assessment.add.pleaseSelectSpecification")}

                {values.specification && (
                    <>
                        {values.customisedAssessments?.[index]?.tierUnits?.length > 0 ? (
                            <>
                                <Grid container spacing={4} sx={{ marginBottom: 4 }}>
                                    {values.customisedAssessments?.[index]?.tierUnits?.map(
                                        (tierUnit, tuI) => (
                                            <Grid key={tuI} item sm={4}>
                                                <Field
                                                    name={`customisedAssessments.${index}.tierUnits.${tuI}.unit`}
                                                    label={`${
                                                        tierUnit.tier !== "-"
                                                            ? t("common.tierName" + tierUnit.tier) +
                                                              " "
                                                            : ""
                                                    }  ${t("class.assessment.add.unit")}`}
                                                    component={TextField}
                                                    select
                                                >
                                                    {caUnits
                                                        .find(ca => ca.tier === tierUnit.tier)
                                                        ?.units?.map(unit => (
                                                            <MenuItem key={unit.id} value={unit.id}>
                                                                {unit.name}
                                                            </MenuItem>
                                                        ))}
                                                </Field>
                                                {errors &&
                                                errors?.customisedAssessments?.[index]?.[
                                                    "tierUnits"
                                                ] ? (
                                                    <ErrorMessage
                                                        name={`customisedAssessments.${index}.tierUnits`}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                        ),
                                    )}
                                </Grid>
                                {showTierWarning && (
                                    <Grid container spacing={4}>
                                        <Grid item sm={12}>
                                            <Typography
                                                sx={{ marginBottom: 3, color: COLORS.RED_1 }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t(
                                                            "class.assessment.add.tierWarning",
                                                        ),
                                                    }}
                                                ></div>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={4} sx={{ marginBottom: 3 }}>
                                    <Grid item sm={4}>
                                        <Field
                                            name={`customisedAssessments.${index}.name`}
                                            label={t("class.assessment.add.name")}
                                            component={TextField}
                                        />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Datepicker
                                            name={`customisedAssessments.${index}.examDate`}
                                            label={t("common.form.label.examDate")}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ mb: 4 }}>
                                    <Field
                                        name={`customisedAssessments.${index}.countsTowardGrade`}
                                        component={CheckboxField}
                                        label={t("class.assessment.add.countsTowardGrade")}
                                    />
                                    {!values.customisedAssessments[index].countsTowardGrade && (
                                        <Field
                                            name={`customisedAssessments.${index}.hiddenColumns`}
                                            component={CheckboxField}
                                            label={t("class.assessment.add.hiddenColumns")}
                                        />
                                    )}
                                    <Field
                                        name={`customisedAssessments.${index}.showQlaInReports`}
                                        component={CheckboxField}
                                        label={t("class.assessment.add.showQlaInReports")}
                                    />
                                </Box>
                                <Divider variant="fullWidth" />
                                <Grid container spacing={4} sx={{ marginBottom: 4 }}>
                                    <Grid item sm={12}>
                                        <Typography variant="h4" gutterBottom>
                                            {t("class.assessment.add.dataEntry")}
                                        </Typography>
                                        <Field
                                            name={`customisedAssessments.${index}.dataEntryType`}
                                            label=""
                                            component={RadioGroup}
                                            row
                                            sx={{ mb: 3 }}
                                        >
                                            <FormControlLabel
                                                value={"single"}
                                                control={<Radio />}
                                                label={t("class.assessment.add.singleMark")}
                                            />
                                            <FormControlLabel
                                                value={"qla"}
                                                control={<Radio />}
                                                label={t("class.assessment.add.qla")}
                                            />
                                        </Field>
                                        {values.customisedAssessments[index].dataEntryType ===
                                            "single" && (
                                            <Grid container spacing={4}>
                                                <Grid item sm={4}>
                                                    <Field
                                                        name={`customisedAssessments.${index}.personalisedAssesmentQuestions[0].maxValue`}
                                                        label={t("class.assessment.add.maxValue")}
                                                        component={TextField}
                                                    />
                                                </Grid>
                                                <AssessmentTopicSelector
                                                    topics={topics}
                                                    i={0}
                                                    setFieldValue={setFieldValue}
                                                    personalisedAssesmentQuestions={
                                                        values?.customisedAssessments[index]
                                                            .personalisedAssesmentQuestions
                                                    }
                                                    fieldValuePrefix={`customisedAssessments.${index}.`}
                                                />
                                            </Grid>
                                        )}
                                        {values.customisedAssessments[index].dataEntryType ===
                                            "qla" && (
                                            <FieldArray
                                                name={`customisedAssessments.${index}.personalisedAssesmentQuestions`}
                                                render={arrayHelpers => (
                                                    <>
                                                        {values.customisedAssessments[
                                                            index
                                                        ].personalisedAssesmentQuestions.map(
                                                            (paq, i) => (
                                                                <Grid
                                                                    key={i}
                                                                    container
                                                                    spacing={4}
                                                                    sx={{ mb: 3 }}
                                                                >
                                                                    <Grid item xs={4}>
                                                                        <Field
                                                                            name={`customisedAssessments.${index}.personalisedAssesmentQuestions.${i}.question`}
                                                                            label={t(
                                                                                `class.assessment.add.question`,
                                                                            )}
                                                                            component={TextField}
                                                                        />
                                                                    </Grid>
                                                                    <AssessmentTopicSelector
                                                                        topics={topics}
                                                                        i={i}
                                                                        setFieldValue={
                                                                            setFieldValue
                                                                        }
                                                                        personalisedAssesmentQuestions={
                                                                            values
                                                                                ?.customisedAssessments[
                                                                                index
                                                                            ]
                                                                                .personalisedAssesmentQuestions
                                                                        }
                                                                        fieldValuePrefix={`customisedAssessments.${index}.`}
                                                                    />
                                                                    <Grid item xs={4}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                gap: 3,
                                                                            }}
                                                                        >
                                                                            <Field
                                                                                name={`customisedAssessments.${index}.personalisedAssesmentQuestions[${i}].maxValue`}
                                                                                type="number"
                                                                                label={t(
                                                                                    `class.assessment.add.maxValue`,
                                                                                )}
                                                                                component={
                                                                                    TextField
                                                                                }
                                                                                fullWidth
                                                                            />
                                                                            <ActionsTableButton
                                                                                rowData={paq}
                                                                                actions={questionActions(
                                                                                    arrayHelpers,
                                                                                    i,
                                                                                    t,
                                                                                )}
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            ),
                                                        )}

                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                mt: 4,
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={() =>
                                                                    arrayHelpers.push({
                                                                        question: "",
                                                                        maxValue: 1,
                                                                        topics: [],
                                                                    })
                                                                }
                                                                variant="text"
                                                                startIcon={<Icon path={mdiPlus} />}
                                                                disableRipple
                                                            >
                                                                {t(
                                                                    "class.endOfTopicTest.add.questionAdd",
                                                                )}
                                                            </Button>
                                                            <StyledTotalMaxMarks>
                                                                <span>
                                                                    {t(
                                                                        "class.assessment.add.totalMaxMarks",
                                                                    )}
                                                                </span>
                                                                <div>
                                                                    {values.customisedAssessments[
                                                                        index
                                                                    ].personalisedAssesmentQuestions.reduce(
                                                                        (curr, prev) => {
                                                                            const maxValue = isNaN(
                                                                                parseInt(
                                                                                    `${prev.maxValue}`,
                                                                                ),
                                                                            )
                                                                                ? 0
                                                                                : parseInt(
                                                                                      `${prev.maxValue}`,
                                                                                  );

                                                                            return curr + maxValue;
                                                                        },
                                                                        0,
                                                                    )}
                                                                </div>
                                                            </StyledTotalMaxMarks>
                                                        </Box>
                                                    </>
                                                )}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <Typography>{t("class.assessment.add.noUnits")}</Typography>
                        )}
                    </>
                )}
            </Box>
            <Divider sx={{ mx: -6 }} />
            <HeadingCounter number={3}>{t("class.assessment.add.gradeBoundaries")}</HeadingCounter>
            {!values.specification && t("class.assessment.add.pleaseSelectSpecification")}
            {values.specification &&
            values.customisedAssessments?.[index]?.tierUnits?.length > 0 ? (
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={4}>
                        <Grid item sm={4}>
                            <Field
                                name={`customisedAssessments.${index}.gradeBoundaryType`}
                                label={t("class.assessment.add.gradeBoundaryType")}
                                component={TextField}
                                select
                            >
                                <MenuItem value={"tracker"}>
                                    {t("class.assessment.add.sameAsTracker")}
                                </MenuItem>
                                <MenuItem value={"factor"}>
                                    {t("class.assessment.add.gradeBoundaryFactor")}
                                </MenuItem>
                                {canAddCustomGradeBoundary && (
                                    <MenuItem value={"custom"}>
                                        {t("class.assessment.add.custom")}
                                    </MenuItem>
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                    {values.customisedAssessments[index].gradeBoundaryType === "factor" && (
                        <Box mt={2}>
                            <Typography component="h3" variant="h5" gutterBottom>
                                {t("class.assessment.add.gradeShift")}
                            </Typography>
                            <Typography gutterBottom>
                                {t("class.assessment.add.gradeShiftHint")}
                            </Typography>
                            <Field
                                name={`customisedAssessments.${index}.gradeBoundaryFactor`}
                                label=""
                                component={RadioGroup}
                                row
                            >
                                <Grid container spacing={4}>
                                    <Grid item sm={4}>
                                        <Box>
                                            <FormControlLabel
                                                value={`-5`}
                                                control={<Radio />}
                                                label={t("class.assessment.list.gradeShiftMin5")}
                                            />
                                        </Box>
                                        <Box>
                                            <FormControlLabel
                                                value={`-10`}
                                                control={<Radio />}
                                                label={t("class.assessment.list.gradeShiftMin10")}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Box>
                                            <FormControlLabel
                                                value={`0`}
                                                control={<Radio />}
                                                label={t("class.assessment.list.gradeShift0")}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Box>
                                            <FormControlLabel
                                                value={`5`}
                                                control={<Radio />}
                                                label={t("class.assessment.list.gradeShift5")}
                                            />
                                        </Box>
                                        <Box>
                                            <FormControlLabel
                                                value={`10`}
                                                control={<Radio />}
                                                label={t("class.assessment.list.gradeShift10")}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Field>
                        </Box>
                    )}
                    {values.customisedAssessments[index].gradeBoundaryType === "custom" && (
                        <>
                            <Box sx={{ mt: 3, mb: 3, pr: "25%" }}>
                                <Typography gutterBottom>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t("class.assessment.add.customHint1"),
                                        }}
                                    ></div>
                                </Typography>
                                <Typography gutterBottom>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t("class.assessment.add.customHint2"),
                                        }}
                                    ></div>
                                </Typography>
                                <Typography gutterBottom>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t("class.assessment.add.customHint3"),
                                        }}
                                    ></div>
                                </Typography>
                                <Typography gutterBottom>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t("class.assessment.add.customHint4"),
                                        }}
                                    ></div>
                                </Typography>
                            </Box>
                            <Box>
                                {values.customisedAssessments[index].gradeBoundaryValues?.map(
                                    (gB, i) => (
                                        <Grid container key={gB.id} spacing={4}>
                                            <Grid item sm={2}>
                                                <Field
                                                    name={`customisedAssessments.${index}.gradeBoundaryValues[${i}].name`}
                                                    label={t("class.assessment.add.name")}
                                                    disabled={true}
                                                    component={TextField}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Field
                                                    name={`customisedAssessments.${index}.gradeBoundaryValues[${i}].requireMark`}
                                                    label={t("class.assessment.add.requireMark")}
                                                    type="number"
                                                    component={TextField}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            {classTrackerGroupId &&
                                            !!values.customisedAssessments[index]?.tierUnits.find(
                                                tu => tu.unit !== null,
                                            ) ? (
                                                <>
                                                    <Grid item sm={2}>
                                                        <StyledConvertsLabel>
                                                            <Typography whiteSpace="nowrap">
                                                                {t(
                                                                    "class.assessment.add.convertsTo",
                                                                )}
                                                            </Typography>
                                                            <Icon path={mdiArrowRight} />
                                                        </StyledConvertsLabel>
                                                    </Grid>
                                                    {gradeBoundaries?.map(gb => {
                                                        return (
                                                            <>
                                                                <StyledGrid key={gb.id} item sm={2}>
                                                                    <Box className="highlight">
                                                                        <MuiTextField
                                                                            value={gb.values[i]}
                                                                            disabled={true}
                                                                            label={
                                                                                gb.tier !== "-"
                                                                                    ? t(
                                                                                          "common.tierName" +
                                                                                              gb.tier,
                                                                                      )
                                                                                    : ""
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </StyledGrid>
                                                            </>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    ),
                                )}
                            </Box>
                        </>
                    )}
                </Box>
            ) : (
                <Typography>{t("class.assessment.add.noUnits")}</Typography>
            )}
            {values.customisedAssessments?.[index]?.tierUnits?.length > 0 ? (
                <>
                    <Divider sx={{ mx: -6 }} />
                    <HeadingCounter number={4}>
                        {t("class.assessment.add.targetClasses")}
                    </HeadingCounter>
                    {!values.specification || values.yearGroup === null ? (
                        t("class.assessment.add.pleaseSelectYg")
                    ) : (
                        <></>
                    )}
                    {values.specification &&
                    values.yearGroup !== null &&
                    values.customisedAssessments[index]?.visibleClassTrackers.length > 0 ? (
                        <>
                            <Typography gutterBottom>
                                {t("class.assessment.add.chooseClassHint")}
                            </Typography>
                            <Box p={4} height="100%" bgcolor="#f9f9f9" width={500}>
                                <Box mb={2}>
                                    <strong>
                                        {t("common.yearGroup") +
                                            " " +
                                            (values.yearGroup === 0
                                                ? t("common.yg0")
                                                : values.yearGroup)}
                                    </strong>
                                </Box>
                                <Field
                                    name={`customisedAssessments.${index}.hideForNow`}
                                    component={CheckboxField}
                                    label={t("class.assessment.add.hideForNow")}
                                    disabled={values.customisedAssessments[
                                        index
                                    ].visibleClassTrackers.find(vct => vct.visible)}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isMultiCheckboxChecked}
                                            name=""
                                            onChange={() => {
                                                setFieldValue(
                                                    `customisedAssessments.${index}.visibleClassTrackers`,
                                                    values.customisedAssessments[
                                                        index
                                                    ].visibleClassTrackers.map(vct => ({
                                                        ...vct,
                                                        visible: !isMultiCheckboxChecked,
                                                    })),
                                                );
                                            }}
                                        />
                                    }
                                    label={t("class.assessment.add.selectDeselectAll")}
                                />
                                {values.customisedAssessments[index]?.visibleClassTrackers.map(
                                    (visibleClassTracker, vctI) => (
                                        <Box key={visibleClassTracker.id}>
                                            <Field
                                                name={`customisedAssessments.${index}.visibleClassTrackers.${vctI}.visible`}
                                                component={CheckboxField}
                                                label={visibleClassTracker.name}
                                            />
                                        </Box>
                                    ),
                                )}
                            </Box>
                        </>
                    ) : (
                        t("class.assessment.add.noClasses")
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default AddCustomisedAssessmentForm;
