import { Grid, MenuItem } from "@mui/material";
import { Field } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import nullableField from "src/forms/nullableField";
import { TextField } from "formik-mui";
import { ListObject } from "src/forms/types";

const NullableTextField = nullableField(TextField);

interface OwnProps {
    subjectArea: ListObject<number> | null;
    qualification: ListObject<number> | null;
    specification: ListObject<number> | null;
}

const StaticSpecificationSelector: FC<OwnProps> = ({
    subjectArea,
    qualification,
    specification,
}) => {
    const { t } = useTranslation();
    return (
        <Grid container spacing={4}>
            <Grid item sm={4}>
                {subjectArea && (
                    <Field
                        name="subjectArea.id"
                        select
                        disabled={true}
                        label={t("common.subjectArea")}
                        component={NullableTextField}
                    >
                        <MenuItem value={subjectArea.id}>{subjectArea.name}</MenuItem>
                    </Field>
                )}
            </Grid>
            <Grid item sm={4}>
                {qualification && (
                    <Field
                        name="qualification.id"
                        select
                        disabled={true}
                        label={t("common.qualification")}
                        component={NullableTextField}
                    >
                        <MenuItem value={qualification.id}>{qualification.name}</MenuItem>
                    </Field>
                )}
            </Grid>
            <Grid item sm={4}>
                {specification && (
                    <Field
                        name="specification.id"
                        select
                        disabled={true}
                        label={t("common.specification")}
                        component={NullableTextField}
                    >
                        <MenuItem value={specification.id}>{specification.name}</MenuItem>
                    </Field>
                )}
            </Grid>
        </Grid>
    );
};

export default StaticSpecificationSelector;
