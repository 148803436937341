import { Box, Chip, MenuItem, TextField, SelectProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "src/styles/theme";
import Icon from "src/components/Icon";
import { mdiClose } from "@mdi/js";
import COLORS from "src/styles/colors";
import { useState } from "react";
import { IdParam, ListObject } from "src/forms/types";
import { useFormContext, Controller } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) => ({
    chipsList: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(1.5, 1.5, 0, 0),
        },
    },
}));

export interface MultiSelectProps extends Omit<SelectProps, "value"> {
    options: ListObject<number>[];
    hideSelect?: boolean;
    disableDelete?: boolean;
}

const RHFMultipleSelect = ({
    placeholder,
    options,
    disabled,
    name,
    ...props
}: MultiSelectProps) => {
    const { control, setValue, getValues } = useFormContext();
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const availableOptions = () =>
        (options as any)
            .filter(option => !getValues(name).some(val => val.id === option.id))
            .map(option => {
                if (option.rgt - option.lft > 1) option.disabled = true;
                return option;
            });

    const availableNotDisabledOptions = () => {
        return availableOptions().filter(ao => ao.disabled === undefined || ao.disabled !== true);
    };

    const handleOpen = () => availableNotDisabledOptions().length > 0 && setOpen(true);

    const handleClose = () => setOpen(false);

    const fieldDisabled = isSubmitting =>
        disabled !== undefined
            ? disabled
            : availableNotDisabledOptions().length === 0 || isSubmitting;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error }, formState: { isSubmitting } }) => {
                const handleChange = (
                    event: React.ChangeEvent<HTMLInputElement & { value: IdParam[] }>,
                ) => {
                    setValue(
                        name,
                        event.target.value.map(id => {
                            if (typeof id === "number") return { id };
                            return id;
                        }),
                    );
                    setOpen(false);
                };

                const handleRemove = id =>
                    setValue(
                        name,
                        field.value.filter(val => val.id !== id),
                    );
                return (
                    <>
                        {!props.hideSelect && (
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            <TextField
                                name={name}
                                disabled={fieldDisabled(isSubmitting)}
                                helperText={error?.message}
                                // placeholder={placeholder}
                                // label={label}
                                select
                                SelectProps={{
                                    multiple: true,
                                    displayEmpty: true,
                                    onOpen: handleOpen,
                                    onClose: handleClose,
                                    open: open,
                                    onChange: handleChange,
                                    renderValue: () => placeholder,
                                }}
                                {...field}
                                value={field.value || ""}
                                {...props}
                            >
                                {availableOptions().map((obj: any) => (
                                    <MenuItem
                                        disabled={obj.disabled && obj.disabled === true}
                                        key={obj.id}
                                        value={obj.id}
                                    >
                                        {obj.lvl && obj.lvl > 0 ? "―" : ""}
                                        {obj.displayName ? obj.displayName : obj.name || obj.id}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}

                        {field.value && field.value.length > 0 && (
                            <Box mt={1.5} className={classes.chipsList}>
                                {field.value.map(val => {
                                    const option = options.find(
                                        o =>
                                            parseInt(o.id as unknown as string) ===
                                            parseInt(val.id),
                                    );
                                    if (option) {
                                        return (
                                            <Chip
                                                style={{
                                                    background:
                                                        (options as any).find(
                                                            o =>
                                                                parseInt(o.id) === parseInt(val.id),
                                                        ).colour || COLORS.BLUE_3,
                                                    color: COLORS.WHITE,
                                                }}
                                                key={`selectedCat-${val.id}`}
                                                label={
                                                    options.find(option => option.id === val.id)
                                                        .name
                                                }
                                                onDelete={
                                                    !props.disableDelete
                                                        ? () => handleRemove(val.id)
                                                        : undefined
                                                }
                                                deleteIcon={
                                                    !props.disableDelete ? (
                                                        <Box
                                                            component="span"
                                                            color={theme.palette.getContrastText(
                                                                (options as any).find(
                                                                    o =>
                                                                        parseInt(o.id) ===
                                                                        parseInt(val.id),
                                                                ).colour || COLORS.GREY_2,
                                                            )}
                                                        >
                                                            <Icon path={mdiClose} />
                                                        </Box>
                                                    ) : undefined
                                                }
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </Box>
                        )}
                    </>
                );
            }}
        />
    );
};

export default RHFMultipleSelect;
