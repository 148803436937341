import AppContainer from "src/components/AppContainer";
import DotsProgress from "src/components/DotsProgress";
import PaperInner from "src/components/PaperInner";
import FormikRef from "src/forms/FormikRef";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import BulkEditClassForm, {
    BulkEditClassFormFields,
} from "../components/ClassTracking/forms/BulkEditClassForm";
import { Box, Button, Paper, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { createRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router";
import { Dispatch } from "redux";
import { ApiData, ApiStatus } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { useMount } from "src/hooks/useMount";
import { useResponse } from "src/hooks/useResponse";
import { ClassTrackerRelatedObject } from "src/modules/tracker/dto/TrackerRelated";
import { classTrackerDetailsSelector } from "src/modules/tracker/selectors/ClassTrackerSelectors";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import { ROUTE_CLASS_CLASS_LIST, ROUTE_CLASS_CLASS_LIST_COHORT } from "src/routes";
import { FormError, getErrorMessage } from "src/services/error";
import { AppState } from "src/store/reducers";
import { BulkEditClassesRequest } from "../api/Classes/bulkEditClass";
import { bulkEditClassSchema } from "../components/ClassTracking/forms/addClassSchema";
import { ClassActions } from "../store/actions";
import { useTrackerPath } from "../hooks/useTrackerPath";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const dispatchActions = (dispatch: Dispatch) => ({
    getClassTrackerGroupRelatedData: (classTrackerGroup: number) => {
        dispatch(TrackerActions.getClassTrackerGroupRelated(classTrackerGroup));
    },
    getClassDetails: (classTrackerId: number) => {
        dispatch(ClassActions.getClassDetails(classTrackerId));
    },
    bulkEditClasses: (classTrackerGroupId: number, values: BulkEditClassesRequest) => {
        dispatch(ClassActions.bulkEditClasses(classTrackerGroupId, values));
    },
});

const convertFormDataToRequest = (values: BulkEditClassFormFields): BulkEditClassesRequest => ({
    classTrackers: values.classTrackers.map(ct => ({ id: ct.id })),
    attributes: values.attributes
        .filter(attr => {
            return (
                (attr.isSelected === true || attr.isMandatory === true) &&
                attr.attributeItemId &&
                !values.disabledItems?.includes(attr.attributeItemId)
            );
        })
        .map(attr => ({
            id: attr.classTrackerAttributeValueId || undefined,
            customName: attr.customName || null,
            attributeValue: { id: attr.id },
        })),
});

const BulkEditClassesContainer = () => {
    const form = createRef() as any;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { classTrackerGroup, specification } = useParams() as any;
    const location = useLocation();
    const { navigate } = useSchoolNavigate();
    const searchParams = new URLSearchParams(location.search);
    const sSubject = searchParams.get("fSubjectArea");
    const sQual = searchParams.get("fQualification");
    const sYearGroup = searchParams.get("fYearGroup");
    const sCohort = searchParams.get("fCohort");
    const firstClassId = searchParams.get("fFirstClassId");
    const { getClassDetails, bulkEditClasses, getClassTrackerGroupRelatedData } =
        dispatchActions(dispatch);
    const {
        apiBulkEdit,
        classTrackerRelatedData,
        classTracker,
    }: {
        apiBulkEdit: ApiData;
        classTrackerRelatedData: ClassTrackerRelatedObject | null;
        classTracker: OrmClassTracker;
    } = useSelector(
        (state: AppState) => ({
            apiBulkEdit: state.api.class.bulkEditClasses,
            classTrackerRelatedData: state.tracker.relatedData,
            classTracker: classTrackerDetailsSelector(
                state,
                firstClassId ? parseInt(firstClassId) : 0,
            ),
        }),
        shallowEqual,
    );

    const { data: trackerPath, refetch } = useTrackerPath(specification);

    const initialValues: BulkEditClassFormFields = {
        attributes: [],
        items: [],
        groups: [],
        disabledItems: [],
        classTrackers: classTrackerRelatedData?.classTrackers || [],
        initialEditAttributes: classTracker.attributes || [],
    };

    const getTrackerPathParam = name => {
        if (trackerPath) {
            const filtered = trackerPath.filter(tp => tp.type === name);
            if (filtered.length > 1) {
                let nameWithParent = "";
                filtered.forEach((f: ListObject<number>, i) => {
                    nameWithParent = nameWithParent + (i > 0 ? " - " : "") + f.name;
                });
                return { ...filtered[filtered.length - 1], name: nameWithParent };
            }

            return trackerPath?.find(tp => tp.type === name) || null;
        }
        return null;
    };

    const selectedSubjectArea = getTrackerPathParam("SubjectArea");
    const selectedQualification = getTrackerPathParam("Qualification");
    const selectedSpecification = getTrackerPathParam("Specification");

    const handleSubmit = (values: BulkEditClassFormFields) => {
        bulkEditClassSchema(t)
            .isValid(values)
            .then(isValid => {
                if (isValid) {
                    const convertedValues = convertFormDataToRequest(values);
                    bulkEditClasses(classTrackerGroup, convertedValues);
                } else {
                    enqueueSnackbar(t("common.validationFailed"), {
                        ...SnackbarErrorOptions,
                    });
                }
            });
    };

    useMount(() => {
        if (specification) refetch();
        if (classTrackerGroup) getClassTrackerGroupRelatedData(parseInt(`${classTrackerGroup}`));
        if (firstClassId) getClassDetails(parseInt(`${firstClassId}`));
    });

    const handleErrorResponse = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    if (error.formError) currentForm.setErrors(error.formError);
                    break;
                }
            }
        },
        [enqueueSnackbar, form],
    );

    useResponse(() => {
        if (apiBulkEdit && apiBulkEdit.status === ApiStatus.SUCCESS) {
            setTimeout(() => {
                if (sCohort) {
                    navigate(
                        ROUTE_CLASS_CLASS_LIST_COHORT.replace(":cohort", sCohort || "") +
                            `?subjectArea=${sSubject}&qualification=${sQual}&yearGroup=${sYearGroup}`,
                    );
                } else {
                    navigate(ROUTE_CLASS_CLASS_LIST);
                }
            }, 5000);
        }
        if (apiBulkEdit.status === ApiStatus.ERROR) {
            handleErrorResponse(apiBulkEdit);
        }
    }, apiBulkEdit);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.bulkEditClass.header")}
            </Typography>
            <Paper>
                {classTracker.isFullyloaded &&
                trackerPath &&
                classTrackerRelatedData &&
                classTrackerRelatedData.classTrackers ? (
                    <FormikRef
                        ref={form}
                        initialValues={initialValues}
                        validationSchema={() => bulkEditClassSchema(t)}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {(formProps: FormikProps<BulkEditClassFormFields>) => (
                            <Box mt={4}>
                                <BulkEditClassForm
                                    selectedSubjectArea={selectedSubjectArea}
                                    selectedQualification={selectedQualification}
                                    selectedSpecification={selectedSpecification}
                                    classTrackers={classTrackerRelatedData.classTrackers}
                                    formProps={formProps}
                                />
                                <PaperInner variant="paddingTopNone">
                                    <Button
                                        disabled={formProps.isSubmitting}
                                        onClick={formProps.submitForm}
                                        color="primary"
                                    >
                                        {t("class.editClass.editClassButton")}
                                    </Button>
                                    <GenericErrorMessage
                                        errors={formProps.errors}
                                        submitCount={formProps.submitCount}
                                    />
                                </PaperInner>
                            </Box>
                        )}
                    </FormikRef>
                ) : (
                    <PaperInner>
                        <DotsProgress />
                    </PaperInner>
                )}
            </Paper>
        </AppContainer>
    );
};

export default BulkEditClassesContainer;
