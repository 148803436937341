import { Typography, Paper, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import SummaryBox from "./SummaryBox";
import COLORS from "src/styles/colors";
import PaperInner from "src/components/PaperInner";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";

const ConfirmationBox = ({ values, additionalMessage, isWholeSchool = false }) => {
    const { t } = useTranslation();
    const { data: accountInfo } = useAccountInfo();

    return (
        <Paper>
            <PaperInner variant="subscription" border="bottom" color="lightGrey">
                <Typography variant="h5" component="h2" gutterBottom>
                    {t("subscription.confirmed.header")}
                </Typography>
                <SummaryBox
                    {...values}
                    oneline={true}
                    isWholeSchool={isWholeSchool}
                    period={
                        !values.isAnnualy
                            ? `P${values.period || values.length}M`
                            : `P${values.period || values.length}Y`
                    }
                />
            </PaperInner>
            <PaperInner>
                <Grid container>
                    <Grid item sm={6}>
                        <Typography gutterBottom variant="h4" component="h3">
                            {t("subscription.confirmed.whatHappensHeader")}
                        </Typography>
                        <Typography gutterBottom>
                            {accountInfo?.hasFinanceManager
                                ? t("subscription.confirmed.whatHappensFMMessage")
                                : t("subscription.confirmed.whatHappensMessage")}
                        </Typography>
                        <Typography>{t("subscription.confirmed.whatHappensThanks")}</Typography>
                    </Grid>
                    {additionalMessage && (
                        <>
                            <Grid item sm={1} />
                            <Grid item sm={5}>
                                <Box
                                    px={4}
                                    py={3}
                                    fontStyle="italic"
                                    lineHeight={1.71}
                                    bgcolor={COLORS.VERY_LIGHT_GREY_1}
                                    borderRadius={2}
                                >
                                    {additionalMessage}
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </PaperInner>
        </Paper>
    );
};

export default ConfirmationBox;
