import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getStudentReportBySubjectEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/student-report/{cohort}/{yearGroup}/by-subject/`,
    method: ApiRequests.POST,
};

export interface StudentReportBySubjectRequest {
    subjectArea: { id: number } | null;
    qualification: { id: number } | null;
    specification: { id: number } | null;
    classTracker: { id: number } | null;
    analysisStudents: { id: number }[] | null;
    schoolSnapshot?: { id: number } | null;
}
