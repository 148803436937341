import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction, paramRequest } from "src/api/helpers";
import { StudentFilterRequest } from "../../hooks/GradeSumary/useAnalysisGradeSummaryValues";
import { analysisKS4ClearForecastEndpoint } from "../../api/KS4Grade/clearForecast";
import { getAnalysisKS4ForecastValuesEndpoint } from "../../api/KS4Grade/getForecast";
import {
    AnalysisKS4GradeHeaderObject,
    getAnalysisKS4GradeHeaderEndpoint,
} from "../../api/KS4Grade/getHeader";
import {
    getAnalysisKS4GradeSnapshotCompareEndpoint,
    KS4GradeSnapshotCompareResponse,
} from "../../api/KS4Grade/getSnapshotCompare";
import { getAnalysisKs4GradeSnapshotValuesEndpoint } from "../../api/KS4Grade/getSnapshotValues";
import {
    AnalysisKS4GradeValuesObject,
    getAnalysisKS4GradeValuesEndpoint,
    KS4ViewGrades,
    KS4ViewTypes,
} from "../../api/KS4Grade/getValues";
import { analysisKS4LoadForecastEndpoint } from "../../api/KS4Grade/loadForecast";

export enum AnalysisKS4GradeActionsTypes {
    GET_ANALYSIS_KS4_GRADE_HEADER = "[analysis] GET ANALYSIS KS4 GRADE HEADER",
    GET_ANALYSIS_KS4_GRADE_HEADER_SUCCESS = "[analysis] GET ANALYSIS KS4 GRADE HEADER SUCCESS",
    SET_ANALYSIS_KS4_GRADE_HEADER = "[analysis] SET ANALYSIS KS4 GRADE HEADER",
    GET_ANALYSIS_KS4_GRADE_VALUES = "[analysis] GET ANALYSIS KS4 GRADE VALUES",
    GET_ANALYSIS_KS4_GRADE_VALUES_SUCCESS = "[analysis] GET ANALYSIS KS4 GRADE VALUES SUCCESS",
    SET_ANALYSIS_KS4_GRADE_VALUES = "[analysis] SET ANALYSIS KS4 GRADE VALUES",
    GET_ANALYSIS_KS4_GRADE_FORECAST_VALUES = "[analysis] GET ANALYSIS KS4 GRADE FORECAST VALUES",
    GET_ANALYSIS_KS4_GRADE_FORECAST_VALUES_SUCCESS = "[analysis] GET ANALYSIS KS4 GRADE FORECAST VALUES SUCCESS",
    LOAD_ANALYSIS_KS4_GRADE_FORECAST = "[analysis] LOAD ANALYSIS KS4 GRADE FORECAST",
    LOAD_ANALYSIS_KS4_GRADE_FORECAST_SUCCESS = "[analysis] LOAD ANALYSIS KS4 GRADE FORECAST SUCCESS",
    CLEAR_ANALYSIS_KS4_GRADE_FORECAST = "[analysis] CLEAR ANALYSIS KS4 GRADE FORECAST",
    CLEAR_ANALYSIS_KS4_GRADE_FORECAST_SUCCESS = "[analysis] CLEAR ANALYSIS KS4 GRADE FORECAST SUCCESS",
    GET_ANALYSIS_KS4_GRADE_SNAPSHOT = "[analysis] GET ANALYSIS KS4 GRADE SNAPSHOT ",
    GET_ANALYSIS_KS4_GRADE_SNAPSHOT_SUCCESS = "[analysis] GET ANALYSIS KS4 GRADE SNAPSHOT SUCCESS",
    GET_ANALYSIS_KS4_GRADE_SNAPSHOT_COMPARE = "[analysis] GET ANALYSIS KS4 GRADE SNAPSHOT COMPARE",
    GET_ANALYSIS_KS4_GRADE_SNAPSHOT_COMPARE_SUCCESS = "[analysis] GET ANALYSIS KS4 GRADE SNAPSHOT COMPARE SUCCESS",
    SET_ANALYSIS_KS4_GRADE_COMPARE = "[analysis] SET ANALYSIS KS4 GRADE COMPARE",
}

export const AnalysisKS4GradeApiEndpoints = {
    getAnalysisKS4GradeHeader: createActionObject(
        getAnalysisKS4GradeHeaderEndpoint,
        AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_HEADER_SUCCESS,
    ),
    getAnalysisKS4GradeValues: createActionObject(
        getAnalysisKS4GradeValuesEndpoint,
        AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_VALUES_SUCCESS,
    ),
    getAnalysisKS4ForecastValues: createActionObject(
        getAnalysisKS4ForecastValuesEndpoint,
        AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_FORECAST_VALUES_SUCCESS,
    ),
    loadAnalysisKs4GradeForecast: createActionObject(
        analysisKS4LoadForecastEndpoint,
        AnalysisKS4GradeActionsTypes.LOAD_ANALYSIS_KS4_GRADE_FORECAST_SUCCESS,
    ),
    clearAnalysisKs4GradeForecast: createActionObject(
        analysisKS4ClearForecastEndpoint,
        AnalysisKS4GradeActionsTypes.CLEAR_ANALYSIS_KS4_GRADE_FORECAST_SUCCESS,
    ),
    getAnalysisKS4GradeSnapshot: createActionObject(
        getAnalysisKs4GradeSnapshotValuesEndpoint,
        AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_SNAPSHOT_SUCCESS,
    ),
    getAnalysisKS4GradeSnapshotCompare: createActionObject(
        getAnalysisKS4GradeSnapshotCompareEndpoint,
        AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_SNAPSHOT_COMPARE_SUCCESS,
    ),
};

export const AnalysisKS4GradeRawActions = (
    AnalysisKS4GradeEndpoints: Record<Keys<typeof AnalysisKS4GradeApiEndpoints>, ApiActionMeta>,
) => ({
    getAnalysisKS4GradeHeader: (cohort: number, yearGroup: number) =>
        createApiAction(
            AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_HEADER,
            paramRequest({ cohort, yearGroup }),
            AnalysisKS4GradeEndpoints.getAnalysisKS4GradeHeader,
        ),
    getAnalysisKS4GradeHeaderSuccess: (payload: ApiCallbackPayload<AnalysisKS4GradeHeaderObject>) =>
        createAction(AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_HEADER_SUCCESS, payload),
    setAnalysisKS4GradeHeader: (payload: AnalysisKS4GradeHeaderObject) =>
        createAction(AnalysisKS4GradeActionsTypes.SET_ANALYSIS_KS4_GRADE_HEADER, payload),
    getAnalysisKS4GradeValues: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        studentFilters: StudentFilterRequest,
    ) =>
        createApiAction(
            AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_VALUES,
            { values: studentFilters, params: { cohort, yearGroup, viewGrade, viewType } },
            AnalysisKS4GradeEndpoints.getAnalysisKS4GradeValues,
        ),
    getAnalysisKS4GradeValuesSuccess: (payload: ApiCallbackPayload<AnalysisKS4GradeValuesObject>) =>
        createAction(AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_VALUES_SUCCESS, payload),
    setAnalysisKS4GradeValues: (payload: AnalysisKS4GradeValuesObject) =>
        createAction(AnalysisKS4GradeActionsTypes.SET_ANALYSIS_KS4_GRADE_VALUES, payload),
    getAnalysisKS4ForecastValues: (
        cohort: number,
        yearGroup: number,
        viewType: KS4ViewTypes,
        studentFilters: StudentFilterRequest,
    ) =>
        createApiAction(
            AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_FORECAST_VALUES,
            { values: studentFilters, params: { cohort, yearGroup, viewType } },
            AnalysisKS4GradeEndpoints.getAnalysisKS4ForecastValues,
        ),
    getAnalysisKS4ForecastValuesSuccess: (
        payload: ApiCallbackPayload<AnalysisKS4GradeValuesObject>,
    ) =>
        createAction(
            AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_FORECAST_VALUES_SUCCESS,
            payload,
        ),
    loadAnalysisKS4GradeForecast: (
        cohort: number,
        yearGroup: number,
        source: string,
        viewGrade: string,
        viewType: KS4ViewTypes,
    ) =>
        createApiAction(
            AnalysisKS4GradeActionsTypes.LOAD_ANALYSIS_KS4_GRADE_FORECAST,
            { values: {}, params: { cohort, yearGroup, source, viewGrade, viewType } },
            AnalysisKS4GradeEndpoints.loadAnalysisKs4GradeForecast,
        ),
    loadAnalysisKS4GradeForecastSuccess: (
        payload: ApiCallbackPayload<AnalysisKS4GradeValuesObject>,
    ) =>
        createAction(
            AnalysisKS4GradeActionsTypes.LOAD_ANALYSIS_KS4_GRADE_FORECAST_SUCCESS,
            payload,
        ),
    clearAnalysisKS4GradeForecast: (cohort: number, yearGroup: number, viewType: KS4ViewTypes) =>
        createApiAction(
            AnalysisKS4GradeActionsTypes.CLEAR_ANALYSIS_KS4_GRADE_FORECAST,
            { values: {}, params: { cohort, yearGroup, viewType } },
            AnalysisKS4GradeEndpoints.clearAnalysisKs4GradeForecast,
        ),
    clearAnalysisKS4GradeForecastSuccess: (
        payload: ApiCallbackPayload<AnalysisKS4GradeValuesObject>,
    ) =>
        createAction(
            AnalysisKS4GradeActionsTypes.CLEAR_ANALYSIS_KS4_GRADE_FORECAST_SUCCESS,
            payload,
        ),
    getAnalysisKS4GradeSnapshot: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        snapshot: number,
        studentFilters: StudentFilterRequest,
    ) =>
        createApiAction(
            AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_SNAPSHOT,
            {
                values: studentFilters,
                params: { cohort, yearGroup, viewGrade, snapshot, viewType },
            },
            AnalysisKS4GradeEndpoints.getAnalysisKS4GradeSnapshot,
        ),
    getAnalysisKS4GradeSnapshotSuccess: (
        payload: ApiCallbackPayload<AnalysisKS4GradeValuesObject>,
    ) =>
        createAction(AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_SNAPSHOT_SUCCESS, payload),
    getAnalysisKS4GradeSnapshotCompare: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        dataSource1: string,
        dataSource2: string,
        studentFilters: StudentFilterRequest,
    ) =>
        createApiAction(
            AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_SNAPSHOT_COMPARE,
            {
                values: studentFilters,
                params: { cohort, yearGroup, viewGrade, viewType, dataSource1, dataSource2 },
            },
            AnalysisKS4GradeEndpoints.getAnalysisKS4GradeSnapshotCompare,
        ),
    getAnalysisKS4GradeSnapshotCompareSuccess: (
        payload: ApiCallbackPayload<KS4GradeSnapshotCompareResponse>,
    ) =>
        createAction(
            AnalysisKS4GradeActionsTypes.GET_ANALYSIS_KS4_GRADE_SNAPSHOT_COMPARE_SUCCESS,
            payload,
        ),
    setAnalysisKS4GradeCompare: (payload: KS4GradeSnapshotCompareResponse) =>
        createAction(AnalysisKS4GradeActionsTypes.SET_ANALYSIS_KS4_GRADE_COMPARE, payload),
});
