import { ActionsUnion, Keys } from "@martin_hotell/rex-tils";
import { processEndpoints } from "src/api/helpers";
import { Modules } from "src/types";
import {
    AnalysisAtlActionsTypes,
    AnalysisAtlApiEndpoints,
    AnalysisAtlRawActions,
} from "./Atl/actions";
import {
    AnalysisCommonActionsTypes,
    AnalysisCommonApiEndpoints,
    AnalysisCommonRawActions,
} from "./Common/actions";
import {
    AnalysisKS4GradeActionsTypes,
    AnalysisKS4GradeApiEndpoints,
    AnalysisKS4GradeRawActions,
} from "./KS4Grade/actions";

export const AnalysisActionTypes = {
    ...AnalysisCommonActionsTypes,
    ...AnalysisKS4GradeActionsTypes,
    ...AnalysisAtlActionsTypes,
};

const _endpoints = {
    ...AnalysisCommonApiEndpoints,
    ...AnalysisKS4GradeApiEndpoints,
    ...AnalysisAtlApiEndpoints,
};

export type AnalysisEndpointKeys = Keys<typeof _endpoints>;

export const AnalysisEndpoints = processEndpoints<AnalysisEndpointKeys>(
    Modules.ANALYSIS,
    _endpoints,
);

export const AnalysisActions = {
    ...AnalysisCommonRawActions(AnalysisEndpoints),
    ...AnalysisKS4GradeRawActions(AnalysisEndpoints),
    ...AnalysisAtlRawActions(AnalysisEndpoints),
};

export type AnalysisActions = ActionsUnion<typeof AnalysisActions>;
