import CustomTable from "src/components/CustomTable";
import PromptDialog from "src/forms/PromptDialog";
import COLORS from "src/styles/colors";
import AppContainer from "src/components/AppContainer";
import Icon from "src/components/Icon";
import {
    Box,
    Button,
    MenuItem,
    Paper,
    TextField,
    Grid,
    Theme,
    Typography,
    CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { format, fromUnixTime } from "date-fns";
import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellDataGetterParams } from "src/components/CustomTable/types";
import { filterNotNullable } from "src/services/object";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { theme } from "src/styles/theme";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { mdiSquareEditOutline } from "@mdi/js";
import { ROUTE_REPORT_SNAPSHOT_LIST } from "src/routes";
import { SchoolUserRole } from "src/orm/models/User";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { CohortSnapshotModel } from "../models/CohortSnapshotModel";
import { useCohortSnapshotList } from "../hooks/query/CohortSnapshot/useCohortSnapshotList";
import { useCreateSnapshotMutation } from "../hooks/query/CohortSnapshot/useCreateMutation";
import { useRemoveSnapshotMutation } from "../hooks/query/CohortSnapshot/useRemoveMutation";
import { useRenameSnapshotMutation } from "../hooks/query/CohortSnapshot/useRenameMutation";
import { useProfile } from "src/modules/user/hooks/useProfile";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const useStyles = makeStyles((theme: Theme) => ({
    hint: {
        padding: theme.spacing(3, 6, 3, 3),
        backgroundColor: COLORS.VERY_LIGHT_GREY_5,
        fontStyle: "italic",
    },
}));

const CohortSnapshotListContainer = () => {
    const { t }: { t: TFunction } = useTranslation();
    const { navigate } = useSchoolNavigate();
    const classes = useStyles();
    const [selectedCohort, setSelectedCohort] = useState<number | null>(null);
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [renameCreateDialogOpened, setRenameCreateDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);
    const [renameCreateId, setRenameCreateId] = useState<number | null>(null);
    const [tmpName, setTmpName] = useState<string>("");
    const [createMode, setCreateMode] = useState<boolean | null>(null);

    const { data: authUser } = useProfile();
    const canCreateSnapshot = !!(
        authUser?.userRole === SchoolUserRole.SCHOOL_ADMIN ||
        authUser?.userRole === SchoolUserRole.OWNER ||
        authUser?.isLeaderOfAll ||
        authUser?.leaderOfSubjectAreas?.length > 0
    );
    const { data: accountInfo } = useAccountInfo();
    const activeCohortId = accountInfo?.activeCohortId || null;

    const { data } = useCoreValues();
    const cohorts = data?.cohorts;

    const {
        data: cohortSnapshots,
        refetch: fetchCohortSnapshots,
        isSuccess,
        isLoading,
    } = useCohortSnapshotList(selectedCohort);

    const { mutate: createNewSnapshot } = useCreateSnapshotMutation(
        selectedCohort,
        fetchCohortSnapshots,
    );

    const { mutate: removeSnapshot } = useRemoveSnapshotMutation(fetchCohortSnapshots);

    const { mutate: renameSnapshot } = useRenameSnapshotMutation(
        selectedCohort,
        fetchCohortSnapshots,
    );

    const handleRemoveDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null && selectedCohort) {
            removeSnapshot({ id: removeId });
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    const handleRenameCreateDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && renameCreateId !== null && tmpName) {
            renameSnapshot({ name: tmpName });
        }
        if (confirmed && createMode && tmpName && selectedCohort) {
            createNewSnapshot({ name: tmpName });
        }
        setRenameCreateDialogOpened(false);
        setRenameCreateId(null);
        setTmpName("");
        setCreateMode(null);
    };

    const handleRenameDialogOpen = snapshotId => {
        setRenameCreateDialogOpened(true);
        setRenameCreateId(snapshotId);
    };

    const handleCreateDialogOpen = () => {
        setRenameCreateDialogOpened(true);
        setCreateMode(true);
    };

    useEffect(() => {
        if (cohorts.length > 0 && selectedCohort !== activeCohortId) {
            const defaultCohort = cohorts.find(c => c.id === activeCohortId);
            if (defaultCohort) {
                setSelectedCohort(defaultCohort.id);
            } else {
                setSelectedCohort(cohorts[0].id);
            }
        }
    }, [cohorts, activeCohortId, accountInfo]);

    useEffect(() => {
        if (selectedCohort) {
            fetchCohortSnapshots();
        }
    }, [selectedCohort]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("school.snapshots.tab")}
            </Typography>
            <Grid container>
                <Grid item sm={6}>
                    {canCreateSnapshot && (
                        <Button
                            onClick={() => {
                                if (selectedCohort) handleCreateDialogOpen();
                            }}
                            color="primary"
                            id="createNewSnapshotButton"
                        >
                            {t("school.snapshots.createNewSnapshot")}
                        </Button>
                    )}
                    <Box width={200} mt={3} mb={4}>
                        <TextField
                            label={t("school.snapshots.cohortFilterLabel")}
                            select
                            SelectProps={{ displayEmpty: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            margin="none"
                            name="cohort"
                            value={selectedCohort || 0}
                            onChange={e => setSelectedCohort(parseInt(`${e.target.value}`))}
                        >
                            {filterNotNullable(cohorts).map(obj => (
                                <MenuItem key={obj.id} value={obj.id}>
                                    {obj.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box className={classes.hint}>
                        <Typography variant="inherit">{t("school.snapshots.note")}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Paper>
                {isLoading && (
                    <Box p={4} width={"100%"} textAlign={"center"}>
                        <CircularProgress />
                    </Box>
                )}
                {isSuccess && cohortSnapshots.length > 0 ? (
                    <CustomTable
                        data={cohortSnapshots}
                        showPaginator
                        showRowsPerPageOptions={false}
                        columns={[
                            { key: "name", label: t("school.snapshots.name") },
                            {
                                key: "created",
                                label: t("school.snapshots.created"),
                                cellDataGetter: ({
                                    rowData,
                                }: CellDataGetterParams<CohortSnapshotModel>) => {
                                    if (rowData.created) {
                                        return (
                                            <Box fontWeight={theme.typography.fontWeightMedium}>
                                                {format(
                                                    fromUnixTime(rowData.created),
                                                    "dd MMMM yyyy hh:mm:ss",
                                                )}
                                            </Box>
                                        );
                                    }

                                    return "-";
                                },
                            },
                            ...(accountInfo?.hasParentPortalEnabled ||
                            accountInfo?.hasStudentPortalEnabled
                                ? [
                                      {
                                          key: "hasReports",
                                          label: t("school.snapshots.hasReports"),
                                          cellDataGetter: ({
                                              rowData,
                                          }: CellDataGetterParams<CohortSnapshotModel>) => {
                                              if (rowData.hasReports) {
                                                  return t("common.yes");
                                              }
                                              return t("common.no");
                                          },
                                      },
                                  ]
                                : []),
                        ]}
                        actions={[
                            getMenuActionProps("showPublished", {
                                text: t("school.snapshots.showPublishedReports"),
                                icon: <Icon path={mdiSquareEditOutline} />,
                                onAction: (rowData: CohortSnapshotModel) => {
                                    navigate(
                                        ROUTE_REPORT_SNAPSHOT_LIST +
                                            `?snapshotName=${rowData.name
                                                .replace("/", " ")
                                                .replace("-", " ")}`,
                                    );
                                },
                                showAction: (rowData: CohortSnapshotModel) => !!rowData.hasReports,
                            }),
                            getMenuActionProps("rename", {
                                text: t("school.snapshots.rename"),
                                icon: <Icon path={mdiSquareEditOutline} />,
                                onAction: (rowData: CohortSnapshotModel) => {
                                    handleRenameDialogOpen(rowData.id);
                                },
                            }),
                            getMenuActionProps("delete", {
                                onAction: (rowData: CohortSnapshotModel) => {
                                    setRemoveDialogOpened(true);
                                    setRemoveId(rowData.id);
                                },
                                showAction: (rowData: CohortSnapshotModel) =>
                                    rowData.isCreated && !rowData.hasReports,
                            }),
                        ]}
                        actionsText={(rowData: CohortSnapshotModel) => {
                            return !rowData.isCreated ? (
                                <Box
                                    textAlign="right"
                                    fontSize={theme.typography.pxToRem(14)}
                                    fontStyle="italic"
                                    color={COLORS.BLUE_3}
                                    p={1.5}
                                >
                                    {t("school.snapshots.isCreating")}
                                </Box>
                            ) : null;
                        }}
                    />
                ) : isSuccess && !cohortSnapshots.length ? (
                    <Box p={4} width={"100%"}>
                        {t("common.noData")}
                    </Box>
                ) : (
                    <></>
                )}
            </Paper>
            <PromptDeleteConfirm open={removeDialogOpened} onClose={handleRemoveDialogClose}>
                <Typography variant="h3">
                    {t("school.snapshots.deleteSnapshotPromptHeader")}
                </Typography>
                <Box pt={3}>{t("school.snapshots.deleteSnapshotPromptType")}</Box>
            </PromptDeleteConfirm>
            <PromptDialog
                yesLabel={tmpName ? t("common.continue") : ""}
                noLabel={t("common.cancel")}
                open={renameCreateDialogOpened}
                onClose={handleRenameCreateDialogClose}
            >
                {t("school.snapshots.renameSnapshotDialogHeader")}
                <TextField value={tmpName} onChange={e => setTmpName(e.target.value)} />
            </PromptDialog>
        </AppContainer>
    );
};

export default CohortSnapshotListContainer;
