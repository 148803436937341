import { Dispatch } from "redux";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { useMount } from "src/hooks/useMount";
import { ApiData } from "src/api/constants";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { ClassTrackerHeaderObject } from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";

const dispatchActions = (dispatch: Dispatch) => ({
    getClassTrackerGroupHeader: (classTrackerGroupId: number, tier: TiersTypes) => {
        dispatch(TrackerActions.getClassTrackerGroupHeader(classTrackerGroupId, tier));
    },
    getClassTrackerGroupRelated: (classTrackerGroupId: number) => {
        dispatch(TrackerActions.getClassTrackerGroupRelated(classTrackerGroupId));
    },
    clearClassTrackerRelated: () => {
        dispatch(TrackerActions.clearClassTrackerRelated());
    },
});

interface ClassTrackerGroupInitialData {
    classTrackerHeader: ClassTrackerHeaderObject | null;
    classTrackerRelatedData: ClassTrackerRelatedObject | null;
    apiRelatedData: ApiData;
    apiValues: ApiData;
    apiHeader: ApiData;
}

export const useTrackerGroupInitialConfigData = (
    classTrackerGroupId?: number,
    tier?: TiersTypes,
): ClassTrackerGroupInitialData & {
    clearClassTrackerRelated: () => void;
    getClassTrackerGroupHeader: (classTrackerGroupId: number, tier: TiersTypes) => void;
} & {
    getClassTrackerGroupRelated: (classTrackerGroupId: number) => void;
} => {
    const dispatch = useDispatch();

    const { getClassTrackerGroupHeader, getClassTrackerGroupRelated, clearClassTrackerRelated } =
        dispatchActions(dispatch);

    useMount(() => {
        if (classTrackerGroupId && tier) {
            getClassTrackerGroupHeader(classTrackerGroupId, tier);
            getClassTrackerGroupRelated(classTrackerGroupId);
        }
    });

    const {
        classTrackerHeader,
        classTrackerRelatedData,
        apiRelatedData,
        apiValues,
        apiHeader,
    }: ClassTrackerGroupInitialData = useSelector(
        (state: AppState) => ({
            classTrackerHeader: state.tracker.classTrackerHeader,
            classTrackerRelatedData: state.tracker.relatedData,
            apiRelatedData: state.api.tracker.getClassTrackerGroupRelated,
            apiValues: state.api.tracker.getClassTrackerGroupValues,
            apiHeader: state.api.tracker.getClassTrackerGroupHeader,
        }),
        shallowEqual,
    );

    return {
        classTrackerHeader,
        classTrackerRelatedData,
        getClassTrackerGroupHeader,
        getClassTrackerGroupRelated,
        apiRelatedData,
        apiValues,
        apiHeader,
        clearClassTrackerRelated,
    };
};
