import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: 700,
    },
}));

const CompletedStep = () => {
    const { getValues } = useFormContext();
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Typography gutterBottom className={classes.bold}>
                {t("auth.register.completedStep.header")}
            </Typography>
            <Typography gutterBottom>
                {t("auth.register.completedStep.message1")}
                <Typography className={classes.bold} component="span">
                    {getValues("email")}
                </Typography>
            </Typography>
            <Typography>{t("auth.register.completedStep.message2")}</Typography>
        </Fragment>
    );
};
export default CompletedStep;
