import AppContainer from "src/components/AppContainer";
import FormikRef from "src/forms/FormikRef";
import AddAssessmentForm, {
    AssessmentCreateFormShape,
    createAssessmentSchema,
} from "../components/forms/AddAssessmentForm";
import { createRef, useEffect, useRef, useState } from "react";
import { Typography, Paper, Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form } from "formik";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { AssessmentCreateTypes } from "../components/AssessmentCreateModal";
import { useRoles } from "src/modules/common/hooks/useRoles";
import { PublishedAssessmentFormShape } from "../components/forms/AddPublishedAssessmentForm";
import { CustomisedAssessmentFormShape } from "../components/forms/AddCustomisedAssessmentForm";
import { AssessmentCreateRequest, useAssessmentCreateMutation } from "../hooks/useCreate";
import { format, parse } from "date-fns";
import {
    ROUTE_CLASS_ASSESSMENT_LIST,
    ROUTE_TRACKER_CLASS_TRACKER,
    ROUTE_TRACKER_CLASS_TRACKER_GROUP,
} from "src/routes";
import { useSnackbar } from "notistack";
import { CopyAssessmentFormShape } from "../components/forms/CopyAssessmentForm";
import { Storage } from "src/services/storage";
import { useTrackerPath } from "src/modules/class/hooks/useTrackerPath";
import { useTrackerValues } from "src/modules/tracker/hooks/useTrackerValues";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { useResponse } from "src/hooks/useResponse";
import { ApiStatus } from "src/api/constants";
import { generateTrackerUrl } from "src/services/url";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export interface UncollapsedObject {
    type: AssessmentCreateTypes;
    index: number;
}

export const emptyPublishedAssessmentValues: PublishedAssessmentFormShape = {
    tier: "-",
    unit: 0,
    hideForNow: false,
    module: "",
    moduleType: "All",
    hasModules: false,
    name: "",
    countsTowardGrade: true,
    showQlaInReports: false,
    hiddenColumns: false,
    visibleClassTrackers: [],
    savedAndCollapsed: false,
};

export const emptyCustomisedAssessmentValues: CustomisedAssessmentFormShape = {
    gradeBoundaryFactor: 0,
    gradeBoundaryType: "tracker",
    examDate: "",
    dataEntryType: "single",
    tierUnits: [],
    hideForNow: false,
    personalisedAssesmentQuestions: [
        {
            question: "",
            maxValue: 1,
            topics: [],
        },
    ],
    gradeBoundaryValues: [],
    name: "",
    hiddenColumns: false,
    countsTowardGrade: true,
    visibleClassTrackers: [],
    savedAndCollapsed: false,
    showQlaInReports: false,
};

export const getEmptyCopyAssessmentValues = (cohort: string): CopyAssessmentFormShape => {
    return {
        specification: null,
        qualification: null,
        subjectArea: null,
        academicYear: parseInt(cohort),
        yearGroup: 0,
        assessmentType: "pp",
        assessmentNames: [],
    };
};

const AssessmentCreateContainer = () => {
    const form = createRef() as any;
    const interval = useRef() as any;
    const { navigate } = useSchoolNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { cohort } = useParams();
    const { canManageAssessments } = useRoles();
    const [searchParams] = useSearchParams();
    const [recoverPP] = useState<string>(searchParams.get("recoverPP"));
    const [recoverCA] = useState<string>(searchParams.get("recoverCA"));
    const [startingType] = useState<AssessmentCreateTypes>(
        searchParams.get("startingType") as AssessmentCreateTypes,
    );
    const [returnTrackerId] = useState<number | null>(
        searchParams.get("trackerId") ? parseInt(searchParams.get("trackerId")) : null,
    );
    const [returnTrackerGroupId] = useState<number | null>(
        searchParams.get("trackerGroupId") ? parseInt(searchParams.get("trackerGroupId")) : null,
    );
    const [returnTier] = useState<TiersTypes>(searchParams.get("rtier") as TiersTypes | "-");
    const [isWaitingForTracker, setIsWaitingForTracker] = useState<boolean>(false);
    const {
        getClassTrackerValues,
        getClassTrackerGroupValues,
        apiClassTrackerGroupValues,
        apiClassTrackerValues,
    } = useTrackerValues(true);
    const [sSpecification] = useState<string>(searchParams.get("sSpecification"));
    const [sYg] = useState<string>(searchParams.get("sYg"));
    const [storageRecovery] = useState<AssessmentCreateFormShape>(
        Storage.getItem("addAssessmentForm") ? Storage.getItem("addAssessmentForm") : null,
    );
    const [uncollapsed, setUncollapsed] = useState<UncollapsedObject | null>(
        recoverPP || recoverCA ? null : { type: startingType || "pp", index: 0 },
    );

    const initialValues =
        (recoverPP || recoverCA) && storageRecovery
            ? {
                  ...storageRecovery,
                  publishedAssessments: storageRecovery.publishedAssessments.filter((_, pi) =>
                      recoverPP.split(",").includes(`${pi}`),
                  ),
                  customisedAssessments: storageRecovery.customisedAssessments.filter((_, ci) =>
                      recoverCA.split(",").includes(`${ci}`),
                  ),
                  copyAssessmentForm: getEmptyCopyAssessmentValues(cohort),
              }
            : {
                  specification: sSpecification ? parseInt(sSpecification) : null,
                  academicYear: parseInt(cohort),
                  yearGroup: sYg ? parseInt(sYg) : 0,
                  publishedAssessments:
                      startingType === "pp" ? [emptyPublishedAssessmentValues] : [],
                  customisedAssessments:
                      startingType === "eott" ? [emptyCustomisedAssessmentValues] : [],
                  copyAssessmentForm: getEmptyCopyAssessmentValues(cohort),
              };

    const {
        mutate: createAssessments,
        isError,
        isSuccess,
    } = useAssessmentCreateMutation(
        () => {
            enqueueSnackbar(t("class.assessment.add.successCreated"), { variant: "success" });
            Storage.removeItem("addAssessmentForm");
            if (!returnTrackerId && !returnTrackerGroupId) {
                navigate(ROUTE_CLASS_ASSESSMENT_LIST.replace(":cohort", cohort));
            } else {
                interval.current = setInterval(
                    () => {
                        if (returnTrackerGroupId) {
                            getClassTrackerGroupValues(returnTrackerGroupId, returnTier);
                        }
                        if (returnTrackerId) {
                            getClassTrackerValues(returnTrackerId, returnTier);
                        }
                    },
                    returnTrackerGroupId ? 12000 : 6000,
                );
            }
        },
        errors => {
            if (errors?.customisedAssessments) {
                if (errors.customisedAssessments[0]) {
                    setUncollapsed({ type: "eott", index: 0 });
                    if (errors.customisedAssessments[0]["gradeBoundaryValues"])
                        enqueueSnackbar(
                            t("class.assessment.add.gradeBoundaryValuesError"),
                            SnackbarErrorOptions,
                        );
                } else {
                    const firstKey = Object.keys(errors.customisedAssessments).pop();
                    setUncollapsed({ type: "eott", index: parseInt(`${firstKey}`) });
                    Object.keys(errors.customisedAssessments[firstKey]).forEach(key => {
                        if (key === "gradeBoundaryValues")
                            enqueueSnackbar(
                                t("class.assessment.add.gradeBoundaryValuesError"),
                                SnackbarErrorOptions,
                            );
                    });
                }
                setIsWaitingForTracker(false);
            }
        },
    );

    const canShowSubmitButton = (formValues: AssessmentCreateFormShape) => {
        return (
            formValues.specification &&
            (formValues.customisedAssessments.length > 0 ||
                formValues.publishedAssessments.length > 0) &&
            (formValues.customisedAssessments.find(ca => ca.savedAndCollapsed) ||
                formValues.publishedAssessments.find(pa => pa.savedAndCollapsed))
        );
    };

    const handleSubmit = (values: AssessmentCreateFormShape) => {
        const convertedAssessments: AssessmentCreateRequest = {
            ...values,
            specification: { id: values.specification },
            copyAssessmentForm: undefined,
            publishedAssessments: values.publishedAssessments.map(pa => ({
                ...pa,
                module: { id: parseInt(pa.module) },
                unit: { id: pa.unit },
                visibleClassTrackerIds: pa.visibleClassTrackers
                    .filter(vct => vct.visible)
                    .map(vct => vct.id),
                visibleClassTrackers: undefined,
            })),
            customisedAssessments: values.customisedAssessments.map(ca => ({
                ...ca,
                examDate: ca.examDate
                    ? format(parse(ca.examDate, "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
                    : null,
                tierUnits: ca.tierUnits
                    .filter(tu => tu.unit)
                    .map(tu => ({
                        tier: tu.tier,
                        unit: { id: tu.unit },
                    })),
                gradeBoundaryFactor: parseInt(`${ca.gradeBoundaryFactor}`),
                visibleClassTrackerIds: ca.visibleClassTrackers
                    .filter(vct => vct.visible)
                    .map(vct => vct.id),
                visibleClassTrackers: undefined,
                hiddenColumns: !ca.countsTowardGrade ? ca.hiddenColumns : false,
                personalisedAssesmentQuestions: ca.personalisedAssesmentQuestions?.map(pa => ({
                    ...pa,
                    question: pa.question || ca.name,
                    maxValue: parseInt(`${pa.maxValue}`),
                    topics: (pa.topics || []).map(t => ({ id: t })),
                })),
            })),
        } as AssessmentCreateRequest;
        if (returnTrackerGroupId || returnTrackerId) {
            setIsWaitingForTracker(true);
        }
        createAssessments(convertedAssessments);
    };

    const { refetch } = useTrackerPath(parseInt(sSpecification));

    useEffect(() => {
        if (isError || isSuccess) form?.current?.setSubmitting(false);
    }, [isError, isSuccess, form]);

    useEffect(() => {
        if (sSpecification) {
            refetch();
        }
    }, [sSpecification]);

    useResponse(() => {
        if (apiClassTrackerValues.status === ApiStatus.SUCCESS) {
            const classTrackerRoute = generateTrackerUrl(
                ROUTE_TRACKER_CLASS_TRACKER,
                returnTrackerId,
                parseInt(cohort),
                searchParams.get("rSubject") || "-",
                searchParams.get("rQual") || "-",
                sSpecification,
                returnTier,
                sYg,
            );
            clearInterval(interval.current);
            setIsWaitingForTracker(false);
            navigate(classTrackerRoute);
        }
    }, apiClassTrackerValues);

    useResponse(() => {
        if (apiClassTrackerGroupValues.status === ApiStatus.SUCCESS) {
            const classTrackerGroupRoute = generateTrackerUrl(
                ROUTE_TRACKER_CLASS_TRACKER_GROUP,
                returnTrackerGroupId,
                parseInt(cohort),
                searchParams.get("rSubject") || "-",
                searchParams.get("rQual") || "-",
                sSpecification,
                returnTier,
                sYg,
            );
            clearInterval(interval.current);
            navigate(classTrackerGroupRoute);
        }
    }, apiClassTrackerGroupValues);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.assessment.add.header")}
            </Typography>
            <Paper>
                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => createAssessmentSchema(t)}
                    onSubmit={values => {
                        handleSubmit(values);
                    }}
                    enableReinitialize={true}
                >
                    {formProps => (
                        <Form>
                            <AddAssessmentForm
                                cohort={parseInt(cohort)}
                                uncollapsed={uncollapsed}
                                handleUncollapsed={(uncollapsed: UncollapsedObject) =>
                                    setUncollapsed(uncollapsed)
                                }
                                canManageAssessments={canManageAssessments}
                                startingType={startingType as AssessmentCreateTypes}
                            />
                            {canShowSubmitButton(formProps.values) ? (
                                <Box
                                    sx={{
                                        px: 6,
                                        pt: 5,
                                        pb: 5,
                                        borderTopWidth: "1px",
                                        borderTopStyle: "solid",
                                        borderTopColor: "divider",
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        disabled={formProps.isSubmitting || isWaitingForTracker}
                                        onClick={() => {
                                            formProps.submitForm();
                                        }}
                                    >
                                        {t("class.assessment.add.createAssessments")}
                                        {(isWaitingForTracker || formProps.isSubmitting) && (
                                            <CircularProgress size={16} sx={{ marginLeft: 2 }} />
                                        )}
                                    </Button>
                                    {isWaitingForTracker && (
                                        <Box component={"span"} sx={{ marginLeft: 2 }}>
                                            {t("class.assessment.add.waitingForTracker")}
                                        </Box>
                                    )}
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Form>
                    )}
                </FormikRef>
            </Paper>
        </AppContainer>
    );
};

export default AssessmentCreateContainer;
