import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { PROFILE_QUERY, ProfileResponse } from "./useProfile";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface EditUserProfileRequest {
    phone: string;
    mobile: string;
    title: string;
    firstName: string;
    lastName: string;
    schoolTitle?: string;
}

const apiEditProfile = async (
    schoolAccountId: number,
    values: EditUserProfileRequest,
): Promise<AxiosResponse<ProfileResponse>> => {
    return await axiosInstance.put(`school/${schoolAccountId}/profile/`, values);
};

export const useEditProfile = (schoolAccount?: number) => {
    const schoolAccountId = schoolAccount || getSchoolAccountId();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: EditUserProfileRequest) =>
            apiEditProfile(parseInt(`${schoolAccountId}`), values),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [PROFILE_QUERY, schoolAccountId] });
            enqueueSnackbar(t("account.profileSaved"), { variant: "success" });
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("common.unknownError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
