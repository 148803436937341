import { TFunction } from "i18next";
import * as yup from "yup";

export const resetPasswordSchema = (t: TFunction) =>
    yup.object().shape({
        email: yup
            .string()
            .email(t("common.form.validation.email.type"))
            .required(t("common.form.validation.email.required")),
    });
