import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSubscription } from "src/orm/models/Subscription";
import { getSchoolAccountId } from "src/services/url";

export const convertToNahtSubscriptionEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscriptions/{subscription}/convert-to-naht-supported/`,
    method: ApiRequests.POST,
};

export type ConvertToNahtSubscriptionResponse = OrmSubscription[];
