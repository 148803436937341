import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { USER_CONTEXT_QUERY } from "./useUserContext";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const apiChangeLanguage = async (language: string): Promise<AxiosResponse> => {
    return await axiosInstance.put(`user/language/${language}/`);
};

export const useChangeLanguage = () => {
    const queryClient = useQueryClient();
    const { navigate } = useSchoolNavigate();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (language: string) => apiChangeLanguage(language),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [USER_CONTEXT_QUERY] });
            navigate(0);
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
