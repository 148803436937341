import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import ChangeGradeBoundaryModal from "../../Common/ChangeGradeBoundaryModal";
import ForecastActionButtons from "../../Forecast/ForecastActionButtons";
import LiveTrackerGroupActionButtons from "../../Common/LiveTrackerGroupActionButtons";
import SnapshotActionButtons from "../../Snapshot/SnapshotActionButtons";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { mdiBorderHorizontal, mdiTextBoxPlusOutline, mdiFinance } from "@mdi/js";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Dispatch } from "redux";
import { ApiData, ApiStatus } from "src/api/constants";
import { apiUrl } from "src/config/globals";
import { useResponse } from "src/hooks/useResponse";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { GradeBoundariesSeriesObject } from "src/modules/tracker/api/GradeBoundaries/getLiveGradeBoundarySeries";
import { exportTrackerToExcel } from "src/modules/tracker/services/dataExport";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { GradeTypes } from "src/orm/models/Grade";
import {
    ROUTE_ANALYSIS_SUBJECT_ANALYSIS,
    ROUTE_CLASS_ASSESSMENT_CREATE,
    ROUTE_CLASS_ASSESSMENT_LIST,
} from "src/routes";
import { baseHeaders, post } from "src/services/ajax";
import { FormError, getErrorMessage } from "src/services/error";
import { AppState } from "src/store/reducers";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { Storage } from "src/services/storage";
import { SchoolUserRole } from "src/orm/models/User";
import AssessmentRecoveryModal from "src/modules/assessment/components/AssessmentRecoveryModal";
import AssessmentCreateModal, {
    AssessmentCreateTypes,
} from "src/modules/assessment/components/AssessmentCreateModal";
import { AssessmentCreateFormShape } from "src/modules/assessment/components/forms/AddAssessmentForm";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useClassTrackerGroupPixlSeriesList } from "src/modules/tracker/hooks/query/ClassTrackerGroup/useTrackerGroupPixlSeriesList";
import { useClassTrackerGroupPixlSeriesAvg } from "src/modules/tracker/hooks/query/ClassTrackerGroup/useTrackerGroupPixlSeriesAvg";
import { AnalysisTypes } from "src/modules/analysis/components/Common/AnalysisTypeModal";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { AxiosError, HttpStatusCode } from "axios";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getSchoolAccountId } from "src/services/url";

export interface SeriesDataUnitsObject {
    id: number;
    name: string;
    values: number[];
    maxValue: number;
}

export interface SeriesDataObject {
    gradeBoundaryFactor: number;
    units: SeriesDataUnitsObject[];
}

const dispatchActions = (dispatch: Dispatch) => ({
    getLiveGradeBoundaries: (classTrackerGroupId: number) => {
        dispatch(TrackerActions.getClassTrackerLiveGradeBoundaries(classTrackerGroupId));
    },
    changeClassGradeBoundary: (classTrackerGroupId: number, seriesId: number, factor: number) => {
        dispatch(TrackerActions.changeClassGradeBoundary(classTrackerGroupId, seriesId, factor));
    },
    clearLiveGradeBoundaries: () => {
        dispatch(TrackerActions.setClassLiveGradeBoundaries(null));
    },
});

const GroupToolsPanel = ({ context, api }) => {
    const {
        cohortId,
        relatedData,
        dataType,
        handleBlocked,
        handleDataAsyncLoaded,
        classTrackerGroupId,
        handleGradeBoundariesProcessing,
        tier,
        handleBulkEditSave,
        isSyncedClass,
        archived,
        handlePixlSeriesChange,
        gridUserSettings,
    } = context;

    const { bulkEdit } = useParams();

    const { navigate } = useSchoolNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedSeries, setSelectedSeries] = useState<number>(
        gridUserSettings?.selectedSeriesId || 0,
    );
    const [recoveryDialogOpened, setRecoveryDialogOpened] = useState<boolean>(false);
    const [createDialogOpened, setCreateDialogOpened] = useState<boolean>(false);
    const [addAssessmentType, setAddAssessmentType] = useState<AssessmentCreateTypes | null>(null);
    const [storageRecovery] = useState<AssessmentCreateFormShape>(
        Storage.getItem("addAssessmentForm") ? Storage.getItem("addAssessmentForm") : null,
    );
    const { data: classTrackerGroupSeriesList } =
        useClassTrackerGroupPixlSeriesList(classTrackerGroupId);

    const { data: pixlSeries, error } = useClassTrackerGroupPixlSeriesAvg(
        classTrackerGroupId,
        tier,
        selectedSeries,
    );

    useEffect(() => {
        const err = error as AxiosError<{ error: string }>;
        if (err?.response?.data?.error) {
            if (err?.response?.status === HttpStatusCode.NotFound) {
                enqueueSnackbar(t("tracker.grid.pixlSeriesRemoved"), { variant: "error" });
            } else {
                enqueueSnackbar(err?.response?.data?.error, { variant: "error" });
            }
            setSelectedSeries(0);
        }
    }, [error]);

    const { getLiveGradeBoundaries, changeClassGradeBoundary, clearLiveGradeBoundaries } =
        dispatchActions(dispatch);
    const {
        liveGradeBoundaries,
        apiChange,
    }: {
        liveGradeBoundaries: GradeBoundariesSeriesObject[] | null;
        apiChange: ApiData;
    } = useSelector(
        (state: AppState) => ({
            liveGradeBoundaries: state.tracker.liveGradeBoundarySeries,
            apiChange: state.api.tracker.changeClassGradeBoudary,
        }),
        shallowEqual,
    );
    const { data: userProfile } = useProfile();

    const hasAccessToPastPapers = !!(
        userProfile &&
        userProfile &&
        (userProfile.userRole === SchoolUserRole.OWNER ||
            userProfile.userRole === SchoolUserRole.SCHOOL_ADMIN ||
            userProfile.isLeaderOfAll ||
            userProfile.leaderOfSubjectAreas.length > 0)
    );

    const currentGradeBoundarySeriesName = relatedData?.gradeBoundary.seriesName;

    const [changeBoundaryOpen, setChangeBoundaryOpen] = useState<boolean>(false);
    const [selectedGradeBoundary, setSelectedGradeBoundary] = useState<number | null>(null);
    const [gradeBoundaryFactor, setGradeBoundaryFactor] = useState<number>(
        relatedData?.gradeBoundary.factor || 0,
    );
    const [seriesData, setSeriesData] = useState<SeriesDataObject | null>(null);

    const handleBoundaryOpen = () => setChangeBoundaryOpen(true);

    const handleBoundaryClose = (confirmed: boolean) => {
        if (confirmed && selectedGradeBoundary && classTrackerGroupId) {
            changeClassGradeBoundary(
                classTrackerGroupId,
                selectedGradeBoundary,
                gradeBoundaryFactor,
            );
        } else {
            setChangeBoundaryOpen(false);
        }
    };

    // to refactor
    const getGradeBoundaryValues = (
        classTrackerGroupId: number,
        selectedGradeBoundary: number,
        gradeBoundaryFactor: number,
        tier?: TiersTypes,
    ) => {
        post(
            apiUrl(
                `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/${
                    tier || "-"
                }/grade-boundaries-series/${selectedGradeBoundary}/`,
            ),
            { gradeBoundaryFactor },
            baseHeaders(),
        ).subscribe(
            res => setSeriesData(res.response),
            err =>
                enqueueSnackbar(err.response.message, {
                    ...SnackbarErrorOptions,
                }),
        );
    };

    useEffect(() => {
        const currentGradeBoundary = liveGradeBoundaries?.find(
            lgb => lgb.name === currentGradeBoundarySeriesName,
        );
        if (
            liveGradeBoundaries &&
            liveGradeBoundaries.length > 0 &&
            selectedGradeBoundary === null
        ) {
            if (!currentGradeBoundary) {
                setSelectedGradeBoundary(liveGradeBoundaries[0].id);
            } else {
                setSelectedGradeBoundary(currentGradeBoundary.id);
            }
        }
    }, [
        currentGradeBoundarySeriesName,
        liveGradeBoundaries,
        selectedGradeBoundary,
        setSelectedGradeBoundary,
    ]);

    useEffect(() => {
        if (selectedGradeBoundary && classTrackerGroupId) {
            getGradeBoundaryValues(
                classTrackerGroupId,
                selectedGradeBoundary,
                gradeBoundaryFactor,
                tier,
            );
        }
    }, [gradeBoundaryFactor, classTrackerGroupId, selectedGradeBoundary]);

    useEffect(() => {
        if (!selectedSeries && handlePixlSeriesChange) handlePixlSeriesChange(null, null);
    }, [selectedSeries]);

    useEffect(() => {
        if (pixlSeries && handlePixlSeriesChange) {
            handlePixlSeriesChange(selectedSeries, pixlSeries);
        }
    }, [pixlSeries]);

    useEffect(() => {
        if (classTrackerGroupId && relatedData && relatedData.gradeType.type !== GradeTypes.BTEC) {
            getLiveGradeBoundaries(classTrackerGroupId);
        }
    }, [classTrackerGroupId, relatedData]);

    useEffect(() => {
        return () => {
            setSelectedGradeBoundary(null);
            clearLiveGradeBoundaries();
        };
    }, []);

    const handleResponse = useCallback(
        data => {
            switch (data.status) {
                case ApiStatus.SUCCESS: {
                    if (handleGradeBoundariesProcessing) handleGradeBoundariesProcessing();
                    setChangeBoundaryOpen(false);

                    break;
                }
                case ApiStatus.ERROR: {
                    if (data.message)
                        enqueueSnackbar(data.message, {
                            ...SnackbarErrorOptions,
                        });
                    const error: FormError = getErrorMessage(data);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
        },

        [enqueueSnackbar, t],
    );

    useResponse(() => handleResponse(apiChange), apiChange);

    if (dataType === DataTypeTypes.LIVE && bulkEdit) {
        return (
            <LiveTrackerGroupActionButtons
                isSyncedClass={!!isSyncedClass}
                exportAsExcel={() => exportTrackerToExcel(api)}
                saveBulkEdit={handleBulkEditSave}
                tier={tier}
                archived={archived}
            />
        );
    }

    return (
        <Box>
            {!archived && hasAccessToPastPapers && (
                <Box
                    px={4}
                    py={3}
                    borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                    display="flex"
                    flexDirection="column"
                >
                    <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27} mb={1}>
                        {t("common.toolsAssessments")}
                    </Box>
                    <Button
                        variant="text"
                        onClick={() => setCreateDialogOpened(true)}
                        startIcon={<Icon path={mdiTextBoxPlusOutline} />}
                        disableRipple
                    >
                        {t("tracker.grid.addAssessment")}
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => {
                            navigate(
                                ROUTE_CLASS_ASSESSMENT_LIST.replace(":cohort", `${cohortId}`) +
                                    `?subjectArea=${relatedData?.subjectArea?.id}&qualification=${
                                        relatedData?.qualification?.id
                                    }&yearGroup=${relatedData?.yearGroup}&returnUrl=${encodeURIComponent(
                                        location.pathname + location.search,
                                    )}`,
                            );
                        }}
                        startIcon={
                            <Icon path="M10 21H5C3.89 21 3 20.11 3 19V5C3 3.89 3.89 3 5 3H19C20.11 3 21 3.89 21 5V10.33C20.7 10.21 20.37 10.14 20.04 10.14C19.67 10.14 19.32 10.22 19 10.37V5H5V19H10.11L10 19.11V21M7 9H17V7H7V9M7 17H12.11L14 15.12V15H7V17M7 13H16.12L17 12.12V11H7V13M21.7 13.58L20.42 12.3C20.21 12.09 19.86 12.09 19.65 12.3L18.65 13.3L20.7 15.35L21.7 14.35C21.91 14.14 21.91 13.79 21.7 13.58M12 22H14.06L20.11 15.93L18.06 13.88L12 19.94V22Z" />
                        }
                        disableRipple
                    >
                        {t("tracker.grid.editAssessmentBtn")}
                    </Button>
                </Box>
            )}
            {!archived && relatedData?.gradeType.type !== GradeTypes.BTEC && (
                <>
                    <Box
                        px={4}
                        py={3}
                        borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                        display="flex"
                        flexDirection="column"
                    >
                        <Box fontFamily={PRIMARY_FONT_FAMILY} mr={4}>
                            <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27}>
                                {t("tracker.grid.geadeBoundarySeries")}
                            </Box>
                            <Box fontSize={15} fontWeight="fontWeightMedium" lineHeight={1.375}>
                                {relatedData?.gradeBoundary.seriesName || ""}
                            </Box>
                        </Box>
                        <Button
                            variant="text"
                            startIcon={<Icon path={mdiBorderHorizontal} />}
                            disableRipple
                            onClick={handleBoundaryOpen}
                        >
                            {t("tracker.grid.gradeBoundaryChangeBtn")}
                        </Button>
                    </Box>
                    <ChangeGradeBoundaryModal
                        open={changeBoundaryOpen}
                        handleClose={c => handleBoundaryClose(c)}
                        relatedData={relatedData}
                        setGradeBoundaryFactor={(factor: number) => setGradeBoundaryFactor(factor)}
                        setGradeBoundary={gradeBoundaryId =>
                            setSelectedGradeBoundary(gradeBoundaryId)
                        }
                        seriesData={seriesData}
                        selectedGradeBoundary={selectedGradeBoundary}
                        liveGradeBoundaries={liveGradeBoundaries}
                        editMode={true}
                        isLoading={apiChange.status === ApiStatus.LOADING}
                    />
                </>
            )}
            {dataType && dataType === DataTypeTypes.LIVE && (
                <LiveTrackerGroupActionButtons
                    isSyncedClass={!!isSyncedClass}
                    exportAsExcel={() => exportTrackerToExcel(api)}
                    saveBulkEdit={handleBulkEditSave}
                    tier={tier}
                    archived={archived}
                />
            )}
            {!archived && dataType && dataType === DataTypeTypes.FORECAST && (
                <ForecastActionButtons
                    classTrackerGroup={classTrackerGroupId}
                    exportAsExcel={() => exportTrackerToExcel(api)}
                    handleBlocked={handleBlocked}
                    handleDataAsyncLoaded={handleDataAsyncLoaded}
                    isGroup={true}
                    gridApi={api}
                    tier={tier}
                />
            )}
            {dataType && dataType === DataTypeTypes.SNAPSHOT && (
                <SnapshotActionButtons exportAsExcel={() => exportTrackerToExcel(api)} />
            )}
            <Box
                px={4}
                py={3}
                borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                display="flex"
                flexDirection="column"
            >
                <Box color={COLORS.GREY_3} fontSize={11} lineHeight={1.27} mb={1}>
                    {t("common.reportsAnalysis")}
                </Box>
                {userProfile.accessToAnalysisTypes.includes(AnalysisTypes.SUBJECT_ANALYSIS) && (
                    <Button
                        variant="text"
                        onClick={() => {
                            navigate(
                                ROUTE_ANALYSIS_SUBJECT_ANALYSIS.replace(
                                    ":cohort",
                                    `${cohortId}`,
                                ).replace(":yearGroup", `${relatedData?.yearGroup}`) +
                                    `?dataType=live&viewGrade=projected&subjectArea=${
                                        relatedData?.subjectArea?.id || ""
                                    }&qualification=${
                                        relatedData?.qualification?.id || ""
                                    }&specification=${relatedData?.specification?.id || ""}`,
                            );
                        }}
                        startIcon={<Icon path={mdiFinance} />}
                        disableRipple
                    >
                        {t("tracker.grid.subjectAnalysis")}
                    </Button>
                )}
            </Box>

            {classTrackerGroupSeriesList && classTrackerGroupSeriesList.length > 0 ? (
                <Box p={4}>
                    <TextField
                        select
                        label={t("tracker.grid.selectedSeries")}
                        value={selectedSeries}
                        onChange={e => setSelectedSeries(parseInt(`${e.target.value}`))}
                    >
                        <MenuItem value={0}>{t("common.none")}</MenuItem>
                        {classTrackerGroupSeriesList.map(ctgsl => (
                            <MenuItem key={ctgsl.id} value={ctgsl.id}>
                                {ctgsl.name || ctgsl.id}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            ) : (
                <></>
            )}
            <AssessmentCreateModal
                open={createDialogOpened}
                handleClose={(confirm, selectedType) => {
                    setCreateDialogOpened(false);
                    if (confirm) {
                        if (
                            storageRecovery &&
                            (storageRecovery?.publishedAssessments?.length > 0 ||
                                storageRecovery?.customisedAssessments?.length > 0)
                        ) {
                            setRecoveryDialogOpened(true);
                            setAddAssessmentType(selectedType);
                        } else {
                            const addAssesmentURLBasicParams = `&trackerGroupId=${classTrackerGroupId}&sYg=${relatedData.yearGroup}&sSpecification=${relatedData.specification.id}&rSubject=${relatedData?.subjectArea?.name}&rQual=${relatedData?.qualification?.name}&rtier=${tier}`;
                            navigate(
                                ROUTE_CLASS_ASSESSMENT_CREATE.replace(":cohort", `${cohortId}`) +
                                    `?startingType=${selectedType}${addAssesmentURLBasicParams}`,
                            );
                        }
                    }
                }}
            />
            <AssessmentRecoveryModal
                open={recoveryDialogOpened}
                handleClose={() => {
                    setRecoveryDialogOpened(false);
                }}
                handleRemove={() => {
                    Storage.removeItem("addAssessmentForm");
                    setRecoveryDialogOpened(false);
                    navigate(
                        ROUTE_CLASS_ASSESSMENT_CREATE.replace(":cohort", `${cohortId}`) +
                            `?startingType=${addAssessmentType}&sYg=${relatedData.yearGroup}&sSpecification=${relatedData.specification.id}`,
                    );
                }}
                handleRecover={(selectedPPIndex, selectedCaIndex) => {
                    setRecoveryDialogOpened(false);
                    navigate(
                        ROUTE_CLASS_ASSESSMENT_CREATE.replace(":cohort", `${cohortId}`) +
                            "?recoverPP=" +
                            selectedPPIndex.join(",") +
                            "&recoverCA=" +
                            selectedCaIndex.join(","),
                    );
                }}
            />
        </Box>
    );
};

export default GroupToolsPanel;
