import App from "./App";
import i18n from "i18next";
import { translations } from "src/i18n/translations";
import { initReactI18next } from "react-i18next";
import { bugsnagClient } from "./services/bugsnagClient";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import { LicenseManager } from "ag-grid-enterprise";
import { AGGRID_KEY } from "./config/globals";
import { createRoot } from "react-dom/client";
import "./index.scss";

const licenseKey = AGGRID_KEY;

if (licenseKey) {
    LicenseManager.setLicenseKey(licenseKey);
}

const ErrorBoundary = bugsnagClient.getPlugin("react");

i18n.use(initReactI18next).init({
    resources: translations,
    lng: "en",
});
const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
);

unregisterServiceWorker();
