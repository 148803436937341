import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { ListObject } from "src/forms/types";
import { AttributesRequest } from "../../api/Classes/uploadAndSend";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface GroupCallYearGroups {
    yearGroup: number;
    hasManualClasses?: boolean;
    classes: (ListObject & { synchronized?: boolean; selected?: boolean })[];
}

export interface CreateGroupCallClassRequest {
    attributes: AttributesRequest[];
    yearGroups: GroupCallYearGroups[];
    syncWithMis: boolean;
    specificationId?: number;
}

const apiGroupCallClassesCreateCreate = async (values: CreateGroupCallClassRequest) => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/group-call/specification/${values.specificationId}/year-groups/`,
        values,
    );
};

export const useGroupCallClassesCreateMutation = onError => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: CreateGroupCallClassRequest) =>
            apiGroupCallClassesCreateCreate(values),

        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError) onError(err);
        },
    });
};
