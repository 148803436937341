import { create } from "zustand";
import { AnalysisUserSettingsObject, useUserSettings } from "./useUserSettings";
import { useUserSettingsEditMutation } from "./useUserSettingsEditMutation";
import { useCallback, useEffect, useRef } from "react";
import { useBeforeUnload } from "react-router-dom";
import { getSchoolAccountId } from "src/services/url";
import { apiUrl } from "src/config/globals";
import { baseHeaders } from "src/services/ajax";

export type AnalysisUserSettingsStoreObject = AnalysisUserSettingsObject & {
    setAnalysisUserSettings: (newSettings: AnalysisUserSettingsObject) => void;
};

export const useAnalysisUserSettingsStore = create<AnalysisUserSettingsStoreObject>(set => ({
    setAnalysisUserSettings: (newSettings: AnalysisUserSettingsObject) =>
        set(state => ({ ...state, ...newSettings })),
}));

export const useAnalysisUserSettingsApiConnector = (
    cohort: number,
    yearGroup: number,
    viewType: string,
) => {
    const { data: userSettings } = useUserSettings(cohort, yearGroup, viewType);
    const { mutate: setAnalysisUserSettingsApi } = useUserSettingsEditMutation(
        cohort,
        yearGroup,
        viewType,
    );

    const { setAnalysisUserSettings, ...analysisUserSettings } = useAnalysisUserSettingsStore();

    useEffect(() => {
        setAnalysisUserSettings(userSettings);
    }, [userSettings]);

    const analysisUserSettingsRef = useRef(analysisUserSettings);
    analysisUserSettingsRef.current = analysisUserSettings;
    useEffect(() => {
        return () => setAnalysisUserSettingsApi(analysisUserSettingsRef.current);
    }, []);

    useBeforeUnload(
        useCallback(() => {
            fetch(
                `${apiUrl("")}school/${getSchoolAccountId()}/analysis/common/${cohort}/${yearGroup}/${viewType}/user-settings/`,
                {
                    keepalive: true,
                    method: "POST",
                    headers: baseHeaders(),
                    body: JSON.stringify(analysisUserSettings),
                },
            );
        }, [analysisUserSettings]),
    );

    const saveAnalysisUserSettings = useCallback(() => {
        setAnalysisUserSettingsApi(analysisUserSettings);
    }, [analysisUserSettings]);

    return { setAnalysisUserSettings, analysisUserSettings, saveAnalysisUserSettings };
};
