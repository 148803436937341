import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { ParentTitlesType } from "../model/ParentModel";
import { PARENT_LIST_QUERY } from "./useParentsList";
import { getSchoolAccountId } from "src/services/url";

export interface ParentEditRequest {
    id: number;
    title: ParentTitlesType;
    firstName: string;
    lastName: string;
    email: string;
}
const apiEditParent = async (values: ParentEditRequest): Promise<AxiosResponse> => {
    return await axiosInstance.put(`school/${getSchoolAccountId()}/parents/${values.id}/`, values);
};

export const useParentEditMutation = (onSuccess: () => void, onError: (err) => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: ParentEditRequest) => apiEditParent(values),
        onSuccess: () => {
            enqueueSnackbar(t("parent.addEdit.parentEdited"), { variant: "success" });
            queryClient.refetchQueries({ queryKey: [PARENT_LIST_QUERY] });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(
                    err?.response?.data?.error || t("parent.addEdit.parentUpdateError"),
                    {
                        ...SnackbarErrorOptions,
                    },
                );
            }
            if (onError) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
