import {
    Box,
    Button,
    Grid,
    MenuItem,
    Paper,
    Select,
    Typography,
    FormGroup,
    FormHelperText,
    FormControl,
    InputLabel,
} from "@mui/material";
import { ApiData, ApiStatus } from "src/api/constants";
import { shouldLoadData } from "src/api/helpers";
import CheckboxField from "src/forms/CheckboxField";
import FormikObserver from "src/forms/FormikObserver";
import FormikRef from "src/forms/FormikRef";
import { usePrevious } from "src/hooks/usePrevious";
import { subjectAreaSelector } from "src/modules/common/selectors/SubjectAreaSelectors";
import { CommonActions } from "src/modules/common/store/actions";
import { OrmQualification } from "src/orm/models/Qualification";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { OrmSubscription, SubscriptionTypes } from "src/orm/models/Subscription";
import { FormError, getErrorMessage } from "src/services/error";
import { filterNotNullable } from "src/services/object";
import { AppState } from "src/store/reducers";
import { Field, Form, FormikProps } from "formik";
import { TFunction } from "i18next";
import { useSnackbar } from "notistack";
import { createRef, Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch } from "redux";
import * as yup from "yup";
import FinancialManagerForm, {
    FinanceManagerFormFields,
    financeManagerSchema,
} from "../components/FinancialManagerForm";
import SubscriptionTypeForm from "../components/SubscriptionTypeForm";
import SummaryBox from "../components/SummaryBox";
import TotalsTable from "../components/TotalsTable";
import { SubscriptionValidTypes, useSubscriptionValid } from "../hooks/useSubscriptionValid";
import { subscriptionsSelector } from "../selectors/SubscriptionSelectors";
import { SubscriptionsActions } from "../store/actions";
import ConfirmationBox from "../components/ConfirmationBox";
import AppContainer from "src/components/AppContainer";
import HeadingCounter from "src/components/HeadingCounter";
import COLORS from "src/styles/colors";
import PaperInner from "src/components/PaperInner";
import FieldChangeHandler from "src/forms/FieldChangeHandler";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { useParams } from "react-router";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useQualificationsList } from "src/modules/tagging/components/subjectRows/hooks/useQualificationsList";

interface ExtendSubscriptionForm {
    couponName: string;
    length: string;
    financeManager: FinanceManagerFormFields;
    qualifications: string[];
}

export const extendSchema = (t: TFunction) =>
    yup.object().shape({
        financeManager: financeManagerSchema(t),
        qualifications: yup.array().min(1),
        length: yup.number().required(t("common.form.validation.subscriptionLength.required")),
    });

const convertToRequestData = values => {
    const qualifications = values.qualifications?.filter(q => q[Object.keys(q)[0]]);

    return {
        ...values,
        qualifications: qualifications?.map(q => ({ id: parseInt(Object.keys(q)[0]) })),
        length: parseInt(values.length),
        couponName: values.couponName || null,
        financeManager:
            values.financeManager.title &&
            values.financeManager.email &&
            values.financeManager.firstName &&
            values.financeManager.lastName
                ? values.financeManager
                : undefined,
    };
};

const dispatchActions = (dispatch: Dispatch) => ({
    getSubjectAreas: () => {
        dispatch(CommonActions.getSubjectAreaList());
    },
    getSubscriptions: () => {
        dispatch(SubscriptionsActions.getSubscriptionList());
    },
    extendSubscription: (subscriptionId: number, values) => {
        dispatch(
            SubscriptionsActions.extendSubscription(subscriptionId, convertToRequestData(values)),
        );
    },
});

const ExtendSubscription = () => {
    const params = useParams();
    const subscriptionId = params.id;
    const dispatch = useDispatch();
    const form = createRef();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedSubjectArea, setSelectedSubjectArea] = useState<number>(0);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const { getSubjectAreas, extendSubscription, getSubscriptions } = dispatchActions(dispatch);
    const { data: qualifications } = useQualificationsList(selectedSubjectArea);
    const {
        apiSubscriptions,
        apiList,
        apiCreate,
        subjectAreas,
        subscriptions,
    }: {
        apiSubscriptions: ApiData;
        apiList: ApiData;
        apiCreate: ApiData;
        subjectAreas: OrmSubjectArea[];
        subscriptions: OrmSubscription[];
    } = useSelector((state: AppState) => ({
        apiSubscriptions: state.api.subscription.getSubscriptionList,
        apiList: state.api.common.getSubjectAreaList,
        apiCreate: state.api.subscription.extendSubscription,
        subjectAreas: subjectAreaSelector(state),
        subscriptions: subscriptionsSelector(state),
    }));
    const prevApiStatus = usePrevious(apiCreate.status);
    const prevSelectedSubjectArea = usePrevious(selectedSubjectArea);
    const subscription = subscriptions.find(s => s.id === parseInt(subscriptionId || ""));
    const initialValues = {
        couponName: "",
        length: "1",
        financeManager: { firstName: "", lastName: "", email: "", title: "Mr" },
        qualifications:
            qualifications?.map(q => ({
                [`${q.id}`]: false,
                // subscription &&
                // (subscription.qualifications.find(sq => sq.id === q.id) !== undefined ||
                //     subscription.subscriptionType === SubscriptionTypes.FULL_SUITE),
            })) || [],
    };

    const [validState, setValidState] = useState(null);

    const dataToValidator = validState || initialValues;
    const { result: validatorResponse, error: validatorError } = useSubscriptionValid(
        SubscriptionValidTypes.EXTEND,
        convertToRequestData(dataToValidator),
        parseInt(subscriptionId || ""),
        ["qualifications"],
    );
    const prevValidatorError = usePrevious(validatorError);

    if (validatorError !== null && prevValidatorError !== validatorError) {
        enqueueSnackbar(validatorError, {
            ...SnackbarErrorOptions,
        });
    }

    const handleQualificationsChange = useCallback(
        (newValue, prevValue, args: { setFieldValue: (key: string, value: any) => void }) => {
            const newQualLen = newValue.filter(nV => {
                return Object.keys(nV).filter(key => nV[key] === true).length > 0;
            }).length;
            const prevQualLen = prevValue.filter(nV => {
                return Object.keys(nV).filter(key => nV[key] === true).length > 0;
            }).length;
            if (newQualLen >= 3 && newQualLen > prevQualLen) {
                args.setFieldValue(
                    "qualifications",
                    qualifications?.map(q => ({ [`${q.id}`]: true })) || [],
                );
            }
        },
        [qualifications],
    );

    let subjectArea: OrmSubjectArea | undefined = undefined;
    if (subscription && subscription.subjectArea) {
        subjectArea = subjectAreas.find(sa => sa.id === subscription.subjectArea.id);
    }

    const handleRequestValid = values => {
        const convertedValues = convertToRequestData(values);

        extendSchema(t)
            .isValid(convertedValues)
            .then(isValid => {
                if (isValid) setValidState(values);
            });
    };
    const handleSubmit = values => {
        extendSubscription(parseInt(subscriptionId || ""), values);
    };

    const handleError = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.formError && error.formError["email"]) {
                        currentForm.setFieldError("financeManager.email", error.formError["email"]);
                    }
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
        },
        [enqueueSnackbar, form],
    );

    useEffect(() => {
        if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.SUCCESS) {
            setShowConfirmation(true);
        } else if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.ERROR) {
            handleError(apiCreate);
        }
    }, [apiCreate, enqueueSnackbar, handleError, prevApiStatus, subscriptionId, t]);

    useEffect(() => {
        if (shouldLoadData(apiList)) getSubjectAreas();
        if (shouldLoadData(apiSubscriptions)) getSubscriptions();
    }, [apiList, apiSubscriptions]);

    useEffect(() => {
        if (
            subscription &&
            subjectArea &&
            subjectArea.id &&
            subjectArea.id !== selectedSubjectArea
        ) {
            setSelectedSubjectArea(subjectArea.id);
        }
    }, [prevSelectedSubjectArea, selectedSubjectArea, subjectArea, subscription]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("subscription.extend.header")}
            </Typography>
            {qualifications?.length > 0 && (
                <FormikRef
                    ref={form}
                    initialValues={initialValues}
                    validationSchema={() => extendSchema(t)}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<ExtendSubscriptionForm>) => {
                        const qualLength = convertToRequestData(formProps.values).qualifications;
                        const summaryValues = {
                            qualifications: filterNotNullable(qualifications).filter(qual => {
                                const mapped =
                                    formProps.values.qualifications
                                        ?.filter(q => q[Object.keys(q)[0]] === true)
                                        .map(q => Object.keys(q)[0]) || [];
                                return mapped.includes(qual.id.toString());
                            }),
                            isWholeSchool: false,
                            subjectArea,
                            period: `P${formProps.values["length"]}Y`,
                            type:
                                qualLength && qualLength.length > 2
                                    ? SubscriptionTypes.FULL_SUITE
                                    : SubscriptionTypes.SINGLE,
                        };

                        if (showConfirmation) {
                            return (
                                <ConfirmationBox
                                    values={{
                                        ...formProps.values,
                                        ...summaryValues,
                                        dueDate: subscription?.dueDate || undefined,
                                        period: formProps.values.length,
                                    }}
                                    additionalMessage={null}
                                />
                            );
                        }

                        return (
                            <Form>
                                <FormikObserver
                                    values={formProps.values}
                                    onChange={handleRequestValid}
                                />
                                <Paper>
                                    {subscription && (
                                        <PaperInner
                                            variant="subscription"
                                            border="bottom"
                                            color="lightGrey"
                                        >
                                            <Typography variant="h5" component="h2" gutterBottom>
                                                {t("subscription.extend.currentlyOwn")}
                                            </Typography>
                                            <SummaryBox
                                                subjectArea={subscription.subjectArea}
                                                qualifications={
                                                    subscription.qualifications as OrmQualification[]
                                                }
                                                period={subscription.subscriptionLength}
                                                oneline={true}
                                                type={subscription.subscriptionType}
                                                startDate={subscription.startDate}
                                                dueDate={subscription.dueDate}
                                            />
                                        </PaperInner>
                                    )}
                                    <PaperInner>
                                        <Grid container>
                                            <Grid item sm={4}>
                                                <HeadingCounter number="1">
                                                    {t("subscription.extend.chooseSubject")}
                                                </HeadingCounter>
                                                <FieldChangeHandler
                                                    onChange={handleQualificationsChange}
                                                    value={formProps.values.qualifications}
                                                    setFieldValue={formProps.setFieldValue}
                                                />
                                                <Box mb={4}>
                                                    <FormControl>
                                                        <InputLabel id="subjectAreaLabel">
                                                            {t("subscription.add.subjectArea")}
                                                        </InputLabel>
                                                        <Select
                                                            id="subjectAreaLabel"
                                                            name="subjectArea"
                                                            fullWidth
                                                            value={selectedSubjectArea}
                                                            disabled={true}
                                                        >
                                                            <MenuItem key={0} value={0}>
                                                                {t("common.noneSelect")}
                                                            </MenuItem>
                                                            {filterNotNullable(subjectAreas).map(
                                                                obj => (
                                                                    <MenuItem
                                                                        key={obj.id}
                                                                        value={obj.id}
                                                                    >
                                                                        {obj.name}
                                                                    </MenuItem>
                                                                ),
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <HeadingCounter number="2">
                                                    {t("subscription.extend.chooseCourses")}
                                                </HeadingCounter>
                                                <Box mb={4}>
                                                    {formProps.values.qualifications?.length > 0 ? (
                                                        <FormGroup>
                                                            {filterNotNullable(qualifications).map(
                                                                (qual, index) => (
                                                                    <Field
                                                                        key={qual.id}
                                                                        name={`qualifications.${index}.${qual.id}`}
                                                                        component={CheckboxField}
                                                                        label={qual.name}
                                                                        color="primary"
                                                                    />
                                                                ),
                                                            )}
                                                        </FormGroup>
                                                    ) : (
                                                        selectedSubjectArea > 0 &&
                                                        t("subscription.extend.noCourses")
                                                    )}
                                                    {qualLength.length === 0 && (
                                                        <FormHelperText error>
                                                            {t(
                                                                "subscription.add.minQualifications",
                                                            )}
                                                        </FormHelperText>
                                                    )}
                                                </Box>
                                                <HeadingCounter number="3">
                                                    {t("subscription.extend.choosePeriod")}
                                                </HeadingCounter>
                                                <Box mb={4}>
                                                    <SubscriptionTypeForm
                                                        fieldName="length"
                                                        {...formProps}
                                                        showType={false}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item sm={3} />
                                            <Grid item xs={5}>
                                                <Box
                                                    pt={3}
                                                    pr={4}
                                                    pb={4}
                                                    pl={4}
                                                    bgcolor={COLORS.VERY_LIGHT_GREY_1}
                                                    borderBottom={`1px solid ${COLORS.VERY_LIGHT_GREY_3}`}
                                                >
                                                    <Typography
                                                        variant="h4"
                                                        component="h2"
                                                        color="textSecondary"
                                                        gutterBottom
                                                    >
                                                        {t("subscription.extend.newSubscription")}
                                                    </Typography>
                                                    <SummaryBox
                                                        {...summaryValues}
                                                        startDate={
                                                            subscription?.dueDate || undefined
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    pt={3}
                                                    pr={4}
                                                    pb={4}
                                                    pl={4}
                                                    bgcolor={COLORS.VERY_LIGHT_GREY_1}
                                                >
                                                    <TotalsTable
                                                        validatorResponse={validatorResponse}
                                                        {...formProps}
                                                    />
                                                    {validatorResponse &&
                                                    validatorResponse.isFinanceManager ? (
                                                        <Box pr={6.5}>
                                                            <Typography variant="overline">
                                                                {t(
                                                                    "subscription.summary.byClicking",
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    ) : (
                                                        <Fragment>
                                                            <Box pr={6.5}>
                                                                <Typography
                                                                    variant="h4"
                                                                    component="h2"
                                                                    color="textSecondary"
                                                                    gutterBottom
                                                                >
                                                                    {t(
                                                                        "subscription.summary.inviteFinanceManager",
                                                                    )}
                                                                </Typography>
                                                                <Typography variant="overline">
                                                                    {t(
                                                                        "subscription.summary.isNoFinanceManagerMessage",
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                            <Box mt={2} mr={4}>
                                                                <FinancialManagerForm
                                                                    financeManager={
                                                                        formProps.values
                                                                            .financeManager
                                                                    }
                                                                />
                                                            </Box>
                                                        </Fragment>
                                                    )}
                                                    {qualLength.length > 0 &&
                                                        validatorError === null &&
                                                        validatorResponse !== null && (
                                                            <Box mt={4}>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={formProps.submitForm}
                                                                >
                                                                    {t(
                                                                        "subscription.extend.extendSubscription",
                                                                    )}
                                                                </Button>
                                                                <GenericErrorMessage
                                                                    errors={formProps.errors}
                                                                    submitCount={
                                                                        formProps.submitCount
                                                                    }
                                                                />
                                                            </Box>
                                                        )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </PaperInner>
                                </Paper>
                            </Form>
                        );
                    }}
                </FormikRef>
            )}
        </AppContainer>
    );
};

export default ExtendSubscription;
