import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

export interface LinkCreateRequest {
    yearGroup: number;
    specification: { id: number };
    academicYear: { id: number };
    title: string;
    active: boolean;
    url: string;
    description: string;
}

const apiCreateLink = async (values: LinkCreateRequest) => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/report-links/`, values);
};

export const useCreateLinkMutation = (onSuccess: () => void, onError?) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: LinkCreateRequest) => apiCreateLink(values),
        onSuccess: () => {
            enqueueSnackbar(t("link.addEdit.linkCreated"), { variant: "success" });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error && typeof err?.response?.data?.error === "string") {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
