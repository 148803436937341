import { useTranslation } from "react-i18next";

export const MoveTierHeader = () => {
    const { t } = useTranslation();

    return (
        <div className="ag-cell-label-container" style={{ flexDirection: "row" }}>
            {t("tracker.moveStudents.moveToTier")}
        </div>
    );
};
