import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const GROUP_CALL_CLASS_LIST_QUERY = "groupCallClassListQuery";

export interface GroupCallClassListItem {
    yearGroup: number;
    hasManualClasses: boolean;
    classes: {
        id: number;
        name: string;
        synchronized: boolean;
        selected?: boolean;
    }[];
}

export const useGroupCallClassList = (specificationId: number | "") => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [GROUP_CALL_CLASS_LIST_QUERY, schoolAccountId, specificationId],
        queryFn: async (): Promise<GroupCallClassListItem[] | null> => {
            if (specificationId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/group-call/specification/${specificationId}/year-groups/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
