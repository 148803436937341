import { useState } from "react";
import Dialog from "src/components/Dialog";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import {
    Select,
    MenuItem,
    Button,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Grid,
    CircularProgress,
    Theme,
    InputLabelProps,
} from "@mui/material";
import { KS4ViewGrades } from "../../api/KS4Grade/getValues";
import { makeStyles } from "@mui/styles";

export enum AnalysisForecastDataSources {
    ALL = "all",
    ENMA = "en-ma",
    EBACC = "ebacc",
    OPEN = "open",
}

const useStylesInputLabel = makeStyles((theme: Theme) => ({
    root: {
        fontSize: theme.typography.pxToRem(11),
        paddingRight: theme.spacing(2),
        top: "auto",
        left: "auto",
        transform: "none",
    },
}));

function CustomInputLabel(props: InputLabelProps) {
    const classes = useStylesInputLabel();

    return <InputLabel classes={classes} {...props} />;
}

const LoadForecastModal = ({
    open,
    handleClose,
    isForecastLoading,
}: {
    open: boolean;
    isForecastLoading: boolean;
    handleClose: (
        confirm: boolean,
        selectedDataSource?: AnalysisForecastDataSources,
        selectedSource?: KS4ViewGrades,
    ) => void;
}) => {
    const { t }: { t: TFunction } = useTranslation();
    const [selectedDataSource, setSelectedDataSource] = useState<AnalysisForecastDataSources>(
        AnalysisForecastDataSources.ALL,
    );
    const [selectedSource, setSelectedSource] = useState<KS4ViewGrades>(KS4ViewGrades.PROJECTED);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            title={t("analysis.ks4Grade.loadData")}
            actions={
                <>
                    <Button
                        color="primary"
                        disabled={isForecastLoading}
                        onClick={() => handleClose(true, selectedDataSource, selectedSource)}
                    >
                        {t("common.continue")}
                        {isForecastLoading && (
                            <Box ml={2}>
                                <CircularProgress size={18} />
                            </Box>
                        )}
                    </Button>
                    <Button disabled={isForecastLoading} onClick={() => handleClose(false)}>
                        {t("common.cancel")}
                    </Button>
                </>
            }
        >
            <Typography gutterBottom>{t("analysis.ks4Grade.copyHint")}</Typography>
            <Typography gutterBottom>
                <Box component="strong">{t("analysis.ks4Grade.copyHint2")}</Box>
            </Typography>
            <Grid container spacing={4}>
                <Grid item sm={5}>
                    <FormControl fullWidth margin="normal">
                        <CustomInputLabel id="dataSource">
                            {t("analysis.ks4Grade.dataSource")}
                        </CustomInputLabel>
                        <Select
                            labelId="dataSource"
                            fullWidth
                            value={selectedDataSource}
                            onChange={e =>
                                setSelectedDataSource(e.target.value as AnalysisForecastDataSources)
                            }
                        >
                            {Object.keys(AnalysisForecastDataSources).map(key => {
                                return (
                                    <MenuItem key={key} value={AnalysisForecastDataSources[key]}>
                                        <em>
                                            {t(
                                                `analysis.ks4Grade.dataSource${AnalysisForecastDataSources[
                                                    key
                                                ].replace("-", "")}`,
                                            )}
                                        </em>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <CustomInputLabel id="source">
                            {t("analysis.ks4Grade.source")}
                        </CustomInputLabel>
                        <Select
                            labelId="source"
                            fullWidth
                            value={selectedSource}
                            onChange={e => setSelectedSource(e.target.value as KS4ViewGrades)}
                        >
                            <MenuItem value={KS4ViewGrades.PROJECTED}>
                                {t("analysis.ks4Grade.sourceProjectedGrade")}
                            </MenuItem>
                            <MenuItem value={KS4ViewGrades.CURRENT}>
                                {t("analysis.ks4Grade.sourceCurrentGrade")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default LoadForecastModal;
