import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const PROFILE_QUERY = "profileQuery";

export interface ProfileResponse {
    phone: string;
    mobile: string;
    userHash: string;
    numberOfClasses: number;
    school: {
        id: number;
        name: string;
    };
    availableToAllSubjectAreas: true;
    availableSubjectAreas: {
        colour: string;
        id: number;
        name: string;
    }[];

    leaderOfSubjectAreas: {
        colour: string;
        id: number;
        name: string;
    }[];
    accessToAnalysisTypes: string[];
    status: string;
    email: string;
    title: string;
    userRole: string;
    schoolRole: string;
    isLeaderOfAll: true;
    isTeacherOfAll: true;
    firstName: string;
    lastName: string;
    id: number;
    schoolTitle: string;
    jobTitle: string;
    userCode: string;
}

export const useProfile = (schoolAccount?: number) => {
    const schoolAccountId = schoolAccount || getSchoolAccountId() || null;

    return useQuery({
        queryKey: [PROFILE_QUERY, parseInt(schoolAccountId)],
        queryFn: async (): Promise<ProfileResponse | null> => {
            if (schoolAccountId) {
                const { data } = await axiosInstance.get(`school/${schoolAccountId}/profile/`);
                return data || null;
            }
            return null;
        },
    });
};
