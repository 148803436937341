import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_STUDENT_FORMS_QUERY = "classTrackerGroupStudentFormsHeader";

export const useGroupStudentsForms = (groupId: number, tier: string) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CLASS_TRACKER_GROUP_STUDENT_FORMS_QUERY, schoolAccountId, groupId, tier],
        queryFn: async (): Promise<string[] | null> => {
            if (groupId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker-group/${groupId}/${tier || "-"}/student-forms/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
