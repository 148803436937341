import { ActionsUnion, Keys } from "@martin_hotell/rex-tils";
import { processEndpoints } from "src/api/helpers";
import { Modules } from "src/types";
import {
    SubscriptionActionsTypes,
    SubscriptionApiEndpoints,
    SubscriptionRawActions,
} from "./Subscription/actions";
import { InvoiceActionsTypes, InvoiceApiEndpoints, InvoiceRawActions } from "./Invoice/actions";
import { PaymentActionsTypes, PaymentApiEndpoints, PaymentRawActions } from "./Payment/actions";

export const SubscriptionsActionTypes = {
    ...SubscriptionActionsTypes,
    ...InvoiceActionsTypes,
    ...PaymentActionsTypes,
};

const _endpoints = {
    ...SubscriptionApiEndpoints,
    ...InvoiceApiEndpoints,
    ...PaymentApiEndpoints,
};

export type SubscriptionsEndpointKeys = Keys<typeof _endpoints>;

export const SubscriptionsEndpoints = processEndpoints<SubscriptionsEndpointKeys>(
    Modules.SUBSCRIPTION,
    _endpoints,
);

export const SubscriptionsActions = {
    ...SubscriptionRawActions(SubscriptionsEndpoints),
    ...InvoiceRawActions(SubscriptionsEndpoints),
    ...PaymentRawActions(SubscriptionsEndpoints),
};

export type SubscriptionsActions = ActionsUnion<typeof SubscriptionsActions>;
