import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import { getSchoolAccountId } from "src/services/url";

const apiImportTemplateStudent = async (): Promise<AxiosResponse> => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/student/import-template/`,
        {},
        { responseType: "blob" },
    );
};

export const useStudentImportTemplateMutation = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: () => apiImportTemplateStudent(),
        onSuccess: res => {
            FileSaver.saveAs(res.data, `Pupil Progress Student List Upload.xlsx`);
        },
        onError: (err: AxiosError<{ error: string }>) => {
            enqueueSnackbar(
                err.response.data?.error ||
                    t("students.list.errorDownloadingStudentImportTemplate"),
                {
                    ...SnackbarErrorOptions,
                },
            );
        },
    });
};
