import { ActionsUnion, Keys } from "@martin_hotell/rex-tils";
import { processEndpoints } from "src/api/helpers";
import { Modules } from "src/types";
import {
    ClassTrackerActionsTypes,
    ClassTrackerApiEndpoints,
    ClassTrackerRawActions,
} from "./ClassTracker/actions";
import {
    ClassTrackerGroupActionsTypes,
    ClassTrackerGroupApiEndpoints,
    ClassTrackerGroupRawActions,
} from "./ClassTrackerGroup/actions";
import { ForecastActionsTypes, ForecastApiEndpoints, ForecastRawActions } from "./Forecast/actions";
import { SnapshotActionsTypes, SnapshotApiEndpoints, SnapshotRawActions } from "./Snapshot/actions";

export const TrackerActionTypes = {
    ...ClassTrackerActionsTypes,
    ...ClassTrackerGroupActionsTypes,
    ...ForecastActionsTypes,
    ...SnapshotActionsTypes,
};

const _endpoints = {
    ...ClassTrackerApiEndpoints,
    ...ClassTrackerGroupApiEndpoints,
    ...ForecastApiEndpoints,
    ...SnapshotApiEndpoints,
};

export type TrackerEndpointKeys = Keys<typeof _endpoints>;

export const TrackerEndpoints = processEndpoints<TrackerEndpointKeys>(Modules.TRACKER, _endpoints);

export const TrackerActions = {
    ...ClassTrackerRawActions(TrackerEndpoints),
    ...ClassTrackerGroupRawActions(TrackerEndpoints),
    ...ForecastRawActions(TrackerEndpoints),
    ...SnapshotRawActions(TrackerEndpoints),
};

export type TrackerActions = ActionsUnion<typeof TrackerActions>;
