import AuthContainer from "src/components/AuthContainer";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useMergeAccount } from "../hooks/useMergeAccount";
import { Link } from "react-router-dom";
import { ROUTE_AUTH_LOGIN } from "src/routes";

const MergeAccountContainer = () => {
    const { t } = useTranslation();

    const { mutate: mergeAccount, isSuccess } = useMergeAccount();
    const params = useParams();
    useEffect(() => {
        mergeAccount(params.hash);
    }, [mergeAccount, params.hash]);

    return (
        <AuthContainer>
            {isSuccess && <Typography gutterBottom>{t("auth.mergeAccount.header")}</Typography>}
            <Button component={Link} to={ROUTE_AUTH_LOGIN} variant="contained" color="primary">
                {t("auth.mergeAccount.signIn")}
            </Button>
        </AuthContainer>
    );
};

export default MergeAccountContainer;
