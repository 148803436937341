import { ofType } from "@martin_hotell/rex-tils";
import { NotificationActions } from "src/notifications/store/actions";
import { StudentModelActions } from "src/orm/models/Student/actions";
import FileSaver from "file-saver";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ClassActions, ClassActionTypes } from "../actions";
import { HTTP_NO_CONTENT } from "src/config/globals";
import { MultipleClassesRequest } from "../../api/Classes/multipleClasses";
import { OrmClassTrackerActions } from "src/orm/models/ClassTracker/actions";
import { TrackerActions } from "src/modules/tracker/store/actions";
import { OrmClassTrackerGroupActions } from "src/orm/models/ClassTrackerGroup/actions";

const getLastUpdatedClassTrackerStatusesSuccessEpic = (
    action$: ActionsObservable<TrackerActions>,
) =>
    action$.pipe(
        ofType(ClassActionTypes.GET_LAST_UPDATED_CLASS_TRACKERS_SUCCESS),
        map(
            (action: ReturnType<typeof ClassActions.getLastUpdatedClassTrackersSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(OrmClassTrackerGroupActions.updateStatus(payload.response))),
    );

const getClassTrackerListSuccessEpic = (action$: ActionsObservable<TrackerActions>) =>
    action$.pipe(
        ofType(ClassActionTypes.GET_CLASS_TRACKER_GROUP_LIST_SUCCESS),
        map(
            (action: ReturnType<typeof ClassActions.getClassTrackerGroupListSuccess>) =>
                action.payload,
        ),
        mergeMap(payload =>
            of(
                OrmClassTrackerGroupActions.bulkImport(
                    payload.response,
                    payload.request.payload.params.cohort,
                ),
            ),
        ),
    );

const getImportStudentsTemplateSuccessEpic = (action$: ActionsObservable<ClassActions>) =>
    action$.pipe(
        ofType(ClassActionTypes.GET_IMPORT_STUDENTS_TEMPLATE_SUCCESS),
        map(
            (action: ReturnType<typeof ClassActions.getImportStudentsTemplateSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            FileSaver.saveAs(payload.response, `Pupil Progress Add a single class.xlsx`);
            return of(
                NotificationActions.enqueueSnackbar({
                    message: "Template downloaded",
                    variant: "success",
                }),
            );
        }),
    );

const getMultipleClassesTemplateSuccessEpic = (action$: ActionsObservable<ClassActions>) =>
    action$.pipe(
        ofType(ClassActionTypes.GET_IMPORT_MULTIPLE_CLASSES_TEMPLATE_SUCCESS),
        map(
            (action: ReturnType<typeof ClassActions.getImportMultipleClassesTemplateSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            FileSaver.saveAs(payload.response, `Pupil Progress Multiple classes template.xlsx`);
            return of(
                NotificationActions.enqueueSnackbar({
                    message: "Template downloaded",
                    variant: "success",
                }),
            );
        }),
    );

const getStudentsByClassSuccessEpic = (action$: ActionsObservable<ClassActions>) =>
    action$.pipe(
        ofType(ClassActionTypes.GET_STUDENTS_BY_CLASS_SUCCESS),
        map((action: ReturnType<typeof ClassActions.getStudentsByClassSuccess>) => action.payload),
        mergeMap(payload => {
            return of(
                StudentModelActions.bulkImport(payload.response),
                NotificationActions.enqueueSnackbar({
                    message: "Students imported",
                    variant: "success",
                }),
            );
        }),
    );

const getStudentsByGroupSuccessEpic = (action$: ActionsObservable<ClassActions>) =>
    action$.pipe(
        ofType(ClassActionTypes.GET_STUDENTS_BY_GROUP_SUCCESS),
        map((action: ReturnType<typeof ClassActions.getStudentsByGroupSuccess>) => action.payload),
        mergeMap(payload => {
            return of(
                StudentModelActions.bulkImport(payload.response),
                NotificationActions.enqueueSnackbar({
                    message: "Students imported",
                    variant: "success",
                }),
            );
        }),
    );

const createMultipleClassesSuccessEpic = (action$: ActionsObservable<ClassActions>) =>
    action$.pipe(
        ofType(ClassActionTypes.CREATE_MULTIPLE_CLASSES_SUCCESS),
        map(
            (action: ReturnType<typeof ClassActions.createMultipleClassesSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => {
            if (payload.status === HTTP_NO_CONTENT) {
                return of(ClassActions.clearImportDatabaseError());
            }

            return of(
                ClassActions.setImportDatabaseError(payload.response as any),
                ClassActions.setImportDatabaseErrorFile(
                    payload.request.payload.values as MultipleClassesRequest,
                ),
            );
        }),
    );

const clearStudentsEpic = (action$: ActionsObservable<ClassActions>) =>
    action$.pipe(
        ofType(ClassActionTypes.CLEAR_STUDENTS),
        mergeMap(() => of(StudentModelActions.clear())),
    );

const getClassDetailsSuccessEpic = (action$: ActionsObservable<ClassActions>) =>
    action$.pipe(
        ofType(ClassActionTypes.GET_CLASS_DETAILS_SUCCESS),
        map((action: ReturnType<typeof ClassActions.getClassDetailsSuccess>) => action.payload),
        mergeMap(payload => of(OrmClassTrackerActions.upsert(payload.response))),
    );

const removeClassSuccessEpic = (action$: ActionsObservable<ClassActions>) =>
    action$.pipe(
        ofType(ClassActionTypes.REMOVE_CLASS_SUCCESS),
        mergeMap(() => of(TrackerActions.clearClassTracker())),
    );

export const classesEpics = combineEpics(
    getImportStudentsTemplateSuccessEpic,
    getMultipleClassesTemplateSuccessEpic,
    getStudentsByClassSuccessEpic,
    getStudentsByGroupSuccessEpic,
    createMultipleClassesSuccessEpic,
    clearStudentsEpic,
    getClassDetailsSuccessEpic,
    removeClassSuccessEpic,
    getClassTrackerListSuccessEpic,
    getLastUpdatedClassTrackerStatusesSuccessEpic,
);
