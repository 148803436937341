import React, { createContext, Dispatch, useContext } from "react";

export interface SnapshotData {
    compare: { dataSource1: string; dataSource2: string } | null;
    snapshot: string | null;
}

const TrackerStateContext = createContext({});
const TrackerDispatchContext = createContext({});

interface TrackerState {
    snapshotData: SnapshotData | null;
}

const initialState: TrackerState = {
    snapshotData: null,
};

export const TrackerActionTypes = {
    SET_SNAPSHOT_DATA: "setSnapshotData",
    CLEAR_SNAPSHOT_DATA: "clearSnapshotData",
};

const userReducer = (state, action) => {
    switch (action.type) {
        case TrackerActionTypes.SET_SNAPSHOT_DATA: {
            return { ...state, snapshotData: action.payload };
        }
        case TrackerActionTypes.CLEAR_SNAPSHOT_DATA: {
            return { ...state, snapshotData: null };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const TrackerStateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(userReducer, initialState);

    return (
        <TrackerStateContext.Provider value={state}>
            <TrackerDispatchContext.Provider value={dispatch}>
                {children}
            </TrackerDispatchContext.Provider>
        </TrackerStateContext.Provider>
    );
};

const useTrackerState = () => {
    const trackerState = React.useContext(TrackerStateContext);

    if (trackerState === undefined) {
        throw new Error("useOverlayState must be used within a OverlayProvider");
    }

    return trackerState;
};

const useTrackerStateDispatch = () => {
    const dispatch = useContext(TrackerDispatchContext);

    if (dispatch === undefined) {
        throw new Error("useTrackerStateDispatch must be used within a TrackerStateProvider");
    }

    return dispatch;
};

const useTrackerContext = () => {
    const dispatch = useTrackerStateDispatch() as Dispatch<any>;
    const trackerState = useContext(TrackerStateContext);

    const setSnapshotData = (data: any) => {
        dispatch({
            type: TrackerActionTypes.SET_SNAPSHOT_DATA,
            payload: data,
        });
    };

    const clearSnapshotData = () => {
        dispatch({
            type: TrackerActionTypes.CLEAR_SNAPSHOT_DATA,
        });
    };

    return { setSnapshotData, clearSnapshotData, trackerState } as {
        setSnapshotData: (data: SnapshotData) => void;
        clearSnapshotData: () => void;
        trackerState: TrackerState;
    };
};

export { TrackerStateProvider, useTrackerState, useTrackerStateDispatch, useTrackerContext };
