import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ClassTrackerModel } from "src/modules/tracker/models/ClassTrackerModel";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_DETAILS_QUERY = "classDetailsQuery";

export type ClassDetailsResponse = ClassTrackerModel;

export const useClassDetails = (id: number) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [CLASS_DETAILS_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<ClassDetailsResponse | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker/${id}/`,
                );

                return data;
            }
            return null;
        },
    });
};
