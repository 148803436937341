import { TFunction } from "i18next";
import { ColumnConfigTypes, NodeTypes, getFlatHeaderStructure } from "./headerGenerator";
import { GradeTypes } from "src/orm/models/Grade";
import { UnitTypes } from "src/orm/models/ClassTracker";
import {
    ClassTrackerFlatHeader,
    ClassTrackerHeaderObject,
    ColumnTypeGradeTypes,
} from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";
import { absenceValuesArray } from "./valueHandler";

interface RowNode {
    [key: string]: any;
}

// selectable mark types
const selectableMarkTypes = [
    ColumnConfigTypes.SELECTABLE,
    ColumnConfigTypes.SINGLE_TEXT_CONVERSION,
    ColumnConfigTypes.MULTI_TEXT_CONVERSION,
] as string[];

const simpleColumnTypes = ["mark", "number", "average", "distance"];
const gradeColumnTypes = ["grade"];
const preciseRoundTypes = ["p8-score", "pmv"];

// fields to omit
const omitColumnTypes = [ColumnConfigTypes.MTG];

export const roundPrecised = (number: number, precision = 2) => {
    const power = Math.pow(10, precision);
    return Math.round(number * power) / power;
};

export const getAverageColumnList = (
    rowsData: any[] | null,
    classTrackerHeader: ClassTrackerHeaderObject | null,
) => {
    const averageRow = {};
    if (classTrackerHeader?.columns && rowsData && rowsData[0]) {
        const { columns } = classTrackerHeader;
        Object.keys(rowsData[0]).forEach(key => {
            const columnConfigType = columns[key]?.columnConfig?.type || null;
            if (
                key.includes("column", 0) &&
                columnConfigType !== null &&
                !(omitColumnTypes as string[]).includes(columnConfigType)
            )
                averageRow[key] = null;
        });
    }

    return averageRow;
};

// not calculated average row
export const prepareEmptyAverageRow = (
    rowsData: {}[],
    classTrackerHeader: ClassTrackerHeaderObject | null,
) => {
    const averageRow = {};
    if (classTrackerHeader?.columns) {
        const { columns } = classTrackerHeader;

        // process only first row (average row)
        if (rowsData && rowsData[0]) {
            Object.keys(rowsData[0]).forEach(key => {
                const columnConfigType = columns[key]?.columnConfig?.type || null;

                if (
                    key.includes("column", 0) &&
                    columnConfigType !== null &&
                    !(omitColumnTypes as string[]).includes(columnConfigType)
                ) {
                    averageRow[key] = { value: null, data: [], counter: 0, round: null };
                }
            });
        }
    }

    return averageRow;
};

// process only not empty "columns" with columnConfig
const canProcessColumn = (key: string, row: RowNode, columnConfigType: string | null): boolean =>
    key.indexOf("column", 0) === 0 &&
    row[key] !== null &&
    row[key] !== "" &&
    columnConfigType !== null &&
    !(omitColumnTypes as string[]).includes(columnConfigType);

// get only dropdown type fields
const getSelectableColumns = (
    columnConfigType: string | null,
    selectableColumns: string[],
    currentHash: string,
) => {
    let selectableMarkValueType = false;
    // add columns to selectable field array
    if (columnConfigType) {
        selectableMarkValueType = selectableMarkTypes.includes(columnConfigType || "");
        if (selectableMarkValueType === true) {
            selectableColumns.push(currentHash);
        }
    }
    return selectableMarkValueType;
};

// resolve grades for current grade average
const resolveCurrentGradeAverage = (
    key: string,
    value: number | string | null,
    flatHeader: ClassTrackerFlatHeader[] | null,
    relatedData: ClassTrackerRelatedObject | null,
    columnTypeGradeType?: ColumnTypeGradeTypes | null,
) => {
    const gradeType = relatedData?.gradeType.type;
    const unitType = flatHeader?.find(fh => fh.field === key)?.parentUnitType;
    const hasCourseLevel = !!relatedData?.courseGrades?.length;

    if (!unitType) {
        return null;
    }

    if (gradeType === GradeTypes.COMBINED) {
        if (unitType && unitType === UnitTypes.STANDARD && relatedData?.grades) {
            const option = relatedData?.grades?.find(g => g.id === value);
            return option ? option.name : null;
        }
        if (unitType && unitType === UnitTypes.SUMMARY && relatedData?.courseGrades) {
            const option = relatedData?.courseGrades?.find(g => g.id === value);
            return option ? option.name : null;
        }
    } else {
        if (
            columnTypeGradeType &&
            columnTypeGradeType === ColumnTypeGradeTypes.UNIT &&
            relatedData?.grades
        ) {
            const gradeValue = relatedData.grades.find(g => g.id === value);

            return gradeValue?.name || null;
        }
        if (
            columnTypeGradeType &&
            columnTypeGradeType === ColumnTypeGradeTypes.COURSE &&
            relatedData?.courseGrades
        ) {
            const gradeValue = relatedData.courseGrades.find(g => g.id === value);
            return gradeValue?.name || null;
        }

        // this should be removed
        if (hasCourseLevel && value) {
            const option = relatedData?.courseGrades?.find(g => g.id === value);
            return option ? option.name : null;
        } else {
            const option = relatedData?.grades?.find(g => g.id === value);
            return option ? option.name : null;
        }
    }

    return null;
};

// main generate average function
export const generateAverageRow = (
    rowsData: {}[],
    classTrackerHeader: ClassTrackerHeaderObject | null,
    classTrackerRelated: ClassTrackerRelatedObject | null,
    firstColumnKey: string | null,
    t: TFunction,
) => {
    const averageRow: RowNode = prepareEmptyAverageRow(rowsData, classTrackerHeader);

    const resultAverage = getAverageColumnList(rowsData, classTrackerHeader);
    const flatHeader = getFlatHeaderStructure(classTrackerHeader);
    const selectableColumns: string[] = [];

    if (classTrackerHeader && rowsData.length > 0 && classTrackerHeader.columns) {
        const columns = classTrackerHeader.columns;

        rowsData.forEach(row => {
            Object.keys(row).forEach(key => {
                const columnConfigType = columns[key]?.columnConfig?.type || null;

                if (!canProcessColumn(key, row, columnConfigType)) {
                    return;
                }

                const selectableMarkValueType = getSelectableColumns(
                    columnConfigType,
                    selectableColumns,
                    columns[key].hash,
                );

                // mark types + numeric types
                if (
                    simpleColumnTypes.includes(columns[key].columnType) &&
                    !selectableMarkValueType
                ) {
                    if (
                        !(
                            ((columnConfigType &&
                                (columnConfigType === ColumnConfigTypes.PROJECTED_GRADE ||
                                    columnConfigType === ColumnConfigTypes.CURRENT_GRADE ||
                                    columnConfigType === ColumnConfigTypes.OTHER_GRADE ||
                                    columnConfigType === ColumnConfigTypes.TEACHER_JUDGEMENT ||
                                    columnConfigType === ColumnConfigTypes.BTEC_PROJECTED_GRADE)) ||
                                columns[key].columnConfig?.type === NodeTypes.CUSTOM_FIELD) &&
                            row[key] < 0
                        )
                    ) {
                        averageRow[key] = {
                            value:
                                averageRow[key] && averageRow[key].value
                                    ? averageRow[key].value +
                                      (isNaN(row[key]) ? 0 : parseFloat(row[key]))
                                    : parseFloat(row[key]),
                            data: row[key],
                            counter: averageRow[key]
                                ? isNaN(row[key])
                                    ? averageRow[key].counter
                                    : averageRow[key].counter + 1
                                : 1,
                            columnType: columns[key].columnConfig?.type,
                        };
                    }
                }

                // mark types selectable + text conversion
                if (
                    simpleColumnTypes.includes(columns[key].columnType) &&
                    selectableMarkValueType
                ) {
                    const options: any[] = columns[key].columnConfig?.typeConfig?.choices || [];
                    const value = options.find(o => o.option === `${row[key]}`);

                    if (value) {
                        averageRow[key] = {
                            value: averageRow[key]
                                ? averageRow[key].value + parseInt(value.value)
                                : parseInt(value.value),
                            data:
                                averageRow[key] && averageRow[key].data
                                    ? averageRow[key].data.concat(value.value)
                                    : value.value,
                            counter: averageRow[key] ? averageRow[key].counter + 1 : 1,
                        };
                    }
                }

                // grade types
                if (gradeColumnTypes.includes(columns[key].columnType) && averageRow[key]) {
                    const rowData = row[key];
                    if (!absenceValuesArray.includes(`${rowData}`)) {
                        averageRow[key].value = averageRow[key]
                            ? averageRow[key].value + parseInt(rowData)
                            : parseInt(rowData);
                        averageRow[key].data.push(rowData);
                        averageRow[key].counter = averageRow[key] ? averageRow[key].counter + 1 : 1;
                    }
                }
            });
        });

        // process averageRow temp array
        if (Object.keys(averageRow).length > 0) {
            Object.keys(resultAverage).forEach(key => {
                let value = Math.round(averageRow[key].value / averageRow[key].counter);

                if (preciseRoundTypes.includes(averageRow[key].columnType)) {
                    value = roundPrecised(
                        parseFloat(averageRow[key].value) / averageRow[key].counter,
                        2,
                    );
                }

                if (selectableColumns.includes(key)) {
                    let options: any[] = [];
                    if (columns[key].columnConfig?.typeConfig?.choices) {
                        options = [...(columns[key].columnConfig?.typeConfig?.choices as any[])];
                    }

                    options.sort((a, b) => (a.value > b.value ? 1 : -1));
                    const option = options.find(o => o.value >= value);

                    if (option) resultAverage[key] = { value, displayValue: option.option } || null;
                } else {
                    // handling current grade
                    if (
                        columns[key].columnConfig?.type === ColumnConfigTypes.CURRENT_GRADE ||
                        columns[key].columnConfig?.type === ColumnConfigTypes.PROJECTED_GRADE ||
                        columns[key].columnConfig?.type === ColumnConfigTypes.OTHER_GRADE ||
                        columns[key].columnConfig?.type === ColumnConfigTypes.BTEC_GRADE ||
                        columns[key].columnConfig?.type ===
                            ColumnConfigTypes.BTEC_PROJECTED_GRADE ||
                        columns[key].columnConfig?.type === ColumnConfigTypes.TEACHER_JUDGEMENT
                    ) {
                        if (isNaN(value)) {
                            resultAverage[key] = null;
                        } else {
                            resultAverage[key] = {
                                value,
                                displayValue: resolveCurrentGradeAverage(
                                    key,
                                    value,
                                    flatHeader,
                                    classTrackerRelated,
                                    columns[key].columnConfig?.typeConfig?.gradeType,
                                ),
                            };
                        }
                    } else {
                        resultAverage[key] = isNaN(value) ? null : { value, displayValue: value };
                    }
                }
                const currentHeader = flatHeader?.find(({ field }) => field === key);
                if (currentHeader?.parentUnitType === "atl") {
                    const altConfigsList = classTrackerRelated?.atlConfigs;
                    const columnName = currentHeader?.name;
                    const customField =
                        altConfigsList?.length > 0
                            ? altConfigsList.find(atl => atl.fieldType === columnName)
                            : null;

                    resultAverage[key + "-display"] =
                        customField && resultAverage?.[key]?.value
                            ? {
                                  value: customField?.values.length - resultAverage[key].value,
                                  displayValue:
                                      customField.values[
                                          customField.values.length - resultAverage[key].value
                                      ],
                              }
                            : { value: "" };
                }
            });
        }

        resultAverage[firstColumnKey || ""] = t("tracker.grid.averageRow");
    }

    return resultAverage;
};
