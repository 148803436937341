import { Keys, createAction } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { getSubjectAreaListEndpoint } from "../../api/SubjectArea/getList";
import {
    getSubjectAreaUsersListEndpoint,
    SubjectAreaUsersListResponse,
} from "../../api/SubjectArea/getUsers";
import { CommonSubjectAreasResponse } from "src/modules/common/api/subjectAreas";
import { setLeaderEndpoint } from "../../api/SubjectArea/setLeader";
import { setTeacherEndpoint } from "../../api/SubjectArea/setTeacher";
import { removeLeaderEndpoint } from "../../api/SubjectArea/removeLeader";
import { removeTeacherEndpoint } from "../../api/SubjectArea/removeTeacher";
import { SubjectParam } from "src/forms/types";

export enum SubjectAreaActionsTypes {
    GET_SUBJECT_AREA_LIST = "[subject area] GET SUBJECT AREA LIST",
    GET_SUBJECT_AREA_LIST_SUCCESS = "[subject area] GET SUBJECT AREA LIST SUCCESS",
    GET_SUBJECT_AREA_USERS_LIST = "[subject area] GET SUBJECT AREA USERS LIST",
    GET_SUBJECT_AREA_USERS_LIST_SUCCESS = "[subject area] GET SUBJECT AREA USERS LIST SUCCESS",
    SET_SUBJECT_AREA_USERS = "[subject area] SET SUBJECT AREA USERS",
    SET_SUBJECT_AREA_FROM_SUBSCRIPTIONS = "[subject area] SET SUBJECT AREA FROM SUBSCRIPTIONS",
    SET_LEADER = "[subject area] SET LEADER",
    SET_LEADER_SUCCESS = "[subject area] SET LEADER SUCCESS",
    SET_TEACHER = "[subject area] SET TEACHER",
    SET_TEACHER_SUCCESS = "[subject area] SET TEACHER SUCCESS",
    REMOVE_LEADER = "[subject area] REMOVE LEADER",
    REMOVE_LEADER_SUCCESS = "[subject area] REMOVE LEADER SUCCESS",
    REMOVE_TEACHER = "[subject area] REMOVE TEACHER",
    REMOVE_TEACHER_SUCCESS = "[subject area] REMOVE TEACHER SUCCESS",
}

export const SubjectAreaApiEndpoints = {
    getSubjectAreaList: createActionObject(
        getSubjectAreaListEndpoint,
        SubjectAreaActionsTypes.GET_SUBJECT_AREA_LIST_SUCCESS,
    ),
    getSubjectAreaUsersList: createActionObject(
        getSubjectAreaUsersListEndpoint,
        SubjectAreaActionsTypes.GET_SUBJECT_AREA_USERS_LIST_SUCCESS,
    ),
    setLeader: createActionObject(setLeaderEndpoint, SubjectAreaActionsTypes.SET_LEADER_SUCCESS),
    setTeacher: createActionObject(setTeacherEndpoint, SubjectAreaActionsTypes.SET_TEACHER_SUCCESS),
    removeLeader: createActionObject(
        removeLeaderEndpoint,
        SubjectAreaActionsTypes.REMOVE_LEADER_SUCCESS,
    ),
    removeTeacher: createActionObject(
        removeTeacherEndpoint,
        SubjectAreaActionsTypes.REMOVE_TEACHER_SUCCESS,
    ),
};

export const SubjectAreaRawActions = (
    SubjectAreaEndpoints: Record<Keys<typeof SubjectAreaApiEndpoints>, ApiActionMeta>,
) => ({
    getSubjectAreaList: () =>
        createApiAction(
            SubjectAreaActionsTypes.GET_SUBJECT_AREA_LIST,
            undefined,
            SubjectAreaEndpoints.getSubjectAreaList,
        ),
    getSubjectAreaListSuccess: (payload: ApiCallbackPayload<CommonSubjectAreasResponse>) =>
        createAction(SubjectAreaActionsTypes.GET_SUBJECT_AREA_LIST_SUCCESS, payload),
    getSubjectAreaUsersList: (subjectArea: number) =>
        createApiAction(
            SubjectAreaActionsTypes.GET_SUBJECT_AREA_USERS_LIST,
            { values: {}, params: { subjectArea } },
            SubjectAreaEndpoints.getSubjectAreaUsersList,
        ),
    getSubjectAreaUsersListSuccess: (
        payload: ApiCallbackPayload<SubjectAreaUsersListResponse, {}, SubjectParam>,
    ) => createAction(SubjectAreaActionsTypes.GET_SUBJECT_AREA_USERS_LIST_SUCCESS, payload),
    setLeader: (subjectArea: number, leader: number) =>
        createApiAction(
            SubjectAreaActionsTypes.SET_LEADER,
            { values: {}, params: { subjectArea, leader } },
            SubjectAreaEndpoints.setLeader,
        ),
    setLeaderSuccess: (
        payload: ApiCallbackPayload<SubjectAreaUsersListResponse, {}, SubjectParam>,
    ) => createAction(SubjectAreaActionsTypes.SET_LEADER_SUCCESS, payload),
    setTeacher: (subjectArea: number, teacher: number) =>
        createApiAction(
            SubjectAreaActionsTypes.SET_TEACHER,
            { values: {}, params: { subjectArea, teacher } },
            SubjectAreaEndpoints.setTeacher,
        ),
    setTeacherSuccess: (
        payload: ApiCallbackPayload<SubjectAreaUsersListResponse, {}, SubjectParam>,
    ) => createAction(SubjectAreaActionsTypes.SET_TEACHER_SUCCESS, payload),
    removeLeader: (subjectArea: number, leader: number) =>
        createApiAction(
            SubjectAreaActionsTypes.REMOVE_LEADER,
            { values: {}, params: { subjectArea, leader } },
            SubjectAreaEndpoints.removeLeader,
        ),
    removeLeaderSuccess: (
        payload: ApiCallbackPayload<SubjectAreaUsersListResponse, {}, SubjectParam>,
    ) => createAction(SubjectAreaActionsTypes.REMOVE_LEADER_SUCCESS, payload),
    removeTeacher: (subjectArea: number, teacher: number) =>
        createApiAction(
            SubjectAreaActionsTypes.REMOVE_TEACHER,
            { values: {}, params: { subjectArea, teacher } },
            SubjectAreaEndpoints.removeTeacher,
        ),
    removeTeacherSuccess: (
        payload: ApiCallbackPayload<SubjectAreaUsersListResponse, {}, SubjectParam>,
    ) => createAction(SubjectAreaActionsTypes.REMOVE_TEACHER_SUCCESS, payload),
    setSubjectAreaUsers: (payload: { data: SubjectAreaUsersListResponse; subjectArea: number }) =>
        createAction(SubjectAreaActionsTypes.SET_SUBJECT_AREA_USERS, payload),
    setSubjectAreaForSubscriptions: (payload: CommonSubjectAreasResponse) => {
        return createAction(SubjectAreaActionsTypes.SET_SUBJECT_AREA_FROM_SUBSCRIPTIONS, payload);
    },
});
