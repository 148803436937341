import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getStudentReportByStudentEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/student-report/{cohort}/{yearGroup}/by-student/`,
    method: ApiRequests.POST,
};

export interface StudentReportByStudentRequest {
    analysisStudents: { id: number }[];
    onlySummary: boolean;
}
