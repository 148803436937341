import { AttributeGroup, AttributeItem } from "src/orm/models/SpecificationAttribute";
import { TFunction } from "i18next";
import * as yup from "yup";
import {
    shouldDisableContainsAll,
    shouldDisableContainsAny,
    shouldDisableEquals,
} from "../../Common/attributesFormHelper";

export const attributeItemsSchema = (t: TFunction) =>
    yup.mixed().when(["attributes", "disabledItems"], ([attributes, disabledItems]) => {
        return yup.array().of(
            yup.lazy((item: AttributeItem) => {
                const itemValues = attributes.filter(
                    val =>
                        val.attributeItemId === item.id &&
                        (val.isSelected === true || val.isMandatory === true),
                );

                if (disabledItems && disabledItems.includes(item.id)) return yup.mixed();

                return yup
                    .object()
                    .test(
                        "itemsWeightTest",
                        `${t("class.addClass.toLowWeight")} (${item.validation.weightSelected} ${t(
                            "common.required",
                        )})`,
                        () => {
                            const valuesWeight = itemValues.reduce(
                                (prevVal, currVal) =>
                                    prevVal + (currVal.weight ? currVal.weight : 0),
                                0,
                            );
                            return !(
                                item.validation &&
                                item.validation.weightSelected &&
                                valuesWeight < item.validation.weightSelected
                            );
                        },
                    )
                    .test(
                        "itemsMinCountTest",
                        `${t("class.addClass.notEnought")} (${t("common.min")} ${
                            item.validation.minSelected
                        } ${t("common.required")})`,
                        () =>
                            !(
                                item.validation &&
                                item.validation.minSelected &&
                                itemValues.length < item.validation.minSelected
                            ),
                    )
                    .test(
                        "itemsMaxCountTest",
                        `${t("class.addClass.tooManyItems")} (${t("common.max")} ${
                            item.validation.maxSelected
                        } ${t("common.required")})`,
                        () =>
                            !(
                                item.validation &&
                                item.validation.maxSelected &&
                                itemValues.length > item.validation.maxSelected
                            ),
                    )
                    .test(
                        "itemsMinOtherTest",
                        `${t("class.addClass.notEnought")} (${t("common.min")} ${
                            item.minOtherSelected
                        } ${t("common.required")})`,
                        () => {
                            let shouldValidate = false;
                            if (item.validationRule && item.minOtherSelected) {
                                switch (item.validationRule.type) {
                                    case "equals":
                                        shouldValidate = !shouldDisableEquals(
                                            item.validationRule,
                                            attributes,
                                        );
                                        break;
                                    case "contains-any":
                                        shouldValidate = !shouldDisableContainsAny(
                                            item.validationRule,
                                            attributes,
                                        );
                                        break;
                                    case "contains-all":
                                        shouldValidate = !shouldDisableContainsAll(
                                            item.validationRule,
                                            attributes,
                                        );
                                        break;
                                }
                                if (shouldValidate) {
                                    return !(itemValues.length < item.minOtherSelected);
                                }
                            }

                            return true;
                        },
                    )
                    .test(
                        "itemsMaxOtherTest",
                        `${t("class.addClass.tooManyItems")} (${t("common.max")} ${
                            item.maxOtherSelected
                        } ${t("class.common.required")})`,
                        () => {
                            let shouldValidate = false;
                            if (item.validationRule && item.maxOtherSelected) {
                                switch (item.validationRule.type) {
                                    case "equals":
                                        shouldValidate = !shouldDisableEquals(
                                            item.validationRule,
                                            attributes,
                                        );
                                        break;
                                    case "contains-any":
                                        shouldValidate = !shouldDisableContainsAny(
                                            item.validationRule,
                                            attributes,
                                        );
                                        break;
                                    case "contains-all":
                                        shouldValidate = !shouldDisableContainsAll(
                                            item.validationRule,
                                            attributes,
                                        );
                                        break;
                                }
                                if (shouldValidate) {
                                    return !(itemValues.length > item.maxOtherSelected);
                                }
                            }

                            return true;
                        },
                    );
            }),
        );
    });

export const attributeGroupsSchema = (t: TFunction) =>
    yup
        .mixed()
        .when(["attributes", "items", "disabledItems"], ([attributes, items, disabledItems]) => {
            return yup.array().of(
                yup.lazy((group: AttributeGroup) => {
                    const groupItems = (items as AttributeItem[])
                        .filter(
                            item =>
                                item.attributeGroup &&
                                item.attributeGroup.id === group.id &&
                                !disabledItems?.includes(item.id),
                        )
                        .map(item => item.id);

                    const itemValues = attributes.filter(
                        value =>
                            groupItems.includes(value.attributeItemId) &&
                            (value.isSelected === true || value.isMandatory === true),
                    );
                    return yup
                        .object()
                        .test(
                            "groupWeightTest",
                            `${t("class.addClass.toLowWeight")} (${
                                group.validation.weightSelected
                            } ${t("common.required")})`,
                            () => {
                                if (itemValues.length > 0) {
                                    const itemValuesWeight = itemValues.reduce(
                                        (prevVal, currVal) =>
                                            prevVal + (currVal.weight ? currVal.weight : 0),
                                        0,
                                    );

                                    if (
                                        group.validation.weightSelected &&
                                        itemValuesWeight < group.validation.weightSelected
                                    ) {
                                        return false;
                                    }
                                }

                                return true;
                            },
                        )
                        .test(
                            "groupMinCountTest",
                            `${t("class.addClass.notEnought")} (${t("common.min")} ${
                                group.validation.minSelected
                            } ${t("common.required")})`,
                            () =>
                                !(
                                    itemValues.length > 0 &&
                                    group.validation &&
                                    group.validation.minSelected &&
                                    itemValues.length < group.validation.minSelected
                                ),
                        )
                        .test(
                            "groupMaxCountTest",
                            `${t("class.addClass.tooManyItems")} (${t("common.max")} ${
                                group.validation.maxSelected
                            } ${t("common.required")})`,
                            () =>
                                !(
                                    itemValues.length > 0 &&
                                    group.validation &&
                                    group.validation.maxSelected &&
                                    itemValues.length > group.validation.maxSelected
                                ),
                        );
                }),
            );
        });

export const editClassSchema = (t: TFunction) => {
    return yup.object().shape({
        attributes: yup.array(),
        disabledItems: yup.array(),
        items: attributeItemsSchema(t),
        groups: attributeGroupsSchema(t),
        name: yup.string().required(),
        students: yup
            .array()
            .min(1, t("class.addClass.studentsRequired"))
            .of(
                yup.object().shape({
                    firstName: yup
                        .string()
                        .required(t("common.form.validation.firstName.required")),
                    lastName: yup.string().required(t("common.form.validation.lastName.required")),
                }),
            ),
        yearGroup: yup
            .number()
            .required(t("class.addClass.yearGroupRequired"))
            .typeError(t("class.addClass.yearGroupType"))
            .moreThan(-1, t("class.addClass.yearGroupMinOne")),
        cohort: yup
            .number()
            .required(t("class.addClass.cohortRequired"))
            .typeError(t("class.addClass.cohortType"))
            .moreThan(0, t("class.addClass.cohortMinOne")),
    });
};

export const bulkEditClassSchema = (t: TFunction) => {
    return yup.object().shape({
        attributes: yup.array(),
        disabledItems: yup.array(),
        items: attributeItemsSchema(t),
        groups: attributeGroupsSchema(t),
    });
};

export const addDataSyncClassSchema = (t: TFunction) => {
    return yup.object().shape({
        attributes: yup.array(),
        disabledItems: yup.array(),
        items: attributeItemsSchema(t),
        groups: attributeGroupsSchema(t),
        yearGroups: yup.array().min(1),
    });
};

export const uploadClassSchema = (t: TFunction) => {
    return yup.object().shape({
        classToUploadData: yup.array(
            yup.object().shape({
                attributes: yup.array(),
                disabledItems: yup.array(),
                // fileNames: yup.array().min(1, t("class.addClass.minOneFile")),
                items: attributeItemsSchema(t),
                groups: attributeGroupsSchema(t),
                specification: yup
                    .number()
                    .required(t("class.addClass.specificationRequired"))
                    .typeError(t("class.addClass.specificationRequired"))
                    .label(t("common.specification")),
            }),
        ),
    });
};

export const createMultipleClassSchema = (t: TFunction) => {
    return yup.object().shape({
        cohort: yup
            .number()
            .required(t("class.addClass.cohortRequired"))
            .typeError(t("class.addClass.cohortType"))
            .moreThan(0, t("class.addClass.cohortMinOne")),
        attributes: yup.array(),
        disabledItems: yup.array(),
        items: attributeItemsSchema(t),
        groups: attributeGroupsSchema(t),
        file: yup.array().min(1, t("class.addClass.minOneFile")),
    });
};

export const createSingleClassSchema = (t: TFunction) => {
    return yup.object().shape({
        name: yup.string().required(),
        cohort: yup
            .number()
            .required(t("class.addClass.cohortRequired"))
            .typeError(t("class.addClass.cohortType"))
            .moreThan(0, t("class.addClass.cohortMinOne")),
        yearGroup: yup
            .number()
            .required(t("class.addClass.yearGroupRequired"))
            .typeError(t("class.addClass.yearGroupType"))
            .moreThan(-1, t("class.addClass.yearGroupMinOne")),
        attributes: yup.array(),
        disabledItems: yup.array(),
        students: yup
            .array()
            .min(1, t("class.addClass.studentsRequired"))
            .of(
                yup.object().shape({
                    firstName: yup
                        .string()
                        .required(t("common.form.validation.firstName.required")),
                    lastName: yup.string().required(t("common.form.validation.lastName.required")),
                }),
            ),
        items: attributeItemsSchema(t),
        groups: attributeGroupsSchema(t),
    });
};
