import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getLastUpdatedClassTrackersEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker/by-cohort/{cohort}/statuses/`,
    method: ApiRequests.GET,
};

interface LastUpdatedClassTrackersStatus {
    id: number;
    status: string;
}

export type LastUpdatedClassTrackerStatuses = LastUpdatedClassTrackersStatus[];
