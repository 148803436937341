import { Checkbox } from "@mui/material";

interface OwnProps {
    value: any;
    api;
    colDef;
    rowIndex;
    node;
}

const LinkDataRenderer = ({ value: firstValue, api: gridApi, colDef, node }: OwnProps) => {
    let value: number;
    let whileUpdate = false;

    if (typeof firstValue === "object" && firstValue !== null && firstValue?.value !== undefined) {
        value = firstValue.value;
        whileUpdate = true;
    } else {
        value = firstValue;
        whileUpdate = false;
    }
    const isAllSelected = gridApi?.getRenderedNodes()?.reduce((prev, curr) => {
        if (
            ((curr.data?.[colDef.field]?.["value"] !== undefined &&
                curr.data?.[colDef.field]?.["value"] !== 1) ||
                (curr.data?.[colDef.field]?.["value"] === undefined &&
                    curr.data?.[colDef.field] !== 1)) &&
            prev
        ) {
            return false;
        }

        return prev;
    }, true);

    return (
        <div
            className="ag-student-data-cell ag-student-data-cell--checkbox ag-student-data-cell--border-left"
            style={{ height: "100%" }}
        >
            <Checkbox
                disabled={true}
                checked={node.rowPinned ? isAllSelected : !!value}
                sx={{ p: 0.875 }}
            />
            {whileUpdate && (
                <span
                    className={`ag-value-cell-status${
                        whileUpdate ? " ag-value-cell-status--update" : ""
                    }`}
                />
            )}
        </div>
    );
};

export default LinkDataRenderer;
