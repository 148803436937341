import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload, ApiPayload } from "src/api/constants";
import { createActionObject, createApiAction, paramRequest } from "src/api/helpers";
import { getImportStudentsTemplateDownloadEndpoint } from "../../api/Classes/getImportStudentsTemplate";
import {
    getStudentsByClassEndpoint,
    StudentsByClassResponse,
} from "../../api/Classes/getStudentsByClass";
import { singleClassEndpoint, SingleClassRequest } from "../../api/Classes/singleClass";
import { uploadAndSendClassEndpoint, UploadClassRequest } from "../../api/Classes/uploadAndSend";
import { multipleClassesEndpoint, MultipleClassesRequest } from "../../api/Classes/multipleClasses";
import { getMultipleClassesTemplateEndpoint } from "../../api/Classes/getMultipleClassesTemplate";
import {
    getStudentsByGroupEndpoint,
    StudentsByGroupResponse,
} from "../../api/Classes/getStudentsByGroup";
import { CohortParam, FileType } from "src/forms/types";
import { demoClassEndpoint, DemoClassRequest } from "../../api/Classes/demoClass";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import { uploadAndSendClassFilesEndpoint } from "../../api/Classes/uploadAndSendFiles";
import { editClassEndpoint, EditClassRequest } from "../../api/Classes/editClass";
import { getClassDetailsEndpoint } from "../../api/Classes/getClassDetails";
import { removeClassTrackerEndpoint } from "../../api/Classes/removeClassTracker";
import { bulkEditClassesEndpoint, BulkEditClassesRequest } from "../../api/Classes/bulkEditClass";
import { unsyncClassTrackerEndpoint } from "../../api/Classes/unsyncClassTracker";
import {
    ClassTrackerListFilters,
    ClassTrackerListResponse,
    getClassTrackersEndpoint,
} from "../../api/Classes/getClassTrackers";
import {
    ClassTrackerFilterResponse,
    getClassTrackerFiltersEndpoint,
} from "../../api/Classes/filters";
import {
    getLastUpdatedClassTrackersEndpoint,
    LastUpdatedClassTrackerStatuses,
} from "../../api/Classes/getLastUpdated";
import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";

export enum ClassesActionsTypes {
    GET_CLASS_TRACKER_GROUP_LIST = "[class] GET CLASS TRACKER GROUP LIST",
    GET_CLASS_TRACKER_GROUP_LIST_SUCCESS = "[class] GET CLASS TRACKER GROUP LIST SUCCESS",
    GET_CLASS_TRACKER_FILTERS = "[class] GET CLASS TRACKER FILTERS",
    GET_CLASS_TRACKER_FILTERS_SUCCESS = "[class] GET CLASS TRACKER FILTERS SUCCESS",
    UPLOAD_AND_SEND_CLASS = "[class] UPLOAD AND SEND",
    UPLOAD_AND_SEND_CLASS_SUCCESS = "[class] UPLOAD AND SEND CLASS SUCCESS",
    UPLOAD_AND_SEND_CLASS_FILES = "[class] UPLOAD AND SEND FILES",
    UPLOAD_AND_SEND_CLASS_FILES_SUCCESS = "[class] UPLOAD AND SEND FILES SUCCESS",
    CREATE_SINGLE_CLASS = "[class] CREATE SINGLE CLASS",
    CREATE_SINGLE_CLASS_SUCCESS = "[class] CREATE SINGLE CLASS SUCCESS",
    CREATE_MULTIPLE_CLASSES = "[class] CREATE MULTIPLE CLASSES",
    CREATE_MULTIPLE_CLASSES_SUCCESS = "[class] CREATE MULTIPLE CLASSES SUCCESS",
    CREATE_DEMO_CLASS = "[class] CREATE DEMO CLASS",
    CREATE_DEMO_CLASS_SUCCESS = "[class] CREATE DEMO CLASS SUCCESS",
    IMPORT_STUDENTS_FROM_TEMPLATE = "[class] IMPORT STUDENTS FROM TEMPLATE",
    IMPORT_STUDENTS_FROM_TEMPLATE_SUCCESS = "[class] IMPORT STUDENTS FROM TEMPLATE SUCCESS",
    GET_STUDENTS_BY_CLASS = "[class] GET STUDENTS BY CLASS",
    GET_STUDENTS_BY_CLASS_SUCCESS = "[class] GET STUDENTS BY CLASS SUCCESS",
    GET_STUDENTS_BY_GROUP = "[class] GET STUDENTS BY GROUP",
    GET_STUDENTS_BY_GROUP_SUCCESS = "[class] GET STUDENTS BY GROUP SUCCESS",
    GET_LAST_UPDATED_CLASS_TRACKERS = "[class] GET LAST UPDATED CLASS TRACKERS",
    GET_LAST_UPDATED_CLASS_TRACKERS_SUCCESS = "[class] GET LAST UPDATED CLASS TRACKERS SUCCESS",
    CLEAR_LAST_UPDATED_CLASS_TRACKERS_SUCCESS = "[class] CLEAR LAST UPDATED CLASS TRACKERS SUCCESS",
    GET_IMPORT_STUDENTS_TEMPLATE = "[class] GET IMPORT STUDENTS TEMPLATE",
    GET_IMPORT_STUDENTS_TEMPLATE_SUCCESS = "[class] GET IMPORT STUDENTS TEMPLATE SUCCESS",
    GET_IMPORT_MULTIPLE_CLASSES_TEMPLATE = "[class] GET IMPORT MULTIPLE CLASSES TEMPLATE",
    GET_IMPORT_MULTIPLE_CLASSES_TEMPLATE_SUCCESS = "[class] GET IMPORT MULTIPLE CLASSES TEMPLATE SUCCESS",
    CLEAR_STUDENTS = "[class] CLEAR STUDENTS",
    CLEAR_CURRENT_UPLOAD_ID = "[class] CLEAR CURRENT UPLOAD ID",
    SET_CURRENT_UPLOAD_ID = "[class] SET CURRENT UPLOAD ID",
    SET_IMPORT_DATABASE_ERROR = "[class] SET IMPORT DATABASE ERROR",
    SET_IMPORT_DATABASE_ERROR_FILE = "[class] SET IMPORT DATABSE ERROR FILE",
    CLEAR_IMPORT_DATABASE_ERROR = "[class] CLEAR IMPORT DATABASE ERROR",
    CLEAR_TMP_DEMO_CLASS_ID = "[class] CLEAR TMP DEMO CLASS ID",
    EDIT_CLASS = "[class] EDIT CLASS",
    EDIT_CLASS_SUCCESS = "[class] EDIT CLASS SUCCESS",
    GET_CLASS_DETAILS = "[class] GET CLASS DETAILS",
    GET_CLASS_DETAILS_SUCCESS = "[class] GET CLASS DETAILS SUCCESS",
    REMOVE_CLASS = "[class] REMOVE CLASS",
    REMOVE_CLASS_SUCCESS = "[class] REMOVE CLASS SUCCESS",
    BULK_EDIT_CLASSES = "[class] BULK EDIT CLASSES",
    BULK_EDIT_CLASSES_SUCCESS = "[class] BULK EDIT CLASSES SUCCESS",
    UNSYNC_CLASS_TRACKER = "[class] UNSYNC CLASS TRACKER",
    UNSYNC_CLASS_TRACKER_SUCCESS = "[class] UNSYNC CLASS TRACKER SUCCESS",
    SET_PAGER_RESET = "[class] SET PAGER RESET",
    SET_LAST_FILTERING_OPTIONS = "[class] SET LAST FILTERING OPTIONS",
}

export const ClassesApiEndpoints = {
    getClassTrackerGroupList: createActionObject(
        getClassTrackersEndpoint,
        ClassesActionsTypes.GET_CLASS_TRACKER_GROUP_LIST_SUCCESS,
    ),
    getLastUpdatedClassTrackers: createActionObject(
        getLastUpdatedClassTrackersEndpoint,
        ClassesActionsTypes.GET_LAST_UPDATED_CLASS_TRACKERS_SUCCESS,
    ),
    getClassTrackerFilters: createActionObject(
        getClassTrackerFiltersEndpoint,
        ClassesActionsTypes.GET_CLASS_TRACKER_FILTERS_SUCCESS,
    ),
    uploadAndSendClass: createActionObject(
        uploadAndSendClassEndpoint,
        ClassesActionsTypes.UPLOAD_AND_SEND_CLASS_SUCCESS,
    ),
    uploadAndSendClassFiles: createActionObject(
        uploadAndSendClassFilesEndpoint,
        ClassesActionsTypes.UPLOAD_AND_SEND_CLASS_FILES_SUCCESS,
    ),
    createSingleClass: createActionObject(
        singleClassEndpoint,
        ClassesActionsTypes.CREATE_SINGLE_CLASS_SUCCESS,
    ),
    createMultipleClasses: createActionObject(
        multipleClassesEndpoint,
        ClassesActionsTypes.CREATE_MULTIPLE_CLASSES_SUCCESS,
    ),
    createDemoClass: createActionObject(
        demoClassEndpoint,
        ClassesActionsTypes.CREATE_DEMO_CLASS_SUCCESS,
    ),
    getStudentsByClass: createActionObject(
        getStudentsByClassEndpoint,
        ClassesActionsTypes.GET_STUDENTS_BY_CLASS_SUCCESS,
    ),
    getStudentsByGroup: createActionObject(
        getStudentsByGroupEndpoint,
        ClassesActionsTypes.GET_STUDENTS_BY_GROUP_SUCCESS,
    ),
    getImportStudentsTemplate: createActionObject(
        getImportStudentsTemplateDownloadEndpoint,
        ClassesActionsTypes.GET_IMPORT_STUDENTS_TEMPLATE_SUCCESS,
    ),
    getImportMultipleClassesTemplate: createActionObject(
        getMultipleClassesTemplateEndpoint,
        ClassesActionsTypes.GET_IMPORT_MULTIPLE_CLASSES_TEMPLATE_SUCCESS,
    ),
    editClass: createActionObject(editClassEndpoint, ClassesActionsTypes.EDIT_CLASS_SUCCESS),
    getClassDetails: createActionObject(
        getClassDetailsEndpoint,
        ClassesActionsTypes.GET_CLASS_DETAILS_SUCCESS,
    ),
    removeClass: createActionObject(
        removeClassTrackerEndpoint,
        ClassesActionsTypes.REMOVE_CLASS_SUCCESS,
    ),
    bulkEditClasses: createActionObject(
        bulkEditClassesEndpoint,
        ClassesActionsTypes.BULK_EDIT_CLASSES_SUCCESS,
    ),
    unsyncClassTracker: createActionObject(
        unsyncClassTrackerEndpoint,
        ClassesActionsTypes.UNSYNC_CLASS_TRACKER_SUCCESS,
    ),
};

export const ClassesRawActions = (
    ClassesEndpoints: Record<Keys<typeof ClassesApiEndpoints>, ApiActionMeta>,
) => ({
    getClassTrackerGroupList: (cohort: number, filters?: ClassTrackerListFilters) =>
        createApiAction(
            ClassesActionsTypes.GET_CLASS_TRACKER_GROUP_LIST,
            { params: { cohort }, values: filters ? { ...filters } : {} },
            ClassesEndpoints.getClassTrackerGroupList,
        ),
    getClassTrackerGroupListSuccess: (
        payload: ApiCallbackPayload<ClassTrackerListResponse, {}, CohortParam>,
    ) => createAction(ClassesActionsTypes.GET_CLASS_TRACKER_GROUP_LIST_SUCCESS, payload),
    getLastUpdatedClassTrackers: (cohort: number) =>
        createApiAction(
            ClassesActionsTypes.GET_LAST_UPDATED_CLASS_TRACKERS,
            { params: { cohort }, values: {} },
            ClassesEndpoints.getLastUpdatedClassTrackers,
        ),
    getLastUpdatedClassTrackersSuccess: (
        payload: ApiCallbackPayload<LastUpdatedClassTrackerStatuses, {}, CohortParam>,
    ) => createAction(ClassesActionsTypes.GET_LAST_UPDATED_CLASS_TRACKERS_SUCCESS, payload),
    getClassTrackerFilters: (cohort: number) => {
        return createApiAction(
            ClassesActionsTypes.GET_CLASS_TRACKER_FILTERS,
            paramRequest({ cohort }),
            ClassesEndpoints.getClassTrackerFilters,
        );
    },
    getClassTrackerFiltersSuccess: (
        payload: ApiCallbackPayload<ClassTrackerFilterResponse, {}, CohortParam>,
    ) => createAction(ClassesActionsTypes.GET_CLASS_TRACKER_FILTERS_SUCCESS, payload),
    uploadAndSendClass: (payload: ApiPayload<UploadClassRequest>) =>
        createApiAction(
            ClassesActionsTypes.UPLOAD_AND_SEND_CLASS,
            { values: payload, params: undefined },
            ClassesEndpoints.uploadAndSendClass,
        ),
    uploadAndSendClassSuccess: (payload: ApiCallbackPayload<{ id: number }>) =>
        createAction(ClassesActionsTypes.UPLOAD_AND_SEND_CLASS_SUCCESS, payload),
    uploadAndSendClassFiles: (id: number, file: FileType) =>
        createApiAction(
            ClassesActionsTypes.UPLOAD_AND_SEND_CLASS_FILES,
            { params: { id }, values: { file } },
            ClassesEndpoints.uploadAndSendClassFiles,
        ),
    uploadAndSendClassFilesSuccess: () =>
        createAction(ClassesActionsTypes.UPLOAD_AND_SEND_CLASS_FILES_SUCCESS),
    createSingleClass: (payload: ApiPayload<SingleClassRequest>) =>
        createApiAction(
            ClassesActionsTypes.CREATE_SINGLE_CLASS,
            { values: payload, params: undefined },
            ClassesEndpoints.createSingleClass,
        ),
    createSingleClassSuccess: () => createAction(ClassesActionsTypes.CREATE_SINGLE_CLASS_SUCCESS),
    createDemoClass: (payload: DemoClassRequest) =>
        createApiAction(
            ClassesActionsTypes.CREATE_DEMO_CLASS,
            { values: payload, params: undefined },
            ClassesEndpoints.createDemoClass,
        ),
    createDemoClassSuccess: (payload: ApiCallbackPayload<OrmClassTracker>) =>
        createAction(ClassesActionsTypes.CREATE_DEMO_CLASS_SUCCESS, payload),
    createMultipleClasses: (payload: MultipleClassesRequest, overwrite?: boolean | null) =>
        createApiAction(
            ClassesActionsTypes.CREATE_MULTIPLE_CLASSES,
            { values: { ...payload, overwrite }, params: undefined },
            ClassesEndpoints.createMultipleClasses,
        ),
    createMultipleClassesSuccess: (payload: ApiCallbackPayload<any[]>) =>
        createAction(ClassesActionsTypes.CREATE_MULTIPLE_CLASSES_SUCCESS, payload),
    getImportStudentsTemplate: (specificationId: number) =>
        createApiAction(
            ClassesActionsTypes.GET_IMPORT_STUDENTS_TEMPLATE,
            paramRequest({ specification: specificationId }),
            ClassesEndpoints.getImportStudentsTemplate,
        ),
    getImportStudentsTemplateSuccess: (payload: ApiCallbackPayload<Blob>) =>
        createAction(ClassesActionsTypes.GET_IMPORT_STUDENTS_TEMPLATE_SUCCESS, payload),
    getImportMultipleClassesTemplate: (specification: number) =>
        createApiAction(
            ClassesActionsTypes.GET_IMPORT_MULTIPLE_CLASSES_TEMPLATE,
            paramRequest({ specification }),
            ClassesEndpoints.getImportMultipleClassesTemplate,
        ),
    getImportMultipleClassesTemplateSuccess: (payload: ApiCallbackPayload<Blob>) =>
        createAction(ClassesActionsTypes.GET_IMPORT_MULTIPLE_CLASSES_TEMPLATE_SUCCESS, payload),
    getStudentsByClass: (classTracker: number, tier?: TiersTypes) =>
        createApiAction(
            ClassesActionsTypes.GET_STUDENTS_BY_CLASS,
            paramRequest({ classTracker, tier }),
            ClassesEndpoints.getStudentsByClass,
        ),
    getStudentsByClassSuccess: (payload: ApiCallbackPayload<StudentsByClassResponse>) =>
        createAction(ClassesActionsTypes.GET_STUDENTS_BY_CLASS_SUCCESS, payload),
    getStudentsByGroup: (classTrackerGroup: number, tier?: TiersTypes) =>
        createApiAction(
            ClassesActionsTypes.GET_STUDENTS_BY_GROUP,
            paramRequest({ classTrackerGroup, tier }),
            ClassesEndpoints.getStudentsByGroup,
        ),
    getStudentsByGroupSuccess: (payload: ApiCallbackPayload<StudentsByGroupResponse>) =>
        createAction(ClassesActionsTypes.GET_STUDENTS_BY_GROUP_SUCCESS, payload),
    clearStudents: () => createAction(ClassesActionsTypes.CLEAR_STUDENTS),
    clearCurrentClassUploadId: () => createAction(ClassesActionsTypes.CLEAR_CURRENT_UPLOAD_ID),
    setCurrentUploadId: (id: number) => createAction(ClassesActionsTypes.SET_CURRENT_UPLOAD_ID, id),
    setImportDatabaseError: (payload: any[]) =>
        createAction(ClassesActionsTypes.SET_IMPORT_DATABASE_ERROR, payload),
    setImportDatabaseErrorFile: (file: MultipleClassesRequest) =>
        createAction(ClassesActionsTypes.SET_IMPORT_DATABASE_ERROR_FILE, file),
    clearImportDatabaseError: () => createAction(ClassesActionsTypes.CLEAR_IMPORT_DATABASE_ERROR),
    clearLastUpdatedClassTrackers: () =>
        createAction(ClassesActionsTypes.CLEAR_LAST_UPDATED_CLASS_TRACKERS_SUCCESS),
    clearTmpDemoClassId: () => createAction(ClassesActionsTypes.CLEAR_TMP_DEMO_CLASS_ID),
    editClass: (classTracker: number, values: EditClassRequest) =>
        createApiAction(
            ClassesActionsTypes.EDIT_CLASS,
            { values, params: { classTracker } },
            ClassesEndpoints.editClass,
        ),
    editClassSuccess: () => createAction(ClassesActionsTypes.EDIT_CLASS_SUCCESS),
    getClassDetails: (classTracker: number) =>
        createApiAction(
            ClassesActionsTypes.GET_CLASS_DETAILS,
            paramRequest({ classTracker }),
            ClassesEndpoints.getClassDetails,
        ),
    getClassDetailsSuccess: (payload: ApiCallbackPayload<OrmClassTracker>) =>
        createAction(ClassesActionsTypes.GET_CLASS_DETAILS_SUCCESS, payload),
    removeClass: (classTracker: number) =>
        createApiAction(
            ClassesActionsTypes.REMOVE_CLASS,
            paramRequest({ classTracker }),
            ClassesEndpoints.removeClass,
        ),
    removeClassSuccess: () => createAction(ClassesActionsTypes.REMOVE_CLASS_SUCCESS),
    unsyncClassTracker: (classTracker: number) =>
        createApiAction(
            ClassesActionsTypes.UNSYNC_CLASS_TRACKER,
            paramRequest({ classTracker }),
            ClassesEndpoints.unsyncClassTracker,
        ),
    unsyncClassTrackerSuccess: () => createAction(ClassesActionsTypes.UNSYNC_CLASS_TRACKER_SUCCESS),
    bulkEditClasses: (classTrackerGroup: number, values: BulkEditClassesRequest) =>
        createApiAction(
            ClassesActionsTypes.BULK_EDIT_CLASSES,
            { values, params: { classTrackerGroup } },
            ClassesEndpoints.bulkEditClasses,
        ),
    bulkEditClassesSuccess: () => createAction(ClassesActionsTypes.BULK_EDIT_CLASSES_SUCCESS),
    setPagerReset: () => createAction(ClassesActionsTypes.SET_PAGER_RESET),
    setLastFilteringOptions: (cohort: number, filters?: ClassTrackerListFilters) =>
        createAction(ClassesActionsTypes.SET_LAST_FILTERING_OPTIONS, { cohort, filters }),
});
