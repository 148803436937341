/* eslint-disable react/display-name */
import { GridApi } from "ag-grid-community";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { usePrevious } from "src/hooks/usePrevious";
import { NAV_KEYS } from "src/modules/tracker/services/gridNavigation";

interface OwnProps {
    value: string | { value: string };
    cellConfig?: any;
    api?: GridApi;
    context: any;
}

const DefaultValueEditor = forwardRef(
    ({ value: propsValue, cellConfig, api: gridApi, context }: OwnProps, ref) => {
        const [value, setValue] = useState(
            typeof propsValue === "object" && propsValue !== null
                ? propsValue.value
                : propsValue || "",
        );

        const prevValue = usePrevious(value);
        const refInput = useRef(null) as any;

        const { isBlocked, isGradeVBoundaryProcessing } = context;

        useEffect(() => {
            refInput.current.focus();
            refInput.current.select();
            //eslint-disable-next-line
        }, []);

        useEffect(() => {
            if (gridApi && (isBlocked || isGradeVBoundaryProcessing)) {
                gridApi.stopEditing(true);
            }
        }, [isBlocked, isGradeVBoundaryProcessing, gridApi]);

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    if (prevValue !== value) {
                        return { value, whileUpdate: true };
                    }
                    return value;
                },
            };
        });

        const handleOnChange = (e: any) => {
            const newValue = e.target.value;
            const options = cellConfig?.colourConfig?.options;
            if (options) {
                let availableValues = Object.keys(options);
                availableValues = availableValues
                    .concat(availableValues.map(v => `${v}`.toLowerCase()))
                    .concat("");
                if (availableValues.includes(newValue)) {
                    setValue(newValue.toUpperCase());
                }
            }
        };

        return (
            <div className="ag-editor-cell">
                <input
                    className="ag-editor-cell__input"
                    onChange={handleOnChange}
                    onKeyDown={e => {
                        if (NAV_KEYS.includes(e.key)) {
                            e.preventDefault();
                            gridApi?.stopEditing();
                        }
                    }}
                    ref={refInput}
                    value={value as string}
                    type="text"
                />
            </div>
        );
    },
);

export default DefaultValueEditor;
