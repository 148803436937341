import { FC } from "react";
import Icon from "src/components/Icon";
import { mdiChevronDown } from "@mdi/js";
import { hexToRGB, getContrastText } from "src/modules/tracker/services/colorHandler";
import { ColDef } from "ag-grid-community";
import PinnedTopRenderer from "./PinnedTopRenderer";

interface OwnProps {
    value: string;
    choices: { value: number; option: string; colour?: string; opacity?: number }[];
    node: any;
    data: any;
    colDef: ColDef;
    context: any;
}

const SelectableRenderer: FC<OwnProps> = ({ value, choices, node, data, colDef, context }) => {
    const { field } = colDef;

    if (node.rowPinned) {
        return (
            <PinnedTopRenderer
                field={field}
                value={field && data ? data[field] : ""}
                context={context}
            />
        );
    }

    const colour = choices.find(c => `${c.option}` === `${value}`);

    const backgroundColor =
        colour?.colour && colour?.opacity
            ? hexToRGB(colour?.colour, colour?.opacity)
            : "transparent";

    return (
        <div
            title={value}
            className="ag-value-cell-row"
            style={{
                backgroundColor,
            }}
        >
            <div
                className="ag-value-cell-label"
                style={{ color: getContrastText(colour?.colour, colour?.opacity) }}
            >
                {value}
            </div>
            <div className="ag-value-cell__icon">
                <Icon path={mdiChevronDown} size="16px" />
            </div>
        </div>
    );
};

export default SelectableRenderer;
