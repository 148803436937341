import { StudentShortForParentModel } from "../../student/model/StudentModel";

export type ParentTitlesType = "Mr" | "Mrs" | "Miss" | "Ms" | "Dr" | "Prof" | "Mx";

export enum ParentTitles {
    MR = "Mr",
    MRS = "Mrs",
    MISS = "Miss",
    MS = "Ms",
    DR = "Dr",
    PROF = "Prof",
    MX = "Mx",
}

export const parentTitlesList = [
    ParentTitles.MR,
    ParentTitles.MRS,
    ParentTitles.MISS,
    ParentTitles.MS,
    ParentTitles.DR,
    ParentTitles.PROF,
    ParentTitles.MX,
];

export enum ParentStatus {
    CONFIRMED = "confirmed",
    ADDED = "added",
    INVITED = "invited",
    DEACTIVATED = "deactivated",
    NOT_INVITED = "not-invited",
    NON_INVITABLE = "non-invitable",
}

export const parentStatusList = [
    ParentStatus.CONFIRMED,
    ParentStatus.ADDED,
    ParentStatus.INVITED,
    ParentStatus.DEACTIVATED,
    ParentStatus.NON_INVITABLE,
    ParentStatus.NOT_INVITED,
];

export interface ParentShortForStudentModel {
    id: number;
    userIdentifier: string;
    fullName: string;
    status: ParentStatus;
}

export interface ParentModel {
    id: number;
    userIdentifier: string;
    title: ParentTitlesType;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    students?: StudentShortForParentModel[];
    status?: ParentStatus;
    groupCallStatus?: string;
    groupCallIdaasId?: string;
}
