import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FC } from "react";

interface OwnProps {
    pinned: string;
}

export const ReportHeader: FC<OwnProps> = ({ pinned }) => {
    const { t } = useTranslation();

    return (
        <div className={clsx("ag-column-header", pinned === "left" && "ag-column-header--report")}>
            <div className="ag-column-header-row">
                <span className="ag-column-header-label ag-column-header-label--bold">
                    {t("tracker.grid.report")}
                </span>
            </div>
        </div>
    );
};
