import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionsTableButton from "src/components/ActionTableButton";
import AppContainer from "src/components/AppContainer";
import TablePage, { TablePageFilterType } from "src/components/TablePage";
import { useTagsList } from "../hooks/useTagsList";
import { PaginationState } from "@tanstack/react-table";
import { TagModel, TagStatusEnum, tagModelToTagRequest } from "../model/TagModel";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { Link } from "react-router-dom";
import {
    ROUTE_MY_SCHOOL_STUDENTS,
    ROUTE_MY_SCHOOL_TAG_ADD,
    ROUTE_MY_SCHOOL_TAG_EDIT,
} from "src/routes";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import { useRemoveTagMutation } from "../hooks/useRemoveMutation";
import { useEditTagMutation } from "../hooks/useEditMutation";
import useDebounce from "src/hooks/useDebounce";
import Icon from "src/components/Icon";
import {
    mdiLockOutline,
    mdiCheckCircleOutline,
    mdiCloseCircleOutline,
    mdiEye,
    mdiArrowLeft,
} from "@mdi/js";
import { useSubjectAreasList } from "../components/subjectRows/hooks/useSubjectAreasList";
import { useQualificationsList } from "../components/subjectRows/hooks/useQualificationsList";
import { SchoolUserRole } from "src/orm/models/User";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

enum AdditionalOptionsId {
    NOT_ACTIVE_FILTER = -1,
    ALL_API_FLAG = -2,
}

const TagListContainer = () => {
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();
    const { attachSchoolId } = useSchoolNavigate();

    // delete tag dialog
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);

    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const returnUrl = decodeURIComponent(searchParams["returnUrl"] || "");
    // filters and pagination
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 50,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const { data: accountInfo } = useAccountInfo();
    const defaultCohortId = accountInfo?.activeCohortId;
    const { data: subjectAreas } = useSubjectAreasList();
    const { data: qualifications } = useQualificationsList(
        parseInt(searchParams["subjectAreaId"]) || undefined,
    );
    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const yearGroups = data?.yearGroups;

    //tags list
    const [filters, setFilters] = useState<TablePageFilterType[]>([]);
    const debouncedFilters: TablePageFilterType[] = useDebounce(filters, 200);

    const handleFiltersChange = (newFilters: TablePageFilterType[]) => {
        const currentSubjectAreaIdFilter = debouncedFilters.find(({ id }) => id === "subjectAreaId")
            ?.value as number;
        const newSubjectAreaIdFilter = newFilters.find(({ id }) => id === "subjectAreaId")
            ?.value as number;
        if (currentSubjectAreaIdFilter != newSubjectAreaIdFilter) {
            const qualificationIdFilterIndex = newFilters.findIndex(
                ({ id }) => id === "qualificationId",
            );
            newFilters[qualificationIdFilterIndex].value = AdditionalOptionsId.NOT_ACTIVE_FILTER;
        }
        setFilters(newFilters);
    };

    const {
        data: tags,
        refetch: refetchTags,
        isLoading,
    } = useTagsList(
        { page: 1, perPage: 9999 },
        { cohort: debouncedFilters.find(({ id }) => id === "cohort")?.value as number },
    );
    //change filters to local, frontend filtering
    const [filterdTags, setFilteredTags] = useState<TagModel[]>(tags?.data || []);

    //tag actions
    const { mutate: removeTag } = useRemoveTagMutation(() => refetchTags());
    const { mutate: editTag } = useEditTagMutation(() => refetchTags());

    const [filtersValues, setFiltersValues] = useState<string>("");
    useEffect(() => {
        setFiltersValues(debouncedFilters.map(({ id, value }) => `${id}:${value}`).join(","));
    }, [debouncedFilters]);

    useEffect(() => {
        const qualificationIdFilter = debouncedFilters.find(({ id }) => id === "qualificationId")
            ?.value as number;
        const yearGroupFilter = debouncedFilters.find(({ id }) => id === "yearGroup")
            ?.value as number;
        const subjectAreaIdFilter = debouncedFilters.find(({ id }) => id === "subjectAreaId")
            ?.value as number;
        const newTags =
            tags?.data?.filter(t => {
                if (yearGroupFilter == AdditionalOptionsId.ALL_API_FLAG) {
                    if (t.yearGroups !== null) {
                        return false;
                    }
                } else if (yearGroupFilter != AdditionalOptionsId.NOT_ACTIVE_FILTER) {
                    if (!(t.yearGroups || []).find(v => v == yearGroupFilter)) {
                        return false;
                    }
                }
                if (subjectAreaIdFilter == AdditionalOptionsId.ALL_API_FLAG) {
                    if (t.specifications !== null) {
                        return false;
                    }
                } else if (subjectAreaIdFilter != AdditionalOptionsId.NOT_ACTIVE_FILTER) {
                    if (
                        !(t.specifications || []).find(
                            ({ subjectAreaId }) => subjectAreaId == subjectAreaIdFilter,
                        )
                    ) {
                        return false;
                    }
                }
                if (qualificationIdFilter != AdditionalOptionsId.NOT_ACTIVE_FILTER) {
                    if (
                        !(t.specifications || []).find(
                            ({ qualificationId }) => qualificationId == qualificationIdFilter,
                        )
                    ) {
                        return false;
                    }
                }
                return true;
            }) || [];
        setFilteredTags(newTags);
    }, [filtersValues, tags]);

    // filter types definition
    useEffect(() => {
        let availableSubjectAreaIds = [];
        let availableQualificationIds = [];
        let allSubjects = false;
        let availableYearGroups = [];
        let allYearGroups = false;
        tags?.data?.forEach(({ specifications }) => {
            if (specifications === null) {
                allSubjects = true;
            } else {
                specifications.forEach(({ subjectAreaId, qualificationId }) => {
                    availableSubjectAreaIds.push(subjectAreaId);
                    availableQualificationIds.push(qualificationId);
                });
            }
            availableSubjectAreaIds = [...new Set(availableSubjectAreaIds)];
            availableQualificationIds = [...new Set(availableQualificationIds)];
        });
        filterdTags?.forEach(({ yearGroups }) => {
            if (yearGroups === null) {
                allYearGroups = true;
            } else {
                availableYearGroups = [...availableYearGroups, ...yearGroups];
            }
        });
        availableYearGroups = [...new Set(availableYearGroups)];
        const subjectAreaIdOptions = [
            {
                value: AdditionalOptionsId.NOT_ACTIVE_FILTER as unknown as string,
                label: t("tag.list.filters.all"),
            },
        ];
        if (allSubjects) {
            subjectAreaIdOptions.push({
                value: AdditionalOptionsId.ALL_API_FLAG as unknown as string,
                label: t("tag.list.filters.allSubjects"),
            });
        }
        subjectAreaIdOptions.push(
            ...(subjectAreas
                ?.filter(({ id }) => availableSubjectAreaIds.includes(id))
                .map(({ id, name }) => ({
                    label: name,
                    value: id as unknown as string,
                })) || []),
        );

        const yearGroupOptions = [
            {
                value: AdditionalOptionsId.NOT_ACTIVE_FILTER as unknown as string,
                label: t("tag.list.filters.all"),
            },
        ];
        if (allYearGroups) {
            yearGroupOptions.push({
                value: AdditionalOptionsId.ALL_API_FLAG as unknown as string,
                label: t("tag.list.filters.allYearGroups"),
            });
        }
        yearGroupOptions.push(
            ...(yearGroups
                ?.filter(({ id }) => availableYearGroups.includes(id))
                .map(({ id, name }) => ({
                    value: id as unknown as string,
                    label: name,
                })) || []),
        );
        setFilters([
            {
                id: "cohort",
                type: "select",
                label: t("tag.list.filters.cohort"),
                options: cohorts?.map(({ id, name }) => ({
                    label: name,
                    value: id as unknown as string,
                })),
                value: searchParams["cohort"] || defaultCohortId,
            },
            {
                id: "subjectAreaId",
                type: "select",
                label: t("tag.list.filters.subjectArea"),
                options: subjectAreaIdOptions,
                value: searchParams["subjectAreaId"] || AdditionalOptionsId.NOT_ACTIVE_FILTER,
            },
            {
                id: "qualificationId",
                type: "select",
                label: t("tag.list.filters.qualification"),
                options: [
                    {
                        value: AdditionalOptionsId.NOT_ACTIVE_FILTER as unknown as string,
                        label: t("tag.list.filters.all"),
                    },
                    ...(qualifications
                        ?.filter(({ id }) => availableQualificationIds.includes(id))
                        .map(({ id, name }) => ({
                            label: name,
                            value: id as unknown as string,
                        })) || []),
                ],
                value: searchParams["qualificationId"] || AdditionalOptionsId.NOT_ACTIVE_FILTER,
            },
            {
                id: "yearGroup",
                type: "select",
                label: t("tag.list.filters.yearGroup"),
                options: yearGroupOptions,
                value: availableYearGroups.find(v => v == searchParams["yearGroup"])
                    ? searchParams["yearGroup"]
                    : AdditionalOptionsId.NOT_ACTIVE_FILTER,
            },
        ]);
    }, [cohorts, subjectAreas, qualifications, yearGroups, defaultCohortId, tags, filterdTags]);

    //Actions

    const handleDeleteDialogOpen = (id: number): void => {
        setRemoveDialogOpened(true);
        setRemoveId(id);
    };

    const handleDeleteDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            removeTag({ id: removeId });
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };
    const { data: userProfile } = useProfile();
    const canEditTags = !!(
        userProfile?.userRole === SchoolUserRole.SCHOOL_ADMIN ||
        userProfile?.userRole === SchoolUserRole.OWNER ||
        userProfile?.isLeaderOfAll ||
        (userProfile?.leaderOfSubjectAreas && userProfile?.leaderOfSubjectAreas.length > 0)
    );

    const tableActions = [
        getMenuActionProps("edit", {
            linkCallback: (tagData: TagModel) =>
                attachSchoolId(ROUTE_MY_SCHOOL_TAG_EDIT).replace(":tag", `${tagData.id}`),
            showAction: () => canEditTags,
        }),
        getMenuActionProps("delete", {
            onAction: (tagData: TagModel) =>
                tagData.id ? handleDeleteDialogOpen(tagData.id) : null,
            showAction: () => canEditTags,
        }),
        getMenuActionProps("active", {
            icon: <Icon path={mdiCheckCircleOutline} />,
            text: t("tag.list.makeActive"),
            onAction: (tagData: TagModel) =>
                editTag({ ...tagModelToTagRequest(tagData), status: TagStatusEnum.ACTIVE }),
            showAction: (tagData: TagModel) =>
                canEditTags && tagData.status !== TagStatusEnum.ACTIVE,
        }),
        getMenuActionProps("inactive", {
            icon: <Icon path={mdiCloseCircleOutline} />,
            text: t("tag.list.makeInactive"),
            onAction: (tagData: TagModel) =>
                editTag({ ...tagModelToTagRequest(tagData), status: TagStatusEnum.INACTIVE }),
            showAction: (tagData: TagModel) =>
                canEditTags && tagData.status !== TagStatusEnum.INACTIVE,
        }),
        getMenuActionProps("active", {
            icon: <Icon path={mdiLockOutline} />,
            text: t("tag.list.makeLocked"),
            onAction: (tagData: TagModel) =>
                editTag({ ...tagModelToTagRequest(tagData), status: TagStatusEnum.LOCKED }),
            showAction: (tagData: TagModel) =>
                canEditTags && tagData.status !== TagStatusEnum.LOCKED,
        }),
        getMenuActionProps("show", {
            icon: <Icon path={mdiEye} />,
            text: t("tag.list.showStudents"),
            linkCallback: (tagData: TagModel) =>
                attachSchoolId(ROUTE_MY_SCHOOL_STUDENTS) + "?tagId=" + tagData.id,
            showAction: (tagData: TagModel) => !!tagData.numberOfStudents,
        }),
    ];
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const columns = [
            {
                id: "name",
                header: t("tag.list.name"),
                accessorKey: "name",
            },
            {
                id: "author",
                header: t("tag.list.author"),
                cell: ({ row }: { row: { original: TagModel } }) => row.original.author.name,
            },
            {
                id: "status",
                header: t("tag.list.status"),
                accessorKey: "status",
                cell: ({ row }: { row: { original: TagModel } }) =>
                    t(`tag.status.${row.original.status}`),
            },
            {
                id: "subjects",
                header: t("tag.list.subjects"),
                cell: ({ row }: { row: { original: TagModel } }) => {
                    if (row.original.specifications) {
                        return row.original.specifications.map(
                            ({
                                specificationName,
                                qualificationName,
                                subjectAreaName,
                                specificationId,
                            }) => (
                                <div
                                    key={specificationId}
                                >{`${subjectAreaName} > ${qualificationName} > ${specificationName}`}</div>
                            ),
                        );
                    }
                    return t("tag.list.allSubjects");
                },
            },
            {
                id: "yearGroups",
                header: t("tag.list.yearGroups"),
                cell: ({ row }: { row: { original: TagModel } }) => {
                    if (row.original.yearGroups) {
                        return row.original.yearGroups.sort((a, b) => a - b).join(", ");
                    }
                    return t("tag.list.allYearGroups");
                },
            },
            {
                id: "students",
                header: t("tag.list.students"),
                accessorKey: "numberOfStudents",
            },
            {
                // actions definition (always use id "actions")
                id: "actions",
                cell: ({ row }) => (
                    <Box textAlign={"right"}>
                        <ActionsTableButton
                            actions={tableActions}
                            rowData={row.original}
                            forceDotMenu={true}
                        />
                    </Box>
                ),
            },
        ];

        setColumns(columns);
    }, [tags]);
    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("tag.tab")}
            </Typography>
            {columns && (
                <TablePage
                    headerNavButtons={
                        <>
                            {canEditTags &&
                                (!searchParams["cohort"] ||
                                    parseInt(searchParams["cohort"]) === defaultCohortId) && (
                                    <Button
                                        component={Link}
                                        to={attachSchoolId(ROUTE_MY_SCHOOL_TAG_ADD)}
                                        color="primary"
                                        id="addButton"
                                    >
                                        {t("tag.list.addButton")}
                                    </Button>
                                )}
                            {returnUrl && (
                                <Button
                                    onClick={() => {
                                        navigate(returnUrl);
                                    }}
                                    variant="text"
                                    disableRipple
                                    startIcon={<Icon path={mdiArrowLeft} />}
                                >
                                    {returnUrl.toUpperCase().includes("/ANALYSIS/")
                                        ? t("tag.list.backToAnalysis")
                                        : t("tag.list.backToTracker")}
                                </Button>
                            )}
                        </>
                    }
                    data={
                        filterdTags?.slice(
                            pagination.pageIndex * pagination.pageSize,
                            (pagination.pageIndex + 1) * pagination.pageSize,
                        ) || []
                    }
                    isLoading={isLoading}
                    columns={columns}
                    filters={filters}
                    count={filterdTags.length}
                    pagination={pagination}
                    handlePaginationChange={setPagination}
                    handleFiltersChange={handleFiltersChange}
                />
            )}
            <PromptDeleteConfirm open={removeDialogOpened} onClose={handleDeleteDialogClose}>
                <Typography variant="h3">{t("tag.list.deleteTagPromptHeader")}</Typography>
                <Box pt={3}>{t("tag.list.deleteTagPromptType")}</Box>
            </PromptDeleteConfirm>
        </AppContainer>
    );
};

export default TagListContainer;
