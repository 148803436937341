import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { WithRequiredKeys } from "src/types";
import { VariantType } from "notistack";

export interface SnackBarData {
    message: string;
    key: string | number;
    variant?: VariantType;
    persist?: boolean;
    preventDuplicate?: boolean;
    dismissed?: boolean;
    action?: any;
    autoHideDuration?: any;
}

export enum NotificationActionTypes {
    ENQUEUE = "[snackbar] ENQUEUE",
    REMOVE = "[snackbar] REMOVE",
    DISMISS = "[snackbar] DISMISS",
    DISMISS_ALL = "[snackbar] DISMISS ALL",
}

export const NotificationActions = {
    enqueueSnackbar: (payload: WithRequiredKeys<SnackBarData, "message">) =>
        createAction(NotificationActionTypes.ENQUEUE, payload),
    removeSnackbar: (payload: SnackBarData["key"]) =>
        createAction(NotificationActionTypes.REMOVE, payload),
    dismissSnackbar: (payload: SnackBarData["key"]) =>
        createAction(NotificationActionTypes.DISMISS, payload),
    dismissAllSnackbars: () => createAction(NotificationActionTypes.DISMISS_ALL),
};

export type NotificationActions = ActionsUnion<typeof NotificationActions>;
