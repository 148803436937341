import HeadingCounter from "src/components/HeadingCounter";
import PaperInner from "src/components/PaperInner";
import SpecificationSelectField from "src/forms/SpecificationSelectField";
import AttributesConfigList from "../../Common/AttributesConfigList";
import ErrorMessage from "src/forms/ErrorMessage";
import CheckboxField from "src/forms/CheckboxField";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
} from "@mui/material";
import { Field, Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
    AttributeGroup,
    AttributeItem,
    AttributeValueExtended,
    InitialAttribute,
} from "src/orm/models/SpecificationAttribute";
import { mdiClose, mdiHelpCircleOutline } from "@mdi/js";
import { ROUTE_CLASS_GROUP_CALL_CONVERT } from "src/routes";
import { GroupCallYearGroups } from "src/modules/class/hooks/MIS/useGroupCallClassesCreateMutation";
import { useGroupCallClassList } from "src/modules/class/hooks/MIS/useGroupCallClassList";
import { FC, useEffect } from "react";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

export interface SyncUnsyncFormShape {
    attributes: AttributeValueExtended[];
    items: AttributeItem[];
    groups: AttributeGroup[];
    disabledItems: number[];
    initialEditAttributes?: InitialAttribute[];
    yearGroups: GroupCallYearGroups[];
    specification?: number | null;
}

interface OwnProps {
    shouldSynchronise: boolean;
}

const MisSyncUnsyncForm: FC<OwnProps> = ({ shouldSynchronise }) => {
    const { data: accountInfo } = useAccountInfo();
    const { navigate } = useSchoolNavigate();
    const { t } = useTranslation();
    const { values, errors, setFieldTouched, setFieldValue } =
        useFormikContext<SyncUnsyncFormShape>();
    const { data: groupCallClasses, refetch } = useGroupCallClassList(values.specification);

    const isCheckboxChecked = (yearGroup: number) => {
        const yearGroupClasses = values?.yearGroups?.find(gcc => gcc.yearGroup === yearGroup);

        return !!yearGroupClasses?.classes
            .filter(c => !c.synchronized)
            .some(c => c.selected === true);
    };

    const handleSelectDeselectAll = (yearGroup: number) => {
        const newYearGroups = [...values.yearGroups];
        const index = newYearGroups.findIndex(nyg => nyg.yearGroup === yearGroup);
        const classes = newYearGroups[index].classes.map(c => ({
            ...c,
            selected: c.synchronized ? true : !isCheckboxChecked(yearGroup),
        }));
        newYearGroups[index].classes = classes;
        setFieldValue("yearGroups", newYearGroups);
    };

    useEffect(() => {
        setFieldValue(
            "yearGroups",
            (groupCallClasses || []).map(gcc => ({
                ...gcc,
                classes: gcc.classes.map(c => ({ ...c, selected: c.synchronized })),
            })),
        );
    }, [groupCallClasses]);

    return (
        <Form>
            <PaperInner>
                <HeadingCounter number="1">
                    {t("class.addClass.chooseTrackerHeader")}
                </HeadingCounter>
                <Typography component="p" gutterBottom>
                    {shouldSynchronise ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t("class.addClass.unsyncIntro").replaceAll(
                                    "MIS_Name",
                                    accountInfo?.misName || "",
                                ),
                            }}
                        ></div>
                    ) : (
                        t("class.addClass.singleClassIntro").replaceAll(
                            "MIS_Name",
                            accountInfo?.misName || "",
                        )
                    )}
                </Typography>
                <Field
                    name={`specification`}
                    liveOnly={true}
                    component={SpecificationSelectField}
                    showParentSpecification={true}
                    allowedOnly={true}
                    handleSpecificationChange={spec => {
                        refetch(spec.id);
                    }}
                />
                {values.specification && (
                    <AttributesConfigList
                        attributes={values.attributes}
                        specificationId={values.specification}
                        items={values.items}
                        groups={values.groups}
                        disabledItems={values.disabledItems}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialAttributes={[]}
                    />
                )}
                {errors && errors.attributes && <ErrorMessage name="attributes" />}
            </PaperInner>
            {values.specification && (
                <PaperInner border="top">
                    <HeadingCounter number="2">{t("class.addClass.academicYear")}</HeadingCounter>
                    <FormGroup>
                        <Grid container spacing={4}>
                            {values.yearGroups?.length === 0 && (
                                <Box ml={2} mt={2}>
                                    {t("class.addClass.noYearGroups")}
                                </Box>
                            )}

                            {values.yearGroups?.map((gcc, i) => (
                                <Grid key={gcc.yearGroup} item sm={4}>
                                    <Box p={4} height="100%" bgcolor="#f9f9f9">
                                        <Box mb={2}>
                                            <strong>
                                                {t("common.yearGroup") +
                                                    " " +
                                                    (gcc.yearGroup === 0
                                                        ? t("common.yg0")
                                                        : gcc.yearGroup)}
                                            </strong>
                                        </Box>
                                        <Box>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="selectAll"
                                                        value={true}
                                                        disabled={gcc.hasManualClasses}
                                                        checked={isCheckboxChecked(gcc.yearGroup)}
                                                        onChange={() =>
                                                            handleSelectDeselectAll(gcc.yearGroup)
                                                        }
                                                    />
                                                }
                                                label={
                                                    isCheckboxChecked(gcc.yearGroup) ? (
                                                        <i>{t("common.deselectAllClasses")}</i>
                                                    ) : (
                                                        <i>{t("common.selectAllClasses")}</i>
                                                    )
                                                }
                                            />
                                        </Box>
                                        {gcc.classes.map((c, j) => (
                                            <Box key={c.id}>
                                                {!gcc.hasManualClasses ? (
                                                    <Field
                                                        name={`yearGroups[${i}].classes.${j}.selected`}
                                                        component={CheckboxField}
                                                        disabled={
                                                            // shouldSynchronise &&
                                                            values.yearGroups[i].classes[j]
                                                                .synchronized
                                                        }
                                                        label={c.name}
                                                    />
                                                ) : (
                                                    <Box display="flex" pt={1.625} pb={0.75}>
                                                        <Box width={20} mr={1.5}>
                                                            <Icon
                                                                path={mdiClose}
                                                                size="20px"
                                                                color={COLORS.GREY_2}
                                                            />
                                                        </Box>
                                                        <Box flex={1}>
                                                            {values.yearGroups[i].classes[j].name}
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                        {gcc.hasManualClasses && (
                                            <Box
                                                mt={4}
                                                mx={-4}
                                                px={4}
                                                pt={2.5}
                                                bgcolor="#FAFBFC"
                                                borderTop={`1px solid ${COLORS.VERY_LIGHT_GREY_3}`}
                                            >
                                                <Box mb={2.5} display="flex">
                                                    <Box width={24} mr={1.5}>
                                                        <Icon
                                                            path={mdiHelpCircleOutline}
                                                            size="24px"
                                                            color={COLORS.BLUE_2}
                                                        />
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        fontSize={14}
                                                        fontStyle="italic"
                                                        lineHeight={1.7}
                                                        flex={1}
                                                    >
                                                        {t("class.addClass.convertHint")}
                                                    </Box>
                                                </Box>
                                                <Button
                                                    onClick={() => {
                                                        navigate(
                                                            ROUTE_CLASS_GROUP_CALL_CONVERT.replace(
                                                                ":specification",
                                                                values.specification
                                                                    ? `${values.specification}`
                                                                    : "",
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    {t("class.addClass.convertBtn")}
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                    {errors.yearGroups &&
                                    errors.yearGroups[i] &&
                                    typeof errors.yearGroups[i] === "string" ? (
                                        <ErrorMessage name={`yearGroups.${i}`} />
                                    ) : (
                                        <></>
                                    )}
                                    {errors.yearGroups &&
                                    errors.yearGroups[i] &&
                                    errors.yearGroups[i]["classes"] &&
                                    typeof errors.yearGroups[i]["classes"] === "string" ? (
                                        <ErrorMessage name={`yearGroups.${i}.classes`} />
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </FormGroup>
                    {errors.yearGroups && typeof errors.yearGroups === "string" ? (
                        <ErrorMessage name="yearGroups" />
                    ) : (
                        <></>
                    )}
                </PaperInner>
            )}
        </Form>
    );
};

export default MisSyncUnsyncForm;
