import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { PARENT_EXPORTED_FILE_QUERY, useParentExportedFile } from "../hooks/useExportedFileLink";
import { useQueryClient } from "@tanstack/react-query";

const ParentUserExportContainer = () => {
    const { hash } = useParams();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { data: exportLink, isLoading } = useParentExportedFile(hash);

    useEffect(() => {
        return () => {
            queryClient.removeQueries({ queryKey: [PARENT_EXPORTED_FILE_QUERY, hash] });
        };
    }, []);

    useEffect(() => {
        if (exportLink?.link) {
            window.location.replace(exportLink?.link);
        } //eslint-disable-next-line
    }, [exportLink?.link]);

    return (
        <Box
            component="main"
            height="80vh"
            px={10}
            pt={14}
            pb={3}
            display="flex"
            flexDirection="column"
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    {t("parent.export.hint")}
                    {exportLink?.link && (
                        <a href={exportLink?.link} target={"blank"}>
                            {t("parent.export.link")}
                        </a>
                    )}
                </>
            )}
        </Box>
    );
};

export default ParentUserExportContainer;
