import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { ApiData, ApiStatus } from "src/api/constants";
import FormikObserver from "src/forms/FormikObserver";
import FormikRef from "src/forms/FormikRef";
import { usePrevious } from "src/hooks/usePrevious";
import { FormError, getErrorMessage } from "src/services/error";
import { AppState } from "src/store/reducers";
import { Form, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { createRef, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import * as yup from "yup";
import { FinanceManagerFormFields, financeManagerSchema } from "../components/FinancialManagerForm";
import SubscriptionTypeForm from "../components/SubscriptionTypeForm";
import SummaryBox from "../components/SummaryBox";
import TotalsTable from "../components/TotalsTable";
import { SubscriptionValidTypes, useSubscriptionValid } from "../hooks/useSubscriptionValid";
import { SubscriptionsActions } from "../store/actions";
import ConfirmationBox from "../components/ConfirmationBox";
import { TFunction } from "i18next";
import AppContainer from "src/components/AppContainer";
import HeadingCounter from "src/components/HeadingCounter";
import COLORS from "src/styles/colors";
import { theme } from "src/styles/theme";
import PaperInner from "src/components/PaperInner";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

interface WholeSchoolSubscriptionForm {
    period: string;
    couponName: string;
    financeManager: FinanceManagerFormFields;
}

export const upgradeSchema = (t: TFunction) =>
    yup.object().shape({
        period: yup.string(),
        financeManager: financeManagerSchema(t),
    });

const convertToRequestData = values => ({
    ...values,
    period: parseInt(values.period),
    couponName: values.couponName || null,
    financeManager:
        values.financeManager.title &&
        values.financeManager.email &&
        values.financeManager.firstName &&
        values.financeManager.lastName
            ? values.financeManager
            : undefined,
});

const dispatchActions = (dispatch: Dispatch) => ({
    upgradeToWholeSchoolSubscription: (values: WholeSchoolSubscriptionForm) => {
        dispatch(
            SubscriptionsActions.upgradeToWholeSchoolSubscription({
                values: convertToRequestData(values),
                params: undefined,
            }),
        );
    },
});

const WholeSchoolSubscription = () => {
    const dispatch = useDispatch();
    const form = createRef();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const { upgradeToWholeSchoolSubscription } = dispatchActions(dispatch);
    const { apiCreate }: { apiCreate: ApiData } = useSelector((state: AppState) => ({
        apiCreate: state.api.subscription.upgradeToWholeSchool,
    }));
    const prevApiStatus = usePrevious(apiCreate.status);

    const initialValues = {
        period: "1",
        couponName: "",
        financeManager: { firstName: "", lastName: "", email: "", title: "Mr" },
    };
    const [validState, setValidState] = useState<WholeSchoolSubscriptionForm | null>(null);
    const dataToValidator = validState || initialValues;

    const { result: validatorResponse, error: validatorError } = useSubscriptionValid(
        SubscriptionValidTypes.UPRGADE_TO_WHOLESCHOOL,
        convertToRequestData(dataToValidator),
        undefined,
        undefined,
        true,
    );

    const prevValidatorError = usePrevious(validatorError);

    if (validatorError !== null && prevValidatorError !== validatorError) {
        enqueueSnackbar(validatorError, {
            ...SnackbarErrorOptions,
        });
    }

    const handleError = useCallback(
        data => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.formError && error.formError["email"]) {
                        currentForm.setFieldError("financeManager.email", error.formError["email"]);
                    }
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
        },
        [enqueueSnackbar, form],
    );

    useEffect(() => {
        if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.SUCCESS) {
            setShowConfirmation(true);
        } else if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.ERROR) {
            handleError(apiCreate);
        }
    }, [apiCreate, enqueueSnackbar, handleError, prevApiStatus, t]);

    const handleRequestValid = (values: WholeSchoolSubscriptionForm) => {
        const convertedValues = convertToRequestData(values);
        upgradeSchema(t)
            .isValid(convertedValues)
            .then(isValid => {
                if (isValid) {
                    setValidState(values);
                }
            });
    };

    const handleSubmit = (values: WholeSchoolSubscriptionForm) => {
        upgradeToWholeSchoolSubscription(values);
    };

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("subscription.upgrade.wholeSchoolHeader")}
            </Typography>
            <FormikRef
                ref={form}
                initialValues={initialValues}
                validationSchema={() => upgradeSchema(t)}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<WholeSchoolSubscriptionForm>) => {
                    if (showConfirmation) {
                        return (
                            <ConfirmationBox
                                {...formProps}
                                additionalMessage={null}
                                isWholeSchool={true}
                            />
                        );
                    }
                    return (
                        <Form>
                            <FormikObserver
                                values={formProps.values}
                                onChange={handleRequestValid}
                            />
                            <Paper>
                                <PaperInner
                                    variant="subscription"
                                    border="bottom"
                                    color="lightGrey"
                                >
                                    <Grid container>
                                        <Grid item sm={6}>
                                            <Typography gutterBottom>
                                                <Box
                                                    component="span"
                                                    fontWeight={theme.typography.fontWeightMedium}
                                                >
                                                    {t(
                                                        "subscription.upgrade.wholeSchoolRebateHeader",
                                                    )}
                                                </Box>
                                            </Typography>
                                            <Typography>
                                                {t("subscription.upgrade.wholeSchoolRebateMessage")}
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={1} />
                                        <Grid item sm={5}>
                                            <Box
                                                px={4}
                                                py={3}
                                                lineHeight={1.71}
                                                bgcolor={COLORS.VERY_LIGHT_GREY_1}
                                                borderRadius={2}
                                            >
                                                <Box
                                                    fontSize={18}
                                                    fontWeight={theme.typography.fontWeightBold}
                                                    mb={1}
                                                    color={COLORS.BLUE_3}
                                                >
                                                    {validatorResponse &&
                                                    validatorResponse.subscriptionsRebate &&
                                                    parseFloat(
                                                        validatorResponse.subscriptionsRebate,
                                                    ) > 0
                                                        ? `-£${parseFloat(
                                                              validatorResponse.subscriptionsRebate,
                                                          )}`
                                                        : `£0`}
                                                </Box>
                                                <Box fontStyle="italic">
                                                    {t(
                                                        "subscription.upgrade.wholeSchoolRebateHint",
                                                    )}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </PaperInner>
                                <PaperInner>
                                    <Grid container>
                                        <Grid item sm={4}>
                                            <HeadingCounter number="1">
                                                {t("subscription.add.chooseSubject")}
                                            </HeadingCounter>
                                            <Box mb={4}>
                                                <Box
                                                    fontWeight={theme.typography.fontWeightBold}
                                                    component="span"
                                                >
                                                    {t("subscription.add.allSubjectAreas")}
                                                </Box>{" "}
                                                |{" "}
                                                <Box fontStyle="italic" component="span">
                                                    {t("subscription.add.nolimits")}
                                                </Box>
                                            </Box>
                                            <HeadingCounter number="2">
                                                {t("subscription.add.chooseCourses")}
                                            </HeadingCounter>
                                            <Box mb={4}>
                                                <Box
                                                    fontWeight={theme.typography.fontWeightBold}
                                                    component="span"
                                                >
                                                    {t("subscription.add.allCourses")}
                                                </Box>{" "}
                                                |{" "}
                                                <Box fontStyle="italic" component="span">
                                                    {t("subscription.add.nolimits")}
                                                </Box>
                                            </Box>
                                            <HeadingCounter number="3">
                                                {t("subscription.add.choosePeriod")}
                                            </HeadingCounter>
                                            <Box mb={4}>
                                                <SubscriptionTypeForm
                                                    fieldName="period"
                                                    {...formProps}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={3} />
                                        <Grid item xs={5}>
                                            <Box
                                                pt={3}
                                                pr={4}
                                                pb={4}
                                                pl={4}
                                                bgcolor={COLORS.VERY_LIGHT_GREY_1}
                                                borderBottom={`1px solid ${COLORS.VERY_LIGHT_GREY_3}`}
                                            >
                                                <Typography
                                                    variant="h4"
                                                    component="h2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    {t("subscription.summary.newSubscription")}
                                                </Typography>
                                                <SummaryBox
                                                    isWholeSchool={true}
                                                    period={`P${formProps.values.period}Y`}
                                                />
                                            </Box>
                                            <Box
                                                pt={3}
                                                pr={4}
                                                pb={4}
                                                pl={4}
                                                bgcolor={COLORS.VERY_LIGHT_GREY_1}
                                            >
                                                <TotalsTable
                                                    validatorResponse={validatorResponse}
                                                    {...formProps}
                                                />
                                                {validatorError === null &&
                                                    validatorResponse !== null && (
                                                        <Box mt={4}>
                                                            <Box pr={6.5} pb={2}>
                                                                <Typography variant="overline">
                                                                    {t(
                                                                        "subscription.summary.byClicking",
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                            <Button
                                                                color="primary"
                                                                onClick={formProps.submitForm}
                                                            >
                                                                {t(
                                                                    "subscription.upgrade.upgradeToWholeSchoolButton",
                                                                )}
                                                            </Button>
                                                            <GenericErrorMessage
                                                                errors={formProps.errors}
                                                                submitCount={formProps.submitCount}
                                                            />
                                                        </Box>
                                                    )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </PaperInner>
                            </Paper>
                        </Form>
                    );
                }}
            </FormikRef>
        </AppContainer>
    );
};

export default WholeSchoolSubscription;
