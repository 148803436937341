import clsx from "clsx";
import { FC } from "react";
import { Translation, useTranslation } from "react-i18next";
import { isBoolean } from "@martin_hotell/rex-tils";
import { CELL_SIZE_MIN } from "src/modules/tracker/services/headerGenerator";

interface OwnProps {
    value: string;
    context: any;
    colDef: any;
}

const StudentDataRenderer: FC<OwnProps> = ({ value, context, colDef: { field } }) => {
    const { t } = useTranslation();
    const normalizeValue = value => {
        if (isBoolean(value)) {
            return (
                <Translation>{t => <p>{value ? t("common.yes") : t("common.no")}</p>}</Translation>
            );
        }

        if (value !== null && typeof value === "object") {
            return value.name || value.id;
        }

        return value;
    };

    const isComparing = context.snapshotData && context.snapshotData.compare !== null;

    const fieldsToTranlsate = ["student_gender"];

    return (
        <div
            style={{ height: isComparing ? 56 : CELL_SIZE_MIN }}
            className={clsx(
                "ag-student-data-cell",
                isComparing && "ag-student-data-cell--compare",
                "ag-student-data-cell--truncate",
            )}
        >
            <span>
                {fieldsToTranlsate.includes(field) && value
                    ? t("common." + normalizeValue(value))
                    : normalizeValue(value)}
            </span>
        </div>
    );
};

export default StudentDataRenderer;
