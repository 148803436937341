import PromptDialog from "src/forms/PromptDialog";
import useDebounce from "src/hooks/useDebounce";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTrustUserSearch } from "../hooks/useTrustUserSearch";
import { ShortUserObject } from "src/forms/types";
import { useAddUserToTrustMutation } from "../hooks/useAddUserToTrustMutation";
import { useParams } from "react-router";
import { getTrustAccountId } from "src/services/url";

interface OwnProps {
    opened: boolean;
    handleClose: () => void;
}

const InviteTrustAdminModal: FC<OwnProps> = ({ opened, handleClose }) => {
    const { t } = useTranslation();
    const params = useParams();
    const [autocompleteInput, setAutocompleteInput] = useState<string>("");
    const debouncedAutocompleteInput = useDebounce(autocompleteInput, 500);
    const [selectedUser, setSelectedUser] = useState<ShortUserObject | null>(null);

    const { data: searchUsers } = useTrustUserSearch(
        parseInt(getTrustAccountId()),
        debouncedAutocompleteInput,
    );

    const { mutate: addUserToTrust, isPending, isSuccess } = useAddUserToTrustMutation();

    useEffect(() => {
        if (isSuccess) {
            handleClose();
        }
    }, [isSuccess]);

    return (
        <PromptDialog
            yesLabel={selectedUser ? t("trust.users.inviteBtn") : ""}
            noLabel={t("common.cancel")}
            open={opened}
            loading={isPending}
            onClose={confirmed => {
                if (confirmed) {
                    addUserToTrust({
                        schoolUserId: selectedUser.id,
                        trustId: parseInt(params.trust),
                    });
                }
                if (!confirmed) {
                    handleClose();
                }
            }}
        >
            <Box>
                <Typography variant={"h4"}>{t("trust.users.inviteModalHeader")}</Typography>
                <Autocomplete
                    freeSolo
                    options={
                        searchUsers?.length > 0
                            ? searchUsers.map(su => ({
                                  id: su.id,
                                  label: su.firstName + " " + su.lastName + ` (${su.email})`,
                              }))
                            : []
                    }
                    onInputChange={(_event, newInputValue) => {
                        setAutocompleteInput(newInputValue);
                    }}
                    onChange={(_e, value) => {
                        setSelectedUser(value as ShortUserObject);
                    }}
                    renderInput={params => {
                        return (
                            <TextField
                                {...params}
                                label={t("trust.users.autocompletePlaceholder")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        );
                    }}
                    forcePopupIcon={false}
                />
            </Box>
        </PromptDialog>
    );
};

export default InviteTrustAdminModal;
