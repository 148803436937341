import {
    ROUTE_AUTH_CONFIRM,
    ROUTE_AUTH_CONFIRMED,
    ROUTE_AUTH_LOGIN,
    ROUTE_AUTH_NEW_PASSWORD,
    ROUTE_AUTH_REGISTER,
    ROUTE_AUTH_RESET_PASSWORD,
    ROUTE_AUTH_INVITATION,
    ROUTE_AUTH_INVITED,
    ROUTE_AUTH_LOGIN_AS,
    ROUTE_PARENT_APP_PROXY,
    ROUTE_PARENT_APP_PROXY_REG,
    ROUTE_AUTH_POST_LOGIN,
    ROUTE_MERGE_ACCOUNT,
    ROUTE_LOGOUT,
} from "src/routes";
import ConfirmContainer from "../containers/ConfirmContainer";
import LoginContainer from "../containers/LoginContainer";
import NewPasswordContainer from "../containers/NewPasswordContainer";
import RegisterContainer from "../containers/RegisterContainer";
import ResetPasswordContainer from "../containers/ResetPasswordContainer";
import InvitationContainer from "../containers/InvitationContainer";
import InvitedContainer from "../containers/InvitedContainer";
import LoginAsContainer from "../containers/LoginAsContainer";
import ParentAppProxyContainer from "src/modules/common/containers/ParentAppProxy";
import PostLoginContainer from "../containers/PostLoginContainer";
import MergeAccountContainer from "../containers/MergeAccountContainer";
import LogoutContainer from "../containers/LogoutContainer";
// import SwitchAccountContainer from "../containers/SwitchAcountContainer";

export const AuthRoutes = [
    {
        path: ROUTE_PARENT_APP_PROXY,
        element: <ParentAppProxyContainer />,
    },
    {
        path: ROUTE_PARENT_APP_PROXY_REG,
        element: <ParentAppProxyContainer />,
    },
    { path: ROUTE_AUTH_RESET_PASSWORD, element: <ResetPasswordContainer /> },
    { path: ROUTE_AUTH_REGISTER, element: <RegisterContainer /> },
    { path: ROUTE_AUTH_INVITATION, element: <InvitationContainer /> },
    { path: ROUTE_AUTH_CONFIRM, element: <ConfirmContainer /> },
    { path: ROUTE_AUTH_NEW_PASSWORD, element: <NewPasswordContainer /> },
    { path: ROUTE_AUTH_LOGIN, element: <LoginContainer /> },
    { path: ROUTE_AUTH_LOGIN_AS, element: <LoginAsContainer /> },
    { path: ROUTE_AUTH_INVITED, element: <InvitedContainer /> },
    { path: ROUTE_AUTH_CONFIRMED, element: <ConfirmContainer /> },
    { path: ROUTE_AUTH_POST_LOGIN, element: <PostLoginContainer /> },
    { path: ROUTE_MERGE_ACCOUNT, element: <MergeAccountContainer /> },
    { path: ROUTE_LOGOUT, element: <LogoutContainer /> },
];
