import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { PARENT_LIST_QUERY } from "./useParentsList";
import { getSchoolAccountId } from "src/services/url";

export interface ParentInviteRequest {
    ids?: number[] | null;
}

const apiInviteParents = async (values: ParentInviteRequest): Promise<AxiosResponse> => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/parents/invite/`, values);
};

export const useParentInviteMutation = (onSuccess: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: ParentInviteRequest) => apiInviteParents(values),
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [PARENT_LIST_QUERY] });
            enqueueSnackbar(t("parent.requestModal.inviteSuccess"), { variant: "success" });
            if (onSuccess) onSuccess();
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
