import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmSnapshotReportActionTypes, OrmSnapshotReportActions } from "./actions";

export const snapshotReportReducer: ModelReducer = {
    [OrmSnapshotReportActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmSnapshotReportActions.bulkImport>,
        session,
    ) => {
        const { SnapshotReport } = session;
        SnapshotReport.all().delete();
        action.payload.data.forEach(e => SnapshotReport.create(ormToRef(e, {})));
    },
    [OrmSnapshotReportActionTypes.UPSERT]: (
        action: ReturnType<typeof OrmSnapshotReportActions.upsert>,
        session,
    ) => {
        const { SnapshotReport } = session;
        SnapshotReport.upsert(
            ormToRef(action.payload, {
                defaults: {},
            }),
        );
    },
};
