import { Box, MenuItem, TextField } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SnapshotReportFilterResponse } from "src/modules/report/api/SnapshotReport/getListFilters";
import { filterNotNullable } from "src/services/object";
import { ClassTrackerFilterResponse } from "../../api/Classes/filters";
import { AssessmentFiltersResponse } from "../../../assessment/hooks/useFilters";
// import { PastPaperFilterResponse } from "../../api/PastPapers/filters";

export interface SelectedTrackingFilters {
    yearGroup: string;
    subjectArea: string;
    qualification: string;
    page: number;
    perPage: number;
}

interface OwnProps {
    filters:
        | ClassTrackerFilterResponse
        | AssessmentFiltersResponse
        // | PastPaperFilterResponse
        | SnapshotReportFilterResponse
        | null;
    selectedFilters: SelectedTrackingFilters;
    handleFilterChange: (type: string, value: string) => void;
}

const TrackingFilters: FC<OwnProps> = ({ filters, selectedFilters, handleFilterChange }) => {
    const { t } = useTranslation();

    const getQualificationFilterOptions = (selectedFilters): any[] => {
        if (selectedFilters.subjectArea) {
            return (
                filters?.find(ctf => ctf.id === parseInt(selectedFilters.subjectArea))
                    ?.qualifications || []
            );
        }

        return (
            filters?.reduce((qFilters, currentFilter) => {
                if (currentFilter.qualifications && currentFilter.qualifications.length > 0) {
                    return qFilters.concat(currentFilter.qualifications as any);
                }
                return qFilters;
            }, []) || []
        );
    };

    const getFilteredYearGroups = (selectedFilters): number[] => {
        let yearGroups: number[] = [];
        const subjectAreas = filters?.filter(ctf => {
            if (selectedFilters.subjectArea) {
                return ctf.id === parseInt(selectedFilters.subjectArea);
            }
            return true;
        });
        subjectAreas?.forEach(sa => {
            const qualifications = sa.qualifications.filter(q => {
                if (selectedFilters.qualification) {
                    return q.id === parseInt(selectedFilters.qualification);
                }
                return true;
            });
            if (qualifications && qualifications.length > 0) {
                qualifications.forEach(q => {
                    yearGroups = yearGroups.concat(q.yearGroups);
                });
            }
        });
        const uniqueYearGroups = [...Array.from(new Set(yearGroups))];
        uniqueYearGroups.sort((a, b) => a - b);

        return uniqueYearGroups;
    };

    return (
        <Box display="flex" className="trackingFilters">
            <Box width={220}>
                <TextField
                    label={t("class.list.subjectAreaFilterLabel")}
                    select
                    SelectProps={{ displayEmpty: true }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="none"
                    name="subjectArea"
                    fullWidth
                    value={selectedFilters.subjectArea}
                    onChange={e => handleFilterChange("subjectArea", e.target.value)}
                >
                    <MenuItem value="">{t("common.all")}</MenuItem>
                    {filters &&
                        filterNotNullable(filters).map(sa => (
                            <MenuItem key={sa.id} value={sa.id}>
                                {sa.name}
                            </MenuItem>
                        ))}
                </TextField>
            </Box>
            <Box width={220} ml={3}>
                <TextField
                    label={t("class.list.qualificationFilterLabel")}
                    select
                    SelectProps={{ displayEmpty: true }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="none"
                    name="qualification"
                    fullWidth
                    value={selectedFilters.qualification}
                    onChange={e => handleFilterChange("qualification", e.target.value)}
                >
                    <MenuItem value="">{t("common.all")}</MenuItem>
                    {getQualificationFilterOptions(selectedFilters).map(q => (
                        <MenuItem key={q.id} value={q.id}>
                            {q.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box width={120} ml={3}>
                <TextField
                    label={t("class.list.yearGroupFilterLabel")}
                    select
                    SelectProps={{ displayEmpty: true }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="none"
                    name="subjectArea"
                    fullWidth
                    value={selectedFilters.yearGroup}
                    onChange={e => handleFilterChange("yearGroup", e.target.value)}
                >
                    <MenuItem value="">{t("common.all")}</MenuItem>
                    {getFilteredYearGroups(selectedFilters).map(yg => (
                        <MenuItem key={yg} value={yg}>
                            {yg === 0 ? t("common.yg0") : yg}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        </Box>
    );
};

export default TrackingFilters;
