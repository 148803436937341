import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ShortUserObject } from "src/forms/types";

export const TRUST_USER_SEARCH_QUERY = "trustUserSearchQuery";

export const useTrustUserSearch = (trustId: number, filter: string) => {
    return useQuery({
        queryKey: [TRUST_USER_SEARCH_QUERY, trustId, filter],
        queryFn: async (): Promise<ShortUserObject<number>[] | null> => {
            if (trustId && filter) {
                const { data } = await axiosInstance.get(
                    `trust/${trustId}/search-user/?filter=${filter}`,
                );

                return data || null;
            }
            return null;
        },
    });
};
