import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const AUTO_SUGGEST_CLASS_LIST_QUERY = "autoSuggestClassListQuery";

export interface AutoSuggestClassListItem {
    groupCallGroups: {
        id: number;
        name: string;
    }[];
    subjectArea: {
        parentName: string;
        colour: string;
        id: number;
        name: string;
    };
    qualification: {
        id: number;
        name: string;
    };
    specification: {
        parentName: string;
        id: number;
        name: string;
    };
    discontinued: boolean;
    hasSubscription: boolean;
    existingClassTrackers: {
        id: number;
        name: string;
        groupCallStatus: string;
    }[];
}

export const useAutoSuggestClassList = (
    sourceAcademicYear: number | "",
    sourceYearGroup: number | "",
    targetAcademicYear: number | "",
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            AUTO_SUGGEST_CLASS_LIST_QUERY,
            schoolAccountId,
            sourceAcademicYear,
            sourceYearGroup,
            targetAcademicYear,
        ],
        queryFn: async (): Promise<AutoSuggestClassListItem[] | null> => {
            if (sourceAcademicYear && sourceYearGroup !== "" && targetAcademicYear) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/auto-suggest-trackers/${sourceAcademicYear}/${sourceYearGroup}/${targetAcademicYear}/`,
                );

                return data;
            }
            return null;
        },

        enabled: false,
        retry: false,
    });
};
