import { isValueSet } from "src/services/utilsGPT";
import {
    AttributePair,
    FiltersShape,
    studentValueMinMaxFilters,
} from "../components/Grid/GridStudentFilter";

const filterRowFromFiltersByStudentParams = (
    filter: AttributePair,
    initialValuesRow,
    gridHeader?,
    hasFineGrades?,
) => {
    const field = "student_" + filter.attribute;
    const isTPG = !!gridHeader?.columns[filter.attribute]?.columnConfig?.type;

    if (initialValuesRow[field] === null) {
        if (filter.values.find(fV => fV.value === "no-data")) return true;
    }

    //Fine Grades range
    if (hasFineGrades && isTPG && filter) {
        const currentValue = parseFloat(`${initialValuesRow[field]}`);
        if (!currentValue) {
            return false;
        }

        const currentFine = initialValuesRow?.metaColumnsConfig?.[field]?.fineGrade || "";

        return filter.values.some(fV => {
            const value = fV.value as string;
            const valueTo = fV.valueTo as string;

            const minValue = parseFloat(value);
            const maxValue = parseFloat(valueTo);

            let minFine = value?.charAt(value.length - 1);
            minFine = minFine === "-" || minFine === "+" ? minFine : "";
            let maxFine = valueTo?.charAt(valueTo.length - 1);
            maxFine = maxFine === "-" || maxFine === "+" ? maxFine : "";

            const isCurrentFineAboveMin =
                minFine === "-" ||
                (minFine === "" && (currentFine === "" || currentFine === "+")) ||
                minFine === currentFine;
            const isCurrentFineBelowMax =
                maxFine === "+" ||
                (maxFine === "" && (currentFine === "" || currentFine === "-")) ||
                maxFine === currentFine;

            const isValueWithFineAboveMin =
                currentValue > minValue || (currentValue === minValue && isCurrentFineAboveMin);
            const isValueWithFineBelowMax =
                currentValue < maxValue || (currentValue === maxValue && isCurrentFineBelowMax);
            if (isValueWithFineAboveMin && isValueWithFineBelowMax) {
                return true;
            }
            return false;
        });
    }
    //is value in a filter range
    if (studentValueMinMaxFilters.includes(filter.attribute)) {
        let isValueInRange = false;
        filter.values.forEach(fV => {
            const value = parseFloat(`${initialValuesRow[field]}`);
            const minValue = parseFloat(fV.value as string);
            const maxValue = parseFloat(fV.valueTo as string);
            if (
                (!isNaN(minValue) && isNaN(maxValue) && value >= minValue) ||
                (isNaN(minValue) && !isNaN(maxValue) && value <= maxValue) ||
                (!isNaN(minValue) && !isNaN(maxValue) && value >= minValue && value <= maxValue)
            ) {
                isValueInRange = true;
            }
        });
        //there is no not verified filter or value is in some range
        return isValueInRange;
    }

    //check is value and one of filters are equal
    if (
        !filter.values.find(
            ({ value }) => `${initialValuesRow[field]}`.toUpperCase() === `${value}`.toUpperCase(),
        )
    ) {
        return false;
    }

    //default
    return true;
};

const filterRowFromFiltersMtg = (filter: AttributePair, initialValuesRow) => {
    const { values } = filter;
    const field = filter.attribute.replace("mtg-", "");
    let isMtgVisible = false;

    if (!initialValuesRow?.metaColumnsConfig?.[field]) {
        isMtgVisible = values.some(({ value }) => (value as string).split(",").includes("N"));
    } else {
        const { mtgDistance: rowMtgDistance } = initialValuesRow.metaColumnsConfig[field];

        values.forEach(({ value }) =>
            (value as string).split(",").forEach(filterMtgValue => {
                const filterValue = parseInt(filterMtgValue);
                const rowValue = parseInt(rowMtgDistance);

                if (
                    filterValue === rowValue ||
                    (rowValue > 1 && filterValue === 1) ||
                    (rowValue <= -2 && filterValue === -2) ||
                    (filterValue === -99 && !isValueSet(rowValue))
                ) {
                    isMtgVisible = true;
                }
            }),
        );
    }

    return isMtgVisible;
};

export const filterRowFromFilters = (
    gridFilters: FiltersShape,
    initialValuesRow,
    atlColumnsMap?,
    gridHeader?,
    hasFineGrades?,
) => {
    let isVisible = true;

    for (const key in gridFilters) {
        const isStudent = gridFilters[key].type === "student";
        const isMtg = key.indexOf("mtg-") === 0;
        const isAtl = key.indexOf("atl-") === 0;
        const isTag = key === "tags";

        if (isMtg) {
            isVisible = filterRowFromFiltersMtg(gridFilters[key], initialValuesRow);
            if (!isVisible) break;
        } else if (isTag) {
            let isTagVisible = false;
            const values = gridFilters["tags"].values
                .flatMap(({ value }) => value)
                .join(",")
                .split(",")
                .map(v => parseInt(v));
            values.forEach(v => {
                if (initialValuesRow["tag-" + v] && initialValuesRow["tag-" + v] === 1) {
                    isTagVisible = true;
                }
            });
            isVisible = isTagVisible;
            if (!isVisible) break;
        } else if (isAtl && atlColumnsMap && Object.keys(atlColumnsMap).length > 0) {
            const altField = key.replace("atl-", "");
            const field = atlColumnsMap[altField];

            const initialValue = initialValuesRow[field];
            isVisible = gridFilters[key].values.some(({ value }) => {
                const values = (value as string).split("|").map(v => parseInt(v));
                if (
                    (!isNaN(values[0]) && isNaN(values[1]) && initialValue >= values[0]) ||
                    (isNaN(values[0]) && !isNaN(values[1]) && initialValue <= values[1]) ||
                    (!isNaN(values[0]) &&
                        !isNaN(values[1]) &&
                        initialValue >= values[0] &&
                        initialValue <= values[1])
                ) {
                    return true;
                }
                return false;
            });
            if (!isVisible) break;
        } else if (isStudent) {
            isVisible = filterRowFromFiltersByStudentParams(
                gridFilters[key],
                initialValuesRow,
                gridHeader,
                hasFineGrades,
            );
            if (!isVisible) break;
        } else if (key === "fromDateJoined" || key === "toDateJoined") {
            const studentDateJoined = initialValuesRow["student_dateJoined"];

            if (!studentDateJoined) {
                isVisible = false;
                break;
            }

            const splitedDate = studentDateJoined.split("/");
            if (splitedDate.length !== 3) {
                continue;
            }

            const convertedDate = new Date(splitedDate[2], splitedDate[1] - 1, splitedDate[0]);

            if (
                !gridFilters[key].values.some(({ value }) => {
                    const filterDate = new Date(value);

                    if (
                        (gridFilters[key].attribute === "fromDateJoined" &&
                            convertedDate < filterDate) ||
                        (gridFilters[key].attribute === "toDateJoined" &&
                            convertedDate > filterDate)
                    ) {
                        return false;
                    }
                    return true;
                })
            ) {
                isVisible = false;
                break;
            }
        } else if (key === "ALL_GRADES") {
            if (
                !gridFilters[key].values.some(({ value }) => {
                    if (value === "no-data") {
                        const noDataColumns = Object.keys(initialValuesRow).filter(
                            columnKey =>
                                columnKey.indexOf("spec_") === 0 &&
                                initialValuesRow[columnKey] === null,
                        );
                        if (noDataColumns.length > 0) return true;
                    }
                    return false;
                })
            ) {
                isVisible = false;
                break;
            }
        } else if (gridFilters[key].values) {
            if (
                !gridFilters[key].values.some(({ value, valueTo }) => {
                    if (value === "no-data" && initialValuesRow[key] === null) {
                        return true;
                    } else if (
                        value &&
                        !isNaN(parseInt(`${value}`)) &&
                        valueTo &&
                        !isNaN(parseInt(`${valueTo}`)) &&
                        initialValuesRow[key] !== null &&
                        initialValuesRow[key] !== undefined
                    ) {
                        return (
                            parseFloat(`${value}`) <= parseFloat(initialValuesRow[key]) &&
                            parseFloat(initialValuesRow[key]) <= parseFloat(`${valueTo}`)
                        );
                    } else if (value && (!valueTo || isNaN(valueTo as number))) {
                        return parseFloat(`${value}`) <= parseFloat(initialValuesRow[key]);
                    } else if (valueTo && (!value || isNaN(value as number))) {
                        return parseFloat(initialValuesRow[key]) <= parseFloat(`${valueTo}`);
                    }

                    return false;
                })
            ) {
                isVisible = false;
                break;
            }
        }
    }

    return isVisible;
};
