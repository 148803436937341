import { Box, styled, BoxProps } from "@mui/material";
import COLORS from "src/styles/colors";

const DotsContainer = styled(Box)<BoxProps>(({ theme }) => ({
    height: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    flex: 1,
}));

const Dot = styled(Box)<BoxProps>(({ theme }) => ({
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: theme.spacing(0, 1),
    backgroundColor: COLORS.LIGHT_GREY_3,
    borderRadius: theme.spacing(1),
    "@keyframes dotAnimation": {
        "0%": {
            opacity: 1,
        },
        "30%": {
            opacity: 0.2,
        },
        "60%": {
            opacity: 1,
        },
        "100%": {
            opacity: 1,
        },
    },
    "&.dot1": {
        animation: "dotAnimation 1.2s ease-in-out infinite",
    },
    "&.dot2": {
        animation: "dotAnimation 1.2s ease-in-out 0.24s infinite",
    },
    "&.dot3": {
        animation: "dotAnimation 1.2s ease-in-out 0.48s infinite",
    },
}));

const DotsProgress = () => {
    return (
        <DotsContainer>
            <Dot className="dot1" />
            <Dot className="dot2" />
            <Dot className="dot3" />
        </DotsContainer>
    );
};

export default DotsProgress;
