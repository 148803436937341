import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { FileType } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const schoolLogoEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/account/logo/`,
    method: ApiRequests.POST,
};

export interface SchoolLogoRequest {
    logoFile: FileType;
}
