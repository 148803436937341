import {
    ROUTE_CLASS_EDIT_CLASS,
    ROUTE_CLASS_BULK_EDIT,
    ROUTE_CLASS_GROUP_CALL_CONVERT,
    ROUTE_CLASS_CLASS_LIST,
    ROUTE_CLASS_CLASS_LIST_COHORT,
    ROUTE_CLASS_MIGRATE,
    ROUTE_CLASS_MIGRATE_SYNC,
    ROUTE_CLASS_ADD_CLASS_AUTOSUGGEST,
    ROUTE_CLASS_ADD_MANUAL_XLS,
    ROUTE_CLASS_ADD_MANUAL_EXISTING_STUDENTS,
    ROUTE_CLASS_ADD_OTHER_UPLOAD,
    ROUTE_CLASS_ADD_OTHER_DEMO,
    ROUTE_CLASS_ADD_CLASS_SYNCHRONIZE,
    ROUTE_CLASS_ADD_CLASS_UNSYNCHRONIZE,
} from "src/routes";
import EditClassContainer from "../containers/EditClassContainer";
import BulkEditClassesContainer from "../containers/BulkEditClassesContainer";
import ConvertGroupCallClassContainer from "../containers/ConvertGroupCallClass";
import ClassTrackingListContainer from "../containers/ClassTrackingListContainer";
import MigrateContainer from "../containers/Migrate/MigrateContainer";
import AddClassAutoSuggestTrackerContainer from "../containers/AddClass/AutoSuggestTrackerContainer";
import AddClassManualXlsUploadContainer from "../containers/AddClass/ManualXlsUploadContainer";
import AddClassManualExistingStudentsContainer from "../containers/AddClass/ManualExistingStudentsContainer";
import AddClassOtherUploadClassContainer from "../containers/AddClass/OtherUploadClassContainer";
import AddClassOtherDemoClassContainer from "../containers/AddClass/OtherDemoClassContainer";
import AddClassMisSynchroniseClassContainer from "../containers/AddClass/MisSynchroniseClassContainer";
import AddClassMisUnsynchronisedClassContainer from "../containers/AddClass/MisUnsynchronisedClassContainer";

export const ClassRoutes = [
    {
        path: ROUTE_CLASS_GROUP_CALL_CONVERT,
        element: <ConvertGroupCallClassContainer />,
    },
    {
        path: ROUTE_CLASS_BULK_EDIT,
        element: <BulkEditClassesContainer />,
    },
    {
        path: ROUTE_CLASS_EDIT_CLASS,
        element: <EditClassContainer />,
    },
    {
        path: ROUTE_CLASS_ADD_CLASS_SYNCHRONIZE,
        element: <AddClassMisSynchroniseClassContainer />,
    },
    {
        path: ROUTE_CLASS_ADD_CLASS_UNSYNCHRONIZE,
        element: <AddClassMisUnsynchronisedClassContainer />,
    },
    {
        path: ROUTE_CLASS_ADD_CLASS_AUTOSUGGEST,
        element: <AddClassAutoSuggestTrackerContainer />,
    },
    {
        path: ROUTE_CLASS_ADD_MANUAL_XLS,
        element: <AddClassManualXlsUploadContainer />,
    },
    {
        path: ROUTE_CLASS_ADD_MANUAL_EXISTING_STUDENTS,
        element: <AddClassManualExistingStudentsContainer />,
    },
    {
        path: ROUTE_CLASS_ADD_OTHER_UPLOAD,
        element: <AddClassOtherUploadClassContainer />,
    },
    {
        path: ROUTE_CLASS_ADD_OTHER_DEMO,
        element: <AddClassOtherDemoClassContainer />,
    },
    {
        path: ROUTE_CLASS_CLASS_LIST_COHORT,
        element: <ClassTrackingListContainer />,
    },
    {
        path: ROUTE_CLASS_CLASS_LIST,
        element: <ClassTrackingListContainer />,
    },
    {
        path: ROUTE_CLASS_MIGRATE,
        element: <MigrateContainer />,
    },
    {
        path: ROUTE_CLASS_MIGRATE_SYNC,
        element: <MigrateContainer />,
    },
];
