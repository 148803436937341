import { SchoolActions, SchoolActionTypes } from "./actions";
import { filterNotNullable } from "src/services/object";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
export interface StaffFilterParams {
    name: string;
    status?: string;
    statusSync?: string;
    page: number;
    perPage: number;
}

export interface SchoolState {
    staffFilter: {
        requestParams: StaffFilterParams | null;
    };

    subjectAreaFromSubscriptions: (number | null)[];
    subjectAreaUserList: { [key: string]: { leaders: number[]; teachers: number[] } };
    schoolStudentCount: number | null;
}

export const initialState: SchoolState = {
    subjectAreaFromSubscriptions: [],
    subjectAreaUserList: {},
    schoolStudentCount: null,
    staffFilter: { requestParams: null },
};

export const reducer = (state: SchoolState = initialState, action: SchoolActions): SchoolState => {
    switch (action.type) {
        case SchoolActionTypes.SET_STAFF_FILTER:
            return {
                ...state,
                staffFilter: action.payload,
            };
        case SchoolActionTypes.SET_SUBJECT_AREA_USERS:
            return {
                ...state,
                subjectAreaUserList: {
                    ...state.subjectAreaUserList,
                    [`sa-${action.payload.subjectArea}`]: {
                        teachers: filterNotNullable(
                            action.payload.data.teachers as { id: number }[],
                        ).map(t => t.id),
                        leaders: filterNotNullable(
                            action.payload.data.leaders as { id: number }[],
                        ).map(l => l.id),
                    },
                },
            };
        case SchoolActionTypes.SET_SUBJECT_AREA_FROM_SUBSCRIPTIONS:
            return {
                ...state,
                subjectAreaFromSubscriptions: filterNotNullable(action.payload).map(
                    (sa: OrmSubjectArea) => sa.id,
                ),
            };
        default:
            return state;
    }
};
