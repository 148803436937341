import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useTranslation } from "react-i18next";
import { AnalysisUserSettingsObject, USER_SETTINGS_QUERY } from "./useUserSettings";
import { getSchoolAccountId } from "src/services/url";

const apiUpdateUserSettings = async (
    cohort: number,
    yearGroup: number,
    viewType: string,
    values: AnalysisUserSettingsObject,
): Promise<AxiosResponse<AnalysisUserSettingsObject>> => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/analysis/common/${cohort}/${yearGroup}/${viewType}/user-settings/`,
        values,
    );
};

export const useUserSettingsEditMutation = (
    cohort: number,
    yearGroup: number,
    viewType: string,
    onSuccess?: () => void,
    onError?: any,
) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: AnalysisUserSettingsObject) =>
            apiUpdateUserSettings(cohort, yearGroup, viewType, values),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [USER_SETTINGS_QUERY, getSchoolAccountId(), cohort, yearGroup, viewType],
            });
            if (onSuccess) {
                enqueueSnackbar(t("common.saved"), { variant: "success" });
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError) {
                onError();
            }
        },
    });
};
