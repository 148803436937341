import { Dispatch } from "redux";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { AnalysisActions } from "../store/actions";
import { KS4ViewGrades, KS4ViewTypes } from "../api/KS4Grade/getValues";
import { useEffect, useState } from "react";
import { ApiStatus } from "src/api/constants";
import { prepareStudentFilters } from "../services/analysisValueHandler";
import { StudentFilterRequest } from "../hooks/GradeSumary/useAnalysisGradeSummaryValues";

export interface StudentAttributePair {
    attribute: string;
    attributeDisplay: string;
    value: string;
    valueDisplay: string;
}

const dispatchActions = (dispatch: Dispatch) => ({
    getAnalysisKS4GradeHeader: (cohort: number, yearGroup: number) => {
        dispatch(AnalysisActions.getAnalysisKS4GradeHeader(cohort, yearGroup));
    },
    getAnalysisRelated: (cohort: number, yearGroup: number) => {
        dispatch(AnalysisActions.getAnalysisRelated(cohort, yearGroup));
    },
    getAnalysisKS4GradeValues: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        studentFilters: StudentFilterRequest,
    ) => {
        dispatch(
            AnalysisActions.getAnalysisKS4GradeValues(
                cohort,
                yearGroup,
                viewGrade,
                viewType,
                studentFilters,
            ),
        );
    },
    getAnalysisKs4ForecastValues: (
        cohort: number,
        yearGroup: number,
        viewType: KS4ViewTypes,
        studentFilters: StudentFilterRequest,
    ) => {
        dispatch(
            AnalysisActions.getAnalysisKS4ForecastValues(
                cohort,
                yearGroup,
                viewType,
                studentFilters,
            ),
        );
    },
    clearAnalysisData: () => {
        dispatch(AnalysisActions.clearAllAnalysisData());
    },
    getSnapshotCompareValues: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        dataSource1: string,
        dataSource2: string,
        studentFilters: StudentAttributePair[],
    ) => {
        dispatch(
            AnalysisActions.getAnalysisKS4GradeSnapshotCompare(
                cohort,
                yearGroup,
                viewGrade,
                viewType,
                dataSource1,
                dataSource2,
                prepareStudentFilters(studentFilters),
            ),
        );
    },
    getSnapshotValues: (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        snapshot: number,
        studentFilters: StudentAttributePair[],
    ) => {
        dispatch(
            AnalysisActions.getAnalysisKS4GradeSnapshot(
                cohort,
                yearGroup,
                viewGrade,
                viewType,
                snapshot,
                prepareStudentFilters(studentFilters),
            ),
        );
    },
});

export const useAnalysisKS4GradeData = (studentFilters: StudentAttributePair[]) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const {
        getAnalysisKS4GradeHeader,
        getAnalysisKS4GradeValues,
        getAnalysisRelated,
        getAnalysisKs4ForecastValues,
        clearAnalysisData,
        getSnapshotCompareValues,
        getSnapshotValues,
    } = dispatchActions(dispatch);

    const getAnalysisData = (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
    ) => {
        getAnalysisRelated(cohort, yearGroup);
        getAnalysisKS4GradeHeader(cohort, yearGroup);
        getAnalysisKS4GradeValues(
            cohort,
            yearGroup,
            viewGrade,
            viewType,
            prepareStudentFilters(studentFilters),
        );
    };

    const getAnalysisForecastData = (cohort: number, yearGroup: number, viewType: KS4ViewTypes) => {
        getAnalysisRelated(cohort, yearGroup);
        getAnalysisKS4GradeHeader(cohort, yearGroup);
        getAnalysisKs4ForecastValues(
            cohort,
            yearGroup,
            viewType,
            prepareStudentFilters(studentFilters),
        );
    };

    const getSnapshotCompareData = (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        dataSource1: string,
        dataSource2: string,
        studentFilters: StudentAttributePair[],
    ) => {
        getAnalysisRelated(cohort, yearGroup);
        getAnalysisKS4GradeHeader(cohort, yearGroup);
        getSnapshotCompareValues(
            cohort,
            yearGroup,
            viewGrade,
            viewType,
            dataSource1,
            dataSource2,
            studentFilters,
        );
    };

    const getSnapshotData = (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        snapshot: number,
        studentFilters: StudentAttributePair[],
    ) => {
        getAnalysisRelated(cohort, yearGroup);
        getAnalysisKS4GradeHeader(cohort, yearGroup);
        getSnapshotValues(cohort, yearGroup, viewGrade, viewType, snapshot, studentFilters);
    };

    const refetchValues = (
        cohort: number,
        yearGroup: number,
        viewGrade: KS4ViewGrades,
        viewType: KS4ViewTypes,
        studentFilters: StudentAttributePair[],
    ) => {
        getAnalysisKS4GradeValues(
            cohort,
            yearGroup,
            viewGrade,
            viewType,
            prepareStudentFilters(studentFilters),
        );
    };

    const {
        apiHeader,
        apiValues,
        apiForecast,
        apiRelated,
        apiCompare,
        analysisKS4GradeHeader,
        analysisKS4GradeCompareValues,
        analysisRelated,
        analysisKS4GradeValues,
        analysisStudents,
    } = useSelector(
        (state: AppState) => ({
            apiHeader: state.api.analysis.getAnalysisKS4GradeHeader,
            apiRelated: state.api.analysis.getAnalysisRelated,
            apiForecast: state.api.analysis.getAnalysisKS4ForecastValues,
            apiValues: state.api.analysis.getAnalysisKS4GradeValues,
            apiCompare: state.api.analysis.getAnalysisKS4GradeSnapshotCompare,
            analysisKS4GradeHeader: state.analysis.analysisKS4GradeHeader,
            analysisKS4GradeValues: state.analysis.analysisKS4GradeValues,
            analysisKS4GradeCompareValues: state.analysis.analysisKS4GradeCompare,
            analysisRelated: state.analysis.analysisRelated,
            analysisStudents: state.analysis.analysisStudents,
        }),
        shallowEqual,
    );

    const isDataFetched =
        analysisKS4GradeHeader && (analysisKS4GradeValues || analysisKS4GradeCompareValues);

    useEffect(() => {
        let isHeaderLoading = false;
        let isValuesLoading = false;

        if (apiHeader.status !== ApiStatus.SUCCESS || apiRelated.status !== ApiStatus.SUCCESS) {
            isHeaderLoading = true;
        }
        if (
            apiValues.status !== ApiStatus.SUCCESS ||
            apiForecast.status !== ApiStatus.SUCCESS ||
            apiCompare.status !== ApiStatus.SUCCESS
        ) {
            isValuesLoading = true;
        }

        if (apiHeader.status === ApiStatus.SUCCESS && apiRelated.status === ApiStatus.SUCCESS) {
            isHeaderLoading = false;
        }

        if (
            apiValues.status === ApiStatus.SUCCESS ||
            apiForecast.status === ApiStatus.SUCCESS ||
            apiCompare.status === ApiStatus.SUCCESS
        ) {
            isValuesLoading = false;
        }

        if (!isHeaderLoading && !isValuesLoading) {
            setIsLoading(false);
        }
        // eslint-disable-next-linea  react-hooks/exhaustive-deps
    }, [apiHeader, apiValues, apiForecast, apiRelated, apiCompare]);

    return {
        apiHeader,
        apiValues,
        apiRelated,
        analysisKS4GradeHeader,
        analysisKS4GradeValues,
        analysisKS4GradeCompareValues,
        analysisRelated,
        getAnalysisData,
        getSnapshotCompareData,
        getAnalysisForecastData,
        clearAnalysisData,
        isDataFetched,
        isLoading,
        setIsLoading,
        refetchValues,
        analysisStudents,
        getSnapshotData,
    };
};
