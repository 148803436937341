import { TFunction } from "i18next";

export const steps = (t: TFunction): string[] => {
    return [
        `${t("auth.register.steps.school")}`,
        `${t("auth.register.steps.subjectArea")}`,
        `${t("auth.register.steps.details")}`,
        `${t("auth.register.steps.credentials")}`,
        `${t("auth.register.steps.terms")}`,
        `${t("auth.register.steps.confirm")}`,
        `${t("auth.register.steps.contact")}`,
        `${t("auth.register.steps.finish")}`,
    ];
};
