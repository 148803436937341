import clsx from "clsx";
import { StepIconProps } from "@mui/material/StepIcon";
import COLORS from "src/styles/colors";
import {
    StepConnector,
    StepConnectorProps,
    StepLabel,
    StepLabelProps,
    styled,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

export const CustomConnector = styled(StepConnector)<StepConnectorProps>(() => ({
    "&.MuiStepConnector-alternativeLabel": {
        width: "100%",
        top: 4,
        left: "-50%",
        right: "auto",
    },
    "&.Mui-active": {
        ".MuiStepConnector-line": {
            borderColor: COLORS.BLUE_1,
        },
    },
}));

const useCustomStepIconStyles = makeStyles({
    root: {
        color: COLORS.LIGHT_GREY_2,
        display: "flex",
        height: 8,
        alignItems: "center",
        position: "relative",
        zIndex: 10,
    },
    active: {
        color: COLORS.BLUE_1,
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor",
    },
    completed: {
        color: COLORS.BLUE_1,
        zIndex: 1,
        fontSize: 18,
    },
});

export const CustomStepIcon = (props: StepIconProps) => {
    const classes = useCustomStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            <div
                className={clsx(classes.circle, {
                    [classes.completed]: completed,
                })}
            />
        </div>
    );
};

export const CustomStepLabel = styled(StepLabel)<StepLabelProps>(() => ({
    position: "relative",
    "& .MuiStepLabel-label": {
        display: "none",
        marginTop: "0 !important",
        textAlign: "center",
        position: "absolute",
        top: 15,
        whiteSpace: "nowrap",
        left: "50%",
        transform: "translateX(-50%)",
    },
    "& .Mui-active": {
        display: "block",
        fontSize: "0.75rem",
        fontWeight: 400,
        color: `${COLORS.BLUE_1} !important`,
    },
}));
