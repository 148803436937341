import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface AssessmentCreateRequest {
    specification: {
        id: number;
    };
    academicYear: number;
    yearGroup: number;
    publishedAssessments: {
        unit: {
            id: number;
        };
        module: {
            id: number;
        };
        name: string;
        countsTowardGrade: boolean;
        hiddenColumns: boolean;
        visibleClassTrackerIds: number[];
        showQlaInReports: boolean;
    }[];
    customisedAssessments: {
        gradeBoundaryFactor: number;
        examDate: string;
        tierUnits: {
            tier: TiersTypes;
            unit: {
                id: number;
            };
        }[];
        personalisedAssesmentQuestions: {
            question: string;
            maxValue: number;
            topics: {
                id: number;
            }[];
        }[];
        gradeBoundaryValues: {
            id: number;
            requireMark: number;
        }[];
        name: string;
        countsTowardGrade: boolean;
        hiddenColumns: boolean;
        visibleClassTrackerIds: number[];
        showQlaInReports: boolean;
    }[];
}

const apiCreateAssessment = async (values: AssessmentCreateRequest) => {
    return await axiosInstance.post(`school/${getSchoolAccountId()}/assessments/`, values);
};

export const useAssessmentCreateMutation = (onSuccess, onError?) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (values: AssessmentCreateRequest) => apiCreateAssessment(values),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error && typeof err?.response?.data?.error === "string") {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError && err?.response?.data?.errors) {
                onError(err?.response?.data?.errors);
            }
        },
    });
};
