import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { OrmSubscription } from ".";

export enum OrmSubscriptionActionTypes {
    BULK_IMPORT = "[orm] SUBSCRIPTION BULK IMPORT",
    UPSERT = "[orm] SUBSCRIPTION UPSERT",
}

export const OrmSubscriptionActions = {
    bulkImport: (payload: OrmSubscription[]) =>
        createAction(OrmSubscriptionActionTypes.BULK_IMPORT, payload),
    upsert: (payload: OrmSubscription) => createAction(OrmSubscriptionActionTypes.UPSERT, payload),
};

export type OrmSubscriptionActions = ActionsUnion<typeof OrmSubscriptionActions>;
