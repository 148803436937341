import AuthContainer from "src/components/AuthContainer";
import COLORS from "src/styles/colors";
import Icon from "src/components/Icon";
import ReCAPTCHA from "react-google-recaptcha";
import { createRef, useEffect } from "react";
import { Button, Box, FormHelperText, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ROUTE_AUTH_RESET_PASSWORD, ROUTE_AUTH_REGISTER } from "src/routes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { loginSchema } from "../components/Login/loginSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { mdiHelpCircleOutline } from "@mdi/js";
import { LoginRequest, useLogin } from "../hooks/useLogin";
import { FormProvider, RHFTextField } from "src/components/hookFormComponents";
import { LoadingButton } from "@mui/lab";
import { useAuthContext } from "src/context/AuthContext";

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "underline",
        fontWeight: 600,
    },
}));

const LoginContainer = () => {
    const { t } = useTranslation();
    const { clearAuthContext } = useAuthContext();
    const methods = useForm({
        resolver: yupResolver(loginSchema(t)),
        mode: "all",
        defaultValues: {
            email: "",
            password: "",
            responseToken: "",
        },
    });
    const {
        formState: { errors, isSubmitting },
        reset,
        setValue,
    } = methods;

    const classes = useStyles();
    const captchaRef = createRef() as any;
    const { mutate: login, isPending, isError } = useLogin();

    const onSubmitHandler = async (values: LoginRequest) => {
        clearAuthContext();
        login({
            email: values.email,
            password: values.password,
            responseToken: values.responseToken,
        });
    };

    useEffect(() => {
        if (isError) {
            captchaRef?.current?.reset();
            reset();
        }
    }, [isError]);

    return (
        <AuthContainer>
            <FormProvider methods={methods} onSubmit={onSubmitHandler}>
                <RHFTextField
                    name="email"
                    type="email"
                    label={t("common.form.label.email")}
                    autoFocus
                    margin="normal"
                />
                <RHFTextField
                    password
                    label={t("common.form.label.password")}
                    name="password"
                    autoComplete="current-password"
                />
                <Box width={1} mt={3}>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        ref={captchaRef}
                        onChange={(token: string) => setValue("responseToken", token)}
                    />
                    <FormHelperText error>{(errors as any)?.responseToken?.message}</FormHelperText>
                </Box>
                <Box
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent="space-between"
                    width={1}
                    mt={5}
                >
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={isSubmitting || isPending}
                        disabled={isSubmitting}
                    >
                        {t("auth.login.signIn")}
                    </LoadingButton>
                    <Button
                        component={Link}
                        to={ROUTE_AUTH_RESET_PASSWORD}
                        variant="text"
                        disabled={isSubmitting}
                        startIcon={<Icon path={mdiHelpCircleOutline} />}
                        disableRipple
                    >
                        {t("auth.login.forgotPassword")}
                    </Button>
                </Box>
                <Box color={COLORS.GREY_3} mt={3} textAlign={{ xs: "center", md: "left" }}>
                    <Typography component="span" color="inherit">
                        {t("auth.login.newToPupilProgress")}
                    </Typography>{" "}
                    <a href={ROUTE_AUTH_REGISTER} className={classes.link}>
                        {t("auth.login.clickHereToRegister")}
                    </a>
                </Box>
            </FormProvider>
        </AuthContainer>
    );
};

export default LoginContainer;
