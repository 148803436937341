import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const SEARCH_SNAPSHOT_LIST_QUERY = "searchtSearchSnapshotListQuery";

export interface SearchSnapshotListFilters {
    subjectAreaId: number;
    qualificationId: number;
    specificationId: number;
    yearGroup: number;
    onlyCreated?: boolean;
}

export type SearchSnapshotListResponse = ListObject<number>[];

export const useSearchSnapshotList = (cohort: number, params: SearchSnapshotListFilters) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [SEARCH_SNAPSHOT_LIST_QUERY, schoolAccountId, cohort, params],
        queryFn: async (): Promise<SearchSnapshotListResponse | null> => {
            if (cohort) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/search/snapshot/${cohort}/`,
                    {
                        params,
                    },
                );

                return data;
            }
            return null;
        },
    });
};
