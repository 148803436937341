import { useEffect, useState, ChangeEventHandler, useCallback } from "react";
import { Paper, Box, Grid, Typography, TextField, MenuItem, Chip } from "@mui/material";
import { Dispatch } from "redux";
import { SchoolActions } from "../store/actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { useMount } from "src/hooks/useMount";
import { OrmSubjectArea } from "src/orm/models/SubjectArea";
import { subjectAreaSelector } from "src/modules/common/selectors/SubjectAreaSelectors";
import { useTranslation } from "react-i18next";
import SubjectAreaTeachersList from "../components/SubjectAreaTeachersList";
import { OrmQualification } from "src/orm/models/Qualification";
import { filterNotNullable } from "src/services/object";
import { usePrevious } from "src/hooks/usePrevious";
import { UserActions } from "src/modules/user/store/actions";
import { usersWithFilterSelector } from "src/modules/user/selectors/UsersSelectors";
import { OrmUser } from "src/orm/models/User";
import PaperInner from "src/components/PaperInner";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";
import COLORS from "src/styles/colors";
import DotsProgress from "src/components/DotsProgress";
import { ApiStatus, ApiData } from "src/api/constants";
import { FormError, getErrorMessage } from "src/services/error";
import { useSnackbar } from "notistack";
import { useResponse } from "src/hooks/useResponse";
import AppContainer from "src/components/AppContainer";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

const LAZY_LOAD_PAGE_ELEMENTS = 2;

const dispatchActions = (dispatch: Dispatch) => ({
    getSubjectAreaList: () => {
        dispatch(SchoolActions.getSubjectAreaList());
    },
    getSubjectAreaUsersList: (subjectAreaId: number) => {
        dispatch(SchoolActions.getSubjectAreaUsersList(subjectAreaId));
    },
    getAdminList: () => {
        dispatch(UserActions.getAdminList());
    },
});

const SubjectAreaListContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { getSubjectAreaList, getSubjectAreaUsersList, getAdminList } = dispatchActions(dispatch);

    const [loadElementsNumber, setLoadElementsNumber] = useState<number>(LAZY_LOAD_PAGE_ELEMENTS);
    const [loadFinished, setLoadFinished] = useState<boolean>(false);
    const [loadedElements, setLoadedElements] = useState<string[]>([]);
    const [filter, setFilter] = useState<string>("");
    const {
        subjectAreaIds,
        subjectAreaList,
        apiSetLeader,
        apiSetTeacher,
        apiRemoveLeader,
        apiRemoveTeacher,
        adminIds,
    }: {
        subjectAreaIds: (number | null)[];
        subjectAreaList: OrmSubjectArea[];
        adminIds: number[] | null;
        apiSetLeader: ApiData;
        apiSetTeacher: ApiData;
        apiRemoveLeader: ApiData;
        apiRemoveTeacher: ApiData;
    } = useSelector(
        (state: AppState) => ({
            subjectAreaIds: state.school.subjectAreaFromSubscriptions,
            subjectAreaList: subjectAreaSelector(state),
            adminIds: state.user.adminIds,
            apiSetLeader: state.api.school.setLeader,
            apiSetTeacher: state.api.school.setTeacher,
            apiRemoveLeader: state.api.school.removeLeader,
            apiRemoveTeacher: state.api.school.removeTeacher,
        }),
        shallowEqual,
    );

    const { adminList }: { adminList: OrmUser[] } = useSelector(
        (state: AppState) => ({
            adminList: usersWithFilterSelector(
                state,
                u =>
                    adminIds !== undefined &&
                    adminIds !== null &&
                    u.id !== undefined &&
                    adminIds.includes(u.id),
            ),
        }),
        shallowEqual,
    );

    const resetState = () => {
        setLoadElementsNumber(LAZY_LOAD_PAGE_ELEMENTS);
        setLoadedElements([]);
        setLoadFinished(false);
    };

    const subjectAreaFromSubscriptions = subjectAreaList.filter(sa => {
        if (sa.id) {
            return (subjectAreaIds || []).includes(sa.id);
        }
        return false;
    });

    useMount(() => {
        getSubjectAreaList();
        getAdminList();
    });

    const prevSubjectAreaLength = usePrevious(subjectAreaIds.length);

    useEffect(() => {
        if (subjectAreaIds.length > 0 && prevSubjectAreaLength !== subjectAreaIds.length) {
            subjectAreaIds.forEach((subjectAreaId: number) => {
                getSubjectAreaUsersList(subjectAreaId);
            });
        }
    }, [getSubjectAreaUsersList, prevSubjectAreaLength, subjectAreaIds]);

    const handleFilterChange: ChangeEventHandler<HTMLInputElement> = e => {
        setFilter(e.target.value);
        resetState();
    };

    const filteredList = subjectAreaFromSubscriptions.filter(safs => {
        if (!filter) {
            return true;
        }
        return safs.id && safs.id === parseInt(filter);
    });
    const allLoadedElements: string[] = Object.keys(filteredList);
    const prevNumber = usePrevious(loadElementsNumber);

    if (loadedElements.length === 0 && allLoadedElements.length > 0) {
        setLoadedElements(allLoadedElements.slice(0, loadElementsNumber));
    }

    const handleResponse = useCallback(
        data => {
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = getErrorMessage(data);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
        },
        [enqueueSnackbar],
    );

    useResponse(() => handleResponse(apiSetLeader), apiSetLeader);
    useResponse(() => handleResponse(apiSetTeacher), apiSetTeacher);
    useResponse(() => handleResponse(apiRemoveTeacher), apiRemoveTeacher);
    useResponse(() => handleResponse(apiRemoveLeader), apiRemoveLeader);

    useEffect(() => {
        if (prevNumber !== loadElementsNumber) {
            setLoadedElements(allLoadedElements.slice(0, loadElementsNumber));
        }
        if (loadedElements.length > 0 && loadElementsNumber > allLoadedElements.length) {
            setLoadFinished(true);
        }
    }, [allLoadedElements, loadElementsNumber, loadedElements, prevNumber]);

    useEffect(() => {
        const onScroll = e => {
            if (
                window.innerHeight + window.scrollY >=
                    e.target.documentElement.offsetHeight - 200 &&
                loadFinished === false
            ) {
                setTimeout(() => {
                    setLoadElementsNumber(loadElementsNumber + LAZY_LOAD_PAGE_ELEMENTS);
                    if (loadElementsNumber + LAZY_LOAD_PAGE_ELEMENTS >= allLoadedElements.length) {
                        setLoadFinished(true);
                    }
                }, 500);
            }
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [allLoadedElements.length, loadElementsNumber, loadFinished]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("school.subjectAreas.tab")}
            </Typography>
            <Grid container>
                <Grid item sm={4}>
                    <Box mb={4}>
                        <TextField
                            id="filter-by-suybjectArea"
                            label={t("school.subjectAreas.filterPlaceholder")}
                            value={filter}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            SelectProps={{ displayEmpty: true }}
                            onChange={handleFilterChange}
                            select
                        >
                            <MenuItem value={""}>{t("common.all")}</MenuItem>
                            {filterNotNullable(subjectAreaList).map(sa => {
                                return (
                                    <MenuItem value={sa.id} key={sa.id}>
                                        {sa.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Box>
                </Grid>
            </Grid>
            {Object.keys(filteredList)
                .filter(key => loadedElements.includes(key))
                .map(key => {
                    const subjectArea: OrmSubjectArea = filteredList[key];
                    if (subjectArea) {
                        return (
                            <Box mb={4} key={key}>
                                <Paper>
                                    <PaperInner
                                        variant="subjectArea"
                                        color="lightGrey"
                                        border="bottom"
                                    >
                                        <Box display="flex">
                                            <Chip
                                                style={{ backgroundColor: subjectArea.colour }}
                                                label={subjectArea.name}
                                            />
                                            {subjectArea.qualifications && (
                                                <Box
                                                    display="inline-flex"
                                                    alignItems="center"
                                                    height={24}
                                                    fontSize={16}
                                                    fontWeight="fontWeightBold"
                                                    fontFamily={PRIMARY_FONT_FAMILY}
                                                    borderLeft={`1px solid ${COLORS.LIGHT_GREY_2}`}
                                                    pl={2}
                                                    ml={2}
                                                >
                                                    {subjectArea.qualifications.length > 0 &&
                                                        subjectArea.qualifications.map(
                                                            (q: OrmQualification) => q.name,
                                                        )}
                                                </Box>
                                            )}
                                        </Box>
                                    </PaperInner>
                                    <Grid container>
                                        <Grid item sm={6}>
                                            <PaperInner>
                                                <Typography
                                                    component="h2"
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    {t("common.teachers")}
                                                </Typography>
                                                <Box minHeight={64}>
                                                    <Typography variant="overline">
                                                        {t("school.subjectAreas.teachersHint")}
                                                    </Typography>
                                                </Box>
                                                <SubjectAreaTeachersList
                                                    subjectAreaId={subjectArea.id}
                                                    name="teacher"
                                                    adminList={[]}
                                                    canModify={
                                                        subjectArea.canModifyTeachersAndLeaders ||
                                                        false
                                                    }
                                                />
                                            </PaperInner>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <PaperInner border="left">
                                                <Typography
                                                    component="h2"
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    {t("common.leaders")}
                                                </Typography>
                                                <Box minHeight={64}>
                                                    <Typography variant="overline">
                                                        {t("school.subjectAreas.leaderHint")}
                                                    </Typography>
                                                </Box>
                                                <SubjectAreaTeachersList
                                                    subjectAreaId={subjectArea.id}
                                                    name="leader"
                                                    adminList={adminList}
                                                    canModify={
                                                        subjectArea.canModifyTeachersAndLeaders ||
                                                        false
                                                    }
                                                />
                                            </PaperInner>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        );
                    }
                    return null;
                })}
            {loadFinished === false && <DotsProgress />}
        </AppContainer>
    );
};

export default SubjectAreaListContainer;
