import { Box, Chip, Theme } from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";
import { mdiClose } from "@mdi/js";
import { FC } from "react";
import Icon from "src/components/Icon";
import { AttributePair, FiltersShape } from "src/modules/common/components/Grid/GridStudentFilter";
import COLORS from "src/styles/colors";

const FilterChip = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "auto",
            borderRadius: 8,
            backgroundColor: COLORS.VERY_LIGHT_GREY_3,
            color: COLORS.GREY_2,
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        label: {
            whiteSpace: "normal",
            paddingTop: theme.spacing(0.75),
            paddingBottom: theme.spacing(0.75),
            color: COLORS.GREY_5,
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 600,
            "& > b": {
                fontWeight: 600,
                textTransform: "capitalize",
            },
            "& > span": {},
            "& > strong": {
                color: COLORS.GREY_1,
                fontWeight: 600,
            },
            "& > strong > span": {
                color: COLORS.BLUE_3,
                fontWeight: 600,
            },
        },
    }),
)(Chip);

interface OwnProps {
    filters: FiltersShape;
    handleFiltersRemove: (filterKey: string, valueHash: string) => void;
}

const FilterSummary: FC<OwnProps> = ({ filters, handleFiltersRemove }) => (
    <>
        {Object.values(filters).map((attribute: AttributePair) => {
            return attribute.values.map(({ valueDisplay, value, valueTo }) => (
                <FilterChip
                    key={attribute.attribute + value + "x" + valueTo}
                    label={
                        <>
                            [<Box component="b">{attribute.type}]</Box>{" "}
                            <Box component="span">{`${attribute.attributeDisplay}${
                                attribute.type === "grade" ? "" : ":"
                            }`}</Box>
                            {typeof valueDisplay === "string" ? (
                                <Box
                                    component="strong"
                                    display={attribute.type === "student" ? "inline" : "block"}
                                    marginLeft={attribute.type === "student" ? 1.25 : undefined}
                                    marginTop={attribute.type === "student" ? undefined : 0.5}
                                    dangerouslySetInnerHTML={{ __html: valueDisplay }}
                                />
                            ) : (
                                <Box
                                    marginLeft={attribute.type === "student" ? 1.25 : undefined}
                                    marginTop={attribute.type === "student" ? undefined : 0.5}
                                >
                                    {valueDisplay}
                                </Box>
                            )}
                        </>
                    }
                    onDelete={() => {
                        if (handleFiltersRemove) {
                            handleFiltersRemove(attribute.attribute, value + "x" + valueTo);
                        }
                    }}
                    deleteIcon={
                        <Box component="span">
                            <Icon path={mdiClose} />
                        </Box>
                    }
                />
            ));
        })}
    </>
);

export default FilterSummary;
