import { Box } from "@mui/material";

import { useTranslation } from "react-i18next";
import { APP_VERSION } from "src/config/globals";
import COLORS from "src/styles/colors";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";

const FooterTracker = () => {
    const { t } = useTranslation();

    return (
        <Box
            height={24}
            bgcolor={COLORS.VERY_LIGHT_GREY_2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            component="footer"
            px={4}
            position="fixed"
            right={0}
            bottom={0}
            left={0}
        >
            <Box
                component="p"
                fontFamily={PRIMARY_FONT_FAMILY}
                fontSize={10}
                lineHeight={1}
                m={0}
                p={0}
                textAlign="center"
                color={COLORS.LIGHT_GREY_1}
            >
                {t("footer.copy1")}
                <Box component="span" mx={2}>
                    |
                </Box>
                {t("footer.copy2").replace(":year", `${new Date().getFullYear()}`)}{" "}
                {` (v${APP_VERSION})`}
            </Box>
        </Box>
    );
};

export default FooterTracker;
