const API_HOST = process.env.REACT_APP_API_HOST; // api domain
const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL; // protocol
export const HTTP_NOT_FOUND = 404;
export const HTTP_SUCCESS = 200;
export const HTTP_SUCCESS_ACCEPTED = 202;
export const HTTP_SUCCESS_NON_AUTHORIATIVE = 203;
export const HTTP_SUCCESS_CREATED = 201;
export const HTTP_NO_CONTENT = 204;
export const HTTP_SUCCESS_CONFLICT = 208;
export const HTTP_ERROR_CONFLICT = 409;
export const HTTP_TOO_MANY_REQUESTS = 429;
export const HTTP_FAILED_DEPENDENCY = 424;
export const HTTP_FORBIDDEN = 403;
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
export const AGGRID_KEY = process.env.REACT_APP_AGGRID_LICENSE_KEY;
export const PUPIL_PROGRESS_LANDING_URL = process.env.REACT_APP_PUPIL_PROGRESS_LANDING_URL;
export const TERMS_URL = process.env.REACT_APP_TERMS_URL;
export const HELP_CENTRE_URL = process.env.REACT_APP_HELP_CENTRE_URL;
export const DEVELOPMENT_BOARD_URL = process.env.REACT_APP_DEVELOPMENT_BOARD_URL;
export const REQUEST_WHOLESCHOOL_URL = process.env.REACT_APP_REQUEST_WHOLESCHOOL_URL;
export const PARENT_PORTAL_DEEPLINK = process.env.REACT_APP_PARENT_PORTAL_DEEPLINK;
export const GA4_ID = process.env.REACT_APP_GA4_ID;
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const INTERCOM_API_BASE = process.env.REACT_APP_INTERCOM_API_BASE;
export const MIGRATION_SYNC_DATE = process.env.REACT_APP_MIGRATION_SYNC_DATE;
export const PRODUCT_FRUITS_WORKSPACE_ID = process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_ID;

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_NAME = process.env.REACT_APP_NAME;
export const apiUrl = (endpoint: string): string => `${API_PROTOCOL}://${API_HOST}/${endpoint}`;
