import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { ClassTrackerHeaderObject } from "src/modules/tracker/dto/TrackerHeader";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_SNAPSHOT_HEADER_QUERY = "classTrackerGroupSnapshotHeader";

export const useClassTrackerGroupSnapshotHeader = (
    id: number,
    tier: TiersTypes,
    snapshotId: string,
) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            CLASS_TRACKER_GROUP_SNAPSHOT_HEADER_QUERY,
            schoolAccountId,
            id,
            tier,
            snapshotId,
        ],
        queryFn: async (): Promise<ClassTrackerHeaderObject | null> => {
            if (id && tier && snapshotId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker-group/${id}/${tier}/snapshot/${snapshotId}/header/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
