import { useState, useEffect, useCallback } from "react";
import {
    Typography,
    Grid,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Box,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { TFunction } from "i18next";
import { Dispatch } from "redux";
import { SubscriptionsActions } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApiStatus, ApiData } from "src/api/constants";
import { FormError, getErrorMessage as errorMessage } from "src/services/error";
import { useSnackbar } from "notistack";
import { AppState } from "src/store/reducers";
import { usePrevious } from "src/hooks/usePrevious";
import HeadingCounter from "src/components/HeadingCounter";
import { StripeInput } from "src/components/StripeInput";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import COLORS from "src/styles/colors";
import { mdiAutorenew } from "@mdi/js";
import Icon from "src/components/Icon";
import PaperInner from "src/components/PaperInner";
import { useResponse } from "src/hooks/useResponse";
import { HTTP_NO_CONTENT } from "src/config/globals";
import { CreatePaymentIntentResponse } from "../api/Payment/createPaymentIntent";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

interface ErrorData {
    name: string;
    message: string;
}

export const makePaymentSchema = (t: TFunction) =>
    yup.object().shape({
        nameOnCard: yup.string().required(t("subscription.makePayment.nameOnCard")),
        useBilling: yup.boolean(),
        schoolName: yup.string(),
        city: yup.string(),
        address: yup.string().required(t("common.form.label.address")),
        address2: yup.string(),
        postcode: yup.string().required(t("common.form.label.postcode")),
        couponName: yup.string(),
    });

const dispatchActions = (dispatch: Dispatch) => ({
    createPaymentIntent: (
        paymentId,
        couponCode: string,
        subscriptionId,
        description: string | null,
    ) => {
        dispatch(
            SubscriptionsActions.createPaymentIntent(
                paymentId,
                couponCode,
                subscriptionId,
                description,
            ),
        );
    },
    changeCouponCode: (couponName: string, subscriptionId: number) => {
        dispatch(SubscriptionsActions.changeCouponCode(subscriptionId, couponName));
    },
    confirmPaymentIntent: (intentId, subscriptionId) => {
        dispatch(SubscriptionsActions.confirmPaymentIntent(intentId, subscriptionId));
    },
    clearPaymentIntent: () => {
        dispatch(SubscriptionsActions.clearPaymentIntent());
    },
});

const MakePaymentForm = ({
    paymentDetails,
    schoolDetails,
    onAlreadyPaidHandle,
    isProcessing,
    setIsProcessing,
    handlePaymentComplete,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [coupon, setCoupon] = useState<string>("");
    const [errors, setErrors] = useState<ErrorData[]>([]);
    const [orderDescription, setOrderDescription] = useState<string>("");
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { createPaymentIntent, changeCouponCode, confirmPaymentIntent, clearPaymentIntent } =
        dispatchActions(dispatch);

    const {
        apiCreate,
        apiChange,
        intent,
        apiConfirmIntent,
    }: {
        apiCreate: ApiData;
        apiChange: ApiData;
        intent: CreatePaymentIntentResponse | null;
        apiConfirmIntent: ApiData;
    } = useSelector((state: AppState) => ({
        apiCreate: state.api.subscription.createPaymentIntent,
        apiChange: state.api.subscription.changeCouponCode,
        intent: state.subscription.paymentIntent,
        apiConfirmIntent: state.api.subscription.confirmPaymentIntent,
    }));

    const initialValues = {
        nameOnCard: "",
        useBilling: true,
        schoolName: "",
        city: "",
        address: "",
        address2: "",
        postcode: "",
        couponName: "",
    };
    const [formValues, setFormValues] = useState(initialValues);
    const price = paymentDetails && paymentDetails.price ? parseFloat(paymentDetails.price) : null;

    const vatValue =
        paymentDetails && paymentDetails.vatValue ? parseFloat(paymentDetails.vatValue) : 0;

    const discount =
        paymentDetails && paymentDetails.couponValue
            ? parseFloat(paymentDetails.couponValue)
            : null;

    const rebate =
        paymentDetails && paymentDetails.rebate ? parseFloat(paymentDetails.rebate) : null;

    const grandTotal =
        (price !== null ? price : 0) -
        (rebate !== null ? rebate : 0) -
        (discount !== null ? discount : 0);

    const grandTotalInclVAT = grandTotal + vatValue;

    const schoolValues = {
        schoolName: (schoolDetails && schoolDetails.name) || "",
        city: (schoolDetails && schoolDetails.city) || "",
        address: (schoolDetails && schoolDetails.address1) || "",
        address2: (schoolDetails && schoolDetails.address2) || "",
        postcode: (schoolDetails && schoolDetails.postcode) || "",
    };

    const handleUseBillingChange = () => {
        const newValue = !formValues.useBilling;

        if (newValue === true) {
            setFormValues({
                ...formValues,
                useBilling: newValue,
                ...schoolValues,
            });
        } else {
            setFormValues({
                ...formValues,
                useBilling: newValue,
            });
        }
    };

    const handleErrorResponse = useCallback(
        data => {
            switch (data.status) {
                case ApiStatus.ERROR: {
                    const error: FormError = errorMessage(data);
                    if (error.message)
                        enqueueSnackbar(error.message, {
                            ...SnackbarErrorOptions,
                        });
                    break;
                }
            }
            setIsProcessing(false);
        },
        [setIsProcessing],
    );

    const makePayment = useCallback(async () => {
        if (stripe && elements && formValues) {
            const cardElement = elements.getElement(CardNumberElement);
            if (cardElement) {
                const { paymentMethod, error } = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                    billing_details: {
                        name: formValues.nameOnCard,
                        address: {
                            city: formValues.city,
                            line1: formValues.address,
                            line2: formValues.address2,
                            postal_code: formValues.postcode,
                        },
                    },
                });
                if (error) {
                    enqueueSnackbar(error.message, {
                        ...SnackbarErrorOptions,
                    });
                }

                if (paymentMethod && paymentMethod.id) {
                    createPaymentIntent(
                        paymentMethod.id,
                        coupon,
                        paymentDetails.id,
                        orderDescription || null,
                    );
                }
                setIsProcessing(false);
            }
            return;
        }
    }, [elements, stripe, formValues]);

    const handleSubmit = ev => {
        setIsProcessing(true);
        ev.preventDefault();
        makePaymentSchema(t)
            .validate(formValues, { abortEarly: false })
            .then(() => {
                setErrors([]);
                makePayment();
            })
            .catch(err => {
                setErrors(err.inner.map(e => ({ name: e.path, message: e.message })));
            });
    };

    useEffect(() => {
        if (intent && stripe) {
            if (intent.status !== "succeeded") {
                const handlePayment = async () => {
                    try {
                        const { paymentIntent, error } = await stripe.handleCardAction(
                            intent.client_secret,
                        );
                        if (error) {
                            enqueueSnackbar(t("subscription.makePayment.failure"), {
                                ...SnackbarErrorOptions,
                            });
                            clearPaymentIntent();
                            setIsProcessing(false);
                        }
                        if (paymentIntent && paymentIntent.id) {
                            confirmPaymentIntent(paymentIntent.id, paymentDetails.id);
                        }
                    } catch (e) {
                        enqueueSnackbar(t("subscription.makePayment.failure"), {
                            ...SnackbarErrorOptions,
                        });
                        clearPaymentIntent();
                        setIsProcessing(false);
                    }
                };
                handlePayment();
            } else {
                handlePaymentComplete();
                clearPaymentIntent();
            }
        }
    }, [intent, stripe]);

    useEffect(() => {
        if (formValues.schoolName === "") {
            setFormValues({
                ...formValues,
                ...schoolValues,
            });
        }
    }, [formValues, schoolValues]);

    const handleCouponCodeChange = () => {
        changeCouponCode(coupon, paymentDetails.subscriptionId);
    };

    const handleOnChangeField = (name, value) => {
        setFormValues({ ...formValues, [name]: value });
    };

    const getErrorHint = useCallback(
        (name: string) => {
            if (errors) {
                const obj = errors.find(e => e.name === name);
                if (obj !== undefined) {
                    return obj.message;
                }
            }
            return "";
        },
        [errors],
    );

    const prevApiStatus = usePrevious(apiCreate.status);

    useEffect(() => {
        if (prevApiStatus === ApiStatus.LOADING && apiCreate.status === ApiStatus.ERROR) {
            handleErrorResponse(apiCreate);
        }
        if (prevApiStatus === ApiStatus.LOADING && apiCreate.responseStatus === HTTP_NO_CONTENT) {
            onAlreadyPaidHandle();
        }
    }, [apiCreate, onAlreadyPaidHandle, prevApiStatus]);

    const handleCouponChangeError = useCallback(data => {
        switch (data.status) {
            case ApiStatus.ERROR: {
                const error: FormError = errorMessage(data);
                if (error.message)
                    enqueueSnackbar(error.message, {
                        ...SnackbarErrorOptions,
                    });
                if (error.formError && error.formError.couponName) {
                    enqueueSnackbar(error.formError.couponName, {
                        ...SnackbarErrorOptions,
                    });
                }
                break;
            }
        }
    }, []);

    const handleConfirmErrorError = useCallback(data => {
        switch (data.status) {
            case ApiStatus.ERROR: {
                if (data.error)
                    enqueueSnackbar(data.error.response.error, {
                        ...SnackbarErrorOptions,
                    });
                break;
            }
        }
    }, []);

    useResponse(() => handleCouponChangeError(apiChange), apiChange);
    useResponse(() => handleConfirmErrorError(apiConfirmIntent), apiConfirmIntent);

    return (
        <form onSubmit={handleSubmit}>
            <PaperInner border="bottom">
                <HeadingCounter number="1">
                    {t("subscription.makePayment.cardDetails")}
                </HeadingCounter>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <TextField
                            name="nameOnCard"
                            label={t("subscription.makePayment.nameOnCard")}
                            onChange={e => handleOnChangeField("nameOnCard", e.target.value)}
                            error={getErrorHint("nameOnCard") !== ""}
                            helperText={getErrorHint("nameOnCard")}
                            margin="normal"
                        />
                        <TextField
                            label={t("subscription.makePayment.cardNumber")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: { component: CardNumberElement },
                            }}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item sm={2}>
                        <TextField
                            label={t("subscription.makePayment.expiryDate")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: { component: CardExpiryElement },
                            }}
                            margin="normal"
                        />
                        <TextField
                            label={t("subscription.makePayment.cvv")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: { component: CardCvcElement },
                            }}
                            margin="normal"
                        />
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner border="bottom">
                <HeadingCounter number="2">
                    {t("subscription.makePayment.billingAddress")}
                </HeadingCounter>
                <Box mb={1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="useBilling"
                                value={formValues.useBilling}
                                checked={formValues.useBilling}
                                onChange={handleUseBillingChange}
                            />
                        }
                        label={t("subscription.makePayment.useBillingAddress")}
                    />
                </Box>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <TextField
                            name={"schoolName"}
                            label={t("subscription.makePayment.schoolName")}
                            value={formValues.schoolName}
                            onChange={e => handleOnChangeField("schoolName", e.target.value)}
                            disabled={formValues.useBilling}
                            error={getErrorHint("schoolName") !== ""}
                            helperText={getErrorHint("schoolName")}
                        />
                        <TextField
                            name={"address"}
                            label={t("common.form.label.address")}
                            value={formValues.address}
                            onChange={e => handleOnChangeField("address", e.target.value)}
                            disabled={formValues.useBilling}
                            error={getErrorHint("address") !== ""}
                            helperText={getErrorHint("address")}
                        />
                        <TextField
                            name={"address2"}
                            label={t("common.form.label.address2")}
                            value={formValues.address2}
                            onChange={e =>
                                setFormValues({ ...formValues, address2: e.target.value })
                            }
                            disabled={formValues.useBilling}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <TextField
                            name={"city"}
                            label={t("common.form.label.city")}
                            value={formValues.city}
                            onChange={e => handleOnChangeField("city", e.target.value)}
                            disabled={formValues.useBilling}
                            error={getErrorHint("city") !== ""}
                            helperText={getErrorHint("city")}
                        />
                        <TextField
                            name={"postcode"}
                            label={t("common.form.label.postcode")}
                            value={formValues.postcode}
                            onChange={e => handleOnChangeField("postcode", e.target.value)}
                            disabled={formValues.useBilling}
                            error={getErrorHint("postcode") !== ""}
                            helperText={getErrorHint("postcode")}
                        />
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner>
                <HeadingCounter number="3">{t("subscription.makePayment.header")}</HeadingCounter>
                <Grid container>
                    <Grid item sm={6}>
                        <Typography gutterBottom>
                            {t("subscription.makePayment.charged").replace(
                                "{amount}",
                                paymentDetails.price,
                            )}
                        </Typography>
                        <Typography variant="overline" component="p" gutterBottom>
                            {t("subscription.makePayment.customNote")}
                        </Typography>
                        <TextField
                            name={"note"}
                            onChange={e => {
                                setOrderDescription(e.target.value);
                            }}
                            label={t("subscription.makePayment.note")}
                            multiline
                            rows={6}
                            margin="normal"
                        />
                        <Button disabled={isProcessing} color="primary" onClick={handleSubmit}>
                            {`${t("subscription.makePayment.header")} (£${grandTotalInclVAT.toFixed(
                                2,
                            )})`}
                        </Button>
                    </Grid>
                    <Grid item sm={1} />
                    <Grid item sm={5}>
                        <Box pt={3} pr={4} pb={4} pl={4} bgcolor={COLORS.VERY_LIGHT_GREY_1}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                pb={2}
                                mr={6.5}
                            >
                                <Box>{t("subscription.summary.subtotal")}</Box>
                                <Box fontWeight={theme.typography.fontWeightBold}>{`£${
                                    price || 0
                                }`}</Box>
                            </Box>
                            <Box
                                bgcolor={COLORS.VERY_LIGHT_GREY_5}
                                mx={-4}
                                pl={4}
                                pr={10.5}
                                py={2}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <TextField
                                    name={"couponName"}
                                    label={t("subscription.summary.couponName")}
                                    onChange={e => setCoupon(e.currentTarget.value)}
                                    margin="none"
                                    value={coupon}
                                />
                                <Button
                                    variant="text"
                                    startIcon={<Icon path={mdiAutorenew} />}
                                    disableRipple
                                    onClick={handleCouponCodeChange}
                                >
                                    {t("subscription.summary.applyCoupon")}
                                </Button>
                            </Box>
                            {discount !== null && discount > 0 && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    py={2}
                                    mr={6.5}
                                    borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                                >
                                    <Box>{t("subscription.summary.discount")}</Box>
                                    <Box fontWeight={theme.typography.fontWeightBold}>{`-£${
                                        discount || 0
                                    }`}</Box>
                                </Box>
                            )}
                            {rebate !== null && rebate > 0 && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    py={2}
                                    mr={6.5}
                                    borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                                >
                                    <Box>{t("subscription.summary.rebate")}</Box>
                                    <Box fontWeight={theme.typography.fontWeightBold}>{`-£${
                                        rebate || 0
                                    }`}</Box>
                                </Box>
                            )}
                            {grandTotal > 0 && (
                                <>
                                    <Box
                                        display="flex"
                                        alignItems="flex-start"
                                        justifyContent="space-between"
                                        py={2.5}
                                        mr={6.5}
                                        mt={3.5}
                                        fontSize={theme.typography.pxToRem(14)}
                                        borderTop={`1px solid ${COLORS.LIGHT_GREY_2}`}
                                    >
                                        <Box>{t("subscription.summary.excludingVAT")}</Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-end"
                                        >
                                            <Box
                                                color={COLORS.GREY_1}
                                                fontWeight={theme.typography.fontWeightBold}
                                            >{`£${grandTotal.toFixed(2)}`}</Box>
                                        </Box>
                                    </Box>
                                    {vatValue === 0 ? (
                                        <Box
                                            display="flex"
                                            alignItems="flex-start"
                                            justifyContent="space-between"
                                            fontStyle={"italic"}
                                            mr={6.5}
                                            fontSize={theme.typography.pxToRem(14)}
                                        >
                                            <Box>{t("subscription.summary.outside")}</Box>
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            alignItems="flex-start"
                                            justifyContent="space-between"
                                            mr={6.5}
                                            fontSize={theme.typography.pxToRem(14)}
                                        >
                                            <Box>{t("subscription.summary.vatAmount")}</Box>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="flex-end"
                                            >
                                                <Box
                                                    color={COLORS.GREY_1}
                                                    fontWeight={theme.typography.fontWeightBold}
                                                >{`£${vatValue.toFixed(2)}`}</Box>
                                            </Box>
                                        </Box>
                                    )}
                                </>
                            )}
                            <Box
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="space-between"
                                py={2.5}
                                mr={6.5}
                                mb={2.5}
                                borderBottom={`1px solid ${COLORS.LIGHT_GREY_2}`}
                                fontSize={theme.typography.pxToRem(18)}
                                fontWeight={theme.typography.fontWeightBold}
                            >
                                <Box>
                                    {vatValue === 0
                                        ? t("subscription.summary.grandTotal")
                                        : t("subscription.summary.grandTotalInclVAT")}
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="flex-end">
                                    <Box color={COLORS.BLUE_2}>{`£${grandTotalInclVAT.toFixed(
                                        2,
                                    )}`}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </PaperInner>
        </form>
    );
};

export default MakePaymentForm;
