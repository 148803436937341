import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { ClassTrackerHeaderObject } from "src/modules/tracker/dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "src/modules/tracker/dto/TrackerRelated";
import { ClassTrackerValuesObject } from "src/modules/tracker/dto/TrackerValues";
import { useClassTrackerSnapshotHeader } from "./Snapshots/useSnapshotHeader";
import { useClassTrackerSnapshotValues } from "./Snapshots/useSnapshotValues";
import { useClassTrackerSnapshotRelatedData } from "./Snapshots/useSnapshotRelatedData";
import { SnapshotData, useTrackerContext } from "src/context/TrackerContext";
import { useEffect } from "react";

export interface SnapshotTrackerData {
    refetchRelatedData: () => void;
    relatedData: ClassTrackerRelatedObject;
    isLoadingRelatedData: boolean;
    isRefetchingRelatedData: boolean;
    isSuccessRelatedData: boolean;
    refetchValues: () => void;
    values: ClassTrackerValuesObject;
    isLoadingValues: boolean;
    isRefetchingValues: boolean;
    isSuccessValues: boolean;
    refetchHeader: () => void;
    header: ClassTrackerHeaderObject;
    isLoadingHeader: boolean;
    isRefetchingHeader: boolean;
    isSuccessHeader: boolean;
    isRefetchingAll: boolean;
    isLoadingAll: boolean;
    isSuccessAll: boolean;
    fetchSnapshotData: () => void;
    snapshotData: SnapshotData | null;
    setSnapshotData: (data: SnapshotData) => void;
}

export const useTrackerSnapshotData = (id: number, tier: TiersTypes): SnapshotTrackerData => {
    const { trackerState, setSnapshotData } = useTrackerContext();
    const { snapshotData } = trackerState;

    const {
        refetch: refetchHeader,
        data: header,
        isLoading: isLoadingHeader,
        isSuccess: isSuccessHeader,
        isRefetching: isRefetchingHeader,
    } = useClassTrackerSnapshotHeader(id, tier, snapshotData?.snapshot || null);
    const {
        refetch: refetchValues,
        data: values,
        isLoading: isLoadingValues,
        isSuccess: isSuccessValues,
        isRefetching: isRefetchingValues,
    } = useClassTrackerSnapshotValues(id, tier, snapshotData?.snapshot || null);
    const {
        refetch: refetchRelatedData,
        data: relatedData,
        isLoading: isLoadingRelatedData,
        isSuccess: isSuccessRelatedData,
        isRefetching: isRefetchingRelatedData,
    } = useClassTrackerSnapshotRelatedData(id, snapshotData?.snapshot || null);

    const fetchSnapshotData = () => {
        refetchHeader();
        refetchValues();
        refetchRelatedData();
    };

    const isRefetchingAll = isRefetchingRelatedData || isRefetchingHeader || isRefetchingValues;

    const isLoadingAll = isLoadingRelatedData || isLoadingHeader || isLoadingValues;

    const isSuccessAll = isSuccessRelatedData || isSuccessHeader || isSuccessValues;

    useEffect(() => {
        if (snapshotData?.snapshot) {
            fetchSnapshotData();
        }
    }, [snapshotData]);

    return {
        refetchRelatedData,
        relatedData,
        isLoadingRelatedData,
        isSuccessRelatedData,
        isRefetchingRelatedData,
        refetchValues,
        values,
        isLoadingValues,
        isSuccessValues,
        isRefetchingValues,
        refetchHeader,
        header,
        isLoadingHeader,
        isSuccessHeader,
        isRefetchingHeader,
        isRefetchingAll,
        isLoadingAll,
        isSuccessAll,
        fetchSnapshotData,
        snapshotData,
        setSnapshotData,
    };
};
