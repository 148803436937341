import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ColumnNode, HeaderNodeWithChildren } from "src/modules/tracker/dto/TrackerHeader";
import { getSchoolAccountId } from "src/services/url";

export const getAnalysisAtlHeaderEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/atl/{cohort}/{yearGroup}/header/`,
    method: ApiRequests.GET,
};

export interface AnalysisAtlHeaderObject {
    header: HeaderNodeWithChildren[];
    maxLevel: number;
    columns: {
        [key: string]: ColumnNode;
    };
}
