import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { LinkDetailedModel } from "../model/LinkModel";
import { LinkCreateRequest } from "./useCreateMutation";
import { LINKS_LIST_QUERY } from "./useLinksList";
import { LINK_DETAILS_QUERY } from "./useLinkDetails";
import { useTranslation } from "react-i18next";
import { getSchoolAccountId } from "src/services/url";

export interface LinkEditRequest extends LinkCreateRequest {
    id?: number;
}

const apiUpdateLink = async (
    id: number,
    values: LinkEditRequest,
): Promise<AxiosResponse<LinkDetailedModel>> => {
    return await axiosInstance.put(`school/${getSchoolAccountId()}/report-links/${id}/`, values);
};

export const useEditLinkMutation = (
    onSuccess: (data: LinkDetailedModel) => void,
    onError?: any,
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: LinkEditRequest) => apiUpdateLink(values.id, values),
        onSuccess: ({ data }: { data: LinkDetailedModel }) => {
            queryClient.invalidateQueries({ queryKey: [LINKS_LIST_QUERY] });
            queryClient.invalidateQueries({ queryKey: [LINK_DETAILS_QUERY] });
            enqueueSnackbar(t("common.saved"), { variant: "success" });
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: (err: any) => {
            if (err?.response?.data?.error) {
                enqueueSnackbar(err?.response?.data?.error, {
                    ...SnackbarErrorOptions,
                });
            }
            if (onError) {
                onError();
            }
        },
    });
};
