import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction } from "src/api/helpers";
import { OrmReportTemplate } from "src/orm/models/ReportTemplate";
import {
    createReportTemplateEndpoint,
    ReportTemplateRequest,
} from "../../api/ReportTemplate/create";
import { setAsDefaultReportTemplateEndpoint } from "../../api/ReportTemplate/default";
import { getReportTemplateDetailsEndpoint } from "../../api/ReportTemplate/getDetails";
import {
    getReportTemplateListEndpoint,
    ReportTemplateList,
} from "../../api/ReportTemplate/getList";
import { removeReportTemplateEndpoint } from "../../api/ReportTemplate/remove";
import { updateReportTemplateEndpoint } from "../../api/ReportTemplate/update";

export enum ReportTemplateActionsTypes {
    GET_REPORT_TEMPLATE_LIST = "[report] GET REPORT TEMPLATE LIST",
    GET_REPORT_TEMPLATE_LIST_SUCCESS = "[report] GET REPORT TEMPLATE LIST SUCCESS",
    GET_REPORT_TEMPLATE_DETAILS = "[report] GET REPORT TEMPLATE DETAILS",
    GET_REPORT_TEMPLATE_DETAILS_SUCCESS = "[report] GET REPORT TEMPLATE DETAILS SUCCESS",
    CREATE_REPORT_TEMPLATE = "[report] CREATE REPORT TEMPLATE",
    CREATE_REPORT_TEMPLATE_SUCCESS = "[report] CREATE REPORT TEMPLATE SUCCESS",
    UPDATE_REPORT_TEMPLATE = "[report] UPDATE REPORT TEMPLATE",
    UPDATE_REPORT_TEMPLATE_SUCCESS = "[report] UPDATE REPORT TEMPLATE SUCCESS",
    REMOVE_REPORT_TEMPLATE = "[report] REMOVE REPORT TEMPLATE",
    REMOVE_REPORT_TEMPLATE_SUCCESS = "[report] REMOVE REPORT TEMPLATE SUCCESS",
    DEFAULT_REPORT_TEMPLATE = "[report] DEFAULT REPORT TEMPLATE",
    DEFAULT_REPORT_TEMPLATE_SUCCESS = "[report] DEFAULT REPORT TEMPLATE SUCCESS",
}

export const ReportTemplateApiEndpoints = {
    getReportTemplateList: createActionObject(
        getReportTemplateListEndpoint,
        ReportTemplateActionsTypes.GET_REPORT_TEMPLATE_LIST_SUCCESS,
    ),
    getReportTemplateDetails: createActionObject(
        getReportTemplateDetailsEndpoint,
        ReportTemplateActionsTypes.GET_REPORT_TEMPLATE_DETAILS_SUCCESS,
    ),
    createReportTemplate: createActionObject(
        createReportTemplateEndpoint,
        ReportTemplateActionsTypes.CREATE_REPORT_TEMPLATE_SUCCESS,
    ),
    updateReportTemplate: createActionObject(
        updateReportTemplateEndpoint,
        ReportTemplateActionsTypes.UPDATE_REPORT_TEMPLATE_SUCCESS,
    ),
    removeReportTemplate: createActionObject(
        removeReportTemplateEndpoint,
        ReportTemplateActionsTypes.REMOVE_REPORT_TEMPLATE_SUCCESS,
    ),
    setAsDefaultReportTemplate: createActionObject(
        setAsDefaultReportTemplateEndpoint,
        ReportTemplateActionsTypes.DEFAULT_REPORT_TEMPLATE_SUCCESS,
    ),
};

export const ReportTemplateRawActions = (
    ReportTemplateEndpoints: Record<Keys<typeof ReportTemplateApiEndpoints>, ApiActionMeta>,
) => ({
    getReportTemplateList: () =>
        createApiAction(
            ReportTemplateActionsTypes.GET_REPORT_TEMPLATE_LIST,
            undefined,
            ReportTemplateEndpoints.getReportTemplateList,
        ),
    getReportTemplateListSuccess: (payload: ApiCallbackPayload<ReportTemplateList>) =>
        createAction(ReportTemplateActionsTypes.GET_REPORT_TEMPLATE_LIST, payload),
    getReportTemplateDetails: (id: number) =>
        createApiAction(
            ReportTemplateActionsTypes.GET_REPORT_TEMPLATE_DETAILS,
            { params: { id }, values: {} },
            ReportTemplateEndpoints.getReportTemplateDetails,
        ),
    getReportTemplateDetailsSuccess: (payload: ApiCallbackPayload<OrmReportTemplate>) =>
        createAction(ReportTemplateActionsTypes.GET_REPORT_TEMPLATE_DETAILS_SUCCESS, payload),
    createReportTemplate: (values: ReportTemplateRequest) =>
        createApiAction(
            ReportTemplateActionsTypes.CREATE_REPORT_TEMPLATE,
            { params: {}, values: { ...values } },
            ReportTemplateEndpoints.createReportTemplate,
        ),
    createReportTemplateSuccess: (payload: ApiCallbackPayload<OrmReportTemplate>) =>
        createAction(ReportTemplateActionsTypes.CREATE_REPORT_TEMPLATE_SUCCESS, payload),
    updateReportTemplate: (id: number, values: ReportTemplateRequest) =>
        createApiAction(
            ReportTemplateActionsTypes.UPDATE_REPORT_TEMPLATE,
            { params: { id }, values: { ...values } },
            ReportTemplateEndpoints.updateReportTemplate,
        ),
    updateReportTemplateSuccess: (payload: ApiCallbackPayload<OrmReportTemplate>) =>
        createAction(ReportTemplateActionsTypes.UPDATE_REPORT_TEMPLATE_SUCCESS, payload),
    removeReportTemplate: (id: number) =>
        createApiAction(
            ReportTemplateActionsTypes.REMOVE_REPORT_TEMPLATE,
            { params: { id }, values: {} },
            ReportTemplateEndpoints.removeReportTemplate,
        ),
    removeReportTemplateSuccess: () =>
        createAction(ReportTemplateActionsTypes.REMOVE_REPORT_TEMPLATE_SUCCESS),
    setAsDefaultReportTemplate: (id: number) =>
        createApiAction(
            ReportTemplateActionsTypes.DEFAULT_REPORT_TEMPLATE,
            { params: { id }, values: {} },
            ReportTemplateEndpoints.setAsDefaultReportTemplate,
        ),
    setAsDefaultReportTemplateSuccess: (payload: ApiCallbackPayload<OrmReportTemplate>) =>
        createAction(ReportTemplateActionsTypes.DEFAULT_REPORT_TEMPLATE_SUCCESS, payload),
});
