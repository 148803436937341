import { attr, Model } from "redux-orm";
import { OrmSpecification } from "../Specification";
import { OrmUser } from "../User";
import { OrmSubjectArea } from "../SubjectArea";
import { OrmQualification } from "../Qualification";
import { InitialAttribute } from "../SpecificationAttribute";
import { ListObject } from "src/forms/types";
import { StudentModel } from "../Student";
import { sortTiers } from "src/services/array";

export enum TrackerStatus {
    LIVE = "live",
    DRAFT = "draft",
    MAINTENANCE = "maintenance",
    ARCHIVING = "archiving",
    ARCHIVED = "archived",
}

export enum UnitTypes {
    STANDARD = "standard",
    SUMMARY = "summary",
}

const fields = {
    id: attr(),
    name: attr(),
    yearGroup: attr(),
    numberOfStudents: attr(),
    specification: attr(),
    teachers: attr(),
    lastUpdated: attr(),
    qualification: attr(),
    subjectArea: attr(),
    status: attr(),
    attributes: attr(),
    cohort: attr(),
    students: attr(),
    classTrackerGroup: attr(),
    maintenanceMessage: attr(),
    isDisabled: attr(),
    cohortId: attr(),
    isDemo: attr(),
    classTrackerGroupId: attr(),
};

export interface OrmClassTracker {
    id: number;
    name: string;
    yearGroup: number;
    numberOfStudents: number;
    specification: OrmSpecification | null;
    teachers: OrmUser[];
    lastUpdated: string;
    qualification: OrmQualification | null;
    subjectArea: OrmSubjectArea | null;
    status: string;
    attributes?: InitialAttribute[];
    cohort?: ListObject<number>;
    students?: StudentModel[];
    classTrackerGroup?: ListObject<number>;
    maintenanceMessage?: string | null;
    isDisabled?: boolean;
    cohortId?: number;
    isDemo?: boolean;
    tiers?: string[];
    groupCallStatus?: string;
    classTrackerGroupId?: number;
    isFullyloaded?: boolean;
    isBlocked?: boolean;
}

class ClassTracker extends Model<typeof fields> {
    [x: string]: any; // NOTE: typescript workaround
    static modelName = "ClassTracker";
    static fields = fields;
    static emptyRef: OrmClassTracker = {
        id: 0,
        name: "",
        yearGroup: 0,
        numberOfStudents: 0,
        specification: null,
        teachers: [],
        lastUpdated: "",
        qualification: null,
        subjectArea: null,
        status: TrackerStatus.DRAFT,
        isFullyloaded: false,
    };

    get ormRef(): OrmClassTracker {
        const sortedTiers = sortTiers((this.ref as any).tiers || []);

        return { ...this.ref, tiers: sortedTiers } as any;
    }

    get ormRefDetails(): OrmClassTracker {
        return this.ormRef as any;
    }
}

export default ClassTracker;
