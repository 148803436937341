import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmUser } from "src/orm/models/User";
import { getSchoolAccountId } from "src/services/url";

export const adminsListEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/user/admins/`,
    method: ApiRequests.GET,
};

export type AdminListResponse = OrmUser[];
