import { ColourConfigTypes, ColourTypes } from "../services/colorHandler";

export interface HeaderConfig {
    sortable: boolean;
    colour: string;
    type?: string;
    isCollapsed: boolean;
    isNeverCollapsed?: boolean;
    displayMaxMarks?: boolean;
    isDisplay?: boolean;
    showTitle?: boolean;
    title?: string;
}

export interface HeaderNode {
    nodeId: number;
    name: string;
    nodeType: string;
    headerClass?: {};
    sortable?: boolean;
    headerConfig: HeaderConfig | null;
    hash: string;
}

export enum ColumnTypeGradeTypes {
    UNIT = "unit",
    COURSE = "course",
}

export interface ColumnConfig {
    type: string;
    typeConfig?: {
        displayText?: boolean;
        map?: {
            "<": string;
            ">": string;
            "==": string;
            "-1": string;
        };
        allowAbsent: boolean;
        choices: any[];
        gradeType?: ColumnTypeGradeTypes | null;
    };
    colourConfigType?: ColourConfigTypes;
    colourConfig?: {} | null;
}

export interface ColourConfigDetailsObject {
    mtgDistance: string;
    opacity: number;
    threshold: number;
    colour: string;
    gradeId: number;
}

export interface ColourConfig {
    type?: ColourTypes;
    details: ColourConfigDetailsObject[] | null;
    options?: { I: string; N: string; S: string; Y: string };
}

export interface ColumnNode {
    id: number;
    readOnly: boolean;
    columnType: string;
    maxValue?: number;
    columnConfig?: ColumnConfig | null;
    colourConfig?: ColourConfig;
    hash: string;
}

export interface HeaderNodeWithChildren extends HeaderNode {
    children: HeaderNodeWithChildren[];
}

export interface ClassTrackerHeaderObject {
    header: HeaderNodeWithChildren;
    maxLevel: number;
    columns: {
        [key: string]: ColumnNode;
    };
    version: number;
}

export interface ClassTrackerFlatHeader {
    parentUnit?: string;
    parentNode?: string;
    name: string;
    field: string;
    type: string;
    isNeverCollapsed: boolean;
    parentUnitType?: string;
    columnType?: string;
    columnConfigType?: string;
}
