import FormikRef from "src/forms/FormikRef";
import EditEndOfTopicTestForm, {
    EditEndOfTopicTestFormShape,
    convertFormDataToApiRequest,
    editEndOfTopicTestSchema,
} from "../components/forms/EditEndOfTopicTestForm";
import AppContainer from "src/components/AppContainer";
import { useEffect, useRef } from "react";
import { Typography, Paper, CircularProgress, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch } from "redux";
import { CommonActions } from "src/modules/common/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { AppState } from "src/store/reducers";
import { ROUTE_CLASS_ASSESSMENT_LIST } from "src/routes";
import { useLocation, useParams } from "react-router";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { OrmTopic } from "src/orm/models/Topic";
import { OrmGrade } from "src/orm/models/Grade";
import { topicsWithFilterSelector } from "src/modules/common/selectors/TopicSelectors";
import { useCustomisedAssessmentDetails } from "../hooks/Customised/useDetails";
import { useCustomisedAssessmentEditMutation } from "../hooks/Customised/useEditMutation";
import { gradesSelector } from "src/modules/common/selectors/GradeSelectors";
import { sortByKey } from "src/services/object";
import { useMount } from "src/hooks/useMount";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const dispatchActions = (dispatch: Dispatch) => ({
    getTopics: (specificationId: number) => {
        dispatch(CommonActions.getTopicList(specificationId));
    },
    getGrades: (specificationId: number) => {
        dispatch(CommonActions.getGradeList(specificationId));
    },
});

const EditEndOfTopicTestContainer = () => {
    const form = useRef() as any;
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { navigate } = useSchoolNavigate();
    const { data } = useCoreValues();
    const cohorts = data?.cohorts;

    const { getTopics, getGrades } = dispatchActions(dispatch);

    const { cohort, endOfTopicTest: endOfTopicTestId } = useParams();
    const { data: endOfTopicTest, refetch } = useCustomisedAssessmentDetails(
        parseInt(endOfTopicTestId),
    );
    const { qualification, subjectArea, yearGroup } = Object.fromEntries(
        new URLSearchParams(location.search),
    );

    useEffect(() => {
        if (endOfTopicTest?.specification?.id) {
            getGrades(endOfTopicTest.specification?.id);
            getTopics(endOfTopicTest.specification?.id);
        }
    }, [endOfTopicTest?.specification]);

    const { topics, grades }: { topics: OrmTopic[]; grades: OrmGrade[] } = useSelector(
        (state: AppState) => ({
            topics: topicsWithFilterSelector(
                state,
                t => t.specificationId === endOfTopicTest?.specification?.id,
            ).sort(sortByKey("lft")),
            grades: gradesSelector(state),
        }),
    );

    const initialValues: EditEndOfTopicTestFormShape = {
        ...endOfTopicTest,
        hiddenColumns: endOfTopicTest?.hiddenColumns || false,
        dataEntryType:
            endOfTopicTest?.personalisedAssesmentQuestions?.length === 1 &&
            endOfTopicTest?.personalisedAssesmentQuestions[0].question === endOfTopicTest.name
                ? "single"
                : "qla",
        personalisedAssesmentQuestions: endOfTopicTest?.personalisedAssesmentQuestions
            ? endOfTopicTest?.personalisedAssesmentQuestions.map(paq => ({
                  ...paq,
                  topics: paq.topics?.map(t => t.id) || [],
                  maxValue: `${paq.maxValue}`,
              }))
            : [{ question: "", maxValue: "" }],
        yearGroupWithoutVisibility: [],
        gradeBoundaryType:
            endOfTopicTest?.gradeBoundaryValues && endOfTopicTest?.gradeBoundaryValues.length > 0
                ? "custom"
                : endOfTopicTest?.gradeBoundaryFactor === 0
                  ? "tracker"
                  : "factor",
        cohort: parseInt(cohort),
        gradeBoundaryFactor: `${endOfTopicTest?.gradeBoundaryFactor}`,
        gradeBoundaryValues: endOfTopicTest?.gradeBoundaryValues.map(gbv => ({
            id: gbv.grade.id,
            name: gbv.grade.name,
            requireMark: gbv.requireMark,
        })),
    };

    const { mutate: editEndOfTopicTest } = useCustomisedAssessmentEditMutation(
        parseInt(endOfTopicTestId),
        () => {
            enqueueSnackbar(t("class.endOfTopicTest.add.success"), {
                variant: "success",
            });
            form.current.setSubmitting(false);
            navigate(
                ROUTE_CLASS_ASSESSMENT_LIST.replace(":cohort", cohort) +
                    `?subjectArea=${subjectArea || ""}&qualification=${
                        qualification || ""
                    }&yearGroup=${yearGroup || ""}`,
            );
        },
        () => {
            form.current.setSubmitting(false);
        },
    );

    const handleSubmit = values => {
        if (values.id) {
            const processedValues = convertFormDataToApiRequest(values);
            editEndOfTopicTest(processedValues);
        }
    };

    useMount(() => {
        refetch();
    });

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("class.endOfTopicTest.edit.header")}
            </Typography>
            <Paper>
                {endOfTopicTest ? (
                    <FormikRef
                        ref={form}
                        initialValues={initialValues}
                        validationSchema={() => editEndOfTopicTestSchema(t)}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {() => (
                            <EditEndOfTopicTestForm
                                grades={grades}
                                topics={topics}
                                cohort={cohorts.find(c => c.id === parseInt(cohort || ""))}
                                isFormLocked={!endOfTopicTest.canEdit}
                            />
                        )}
                    </FormikRef>
                ) : (
                    <Box p={4} sx={{ textAlign: "center" }}>
                        <CircularProgress />
                    </Box>
                )}
            </Paper>
        </AppContainer>
    );
};

export default EditEndOfTopicTestContainer;
