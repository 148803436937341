import { createAction, Keys } from "@martin_hotell/rex-tils";
import { ApiActionMeta, ApiCallbackPayload } from "src/api/constants";
import { createActionObject, createApiAction, paramRequest } from "src/api/helpers";
import { getClassTrackerGroupHeaderEndpoint } from "../../api/YearGroupOverview/getClassTrackerGroupHeader";
import { getClassTrackerGroupValuesEndpoint } from "../../api/YearGroupOverview/getClassTrackerGroupValues";
import { getTrackerGroupRelatedEndpoint } from "../../api/YearGroupOverview/getClassTrackerGroupRelated";
import { moveStudentsToClassEndpoint } from "../../api/YearGroupOverview/moveStudents";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { ClassTrackerHeaderObject } from "../../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../../dto/TrackerRelated";
import { ClassTrackerValuesObject } from "../../dto/TrackerValues";

export enum ClassTrackerGroupActionsTypes {
    GET_CLASS_TRACKER_GROUP_HEADER = "[tracker] GET CLASS TRACKER GROUP HEADER",
    GET_CLASS_TRACKER_GROUP_HEADER_SUCCESS = "[tracker] GET CLASS TRACKER GROUP HEADER SUCCESS",
    GET_CLASS_TRACKER_GROUP_FORECAST_HEADER = "[tracker] GET CLASS TRACKER GROUP FORECAST HEADER",
    GET_CLASS_TRACKER_GROUP_FORECAST_HEADER_SUCCESS = "[tracker] GET CLASS TRACKER GROUP FORECAST HEADER SUCCESS",
    GET_CLASS_TRACKER_GROUP_VALUES = "[tracker] GET CLASS TRACKER GROUP VALUES",
    GET_CLASS_TRACKER_GROUP_VALUES_SUCCESS = "[tracker] GET CLASS TRACKER GROUP VALUES SUCCESS",
    GET_CLASS_TRACKER_GROUP_RELATED = "[tracker] GET CLASS TRACKER GROUP RELATED",
    GET_CLASS_TRACKER_GROUP_RELATED_SUCCESS = "[tracker] GET CLASS TRACKER GROUP RELATED SUCCESS",
    SET_CLASS_TRACKER_GROUP_RELATED = "[tracker] SET CLASS TRACKER GROUP RELATED",
    SET_CLASS_TRACKER_GROUP_HEADER = "[tracker] SET CLASS TRACKER GROUP HEADER",
    SET_CLASS_TRACKER_GROUP_VALUES = "[tracker] SET CLASS TRACKER GROUP VALUES",
    MOVE_STUDENTS_TO_CLASS = "[tracker] MOVE STUDENTS TO CLASS",
    MOVE_STUDENTS_TO_CLASS_SUCCESS = "[tracker] MOVE STUDENTS TO CLASS SUCCESS",
}

export const ClassTrackerGroupApiEndpoints = {
    getClassTrackerGroupHeader: createActionObject(
        getClassTrackerGroupHeaderEndpoint,
        ClassTrackerGroupActionsTypes.GET_CLASS_TRACKER_GROUP_HEADER_SUCCESS,
    ),
    getClassTrackerGroupValues: createActionObject(
        getClassTrackerGroupValuesEndpoint,
        ClassTrackerGroupActionsTypes.GET_CLASS_TRACKER_GROUP_VALUES_SUCCESS,
    ),
    getClassTrackerGroupRelated: createActionObject(
        getTrackerGroupRelatedEndpoint,
        ClassTrackerGroupActionsTypes.GET_CLASS_TRACKER_GROUP_RELATED_SUCCESS,
    ),
    moveStudentsToClass: createActionObject(
        moveStudentsToClassEndpoint,
        ClassTrackerGroupActionsTypes.MOVE_STUDENTS_TO_CLASS_SUCCESS,
    ),
};

export const ClassTrackerGroupRawActions = (
    ClassTrackerEndpoints: Record<Keys<typeof ClassTrackerGroupApiEndpoints>, ApiActionMeta>,
) => ({
    getClassTrackerGroupHeader: (classTrackerGroup: number, tier: TiersTypes) =>
        createApiAction(
            ClassTrackerGroupActionsTypes.GET_CLASS_TRACKER_GROUP_HEADER,
            { params: { classTrackerGroup, tier }, values: {} },
            ClassTrackerEndpoints.getClassTrackerGroupHeader,
        ),
    getClassTrackerGroupHeaderSuccess: (payload: ApiCallbackPayload<ClassTrackerHeaderObject>) =>
        createAction(ClassTrackerGroupActionsTypes.GET_CLASS_TRACKER_GROUP_HEADER_SUCCESS, payload),
    setClassTrackerGroupHeader: (payload: ClassTrackerHeaderObject) =>
        createAction(ClassTrackerGroupActionsTypes.SET_CLASS_TRACKER_GROUP_HEADER, payload),
    getClassTrackerGroupValues: (classTrackerGroup: number, tier: TiersTypes) =>
        createApiAction(
            ClassTrackerGroupActionsTypes.GET_CLASS_TRACKER_GROUP_VALUES,
            { params: { classTrackerGroup, tier }, values: {} },
            ClassTrackerEndpoints.getClassTrackerGroupValues,
        ),
    getClassTrackerGroupValuesSuccess: (payload: ApiCallbackPayload<ClassTrackerValuesObject>) =>
        createAction(ClassTrackerGroupActionsTypes.GET_CLASS_TRACKER_GROUP_VALUES_SUCCESS, payload),
    setClassTrackerGroupValues: (payload: ClassTrackerValuesObject) =>
        createAction(ClassTrackerGroupActionsTypes.SET_CLASS_TRACKER_GROUP_VALUES, payload),
    getClassTrackerGroupRelated: (classTrackerGroup: number) =>
        createApiAction(
            ClassTrackerGroupActionsTypes.GET_CLASS_TRACKER_GROUP_RELATED,
            paramRequest({ classTrackerGroup }),
            ClassTrackerEndpoints.getClassTrackerGroupRelated,
        ),
    getClassTrackerGroupRelatedSuccess: (payload: ApiCallbackPayload<ClassTrackerRelatedObject>) =>
        createAction(
            ClassTrackerGroupActionsTypes.GET_CLASS_TRACKER_GROUP_RELATED_SUCCESS,
            payload,
        ),
    setClassTrackerGroupRelated: (payload: ClassTrackerRelatedObject) =>
        createAction(ClassTrackerGroupActionsTypes.SET_CLASS_TRACKER_GROUP_RELATED, payload),

    moveStudentsToClass: (classTrackerGroup: number, values) =>
        createApiAction(
            ClassTrackerGroupActionsTypes.MOVE_STUDENTS_TO_CLASS,
            { values, params: { classTrackerGroup } },
            ClassTrackerEndpoints.moveStudentsToClass,
        ),
    moveStudentsToClassSuccess: () =>
        createAction(ClassTrackerGroupActionsTypes.MOVE_STUDENTS_TO_CLASS_SUCCESS),
});
