import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { PROFILE_QUERY, ProfileResponse } from "./useProfile";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

export interface EditUserProfileAfterConfirmRequest {
    phone?: string;
    mobile?: string;
    schoolTitle?: string;
}

const apiEditProfileAfterConfirm = async (
    values: EditUserProfileAfterConfirmRequest,
): Promise<AxiosResponse<ProfileResponse>> => {
    return await axiosInstance.put(`school/${getSchoolAccountId()}/profile/phone/`, values);
};

export const useEditProfileAfterConfirm = (onSuccess?: (data: ProfileResponse) => void) => {
    const schoolAccountId = getSchoolAccountId();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: EditUserProfileAfterConfirmRequest) =>
            apiEditProfileAfterConfirm(values),
        onSuccess: ({ data }: { data: ProfileResponse }) => {
            queryClient.invalidateQueries({ queryKey: [PROFILE_QUERY, schoolAccountId] });
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error, {
                    ...SnackbarErrorOptions,
                });
            } else {
                enqueueSnackbar(t("common.unknownError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
