import { Dispatch } from "redux";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "src/store/reducers";
import { AnalysisActions } from "../store/actions";
import { useEffect, useState } from "react";
import { ApiStatus } from "src/api/constants";
import { prepareStudentFilters } from "../services/analysisValueHandler";
import { StudentFilterRequest } from "../hooks/GradeSumary/useAnalysisGradeSummaryValues";

export interface StudentAttributePair {
    attribute: string;
    attributeDisplay: string;
    value: string;
    valueDisplay: string;
}

const dispatchActions = (dispatch: Dispatch) => ({
    getAnalysisAtlHeader: (cohort: number, yearGroup: number) => {
        dispatch(AnalysisActions.getAnalysisAtlHeader(cohort, yearGroup));
    },
    getAnalysisRelated: (cohort: number, yearGroup: number) => {
        dispatch(AnalysisActions.getAnalysisRelated(cohort, yearGroup));
    },
    getAnalysisAtlValues: (
        cohort: number,
        yearGroup: number,

        studentFilters: StudentFilterRequest,
    ) => {
        dispatch(
            AnalysisActions.getAnalysisAtlValues(
                cohort,
                yearGroup,

                studentFilters,
            ),
        );
    },
    clearAnalysisData: () => {
        dispatch(AnalysisActions.clearAllAnalysisData());
    },

    getSnapshotCompareValues: (
        cohort: number,
        yearGroup: number,
        dataSource1: string,
        dataSource2: string,
        studentFilters: StudentAttributePair[],
    ) => {
        dispatch(
            AnalysisActions.getAnalysisAtlSnapshotCompare(
                cohort,
                yearGroup,
                dataSource1,
                dataSource2,
                prepareStudentFilters(studentFilters),
            ),
        );
    },
    getSnapshotValues: (
        cohort: number,
        yearGroup: number,
        snapshot: number,
        studentFilters: StudentAttributePair[],
    ) => {
        dispatch(
            AnalysisActions.getAnalysisAtlSnapshot(
                cohort,
                yearGroup,
                snapshot,
                prepareStudentFilters(studentFilters),
            ),
        );
    },
});

export const useAnalysisAtlData = (studentFilters: StudentAttributePair[]) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const {
        getAnalysisAtlHeader,
        getAnalysisAtlValues,
        getAnalysisRelated,
        clearAnalysisData,
        getSnapshotCompareValues,
        getSnapshotValues,
    } = dispatchActions(dispatch);

    const getAnalysisData = (cohort: number, yearGroup: number) => {
        getAnalysisRelated(cohort, yearGroup);
        getAnalysisAtlHeader(cohort, yearGroup);
        getAnalysisAtlValues(cohort, yearGroup, prepareStudentFilters(studentFilters));
    };
    const getSnapshotCompareData = (
        cohort: number,
        yearGroup: number,
        dataSource1: string,
        dataSource2: string,
    ) => {
        getAnalysisRelated(cohort, yearGroup);
        getAnalysisAtlHeader(cohort, yearGroup);
        getSnapshotCompareValues(cohort, yearGroup, dataSource1, dataSource2, studentFilters);
    };
    const getSnapshotData = (
        cohort: number,
        yearGroup: number,
        snapshot: number,
        studentFilters: StudentAttributePair[],
    ) => {
        getAnalysisRelated(cohort, yearGroup);
        getAnalysisAtlHeader(cohort, yearGroup);
        getSnapshotValues(cohort, yearGroup, snapshot, studentFilters);
    };

    const refetchValues = (
        cohort: number,
        yearGroup: number,
        studentFilters: StudentAttributePair[],
    ) => {
        getAnalysisAtlValues(cohort, yearGroup, prepareStudentFilters(studentFilters));
    };

    const {
        apiHeader,
        apiValues,
        apiRelated,
        apiCompare,
        analysisAtlHeader,
        analysisAtlCompareValues,
        analysisRelated,
        analysisAtlValues,
        analysisStudents,
    } = useSelector(
        (state: AppState) => ({
            apiHeader: state.api.analysis.getAnalysisAtlHeader,
            apiRelated: state.api.analysis.getAnalysisRelated,
            apiValues: state.api.analysis.getAnalysisAtlValues,
            apiCompare: state.api.analysis.getAnalysisAtlSnapshotCompare,
            analysisAtlHeader: state.analysis.analysisAtlHeader,
            analysisAtlValues: state.analysis.analysisAtlValues,
            analysisAtlCompareValues: state.analysis.analysisAtlCompare,
            analysisRelated: state.analysis.analysisRelated,
            analysisStudents: state.analysis.analysisStudents,
        }),
        shallowEqual,
    );

    const isDataFetched =
        analysisAtlHeader && (analysisAtlValues || analysisAtlCompareValues) && analysisRelated;

    useEffect(() => {
        let isHeaderLoading = false;
        let isValuesLoading = false;

        if (apiHeader.status !== ApiStatus.SUCCESS || apiRelated.status !== ApiStatus.SUCCESS) {
            isHeaderLoading = true;
        }
        if (apiValues.status !== ApiStatus.SUCCESS || apiCompare.status !== ApiStatus.SUCCESS) {
            isValuesLoading = true;
        }

        if (apiHeader.status === ApiStatus.SUCCESS && apiRelated.status === ApiStatus.SUCCESS) {
            isHeaderLoading = false;
        }

        if (apiValues.status === ApiStatus.SUCCESS || apiCompare.status === ApiStatus.SUCCESS) {
            isValuesLoading = false;
        }

        if (!isHeaderLoading && !isValuesLoading) {
            setIsLoading(false);
        }
        // eslint-disable-next-linea  react-hooks/exhaustive-deps
    }, [apiHeader, apiValues, apiRelated, apiCompare]);

    return {
        apiHeader,
        apiValues,
        apiRelated,
        analysisAtlHeader,
        analysisAtlValues,
        analysisAtlCompareValues,
        analysisRelated,
        getAnalysisData,
        clearAnalysisData,
        isDataFetched,
        isLoading,
        setIsLoading,
        refetchValues,
        analysisStudents,
        getSnapshotCompareData,
        getSnapshotData,
    };
};
