import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ParentModel } from "../model/ParentModel";
import { PagedList, Pagination } from "src/types";
import { getSchoolAccountId } from "src/services/url";

export const PARENT_LIST_QUERY = "parentListQuery";

export interface ParentListFilters {
    name?: string;
    userIdentifier?: string;
    status?: "non-invitable" | "not-invited" | "invited" | "confirmed" | "deactivated";
    groupCallStatus?: string;
    studentName?: string;
    studentUpn?: string;
    studentForm?: string;
    studentGroupCallStatus?: string;
    studentYearGroup?: string;
}

export const useParentsList = (pagination: Pagination, filters: ParentListFilters) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [PARENT_LIST_QUERY, schoolAccountId, pagination, filters],
        queryFn: async (): Promise<PagedList<ParentModel> | null> => {
            const { data } = await axiosInstance.get(`school/${getSchoolAccountId()}/parents/`, {
                params: { ...pagination, ...filters },
            });

            return data || null;
        },
    });
};
