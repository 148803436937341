import COLORS from "src/styles/colors";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TrackerCompareDataSourceTypes } from "src/modules/tracker/services/valueHandler";
import { theme } from "src/styles/theme";
import { format, fromUnixTime } from "date-fns";

const GridSnapshotCompareInfo = ({ snapshotData, snapshots }) => {
    const { t } = useTranslation();

    const snapshotCreated = snapshots?.find(
        s => s.id === (snapshotData?.snapshot ? parseInt(snapshotData?.snapshot) : 0),
    )?.created;

    const compareSource1 =
        snapshotData?.compare?.dataSource1 === TrackerCompareDataSourceTypes.LIVE_TRACKING
            ? t("tracker.snapshot.liveTracking")
            : snapshotData?.compare?.dataSource1 === TrackerCompareDataSourceTypes.FORECAST
              ? t("tracker.snapshot.forecast")
              : format(
                    fromUnixTime(
                        snapshots?.find(
                            s =>
                                s.id ===
                                (snapshotData?.compare?.dataSource1
                                    ? parseInt(snapshotData?.compare?.dataSource1)
                                    : 0),
                        )?.created || 0,
                    ),
                    "dd MMMM yyyy hh:mm:ss",
                );

    const compareSource2 =
        snapshotData?.compare?.dataSource2 === TrackerCompareDataSourceTypes.LIVE_TRACKING
            ? t("tracker.snapshot.liveTracking")
            : snapshotData?.compare?.dataSource2 === TrackerCompareDataSourceTypes.FORECAST
              ? t("tracker.snapshot.forecast")
              : format(
                    fromUnixTime(
                        snapshots?.find(
                            s =>
                                s.id ===
                                (snapshotData?.compare?.dataSource2
                                    ? parseInt(snapshotData?.compare?.dataSource2)
                                    : 0),
                        )?.created || 0,
                    ),
                    "dd MMMM yyyy hh:mm:ss",
                );

    return (
        <>
            {snapshotData?.compare && (
                <Box
                    mt={0.75}
                    display="flex"
                    justifyContent="flex-end"
                    className="classTrackerHeaderSnapshotData"
                >
                    <Box
                        component="span"
                        fontFamily={theme.typography.fontFamilyPrimary}
                        fontSize={theme.typography.pxToRem(14)}
                        fontWeight={theme.typography.fontWeightBold}
                        color={COLORS.GREY_3}
                    >
                        {`${compareSource1} - ${compareSource2}`}
                    </Box>
                </Box>
            )}
            {snapshotCreated && (
                <Box mt={0.75} display="flex" justifyContent="flex-end">
                    <Box
                        component="span"
                        fontFamily={theme.typography.fontFamilyPrimary}
                        fontSize={theme.typography.pxToRem(14)}
                        fontWeight={theme.typography.fontWeightBold}
                        color={COLORS.GREY_3}
                    >
                        <Box component="span" color={COLORS.LIGHT_GREY_1}>
                            {t("tracker.grid.viewSnapshot")}
                        </Box>{" "}
                        {format(fromUnixTime(snapshotCreated), "dd MMMM yyyy hh:mm:ss")}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default GridSnapshotCompareInfo;
