/* eslint-disable react/display-name */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import { MenuItem, Typography } from "@mui/material";
import { usePrevious } from "src/hooks/usePrevious";
import { StyledCustomInput, StyledCustomSelect } from "./StyledCustomEditors";

interface OwnProps {
    value: string;
    api: GridApi;
    options: { value: number; option: string }[];
    context: any;
}

const SelectableEditor = forwardRef(
    ({ value: propsValue, api: gridApi, options, context }: OwnProps, ref) => {
        const [value, setValue] = useState(propsValue || "");
        const [open, setOpen] = useState<boolean>(true);
        const refInput = useRef(null) as any;
        const { isBlocked, isGradeVBoundaryProcessing } = context;

        const handleChange = (value: string) => {
            setValue(value);
            setOpen(false);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleOpen = () => {
            setOpen(true);
        };

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    return value;
                },
            };
        });

        const prevValue = usePrevious(value);
        useEffect(() => {
            if (prevValue !== undefined && prevValue !== value) {
                gridApi.stopEditing();
            }
            //eslint-disable-next-line
        }, [value]);

        useEffect(() => {
            if (gridApi && (isBlocked || isGradeVBoundaryProcessing)) {
                gridApi.stopEditing(true);
            }
        }, [isBlocked, isGradeVBoundaryProcessing, gridApi]);

        return (
            <div className="ag-editor-cell">
                <StyledCustomSelect
                    ref={refInput}
                    value={value || ""}
                    onChange={e => handleChange(e.target.value as string)}
                    input={<StyledCustomInput />}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    fullWidth
                >
                    <MenuItem value=""></MenuItem>
                    {options?.map((option: { value: number; option: string }) => (
                        <MenuItem key={option.value} value={option.option}>
                            <Typography variant="inherit" noWrap>
                                {option.option}
                            </Typography>
                        </MenuItem>
                    ))}
                </StyledCustomSelect>
            </div>
        );
    },
);

export default SelectableEditor;
