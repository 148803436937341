import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const createSubjectSnapshotReportEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/snapshot/report/subject/`,
    method: ApiRequests.POST,
};

export interface CreateSubjectSnapshotReportRequest {
    snapshot: ListObject<number>;
    publishedAt: string;
    reportTemplate: ListObject<number>;
}
