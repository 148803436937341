import axiosInstance from "src/config/axios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { getSchoolAccountId } from "src/services/url";

const apiResendInvitation = async (values: ListObject<number>): Promise<AxiosResponse> => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/parents/${values.id}/resend-invitation/`,
        values,
    );
};

export const useParentResendInvitationMutation = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: (values: ListObject<number>) => apiResendInvitation(values),
        onSuccess: () => {
            enqueueSnackbar(t("parent.list.resendSuccess"), { variant: "success" });
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error || t("parent.list.activateError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
