import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const TAG_SEARCH_QUERY = "tagSearch";

export const useNumberOfStudentsByTag = (cohort: number, yearGroup: number, tagId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [TAG_SEARCH_QUERY, schoolAccountId, cohort, yearGroup, tagId],
        queryFn: async (): Promise<number> => {
            if (tagId && cohort && yearGroup > -1) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/analysis/student-report/${cohort}/${yearGroup}/count-by-tag/${tagId}/`,
                );

                return data?.numberOfStudents || 0;
            }
            return 0;
        },
    });
};
