import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { SnapshotModel } from "src/modules/tracker/models/SnapshotModel";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_SNAPSHOT_LIST_QUERY = "classTrackerGroupSnapshotsList";

export type ClassTrackerGroupSnapshotListResponse = SnapshotModel[];

export const useClassTrackerGroupSnapshots = (id: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CLASS_TRACKER_GROUP_SNAPSHOT_LIST_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<ClassTrackerGroupSnapshotListResponse | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker-group/${id}/snapshot/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
