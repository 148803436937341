import { Fragment, useState } from "react";
import { Grid, Box, Button, Chip, Theme, MenuItem, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTE_MY_SCHOOL } from "src/routes";
import { SchoolUserRole } from "src/orm/models/User";
import YourDetailsForm from "../components/YourDetailsForm";
import PaperInner from "src/components/PaperInner";
import HeadingCounter from "src/components/HeadingCounter";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import { mdiPlaylistCheck } from "@mdi/js";
import { useProfile } from "../hooks/useProfile";
import { useSchoolDetails } from "src/modules/school/hooks/query/SchoolDetails/useSchoolDetails";
import { useUserContext } from "../hooks/useUserContext";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getSchoolAccountId } from "src/services/url";

const useStyles = makeStyles((theme: Theme) => ({
    chipsList: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: theme.spacing(1.25),
        "& > *": {
            margin: theme.spacing(0, 1.5, 1.5, 0),
        },
    },
}));

const MyProfilesTab = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [selectedProfile, setProfile] = useState<number | undefined>(getSchoolAccountId());
    const { data: profile } = useProfile(selectedProfile);
    const { data: schoolDetails } = useSchoolDetails(selectedProfile);
    const { data: userContext } = useUserContext();
    const { attachSchoolId } = useSchoolNavigate();

    if (userContext?.schoolAccounts.length === 0) {
        return (
            <>
                <PaperInner border="bottom">{t("account.noProfiles")}</PaperInner>
            </>
        );
    }

    return (
        <>
            <PaperInner border="bottom">
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <TextField
                            id="profile"
                            name="profile"
                            label={t("account.profile")}
                            fullWidth
                            value={selectedProfile}
                            onChange={e => {
                                setProfile(parseInt(`${e.target.value}`));
                            }}
                            select
                        >
                            {userContext?.schoolAccounts.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {selectedProfile && (
                            <Box sx={{ mt: 3 }}>
                                <Box fontSize={11} color={COLORS.GREY_2}>
                                    {t(`school.staff.list.status`)}
                                </Box>
                                <Box fontWeight="bold" fontSize={16}>
                                    {profile?.status &&
                                        t(`school.staff.user.status.${profile?.status}`)}
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </PaperInner>
            {selectedProfile && (
                <Grid container>
                    <Grid item sm={8}>
                        <YourDetailsForm selectedSchoolAccountId={selectedProfile} />
                    </Grid>
                    <Grid item sm={4}>
                        <PaperInner color="lightGrey">
                            <HeadingCounter number="2">{t("account.schoolAccount")}</HeadingCounter>
                            <Box mb={2.5}>
                                <Box color={COLORS.GREY_4} fontSize={11}>
                                    {t("account.accountType")}
                                </Box>
                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                    {profile?.schoolRole}
                                </Box>
                            </Box>
                            <Box mb={2.5}>
                                <Box color={COLORS.GREY_4} fontSize={11}>
                                    {t("account.yourSchool")}
                                </Box>
                                <Box fontWeight="fontWeightMedium" mt={0.25}>
                                    {profile?.school && profile.school.name}
                                </Box>
                                <Box
                                    color={COLORS.GREY_3}
                                    fontSize={12}
                                    fontWeight="fontWeightMedium"
                                    mt={0.25}
                                >
                                    {schoolDetails &&
                                        `${t("account.urn")}: ${schoolDetails.code || "-"}`}
                                </Box>
                            </Box>
                            {profile?.userRole !== SchoolUserRole.FINANCE && (
                                <Fragment>
                                    <Box color={COLORS.GREY_4} fontSize={11}>
                                        {t("account.availableSubjectAreas")}
                                    </Box>
                                    <Box className={classes.chipsList}>
                                        {profile && profile.availableToAllSubjectAreas === true && (
                                            <Chip
                                                style={{
                                                    paddingRight: 2,
                                                    backgroundColor: COLORS.BLUE_2,
                                                }}
                                                label={t("account.allSubjectAreas")}
                                            />
                                        )}
                                        {profile &&
                                        !profile.availableToAllSubjectAreas &&
                                        profile.leaderOfSubjectAreas &&
                                        profile.leaderOfSubjectAreas.length > 0 ? (
                                            <>
                                                <Box
                                                    color={COLORS.GREY_3}
                                                    fontSize={12}
                                                    fontWeight="fontWeightMedium"
                                                    mt={0.25}
                                                >
                                                    {t("account.leaderOfSubjects")}
                                                </Box>
                                                <Box>
                                                    {profile &&
                                                        profile.leaderOfSubjectAreas.map(sa => (
                                                            <Chip
                                                                style={{
                                                                    marginRight: 4,
                                                                    marginBottom: 4,
                                                                    backgroundColor: sa.colour,
                                                                }}
                                                                key={sa.id}
                                                                label={sa.name}
                                                            />
                                                        ))}
                                                </Box>
                                            </>
                                        ) : null}
                                        {profile &&
                                        !profile.availableToAllSubjectAreas &&
                                        profile.availableSubjectAreas.length !==
                                            profile.leaderOfSubjectAreas.length ? (
                                            <>
                                                <Box
                                                    color={COLORS.GREY_3}
                                                    fontSize={12}
                                                    fontWeight="fontWeightMedium"
                                                    mt={0.25}
                                                >
                                                    {t("account.teacherOfSubjects")}
                                                </Box>
                                                <Box>
                                                    {profile &&
                                                        profile.availableSubjectAreas
                                                            .filter(
                                                                asa =>
                                                                    !profile.leaderOfSubjectAreas
                                                                        .map(losa => losa.id)
                                                                        .includes(asa.id),
                                                            )
                                                            .map(sa => (
                                                                <Chip
                                                                    style={{
                                                                        marginRight: 4,
                                                                        marginBottom: 4,
                                                                        backgroundColor: sa.colour,
                                                                    }}
                                                                    key={sa.id}
                                                                    label={sa.name}
                                                                />
                                                            ))}
                                                </Box>
                                            </>
                                        ) : null}
                                    </Box>
                                    <Box mt={1.5}>
                                        <Button
                                            component={Link}
                                            to={attachSchoolId(ROUTE_MY_SCHOOL)}
                                            variant="text"
                                            startIcon={<Icon path={mdiPlaylistCheck} />}
                                            disableRipple
                                        >
                                            {t("account.manageSubjectAreas")}
                                        </Button>
                                    </Box>
                                </Fragment>
                            )}
                        </PaperInner>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default MyProfilesTab;
