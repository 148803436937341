import { Box, Paper, Chip } from "@mui/material";
import { mdiCartPlus, mdiCancel } from "@mdi/js";
import { ApiData, ApiStatus } from "src/api/constants";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import CustomTable from "src/components/CustomTable";
import { CellDataGetterParams } from "src/components/CustomTable/types";
import Icon from "src/components/Icon";
import PromptDialog from "src/forms/PromptDialog";
import { usePrevious } from "src/hooks/usePrevious";
import {
    SubscriptionTypeLabels,
    SubscriptionTypes,
    OrmSubscription,
} from "src/orm/models/Subscription";
import { OrmSubscriptionHistory } from "src/orm/models/SubscriptionHistory";
import { FormError, getErrorMessage } from "src/services/error";
import { convertIsoPeriodToString } from "src/services/translation";
import { AppState } from "src/store/reducers";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Dispatch } from "redux";
import { subscriptionHistorySelector } from "../selectors/SubscriptionSelectors";
import { SubscriptionsActions } from "../store/actions";
import { useMount } from "src/hooks/useMount";
import { ROUTE_MAKE_PAYMENT } from "src/routes";
import COLORS from "src/styles/colors";
import { SchoolUserRole } from "src/orm/models/User";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { useProfile } from "src/modules/user/hooks/useProfile";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const dispatchActions = (dispatch: Dispatch) => ({
    getRequestedList: () => {
        dispatch(SubscriptionsActions.getRequestedList());
    },
    rejectPending: (subscriptionId: number) => {
        dispatch(SubscriptionsActions.rejectPendingSubscription(subscriptionId));
    },
});

const OwnedList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { getRequestedList, rejectPending } = dispatchActions(dispatch);
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);
    const { data: authUser } = useProfile();
    const { attachSchoolId } = useSchoolNavigate();

    const {
        apiReject,
        subscriptionHistory,
    }: {
        apiReject: ApiData;
        subscriptionHistory: OrmSubscriptionHistory[];
    } = useSelector(
        (state: AppState) => ({
            apiReject: state.api.subscription.rejectPendingSubscription,
            subscriptionHistory: subscriptionHistorySelector(state),
        }),
        shallowEqual,
    );

    const handleRemoveDialogOpen = (itemId: number): void => {
        setRemoveDialogOpened(true);
        setRemoveId(itemId);
    };

    const handleRemoveDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            rejectPending(removeId);
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    const prevRejectStatus = usePrevious(apiReject.status);

    const handleErrorResponse = useCallback(
        data => {
            const error: FormError = getErrorMessage(data);
            if (error.message)
                enqueueSnackbar(error.message, {
                    ...SnackbarErrorOptions,
                });
        },
        [enqueueSnackbar],
    );

    useEffect(() => {
        if (prevRejectStatus === ApiStatus.LOADING && apiReject.status === ApiStatus.SUCCESS) {
            getRequestedList();
        } else if (prevRejectStatus === ApiStatus.LOADING && apiReject.status === ApiStatus.ERROR) {
            handleErrorResponse(apiReject);
        }
    }, [apiReject, handleErrorResponse, prevRejectStatus, getRequestedList]);

    useMount(() => getRequestedList());

    return (
        <Fragment>
            <Paper>
                <CustomTable
                    data={subscriptionHistory}
                    showPaginator
                    columns={[
                        {
                            key: "requestedBy",
                            label: t("subscription.requestedList.requestedBy"),
                            cellDataGetter: ({ rowData }: CellDataGetterParams) => {
                                if (rowData) {
                                    const { firstName, lastName, email } = rowData.schoolUser;
                                    return (
                                        <Fragment>
                                            <Box fontSize={14}>{`${firstName} ${lastName}`}</Box>
                                            <Box
                                                color={COLORS.BLUE_3}
                                                mt={0.375}
                                                fontSize={11}
                                                fontStyle="italic"
                                            >
                                                {email}
                                            </Box>
                                        </Fragment>
                                    );
                                }
                                return "";
                            },
                        },
                        {
                            key: "dateAndTime",
                            label: t("subscription.requestedList.dateTime"),
                            cellDataGetter: ({ rowData }: CellDataGetterParams) => {
                                if (rowData.dateAndTime) {
                                    return (
                                        <Fragment>
                                            <Box fontSize={14}>
                                                {format(new Date(rowData.dateAndTime), "dd MMMM Y")}
                                            </Box>
                                            <Box mt={0.375} fontSize={11} fontStyle="italic">
                                                {format(new Date(rowData.dateAndTime), "HH:mm")}
                                            </Box>
                                        </Fragment>
                                    );
                                }
                                return "";
                            },
                        },
                        {
                            key: "subscription",
                            label: t("common.subscription"),
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSubscription>) => {
                                if (rowData.subscriptionType) {
                                    return (
                                        <Fragment>
                                            <Box fontSize={14} fontWeight={700}>
                                                {
                                                    SubscriptionTypeLabels(t)[
                                                        rowData.subscriptionType
                                                    ]
                                                }
                                                {rowData.qualifications.length > 0 &&
                                                    `(${rowData.qualifications.length} ${t(
                                                        rowData.qualifications.length === 1
                                                            ? "common.course"
                                                            : "common.courses",
                                                    )})`}
                                            </Box>
                                            {rowData.subscriptionLength ? (
                                                <Box mt={0.375} fontSize={11} fontStyle="italic">
                                                    {convertIsoPeriodToString(
                                                        rowData.subscriptionLength,
                                                    )}
                                                </Box>
                                            ) : null}
                                        </Fragment>
                                    );
                                }
                                return "";
                            },
                        },
                        {
                            key: "subjectArea",
                            label: t("subscription.list.subjectArea"),
                            cellDataGetter: ({ rowData }: CellDataGetterParams) => {
                                const subjectArea = rowData.subjectArea
                                    ? rowData.subjectArea.name
                                    : "";
                                const qualifications =
                                    rowData.qualifications.length > 0
                                        ? rowData.qualifications.map(q => q.name).join(", ")
                                        : "";
                                if (subjectArea) {
                                    return (
                                        <Fragment>
                                            {subjectArea && (
                                                <Chip
                                                    label={subjectArea}
                                                    style={{
                                                        backgroundColor: rowData.subjectArea.colour,
                                                    }}
                                                />
                                            )}
                                            {qualifications && (
                                                <Box
                                                    component="div"
                                                    fontStyle={
                                                        rowData.subscriptionType ===
                                                        SubscriptionTypes.FULL_SUITE
                                                            ? "italic"
                                                            : "normal"
                                                    }
                                                    fontSize={12}
                                                    mt={0.625}
                                                    color="inherit"
                                                >
                                                    {qualifications}
                                                </Box>
                                            )}
                                        </Fragment>
                                    );
                                } else {
                                    return (
                                        <Box fontSize={12}>
                                            {rowData.subscriptionType ===
                                            SubscriptionTypes.WHOLE_SCHOOL
                                                ? t("subscription.type.wholeSchool")
                                                : "-"}
                                        </Box>
                                    );
                                }
                            },
                        },
                        {
                            key: "amount",
                            label: t("subscription.requestedList.amount"),
                            cellDataGetter: ({ rowData }: CellDataGetterParams) => {
                                if (rowData.totalPrice) {
                                    return (
                                        <Fragment>
                                            <Box
                                                color={COLORS.BLUE_2}
                                                fontSize={14}
                                                fontWeight={700}
                                            >{`£${rowData.totalPrice}`}</Box>
                                            {rowData.couponName && (
                                                <Box
                                                    color={COLORS.GREY_3}
                                                    mt={0.375}
                                                    fontSize={11}
                                                    fontStyle="italic"
                                                >
                                                    {rowData.couponName}
                                                </Box>
                                            )}
                                        </Fragment>
                                    );
                                }
                                return "";
                            },
                        },
                        {
                            key: "dueDate",
                            label: t("subscription.requestedList.valid"),
                            cellDataGetter: ({ rowData }: CellDataGetterParams) => {
                                if (rowData.dueDate) {
                                    return (
                                        <Box fontSize={14}>
                                            {format(new Date(rowData.dueDate), "dd MMMM Y")}
                                        </Box>
                                    );
                                }
                                return "-";
                            },
                        },
                    ]}
                    actions={
                        authUser &&
                        (authUser.userRole === SchoolUserRole.FINANCE ||
                            authUser.userRole === SchoolUserRole.OWNER)
                            ? [
                                  getMenuActionProps("reject", {
                                      text: t("subscription.requestedList.reject"),
                                      icon: <Icon path={mdiCancel} />,
                                      onAction: (s: OrmSubscriptionHistory) => {
                                          if (s.subscriptionId)
                                              handleRemoveDialogOpen(s.subscriptionId);
                                      },
                                  }),
                                  getMenuActionProps("approve", {
                                      text: t("subscription.requestedList.approve"),
                                      icon: <Icon path={mdiCartPlus} />,
                                      linkCallback: (s: OrmSubscriptionHistory) =>
                                          attachSchoolId(ROUTE_MAKE_PAYMENT).replace(
                                              ":id",
                                              `${s.subscriptionId}`,
                                          ),
                                  }),
                              ]
                            : undefined
                    }
                />
            </Paper>
            <PromptDialog open={removeDialogOpened} onClose={handleRemoveDialogClose}>
                {t("subscription.requestedList.rejectPrompt")}
            </PromptDialog>
        </Fragment>
    );
};

export default OwnedList;
