import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export interface ClassTrackerStudentLinkRows {
    [key: string]: { reportLinkId: number; enabled: boolean }[];
}

export const STUDENT_CLASS_TRACKER_GROUP_REPORT_LINKS_LIST_QUERY =
    "studentClassTrackerGroupReportListQuery";

export const useClassTrackerGroupStudentLinksList = (classTrackerGroupId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            STUDENT_CLASS_TRACKER_GROUP_REPORT_LINKS_LIST_QUERY,
            schoolAccountId,
            classTrackerGroupId,
        ],
        queryFn: async (): Promise<ClassTrackerStudentLinkRows | null> => {
            const { data } = await axiosInstance.get(
                `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/student-report-links/`,
            );

            return data || null;
        },
    });
};
