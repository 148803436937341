import { SxProps, styled } from "@mui/material";
import { FC } from "react";

const RootLabel = styled("span", {
    shouldForwardProp: prop => prop !== "variant" && prop !== "size" && prop !== "sx",
})<OwnProps>(({ theme, variant, size }) => ({
    backgroundColor: variant === "eott" ? "#DA6D57" : "#447BBE",
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightMedium,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: size === "medium" ? 26 : 19,
    height: size === "medium" ? 20 : 15,
    borderRadius: 2,
}));

const getLabel = (variant: AssessmentLabelVariant) => {
    return {
        pp: "PA",
        eott: "CA",
    }[variant];
};

type AssessmentLabelVariant = "pp" | "eott";
type AssessmentLabelSize = "small" | "medium";
type OwnProps = {
    variant: AssessmentLabelVariant;
    size?: AssessmentLabelSize;
    sx?: SxProps;
};

const AssessmentLabel: FC<OwnProps> = ({ variant, size = "small", sx }) => {
    return (
        <RootLabel variant={variant} size={size} sx={sx}>
            {getLabel(variant)}
        </RootLabel>
    );
};

export default AssessmentLabel;
