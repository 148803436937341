import { StringMap } from "@martin_hotell/rex-tils";
import qs from "query-string";
import { Observable } from "rxjs";
import { ajax, AjaxResponse } from "rxjs/ajax";
import { trimObjectStringFields } from "./object";
import { Storage } from "src/services/storage";

export const baseHeaders = (): StringMap<string> => {
    return {
        ...(Storage.getItem("token") !== null ? { Authorization: Storage.getItem("token") } : {}),
        "Content-Type": "application/json",
        // "x-frontend-version": process.env.REACT_APP_VERSION ? `${process.env.REACT_APP_VERSION}` : "",
    };
};

export interface Query {
    [key: string]: unknown;
}

export const queryString = (params: Query) => qs.stringify(params);

const withQuery = (url: string, querystring: string | undefined) =>
    [url, querystring].filter(item => !!item).join("?");

export const get = (
    url: string,
    query: Query | undefined,
    headers: object | undefined,
): Observable<AjaxResponse> => {
    const params = {};
    if (query) {
        Object.keys(query).forEach(key => {
            if (query[key]) {
                params[key] = query[key];
            }
        });
    }

    return ajax.get(
        withQuery(url, params && queryString(params)),
        Object.assign({}, baseHeaders(), headers),
    );
};

export const post = (
    url: string,
    body: object | undefined,
    headers: object | undefined,
): Observable<AjaxResponse> => {
    return ajax.post(
        url,
        body ? trimObjectStringFields(body) : body,
        Object.assign({}, baseHeaders(), headers),
    );
};

export const put = (
    url: string,
    body: object | undefined,
    headers: object | undefined,
): Observable<AjaxResponse> => {
    return ajax.put(
        url,
        body ? trimObjectStringFields(body) : body,
        Object.assign({}, baseHeaders(), headers),
    );
};

export const patch = (
    url: string,
    body: object | undefined,
    headers: object | undefined,
): Observable<AjaxResponse> => ajax.patch(url, body, Object.assign({}, baseHeaders(), headers));

export const remove = (
    url: string,
    body: object | undefined,
    headers: object | undefined,
): Observable<AjaxResponse> => ajax.delete(url, Object.assign({}, baseHeaders(), headers));

export const blob = (
    url: string,
    query: Query | undefined,
    headers: object | undefined,
): Observable<AjaxResponse> =>
    ajax({
        url: withQuery(url, query && queryString(query)),
        method: "GET",
        headers: Object.assign({}, baseHeaders(), headers),
        responseType: "blob",
    });

export const postToBlob = (
    url: string,
    body: object | undefined,
    headers: object | undefined,
): Observable<AjaxResponse> =>
    ajax({
        url,
        method: "POST",
        body,
        headers: Object.assign({}, baseHeaders(), headers),
        responseType: "blob",
    });
