import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    Typography,
    darken,
    ListItemIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FieldProps } from "formik";
import { useSnackbar } from "notistack";
import { Fragment, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../ErrorMessage";
import { FileType } from "../types";
import { mdiPublish, mdiCameraEnhance, mdiFileDocumentOutline, mdiDelete } from "@mdi/js";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import { findValueInObjectByPath } from "src/services/object";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";

const useStyles = makeStyles((theme: Theme) => ({
    uploader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: theme.spacing(11),
        backgroundColor: COLORS.VERY_LIGHT_GREY_1,
        border: `2px dashed ${COLORS.VERY_LIGHT_GREY_3}`,
        outline: "none",
        transition: theme.transitions.create(["border"], {
            duration: theme.transitions.duration.complex,
        }),
        "&:hover, &:focus": {
            borderColor: darken(COLORS.VERY_LIGHT_GREY_3, 0.1),
        },
    },
    uploaderText: {
        color: COLORS.LIGHT_GREY_2,
        fontWeight: 600,
    },
    listItemIcon: {
        minWidth: 40,
    },
}));

interface OwnProps {
    multiple: boolean;
    label?: string;
    accept?: string;
    disabled?: boolean;
    type?: "button" | "dragdrop";
    showList?: boolean;
    icon?: "upload" | "camera";
    buttonGutter?: "none" | "left" | "right";
}

export const DragDropFileUpload = ({
    form,
    field,
    multiple,
    disabled,
    label,
    accept = "image/*,audio/*,video/*,.pdf",
    type = "dragdrop",
    showList = true,
    icon = "upload",
    buttonGutter = "left",
}: FieldProps & OwnProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const getIcon = (icon: string) => {
        switch (icon) {
            case "upload":
                return mdiPublish;
            case "camera":
                return mdiCameraEnhance;
            default:
                return mdiPublish;
        }
    };

    const onDrop = useCallback(
        acceptedFiles => {
            acceptedFiles.forEach((file: File) => {
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onerror = () => {
                    enqueueSnackbar(t("common.errorUploadingSnackbar"), {
                        ...SnackbarErrorOptions,
                    });
                };
                reader.onload = () => {
                    const fileObject: FileType = {
                        name: file.name,
                        size: `${file.size}`,
                        type: file.type,
                        base64: reader.result,
                    };
                    if (!multiple) {
                        form.setFieldValue(field.name, [fileObject]);
                    } else {
                        let currentFieldValues = form.values[field.name];

                        if (!currentFieldValues) {
                            currentFieldValues = findValueInObjectByPath(
                                form.values,
                                field.name,
                                [],
                            );
                        }

                        currentFieldValues.push(fileObject);
                        form.setFieldValue(field.name, currentFieldValues);
                    }
                };
            });
        },
        [enqueueSnackbar, field.name, form, multiple, t],
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple,
        accept,
        disabled: disabled || false,
    });

    const deleteFile = useCallback(
        index => {
            if (!multiple) {
                form.setFieldValue(field.name, []);
            } else {
                form.setFieldValue(
                    field.name,
                    form.values[field.name].filter((item: FileType, i: number) => index !== i),
                );
            }
        },
        [field.name, form, multiple],
    );

    const getFileList = useCallback(() => {
        if (!showList)
            return (form.values[field.name] || []).map((file: FileType, i: number) => (
                <Box key={i} component="em" ml={2}>
                    {file.name}
                </Box>
            ));

        return (
            <List>
                {(form.values[field.name] || []).map((file: FileType, i: number) => (
                    <ListItem key={i}>
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                            <Icon size={1} path={mdiFileDocumentOutline} />
                        </ListItemIcon>
                        <ListItemText primary={file.name} />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => deleteFile(i)}
                            >
                                <Icon size={1} path={mdiDelete} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        );
    }, [classes.listItemIcon, deleteFile, field.name, form.values, showList]);

    if (type && type === "button") {
        return (
            <Fragment>
                <Box
                    display="inline-flex"
                    alignItems="center"
                    ml={buttonGutter === "left" ? 4 : 0}
                    mr={buttonGutter === "right" ? 4 : 0}
                >
                    <Button
                        {...(getRootProps() as any)}
                        variant="text"
                        startIcon={<Icon path={getIcon(icon)} />}
                        disableRipple
                    >
                        <input {...getInputProps()} />
                        {label ? label : t("common.uploadFile")}
                    </Button>
                    {getFileList()}
                </Box>
                <ErrorMessage name={field.name} />
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Box {...getRootProps()} className={classes.uploader}>
                <input {...getInputProps()} />
                <Typography className={classes.uploaderText}>
                    {label ? label : t("common.uploadFile")}
                </Typography>
            </Box>
            <Box width="33%">{getFileList()}</Box>
            <ErrorMessage name={field.name} />
        </Fragment>
    );
};
