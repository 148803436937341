import { ormToRef } from "src/orm/helpers";
import { ModelReducer } from "src/orm/types";
import { OrmClassTrackerGroupActions, OrmClassTrackerGroupActionTypes } from "./actions";

export const classTrackerGroupReducer: ModelReducer = {
    [OrmClassTrackerGroupActionTypes.BULK_IMPORT]: (
        action: ReturnType<typeof OrmClassTrackerGroupActions.bulkImport>,
        session,
    ) => {
        const { ClassTrackerGroup, ClassTracker } = session;

        action.payload.data.data.forEach(ctg => {
            const classTrackerGroup = ClassTrackerGroup.withId(`${ctg.id}`);
            if (classTrackerGroup !== null) {
                const classTrackers = [...classTrackerGroup.classTrackers];

                ctg.classTrackers.forEach(ct => {
                    if (!classTrackers.find(c => c.id === ct.id)) {
                        classTrackers.push(ct);
                    }
                });

                classTrackerGroup.update({ classTrackers });
            } else {
                ClassTrackerGroup.create(
                    ormToRef(
                        ctg,
                        action.payload.cohort
                            ? { defaults: { cohortId: action.payload.cohort } }
                            : {},
                    ),
                );
            }

            ctg.classTrackers.forEach(ct => {
                ClassTracker.upsert(
                    ormToRef(ct, {
                        defaults: {
                            classTrackerGroupId: ctg.id,
                            specification: ctg.specification,
                            qualification: ctg.qualification,
                            subjectArea: ctg.subjectArea,
                            yearGroup: ctg.yearGroup,
                        },
                    }),
                );
            });
        });
    },
    [OrmClassTrackerGroupActionTypes.UPSERT]: (
        action: ReturnType<typeof OrmClassTrackerGroupActions.upsert>,
        session,
    ) => {
        const { ClassTrackerGroup } = session;
        ClassTrackerGroup.upsert(
            ormToRef(action.payload, {
                defaults: {},
            }),
        );
    },
    [OrmClassTrackerGroupActionTypes.UPDATE_STATUS]: (
        action: ReturnType<typeof OrmClassTrackerGroupActions.updateStatus>,
        session,
    ) => {
        const { ClassTrackerGroup, ClassTracker } = session;

        action.payload.forEach(item => {
            const { id: classTrackerId, status: classTrackerStatus } = item;
            const classTracker = ClassTracker.withId(`${classTrackerId}`);
            if (classTracker !== null) {
                classTracker.update({ status: classTrackerStatus });

                const classTrackerGroup = ClassTrackerGroup.withId(
                    `${classTracker.classTrackerGroupId}`,
                );
                if (classTrackerGroup) {
                    const newClassTrackersInGroup = classTrackerGroup.classTrackers.map(ct => {
                        if (ct.id === classTrackerId) {
                            return { ...ct, status: classTrackerStatus };
                        }
                        return ct;
                    });
                    classTrackerGroup.update({ classTrackers: newClassTrackersInGroup });
                }
            }
        });
    },
    [OrmClassTrackerGroupActionTypes.CLEAR]: (
        action: ReturnType<typeof OrmClassTrackerGroupActions.clear>,
        session,
    ) => {
        const { ClassTrackerGroup } = session;
        ClassTrackerGroup.all().delete();
    },
};
