import COLORS from "src/styles/colors";
import { ColDef } from "ag-grid-community";
import { FC } from "react";
import { ColumnNode } from "src/modules/tracker/dto/TrackerHeader";
import { getBtecCellColour, hexToRGB } from "src/modules/tracker/services/colorHandler";
import PinnedTopRenderer from "./PinnedTopRenderer";

interface OwnProps {
    value: string | { value: string; whileUpdate: boolean };
    cellConfig: ColumnNode | null;
    parentUnitColour?: string;
    node: any;
    data: any;
    colDef: ColDef;
    context;
}

export const BtecTrackedFieldRenderer: FC<OwnProps> = ({
    parentUnitColour,
    cellConfig,
    value: firstValue,
    data,
    node,
    colDef,
    context,
}) => {
    const { field } = colDef;

    if (node.rowPinned) {
        return (
            <PinnedTopRenderer
                field={field}
                value={field && data ? data[field] : ""}
                context={context}
            />
        );
    }

    let value: string;
    let whileUpdate = false;

    if (typeof firstValue === "object" && firstValue !== null && firstValue?.value !== undefined) {
        value = firstValue.value;
        whileUpdate = true;
    } else {
        value = firstValue as string;
        whileUpdate = false;
    }

    const { colour } = getBtecCellColour(value, parentUnitColour, cellConfig);

    const backgroundColor =
        value && colour && colour !== parentUnitColour ? hexToRGB(colour, 100) : "transparent";

    const fontColor = ["Y", "N", "I"].includes(value) ? COLORS.WHITE : COLORS.GREY_2;

    return (
        <div
            title={value}
            className="ag-value-cell-label"
            style={{ backgroundColor, color: fontColor }}
        >
            {value}{" "}
            {whileUpdate && (
                <span
                    className={`ag-value-cell-status${
                        whileUpdate ? " ag-value-cell-status--update" : ""
                    }`}
                />
            )}
        </div>
    );
};
