import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { CommonSpecificationsResponse } from "src/modules/common/api/specifications";

export enum OrmSpecificationActionTypes {
    BULK_IMPORT = "[orm] SPECIFICATION BULK IMPORT",
}

export const OrmSpecificationActions = {
    bulkImport: (payload: { data: CommonSpecificationsResponse; qualification: number }) => {
        return createAction(OrmSpecificationActionTypes.BULK_IMPORT, payload);
    },
};

export type OrmSpecificationActions = ActionsUnion<typeof OrmSpecificationActions>;
