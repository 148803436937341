import AppContainer from "src/components/AppContainer";
import FilterTextField from "src/components/FilterBar/FilterTextField";
import useDebounce from "src/hooks/useDebounce";
import ApiTable from "src/components/ApiTable";
import PromptDeleteConfirm from "src/forms/PromptDeleteConfirm";
import TrackingFilters from "src/modules/class/components/Common/TrackingFilters";
import Icon from "src/components/Icon";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTE_REPORT_SNAPSHOT_CREATE } from "src/routes";
import { GetSnapshotReportListFilter } from "../api/SnapshotReport/getList";
import { useLocation } from "react-router";
import { ReportActions } from "../store/actions";
import { Dispatch } from "redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMenuActionProps } from "src/components/ActionTableButton/actions";
import {
    OrmSnapshotReport,
    SnapshotReportState,
    SnapshotReportType,
} from "src/orm/models/SnapshotReport";
import { ApiData } from "src/api/constants";
import { AppState } from "src/store/reducers";
import { snapshotReportsSelector } from "../selectors/SnapshotReportsSelectors";
import { SnapshotReportFilterResponse } from "../api/SnapshotReport/getListFilters";
import { CellDataGetterParams } from "src/components/CustomTable/types";
import { mdiCancel, mdiCheckboxMultipleBlankOutline } from "@mdi/js";
import { format } from "date-fns";
import { useResponse } from "src/hooks/useResponse";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const SnapshotReportsListContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { navigate, attachSchoolId } = useSchoolNavigate();
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const { t } = useTranslation();
    const { data } = useCoreValues();
    const cohorts = data?.cohorts;

    const { data: accountInfo } = useAccountInfo();
    const defaultCohortId = accountInfo?.activeCohortId;
    const [selectedCohort, setSelectedCohort] = useState<number | null>(defaultCohortId);
    const [filter, setFilter] = useState<GetSnapshotReportListFilter>(
        searchParams
            ? {
                  ...(searchParams as any),
                  page: searchParams.page ? parseInt(searchParams.page) : 1,
                  perPage: searchParams.perPage ? parseInt(searchParams.perPage) : 50,
                  reportType: searchParams.reportType || "all",
                  state: searchParams.state || "all",
                  subjectAreaId: searchParams.subjectAreaId || "",
                  qualificationId: searchParams.qualificationId || "",
                  yearGroup: searchParams.yearGroup || "",
              }
            : {
                  page: 1,
                  perPage: 50,
                  reportType: "all",
                  state: "all",
                  subjectAreaId: "",
                  qualificationId: "",
                  yearGroup: "",
              },
    );

    const debouncedFilter = useDebounce(filter, 500);
    const [removeDialogOpened, setRemoveDialogOpened] = useState<boolean>(false);
    const [removeId, setRemoveId] = useState<number | null>(null);

    const dispatchActions = useCallback(
        (dispatch: Dispatch) => ({
            getSnapshotReportFilters: (cohort: number) => {
                dispatch(ReportActions.getSnapshotReportListFilters(cohort));
            },
            cancelReport: (reportId: number) => {
                dispatch(ReportActions.cancelSnapshotReport(reportId));
            },
            getSnapshotReports: (cohort, filter) => {
                Object.keys(filter).forEach(key => {
                    if (!filter[key]) delete filter[key];
                });
                dispatch(
                    ReportActions.getSnapshotReportList({
                        values: {
                            ...filter,
                            reportType: filter.reportType === "all" ? undefined : filter.reportType,
                            state: filter.state === "all" ? undefined : filter.state,
                            page: filter.page || 1,
                            perPage: filter.perPage || 50,
                        },
                        params: { cohort },
                    }),
                );
            },

            removeSnapshotReport: (reportId: number) => {
                dispatch(ReportActions.removeSnapshotReport(reportId));
            },
            publishSnapshotReport: (reportId: number) => {
                dispatch(ReportActions.publishSnapshotReport(reportId));
            },
            withdrawSnapshotReport: (reportId: number) => {
                dispatch(ReportActions.withdrawSnapshotReport(reportId));
            },
        }),
        [],
    );

    useEffect(() => {
        if (cohorts && defaultCohortId && selectedCohort === null) {
            setSelectedCohort(defaultCohortId || cohorts[0].id || null);
        }
    }, [defaultCohortId, cohorts]);

    const {
        apiWithdraw,
        apiCancel,
        apiPublish,
        apiDelete,
        snapshotReports,
        snapshotReportsListFilter,
        snapshotReportsCourseFilter,
    }: {
        apiWithdraw: ApiData;
        apiPublish: ApiData;
        apiCancel: ApiData;
        apiDelete: ApiData;
        apiSnapshotReports: ApiData;
        snapshotReports: OrmSnapshotReport[];
        snapshotReportsListFilter: { count: number; nextPage: number | null };
        snapshotReportsCourseFilter: SnapshotReportFilterResponse | null;
    } = useSelector(
        (state: AppState) => ({
            apiWithdraw: state.api.report.withdrawSnapshotReport,
            apiCancel: state.api.report.cancelSnapshotReport,
            apiPublish: state.api.report.publishSnapshotReport,
            apiDelete: state.api.report.removeSnapshotReport,
            apiSnapshotReports: state.api.report.getSnapshotReportList,
            snapshotReports: snapshotReportsSelector(state),
            snapshotReportsListFilter: state.report.snapshotReportListFilter,
            snapshotReportsCourseFilter: state.report.snapshotReportCourseFilters,
        }),
        shallowEqual,
    );

    const {
        removeSnapshotReport,
        getSnapshotReportFilters,
        getSnapshotReports,
        publishSnapshotReport,
        withdrawSnapshotReport,
        cancelReport,
    } = dispatchActions(dispatch);

    const handleFilterChange = (value, name) => {
        setFilter({ ...filter, [name]: value });
    };

    const handleDeleteDialogOpen = (id: number): void => {
        setRemoveDialogOpened(true);
        setRemoveId(id);
    };

    const handleDeleteDialogClose = (confirmed: boolean): void => {
        if (confirmed === true && removeId !== null) {
            removeSnapshotReport(removeId);
        }
        setRemoveDialogOpened(false);
        setRemoveId(null);
    };

    useEffect(() => {
        const filterString = new URLSearchParams(debouncedFilter).toString();

        navigate(location.pathname + "?" + filterString);
    }, [debouncedFilter, history, location.pathname]);

    useEffect(() => {
        if (selectedCohort) {
            getSnapshotReportFilters(selectedCohort);
            getSnapshotReports(selectedCohort, debouncedFilter);
        }
    }, [selectedCohort, debouncedFilter]);

    useResponse(() => {
        getSnapshotReports(selectedCohort, debouncedFilter);
    }, apiPublish);

    useResponse(() => {
        getSnapshotReports(selectedCohort, debouncedFilter);
    }, apiCancel);

    useResponse(() => {
        getSnapshotReports(selectedCohort, debouncedFilter);
    }, apiWithdraw);

    useResponse(() => {
        getSnapshotReports(selectedCohort, debouncedFilter);
    }, apiDelete);

    useEffect(() => {
        if (accountInfo?.activeCohortId !== selectedCohort && !selectedCohort) {
            setSelectedCohort(accountInfo.activeCohortId);
        }
    }, [accountInfo]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("report.snapshot.list.header")}
            </Typography>
            <Box mb={4}>
                <Button
                    component={Link}
                    to={attachSchoolId(ROUTE_REPORT_SNAPSHOT_CREATE)}
                    color="primary"
                >
                    {t("report.snapshot.list.createBtn")}
                </Button>
            </Box>
            <Box mb={4}>
                <Grid container spacing={3}>
                    {cohorts && cohorts.length > 0 && selectedCohort && (
                        <Grid item sm={3}>
                            <TextField
                                label={t("report.snapshot.list.academicYear")}
                                id="filterAcademicYear"
                                value={selectedCohort}
                                onChange={e => {
                                    setSelectedCohort(parseInt(e.target.value as string));
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                select
                            >
                                {cohorts.map(c => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                    <Grid item sm={3}>
                        <FilterTextField
                            id="snapshotName"
                            label={t("report.snapshot.list.filterSnapshotName")}
                            value={filter.snapshotName || ""}
                            onChange={e => handleFilterChange(e.target.value, "snapshotName")}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            label={t("report.snapshot.list.filterType")}
                            id="filterByType"
                            fullWidth
                            value={filter.reportType || "all"}
                            onChange={e => {
                                handleFilterChange(e.target.value, "reportType");
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                        >
                            <MenuItem value={"all"}>{t(`common.allFilter`)}</MenuItem>
                            <MenuItem value={SnapshotReportType.TYPE_SUBJECT}>
                                {t(
                                    `report.snapshot.list.reportType${SnapshotReportType.TYPE_SUBJECT}`,
                                )}
                            </MenuItem>
                            <MenuItem value={SnapshotReportType.TYPE_ALL_SUBJECT}>
                                {t(
                                    `report.snapshot.list.reportType${SnapshotReportType.TYPE_ALL_SUBJECT}`,
                                )}
                            </MenuItem>
                            <MenuItem value={SnapshotReportType.TYPE_SUMMARY}>
                                {t(
                                    `report.snapshot.list.reportType${SnapshotReportType.TYPE_SUMMARY}`,
                                )}
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            label={t("report.snapshot.list.filterStatus")}
                            id="filterByState"
                            fullWidth
                            value={filter.state}
                            onChange={e => {
                                handleFilterChange(e.target.value, "state");
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                        >
                            <MenuItem value={"all"}>{t(`common.allFilter`)}</MenuItem>
                            <MenuItem value={SnapshotReportState.STATE_READY}>
                                {t(
                                    `report.snapshot.list.reportTypeState${SnapshotReportState.STATE_READY}`,
                                )}
                            </MenuItem>
                            <MenuItem value={SnapshotReportState.STATE_PUBLISHED}>
                                {t(
                                    `report.snapshot.list.reportTypeState${SnapshotReportState.STATE_PUBLISHED}`,
                                )}
                            </MenuItem>
                            <MenuItem value={SnapshotReportState.STATE_SCHEDULED}>
                                {t(
                                    `report.snapshot.list.reportTypeState${SnapshotReportState.STATE_SCHEDULED}`,
                                )}
                            </MenuItem>
                            <MenuItem value={SnapshotReportState.STATE_PROCESSING}>
                                {t(
                                    `report.snapshot.list.reportTypeState${SnapshotReportState.STATE_PROCESSING}`,
                                )}
                            </MenuItem>
                            <MenuItem value={SnapshotReportState.STATE_QUEUED}>
                                {t(
                                    `report.snapshot.list.reportTypeState${SnapshotReportState.STATE_QUEUED}`,
                                )}
                            </MenuItem>
                            <MenuItem value={SnapshotReportState.STATE_WITHDRAWN}>
                                {t(
                                    `report.snapshot.list.reportTypeState${SnapshotReportState.STATE_WITHDRAWN}`,
                                )}
                            </MenuItem>
                            <MenuItem value={SnapshotReportState.STATE_CANCEL}>
                                {t(
                                    `report.snapshot.list.reportTypeState${SnapshotReportState.STATE_CANCEL}`,
                                )}
                            </MenuItem>
                            <MenuItem value={SnapshotReportState.STATE_ERROR}>
                                {t(
                                    `report.snapshot.list.reportTypeState${SnapshotReportState.STATE_ERROR}`,
                                )}
                            </MenuItem>
                        </TextField>
                    </Grid>
                    {selectedCohort &&
                        snapshotReportsCourseFilter &&
                        snapshotReportsCourseFilter.length > 0 && (
                            <Grid item sm={12}>
                                <TrackingFilters
                                    filters={snapshotReportsCourseFilter}
                                    selectedFilters={{
                                        ...filter,
                                        yearGroup: `${filter.yearGroup}`,
                                        subjectArea: `${filter.subjectAreaId}`,
                                        qualification: `${filter.qualificationId}`,
                                    }}
                                    handleFilterChange={(type: string, value: string) => {
                                        handleFilterChange(
                                            value,
                                            type === "subjectArea" || type === "qualification"
                                                ? type + "Id"
                                                : type,
                                        );
                                    }}
                                />
                            </Grid>
                        )}
                </Grid>
            </Box>
            <Paper>
                <ApiTable
                    actions={[
                        getMenuActionProps("publish", {
                            text: t("report.snapshot.list.publish"),
                            icon: <Icon path={mdiCheckboxMultipleBlankOutline} />,
                            onAction: (su: OrmSnapshotReport) =>
                                su.id && su.state === SnapshotReportState.STATE_WITHDRAWN
                                    ? publishSnapshotReport(su.id)
                                    : null,
                            showAction: (rowData: OrmSnapshotReport) =>
                                rowData.state === SnapshotReportState.STATE_WITHDRAWN,
                        }),
                        getMenuActionProps("cancel", {
                            text: t("report.snapshot.list.cancel"),
                            icon: <Icon path={mdiCancel} />,
                            onAction: (su: OrmSnapshotReport) => cancelReport(su.id),
                            showAction: (rowData: OrmSnapshotReport) =>
                                [
                                    SnapshotReportState.STATE_PROCESSING,
                                    SnapshotReportState.STATE_PUBLISHING,
                                    SnapshotReportState.STATE_SCHEDULED,
                                    SnapshotReportState.STATE_QUEUED,
                                ].includes(rowData.state),
                        }),
                        getMenuActionProps("withdraw", {
                            text: t("report.snapshot.list.withdraw"),
                            icon: <Icon path={mdiCheckboxMultipleBlankOutline} />,
                            onAction: (su: OrmSnapshotReport) =>
                                su.id ? withdrawSnapshotReport(su.id) : null,
                            showAction: (rowData: OrmSnapshotReport) =>
                                rowData.state === SnapshotReportState.STATE_READY,
                        }),
                        getMenuActionProps("delete", {
                            onAction: (su: OrmSnapshotReport) =>
                                su.id ? handleDeleteDialogOpen(su.id) : null,
                        }),
                    ]}
                    columns={[
                        {
                            label: t("report.snapshot.list.status"),
                            key: "state",
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSnapshotReport>) => {
                                return t("report.snapshot.list.reportTypeState" + rowData.state);
                            },
                        },
                        {
                            label: t("report.snapshot.list.publishedAt"),
                            key: "publishedAt",
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSnapshotReport>) => {
                                return format(new Date(rowData.publishedAt), "dd MMMM yyyy HH:mm");
                            },
                        },
                        {
                            label: t("report.snapshot.list.reportType"),
                            key: "reportType",
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSnapshotReport>) => {
                                return t("report.snapshot.list.reportType" + rowData.reportType);
                            },
                        },
                        {
                            label: t("report.snapshot.list.snapshotName"),
                            key: "snapshotName",
                            cellDataGetter: ({
                                rowData,
                            }: CellDataGetterParams<OrmSnapshotReport>) => {
                                return rowData.snapshot?.name || rowData.schoolSnapshot?.name || "";
                            },
                        },
                        {
                            label: t("report.snapshot.list.yearGroup"),
                            key: "yearGroup",
                        },
                    ]}
                    count={snapshotReportsListFilter.count}
                    data={snapshotReports}
                    apiPerPage={filter.perPage}
                    apiPage={filter.page}
                    onPaginatorChange={(page, perPage) => {
                        setFilter({ ...filter, page, perPage });
                    }}
                />
            </Paper>
            <PromptDeleteConfirm open={removeDialogOpened} onClose={handleDeleteDialogClose}>
                <Typography variant="h3">{t("report.snapshot.list.deletePromptHeader")}</Typography>
                <Box pt={3}>{t("report.snapshot.list.deletePromptType")}</Box>
            </PromptDeleteConfirm>
        </AppContainer>
    );
};

export default SnapshotReportsListContainer;
