import COLORS from "src/styles/colors";
import fromUnixTime from "date-fns/fromUnixTime";
import Icon from "src/components/Icon";
import {
    Button,
    createStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { format } from "date-fns";
import { FC } from "react";
import { ShortSnapshotItem } from "../SnapshotModal";
import { mdiDelete, mdiRotateLeft } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { useAccountInfo } from "../../hooks/useAccountInfo";

const useStyles = makeStyles((theme: Theme) => ({
    dividerVertical: {
        margin: theme.spacing(4, -6),
    },
    tableContainer: {
        maxHeight: 250,
    },
}));

const CustomTableHeadCell = withStyles(() =>
    createStyles({
        root: {
            paddingTop: 0,
            backgroundColor: COLORS.WHITE,
        },
    }),
)(TableCell);

const CustomTableCell = withStyles(({ typography }: Theme) =>
    createStyles({
        root: {
            fontSize: typography.pxToRem(14),
        },
    }),
)(TableCell);

interface OwnProps {
    snapshots: ShortSnapshotItem[];
    handleSnapshotView: (snapshotId: number) => void;
    handleSnapshotRemove: (snapshotId: number) => void;
}

const ViewSnapshots: FC<OwnProps> = ({ snapshots, handleSnapshotView, handleSnapshotRemove }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { data: accountInfo } = useAccountInfo();

    return (
        <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <CustomTableHeadCell>
                            {t("common.snapshotModal.created")}
                        </CustomTableHeadCell>
                        <CustomTableHeadCell>{t("common.snapshotModal.name")}</CustomTableHeadCell>
                        {(accountInfo?.hasParentPortalEnabled ||
                            accountInfo?.hasStudentPortalEnabled) && (
                            <CustomTableHeadCell>
                                {t("common.snapshotModal.hasReports")}
                            </CustomTableHeadCell>
                        )}
                        <CustomTableHeadCell></CustomTableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {snapshots &&
                        snapshots.map((s, i) => {
                            return (
                                <TableRow key={i}>
                                    <CustomTableCell>
                                        {format(fromUnixTime(s.created), "dd MMMM yyyy hh:mm:ss")}
                                    </CustomTableCell>
                                    <CustomTableCell>{s.name}</CustomTableCell>
                                    {(accountInfo?.hasParentPortalEnabled ||
                                        accountInfo?.hasStudentPortalEnabled) && (
                                        <CustomTableCell>
                                            {s.hasReports ? t("common.yes") : t("common.no")}
                                        </CustomTableCell>
                                    )}
                                    <TableCell align="right">
                                        <Button
                                            variant="text"
                                            onClick={() => handleSnapshotView(s.id)}
                                            startIcon={<Icon path={mdiRotateLeft} />}
                                            disableRipple
                                        >
                                            {t("tracker.snapshot.viewSnapshotBtn")}
                                        </Button>
                                        {s.canRemove && !s.hasReports && (
                                            <Button
                                                variant="text"
                                                onClick={() => {
                                                    handleSnapshotRemove(s.id);
                                                }}
                                                startIcon={<Icon path={mdiDelete} />}
                                                disableRipple
                                            >
                                                {t("tracker.snapshot.removeSnapshotBtn")}
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ViewSnapshots;
