import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { StudentModel } from ".";

export enum StudentModelActionTypes {
    BULK_IMPORT = "[orm] STUDENT BULK IMPORT",
    UPSERT = "[orm] STUDENT UPSERT",
    CLEAR = "[orm] STUDENT CLEAR",
}

export const StudentModelActions = {
    bulkImport: (payload: StudentModel[]) =>
        createAction(StudentModelActionTypes.BULK_IMPORT, payload),
    upsert: (payload: StudentModel) => createAction(StudentModelActionTypes.UPSERT, payload),
    clear: () => createAction(StudentModelActionTypes.CLEAR),
};

export type StudentModelActions = ActionsUnion<typeof StudentModelActions>;
