import Icon from "src/components/Icon";
import { FC, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { mdiGrid } from "@mdi/js";
import { usePrevious } from "src/hooks/usePrevious";
import { useStudentMove } from "src/modules/tracker/hooks/useStudentMove";
import { ApiStatus } from "src/api/constants";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";

const useStyles = makeStyles(() => ({
    saveButton: {
        position: "absolute",
        left: 20,
        zIndex: 200,
    },
}));

interface OwnProps {
    context: any;
}

export const MoveStudentsHeader: FC<OwnProps> = ({ context }) => {
    const { t }: { t: TFunction } = useTranslation();
    const {
        headerHeight,
        gridApi,
        handleOnSaveMoveClick,
        handleSetAllTiers,
        handleMoveStudentsBulkEditChange,
        relatedData,
    } = context;
    const { apiMoveStudents } = useStudentMove();
    const [buttonToggle, setButtonToggle] = useState<boolean>(false);
    const classes = useStyles();
    const previousToggle = usePrevious(buttonToggle);
    const topValue = headerHeight - 92;
    const sortOrder = ["H", "I", "F"];

    useEffect(() => {
        if (gridApi && !buttonToggle && previousToggle) gridApi.stopEditing();
    }, [buttonToggle]);

    return (
        <>
            <Button
                onClick={handleOnSaveMoveClick}
                color="primary"
                disabled={buttonToggle || apiMoveStudents.status === ApiStatus.LOADING}
                className={classes.saveButton}
                style={{ top: topValue }}
            >
                {t("tracker.grid.saveChangesBtn")}
            </Button>
            {handleMoveStudentsBulkEditChange && (
                <Button
                    size="small"
                    onClick={() => {
                        setButtonToggle(prev => !prev);
                        handleMoveStudentsBulkEditChange();
                    }}
                    variant="text"
                    startIcon={<Icon path={mdiGrid} />}
                    disableRipple
                    style={{
                        left: 200,
                        top: topValue + 19,
                        zIndex: 200,
                        marginLeft: 0,
                    }}
                >
                    {!buttonToggle
                        ? t("tracker.grid.bulkEditBtn")
                        : t("tracker.grid.exitBulkEditBtn")}
                </Button>
            )}
            <div
                style={{
                    position: "absolute",
                    top: topValue,
                    right: 8,
                    zIndex: 205,
                }}
            >
                {relatedData?.tierConfig &&
                handleSetAllTiers &&
                Object.keys(relatedData?.tierConfig).length > 0 ? (
                    Object.keys(relatedData?.tierConfig)
                        .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
                        .filter(tc => tc !== "-")
                        .map(tc => {
                            return (
                                <Button
                                    variant="contained"
                                    size="small"
                                    key={tc}
                                    onClick={() => context.handleSetAllTiers(tc as TiersTypes)}
                                >
                                    {t("tracker.moveStudents.setAllTiers").replace(":tier", tc)}
                                </Button>
                            );
                        })
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
