import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const getAnalysisKS4GradeValuesEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/ks4-grade/{cohort}/{yearGroup}/{viewGrade}/{viewType}/values/`,
    method: ApiRequests.GET,
};

export interface AnalysisKS4GradeValuesFlatObject {
    [key: string]: any;
}

export interface AnalysisKS4GradeValuesObject {
    [key: string]: AnalysisKS4GradeValuesFlatObject;
}

export enum KS4ViewGrades {
    CURRENT = "current",
    PROJECTED = "projected",
    TEACHER = "teacher",
}

export enum KS4ViewTypes {
    A8 = "a8",
    P8 = "p8",
}
