import GridBlockedOverlay from "./GridBlockedOverlay";
import FilterPanel from "./ToolsPanel/FilterPanel";
import ToolsPanel from "./ToolsPanel/ToolsPanel";
import {
    CellKeyDownEvent,
    CellValueChangedEvent,
    ColumnApi,
    ColumnState,
    GridApi,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import {
    ClassTrackerFlatHeader,
    ClassTrackerHeaderObject,
    ColumnNode,
} from "../../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../../dto/TrackerRelated";
import { ClassTrackerCompareValues, ClassTrackerValuesObject } from "../../dto/TrackerValues";
import { generateAverageRow, getAverageColumnList } from "../../services/averageRow";
import { processClipboardPaste, processCopyToClipboard } from "../../services/clipboard";
import { getFlatColumnGroups } from "../../services/columnConfig";
import { frameworkComponentsList } from "../../services/gridConfig";
import { navigateToNextCell } from "../../services/gridNavigation";
import {
    calculateHeaderHeight,
    CELL_SIZE_MIN,
    ClassTrackerSettings,
    generateHeader,
    generateLinksHeader,
    generateMoveStudentsHeader,
    generateStudentHeader,
    generateTagsHeader,
    VIRTUALIZATION_MIN_COLUMNS,
} from "../../services/headerGenerator";
import {
    DataToSendType,
    getAvailableStudentParams,
    TrackerValueType,
} from "../../services/valueHandler";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import { DataTypeTypes } from "../Common/TierHeaderSelect";
import { SnapshotData } from "src/context/TrackerContext";
import { useSnackbar } from "notistack";
import { useUpdateTimestamps } from "../../hooks/useUpdateTimestamps";
import SaveIgnoreTimestampChangeBar from "../Common/SaveIgnoreTimestampChangeBar";
import GroupToolsPanel from "./ToolsPanel/GroupToolsPanel";
import { TagModel } from "src/modules/tagging/model/TagModel";
import { useClassTrackerTagList } from "src/modules/tagging/hooks/ClassTracker/useClassTrackerTagList";
import { useClassTrackerGroupTagList } from "src/modules/tagging/hooks/ClassTrackerGroup/useClassTrackerGroupTagList";
import { ClassTrackerLinkModel } from "src/modules/links/model/ClassTrackerLinkModel";
import { useClassTrackerLinksList } from "src/modules/links/hooks/ClassTracker/useClassTrackerLinks";
import { useClassTrackerGroupLinksList } from "src/modules/links/hooks/ClassTrackerGroup/useClassTrackerGroupLinks";
import { ListObject } from "src/forms/types";
import { ClassTrackerUserSettingsObject } from "../../hooks/query/ClassTracker/useTrackerUserSettings";
import { useTrackerUserSettingsStore } from "../../hooks/query/ClassTracker/useTrackerUserSettingsStore";
import { ProfileResponse } from "src/modules/user/hooks/useProfile";
import { useTrackerGroupUserSettingsStore } from "../../hooks/query/ClassTrackerGroup/useTrackerGroupUserSettingsStore";

export interface TrackerContext {
    cohortId: number;
    classTrackerId?: number;
    classTrackerGroupId?: number | null;
    classDetails?: OrmClassTracker;
    relatedData: ClassTrackerRelatedObject | null;
    gridUserSettings?: ClassTrackerUserSettingsObject | null;
    dataToSend?: DataToSendType[];
    expanded?: { [key: string]: boolean };
    initialExpandSettings?: boolean;
    compareValues: ClassTrackerValuesObject | null;
    flatHeader: ClassTrackerFlatHeader[];
    tier: TiersTypes;
    headerHeight: number;
    dataType: DataTypeTypes;
    bulkEdit?: boolean;
    moveStudents?: boolean;
    isBlocked: boolean;
    isGradeVBoundaryProcessing: boolean;
    filtersVisible?: boolean;
    userProfile?: ProfileResponse | null;
    toolsVisible?: boolean;
    columns: { [key: string]: ColumnNode };
    allClassTrackers?: OrmClassTracker[];
    snapshotData?: SnapshotData | null;
    yearGroupOverview?: boolean;
    isSyncedClass?: boolean;
    gridFilters;
    archived?: boolean;
    handleConfigureColumnsChange?: () => void;
    handleMoveStudentTo?: (studentId: number, moveToId: number) => void;
    handleMoveStudentTierTo?: (studentId: number, classId: number, moveToTier: TiersTypes) => void;
    requestDataChange?: (dataToSend: DataToSendType[]) => void;
    handleBulkEditSave?: () => void;
    handleFiltersChange?: (filters) => void;
    handleBlocked?: (locked) => void;
    handleDataAsyncLoaded?: () => void;
    handleGradeBoundariesProcessing?: () => void;
    handleSetAllTiers?: (tier: TiersTypes) => void;
    handleOnSaveMoveClick?: () => void;
    handleColumnConfigPassData?: (values) => void;
    handlePixlSeriesChange?: (selectedSeriesId, seriesData) => void;
    tags?: TagModel[];
    tagsVisible?: boolean;
    handleTagsVisible?: (visible) => void;
    handleTagsLinksAllColumnSelect?: (field: string, value: number, type: "tag" | "link") => void;
    linksVisible?: boolean;
    handleLinksVisible?: (visible) => void;
    selectedLinks?: number[];
    links?: ClassTrackerLinkModel[];
    pixlData?: any;
    pixlSeriesList?: ListObject[] | null;
}

export const sortTagsColumnState = (
    columnState: ColumnState[],
    tagsOrder: string[],
): ColumnState[] =>
    columnState.sort((a, b) => {
        if (a.colId.indexOf("tag-") === 0 && b.colId.indexOf("tag-") === 0) {
            const aIndex = tagsOrder.findIndex(o => o === a.colId);
            const bIndex = tagsOrder.findIndex(o => o === b.colId);
            return aIndex < bIndex ? -1 : 1;
        }
        return 0;
    }) || [];

interface OwnProps {
    isLoading: boolean;
    isArchive?: boolean;
    gridHeader: ClassTrackerHeaderObject;
    rowsToRefresh?: string[] | null;
    rowsToRedraw?: number[];
    gridRelated: ClassTrackerRelatedObject;
    gridValues: ClassTrackerValuesObject;
    snapshotValues?: ClassTrackerValuesObject | null;
    compareValues?: ClassTrackerCompareValues | null;
    dataSet: { [key: string]: TrackerValueType }[] | null;
    initialDataSet: { [key: string]: TrackerValueType }[] | null;
    gridUserSettings?: ClassTrackerUserSettingsObject;
    isBlocked?: boolean;
    isSyncedClass?: boolean;
    trackerContext: TrackerContext;
    handleValueChange?: (e: CellValueChangedEvent) => void;
    handleChangeExpandedColumns?: (expandedColumns) => void;
    handleIgnoreTimestamps?: (newFilteredClassTrackerData) => void;
}

const TrackerGrid: FC<OwnProps> = ({
    isLoading,
    isArchive,
    gridHeader,
    gridRelated,
    rowsToRefresh,
    gridValues,
    compareValues,
    rowsToRedraw,
    isSyncedClass,
    dataSet,
    gridUserSettings,
    isBlocked,
    trackerContext,
    handleValueChange,
    handleChangeExpandedColumns,
    handleIgnoreTimestamps,
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const gridApi = useRef<GridApi>();
    const columnApi = useRef<ColumnApi>();

    const initialColumnOrder = useRef<ColumnState[]>();
    const [canShow, setCanShow] = useState<boolean>(false);
    const [convertedHeader, setConvertedHeader] = useState(null);
    const { updateTimestamps, getCommitAuthor, hasBeenEditedByAnotherUser } = useUpdateTimestamps(
        trackerContext.classTrackerId,
        isArchive,
    );

    const {
        classTrackerId,
        classTrackerGroupId,
        dataType,
        bulkEdit,
        tier,
        filtersVisible,
        toolsVisible,
        snapshotData,
        pixlData,
        moveStudents,
        allClassTrackers,
        classDetails,
        tagsVisible,
        linksVisible,
        selectedLinks,
        pixlSeriesList,
        relatedData,
    } = trackerContext;

    const { setClassTrackerUserSettings: setTrackerUserSettings, ...classTrackerUserSettings } =
        useTrackerUserSettingsStore();

    const {
        setClassTrackerGroupUserSettings: setTrackerGroupUserSettings,
        ...classTrackerGroupUserSettings
    } = useTrackerGroupUserSettingsStore();

    const userSettings = classTrackerId ? classTrackerUserSettings : classTrackerGroupUserSettings;
    const setUserSettings = classTrackerId ? setTrackerUserSettings : setTrackerGroupUserSettings;

    const { data: classTrackerTags } = useClassTrackerTagList(classTrackerId);
    const { data: classTrackerGroupTags } = useClassTrackerGroupTagList(classTrackerGroupId);

    const tags = classTrackerId ? classTrackerTags : classTrackerGroupTags;

    const { data: classTrackerLinks } = useClassTrackerLinksList(classTrackerId || 0);

    const { data: classTrackerGroupLinks } = useClassTrackerGroupLinksList(
        classTrackerGroupId || 0,
    );

    const links = classTrackerLinks || classTrackerGroupLinks;

    const classTrackerSettings: ClassTrackerSettings = {
        classTrackerId,
        classTrackerGroupId,
        header: gridHeader,
        user: gridUserSettings || userSettings,
        related: gridRelated,
        dataType,
        bulkEdit: !!bulkEdit,
        currentTier: tier as TiersTypes,
    };

    const applayTagsOrder = tagsOrder => {
        if (tagsOrder) {
            const columnState: { colId: string }[] =
                initialColumnOrder.current || columnApi.current.getColumnState() || [];

            columnApi.current.applyColumnState({
                state: sortTagsColumnState(columnState, tagsOrder),
                applyOrder: true,
            });
        }
    };

    // grid ready (not rendered for first time) handler
    const handleOnGridReady = params => {
        gridApi.current = params.api;
        columnApi.current = params.columnApi;
        initialColumnOrder.current = params.columnApi.getColumnState() || [];
        applayTagsOrder(userSettings?.tagsOrder);
    };

    const handleOnColumnMoved = params => {
        if (params.finished && params.column) {
            const columnState = params.columnApi.getColumnState();
            const tagsOrder = columnState
                .filter(({ colId }) => colId.indexOf("tag-") === 0)
                .map(({ colId }) => colId);
            setUserSettings({ ...userSettings, tagsOrder });
            applayTagsOrder(tagsOrder);
        }
    };
    const handleOnColumnVisible = params => {
        initialColumnOrder.current = params.columnApi.getColumnState() || [];
    };

    // grid header generator
    const generateConvertedHeader = useCallback(() => {
        const columnGroups: any[] = [];

        const header = generateHeader(classTrackerSettings, t, columnGroups, isBlocked) as any;

        const linksHeader = generateLinksHeader(links, linksVisible);
        const tagsHeader = [
            ...generateTagsHeader(tags, relatedData?.subjectArea?.id),
            ...linksHeader,
        ];

        let studentHeader = generateStudentHeader(
            classTrackerSettings,
            getAvailableStudentParams(
                snapshotData?.compare && compareValues?.values1
                    ? compareValues?.values1
                    : gridValues,
            ),
            t,
        ) as any;

        if (moveStudents) {
            // generate move students functionality
            const moveStudentsHeader = generateMoveStudentsHeader(
                gridRelated,
                t,
                allClassTrackers || undefined,
                classDetails?.id || undefined,
                isSyncedClass,
            );
            // merge student header with move student
            studentHeader = studentHeader.concat(moveStudentsHeader);
        }

        return { header, studentHeader, columnGroups, tagsHeader };
    }, [classTrackerSettings, gridValues, tags]);

    useEffect(() => {
        if (gridApi?.current) {
            if (pixlData) {
                const newPixlAvg = {};

                Object.keys(pixlData).forEach(key => {
                    newPixlAvg[key] = { value: pixlData[key], displayValue: pixlData[key] };
                });

                if (newPixlAvg && Object.keys(newPixlAvg).length > 0) {
                    gridApi?.current?.setPinnedTopRowData([
                        {
                            ...newPixlAvg,
                            student_firstName:
                                pixlSeriesList?.find(
                                    psl =>
                                        psl.id ===
                                        (gridUserSettings?.selectedSeriesId ||
                                            userSettings?.selectedSeriesId),
                                )?.name || "Pixl",
                        },
                        generateAverageRow(
                            dataSet,
                            gridHeader,
                            gridRelated,
                            "student_firstName",
                            t,
                        ),
                    ]);
                }
            } else {
                gridApi?.current?.setPinnedTopRowData([
                    generateAverageRow(dataSet, gridHeader, gridRelated, "student_firstName", t),
                ]);
            }
        }
    }, [
        pixlData,
        gridApi?.current,
        dataSet,
        gridHeader,
        pixlSeriesList,
        gridUserSettings?.selectedSeriesId,
        userSettings?.selectedSeriesId,
    ]);

    useEffect(() => {
        if (convertedHeader && tags) {
            //tags visibility
            if (!tagsVisible || dataType !== DataTypeTypes.LIVE) {
                columnApi?.current?.setColumnsVisible(
                    tags?.map(tag => `tag-${tag.id}`),
                    false,
                );
            } else {
                const visibleTags = tags
                    ?.filter(tag => !!userSettings?.selectedTags?.includes(tag.id))
                    .map(tag => `tag-${tag.id}`);
                columnApi?.current?.setColumnsVisible(visibleTags, true);

                const hiddenTags = tags
                    ?.filter(tag => !userSettings?.selectedTags?.includes(tag.id))
                    .map(tag => `tag-${tag.id}`);
                columnApi?.current?.setColumnsVisible(hiddenTags, false);
            }
            //links visibility
            if (!linksVisible || dataType !== DataTypeTypes.LIVE) {
                columnApi?.current?.setColumnsVisible(
                    links?.map(link => `link-${link.id}`),
                    false,
                );
            } else {
                const visibleLinks = links
                    ?.filter(link => !!selectedLinks?.includes(link.id))
                    .map(link => `link-${link.id}`);
                columnApi?.current?.setColumnsVisible(visibleLinks, true);

                const hiddenLinks = links
                    ?.filter(link => !selectedLinks?.includes(link.id))
                    .map(link => `link-${link.id}`);
                columnApi?.current?.setColumnsVisible(hiddenLinks, false);
            }

            gridApi?.current?.refreshHeader();
        }
    }, [convertedHeader, tagsVisible, linksVisible, gridApi?.current, columnApi?.current]);

    // handle open header group
    const handleGroupOpen = () => {
        const tmpExpandedColumns: { [key: string]: boolean } = {};
        const columnGroups = [] as any;
        generateHeader(classTrackerSettings, t, columnGroups, isBlocked);
        if (columnGroups) {
            columnGroups.forEach(hg => {
                const group = columnApi?.current?.getColumnGroup(hg.groupId);
                if (group) {
                    getFlatColumnGroups(group, tmpExpandedColumns);
                }
            });
        }
        if (tmpExpandedColumns && Object.keys(tmpExpandedColumns).length > 0) {
            handleChangeExpandedColumns(tmpExpandedColumns);
        }
    };

    // initial on first data rended
    const handleOnDataRendered = () => {
        calculateAverageRow(dataSet);
        setTimeout(() => {
            setCanShow(true);
        }, 2000);
    };

    // calculate average
    const calculateAverageRow = useCallback(
        rowData => {
            if (rowData) {
                const newAverage = generateAverageRow(
                    rowData,
                    gridHeader,
                    gridRelated,
                    "student_firstName",
                    t,
                );

                if (gridApi?.current) {
                    const row = gridApi?.current?.getPinnedTopRow(pixlData ? 1 : 0);

                    if (row) {
                        row.setData(newAverage);
                    }
                }
            }
        },
        [gridHeader, gridRelated, pixlData],
    );

    const getRowId = useCallback(params => params.data.id, []);

    const components = useMemo(() => ({ ...frameworkComponentsList }), []);

    const sideBar = useMemo(
        () => ({
            toolPanels: [
                {
                    id: "studentFilterPanel",
                    labelKey: "studentFilterPanel",
                    labelDefault: "",
                    iconKey: "student-filter-panel",
                    toolPanel: FilterPanel,
                },
                {
                    id: "toolsPanel",
                    labelKey: "toolsPanel",
                    labelDefault: "",
                    iconKey: "tools-panel",
                    toolPanel: classTrackerId ? ToolsPanel : GroupToolsPanel,
                },
            ],
        }),
        [],
    );

    // toggle grid filters & tools
    useEffect(() => {
        if (gridApi?.current) {
            if (filtersVisible) gridApi.current?.openToolPanel("studentFilterPanel");
            if (toolsVisible) gridApi.current?.openToolPanel("toolsPanel");
            if (!toolsVisible && !filtersVisible) gridApi.current?.closeToolPanel();
        }
    }, [filtersVisible, toolsVisible, gridApi]);

    useEffect(() => {
        if (!isLoading) {
            const { header, studentHeader, tagsHeader } = generateConvertedHeader();

            if (studentHeader && header) {
                setConvertedHeader(studentHeader.concat(tagsHeader).concat(header));
            }
        }
    }, [isLoading, tags]);

    // recalculate on dataset change
    useEffect(() => {
        if (gridApi.current) {
            calculateAverageRow(dataSet || []);
        }
    }, [dataSet, gridApi]);

    useEffect(() => {
        if (isBlocked) {
            enqueueSnackbar(t("common.trackerBlocked"), { persist: true, variant: "info" });
        } else {
            closeSnackbar();
        }
    }, [isBlocked]);

    useEffect(() => {
        if (trackerContext.isGradeVBoundaryProcessing) {
            enqueueSnackbar(t("common.trackerBlocked"), { persist: true, variant: "info" });
        } else {
            closeSnackbar();
        }
    }, [trackerContext.isGradeVBoundaryProcessing]);

    useEffect(() => {
        if (gridApi.current && snapshotData) {
            gridApi.current.forEachNode(function (rowNode) {
                rowNode.setRowHeight(snapshotData?.compare ? 56 : CELL_SIZE_MIN);
            });
            const topRow = gridApi.current.getPinnedTopRow(0);

            if (topRow && snapshotData.compare) {
                topRow.setRowHeight(56);
            }
            setTimeout(() => {
                gridApi.current.redrawRows();
                gridApi.current.refreshCells();
            }, 500);
        }
    }, [snapshotData, gridApi.current]);

    // handle timestamps (is edited by another user)
    useEffect(() => {
        if (hasBeenEditedByAnotherUser(dataSet)) {
            setTimeout(() => {
                const author = getCommitAuthor(dataSet);
                if (author && dataSet) {
                    enqueueSnackbar(
                        <SaveIgnoreTimestampChangeBar
                            lastCommitedBy={author}
                            ignoreEditChanges={() => {
                                const newDataSet = dataSet.map(fctd => ({
                                    ...fctd,
                                    lastSessionUpdatedAt: new Date().toISOString(),
                                }));
                                handleIgnoreTimestamps(newDataSet);
                                closeSnackbar();
                            }}
                        />,
                        { variant: "warning" },
                    );
                }
            }, 100);
        }
    }, [updateTimestamps]);

    useEffect(() => {
        if (rowsToRefresh && rowsToRefresh.length > 0 && gridApi?.current) {
            const rowNodes = [];

            rowsToRefresh.forEach(rowIndex => {
                const rowNode = gridApi.current?.getDisplayedRowAtIndex(parseInt(rowIndex));
                if (rowNode) rowNodes.push(rowNode);
            });

            gridApi.current.refreshCells({ rowNodes, force: true });
        }
    }, [rowsToRefresh, gridApi?.current]);

    useEffect(() => {
        if (rowsToRedraw && rowsToRedraw.length > 0 && gridApi?.current) {
            gridApi.current.redrawRows();
        }
    }, [rowsToRedraw, gridApi?.current]);

    return (
        <>
            {(!convertedHeader || isLoading || !canShow) && <GridBlockedOverlay />}
            {!isLoading && dataSet ? (
                <AgGridReact
                    maxComponentCreationTimeMs={1500} //it is needed for GroupToolsPanel, default it is 1000
                    columnDefs={convertedHeader}
                    rowData={dataSet}
                    onGridReady={handleOnGridReady}
                    onColumnVisible={handleOnColumnVisible}
                    onColumnMoved={handleOnColumnMoved}
                    components={components}
                    sideBar={sideBar}
                    onFirstDataRendered={handleOnDataRendered}
                    context={trackerContext}
                    getRowId={getRowId}
                    suppressColumnVirtualisation={
                        trackerContext.flatHeader.length < VIRTUALIZATION_MIN_COLUMNS ? true : false
                    }
                    suppressColumnMoveAnimation={false}
                    enableRangeSelection={!!bulkEdit || false}
                    suppressClipboardPaste={!bulkEdit}
                    rowBuffer={15}
                    suppressMaxRenderedRowRestriction={true}
                    processCellForClipboard={params => processCopyToClipboard(params, gridRelated)}
                    processCellFromClipboard={params => processClipboardPaste(params, gridRelated)}
                    enableBrowserTooltips={true}
                    singleClickEdit={!bulkEdit}
                    gridOptions={{
                        onCellKeyDown: (event: CellKeyDownEvent) => {
                            navigateToNextCell(
                                event,
                                gridApi.current,
                                columnApi.current,
                                !!bulkEdit,
                            );
                        },
                        rowHeight: snapshotData?.compare ? 56 : CELL_SIZE_MIN,
                        groupHeaderHeight: 0,
                        headerHeight: calculateHeaderHeight(gridHeader),
                        onCellValueChanged: handleValueChange,
                        onColumnGroupOpened: handleGroupOpen,
                        pinnedTopRowData: [getAverageColumnList(dataSet, gridHeader)],
                        animateRows: false,
                        suppressColumnMoveAnimation: true,
                        suppressContextMenu: true,
                        defaultColDef: { width: CELL_SIZE_MIN },
                        debug: false,
                    }}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default TrackerGrid;
