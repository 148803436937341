import {
    getUnitColourByHash,
    DEFAULT_CELL_COLOUR,
    DEFAULT_DISPLAY_FIELD_OPACITY,
    hexToRgba,
    DEFAULT_FIELD_OPACITY,
} from "./colorHandler";
import { TFunction } from "i18next";
import { CriterionHeader } from "../components/Grid/HeaderRenderer/CriterionHeader";
import { ColumnHeader } from "../components/Grid/HeaderRenderer/ColumnHeader";
import { ColumnConfigurationHeader } from "../components/Grid/HeaderRenderer/ColumnConfigurationHeader";
import { ColGroupDef, ColDef, ColumnGroup } from "ag-grid-community";
import { GridFrameworkComponents } from "./gridConfig";
import { OrmClassTracker } from "src/orm/models/ClassTracker";
import { TargetGridHeader } from "../components/Grid/HeaderRenderer/TargetGridHeader";
import { ReportHeader } from "../components/Grid/HeaderRenderer/ReportHeader";
import { UnitGroupHeader } from "../components/Grid/HeaderRenderer/UnitGroupHeader";
import { MoveStudentsHeader } from "../components/Grid/HeaderRenderer/MoveStudentsHeader";
import { DataTypeTypes } from "src/modules/common/components/Grid/GridDataTypeButtons";
import { MoveTierHeader } from "../components/Grid/HeaderRenderer/MoveTierHeader";
import {
    ClassTrackerFlatHeader,
    ClassTrackerHeaderObject,
    ColumnNode,
    HeaderNodeWithChildren,
} from "../dto/TrackerHeader";
import { ClassTrackerRelatedObject } from "../dto/TrackerRelated";
import { TagLinkHeader } from "../components/Grid/HeaderRenderer/TagLinkHeader";
import { TagModel } from "src/modules/tagging/model/TagModel";
import { TrackerContext } from "../components/Grid/TrackerGrid";
import { SchoolUserRole } from "src/orm/models/User";
import { ClassTrackerLinkModel } from "src/modules/links/model/ClassTrackerLinkModel";
import { ClassTrackerUserSettingsObject } from "../hooks/query/ClassTracker/useTrackerUserSettings";
import { TiersTypes } from "src/orm/models/ClassTrackerGroup";
import { mdiSortAscending, mdiSortDescending } from "@mdi/js";
import { isValueSet } from "src/services/utilsGPT";
import { ProfileResponse } from "src/modules/user/hooks/useProfile";

export enum NodeTypes {
    ROOT = "root",
    DUMMY = "dummy",
    UNIT = "unit",
    SECTION = "section",
    MODULE = "module",
    AVERAGE = "average",
    COLUMN = "column",
    TARGET_GRID = "target-grid",
    TOPIC_SUMMARY = "topic-summary",
    CRITERION = "criterion",
    BTEC_GRADE = "btec-grade",
    CUSTOM_FIELD = "custom-field",
}

export enum ColumnConfigTypes {
    SINGLE_VALUE = "single-value",
    MULTI_VALUE = "multi-value",
    SINGLE_TEXT_CONVERSION = "single-text-conversion",
    MULTI_TEXT_CONVERSION = "multi-text-conversion",
    SELECTABLE = "selectable",
    MTG = "mtg",
    CURRENT_GRADE = "current-grade",
    PROJECTED_GRADE = "projected-grade",
    OTHER_GRADE = "other-grade",
    BTEC_PROJECTED_GRADE = "btec-projected-grade",
    DISTANCE = "distance-to-mtg",
    BTEC = "btec",
    BTEC_GRADE = "btec-grade",
    TEACHER_JUDGEMENT = "teacher-judgement",
    P8_SCORE = "p8-score",
    PMV = "pmv",
    AVERAGE = "average",
}

export enum HeaderNodeTypes {
    STUDENT = "student",
    ATTRIBUTE = "attribute",
}

export enum GroupColumShow {
    OPENED = "opened",
    CLOSED = "closed",
}

export enum PinnedTypes {
    LEFT = "left",
    TOP = "top",
    RIGHT = "right",
}

export const MOVE_STUDENTS_GROUP_FIELD = "moveStudentsGroup";
export const MOVE_STUDENTS_CURRENT_CLASS_FIELD = "currentClass";
export const MOVE_STUDENTS_MOVE_TO_FIELD = "moveStudentTo";
export const MOVE_STUDENTS_MOVE_TO_TIER_FIELD = "moveStudentToTier";
export const MOVE_STUDENTS_GROUP_WIDTH = 300;
export const MOVE_STUDENTS_GROUP_CHILD_WIDTH = 200;
export const STUDENTS_HEADER_CLASS = "ag-students-header";
export const STUDENTS_CELL_CLASS = "ag-students-cell";
export const REPORT_DOWNLOAD_FIELD = "report-download-1";
export const REPORT_HEADER_CLASS = "ag-header-row-column";
export const INITIAL_STUDENTS_COLUMNS = ["firstName", "lastName"];

export const CELL_SIZE_MIN = 38;
export const CELL_GRADE_SIZE_MIN = 153;
export const VIRTUALIZATION_MIN_COLUMNS = 130;

export interface ClassTrackerSettings {
    classTrackerId?: number | null;
    classTrackerGroupId?: number | null;
    header: ClassTrackerHeaderObject | null;
    user: ClassTrackerUserSettingsObject | null;
    related: ClassTrackerRelatedObject | null;
    dataType: DataTypeTypes | null;
    bulkEdit?: boolean;
    currentTier?: TiersTypes;
}

// return first grid column name | null
export const getFirstColumnKey = (studentHeader: (ColDef | ColGroupDef)[]): string | null => {
    const studentNode = (studentHeader.length > 0 && studentHeader[0]) || null;

    if (studentNode && studentNode["children"].length > 0) {
        return studentNode["children"][0].field;
    }

    return null;
};

// return visible student column array | initial student columns array
export const getVisibleStudentColumns = (
    classTrackerUserSettings: ClassTrackerUserSettingsObject | null,
): string[] => {
    if (classTrackerUserSettings?.columnConfig) {
        const tmpVisible: string[] = [];
        Object.entries(classTrackerUserSettings.columnConfig).forEach(([key, value]) => {
            if (value === true && !INITIAL_STUDENTS_COLUMNS.includes(key)) tmpVisible.push(key);
        });

        return INITIAL_STUDENTS_COLUMNS.concat(tmpVisible);
    }

    return INITIAL_STUDENTS_COLUMNS;
};

// return flat header structure
export const getFlatHeaderStructure = (
    classTrackerHeader: ClassTrackerHeaderObject | null,
): ClassTrackerFlatHeader[] => {
    const flatHeaderStructure: ClassTrackerFlatHeader[] = [];

    const handleStructure = (
        children: HeaderNodeWithChildren[],
        parentUnit?: string,
        parentNode?: string,
        parentUnitType?: string,
    ) => {
        children.forEach(headerNode => {
            if (headerNode.children) {
                handleStructure(
                    headerNode.children,
                    headerNode.nodeType === NodeTypes.UNIT ? headerNode.hash : parentUnit,
                    headerNode.hash,
                    headerNode.nodeType === NodeTypes.UNIT
                        ? headerNode.headerConfig?.type
                        : parentUnitType,
                );
            }
            flatHeaderStructure.push({
                parentUnit,
                parentNode,
                name: headerNode.name,
                field: headerNode.hash,
                type: headerNode.nodeType,
                isNeverCollapsed: headerNode.headerConfig?.isNeverCollapsed || false,
                parentUnitType,
                columnType: classTrackerHeader?.columns[headerNode.hash]
                    ? classTrackerHeader.columns[headerNode.hash].columnType
                    : undefined,
                columnConfigType: classTrackerHeader?.columns[headerNode.hash]
                    ? classTrackerHeader.columns[headerNode.hash].columnConfig?.type
                    : undefined,
            });
        });
    };

    const rootNode = classTrackerHeader?.header[0];

    if (rootNode) handleStructure(rootNode.children);

    return flatHeaderStructure;
};

// return header component
export const resolveHeaderComponentByNodeType = (nodeType: string) =>
    nodeType === NodeTypes.CRITERION ? CriterionHeader : ColumnHeader;

// calculate header height
export const calculateHeaderHeight = (classTrackerHeader: ClassTrackerHeaderObject | null) => {
    return 200 + ((classTrackerHeader?.maxLevel || 0) - 1) * CELL_SIZE_MIN;
};

const resolveGroupId = headerNode => {
    return headerNode.nodeType !== NodeTypes.COLUMN ? `g-${headerNode.hash}` : undefined;
};

// resolve should column group expandable
const resolveColumnGroupShow = (
    nodeType: string,
    isDisplayField?: boolean,
    isNeverCollapsed?: boolean,
) => {
    return (nodeType === NodeTypes.COLUMN && isDisplayField === true && isNeverCollapsed) ||
        nodeType === NodeTypes.AVERAGE ||
        nodeType === NodeTypes.CUSTOM_FIELD
        ? GroupColumShow.OPENED
        : GroupColumShow.CLOSED;
};

// get params (for ComponentParams props (ag-grid)) by node type
const resolveHeaderComponentParamsByNodeType = (
    headerNode: HeaderNodeWithChildren,
    currentLevel: number,
    classTrackerHeader: ClassTrackerHeaderObject | null,
    classTrackerUserSettings: ClassTrackerUserSettingsObject | null,
    classTrackerId: number | null,
    parentUnit?: string,
    maxValue?: number | string,
    gradeType?: string | null,
    showTitle?: boolean,
) => {
    const flatHeaderHelperStructure = getFlatHeaderStructure(classTrackerHeader);
    const customColor = getUnitColourByHash(headerNode.hash, classTrackerHeader).colour;
    const parentUnitColour = parentUnit
        ? getUnitColourByHash(parentUnit, classTrackerHeader).colour
        : DEFAULT_CELL_COLOUR;
    const cellConfig = classTrackerHeader?.columns
        ? classTrackerHeader?.columns[headerNode.hash]
        : null;
    const columnType = cellConfig?.columnConfig?.type;
    const isDropdown =
        columnType === ColumnConfigTypes.SELECTABLE || columnType === ColumnConfigTypes.MTG;

    return {
        label: headerNode.name,
        level: currentLevel,
        type: headerNode.nodeType,
        hash: headerNode.hash,
        classTrackerId,
        isDropdown,
        unitType: headerNode.headerConfig?.type,
        childNodes: flatHeaderHelperStructure.filter(
            s => s.parentUnit === headerNode.hash && s.type === NodeTypes.COLUMN,
        ),
        customColor: headerNode.nodeType === NodeTypes.UNIT ? customColor : parentUnitColour,
        showTitle,
        classTrackerUserSettings: classTrackerUserSettings || undefined,
        displayMaxMarks: headerNode.headerConfig?.displayMaxMarks || false,
        isDisplayField: headerNode.headerConfig?.isDisplay || false,
        isCurrentGrade:
            cellConfig?.columnConfig?.type === ColumnConfigTypes.CURRENT_GRADE ||
            cellConfig?.columnConfig?.type === ColumnConfigTypes.BTEC_GRADE,
        maxValue,
        columnConfigType: cellConfig?.columnConfig?.type,
        columnType: cellConfig?.columnType || null,
        description: headerNode.headerConfig?.title,
        colourConfig: cellConfig?.colourConfig,
        gradeType,
    };
};

// resolve editor component with params by column type
const resolveCellEditorSelector = (
    cellConfig: ColumnNode | null,
    classTrackerRelatedData: ClassTrackerRelatedObject | null,
    parentUnitColour?: string,
    isDisplayField?: boolean,
    allowAbsent?: boolean,
    allowX?: boolean,
) => {
    const hasFineGrades = !!classTrackerRelatedData?.fineGradeEnabled;

    const commonRendererParams = {
        parentUnitColour,
        isDisplayField,
    };
    const selectableComponent = {
        component: GridFrameworkComponents.SELECTABLE_EDITOR,
        params: {
            options: cellConfig?.columnConfig?.typeConfig?.choices,
            ...commonRendererParams,
        },
    };
    const textConversionComponent = {
        component: GridFrameworkComponents.TEXT_CONVERSION_EDITOR,
        params: {
            options: cellConfig?.columnConfig?.typeConfig?.choices,
            allowAbsent,
            ...commonRendererParams,
        },
    };

    const grades =
        classTrackerRelatedData?.courseGrades && classTrackerRelatedData?.courseGrades.length > 0
            ? classTrackerRelatedData?.courseGrades
            : classTrackerRelatedData?.grades;

    switch (cellConfig?.columnConfig?.type) {
        case ColumnConfigTypes.SELECTABLE:
            return selectableComponent;
        case ColumnConfigTypes.MTG:
            return {
                component: GridFrameworkComponents.MTG_EDITOR,
                params: { ...commonRendererParams, grades },
            };
        case ColumnConfigTypes.TEACHER_JUDGEMENT:
            return {
                component: GridFrameworkComponents.MTG_EDITOR,
                params: {
                    ...commonRendererParams,
                    grades,
                    allowUnderscore: true,
                    hasFineGrades,
                    allowX: true,
                },
            };
        case ColumnConfigTypes.BTEC:
            return {
                component: GridFrameworkComponents.BTEC_TRACKED_FIELD_EDITOR,
                params: { cellConfig, parentUnitColour },
            };
        case ColumnConfigTypes.MULTI_TEXT_CONVERSION:
            return textConversionComponent;
        case ColumnConfigTypes.SINGLE_TEXT_CONVERSION:
            return textConversionComponent;
        default:
            return {
                component: GridFrameworkComponents.DEFAULT_VALUE_EDITOR,
                params: { ...commonRendererParams, allowAbsent, allowX },
            };
    }
};

// resolve renderer component with params by column type
const resolveCellRendererSelector = (
    cellConfig: ColumnNode | null,
    classTrackerRelatedData: ClassTrackerRelatedObject | null,
    parentUnitColour?: string,
    isDisplayField?: boolean,
    unitType?: string | null,
    allowAbsent?: boolean,
    isDistanceMtgDescription?: boolean,
): { component: string; params: any } | undefined => {
    const columnType = cellConfig?.columnConfig?.type;

    const isStandardGradeType = classTrackerRelatedData?.gradeType.type === "Standard";
    // const hasFineGrades = !!classTrackerRelatedData?.fineGradeEnabled;
    const hasFineGrades = true; // TODO

    if (columnType) {
        const commonRendererParams = {
            cellConfig,
            relatedData: classTrackerRelatedData,
            parentUnitColour,
            isDisplayField,
            unitType,
        };
        const selectableRendererConfig = {
            component: GridFrameworkComponents.SELECTABLE_RENDERER,
            params: {
                ...commonRendererParams,
                choices: cellConfig?.columnConfig?.typeConfig?.choices,
            },
        };
        const textConversionRendererConfig = {
            component: GridFrameworkComponents.TEXT_CONVERSION_RENDERER,
            params: {
                ...commonRendererParams,
                choices: cellConfig?.columnConfig?.typeConfig?.choices,
                allowAbsent,
            },
        };
        const grades =
            classTrackerRelatedData?.courseGrades &&
            classTrackerRelatedData?.courseGrades.length > 0
                ? classTrackerRelatedData?.courseGrades
                : classTrackerRelatedData?.grades;

        switch (columnType) {
            case ColumnConfigTypes.SELECTABLE:
                return selectableRendererConfig;
            case ColumnConfigTypes.MTG:
                return {
                    component: GridFrameworkComponents.MTG_RENDERER,
                    params: { ...commonRendererParams, grades },
                };
            case ColumnConfigTypes.TEACHER_JUDGEMENT:
                return {
                    component: GridFrameworkComponents.MTG_RENDERER,
                    params: { ...commonRendererParams, grades, isStandardGradeType, hasFineGrades },
                };
            case ColumnConfigTypes.SINGLE_TEXT_CONVERSION:
                return textConversionRendererConfig;
            case ColumnConfigTypes.MULTI_TEXT_CONVERSION:
                return textConversionRendererConfig;
            case ColumnConfigTypes.BTEC_GRADE:
                return {
                    component: GridFrameworkComponents.CURRENT_GRADE_RENDERER,
                    params: { ...commonRendererParams, isBtecCourseGrade: true },
                };
            case ColumnConfigTypes.BTEC:
                return {
                    component: GridFrameworkComponents.BTEC_TRACKED_FIELD_RENDERER,
                    params: { cellConfig, parentUnitColour },
                };
            default:
                return {
                    component: GridFrameworkComponents.DEFAULT_VALUE_RENDERER,
                    params: commonRendererParams,
                };
        }
    }

    if (isDistanceMtgDescription) {
        return {
            component: GridFrameworkComponents.DISTANCE_MTG_DESCRIPTION_RENDERER,
            params: { isDistanceMtgDescription },
        };
    }

    return undefined;
};

// generate Target Grid Columns from grades
export const generateTargetGridColumns = (
    children: HeaderNodeWithChildren[],
    classTrackerSettings: ClassTrackerSettings,
    parentUnit?: string,
    isLite?: boolean,
) => {
    const { header: classTrackerHeader, related: classTrackerRelatedData } = classTrackerSettings;
    let allTargetColumns: {}[] = [];

    let gradeRange = classTrackerSettings.related?.gradeRange || null;

    if (
        classTrackerSettings.currentTier &&
        classTrackerSettings.related?.tierConfig[classTrackerSettings.currentTier]
    ) {
        gradeRange =
            classTrackerSettings.related?.tierConfig[classTrackerSettings.currentTier] || null;
    }
    let grades = classTrackerRelatedData?.grades;

    if (classTrackerRelatedData?.courseGrades && classTrackerRelatedData?.courseGrades.length > 0) {
        grades = classTrackerRelatedData?.courseGrades;
    }

    const reversedGrades = grades ? [...grades].reverse() : [];

    children.forEach(child => {
        const parentUnitColour = parentUnit
            ? getUnitColourByHash(parentUnit, classTrackerHeader).colour
            : undefined;
        const isDisplayField = child.headerConfig?.isDisplay;

        const targetGridColumns = reversedGrades
            .filter(grade => {
                if (gradeRange && gradeRange.min !== null && gradeRange.max !== null) {
                    return grade.id >= gradeRange.min && grade.id <= gradeRange.max;
                }
                return true;
            })
            .map(grade => ({
                headerName: grade.name,
                headerComponent: TargetGridHeader,
                headerComponentParams: () => ({
                    label: grade.name,
                    customColor: parentUnitColour ? parentUnitColour : DEFAULT_CELL_COLOUR,
                }),
                cellRendererSelector: isLite
                    ? undefined
                    : () => ({
                          component: GridFrameworkComponents.DEFAULT_VALUE_RENDERER,
                          params: { parentUnitColour },
                      }),
                field: child.hash + "-" + grade.id,
                minWidth: CELL_SIZE_MIN,
                width: CELL_SIZE_MIN,
                suppressNavigable: true,
                sortable: false,
                suppressMovable: true,
                editable: false,
                singleClickEdit: true,
                children: undefined,
                cellClass: () =>
                    `ag-value-cell${isLite ? "-lite" : ""} ag-value-cell--is-display-field`,
                cellStyle: {
                    backgroundColor: parentUnitColour
                        ? hexToRgba(
                              parentUnitColour,
                              isDisplayField
                                  ? DEFAULT_DISPLAY_FIELD_OPACITY / 100
                                  : DEFAULT_FIELD_OPACITY / 100,
                          )
                        : "transparent",
                },
            }));

        if (allTargetColumns.length === 0) {
            allTargetColumns = targetGridColumns;
        } else {
            allTargetColumns = allTargetColumns.concat(targetGridColumns);
        }
    });

    return allTargetColumns;
};

// generate Target Grid Columns from grades
export const generateAtlColumns = (
    children: HeaderNodeWithChildren[],
    classTrackerSettings: ClassTrackerSettings,
    parentUnit?: string,
    bulkEdit?: boolean,
) => {
    const { header: classTrackerHeader, related: classTrackerRelatedData } = classTrackerSettings;
    const fields = [] as any[];

    children.forEach(mainNode => {
        const parentUnitColour = parentUnit
            ? getUnitColourByHash(parentUnit, classTrackerHeader).colour
            : undefined;

        const atlConfig =
            classTrackerRelatedData?.atlConfigs && classTrackerRelatedData?.atlConfigs.length > 0
                ? classTrackerRelatedData?.atlConfigs.find(atl => atl.fieldType === mainNode.name)
                : null;

        const maxValue = classTrackerHeader?.columns[mainNode.hash]?.maxValue;

        if (atlConfig) {
            const cellData = {
                headerName: atlConfig?.name || mainNode.name,
                headerComponent: resolveHeaderComponentByNodeType(NodeTypes.COLUMN),
                headerComponentParams: {
                    label: atlConfig?.name + (maxValue ? ` /${maxValue}` : ""),
                    customColor: parentUnitColour ? parentUnitColour : DEFAULT_CELL_COLOUR,
                    maxValue,
                    columnType: "custom-field",
                },
                columnGroupShow: GroupColumShow.OPENED,
                field: mainNode.hash,
                headerClass: mainNode.headerClass,
                suppressSizeToFit: false,
                minWidth: CELL_SIZE_MIN,
                sortable: false,
                resizable: false,
                suppressMovable: true,
                cellStyle: {
                    backgroundColor: hexToRgba(
                        parentUnitColour ? parentUnitColour : DEFAULT_CELL_COLOUR,
                        DEFAULT_FIELD_OPACITY / 100,
                    ),
                },
                cellClass: () => `ag-value-cell`,
                cellRendererSelector: () => ({
                    component: GridFrameworkComponents.DEFAULT_VALUE_RENDERER,
                    params: {
                        columnType: "custom-field",
                        cellConfig: classTrackerHeader?.columns[mainNode.hash],
                        parentUnitColour,
                    },
                }),
                cellEditorSelector: () => ({
                    component: GridFrameworkComponents.DEFAULT_VALUE_EDITOR,
                    params: { columnType: "custom-field" },
                }),
                editable: data => {
                    if (data.node.rowPinned === "top" || data.data["student_isMoving"]) {
                        return false;
                    }
                    if (
                        classTrackerSettings.classTrackerGroupId &&
                        !classTrackerSettings.classTrackerId &&
                        classTrackerSettings.dataType === DataTypeTypes.FORECAST
                    ) {
                        return false;
                    }
                    if (classTrackerSettings.dataType === DataTypeTypes.SNAPSHOT) {
                        return false;
                    }
                    return true;
                },
                singleClickEdit: bulkEdit ? false : true,
                children: undefined,
            };
            fields.push({ ...cellData });
            fields.push({
                ...cellData,
                editable: false,
                minWidth: 114,
                resizable: false,
                columnGroupShow: GroupColumShow.CLOSED,
                field: mainNode.hash + "-display",
                headerComponentParams: {
                    label: atlConfig?.name,
                    customColor: parentUnitColour ? parentUnitColour : DEFAULT_CELL_COLOUR,
                    maxValue,
                    columnType: "custom-field",
                },
                cellRendererSelector: () => ({
                    component: GridFrameworkComponents.DEFAULT_VALUE_RENDERER,
                    params: { customField: atlConfig, columnType: "custom-field" },
                }),
                cellStyle: {
                    backgroundColor: hexToRgba(
                        parentUnitColour ? parentUnitColour : DEFAULT_CELL_COLOUR,
                        DEFAULT_DISPLAY_FIELD_OPACITY / 100,
                    ),
                },
            });
        }
    });

    return fields;
};

const resolveMinWidth = (
    columnType: ColumnConfigTypes | string,
    relatedData,
    isDistanceDescription,
    // isNumber,
) => {
    // if (isNumber) {
    //     return 60;
    // }
    if (isDistanceDescription) {
        return 114;
    }

    if (
        [
            ColumnConfigTypes.CURRENT_GRADE,
            ColumnConfigTypes.OTHER_GRADE,
            ColumnConfigTypes.BTEC_GRADE,
            ColumnConfigTypes.BTEC_PROJECTED_GRADE,
            ColumnConfigTypes.PROJECTED_GRADE,
        ].includes(columnType as ColumnConfigTypes)
    ) {
        return relatedData?.gradeType?.colWidth || 114;
    }

    if (
        [
            ColumnConfigTypes.P8_SCORE,
            ColumnConfigTypes.PMV,
            ColumnConfigTypes.MTG,
            ColumnConfigTypes.TEACHER_JUDGEMENT,
        ].includes(columnType as ColumnConfigTypes)
    ) {
        return 114;
    }

    if ([ColumnConfigTypes.SELECTABLE].includes(columnType as ColumnConfigTypes)) {
        return 105;
    }
    return CELL_SIZE_MIN;
};

// main function for generating header
const handleChildNodesRecursively = (
    children: HeaderNodeWithChildren[],
    classTrackerSettings: ClassTrackerSettings,
    columnGroups: ColumnGroup[],
    parentUnit?: string,
    level?: number,
    unitType?: string | null,
    isBlocked?: boolean,
) => {
    const currentlevel: number = level || 1;

    const {
        header: classTrackerHeader,
        user: classTrackerUserSettings,
        classTrackerId,
        classTrackerGroupId,
        related: classTrackerRelatedData,
        dataType,
        bulkEdit,
    } = classTrackerSettings;

    // handle Target Grid Columns
    if (children.length > 0 && children[0].nodeType === NodeTypes.TARGET_GRID) {
        return generateTargetGridColumns(children, classTrackerSettings, parentUnit);
    }

    if (
        children.length > 0 &&
        children[0].hash &&
        classTrackerHeader?.columns[children[0].hash] &&
        classTrackerHeader?.columns[children[0].hash].columnConfig?.type === "custom-field"
    ) {
        return generateAtlColumns(children, classTrackerSettings, parentUnit, bulkEdit);
    }

    const distanceToMtgColumnHash: string[] = [];
    Object.keys(classTrackerHeader.columns).forEach(key => {
        if (classTrackerHeader.columns[key].columnConfig.type === ColumnConfigTypes.DISTANCE) {
            distanceToMtgColumnHash.push(key);
        }
    });

    children.forEach(child => {
        if (
            distanceToMtgColumnHash.includes(child.hash) &&
            classTrackerHeader?.columns[child.hash]?.columnConfig?.typeConfig?.displayText &&
            classTrackerHeader?.columns[child.hash]?.columnConfig?.typeConfig?.["map"]
        ) {
            const childIndex = children.findIndex(c => c.hash === child.hash);
            children.splice(childIndex + 1, 0, { ...child, hash: child.hash + "-mtgdescription" });
        }
    });

    // handle other types of columns
    return children
        .filter(headerNode => headerNode.nodeType !== NodeTypes.DUMMY)
        .map((headerNode, i) => {
            const cellConfig = classTrackerHeader?.columns
                ? classTrackerHeader?.columns[headerNode.hash]
                : null;
            const allowAbsent = cellConfig?.columnConfig?.typeConfig?.allowAbsent;
            const maxValue = cellConfig?.maxValue;
            const parentUnitColour = parentUnit
                ? getUnitColourByHash(parentUnit, classTrackerHeader).colour
                : undefined;
            const isDisplayField = headerNode.headerConfig?.isDisplay;
            let editable = false;
            const isFirstChild = !!(i === 0);
            const isLastChild = !!(i === children.length - 1);

            const isDistanceDescription = headerNode.hash.includes("mtgdescription");

            if (classTrackerSettings && classTrackerSettings.classTrackerGroupId) {
                editable = true;
            }

            if (dataType === DataTypeTypes.FORECAST) {
                editable = !isDisplayField || !cellConfig?.readOnly;
            }

            if (dataType === DataTypeTypes.LIVE || dataType === DataTypeTypes.SNAPSHOT) {
                editable = !cellConfig?.readOnly;
            }
            if (
                dataType === DataTypeTypes.FORECAST &&
                cellConfig?.columnConfig?.type === ColumnConfigTypes.TEACHER_JUDGEMENT
            ) {
                editable = true;
            }

            if (
                dataType === DataTypeTypes.SNAPSHOT ||
                (classTrackerGroupId && !classTrackerId && dataType === DataTypeTypes.FORECAST) ||
                isBlocked ||
                isDistanceDescription
            ) {
                editable = false;
            }

            const isNeverCollapsed = headerNode.headerConfig?.isNeverCollapsed;
            const showTitle = headerNode.headerConfig?.showTitle;
            const gradeType = cellConfig?.columnConfig?.typeConfig?.gradeType;

            // MAIN Cell Data Object
            const cellData = {
                headerName: headerNode.name,
                headerComponent: resolveHeaderComponentByNodeType(headerNode.nodeType),
                headerTooltip: headerNode.headerConfig?.title || headerNode.name,
                headerGroupComponent: UnitGroupHeader,
                columnGroupShow: resolveColumnGroupShow(
                    headerNode.nodeType,
                    isDisplayField,
                    isNeverCollapsed,
                ),
                headerGroupComponentParams: resolveHeaderComponentParamsByNodeType(
                    headerNode,
                    currentlevel,
                    classTrackerHeader,
                    classTrackerUserSettings,
                    classTrackerId || null,
                    parentUnit,
                    maxValue,
                    gradeType,
                    showTitle,
                ),
                headerComponentParams: resolveHeaderComponentParamsByNodeType(
                    headerNode,
                    currentlevel,
                    classTrackerHeader,
                    classTrackerUserSettings,
                    classTrackerId || null,
                    parentUnit,
                    maxValue,
                    gradeType,
                ),
                field: headerNode.hash,
                resizable: [ColumnConfigTypes.MTG, ColumnConfigTypes.TEACHER_JUDGEMENT].includes(
                    cellConfig?.columnConfig?.type as ColumnConfigTypes,
                ),
                groupId: resolveGroupId(headerNode),
                headerClass: headerNode.headerClass || "",
                suppressSizeToFit: false,
                minWidth: resolveMinWidth(
                    cellConfig?.columnConfig?.type,
                    classTrackerRelatedData,
                    isDistanceDescription,
                ),
                sortable: true,
                comparator: (
                    num1: string | null,
                    num2: string | null,
                    nodeA,
                    nodeB,
                    isInverted,
                ) => {
                    if (!isValueSet(num1) && !isValueSet(num2)) {
                        if (num1 === null) return isInverted ? 1 : -1;
                        return isInverted ? -1 : 1;
                    }
                    if (!isValueSet(num1)) return isInverted ? -1 : 1;
                    if (!isValueSet(num2)) return isInverted ? 1 : -1;

                    if (num1 === num2) {
                        const fineGrade1 =
                            nodeA.data?.["metaColumnsConfig"]?.[headerNode.hash]?.fineGrade || "";
                        const fineGrade2 =
                            nodeB.data?.["metaColumnsConfig"]?.[headerNode.hash]?.fineGrade || "";
                        if (fineGrade1 === "+") return 1;
                        if (fineGrade1 === "-") return -1;
                        if (fineGrade2 === "+") return -1;
                        if (fineGrade2 === "-") return 1;
                    }
                    const value1 = Number(num1);
                    const value2 = Number(num2);
                    if (isNaN(value1) || isNaN(value2)) {
                        return num1 > num2 ? 1 : -1;
                    }
                    return value1 - value2;
                },
                suppressMovable: true,
                cellStyle: {
                    backgroundColor: parentUnitColour
                        ? hexToRgba(
                              parentUnitColour,
                              isDisplayField
                                  ? DEFAULT_DISPLAY_FIELD_OPACITY / 100
                                  : DEFAULT_FIELD_OPACITY / 100,
                          )
                        : "transparent",
                },
                cellClass: () =>
                    `ag-value-cell${
                        cellConfig?.columnConfig?.type === ColumnConfigTypes.SELECTABLE
                            ? " ag-value-cell--select"
                            : ""
                    }${isDisplayField ? " ag-value-cell--is-display-field" : ""}${
                        isFirstChild ? " hover-current-grade-first" : ""
                    }${isLastChild ? " hover-current-grade-last" : ""}`,
                cellRendererSelector: () =>
                    resolveCellRendererSelector(
                        cellConfig,
                        classTrackerRelatedData,
                        parentUnitColour,
                        isDisplayField,
                        unitType,
                        allowAbsent,
                        isDistanceDescription,
                    ),
                cellEditorSelector: () =>
                    resolveCellEditorSelector(
                        cellConfig,
                        classTrackerRelatedData,
                        parentUnitColour,
                        isDisplayField,
                        allowAbsent,
                    ),
                editable: data => {
                    const classTrackerId = data.data["student_classTrackerId"];
                    const relatedData = classTrackerSettings?.related;
                    const fieldName = data.column.colId;
                    const splited = fieldName.split("$");
                    const ppId = splited?.[1] || undefined;

                    if (data.node.rowPinned === "top" || data.data["student_isMoving"]) {
                        return false;
                    }
                    if (
                        relatedData.enabledAssessments &&
                        relatedData.enabledAssessments[classTrackerId] &&
                        ppId &&
                        editable
                    ) {
                        return !!relatedData.enabledAssessments[classTrackerId].includes(ppId);
                    }

                    return editable;
                },
                singleClickEdit: bulkEdit ? false : true,
                children: headerNode.children
                    ? handleChildNodesRecursively(
                          headerNode.children,
                          classTrackerSettings,
                          columnGroups,
                          headerNode.nodeType === NodeTypes.UNIT ? headerNode.hash : parentUnit,
                          currentlevel + 1,
                          unitType ||
                              (headerNode.nodeType === NodeTypes.UNIT
                                  ? headerNode.headerConfig?.type
                                  : null),
                          isBlocked,
                      )
                    : undefined,
            };

            if (headerNode.nodeType !== NodeTypes.COLUMN) {
                columnGroups.push(cellData);
            }

            return cellData;
        });
};

// merge neighbouring topic summaries
export const mergeNeighbouringTopicSummary = (rootNode, t) => {
    const children: HeaderNodeWithChildren[] = [];

    rootNode.children.forEach(child => {
        const prevIndex = children.length - 1;
        const prevElement = children[prevIndex];
        if (
            prevElement?.headerConfig?.type === NodeTypes.TOPIC_SUMMARY &&
            child.headerConfig?.type === NodeTypes.TOPIC_SUMMARY
        ) {
            prevElement.name = t("tracker.grid.topicSummary");
            prevElement.children = prevElement.children.concat(child.children);
        } else {
            children.push({ ...child });
        }
    });

    return children;
};

// generate move students header
export const generateMoveStudentsHeader = (
    relatedData: ClassTrackerRelatedObject | null,
    t: TFunction,
    classTrackersOverride?: OrmClassTracker[],
    classTrackerId?: number,
    isSyncedClass?: boolean,
) => {
    const defaultConfig = {
        sortable: false,
        suppressNavigable: true,
        suppressMovable: true,
        pinned: PinnedTypes.LEFT,
        resizable: false,
        colSpan: () => 1,
        suppressMenu: true,
        autoHeight: false,
        headerClass: STUDENTS_HEADER_CLASS,
        editable: false,
        pinnedRowCellRenderer: GridFrameworkComponents.PINNED_ROW_RENDERER,
        pinnedRowCellRendererParams: { isDisplayField: true },
    };

    const hasTiers = !!(
        relatedData &&
        relatedData.tierConfig &&
        !Object.keys(relatedData.tierConfig).find(t => t === "-")
    );

    let moveChildren = [
        {
            ...defaultConfig,
            headerName: t("tracker.moveStudents.currentClass"),
            field: MOVE_STUDENTS_CURRENT_CLASS_FIELD,
            width: MOVE_STUDENTS_GROUP_CHILD_WIDTH,
            cellRendererSelector: () => ({
                component: GridFrameworkComponents.CURRENT_CLASS_RENDERER,
            }),
        },
    ] as any;

    if (!isSyncedClass) {
        moveChildren = moveChildren.concat({
            ...defaultConfig,
            headerName: t("tracker.moveStudents.moveTo"),
            field: MOVE_STUDENTS_MOVE_TO_FIELD,
            width: MOVE_STUDENTS_GROUP_CHILD_WIDTH,
            editable: classTrackerId ? !isSyncedClass : true,
            singleClickEdit: true,
            cellRendererSelector: () => ({
                component: GridFrameworkComponents.MOVE_STUDENTS_RENDERER,
                params: {
                    classTrackers: relatedData?.classTrackers || classTrackersOverride,
                },
            }),
            cellEditorSelector: () => ({
                component: GridFrameworkComponents.MOVE_STUDENTS_EDITOR,
                params: {
                    classTrackers: relatedData?.classTrackers || classTrackersOverride,
                },
            }),
        });
    }

    if (hasTiers) {
        moveChildren.push({
            ...defaultConfig,
            headerName: t("tracker.moveStudents.moveToTier"),
            field: MOVE_STUDENTS_MOVE_TO_TIER_FIELD,
            width: MOVE_STUDENTS_GROUP_CHILD_WIDTH,
            editable: true,
            singleClickEdit: true,
            headerComponent: MoveTierHeader,
            cellRendererSelector: () => ({
                component: GridFrameworkComponents.MOVE_TIERS_RENDERER,
            }),
            cellEditorSelector: () => ({
                component: GridFrameworkComponents.MOVE_TIERS_EDITOR,
            }),
        } as any);
    }

    return {
        ...defaultConfig,
        headerName: "",
        field: MOVE_STUDENTS_GROUP_FIELD,
        width: MOVE_STUDENTS_GROUP_WIDTH,
        headerGroupComponent: MoveStudentsHeader,
        // pinnedRowCellRenderer: undefined,
        children: moveChildren,
    };
};

// generate report header
export const generateReportHeader = (label?: string, pinned?: string) => ({
    headerName: "",
    field: REPORT_DOWNLOAD_FIELD,
    width: CELL_SIZE_MIN,
    maxWidth: CELL_SIZE_MIN,
    headerClass: REPORT_HEADER_CLASS,
    pinned: pinned,
    sortable: false,
    suppressNavigable: true,
    suppressMovable: true,
    resizable: false,
    colSpan: () => 1,
    autoHeight: false,
    suppressMenu: true,
    cellRendererSelector: () => ({
        component: GridFrameworkComponents.REPORT_DOWNLOAD_RENDERER,
        params: { label: label || "" },
    }),
    headerComponent: ReportHeader,
    headerComponentParams: () => ({ pinned }),
});

// generate report header
export const generateGridEndHeader = () => ({
    headerName: "",
    field: "grid-end",
    width: 72,
    maxWidth: 72,
    headerClass: "grid-end-margin",
    sortable: false,
    suppressNavigable: true,
    suppressMovable: true,
    resizable: false,
    colSpan: () => 1,
    autoHeight: false,
    suppressMenu: true,
});

export const generateTagsAvgHeader = () => {
    return [
        {
            headerName: "",
            field: `tagAvg`,
            singleClickEdit: false,
            pinned: PinnedTypes.LEFT,
            width: 42,
            editable: false,
            sortable: false,
            suppressNavigable: true,
            suppressMovable: true,
            resizable: false,
            autoHeight: false,
            suppressMenu: true,

            cellRendererSelector: () => ({
                component: GridFrameworkComponents.TAG_AVG_RENDERER,
            }),
        },
    ];
};

export const generateTagsHeader = (tags: TagModel[], subjectArea?: number) => {
    return (tags || []).map((t: TagModel) => {
        const checkIsEditable = ({
            archived,
            dataType,
            userProfile,
        }: {
            archived?: boolean;
            dataType: DataTypeTypes;
            userProfile?: ProfileResponse | null;
        }): boolean => {
            if (archived) return false;
            const isEditable = dataType === DataTypeTypes.LIVE && t.status === "active";
            if (
                userProfile.userRole === SchoolUserRole.SCHOOL_ADMIN ||
                userProfile.userRole === SchoolUserRole.OWNER ||
                t.accessLevel === "all"
            )
                return isEditable;
            if (t.accessLevel === "author" && userProfile) {
                return isEditable && userProfile.id === t.authorId;
            }
            if (t.accessLevel === "leader" && userProfile) {
                return (
                    isEditable &&
                    (userProfile.isLeaderOfAll ||
                        (subjectArea &&
                            !!userProfile.leaderOfSubjectAreas.find(
                                ({ id }) => id === subjectArea,
                            )))
                );
            }

            return isEditable;
        };
        return {
            headerName: t.name,
            field: `tag-${t.id}`,
            singleClickEdit: true,
            pinned: PinnedTypes.LEFT,
            width: 42,
            editable: ({ context }: { context: TrackerContext }) => checkIsEditable(context),
            hide: true,
            headerComponent: TagLinkHeader,
            headerComponentParams: { isTag: true, locked: !(t.status === "active") },
            sortable: false,
            suppressNavigable: true,
            suppressMovable: true, //991 enable move tags
            resizable: false,
            autoHeight: false,
            suppressMenu: true,
            cellDataType: "object",
            cellEditorSelector: () => ({ component: GridFrameworkComponents.TAG_DATA_EDITOR }),
            cellRendererSelector: () => ({
                component: GridFrameworkComponents.TAG_DATA_RENDERER,
                params: { checkIsEditable },
            }),
        };
    });
};

export const generateLinksHeader = (links: ClassTrackerLinkModel[], initialyVisible) => {
    return (links || []).map((l: ClassTrackerLinkModel) => ({
        headerName: l.title,
        field: `link-${l.id}`,
        singleClickEdit: true,
        pinned: PinnedTypes.LEFT,
        width: 42,
        editable: true,
        hide: !initialyVisible,
        headerComponent: TagLinkHeader,
        headerComponentParams: { locked: !l.canEdit },
        sortable: false,
        suppressNavigable: true,
        suppressMovable: true,
        resizable: false,
        autoHeight: false,
        suppressMenu: true,
        cellDataType: "object",
        cellEditorSelector: () => ({ component: GridFrameworkComponents.LINK_DATA_EDITOR }),
        cellRendererSelector: () => ({
            component: GridFrameworkComponents.LINK_DATA_RENDERER,
        }),
    }));
};

// Generate header MAIN entry
export const generateHeader = (
    classTrackerSettings: ClassTrackerSettings,
    t: TFunction,
    columnGroups: ColumnGroup[],
    isBlocked,
) => {
    const { header: classTrackerHeader } = classTrackerSettings;
    const rootNode: HeaderNodeWithChildren | null = classTrackerHeader?.header[0] || null;

    if (rootNode) {
        const children =
            classTrackerSettings.header.version === 2
                ? rootNode.children
                : mergeNeighbouringTopicSummary(rootNode, t);
        const headerNodes = handleChildNodesRecursively(
            children,
            classTrackerSettings,
            columnGroups,
            undefined,
            undefined,
            undefined,
            isBlocked,
        );
        return headerNodes
            .concat(generateReportHeader("", PinnedTypes.LEFT))
            .concat(generateGridEndHeader());
    }

    return [];
};

// generate students header
export const generateStudentHeader = (
    classTrackerSettings: ClassTrackerSettings,
    availableStudentColumns: string[],
    t: TFunction,
) => {
    const { user, classTrackerId, classTrackerGroupId } = classTrackerSettings;

    const visibleStudentColumns = getVisibleStudentColumns(user);

    const resolveStudentColumnWidth = cc => {
        if (["uniquePupilNumber", "lastName", "firstName"].includes(cc)) {
            return 160;
        }
        if (["ethnicity_name"].includes(cc)) {
            return 250;
        }

        return 110;
    };
    const tagsAvg: any = generateTagsAvgHeader();
    const children = tagsAvg.concat(
        visibleStudentColumns.map(cc => {
            return {
                headerName: t(`tracker.grid.configureColumns.${cc}`),
                field: "student_" + cc,
                width: resolveStudentColumnWidth(cc),
                headerTooltip: t(`tracker.grid.configureColumns.${cc}`),
                headerClass: STUDENTS_HEADER_CLASS,
                cellClass: () => STUDENTS_CELL_CLASS,
                pinned: PinnedTypes.LEFT as string,
                sortable: true,
                suppressNavigable: true,
                suppressMovable: true,
                resizable: false,
                autoHeight: false,
                suppressMenu: true,
                colSpan: (): number => 1,
                cellRendererSelector: () => ({
                    component: GridFrameworkComponents.STUDENT_DATA_RENDERER,
                }),
                icons: {
                    sortAscending: `<svg viewBox="0 0 24 24" role="presentation" style="width: 18px; height: 18px; margin: 0 0 -4px 0"><path d=${mdiSortAscending.replaceAll(
                        " ",
                        ",",
                    )} style="fill: currentcolor;"></path></svg>`,
                    sortDescending: `<svg viewBox="0 0 24 24" role="presentation" style="width: 18px; height: 18px; margin: 0 0 -4px 0"><path d=${mdiSortDescending} style="fill: currentcolor;"></path></svg>`,
                },
            };
        }),
    );

    const isMovingHeader = {
        headerName: "",
        field: "student_isMoving",
        width: 50,
        headerClass: STUDENTS_HEADER_CLASS,
        cellClass: () => STUDENTS_CELL_CLASS,
        pinned: PinnedTypes.LEFT as string,
        sortable: false,
        suppressNavigable: true,
        suppressMovable: true,
        resizable: false,
        autoHeight: false,
        suppressMenu: true,
        colSpan: (): number => 1,
        cellRendererSelector: () => ({
            component: GridFrameworkComponents.STUDENT_IS_MOVING_RENDERER,
        }),
    };

    return [
        {
            headerName: "student",
            field: "student",
            headerGroupComponent: ColumnConfigurationHeader,
            headerGroupComponentParams: {
                classTrackerId: classTrackerId,
                classTrackerGroupId: classTrackerGroupId,
                availableStudentColumns,
            },
            sortable: false,
            suppressNavigable: true,
            suppressMovable: true,
            resizable: false,
            autoHeight: false,
            suppressMenu: true,
            children: children.concat(isMovingHeader as any),
        },
    ];
};
