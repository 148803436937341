import { darken } from "@mui/material";
import { ColDef } from "ag-grid-community";
import { Component } from "react";

interface OwnProps {
    value: boolean | string;
    data: any;
    context: any;
    colDef: ColDef;
    columnColour?: string;
    headerConfig?: any;
    column: any;
}

export default class AnalysisAverageRenderer extends Component<OwnProps> {
    render() {
        const backgroundColor = this.props.columnColour;
        const isDisplayField = this.props.headerConfig?.isDisplay || false;
        const parentColId = this.props.column.colId.replace("-atl-average", "");
        const flatHeader = this.props.context.flatHeader;
        const cols = flatHeader.filter(fh => fh.parentUnit === parentColId).map(fh => fh.field);
        const data = this.props.data;
        let average = 0;
        let colsWithData = 0;
        cols.forEach(col => {
            if (data[col] && data[col] > 0) {
                average = average + data[col];
                colsWithData++;
            }
        });

        return (
            <div
                style={{
                    backgroundColor:
                        isDisplayField && backgroundColor
                            ? darken(backgroundColor, 0.02)
                            : backgroundColor,
                }}
                className="ag-value-cell-label"
            >
                {average && colsWithData ? Math.round(average / colsWithData) : ""}
            </div>
        );
    }
}
