import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { CommonSubjectAreasResponse } from "src/modules/common/api/subjectAreas";

export enum OrmSubjectAreaActionTypes {
    BULK_IMPORT = "[orm] SUBJECT AREA BULK IMPORT",
}

export const OrmSubjectAreaActions = {
    bulkImport: (payload: CommonSubjectAreasResponse) => {
        return createAction(OrmSubjectAreaActionTypes.BULK_IMPORT, payload);
    },
};

export type OrmSubjectAreaActions = ActionsUnion<typeof OrmSubjectAreaActions>;
