import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { OrmSubscription } from "src/orm/models/Subscription";
import { getSchoolAccountId } from "src/services/url";

export const rejectPendingSubscriptionEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscriptions/{subscription}/reject/`,
    method: ApiRequests.POST,
};

export type RejectPendingSubscriptionResponse = OrmSubscription[];
