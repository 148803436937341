import { TagEditRequest } from "../hooks/useEditMutation";

export enum TagAccessLevelEnum {
    ALL = "all",
    LEADER = "leader",
    AUTHOR = "author",
}

export enum TagStatusEnum {
    ACTIVE = "active",
    INACTIVE = "inactive",
    LOCKED = "locked",
}

export interface TagModel {
    id: number;
    name: string;
    authorId?: number;
    author?: {
        id: number;
        name: string;
    };
    accessLevel: TagAccessLevelEnum;
    status: TagStatusEnum;
    yearGroups: number[];
    cohort: {
        id: number;
        name: string;
    };
    numberOfStudents?: number;
    specifications: [
        {
            specificationId: number;
            specificationName: string;
            qualificationId: number;
            qualificationName: string;
            subjectAreaId: number;
            subjectAreaName: string;
        },
    ];
}

export interface ClassTrackerStudentTagModel {
    [key: string]: number;
}

export const tagModelToTagRequest = (tagData: TagModel): TagEditRequest => {
    return {
        ...tagData,
        specifications:
            tagData.specifications?.length > 0
                ? tagData.specifications.map(spec => ({ id: spec.specificationId }))
                : null,
        yearGroups: tagData.yearGroups?.length > 0 ? tagData.yearGroups : null,
    };
};
