import AppContainer from "src/components/AppContainer";
import SubjectReportTab from "../components/SnapshotReport/SubjectReportTab";
import SummaryReportTab from "../components/SnapshotReport/SummaryReportTab";
import AllSubjectsReportTab from "../components/SnapshotReport/AllSubjectsReportTab";
import COLORS from "src/styles/colors";
import { useEffect, useState } from "react";
import { Box, MenuItem, Paper, Tabs, Tab, TextField, Theme, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { filterNotNullable } from "src/services/object";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flex: 1,
        width: "100%",
        overflow: "hidden",
        backgroundColor: COLORS.WHITE,
    },
    tabPanel: {
        flex: 1,
        overflowY: "auto",
        padding: theme.spacing(6, 7),
        position: "relative",
    },
}));
export enum PublishedReportsTabs {
    TYPE_SUBJECT = "subject",
    TYPE_SUMMARY = "summary",
    TYPE_ALL_SUBJECTS = "all-subjects",
}

const SnapshotReportsCreateContainer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { data } = useCoreValues();
    const cohorts = data?.cohorts;

    const { data: accountInfo } = useAccountInfo();
    const defaultCohortId = accountInfo?.activeCohortId;
    const [currentTab, setCurrentTab] = useState<string>(PublishedReportsTabs.TYPE_ALL_SUBJECTS);
    const [selectedCohort, setSelectedCohort] = useState<number | null>(defaultCohortId || null);

    const handleTabChange = (event: React.ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    const tabs = [
        {
            key: PublishedReportsTabs.TYPE_ALL_SUBJECTS,
            label: t("report.snapshot.create.allSubjectsReport"),
        },
        {
            key: PublishedReportsTabs.TYPE_SUMMARY,
            label: t("report.snapshot.create.summaryReport"),
        },
        {
            key: PublishedReportsTabs.TYPE_SUBJECT,
            label: t("report.snapshot.create.subjectReport"),
        },
    ];

    useEffect(() => {
        if (cohorts && cohorts.length > 0 && selectedCohort === null && defaultCohortId !== null) {
            setSelectedCohort(defaultCohortId || cohorts[0].id || null);
        }
    }, [defaultCohortId, cohorts]);

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {t("report.snapshot.create.header")}
            </Typography>
            {selectedCohort && (
                <Box mb={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <TextField
                                label={t("report.snapshot.create.cohortFilterLabel")}
                                select
                                SelectProps={{ displayEmpty: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="none"
                                name="cohort"
                                value={selectedCohort || 0}
                                onChange={e => setSelectedCohort(parseInt(e.target.value))}
                            >
                                {filterNotNullable(cohorts).map(obj => (
                                    <MenuItem key={obj.id} value={obj.id}>
                                        {obj.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Paper>
                <Box className={classes.root}>
                    <Box className={classes.tabPanel}>
                        {selectedCohort && (
                            <Tabs value={currentTab} onChange={handleTabChange}>
                                {tabs.map(tab => (
                                    <Tab label={tab.label} key={tab.key} value={tab.key} />
                                ))}
                            </Tabs>
                        )}
                        {currentTab === PublishedReportsTabs.TYPE_ALL_SUBJECTS && (
                            <AllSubjectsReportTab cohort={selectedCohort} />
                        )}
                        {currentTab === PublishedReportsTabs.TYPE_SUMMARY && (
                            <SummaryReportTab cohort={selectedCohort} />
                        )}
                        {currentTab === PublishedReportsTabs.TYPE_SUBJECT && (
                            <SubjectReportTab cohort={selectedCohort} />
                        )}
                    </Box>
                </Box>
            </Paper>
        </AppContainer>
    );
};

export default SnapshotReportsCreateContainer;
