import { FC, ReactNode } from "react";
import {
    Dialog as MuiDialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions as MuiDialogActions,
    DialogProps as MuiDialogProps,
    Theme,
    IconButton,
    Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import { mdiWindowClose } from "@mdi/js";

const useStyles = makeStyles((theme: Theme) => ({
    dialog: {},
    closeButton: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
}));

interface DialogProps {
    children: ReactNode;
    title?: string;
    onClose: () => void;
    actions?: ReactNode;
    underTitle?: ReactNode;
    verticalBackground?: "left" | "right" | undefined;
    verticalBackgroundCols?: number | undefined;
    contentOverflow?: "hidden" | "visible";
    maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined;
}

const Dialog: FC<DialogProps & MuiDialogProps> = ({
    children,
    title,
    actions,
    underTitle,
    onClose,
    verticalBackground = "left",
    verticalBackgroundCols,
    contentOverflow = "hidden",
    maxWidth = "md",
    ...props
}) => {
    const classes = useStyles();

    return (
        <MuiDialog onClose={onClose} maxWidth={maxWidth} {...props}>
            {verticalBackgroundCols && (
                <Box
                    component="span"
                    bgcolor={COLORS.VERY_LIGHT_GREY_4}
                    position="absolute"
                    top={0}
                    right={verticalBackground === "right" ? 0 : "auto"}
                    bottom={0}
                    left={verticalBackground === "left" ? 0 : "auto"}
                    width={`calc(${(100 / 12) * verticalBackgroundCols}% + 45px)`}
                />
            )}
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
                size="small"
            >
                <Icon size="32px" color={COLORS.LIGHT_GREY_1} path={mdiWindowClose} />
            </IconButton>
            {title && <MuiDialogTitle>{title}</MuiDialogTitle>}
            {underTitle && underTitle}
            <MuiDialogContent>
                <Box overflow={contentOverflow}>{children}</Box>
            </MuiDialogContent>
            {actions && <MuiDialogActions>{actions}</MuiDialogActions>}
        </MuiDialog>
    );
};

export default Dialog;
