import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TagModel } from "../model/TagModel";
import { getSchoolAccountId } from "src/services/url";

export const TAG_DETAILS_QUERY = "tagDetailsQuery";

export const useTagDetails = (id: number) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [TAG_DETAILS_QUERY, schoolAccountId, id],
        queryFn: async (): Promise<TagModel | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/tag/${id}/`,
                );

                return data || null;
            }
            return null;
        },
    });
};
