import HeadingCounter from "src/components/HeadingCounter";
import PaperInner from "src/components/PaperInner";
import SpecificationSelectField from "src/forms/SpecificationSelectField";
import AssessmentAccordeonHeader from "../AssessmentAccordeonHeader";
import Icon from "src/components/Icon";
import styled from "@emotion/styled";
import CopyAssessmentForm, { CopyAssessmentFormShape } from "./CopyAssessmentForm";
import AssessmentCreateModal, { AssessmentCreateTypes } from "../AssessmentCreateModal";
import AddPublishedAssessmentForm, {
    PublishedAssessmentFormShape,
    publishedAssessmentsFromSchema,
} from "./AddPublishedAssessmentForm";
import COLORS from "src/styles/colors";
import * as yup from "yup";
import { Box, BoxProps, Button, Divider, Grid, MenuItem, Typography } from "@mui/material";
import AddCustomisedAssessmentForm, {
    CustomisedAssessmentFormShape,
    customisedAssessmentFormSchema,
} from "./AddCustomisedAssessmentForm";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCoreValues } from "src/modules/common/hooks/useCore";
import { SpecificationModel, TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { useCustomisedAssessmentUnits } from "../../hooks/Customised/useUnits";
import { usePublishedAssessmentUnits } from "../../hooks/Published/useUnits";
import { useClassListByCohort } from "src/modules/class/hooks/useClassList";
import { CommonActions } from "src/modules/common/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { OrmTopic } from "src/orm/models/Topic";
import { AppState } from "src/store/reducers";
import { topicsWithFilterSelector } from "src/modules/common/selectors/TopicSelectors";
import { sortByKey } from "src/services/object";
import { gradesSelector } from "src/modules/common/selectors/GradeSelectors";
import { OrmGrade } from "src/orm/models/Grade";
import {
    UncollapsedObject,
    emptyCustomisedAssessmentValues,
    emptyPublishedAssessmentValues,
    getEmptyCopyAssessmentValues,
} from "../../containers/AssessmentCreateContainer";
import { Storage } from "src/services/storage";
import { CustomisedAssessmentUnitModel } from "../../models/AssessmentUnitModel";
import { TFunction } from "i18next";
import { mdiPlus } from "@mdi/js";
import { OrmClassTrackerGroup } from "src/orm/models/ClassTrackerGroup";
import { useTrackerPath } from "src/modules/class/hooks/useTrackerPath";
import PromptDialog from "src/forms/PromptDialog";

interface OutlineSelectionBoxProps extends BoxProps {
    selected?: boolean;
}

const OutlineSelectionBox = styled(Box)<OutlineSelectionBoxProps>(props => ({
    position: "relative",
    "&::before": {
        display: props.selected === true ? "block" : "none",
        content: '""',
        position: "absolute",
        top: -1,
        right: -1,
        bottom: 0,
        left: -1,
        zIndex: 10,
        pointerEvents: "none",
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23979797' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    },
}));

export interface AssessmentCreateFormShape {
    specification: number | null;
    academicYear: number | null;
    yearGroup: number | null;
    publishedAssessments: PublishedAssessmentFormShape[];
    customisedAssessments: CustomisedAssessmentFormShape[];
    copyAssessmentForm: CopyAssessmentFormShape;
}

export const createAssessmentSchema = (t: TFunction) => {
    return yup.object().shape({
        specification: yup.number().required(t("common.form.validation.specification.required")),
        academicYear: yup.number().required(t("common.form.validation.academicYear.required")),
        yearGroup: yup
            .number()
            .required(t("common.form.validation.yearGroup.required"))
            .typeError(t("common.form.validation.yearGroup.required")),
        publishedAssessments: publishedAssessmentsFromSchema(t),
        customisedAssessments: customisedAssessmentFormSchema(t),
    });
};

interface OwnProps {
    canManageAssessments: boolean;
    cohort: number;
    startingType: AssessmentCreateTypes | null;
    uncollapsed: UncollapsedObject;
    handleUncollapsed: (uncollapsed: UncollapsedObject | null) => void;
}

/**
 * 1. select qualification triggers get class list
 * 2. year group changes refetches class list
 * 3. select specification -> resolve tiers
 * 4. when tiers list is resolved -> get units
 * 5. when units & class list present -> render form
 */

const AddAssessmentForm: FC<OwnProps> = ({
    cohort,
    startingType,
    uncollapsed,
    handleUncollapsed,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [warnModalOpened, setWarnModalOpened] = useState<boolean>(false);
    const { data } = useCoreValues();
    const cohorts = data?.cohorts;
    const { values, setFieldValue, validateForm, setErrors, setTouched, touched, errors } =
        useFormikContext<AssessmentCreateFormShape>();
    const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
    const [createNew, setCreateNew] = useState<boolean>(false);
    const [isInCopyMode, setIsInCopyMode] = useState<boolean>(startingType === "copy");
    const [camAddCustomGradeBoundary, setCanAddCustomGradeBoundary] = useState<boolean>(false);
    const [collapseSubmitCount, setCollapseSubmitCount] = useState<number>(0);
    const [filteredYearGroups, setFilteredYearGroups] = useState<number[]>([]);
    const [tiers, setTiers] = useState<TiersTypes[] | null>(null);
    const [caUnits, setCaUnits] = useState<
        { tier: TiersTypes; units: CustomisedAssessmentUnitModel[] }[]
    >([]);
    const [selectedQualification, setSelectedQualification] = useState<number | null>(null);
    const [selectedSubjectArea, setSelectedSubjectArea] = useState<number | null>(null);

    const [paTier, setPaTier] = useState<TiersTypes | null>(null);
    const { data: paUnits, refetch: getPaUnits } = usePublishedAssessmentUnits(
        values.specification,
        paTier,
    );

    const cuUnitsQueries = useCustomisedAssessmentUnits(values.specification, tiers || []);

    const {
        data: classList,
        refetch: getClasses,
        isStale,
    } = useClassListByCohort(cohort, {
        qualificationId: selectedQualification,
        yearGroup: undefined,
        page: 1,
        perPage: 100000,
    });

    const {
        topics,
        grades,
    }: {
        topics: OrmTopic[];
        grades: OrmGrade[];
    } = useSelector((state: AppState) => ({
        topics: topicsWithFilterSelector(
            state,
            t => t.specificationId === values.specification,
        ).sort(sortByKey("lft")),
        grades: gradesSelector(state),
    }));

    const getCurrentWarningsCount = () => {
        return parseInt(Storage.getItem("warnAssessmentModal")) || 0;
    };

    const handleAddToStorage = () => {
        Storage.setItem("addAssessmentForm", convertAssessmentToStorage());
    };

    const handleCreateNew = (type: AssessmentCreateTypes) => {
        if (type === "pp") {
            setFieldValue(
                "publishedAssessments",
                values.publishedAssessments.concat({ ...emptyPublishedAssessmentValues }),
            );
        } else if (type === "eott") {
            setFieldValue(
                "customisedAssessments",
                values.customisedAssessments.concat({ ...emptyCustomisedAssessmentValues }),
            );
        } else {
            setIsInCopyMode(true);
        }
        handleUncollapsed({
            type,
            index:
                type === "pp"
                    ? values.publishedAssessments.length
                    : values.customisedAssessments.length,
        });
    };

    const getCaUnits = caUnits => {
        const flatCaUnits = [];
        caUnits.forEach(ca => {
            ca.units?.forEach(u => {
                flatCaUnits.push(u);
            });
        });
        return flatCaUnits;
    };

    const handleDuplicate = (type: AssessmentCreateTypes, index: number) => {
        const current = Storage.getItem("addAssessmentForm");
        const newCas = [...values.customisedAssessments];
        const newPPs = [...values.publishedAssessments];

        if (type === "pp") {
            const element = values.publishedAssessments[index];
            newPPs.push(element);
            setFieldValue(`publishedAssessments`, newPPs);
            Storage.setItem("addAssessmentForm", {
                ...current,
                publishedAssessments: newPPs.map(pp => ({
                    ...pp,
                    saveAndCollapse: true,
                })),
            });
        }
        if (type === "eott") {
            const element = values.customisedAssessments[index];
            newCas.push(element);
            setFieldValue(`customisedAssessments`, newCas);
            Storage.setItem("addAssessmentForm", {
                ...current,
                customisedAssessments: newCas.map(ca => ({
                    ...ca,
                    saveAndCollapse: true,
                })),
            });
        }
        if (getCurrentWarningsCount() <= 3) {
            setWarnModalOpened(true);
        }
    };

    const handleRemove = (type: AssessmentCreateTypes, index: number) => {
        const current = Storage.getItem("addAssessmentForm");
        if (type === "pp") {
            const newPublished = values.publishedAssessments.filter((_, i) => i !== index);
            setFieldValue("publishedAssessments", newPublished);
            Storage.setItem("addAssessmentForm", {
                ...current,
                publishedAssessments: newPublished,
            });
        }
        if (type === "eott") {
            const newEott = values.customisedAssessments.filter((_, i) => i !== index);
            setFieldValue("customisedAssessments", newEott);
            Storage.setItem("addAssessmentForm", {
                ...current,
                customisedAssessments: newEott,
            });
        }
        handleUncollapsed(null);
        if (getCurrentWarningsCount() <= 3) {
            setWarnModalOpened(true);
        }
    };

    const convertAssessmentToStorage = useCallback(() => {
        return {
            ...values,
            publishedAssessments: values.publishedAssessments.map(pa => ({
                ...pa,
                savedAndCollapsed: true,
                unitName:
                    pa.unitName ||
                    paUnits?.find(pau => parseInt(`${pau.id}`) === parseInt(`${pa.unit}`))?.name,
            })),
            customisedAssessments: values.customisedAssessments.map(ca => ({
                ...ca,
                savedAndCollapsed: true,
                tierUnits: ca.tierUnits.map(tu => ({
                    ...tu,
                    unitName:
                        tu.unitName ||
                        caUnits
                            ?.find(cau => cau.tier === tu.tier)
                            ?.units.find(u => u.id === tu.unit)?.name ||
                        null,
                })),
            })),
        };
    }, [values, paUnits, caUnits]);

    const specificationLocked =
        values.customisedAssessments.find(ca => ca.savedAndCollapsed) ||
        values.publishedAssessments.find(ca => ca.savedAndCollapsed);

    const { data: trackerPath } = useTrackerPath(values.specification);

    useEffect(() => {
        if (values.specification && paTier && uncollapsed?.type === "pp") {
            getPaUnits();
        }
    }, [values.specification, paTier, uncollapsed]);

    useEffect(() => {
        if (tiers && uncollapsed?.type === "eott") {
            tiers.forEach((tier, i) => {
                cuUnitsQueries[i].refetch();
            });
        }
    }, [values.specification, tiers, uncollapsed]);

    useEffect(() => {
        if (
            uncollapsed &&
            uncollapsed.type === "pp" &&
            !paTier &&
            values.publishedAssessments[uncollapsed.index]?.tier
        ) {
            setPaTier(values.publishedAssessments[uncollapsed.index]?.tier);
        }
    }, [uncollapsed]);

    useEffect(() => {
        if (
            cuUnitsQueries?.length === tiers?.length &&
            cuUnitsQueries?.filter(cuq => cuq.isSuccess).length === tiers?.length
        ) {
            const newCaUnits = [];
            tiers.forEach((tier, i) => {
                newCaUnits.push({ tier: tier, units: cuUnitsQueries[i].data });
            });
            if (JSON.stringify(caUnits) !== JSON.stringify(newCaUnits)) {
                setCaUnits(newCaUnits);
            }
        }
    }, [cuUnitsQueries, tiers]);

    // qualification changes => refetch classes

    useEffect(() => {
        if (selectedQualification) {
            getClasses();
        }
    }, [selectedQualification]);

    useEffect(() => {
        if (classList?.data) {
            const filteredYgs = classList?.data
                ?.reduce((yearGroups: number[], singleClass: OrmClassTrackerGroup) => {
                    if (
                        singleClass?.specification?.id === values.specification &&
                        !yearGroups.find(yg => yg === singleClass.yearGroup)
                    ) {
                        return yearGroups.concat(singleClass.yearGroup);
                    }
                    return yearGroups;
                }, [])
                .sort((a, b) => a - b);

            setFilteredYearGroups(filteredYgs);

            if (!filteredYgs.includes(values.yearGroup) && filteredYgs.length > 0) {
                setFieldValue("yearGroup", filteredYgs[0]);
            }
        }
    }, [classList?.data, values.specification, isStale]);

    const resetAssessmentListElements = () => {
        if (
            values.customisedAssessments.length === 1 &&
            !values.customisedAssessments[0].savedAndCollapsed
        ) {
            setFieldValue("customisedAssessments", [{ ...emptyCustomisedAssessmentValues }]);
            setTouched({});
        }
        if (
            values.publishedAssessments.length === 1 &&
            !values.publishedAssessments[0].savedAndCollapsed
        ) {
            setFieldValue("publishedAssessments", [{ ...emptyPublishedAssessmentValues }]);
            setTouched({});
        }
    };

    return (
        <>
            <PaperInner>
                <HeadingCounter number="1">
                    {t("class.assessment.add.targetTracker")}
                </HeadingCounter>
                <Typography component="p" variant="overline" sx={{ mb: 3 }}>
                    {t("class.assessment.add.targetTrackerHint")}
                </Typography>
                <Box mb={4}>
                    <Field
                        locked={specificationLocked}
                        name={`specification`}
                        component={SpecificationSelectField}
                        allowedQualificationsOnly={true}
                        allowedOnly={true}
                        showWithClassesOnly={true}
                        cohort={cohort}
                        trackerPath={trackerPath}
                        handleSubjectAreaChange={(subjectArea: number) => {
                            setSelectedSubjectArea(subjectArea);
                        }}
                        handleQualificationChange={(qualification: number) => {
                            setSelectedQualification(qualification);
                            resetAssessmentListElements();
                            setFilteredYearGroups([]);
                            setCaUnits([]);
                        }}
                        handleSpecificationChange={(specification: SpecificationModel) => {
                            if (specification) {
                                setCaUnits([]);
                                if (specification?.tiers.length > 0) {
                                    setTiers(specification.tiers);
                                } else {
                                    setTiers(["-"]);
                                }

                                setCanAddCustomGradeBoundary(
                                    specification?.canAddCustomGradeBoundary,
                                );
                                dispatch(
                                    CommonActions.getTopicList(
                                        specification?.id || (specification as any),
                                    ),
                                ); // TODO: orm remove
                                dispatch(
                                    CommonActions.getGradeList(
                                        specification?.id || (specification as any),
                                    ),
                                ); // TODO: orm remove
                            }
                        }}
                    />
                </Box>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <Field
                            name="academicYear"
                            label={t("class.assessment.add.academicYear")}
                            component={TextField}
                            select
                            disabled
                        >
                            {cohorts?.map(cohort => (
                                <MenuItem key={cohort.id} value={cohort.id}>
                                    {cohort.name}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>

                    {values.specification && (
                        <Grid item sm={4}>
                            <Field
                                name="yearGroup"
                                label={t("class.assessment.add.yearGroup")}
                                component={TextField}
                                select
                                disabled={specificationLocked}
                            >
                                {filteredYearGroups.map(year => (
                                    <MenuItem key={year} value={year}>
                                        {year === 0 ? t("common.yg0") : year}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    )}
                </Grid>
            </PaperInner>
            <Divider sx={{ m: 0 }} />
            {values.customisedAssessments.map((customisedAssessment, cI) => {
                return (
                    <OutlineSelectionBox
                        key={cI}
                        selected={uncollapsed?.type === "eott" && uncollapsed?.index === cI}
                    >
                        <AssessmentAccordeonHeader
                            index={cI}
                            assessment={customisedAssessment}
                            type={"eott"}
                            handleEdit={(type, index) => handleUncollapsed({ type, index })}
                            collapsed={
                                isInCopyMode ||
                                !(uncollapsed?.type === "eott" && uncollapsed?.index === cI)
                            }
                            handleRemove={handleRemove}
                            handleDuplicate={handleDuplicate}
                            units={getCaUnits(caUnits)}
                        />

                        {uncollapsed?.type === "eott" && uncollapsed?.index === cI && (
                            <>
                                <PaperInner>
                                    <AddCustomisedAssessmentForm
                                        index={cI}
                                        tiers={tiers}
                                        classList={classList?.data}
                                        grades={grades}
                                        topics={topics}
                                        caUnits={caUnits}
                                        canAddCustomGradeBoundary={camAddCustomGradeBoundary}
                                    />
                                </PaperInner>
                                {customisedAssessment.visibleClassTrackers.filter(
                                    vct => vct.visible,
                                ).length > 0 || customisedAssessment.hideForNow ? (
                                    <PaperInner
                                        border="bottom"
                                        color="lightGrey"
                                        variant="smallAction"
                                    >
                                        <Button
                                            sx={{ marginTop: 4 }}
                                            onClick={() => {
                                                validateForm(values).then(err => {
                                                    if (
                                                        !err?.customisedAssessments?.[cI] ||
                                                        Object.keys(err).length === 0
                                                    ) {
                                                        setFieldValue(
                                                            `customisedAssessments.${cI}.savedAndCollapsed`,
                                                            true,
                                                        );
                                                        if (err?.customisedAssessments?.[cI + 1]) {
                                                            handleUncollapsed({
                                                                type: "eott",
                                                                index: cI + 1,
                                                            });
                                                        } else {
                                                            handleUncollapsed(null);
                                                        }

                                                        handleAddToStorage();
                                                        setCollapseSubmitCount(0);
                                                        if (getCurrentWarningsCount() <= 3) {
                                                            setWarnModalOpened(true);
                                                        }
                                                    } else {
                                                        setErrors(err);
                                                        setTouched({ ...touched, ...err } as any);
                                                        setCollapseSubmitCount(
                                                            collapseSubmitCount + 1,
                                                        );
                                                    }
                                                });
                                            }}
                                        >
                                            {t("class.assessment.add.saveAndCollapse")}
                                        </Button>
                                        {errors &&
                                            Object.keys(errors).length > 0 &&
                                            collapseSubmitCount > 0 && (
                                                <Box style={{ marginTop: 10 }} color={COLORS.RED_1}>
                                                    {t("common.genericValidation")}
                                                </Box>
                                            )}
                                    </PaperInner>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </OutlineSelectionBox>
                );
            })}
            {values.publishedAssessments.map((publishedAssessment, pI) => {
                return (
                    <OutlineSelectionBox
                        key={pI}
                        selected={uncollapsed?.type === "pp" && uncollapsed?.index === pI}
                    >
                        <AssessmentAccordeonHeader
                            index={pI}
                            assessment={publishedAssessment}
                            type={"pp"}
                            collapsed={
                                isInCopyMode ||
                                !(uncollapsed?.type === "pp" && uncollapsed?.index === pI)
                            }
                            handleRemove={handleRemove}
                            handleDuplicate={handleDuplicate}
                            handleEdit={(type, index) => handleUncollapsed({ type, index })}
                            units={paUnits}
                        />
                        {uncollapsed?.type === "pp" && uncollapsed?.index === pI && (
                            <>
                                <PaperInner>
                                    <AddPublishedAssessmentForm
                                        index={pI}
                                        tiers={tiers}
                                        classList={classList?.data}
                                        units={paUnits}
                                        handleTierChange={tier => setPaTier(tier)}
                                    />
                                </PaperInner>
                                {publishedAssessment.visibleClassTrackers.filter(vct => vct.visible)
                                    .length > 0 || publishedAssessment.hideForNow ? (
                                    <PaperInner
                                        border="bottom"
                                        color="lightGrey"
                                        variant="smallAction"
                                    >
                                        <Button
                                            sx={{ marginTop: 4 }}
                                            onClick={() => {
                                                validateForm(values).then(err => {
                                                    if (Object.keys(err).length === 0) {
                                                        setFieldValue(
                                                            `publishedAssessments.${pI}.savedAndCollapsed`,
                                                            true,
                                                        );
                                                        handleUncollapsed(null);
                                                        handleAddToStorage();
                                                        setCollapseSubmitCount(0);
                                                        if (getCurrentWarningsCount() <= 3) {
                                                            setWarnModalOpened(true);
                                                        }
                                                    } else {
                                                        setErrors(err);
                                                        setTouched({ ...touched, ...err } as any);
                                                        setCollapseSubmitCount(
                                                            collapseSubmitCount + 1,
                                                        );
                                                    }
                                                });
                                            }}
                                        >
                                            {t("class.assessment.add.saveAndCollapse")}
                                        </Button>
                                        {errors &&
                                            Object.keys(errors).length > 0 &&
                                            collapseSubmitCount > 0 && (
                                                <Box style={{ marginTop: 10 }} color={COLORS.RED_1}>
                                                    {t("common.genericValidation")}
                                                </Box>
                                            )}
                                    </PaperInner>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </OutlineSelectionBox>
                );
            })}
            {isInCopyMode ? (
                <>
                    <AssessmentAccordeonHeader
                        index={null}
                        assessment={null}
                        type={"copy"}
                        collapsed={!isInCopyMode}
                        handleRemove={() => {
                            handleUncollapsed(null);
                            setIsInCopyMode(false);
                        }}
                    />

                    {values.specification ? (
                        <CopyAssessmentForm
                            trackerPath={trackerPath}
                            cohort={cohort}
                            initialSpecification={values.specification}
                            initialQualification={selectedQualification}
                            initialSubjectArea={selectedSubjectArea}
                            handleClose={(type: AssessmentCreateTypes) => {
                                handleUncollapsed({
                                    type,
                                    index:
                                        type === "eott"
                                            ? values.customisedAssessments.length
                                            : values.publishedAssessments.length,
                                });
                                setIsInCopyMode(false);
                                setFieldValue(
                                    "copyAssessmentForm",
                                    getEmptyCopyAssessmentValues(`${cohort}`),
                                );
                            }}
                        />
                    ) : (
                        <Box p={6}>{t("class.assessment.add.selectSpecification")}</Box>
                    )}
                </>
            ) : (
                <></>
            )}
            {uncollapsed === null && (
                <Box pl={6} pb={4}>
                    <Button
                        sx={{ marginTop: 4 }}
                        onClick={() => {
                            setCreateModalOpen(true);
                            setCreateNew(true);
                        }}
                        variant="text"
                        startIcon={<Icon path={mdiPlus} />}
                        disableRipple
                    >
                        {t("class.assessment.add.addAssessment")}
                    </Button>
                </Box>
            )}
            <AssessmentCreateModal
                open={createModalOpen}
                handleClose={(confirm, selectedType) => {
                    if (confirm && createNew) {
                        handleCreateNew(selectedType);
                    }
                    if (createNew) setCreateNew(false);

                    setCreateModalOpen(false);
                }}
            />
            <PromptDialog
                open={warnModalOpened}
                yesLabel={t("common.ok")}
                noLabel=""
                onClose={() => {
                    setWarnModalOpened(false);
                    Storage.setItem(
                        "warnAssessmentModal",
                        parseInt(Storage.getItem("warnAssessmentModal") || "0") + 1,
                    );
                }}
            >
                {t("class.assessment.add.saveAssessmentsWarning")}
            </PromptDialog>
        </>
    );
};

export default AddAssessmentForm;
