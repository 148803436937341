import { ofType } from "@martin_hotell/rex-tils";
import { ActionsObservable, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AnalysisActionTypes, AnalysisActions } from "../actions";

const getAnalysisGradeSummaryRelatedSuccessEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_RELATED_SUCCESS),
        map(
            (action: ReturnType<typeof AnalysisActions.getAnalysisRelatedSuccess>) =>
                action.payload,
        ),
        mergeMap(payload => of(AnalysisActions.setAnalysisRelated(payload.response))),
    );

const getAnalysisStudentsForClassTrackerEpic = (action$: ActionsObservable<AnalysisActions>) =>
    action$.pipe(
        ofType(AnalysisActionTypes.GET_ANALYSIS_STUDENTS_FOR_CLASS_TRACKER_SUCCESS),
        map(
            (
                action: ReturnType<
                    typeof AnalysisActions.getAnalysisStudentsForClassTrackerSuccess
                >,
            ) => action.payload,
        ),
        mergeMap(payload =>
            of(AnalysisActions.setAnalysisStudentsForClassTracker(payload.response)),
        ),
    );

export const analysisCommonEpics = combineEpics(
    getAnalysisGradeSummaryRelatedSuccessEpic,
    getAnalysisStudentsForClassTrackerEpic,
);
