/* eslint-disable react/display-name */
import { mdiChevronDownBox, mdiChevronUpBox } from "@mdi/js";
import { darken } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { forwardRef, useImperativeHandle, useRef } from "react";
import Icon from "src/components/Icon";
import { NAV_KEYS } from "src/modules/analysis/services/analysisGridNavigation";
import COLORS from "src/styles/colors";

interface OwnProps {
    value: any;
    data: { id: number; [key: string]: any };
    columnColour?: string;
    colDef: any;
    api?: GridApi;
}

const DefaultValueEditor = forwardRef(
    ({ value, data, columnColour, colDef, api: gridApi }: OwnProps, ref) => {
        const textInput = useRef(null);

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (NAV_KEYS.includes(e.key)) {
                e.preventDefault();
                gridApi?.stopEditing(true);
            } else {
                const currKey = e.key;
                const isNumeric = (key: string) => /^[0-9]*\.?[0-9]*$/.test(key);
                if (!(isNumeric(currKey) || currKey === "Backspace")) e.preventDefault();
            }
        };
        const changeValueByQuater = (add?: boolean) => {
            let nevValue = 0;

            if (textInput.current.value) {
                const value = parseFloat(textInput.current.value);
                const quaters = value * 4;
                if (quaters % 1 && !add) {
                    nevValue = Math.floor(quaters) / 4;
                } else {
                    nevValue = Math.floor(quaters + (add ? 1 : -1)) / 4;
                }
                nevValue = Math.min(9, Math.max(0, nevValue));
            }
            textInput.current.value = nevValue;
        };

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    const field = colDef.field;
                    const isEditable = Object.keys(data).includes(field);

                    if (!isEditable) return undefined;
                    const value = parseFloat(textInput.current.value);
                    if (isNaN(value)) {
                        return null;
                    }

                    return value > 9 ? "9.00" : `${value.toFixed(2)}`;
                },
            };
        });

        const backgroundColor = columnColour;
        const field = colDef?.field;
        const isEditable = Object.keys(data).includes(field);

        return (
            <div
                className="ag-editor-cell"
                style={{
                    cursor: !isEditable ? "not-allowed" : "default",
                    backgroundColor:
                        (isEditable
                            ? backgroundColor
                            : darken(
                                  backgroundColor ? backgroundColor : COLORS.VERY_LIGHT_GREY_8,
                                  0.02,
                              )) || COLORS.VERY_LIGHT_GREY_8,
                }}
            >
                <input
                    style={{
                        cursor: !isEditable ? "not-allowed" : "default",
                    }}
                    className="ag-editor-cell__input"
                    onKeyDown={handleKeyDown}
                    disabled={!isEditable}
                    ref={textInput}
                    defaultValue={value}
                    type="text"
                />
                {isEditable && (
                    <div className="ag-editor-cell__actions">
                        <button onClick={() => changeValueByQuater(true)}>
                            <Icon path={mdiChevronUpBox} />
                        </button>
                        <button onClick={() => changeValueByQuater()}>
                            <Icon path={mdiChevronDownBox} />
                        </button>
                    </div>
                )}
            </div>
        );
    },
);
export default DefaultValueEditor;
