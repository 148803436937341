import COLORS from "src/styles/colors";
import { Box } from "@mui/material";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const GenericErrorMessage = ({ errors, submitCount }: { errors; submitCount: number }) => {
    const { t }: { t: TFunction } = useTranslation();
    return submitCount > 0 && errors && Object.keys(errors).length > 0 ? (
        <Box style={{ marginTop: 10 }} color={COLORS.RED_1} id="validationErrorMessage">
            {t("common.genericValidation")}
        </Box>
    ) : null;
};

export default GenericErrorMessage;
