import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { TiersTypes } from "src/modules/tracker/models/SpecificationModel";
import { ClassTrackerHeaderObject } from "src/modules/tracker/dto/TrackerHeader";
import { getSchoolAccountId } from "src/services/url";

export const ARCHIVED_TRACKER_HEADER_QUERY = "archivedTrackerHeaderQuery";

export const useArchivedTrackerHeader = (id: number, tier: TiersTypes) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [ARCHIVED_TRACKER_HEADER_QUERY, schoolAccountId, id, tier],
        queryFn: async (): Promise<ClassTrackerHeaderObject | null> => {
            if (id) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/archive/class-tracker/${id}/${tier}/header/`,
                );

                return data;
            }
            return null;
        },
        enabled: false,
    });
};
