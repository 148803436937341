import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { ListObject } from "src/forms/types";
import { getSchoolAccountId } from "src/services/url";

export const CLASS_TRACKER_GROUP_PIXL_SERIES_LIST_QUERY = "classTrackerGroupPixlSeriesListQuery";

export const useClassTrackerGroupPixlSeriesList = (classTrackerGroupId: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [
            CLASS_TRACKER_GROUP_PIXL_SERIES_LIST_QUERY,
            schoolAccountId,
            classTrackerGroupId,
        ],
        queryFn: async (): Promise<ListObject[] | null> => {
            if (classTrackerGroupId) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/class-tracker-group/${classTrackerGroupId}/pixl-waves-series/`,
                );

                return data;
            }
            return null;
        },
    });
};
