/* eslint-disable react/display-name */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import { FormControl, MenuItem } from "@mui/material";
import { usePrevious } from "src/hooks/usePrevious";
import { StyledCustomInput, StyledCustomSelect } from "./StyledCustomEditors";
import { ListObject } from "src/forms/types";

interface OwnProps {
    value: string;
    api: GridApi;
    data: { id: number };
    classTrackers: ListObject<number>[];
    context: any;
}

const MoveStudentsEditor = forwardRef(
    ({ value: propsValue, context, data, api: gridApi, classTrackers }: OwnProps, ref) => {
        const dropdownRef = useRef() as any;
        const inputRef = useRef() as any;
        const [value, setValue] = useState(propsValue || "");
        const [open, setOpen] = useState<boolean>(true);

        const handleChange = (value: string) => {
            if (context && context.handleMoveStudentTo) {
                context.handleMoveStudentTo(data.id, parseInt(value));
            }
            setValue(value);
            setOpen(false);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleOpen = () => {
            setOpen(true);
        };

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    return value;
                },
            };
        });

        const prevValue = usePrevious(value);

        useEffect(() => {
            if (prevValue !== undefined && prevValue !== value) {
                gridApi.stopEditing();
            }
            //eslint-disable-next-line
        }, [value]);

        const isDemo =
            data["student_uniquePupilNumber"] &&
            data["student_uniquePupilNumber"].indexOf("PPDEMO") === 0;

        return (
            <div className="ag-editor-cell ag-editor-cell--student">
                {context.moveStudentsBulkEditMode ? (
                    <input
                        ref={inputRef}
                        disabled={isDemo}
                        type="text"
                        className="ag-editor-cell__input ag-editor-cell__input--left"
                        value={value || ""}
                        onChange={e => setValue(e.target.value)}
                    />
                ) : (
                    <FormControl margin="none">
                        <StyledCustomSelect
                            ref={dropdownRef}
                            value={value || ""}
                            onChange={e => handleChange(e.target.value as string)}
                            input={<StyledCustomInput />}
                            open={open}
                            onClose={handleClose}
                            disabled={isDemo}
                            onOpen={isDemo ? () => {} : handleOpen}
                            fullWidth
                        >
                            <MenuItem value="0">Please select</MenuItem>
                            {!isDemo &&
                                classTrackers
                                    .filter(ct => !ct["isDemo"])
                                    .map(ct => (
                                        <MenuItem key={ct.id} value={ct.id}>
                                            {ct.name}
                                        </MenuItem>
                                    ))}
                        </StyledCustomSelect>
                    </FormControl>
                )}
            </div>
        );
    },
);

export default MoveStudentsEditor;
