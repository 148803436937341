import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const SCHOOL_DETAILS_QUERY = "schoolDetailsQuery";

export interface SchoolDetailsResponse {
    id: number;
    name: string;
    code: string;
    address1: string;
    address2: string;
    postcode: string;
    city: string;
    country: {
        id: string;
        name: string;
    };
    logoUrl: string;
    privacyPolicyUrl?: string | null;
    fineGrades?: boolean;
    enableNaht: boolean;
    contactEmail?: string | null;
    yearGroupsWithLockedMtg?: number[];
}

export const useSchoolDetails = (schoolAccount?: number) => {
    const schoolAccountId = schoolAccount || getSchoolAccountId() || null;

    return useQuery({
        queryKey: [SCHOOL_DETAILS_QUERY, schoolAccountId],
        queryFn: async (): Promise<SchoolDetailsResponse | null> => {
            if (schoolAccountId) {
                const { data } = await axiosInstance.get(`school/${schoolAccountId}/account/`);
                return data || null;
            }
            return null;
        },
    });
};
