import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { OrmSchoolCustomField } from "src/orm/models/SchoolCustomField";
import { getSchoolAccountId } from "src/services/url";

export const CUSTOM_FIELDS_LIST_QUERY = "customFieldsListQuery";

export type CustomFieldByCohortList = OrmSchoolCustomField[];

export const useCustomFieldsList = (cohort: number) => {
    const schoolAccountId = getSchoolAccountId();

    return useQuery({
        queryKey: [CUSTOM_FIELDS_LIST_QUERY, schoolAccountId, cohort],
        queryFn: async (): Promise<CustomFieldByCohortList | null> => {
            if (cohort) {
                const { data } = await axiosInstance.get(
                    `school/${getSchoolAccountId()}/school-custom-fields/${cohort}/`,
                );
                return data || null;
            }
            return null;
        },
    });
};
