import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { getSchoolAccountId } from "src/services/url";

export const createPaymentIntentEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/subscription-history/{id}/create-payment-intent/`,
    method: ApiRequests.POST,
};

export interface CreatePaymentIntentResponse {
    id: string;
    object: string;
    amount: number;
    amount_capturable: number;
    amount_received: number;
    application: string;
    application_fee_amount: number;
    canceled_at: number;
    cancellation_reason: string;
    capture_method: string;
    charges: {};
    client_secret: string;
    confirmation_method: string;
    created: number;
    currency: string;
    customer: string;
    description: string;
    invoice: string;
    last_payment_error: {};
    livemode: boolean;
    metadata: {};
    next_action: string;
    on_behalf_of: string;
    payment_method: string;
    payment_method_options: {};
    payment_method_types: string[];
    receipt_email: string;
    review: string;
    setup_future_usage: string;
    shipping: string;
    source: string;
    statement_descriptor: string;
    statement_descriptor_suffix: string;
    status: string;
    transfer_data: string;
    transfer_group: string;
}
