import { useNavigate } from "react-router";
import { getSchoolAccountId, getTrustAccountId } from "src/services/url";

export const useSchoolNavigate = () => {
    const routerNavigate = useNavigate();

    const navigate = (path: { pathname: string; search: string } | string | number) => {
        if (typeof path === "object" && path?.pathname) {
            routerNavigate({
                pathname: path.pathname.replace(":schoolAccountId", getSchoolAccountId()),
                search: path?.search,
            });
        }
        if (path === 0) {
            routerNavigate(0);
        }
        if (typeof path === "string") {
            routerNavigate(path.replace(":schoolAccountId", getSchoolAccountId()));
        }
    };

    const attachSchoolId = (url: string) => {
        return url.replace(":schoolAccountId", getSchoolAccountId());
    };

    const attachTrustId = (url: string) => {
        return url.replace(":trust", getTrustAccountId());
    };

    return { navigate, attachSchoolId, attachTrustId };
};
