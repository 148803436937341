import { Box, Button, Typography } from "@mui/material";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import AppContainer from "src/components/AppContainer";
import { ROUTE_REPORT_LINKS_LIST } from "src/routes";
import { LinkCreateRequest, useCreateLinkMutation } from "../hooks/useCreateMutation";
import FormikRef from "src/forms/FormikRef";
import LinkAddEditForm, {
    LinkAddEditFormShape,
    addEditLinkSchema,
    getEmptyLinkForm,
} from "../components/forms/LinkAddEditForm";
import PaperInner from "src/components/PaperInner";
import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import { useAccountInfo } from "src/modules/common/hooks/useAccountInfo";
import { useEditLinkMutation } from "../hooks/useEditMutation";
import { useLinkDetails } from "../hooks/useLinkDetails";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";

const LinkUpsertContainer = () => {
    const { t } = useTranslation();
    const { id: link } = useParams();
    const linkId = link ? parseInt(link) : 0;
    const form = useRef();
    const { navigate } = useSchoolNavigate();
    const { data: accountInfo } = useAccountInfo();

    const handleErrorResponse = useCallback(
        errors => {
            const currentForm = form.current as any;
            currentForm.setSubmitting(false);
            if (errors) currentForm.setErrors(errors);
        },
        [form],
    );

    const { mutate: addLink } = useCreateLinkMutation(
        () => navigate(ROUTE_REPORT_LINKS_LIST),
        ({ errors }) => handleErrorResponse(errors),
    );
    const { mutate: editLink } = useEditLinkMutation(
        () => navigate(ROUTE_REPORT_LINKS_LIST),
        ({ errors }) => handleErrorResponse(errors),
    );

    const { data: linkDetails } = useLinkDetails(linkId);

    const initialValues = linkId
        ? {
              ...getEmptyLinkForm(accountInfo?.activeCohortId || 0),
              ...linkDetails,
              specification: linkDetails?.specification?.id,
              academicYear: linkDetails?.academicYear?.id,
              status: linkDetails?.active ? "active" : "inactive",
          }
        : getEmptyLinkForm(accountInfo?.activeCohortId || 0);
    const handleSubmit = ({
        specification,
        academicYear,
        status,
        ...restValues
    }: LinkAddEditFormShape) => {
        const data: LinkCreateRequest = {
            ...restValues,
            specification: { id: specification },
            academicYear: { id: academicYear },
            active: status === "active",
        };
        if (linkId) {
            editLink({ id: linkId, ...data });
        } else {
            addLink(data);
        }
    };

    return (
        <AppContainer>
            <Typography variant="h1" component="h1" gutterBottom>
                {linkId ? t("link.addEdit.headerEdit") : t("link.addEdit.headerAdd")}
            </Typography>
            <FormikRef
                ref={form}
                initialValues={initialValues}
                validationSchema={() => addEditLinkSchema(t)}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {({ submitForm, errors, submitCount, isSubmitting }) => (
                    <>
                        <LinkAddEditForm linkDetails={linkDetails} />
                        <PaperInner border="top">
                            <Box mt={4}>
                                <Button
                                    onClick={submitForm}
                                    color="primary"
                                    disabled={isSubmitting}
                                    id="AddEditButton"
                                >
                                    {linkId
                                        ? t("link.addEdit.headerEdit")
                                        : t("link.addEdit.headerAdd")}
                                </Button>
                                <GenericErrorMessage errors={errors} submitCount={submitCount} />
                            </Box>
                        </PaperInner>
                    </>
                )}
            </FormikRef>
        </AppContainer>
    );
};
export default LinkUpsertContainer;
