// import all epics from modules
import { apiEpics } from "../api/epics";
import { commonEpics } from "../modules/common/store/epics";
import { userEpics } from "../modules/user/store/epics";
import { classEpics } from "../modules/class/store/epics";
import { schoolEpics } from "../modules/school/store/epics";
import { subscriptionsEpics } from "../modules/subscription/store/epics";
import { trackerEpics } from "../modules/tracker/store/epics";
import { combineEpics } from "redux-observable";
import { subjectAreaEpics } from "src/modules/common/store/SubjectArea/epics";
import { subjectAreaEpics as schoolSubjectAreaEpics } from "src/modules/school/store/SubjectArea/epics";
import { analysisEpics } from "src/modules/analysis/store/epics";
import { reportEpics } from "src/modules/report/store/epics";

export const rootEpic = combineEpics(
    apiEpics,
    commonEpics,
    userEpics,
    classEpics,
    schoolEpics,
    subscriptionsEpics,
    trackerEpics,
    subjectAreaEpics,
    schoolSubjectAreaEpics,
    analysisEpics,
    reportEpics,
);
