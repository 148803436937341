import GenericErrorMessage from "src/modules/common/components/GenericErrorMessage";
import PaperInner from "src/components/PaperInner";
import { FC, useEffect } from "react";
import {
    Box,
    Button,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Form, Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { AutocompleteField } from "src/forms/Autocomplete";
import { Link } from "react-router-dom";
import { ROUTE_MY_SCHOOL_STUDENT_EDIT } from "src/routes";
import { ParentTitles, ParentTitlesType, parentTitlesList } from "../model/ParentModel";
import { useSchoolNavigate } from "src/modules/common/hooks/useSchoolNavigate";
import { getSchoolAccountId } from "src/services/url";
import * as yup from "yup";

export interface ParentUserAddEditFormFields {
    id?: number;
    title: ParentTitlesType;
    firstName: string;
    lastName: string;
    email: string;
    students: any[];
    tmpStudentsAutocomplete: any;
    tmpParentsAutocomplete: any;
}
export const emptyParentForm: ParentUserAddEditFormFields = {
    firstName: "",
    lastName: "",
    title: ParentTitles.MR,
    email: "",
    students: [],
    tmpStudentsAutocomplete: "",
    tmpParentsAutocomplete: "",
};

interface OwnProps {
    isLinked: boolean;
    hasGroupCallSync: boolean;
}

export const addEditParentUserSchema = (t: TFunction) => {
    return yup.object().shape({
        firstName: yup
            .string()
            .required(t("parent.addEdit.firstNameReq"))
            .label(t("parent.addEdit.firstName")),
        lastName: yup
            .string()
            .required(t("parent.addEdit.lastNameReq"))
            .label(t("parent.addEdit.lastName")),
        email: yup
            .string()
            .email()
            .required(t("parent.addEdit.emailReq"))
            .label(t("parent.addEdit.email")),
    });
};

const ParentAddEditForm: FC<OwnProps> = ({ isLinked, hasGroupCallSync }) => {
    const { values, setFieldValue, isSubmitting, errors, submitCount, submitForm } =
        useFormikContext<ParentUserAddEditFormFields>();
    const { t } = useTranslation();
    const { attachSchoolId } = useSchoolNavigate();

    useEffect(() => {
        if (values.tmpStudentsAutocomplete !== "") {
            setFieldValue("students", [...values.students, values.tmpStudentsAutocomplete]);
            setFieldValue("tmpStudentsAutocomplete", "");
        }
    }, [values.students, values.tmpStudentsAutocomplete]);

    return (
        <Form>
            <PaperInner border="bottom">
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <Field
                            name="title"
                            label={t("parent.addEdit.title")}
                            component={TextField}
                            select
                            disabled={isLinked}
                        >
                            {parentTitlesList.map(title => (
                                <MenuItem key={title} value={title}>
                                    {title}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>
                    <Grid item sm={4}>
                        <Field
                            name="firstName"
                            label={t("parent.addEdit.firstName")}
                            component={TextField}
                            disabled={isLinked}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Field
                            name="lastName"
                            label={t("parent.addEdit.lastName")}
                            component={TextField}
                            disabled={isLinked}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Field
                            name="email"
                            label={t("parent.addEdit.email")}
                            component={TextField}
                            disabled={isLinked}
                        />
                    </Grid>
                </Grid>
            </PaperInner>
            <PaperInner border="bottom">
                {values?.id ? (
                    <Grid container>
                        <Grid item sm={4}>
                            <Field
                                apiPathName={`school/${getSchoolAccountId()}/search/students`} // to refactor
                                name="tmpStudentsAutocomplete"
                                placeholder={t("parent.addEdit.searchStudentAutoPlaceholder")}
                                label={t("parent.addEdit.searchStudentAutoPlaceholder")}
                                helperText={t(
                                    "parent.addEdit.searchStudentAutoPlaceholderHelperText",
                                )}
                                component={AutocompleteField}
                                multiple={false}
                                resultCallback={results => {
                                    return results.map(val => ({
                                        ...val,
                                        name: `${val.firstName} ${val.lastName}`,
                                        displayName: `${val.firstName} ${val.lastName} (${val.uniquePupilNumber})`,
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            {values.students && values.students.length > 0 ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t("parent.addEdit.studentName")}</TableCell>
                                            <TableCell>{t("parent.addEdit.studentUpn")}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(values.students as any[]).map(s => (
                                            <TableRow key={s.id}>
                                                <TableCell>
                                                    <Link
                                                        to={attachSchoolId(
                                                            ROUTE_MY_SCHOOL_STUDENT_EDIT,
                                                        ).replace(":student", s.id)}
                                                    >
                                                        {s.fullName || s.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{s.uniquePupilNumber}</TableCell>
                                                <TableCell>
                                                    {!hasGroupCallSync ? (
                                                        <Button
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    "students",
                                                                    values.students.filter(
                                                                        stud => stud.id !== s.id,
                                                                    ),
                                                                );
                                                            }}
                                                            id="removeStudentButton"
                                                        >
                                                            {t("common.remove")}
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
                <Box mt={4}>
                    <Button
                        disabled={isSubmitting}
                        onClick={submitForm}
                        color="primary"
                        id="saveChangesButton"
                    >
                        {t("common.saveChanges")}
                    </Button>
                    <GenericErrorMessage errors={errors} submitCount={submitCount} />
                </Box>
            </PaperInner>
        </Form>
    );
};

export default ParentAddEditForm;
