import { ActionsUnion, Keys } from "@martin_hotell/rex-tils";
import { processEndpoints } from "src/api/helpers";
import { Modules } from "src/types";
import { StaffActionsTypes, StaffApiEndpoints, StaffRawActions } from "./Staff/actions";
import { SchoolActionsTypes, SchoolApiEndpoints, SchoolRawActions } from "./School/actions";
import {
    SubjectAreaActionsTypes,
    SubjectAreaApiEndpoints,
    SubjectAreaRawActions,
} from "./SubjectArea/actions";

export const SchoolActionTypes = {
    ...StaffActionsTypes,
    ...SchoolActionsTypes,
    ...SubjectAreaActionsTypes,
};

const _endpoints = {
    ...StaffApiEndpoints,
    ...SchoolApiEndpoints,
    ...SubjectAreaApiEndpoints,
};

export type SchoolEndpointKeys = Keys<typeof _endpoints>;

export const SchoolEndpoints = processEndpoints<SchoolEndpointKeys>(Modules.SCHOOL, _endpoints);

export const SchoolActions = {
    ...StaffRawActions(SchoolEndpoints),
    ...SchoolRawActions(SchoolEndpoints),
    ...SubjectAreaRawActions(SchoolEndpoints),
};

export type SchoolActions = ActionsUnion<typeof SchoolActions>;
