import clsx from "clsx";
import DividerVertical from "src/components/DividerVertical";
import HeadingCounter from "src/components/HeadingCounter";
import Icon from "src/components/Icon";
import COLORS from "src/styles/colors";
import PaperInner from "src/components/PaperInner";
import { Box, Button, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mdiChevronUp, mdiDelete, mdiPencil } from "@mdi/js";
import { TFunction } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ListObject } from "src/forms/types";
import { usePrevious } from "src/hooks/usePrevious";
import { PRIMARY_FONT_FAMILY } from "src/styles/theme";
import { useTrackerPath } from "../../hooks/useTrackerPath";

const useStyles = makeStyles((theme: Theme) => ({
    classMeta: {},
    classMetaItem: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    classMetaLabel: {
        color: COLORS.GREY_3,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: 400,
        fontFamily: PRIMARY_FONT_FAMILY,
    },
    classMetaText: {
        color: COLORS.GREY_1,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        margin: theme.spacing(0.25, 0, 0),
    },
    classMetaTextNormal: {
        fontWeight: 400,
    },
}));

const UploadClassCourseSummary = ({
    handlePanelShow,
    handleDelete,
    handlePanelHide,
    index,
    currentIndex,
    specificationId,
    fileNames,
}: {
    handlePanelShow: () => void;
    handleDelete: () => void;
    handlePanelHide: () => void;
    index: number;
    currentIndex: number | null;
    specificationId: number | null;
    fileNames: string[];
}) => {
    const { t }: { t: TFunction } = useTranslation();
    const classes = useStyles();
    const prevSpecificationId = usePrevious(specificationId);
    const { data: trackerPath, refetch } = useTrackerPath(specificationId);

    const getTrackerPathParam = name => {
        if (trackerPath) {
            const filtered = trackerPath.filter(tp => tp.type === name);
            if (filtered.length > 1) {
                let nameWithParent = "";
                filtered.forEach((f: ListObject<number>, i) => {
                    nameWithParent = nameWithParent + (i > 0 ? " - " : "") + f.name;
                });
                return { ...filtered[filtered.length - 1], name: nameWithParent };
            }

            return trackerPath?.find(tp => tp.type === name) || null;
        }
        return null;
    };

    const selectedSubjectArea = getTrackerPathParam("SubjectArea");
    const selectedQualification = getTrackerPathParam("Qualification");
    const selectedSpecification = getTrackerPathParam("Specification");

    useEffect(() => {
        if (specificationId && prevSpecificationId !== specificationId) {
            refetch();
        }
    }, [specificationId]);

    return (
        <PaperInner border="bottom" color="lightGrey" variant="course">
            <Box className={classes.classMeta}>
                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <HeadingCounter number={index + 1} variant="contained" size="big">
                            <Box className={classes.classMetaItem}>
                                <Box className={classes.classMetaLabel}>
                                    {t("class.addClass.course")}
                                </Box>
                                <Box className={classes.classMetaText}>
                                    {selectedSpecification ? (
                                        <>
                                            {selectedSubjectArea?.name} &gt;{" "}
                                            {selectedQualification?.name} &gt;{" "}
                                            {selectedSpecification.name}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </Box>
                            </Box>
                        </HeadingCounter>
                    </Grid>
                    <Grid item sm={4}>
                        <Box className={classes.classMetaItem}>
                            <Box className={classes.classMetaLabel}>
                                {t("class.addClass.files")}
                            </Box>
                            <Box
                                className={clsx(classes.classMetaText, classes.classMetaTextNormal)}
                            >
                                {fileNames && fileNames.length > 0
                                    ? fileNames.map(file => <Box key={file}>{file}</Box>)
                                    : "-"}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={4}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            height={24}
                        >
                            {index !== currentIndex && (
                                <Button
                                    onClick={handlePanelShow}
                                    variant="text"
                                    startIcon={<Icon path={mdiPencil} />}
                                    disableRipple
                                >
                                    {t("class.addClass.editCourseBtn")}
                                </Button>
                            )}
                            {index === currentIndex && (
                                <Button
                                    onClick={handlePanelHide}
                                    variant="text"
                                    startIcon={<Icon path={mdiChevronUp} />}
                                    disableRipple
                                >
                                    {t("class.addClass.collapseCourseBtn")}
                                </Button>
                            )}
                            {index > 0 && (
                                <>
                                    <DividerVertical margin={4} height={24} />
                                    <Button
                                        onClick={handleDelete}
                                        variant="text"
                                        startIcon={<Icon path={mdiDelete} />}
                                        disableRipple
                                    >
                                        {t("class.addClass.deleteBtn")}
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </PaperInner>
    );
};

export default UploadClassCourseSummary;
