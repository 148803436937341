import { Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Dispatch } from "redux";
import { useMount } from "src/hooks/useMount";
import { AppState } from "src/store/reducers";
import { ReportActions } from "../store/actions";

const dispatchActions = (dispatch: Dispatch) => ({
    validate: hash => {
        dispatch(ReportActions.validateStudentReport(hash));
    },
});

const ReportDownloadContainer = () => {
    const routerParams = useParams();
    const { t } = useTranslation();
    const hash = routerParams["hash"];
    const dispatch = useDispatch();
    const { validate } = dispatchActions(dispatch);
    const { url }: { url: string | null } = useSelector(
        (state: AppState) => ({
            url: state.report.reportValidationUrl,
        }),
        shallowEqual,
    );

    useMount(() => {
        validate(hash);
    });

    useEffect(() => {
        if (url) {
            window.location.replace(url);
        }
    }, [url]);

    return (
        <Box
            component="main"
            height="80vh"
            px={10}
            pt={14}
            pb={3}
            display="flex"
            flexDirection="column"
        >
            {t("report.studentReport.download")}
            {url && (
                <a href={url} target={"blank"}>
                    {t("report.studentReport.link")}
                </a>
            )}
        </Box>
    );
};

export default ReportDownloadContainer;
