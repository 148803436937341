import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import { AjaxErrorCustom, ApiActionMeta, ApiPayload } from "./constants";

export enum ApiActionTypes {
    API_REQUEST = "[api] REQUEST",
    API_SUCCESS = "[api] SUCCESS",
    API_ERROR = "[api] ERROR",
}

export interface ApiRequestPayload<
    V extends object = object,
    P extends object | undefined = undefined,
> {
    payload: ApiPayload<V, P>;
    meta: ApiActionMeta;
    status?: number | null;
}

export interface ApiSuccessPayload {
    meta: ApiActionMeta;
    status?: number | null;
    responseStatus?: number;
}

export interface ApiErrorPayload {
    error: AjaxErrorCustom;
    meta: ApiActionMeta;
    status?: number | null;
}

export const ApiActions = {
    apiRequest: (payload: ApiRequestPayload) => createAction(ApiActionTypes.API_REQUEST, payload),
    apiSuccess: (payload: ApiSuccessPayload) => createAction(ApiActionTypes.API_SUCCESS, payload),
    apiError: (payload: ApiErrorPayload) => createAction(ApiActionTypes.API_ERROR, payload),
};

export type ApiActions = ActionsUnion<typeof ApiActions>;
