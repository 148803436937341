import { Box, Chip, MenuItem, TextField, SelectProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FieldProps, getIn } from "formik";
import { ListObject, IdParam } from "../types";
import { theme } from "src/styles/theme";
import Icon from "src/components/Icon";
import { mdiClose } from "@mdi/js";
import COLORS from "src/styles/colors";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    chipsList: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(1.5, 1.5, 0, 0),
        },
    },
}));

export interface MultiSelectProps extends FieldProps, Omit<SelectProps, "value"> {
    options: ListObject<number>[];
}

const MultipleSelectField = ({
    placeholder,
    helperText,
    options,
    field: { name, value },
    form: { touched, errors, setFieldValue, isSubmitting },
    disabled,
    ...props
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const fieldError = getIn(errors, name);
    const showError = getIn(touched, name) && !!fieldError;
    const formHelperText = showError ? fieldError : helperText;
    const availableOptions = options
        .filter(option => !value.some(val => val.id === option.id))
        .map(option => {
            if (option.rgt - option.lft > 1) option.disabled = true;
            return option;
        });

    const availableNotDisabledOptions = availableOptions.filter(
        ao => ao.disabled === undefined || ao.disabled !== true,
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement & { value: IdParam[] }>) => {
        setFieldValue(
            name,
            event.target.value.map(id => ({ id })),
        );
        setOpen(false);
    };

    const handleRemove = id =>
        setFieldValue(
            name,
            value.filter(val => val.id !== id),
        );

    const handleOpen = () => availableNotDisabledOptions.length > 0 && setOpen(true);

    const handleClose = () => setOpen(false);

    const fieldDisabled =
        disabled !== undefined
            ? disabled
            : availableNotDisabledOptions.length === 0 || isSubmitting;

    return (
        <>
            {!props.hideSelect && (
                <TextField
                    name={name}
                    error={showError}
                    disabled={fieldDisabled}
                    value={value && value.length > 0 ? value.map(v => (v.id ? v.id : false)) : []}
                    // placeholder={placeholder}
                    // label={label}
                    helperText={formHelperText}
                    select
                    SelectProps={{
                        multiple: true,
                        displayEmpty: true,
                        onOpen: handleOpen,
                        onClose: handleClose,
                        open: open,
                        onChange: handleChange,
                        renderValue: () => placeholder,
                    }}
                    {...props}
                >
                    {availableOptions.map(obj => (
                        <MenuItem
                            disabled={obj.disabled && obj.disabled === true}
                            key={obj.id}
                            value={obj.id}
                        >
                            {obj.lvl && obj.lvl > 0 ? "―" : ""}
                            {obj.displayName ? obj.displayName : obj.name || obj.id}
                        </MenuItem>
                    ))}
                </TextField>
            )}

            {value && value.length > 0 && (
                <Box mt={1.5} className={classes.chipsList}>
                    {value.map(val => {
                        const option = options.find(o => parseInt(o.id) === parseInt(val.id));
                        if (option) {
                            return (
                                <Chip
                                    style={{
                                        background:
                                            options.find(o => parseInt(o.id) === parseInt(val.id))
                                                .colour || COLORS.BLUE_3,
                                        color: COLORS.WHITE,
                                    }}
                                    key={`selectedCat-${val.id}`}
                                    label={options.find(option => option.id === val.id).name}
                                    onDelete={
                                        !props.disableDelete
                                            ? () => handleRemove(val.id)
                                            : undefined
                                    }
                                    deleteIcon={
                                        // !disabled ? (
                                        !props.disableDelete ? (
                                            <Box
                                                component="span"
                                                color={theme.palette.getContrastText(
                                                    options.find(
                                                        o => parseInt(o.id) === parseInt(val.id),
                                                    ).colour || COLORS.GREY_2,
                                                )}
                                            >
                                                <Icon path={mdiClose} />
                                            </Box>
                                        ) : undefined

                                        // ) : (
                                        //     undefined
                                        // )
                                    }
                                />
                            );
                        }
                        return null;
                    })}
                </Box>
            )}
        </>
    );
};

export default MultipleSelectField;
