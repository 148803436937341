import axiosInstance from "src/config/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { PARENT_LIST_QUERY } from "./useParentsList";
import { getSchoolAccountId } from "src/services/url";

export interface ParentUninviteRequest {
    id?: number;
}

const apiUninvite = async (values: ParentUninviteRequest): Promise<AxiosResponse> => {
    return await axiosInstance.post(
        `school/${getSchoolAccountId()}/parents/${values.id}/uninvite/`,
        values,
    );
};

export const useParentUninviteMutation = (onSuccess: () => void) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: ParentUninviteRequest) => apiUninvite(values),
        onSuccess: () => {
            enqueueSnackbar(t("parent.list.uninvited"), { variant: "success" });
            queryClient.refetchQueries({ queryKey: [PARENT_LIST_QUERY] });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: AxiosError<{ error?: string; errors?: any }>) => {
            if (err.response.data?.error) {
                enqueueSnackbar(err.response.data?.error || t("parent.list.activateError"), {
                    ...SnackbarErrorOptions,
                });
            }
        },
    });
};
