import axiosInstance from "src/config/axios";
import { useQuery } from "@tanstack/react-query";
import { getSchoolAccountId } from "src/services/url";

export const ANALYSIS_COMMON_RELATED_DATA_QUERY = "analysisCommonRelatedDataQuery";

export interface AnalysisColourConfigDetailsObject {
    mtgDistance: string;
    opacity: number;
    threshold: number;
    colour: string;
    gradeId: number;
}

export interface AnalysisRelatedDataObject {
    colourConfig: {
        type: string;
        details: AnalysisColourConfigDetailsObject[];
    }[];
    gradeTypes: [
        {
            id: number;
            name: string;
            courseGrades: [
                {
                    name: string;
                    id: number;
                },
            ];
        },
    ];
}

export const useAnalysisCommonRelatedData = (cohort: number, yearGroup: number) => {
    const schoolAccountId = getSchoolAccountId();
    return useQuery({
        queryKey: [ANALYSIS_COMMON_RELATED_DATA_QUERY, schoolAccountId, cohort, yearGroup],
        queryFn: async (): Promise<AnalysisRelatedDataObject | null> => {
            if (cohort && yearGroup) {
                const { data } = await axiosInstance.get(
                    `school/${schoolAccountId}/analysis/common/${cohort}/${yearGroup}/related-data/`,
                );
                return data || null;
            }
            return null;
        },
    });
};
