import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { AnalysisAtlValuesObject } from "./getValues";
import { getSchoolAccountId } from "src/services/url";

export const getAnalysisAtlSnapshotCompareEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/analysis/atl/{cohort}/{yearGroup}/snapshot/compare/{dataSource1}/{dataSource2}`,
    method: ApiRequests.GET,
};

export interface AtlSnapshotCompareResponse {
    values1: AnalysisAtlValuesObject;
    values2: AnalysisAtlValuesObject;
}
