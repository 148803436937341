import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PromptDialog from "src/forms/PromptDialog";
import { useCoreValues } from "src/modules/common/hooks/useCore";

interface OwnProps {
    modalOpen: boolean;
    yearGroupsWithLockedMtg: number[];
    modalClose: (confirm: boolean, newYearGroupsWithLockedMtg: number[]) => void;
}

const LockMtgModal: FC<OwnProps> = ({ modalOpen, modalClose, yearGroupsWithLockedMtg }) => {
    const { t } = useTranslation();
    const [selectedYg, setSelectedYg] = useState<number[]>(yearGroupsWithLockedMtg || []);
    const { data } = useCoreValues();
    const yearGroups = data?.yearGroups;

    useEffect(() => {
        setSelectedYg(yearGroupsWithLockedMtg);
    }, [yearGroupsWithLockedMtg]);

    return (
        <PromptDialog
            open={modalOpen}
            noLabel="Cancel"
            yesLabel={t("common.select")}
            onClose={confirm => {
                modalClose(confirm, selectedYg);
                if (!confirm) {
                    setSelectedYg(yearGroupsWithLockedMtg);
                }
            }}
        >
            <Typography>{t("school.schoolDetails.setNewLockMtgList")}</Typography>
            {yearGroups?.map(yg => (
                <>
                    <FormControlLabel
                        key={yg.id}
                        control={
                            <Checkbox
                                name="yg"
                                value={yg.id}
                                checked={!!(selectedYg.find(syg => syg === yg.id) > -1)}
                                onChange={(e, checked) => {
                                    let newSelectedYg = [...selectedYg];

                                    if (checked) {
                                        newSelectedYg.push(yg.id);
                                    } else {
                                        newSelectedYg = newSelectedYg.filter(nsy => nsy !== yg.id);
                                    }

                                    setSelectedYg(newSelectedYg);
                                }}
                            />
                        }
                        label={yg.name}
                    />
                </>
            ))}
        </PromptDialog>
    );
};
export default LockMtgModal;
