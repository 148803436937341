import { ofType } from "@martin_hotell/rex-tils";
import { apiUrl } from "src/config/globals";
import { NotificationActions } from "src/notifications/store/actions";
import { blob, get, patch, post, postToBlob, put, remove } from "src/services/ajax";
import { bugsnagClient } from "src/services/bugsnagClient";
import { ActionsObservable, combineEpics } from "redux-observable";
import { concat, empty, Observable, of } from "rxjs";
import { AjaxError, AjaxResponse } from "rxjs/ajax";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ApiActions, ApiActionTypes, ApiRequestPayload } from "./actions";
import { AjaxErrorCustom, ApiRequests } from "./constants";
import { ApiRequestAction } from "./middleware";
import { Modules } from "src/types";
import { Storage } from "src/services/storage";
import { SnackbarErrorOptions } from "src/components/SnackbarErrorAction.tsx";
import { ROUTE_LOGOUT } from "src/routes";

const getHandler = (requestType: ApiRequests): Function => {
    switch (requestType) {
        case ApiRequests.GET:
            return get;
        case ApiRequests.POST:
            return post;
        case ApiRequests.PATCH:
            return patch;
        case ApiRequests.PUT:
            return put;
        case ApiRequests.REMOVE:
            return remove;
        case ApiRequests.BLOB:
            return blob;
        case ApiRequests.POSTBLOB:
            return postToBlob;
        default:
            return get;
    }
};

const createUrlFromPayload = (payload: ApiRequestPayload): string => {
    const url = payload.meta.endpoint.replace(/\{(\w+)\}/g, (all, key) => {
        const processed =
            payload.payload.params && payload.payload.params[key] === 0
                ? "0"
                : (payload.payload.params && payload.payload.params[key]) || "";

        return processed;
    });

    return apiUrl(url);
};

const processRequest = (payload: ApiRequestPayload): Observable<AjaxResponse> =>
    getHandler(payload.meta.method)(createUrlFromPayload(payload), payload.payload.values);

export const standarApiErrorHandler = (
    error: AjaxErrorCustom,
    statuses = [403],
    message = "Unknown API error",
): Observable<any> => {
    const { url, body, method, responseType } = error.request;
    const errorMessage: any = {
        severity: "error",
        metaData: {
            payload: { body: body ? JSON.parse(body) : {} },
            ajax: {
                status: error.status,
                url,
                method,
                responseType,
            },
        },
    };
    switch (error.status) {
        case 0:
            return of(
                NotificationActions.enqueueSnackbar({
                    message: "API unreachable",
                    ...(SnackbarErrorOptions as any),
                }),
            );
        case 401:
            Storage.removeItem("token");
            Storage.removeItem("schoolAccountId");
            Storage.removeItem("reminderConfirmed");
            Storage.removeItem("hasReminder");
            return of("clearAuthContext", window.location.replace(ROUTE_LOGOUT));
        case 500:
            bugsnagClient.notify({ name: "Internal 500", message: errorMessage });
            return of(
                NotificationActions.enqueueSnackbar({
                    message: "API Error",
                    ...(SnackbarErrorOptions as any),
                }),
            );
        case 503:
            Storage.setItem("maintenanceMessage", error.response["maintenanceMessage"]);
            if (!window.location.href.includes("maintenance")) {
                return of(window.location.replace(`/${Modules.MAINTENANCE}`));
            }
            return empty();
    }

    if (statuses.includes(error.status)) {
        if (error.response.error) {
            return of(
                NotificationActions.enqueueSnackbar({
                    message: error.response.error,
                    ...(SnackbarErrorOptions as any),
                }),
            );
        } else {
            bugsnagClient.notify(errorMessage);

            return of(
                NotificationActions.enqueueSnackbar({
                    message,
                    ...(SnackbarErrorOptions as any),
                }),
            );
        }
    }

    return empty();
};

const createOnFailureAction = (payload: ApiRequestPayload, error: AjaxError): Observable<any> =>
    payload.meta.onFailure
        ? of({
              type: payload.meta.onFailure,
              payload: {
                  error,
                  request: payload,
              },
          })
        : standarApiErrorHandler(error);

const apiRequestEpic = (action$: ActionsObservable<ApiActions>) =>
    action$.pipe(
        ofType(ApiActionTypes.API_REQUEST),
        mergeMap(({ payload }: ApiRequestAction) => {
            return processRequest(payload).pipe(
                map(({ response, status }) => ({ response, status })),
                mergeMap(({ response, status }) =>
                    concat(
                        of(
                            ApiActions.apiSuccess({
                                meta: payload.meta,
                                responseStatus: status,
                            }),
                        ),
                        payload.meta.onSuccess
                            ? of({
                                  type: payload.meta.onSuccess,
                                  payload: {
                                      response: response,
                                      status: status,
                                      request: payload,
                                  },
                              })
                            : empty(),
                    ),
                ),
                catchError(err => {
                    if (!(err instanceof AjaxError)) {
                        throw err;
                    }
                    return concat(
                        of(ApiActions.apiError({ error: err, meta: payload.meta })),
                        createOnFailureAction(payload, err),
                    );
                }),
            );
        }),
    );

export const apiEpics = combineEpics(apiRequestEpic);
