import { Box, Theme } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { withStyles, createStyles } from "@mui/styles";
import { FC } from "react";
import COLORS from "src/styles/colors";

export enum DataTypeTypes {
    FORECAST = "forecast",
    SNAPSHOT = "snapshot",
    LIVE = "live",
}

const CustomToggleButton = withStyles(({ spacing, typography, transitions }: Theme) =>
    createStyles({
        root: {
            border: `1px solid ${COLORS.VERY_LIGHT_GREY_3}`,
            color: COLORS.GREY_1,
            fontFamily: typography.fontFamilyPrimary,
            textTransform: "capitalize",
            height: "auto",
            padding: `${spacing(0.75)}px ${spacing(1.75)}px`,
            transition: transitions.create(["background-color", "color"], {
                duration: transitions.duration.short,
            }),
        },
        selected: {
            "&.tierH": {
                backgroundColor: COLORS.GREEN_1,
                color: COLORS.WHITE,
                "&:hover": {
                    backgroundColor: COLORS.GREEN_1,
                },
            },
            "&.tierI": {
                backgroundColor: COLORS.GREEN_1,
                color: COLORS.WHITE,
                "&:hover": {
                    backgroundColor: COLORS.GREEN_1,
                },
            },
            "&.tierF": {
                backgroundColor: COLORS.GREEN_1,
                color: COLORS.WHITE,
                "&:hover": {
                    backgroundColor: COLORS.GREEN_1,
                },
            },
        },
        label: {
            "& > svg": {
                display: "inherit",
                marginRight: spacing(1),
            },
        },
    }),
)(ToggleButton);

interface OwnProps {
    tiers: string[];
    selectedTier: string;
    handleChange: (tier: string) => void;
}

const TierHeaderSelect: FC<OwnProps> = ({ handleChange, tiers, selectedTier }) => {
    return (
        <Box pr={2} pl={2}>
            <ToggleButtonGroup
                value={selectedTier}
                onChange={(_, value) => {
                    handleChange(value);
                }}
                exclusive
            >
                {tiers &&
                    tiers.length > 1 &&
                    tiers.map(tierFullName => {
                        const tier = tierFullName.charAt(0);
                        return (
                            <CustomToggleButton key={tier} className={"tier" + tier} value={tier}>
                                {tier}
                            </CustomToggleButton>
                        );
                    })}
            </ToggleButtonGroup>
        </Box>
    );
};

export default TierHeaderSelect;
