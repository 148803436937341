import { ApiEndpoint, ApiRequests } from "src/api/constants";
import { StudentModel } from "src/orm/models/Student";
import { getSchoolAccountId } from "src/services/url";

export const getStudentsByClassEndpoint: ApiEndpoint = {
    endpoint: `school/${getSchoolAccountId()}/class-tracker/{classTracker}/students/`,
    method: ApiRequests.GET,
};

export type StudentsByClassResponse = StudentModel[];
